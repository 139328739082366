export type ProductStatus = 0 | 10 | 110 | 11 | 1 | 12 | 2 | 3 | 99 | 1001 | 1003 | 1002 | 1004 | 1012 | 122 | 123 | 124 | 2020 | 1200

export const JUST_SAVED: ProductStatus = 0
export const LOADING_FAILED: ProductStatus = 2

// Валидация пройдена/не пройдена
export const VALIDATION_OK: ProductStatus = 10
export const VALIDATION_FAILED: ProductStatus = 110

// Ценник по умолчанию есть/отсутствует
export const NO_DEFAULT_PRICE_TAG: ProductStatus = 11
export const DEFAULT_PRICE_TAG_PRESENTED: ProductStatus = 1

// Ценник напечатан/не напечатан
export const PRICE_TAG_NOT_PRINTED: ProductStatus = 12

// Товар помещен в буфер товаров для печати ценников
export const MARKED_FOR_PRINTING: ProductStatus = 3

// Товар активен и готов к выгрузке на кассу и продаже
export const ACTIVE: ProductStatus = 99

// Товар не загружен на кассу (лежит в очереди на отправку на кассу)
export const NOT_SENT_TO_CASH: ProductStatus = 1001
export const FAILED_SENT_TO_CASH: ProductStatus = 1003

// Товар не загружен на весы (лежит в очереди на отправку на весы)
export const NOT_SENT_TO_SCALES: ProductStatus = 1002
export const FAILED_SENT_TO_SCALES: ProductStatus = 1004

// Товар не привязан к весам
export const NOT_BINDED_WITH_SCALES: ProductStatus = 1012

// При печати ценника возникла ошибка
export const PRICE_TAG_PRINT_ERROR: ProductStatus = 1200

// Не напечатан добавочный или замещающий ценник на товар
export const ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED: ProductStatus = 122
export const ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED: ProductStatus = 123
// Не актуальные скидочные ценники
export const ACTION_PRICE_TAG_EXPIRED: ProductStatus = 124
// У товара закончилась регулярная цена
export const REGULAR_PRICE_ENDED: ProductStatus = 2020
