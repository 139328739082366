import { PrintingModulePropertiesVO } from './set-retail10-server/retailx/set-print-price-tags/printing-module-properties-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class PrintingModuleConfigurator extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-PrintPriceTags/SET/PrintingModuleConfigurator!ru.crystals.setretailx.printing.configuration.PrintingModuleConfigurator'
    }

    getProperties1 = (options?: RequestOptions): Promise<PrintingModulePropertiesVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getProperties',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getProperties2 = (renewCache: boolean, options?: RequestOptions): Promise<PrintingModulePropertiesVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getProperties',
                params: [renewCache]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    setProperties = (properties: PrintingModulePropertiesVO, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'setProperties',
                params: [properties]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const printingModuleConfigurator: PrintingModuleConfigurator = new PrintingModuleConfigurator()
