import { createCardTypeVO } from './card-type-vo'
import { createBaseVO } from './base-vo'
import { CardTypeVO } from './card-type-vo'
import { ConditionChangeVO } from './condition-change-vo'

export interface InternalCardsVO extends CardTypeVO {
    '@class': 'ru.crystals.cards.common.InternalCardsVO'
    percentageDiscount?: number
    accumulative?: boolean
    bonus?: boolean
    domesticCredit?: boolean
    creditLimit?: number
    withoutFinishDate?: boolean
    start?: string
    finish?: string
    showCardFromRangeNotification?: boolean
    conditionChanges?: ConditionChangeVO[]
}

export const createInternalCardsVO = (initialization: Partial<InternalCardsVO>): InternalCardsVO => {
    if (!initialization) return null
    
    let result: InternalCardsVO = {
        ...createCardTypeVO(initialization),        ...createBaseVO(initialization),
        percentageDiscount: initialization.percentageDiscount,
        accumulative: initialization.accumulative,
        bonus: initialization.bonus,
        domesticCredit: initialization.domesticCredit,
        creditLimit: initialization.creditLimit,
        withoutFinishDate: initialization.withoutFinishDate,
        start: initialization.start,
        finish: initialization.finish,
        showCardFromRangeNotification: initialization.showCardFromRangeNotification,
        conditionChanges: initialization.conditionChanges,
        '@class': 'ru.crystals.cards.common.InternalCardsVO',
    }
    
    return result
}
