import { t } from 'i18next'
import { action, observable, runInAction, toJS, computed } from 'mobx'
import { iEquipmentManagerLocal } from '../../../../protocol/set10/i-equipment-manager-local'
import { EquipmentModelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { FormValidation, ValidationResult } from '../../../../utils/form-validation/form-validation'
import { requiredField } from '../../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../../utils/form-validation/validators/unique-field'
import {
    createPriceCheckerVO,
    PriceCheckerVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-checker-vo'
import {priceCheckerManagerLocal} from '../../../../protocol/set10/price-checker-manager-local'
import {
    PRICE_DISPLAYING, PriceCheckerType, DATE_OF_MANUFACTURE_LOADING
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-checker-type'
import { AppStore } from '../../app-store'
import { UserStore } from '../../user-store'
import { getStore } from '../../stores-repository'
import {
    APP_STORE,
    SHOP_STORE,
    USER_STORE,
    SHOP_SCALES_STORE,
} from '../../stores'
import { withSpinner } from '../../with-spinner'
import {DeviceSettingsType} from '../../../core/values'
import {ShopStore} from './shop-store'
import {ShopScalesStore} from './shop-scales-store'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { sortBy } from 'lodash'
import uuid from 'uuid'
import { regExpValidator } from '../../../../utils/form-validation/validators/regexp-validator'
import { fieldLengthValidator } from '../../../../utils/form-validation/validators/length-validator'
import { textValidator } from '../../../../utils/form-validation/validators/text-validator'

export class ShopPriceCheckersStore {

    @observable
    priceCheckers: PriceCheckerVO[] = []
    @observable
    allPriceCheckers: PriceCheckerVO[] = []
    @observable
    priceCheckersModels: EquipmentModelVO[] = []
    @observable
    priceCheckerSettings: PriceCheckerVO
    @observable
    priceCheckerValidation: FormValidation<PriceCheckerVO>
    @observable
    priceCheckerSettingsType: DeviceSettingsType
    @observable
    showNotBoundPriceCheckers: boolean = true
    @observable
    priceCheckerDevicesDialogOpen: boolean = false
    @observable
    paginatorKey: string = ''
    @observable
    paginationState: PaginationState = undefined

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private shopStore: ShopStore = getStore(SHOP_STORE)
    private shopScalesStore: ShopScalesStore = getStore(SHOP_SCALES_STORE)

    @computed
    get sortedPriceCheckers(): PriceCheckerVO[]  {
        if (this.appStore.isCentrum) {
            return sortBy(this.priceCheckers, ['shopNumber', 'type', 'number'])
        }
        return sortBy(this.priceCheckers, ['type', 'number'])
    }

    // PriceCheckers

    fetchAllPriceChekersData = async (): Promise<void> => {
        await withSpinner(async () => {
            await this.fetchPriceCheckers()
            await this.fetchPriceCheckersModels()
            if (this.appStore.isCentrum) {
                await this.fetchAllPriceCheckers()
                if (this.showNotBoundPriceCheckers) {
                    await this.fetchPriceCheckersWithoutShop()
                }
            } else {
                await this.shopScalesStore.fetchScalesTemplates()
            }
        })
    }

    // На центруме необходим список всех прайс-чекеров, чтобы исключить совпадения по мак-адресу
    fetchAllPriceCheckers = (): Promise<void> => {
        return priceCheckerManagerLocal.findPriceCheckers1()
            .then(allPriceCheckers => {
                runInAction(() => {
                    this.allPriceCheckers = allPriceCheckers
                })
            })
    }

    fetchPriceCheckers = (): Promise<void> => {
        if (!this.shopStore.shop || !this.shopStore.shop.number) {
            return Promise.resolve()
        }
        return priceCheckerManagerLocal.findPriceCheckers2(this.shopStore.shop.number)
            .then(priceCheckers => {
                runInAction(() => {
                    this.priceCheckers = priceCheckers
                    this.paginatorKey = uuid()
                })
            })
    }

    fetchPriceCheckersWithoutShop = (): Promise<void> => {
        return priceCheckerManagerLocal.findPriceCheckersWithoutShop()
            .then(priceCheckersWithoutShop => {
                runInAction(() => {
                    this.priceCheckers = this.priceCheckers.concat(priceCheckersWithoutShop)
                    this.paginatorKey = uuid()
                })
            })
    }

    fetchPriceCheckersModels = (): Promise<void> => {
        return iEquipmentManagerLocal.getAllRegisteredEquipmentModelsOfClass(this.userStore.session, 'PriceCheckers', this.appStore.locale)
            .then(priceCheckersModels => {
                runInAction(() => {
                    this.priceCheckersModels = priceCheckersModels
                })
            })
    }

    @action
    showPriceCheckersDevicesDialog = (): void => {
        this.priceCheckerDevicesDialogOpen = true
    }

    @action
    closePriceCheckersDevicesDialog = (): Promise<void> => {
        this.priceCheckerDevicesDialogOpen = false

        // При закрытии диалога надо обновить доступные модели -
        return this.fetchPriceCheckersModels()
    }

    @action
    editPriceChecker = (priceChecker: PriceCheckerVO): void => {
        if (priceChecker) {
            this.priceCheckerSettings = toJS(priceChecker)
            this.priceCheckerSettingsType = DeviceSettingsType.EDIT
            this.createPriceCheckerValidation()
        }
    }

    @action
    addPriceChecker = (): void => {
        this.priceCheckerSettings = this.getBlankPriceCheckerSettings()
        this.priceCheckerSettingsType = DeviceSettingsType.NEW
        this.createPriceCheckerValidation()
    }

    getBlankPriceCheckerSettings = (): PriceCheckerVO => {
        const firstModel = this.priceCheckersModels.length > 0 ? this.priceCheckersModels[0].id : null

        let usedNumbers: boolean[] = []
        this.priceCheckers.forEach(priceChecker => usedNumbers[priceChecker.number] = true)

        let nextNumber: number
        for (nextNumber = 1; nextNumber < usedNumbers.length; nextNumber++) {
            if (!usedNumbers[nextNumber]) {
                break
            }
        }

        return createPriceCheckerVO({
            id: -1,
            shopNumber: this.shopStore.shop.number,
            mac: '',
            name: '',
            type: PRICE_DISPLAYING,
            number: nextNumber,
            scalesTemplateId: -1,
            equipmentModelId: firstModel
        })
    }

    @action
    createPriceCheckerValidation = (creation: boolean = false): void => {
        this.priceCheckerValidation = new FormValidation<PriceCheckerVO>(
            this.priceCheckerSettings,
            [
                {
                    field: 'number',
                    rules: [
                        (value: number): ValidationResult => {
                            if (!value) {
                                return {
                                    valid: false,
                                    error: t('validation.requiredField'),
                                }
                            } else {
                                return {
                                    valid: true
                                }
                            }
                        }
                    ]
                },
                {
                    field: 'name',
                    rules: [
                        fieldLengthValidator({ max: 255 }),
                    ]
                },
                {
                    field: 'mac',
                    rules: [
                        requiredField,
                        fieldLengthValidator({ max: 255 }),
                        uniqueField((this.appStore.isCentrum ? this.allPriceCheckers : this.priceCheckers)
                            .filter(p => p.id !== this.priceCheckerSettings.id)
                            .map(p => p.mac))
                    ]
                },
                {
                    field: 'equipmentModelId',
                    rules: [
                        requiredField
                    ]
                },
                {
                    field: 'scalesTemplateId',
                    rules: [
                        (value: number): ValidationResult => {
                            if (this.priceCheckerSettings.type === DATE_OF_MANUFACTURE_LOADING && value === -1) {
                                return {
                                    valid: false,
                                    error: t('validation.requiredField'),
                                }
                            }
                            return {
                                valid: true
                            }
                        }
                    ]
                },
            ],
            creation
        )
    }

    @action
    toggleShowNotBoundPriceCheckers = (): Promise<void> => {
        this.showNotBoundPriceCheckers = !this.showNotBoundPriceCheckers
        return withSpinner(this.fetchAllPriceChekersData())
    }

    @action
    updatePriceCheckerType = (newType: PriceCheckerType) => {
        this.priceCheckerSettings.type = newType
        if (newType === DATE_OF_MANUFACTURE_LOADING && this.shopScalesStore.scalesTemplates.length > 0) {
            this.priceCheckerSettings.scalesTemplateId = this.shopScalesStore.scalesTemplates[0].id
        } else {
            this.priceCheckerSettings.scalesTemplateId = -1
        }
    }

    @action
    updatePriceChecker = (changes: Partial<PriceCheckerVO>) => {
        Object.keys(changes).forEach(k => {
            this.priceCheckerSettings[k] = changes[k]
        })
    }

    @action
    closePriceCheckerSettings = () => {
        this.priceCheckerSettings = null
        this.priceCheckerSettingsType = null
        this.priceCheckerValidation = null
    }

    deletePriceChecker = async (priceChecker: PriceCheckerVO): Promise<void> => {
        // После удаления обновляем список
        await withSpinner(async () => {
            await priceCheckerManagerLocal.removePriceChecker(priceChecker.id)
            await this.fetchPriceCheckers()
            // Для центрума надо обновить список всех прайс-чекеров
            if (this.appStore.isCentrum) {
                await this.fetchAllPriceCheckers()
                if (this.showNotBoundPriceCheckers) {
                    await this.fetchPriceCheckersWithoutShop()
                }
            }
        })
    }

    savePriceChecker = (priceChecker: PriceCheckerVO): Promise<void> => {
        return withSpinner(priceCheckerManagerLocal.savePriceChecker(priceChecker)
            .then(savedPriceChecker => {
                if (savedPriceChecker) {
                    if (savedPriceChecker.shopNumber !== this.shopStore.shop.number) {
                        // При сохранении мы могли назначить прайс-чекеру другой магазин
                        runInAction(() => {
                            const deletedId = savedPriceChecker.id
                            const deleteIndex = this.priceCheckers.findIndex(item => item.id === deletedId)
                            if (deleteIndex !== -1) {
                                this.priceCheckers.splice(deleteIndex, 1)
                            }
                            this.paginatorKey = uuid()

                            // Для центрума надо обновить список всех прайс-чекеров
                            if (this.appStore.isCentrum) {
                                this.fetchAllPriceCheckers()
                            }
                        })
                        return
                    }
                    runInAction(() => {
                        let id = savedPriceChecker.id
                        let index = this.priceCheckers.findIndex(item => item.id === id)
                        if (index !== -1) {
                            this.priceCheckers[index] = savedPriceChecker
                        } else {
                            this.priceCheckers.push(savedPriceChecker)
                        }
                        this.paginatorKey = uuid()

                        // Для центрума надо обновить список всех прайс-чекеров
                        if (this.appStore.isCentrum) {
                            this.fetchAllPriceCheckers()
                        }
                    })
                }
            }))
    }

    @action
    setPaginationState = (paginationState: PaginationState) => {
        this.paginationState = paginationState
    }

    @action
    reset = (): void => {
        this.priceCheckers = []
        this.allPriceCheckers = []
        this.priceCheckersModels = []
        this.priceCheckerSettings = undefined
        this.priceCheckerValidation = undefined
        this.priceCheckerSettingsType = undefined
        this.showNotBoundPriceCheckers = true
        this.priceCheckerDevicesDialogOpen = false
        this.paginatorKey = ''
        this.paginationState = undefined
    }
}
