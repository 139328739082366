import {FILE_TYPE} from '../../core/file-types'
import {config} from '../../config/config'
import {
    STORNO,
    CHANGE,
    CANCEL,
    OperationType
} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/operation-type'
import {t} from 'i18next'
import { fromServerToClientTime } from '../../utils/app-util'

export interface ReportWithDateRange {
    datePeriodStart: Date
    datePeriodEnd: Date
}

export type ReportActionTypes = 'AccumulationsByCards'
    | 'ProductReportInAction'
    | 'BonusesByCards'
    | 'LOYALTRANSACTIONSBYACTION'
    | 'StartOrFinishPrices'
    | 'IncorrectActionPriceReport'
    | 'KM4'
    | 'SoldGoods'
    | 'ALCOHOL_LIMIT'
    | 'PriceCheckerReport'
    | 'GoodsCheque'
    | 'ProductReportInAction'
    | 'PURCHASESWITHCORRECTION'
    | 'RefundReport'
    | 'StornoReport'
    | 'PluInScales'
    | 'TobaccoPrice'
    | 'CashRegNumberReport'
    | 'getFile'
    | 'ProductReport'
    | 'INTERVAL_CASHIERS_ACTIVITY'
    | 'CASHIERPERFORMANCEREPORT'
    | 'getFileStream'
    | 'SCALESTEMPLATEPRODUCTS'
    | 'CASHLESS_PAYMENTS_OF_CASHIER'

export type ReportParametersNames = 'BEGIN'
    | 'END'
    | 'ERP_CODE'
    | 'SHOP'
    | 'ACTION_ID'
    | 'PRICE_NUMBER'
    | 'CASH'
    | 'CASHES'
    | 'PRODUCT_GROUP'
    | 'GOOD'
    | 'REGION_ID'
    | 'CITY_ID'
    | 'SHOP_ID'
    | 'PRICE_CHECKER_TYPE'
    | 'PRICE_CHECKER_MAC'
    | 'TAB_NUMBER'
    | 'PURCHASE_ACTION_TYPE'
    | 'FILE_NAME'
    | 'DATE'
    | 'CASH_TEMPLATE_IDS'
    | 'INTERVAL_SECONDS'
    | 'PURCHASE_ID'
    | 'SCALE_TEMPLATE_ID'
    | 'LIST_TYPE'

export type ReportParameters = {
    [k in ReportParametersNames]?: string | number | string[]
}

export const getDateTime = (date: Date): number => {
    return fromServerToClientTime(date)?.getTime()
}

export const openReport = (actionName: ReportActionTypes, fileType?: FILE_TYPE, params?: ReportParameters) => {
    let reportUrl = `${config.reportsAddress}?Action=${actionName}`

    if (params) {
        Object.keys(params).forEach(key => {
            reportUrl += `&${key}=${encodeURIComponent(params[key])}`
        })
    }

    if (fileType) {
        reportUrl += `&FILE_TYPE=${fileType}`
    }

    window.open(reportUrl)
}

export const getStornoTypes = (): Array<{ label: string, value: OperationType }> => [
    {label: t('reports.typeStorno'), value: STORNO},
    {label: t('reports.typeChange'), value: CHANGE},
    {label: t('reports.typeCancel'), value: CANCEL},
]

export type IntervalType = 'DATE' | 'DAY_OF_WEEK' | 'HOUR' | 'SECOND'

export const DATE: IntervalType = 'DATE'
export const DAY_OF_WEEK: IntervalType = 'DAY_OF_WEEK'
export const HOUR: IntervalType = 'HOUR'
export const SECOND: IntervalType = 'SECOND'
