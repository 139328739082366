import { LicenseInfoVO } from './set-retail10-commons/set-hasp-commons/license-info-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class HaspDataManagerRemote extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Register/HaspDataManager!ru.crystals.setregister.HaspDataManagerRemote'
    }

    getLicenseInfo = (options?: RequestOptions): Promise<LicenseInfoVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getLicenseInfo',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    validateLicenseData = (serialNumber: string, setId: string, options?: RequestOptions): Promise<LicenseInfoVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'validateLicenseData',
                params: [serialNumber, setId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const haspDataManagerRemote: HaspDataManagerRemote = new HaspDataManagerRemote()
