import { AdvertisingAction } from './set-retail10-server/retailx/server-ds/advertising-action'
import { ActionsFilter } from './set-retail10-server/retailx/server-ds/actions-filter'
import { OrderingField } from './set-retail10-server/retailx/server-ds/ordering-field'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class ActionsSelectorFacade extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Discounts/ActionsSelectorFacadeBean!ru.crystalservice.setv6.discounts.ActionsSelectorFacade'
    }

    getActionsByFilterWithOffset = (filter: ActionsFilter, ordering: OrderingField[], offset: number, count: number, options?: RequestOptions): Promise<AdvertisingAction[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getActionsByFilterWithOffset',
                params: [filter, ordering, offset, count]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

}

export const actionsSelectorFacade: ActionsSelectorFacade = new ActionsSelectorFacade()
