import * as React from 'react'
import Grid, { GridSize } from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { DepartmentAutocomplete } from '../../../components/inputs/autocomplete-input/department-autocomplete'
import { ProductAutocomplete } from '../../../components/inputs/autocomplete-input/product-autocomplete'
import { Table, TableProps } from '@crystalservice/crystals-ui/lib/components/table/table'
import { Selector } from '@crystalservice/crystals-ui/lib/components/table/selector'
import { ALL_ITEMS } from '@crystalservice/crystals-ui/lib/components/table/paginator'
import { TEXT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { ProductGroupsAutocomplete } from '../../../components/inputs/autocomplete-input/product-groups-autocomplete'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import Button from '@material-ui/core/Button'
import { ProductsListInput } from '../../../components/inputs/list-input/products-list-input'
import { t } from 'i18next'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import { ProductGroupsListInput } from '../../../components/inputs/list-input/product-groups-list-input'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import {
    createPriceTagActionIdent,
    PriceTagActionIdent
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-action-ident'
import { SimpleDepartment } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-department'
import { ShortProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/short-product-vo-sf'
import { SimpleProductsGroup } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-products-group'
import { SimpleProduct } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { ProductTypeSelect } from '../../../components/inputs/select-input/product-type-select'
import { MeasureUnitSelect } from '../../../components/inputs/select-input/measure-unit-select'
import Typography from '@material-ui/core/Typography'
import { PaginationState, DEFAULT_PAGINATION } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

export type ProductEntityType =
    'product'
    | 'department'
    | 'productsGroup'
    | 'priceTagIdentifier'
    | 'productType'
    | 'measureUnit'
export const PRODUCT: ProductEntityType = 'product'
export const DEPARTMENT: ProductEntityType = 'department'
export const PRODUCTS_GROUP: ProductEntityType = 'productsGroup'
export const PRICE_TAG_IDENTIFIER: ProductEntityType = 'priceTagIdentifier'
export const PRODUCT_TYPE: ProductEntityType = 'productType'
export const MEASURE_UNIT: ProductEntityType = 'measureUnit'

const styles = require('./product-entities-binder.scss')

export interface BoundProductEntity {
    label: string,
    code: string,
    type: ProductEntityType,
    value: ShortProductVO_SF | SimpleProduct | SimpleDepartment | SimpleProductsGroup | PriceTagActionIdent | string
}

export interface ProductEntitiesBinderProps extends React.HTMLProps<HTMLDivElement> {
    productEntitiesTypes: ProductEntityType[]
    boundEntities?: BoundProductEntity[]
    session: string
    onEntitiesChanged: (value: BoundProductEntity[]) => void
    menuLabel?: string
    withPaper?: boolean
    TableProps?: TableProps
    selectorKeyFunction?: (items: BoundProductEntity[]) => string
}

export interface ProductEntitiesBinderState {
    selectedProductEntityType: ProductEntityType,
    priceTagIdentifier: string,
    paginationState: PaginationState,
    selectedEntities: BoundProductEntity[]
    selectedKeys: React.Key[]
}

export class ProductEntitiesBinder extends React.Component<ProductEntitiesBinderProps, ProductEntitiesBinderState> {
    static defaultProps: Partial<ProductEntitiesBinderProps> = {
        productEntitiesTypes: [],
        boundEntities: [],
        withPaper: false,
        selectorKeyFunction: entitiesToShow => entitiesToShow.reduce((newKey, value) => {
            return newKey + ',' + value.code + value.label
        }, '')
    }

    state: ProductEntitiesBinderState = {
        selectedProductEntityType: this.props.productEntitiesTypes[0],
        priceTagIdentifier: '',
        paginationState: DEFAULT_PAGINATION,
        selectedEntities: [],
        selectedKeys: [],
    }

    addEntities = (newEntities: BoundProductEntity[]): void => {
        // здесь важно, что сначала вызывается проверка на выделение позиций
        this.addEntitiesToSelected(newEntities)
        this.addEntitiesToBound(newEntities)
    }

    addEntitiesToBound = (newEntities: BoundProductEntity[]): void => {
        const updatedEntities = this.getEntitiesToBound(newEntities)

        if (this.props.boundEntities.length === updatedEntities.length) return

        this.props.onEntitiesChanged(updatedEntities)
    }

    getEntitiesToBound = (newEntities: BoundProductEntity[]): BoundProductEntity[] => {
        const entities = [...this.props.boundEntities]

        newEntities.forEach(newEntity => {
            if (!this.doesEntitiesHaveValue(newEntity)) {
                entities.push(newEntity)
            }
        })

        return entities
    }

    addEntitiesToSelected = (newEntities: BoundProductEntity[]): void => {
        const updatedSelectedEntities = this.getEntitiesToSelected(newEntities)

        if (this.state.selectedEntities.length === updatedSelectedEntities.length) return

        const keys = updatedSelectedEntities.map(entity => this.getItemKey(entity))

        this.setState({ selectedEntities: updatedSelectedEntities, selectedKeys: keys })
    }

    getEntitiesToSelected = (newEntities: BoundProductEntity[]): BoundProductEntity[] => {
        const selectedEntities = [...this.state.selectedEntities]

        newEntities.forEach(newEntity => {
            const isNew = this.doesEntitiesHaveValue(newEntity) && !this.state.selectedKeys.includes(this.getItemKey(newEntity))

            if (isNew) {
                selectedEntities.push(newEntity)
            }
        })

        return selectedEntities
    }

    doesEntitiesHaveValue = (value: BoundProductEntity): boolean => {
        const entities = this.props.boundEntities

        return entities.some(entity => {
            if (entity.type === PRICE_TAG_IDENTIFIER) {
                return entity.label === value.label
            }

            return entity.type === value.type && entity.code === value.code
        })
    }

    getItemKey = (item: BoundProductEntity) => `${item.code}${item.type}${item.label}`

    renderMenu = (): React.ReactNode => {
        const { productEntitiesTypes, disabled } = this.props
        const { paginationState } = this.state

        return (
            <div className={styles.menu}>
                <SelectInput<ProductEntityType>
                    options={productEntitiesTypes}
                    value={this.state.selectedProductEntityType}
                    labelFunction={this.getSelectInputOption}
                    onSelect={value => {
                        this.setState({
                            selectedProductEntityType: value,
                            paginationState: {
                                numberOfRows: paginationState.numberOfRows,
                                page: 1,
                            },
                            selectedEntities: [],
                            selectedKeys: []
                        })
                    }}
                    disabled={disabled}
                />
            </div>

        )
    }

    renderInput = (): React.ReactNode => {
        const { session, disabled } = this.props
        switch (this.state.selectedProductEntityType) {
            case DEPARTMENT:
                return (
                    <div
                        id="departmentInput"
                        className={styles.inputWithAutocomplete}
                    >
                        <DepartmentAutocomplete
                            session={session}
                            disabled={disabled}
                            className={styles.autocomplete}
                            onSuggestionSelect={department => {
                                const newItem: BoundProductEntity = {
                                    label: department.name,
                                    code: String(department.number),
                                    type: DEPARTMENT,
                                    value: department
                                }
                                this.addEntities([newItem])
                            }}
                        />
                    </div>
                )
            case PRODUCT:
                return (
                    <div
                        id="productInput"
                        className={styles.inputWithAutocomplete}
                    >
                        <ProductAutocomplete
                            className={styles.autocomplete}
                            placeholder={t('components.autocomplete.productPlaceholder')}
                            disabled={disabled}
                            onSuggestionSelect={product => {
                                const newItem: BoundProductEntity = {
                                    label: product.name,
                                    code: product.code,
                                    type: PRODUCT,
                                    value: product
                                }

                                this.addEntities([newItem])
                            }}
                        />

                        <ProductsListInput
                            sessionId={session}
                            resetAfterValidation
                            CustomView={props => (
                                <Button
                                    onClick={props.onClick}
                                    color="primary"
                                    className={styles.addButton}
                                    disabled={disabled}
                                >
                                    {t('priceTags.addList')}
                                </Button>
                            )}
                            onChange={async productCode => {
                                const codes = productCode.split(',')
                                const response = await productManagerFinderLocal.getSimpleProductsByCodes1(session, codes)
                                this.addEntities(
                                    response.foundProducts.map(product => {
                                        return {
                                            label: product.name,
                                            code: product.code,
                                            type: PRODUCT,
                                            value: product
                                        }
                                    })
                                )
                            }}
                        />
                    </div>
                )
            case PRODUCTS_GROUP:
                return (
                    <div
                        id="productsGroupInput"
                        className={styles.inputWithAutocomplete}
                    >
                        <ProductGroupsAutocomplete
                            sessionId={session}
                            className={styles.autocomplete}
                            disabled={disabled}
                            onSuggestionSelect={productsGroup => {
                                const newItem: BoundProductEntity = {
                                    label: productsGroup.name,
                                    code: productsGroup.code,
                                    type: PRODUCTS_GROUP,
                                    value: productsGroup
                                }

                                this.addEntities([newItem])
                            }}
                        />
                        <ProductGroupsListInput
                            sessionId={session}
                            resetAfterValidation
                            CustomView={props => (
                                <Button
                                    onClick={props.onClick}
                                    color="primary"
                                    className={styles.addButton}
                                    disabled={disabled}
                                >
                                    {t('topology.addList')}
                                </Button>
                            )}
                            onChange={async productsGroupCode => {
                                const codes = productsGroupCode.split(',')
                                if (codes.length > 0) {
                                    const allGroups = await iProductsManagerLocal.getProductGroups(session, '', { useCache: true })
                                    this.addEntities(
                                        codes.map(groupCode => {
                                            const group = (allGroups.find(group => group.code === groupCode))
                                            return {
                                                label: group.name,
                                                code: group.code,
                                                type: PRODUCTS_GROUP,
                                                value: group
                                            }
                                        })
                                    )
                                }
                            }}
                        />
                    </div>
                )
            case PRICE_TAG_IDENTIFIER:
                return (
                    <div
                        id="priceTagIdentifierInput"
                        className={styles.inputWithInput}
                    >
                        <TextField
                            id="priceTagIdentifierTextInput"
                            className={styles.input}
                            disabled={disabled}
                            label={t('priceTags.actionIdentifier')}
                            value={this.state.priceTagIdentifier}
                            onValueChange={value => {
                                this.setState({ priceTagIdentifier: value })
                            }}
                        />
                        <Button
                            id="priceTagIdentifierAddButton"
                            color="primary"
                            className={styles.addButton}
                            disabled={disabled || !this.state.priceTagIdentifier}
                            onClick={() => {
                                const newItem: BoundProductEntity = {
                                    label: this.state.priceTagIdentifier,
                                    code: '',
                                    type: PRICE_TAG_IDENTIFIER,
                                    value: createPriceTagActionIdent({ actionIdent: this.state.priceTagIdentifier })
                                }

                                this.addEntities([newItem])
                                this.setState({ priceTagIdentifier: '' })
                            }}
                        >
                            {t('common.add')}
                        </Button>
                    </div>
                )
            case PRODUCT_TYPE:
                return (
                    <div
                        id="productTypeSelect"
                        className={styles.inputWithInput}
                    >
                        <ProductTypeSelect
                            cleanAfterSelect
                            disabled={disabled}
                            className={styles.input}
                            onSelect={selectedOption => {
                                const newItem: BoundProductEntity = {
                                    label: selectedOption.label,
                                    code: selectedOption.value,
                                    type: PRODUCT_TYPE,
                                    value: selectedOption.value
                                }

                                this.addEntities([newItem])
                            }}
                        />
                    </div>
                )
            case MEASURE_UNIT:
                return (
                    <div
                        id="measureUnitSelect"
                        className={styles.inputWithInput}
                    >
                        <MeasureUnitSelect
                            session={session}
                            cleanAfterSelect
                            disabled={disabled}
                            className={styles.input}
                            onSelect={selectedOption => {
                                const newItem: BoundProductEntity = {
                                    label: selectedOption.label,
                                    code: selectedOption.value,
                                    type: MEASURE_UNIT,
                                    value: selectedOption.value
                                }

                                this.addEntities([newItem])
                            }}
                        />
                    </div>
                )
            default:
                return null
        }
    }

    renderTable = (): React.ReactNode => {
        const { boundEntities, onEntitiesChanged, disabled, TableProps, selectorKeyFunction } = this.props
        const { selectedKeys, selectedEntities, paginationState } = this.state
        const entitiesToShow = boundEntities.filter(item => item.type === this.state.selectedProductEntityType)
        const showTable = entitiesToShow.length > 0

        return (
            showTable ? (
                <Selector
                    defaultItems={entitiesToShow}
                    defaultSelection={selectedKeys}
                    defaultPagination={paginationState}
                    onPageChange={paginationState => this.setState({ paginationState })}
                    idFunction={this.getItemKey}
                    mode={ALL_ITEMS}
                    disabled={disabled}
                    key={selectorKeyFunction(entitiesToShow) + selectedKeys}
                    onSelectionChange={keys => {
                        this.setState({
                            selectedEntities: boundEntities.filter(entity => keys.includes(this.getItemKey(entity))),
                            selectedKeys: keys
                        })
                    }}
                    AdditionalControls={props => {
                        const { count } = props

                        return (
                            <Button
                                id="deleteSelectedButton"
                                disabled={disabled || count === 0}
                                className={styles.deleteButton}
                                onClick={() => {
                                    const newBoundEntities = boundEntities.filter(
                                        boundEntity => {
                                            const key = this.getItemKey(boundEntity)
                                            return !selectedEntities.some(selectedEntity => {
                                                return key === this.getItemKey(selectedEntity)
                                            })
                                        }
                                    )
                                    this.setState({
                                        selectedEntities: [],
                                        selectedKeys: []
                                    })
                                    onEntitiesChanged(newBoundEntities)
                                }}
                            >
                                {count !== 0
                                    ? `${t('components.productEntitiesBinder.removeSelected')} (${count})`
                                    : t('components.productEntitiesBinder.removeSelected')
                                }
                            </Button>
                        )
                    }}
                >
                    <Table
                        withHeader
                        adaptive={false}
                        disabled={disabled}
                        columns={[
                            {
                                header: t('priceTags.objectCode'),
                                labelField: 'code',
                                dataType: TEXT
                            },
                            {
                                header: t('priceTags.name'),
                                labelField: 'label',
                                dataType: TEXT
                            },
                        ]}
                        {...TableProps}
                    />
                </Selector>
            ) : (
                <Empty/>
            )
        )
    }

    renderInfo = (): React.ReactNode => {
        const labels: string[] = this.props.productEntitiesTypes.map(entityType => {
            let label = this.getSelectInputOption(entityType)
            // Отображаем только строки с количеством, например Товар (1)
            if (label.indexOf('(') !== -1) {
                return label
            } else {
                return null
            }
        }).filter(label => label !== null)

        const text = labels.join(', ')
        if (text) {
            return (
                <Typography className={styles.text}>
                    {t('common.added') + ': ' + text}
                </Typography>
            )
        } else {
            return null
        }
    }

    getSelectInputOption = (productEntityType: ProductEntityType): string => {
        let label: string
        const count = this.props.boundEntities.filter(entity => entity.type === productEntityType).length

        switch (productEntityType) {
            case PRODUCT:
                label = t('priceTags.product')
                break
            case DEPARTMENT:
                label = t('priceTags.department')
                break
            case PRODUCTS_GROUP:
                label = t('priceTags.productsGroup')
                break
            case PRICE_TAG_IDENTIFIER:
                label = t('priceTags.priceIdentifier')
                break
            case PRODUCT_TYPE:
                label = t('set10.productTypes')
                break
            case MEASURE_UNIT:
                label = t('set10.measureUnits')
                break
        }

        if (count > 0) {
            label += ` (${count})`
        }

        return label
    }

    render() {
        const { productEntitiesTypes, menuLabel, withPaper } = this.props
        const numOfTypes = productEntitiesTypes.length
        const Container = withPaper ? Paper : 'div'

        const showMenu = numOfTypes > 1

        const menuGridSize = showMenu ? 4 : 0
        const renderGridSize = 12 - menuGridSize

        return (
            <Container className={styles.container}>
                <Grid container>
                    <Grid
                        item xs={12}
                        container spacing={2}
                        className={styles.inputs}
                    >
                        {showMenu && (
                            <Grid
                                item
                                xs={12}
                                sm={menuGridSize as GridSize}
                                container
                                className={styles.menuBlock}
                                alignItems="center"
                            >
                                {menuLabel && (
                                    <Grid
                                        item
                                        xs="auto"
                                        className={styles.menuLabel}
                                    >
                                        {menuLabel}
                                    </Grid>
                                )}
                                {this.renderMenu()}
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            sm={renderGridSize as GridSize}
                            className={styles.inputBlock}
                        >
                            {this.renderInput()}
                        </Grid>
                    </Grid>
                    {numOfTypes > 1 && (
                        <Grid item container xs={12} className={styles.addedEntitiesInfo}>
                            {this.renderInfo()}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {this.renderTable()}
                    </Grid>
                </Grid>
            </Container>

        )
    }

}
