import * as React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarStore } from '../../../store/snackbar-store'
import {observer, inject} from 'mobx-react'
import { SNACKBAR_STORE } from '../../../store/stores'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import { SnackbarContent } from '@material-ui/core'

const styles = require('./snackbar-manager.scss')

export interface SnackbarManagerProps {
    snackbar?: SnackbarStore
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

@inject(SNACKBAR_STORE)
@observer
export class SnackbarManager extends React.Component<SnackbarManagerProps> {

    render() {
        const {snack, close} = this.props.snackbar
        const {open, origin, message, duration, actions, showCloseIcon, variant, beforeIcon } = snack

        const icon = beforeIcon
            ? React.cloneElement(beforeIcon, { className: styles.iconBefore })
            : variant
            ? React.createElement(variantIcon[variant], { className: styles.iconBefore })
            : null

        return (
            <Snackbar
                id="snackbar"
                anchorOrigin={origin}
                open={open}
                autoHideDuration={duration}
                onClose={close}
                className={variant && styles.variantSnackBar}
            >
                <SnackbarContent
                    id="snackbar"
                    className={styles[variant]}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={styles.message}>
                            {icon}
                            {message}
                        </span>
                    }
                    action={[
                        ...actions,
                        showCloseIcon && (
                            <IconButton key="close" aria-label="close" color="inherit" size="small" onClick={close}>
                                <CloseIcon className={styles.closeIcon} />
                            </IconButton>
                        )
                    ]}
                />
            </Snackbar>
        )
    }
}
