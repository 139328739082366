import { DiscountsProcessingCardsPropertiesVO } from './set-retail10-server/discounts/set-processing-discount/discounts-processing-cards-properties-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class DiscountsProcessingCardsPropertiesManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-ProcessingDiscount/SET/DiscountsProcessingCardsPropertiesManager!ru.crystals.discount.processing.DiscountsProcessingCardsPropertiesManagerLocal'
    }

    getCardsPropertiesVO = (options?: RequestOptions): Promise<DiscountsProcessingCardsPropertiesVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCardsPropertiesVO',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    setCardsPropertiesVO = (cardsPropertiesVO: DiscountsProcessingCardsPropertiesVO, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'setCardsPropertiesVO',
                params: [cardsPropertiesVO]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const discountsProcessingCardsPropertiesManagerLocal: DiscountsProcessingCardsPropertiesManagerLocal = new DiscountsProcessingCardsPropertiesManagerLocal()
