export interface OrderingField {
    '@class': 'ru.crystals.discounts.OrderingField'
    field?: string
    ascending?: boolean
}

export const createOrderingField = (initialization: Partial<OrderingField>): OrderingField => {
    if (!initialization) return null
    
    let result: OrderingField = {
        field: initialization.field,
        ascending: initialization.ascending,
        '@class': 'ru.crystals.discounts.OrderingField',
    }
    
    return result
}
