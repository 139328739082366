import React from 'react'
import { t } from 'i18next'
import { isNil } from 'lodash'
import {
    removeCondition, updateCondition, findCondition, BillConditions, ConditionFields, Condition
} from '../../../../../../core/advertising-actions/action-conditions'
import { ConditionEditorProps } from '../types'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'

const styles = require('./product-count-in-bill-editor.scss')

export interface ProductCount {
    units: Array<{
        [ConditionFields.ProductCountConditionUnit]: ProductCountConditionUnit[]
    }>
}

export interface ProductCountConditionUnit {
    comparison: ProductCountType[],
    value: string[]
}

export enum ProductCountType {
    MULTIPLY = 'MULTIPLY',
    MORE = 'MORE',
    LESS = 'LESS'
}

// Вспомогательная функция - получаем непосредственные значения
export const getProductCountConditionUnits = (condition: ProductCount): ProductCountConditionUnit[] => {
    if (!condition || !condition.units) return []
    return condition.units[0][ConditionFields.ProductCountConditionUnit]
}

// Получение текста для чипа
export const getProductCountChipLabel = (condition: ProductCount): string => {
    let productCountText = ''

    const units = getProductCountConditionUnits(condition)
    units.forEach(unit => {
        if (productCountText) {
            productCountText += ', '
        }

        switch (unit.comparison[0]) {
            case ProductCountType.MULTIPLY:
                productCountText += t('advertisingActions.checkConditions.mulptiple', { mulptiple: unit.value[0] })
                break
            case ProductCountType.MORE:
                productCountText += t('advertisingActions.checkConditions.MORE', { summa: unit.value[0] })
                break
            case ProductCountType.LESS:
                productCountText += t('advertisingActions.checkConditions.LESS', { summa: unit.value[0] })
                break
        }
    })

    return `${t('advertisingActions.checkConditions.productCount')} ${productCountText}`
}

interface ProductCountEditorState {
    /**
     * Значение в state используется:
     * В момент когда мы ввели неккоректное значение - надо видеть то, что мы ввели. Но в фильтр передавать это нельзя.
     */
    stateMultiple: number
    stateMore: number
    stateLess: number
    /**
     * Для обновления numberInput.
     * В текущем numberInput бага:
     * если после положительного значения (5 например) в пропсы передать null извне
     * То в инпуте будет все еще отображено 5 (из стейта), хотя пропсы уже имеют null
     */
    numberInputKey: number
}

export class ProductCountInBillEditor extends React.Component<ConditionEditorProps, ProductCountEditorState> {

    constructor(props: ConditionEditorProps, context: any) {
        super(props, context)

        const condition = findCondition(BillConditions.ProductCountCondition, this.props.value)

        // Условие не задано
        if (!condition) {
            this.state = {
                stateMultiple: null,
                stateMore: null,
                stateLess: null,
                numberInputKey: 0
            }
            return
        }

        const { multiple, more, less } = this.getConditionValues(condition)

        this.state = {
            stateMultiple: multiple,
            stateMore: more,
            stateLess: less,
            numberInputKey: 0
        }
    }

    getConditionValues = (condition: Condition): { multiple: number, more: number, less: number } => {
        const productCount: ProductCount = condition && condition.data
        const units = getProductCountConditionUnits(productCount)

        let multiple: number = null
        let more: number = null
        let less: number = null

        units.forEach(unit => {
            switch (unit.comparison[0]) {
                case ProductCountType.MULTIPLY:
                    multiple = Number(unit.value[0])
                    break
                case ProductCountType.MORE:
                    more = Number(unit.value[0])
                    break
                case ProductCountType.LESS:
                    less = Number(unit.value[0])
                    break
            }
        })
        return { multiple, more, less }
    }

    static getDerivedStateFromProps(props: ConditionEditorProps, state: ProductCountEditorState) {
        const condition = findCondition(BillConditions.ProductCountCondition, props.value)

        // Если условие было удалено извне (чипом) то обнуляем state
        if (!condition) {
            return {
                stateMultiple: null,
                stateMore: null,
                stateLess: null,
                numberInputKey: state.numberInputKey + 1,
            }
        }
        return null
    }

    handleMultipleChange = (newValue: number): void => {
        const { stateMore, stateLess } = this.state
        this.handleUpdateCondition(newValue, stateMore, stateLess)
        this.setState({
            stateMultiple: newValue
        })
    }

    handleMoreChange = (newValue: number): void => {
        const { stateMultiple, stateLess } = this.state
        this.handleUpdateCondition(stateMultiple, newValue, stateLess)
        this.setState({
            stateMore: newValue
        })
    }

    handleLessChange = (newValue: number): void => {
        const { stateMultiple, stateMore } = this.state
        this.handleUpdateCondition(stateMultiple, stateMore, newValue)
        this.setState({
            stateLess: newValue
        })
    }

    handleUpdateCondition = (multiple: number, more: number, less: number): void => {
        const { value, onChange } = this.props

        // Если все поля пустые - удаляем условие
        if (isNil(more) && isNil(less) && isNil(multiple)) {
            this.handleRemoveCondition()
            return
        }

        const condition = findCondition(BillConditions.ProductCountCondition, this.props.value)
        const productCount: ProductCount = condition && condition.data
        const units = getProductCountConditionUnits(productCount)

        /**
         * Если нарушается логика "больше меньше", то разрешаем изменение только multiple
         * Изменение будет только в state
         */
        const moreLessError = !(isNil(more) || isNil(less)) && more > less

        // Если ошибка - оставляем старые more less
        let prevMore: number = null
        let prevLess: number = null

        units.forEach(unit => {
            switch (unit.comparison[0]) {
                case ProductCountType.MORE:
                    prevMore = Number(unit.value[0])
                    break
                case ProductCountType.LESS:
                    prevLess = Number(unit.value[0])
                    break
            }
        })

        // Заполняем новый массив с данными
        let unitsArray: ProductCountConditionUnit[] = []

        // Вставляем только не пустые значения
        if (!isNil(multiple)) {
            unitsArray.push({
                comparison: [ProductCountType.MULTIPLY],
                value: [String(multiple)]
            })
        }

        if (moreLessError) {
            // Ошибка - вставляем старые значения
            if (!isNil(prevMore)) {
                unitsArray.push({
                    comparison: [ProductCountType.MORE],
                    value: [String(prevMore)]
                })
            }
            if (!isNil(prevLess)) {
                unitsArray.push({
                    comparison: [ProductCountType.LESS],
                    value: [String(prevLess)]
                })
            }
        } else {
            // Ошибки нет - подставляем имеющиеся значения
            if (!isNil(more)) {
                unitsArray.push({
                    comparison: [ProductCountType.MORE],
                    value: [String(more)]
                })
            }
            if (!isNil(less)) {
                unitsArray.push({
                    comparison: [ProductCountType.LESS],
                    value: [String(less)]
                })
            }
        }

        const newCondition: ProductCount = {
            units: [
                {
                    [ConditionFields.ProductCountConditionUnit]: unitsArray
                }
            ]
        }
        onChange(updateCondition(
            BillConditions.ProductCountCondition,
            newCondition,
            value
        ))
    }

    handleRemoveCondition = (): void => {
        const { value, onChange } = this.props
        onChange(removeCondition(BillConditions.ProductCountCondition, value))
    }

    render() {
        const { stateMultiple, stateMore, stateLess, numberInputKey } = this.state

        const condition = findCondition(BillConditions.ProductCountCondition, this.props.value)
        const { multiple, more, less } = this.getConditionValues(condition)

        let errorWithoutHelperText
        const error = !isNil(stateMore) && !isNil(stateLess) && stateMore > stateLess
        errorWithoutHelperText = { error }

        const readOnly = this.props.readOnly

        return (
            <div className={styles.productCount}>
                <NumberInput
                    key={`a${numberInputKey}`}
                    id="multipleInput"
                    className={styles.input}
                    canBeNull
                    disabled={readOnly}
                    label={t('advertisingActions.checkConditions.multipleLabel')}
                    value={!isNil(stateMultiple) ? stateMultiple : multiple}
                    onValueChange={this.handleMultipleChange}
                />
                <NumberInput
                    key={`b${numberInputKey}`}
                    id="moreInput"
                    className={styles.input}
                    canBeNull
                    disabled={readOnly}
                    label={t('advertisingActions.checkConditions.moreLabel')}
                    value={!isNil(stateMore) ? stateMore : more}
                    onValueChange={this.handleMoreChange}
                    {...errorWithoutHelperText}
                />
                <NumberInput
                    key={`c${numberInputKey}`}
                    id="lessInput"
                    className={styles.input}
                    canBeNull
                    disabled={readOnly}
                    label={t('advertisingActions.checkConditions.lessLabel')}
                    value={!isNil(stateLess) ? stateLess : less}
                    onValueChange={this.handleLessChange}
                    errorText={error ? t('advertisingActions.checkConditions.moreLessError') : null}
                />
            </div>
        )
    }
}
