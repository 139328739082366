export class CompositeApplyObject {
    pluginName: string
    valueType: ValueType
    value: number
    maxCount: number
    applyCount: number
    applyType: string
    wholesaleRestriction: number
    forAllGoods: boolean
    item: string[]
    manufacturerId: string[]
    groupId: string[]
    departId: number[]
    countryId: string[]
    typeProductId: number[]
    saleGroupId: number[]

    constructor(initialization: Partial<CompositeApplyObject>) {
        if (!initialization) {
            return
        }

        this.pluginName = initialization.pluginName
        this.valueType = initialization.valueType
        this.value = initialization.value
        this.maxCount = initialization.maxCount
        this.applyCount = initialization.applyCount
        this.applyType = initialization.applyType
        this.wholesaleRestriction = initialization.wholesaleRestriction
        this.item = initialization.item
        this.manufacturerId = initialization.manufacturerId
        this.groupId = initialization.groupId
        this.departId = initialization.departId
        this.countryId = initialization.countryId
        this.typeProductId = initialization.typeProductId
        this.saleGroupId = initialization.saleGroupId
        this.forAllGoods = initialization.forAllGoods
    }
}

export type ValueType = 'PERCENT' | 'FIXSUMM' | 'FIX_PRICE'
export const PERCENT: ValueType = 'PERCENT'
export const FIXSUMM: ValueType = 'FIXSUMM'
export const FIX_PRICE: ValueType = 'FIX_PRICE'
