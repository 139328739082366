import { observable, action, runInAction, autorun } from 'mobx'
import { sortBy, get } from 'lodash'
import { PRICE_TAGS_PRINTING_STORE } from '../../stores'
import { getStore } from '../../stores-repository'
import { PrinterVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/printer-vo'
import { PriceTagTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { PriceTagsPrintingStore, PairOfPriceTagAndPrinter, SelectedPairOfPriceTagAndPrinter } from './printing-store'
import {
    PriceTagsFiltersState,
    ANY_SIZE_TEMPLATE_ID
} from '../../../pages/price-tags/printing/price-tags-filters'

export class PrintByBindedPriceTagsSelectionStore {

    @observable
    filters: PriceTagsFiltersState = null

    @observable
    filteredBindedPriceTagToPrinterPairs: PairOfPriceTagAndPrinter[] = []

    @observable
    tableKey: string = ''

    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    constructor() {
        autorun(() => {
            const { allPriceTags, priceTagPrinters, shelfParams, changePrintingParamsForBindedPriceTags,
                    selectedBindedPairs, actionable } = this.printingStore
            if (!shelfParams || !allPriceTags || !priceTagPrinters) {
                return
            }
            const filteredPairs = getFilteredPairs(getBindedPriceTagToPrinterPairs(
                    priceTagPrinters,
                    allPriceTags,
                    actionable
                ),
                this.filters
            )

            let nextTableKey: string = filteredPairs.map(pair => pair.getId()).join(';')

            runInAction(() => {
                if (nextTableKey !== this.tableKey) {
                    this.tableKey = nextTableKey
                    selectedBindedPairs.forEach((selectedPair: SelectedPairOfPriceTagAndPrinter) => {
                        if (!filteredPairs.some(pair => pair.getId() === selectedPair.pair.getId())) {
                            changePrintingParamsForBindedPriceTags(selectedPair.pair, 0)
                        }
                    })
                }
                this.filteredBindedPriceTagToPrinterPairs = filteredPairs
            })
        })
    }

    @action
    setFilters = (newFilters: PriceTagsFiltersState): void => {
        this.filters = newFilters
    }

    @action
    reset = (): void => {
        this.filters = null
        this.filteredBindedPriceTagToPrinterPairs = []
        this.tableKey = ''
    }
}

function getBindedPriceTagToPrinterPairs(priceTagPrinters: PrinterVO[], allPriceTags: PriceTagTemplateVO[],
                                         actionable: boolean): PairOfPriceTagAndPrinter[] {
    if (!priceTagPrinters || !allPriceTags) {
        return []
    }

    let filteredPairs: PairOfPriceTagAndPrinter[] = []
    priceTagPrinters.forEach(printer => {
        if (!printer.priceTags) {
            return
        }
        printer.priceTags.forEach(printerPriceTag => {
            let correctPriceTag: PriceTagTemplateVO = allPriceTags.find(priceTag => priceTag.id === printerPriceTag.id)

            if (correctPriceTag && correctPriceTag.actionable === actionable) {
                filteredPairs.push({
                    priceTag: correctPriceTag,
                    printer,
                    getId(): string {
                        return this.printer.id + this.priceTag.id
                    }
                })
            }
        })
    })

    return sortBy(filteredPairs, 'priceTag.name')
}

function getFilteredPairs(pairs: PairOfPriceTagAndPrinter[], filters: PriceTagsFiltersState): PairOfPriceTagAndPrinter[] {
    if (!filters) {
        return pairs
    }

    let filteredPairs: PairOfPriceTagAndPrinter[] = pairs.concat()
    const { priceTagName, printerName, selectedPriceTagSize } = filters

    if (priceTagName) {
        filteredPairs = filteredPairs.filter(pair => {
            return pair.priceTag.name.toLowerCase().includes(priceTagName.toLowerCase().trim())
        })
    }

    if (printerName) {
        filteredPairs = filteredPairs.filter(pair => {
            return pair.printer.name.toLowerCase().includes(printerName.toLowerCase().trim())
        })
    }

    if (selectedPriceTagSize.value !== ANY_SIZE_TEMPLATE_ID) {
        if (selectedPriceTagSize.priceTag) {
            filteredPairs = filteredPairs.filter(pair => {
                return pair.priceTag.width === selectedPriceTagSize.priceTag.width
                    && pair.priceTag.height === selectedPriceTagSize.priceTag.height
            })
        } else if (selectedPriceTagSize.priceTagSizeLabel) {
            filteredPairs = filteredPairs.filter(pair => {
                return get(pair.priceTag, 'sizeLabel.id') === selectedPriceTagSize.priceTagSizeLabel.id
            })
        }
    }

    return filteredPairs
}
