import * as React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import Paper from '@material-ui/core/Paper'
import { APP_STORE, PRODUCT_DETAILS_STORE, PRODUCT_SALES_POLICY_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { RouteComponentProps, withRouter } from 'react-router'
import ProductsSearch from '../products-search/products-search'
import {
    ALCOHOL_KIT_STRUCTURE,
    ALCOHOL_LIMITS,
    BARCODES,
    KIT_STRUCTURE,
    LIMITS,
    PRICE_TAG_TEMPLATES, PRODUCT_INFO, PRODUCT_SALES_GROUPS,
    PROMOTIONS,
    SALES_POLICY,
    WHOLESALE_LEVELS
} from '../../../core/app-routes'
import SalesPolicy from '../sales-policy/sales-policy'
import SalesGroups from '../sales-groups/sales-groups'
import Promotions from '../promotions/promotions'
import AlcoAndBarCodes from '../barcodes/barcodes'
import classNames from 'classnames'
import { AppStore } from '../../../store/app-store'
import ProductRestrictions from '../product-restrictions/product-restrictions'
import { PageLoader } from '../../../../components/loaders/page-loader'
import KitStructure from '../kit-structure/kit-structure'
import AlcoholKitStructure from '../alcohol-kit-structure/alcohol-kit-structure'
import PriceTagTemplates from '../price-tag-templates/price-tag-templates'
import { KIT, SPIRIT } from '../../../core/products/product-types'
import AlcoholLimits from '../alcohol-limits/alcohol-limits'
import { ProductVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import { SpiritsBottleVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/spirits-bottle-vo'
import WholesaleLevels from '../wholesale-levels/wholesale-levels'
import { SimpleWholesaleLevel } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/simple-wholesale-level'
import { ProductInfo } from '../product-info/product-info'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ChevronLeft } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { ProductSalesPolicyStore } from '../../../store/products/product-sales-policy-store'

const styles = require('./product-details.scss')

export interface ProductDetailsURLParams {
    code?: string
    detailType?: string
}

export interface ProductDetailsProps extends RouteComponentProps<ProductDetailsURLParams> {
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
    productSalesPolicyStore?: ProductSalesPolicyStore
}

export interface ProductDetail {
    title: string
    component: React.ReactElement
    urlParam: string
    hidden?: boolean
}

@inject(APP_STORE)
@inject(PRODUCT_DETAILS_STORE)
@inject(PRODUCT_SALES_POLICY_STORE)
@observer
export class ProductDetails extends React.Component<ProductDetailsProps> {

    constructor(props: ProductDetailsProps, context: any) {
        super(props, context)
        const { code, detailType } = props.match.params

        if (!code) return

        const { setProduct, productCode, setDetailType } = props.productDetailsStore

        setDetailType(`/${detailType}`)

        if (!productCode) {
            setProduct(code)
        }
    }

    tabChangeHandler = (detailType: string): void => {
        const { setDetailType, goToTarget } = this.props.productDetailsStore

        if (!detailType) return

        setDetailType(detailType)
        goToTarget()
    }

    get filteredTabList(): ProductDetail[] {
        const { alcoholKit, productDetails, wholesaleLevels } = this.props.productDetailsStore
        const { isCentrum } = this.props.app

        if (!productDetails) return []

        return getProductDetailsTabList(productDetails, alcoholKit, wholesaleLevels, isCentrum).filter(i => !i.hidden)
    }

    render() {
        const { code } = this.props.match.params
        const { productCode, detailType, productName, productDetails, setDetailType } = this.props.productDetailsStore
        const { selectedShop, setSelectedShop } = this.props.productSalesPolicyStore

        const usedTab = this.filteredTabList.find(i => i.urlParam === detailType)
        return (
            <>
                <ProductsSearch />
                {productCode && productDetails && (
                    <>
                        {productDetails?.code ? (
                            <Paper className={styles.productContainer}>
                                <List
                                    className={classNames(styles.list, {
                                        [styles.showTabs]: !usedTab
                                    })}
                                >
                                    {this.filteredTabList.map((item, index) => {
                                        const urlParam = item.urlParam
                                        return (
                                            <ListItem
                                                id={`listItem_${index}`}
                                                key={urlParam}
                                                button
                                                selected={detailType === urlParam}
                                                onClick={() => this.tabChangeHandler(urlParam)}
                                                dense
                                            >
                                                <ListItemText primary={item.title} className={styles.listItem}/>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                {usedTab && (
                                    <div
                                        className={styles.content}
                                    >
                                        <div className={styles.goBackContainer}>
                                            <IconButton
                                                id="fromDetailsToTabsList"
                                                onClick={() => {
                                                    if (!selectedShop) {
                                                        setDetailType(null)
                                                    }
                                                    setSelectedShop(null)
                                                }}
                                                className={styles.goBackMobileButton}
                                            >
                                                <ChevronLeft/>
                                            </IconButton>
                                            {!selectedShop &&
                                                <span className={styles.detailsTitle}>
                                                    {usedTab.title}
                                                </span>
                                            }
                                        </div>
                                        {!selectedShop && <div className={styles.detailsSpacer}></div>}
                                        {usedTab.component}
                                    </div>
                                )}
                            </Paper>
                        ) : (
                            <Paper className={styles.emptyContainer}>
                                <Empty id="emptyProductDetails" message={t('productDetails.emptyProductMessage')} />
                            </Paper>
                        )}
                    </>
                )}
                {code && !productCode && !productDetails && (
                    <PageLoader/>
                )}
            </>
        )
    }
}

export default withRouter(ProductDetails)

export const getProductDetailsTabList = (
    product: ProductVO_SF,
    alcoholKit: SpiritsBottleVO[],
    wholesaleLevels: SimpleWholesaleLevel[],
    isCentrum: boolean,
): ProductDetail[] => [
    {
        title: t('productDetails.salesPolicy'),
        component: <SalesPolicy />,
        urlParam: SALES_POLICY,
    }, {
        title: t('productDetails.promotions'),
        component: <Promotions />,
        urlParam: PROMOTIONS,
    }, {
        title: t('productDetails.limits'),
        component: <ProductRestrictions />,
        urlParam: LIMITS,
    }, {
        title: t('productDetails.productInfo'),
        component: <ProductInfo />,
        urlParam: PRODUCT_INFO,
    }, {
        title: t('productDetails.productSalesGroups'),
        component: <SalesGroups />,
        urlParam: PRODUCT_SALES_GROUPS,
    }, {
        title: t('productDetails.priceTagTemplates'),
        component: <PriceTagTemplates />,
        urlParam: PRICE_TAG_TEMPLATES,
        hidden: isCentrum,
    }, {
        title: t('productDetails.alcoAndBarCodes'),
        component: <AlcoAndBarCodes />,
        urlParam: BARCODES,
    }, {
        title: t('productDetails.kitStructure'),
        component: <KitStructure />,
        urlParam: KIT_STRUCTURE,
        hidden: product && product.productType !== KIT
    }, {
        title: t('productDetails.alcoholKitStructure'),
        component: <AlcoholKitStructure />,
        urlParam: ALCOHOL_KIT_STRUCTURE,
        hidden: !alcoholKit || !alcoholKit.length,
    }, {
        title: t('productDetails.alcoholLimits'),
        component: <AlcoholLimits />,
        urlParam: ALCOHOL_LIMITS,
        hidden: product && product.productType !== SPIRIT,
    }, {
        title: t('productDetails.wholesaleLevels'),
        component: <WholesaleLevels />,
        urlParam: WHOLESALE_LEVELS,
        hidden: !wholesaleLevels || !wholesaleLevels.length,
    },
]
