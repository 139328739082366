import { t } from 'i18next'
import React from 'react'
import { PRODUCT_ITEM_OR_BARCODE_FILTER, FilterType, getFilterTypeName } from '../../../core/filters/filter'
import { getProductsListInputPresetProps } from '../../../../components/inputs/list-input/products-list-input'
import { ProductStatus } from '../../../core/products/product-statuses'
import { InternalFilterComponentProps } from '../new-filters'
import { ListInput } from '@crystalservice/crystals-ui/lib/components/inputs/list-input/list-input'
import { SimpleProduct } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { PIECE } from '../../../core/products/product-types'

interface ProductFilterProps extends InternalFilterComponentProps {
    session: string
    productStatus: ProductStatus
    productsLimit?: number
}

export class ProductCodeOrBarcode extends React.PureComponent<ProductFilterProps> {
    static filterType: FilterType = PRODUCT_ITEM_OR_BARCODE_FILTER

    static defaultProps: Partial<ProductFilterProps> = {
        productsLimit: 20,
    }

    entryRegExp: RegExp = /^.*$/
    getEntryTypeHandler = (count: number) => t('components.listInput.entryType.codeOrBarcode', {count})

    render() {
        const { id, session, productStatus, productsLimit, value, onChange, slim } = this.props
        return (
            <ListInput<SimpleProduct>
                id={id}
                defaultValue={value}
                onChange={value => onChange(ProductCodeOrBarcode.filterType, { value })}
                label={getFilterTypeName(ProductCodeOrBarcode.filterType)}
                entryRegExp={this.entryRegExp}
                getEntryTypeText={this.getEntryTypeHandler}
                {...getProductsListInputPresetProps(session, productStatus, productsLimit, PIECE)}
                slim={slim}
            />
        )
    }
}
