import { GetCheckDiscountsOut } from './set-retail10-server/retailx/server-ds/get-check-discounts-out'
import { GetCheckDiscountsIn } from './set-retail10-server/retailx/server-ds/get-check-discounts-in'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class DiscountsProcessingManager extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-ProcessingDiscount/SET/DiscountsProcessing!ru.crystals.discount.processing.DiscountsProcessingManager'
    }

    getCheckDiscounts = (sessionId: string, in1: GetCheckDiscountsIn, options?: RequestOptions): Promise<GetCheckDiscountsOut> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCheckDiscounts',
                params: [sessionId, in1]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const discountsProcessingManager: DiscountsProcessingManager = new DiscountsProcessingManager()
