import { SaleGroupVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/sale-group-vo-sf'
import { ProductGroupVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-group-vo-sf'
import { ManufacturerVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/manufacturer-vo'
import { ProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import { CountryVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/country-vo'

export const getDoubleCounterText = (counter1: number, counter2: number) => {
    if (counter1 === 0 && counter2 === 0) return ''
    return `${counter1} / ${counter2}`
}

export function checkSaleGroup(saleGroupId: string, saleGroups: SaleGroupVO_SF[]): boolean {
    return saleGroups && saleGroups.some(saleGroup => saleGroup.code === saleGroupId)
}

export function checkGroup(groupId: string, group: ProductGroupVO_SF): boolean {
    while (group) {
        if (group.code === groupId) {
            return true
        }
        group = group.parentGroup
    }

    return false
}

export function checkManufacturer(manufacturerId: string, manufacturer: ManufacturerVO): boolean {
    return manufacturer && manufacturer.code === manufacturerId
}

export function checkDepartment(departId: number, product: ProductVO_SF): boolean {
    return product && product.prices && product.prices.some(price => {
        const department = price.department
        return department && department.number === departId
    })
}

export function checkCountry(countryId: string, country: CountryVO): boolean {
    return country && country.code === countryId
}
