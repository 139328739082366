import { t } from 'i18next'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { iUserManagementLocal } from '../../../protocol/set10/i-user-management-local'
import { TopologyConditionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition-vo'
import { UserRoleVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/user-role-vo'
import { createUserVO, UserVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import { NOT_FOUND, STAFF, USERS, USER } from '../../core/app-routes'
import { NEW } from '../../core/values'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { AppStore } from '../app-store'
import { DialogStore } from '../dialog-store'
import { APP_BAR_STORE, APP_STORE, DIALOG_STORE, USER_SETTINGS_STORE, USER_STORE, USERS_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { UsersStore } from './users-store'
import { textValidator } from '../../../utils/form-validation/validators/text-validator'
import { regExpValidator } from '../../../utils/form-validation/validators/regexp-validator'
import { encodeNonLatinSymbols } from '../../../utils/name-util'

export const ACTIVE_USERS: string = 'activeUsers'
export const BLOCKED_USERS: string = 'blockedUsers'
export const MAX_LOGIN_LENGTH: number = 255
export const MAX_EMAIL_LENGTH: number = 255
export const MIN_PASSWORD_LENGTH: number = 8
export const MAX_NAME_LENGTH: number = 30
export const MAX_POSITION_LENGTH: number = 52

export interface RoleWithChecked extends UserRoleVO {
    checked: boolean
}

export class UserSettingsStore {
    @observable
    user: UserVO

    @observable
    userTopology: TopologyConditionVO[]

    userPasswordHash: string

    @observable
    newUser: boolean = false

    @observable
    userChanged: boolean = false

    @observable
    topologyChanged: boolean = false

    @observable
    activeUsers: UserVO[] = []

    @observable
    blockedUsers: UserVO[] = []

    @observable
    roles: UserRoleVO[] = []

    @observable
    validation: FormValidation<UserVO> = null

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private usersStore: UsersStore = getStore(USERS_STORE)
    private dialogStore: DialogStore = getStore(DIALOG_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    @computed
    get rolesWithCheckStatus(): RoleWithChecked[] {
        if (!this.user) return []
        const addedRoles = this.user.userRoles || []
        return this.roles.map((role: UserRoleVO) => {
            const roleId = role.id
            const value = addedRoles.findIndex(userRole => userRole.id === roleId) !== -1
            return {...role, checked: value}
        })
    }

    @computed
    get allRolesChecked(): boolean {
        if (!this.user) return false
        return this.user.userRoles.length === this.roles.length
    }

    @action
    createValidation = (creation: boolean = false): void => {
        let allUsers = this.activeUsers.concat(this.blockedUsers)

        this.validation = new FormValidation<UserVO>(
            this.user,
            [
                {
                    field: 'login',
                    rules: [
                        requiredField,
                        uniqueField(allUsers
                            .filter(u => u.id !== this.user.id)
                            .map(u => u.login)),
                        fieldLengthValidator({ max: MAX_LOGIN_LENGTH })
                    ]
                },
                {
                    field: 'password',
                    rules: [
                        requiredField,
                        fieldLengthValidator({ min: MIN_PASSWORD_LENGTH })
                    ]
                },
                {
                    field: 'email',
                    rules: [
                        // Регулярное выражение: текст / знак собаки / текст / точка / англ.буквы
                        regExpValidator(/^.+@.+\.[A-Za-z]+$/),
                        fieldLengthValidator({ max: MAX_EMAIL_LENGTH })
                    ]
                },
                {
                    field: 'firstName',
                    rules: [
                        textValidator,
                        fieldLengthValidator({ max: MAX_NAME_LENGTH })
                    ]
                },
                {
                    field: 'middleName',
                    rules: [
                        textValidator,
                        fieldLengthValidator({ max: MAX_NAME_LENGTH })
                    ]
                },
                {
                    field: 'lastName',
                    rules: [
                        textValidator,
                        fieldLengthValidator({ max: MAX_NAME_LENGTH })
                    ]
                },
                {
                    field: 'position',
                    rules: [
                        fieldLengthValidator({ max: MAX_POSITION_LENGTH })
                    ]
                },
            ],
            creation
            // TODO если изменить логин, потом вернуть как было, то кнопка "сохранить" активна
            // переехать на validation.modified и
            // добавить кастомный comparator после того как frontend-stage-4 войдёт в мастер
        )
    }

    openUser = async (ref: string): Promise<void> => {
        if (ref === NEW || !isNaN(Number(ref))) {
            await withSpinner(async () => {
                await this.fetchAllUsersData()
                if (ref === NEW) {
                    this.setNewUser()
                } else {
                    await this.fetchUser(Number(ref))
                }
            })
        } else {
            goTo(NOT_FOUND)
        }
    }

    @action
    fetchUser = async (userId: number): Promise<void> => {
        const user = await iUserManagementLocal.getUserById(Number(userId))
        if (!user) {
            goTo(`${STAFF}${USERS}`)
            return Promise.reject('user not found')
        }
        runInAction(() => {
            this.userPasswordHash = user.password
            this.topologyChanged = false
            this.userChanged = false
            /*
            * обнуляем пришедший хэш пароля
            * на сервере этот случай обрабатывается - когда мы отправляем пустую строку, пароль не перетирается
            * */
            this.user = { ...user, password: '' }
            this.createValidation()
        })

        if (this.appStore.isCentrum && this.user !== null) {
            const conditions = await iUserManagementLocal.getTopologyConditions(toJS(this.user))
            runInAction(() => {
                this.userTopology = conditions
            })
        }

        this.createValidation()
    }

    @action
    setNewUser = (): void => {
        this.newUser = true
        this.user = createUserVO({
            userRoles: [],
            password: '',
            login: '',
            email: '',
            firstName: '',
            middleName: '',
            lastName: '',
            position: ''
        })
        this.userTopology = null
        this.topologyChanged = false
        this.userChanged = false
        this.updateNavMenu()
        this.createValidation()
    }

    // Запрашиваем данные только, если хранилище usersStore пустое - (это значит, что мы по прямому url зашли)
    // В других ситуациях мы тут оказались уже из списка пользователей - и там данные актуальные
    @action
    fetchAllUsersData = async (): Promise<void> => {
        if (this.usersStore.activeUsers === undefined) {
            await this.usersStore.fetchData()
        }
        runInAction(() => {
            this.activeUsers = toJS(this.usersStore.activeUsers)
            this.blockedUsers = toJS(this.usersStore.blockedUsers)
            this.roles = toJS(this.usersStore.roles)
        })
    }

    @action
    clear = (): void => {
        this.user = null
        this.newUser = false
        this.userTopology = null
        this.topologyChanged = false
        this.userChanged = false
        this.validation = null
    }

    @action
    updateUser = (changes: Partial<UserVO>): void => {
        this.userChanged = true
        this.user = {
            ...toJS(this.user),
            ...changes
        }
        this.validation.item = this.user
    }

    @action
    updateUserRole = (roleId: number, newValue: boolean): void => {
        this.userChanged = true
        if (newValue) {
            // true - Надо добавить
            let newRole = this.roles.find(role => role.id === roleId)
            if (newRole) {
                this.user.userRoles.push(newRole)
            }
        } else {
            // false - Надо удалить
            let deleteIndex = this.user.userRoles.findIndex(role => role.id === roleId)
            if (deleteIndex !== -1) {
                this.user.userRoles.splice(deleteIndex, 1)
            }
        }
    }

    @action
    updateAllUserRoles = (newValue: boolean): void => {
        this.userChanged = true
        if (newValue) {
            this.user.userRoles = [...this.roles]
        } else {
            this.user.userRoles = []
        }
    }

    @action
    updateUserTopology = (topology: TopologyConditionVO[]): void => {
        this.userTopology = topology
        this.topologyChanged = true
    }

    @computed
    get step1haveErrors(): boolean {
        if (!this.user || !this.validation) return true

        const userLogin = this.user.login
        const userId = this.user.id

        if (!this.user.adAuth) {
            if (!userLogin || userLogin.length > MAX_LOGIN_LENGTH) return true

            if (this.newUser) {
                if (this.user.password.length < MIN_PASSWORD_LENGTH) return true
            } else {
                if (this.user.password.length < MIN_PASSWORD_LENGTH && this.user.password !== '') return true
            }
        }

        if (!this.validation.getValidationFor('firstName').valid) return true
        if (!this.validation.getValidationFor('middleName').valid) return true
        if (!this.validation.getValidationFor('lastName').valid) return true
        if (!this.validation.getValidationFor('position').valid) return true
        if (!this.validation.getValidationFor('email').valid) return true

        if (!this.activeUsers || this.activeUsers.some(u => u.login === userLogin && u.id !== userId)) return true
        if (!this.activeUsers || this.blockedUsers.some(u => u.login === userLogin && u.id !== userId)) return true

        return false
    }

    @computed
    get step2haveErrors(): boolean {
        if (!this.user) return true

        if (!this.user.userRoles || this.user.userRoles.length === 0) return true
        return false
    }

    saveUser = async (): Promise<void> => {
        let userCopy = {
            ...toJS(this.user),
            login: encodeNonLatinSymbols(this.user.login),
            password: encodeNonLatinSymbols(this.user.password)
        }

        if (!this.newUser && this.user.password === '') {
            userCopy.password = this.userPasswordHash
        }

        const user = await iUserManagementLocal.updateUser(this.userStore.session, userCopy)

        if (this.appStore.isCentrum && this.topologyChanged) {
            await iUserManagementLocal.setTopologyCondition({
                ...userCopy,
                id: user.id,
                login: this.user.login,  // в setTopologyCondition должен уходить незакодированный логин
                password: user.password  // даже при создании нового пользователя в setTopologyCondition должен уходить password в виде хэша
            }, toJS(this.userTopology))
        }
        goTo(`${STAFF}${USERS}`)
    }

    goBack = (): void => {
        if (this.validation.modified) {
            this.dialogStore.showDialog({
                title: t('staff.notSavedTitle'),
                message: t('staff.notSavedMessage'),
                mode: DIALOG,
                onYes: () => goTo(`${STAFF}${USERS}`)
            })
        } else {
            goTo(`${STAFF}${USERS}`)
        }
    }

    generatePassword = () => {
        const LENGTH: number = 9
        let hash: string = 'acbdefghijklmnopqrstuvwxyz'
        let password: string = ''
        let iteration: number = 0
        let hashLenght: number = hash.length

        while (iteration < LENGTH) {
            let num: number = Math.round(Math.random() * hashLenght)

            if (0.2 < Math.random()) {
                if (0.5 > Math.random()) {
                    password += hash.charAt(num).toUpperCase()
                } else {
                    password += hash.charAt(num)
                }
            } else {
                password += Math.round(Math.random() * 8 + 1)
            }
            iteration++
        }
        return password
    }

    updateNavMenu = (): void => {
        this.appBarStore.updateState({
            title: this.newUser ? t('staff.newUserAdding') : t('staff.userEditing'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: this.goBack
        })
    }

    @action
    reset = (): void => {
        this.user = undefined
        this.userTopology = undefined
        this.userPasswordHash = undefined
        this.newUser = false
        this.userChanged = false
        this.topologyChanged = false
        this.activeUsers = []
        this.blockedUsers = []
        this.roles = []
        this.validation = null
    }
}

export const USER_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${STAFF}${USER}/[\\w-]+/?$`),
    onEnter: () => {
        const userSettingsStore: UserSettingsStore = getStore(USER_SETTINGS_STORE)
        userSettingsStore.updateNavMenu()
    },
    onLeave: () => {
        const userSettingsStore: UserSettingsStore = getStore(USER_SETTINGS_STORE)
        userSettingsStore.clear()
    }
}
