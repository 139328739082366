import { TimestampPeriod } from '../../../set-retail10-commons/data-structs-module/timestamp-period'
import { ApplyMode } from '../../../set-retail10-commons/data-structs-module/apply-mode'
import { TopologyCondition } from '../../../set-retail10-commons/data-structs-module/topology-condition'
import { PriceTagTemplateVO } from '../../../set-retail10-commons/data-structs-module/price-tag-template-vo'
import { UserVO } from './user-vo'
import { PluginType } from '../../../set-retail10-commons/data-structs-module/plugin-type'
import { SecondaryResultTypes } from './secondary-result-types'

export interface AdvertisingAction {
    '@class': 'ru.crystals.discounts.AdvertisingAction'
    id?: number
    guid?: number
    parentGuid?: number
    name?: string
    description?: string
    ti?: string
    workPeriod?: TimestampPeriod
    mode?: ApplyMode
    worksAnytime?: boolean
    useRestrictions?: boolean
    active?: boolean
    priority?: number
    displayStyleName?: string
    lastChanges?: Date
    allNodes?: boolean
    topologyConditions?: TopologyCondition[]
    externalConditions?: string[]
    applyObjects?: string[]
    actionResults?: string[]
    masterActions?: AdvertisingAction[]
    externalCode?: string
    version?: number
    pricetagTemplates?: PriceTagTemplateVO[]
    templateFormatExtCodes?: string[]
    pricetagTypes?: string[]
    exemptFromBonusDiscounts?: boolean
    created?: Date
    author?: UserVO
    editor?: UserVO
    affectedShopsCount?: number
    primaryResultType?: PluginType
    secondaryResults?: SecondaryResultTypes[]
    disableChargeOnBonuses?: boolean
    finalAction?: boolean
    labels?: string[]
    couponPriority?: string
}

export const createAdvertisingAction = (initialization: Partial<AdvertisingAction>): AdvertisingAction => {
    if (!initialization) return null
    
    let result: AdvertisingAction = {
        id: initialization.id,
        guid: initialization.guid,
        parentGuid: initialization.parentGuid,
        name: initialization.name,
        description: initialization.description,
        ti: initialization.ti,
        workPeriod: initialization.workPeriod,
        mode: initialization.mode,
        worksAnytime: initialization.worksAnytime,
        useRestrictions: initialization.useRestrictions,
        active: initialization.active,
        priority: initialization.priority,
        displayStyleName: initialization.displayStyleName,
        lastChanges: initialization.lastChanges,
        allNodes: initialization.allNodes,
        topologyConditions: initialization.topologyConditions,
        externalConditions: initialization.externalConditions,
        applyObjects: initialization.applyObjects,
        actionResults: initialization.actionResults,
        masterActions: initialization.masterActions,
        externalCode: initialization.externalCode,
        version: initialization.version,
        pricetagTemplates: initialization.pricetagTemplates,
        templateFormatExtCodes: initialization.templateFormatExtCodes,
        pricetagTypes: initialization.pricetagTypes,
        exemptFromBonusDiscounts: initialization.exemptFromBonusDiscounts,
        created: initialization.created,
        author: initialization.author,
        editor: initialization.editor,
        affectedShopsCount: initialization.affectedShopsCount,
        primaryResultType: initialization.primaryResultType,
        secondaryResults: initialization.secondaryResults,
        disableChargeOnBonuses: initialization.disableChargeOnBonuses,
        finalAction: initialization.finalAction,
        labels: initialization.labels,
        couponPriority: initialization.couponPriority,
        '@class': 'ru.crystals.discounts.AdvertisingAction',
    }
    
    return result
}
