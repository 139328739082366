import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRODUCT_DETAILS_STORE, USER_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { AppStore } from '../../../store/app-store'
import { t } from 'i18next'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import NavigateNext from '@material-ui/icons/NavigateNext'
import { UserStore } from '../../../store/user-store'
import { SpiritsBottleVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/spirits-bottle-vo'
import { formatPrice } from '../../../core/currency-utils'
import moment from 'moment'
import { fromClientToServerTime } from '../../../utils/app-util'
import uuid from 'uuid'
import { productManagerFinderLocal } from '../../../../protocol/set10/product-manager-finder-local'
import { ACTIVE } from '../../../core/products/product-statuses'
import { SpiritsBottleBarcodeVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/spirits-bottle-barcode-vo'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { RIGHT, Table } from '@crystalservice/crystals-ui/lib'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'

export interface AlcoholKitStructureProps {
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
    user?: UserStore
}

const styles = require('./alcohol-kit-structure.scss')

@inject(PRODUCT_DETAILS_STORE)
@inject(APP_STORE)
@inject(USER_STORE)
@observer
export default class AlcoholKitStructure extends React.Component<AlcoholKitStructureProps> {

    goToProductByBarcode = async (bottleBarcodes: SpiritsBottleBarcodeVO[]): Promise<void> => {
        const { session } = this.props.user
        const { goToProduct } = this.props.productDetailsStore
        const barcodesList = bottleBarcodes.map(bottleBarcode => bottleBarcode.barcode.toString())

        const { foundProducts } = await productManagerFinderLocal.getSimpleProductsByBarCodes2(session, barcodesList, ACTIVE)

        if (!foundProducts || foundProducts && !foundProducts.length) return

        goToProduct(foundProducts[0].code)
    }

    render() {
        const { alcoholKit } = this.props.productDetailsStore

        if (!alcoholKit) return <PageLoader/>
        if (!alcoholKit.length) return <Empty/>

        return (
            <Table
                id="alcoholKitStructureList"
                withHeader
                keyFunction={(bottle: SpiritsBottleVO) => bottle.alcoCodes.join(',') + uuid()}
                items={alcoholKit}
                columns={[
                    {
                        header: t('productDetails.alcoholKitStructureEntrance.volume'),
                        labelField: 'volume',
                    },
                    {
                        header: t('common.quantity'),
                        labelField: 'count'
                    },
                    {
                        header: t('common.barcode'),
                        renderer: props => {
                            const bottle: SpiritsBottleVO = props.item

                            return bottle.barcodes.map(b => {
                                return (
                                    <div key={uuid()}>
                                        {b.barcode}
                                    </div>
                                )
                            })
                        },
                    },
                    {
                        header: t('productDetails.alcoholKitStructureEntrance.alcoholCodes'),
                        renderer: props => {
                            const bottle: SpiritsBottleVO = props.item

                            return bottle.alcoCodes.map(alcoCode => {
                                return (
                                    <div key={uuid()}>
                                        {alcoCode}
                                    </div>
                                )
                            })
                        },
                    },
                    {
                        header: t('common.price'),
                        renderer: props => {
                            const bottle: SpiritsBottleVO = props.item

                            return bottle.prices.map(p => {
                                return (
                                    <div key={uuid()}>
                                        {formatPrice(p.price / 100)}
                                        {` (${t('common.fromStart')} ${moment(fromClientToServerTime(p.beginDate)).format('DD.MM.YYYY')}`}
                                        {` ${t('common.toEnd')} `}
                                        {p.endDate && moment(fromClientToServerTime(p.endDate)).format('DD.MM.YYYY')}
                                        {!p.endDate && t('productDetails.indefinitely')}
                                        {`)`}
                                    </div>
                                )
                            })
                        },
                    },
                    {
                        renderer: props => {
                            const bottle: SpiritsBottleVO = props.item

                            return (
                                <AdaptiveIconButton
                                    id={`goToButton${bottle.barcodes.join('')}`}
                                    label={t('productDetails.alcoholKitStructureEntrance.toDetails')}
                                    onClick={() =>
                                        this.goToProductByBarcode(bottle.barcodes)
                                    }
                                >
                                    <NavigateNext />
                                </AdaptiveIconButton>
                            )
                        },
                        hAlign: RIGHT,
                        padding: '0 10px',
                        showOnRowHover: true,
                        withControls: true,
                        width: '70px'
                    }
                ]}
            />
        )
    }
}
