import { observable, action, runInAction, computed, toJS } from 'mobx'
import { APP_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { withSpinner } from '../with-spinner'
import { uniqueCouponsService } from '../../../protocol/set10/unique-coupons-service'
import { SearchArgumentsVO, createSearchArgumentsVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-arguments-vo'
import { SearchResultVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-result-vo'
import {
    UniqueCouponExemplarVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/unique-coupon-exemplar-vo'
import { SideBarFiltersState } from '../../components/filters/side-bar-filters'
import { FilterProps } from '../../components/filters/new-filters'
import {
    NAME_PART, USED_DATE, BASE_DATE_RANGE_FILTER_DELIMITER, CREATED_DATE, COUPON_STATUS,
    CREATED_CHECK, USED_CHECK, COUPON_NAME
} from '../../core/filters/filter'
import { getDateFromString } from '../../../utils/date-util'
import { fromClientToServerTime } from '../../utils/app-util'
import { USED_TYPE, ACTIVE_TYPE } from '../../components/filters/new-modules/loyalty-coupons/coupon-status'
import { getComplexReceiptNumbers } from '../../core/filters/coupon-filters'
import { createComplexReceiptSearchArgument } from '../../../protocol/set10/set-retail10-commons/data-structs-module/complex-receipt-search-argument'
import { uniqueCouponReportsFacade } from '../../../protocol/set10/unique-coupon-reports-facade'
import { FILE_TYPE } from '../../core/file-types'
import { cloneDeep } from 'lodash'
import { AppStore } from '../app-store'
import { t } from 'i18next'
import {
    ReportOrderStatusEnum, COMPLETE, ERROR, INTERRUPTED
} from '../../../protocol/set10/set-retail10-commons/set-report/report-order-status-enum'
import { ReportOrderVO } from '../../../protocol/set10/set-retail10-commons/set-report/report-order-vo'

const getDefaultFilterState = (): SideBarFiltersState => {
    return {
        shownFilters: {}
    }
}

export class LoyaltyCouponsInstancesStore {

    @observable
    coupons: UniqueCouponExemplarVO[]

    @observable
    filtersState: SideBarFiltersState = getDefaultFilterState()

    @observable
    filterShown: boolean = true

    @observable
    barcodeFilter: string = ''

    @observable
    usedSearchArguments: SearchArgumentsVO

    @observable
    allReports: ReportOrderVO[] = null

    @observable
    reportStatus: ReportOrderVO

    timerId: number

    @computed
    get searchArguments(): SearchArgumentsVO {
        let searchArgument = createSearchArgumentsVO({})

        if (this.barcodeFilter?.length) {
            searchArgument.numbers = this.barcodeFilter.split(',').map(item => item.trim())
        }

        const shownFilters = this.filtersState ? this.filtersState.shownFilters : {}
        Object.keys(shownFilters).forEach(filterType => {
            const filterData: FilterProps = shownFilters[filterType]
            const value = filterData.value
            if (!value) return

            switch (filterType) {
                case NAME_PART:
                    searchArgument.partOfCouponName = value
                    break
                case USED_DATE:
                    const usedDate: string[] = value ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER) : [null, null]
                    const usedStart = fromClientToServerTime(getDateFromString(usedDate[0]))
                    const usedEnd = fromClientToServerTime(getDateFromString(usedDate[1]))

                    if (usedStart) {
                        searchArgument.dateOfUsageFrom = usedStart
                    }
                    if (usedEnd) {
                        searchArgument.dateOfUsageTo = usedEnd
                    }
                    break
                case CREATED_DATE:
                    const createdDate: string[] = value ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER) : [null, null]
                    const createdStart = fromClientToServerTime(getDateFromString(createdDate[0]))
                    const createdEnd = fromClientToServerTime(getDateFromString(createdDate[1]))

                    if (createdStart) {
                        searchArgument.dateOfDistributionFrom = createdStart
                    }
                    if (createdEnd) {
                        searchArgument.dateOfDistributionTo = createdEnd
                    }
                    break
                case COUPON_STATUS:
                    if (value === ACTIVE_TYPE) {
                        searchArgument.isUsed = false
                    }
                    if (value === USED_TYPE) {
                        searchArgument.isUsed = true
                    }
                    break
                case CREATED_CHECK:
                    {
                        const numberValues = getComplexReceiptNumbers(filterData.data)
                        if (numberValues) {
                            searchArgument.distributionReceipt = createComplexReceiptSearchArgument(numberValues)
                        }
                    }
                    break
                case USED_CHECK:
                    {
                        const numberValues = getComplexReceiptNumbers(filterData.data)
                        if (numberValues) {
                            searchArgument.usedReceipt = createComplexReceiptSearchArgument(numberValues)
                        }
                    }
                case COUPON_NAME:
                    searchArgument.names = value.split(',')
                    break
            }
        })

        return searchArgument
    }

    @computed
    get gotFilters(): boolean {
        return Object.keys(this.searchArguments).some(key => {
            if (key === '@class') return false
            let item = this.searchArguments[key]
            return item !== undefined
        })
    }

    private appStore: AppStore = getStore(APP_STORE)

    fetchCoupons = async (): Promise<void> => {
        const searchArguments = toJS(this.searchArguments)
        const result: SearchResultVO = await withSpinner(uniqueCouponsService.findExemplars(
            searchArguments
        ))

        runInAction(() => {
            this.coupons = result.result
            this.usedSearchArguments = cloneDeep(searchArguments)
        })
    }

    @action
    fetchAllReports = async (): Promise<void> => {
        // Обнуляем перед запросом чтобы видеть спиннер в таблице
        this.allReports = null
        const result = await uniqueCouponReportsFacade.getCompletedReports()

        runInAction(() => {
            this.allReports = result.filter(item => !!item.fileName) || []
        })
    }

    createReport = async (fileType: FILE_TYPE): Promise<void> => {
        const result = await uniqueCouponReportsFacade.createReportOrder(
            toJS(this.usedSearchArguments),
            fileType.toLowerCase()
        )
        const reportNumber = Array.isArray(result) ? result[1] : result

        await this.checkReportStatus(reportNumber)
    }

    stopPolling = (): void => {
        clearInterval(this.timerId)
    }

    restartPolling = (reportNumber: number): void => {
        this.stopPolling()
        this.timerId = setInterval(() => this.checkReportStatus(reportNumber), 500)
    }

    discardReport = (): Promise<void> => {
        const id = this.reportStatus.id
        this.cancelReportStatus()
        return withSpinner(uniqueCouponReportsFacade.discardReport(id))
    }

    @action
    cancelReportStatus = (): void => {
        this.reportStatus = null
    }

    checkReportStatus = async (reportNumber: number): Promise<void> => {
        this.stopPolling()
        const resultReport = await uniqueCouponReportsFacade.getReportStatus(reportNumber)

        runInAction(() => {
            this.reportStatus = resultReport
        })

        if (!resultReport) {
            this.restartPolling(reportNumber)
            return
        }

        const reportStatus: ReportOrderStatusEnum = resultReport.status

        if (reportStatus === COMPLETE && resultReport.fileName) {
            this.appStore.showSnackbar({
                message: t('loyaltyCoupons.instancesPage.reportReady'),
                variant: 'success'
            })
            return
        }
        if (
            reportStatus === ERROR ||
            reportStatus === COMPLETE && !resultReport.fileName
        ) {
            this.appStore.showSnackbar({
                message: t('loyaltyCoupons.instancesPage.reportFail'),
                variant: 'error'
            })
            runInAction(() => {
                this.reportStatus = null
            })
            return
        }

        if (
            reportStatus === INTERRUPTED
        ) {
            this.stopPolling()
            runInAction(() => {
                this.reportStatus = null
            })
            return
        }

        this.restartPolling(reportNumber)
    }

    @action
    clearFilters = (filterState: SideBarFiltersState): void => {
        this.filtersState = filterState
        this.barcodeFilter = ''
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.filtersState = filterState
    }

    @action
    setBarcodeFilter = (value: string): void => {
        this.barcodeFilter = value
    }

    @action
    onFilterToggle = (): void => {
        this.filterShown = !this.filterShown
    }

    @action
    reset = (): void => {
        this.coupons = null
        this.filtersState = getDefaultFilterState()
        this.filterShown = true
        this.barcodeFilter = ''
        this.reportStatus = null
        this.usedSearchArguments = undefined
        this.allReports = null
    }
}
