import { observable, action, runInAction, computed, toJS } from 'mobx'
import { sortBy, compact } from 'lodash'
import { t } from 'i18next'
import {
    getRouteName,
    PRICE_TAGS,
    PRINTING,
    CUSTOM_PRINTING,
    NOT_PRINTED,
    NOT_ACTUAL,
    NOT_FOUND
} from '../../../core/app-routes'
import { RouteChangeHandler, goTo } from '../../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../../app-bar-store'
import { APP_BAR_STORE, USER_STORE, PRICE_TAGS_PRINTING_STORE, APP_STORE } from '../../stores'
import { getStore } from '../../stores-repository'
import { printersManagerLocal } from '../../../../protocol/set10/printers-manager-local'
import { PrinterVO, createPrinterVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/printer-vo'
import { UserStore } from '../../user-store'
import { PriceTagSizeLabelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-size-label-vo'
import { PriceTagTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { PriceTagFormatVO } from '../../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-vo'
import { PRICETAG } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/print-doc-type'
import {
    SETRETAILX_CHOICE_PRINTER_FOR_PRICETAG,
    SETRETAILX_PRICE_TEMPLATE_PRINT_MANUALLY,
    SETRETAILX_PRICE_TEMPLATE_FORMATS_MANUAL_SELECTION,
    SETRETAILX_PRICE_TEMPLATE_PRINT_DEFAULT,
    SETRETAILX_GROUP_TEMPLATES_BY_PRINTERS_BINDING
} from '../../../core/privileges/privileges'
import {
    ProductStatus,
    ACTION_PRICE_TAG_EXPIRED,
    REGULAR_PRICE_ENDED,
    PRICE_TAG_NOT_PRINTED,
    ACTIVE,
    ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED,
    ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED
} from '../../../core/products/product-statuses'
import { priceTagFormatPresenterLocal } from '../../../../protocol/set10/price-tag-format-presenter-local'
import { AppStore } from '../../app-store'
import { formatsPrintingService } from '../../../../protocol/set10/formats-printing-service'
import { printingService } from '../../../../protocol/set10/printing-service'
import {
    PrintingParamsVO,
    createPrintingParamsVO
} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/printing-params-vo'
import { withSpinner } from '../../with-spinner'

export enum SelectedSettingsTab {
    FormatSelection = 'formatSelectionTab',
    BindedPriceTagsSelection = 'bindedPriceTagsSelectionTab',
    AllPriceTagsSelection = 'allPriceTagsSelectionTab'
}

export interface ShelfParams {
    objectIds: string[]
    productStatus: ProductStatus
    date?: Date
    /**
     * Изменения товаров перед печатью
     * Ожидаемый формат:
     * {
     *     [productId]: {
     *         [param1]: string
     *         ...
     *         [paramN]: string
     *     }
     * }
     */
    substitutions?: { [key: string]: { [key: string]: string } }
    /**
     * Колбэк, вызываемый сразу после запуска печати
     */
    handlePrint?: (shelfParams: ShelfParams, printTasks?: string[]) => void
}

export interface PairOfPriceTagAndPrinter {
    priceTag: PriceTagTemplateVO
    printer: PrinterVO
    getId(): string
}

export interface SelectedPairOfPriceTagAndPrinter {
    pair: PairOfPriceTagAndPrinter
    selectedCount: number
}

export const FAKE_BINDED_TO_PRICE_TAGS_PRINTER_ID: number = -1

export class PriceTagsPrintingStore {

    @observable
    shelfParams: ShelfParams = null

    @observable
    selectedAllPriceTagsCountMap: Map<string, number> = new Map<string, number>()

    @observable
    selectedBindedPairs: SelectedPairOfPriceTagAndPrinter[] = []

    @observable
    selectedFormats: PriceTagFormatVO[] = []

    @observable
    selectedSettingsTab: SelectedSettingsTab = null

    @observable
    selectedPrinter: PrinterVO = createFakeBindedToPriceTagsPrinter()

    @observable
    defaultPrintingSelected: boolean = true

    @observable
    priceTagPrinters: PrinterVO[] = null

    @observable
    priceTagSizeLabels: PriceTagSizeLabelVO[] = null

    @observable
    allPriceTags: PriceTagTemplateVO[] = null

    @observable
    availableFormats: PriceTagFormatVO[] = null

    backPath: string
    lastProductStatus: ProductStatus

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    @computed
    get actionable(): boolean {
        if (!this.shelfParams) {
            return null
        }
        return isActionable(this.shelfParams.productStatus)
    }

    @computed
    get formatModuleEnabled(): boolean {
        return this.appStore.printingModuleProperties && this.appStore.printingModuleProperties.formatModuleEnabled
    }

    @computed
    get printerSelectionAvailable(): boolean {
        return this.userStore.havePrivilege(SETRETAILX_CHOICE_PRINTER_FOR_PRICETAG)
    }

    @computed
    get priceTagsSelectionAvailable(): boolean {
        return this.userStore.havePrivilege(SETRETAILX_PRICE_TEMPLATE_PRINT_MANUALLY)
    }

    @computed
    get formatsSelectionAvailable(): boolean {
        return this.userStore.havePrivilege(SETRETAILX_PRICE_TEMPLATE_FORMATS_MANUAL_SELECTION)
            && this.formatModuleEnabled
    }

    @computed
    get defaultPrintingAvailable(): boolean {
        return this.userStore.havePrivilege(SETRETAILX_PRICE_TEMPLATE_PRINT_DEFAULT)
    }

    @computed
    get priceTagsSelectionByGroupingAvailable(): boolean {
        return this.userStore.havePrivilege(SETRETAILX_GROUP_TEMPLATES_BY_PRINTERS_BINDING)
    }

    @computed
    get anyTabAvailable(): boolean {
        return this.priceTagsSelectionByGroupingAvailable
            || this.priceTagsSelectionAvailable
            || this.formatsSelectionAvailable
    }

    @computed
    get defaultPrintingVisible(): boolean {
        return this.defaultPrintingAvailable && this.anyTabAvailable
    }

    @computed
    get selectedFormatIds(): number[] {
        return this.selectedFormats.map(format => format.id)
    }

    @computed
    get wrongPrinter(): boolean {
        switch (this.selectedSettingsTab) {
            default:
            case SelectedSettingsTab.FormatSelection:
                return false
            case SelectedSettingsTab.BindedPriceTagsSelection: {
                return this.selectedBindedPairs.some(selectedPair => {
                    const { priceTag, printer } = selectedPair.pair
                    return checkPriceTagAndPrinterDimensions(priceTag, printer)
                })
            }
            case SelectedSettingsTab.AllPriceTagsSelection: {
                if (!this.selectedPrinter) return false
                const selectedPriceTags: PriceTagTemplateVO[] = (this.selectedAllPriceTagsCountMap.keys() as unknown as string[])
                    .map(priceTagId => {
                        return this.allPriceTags.find(pt => pt.id === priceTagId)
                    })

                if (!selectedPriceTags) return false

                return selectedPriceTags.some(pt => {
                    return checkPriceTagAndPrinterDimensions(pt, this.selectedPrinter)
                })
            }
        }
    }

    @computed
    get notBoundPriceTagTemplates(): PriceTagTemplateVO[] {
        if (!this.allPriceTags || !this.priceTagPrinters) return []
        const boundTemplates = compact(this.priceTagPrinters.reduce((acc, printer) => {
            return acc.concat(toJS(printer.priceTags))
        }, []))
        return this.allPriceTags.filter(template => {
            return template.actionable === this.actionable
                && !boundTemplates.find(boundTemplate => boundTemplate.id === template.id)
        })
    }

    @action
    openPrinting = (path: string, shelfParams: ShelfParams): void => {
        this.shelfParams = shelfParams
        if (shelfParams) {
            this.lastProductStatus = shelfParams.productStatus
        }
        goTo(path)
    }

    print = async (): Promise<void> => {
        let printTasks: string[]

        const defaultPrint: boolean =
            (this.defaultPrintingVisible && this.defaultPrintingSelected)
            ||
            (!this.defaultPrintingVisible && this.defaultPrintingAvailable)

        if (defaultPrint) {
            if (this.appStore.printingModuleProperties.formatModuleEnabled) {
                printTasks = await withSpinner(printDefaultViaFormats(this)) // Печатаем через форматы ценников
            } else {
                printTasks = await withSpinner(printDefaultViaPriceTags(this)) // Печатаем через привязку ценников
            }
        } else {
            if (this.selectedSettingsTab === SelectedSettingsTab.FormatSelection) {
                printTasks = await withSpinner(printManuallyViaFormats(this)) // Печатаем через форматы ценников
            } else {
                printTasks = await withSpinner(printManuallyViaPriceTags(this)) // Печатаем через привязку ценников
            }
        }

        const { handlePrint } = this.shelfParams

        if (handlePrint) {
            handlePrint(this.shelfParams, printTasks)
        }

        if (this.backPath) {
            goTo(this.backPath)
        }
    }

    @action
    setDefaultPrintingSelected = (defaultPrintingSelected: boolean): void => {
        this.defaultPrintingSelected = defaultPrintingSelected
    }

    @action
    setSelectedSettingsTab = (selectedSettingsTab: SelectedSettingsTab) => {
        this.selectedSettingsTab = selectedSettingsTab
    }

    @action
    setSelectedPrinter = (selectedPrinter: PrinterVO): void => {
        this.selectedPrinter = selectedPrinter
    }

    @action
    setSelectedFormats = (selectedFormats: PriceTagFormatVO[]): void => {
        this.selectedFormats = selectedFormats
    }

    @action
    changePrintingParamsForBindedPriceTags = (pair: PairOfPriceTagAndPrinter, newSelectedCount: number): void => {
        let requestedPairIndex: number = this.selectedBindedPairs.findIndex(selectedPair => {
            return selectedPair.pair.priceTag.id === pair.priceTag.id
                && selectedPair.pair.printer.id === pair.printer.id
        })

        if (requestedPairIndex !== -1) {
            if (newSelectedCount === 0) {
                this.selectedBindedPairs = this.selectedBindedPairs.filter((p, index: number) => index !== requestedPairIndex)
            } else {
                this.selectedBindedPairs = this.selectedBindedPairs.map((p, index: number) => {
                    if (index !== requestedPairIndex) {
                        return p
                    }

                    return {
                        ...p,
                        selectedCount: newSelectedCount
                    }
                })
            }
        } else {
            if (newSelectedCount === 0) {
                return
            } else {
                this.selectedBindedPairs.push({
                    pair,
                    selectedCount: newSelectedCount
                })
            }
        }
    }

    @action
    changeAllPriceTagCountSelection = (priceTagId: string, newSelectedCount: number): void => {
        if (newSelectedCount === 0 && this.selectedAllPriceTagsCountMap.has(priceTagId)) {
            this.selectedAllPriceTagsCountMap.delete(priceTagId)
        } else {
            this.selectedAllPriceTagsCountMap.set(priceTagId, newSelectedCount)
        }
    }

    fetchPrinters = async (): Promise<void> => {
        let priceTagPrinters = await printersManagerLocal.listPrintersLite(this.userStore.session) || []
        priceTagPrinters = priceTagPrinters.filter(printer => printer.printType === PRICETAG)
        priceTagPrinters = sortBy(priceTagPrinters, 'name')

        const userPrinterIndex: number = priceTagPrinters.findIndex(printer => {
            return printer.users.some(user => user.id === this.userStore.userInfo.id)
        })
        let userPrinter = null

        if (userPrinterIndex !== -1) {
            // сохраняем во временную переменную
            userPrinter = {
                ...priceTagPrinters[userPrinterIndex],
                name: t('priceTagsPrinting.userPrinter', { printerName: priceTagPrinters[userPrinterIndex].name })
            }
            // удаляем принтер, привязанный к юзеру
            priceTagPrinters = priceTagPrinters.filter(printer => printer.id !== userPrinter.id)

            // добавляем в начало массива
            priceTagPrinters.unshift(userPrinter)
        }

        const fakeBindedToPriceTagsPrinter = createFakeBindedToPriceTagsPrinter()

        // добавляем в начало массива
        priceTagPrinters.unshift(fakeBindedToPriceTagsPrinter)

        runInAction(() => {
            this.priceTagPrinters = priceTagPrinters
            this.setSelectedPrinter(userPrinter || fakeBindedToPriceTagsPrinter)
        })
    }

    fetchPriceTagSizeLabels = async (): Promise<void> => {
        let allPriceTagSizeLabels = await printersManagerLocal.getAllPriceTagSizeLabels()
        // TODO SFM-208 ошибка транспорта - lastUpdate приходит как timeStamp. Явно приводим его к Date
        allPriceTagSizeLabels = (allPriceTagSizeLabels || []).map(sizeLabel => {
            if (sizeLabel.lastUpdate) {
                return {
                    ...sizeLabel,
                    lastUpdate: new Date(sizeLabel.lastUpdate)
                }
            }
            return sizeLabel
        })
        allPriceTagSizeLabels = sortBy(allPriceTagSizeLabels, 'name')

        runInAction(() => {
            this.priceTagSizeLabels = allPriceTagSizeLabels
        })
    }

    fetchAllPriceTags = async (): Promise<void> => {
        let allPriceTags = await printersManagerLocal.getAllPriceTags(this.userStore.session)
        // TODO SFM-208 ошибка транспорта - lastUpdate приходит как timeStamp. Явно приводим его к Date
        allPriceTags = (allPriceTags || []).map(pt => {
            if (pt.lastUpdate) {
                return {
                    ...pt,
                    lastUpdate: new Date(pt.lastUpdate)
                }
            }
            return pt
        })
        runInAction(() => {
            this.allPriceTags = allPriceTags
        })
    }

    fetchFormats = async (): Promise<void> => {
        const availableFormats = await priceTagFormatPresenterLocal.loadAll()
        runInAction(() => {
            this.availableFormats = availableFormats || []
        })
    }

    clearLastProductStatus = () => {
        this.lastProductStatus = null
    }

    @action
    reset = (): void => {
        this.selectedAllPriceTagsCountMap.clear()
        this.selectedBindedPairs = []
        this.selectedFormats = []
        this.shelfParams = null
        this.selectedSettingsTab = null
        this.priceTagPrinters = null
        this.selectedPrinter = createFakeBindedToPriceTagsPrinter()
        this.priceTagSizeLabels = null
        this.allPriceTags = null
        this.availableFormats = null
        this.defaultPrintingSelected = true
    }
}

/**
 * Обработчик для страниц /price-tags/:desk/:shelf/printing
 */
export const PRICE_TAGS_PRINTING_ROUTE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${PRICE_TAGS}(\/[\\w-]+)${PRINTING}/?$`),
    onEnter: (newRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

        const routeMatch = newRoute.match(PRICE_TAGS_PRINTING_ROUTE_HANDLER.routeMatcher)

        if (routeMatch[1] !== CUSTOM_PRINTING && routeMatch[1] !== NOT_PRINTED && routeMatch[1] !== NOT_ACTUAL) {
            goTo(NOT_FOUND)
            return
        }

        printingStore.backPath = PRICE_TAGS + routeMatch[1]

        if (!printingStore.shelfParams) {
            setTimeout(() => {
                // TODO временное решение - без таймаута не запускается autoRun в appBarStore
                goTo(printingStore.backPath)
            })
            return
        }

        const printingType: string = isRegular(printingStore.shelfParams.productStatus) ? t('set10.regular') : t('set10.action')

        appBarStore.updateState({
            title: `${t('set10.priceTags')} - ${getRouteName(routeMatch[1])} - ${printingType} - ${t('set10.printing')}`,
            leftIcon: LEFT_ARROW,
            showNotifications: true,
            onLeftIconClick: () => goTo(printingStore.backPath)
        })
    }
}

function createFakeBindedToPriceTagsPrinter(): PrinterVO {
    return createPrinterVO({
        id: FAKE_BINDED_TO_PRICE_TAGS_PRINTER_ID,
        name: t('priceTagsPrinting.bindedToPriceTagsPrinters')
    })
}

function isActionable(productStatus: ProductStatus): boolean {
    switch (productStatus) {
        default: return null
        case ACTION_PRICE_TAG_EXPIRED:
        case REGULAR_PRICE_ENDED:
        case PRICE_TAG_NOT_PRINTED:
        case ACTIVE:
            return false
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED:
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED:
            return true
    }
}

function isRegular(productStatus: ProductStatus): boolean {
    switch (productStatus) {
        default: return null
        case REGULAR_PRICE_ENDED:
        case PRICE_TAG_NOT_PRINTED:
        case ACTIVE:
            return true
        case ACTION_PRICE_TAG_EXPIRED:
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED:
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED:
            return false
    }
}

async function printDefaultViaFormats(printingStore: PriceTagsPrintingStore): Promise<string[]> {
    const { shelfParams, selectedPrinter } = printingStore
    const objectIds: string[] = toJS(shelfParams.objectIds)
    const substitutions = prepareSubstitutionsForServer(shelfParams.substitutions)

    switch (shelfParams.productStatus) {
        case PRICE_TAG_NOT_PRINTED: // not printed regular
            return formatsPrintingService.printRegularFormatsByDefault(objectIds, selectedPrinter.id)
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED: // not printed action
            return formatsPrintingService.printDiscountFormatsByDefault(
                objectIds,
                substitutions,
                selectedPrinter.id
            )
        case ACTIVE: // custom regular
            return formatsPrintingService.rePrintRegularFormatsByDefault(
                objectIds,
                substitutions,
                shelfParams.date,
                selectedPrinter.id
            )
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED: // custom action
            return formatsPrintingService.rePrintDiscountFormatsByDefault(
                objectIds,
                substitutions,
                selectedPrinter.id
            )
        case REGULAR_PRICE_ENDED: // not actual regular
            return formatsPrintingService.printToReplaceRegularFormatsByDefault(objectIds, selectedPrinter.id)
        case ACTION_PRICE_TAG_EXPIRED: // not actual action
            return formatsPrintingService.printToReplaceDiscountFormatsByDefault(objectIds, selectedPrinter.id)
        default:

    }
}

async function printDefaultViaPriceTags(printingStore: PriceTagsPrintingStore): Promise<string[]> {
    const { shelfParams, selectedPrinter } = printingStore
    const objectIds: string[] = toJS(shelfParams.objectIds)
    const substitutions = prepareSubstitutionsForServer(shelfParams.substitutions)

    switch (shelfParams.productStatus) {
        case PRICE_TAG_NOT_PRINTED:
            return printingService.printRegularPriceTagsByDefault(objectIds, selectedPrinter.id)
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED:
            return printingService.printDiscountPriceTagsByDefault(
                objectIds,
                substitutions,
                selectedPrinter.id
            )
        case ACTIVE:
            return printingService.rePrintRegularPriceTagsByDefault(
                objectIds,
                substitutions,
                shelfParams.date,
                selectedPrinter.id
            )
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED:
            return printingService.rePrintDiscountPriceTagsByDefault(
                objectIds,
                substitutions,
                selectedPrinter.id
            )
        case REGULAR_PRICE_ENDED:
            return printingService.printToReplaceRegularPriceTagsByDefault(objectIds, selectedPrinter.id)
        case ACTION_PRICE_TAG_EXPIRED:
            return printingService.printToReplaceDiscountPriceTagsByDefault(objectIds, selectedPrinter.id)
        default:

    }
}

async function printManuallyViaFormats(printingStore: PriceTagsPrintingStore): Promise<string[]> {
    const { shelfParams, selectedPrinter } = printingStore

    const objectIds: string[] = toJS(shelfParams.objectIds)
    const selectedFormatIds: number[] = toJS(printingStore.selectedFormatIds)
    const substitutions = prepareSubstitutionsForServer(shelfParams.substitutions)

    switch (shelfParams.productStatus) {
        case PRICE_TAG_NOT_PRINTED:
            return formatsPrintingService.printRegularFormats(objectIds, selectedFormatIds, selectedPrinter.id)
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED:
            return formatsPrintingService.printDiscountFormats(
                objectIds,
                selectedFormatIds,
                substitutions,
                selectedPrinter.id
            )
        case ACTIVE:
            return formatsPrintingService.rePrintRegularFormats(
                objectIds,
                selectedFormatIds,
                substitutions,
                shelfParams.date,
                selectedPrinter.id
            )
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED:
            return formatsPrintingService.rePrintDiscountFormats(
                objectIds,
                selectedFormatIds,
                substitutions,
                selectedPrinter.id
            )
        case REGULAR_PRICE_ENDED:
            return formatsPrintingService.printToReplaceRegularFormats(objectIds, selectedFormatIds, selectedPrinter.id)
        case ACTION_PRICE_TAG_EXPIRED:
            return formatsPrintingService.printToReplaceDiscountFormats(objectIds, selectedFormatIds, selectedPrinter.id)
        default:
            break
    }
}

async function printManuallyViaPriceTags(printingStore: PriceTagsPrintingStore): Promise<string[]> {
    const { shelfParams, selectedSettingsTab, selectedPrinter, selectedBindedPairs, selectedAllPriceTagsCountMap } = printingStore

    const objectIds: string[] = toJS(shelfParams.objectIds)
    const substitutions = prepareSubstitutionsForServer(shelfParams.substitutions)

    let printingParams: PrintingParamsVO[] = []
    if (selectedSettingsTab === SelectedSettingsTab.AllPriceTagsSelection) {
        selectedAllPriceTagsCountMap.forEach((value: number, key: string) => {
            printingParams.push(createPrintingParamsVO({
                templateId: key,
                printerId: selectedPrinter.id,
                amountToPrint: value
            }))
        })
    } else if (selectedSettingsTab === SelectedSettingsTab.BindedPriceTagsSelection) {
        selectedBindedPairs.forEach((selectedPair: SelectedPairOfPriceTagAndPrinter) => {
            printingParams.push(createPrintingParamsVO({
                templateId: selectedPair.pair.priceTag.id,
                printerId: selectedPair.pair.printer.id,
                amountToPrint: selectedPair.selectedCount
            }))
        })
    }

    switch (shelfParams.productStatus) {
        case PRICE_TAG_NOT_PRINTED:
            return printingService.printRegularPriceTags(objectIds, printingParams)
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED:
            return printingService.printDiscountPriceTags(
                objectIds,
                printingParams,
                substitutions
            )
        case ACTIVE:
            return printingService.rePrintRegularPriceTags(
                objectIds,
                printingParams,
                substitutions,
                shelfParams.date
            )
        case ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED:
            return printingService.rePrintDiscountPriceTags(
                objectIds,
                printingParams,
                substitutions,
            )
        case REGULAR_PRICE_ENDED:
            return printingService.printToReplaceRegularPriceTags(objectIds, printingParams)
        case ACTION_PRICE_TAG_EXPIRED:
            return printingService.printToReplaceDiscountPriceTags(objectIds, printingParams)
    }
}

function prepareSubstitutionsForServer(originalSubstitutions: { [key: string]: { [key: string]: string } })
    : { [key: string]: { [key: string]: string } } {
    // обходное решение проблемы SFM-550

    const substitutions = toJS(originalSubstitutions) as any

    if (substitutions) {
        Object.keys(substitutions).forEach(code => {
            substitutions[code]['@class'] = 'java.util.Map'
        })
        substitutions['@class'] = 'java.util.Map'
    }

    return substitutions
}

function checkPriceTagAndPrinterDimensions(priceTag: PriceTagTemplateVO, printer: PrinterVO): boolean {
    return (priceTag.width > printer.width || priceTag.height > printer.height)
        && (priceTag.width > printer.height || priceTag.height > printer.width)
}
