export interface DisplayColor {
    '@class': 'ru.crystals.setretailx.common.DisplayColor'
    red?: number
    green?: number
    blue?: number
}

export const createDisplayColor = (initialization: Partial<DisplayColor>): DisplayColor => {
    if (!initialization) return null
    
    let result: DisplayColor = {
        red: initialization.red,
        green: initialization.green,
        blue: initialization.blue,
        '@class': 'ru.crystals.setretailx.common.DisplayColor',
    }
    
    return result
}
