import React from 'react'
import { t } from 'i18next'
import { ConditionEditorProps } from '../types'
import { Table } from '@crystalservice/crystals-ui/lib/components'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { Delete } from '@material-ui/icons'
import { inject, observer } from 'mobx-react'
import { USER_STORE } from '../../../../../../store/stores'
import { UserStore } from '../../../../../../store/user-store'
import {
    AdvertisingAction
} from '../../../../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { AdaptiveIconButton } from '../../../../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { DiscountAutocomplete } from '../../../../../../../components/inputs/autocomplete-input/discount-autocomplete'

// Получение текста для чипа
export const getMasterActionsChipLabel = (masterActions: AdvertisingAction[]): string => {
    let chipText: string = ''
    masterActions.forEach(item => {
        if (chipText) {
            chipText += ', '
        }

        chipText += t('advertisingActions.common.masterActionsChip', { name: item.name })
    })

    return chipText[0].toUpperCase() + chipText.substring(1)
}

interface MasterActionsEditorProps extends ConditionEditorProps {
    user?: UserStore
}

@inject(USER_STORE)
@observer
export class MasterActionsEditor extends React.Component<MasterActionsEditorProps> {

    handleRemoveItem = (itemId: number): void => {
        const { action, onActionChange } = this.props
        let masterActions = action.masterActions.filter(item => item.id !== itemId)

        onActionChange({ masterActions })
    }

    handleAddAction = (newItem: AdvertisingAction): void => {
        const { action, onActionChange } = this.props

        let masterActions = [...action.masterActions || []]

        if (!masterActions.find(item => item.id === newItem.id)) {
            masterActions.push(newItem)
        }

        onActionChange({ masterActions })
    }

    render() {
        const masterActions = this.props.action.masterActions

        let selectedActionsIds = masterActions?.map(action => action.id)

        const readOnly = this.props.readOnly

        return (
            <div>
                <div style={{ padding: '24px 24px 0 24px' }}>
                    <DiscountAutocomplete
                        session={this.props.user.session}
                        onSuggestionSelect={suggestion => {
                            this.handleAddAction(suggestion)
                        }}
                        excludedActionsIds={[this.props.action.id]}
                        markedActionsIds={selectedActionsIds}
                        disabled={readOnly}
                    />
                </div>

                <Table
                    adaptive={false}
                    title={t('advertisingActions.common.masterActionsTitle')}
                    keyField="id"
                    items={masterActions}
                    columns={[
                        {
                            labelField: 'name'
                        },
                        {
                            renderer: ({ item }: { item: AdvertisingAction }) => {
                                return (
                                    <AdaptiveIconButton
                                        id={`removeButton_${item.id}`}
                                        adaptive={false}
                                        label={t('common.remove')}
                                        disabled={readOnly}
                                        onClick={() => this.handleRemoveItem(item.id)}
                                    >
                                        <Delete/>
                                    </AdaptiveIconButton>
                                )
                            },
                            showOnRowHover: true,
                            withControls: true
                        }
                    ]}
                />
                {!masterActions?.length && (
                    <Empty/>
                )}
            </div>
        )
    }
}
