export interface ClientAddress {
    '@class': 'ru.crystals.cards.common.ClientAddress'
    zip?: string
    city?: string
    district?: string
    region?: string
    districtArea?: string
    other?: string
    street?: string
    house?: string
    building?: string
    appartment?: string
}

export const createClientAddress = (initialization: Partial<ClientAddress>): ClientAddress => {
    if (!initialization) return null
    
    let result: ClientAddress = {
        zip: initialization.zip,
        city: initialization.city,
        district: initialization.district,
        region: initialization.region,
        districtArea: initialization.districtArea,
        other: initialization.other,
        street: initialization.street,
        house: initialization.house,
        building: initialization.building,
        appartment: initialization.appartment,
        '@class': 'ru.crystals.cards.common.ClientAddress',
    }
    
    return result
}
