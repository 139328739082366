import i18next from 'i18next'
import { config } from '../config/config'
import moment from 'moment'
import { AppStore } from '../store/app-store'
import { getStore } from '../store/stores-repository'
import { APP_STORE } from '../store/stores'
import { LOCALE } from '../../locale/languages'

export async function prepareLocale() {

    const appStore: AppStore = getStore(APP_STORE)
    const { serverLocale } = appStore.serverInfo
    const locale = LOCALE[serverLocale]

    moment.locale(locale)
    return import(/* webpackChunkName: "locale-[request]" */`../locale/${locale}/index`)
            .then(bundle => {
                return new Promise((resolve, reject) => {
                    i18next.init({
                        lng: locale,
                        debug: !config.isProduction,
                        resources: {},
                    }, (err, t) => {
                        if (err) {
                            console.error('i18next err:', err)
                            reject(err)
                        } else {
                            i18next.addResourceBundle(locale, 'translation', bundle.default.translation, true, false)
                            resolve()
                        }
                    })
                })
            })
            .catch(error => {
                console.error('import bundle err:', error)
            })
}
