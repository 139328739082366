import { ApplyObject } from './apply-object'

export class FixPriceApplyObject {
    pluginName: string
    applyObjects: ApplyObject[]

    constructor(initialize: Partial<FixPriceApplyObject>) {
        if (!initialize) {
            return
        }

        this.pluginName = initialize.pluginName
        this.applyObjects = initialize.applyObjects
    }
}
