import React from 'react'
import { getFilterTypeName, FilterType, ACTION_COUPON_CATEGORY } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { AutocompleteInput } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { t } from 'i18next'
import { uniqueCouponsService } from '../../../../protocol/set10/unique-coupons-service'
import { CouponInfoVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/coupon-info-vo'
import Tooltip from '@material-ui/core/Tooltip'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'

export class ActionCouponCategory extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_COUPON_CATEGORY

    render() {
        const { id, data, onChange, slim } = this.props

        const shownValue = data || ''
        return (
            <AutocompleteInput
                id={id}
                onSuggestionSelect={(coupon: CouponInfoVO) => {
                    onChange(ActionCouponCategory.filterType, {
                        value: coupon ? String(coupon.guid) : null,
                        data: coupon ? coupon.name : null
                    })
                }}
                label={getFilterTypeName(ActionCouponCategory.filterType)}
                shownValue={shownValue}
                showClearButton
                placeholder={t('filters.actionCouponCategoryPlaceholder')}
                fetchDataCallback={mask => uniqueCouponsService.findActiveCouponInfoWithNamesLike(mask, 20)}
                suggestionsLimit={20}
                menuItemRenderer={(suggestion: CouponInfoVO) => (
                    <Tooltip
                        id={`coupon-category-${suggestion.guid}`}
                        title={suggestion.name}
                        enterDelay={TOOLTIP_DELAY}
                    >
                        <div>{suggestion.name}</div>
                    </Tooltip>
                )}
                keyField="guid"
                labelField="name"
                slim={slim}
                ghostDivWidth="250%"
            />
        )
    }
}
