import { observable, action, toJS } from 'mobx'
import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import {
    createDirectivePrintingTopologyVO,
    DirectivePrintingTopologyVO
} from '../../../../protocol/set10/set-retail10-server/retailx/set-directive-printing/directive-printing-topology-vo'
import { directivePrintingTopologyPresenter } from '../../../../protocol/set10/directive-printing-topology-presenter'
import { goTo, RouteChangeHandler } from '../../../utils/router-util'
import { NEW } from '../../../../set10/core/values'
import { AppBarStore, LEFT_ARROW } from '../../app-bar-store'
import {
    NOT_FOUND,
    PRICE_TAGS,
    DIRECTIVE_PRINTING,
    TOPOLOGIES
} from '../../../core/app-routes'
import { TopologiesStore } from './topologies-store'
import { getUniqueNameWithNumberPostfix } from '../../../../utils/name-util'
import { FormValidation } from '../../../../utils/form-validation/form-validation'
import { requiredField } from '../../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../../utils/form-validation/validators/unique-field'
import { getStore } from '../../../../set10/store/stores-repository'
import { RouterStore } from 'mobx-react-router'
import {
    APP_BAR_STORE,
    DIRECTIVE_PRINTING_TOPOLOGIES_STORE,
    DIRECTIVE_PRINTING_TOPOLOGY_SETTINGS_STORE,
    ROUTING_STORE,
} from '../../stores'
import { withSpinner } from '../../with-spinner'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { DialogStore } from '../../../../set10/store/dialog-store'
import { DIALOG_STORE } from '../../../../set10/store/stores'

export class TopologySettingsStore {
    @observable
    topology: DirectivePrintingTopologyVO
    @observable
    newTopology: boolean = false
    @observable
    originalTopology: DirectivePrintingTopologyVO
    @observable
    validation: FormValidation<DirectivePrintingTopologyVO>

    private topologiesStore: TopologiesStore = getStore(DIRECTIVE_PRINTING_TOPOLOGIES_STORE)
    private routingStore: RouterStore = getStore(ROUTING_STORE)

    @action
    reset = (): void => {
        this.topology = null
        this.originalTopology = null
        this.validation = null
    }

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<DirectivePrintingTopologyVO>(
            this.topology,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        uniqueField(
                            this.topologiesStore.availableTopologies
                                .filter(t => t.id !== this.topology.id)
                                .map(t => t.name)
                        )
                    ]
                }
            ],
            creation
        )
    }

    @action
    editTopology = (
        topology: DirectivePrintingTopologyVO,
        withRedirect: boolean = true,
        creation: boolean = false
    ): void => {
        this.topology = topology
        this.originalTopology = cloneDeep(toJS(topology))
        this.newTopology = !topology.id
        this.createValidation(creation)
        const url = `${PRICE_TAGS}${DIRECTIVE_PRINTING}${TOPOLOGIES}/${topology.id ||
            NEW}`
        if (withRedirect) {
            goTo(url)
        }
    }

    addTopology = (
        existTopologies: DirectivePrintingTopologyVO[],
        withRedirect: boolean = true
    ): void => {
        let topology: DirectivePrintingTopologyVO = createDirectivePrintingTopologyVO(
            {
                name: getUniqueNameWithNumberPostfix(
                    t('directivePrinting.directivePrintingTopology'),
                    existTopologies,
                    'name'
                ),
                description: ''
            }
        )
        this.editTopology(topology, withRedirect, true)
    }

    openTopology = async (ref: string): Promise<void> => {
        let requests: Array<(...args: any[]) => Promise<any>> = []

        if (
            !this.topologiesStore.availableTopologies ||
            this.topologiesStore.availableTopologies.length === 0
        ) {
            // Не можем открыть редактор топологии без списка всех существующих топологий
            requests.push(this.topologiesStore.fetchTopologies)
        }

        if (ref === NEW) {
            requests.push(
                (topologies: DirectivePrintingTopologyVO[]): Promise<any> => {
                    this.addTopology(topologies, false)
                    return Promise.resolve()
                }
            )
            await withSpinner(requests)
        } else {
            try {
                let id: number = Number(ref)

                // Подтягиваем топологию
                requests.push(() => directivePrintingTopologyPresenter.load(id))

                requests.push((topology: DirectivePrintingTopologyVO) => {
                    if (topology) {
                        this.editTopology(topology)
                    } else {
                        goTo(NOT_FOUND)
                    }
                    return Promise.resolve()
                })

                await withSpinner(requests).catch(error =>
                    goTo(NOT_FOUND)
                )
            } catch (error) {
                goTo(NOT_FOUND)
                return Promise.resolve()
            }
        }
    }

    @action
    updateTopology = (changes: Partial<DirectivePrintingTopologyVO>) => {
        this.topology = {
            ...toJS(this.topology),
            ...changes
        }
        this.validation.item = this.topology
    }

    cancelChanges = (): void => {
        goTo(`${PRICE_TAGS}${DIRECTIVE_PRINTING}${TOPOLOGIES}`)
        this.reset()
    }

    saveChanges = async (): Promise<any> => {
        return withSpinner(async () => {
            await directivePrintingTopologyPresenter.save(toJS(this.topology))
            this.routingStore.goBack()
            this.reset()
        })
    }
}

export const topologySettingsRouteHandler: RouteChangeHandler = {
    routeMatcher: /price-tags\/directive-printing\/topologies\/\w+/,
    onEnter: () => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const topologySettingsStore: TopologySettingsStore = getStore(
            DIRECTIVE_PRINTING_TOPOLOGY_SETTINGS_STORE
        )
        const dialogStore: DialogStore = getStore(DIALOG_STORE)

        appBarStore.updateState({
            title: topologySettingsStore.newTopology
                ? t('directivePrinting.topologyCreation')
                : t('directivePrinting.topologyEditing'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                if (!topologySettingsStore.validation.modified) {
                    topologySettingsStore.cancelChanges()
                } else {
                    dialogStore.showDialog({
                        title: t(
                            'directivePrinting.topologiesSaveChangesTitle'
                        ),
                        message: t('directivePrinting.saveChangesMessage'),
                        mode: DIALOG,
                        onYes: topologySettingsStore.cancelChanges,
                        yesLabel: t('common.exit'),
                        noLabel: t('common.cancel')
                    })
                }
            }
        })
    },
    onLeave: () => {
        const topologySettingsStore: TopologySettingsStore = getStore(
            DIRECTIVE_PRINTING_TOPOLOGY_SETTINGS_STORE
        )
        topologySettingsStore.reset()
    }
}
