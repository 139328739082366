import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import { PRODUCT_DETAILS_STORE } from '../../../store/stores'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import moment from 'moment'
import { DatePicker } from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/date-picker/date-picker'

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

interface ProductDateDialogProps {
    productDetailsStore?: ProductDetailsStore
    dateFormat: string
    defaultValue: string
    onSave?: (newValue: string) => void
    onClose?: () => void
}

interface ProductDateDialogState {
    date: Date
}

@inject(PRODUCT_DETAILS_STORE)
@observer
export class ProductDateDialog extends React.Component<ProductDateDialogProps, ProductDateDialogState> {

    constructor(props: ProductDateDialogProps, context: any) {
        super(props, context)

        const momentDate = moment(this.props.defaultValue, this.props.dateFormat)

        if (momentDate.isValid()) {
            this.state = { date: momentDate.toDate() }
        } else {
            this.state = { date: moment(new Date()).toDate() }
        }
    }

    render() {
        const { dateFormat, onSave, onClose } = this.props
        const { date } = this.state

        const withTime = dateFormat === DATE_TIME_FORMAT
        return (
            <Dialog
                id="productGroupsDialog"
                open
                onClose={onClose}
                fullWidth
                maxWidth="xs"
                /**
                 * Если внутри диалоге есть DatePicker обязательно надо делать disableEnforceFocus
                 * Без него диалог будет забирать фокус от timeInput назад и нельзя будет задать время
                 */
                disableEnforceFocus
            >
                <DialogTitle>
                    {withTime ? t('productDetails.generalInfo.dateTimeTitle') : t('productDetails.generalInfo.dateTitle')}
                </DialogTitle>
                <DialogContent>
                    <DatePicker
                        notNullable
                        defaultDate={date}
                        onDateChange={newDate => {
                            if (moment(newDate).isValid()) {
                                this.setState({ date: newDate })
                            }
                        }}
                        withTime={withTime}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            const format = withTime ? DATE_TIME_FORMAT : DATE_FORMAT
                            const stringDate = moment(date).format(format)
                            onSave(stringDate)
                        }}
                        color="primary"
                        id="dialogSaveButton"
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
