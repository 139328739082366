import { action, computed, observable, runInAction, toJS } from 'mobx'
import { cloneDeep, isEqual } from 'lodash'
import { t } from 'i18next'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, SNACKBAR_STORE } from '../stores'
import { eSLConfiguration } from '../../../protocol/set10/esl-configuration'
import { ESLConfigurationVO } from '../../../protocol/set10/set-retail10-commons/set-esl-api/esl-configuration-vo'
import { withSpinner } from '../with-spinner'
import { eSLModule } from '../../../protocol/set10/esl-module'
import { SnackbarStore, SNACK_BAR_SUCCESS } from '../snackbar-store'
import { ALERT } from '../../../components/simple-dialog/simple-dialog'

export class PriceTagsEslConfigurationStore {

    @observable
    originalConfiguration: ESLConfigurationVO = null

    @observable
    editedConfiguration: ESLConfigurationVO = null

    productsForManualUpload: string[] = []

    pollingTimer: any = null
    pollingTimeOut: number = 10 * 1000

    private appStore: AppStore = getStore(APP_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    @computed
    get modified(): boolean {
        return !isEqual(toJS(this.originalConfiguration), toJS(this.editedConfiguration))
    }

    startPolling = (): void => {
        clearTimeout(this.pollingTimer)
        this.pollingTimer = setTimeout(async () => {
            this.fetchConfiguration()
        }, this.pollingTimeOut)
    }

    stopPolling = (): void => {
        clearTimeout(this.pollingTimer)
        this.pollingTimer = null
    }

    fetchConfiguration = async (): Promise<any> => {
        const configuration = await eSLConfiguration.load()
        runInAction(() => {
            this.originalConfiguration = configuration
            this.editedConfiguration = cloneDeep(configuration)

            if (configuration?.invalidateAll) this.startPolling()
        })
    }

    @action
    editConfiguration = (changes: Partial<ESLConfigurationVO>): void => {
        Object.keys(changes).forEach((k: keyof ESLConfigurationVO) => {
            // workflow for https://github.com/microsoft/TypeScript/issues/31663
            (this.editedConfiguration [k] as any) = changes[k]
        })
    }

    saveConfiguration = async (): Promise<void> => {
        await eSLConfiguration.save(toJS(this.editedConfiguration))
        this.snackbarStore.show({
            variant: SNACK_BAR_SUCCESS,
            message: t('common.settingsSaved')
        })
        this.appStore.showDialog({
            mode: ALERT,
            message: t('priceTagsEsl.reloadRequiredMessage')
        })
        // после сохранения запрашиваем с сервера обновленную конфигурацию
        this.fetchConfiguration()
    }

    @action
    cancelConfiguration = (): void => {
        this.editedConfiguration = toJS(this.originalConfiguration)
    }

    invalidateAll = async (): Promise<void> => {
        this.editConfiguration({ invalidateAll: true })
        await eSLConfiguration.save(toJS(this.editedConfiguration))
        this.snackbarStore.show({
            message: t('priceTagsEsl.productsUploadHasLaunched')
        })
        this.fetchConfiguration()
    }

    updateProductsForManualUpload = (products: string): void => {
        if (!products) {
            this.productsForManualUpload = []
            return
        }
        this.productsForManualUpload = products.split(',')
    }

    launchManualUpload = async (): Promise<void> => {
        if (this.productsForManualUpload.length === 0) {
            return Promise.resolve()
        }

        await withSpinner(
            async () => {
                await eSLModule.invalidateItems(this.productsForManualUpload)
                await this.fetchConfiguration()
            }
        )

        this.snackbarStore.show({
            message: t('priceTagsEsl.productsUploadHasLaunched')
        })
    }

    @action
    reset = () => {
        this.originalConfiguration = null
        this.editedConfiguration = null
        this.productsForManualUpload = []
        this.stopPolling()
    }

}
