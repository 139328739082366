import { t } from 'i18next'
import { isEqual, Cancelable, debounce } from 'lodash'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import uuid from 'uuid'
import { DIALOG, ALERT } from '../../../components/simple-dialog/simple-dialog'
import { formatsProductListPresenterLocal } from '../../../protocol/set10/formats-product-list-presenter-local'
import { formatsSizeBindingPresenterLocal } from '../../../protocol/set10/formats-size-binding-presenter-local'
import { priceTagFormatPresenterLocal } from '../../../protocol/set10/price-tag-format-presenter-local'
import { printersManagerLocal } from '../../../protocol/set10/printers-manager-local'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import { PriceTagTemplateVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { createFormatsCellVO, FormatsCellVO } from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/formats-cell-vo'
import { FormatsProductListVO } from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/formats-product-list-vo'
import { FormatsSizeBindingVO } from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/formats-size-binding-vo'
import { PriceTagFormatType, REGULAR, ACTION } from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-type'
// tslint:disable-next-line
import { createPriceTagFormatVO, PriceTagFormatVO } from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-vo'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { DEFAULT_MAX_LENGTH, fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import { getUniqueNameWithNumberPostfix } from '../../../utils/name-util'
import { BoundProductEntity, PRODUCT } from '../../components/product-entities-binder/product-entities-binder'
import { FORMAT_MATRIX_SETTINGS, NEW as ROUTE_NEW, PRICE_TAG_FORMATS, PRICE_TAGS } from '../../core/app-routes'
import { deserializePriceTagXML } from '../../core/price-tags/serialize-utils'
import { XMLPriceTag } from '../../core/price-tags/xml/xml-price-tag'
import { NEW } from '../../core/values'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { AppStore } from '../app-store'
import {
    APP_BAR_STORE,
    APP_STORE,
    PRICE_TAG_FORMAT_MATRICES_STORE,
    PRICE_TAG_FORMAT_SETTINGS_STORE,
    PRICE_TAGS_TEMPLATES_STORE,
    USER_STORE
} from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import {
    FORMAT_MATRIX_TAB_INDEX_CENTRUM,
    FORMAT_MATRIX_TAB_INDEX_RETAIL,
    PriceTagFormatSettingsStore,
    RETAIL_TOPOLOGY_ID
} from './price-tag-format-settings-store'
import { PriceTagsTemplatesStore } from './price-tags-templates-store'
import { mnemonicsPropertiesFacadeLocal } from '../../../protocol/set10/mnemonics-properties-facade-local'
import { MnemonicGroupVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-group-vo'
import { MnemonicPropertiesVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'

export const PREVIEW_TIMEOUT: number = 1000

export class PriceTagFormatMatricesStore {
    /* priceTagFormats, lightweightProductLists, lightweightFormatSizes - намеренно undefined для того,
    чтобы различить состояние до запроса и состояние после запроса, вернувшего пустой список */
    @observable
    priceTagFormats: PriceTagFormatVO[]

    @observable
    lightweightProductLists: FormatsProductListVO[]

    @observable
    lightweightFormatSizes: FormatsSizeBindingVO[]

    // Экран редактирования матрицы форматов
    @observable
    currentFormatMatrix: PriceTagFormatVO

    @observable
    originalCurrentFormatMatrix: PriceTagFormatVO

    @observable
    validation: FormValidation<PriceTagFormatVO>

    @observable
    priceTagsTemplates: Map<string, PriceTagTemplateVO> = new Map<string, PriceTagTemplateVO>()

    @observable
    priceTagsXMLData: Map<string, XMLPriceTag> = new Map<string, XMLPriceTag>()

    // Добавление шаблонов в ячейку
    @observable
    currentCell: FormatsCellVO

    // Превью в диалоге добавления шаблонов
    @observable
    currentTemplate: PriceTagTemplateVO

    @observable
    currentTemplateXML: XMLPriceTag

    @observable
    nameFilter: string

    // Диалог привязки продуктов
    @observable
    boundFormatMatrix: PriceTagFormatVO

    @observable
    boundEntities: BoundProductEntity[]

    @observable
    originalBoundEntities: BoundProductEntity[]

    @observable
    mnemonicGroups: MnemonicGroupVO[] = []

    debouncedShowTemplatePreview: ((template: PriceTagTemplateVO) => void) & Cancelable =
        debounce(template => this.showTemplatePreview(template), PREVIEW_TIMEOUT)

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private priceTagFormatSettingsStore: PriceTagFormatSettingsStore = getStore(PRICE_TAG_FORMAT_SETTINGS_STORE)
    private priceTagsTemplatesStore: PriceTagsTemplatesStore = getStore(PRICE_TAGS_TEMPLATES_STORE)

    @computed
    get sizesToListsMatrix(): FormatsCellVO[][] {
        if (!this.currentFormatMatrix
            || !this.currentFormatMatrix.cells
            || !this.lightweightFormatSizes
            || !this.lightweightProductLists
        ) return []

        const sizesToListsMatrix: FormatsCellVO[][] = []

        this.lightweightFormatSizes.forEach(size => {
            const matrixRow: FormatsCellVO[] = []

            this.lightweightProductLists.forEach(list => {
                matrixRow.push(
                    toJS(this.currentFormatMatrix.cells.find(cell => cell.listId === list.id && cell.sizeId === size.id))
                )
            })

            sizesToListsMatrix.push(matrixRow)
        })

        return sizesToListsMatrix
    }

    @computed
    get hasFilledCellsInMatrix(): boolean {
        return this.sizesToListsMatrix.some(row => {
            return row.some(cell => {
                return cell.priceTags.length > 0
            })
        })
    }

    // TODO используется только в TemplateAdditionDialog
    // нужно перенести вызов printersManagerLocal.getPriceTagTemplates в компонент
    // тогда пропадёт зависимость от priceTagsTemplatesStore
    @computed
    get currentCellAvailableTemplates(): PriceTagTemplateVO[] {
        if (!this.currentCell) {
            return []
        }

        const allPriceTags: PriceTagTemplateVO[] = toJS(this.priceTagsTemplatesStore.priceTags) || []
        return allPriceTags.filter(priceTag => {
            // Фильтруем
            if (this.nameFilter && !priceTag.name.toLowerCase().includes(this.nameFilter.toLowerCase())) return false
            // Акционные ценники показываются только в акционных форматах, регулярные аналогично

            if (this.currentFormatMatrix.type === ACTION && !priceTag.actionable) {
                return false
            }

            if (this.currentFormatMatrix.type === ACTION && !priceTag.extCode) {
                return false
            }

            if (this.currentFormatMatrix.type === REGULAR && priceTag.actionable) {
                return false
            }

            // Удаляем уже добавленные
            if (this.currentCell.priceTags.includes(priceTag.guid)) return false
            // Удаляем не подходящие по размеру
            const cellSizeBindings = this.lightweightFormatSizes.find(size => size.id === this.currentCell.sizeId)
            if (!cellSizeBindings
                || cellSizeBindings.maxWidth < priceTag.width
                || cellSizeBindings.maxHeight < priceTag.height
            ) return false

            return true
        })
    }

    @computed
    get boundEntitiesChanged(): boolean {
        if (!this.boundEntities || !this.originalBoundEntities) return false

        return !isEqual(toJS(this.boundEntities), toJS(this.originalBoundEntities))
    }

    @computed
    get mnemonics(): MnemonicPropertiesVO[] {
        return this.mnemonicGroups.reduce((prevMnems: MnemonicPropertiesVO[], currentGroup: MnemonicGroupVO) => {
            return [
                ...prevMnems,
                ...currentGroup.mnemonicProperties
            ]
        }, [])
    }

    fetchMnemonicGroups = async (): Promise<void> => {
        const mnemonicGroups = await mnemonicsPropertiesFacadeLocal.getAllByGroups({ useCache: true }) || []

        runInAction(() => {
            this.mnemonicGroups = mnemonicGroups
        })
    }

    createEmptyFormatCellVO = (
        topologyId: number,
        formatSize: FormatsSizeBindingVO,
        productList: FormatsProductListVO
    ): FormatsCellVO => {
        return createFormatsCellVO({
            id: -1,
            formatId: topologyId,
            sizeId: formatSize.id,
            sizeUUID: formatSize.uuid,
            listId: productList.id,
            listUUID: productList.uuid,
            priceTags: [],
        })
    }

    // TODO все утильные методы нужно вынести из стора - он и так очень плохо читается
    createEmptyMatrixCells = (
        topologyId: number,
        formatSizes: FormatsSizeBindingVO[],
        productLists: FormatsProductListVO[]
    ): FormatsCellVO[] => {
        const cells: FormatsCellVO[] = []
        productLists.forEach(list => {
            formatSizes.forEach(size => {
                cells.push(this.createEmptyFormatCellVO(topologyId, size, list))
            })
        })

        return cells
    }

    createEmptyFormatMatrix = (
        topologyId: number,
        formatSizes: FormatsSizeBindingVO[],
        productLists: FormatsProductListVO[]
    ): PriceTagFormatVO => {
        return createPriceTagFormatVO({
            id: -1,
            name: getUniqueNameWithNumberPostfix(
                t('priceTagsFormats.formatMatrix.newPriceTagFormat'),
                this.priceTagFormats,
                'name'
            ),
            topologyId,
            // В старой версии генерируется в upperCase
            uuid: uuid().toUpperCase(),
            type: REGULAR,
            cells: this.createEmptyMatrixCells(topologyId, formatSizes, productLists),
        })
    }

    fetchPriceTagFormats = (): Promise<PriceTagFormatVO[]> => {
        return withSpinner(
            priceTagFormatPresenterLocal.load(this.priceTagFormatSettingsStore.formatTopology.id)
        )
    }

    fetchLightweightProductLists = (): Promise<FormatsProductListVO[]> => {
        return withSpinner(
            formatsProductListPresenterLocal.loadAllLightweight(this.priceTagFormatSettingsStore.formatTopology.id)
        )
    }

    fetchLightweightFormatSizes = (): Promise<FormatsSizeBindingVO[]> => {
        return withSpinner(
            formatsSizeBindingPresenterLocal.loadAllLightweight(this.priceTagFormatSettingsStore.formatTopology.id)
        )
    }

    fetchAll = async (): Promise<void> => {
        const [priceTagFormats, productLists, formatSizes] = await Promise.all([
            this.fetchPriceTagFormats(),
            this.fetchLightweightProductLists(),
            this.fetchLightweightFormatSizes(),
        ])

        runInAction('fetchAll', () => {
            this.priceTagFormats = priceTagFormats || []
            this.lightweightProductLists = productLists || []
            this.lightweightFormatSizes = formatSizes || []
        })
    }

    fetchFormatMatrixById = async (formatTopologyId: string, formatMatrixId: string): Promise<void> => {
        if (this.appStore.isCentrum) {
            // Грузим топологию формата (там есть свой обработчик ошибок)
            await this.priceTagFormatSettingsStore.editFormatTopology(Number(formatTopologyId))
        } else {
            await this.priceTagFormatSettingsStore.editRetailTopology()
        }

        if (this.priceTagFormatSettingsStore.formatTopology) {
            // Грузим все необходимые данные
            await this.fetchAll()

            if (formatMatrixId === NEW) {
                this.addFormatMatrix()
            } else {
                this.openFormatMatrix(this.priceTagFormats.find(format => format.id === Number(formatMatrixId)))
            }
        }
    }

    // возвращает uuids, по которым не найдены шаблоны или найденые шаблоны с deleted == true
    fetchTemplatesByUUIDs = async (uuids: string[]): Promise<string[]> => {
        if (!uuids || uuids.length === 0) return

        const priceTagTemplates: PriceTagTemplateVO[] = await withSpinner(
            Promise.all(
                uuids.map(uuid => printersManagerLocal.getPriceTagTemplateByGUID(uuid))
            )
        )
        const priceTagXMLs: XMLPriceTag[] = await withSpinner(
            Promise.all(
                priceTagTemplates.map(priceTagTemplate => priceTagTemplate
                    ? deserializePriceTagXML(priceTagTemplate.data)
                    : undefined
                )
            )
        )

        const uuidsWithNotFoundOrDeletedPriceTags: string[] = []

        runInAction('fetchTemplateByUUID', () => {
            uuids.forEach((uuid, index) => {
                if (!priceTagTemplates[index] || priceTagTemplates[index].deleted) {
                    uuidsWithNotFoundOrDeletedPriceTags.push(uuid)
                    return
                }
                this.priceTagsTemplates.set(uuid, priceTagTemplates[index])
                this.priceTagsXMLData.set(uuid, priceTagXMLs[index])
            })
        })

        return uuidsWithNotFoundOrDeletedPriceTags
    }

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<PriceTagFormatVO>(
            this.currentFormatMatrix,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        fieldLengthValidator({
                            max: DEFAULT_MAX_LENGTH
                        }),
                        uniqueField(this.priceTagFormats
                            .filter(f => f.id !== this.currentFormatMatrix.id)
                            .map(f => f.name))
                    ]
                },
                {
                    field: 'externalCode',
                    rules: [
                        requiredField,
                        fieldLengthValidator({
                            max: DEFAULT_MAX_LENGTH
                        }),
                        uniqueField(this.priceTagFormats
                            .filter(f => f.id !== this.currentFormatMatrix.id)
                            .map(f => f.externalCode))
                    ]
                },
                {
                    field: 'description',
                    rules: [
                        fieldLengthValidator({
                            max: DEFAULT_MAX_LENGTH
                        }),
                    ]
                },
            ],
            creation
        )
    }

    // TODO разбить на методы
    @action
    openFormatMatrix = async (formatMatrix: PriceTagFormatVO): Promise<void> => {
        if (!formatMatrix
            || !this.lightweightFormatSizes
            || this.lightweightFormatSizes.length === 0
            || !this.lightweightProductLists
            || this.lightweightProductLists.length === 0
        ) {
            // Если среди матриц форматов нет матрицы с заданным id, возвращаемся на экран топологии формата
            if (this.priceTagFormatSettingsStore.formatTopology) {
                this.goToFormatTopologySettings(String(this.priceTagFormatSettingsStore.formatTopology.id))
            } else {
                this.priceTagFormatSettingsStore.goBack()
            }

            this.appStore.showSnackbar({
                message: t('priceTagsFormats.formatMatrix.fetchError')
            })
            return
        }

        this.currentFormatMatrix = formatMatrix

        /* TODO В ответе на запрос priceTagFormatPresenterLocal.load приходят некорректные данные:
        количество и состав PriceTagFormatVO.cells корректно изменяется при удалении списка товаров,
        но не изменяется при добавлении нового списка */
        const correctedCells: FormatsCellVO[] = []
        this.lightweightFormatSizes.forEach(size => {
            this.lightweightProductLists.forEach(list => {
                const existingCell = toJS(this.currentFormatMatrix.cells.find(cell => cell.listId === list.id && cell.sizeId === size.id))

                if (!existingCell) {
                    correctedCells.push(
                        this.createEmptyFormatCellVO(this.currentFormatMatrix.topologyId, size, list)
                    )
                } else {
                    correctedCells.push(existingCell)
                }
            })
        })
        this.currentFormatMatrix.cells = correctedCells

        this.originalCurrentFormatMatrix = toJS(formatMatrix)
        this.createValidation()

        // Формируем список uuid всех используемых на экране шаблонов
        const priceTags = []
        this.currentFormatMatrix.cells.forEach(cell => {
            cell.priceTags.forEach(priceTag => {
                if (!priceTags.includes(priceTag)) {
                    priceTags.push(priceTag)
                }
            })
        })
        const uuidsWithNotFoundOrDeletedPriceTags = await this.fetchTemplatesByUUIDs(priceTags) || []
        if (uuidsWithNotFoundOrDeletedPriceTags.length > 0) {
            this.handleUuidsWithNotFoundPriceTags(uuidsWithNotFoundOrDeletedPriceTags)
        }

        // Запрашиваем леговесный список шаблонов
        await this.priceTagsTemplatesStore.fetchPriceTags()

        this.goToFormatMatrixSettings(String(formatMatrix.topologyId), `/${formatMatrix.id}`)
    }

    handleUuidsWithNotFoundPriceTags = (uuids: string[]): void => {
        this.appStore.showDialog({
            title: t('priceTagsFormats.formatMatrix.uuidsWithNotFoundPriceTagsWarningTitle'),
            message: t('priceTagsFormats.formatMatrix.uuidsWithNotFoundPriceTagsWarningMessage', {
                uuids: uuids.join('\n')
            }),
            mode: ALERT,
            okLabel: t('common.yes'),
            onOk: () => {
                const newCells = this.currentFormatMatrix.cells.map(cell => {
                    return {
                        ...cell,
                        priceTags: cell.priceTags.filter(priceTagUuid => {
                            return !uuids.includes(priceTagUuid)
                        })
                    }
                })

                this.updateFormatMatrix({ cells: newCells })
                this.saveFormatMatrix()
            }
        })
    }

    @action
    addFormatMatrix = async (): Promise<void> => {
        this.currentFormatMatrix = this.createEmptyFormatMatrix(
            this.priceTagFormatSettingsStore.formatTopology.id,
            this.lightweightFormatSizes,
            this.lightweightProductLists
        )
        this.createValidation(true)

        // Запрашиваем леговесный список шаблонов
        await this.priceTagsTemplatesStore.fetchPriceTags()

        this.goToFormatMatrixSettings(String(this.currentFormatMatrix.topologyId), ROUTE_NEW)
    }

    /**
     * Переход на экран редактирования матрицы
     * @param topologyId id топологии форматов
     * @param matrixIdRoute id матрицы, начинается со слэша
     */
    goToFormatMatrixSettings = (topologyId: string, matrixIdRoute: string) => {
        if (this.appStore.isCentrum) {
            goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}/${topologyId}${FORMAT_MATRIX_SETTINGS}${matrixIdRoute}`)
        } else {
            goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}${FORMAT_MATRIX_SETTINGS}${matrixIdRoute}`)
        }
    }

    @action
    updateFormatMatrix = (changes: Partial<PriceTagFormatVO>): void => {
        Object.keys(changes).forEach(key => {
            this.currentFormatMatrix[key] = changes[key]
        })
    }

    @action
    changeFormatType = (formatType: PriceTagFormatType): void => {
        if (this.currentFormatMatrix.type !== formatType) {
            const changes: Partial<PriceTagFormatVO> = {
                type: formatType,
                defaultFormat: false
            }

            // Обнулим ячейки, если необходимо
            if (this.hasFilledCellsInMatrix) {
                changes.cells = this.createEmptyMatrixCells(
                    this.currentFormatMatrix.topologyId,
                    this.lightweightFormatSizes,
                    this.lightweightProductLists,
                )
            }

            this.updateFormatMatrix(changes)
        }
    }

    requestFormatTypeChange = (formatType: PriceTagFormatType): void => {
        if (this.hasFilledCellsInMatrix) {
            this.appStore.showDialog({
                title: t('priceTagsFormats.formatMatrix.formatTypeChangeTitle'),
                message: t('priceTagsFormats.formatMatrix.formatTypeChangeMessage'),
                mode: DIALOG,
                onYes: () => this.changeFormatType(formatType),
            })
        } else {
            this.changeFormatType(formatType)
        }
    }

    @action
    editMatrixCell = (cell: FormatsCellVO): void => {
        this.currentCell = cell
        this.nameFilter = undefined
    }

    @action
    updateNameFilter = (value: string): void => {
        this.nameFilter = value
    }

    @action
    closeMatrixCell = (): void => {
        this.currentCell = undefined
        this.nameFilter = undefined
        this.hideTemplatePreview()
    }

    @action
    showTemplatePreview = async (template: PriceTagTemplateVO): Promise<void> => {
        if (!template) return

        if (!this.priceTagsTemplates.has(template.guid)) {
            await this.fetchTemplatesByUUIDs([template.guid])
        }

        runInAction('showTemplatePreview', () => {
            this.currentTemplate = this.priceTagsTemplates.get(template.guid)
            this.currentTemplateXML = this.priceTagsXMLData.get(template.guid)
        })
    }

    @action
    hideTemplatePreview = (): void => {
        this.debouncedShowTemplatePreview.cancel()
        this.currentTemplate = undefined
        this.currentTemplateXML = undefined
    }

    @action
    addTemplateToMatrixCell = (uuid: string, cell: FormatsCellVO): void => {
        if (!uuid || !cell) return

        // Может быть несколько ячеек с id === -1, поэтому ищем по id товарного списка и id размера
        const existingCell = this.currentFormatMatrix.cells.find(c => c.listId === cell.listId && c.sizeId === cell.sizeId)

        if (existingCell) {
            const existingPriceTagIndex = existingCell.priceTags && existingCell.priceTags.findIndex(p => p === uuid)
            if (existingPriceTagIndex === -1) {
                if (!this.priceTagsTemplates.has(uuid)) {
                    // Запрашиваем превью с сервера для отображения в матрице
                    this.fetchTemplatesByUUIDs([uuid])
                }
                existingCell.priceTags.push(uuid)
            }
        }

        // TODO избавиться от хака
        // Оповещаем validation и sizesToListsMatrix об изменениях
        this.currentFormatMatrix.cells = toJS(this.currentFormatMatrix.cells)
    }

    @action
    removePriceTagFromCell = (uuid: string, cell: FormatsCellVO): void => {
        if (!uuid || !cell) return

        // Может быть несколько ячеек с id === -1, поэтому ищем по id товарного списка и id размера
        const existingCell = this.currentFormatMatrix.cells.find(c => c.listId === cell.listId && c.sizeId === cell.sizeId)

        if (existingCell) {
            const existingPriceTagIndex = existingCell.priceTags && existingCell.priceTags.findIndex(p => p === uuid)
            if (existingPriceTagIndex !== -1) {
                existingCell.priceTags.splice(existingPriceTagIndex, 1)
            }
        }

        // Оповещаем validation и sizesToListsMatrix об изменениях
        this.currentFormatMatrix.cells = toJS(this.currentFormatMatrix.cells)
    }

    @action
    closeFormatMatrix = (): void => {
        const { topologyId } = this.currentFormatMatrix
        this.goToFormatTopologySettings(String(topologyId))
        this.currentFormatMatrix = undefined
    }

    @action
    saveFormatMatrix = async (): Promise<void> => {
        const { name } = this.currentFormatMatrix
        await priceTagFormatPresenterLocal.save(toJS(this.currentFormatMatrix))

        this.appStore.showSnackbar({
            message: this.currentFormatMatrix.id === -1
                ? t('priceTagsFormats.formatMatrix.creationSuccess', { name })
                : t('priceTagsFormats.formatMatrix.saveSuccess', { name })
        })

        this.closeFormatMatrix()
    }

    @action
    deleteFormatMatrix = async (formatMatrix: PriceTagFormatVO): Promise<void> => {
        if (!formatMatrix) return

        await priceTagFormatPresenterLocal.delete(toJS(formatMatrix))

        const priceTagFormats = await this.fetchPriceTagFormats()

        runInAction('deleteFormatMatrix', () => {
            this.priceTagFormats = priceTagFormats
        })

        this.appStore.showSnackbar({
            message: t('priceTagsFormats.formatMatrix.deletionSuccess', { name: formatMatrix.name })
        })
    }

    requestFormatMatrixDeletion = (formatMatrix: PriceTagFormatVO): void => {
        if (!formatMatrix) return

        if (formatMatrix.defaultFormat) {
            this.appStore.showDialog({
                title: t('priceTagsFormats.formatMatrix.formatMatrixDeletionTitle'),
                message: t('priceTagsFormats.formatMatrix.formatMatrixDeletionMessage'),
                mode: DIALOG,
                onYes: () => this.deleteFormatMatrix(formatMatrix),
            })
        } else {
            this.deleteFormatMatrix(formatMatrix)
        }
    }

    @action
    openProductsToFormatMatrixBindingDialog = async (formatMatrix: PriceTagFormatVO): Promise<void> => {
        if (!formatMatrix) return

        const { foundProducts = [] } = await withSpinner(
            productManagerFinderLocal.getSimpleProductsByCodes1(this.userStore.session, formatMatrix.markings || [])
        )

        runInAction('bindProductsToFormatMatrix', () => {
            this.boundFormatMatrix = formatMatrix
            this.boundEntities = foundProducts.map(product => ({
                label: product.name,
                code: product.code,
                type: PRODUCT,
                value: product.code
            }))
            this.originalBoundEntities = toJS(this.boundEntities)
        })
    }

    @action
    closeProductsToFormatMatrixBindingDialog = (): void => {
        this.boundFormatMatrix = undefined
        this.boundEntities = undefined
        this.originalBoundEntities = undefined
    }

    @action
    updateBoundEntities = (entities: BoundProductEntity[]): void => {
        this.boundEntities = toJS(entities)
    }

    saveBoundProductsToFormatMatrx = async (): Promise<void> => {
        if (this.boundEntitiesChanged) {
            await priceTagFormatPresenterLocal.save({
                ...toJS(this.boundFormatMatrix),
                markings: this.boundEntities.map(e => e.code)
            })

            // Обновляем данные матриц форматов
            await this.fetchAll()

            this.appStore.showSnackbar({
                message: t('priceTagsFormats.formatMatrix.bindingProductsSuccess')
            })

            this.closeProductsToFormatMatrixBindingDialog()
        }
    }

    goToFormatTopologySettings = (formatTopologyId: string): void => {
        if (!formatTopologyId) {
            this.priceTagFormatSettingsStore.goBack()
            return
        }

        // Выбираем вкладку "Матрица форматов" и переходим на экран настройки топологии
        if (this.appStore.isCentrum) {
            this.priceTagFormatSettingsStore.setLastUsedTabIndex(FORMAT_MATRIX_TAB_INDEX_CENTRUM)
            goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}/${formatTopologyId}`)
        } else {
            this.priceTagFormatSettingsStore.setLastUsedTabIndex(FORMAT_MATRIX_TAB_INDEX_RETAIL)
            goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}`)
        }
    }

    @action
    reset = (): void => {
        this.priceTagFormats = undefined
        this.lightweightProductLists = undefined
        this.lightweightFormatSizes = undefined

        this.currentFormatMatrix = undefined
        this.originalCurrentFormatMatrix = undefined
        this.validation = undefined
        this.priceTagsTemplates = new Map<string, PriceTagTemplateVO>()
        this.priceTagsXMLData = new Map<string, XMLPriceTag>()
        this.currentCell = undefined
        this.currentTemplate = undefined
        this.currentTemplateXML = undefined
        this.nameFilter = undefined
        this.debouncedShowTemplatePreview.cancel()

        this.boundFormatMatrix = undefined
        this.boundEntities = undefined
        this.originalBoundEntities = undefined
        this.mnemonicGroups = []
    }
}

const PriceTagMatricesOnEnter = (formatTopologyId: string, matrixId: string) => {
    const appStore: AppStore = getStore(APP_STORE)
    const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    const priceTagFormatMatricesStore: PriceTagFormatMatricesStore = getStore(PRICE_TAG_FORMAT_MATRICES_STORE)

    appBarStore.updateState({
        title: matrixId === NEW
            ? t('priceTagsFormats.formatMatrix.createMatrixTitle')
            : t(`priceTagsFormats.formatMatrix.editMatrixTitle`),
        leftIcon: LEFT_ARROW,
        onLeftIconClick: () => {
            if (priceTagFormatMatricesStore.validation.modified) {
                appStore.showDialog({
                    title: t('priceTagsFormats.formatMatrix.exitWithoutSavingTitle'),
                    message: t('priceTagsFormats.formatMatrix.exitWithoutSavingMessage'),
                    mode: DIALOG,
                    onYes: () => priceTagFormatMatricesStore.goToFormatTopologySettings(formatTopologyId),
                    yesLabel: t('common.exit'),
                    noLabel: t('common.cancel')
                })
            } else {
                priceTagFormatMatricesStore.goToFormatTopologySettings(formatTopologyId)
            }
        }
    })
}

/**
 * Обработчик для экрана редактирования матрицы формата
 */
export const PRICE_TAG_MATRICES_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${PRICE_TAGS}${PRICE_TAG_FORMATS}/([\\w-]+)${FORMAT_MATRIX_SETTINGS}/([\\w-]+)/?$`),
    onEnter: (newRoute: string) => {
        const matchNewRoute = newRoute.match(PRICE_TAG_MATRICES_SETTINGS_ROUTING_HANDLER.routeMatcher)
        const formatTopologyId = matchNewRoute[1]
        const matrixId = matchNewRoute[2]

        PriceTagMatricesOnEnter(formatTopologyId, matrixId)
    }
}

/**
 * Обработчик для экрана редактирования матрицы формата
 */
export const PRICE_TAG_MATRICES_SETTINGS_RETAIL_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${PRICE_TAGS}${PRICE_TAG_FORMATS}${FORMAT_MATRIX_SETTINGS}/([\\w-]+)/?$`),
    onEnter: (newRoute: string) => {
        const matchNewRoute = newRoute.match(PRICE_TAG_MATRICES_SETTINGS_RETAIL_ROUTING_HANDLER.routeMatcher)
        const matrixId = matchNewRoute[1]

        PriceTagMatricesOnEnter(String(RETAIL_TOPOLOGY_ID), matrixId)
    }
}
