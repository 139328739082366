import { parseString, Builder, OptionsV2 } from 'xml2js'
import { parseBooleans } from 'xml2js/lib/processors'
import { NCR_XR7_MODEL, KeyboardMapVo, KeyboardButtonStyle } from './keyboard-map-vo'
import {
    KeyboardKbdVo, KBD_ALPHA_NUMERIC, KBD_FUNCTION, KBD_CONTROL,
    KBD_PAYMENT, KBD_SALE_GROUP, KBD_MANUAL_ADV_ACTION, KBD_GOODS
} from './keyboard-kbd-vo'
import { observableIsArray } from '../../../utils/mobx-util'
import { Rect } from '../../../utils/math/geom-util'
import { isNil } from 'lodash'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { UserStore } from '../../store/user-store'
import { getStore } from '../../store/stores-repository'
import { USER_STORE } from '../../store/stores'

const ATTR_KEY: string = '$'
const CHILD_KEY: string = '$$'

const ARRAYS = [
    //kbd
    'ScanCode', 'position', KBD_ALPHA_NUMERIC, KBD_FUNCTION, KBD_CONTROL,
    KBD_PAYMENT, KBD_SALE_GROUP, KBD_MANUAL_ADV_ACTION, KBD_GOODS,
    //map
    'button',
]

const SIMPLE_TYPES = [
    'string',
    'number',
    'boolean'
]

const OUTLINE_BACKGROUND_1 = 'rgba(100, 242, 74, 0.4)'
const OUTLINE_BORDER_1 = 'rgba(100, 242, 74, 0.9)'
const OUTLINE_BACKGROUND_2 = 'rgba(242, 153, 74, 0.24)'
const OUTLINE_BORDER_2 = 'rgba(242, 153, 74, 0.8)'

/**
 * Десериализирует XML-строку в KeyboardMapVo
 */
export async function Xml2Js(xml: string): Promise<any | Error> {
    return new Promise((resolve, reject) => {
        const parserOptions: OptionsV2 = {
            attrkey: ATTR_KEY,
            childkey: CHILD_KEY,
            emptyTag: null,
            explicitArray: false,
            mergeAttrs: true,
            explicitChildren: false,
            preserveChildrenOrder: false,
            valueProcessors: [
                parseBooleans,
            ],
            attrValueProcessors: [
                parseBooleans,
            ]
        }

        parseString(xml, parserOptions, (error: Error, data: any) => {
            if (error) {
                return reject(error)
            }
            // map и kbd вложены в объект с одноимённым ключом
            const kbdData = data[Object.keys(data)[0]]

            const updateData = createArrays(kbdData)

            resolve({
                [Object.keys(data)[0]]: updateData
            })
        })
    })
}

function createArrays(data: any): any {
    return Object.keys(data).reduce((acc, key) => {
        if (SIMPLE_TYPES.includes(typeof data[key])) {
            acc[key] = data[key]
            return acc
        }

        if (ARRAYS.includes(key) && !observableIsArray(data[key])) {
            acc[key] = [createArrays(data[key])]
            return acc
        }

        if (ARRAYS.includes(key) && observableIsArray(data[key])) {
            acc[key] = data[key].map(createArrays)
            return acc
        }

        acc[key] = data[key] ? createArrays(data[key]) : data[key]

        return acc
    }, {})
}

/**
 * Сериализирует структуру KeyboardMapVo в XML
 */
export function keyboardMap2Xml(keyboardMapVo: KeyboardMapVo): any {
    const builder = new Builder({
        attrkey: ATTR_KEY,
        xmldec: {
            version: '1.0',
            encoding: 'UTF-8',
        },
    })

    return builder.buildObject(prepareKeyboardMap(keyboardMapVo))
}

/**
 * Сериализирует структуру KeyboardKbdVo в XML
 */
export async function keyboardKbd2Xml(keyboardKbdVo: KeyboardKbdVo): Promise<any> {
    const builder = new Builder({
        attrkey: ATTR_KEY,
        xmldec: {
            version: '1.0',
            encoding: 'UTF-8',
        },
    })
    const preparedKbd = await prepareKbd(keyboardKbdVo)
    return builder.buildObject(preparedKbd)
}

function prepareKeyboardMap(keyboardMapVo: KeyboardMapVo): any {
    const {
        buttons,
        description,
        keyposition,
        name,
        layout,
        model,
        ...options
    } = keyboardMapVo.keyboard

    const {
        main,
        template,
        ...buttonsAttr
    } = buttons

    const {
        button,
        ...mainAttr
    } =  main

    const buttonAttr = button.map(i => {
        return {[ATTR_KEY]: {...i}}
    })

    const {
        button: templateButton,
        ...templateAttr
    } = template

    const templateButtonAttr = templateButton.map(i => {
        return {[ATTR_KEY]: {...i}}
    })

    const preparedOptions = Object.keys(options).map(type => {
        const {
            ...attr
        } = options[type]
        return {
            option: {
                [ATTR_KEY]: {...attr}
            }
        }
    })

    return {
        keyboard: {
            [ATTR_KEY]: {
                name,
                layout,
                model,
            },
            description,
            keyposition,
            buttons: {
                [ATTR_KEY]: buttonsAttr,
                main: {
                    [ATTR_KEY]: mainAttr,
                    button: buttonAttr,
                },
                template: {
                    button: templateButtonAttr,
                    [ATTR_KEY]: templateAttr
                },
            },
            ...preparedOptions
        }
    }
}

const getSaleGroupNameByCode = async (code: string): Promise<string> => {
    const userStore: UserStore = getStore<UserStore>(USER_STORE)
    const saleGroup = await iProductsManagerLocal.getSimpleSalesGroupByCode(
        userStore.session,
        String(code),
    )
    return saleGroup.name
}

async function prepareKbd(keyboardKbdVo: KeyboardKbdVo): Promise<any> {
    const {
        keyLockMap,
        Model,
        CountX,
        CountY,
        ...keyboardButtons
    } = keyboardKbdVo['Keyboard-data']

    const keyLockMapPositionAttr = keyLockMap
        ? keyLockMap.position && keyLockMap.position.map(i => ({[ATTR_KEY]: {...i}}))
        : undefined

    // SFM-1025 Типы кнопок должны начинаться с `kbd`
    const buttonTypes = Object.keys(keyboardButtons).filter(key => key.match(/^kbd/))
    const preparedButtons = await buttonTypes.reduce(async (acc, type) => {
        const buff = await acc
        const buttons = observableIsArray(keyboardButtons[type]) ? keyboardButtons[type] : [keyboardButtons[type]]
        buff[type] = await Promise.all(buttons.map(async button => {
            const {
                ScanCode,
                GoodsCode,
                AdvActionGuid,
                SaleGroupCode,
                ...buttonData
            } = button
            const scanCodeArr = observableIsArray(ScanCode) ? ScanCode : [ScanCode]

            const buttonResult = {
                ScanCode: scanCodeArr.map(code => {
                    const {
                        X,
                        x,
                        Y,
                        y,
                        ...scanCodeData
                    } = code

                    return {
                        [ATTR_KEY]: {X: X || x, Y: Y || y},
                        ...scanCodeData
                    }
                }),
                ...buttonData
            }

            if (GoodsCode) {
                const { name, ...otherGoods } =  GoodsCode
                buttonResult.GoodsCode = {
                    [ATTR_KEY]: { name },
                    ...otherGoods
                }
            }

            if (AdvActionGuid) {
                const { name, ...otherAction } =  AdvActionGuid
                buttonResult.AdvActionGuid = {
                    [ATTR_KEY]: { name },
                    ...otherAction
                }
            }

            if (SaleGroupCode) {
                let { name, ...otherSale } =  SaleGroupCode
                let code = null

                if (!name) {
                    name = await getSaleGroupNameByCode(SaleGroupCode)
                    code = SaleGroupCode
                }

                if (code) {
                    buttonResult.SaleGroupCode = {
                        [ATTR_KEY]: { name },
                        _: code,
                    }
                } else {
                    buttonResult.SaleGroupCode = {
                        [ATTR_KEY]: {name},
                        ...otherSale,
                    }
                }
            }

            return buttonResult
        }))

        return buff
    }, Promise.resolve({}))

    let attributeValue: any = { Model }
    if (!isNil(CountX)) attributeValue.CountX = CountX
    if (!isNil(CountY)) attributeValue.CountY = CountY

    return {
        'Keyboard-data': {
            [ATTR_KEY]: attributeValue,
            // У клавиатур без ключей здесь строка 'null' в XML
            keyLockMap:  keyLockMapPositionAttr ? {
                position: keyLockMapPositionAttr
            } : 'null',
            ...preparedButtons
        }
    }
}

export function isXml(input: string): boolean {
    return input.substr(0, 5) === '<?xml'
}

export async function fixXmlWithoutSpaces(settings: string): Promise<any> {
    // Если между аргументами нет пробела - добавляем его
    try {
        let fixedSettings = settings.replace(
            /([^=])\'(\w)/g,
            '$1\' $2'
        )
        fixedSettings = fixedSettings.replace(
            /([^=])"(\w)/g,
            '$1" $2'
        )
        const parsedSetting = await Xml2Js(fixedSettings)
        return parsedSetting
    } catch (err) {
        return null
    }
}

export function getOutlineStyle(activeCell: Rect, activeTemplate: KeyboardButtonTemplate, blocked: boolean): React.CSSProperties {
    if (!activeCell) return { display: 'none' }
    return {
        left: activeCell.x - 1,
        top: activeCell.y - 1,
        width: activeTemplate.width + 1,
        height: activeTemplate.height + 1,
        borderColor: !blocked ? OUTLINE_BORDER_1 : OUTLINE_BORDER_2,
        backgroundColor: !blocked ? OUTLINE_BACKGROUND_1 : OUTLINE_BACKGROUND_2
    }
}

export function isSingleCellKeyboard({ name }: { name: string }) {
    return ['ncr_dynakey', 'ncr_xr7'].indexOf(name) !== -1
}

/*
 * Клавиатуры, у которых имеет значение параметр `pazzle` шаблонов
 * при проверке доступности ячеек
 */
export function isPazzleKeyboard({name}: {name: string}) {
    return ['posiflex_kb6610'].indexOf(name) !== -1
}

export interface KeyboardCell extends Rect {
    isAlpha: boolean
    style: KeyboardButtonStyle
    scanCode: string
    children: string
}

export interface KeyboardButtonTemplate {
    width: number
    height: number
    pazzle: number
}

export type KeyboardXmlSettings = [KeyboardMapVo, KeyboardKbdVo]

export interface KeyboardProps {
    keyboardCells: KeyboardCell[]
    keyboardButtonTemplates: KeyboardButtonTemplate[]
}

export function parseKeyboardProps([kbdMap, kbdXml]: KeyboardXmlSettings): KeyboardProps {
    const { main, template } = kbdMap.keyboard.buttons
    const keyboardCells: KeyboardCell[] = main.button.map(rawCell => {
        return {
            x: +rawCell.x,
            y: +rawCell.y,
            width: +(rawCell.width || main.width),
            height: +(rawCell.height || main.height),
            isAlpha: (rawCell.kbdAlphaNumeric !== undefined) ? rawCell.kbdAlphaNumeric : main.kbdAlphaNumeric,
            style: rawCell.style,
            scanCode: rawCell.scanCode,
            children: rawCell.ch || rawCell.text,
        }
    })
    const keyboardButtonTemplates: KeyboardButtonTemplate[] = template.button.map(rawTemplate => {
        return { width: +rawTemplate.width, height: +rawTemplate.height, pazzle: +rawTemplate.pazzle }
    })
    // Баг с NCR XR7 - лишний шаблон
    if (kbdMap.keyboard.model === NCR_XR7_MODEL) {
        keyboardButtonTemplates.splice(0, 1)
    }
    return { keyboardCells, keyboardButtonTemplates }
}
