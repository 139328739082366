import { t } from 'i18next'
import { sortBy } from 'lodash'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { iTopologyManagerLocal } from '../../../protocol/set10/i-topology-manager-local'
import { CityVO, createCityVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import { createFormatVO, FormatVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import { createRegionVO, RegionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import { createShopVO, ShopVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { TopologyBundleVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-bundle-vo'
import { SHOPS, SHOPS_SEARCH } from '../../core/app-routes'
import { getAppBarTitleByRoute, RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, MENU } from '../app-bar-store'
import { NavigationMenuStore } from '../navigation-menu-store'
import { APP_BAR_STORE, APP_STORE, NAVIGATION_MENU_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { AppStore } from '../app-store'
import { iTopologyEditorLocal } from '../../../protocol/set10/i-topology-editor-local'
import { SHOP } from '../../../protocol/set10/set-retail10-commons/data-structs-module/en-topology-level'
import { createTopologyAdressVO, TopologyAdressVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-adress-vo'

export const ALL_OPTIONS_ID = -1

export class ShopsSearchStore {
    @observable
    topologyBundle: TopologyBundleVO = null
    @observable
    selectedRegion: RegionVO = null
    @observable
    selectedCity: CityVO = null
    @observable
    selectedShop: ShopVO = null
    @observable
    selectedFormat: FormatVO = null
    @observable
    nameAndNumberFilter: string = ''

    @observable
    addShopDialogOpen: boolean = false
    @observable
    addDialogSelectedRegion: RegionVO = null
    @observable
    addDialogSelectedCity: CityVO = null

    @observable
    paginationState: PaginationState = undefined

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    @computed
    get formats(): FormatVO[] {
        if (!this.topologyBundle || !this.topologyBundle.formats) {
            return [this.getAllFormatsOption()]
        }

        return [
            this.getAllFormatsOption(),
            ...toJS(this.topologyBundle.formats)
        ]
    }

    @computed
    get regions(): RegionVO[] {
        if (!this.topologyBundle || !this.topologyBundle.regions) {
            return [this.getAllRegionsOption()]
        }

        return [
            this.getAllRegionsOption(),
            ...toJS(this.topologyBundle.regions)
        ]
    }

    @computed
    get addDialogRegions(): RegionVO[] {
        if (!this.topologyBundle || !this.topologyBundle.regions) {
            return []
        }

        return this.topologyBundle.regions
    }

    @computed
    get cities(): CityVO[] {
        if (!this.topologyBundle || !this.topologyBundle.cities) {
            return [this.getAllCitiesOption()]
        }

        return [
            this.getAllCitiesOption(),
            ...toJS(this.topologyBundle.cities).filter(city => {
                return (
                    !this.selectedRegion ||
                        city.region && city.region.id === this.selectedRegion.id
                )
            })
        ]
    }

    @computed
    get addDialogCities(): CityVO[] {
        if (!this.topologyBundle || !this.topologyBundle.cities) {
            return []
        }

        return this.topologyBundle.cities.filter(city => {
                return (
                    !this.addDialogSelectedRegion ||
                        city.region && city.region.id === this.addDialogSelectedRegion.id
                )
            })
    }

    @computed
    get shopsOptions(): ShopVO[] {
        if (!this.topologyBundle || !this.topologyBundle.shops) {
            return [this.getAllShopsOption()]
        }

        const {shops} = this.topologyBundle
        let filteredShops = toJS(shops)

        if (this.selectedRegion) {
            filteredShops = filteredShops.filter(shop => {
                if (!shop.city.region.id) {
                    return false
                }
                return shop.city.region.id === this.selectedRegion.id
            })
        }

        if (this.selectedCity) {
            filteredShops = filteredShops.filter(shop => {
                if (!shop.city || !shop.city.id) {
                    return false
                }
                return shop.city.id === this.selectedCity.id
            })
        }

        return [
            this.getAllShopsOption(),
            ...filteredShops
        ]
    }

    @computed
    get shops(): ShopVO[] {
        if (!this.topologyBundle) {
            return null
        }

        if (!this.topologyBundle.shops) {
            return []
        }

        const { shops } = this.topologyBundle
        let filteredShops = toJS(shops)

        if (this.selectedRegion) {
            filteredShops = filteredShops.filter(shop => {
                if (!shop.city.region.id) {
                    return false
                }
                return shop.city.region.id === this.selectedRegion.id
            })
        }

        if (this.selectedCity) {
            filteredShops = filteredShops.filter(shop => {
                if (!shop.city || !shop.city.id) {
                    return false
                }
                return shop.city.id === this.selectedCity.id
            })
        }

        if (this.selectedFormat) {
            filteredShops = filteredShops.filter(shop => {
                if (!shop || !shop.format || !shop.format.id) {
                    return false
                }
                return shop.format.id === this.selectedFormat.id
            })
        }

        if (this.nameAndNumberFilter) {
            filteredShops = filteredShops.filter(shop => {
                if (!shop || !shop.name || !shop.number) {
                    return false
                }
                if (
                    shop.name.toLowerCase().indexOf(this.nameAndNumberFilter.toLowerCase()) !== -1 ||
                        shop.number.toString().toLowerCase().indexOf(this.nameAndNumberFilter.toLowerCase()) !== -1
                ) {
                    return true
                }
                return false
            })
        }

        return sortBy(filteredShops, ['city.region.name', 'city.name', 'number'])
    }

    @computed
    get shopsListSelectorKey(): string {
        return this.shops ? this.shops.map(shop => shop.id).join(';') : ''
    }

    @action
    setPaginationState = (paginationState: PaginationState) => {
        this.paginationState = paginationState
    }

    getAllRegionsOption = (): RegionVO => {
        return createRegionVO({
            id: ALL_OPTIONS_ID,
            name: t('shopsSearch.allRegions'),
        })
    }

    getAllCitiesOption = (): CityVO => {
        return createCityVO({
            id: ALL_OPTIONS_ID,
            name: t('shopsSearch.allCities'),
        })
    }

    getAllShopsOption = (): ShopVO => {
        return createShopVO({
            id: ALL_OPTIONS_ID,
            name: t('shopsSearch.allShops'),
        })
    }

    getAllFormatsOption = (): FormatVO => {
        return createFormatVO({
            id: ALL_OPTIONS_ID,
            name: t('shopsSearch.allFormats'),
        })
    }

    @action
    setRegion = (regionId: number): void => {
        let region = this.regions.find(region => region.id === regionId)
        region = region.id === ALL_OPTIONS_ID ? null : region
        this.selectedRegion = region
        this.selectedCity = null
    }

    @action
    setAddDialogRegion = (regionId: number): void => {
        let region = this.regions.find(region => region.id === regionId)
        this.addDialogSelectedRegion = region
        this.addDialogSelectedCity = this.addDialogCities.length > 0 ? this.addDialogCities[0] : null
    }

    @action
    setCity = (cityId: number): void => {
        let city = this.cities.find(city => city.id === cityId)
        city = city.id === ALL_OPTIONS_ID ? null : city
        this.selectedCity = city
    }

    @action
    setAddDialogCity = (cityId: number): void => {
        let city = this.cities.find(city => city.id === cityId)
        this.addDialogSelectedCity = city
    }

    @action
    setShop = (shopId: number): ShopVO => {
        let shop = this.shopsOptions.find(shop => shop.id === shopId)
        shop = shop.id === ALL_OPTIONS_ID ? null : shop
        this.selectedShop = shop
        return shop
    }

    @action
    setFormat = (formatId: number): void => {
        let format = this.formats.find(format => format.id === formatId)
        format = format.id === ALL_OPTIONS_ID ? null : format
        this.selectedFormat = format
    }

    @action
    setNameAndNumberFilter = (value: string): void => {
        this.nameAndNumberFilter = value
    }

    @action
    openAddShopDialog = () => {
        this.addShopDialogOpen = true

        // Ставим выбранный в фильтре регион, если не был выбран = ставим первый доступный
        let region: RegionVO = null
        if (this.selectedRegion) {
            region = this.selectedRegion
        } else {
            if (this.addDialogRegions.length > 0) {
                region = this.addDialogRegions[0]
            }
        }
        this.addDialogSelectedRegion = region

        // Ставим выбранный в фильтре город, если не был выбран = ставим первый доступный в регионе
        let city: CityVO = null
        if (this.selectedCity) {
            city = this.selectedCity
        } else {
            if (this.addDialogCities.length > 0) {
                city = this.addDialogCities[0]
            }
        }
        this.addDialogSelectedCity = city
    }

    @action
    closeAddShopDialog = () => {
        this.addShopDialogOpen = false
        this.addDialogSelectedCity = null
        this.addDialogSelectedRegion = null
    }

    fetchTopologyMap = (): Promise<void> => {
        return iTopologyManagerLocal.getTopologyBundle()
            .then(bundle => {
                runInAction(() => {
                    this.topologyBundle = bundle
                })
                // Из AppStore берется магазин при открытии
                this.appStore.setTopologyMapFromBundle(bundle)
            })
    }

    deleteShop = (shopId: number): Promise<void> => {
        const adress: TopologyAdressVO = createTopologyAdressVO({
            nodeId: shopId,
            nodeLevel: SHOP
        })

        return iTopologyEditorLocal.deleteNode(adress)
            .then(() => {
                return this.fetchTopologyMap()
            })
    }

    @action
    reset = (): void => {
        this.topologyBundle = null
        this.selectedRegion = null
        this.selectedCity = null
        this.selectedShop = null
        this.selectedFormat = null
        this.nameAndNumberFilter = ''
        this.addShopDialogOpen = false
        this.addDialogSelectedRegion = null
        this.addDialogSelectedCity = null
        this.paginationState = undefined
    }
}

/**
 * Обработчик для страниц поиска магазина
 */
export const SHOPS_SEARCH_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${SHOPS}${SHOPS_SEARCH}/?$`),
    onEnter: (newRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)

        appBarStore.updateState({
            title: getAppBarTitleByRoute(newRoute),
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    }
}
