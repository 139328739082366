import classNames from 'classnames'
import EventEmitter from 'wolfy87-eventemitter'
import { Vector2d } from '../../utils/math/geom-util'
import { MOUSE_DOWN, MOUSE_MOVE, MOUSE_UP } from '../../utils/dom/dom-events'

export type ControllerType = 'tl' | 't' | 'tr' | 'r' | 'br' | 'b' | 'bl' | 'l' | 'rotation' | 'center'

export const ROTATION_CONTROLLER_OFFSET = 20

export const TOP_LEFT: ControllerType = 'tl'
export const TOP: ControllerType = 't'
export const TOP_RIGHT: ControllerType = 'tr'
export const RIGHT: ControllerType = 'r'
export const BOTTOM_RIGHT: ControllerType = 'br'
export const BOTTOM: ControllerType = 'b'
export const BOTTOM_LEFT: ControllerType = 'bl'
export const LEFT: ControllerType = 'l'
export const ROTATION: ControllerType = 'rotation'
// Не отобржается, чтобы выделить опердацию перемещения объекта
// Координатно привязан к TOP_LEFT
export const CENTER: ControllerType = 'center'

export const DRAG_END: string = 'dragEnd'
export const DRAG_MOVE: string = 'dragMove'
export const DRAG_START: string = 'dragStart'

const styles = require('./wysiwyg-editor.scss')

export class HandController {

    events: EventEmitter = new EventEmitter()

    instance: HTMLDivElement

    private _hidden: boolean = false
    private startPosition: Vector2d

    constructor(
        public element: HTMLElement,
        public type: ControllerType,
    ) {
        this.instance = this.create()
        this.updateCSS()
        this.processType()
    }

    get hidden(): boolean {
        return this._hidden
    }

    set hidden(value: boolean) {
        this._hidden = value
        this.updateCSS()
    }

    create = (): HTMLDivElement => {
        const instance = document.createElement('div')
        this.element.appendChild(instance)
        instance.addEventListener(MOUSE_DOWN, this.mouseDownHandler)
        return instance
    }

    updateCSS = (): void => {
        if (!this.instance) return
        this.instance.setAttribute('class', classNames(styles.handle, {
            [styles.circle]: this.type === ROTATION,
            [styles.hidden]: this.hidden
        }))
    }

    remove = (): void => {
        if (!this.instance || !this.instance.parentElement) return
        this.instance.parentElement.removeChild(this.instance)
        this.events.removeAllListeners()
        this.instance.removeEventListener(MOUSE_DOWN, this.mouseDownHandler)
    }

    processType = (): void => {
        let top
        let left
        let right
        let bottom

        switch (this.type) {
            case TOP_LEFT:
            case LEFT:
            case BOTTOM_LEFT:
                left = '-5px'
                break
            case TOP:
            case BOTTOM:
                left = 'calc(50% - 5px)'
                break
            case TOP_RIGHT:
            case RIGHT:
            case BOTTOM_RIGHT:
                right = '-5px'
                break
            case ROTATION:
                right = '-25px'
                break
        }

        switch (this.type) {
            case TOP_LEFT:
            case TOP:
            case TOP_RIGHT:
                top = '-5px'
                break
            case LEFT:
            case RIGHT:
            case ROTATION:
                top = 'calc(50% - 5px)'
                break
            case BOTTOM_LEFT:
            case BOTTOM:
            case BOTTOM_RIGHT:
                bottom = '-5px'
                break
        }

        let style = ''
        if (top) {
            style += `top: ${top};`
        }
        if (right) {
            if (style) style += ' '
            style += `right: ${right};`
        }
        if (bottom) {
            if (style) style += ' '
            style += `bottom: ${bottom};`
        }
        if (left) {
            if (style) style += ' '
            style += `left: ${left};`
        }

        this.instance.setAttribute('style', style)
    }

    mouseDownHandler = (event: MouseEvent): void => {
        event.preventDefault()
        event.stopImmediatePropagation()
        this.startPosition = new Vector2d(
            event.clientX,
            event.clientY
        )
        window.addEventListener(MOUSE_MOVE, this.mouseMoveHandle)
        window.addEventListener(MOUSE_UP, this.mouseUpHandler)

        this.events.emit(DRAG_START, this, this.startPosition)
    }

    mouseMoveHandle = (event: MouseEvent): void => {
        const targetPosition = new Vector2d(
            event.clientX,
            event.clientY
        )

        this.events.emit(DRAG_MOVE, this, targetPosition.subtract(this.startPosition))
    }

    mouseUpHandler = (event: MouseEvent): void => {
        window.removeEventListener(MOUSE_MOVE, this.mouseMoveHandle)
        window.removeEventListener(MOUSE_UP, this.mouseUpHandler)

        const targetPosition = new Vector2d(event.clientX, event.clientY)

        this.events.emit(DRAG_END, this, targetPosition.subtract(this.startPosition))
    }
}
