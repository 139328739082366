import {observable, action, runInAction, computed} from 'mobx'
import {t} from 'i18next'
import { isNil } from 'lodash'
import {checkManagerLocal} from '../../../protocol/set10/check-manager-local'
import {
    createSearchArgumentVO,
    SearchArgumentVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { CHECK_TYPE_FILTER, CHECKS_TYPES_ALL, Filter, getFiltersSearchArguments } from '../../core/filters/filter'
import {SearchResultVO} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/search-result-vo'
import {Check} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check'
import {operDayServiceLocal} from '../../../protocol/set10/oper-day-service-local'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import {reportsProcessorLocal} from '../../../protocol/set10/reports-processor-local'
import {XLSX} from '../../core/file-types'
import {config} from '../../config/config'
import { UserStore } from '../user-store'
import { CheckPageStore } from './check-page-store'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, CHECK_PAGE_STORE, USER_STORE } from '../stores'
import {FiltersState} from '../../components/filters/filters'
import {withSpinner} from '../with-spinner'
import { fromClientToServerTime } from '../../utils/app-util'

const MAX_TRANSACTIONS_BEFORE_ALERT = 50000

export class TransactionsSearchStore {
    maxDisplayedChecks: number = 40

    @observable
    selectedFilters: Filter[] = []

    @observable
    searchResult: SearchResultVO<Check>

    @observable
    foundChecks: Check[] = []

    currentFiltersState: FiltersState

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private checkPageStore: CheckPageStore = getStore(CHECK_PAGE_STORE)

    @action
    setFiltersState = (state: FiltersState) => {
        this.currentFiltersState = state
    }

    @action
    updateFilters = (filters: Filter[]) => {
        this.selectedFilters = filters
    }

    @computed
    get transactionsCount(): number {
        let count = 0
        this.foundChecks.forEach(check => {
            count += check.transactions.length
        })
        return count
    }

    fetchSettings = (): Promise<void> => {
        return operDayServiceLocal.getOperDayProperties()
            .then(result => {
                runInAction(() => {
                    this.maxDisplayedChecks = result.checkAmountDisplayedInSearchCheckMode
                })
            })
    }

    findTransactions = (): Promise<void> => {
        const filters: SearchArgumentVO[] = getFiltersSearchArguments(this.selectedFilters)
        const advancedFilters = [
            createSearchArgumentVO({ type: CHECK_TYPE_FILTER, value: CHECKS_TYPES_ALL }),
            ...filters
        ]

        return withSpinner(checkManagerLocal.findTransactions(this.userStore.session, advancedFilters, this.maxDisplayedChecks))
            .then(result => {
                runInAction(() => {
                    this.searchResult = result
                    this.foundChecks = result.values
                    // TODO SFM-208 ошибки транспорта
                    if (this.foundChecks) {
                        this.foundChecks.forEach(check => {
                            // Для отложенного чека может прийти date === null, но у отложеного чека не может быть транзакции.
                            // Так что, это на всякий случай
                            if (!isNil(check.date)) {
                                if (!(check.date instanceof Date)) {
                                    // unixtime -> Date
                                    check.date = new Date(check.date)
                                }
                                check.date = fromClientToServerTime(check.date)
                            }
                        })
                    }
                })
            })
    }

    openCheck = (check: Check): void => {
        // Вызываем метод из хранилища страницы чеков
        this.checkPageStore.openCheck(check)
    }

    exportTransactions = (): void => {
        if (this.searchResult.maxCount > MAX_TRANSACTIONS_BEFORE_ALERT) {
            this.appStore.showDialog({
                title: t('transactionsSearch.tooMuchTransactionHeader'),
                message: t('transactionsSearch.tooMuchTransactionsText', {count: MAX_TRANSACTIONS_BEFORE_ALERT}),
                mode: DIALOG,
                onYes: this.exportToExcel
            })
        } else {
            this.exportToExcel()
        }
    }

    exportToExcel = (): Promise<void> => {
        const filters: SearchArgumentVO[] = getFiltersSearchArguments(this.selectedFilters)
        const advancedFilters = [
            createSearchArgumentVO({ type: CHECK_TYPE_FILTER, value: CHECKS_TYPES_ALL }),
            ...filters
        ]

        // window.open сработает только если он был вызван в главном потоке
        // поэтому мы сначала открываем окно, а потом подменяем ему адресс
        const reportWindow = window.open()

        return reportsProcessorLocal.getFileNameForTransactionHistory(advancedFilters, XLSX)
            .then(result => {
                const baseAddress = config.reportsAddress
                const reportUrl = `${baseAddress}?Action=getFile&FILE_NAME=${result}`

                reportWindow.location.replace(reportUrl)
            })
    }

    @action
    resetFiltersAndResults = (): void => {
        this.selectedFilters = []
        this.currentFiltersState = undefined
        this.searchResult = undefined
        this.foundChecks = []
    }

    @action
    reset = (): void => {
        this.maxDisplayedChecks = 40
        this.resetFiltersAndResults()
    }
}
