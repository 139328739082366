import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { t } from 'i18next'
import { Tabs, TabInfo } from '@crystalservice/crystals-ui/lib/components/tabs/tabs'
import { goTo, RouteChangeHandler } from '../../../utils/router-util'
import { APP_BAR_STORE, NAVIGATION_MENU_STORE } from '../../../store/stores'
import { AppBarStore, MENU } from '../../../store/app-bar-store'
import { NavigationMenuStore } from '../../../store/navigation-menu-store'
import { getStore } from '../../../store/stores-repository'
import { LOYALTY, COUPONS, COUPONS_LIST, COUPONS_INSTANCES, COUPONS_REPORTS } from '../../../core/app-routes'
import { LoyaltyCouponsList } from './loyalty-coupons-list/loyalty-coupons-list'
import { LoyaltyCouponsInstances } from './loyalty-coupons-instances/loyalty-coupons-instances'
import { LoyaltyCouponsReports } from './loyalty-coupons-reports/loyalty-coupons-reports'

const styles = require('./loyalty-coupons.scss')

export type CouponsTabType = 'list' | 'instances' | 'reports'
export const COUPONS_LIST_TAB: CouponsTabType = 'list'
export const COUPONS_INSTANCES_TAB: CouponsTabType = 'instances'
export const COUPONS_REPORTS_TAB: CouponsTabType = 'reports'

const ALL_TABS: CouponsTab[] = [
    {
        type: COUPONS_LIST_TAB,
        component: <LoyaltyCouponsList key="couponsList" />,
    },
    {
        type: COUPONS_INSTANCES_TAB,
        component: <LoyaltyCouponsInstances key="couponsInstances" />,
    },
    {
        type: COUPONS_REPORTS_TAB,
        component: <LoyaltyCouponsReports key="couponsReports" />,
    },
]

export interface CouponsTab {
    type: CouponsTabType
    component: React.ReactNode
}

export interface CouponsURLParams {
    tab?: CouponsTabType
}

class LoyaltyCoupons extends React.Component<RouteComponentProps<CouponsURLParams>> {
    get dataLabels(): TabInfo[] {
        return [
            {
                data: COUPONS_LIST,
                label: t('loyaltyCoupons.list'),
            },
            {
                data: COUPONS_INSTANCES,
                label: t('loyaltyCoupons.instances'),
            },
            {
                data: COUPONS_REPORTS,
                label: t('loyaltyCoupons.reports'),
            },
        ]
    }

    get selectedTabIndex(): number {
        const { tab = COUPONS_LIST_TAB } = this.props.match.params
        const index = ALL_TABS.findIndex(t => t.type === tab)

        if (index === -1) return 0

        return index
    }

    onTabChange = (tab: TabInfo): void => {
        goTo(`${LOYALTY}${COUPONS}${tab.data}`)
    }

    render() {
        return (
            <>
                <Tabs
                    items={this.dataLabels}
                    defaultSelectedIndex={this.selectedTabIndex}
                    onTabChange={this.onTabChange}
                    className={styles.loyaltyCoupons}
                    tabContentClassName={styles.tabsContent}
                >
                    {ALL_TABS.map(tab => tab.component)}
                </Tabs>
            </>
        )
    }
}

export default withRouter(LoyaltyCoupons)

export const LOYALTY_COUPONS_ROUTE_CHANGE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${LOYALTY}${COUPONS}(${COUPONS_LIST}|${COUPONS_INSTANCES}|${COUPONS_REPORTS})$`),
    onEnter: (newRoute: string, prevRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
        navigationMenuStore.synchronizeWithRoute()
        appBarStore.updateState({
            title: `${t('set10.loyalty')} - ${t('set10.coupons')}`,
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    },
}
