import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { PRODUCT_DETAILS_STORE, USER_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { t } from 'i18next'
import moment from 'moment'
import { getInfoRow } from './product-info-row'
import Edit from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { ProductProducerDialog } from './product-producer-dialog'
import { isNil } from 'lodash'
import MenuItem from '@material-ui/core/MenuItem'
import { GoodsCompositionVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/goods-composition-vo'
import { DATE_FORMAT, DATE_TIME_FORMAT, ProductDateDialog } from './product-date-dialog'
import { UserStore } from '../../../store/user-store'
import { SETRETAILX_GOODS_DATEOFMANUFACTURE_CHANGE, EDIT_PRODUCT_PRODUCER } from '../../../core/privileges/privileges'
import classNames from 'classnames'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { PluginPropertyVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/plugin-property-vo'
import { fromClientToServerTime } from '../../../utils/app-util'
import { LabeledData } from '@crystalservice/crystals-ui/lib/components/labeled-data/labeled-data'
import { PluginPropertyKeys } from '../../../core/products/product-info'

const styles = require('./product-info.scss')

export interface ProductAdditionalInfoProps {
    productDetailsStore?: ProductDetailsStore
    user?: UserStore
}

interface ProductAdditionalInfoState {
    editedProducerIndex?: number
    dateDialogFormat?: string
    dateDialogValue?: string
}

enum DateOptions {
    NOT_SPECIFIED = 1,
    DATE_NO_TIME = 2,
    DATE_WITH_TIME = 3,
    CURRENT = 4,
    SHORT = 5,
}

@inject(PRODUCT_DETAILS_STORE)
@inject(USER_STORE)
@observer
export class ProductAdditionalInfo extends React.Component<ProductAdditionalInfoProps, ProductAdditionalInfoState> {

    state: ProductAdditionalInfoState = {
        editedProducerIndex: null,
        dateDialogFormat: null,
        dateDialogValue: null,
    }

    getPluginPropertyRow = (pluginProperty: PluginPropertyVO, index: number): JSX.Element => {

        if (!pluginProperty.localKey) return null

        switch (pluginProperty.key) {
            case PluginPropertyKeys.MRC_CODE:
                const mrcPair = pluginProperty.value.split(', ')
                const tableItems = mrcPair.map((pair, index) => {
                    const items = pair.split(';')
                    return {
                        mrc: items[0],
                        cost: items.length > 1 ? items[1] : items[0],
                        key: index
                    }
                })
                return (
                    <Fragment key={index}>
                        <Table
                            id="table"
                            adaptive={false}
                            title={t('productDetails.generalInfo.mrc')}
                            className={classNames(styles.mrcTable, styles.table)}
                            items={tableItems}
                            keyField="key"
                            columns={[
                                {
                                    labelField: 'mrc'
                                },
                                {
                                    labelField: 'cost'
                                }
                            ]}
                        />
                    </Fragment>
                )
            case PluginPropertyKeys.DATE_CODE:
                return (
                    <Fragment key={index}>
                        {getInfoRow(
                            pluginProperty.localKey,
                            this.getDateOfManufacture(pluginProperty)
                        )}
                    </Fragment>
                )
            case PluginPropertyKeys.PRODUCER_CODE:
            case PluginPropertyKeys.COMPOSITION_CODE:
            case PluginPropertyKeys.FOOD_VALUE_CODE:
            case PluginPropertyKeys.STORAGE_CONDITIONS_CODE:
            case PluginPropertyKeys.CONSIGNMENT:
            case PluginPropertyKeys.WEIGHING_TO_SALE_DELAY:
                return null
            default:
                return (
                    <Fragment key={index}>
                        {getInfoRow(
                            pluginProperty.localKey,
                            pluginProperty.value
                        )}
                    </Fragment>
                )
        }
    }

    getDateOfManufacture = (pluginProperty: PluginPropertyVO): JSX.Element => {
        const options = [
            {
                label: t('productDetails.generalInfo.dateNotSpecified'),
                value: DateOptions.NOT_SPECIFIED,
            },
            {
                label: t('productDetails.generalInfo.dateNoTime'),
                value: DateOptions.DATE_NO_TIME,
            },
            {
                label: t('productDetails.generalInfo.dateWithTime'),
                value: DateOptions.DATE_WITH_TIME,
            },
            {
                label: t('productDetails.generalInfo.dateCurrent'),
                value: DateOptions.CURRENT,
            },
            {
                label: t('productDetails.generalInfo.dateShort'),
                value: DateOptions.SHORT,
            },
        ]
        let shownValue = pluginProperty.value
        let value: DateOptions = null
        // Если выбран пункт "Дата и время не указаны", придет пустая строка
        if (!shownValue) {
            // Для пунктов, в которых не указано время - берем текстовое описание
            shownValue = t('productDetails.generalInfo.dateNotSpecified')
            value = DateOptions.NOT_SPECIFIED
        }
        // Если выбран пункт "Дата и время печати этикеток", то придет значение "Изготовлено и"
        if (shownValue === t('productDetails.generalInfo.dateShortText')) {
            shownValue = t('productDetails.generalInfo.dateShort')
            value = DateOptions.SHORT
        }
        // Для временных промежутко придет время вида: 'Изготовлено: 06.11.2019' или 'Изготовлено: 06.11.2019 15:00'
        const dotsIndex = shownValue.indexOf(':')
        if (dotsIndex !== -1) {
            // Пример строки: 'Изготовлено: 06.11.2019', после обрезания останется '06.11.2019'
            shownValue = shownValue.substring(dotsIndex + 2)

            // Проверяем короткая дата или полная
            if (shownValue.length > 10) {
                value = DateOptions.DATE_WITH_TIME
            } else {
                value = DateOptions.DATE_NO_TIME
            }
        }

        return (
            <div id="dateOfManufactureInputContainer">
                <SelectInput
                    slim
                    disabled={!this.props.user.havePrivilege(SETRETAILX_GOODS_DATEOFMANUFACTURE_CHANGE)}
                    className={styles.selectInput}
                    renderedValue={() => <div>{shownValue}</div>}
                    value={value}
                    onSelect={option => {
                        switch (option.value) {
                            case DateOptions.NOT_SPECIFIED:
                                this.updateDateOfManufacture('')
                                break
                            case DateOptions.DATE_NO_TIME:
                                this.setState({
                                    dateDialogFormat: DATE_FORMAT,
                                    dateDialogValue: shownValue
                                })
                                break
                            case DateOptions.DATE_WITH_TIME:
                                this.setState({
                                    dateDialogFormat: DATE_TIME_FORMAT,
                                    dateDialogValue: shownValue
                                })
                                break
                            case DateOptions.CURRENT:
                                // Исправляем дату, чтобы была текущая дата сервера.
                                // В остальных случаях оставляем дату как выбрал пользователь
                                const currentTime = moment(fromClientToServerTime(new Date())).format(DATE_TIME_FORMAT)
                                this.updateDateOfManufacture(`${t('productDetails.generalInfo.dateAffix')}${currentTime}`)
                                break
                            case DateOptions.SHORT:
                                // Если выбран пункт "Дата и время печати этикеток", то надо подставлять значение "Изготовлено и"
                                this.updateDateOfManufacture(t('productDetails.generalInfo.dateShortText'))
                                break
                        }
                    }}
                    options={options}
                />
            </div>
        )
    }

    updateDateOfManufacture = (value: string): void => {
        const {
            productDetails, editProductDetails, updateProductDetails
        } = this.props.productDetailsStore

        let newPluginProperties = productDetails.pluginProperties

        newPluginProperties.forEach(item => {
            if (item.key === PluginPropertyKeys.DATE_CODE) {
                item.value = value
            }
        })

        editProductDetails({
            pluginProperties: newPluginProperties
        })

        updateProductDetails()

        this.setState({
            dateDialogFormat: null,
            dateDialogValue: null,
        })
    }

    getGoodsComposition = (): JSX.Element => {
        const {
            productDetails, editProductDetails, updateProductProducer
        } = this.props.productDetailsStore

        let activeOption = 0
        let activeItem: GoodsCompositionVO

        const producerOptions = productDetails.goodsCompositionList.map((item, index) => {
            if (item.active) {
                activeOption = index
                activeItem = item
            }
            let producerName: string = item.producerVo.producer || t('productDetails.generalInfo.producerNotSet')

            if (item.plugin) {
                producerName = `${t('productDetails.generalInfo.default')} ${producerName}`
            } else if (item.producerVo.guid) {
                producerName = `${t('productDetails.generalInfo.fromERP')} ${producerName}`
            }

            return {
                label: producerName,
                value: index
            }
        })

        const readyOnly = !this.props.user.havePrivilege(EDIT_PRODUCT_PRODUCER)
        const showEditButton = activeItem && !activeItem.plugin && !activeItem.producerVo.guid

        return (
            <Fragment key="composition">
                {getInfoRow(
                    t('productDetails.generalInfo.producer'),
                    readyOnly ? (
                        <LabeledData
                            className={styles.selectInput}
                            value={producerOptions[activeOption].label}
                        />
                    ) : (
                    <div className={styles.producerContainer}>
                        <SelectInput<DefaultSelectOption<number>>
                            slim
                            readonly={producerOptions.length === 1}
                            id="producerSelectInput"
                            className={styles.selectInput}
                            options={producerOptions}
                            value={activeOption}
                            onSelect={newOption => {
                                const newGoodsCompositionList = productDetails.goodsCompositionList.map((item, index) => {
                                    item.active = newOption.value === index
                                    return item
                                })
                                editProductDetails({
                                    goodsComposition: {
                                        ...productDetails.goodsCompositionList[newOption.value],
                                        active: true
                                    },
                                    goodsCompositionList: newGoodsCompositionList
                                })
                                updateProductProducer()
                            }}
                        />
                        {showEditButton && (
                            <AdaptiveIconButton
                                id="producerEditButton"
                                adaptive
                                adaptiveClassName={styles.adaptiveButton}
                                label={t('productDetails.generalInfo.producerEdit')}
                                onClick={() => this.setState({
                                    editedProducerIndex: activeOption
                                })}
                            >
                                <Edit />
                            </AdaptiveIconButton>
                        )}
                        <AdaptiveIconButton
                            id="producerAddButton"
                            adaptive
                            adaptiveClassName={styles.adaptiveButton}
                            label={t('productDetails.generalInfo.producerAdd')}
                            onClick={() => this.setState({
                                editedProducerIndex: -1
                            })}
                        >
                            <Add />
                        </AdaptiveIconButton>
                    </div>)
                )}
                { productDetails.goodsComposition.composition && getInfoRow(
                    t('productDetails.generalInfo.producerComposition'),
                    productDetails.goodsComposition.composition
                )}
                { productDetails.goodsComposition.storageConditions && getInfoRow(
                    t('productDetails.generalInfo.producerStorageConditions'),
                    productDetails.goodsComposition.storageConditions
                )}
                { productDetails.goodsComposition.foodValue && getInfoRow(
                    t('productDetails.generalInfo.producerFoodValue'),
                    productDetails.goodsComposition.foodValue
                )}
            </Fragment>
        )
    }

    handleSaveGoodsComposition = (newItem: GoodsCompositionVO, index: number): void => {
        const {
            productDetails, editProductDetails, updateProductProducer
        } = this.props.productDetailsStore

        let newGoodsCompositionList: GoodsCompositionVO[]
        if (index === -1) {
            // Новый изготовитель
            newGoodsCompositionList = productDetails.goodsCompositionList.map(item => {
                item.active = false
                return item
            })
            newGoodsCompositionList.push({
                ...newItem,
                active: true
            })
        } else {
            // Редактируем старого
            newGoodsCompositionList = productDetails.goodsCompositionList.map((item, itemIndex) => {
                return itemIndex === index ? newItem : item
            })
        }

        editProductDetails({
            goodsComposition: {
                ...newItem
            },
            goodsCompositionList: newGoodsCompositionList
        })
        updateProductProducer()

        this.setState({editedProducerIndex: null})
    }

    // Удаление пока не поддерживает бэкенд
    handleDeleteGoodsComposition = (newItem: GoodsCompositionVO, index: number): void => {
        const {
            productDetails, editProductDetails, updateProductProducer
        } = this.props.productDetailsStore

        let newGoodsCompositionList: GoodsCompositionVO[] = productDetails.goodsCompositionList.concat()
        // Удаляем текущего изготовителя
        newGoodsCompositionList.splice(index, 1)

        // Устанавливаем выбранного изготовителя по умолчанию
        const firstItem = newGoodsCompositionList[0]
        firstItem.active = true

        editProductDetails({
            goodsComposition: {
                ...firstItem
            },
            goodsCompositionList: newGoodsCompositionList
        })
        updateProductProducer()

        this.setState({editedProducerIndex: null})
    }

    getCounterpartyInfo = (): JSX.Element => {
        const { productDetails } = this.props.productDetailsStore

        if (!productDetails.counterparty) return null

        return (
            <>
                <div className={styles.groupLabel}>{t('productDetails.generalInfo.counterpartyTitle')}</div>
                {getInfoRow(
                    t('productDetails.generalInfo.counterpartyName'),
                    productDetails.counterparty.name
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.counterpartyType'),
                    productDetails.counterparty.debitorType
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.counterpartyPhone'),
                    productDetails.counterparty.phone1
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.counterpartyERP'),
                    productDetails.counterparty.guid
                )}
            </>
        )
    }

    render() {
        const { productDetails } = this.props.productDetailsStore
        const { editedProducerIndex, dateDialogValue, dateDialogFormat } = this.state

        return (
            <div className={styles.additionalInfo}>
                {!isNil(editedProducerIndex) && (
                    <ProductProducerDialog
                        index={editedProducerIndex}
                        onSave={this.handleSaveGoodsComposition}
                        onClose={() => this.setState({editedProducerIndex: null})}
                    />
                )}

                {dateDialogValue && (
                    <ProductDateDialog
                        defaultValue={dateDialogValue}
                        dateFormat={dateDialogFormat}
                        onSave={dateString => {
                            const value = `${t('productDetails.generalInfo.dateAffix')}${dateString}`
                            this.updateDateOfManufacture(value)
                        }}
                        onClose={() => this.setState({
                            dateDialogFormat: null,
                            dateDialogValue: null,
                        })}
                    />
                )}
                <div className={styles.groupLabel}>{t('productDetails.generalInfo.additional')}</div>

                {this.getGoodsComposition()}

                {productDetails.pluginProperties.map(this.getPluginPropertyRow).filter(Boolean)}

                {this.getCounterpartyInfo()}

            </div>
        )
    }
}
