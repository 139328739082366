import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class SegmentArchivationService extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Cards-Segments/SegmentArchivationServiceImpl!ru.crystals.cards.segments.archivation.SegmentArchivationService'
    }

    archiveSegment = (segmentGuid: string, options?: RequestOptions): Promise<number> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'archiveSegment',
                params: [segmentGuid]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const segmentArchivationService: SegmentArchivationService = new SegmentArchivationService()
