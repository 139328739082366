import React from 'react'
import classNames from 'classnames'
import { DraggableItem, CustomizableDraggableItemVO } from './draggable-item'
import { ItemsContainer, ItemsContainerProps } from './items-container'

const styles = require('./draggable-items-sorter.scss')

interface DraggableItemsSorterProps<T extends CustomizableDraggableItemVO> {
    /**
     * Уникальный строковый идентификатор поля
     */
    dragAndDropType: string
    /**
     * Список элементов в изначальном порядке
     */
    defaultItems: T[]
    /**
     * Вызывается при изменении порядка items
     */
    onItemsChange: (newItems: T[]) => void
    /**
     * Прокидываются без изменения в ItemsContainer
     */
    ItemsContainerProps?: Partial<ItemsContainerProps>
    readOnly?: boolean
}

interface DraggableItemsSorterState<T extends CustomizableDraggableItemVO> {
    items: T[]
}

export class DraggableItemsSorter<T extends CustomizableDraggableItemVO>
    extends React.Component<DraggableItemsSorterProps<T>, DraggableItemsSorterState<T>> {

    static defaultProps: Partial<DraggableItemsSorterProps<CustomizableDraggableItemVO>> = {
        ItemsContainerProps: {}
    }

    state: DraggableItemsSorterState<T> = {
        items: this.props.defaultItems
    }

    render() {
        const { dragAndDropType, onItemsChange, ItemsContainerProps, readOnly } = this.props
        const { items } = this.state

        return (
            <ItemsContainer
                {...ItemsContainerProps}
                className={classNames(ItemsContainerProps.className, styles.draggableItemsSorter)}
                itemsLength={items.length}
            >
                { items.map((item, index) => (
                    <div key={item.key} className={styles.item}>
                        <p className={styles.itemIndex}>{ index + 1 }.</p>
                        <DraggableItem<T>
                            dragAndDropType={dragAndDropType}
                            item={item}
                            getItemIndex={key => items.findIndex(item => item.key === key)}
                            onLayerMove={(fromIndex, toIndex) => {
                                if (readOnly) return
                                this.setState({ items: swapElements(this.state.items, fromIndex, toIndex) })
                            }}
                            onLayerMovementFinished={() => {
                                if (readOnly) return
                                onItemsChange(items)
                            }}
                            isDragging={item.isDragging}
                        />
                    </div>
                )) }
            </ItemsContainer>
        )
    }
}

function swapElements<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    let result: T[] = array

    const fromIndexData = result[fromIndex]

    result.splice(fromIndex, 1)
    result.splice(toIndex, 0, fromIndexData)

    return result
}
