import { OperDayPropertiesVO } from './set-retail10-commons/set-oper-day-api/oper-day-properties-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class OperDayServiceLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-OperDay/SET/OperDayService!ru.crystals.operday.process.OperDayServiceLocal'
    }

    getOperDayProperties = (options?: RequestOptions): Promise<OperDayPropertiesVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getOperDayProperties',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    setOperDayProperties = (operDayPropertiesVO: OperDayPropertiesVO, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'setOperDayProperties',
                params: [operDayPropertiesVO]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const operDayServiceLocal: OperDayServiceLocal = new OperDayServiceLocal()
