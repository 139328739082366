import React from 'react'
import { PageMock } from '../../components/page-mock/page-mock'
import {
    CARDS, getRouteName, CARDS_SEARCH, CARD_CATEGORIES, BONUS_ACCOUNTS, PRESENT_CARDS,
    PRESENT_CARDS_CATEGORY_EDIT, EXTERNAL_CARDS, SEGMENTS_SEARCH, CARDS_DECLINED_ON_IMPORT, SEGMENTS_EDIT,
    PRESENT_CARDS_CATEGORIES, EXTERNAL_CARDS_EDIT, CARDS_NORMAL, CARDS_UNLOADED_ON_CASHES, CARDS_UNLOADED_ON_SHOPS, SETTINGS,
} from '../core/app-routes'
import { RouteScheme, CENTRUM, RETAIL } from '../utils/route-modules-util'
import CreditCard from '@material-ui/icons/CreditCard'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import { CARDS as CARDS_MODULE } from '../core/privileges/app-modules'
import { config } from '../config/config'
import {
    CARDS_SEARCH_CARDS_BY_CHECK, CARDS_INTERNAL_AND_CLIENT_VIEW_PRIVILEGE,
    CARDS_COUPONS_EDIT_PRIVILEGE, CARDS_INTERNAL_CARDS_EDIT_PRIVILEGE,
    CARDS_INTERNAL_CARDS_BONUSACCOUNT_EDIT_PRIVILEGE, CARDS_PRESENT_CARDS_EDIT_PRIVILEGE,
    CARDS_EXTERNAL_CARDS_EDIT_PRIVILEGE, CARDS_SEARCH_SEGMENTS, CARDS_PRESENT_CARDS_VIEW_PRIVILEGE
} from '../core/privileges/privileges'
import { t } from 'i18next'
import { MenuNotification } from '../components/pages-container/notifications/menu-notification'
import { NOT_LOADED_CARDS_ON_SHOPS, REFUSED_CARDS, NOT_LOADED_CARDS_ON_CASHES } from '../store/notification-store'
import { lazy } from '../utils/code-splitting'

export const cardsRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'cardsNavLink',
        label: getRouteName(CARDS),
        icon: <CreditCard/>,
    },
    path: CARDS,
    privileges: (privilegeManager: PrivilegeManager) => {
        return privilegeManager.haveAnyPrivilegeFromModule(CARDS_MODULE)
    },
    component: PageMock,
    children: [
        {
            navItemProps: {
                id: 'cardsSearch',
                label: getRouteName(CARDS_SEARCH),
            },
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    CARDS_SEARCH_CARDS_BY_CHECK,
                    CARDS_INTERNAL_AND_CLIENT_VIEW_PRIVILEGE,
                    CARDS_INTERNAL_CARDS_EDIT_PRIVILEGE,
                    CARDS_EXTERNAL_CARDS_EDIT_PRIVILEGE
                ])
            },
            path: `${CARDS}${CARDS_SEARCH}`,
            component: lazy(() =>
                import(/* webpackChunkName: "card-tabs" */ '../pages/cards/card-tabs/card-tabs')),
        },
        {
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    CARDS_SEARCH_CARDS_BY_CHECK,
                    CARDS_INTERNAL_AND_CLIENT_VIEW_PRIVILEGE
                ])
            },
            path: `${CARDS}${CARDS_SEARCH}${CARDS_NORMAL}`,
            component: lazy(() =>
                import(/* webpackChunkName: "cards-search" */ '../pages/cards/cards-search/cards-search')),
        },
        {
            privileges: [CARDS_INTERNAL_CARDS_EDIT_PRIVILEGE],
            path: `${CARDS}${CARDS_SEARCH}${CARD_CATEGORIES}`,
            component: lazy(() =>
                import(/* webpackChunkName: "card-categories" */ '../pages/cards/card-categories/card-categories')),
        },
        {
            privileges: [CARDS_INTERNAL_CARDS_EDIT_PRIVILEGE],
            path: `${CARDS}${CARDS_SEARCH}${CARD_CATEGORIES}/:id`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "card-categories" */ '../pages/cards/card-categories/card-category-details/card-category-details')),
        },
        {
            privileges: [CARDS_INTERNAL_CARDS_EDIT_PRIVILEGE],
            path: `${CARDS}${CARDS_SEARCH}${CARD_CATEGORIES}/new`,
            component: lazy(() =>
                import(/* webpackChunkName: "card-categories" */ '../pages/cards/card-categories/card-category-details/card-category-details'),
            ),
        },
        {
            privileges: [CARDS_EXTERNAL_CARDS_EDIT_PRIVILEGE],
            path: `${CARDS}${CARDS_SEARCH}${EXTERNAL_CARDS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "cards-external" */ '../pages/cards/cards-external/cards-external')),
        },
        {
            privileges: [CARDS_EXTERNAL_CARDS_EDIT_PRIVILEGE],
            path: `${CARDS}${CARDS_SEARCH}${EXTERNAL_CARDS}${EXTERNAL_CARDS_EDIT}/:id`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "cards-external-edit" */ '../pages/cards/cards-external/cards-external-edit'))
        },
        {
            navItemProps: {
                id: 'bonusAccounts',
                label: getRouteName(BONUS_ACCOUNTS),
            },
            privileges: [CARDS_INTERNAL_CARDS_BONUSACCOUNT_EDIT_PRIVILEGE],
            path: `${CARDS}${BONUS_ACCOUNTS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "bonus-accounts" */ '../pages/cards/bonus-accounts/bonus-accounts')),
        },
        {
            navItemProps: {
                id: 'presentCards',
                label: getRouteName(PRESENT_CARDS),
            },
            path: `${CARDS}${PRESENT_CARDS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "present-cards" */ '../pages/cards/present-cards/present-cards')),
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    CARDS_PRESENT_CARDS_VIEW_PRIVILEGE,
                    CARDS_PRESENT_CARDS_EDIT_PRIVILEGE
                ])
            }
        },
        {
            path: `${CARDS}${PRESENT_CARDS}/:tab`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "present-cards" */ '../pages/cards/present-cards/present-cards')),
        },
        {
            path: `${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORIES}${PRESENT_CARDS_CATEGORY_EDIT}/:id`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "present-cards-category-edit" */
                    '../pages/cards/present-cards/present-cards-category-edit/present-cards-category-edit'
                )),
        },
        {
            path: `${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORY_EDIT}/:id`,
            component: lazy(() =>
                import(/* webpackChunkName: "present-card-edit" */ '../pages/cards/present-cards/present-card-edit/present-card-edit')),
        },
        {
            navItemProps: {
                id: 'segmentsSearch',
                label: getRouteName(SEGMENTS_SEARCH),
            },
            privileges: [CARDS_SEARCH_SEGMENTS],
            path: `${CARDS}${SEGMENTS_SEARCH}`,
            component: lazy(() =>
                import(/* webpackChunkName: "segments-search" */ '../pages/loyalty/segments/segments-search')),
        },
        {
            cannotBeRedirectTarget: true,
            path: `${CARDS}${SEGMENTS_SEARCH}${SEGMENTS_EDIT}`,
            component: lazy(() =>
                import(/* webpackChunkName: "segment-edit" */ '../pages/loyalty/segments/segment-edit')),
        },
        {
            navItemProps: {
                id: 'unloadedOnShops',
                label: t('set10.notLoadedToShops'),
                endAdornment: <MenuNotification notificationType={NOT_LOADED_CARDS_ON_SHOPS}/>,
            },
            serverType: CENTRUM,
            path: `${CARDS}${CARDS_UNLOADED_ON_SHOPS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "unloaded-on-shops" */ '../pages/cards/unloaded-on-shops/unloaded-on-shops')),
        },
        {
            navItemProps: {
                id: 'unloadedOnCashes',
                label: t('set10.notLoadedToCashes'),
                endAdornment: <MenuNotification notificationType={NOT_LOADED_CARDS_ON_CASHES}/>,
            },
            serverType: RETAIL,
            path: `${CARDS}${CARDS_UNLOADED_ON_CASHES}`,
            component: lazy(() =>
                import(/* webpackChunkName: "unloaded-on-cashes" */ '../pages/cards/unloaded-on-cashes/unloaded-on-cashes')),
        },
        {
            navItemProps: {
                id: 'cardsSettings',
                label: t('set10.settings'),
            },
            path: `${CARDS}${SETTINGS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "cards-settings" */ '../pages/cards/cards-settings/cards-settings')),
        },
    ]
})
