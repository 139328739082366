import * as React from 'react'
import { t } from 'i18next'
import classNames from 'classnames'
import {inject, observer} from 'mobx-react'
import {Table} from '@crystalservice/crystals-ui/lib/components/table/table'
import {RIGHT, TEXT, NUMBER} from '@crystalservice/crystals-ui/lib/components/table/column'
import Button from '@material-ui/core/Button'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import {toJS} from 'mobx'
import Divider from '@material-ui/core/Divider'
import AddCircle from '@material-ui/icons/AddCircle'
import {PriceCheckerSettingsDialog} from './price-checkers-settings-dialog'
import {AppStore} from '../../../store/app-store'
import {PriceCheckerVO} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-checker-vo'
import {
    DATE_OF_MANUFACTURE_LOADING, DATE_OD_MANUFACTURE_CHANGING
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-checker-type'
import {AdaptiveIconButton} from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {APP_STORE, SHOP_STORE, SHOP_PRICE_CHECKERS_STORE, SHOP_SCALES_STORE} from '../../../store/stores'
import {ShopDevicesDialog} from './devices/shop-devices-dialog'
import { DevicesClasses } from '../../../core/devices-classes'
import {ShopPriceCheckersStore} from '../../../store/shops/shop-settings/shop-price-checkers-store'
import {ShopScalesStore} from '../../../store/shops/shop-settings/shop-scales-store'
import {ShopStore} from '../../../store/shops/shop-settings/shop-store'
import { Paginator } from '@crystalservice/crystals-ui/lib/components/table/paginator'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./price-checkers-step.scss')

export interface PriceCheckersStepProps {
    shopStore?: ShopStore
    shopScalesStore?: ShopScalesStore
    shopPriceCheckersStore?: ShopPriceCheckersStore
    app?: AppStore
}

@inject(APP_STORE)
@inject(SHOP_STORE)
@inject(SHOP_SCALES_STORE)
@inject(SHOP_PRICE_CHECKERS_STORE)
@observer
export class PriceCheckersStep extends React.Component<PriceCheckersStepProps> {

    componentDidMount(): void {
        const {fetchAllPriceChekersData} = this.props.shopPriceCheckersStore
        fetchAllPriceChekersData()
    }

    render() {
        const {
            sortedPriceCheckers, priceCheckersModels, deletePriceChecker, priceCheckerDevicesDialogOpen,
            editPriceChecker, showPriceCheckersDevicesDialog, closePriceCheckersDevicesDialog,
            addPriceChecker, showNotBoundPriceCheckers, toggleShowNotBoundPriceCheckers,
            paginatorKey, paginationState, setPaginationState,
        }  = this.props.shopPriceCheckersStore
        const {shop} = this.props.shopStore
        const {scalesTemplates} = this.props.shopScalesStore
        const {isCentrum} = this.props.app

        const addButtons = (
            <>
                <Button
                    id="addPriceCheckerButton"
                    className={styles.addButton}
                    onClick={addPriceChecker}
                >
                    <AddCircle
                        className={styles.icon}
                        color="primary"
                    />
                    <span>
                        {t('shopPage.addPriceChecker')}
                    </span>
                </Button>

                <Button
                    id="registerDeviceButton"
                    className={styles.addButton}
                    onClick={showPriceCheckersDevicesDialog}
                >
                    <AddCircle
                        className={styles.icon}
                        color="primary"
                    />
                    <span>
                        {t('shopPage.registerModel')}
                    </span>
                </Button>
            </>
        )

        return (
            <div
                id="priceCheckersStep"
                className={styles.priceCheckersStep}
            >
                <ShopDevicesDialog
                    deviceClass={DevicesClasses.PriceCheckersDeviceClass}
                    title={t('devices.registeredPriceCheckers')}
                    open={priceCheckerDevicesDialogOpen}
                    onClose={closePriceCheckersDevicesDialog}
                />
                <PriceCheckerSettingsDialog/>

                <p className={styles.header}>
                    {t('shopPage.priceCheckersStep')}
                </p>

                {isCentrum &&
                    <Checkbox
                        id="toggleNoBoundPriceCheckers"
                        className={styles.notBound}
                        checked={showNotBoundPriceCheckers}
                        onChange={toggleShowNotBoundPriceCheckers}
                        label={t('shopPage.toggleNotBound')}
                    />
                }

                {sortedPriceCheckers.length === 0 ? (
                    <div>
                        <Divider/>
                        <Empty message={t('shopPage.noAddedDevices')}/>
                        <Divider className={styles.emptyDivider}/>
                        {addButtons}
                    </div>
                ) : (
                    <Paginator
                        defaultItems={toJS(sortedPriceCheckers)}
                        additionalControls={addButtons}
                        key={paginatorKey}
                        defaultPagination={paginationState}
                        onPageItemsChange={setPaginationState}
                    >
                        <Table
                            id="priceCheckersTable"
                            withHeader
                            collapsible
                            keyField="id"
                            columns={[
                                {
                                    header: t('shopPage.linkStatus'),
                                    renderer: itemProps => {
                                        let priceChecker: PriceCheckerVO = itemProps.item
                                        return (
                                            <div
                                                className={classNames(
                                                    styles.statusCell,
                                                    {[styles.active]: priceChecker.active}
                                                )}
                                            >
                                                {priceChecker.active ? t('common.yes') : t('common.no')}
                                            </div>
                                        )
                                    },
                                    padding: '16px 16px 16px 24px',
                                },
                                {
                                    header: t('shopPage.deviceNumber'),
                                    labelField: 'number',
                                    dataType: NUMBER,
                                    width: '10%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.priceCheckerType'),
                                    labelFunction: (priceChecker: PriceCheckerVO) => {
                                        switch (priceChecker.type) {
                                            case DATE_OD_MANUFACTURE_CHANGING:
                                                return t('shopPage.priceCheckerChanging')
                                            case DATE_OF_MANUFACTURE_LOADING:
                                                return t('shopPage.priceCheckerLoading')
                                            default:
                                                return t('shopPage.priceCheckerDisplay')
                                        }
                                    },
                                    width: '20%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.macAddress'),
                                    labelField: 'mac',
                                    dataType: TEXT,
                                    width: '20%',
                                    padding: '16px',
                                },
                                {
                                    header: t('common.name'),
                                    labelField: 'name',
                                    dataType: TEXT,
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.deviceModel'),
                                    labelFunction: (priceChecker: PriceCheckerVO) => {
                                        let modelId = priceChecker.equipmentModelId
                                        let model = priceCheckersModels.find(model => model.id === modelId)
                                        if (model) {
                                            return model.localizedName
                                        }
                                        return ''
                                    },
                                    padding: '16px',
                                },
                                {
                                    header: isCentrum ? t('shopPage.shopNumber') : t('shopPage.scalesTemplate'),
                                    labelFunction: (priceChecker: PriceCheckerVO) => {
                                        if (isCentrum) {
                                            return priceChecker.shopNumber !== -1 ? String(priceChecker.shopNumber) : ''
                                        }

                                        let templateId = priceChecker.scalesTemplateId
                                        if (priceChecker.type === DATE_OF_MANUFACTURE_LOADING && templateId !== -1) {
                                            let scalesTemplate = scalesTemplates.find(template => template.id === templateId)
                                            if (scalesTemplate) {
                                                return scalesTemplate.name
                                            }
                                        }
                                        return ''
                                    },
                                    padding: '16px',
                                },
                                {
                                    width: '120px',
                                    padding: '0 10px',
                                    hAlign: RIGHT,
                                    showOnRowHover: true,
                                    withControls: true,
                                    renderer: itemProps => {
                                        let priceChecker: PriceCheckerVO = itemProps.item
                                        return (
                                            <div className={styles.adaptiveIcons}>
                                                <AdaptiveIconButton
                                                    id={`priceChecker${priceChecker.id}EditButton`}
                                                    label={t('common.edit')}
                                                    onClick={() => editPriceChecker(priceChecker)}
                                                >
                                                    <EditIcon />
                                                </AdaptiveIconButton>
                                                <AdaptiveIconButton
                                                    id={`priceChecker${priceChecker.id}RemoveButton`}
                                                    label={t('common.remove')}
                                                    onClick={() => deletePriceChecker(priceChecker)}
                                                >
                                                    <DeleteIcon />
                                                </AdaptiveIconButton>
                                            </div>
                                        )
                                    }
                                }
                            ]}
                        />
                    </Paginator>
                )}
            </div>
        )
    }
}
