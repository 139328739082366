import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import {t} from 'i18next'
import {NotCreated} from '../../set10/components/stub-with-img/not-created'
import { ROUTING_STORE } from '../../set10/store/stores'

const styles = require('./page-mock.scss')

@inject(ROUTING_STORE)
@observer
export class PageMock extends React.Component<PageMockProps> {
    render() {
        return (
            <div className={styles.pageMock}>
                <NotCreated
                    hintText={t('set10.pageMockHint', {
                        route: this.props.routing.location.pathname,
                        interpolation: { escapeValue: false }
                    })}
                />
            </div>
        )
    }
}

export interface PageMockProps extends React.HTMLProps<PageMock> {
    routing?: RouterStore
}
