export interface TimePeriod {
    '@class': 'ru.crystals.operday.gui.datastructs.TimePeriod'
    startDate?: Date
    endDate?: Date
}

export const createTimePeriod = (initialization: Partial<TimePeriod>): TimePeriod => {
    if (!initialization) return null
    
    let result: TimePeriod = {
        startDate: initialization.startDate,
        endDate: initialization.endDate,
        '@class': 'ru.crystals.operday.gui.datastructs.TimePeriod',
    }
    
    return result
}
