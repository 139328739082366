export type NotSpecified = 'notSpecified'
export const NOT_SPECIFIED: string = 'notSpecified'
export const NEW: string = 'new'
export const INTEGER_MAX_VALUE: number = 2 ** 31 - 1

export interface LabeledValue {
    label: string,
    value: string | number
}

export enum DeviceSettingsType {
    EDIT = 0,
    NEW = 1,
}
