import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import moment from 'moment'
import { PRESENT_CARDS_STORE } from '../../../../store/stores'
import { PresentCardsStore } from '../../../../store/cards/present-cards-store'
import GoToIcon from '@material-ui/icons/NavigateNext'
import { BaseTable, BaseTableProps } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { withSelectionAsColumn } from '@crystalservice/crystals-ui/lib/components/new-table/with-selection-as-column'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { CardVO } from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/card-vo'
import { AdaptiveIconButton } from '../../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { goTo } from '../../../../utils/router-util'
import { CARDS, PRESENT_CARDS, PRESENT_CARDS_CATEGORY_EDIT } from '../../../../core/app-routes'
import {
    CardStatus, Create, Active, Blocked, Inactive, Used,
} from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/card-status'
import { NotSearched } from '../../../../components/stub-with-img/not-searched'
import { NothingFound } from '../../../../components/stub-with-img/nothing-found'
import { formatCardNumber } from '../../../../core/cards/cards-util'

const TableWithSelection = withSelectionAsColumn<CardVO, BaseTableProps<CardVO>>(BaseTable)

export const getCardStatusLabel = (status: CardStatus): string => {
    switch (status) {
        case Create:
            return t('presentCards.list.status.create')
        case Active:
            return t('presentCards.list.status.active')
        case Blocked:
            return t('presentCards.list.status.blocked')
        case Inactive:
            return t('presentCards.list.status.inactive')
        case Used:
            return t('presentCards.list.status.used')
        default:
            return ''
    }
}

interface CardsListTableProps {
    presentCardsStore?: PresentCardsStore
    disabled?: boolean
}

export const PresentCardsListTable: React.FC<CardsListTableProps> = inject(PRESENT_CARDS_STORE)(
    observer(
        props => {
            const keyFunc = (item: CardVO) => item.id
            const {
                cards,
                selectedCards,
                setSelectedCards,
            } = props.presentCardsStore

            return (
                <>
                    {cards?.length > 0 && (
                        <TableWithSelection
                            selectedKeys={selectedCards.map(card => card.id)}
                            onSelectionChange={(keys: number[]) => {
                                if (keys.length === selectedCards.length) return
                                const _cards = cards.filter(card => keys.includes(card.id))
                                setSelectedCards(_cards)
                            }}
                            keyFunction={keyFunc}
                            items={cards}
                            id="cardsTable"
                            fullHeight disabled={props.disabled}
                            hovarableRows
                            onRowClick={({ rowData }: {rowData: CardVO}) => {
                                goTo(`${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORY_EDIT}/${rowData.number}`)
                            }}
                            columns={[
                                {
                                    padding: '0 0 0 14px',
                                    header: t('presentCards.list.cardsNumber'),
                                    keyField: 'name',
                                    renderer: item => formatCardNumber(item.number),
                                },
                                {
                                    header: t('presentCards.list.table.nominal'),
                                    keyField: 'amount',
                                    renderer: item => Number(item.amount / 100),
                                },
                                {
                                    header: t('presentCards.list.table.balance'),
                                    keyField: 'balance',
                                    renderer: item => Number(item.balance / 100),
                                },
                                {
                                    header: t('presentCards.list.table.issued'),
                                    keyField: 'activationDate',
                                    renderer: item => {
                                        if (!item.activationDate) return t('presentCards.list.notActivatedYet')

                                        return moment(item.activationDate).format('D MMM YYYY')
                                    },
                                },
                                {
                                    header: t('presentCards.list.table.released'),
                                    keyField: 'createDate',
                                    renderer: item => moment(item.createDate).format('D MMM YYYY'),
                                },
                                {
                                    header: t('presentCards.list.table.status'),
                                    keyField: 'status',
                                    renderer: item => getCardStatusLabel(item.status),
                                },
                                {
                                    header: t('presentCards.list.table.suitableTo'),
                                    keyField: 'expirationDate',
                                    renderer: item => {
                                        if (!item.expirationDate) return t('presentCards.list.noExpirationDate')

                                        return moment(item.expirationDate).format('D MMM YYYY')
                                    },
                                },
                                {
                                    header: '',
                                    keyField: 'controlBtn',
                                    renderer: item => {
                                        return (
                                            <AdaptiveIconButton
                                                adaptive={false}
                                                id={`goToCard${item.guid}`}
                                                label={t('presentCards.list.openCard')}
                                            >
                                                <GoToIcon />
                                            </AdaptiveIconButton>
                                        )
                                    },
                                    columnWidth: 60,
                                    showOnHover: true,
                                },
                            ]}
                        />
                    )}
                    {!cards && (
                        <NotSearched
                            titleText=""
                            hintText={t('presentCards.list.enterCardsNumbers')}
                        />
                    )}
                    {cards?.length === 0 && (
                        <NothingFound/>
                    )}
                </>
            )
        }
    )
)
