import * as React from 'react'
import { t } from 'i18next'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { SelectInputProps, DefaultSelectOption, SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

interface MeasureUnitSelectProps extends SelectInputProps<DefaultSelectOption> {
    session: string,
    cleanAfterSelect?: boolean
}

interface MeasureUnitSelectState {
    value: string
}

export class MeasureUnitSelect extends React.PureComponent<MeasureUnitSelectProps, MeasureUnitSelectState> {

    state: MeasureUnitSelectState = {
        value: ''
    }

    render() {
        const { onSelect, session, cleanAfterSelect, ref, ...other } = this.props
        const { value } = this.state

        return (
            <SelectInput
                id="measureUnitsSelectInput"
                label={t('set10.measureUnits')}
                fetchFunction={() => iProductsManagerLocal.getSimpleMeasure(session, 0, 1000, { useCache: true })
                    .then(productTypes => productTypes.map(type => ({ label: type.name, value: type.id })))}
                value={value}
                onSelect={selectedOption => {
                    if (!cleanAfterSelect) {
                        this.setState({ value: selectedOption.value })
                    }
                    else {
                        this.setState({value: ''})
                    }
                    onSelect(selectedOption)
                }}
                {...other}
            />
        )
    }
}
