import { EnTopologyLevel } from './en-topology-level'
import { FormatVO } from './format-vo'

export interface TopologyFilterVO {
    '@class': 'ru.crystals.setretailx.topology.TopologyFilterVO'
    id?: number
    level?: EnTopologyLevel
    formats?: FormatVO[]
    onlyTwentyFourHours?: boolean
}

export const createTopologyFilterVO = (initialization: Partial<TopologyFilterVO>): TopologyFilterVO => {
    if (!initialization) return null
    
    let result: TopologyFilterVO = {
        id: initialization.id,
        level: initialization.level,
        formats: initialization.formats,
        onlyTwentyFourHours: initialization.onlyTwentyFourHours,
        '@class': 'ru.crystals.setretailx.topology.TopologyFilterVO',
    }
    
    return result
}
