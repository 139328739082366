import { ESLConfigurationVO } from './set-retail10-commons/set-esl-api/esl-configuration-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class ESLConfiguration extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-ESL/ESLConfiguration!ru.crystals.setretailx.esl.configuration.ESLConfiguration'
    }

    load = (options?: RequestOptions): Promise<ESLConfigurationVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'load',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    save = (configuration: ESLConfigurationVO, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'save',
                params: [configuration]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const eSLConfiguration: ESLConfiguration = new ESLConfiguration()
