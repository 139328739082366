import { CardsConfigurationProperties } from './set-retail10-commons/data-structs-module/cards-configuration-properties'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class CardsConfigurationLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Cards/SET-Cards-GUI-CardsConfiguration!ru.crystals.cards.gui.CardsConfigurationLocal'
    }

    getProperties = (options?: RequestOptions): Promise<CardsConfigurationProperties> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getProperties',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    setProperties = (properties: CardsConfigurationProperties, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'setProperties',
                params: [properties]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const cardsConfigurationLocal: CardsConfigurationLocal = new CardsConfigurationLocal()
