export interface ShiftOpenDocumentVO {
    '@class': 'ru.crystals.setretailx.cash.templates.ShiftOpenDocumentVO'
    id?: number
    documentId?: number
}

export const createShiftOpenDocumentVO = (initialization: Partial<ShiftOpenDocumentVO>): ShiftOpenDocumentVO => {
    if (!initialization) return null
    
    let result: ShiftOpenDocumentVO = {
        id: initialization.id,
        documentId: initialization.documentId,
        '@class': 'ru.crystals.setretailx.cash.templates.ShiftOpenDocumentVO',
    }
    
    return result
}
