import { action, reaction, observable, runInAction, toJS, computed, IReactionDisposer } from 'mobx'
import { CUSTOM_PRINTING_BASKET_STORE, PRICE_TAGS_PRINTING_STORE } from '../stores'
import {
    CustomPrintingBasketStore,
    priceNumberToEditableProperty,
    BasketItem
} from './custom-printing-basket-store'
import { getStore } from '../stores-repository'
import { priceTagFacadeLocal } from '../../../protocol/set10/price-tag-facade-local'
import { ACTIVE } from '../../core/products/product-statuses'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { ProductVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/product-vo'
import { PriceVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/price-vo'
import { XLSX } from '../../core/file-types'
import { config } from '../../config/config'
import { PRICE_TAGS, PRINTING, CUSTOM_PRINTING } from '../../core/app-routes'
import { PriceTagsPrintingStore, ShelfParams } from './printing/printing-store'
import { NAME } from '../../core/price-tags/product-properties'
import { PriceNumber } from '../../core/products/prices'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { fromServerToClientTime, fromClientToServerTime } from '../../utils/app-util'
import { SideBarFiltersState } from '../../components/filters/side-bar-filters'
import { chunk } from 'lodash'
import { withSpinner } from '../with-spinner'
import { chunkedLoading } from '../../core/chunked-loading'
import { DEFAULT_PRICE_TAGS_PAGINATION } from '../../core/price-tags/price-tags-util'

export interface DisplayedProduct {
    name: string
    marking: string
    barCode: string
    wholesaleLevelActive: boolean
    pricesMap: Map<PriceNumber, string>
    selected: boolean
}

export enum DateOfPriceOption {
    CURRENT_PRICES = 'currentPrices',
    TODAY_PRICES = 'todayPrices',
    TOMORROW_PRICES = 'tomorrowPrices',
    MANUAL_PRICES = 'manualPrices',
}

export class CustomPrintingRegularPriceTagsStore {

    @observable
    products: ProductVO[] = null

    @observable
    dateOfPrice: Date = null

    @observable
    dateOfPriceType: DateOfPriceOption = DateOfPriceOption.CURRENT_PRICES

    @observable
    searchArguments: SearchArgumentVO[] = null

    @observable
    currentFiltersState: SideBarFiltersState = null

    @observable
    pagination: PaginationState = DEFAULT_PRICE_TAGS_PAGINATION

    private reactionDisposer: IReactionDisposer = null

    private basketStore: CustomPrintingBasketStore = getStore(CUSTOM_PRINTING_BASKET_STORE)
    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    @computed
    get displayedProducts(): DisplayedProduct[] {
        if (!this.products) {
            return null
        }
        const { regularBasketItems } = this.basketStore

        return this.products.map(product => {
            const basketItem = regularBasketItems.find(basketItem => basketItem.objectId === product.marking)

            function getPriceValue(priceNumber: PriceNumber): string {
                return (basketItem && basketItem.changes[priceNumberToEditableProperty(priceNumber)])
                    || priceFromProduct(product, priceNumber) && priceFromProduct(product, priceNumber).value
            }

            const item: DisplayedProduct = {
                name: (basketItem && basketItem.changes[NAME]) || product.fullName || product.name,
                marking: product.marking,
                barCode: product.barCode,
                wholesaleLevelActive: product.wholesaleLevelActive,
                pricesMap: new Map<number, string>(),
                selected: basketItem?.selected
            }

            item.pricesMap.set(PriceNumber.FIRST, getPriceValue(PriceNumber.FIRST))
            item.pricesMap.set(PriceNumber.SECOND, getPriceValue(PriceNumber.SECOND))
            item.pricesMap.set(PriceNumber.THIRD, getPriceValue(PriceNumber.THIRD))
            item.pricesMap.set(PriceNumber.FOURTH, getPriceValue(PriceNumber.FOURTH))
            item.pricesMap.set(PriceNumber.FIFTH, getPriceValue(PriceNumber.FIFTH))

            return item

        })
    }

    @computed
    get selectedProductIds(): string[] {
        return this.basketStore.regularBasketItems
            .filter(i => i.selected)
            .filter(i => {
                return this.displayedProducts && this.products.some(p => p.marking === i.objectId)
            })
            .map(i => i.objectId)
    }

    setReaction = (): void => {
        this.reactionDisposer = reaction(
            () => this.basketStore.regularBasketItems.map(i => i.objectId),
            () => this.fetchProducts()
        )
    }

    disposeReaction = (): void => {
        if (this.reactionDisposer) {
            this.reactionDisposer()
        }
        this.reactionDisposer = null
    }

    @action
    applyFilters = (searchArguments: SearchArgumentVO[]): void => {
        this.searchArguments = searchArguments
        this.fetchProducts()
    }

    @action
    clearFilters = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
        this.searchArguments = null
        this.fetchProducts()
    }

    fetchProducts = async (): Promise<void> => {
        const basketIds: string[] = this.basketStore.regularBasketItems.map(item => item.objectId)
        if (basketIds.length === 0) {
            runInAction(() => {
                this.products = []
            })
            return
        }

        return withSpinner(async () => {
            const productIds: string[] = await priceTagFacadeLocal.getObjectsIds1(basketIds, toJS(this.searchArguments), ACTIVE) || []

            let products: ProductVO[] = await chunkedLoading(productIds, productChunkIds => {
                return priceTagFacadeLocal.getProducts2(productChunkIds, fromServerToClientTime(this.dateOfPrice), ACTIVE)
            })

            // TODO SFM-208 фиксим огрехи транспорта
            products = castTimestampToDateInProducts(fixProductsOrder(products, productIds))
            runInAction(() => {
                this.products = products
                this.pagination.total = products.length
                this.pagination.page = 1
            })
        })
    }

    @action
    changeDateOfPriceType = (dateOfPriceType: DateOfPriceOption): void => {
        this.dateOfPriceType = dateOfPriceType

        switch (dateOfPriceType) {
            default:
                return
            case DateOfPriceOption.CURRENT_PRICES:
            case DateOfPriceOption.MANUAL_PRICES:
                return this.changeDateOfPrice(this.now())
            case DateOfPriceOption.TODAY_PRICES: {
                const newDate: Date = this.now()
                if (newDate.getHours() < 12) {
                    newDate.setHours(12, 0, 0)
                }

                return this.changeDateOfPrice(newDate)
            }
            case DateOfPriceOption.TOMORROW_PRICES: {
                const newDate: Date = this.now()
                newDate.setDate(newDate.getDate() + 1)
                newDate.setHours(12, 0, 0)

                return this.changeDateOfPrice(newDate)
            }
        }
    }

    changeDateOfPrice = (dateOfPrice: Date): void => {
        this.setDateOfPrice(dateOfPrice)
        this.fetchProducts()
    }

    downloadExcelHandler = async (): Promise<void> => {
        const reportWindow = window.open()
        const fileName: string = await priceTagFacadeLocal.getFileNameForPriceTagShelf(
            toJS(this.selectedProductIds),
            this.basketStore.regularBasketItems.length,
            ACTIVE,
            XLSX
        )

        const reportUrl: string = `${config.reportsAddress}?Action=getFile&FILE_NAME=${fileName}`
        // window.open сработает только если он был вызван в главном потоке
        // поэтому мы сначала открываем окно, а потом подменяем ему адрес
        reportWindow.location.replace(reportUrl)
    }

    printPriceTags = (): void => {
        this.printingStore.openPrinting(
            PRICE_TAGS + CUSTOM_PRINTING + PRINTING,
            {
                productStatus: ACTIVE,
                objectIds: toJS(this.selectedProductIds),
                date: this.getDateForPrinting(),
                substitutions: getProductSubstitutionObject(this.basketStore.regularBasketItems, this.products),
                handlePrint: this.removeCurrentlyPrintedProductsFromBasket
            }
        )
    }

    @action
    setDateOfPrice = (dateOfPrice: Date): void => {
        this.dateOfPrice = dateOfPrice
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
    }

    @action
    setPagination = (pagination: PaginationState): void => {
        this.pagination = pagination
    }

    removeCurrentlyPrintedProductsFromBasket = (shelfParamsOfCurrentlyPrintedProducts: ShelfParams): void => {
        const { productStatus, objectIds } = shelfParamsOfCurrentlyPrintedProducts

        if (shelfParamsOfCurrentlyPrintedProducts) {
            this.basketStore.removeSelectedItems(productStatus, objectIds)
        }
    }

    handleAllTasksCompleted = (): void => {
        this.fetchProducts()
    }

    @action
    reset = (): void => {
        this.products = null
        this.dateOfPriceType = DateOfPriceOption.CURRENT_PRICES
        this.dateOfPrice = null
        this.searchArguments = null
        this.currentFiltersState = null
        this.pagination = DEFAULT_PRICE_TAGS_PAGINATION
        this.disposeReaction()
    }

    getDateForPrinting = (): Date => {
        let result: Date
        switch (this.dateOfPriceType) {
            case DateOfPriceOption.CURRENT_PRICES:
                result = this.now()
                break
            case DateOfPriceOption.MANUAL_PRICES:
                result = this.dateOfPrice ?
                    this.dateOfPrice :
                    this.now()
                break
            case DateOfPriceOption.TODAY_PRICES: {
                const newDate: Date = this.now()
                if (newDate.getHours() < 12) {
                    newDate.setHours(12, 0, 0)
                }

                result = newDate
                break
            }
            case DateOfPriceOption.TOMORROW_PRICES: {
                const newDate: Date = this.now()
                newDate.setDate(newDate.getDate() + 1)
                newDate.setHours(12, 0, 0)

                result = newDate
                break
            }
            default:
                result = null
        }

        return fromServerToClientTime(result)
    }

    // чтобы удобно было мокать в тестах
    now = (): Date => {
        return fromClientToServerTime(new Date())
    }
}

// TODO кастим beginDate к Date https://crystals.atlassian.net/browse/SFM-208
function castTimestampToDateInProducts(products: ProductVO[]): ProductVO[] {
    return products.map(product => {
        if (product.pricesMap) {
            Object.keys(product.pricesMap).forEach(key => {
                product.pricesMap[key] = {
                    ...product.pricesMap[key],
                    beginDate: product.pricesMap[key].beginDate && new Date(product.pricesMap[key].beginDate)
                }
            })
        }

        if (product.prices99Map) {
            Object.keys(product.prices99Map).forEach(key => {
                product.prices99Map[key] = {
                    ...product.prices99Map[key],
                    beginDate: product.prices99Map[key].beginDate && new Date(product.prices99Map[key].beginDate)
                }
            })
        }

        return product
    })
}

export function priceFromProduct(product: ProductVO, priceNumber: PriceNumber): PriceVO {
    return product.pricesMap[priceNumber] || product.prices99Map[priceNumber] || null
}

function getProductSubstitutionObject(basketItems: BasketItem[], products: ProductVO[]): { [key: string]: { [key: string]: string } } {
    const result: { [key: string]: { [key: string]: string } } = {}

    basketItems
        .filter(i => i.selected)
        .filter(i => products && products.some(p => p.marking === i.objectId))
        .filter(item => item.changes && Object.keys(item.changes).length > 0)
        .forEach(item => {
            result[item.objectId] = item.changes
        })

    return result
}

// TODO SFM-208 const const products = await priceTagFacadeLocal.getProducts2(productIds...)
// здесь products вернётся не в том порядке, что productIds. Фиксим это
function fixProductsOrder(products: ProductVO[], productIds: string[]): ProductVO[] {
    return [...products].sort((a: ProductVO, b: ProductVO) => {
        const aIndex = productIds.findIndex(id => id === a.marking)
        const bIndex = productIds.findIndex(id => id === b.marking)

        return aIndex - bIndex
    })
}
