import { OperDay } from './set-retail10-commons/set-oper-day-api/oper-day'
import { CashDesk } from './set-retail10-commons/set-oper-day-api/cash-desk'
import { OperDayInfo } from './set-retail10-commons/set-oper-day-api/oper-day-info'
import { Shift } from './set-retail10-commons/set-oper-day-api/shift'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class CashDeskManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-OperDay/SET/CashDeskManager!ru.crystals.operday.gui.flexendpoints.cashdesk.CashDeskManagerLocal'
    }

    closeOperDay = (sessionId: string, operDay: OperDay, options?: RequestOptions): Promise<OperDay> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'closeOperDay',
                params: [sessionId, operDay]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    findCashDesks1 = (sessionId: string, odDate: Date, options?: RequestOptions): Promise<OperDay> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findCashDesks',
                params: [sessionId, odDate]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    findCashDesks2 = (sessionId: string, odDate: Date, locale: string, options?: RequestOptions): Promise<OperDay> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findCashDesks',
                params: [sessionId, odDate, locale]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getCashDeskInfo1 = (sessionId: string, cashDesk: CashDesk, operDay: Date, options?: RequestOptions): Promise<CashDesk> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCashDeskInfo',
                params: [sessionId, cashDesk, operDay]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getCashDeskInfo2 = (sessionId: string, cashDesk: CashDesk, operDay: Date, locale: string, options?: RequestOptions): Promise<CashDesk> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCashDeskInfo',
                params: [sessionId, cashDesk, operDay, locale]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getCashNumbersFromZReportsByRange = (startDate: Date, endDate: Date, options?: RequestOptions): Promise<number[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCashNumbersFromZReportsByRange',
                params: [startDate, endDate]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getOperDayInfo1 = (sessionId: string, odDate: Date, options?: RequestOptions): Promise<OperDayInfo> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getOperDayInfo',
                params: [sessionId, odDate]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getOperDayInfo2 = (sessionId: string, odDate: Date, locale: string, options?: RequestOptions): Promise<OperDayInfo> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getOperDayInfo',
                params: [sessionId, odDate, locale]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getProblematicShiftsJSON = (sessionId: string, options?: RequestOptions): Promise<Shift[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getProblematicShiftsJSON',
                params: [sessionId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    reopenOperDay = (sessionId: string, operDay: OperDay, options?: RequestOptions): Promise<OperDay> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'reopenOperDay',
                params: [sessionId, operDay]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    repair = (sessionId: string, cashDesk: CashDesk, numShift: number, operDayDate: Date, isRepair: boolean, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'repair',
                params: [sessionId, cashDesk, numShift, operDayDate, isRepair]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    sendCloseShiftMessageToCash = (sessionId: string, shopNumber: number, cash: number, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'sendCloseShiftMessageToCash',
                params: [sessionId, shopNumber, cash]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    sendMessageToCashier = (sessionId: string, shopNumber: number, cashDeskIn: number, message: string, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'sendMessageToCashier',
                params: [sessionId, shopNumber, cashDeskIn, message]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const cashDeskManagerLocal: CashDeskManagerLocal = new CashDeskManagerLocal()
