export type XMLBarcodeElementType = null | 'EAN13' | 'EAN8' | 'UPCA' | 'CODE39' | 'CODE128'

export const DEFAULT_BARCODE: XMLBarcodeElementType = null
export const EAN13: XMLBarcodeElementType = 'EAN13'
export const EAN8: XMLBarcodeElementType = 'EAN8'
export const UPCA: XMLBarcodeElementType = 'UPCA'
export const CODE39: XMLBarcodeElementType = 'CODE39'
export const CODE128: XMLBarcodeElementType = 'CODE128'

export const ALL_BARCODE_ELEMENT_TYPES: XMLBarcodeElementType[] = [
    DEFAULT_BARCODE,
    EAN13,
    EAN8,
    UPCA,
    CODE39,
    CODE128
]
