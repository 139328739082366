import React from 'react'
import {
    FilterType,
    ACTION_START_DATE_FILTER,
} from '../../../core/filters/filter'
import { BaseDateRangeFilter } from '../components/base-date-range-filter'
import { InternalFilterComponentProps } from '../new-filters'

export class ActionStartDate extends React.PureComponent<InternalFilterComponentProps>  {
    static filterType: FilterType = ACTION_START_DATE_FILTER

    render() {
        const { id, value, onChange, slim } = this.props
        return (
            <BaseDateRangeFilter
                id={id}
                defaultValue={value}
                filterType={ActionStartDate.filterType}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                slim={slim}
            />
        )
    }
}
