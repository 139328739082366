export type DomEvent = 'click' | 'dblclick' | 'resize' | 'scroll' | 'keydown' | 'change' | 'select'
    | 'input' | 'blur' | 'focus' | 'keypress' | 'submit' | 'mouseenter' | 'mouseleave' | 'mousedown'
    | 'mousemove' | 'mouseup' | 'load' | 'paste' | 'dblclick' | 'keyup'

export const CLICK: DomEvent = 'click'
export const DBLCLICK: DomEvent = 'dblclick'
export const RESIZE: DomEvent = 'resize'
export const SCROLL: DomEvent = 'scroll'
export const SELECT: DomEvent = 'select'
export const KEYDOWN: DomEvent = 'keydown'
export const CHANGE: DomEvent = 'change'
export const INPUT: DomEvent = 'input'
export const BLUR: DomEvent = 'blur'
export const FOCUS: DomEvent = 'focus'
export const KEY_PRESS: DomEvent = 'keypress'
export const KEY_DOWN: DomEvent = 'keydown'
export const KEY_UP: DomEvent = 'keyup'
export const SUBMIT: DomEvent = 'submit'
export const LOAD: DomEvent = 'load'
export const MOUSE_ENTER: DomEvent = 'mouseenter'
export const MOUSE_LEAVE: DomEvent = 'mouseleave'
export const MOUSE_DOWN: DomEvent = 'mousedown'
export const MOUSE_MOVE: DomEvent = 'mousemove'
export const MOUSE_UP: DomEvent = 'mouseup'
export const PASTE: DomEvent = 'paste'
