import { XMLTagBaseElement } from './xml-tag-base-element'

export interface XMLTagImage extends XMLTagBaseElement {
    data?: string // base64
    fileName?: string
}

export function isImage(item: any): item is XMLTagImage {
    if (!item) return false
    if ('data' in item && 'fileName' in item) return true
    return false
}
