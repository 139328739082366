
// Разрешает использование только буквы и цифр (без спец. символов)
export const simpleTextRegExp = /^[\wа-яА-ЯёЁ ]*$/

// Валидация меток в рекламной акции
export const actionLabelsRegExp = /^[\wа-яА-ЯёЁ ,.():!?-]*$/

// Валидация условия ФИО в рекламной акции
export const actionFullNameConditionRegExp = /^[a-zA-Zа-яА-ЯёЁ .*?\-~']*$/

// Мобильный телефон
export const mobilePhoneRegExp = /^\+?[\d -]*$/

// Электронная почта, допустимые символы
export const emailCharactersRegExp = /^[\w\-@=_.]*$/

// Электронная почта, полная валидация
export const emailRegExp = /^[\w\-=_.]+@[\w\-=_.]+\.[\w\-=_]+$/
