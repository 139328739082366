import { CashierDayVO } from './set-retail10-commons/data-structs-module/cashier-day-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class CashiersStatusesFacadeLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-OperDay/CashiersStatusesFacade!ru.crystals.operday.cashiers.facade.CashiersStatusesFacadeLocal'
    }

    calculateCashier = (tabNum: string, shopNum: number, cashNum: number, options?: RequestOptions): Promise<string> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'calculateCashier',
                params: [tabNum, shopNum, cashNum]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    cancelCalculateCashier = (tabNum: string, shopNum: number, cashNum: number, options?: RequestOptions): Promise<string> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'cancelCalculateCashier',
                params: [tabNum, shopNum, cashNum]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getBriefCashiersDays = (date: Date, options?: RequestOptions): Promise<CashierDayVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getBriefCashiersDays',
                params: [date]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getFullCashierDay1 = (tabNum: string, date: Date, options?: RequestOptions): Promise<CashierDayVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getFullCashierDay',
                params: [tabNum, date]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getFullCashierDay2 = (tabNum: string, date: Date, options?: RequestOptions): Promise<CashierDayVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getFullCashierDay',
                params: [tabNum, date]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const cashiersStatusesFacadeLocal: CashiersStatusesFacadeLocal = new CashiersStatusesFacadeLocal()
