import * as React from 'react'
import { t } from 'i18next'
import {inject, observer} from 'mobx-react'
import {Table} from '@crystalservice/crystals-ui/lib/components/table/table'
import {RIGHT, TEXT, NUMBER} from '@crystalservice/crystals-ui/lib/components/table/column'
import { MenuButton } from '@crystalservice/crystals-ui/lib/components/buttons/menu-button/menu-button'
import Button from '@material-ui/core/Button'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import {toJS} from 'mobx'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AddCircle from '@material-ui/icons/AddCircle'
import {ScalesVO} from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-vo'
import {FROZEN} from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-state'
import {ScalesSettingsDialog} from './scales-settings-dialog'
import {SHOP_SCALES_STORE} from '../../../store/stores'
import {ShopDevicesDialog} from './devices/shop-devices-dialog'
import { DevicesClasses } from '../../../core/devices-classes'
import { ShopScalesStore, ScaleModelsWithLoadableHeaders } from '../../../store/shops/shop-settings/shop-scales-store'
import { Paginator } from '@crystalservice/crystals-ui/lib/components/table/paginator'

const styles = require('./scales-step.scss')

export interface ScalesStepProps {
    shopScalesStore?: ShopScalesStore
}

@inject(SHOP_SCALES_STORE)
@observer
export class ScalesStep extends React.Component<ScalesStepProps> {

    componentDidMount(): void {
        const {fetchAllScalesData} = this.props.shopScalesStore
        fetchAllScalesData()
    }

    render() {
        const {
            sortedScales, deleteScales, scalesDevicesDialogOpen, paginatorKey, paginationState, setPaginationState,
            loadProductsToScales, editScales, showScalesDevicesDialog,
            addScales, updateScalesState, closeScalesDevicesDialog,
            scaleModelLabelsByName, loadHeadersToScale
        }  = this.props.shopScalesStore

        const addButtons = (
            <>
                <Button
                    id="addScalesButton"
                    className={styles.addButton}
                    onClick={addScales}
                >
                    <AddCircle
                        className={styles.icon}
                        color="primary"
                    />
                    <span>
                        {t('shopPage.addScales')}
                    </span>
                </Button>
                <Button
                    id="registerDeviceButton"
                    className={styles.addButton}
                    onClick={showScalesDevicesDialog}
                >
                    <AddCircle
                        className={styles.icon}
                        color="primary"
                    />
                    <span>
                        {t('shopPage.registerModel')}
                    </span>
                </Button>
            </>
        )

        return (
            <div
                id="scalesPage"
                className={styles.scalesStepContent}
            >
                <ShopDevicesDialog
                    deviceClass={DevicesClasses.ScalesDeviceClass}
                    title={t('devices.registeredScales')}
                    open={scalesDevicesDialogOpen}
                    onClose={closeScalesDevicesDialog}
                />
                <ScalesSettingsDialog/>

                <p className={styles.header}>
                    {t('shopPage.addedScales')}
                </p>

                {sortedScales.length === 0 ? (
                    <div>
                        <Divider/>
                        <Empty message={t('shopPage.noAddedDevices')}/>
                        <Divider className={styles.emptyDivider}/>
                        {addButtons}
                    </div>
                ) : (
                    <Paginator
                        defaultItems={toJS(sortedScales)}
                        additionalControls={addButtons}
                        key={paginatorKey}
                        defaultPagination={paginationState}
                        onPageItemsChange={setPaginationState}
                    >
                        <Table
                            id="scalesTable"
                            withHeader
                            collapsible
                            keyField="id"
                            columns={[
                                {
                                    header: t('shopPage.deviceNumber'),
                                    labelField: 'number',
                                    dataType: NUMBER,
                                    width: '10%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.deviceTemplate'),
                                    labelFunction: (item: ScalesVO) => {
                                        return item.scalesTemplate.name
                                    },
                                    dataType: TEXT,
                                    width: '20%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.deviceAddressing'),
                                    labelField: 'addressing',
                                    dataType: TEXT,
                                    width: '25%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.deviceState'),
                                    renderer: itemProps => {
                                        let scales: ScalesVO = itemProps.item
                                        let frozen = scales.state === FROZEN
                                        return (
                                            <Typography
                                                color={frozen ? 'textSecondary' : 'primary'}
                                                className={styles.statusCell}
                                            >
                                                {frozen ? t('shopPage.scaleFrozen') : t('shopPage.scaleActive')}
                                            </Typography>
                                        )
                                    },
                                    width: '20%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.deviceModel'),
                                    labelFunction: (item: ScalesVO) => {
                                        return scaleModelLabelsByName[item.model]
                                    },
                                    dataType: TEXT,
                                    padding: '16px',
                                },
                                {
                                    width: '10%',
                                    padding: '0 10px',
                                    hAlign: RIGHT,
                                    showOnRowHover: true,
                                    withControls: true,
                                    renderer: itemProps => {
                                        let scales: ScalesVO = itemProps.item
                                        let frozen = scales.state === FROZEN
                                        let showLoadHeaders = ScaleModelsWithLoadableHeaders.includes(scales.model)
                                        return (
                                            <MenuButton
                                                id={`scales${scales.id}MenuButton`}
                                                adaptive
                                                labelsContainerClassName={styles.menuButton}
                                                buttons={[
                                                    {
                                                        id: `scales${scales.id}EditButton`,
                                                        label: t('common.edit'),
                                                        onClick: () => editScales(scales)
                                                    },
                                                    {
                                                        id: `scales${scales.id}LoadButton`,
                                                        label: t('shopPage.loadToScale'),
                                                        onClick: () => loadProductsToScales(scales),
                                                        disabled: frozen
                                                    },
                                                    {
                                                        id: `scales${scales.id}FreezeButton`,
                                                        label: frozen ? t('shopPage.scaleUnFreeze') : t('shopPage.scaleFreeze'),
                                                        onClick: () => updateScalesState(scales, !frozen)
                                                    },
                                                    {
                                                        id: `scales${scales.id}LoadHeaders`,
                                                        label: t('shopPage.loadHeadersToScale.action'),
                                                        onClick: () => loadHeadersToScale(scales)
                                                    },
                                                    {
                                                        id: `scales${scales.id}RemoveButton`,
                                                        label: t('common.remove'),
                                                        onClick: () => deleteScales(scales)
                                                    }
                                                ].filter(item => item.id !== `scales${scales.id}LoadHeaders` || showLoadHeaders)}
                                            />
                                        )
                                    }
                                }
                            ]}
                        />
                    </Paginator>
                )}
            </div>
        )
    }
}
