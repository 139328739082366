import React from 'react'
import { FilterType, ACTION_COUPON_CODE, getFilterTypeName } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'

export class ActionCouponCode extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_COUPON_CODE

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <TextField
                id={id}
                label={getFilterTypeName(ActionCouponCode.filterType)}
                value={value}
                onValueChange={(value: string) => {
                    onChange(ActionCouponCode.filterType, { value })
                }}
                slim={slim}
            />
        )
    }
}
