import React from 'react'
import classNames from 'classnames'
import { XMLTagTextBlock } from '../../../core/price-tags/xml/xml-tag-text-block'
import { ITALIC } from '../../../core/price-tags/xml/xml-font-style'
import { metersToPixels } from '../../../core/price-tags/price-tags-util'
import { intToHexColor } from '../../../../utils/math/math-util'
import { DEFAULT_FONTS } from '../../../../utils/font-utils'
import { StrikedSvg } from '../../../../components/inputs/select-input/striked-svg'
import { COORDINATE_SCALE } from '../price-tag-template-preview'
import { TextFit } from '../../../../components/text-fit/text-fit'
import { XMLTagFormula } from '../../../core/price-tags/xml/xml-tag-formula'

const styles = require('./price-tag-text-preview.scss')

const CUSTOM_PSEUDO_UNIQUE_DELIMITER = '|⌐□_□|'

interface StringPart {
    value: string
    isMnemonic?: boolean
}

export interface PriceTagTextPreviewProps extends React.HTMLProps<HTMLDivElement> {
    element: XMLTagTextBlock | XMLTagFormula
    scale: number
    parts: StringPart[]
}

export class PriceTagTextPreview extends React.Component<PriceTagTextPreviewProps> {

    render() {
        const { element, scale, parts, ...other } = this.props

        const hexColor = intToHexColor(element.color)
        const pixel: number = scale / metersToPixels(0.001)

        // логика перенесена из flex-проекта
        const fontSize: number = element.fontSize * scale / COORDINATE_SCALE

        return (
            <div
                {...other}
                className={styles.textPreview}
                style={{
                    textAlign: element.textAlign,
                    fontWeight: element.fontWeight,
                    fontStyle: element.fontStyle === ITALIC ? 'italic' : 'normal',
                    textDecoration: element.textDecoration,
                    fontSize,
                    color: hexColor,
                    whiteSpace: element.wordWrap ? 'normal' : 'pre',
                    ...(element.textFit ? {
                        display: 'flex',
                        flexDirection: 'column',
                    } : {}),
                    ...(this.props.style as object || {})
                }}
            >
                <StrikedSvg
                    hexColor={hexColor}
                    className={styles.svgItem}
                    strikedKind={element.striked}
                />
                { element.textFit ? (
                    <TextFit
                        // запускать проверку в TextFit мы хотим только при изменении размеров, либо контента
                        key={element.width + element.height + parts.map(p => p.value).join(CUSTOM_PSEUDO_UNIQUE_DELIMITER)}
                        ignoreChildrenUpdate
                        minFontSize={4 * pixel}
                        step={pixel}
                        dimensionsForFit="width-and-height"
                        style={{ flex: 1 }}
                    >
                        <PartsElements
                            parts={parts}
                            fontFamily={element.fontFamily}
                        />
                    </TextFit>
                ) : (
                    <PartsElements
                        parts={parts}
                        fontFamily={element.fontFamily}
                    />
                ) }
            </div>
        )
    }
}

interface PartsElementsProps {
    fontFamily: string
    parts: StringPart[]
}

const PartsElements: React.FC<PartsElementsProps> = props => {
    return (
        <>
            {
                props.parts.map((part: StringPart, index: number) => {
                    return (
                        <span
                            key={part.value + part.isMnemonic + index}
                            className={classNames({
                                [styles.mnemonic]: part.isMnemonic,
                                [styles.simpleText]: !part.isMnemonic
                            })}
                            style={{
                                fontFamily: `"${props.fontFamily}", ${DEFAULT_FONTS}`
                            }}
                        >
                        { part.value }
                    </span>
                    )
                })
            }
        </>
    )
}
