export type CardStatus = 'Create' | 'Active' | 'Blocked' | 'Inactive' | 'Used' | 'PreActive' | 'PreUsed' | 'PreDeactive' | 'NeedReturn' | 'NeedTakeAway'

export const Create: CardStatus = 'Create'
export const Active: CardStatus = 'Active'
export const Blocked: CardStatus = 'Blocked'
export const Inactive: CardStatus = 'Inactive'
export const Used: CardStatus = 'Used'
export const PreActive: CardStatus = 'PreActive'
export const PreUsed: CardStatus = 'PreUsed'
export const PreDeactive: CardStatus = 'PreDeactive'
export const NeedReturn: CardStatus = 'NeedReturn'
export const NeedTakeAway: CardStatus = 'NeedTakeAway'
