import classNames from 'classnames'
import { isNil } from 'lodash'
import moment from 'moment'
import * as React from 'react'

import {
    BASE_DATE_RANGE_FILTER_DELIMITER,
    FilterType,
    getFilterTypeName,
} from '../../../core/filters/filter'
import {
    DateRangePickerProps,
    DateRangePicker
} from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/date-range-picker/date-range-picker'

export interface BaseDateRangeFilterProps extends Omit<DateRangePickerProps, 'ref'> {
    filterType: FilterType
    defaultValue?: string
    onFilterChange?: (filter: FilterType, value: string) => void
}

export interface BaseDateRangeFilterState {
    value: string
    startDate: Date
    endDate: Date
}

export class BaseDateRangeFilter extends React.PureComponent<BaseDateRangeFilterProps, BaseDateRangeFilterState> {
    static dateFormat: string = 'YYYY-MM-DD HH:mm:ss'
    static dateDelimiter: string = BASE_DATE_RANGE_FILTER_DELIMITER
    static defaultProps: Partial<BaseDateRangeFilterProps> = {
        onFilterChange: () => null
    }

    inputParts: string[] = this.props.defaultValue
        ? this.props.defaultValue.split(BaseDateRangeFilter.dateDelimiter)
        : null

    state: BaseDateRangeFilterState = {
        value: this.props.defaultValue,
        startDate: this.inputParts && this.inputParts[0] && moment(this.inputParts[0]).isValid()
            ? moment(this.inputParts[0]).toDate()
            : null,
        endDate: this.inputParts && this.inputParts[1] && moment(this.inputParts[1]).isValid()
            ? moment(this.inputParts[1]).toDate()
            : null,
    }

    render() {
        const {defaultValue, filterType, onFilterChange, ...other} = this.props
        const {startDate, endDate} = this.state

        return (
            <DateRangePicker
                {...other}
                defaultStartDate={startDate}
                defaultEndDate={endDate}
                onDatesChange={(datePeriodStart, datePeriodEnd) => {
                    const dateStartFormatted: string = !isNil(datePeriodStart)
                        ? moment(datePeriodStart).format(BaseDateRangeFilter.dateFormat)
                        : ''
                    const dateEndFormatted: string = !isNil(datePeriodEnd)
                        ? moment(datePeriodEnd).format(BaseDateRangeFilter.dateFormat)
                        : ''

                    const value = !isNil(datePeriodStart) || !isNil(datePeriodEnd)
                        ? `${dateStartFormatted}${BaseDateRangeFilter.dateDelimiter}${dateEndFormatted}`
                        : ''

                    this.setState({
                        startDate: datePeriodStart,
                        endDate: datePeriodEnd,
                        value,
                    })
                    onFilterChange(filterType, value)
                }}
                label={getFilterTypeName(filterType)}
                withTime
            />
        )
    }
}
