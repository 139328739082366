import { DatePeriod } from './date-period'

export type SegmentStatus = 'UNUSED' | 'ACTIVE' | 'USED' | 'ARCHIVED'

export const UNUSED: SegmentStatus = 'UNUSED'
export const ACTIVE: SegmentStatus = 'ACTIVE'
export const USED: SegmentStatus = 'USED'
export const ARCHIVED: SegmentStatus = 'ARCHIVED'


export interface SegmentsFilter {
    '@class': 'ru.crystals.discounts.SegmentsFilter'
    nameLike?: string
    guidLike?: string
    actionNameLike?: string
    actionGuidLike?: string
    createDate?: DatePeriod
    lastChangeDate?: DatePeriod
    segmentStatus?: SegmentStatus
    createdByLike?: string
    segmentGuids?: string[]
    nameOrCodeLike?: string
}

export const createSegmentsFilter = (initialization: Partial<SegmentsFilter>): SegmentsFilter => {
    if (!initialization) return null
    
    let result: SegmentsFilter = {
        nameLike: initialization.nameLike,
        guidLike: initialization.guidLike,
        actionNameLike: initialization.actionNameLike,
        actionGuidLike: initialization.actionGuidLike,
        createDate: initialization.createDate,
        lastChangeDate: initialization.lastChangeDate,
        segmentStatus: initialization.segmentStatus,
        createdByLike: initialization.createdByLike,
        segmentGuids: initialization.segmentGuids,
        nameOrCodeLike: initialization.nameOrCodeLike,
        '@class': 'ru.crystals.discounts.SegmentsFilter',
    }
    
    return result
}
