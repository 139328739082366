import { observable, action, runInAction, toJS } from 'mobx'
import { t } from 'i18next'
import { uniqWith } from 'lodash'
import moment from 'moment'
import { ShortProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/short-product-vo-sf'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE, APP_STORE } from '../stores'
import {
    NotBindedProductsFilter,
    createNotBindedProductsFilter
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/not-binded-products-filter'
import {
    Filter,
    DEPARTMENT_AUTOCOMPLETE,
    PRODUCT_AUTOCOMPLETE,
    PRODUCT_GROUP_AUTOCOMPLETE
} from '../../core/filters/filter'
import { NO_DEFAULT_PRICE_TAG } from '../../core/products/product-statuses'
import { PriceTagTemplateVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { printersManagerLocal } from '../../../protocol/set10/printers-manager-local'
import { AppStore } from '../app-store'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'

export class NotBindedToPriceTagsProductsStore {
    @observable
    products: ShortProductVO_SF[] = null

    @observable
    selectedProducts: ShortProductVO_SF[] = []

    @observable
    filter: NotBindedProductsFilter = createNotBindedProductsFilter({})

    @observable
    priceTagBindingDialogOpened: boolean = false

    @observable
    priceTags: PriceTagTemplateVO[] = null

    @observable
    selectedPriceTags: PriceTagTemplateVO[] = []

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    fetchProducts = async (): Promise<void> => {
        const products: ShortProductVO_SF[] = await iProductsManagerLocal.getBilletProductsByStatus(
            this.userStore.session,
            NO_DEFAULT_PRICE_TAG,
            // неочевидное поведение фильтра
            // tslint:disable-next-line max-line-length
            // https://crystals.atlassian.net/browse/SFM-262?focusedCommentId=69452&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-69452
            toJS(this.filter)
        )

        runInAction(() => {
            // важно после первого fetch делать products не null, что бы не вернул сервер
            this.products = products || []
            this.adjustSelectedProducts()
        })
    }

    fetchPriceTags = async (): Promise<void> => {
        // важно после первого fetch делать priceTags не null, что бы не вернул сервер
        const priceTags = await printersManagerLocal.getPriceTagTemplates(this.userStore.session) || []
        runInAction(() => {
            // FIXME: SFM-208 -> Дата приходит в unix_time, в протоколе просто Date указано
            priceTags.forEach((item: PriceTagTemplateVO) => {
                item.lastUpdate = moment(item.lastUpdate).toDate()
            })

            this.priceTags = priceTags
        })
    }

    @action
    bindProductsToPriceTag = async (): Promise<void> => {
        // сразу закрываем диалог
        this.priceTagBindingDialogOpened = false

        await printersManagerLocal.bindProductsToPriceTag(
            this.userStore.session,
            this.selectedProducts.map(product => product.code),
            this.selectedPriceTags.map(pt => pt.id)
        )

        // после успешного привязывания показываем снакбар, обнуляем выбранные элементы
        // и запрашиваем заново непривязанные продукты
        this.appStore.showSnackbar({ message: t('common.settingsSaved') })
        runInAction(() => {
            this.selectedPriceTags = []
            this.selectedProducts = []
        })

        this.fetchProducts()
    }

    @action
    adjustSelectedProducts = (): void => {
        // удаляем из текущих выбранных продуктов те, которых нет в this.products

        let newSelectedProducts: ShortProductVO_SF[] = this.selectedProducts.filter(selectedProduct => {
            return this.products.some(product => productToKey(product) === productToKey(selectedProduct))
        })

        this.setSelectedProducts(newSelectedProducts)
    }

    @action
    setPriceTagBindingDialogOpened = (priceTagBindingDialogOpened: boolean) => {
        this.priceTagBindingDialogOpened = priceTagBindingDialogOpened

        // при открытии диалога получаем с сервера список шаблонов, если его ещё нет
        if (priceTagBindingDialogOpened && !this.priceTags) {
            this.fetchPriceTags()
        }
    }

    @action
    handleFiltersApply = (searchArguments: SearchArgumentVO[]): void => {
        const filtersChanges: Partial<NotBindedProductsFilter>
            = searchArguments.reduce((prevFilterVO: Partial<NotBindedProductsFilter>, currentArgument: SearchArgumentVO) => {
            switch (currentArgument.type) {
                case DEPARTMENT_AUTOCOMPLETE:
                    return {
                        ...prevFilterVO,
                        // onFilterChange принмает строку, а нам надо передать число
                        // поэтому приходится делать cast в двух местах
                        // TODO отрефакторить onFilterChange, чтобы принимал T
                        departId: Number(currentArgument.value)
                    }
                case PRODUCT_AUTOCOMPLETE:
                    return {
                        ...prevFilterVO,
                        productCode: currentArgument.value
                    }
                case PRODUCT_GROUP_AUTOCOMPLETE:
                    return {
                        ...prevFilterVO,
                        productGroupName: currentArgument.value
                    }
                default:
                    return prevFilterVO
            }
        }, {})

        this.filter = createNotBindedProductsFilter(filtersChanges)
        this.fetchProducts()
    }

    @action
    handleFiltersClear = (): void => {
        this.filter = createNotBindedProductsFilter({})
        this.fetchProducts()
    }

    @action
    setSelectedProducts = (selectedProducts: ShortProductVO_SF[]) => {
        this.selectedProducts = selectedProducts
    }

    @action
    setSelectedPriceTags = (selectedPriceTags: PriceTagTemplateVO[]) => {
        this.selectedPriceTags = selectedPriceTags
    }

    @action
    allVisibleRowsSelectHandler = (selected: boolean) => {
        if (selected) {
            // если поставили галочку "все" - добавляем текущие видимые элементы, а ранее выбранные удаляем
            this.setSelectedProducts(this.products)
        } else {
            // если убрали галочку "все" - обнуляем selectedProducts.
            this.setSelectedProducts([])
        }
    }

    @action
    reset = (): void => {
        this.products = null
        this.selectedProducts = []
        this.selectedPriceTags = []
        this.filter = createNotBindedProductsFilter({})
        this.priceTagBindingDialogOpened = false
        this.priceTags = null
    }
}

export function productToKey(item: ShortProductVO_SF): string {
    return `${item.name}_${item.code}_${item.barCode}`
}

export function priceTagToKey(item: PriceTagTemplateVO) {
    return `${item.id}_${item.guid}_${item.name}`
}
