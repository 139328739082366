import { t } from 'i18next'
import { observable, computed, action, runInAction, toJS } from 'mobx'
import uuid from 'uuid'
import { priceTagFacadeLocal } from '../../../protocol/set10/price-tag-facade-local'
import { PRICE_TAG_NOT_PRINTED } from '../../core/products/product-statuses'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { ProductVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/product-vo'
import { printingModuleConfigurator } from '../../../protocol/set10/printing-module-configurator'
import { XLSX } from '../../core/file-types'
import { withSpinner } from '../with-spinner'
import { notPrintedShelfLocal } from '../../../protocol/set10/not-printed-shelf-local'
import { getStore } from '../stores-repository'
import { USER_STORE, DIALOG_STORE, PRICE_TAGS_PRINTING_STORE } from '../stores'
import { UserStore } from '../user-store'
import { DialogStore } from '../dialog-store'
import { Progress } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/progress'
import {
    NEARLY_PRICE_CHANGING_FILTER,
    EMPTY_FILTER_VALUE
} from '../../core/filters/filter'
import { config } from '../../config/config'
import { PRICE_TAGS, NOT_PRINTED, PRINTING } from '../../core/app-routes'
import { PriceTagsPrintingStore } from './printing/printing-store'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { SideBarFiltersState, getSearchArguments } from '../../components/filters/side-bar-filters'
import { ALERT } from '../../../components/simple-dialog/simple-dialog'
import { chunkedLoading } from '../../core/chunked-loading'
import { DEFAULT_PRICE_TAGS_PAGINATION } from '../../core/price-tags/price-tags-util'
import isNil from 'lodash/isNil'
import { isEqual } from 'lodash'

const getDefaultFilterState = (): SideBarFiltersState => {
    return {
        shownFilters: {
            [NEARLY_PRICE_CHANGING_FILTER]: { value: EMPTY_FILTER_VALUE }
        },
    }
}

export class NotPrintedRegularPriceTagsStore {

    @observable
    priceNumbersToShow: number[] = []

    @observable
    productIds: string[] = null

    @observable
    products: ProductVO[] = null

    @observable
    selectedProductIds: string[] = []

    @observable
    searchArguments: SearchArgumentVO[] = []

    @observable
    sendToCashesProgresses: Progress[] = []

    @observable
    currentFiltersState: SideBarFiltersState = getDefaultFilterState()

    @observable
    pagination: PaginationState = DEFAULT_PRICE_TAGS_PAGINATION

    progressPollingTimer: number = null
    progressPollingTimeOut: number = 1000

    priceNumbersPollingTimer: number = null
    priceNumbersPollingTimeOut: number = 30 * 1000

    private userStore: UserStore = getStore(USER_STORE)
    private dialogStore: DialogStore = getStore(DIALOG_STORE)
    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    @computed
    get displayedProducts(): ProductVO[] {
        return this.products?.map(item => {
            // Применяем изменения в имени объекта
            const selected = this.selectedProductIds.includes(item.marking)

            return {
                ...item,
                selected
            }
        }) || []
    }

    // Автообновление прогресса печати
    startProgressesTimer = (): void => {
        clearTimeout(this.progressPollingTimer)
        this.progressPollingTimer = setTimeout(() => {
            this.checkSendToCashesProgress()
        }, this.progressPollingTimeOut)
    }

    stopProgressesTimer = (): void => {
        clearTimeout(this.progressPollingTimer)
    }

    @action
    fetchFirstData = async (): Promise<void> => {
        this.searchArguments = getSearchArguments(this.currentFiltersState.shownFilters)
        await this.fetchProductIds(!!this.productIds)
        this.startPriceNumbersTimer()
    }

    // Автообновление цен
    startPriceNumbersTimer = (): void => {
        clearTimeout(this.priceNumbersPollingTimer)
        this.priceNumbersPollingTimer = setTimeout(() => {
            this.fetchProductIds(true)
            this.startPriceNumbersTimer()
        }, this.priceNumbersPollingTimeOut)
    }

    stopPriceNumbersTimer = (): void => {
        clearTimeout(this.priceNumbersPollingTimer)
    }

    fetchPriceNumbersToShow = async (): Promise<void> => {
        const printingProperties = await printingModuleConfigurator.getProperties1()
        runInAction(() => {
            if (printingProperties.priceNumbersToShow) {
                printingProperties.priceNumbersToShow.sort((a, b) => a - b)
            }
            this.priceNumbersToShow = printingProperties.priceNumbersToShow || []
        })
    }

    fetchProductIds = async (fetchInBackground: boolean = false): Promise<void> => {
        const request = async () => {
            const productIds = await priceTagFacadeLocal.getObjectsIds2(toJS(this.searchArguments), PRICE_TAG_NOT_PRINTED)

            let products: ProductVO[] = []
            products = await chunkedLoading(productIds, productChunkIds => {
                return priceTagFacadeLocal.getProducts1(productChunkIds, PRICE_TAG_NOT_PRINTED)
            })

            // Возможна ситуация, когда getObjectsIds2 вернет неверные id,
            // по которым не будет возвращена полная информация в getProducts1.
            // Показываем пользователю предупреждение.
            const notFoundIds: string[] = productIds.filter(id => {
                return !products.some(p => p.marking === id)
            })

            if (notFoundIds.length > 0) {
                this.dialogStore.showDialog({
                    message: t('notPrintedPriceTags.regular.notFoundIds', {
                        elements: notFoundIds.join(', ')
                    }),
                    mode: ALERT,
                })
            }

            // TODO кастим beginDate к Date https://crystals.atlassian.net/browse/SFM-208
            products = products.map(product => {
                if (product.pricesMap) {
                    Object.keys(product.pricesMap).forEach(key => {
                        product.pricesMap[key] = {
                            ...product.pricesMap[key],
                            beginDate: new Date(product.pricesMap[key].beginDate)
                        }
                    })
                }

                if (product.prices99Map) {
                    Object.keys(product.prices99Map).forEach(key => {
                        product.prices99Map[key] = {
                            ...product.prices99Map[key],
                            beginDate: new Date(product.prices99Map[key].beginDate)
                        }
                    })
                }

                return product
            })

            runInAction(() => {
                const productsChanged = !isEqual(products, toJS(this.products))
                if (productsChanged) {
                    this.productIds = productIds
                    this.products = products
                }
            })
        }
        return fetchInBackground ? request() : withSpinner(request)
    }

    checkSendToCashesProgress = async (): Promise<void> => {
        const sendToCashesProgresses: Progress[] = await notPrintedShelfLocal.getTasksStatus(this.userStore.session)

        if (!sendToCashesProgresses || sendToCashesProgresses.length === 0) {
            // останавливаем рекурсивный таймер, если предыдущий массив с прогрессами был непустой, а следующий пришёл пустой
            if (this.sendToCashesProgresses && this.sendToCashesProgresses.length > 0) {
                this.stopProgressesTimer()
                this.fetchProductIds()
            }
        } else {
            // рекурсивно вызываем этот метод по таймеру, пока не придёт пустой массив с прогрессами
            this.startProgressesTimer()
        }

        runInAction(() => {
            this.sendToCashesProgresses = sendToCashesProgresses
        })
    }

    downloadExcelHandler = async (): Promise<void> => {
        const reportWindow = window.open()
        const fileName: string = await priceTagFacadeLocal.getFileNameForPriceTagShelf(
            toJS(this.selectedProductIds),
            this.productIds.length,
            PRICE_TAG_NOT_PRINTED,
            XLSX
        )

        const reportUrl: string = `${config.reportsAddress}?Action=getFile&FILE_NAME=${fileName}`
        // window.open сработает только если он был вызван в главном потоке
        // поэтому мы сначала открываем окно, а потом подменяем ему адрес
        reportWindow.location.replace(reportUrl)
    }

    leaveWithoutPriceTagsHandler = async (): Promise<void> => {
        await withSpinner(async () => {
            await priceTagFacadeLocal.markAsPrinted(toJS(this.selectedProductIds), PRICE_TAG_NOT_PRINTED)
            await this.checkSendToCashesProgress()
            await this.fetchProductIds()
            this.selectedProductIds = []
        })
    }

    @action
    applyFilters = (searchArguments: SearchArgumentVO[]): void => {
        this.searchArguments = searchArguments
        this.startPriceNumbersTimer()
        this.fetchProductIds()
    }

    @action
    clearFilters = (filterState: SideBarFiltersState): void => {
        this.searchArguments = getSearchArguments(filterState.shownFilters)
        this.currentFiltersState = filterState
        this.fetchProductIds()
    }

    printPriceTags = (): void => {
        this.printingStore.openPrinting(
            PRICE_TAGS + NOT_PRINTED + PRINTING,
            {
                productStatus: PRICE_TAG_NOT_PRINTED,
                objectIds: toJS(this.selectedProductIds)
            }
        )
    }

    handleAllTasksCompleted = (): void => {
        this.selectedProductIds = []
        this.fetchProductIds()
        this.checkSendToCashesProgress()
    }

    @action
    setSelection = (selectedIds: string[]) => {
        this.selectedProductIds = selectedIds
    }

    @action
    setSelectedProductOnPage = (newSelectedIds: string[], oldSelectedIds: string[]): void => {
        let addToSelection: string[] = [...newSelectedIds]
        let newSelectedProducts = [...this.selectedProductIds]

        oldSelectedIds.forEach(oldId => {
            const newIndex = addToSelection.findIndex(newId => newId === oldId)
            if (newIndex === -1) {
                const selectedIndex = newSelectedProducts.findIndex(currentId => currentId === oldId)
                newSelectedProducts.splice(selectedIndex, 1)
            } else {
                addToSelection.splice(newIndex, 1)
            }
        })

        this.selectedProductIds = [...newSelectedProducts, ...addToSelection]
    }

    @action
    setFiltersState = (filtersState: SideBarFiltersState): void => {
        this.currentFiltersState = filtersState
    }

    @action
    setPagination = (pagination: PaginationState): void => {
        this.pagination = pagination
    }

    @action
    reset = (): void => {
        this.priceNumbersToShow = []
        this.productIds = null
        this.selectedProductIds = []
        this.currentFiltersState = getDefaultFilterState()
        this.searchArguments = getSearchArguments(this.currentFiltersState.shownFilters)
        this.sendToCashesProgresses = []
        this.pagination = DEFAULT_PRICE_TAGS_PAGINATION
        this.stopProgressesTimer()
        this.stopPriceNumbersTimer()
    }
}
