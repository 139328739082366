// Частично взято с https://gist.github.com/dperini/729294 (сюда ссылается validate.js)
// Код в свободном доступе
// Убраны IP адреса, порт, путь к ресурсу
export const URLRegExp = new RegExp(
    '^' +
        // host & domain names, may end with dot
        // can be replaced by a shortest alternative
        // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
        '(?:' +
          '(?:' +
            '[a-z0-9\\u00a1-\\uffff]' +
            '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
          ')?' +
          '[a-z0-9\\u00a1-\\uffff]\\.' +
        ')+' +
        // TLD identifier name, may end with dot
        '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
    '$', 'i'
)

export const ipRegExp = new RegExp(
    '^' +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broacast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
  '$', 'i'
)

// Упрощенные RegExp
// const URLRegExp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/
// const ipRegExp = /^(?:(?:0|(?:[1-9]\d{0,2}))\.){3}(?:0|(?:[1-9]\d{0,2}))$/

export const isValidURLOrIP = (address: string): boolean => {
    if (!address) return false

    let addressWithoutPort = address

    // Если порта нет - считаем, что он валиден
    let portIsValid: boolean = true
    const addressParts = address.split(':')

    if (addressParts.length > 2) return false

    if (addressParts.length === 2) {
        if (addressParts[1] === '') return false

        addressWithoutPort = addressParts[0]
        const port = Number(addressParts[1])
        portIsValid = !isNaN(port) && port >= 0 && port <= 65535
    }

    let isValidIP: boolean = false
    if (ipRegExp.test(addressWithoutPort)) {
        const addressParts = addressWithoutPort.split('.')
        isValidIP = addressParts.every(part => !isNaN(Number(part)) && Number(part) >= 0 && Number(part) <= 255)
    }

    let isValidURL: boolean = false
    isValidURL = URLRegExp.test(addressWithoutPort)

    return (isValidIP || isValidURL) && portIsValid
}

export const SimpleURLRegExp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
