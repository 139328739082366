import { ExternalCardsVO } from './set-retail10-commons/data-structs-module/external-cards-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class IExternalCardsManagerRemote extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Cards-ExternalCards/SET/Cards/ExternalCard/ManagerBean!ru.crystals.cards.externalcards.IExternalCardsManagerRemote'
    }

    createExternalCards = (sessionId: string, cards: ExternalCardsVO, options?: RequestOptions): Promise<ExternalCardsVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'createExternalCards',
                params: [sessionId, cards]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getExternalCards = (sessionId: string, start: number, count: number, options?: RequestOptions): Promise<ExternalCardsVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getExternalCards',
                params: [sessionId, start, count]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    storeExternalCards = (sessionId: string, cards: ExternalCardsVO, options?: RequestOptions): Promise<ExternalCardsVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'storeExternalCards',
                params: [sessionId, cards]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const iExternalCardsManagerRemote: IExternalCardsManagerRemote = new IExternalCardsManagerRemote()
