import { CashStatus } from './cash-status'
import { CashTemplateVO } from './cash-template-vo'

export interface CashVO {
    '@class': 'ru.crystals.setretailx.cash.CashVO'
    id?: number
    status?: CashStatus
    number?: number
    shopNumber?: number
    template?: CashTemplateVO
    layoutName?: string
    factoryNum?: string
    fiscalNum?: string
    eklzNum?: string
    innNum?: string
    hardwareName?: string
    fiscalDate?: string
    centrumId?: number
    fwVersion?: string
    version?: string
    fiscalPrinterInfo?: string
    licenseInfo?: string
}

export const createCashVO = (initialization: Partial<CashVO>): CashVO => {
    if (!initialization) return null
    
    let result: CashVO = {
        id: initialization.id,
        status: initialization.status,
        number: initialization.number,
        shopNumber: initialization.shopNumber,
        template: initialization.template,
        layoutName: initialization.layoutName,
        factoryNum: initialization.factoryNum,
        fiscalNum: initialization.fiscalNum,
        eklzNum: initialization.eklzNum,
        innNum: initialization.innNum,
        hardwareName: initialization.hardwareName,
        fiscalDate: initialization.fiscalDate,
        centrumId: initialization.centrumId,
        fwVersion: initialization.fwVersion,
        version: initialization.version,
        fiscalPrinterInfo: initialization.fiscalPrinterInfo,
        licenseInfo: initialization.licenseInfo,
        '@class': 'ru.crystals.setretailx.cash.CashVO',
    }
    
    return result
}
