import { action, computed, observable, runInAction, toJS } from 'mobx'
import { cloneDeep, Cancelable, throttle, isEqual, isNil } from 'lodash'
import { t } from 'i18next'
import { getStore } from '../stores-repository'
import {
    APP_BAR_STORE, PRICE_TAGS_EDITOR_STORE, USER_STORE, APP_STORE, PRICE_TAG_SETTINGS_STORE
} from '../stores'
import { XMLPriceTag } from '../../core/price-tags/xml/xml-price-tag'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { EDITOR, PRICE_TAGS, TEMPLATES } from '../../core/app-routes'
import { printersManagerLocal } from '../../../protocol/set10/printers-manager-local'
import { UserStore } from '../user-store'
import { PriceTagTemplateVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { deserializePriceTagXML, serializePriceTagXML } from '../../core/price-tags/serialize-utils'
import { metersToPixels, pixelsToMeters, fixMnemonicFlagsInOldPriceTag } from '../../core/price-tags/price-tags-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { EditorItem } from '../../../components/wysiwyg/wysiwyg-editor'
import { XMLTagElement } from '../../core/price-tags/xml/xml-tag-base-element'
import { createEditorItem, exportEditorItem } from '../../pages/price-tags/editor/price-tags-editor-util'
import { Constraint } from '../../../components/wysiwyg/constraints/constraint'
import { SnapToGridConstraint } from '../../../components/wysiwyg/constraints/snap-to-grid-constraint'
import { SnapToAnglesConstraint } from '../../../components/wysiwyg/constraints/snap-to-angles-constraint'
import { SizeConstraint } from '../../../components/wysiwyg/constraints/size-constraint'
import { checkFontAvailable } from '../../../utils/font-utils'
import { isTextBlock, XMLTagTextBlock, TextStrikedKind } from '../../core/price-tags/xml/xml-tag-text-block'
import { isImage, XMLTagImage } from '../../core/price-tags/xml/xml-tag-image'
import { isCustomElement, XMLTagCustomElement, isLegacyBarcode } from '../../core/price-tags/xml/xml-tag-custom-element'
import { isFormula, XMLTagFormula } from '../../core/price-tags/xml/xml-tag-formula'
import { isSubTemplate, XMLTagSubTemplate } from '../../core/price-tags/xml/xml-tag-sub-template'
import { REGULAR } from '../../core/price-tags/xml/xml-font-style'
import { NORMAL } from '../../core/price-tags/xml/xml-font-weight'
import { LEFT } from '../../core/price-tags/xml/xml-text-align'
import { NONE } from '../../core/price-tags/xml/xml-text-decoration'
import { XMLCustomElementType } from '../../core/price-tags/xml/xml-custom-element-type'
import { FormulaType, MIN_VALUE, FIRST_NOT_NULL } from '../../core/price-tags/xml/xml-formula-type'
import { REPAINT_DELAY } from '../../../utils/default-timeouts'
import { Matrix2d } from '../../../utils/math/geom-util'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { AppStore } from '../app-store'
import { ObservableHistoryManager } from '../../../utils/history-manager-util'
import {
    getUIDWithoutDashes, mnemonicNamesToFormulaText
} from '../../core/price-tags/price-tag-formulas-util'
import { withSpinner } from '../with-spinner'
import { PriceTagSettingsStore } from './price-tag-settings-store'
import { XMLVisibilityConditionType, VISIBLE, HIDDEN, SCALES_BUTTON } from '../../core/price-tags/xml/xml-visibility-condition-type'
import { XMLVisibilityCondition } from '../../core/price-tags/xml/xml-visibility-condition'
import { NEW } from '../../core/values'
import { XMLTagBarcodeElement, isBarcodeElement } from '../../core/price-tags/xml/xml-tag-barcode-element'
import { XMLTagQrcodeElement, isQrcodeElement } from '../../core/price-tags/xml/xml-tag-qrcode-element'
import { STANDARD } from '../../core/price-tags/xml/xml-qrcode-element-type'
import { MnemonicGroupVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-group-vo'
import { mnemonicsPropertiesFacadeLocal } from '../../../protocol/set10/mnemonics-properties-facade-local'
import {
    MnemonicPropertiesVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'

export const AVAILABLE_SCALES = [0.1, 0.25, 0.5, 0.75, 1, 1.5, 2, 4]
export const AVAILABLE_GRID_STEPS = [1, 2, 5, 10, 20]

export type ItemType = 'textBlock' | 'customElement' | 'image' | 'subTemplate' | 'formula' | 'barcode' | 'qrcode'
export const TEXT_BLOCK: ItemType = 'textBlock'
export const CUSTOM_ELEMENT: ItemType = 'customElement'
export const IMAGE: ItemType = 'image'
export const SUB_TEMPLATE: ItemType = 'subTemplate'
export const FORMULA: ItemType = 'formula'
export const BARCODE: ItemType = 'barcode'
export const QRCODE: ItemType = 'qrcode'

interface FontSelectOption {
    label: string
    // Шрифт существует локально
    existLocally: boolean
}

const MM_IN_METER = 1000

export interface HistoryElement {
    editorItems: EditorItem[]
    elementsMap: { [type: string]: { [key: string]: number } }
    priceTagXML: XMLPriceTag
    scaleRate: number
}

export const SIMPLE_VISIBILITY_CONDITION_TYPES: XMLVisibilityConditionType[] = [
    VISIBLE,
    HIDDEN,
    SCALES_BUTTON,
]

export class PriceTagsEditorStore {

    @observable
    priceTag: PriceTagTemplateVO = null

    @observable
    priceTags: PriceTagTemplateVO[] = null

    @observable
    priceTagXML: XMLPriceTag = null

    originalPriceTagXML: XMLPriceTag = null

    @observable
    scaleRate: number = 1

    @observable
    gridStep: number = AVAILABLE_GRID_STEPS[2]

    @observable
    snapToGrid: boolean = false

    @observable
    snapToObjects: boolean = false

    @observable
    showGrid: boolean = true

    @observable
    editorItems: EditorItem[] = null

    @observable
    hiddenItemsKeys: string[] = []

    @observable
    selectedItemKey: string = null

    @observable
    previewDialogOpened: boolean = false

    @observable
    availableFonts: FontSelectOption[] = []

    @observable
    visibilityDialogOpened: boolean = false

    @observable
    advancedVisibilitySettingsEnabled: boolean = false
    // Для восстановления предыдущего условия видимости при нажатии на кнопку "Отмена" в диалоге
    // В данный момент можно указать только одно условие видимости
    @observable
    selectedItemOriginalVisibilityCondition: XMLVisibilityCondition = null

    @observable
    mnemonicGroups: MnemonicGroupVO[] = []

    @computed
    get selectedItem(): EditorItem {
        return this.editorItems
            ? this.editorItems.find(item => item.key === this.selectedItemKey)
            : undefined
    }

    @computed
    get mnemonicGroupsWithoutActionable(): MnemonicGroupVO[] {
        return this.mnemonicGroups.map(mnemGr => {
            return {
                ...mnemGr,
                mnemonicProperties: mnemGr.mnemonicProperties.filter(mnem => !mnem.actionable)
            }
        })
    }

    @computed
    get mnemonics(): MnemonicPropertiesVO[] {
        return this.mnemonicGroups.reduce((prevMnems: MnemonicPropertiesVO[], currentGroup: MnemonicGroupVO) => {
            return [
                ...prevMnems,
                ...currentGroup.mnemonicProperties
            ]
        }, [])
    }

    @computed
    get priceMnemonics(): MnemonicPropertiesVO[] {
        return this.mnemonics.filter(mnem => mnem.priceMnemonic)
    }

    // В данный момент можно указать только одно условие видимости
    @computed
    get selectedItemVisibilityCondition(): XMLVisibilityCondition {
        return this.selectedItem
            && this.selectedItem.data
            && this.selectedItem.data.visibility
                ? this.selectedItem.data.visibility[0] as XMLVisibilityCondition
                : undefined
    }

    @computed
    get selectedItemVisibilityConditionType(): XMLVisibilityConditionType {
        /* TODO
        В настоящий момент в сгенерированных на сервере данных visibility === undefined,
        а при создании новых макетов через flex - visibility заполняется корректно,
        но с избытком (всегда инициализируются поля value и formula, хотя они опциональные) */
        return this.selectedItemVisibilityCondition
            ? this.selectedItemVisibilityCondition.type
            : undefined
    }

    // Объект уже является observable (расширение происходит в конструкторе класса)
    editorItemsHistory: ObservableHistoryManager<HistoryElement> = new ObservableHistoryManager()

    elementsMap: { [type: string]: { [key: string]: number } } = {
        [TEXT_BLOCK]: {},
        [IMAGE]: {},
        [CUSTOM_ELEMENT]: {},
        [FORMULA]: {},
        [SUB_TEMPLATE]: {},
        [BARCODE]: {},
        [QRCODE]: {},
    }

    // События ondragover генерируются десятки раз в секунду, поэтому здесь нужен не debounce, а throttle
    throttledMoveLayer: ((from: number, to: number) => void) & Cancelable =
        throttle((from: number, to: number) => this.moveLayer(from, to), REPAINT_DELAY)

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    @computed
    get globalConstraints(): Constraint[] {
        return [
            new SnapToGridConstraint(
                {
                    gridStep: this.gridStep
                },
                this.scale,
                this.snapToGrid
            ),
            new SnapToAnglesConstraint(
                {
                    angles: Array(8).fill(0).map((value, index) => index * Math.PI / 4)
                },
                this.scale,
                true
            ),
            // TODO возможно минимальные размеры нужно высчитывать в зависимости от масштаба
            new SizeConstraint(
                {
                    minWidth: 5,
                    minHeight: 5,
                },
                this.scale,
                true
            ),
        ]
    }

    @computed
    get otherPriceTags(): PriceTagTemplateVO[] {
        if (!this.priceTag || !this.priceTags) return []

        return this.priceTags.filter(pt => pt.id !== this.priceTag.id)
    }

    // Масштаб должен учитывать как увеличение/уменьшение, так и перевод метров в пиксели
    @computed
    get scale(): number {
        return metersToPixels(this.scaleRate / MM_IN_METER)
    }

    @computed
    get priceTagsNamesByGuidMap(): Map<string, string> {
        const priceTagsNamesMap = new Map()

        if (this.priceTags) {
            this.priceTags.forEach(priceTag => {
                priceTagsNamesMap.set(priceTag.guid, priceTag.name)
            })
        }

        return priceTagsNamesMap
    }

    @computed
    get saveAvailable(): boolean {
        return !this.editorItemsHistory.isSetToFirstItem
    }

    @action
    openPriceTag = (priceTag: PriceTagTemplateVO): void => {
        this.priceTag = cloneDeep(priceTag)

        goTo(`${PRICE_TAGS}${TEMPLATES}/${priceTag.id || NEW}${EDITOR}`)
    }

    fetchPriceTags = async (): Promise<void> => {
        const priceTags = await printersManagerLocal.getPriceTagTemplates(this.userStore.session)

        runInAction(() => {
            this.priceTags = priceTags
        })
    }

    fetchPriceTag = async (id: string): Promise<void> => {
        if (isNaN(Number(id))) {
            goTo(PRICE_TAGS)
            return Promise.resolve()
        }

        const priceTag = await printersManagerLocal.getPriceTagTemplate(this.userStore.session, id)

        runInAction(() => {
            this.priceTag = priceTag
        })
    }

    fetchServerFonts = async (): Promise<void> => {
        const serverFonts = await printersManagerLocal.getServerFonts()

        runInAction(() => {
            if (serverFonts) {
                this.availableFonts = serverFonts.map(fontName => {
                    return {
                        label: fontName,
                        existLocally: checkFontAvailable(fontName)
                    }
                })
            }
        })
    }

    fetchMnemonicGroups = async (): Promise<void> => {
        const mnemonicGroups = await mnemonicsPropertiesFacadeLocal.getAllByGroups({ useCache: true }) || []

        runInAction(() => {
            this.mnemonicGroups = mnemonicGroups
        })
    }

    createPriceTagXMLData = async (): Promise<void> => {
        let xml = await deserializePriceTagXML(this.priceTag.data)

        xml = fixMnemonicFlagsInOldPriceTag(xml, this.mnemonics)

        runInAction(() => {
            if (!xml.textBlocks) xml.textBlocks = []
            if (!xml.images) xml.images = []
            if (!xml.customElements) xml.customElements = []
            if (!xml.subTemplates) xml.subTemplates = []
            if (!xml.formulas) xml.formulas = []
            if (!xml.barcodeElements) xml.barcodeElements = []
            if (!xml.qrcodeElements) xml.qrcodeElements = []

            this.priceTagXML = xml
            this.originalPriceTagXML = cloneDeep(xml)
        })
    }

    @action
    createEditorItems = (): EditorItem[] => {
        if (!this.priceTagXML || !this.priceTags) return []

        this.editorItems = []
        this.priceTagXML.textBlocks.forEach(this.createFirstItems)
        this.priceTagXML.images.forEach(this.createFirstItems)
        this.priceTagXML.customElements.forEach(this.createFirstItems)
        this.priceTagXML.barcodeElements.forEach(this.createFirstItems)
        this.priceTagXML.qrcodeElements.forEach(this.createFirstItems)
        this.priceTagXML.subTemplates.forEach(this.createFirstItems)
        this.priceTagXML.formulas.forEach(this.createFirstItems)
        // Сортируем для правильного отображения порядка слоев
        this.sortLayersDescending()
        // Записываем стартовые изменения в историю
        this.addChangesToHistory()
    }

    getItemTypeByKey = (itemKey: string): ItemType => {
        return Object.keys(this.elementsMap).find(type => {
            return Object.keys(this.elementsMap[type]).some(key => itemKey === key)
        }) as ItemType
    }

    getItemTypeByElement = (element: XMLTagElement): ItemType => {
        if (isTextBlock(element)) {
            return TEXT_BLOCK
        } else if (isImage(element)) {
            return IMAGE
        } else if (isCustomElement(element)) {
            return CUSTOM_ELEMENT
        } else if (isFormula(element)) {
            return FORMULA
        } else if (isSubTemplate(element)) {
            return SUB_TEMPLATE
        } else if (isBarcodeElement(element)) {
            return BARCODE
        } else if (isQrcodeElement(element)) {
            return QRCODE
        } else {
            throw Error(`Wrong price tag xml element type ${element}`)
        }
    }

    getItemTypeCollection = (type: ItemType): XMLTagElement[] => {
        switch (type) {
            case TEXT_BLOCK:
                return this.priceTagXML.textBlocks
            case IMAGE:
                return this.priceTagXML.images
            case CUSTOM_ELEMENT:
                return this.priceTagXML.customElements
            case FORMULA:
                return this.priceTagXML.formulas
            case SUB_TEMPLATE:
                return this.priceTagXML.subTemplates
            case BARCODE:
                return this.priceTagXML.barcodeElements
            case QRCODE:
                return this.priceTagXML.qrcodeElements
            default: throw Error(`Wrong price tag xml element type ${type}`)
        }
    }

    zOrderVerify = (zOrderValue: number): number => {
        /**
         * Пробежаться по всем объектам в коллекциях вложенных объектов, найти zOrder каждого и сравнить с полученным zOrderValue:
         *  - если значение zOrderValue не занято zOrder-ом коллекции, то дать новому слою текущее zOrderValue
         *  - если zOrderValue занято, то увеличить его на 1 и убедиться, что новое значение свободно.
         */
        const collection = toJS(this.priceTagXML)

        for (let key of Object.keys(collection)) {
            if (Array.isArray(collection[key])) {
                for (let collectionItem of collection[key]) {
                    if (collectionItem.zOrder === zOrderValue) {
                        return this.zOrderVerify(zOrderValue + 1)
                    }
                }
            }
        }

        return zOrderValue
    }

    createTextBlock = (): void => {
        this.addItemByElement(getNewTextBlock(this.zOrderVerify(this.editorItems.length)))
    }

    createMnemonicBlock = (mnemonic: MnemonicPropertiesVO): void => {
        this.addItemByElement(getNewMnemonicBlock(mnemonic, this.zOrderVerify(this.editorItems.length)))
    }

    createImageBlock = (fileName: string, widthInPx: number, heightInPx: number, base64data: string): void => {
        this.addItemByElement(
            getImageBlock(
                fileName,
                MM_IN_METER * pixelsToMeters(widthInPx),
                MM_IN_METER * pixelsToMeters(heightInPx),
                base64data,
                this.zOrderVerify(this.editorItems.length)
            )
        )
    }

    createCustomElementBlock = (customElementType: XMLCustomElementType): void => {
        this.addItemByElement(getCustomElementBlock(customElementType, this.zOrderVerify(this.editorItems.length)))
    }

    createBarcodeElementBlock = (): void => {
        this.addItemByElement(getBarcodeElementBlock(this.zOrderVerify(this.editorItems.length)))
    }

    createQrcodeElementBlock = (): void => {
        this.addItemByElement(getQrcodeElementBlock(this.zOrderVerify(this.editorItems.length)))
    }

    createSubTemplateElementBlock = (template: PriceTagTemplateVO): void => {
        this.addItemByElement(getSubTemplateElementBlock(template, this.zOrderVerify(this.editorItems.length)))
    }

    createFormulaBlock = (formulaType: FormulaType): void => {
        this.addItemByElement(getFormulaBlock(formulaType, this.priceMnemonics.map(pm => pm.name), this.zOrderVerify(this.editorItems.length)))
    }

    // Метод наполняет элементы при открытии шаблона ценника. Не изменяет priceTagXML и историю undo/redo
    createFirstItems = (element: XMLTagElement, index: number): void => {
        const type = this.getItemTypeByElement(element)
        const item = createEditorItem(element, this.mnemonics, this.priceTags, null, this.scale)

        this.editorItems.push(item)
        this.elementsMap[type][item.key] = index
    }

    @action
    addItemByElement = (element: XMLTagElement): void => {
        const item = createEditorItem(element, this.mnemonics, this.priceTags, null, this.scale)
        this._addItem(item)

        // Записываем изменения в историю
        this.addChangesToHistory()
    }

    @action
    undo = (): void => {
        this.editorItemsHistory.setToPreviousItem()
        this.restoreChangesFromHistory()
    }

    @action
    redo = (): void => {
        this.editorItemsHistory.setToNextItem()
        this.restoreChangesFromHistory()
    }

    @action
    setScaleRate = (scaleRate: number): void => {
        this.scaleRate = scaleRate

        // scale меняется извне по отношению к wysiwyg, поэтому пересоздаём элементы
        this.recreateElements()

        // Текущая реализация увеличения меняет editorItems -> надо сохранить в историю
        this.addChangesToHistory()
    }

    @action
    setShowGrid = (showGrid: boolean): void => {
        this.showGrid = showGrid
    }

    @action
    setSnapToGrid = (snapToGrid: boolean): void => {
        this.snapToGrid = snapToGrid
    }

    @action
    setGridStep = (gridStep: number): void => {
        this.gridStep = gridStep
    }

    @action
    updateItemFromEditor = (item: EditorItem, addToHistory: boolean = true): void => {
        const type = this.getItemTypeByKey(item.key)
        const collection = this.getItemTypeCollection(type)
        const index = this.elementsMap[type][item.key]
        const editorIndex = this.editorItems.findIndex(i => i.key === item.key)

        const element = exportEditorItem(item)
        const newItem = createEditorItem(element, this.mnemonics, this.priceTags, item.key, this.scale)

        collection[index] = element
        this.editorItems[editorIndex] = newItem

        // Записываем изменения в историю
        if (addToHistory) {
            this.addChangesToHistory()
        }
    }

    @action
    updateItemXml = (key: string, element: XMLTagElement, addToHistory: boolean = true): void => {
        const type = this.getItemTypeByKey(key)
        const collection = this.getItemTypeCollection(type)
        const index = this.elementsMap[type][key]
        const editorIndex = this.editorItems.findIndex(i => i.key === key)

        const newItem = createEditorItem(element, this.mnemonics, this.priceTags, key, this.scale)

        collection[index] = element
        this.editorItems[editorIndex] = newItem

        // Записываем изменения в историю
        if (addToHistory) {
            this.addChangesToHistory()
        }
    }

    @action
    changeSelectedItem = (item: EditorItem): void => {
        if (isLegacyBarcode(item && item.data)) {
            const newItem: EditorItem = this.migrateLegacyBarcodeItem(item)
            return this.changeSelectedItem(newItem)
        }

        // В случае, если никакой элемент не выделен, wysiwyg-editor передает в качестве item - null
        this.selectedItemKey = item ? item.key : null
        // Включаем кнопку редактирования условий видимости если условие не входит в базовые
        this.advancedVisibilitySettingsEnabled = this.selectedItemVisibilityConditionType
            && !SIMPLE_VISIBILITY_CONDITION_TYPES.includes(this.selectedItemVisibilityConditionType)
    }

    /* moves barcode item from customElements to barcodeElements and returns new barcode item  */
    @action
    migrateLegacyBarcodeItem = (item: EditorItem): EditorItem => {
        this._deleteItem(item)

        const newElement = {
            ...getBarcodeElementBlock(this.editorItems.length),
            ...item.data
        }

        // в XMLTagBarcodeElement нет поля type
        // удаляем его, чтобы не сработал isCustomElement type guard
        delete newElement.type

        const newItem = createEditorItem(newElement, this.mnemonics, this.priceTags, null, this.scale)

        this._addItem(newItem)

        return newItem
    }

    @action
    deleteItem = (item: EditorItem): void => {
        this._deleteItem(item)

        // Записываем изменения в историю
        this.addChangesToHistory()
    }

    @action
    toggleLayerHidden = (layerKey: string): void => {
        const existingKeyIndex = this.hiddenItemsKeys.findIndex(key => key === layerKey)

        if (existingKeyIndex === -1) {
            this.hiddenItemsKeys.push(layerKey)
        } else {
            this.hiddenItemsKeys.splice(existingKeyIndex, 1)
        }
    }

    @action
    moveLayer = (from: number, to: number): void => {
        if (Boolean(this.editorItems)
            && this.editorItems.length > 1
            && from !== -1
            && to !== -1
        ) {
            this.editorItems.splice(to, 0, this.editorItems.splice(from, 1)[0])
        }

        const maxZIndex = this.editorItems.length - 1
        // Сразу обновляем порядок для отображения изменений в превью
        this.editorItems.forEach((item, index) => {
            this.editorItems[index].zIndex = maxZIndex - index
            /* Важно! this.editorItems[index].data указывает непосредственно на объект с данными в this.priceTagXML,
            поэтому изменение объекта data здесь равносильно изменению соответствующего объекта с данными в priceTagXML */
            this.editorItems[index].data.zOrder = maxZIndex - index
        })
        // В историю изменения сохранятся, когда пользователь отпустит кнопку мыши (если в результате изменился порядок слоёв)
    }

    @action
    addChangesToHistory = (): void => {
        this.editorItemsHistory.addItem({
            editorItems: toJS(this.editorItems),
            elementsMap: toJS(this.elementsMap),
            priceTagXML: toJS(this.priceTagXML),
            scaleRate: toJS(this.scaleRate),
        })
    }

    @action
    restoreChangesFromHistory = (): void => {
        const { editorItems, elementsMap, priceTagXML, scaleRate } = this.editorItemsHistory.currentItem

        this.editorItems = toJS(editorItems)
        this.elementsMap = toJS(elementsMap)
        this.priceTagXML = toJS(priceTagXML)
        this.scaleRate = toJS(scaleRate)
    }

    @action
    sortLayersDescending = (): void => {
        this.editorItems = this.editorItems.sort((a, b) => b.zIndex - a.zIndex)
    }

    @action
    updateSelectedItemXMLVisibilityCondition = (visibility: XMLVisibilityCondition, addToHistory: boolean = true): void => {
        if (this.selectedItem && this.selectedItem.data) {
            // TODO По не очень ясной причине типы, связанные с видимостью, продублированы в core, и везде используются именно они
            this.selectedItem.data.visibility = [visibility]
            this.updateItemFromEditor(this.selectedItem, addToHistory)
        }
    }

    @action
    enableAdvancedVisibilitySettings = (): void => {
        this.advancedVisibilitySettingsEnabled = true
    }

    @action
    disableAdvancedVisibilitySettings = (): void => {
        this.advancedVisibilitySettingsEnabled = false
    }

    @action
    cancelVisibilityConditionChanges = (): void => {
        if (this.selectedItemVisibilityCondition) {
            this.selectedItem.data.visibility = [toJS(this.selectedItemOriginalVisibilityCondition)]
        }
    }

    @action
    cancelPriceTagXMLChanges = (checkForChanges?: boolean): void => {
        if (checkForChanges && !isEqual(toJS(this.priceTagXML), toJS(this.originalPriceTagXML))) {
            this.appStore.showDialog({
                title: t('priceTags.editor.saveChangesTitle'),
                message: t('priceTags.editor.saveChangesMessage'),
                mode: DIALOG,
                onYes: this.goBack,
                yesLabel: t('common.exit'),
                noLabel: t('common.cancel')
            })
        } else {
            this.goBack()
        }
    }

    @action
    savePriceTagXML = async (): Promise<void> => {
        // Преобразовываем xml в string
        this.priceTag.data = serializePriceTagXML(toJS(this.priceTagXML))

        const updatedPriceTag = await withSpinner(
            printersManagerLocal.savePriceTagTemplate(this.userStore.session, toJS(this.priceTag))
        )

        this.appStore.showSnackbar({
            message: t('priceTags.priceTagSaved')
        })
        // Обновляем в price-tag-settings-store т.к. если это был "новый шаблон ценника" то ему на сервере присвоили id
        const priceTagSettingsStore: PriceTagSettingsStore = getStore(PRICE_TAG_SETTINGS_STORE)
        await priceTagSettingsStore.reopenPriceTag(updatedPriceTag)

        this.goBack(updatedPriceTag.id)
    }

    goBack = (newId?: string): void => {
        goTo(`${PRICE_TAGS}${TEMPLATES}/${newId || this.priceTag.id}`)
    }

    @action
    recreateElements = (): void => { // при изменении элементов снаружи по отношению к wysiwyg нужно пересоздавать элементы
        if (!this.editorItems) return

        this.editorItems = this.editorItems.map(item => {
            const element = exportEditorItem(item)
            return createEditorItem(element, this.mnemonics, this.priceTags, item.key, this.scale)
        })
    }

    openPreviewDialog = (): void => {
        this.previewDialogOpened = true
    }

    closePreviewDialog = (): void => {
        this.previewDialogOpened = false
    }

    openVisibilityDialog = (): void => {
        this.visibilityDialogOpened = true
        this.selectedItemOriginalVisibilityCondition = toJS(this.selectedItemVisibilityCondition)
    }

    closeVisibilityDialog = (): void => {
        this.visibilityDialogOpened = false
        this.selectedItemOriginalVisibilityCondition = null
    }

    @action
    reset = (): void => {
        this.priceTag = null
        this.priceTags = null
        this.priceTagXML = null
        this.originalPriceTagXML = null
        this.scaleRate = 1
        this.gridStep = AVAILABLE_GRID_STEPS[2]
        this.snapToGrid = false
        this.snapToObjects = false
        this.showGrid = true
        this.editorItems = null
        this.hiddenItemsKeys = []
        this.selectedItemKey = null
        this.previewDialogOpened = false
        this.availableFonts = []
        this.elementsMap = {
            [TEXT_BLOCK]: {},
            [IMAGE]: {},
            [CUSTOM_ELEMENT]: {},
            [FORMULA]: {},
            [SUB_TEMPLATE]: {},
            [BARCODE]: {},
            [QRCODE]: {},
        }
        this.visibilityDialogOpened = false
        this.advancedVisibilitySettingsEnabled = false
        this.selectedItemOriginalVisibilityCondition = null
        this.editorItemsHistory.clear()
        this.mnemonicGroups = []
    }

    @action
    private _addItem = (item: EditorItem): void => {
        const type = this.getItemTypeByElement(item.data)
        const collection = this.getItemTypeCollection(type)
        const index = collection.length
        const element = item.data

        collection.push(element)
        this.editorItems.push(item)
        this.elementsMap[type][item.key] = index
    }

    @action
    private _deleteItem = (item: EditorItem): void => {
        const type = this.getItemTypeByKey(item.key)
        const collection = this.getItemTypeCollection(type)
        const elementMap = this.elementsMap[type]
        const index = elementMap[item.key]

        collection.splice(index, 1)
        this.editorItems.splice(this.editorItems.findIndex(i => i.key === item.key), 1)

        // Надо исправить все индексы в elementsMap
        delete elementMap[item.key]
        Object.keys(elementMap).forEach(key => {
            const currentIndex = elementMap[key]
            if (currentIndex > index) {
                elementMap[key] = currentIndex - 1
            }
        })
    }
}

export const PRICE_TAG_EDITOR_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${PRICE_TAGS}${TEMPLATES}/-?\\w+/editor$`),
    onEnter: () => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const priceTagEditorStore: PriceTagsEditorStore = getStore(PRICE_TAGS_EDITOR_STORE)

        appBarStore.updateState({
            title: t('priceTags.priceTagTemplateEditor'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => priceTagEditorStore.cancelPriceTagXMLChanges(true)
        })
    },
    onLeave: () => {
        const priceTagEditorStore: PriceTagsEditorStore = getStore(PRICE_TAGS_EDITOR_STORE)
        priceTagEditorStore.reset()
    }
}

const DEFAULT_ELEMENT_X: number = 10
const DEFAULT_ELEMENT_Y: number = 10

const defaultTextBlock: XMLTagTextBlock = {
    color: 0,
    fontFamily: 'Arial',
    fontSize: 14,
    fontStyle: REGULAR,
    fontWeight: NORMAL,
    textAlign: LEFT,
    textDecoration: NONE,
    striked: TextStrikedKind.NONE,
    width: 50,
    height: 8
}

const defaultVisibility: XMLVisibilityCondition = {
    type: VISIBLE
}

function getNewTextBlock(elementsLength: number): XMLTagTextBlock {
    return {
        ...defaultTextBlock,
        text: t('priceTags.editor.text'),
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
        zOrder: elementsLength
    }
}

function getNewMnemonicBlock(mnemonic: MnemonicPropertiesVO, elementsLength: number): XMLTagTextBlock {
    return {
        ...defaultTextBlock,
        textFit: mnemonic.textFit,
        wordWrap: mnemonic.wordWrap,
        text: mnemonicNameToMnemonic(mnemonic.name),
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
        zOrder: elementsLength
    }
}

function mnemonicNameToMnemonic(mnemonicName: string): string {
    return '%^' + mnemonicName + '^%'
}

function getImageBlock(fileName: string, width: number, height: number, base64data: string, elementsLength: number): XMLTagImage {

    return {
        fileName,
        data: base64data,
        width,
        height,
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
        zOrder: elementsLength
    }
}

function getCustomElementBlock(customElementType: XMLCustomElementType, elementsLength: number): XMLTagCustomElement {
    return {
        type: customElementType,
        width: 100,
        height: 100,
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
        zOrder: elementsLength
    }
}

function getBarcodeElementBlock(elementsLength: number): XMLTagBarcodeElement {
    return {
        preferredBarcodeType: null,
        width: 100,
        height: 100,
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
        zOrder: elementsLength
    }
}

function getQrcodeElementBlock(elementsLength: number): XMLTagQrcodeElement {
    return {
        preferredQrcodeType: STANDARD,
        width: 100,
        height: 100,
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
        zOrder: elementsLength
    }
}

function getSubTemplateElementBlock(template: PriceTagTemplateVO, elementsLength: number): XMLTagSubTemplate {
    return {
        guid: template.guid,
        width: template.width,
        height: template.height,
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
        zOrder: elementsLength
    }
}

export function getFormulaBlock(formulaType: FormulaType, mnemonicNames: string[], elementsLength?: number): XMLTagFormula {
    let text = ''
    // для новых формул с UniqueElementsAdder сразу добавляем 2 элемента
    if (formulaType === MIN_VALUE || formulaType === FIRST_NOT_NULL) {
        const [first, second] = mnemonicNames
        text = mnemonicNamesToFormulaText([first, second])
    }

    let result = {
        ...defaultTextBlock,
        text,
        uid: getUIDWithoutDashes(),
        type: formulaType,
        transform: new Matrix2d().translate(DEFAULT_ELEMENT_X, DEFAULT_ELEMENT_Y),
        visibility: [defaultVisibility],
    }

    if (!isNil(elementsLength)) {
        result.zOrder = elementsLength
    }

    return result
}
