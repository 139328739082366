import { FilterType } from './filter'
export type FiltersComponentInstance = 'bankTransactionFilters'
    | 'checksSearchFilters'
    | 'updRegistryFilters'
    | 'notPrintedRegularPriceTagsFilters'
    | 'notPrintedActionPriceTagsFilters'
    | 'notActualActionPriceTagsFilters'
    | 'notActualRegularPriceTagsFilters'
    | 'customPrintingActionPriceTagsFilters'
    | 'customPrintingRegularPriceTagsFilters'
    | 'printingHistoryFilters'
    | 'segmentsSearchFilters'
    | 'actionsSearchFilters'
    | 'actionsCouponsFilters'
export const BANK_TRANSACTIONS_FILTERS: FiltersComponentInstance = 'bankTransactionFilters'
export const CHECKS_SEARCH_FILTERS: FiltersComponentInstance = 'checksSearchFilters'
export const UPD_REGISTRY_FILTERS: FiltersComponentInstance = 'updRegistryFilters'
export const NOT_PRINTED_ACTION_PRICE_TAGS_FILTERS: FiltersComponentInstance = 'notPrintedActionPriceTagsFilters'
export const NOT_PRINTED_REGULAR_PRICE_TAGS_FILTERS: FiltersComponentInstance = 'notPrintedRegularPriceTagsFilters'
export const NOT_ACTUAL_ACTION_PRICE_TAGS_FILTERS: FiltersComponentInstance = 'notActualActionPriceTagsFilters'
export const NOT_ACTUAL_REGULAR_PRICE_TAGS_FILTERS: FiltersComponentInstance = 'notActualRegularPriceTagsFilters'
export const CUSTOM_PRINTING_ACTION_PRICE_TAGS_FILTERS: FiltersComponentInstance = 'customPrintingActionPriceTagsFilters'
export const CUSTOM_PRINTING_REGULAR_PRICE_TAGS_FILTERS: FiltersComponentInstance = 'customPrintingRegularPriceTagsFilters'
export const PRINTING_HISTORY_FILTERS: FiltersComponentInstance = 'printingHistoryFilters'
export const SEGMENTS_SEARCH_FILTERS: FiltersComponentInstance = 'segmentsSearchFilters'
export const ACTIONS_SEARCH_FILTERS: FiltersComponentInstance = 'actionsSearchFilters'
export const ACTIONS_COUPONS_FILTERS: FiltersComponentInstance = 'actionsCouponsFilters'

export const ALL_FILTERS_INSTANCES: FiltersComponentInstance[] = [
    BANK_TRANSACTIONS_FILTERS,
    CHECKS_SEARCH_FILTERS,
    UPD_REGISTRY_FILTERS,
    NOT_PRINTED_ACTION_PRICE_TAGS_FILTERS,
    NOT_PRINTED_REGULAR_PRICE_TAGS_FILTERS,
    NOT_ACTUAL_ACTION_PRICE_TAGS_FILTERS,
    NOT_ACTUAL_REGULAR_PRICE_TAGS_FILTERS,
    CUSTOM_PRINTING_ACTION_PRICE_TAGS_FILTERS,
    CUSTOM_PRINTING_REGULAR_PRICE_TAGS_FILTERS,
    PRINTING_HISTORY_FILTERS,
    SEGMENTS_SEARCH_FILTERS,
    ACTIONS_SEARCH_FILTERS,
    ACTIONS_COUPONS_FILTERS,
]

export type UserFavoriteFilters = {
    [K in FiltersComponentInstance]: FilterType[]
}

export type FavoriteFiltersByUsers = {
    [K in string]: UserFavoriteFilters
}
