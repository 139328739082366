import React from 'react'
import { isNil } from 'lodash'
import Chip from '@material-ui/core/Chip'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import classNames from 'classnames'

const styles = require('./chips.scss')

export interface ChipsProps<T = string> {
    id?: string
    items: T[]
    format: (item: T) => string
    formatClassName?: (item: T) => string
    onDelete?: (item: T) => void
    onSelect?: (item: T) => void
    className?: string
    emptyStyles?: React.CSSProperties
}

export function Chips<T = string>({ id, items, format, formatClassName, onDelete, onSelect, className, emptyStyles }: ChipsProps<T>) {
    return (
        <>
            {items.length > 0 ? (
                <React.Fragment>
                    {items.map((item, key) => {
                        const customClassName = formatClassName ? formatClassName(item) : ''
                        return (
                            <Chip
                                id={id}
                                className={classNames(styles.item, className, customClassName)}
                                key={key}
                                label={format(item)}
                                color="primary"
                                onDelete={onDelete ? () => onDelete(item) : undefined}
                                onClick={onSelect ? () => onSelect(item) : undefined}
                            />
                        )
                    })}
                </React.Fragment>
            ) : (
                <Empty style={isNil(emptyStyles) ? {} : emptyStyles} />
            )}
        </>
    )
}
