import { escapeRegExp, difference, isNil } from 'lodash'
import { UserVO } from '../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'

/**
 * Генерирует уникальное имя с числовым постфиксом, сравнивая с уже имеющимися объектами.
 * Например, "Уникальное имя 4". Если в списке нет повторений то вернет "Уникальное имя" без числа.
 *
 * @param {string} nameBase начало имени, "Уникальное имя", к нему будет добавлено число через пробел
 * @param {any[]} others другие имеющиеся элементы, с которыми не должно быть коллизии
 * @param {string} nameField название поля объекта, в котором хранится имя, если null, то думаем. что у нас коллекция строк
 * @returns {string}
 * @deprecated, используем getUniqueNameWithNumberPostfix2
 */
export const getUniqueNameWithNumberPostfix = (nameBase: string, others: any[], nameField: string = null): string => {
    if (!nameBase || !others) return ''

    let useNameField: boolean = false

    if (others && others.length > 0 && nameField
        && !(typeof others[0] === 'string') && (nameField in others[0])) {
        useNameField = true
    } else {
        useNameField = false
    }

    let count: number = 1
    let result: string = ''

    while (!result) {
        let name: string = count === 1 ? nameBase : `${nameBase} ${count}`
        let duplicated: boolean = false
        for (let item of others) {
            if (useNameField && item[nameField] === name) {
                duplicated = true
                break
            } else if (item === name) {
                duplicated = true
                break
            }
        }
        count++
        if (!duplicated) {
            result = name
        }
    }

    return result
}

export const postfixRegexWithBrackets: RegExp = / \((\d{1,})\)/

/**
 * Генерирует уникальное имя с числовым постфиксом, сравнивая с уже имеющимися объектами.
 * Например, "Уникальное имя (2)". Если в списке нет повторений то вернет "Уникальное имя" без числа.
 *
 * @param {string[]} allItems все элементы. С ними не должно быть коллизии
 * @param {string} base шаблон имени
 * @param {RegExp} postfixRegex RegExp для постфикса, по умолчанию такой "(2)"
 * @returns {string}
 */
export function getUniqueNameWithNumberPostfix2(allItems: string[], base: string, postfixRegex: RegExp = postfixRegexWithBrackets): string {
    // приводим массив ['шаблон', 'Новый шаблон', 'Новый шаблон (2)'] к виду [0, 2]
    const matchingNameNumbers: number[] = allItems
        .map(item => {
            const combinedRegex = new RegExp(`${escapeRegExp(base)}(${postfixRegex.source})?$`)
            const matchResults = item.match(combinedRegex)

            if (!matchResults) return null
            if (!matchResults[2]) return 0
            return Number(matchResults[2])
        })
        .filter(num => !isNil(num))

    // массив целых чисел по порядку от 0 до allItems.length [0, 1, ...]
    const simpleOrderedNumbers: number[] = Array.from(new Array(allItems.length)).map((item, i) => i)

    // [разница между этими массивами]
    const diff = difference(simpleOrderedNumbers, matchingNameNumbers)
    diff.sort((a, b) => a - b)

    const newNumber = diff.length > 0 ? diff[0] : allItems.length
    if (newNumber === 0) {
        return base
    }

    return `${base} (${newNumber})`
}

/**
 * Возвращает полное имя пользователя
 * @param  {[UserVO]} user Пользователь
 * @return {[string]}        Полное имя пользователя
 */
export const getUserFullName = (user: { firstName?: string, lastName?: string, middleName?: string }): string => {
    let name: string = ''

    if (!user) return null

    if (user.lastName) {
        name += user.lastName
    }
    if (user.firstName) {
        if (name.length > 0) name += ' '
        name += user.firstName
    }
    if (user.middleName) {
        if (name.length > 0) name += ' '
        name += user.middleName
    }
    
    return name
}

/**
 * Возвращает укороченное имя пользователя (Фамилия И.О.)
 * @param  {[UserVO]} user Пользователь
 * @return {[string]}        Полное имя пользователя
 */
export const getUserShortName = (user: {firstName?: string, lastName?: string, middleName?: string}): string => {
    let name: string = ''

    if (!user) return null

    if (user.lastName) {
        name += user.lastName
    }
    if (user.firstName) {
        if (name.length > 0) name += ' '
        name += `${user.firstName[0]}.`
    }
    if (user.middleName) {
        if (name.length > 0) name += ' '
        name += `${user.middleName[0]}.`
    }
    return name
}

export function encodeNonLatinSymbols(password: string): string {
    return encodeURIComponent(password)
}
