import React from 'react'
import { t } from 'i18next'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import { Tooltip, withStyles } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

import { LOYALTY_SETTINGS_STORE } from '../../../../store/stores'
import { LoyaltySettingsProps } from '../loyalty-settings'
import { CustomTooltip } from './custom-tooltip'

import {
    CHEAP_PRICE_JOINED,
    CHEAP_PRICE_SEPARATELY,
    FairSetPositionPeekMethod
} from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/fair-set-position-peek-method'
import { RadioInput } from '@crystalservice/crystals-ui/lib/components/inputs/radio-input/radio-input'

const styles = require('../loyalty-settings.scss')

const DarkTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        padding: '15px 10px',
    }
}))(Tooltip)

const Component: React.FunctionComponent<LoyaltySettingsProps> = ({ loyaltySettingsStore }) => {
    const { settings, editSettings } = loyaltySettingsStore
    return (
        <Grid
            container
            alignItems="center"
            className={classNames(styles.settingsRow, styles.generalSettingsSpacing)}
        >
            <Grid item xs={12}>
                <Checkbox
                    id="fairSetsCheckbox"
                    checked={settings?.fairSets}
                    label={t('loyaltySettings.fairSetsLabel')}
                    onValueChange={checked => editSettings({ fairSets: checked })}
                />

                {settings?.fairSets && (
                    <Grid container className={styles.settingsRow}>
                        <div className={styles.generalOptionsRadioGroup}>
                            <div>
                                <RadioInput
                                    id="cheapPriceJoined"
                                    value={CHEAP_PRICE_JOINED}
                                    checked={loyaltySettingsStore?.settings?.fairSetPositionPeekMethod === CHEAP_PRICE_JOINED}
                                    onValueChange={(value: FairSetPositionPeekMethod) => editSettings({ fairSetPositionPeekMethod: value })}
                                    label={t('loyaltySettings.cheapPriceJoinedLabel')}
                                />
                                <DarkTooltip
                                    title={
                                        <CustomTooltip
                                            set1={[
                                                { value: { int: '100', float: '00' } },
                                                { value: { int: '90', float: '00' } },
                                                { value: { int: '60', float: '00' }, lowestInSet: true }
                                            ]}
                                            set2={[
                                                { value: { int: '80', float: '00' } },
                                                { value: { int: '70', float: '00' } },
                                                { value: { int: '50', float: '00' }, lowestInSet: true }
                                            ]}
                                        />
                                    }
                                >
                                    <Info className={styles.radioInfoIcon} />
                                </DarkTooltip>
                            </div>

                            <div>
                                <RadioInput
                                    id="chapPriceSeparately"
                                    value={CHEAP_PRICE_SEPARATELY}
                                    checked={loyaltySettingsStore?.settings?.fairSetPositionPeekMethod === CHEAP_PRICE_SEPARATELY}
                                    onValueChange={(value: FairSetPositionPeekMethod) => editSettings({ fairSetPositionPeekMethod: value })}
                                    label={t('loyaltySettings.cheapPriceSeparatelyLabel')}
                                />
                                <DarkTooltip
                                    title={
                                        <CustomTooltip
                                            set1={[
                                                { value: { int: '100', float: '00' } },
                                                { value: { int: '90', float: '00' } },
                                                { value: { int: '80', float: '00' }, lowestInSet: true }
                                            ]}
                                            set2={[
                                                { value: { int: '70', float: '00' } },
                                                { value: { int: '60', float: '00' } },
                                                { value: { int: '50', float: '00' }, lowestInSet: true }
                                            ]}
                                        />
                                    }
                                >
                                    <Info className={styles.radioInfoIcon} />
                                </DarkTooltip>
                            </div>
                        </div>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    id="lossOfProfitNotificationCheckbox"
                    checked={settings?.lossOfProfitNotification}
                    label={t('loyaltySettings.lossOfProfitNotificationLabel')}
                    onValueChange={lossOfProfitNotification => editSettings({ lossOfProfitNotification })}
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    id="segregateDiscountsCheckbox"
                    checked={settings?.segregateDiscounts}
                    label={t('loyaltySettings.segregateDiscounts')}
                    onValueChange={segregateDiscounts => editSettings({ segregateDiscounts })}
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    id="roundDiscountUseNDSCheckbox"
                    checked={settings?.useNdsDuringRoundDiscountDistribution}
                    label={t('loyaltySettings.roundDiscountUseNDSLabel')}
                    onValueChange={useNdsDuringRoundDiscountDistribution => editSettings({
                        useNdsDuringRoundDiscountDistribution
                    })}
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    id="fz54CompatibleCheckbox"
                    checked={settings?.fz54Compatible}
                    label={t('loyaltySettings.fz54CompatibleLabel')}
                    onValueChange={fz54Compatible => editSettings({ fz54Compatible })}
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    id="groupCashierMessageActionResultsCheckbox"
                    checked={settings?.groupCashierMessageActionResults}
                    label={t('loyaltySettings.groupCashierMessageActionResultsLabel')}
                    onValueChange={groupCashierMessageActionResults => editSettings({ groupCashierMessageActionResults })}
                />
            </Grid>
        </Grid>
    )
}

export const LoyaltySettingsGeneralOptions = inject(LOYALTY_SETTINGS_STORE)(observer(Component))
