import React from 'react'
import { t } from 'i18next'
import moment from 'moment'
import classNames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import { inject, observer } from 'mobx-react'
import { ACTION_SETTINGS_STORE, USER_STORE, APP_STORE } from '../../../../../store/stores'
import { ActionSettingsStore } from '../../../../../store/actions/action-settings-store'
import Button from '@material-ui/core/Button'
import { AdvertisingAction } from '../../../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { CustomizableDraggableItemVO, DraggableItemRendererProps } from '../../../../../../components/items-sorter/draggable-item'
import { DraggableItemsSorter } from '../../../../../../components/items-sorter/draggable-items-sorter'
import { withSpinner } from '../../../../../store/with-spinner'
import orderBy from 'lodash/orderBy'
import { UserStore } from '../../../../../store/user-store'
import { createActionsFilter } from '../../../../../../protocol/set10/set-retail10-server/retailx/server-ds/actions-filter'
import {
    FIRST_ORDER, WORKS_ANY, MAXIMUM_DISCOUNT, MAXIMUM_SETS_INDEPENDENT_DISCOUNT, GeneralInteractionMethod
} from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/general-interaction-method'
import { AppStore } from '../../../../../store/app-store'
import { fromClientToServerTime } from '../../../../../utils/app-util'
import { actionsSelectorFacade } from '../../../../../../protocol/set10/actions-selector-facade'

const styles = require('./action-priority-editor.scss')

export interface ActionPriorityProps {
    actionSettingsStore?: ActionSettingsStore
    user?: UserStore
    app?: AppStore
    generalInteractionMethod: GeneralInteractionMethod
}

export interface ActionPriorityState {
    actions: AdvertisingAction[]
}

@inject(APP_STORE)
@inject(USER_STORE)
@inject(ACTION_SETTINGS_STORE)
@observer
export class ActionPriorityEditor extends React.Component<ActionPriorityProps, ActionPriorityState> {

    state: ActionPriorityState = {
        actions: [],
    }

    mounted: boolean = false

    componentDidMount(): void {
        this.mounted = true
        this.fetchActions()
    }

    componentWillUnmount(): void {
        this.mounted = false
    }

    fetchActions = async (): Promise<void> => {
        const { session } = this.props.user
        const { editedAction, _isWorkingAllTime } = this.props.actionSettingsStore
        const { generalInteractionMethod } = this.props

        const allActions = await withSpinner(actionsSelectorFacade.getActionsByFilterWithOffset(
            createActionsFilter({
                keyWord: '%',
            }),
            [],
            0,
            1000
        )) || []

        if (!this.mounted) {
            return
        }

        const now: Date = this.props.app.now()
        const actionsFilters: Array<(action: AdvertisingAction) => boolean> = [
            (action: AdvertisingAction) => {
                if (_isWorkingAllTime(action)) {
                    return true
                }

                return action.workPeriod.finish > now
            }
        ]

        switch (generalInteractionMethod) {
            default:
            case FIRST_ORDER:
            case WORKS_ANY:
                actionsFilters.push((action: AdvertisingAction) => action.worksAnytime === editedAction.worksAnytime)
                break
            case MAXIMUM_DISCOUNT:
            case MAXIMUM_SETS_INDEPENDENT_DISCOUNT:
                actionsFilters.push((action: AdvertisingAction) => action.worksAnytime)
                break
        }

        const filteredActions = allActions.filter((action: AdvertisingAction) => !actionsFilters.some(filter => !filter(action)))

        if (!filteredActions.find(action => action.id === editedAction.id)) {
            filteredActions.push(editedAction)
        }

        let orderedActions = orderBy(filteredActions, 'priority', 'asc')

        this.setState({
            actions: orderedActions
        })
    }

    applyHandler = async (): Promise<void> => {
        const { updateActionsPriority, showPriorityEditor, updatePriorityOfCurrentAction } = this.props.actionSettingsStore
        const { editedAction } = this.props.actionSettingsStore
        const { actions } = this.state

        const editedActionIndex = actions.findIndex(action => action.id === editedAction.id)

        await updateActionsPriority({
            editedAction: actions[editedActionIndex],
            actionBefore: actions[editedActionIndex - 1],
            actionAfter: actions[editedActionIndex + 1]
        })
        await this.fetchActions()
        await updatePriorityOfCurrentAction()
        showPriorityEditor(false)
    }

    get draggableActions(): CustomizableDraggableItemVO[] {
        const { actions } = this.state
        const { editedAction } = this.props.actionSettingsStore

        return actions.map((action, index) => {
            const canDrag = editedAction.id === action.id

            return {
                key: String(index),
                label: String(action.id),
                canDrag,
                renderer: (props: DraggableItemRendererProps) => {
                    return (
                        <div
                            className={classNames(
                                styles.actionItem,
                                styles[action.displayStyleName],
                                getActionColorStyle(action, editedAction.id === action.id),
                                {
                                    [styles.draggingItem]: props.isDragging,
                                    [styles.canDragItem]: canDrag,
                                },
                            )}
                        >
                            <div className={styles.actionNumber}>{`${index + 1}.`}</div>
                            <div className={styles.actionName}>{action.name}</div>
                            <div className={styles.actionDates}>
                                { formatActionWorkPeriod(action.workPeriod) }
                            </div>
                        </div>
                    )
                }
            }
        })
    }

    render() {
        const { showPriorityEditor } = this.props.actionSettingsStore
        const { actions } = this.state

        return (
            <Drawer
                id="actionPriorityEditor"
                PaperProps={{
                    classes: {
                        root: styles.actionPriorityEditor
                    }
                }}
                open
                anchor="right"
                onClose={() => showPriorityEditor(false)}
            >
                <div className={styles.priorityContainer}>
                    <div className={styles.actionPriorityTitle}>
                        {t('advertisingActions.priorityEditorTitle')}
                    </div>
                    <div className={styles.actionPriorityText}>
                        {t('advertisingActions.priorityEditorText')}
                    </div>
                    <div className={styles.actionPriorityList}>
                        <DraggableItemsSorter<CustomizableDraggableItemVO>
                            dragAndDropType="DraggableItemsSelect"
                            defaultItems={this.draggableActions}
                            key={this.draggableActions.map(item => item.label).join()}
                            onItemsChange={(newItems: CustomizableDraggableItemVO[]) => {
                                const newActions = newItems.reduce((acc, item) => {
                                    acc.push(actions.find(action => String(action.id) === item.label))

                                    return acc
                                }, [])
                                this.setState({ actions: newActions })
                            }}
                            ItemsContainerProps={{
                                className: styles.actionDraggableArea
                            }}
                        />
                    </div>
                </div>
                <div className={styles.actionButtonsPanel}>
                    <Button
                        color="primary"
                        id="actionPriorityEditorCancelButton"
                        className={styles.actionButton}
                        variant="text"
                        onClick={() => showPriorityEditor(false)}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        color="primary"
                        id="actionPriorityEditorApplyButton"
                        className={styles.actionButton}
                        variant="contained"
                        onClick={() => this.applyHandler()}
                    >
                        {t('common.apply')}
                    </Button>
                </div>
            </Drawer>
        )
    }
}

const getActionColorStyle = (action: AdvertisingAction, isEditedAction: boolean): string => {
    if (isEditedAction) {
        return styles.editedAction
    }

    const isActive = action.active
    const now = moment(fromClientToServerTime(new Date()))

    const actionStart = action.workPeriod.start && fromClientToServerTime(new Date(action.workPeriod.start))
    const actionFinish = action.workPeriod.finish && fromClientToServerTime(new Date(action.workPeriod.finish))
    const isPast = actionFinish && now.isAfter(moment(actionFinish))
    const isFuture = actionStart && now.isBefore(moment(actionStart))

    if (!isActive) {
        // Черновик
        return styles.draftAction
    }

    if (isPast) {
        // Завершившаяся акция
        return styles.pastAction
    }

    if (isFuture) {
        // Запланированная акция
        return styles.futureAction
    }

    // Действующая акция
    return styles.presentAction
}

function formatActionWorkPeriod(workPeriod: AdvertisingAction['workPeriod']): string {
    let res: string = ''
    if (!workPeriod) {
        return '-'
    }
    if (workPeriod.start) {
        res += moment(new Date(workPeriod.start)).format('DD.MM.YY')
    } else {
        res += '...'
    }

    res += ' - '

    if (workPeriod.finish) {
        res += moment(new Date(workPeriod.finish)).format('DD.MM.YY')
    } else {
        res += '...'
    }

    return res
}
