import {observable, action, computed, runInAction} from 'mobx'
import {goTo, RouteChangeHandler} from '../../utils/router-util'
import { iEquipmentManagerLocal } from '../../../protocol/set10/i-equipment-manager-local'
import { EquipmentClassVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-class-vo'
import { EquipmentModelVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { TabInfo } from '../../../components/tabs/tabs'
import {
    SETTINGS, ROOT,
    TOPOLOGY, SHOP, SHOP_SETTINGS
} from '../../core/app-routes'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import {t} from 'i18next'
import {SETRETAILX_EQUIPMENT_ADMIN} from '../../core/privileges/privileges'
import { UserStore } from '../user-store'
import { AppStore } from '../app-store'
import { SnackbarStore } from '../snackbar-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE, SNACKBAR_STORE, APP_BAR_STORE, DEVICES_STORE } from '../stores'

export class DevicesStore {
    @observable
    loaded: boolean = false

    @observable
    allClasses: EquipmentClassVO[] = []

    // Все модели хранятся в двумерном массиве - первый уровень = классы и у каждого класса свой массив с моделями
    @observable
    allModels: { [className: string]: EquipmentModelVO[] } = {}

    @observable
    registeredModels: { [className: string]: EquipmentModelVO[] } = {}

    @observable
    filterString: string = ''

    @observable
    lastUsedTabIndex: number = 0

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    @computed
    get tabsNames(): TabInfo[] {
        return this.allClasses.map(item => ({label: item.localizedName, data: item.name}))
    }

    // Применение фильтра к списку всего доступного оборудования
    @computed
    get filteredModels(): { [className: string]: EquipmentModelVO[] } {
        const filterString = this.filterString.toLowerCase()
        if (filterString === '') {
            return this.allModels
        }

        let filteredModels = {}
        this.allClasses.map(classObj => {
            const className = classObj.name
            let classModels = this.allModels[className]
            filteredModels[className] = []

            classModels.forEach(modelObj => {
                const modelName = modelObj.localizedName.toLowerCase()
                const modelType = modelObj.equipmentType.localizedName.toLowerCase()

                // Необходимо, чтобы mobx отслеживал изменения в том числе этого параметра
                const modelSettings = modelObj.hasSettings

                if (modelName.indexOf(filterString) !== -1 || modelType.indexOf(filterString) !== -1) {
                    // Нашли в имени модели либо в имени "Типа" - добавляем
                    filteredModels[className].push(modelObj)
                }
            })
        })

        return filteredModels
    }

    @action
    setLastUsedTabIndex = (value: number): void => {
        this.lastUsedTabIndex = value
    }

    fetchAllData = async (): Promise<any> => {
        // Данные с сервера запрашиваем только первый раз - они не изменятся уже
        // При повторных запросах спрашиваем только список зарегистрированных - он может менятся
        if (this.loaded) {
            return this.getAllRegisteredModels(true)
        }

        await this.getNotRegisteredClasses()
        await this.getAllRegisteredModels()
        await this.getAllModels()
        await this.markModelsAsAlreadyRegistered()
        this.loaded = true
    }

    getNotRegisteredClasses = (): Promise<any> => {
        return iEquipmentManagerLocal.getNotRegisteredClasses2(this.userStore.session, this.appStore.locale)
            .then(classes => {
                runInAction(() => {
                    this.allClasses = classes
                })
            })
    }

    getAllModels = async (): Promise<any> => {
        let models = {}
        let promises = []
        this.allClasses.forEach(classObj => {
            promises.push(() => iEquipmentManagerLocal.getAllEquipmentModelsOfClass(this.userStore.session, classObj.name, this.appStore.locale)
                .then(newModels => {
                    newModels.forEach(model => {
                        // Обнуляем для использования "отметки уже зарегистрированного оборудования"
                        model.hasSettings = false
                    })
                    models[classObj.name] = newModels
                })
            )
        })
        await Promise.all(promises)
        runInAction(() => {
            this.allModels = models
        })
    }

    getAllRegisteredModels = async (updateAllModels: boolean = false): Promise<any> => {
        let models = {}
        let promises = []
        this.allClasses.forEach(classObj => {
            promises.push(() => iEquipmentManagerLocal.getAllRegisteredEquipmentModelsOfClass(
                this.userStore.session, classObj.name, this.appStore.locale)
                .then(newModels => {
                    models[classObj.name] = newModels
                })
            )
        })
        await Promise.all(promises)
        runInAction(() => {
            this.registeredModels = models
        })
        if (updateAllModels) {
            this.markModelsAsAlreadyRegistered()
        }
    }

    @action
    registerNewModel = async (device: EquipmentModelVO): Promise<any> => {
        let equipmentType = device.equipmentType
        let className = equipmentType.equipmentClass.name
        let typeName = equipmentType.name
        let modelName = device.name
        await iEquipmentManagerLocal.registerAndGetModel(this.userStore.session, className, typeName, modelName, this.appStore.locale)
        await this.getAllRegisteredModels(true)
        this.snackbarStore.show({message: t('devices.deviceAdded')})
    }

    /**
     * Используется, чтобы пометить модели "среди отображаемых на экране добавления" как уже зарегистрированные
     */
    @action
    markModelsAsAlreadyRegistered = (): void => {
        this.allClasses.forEach(classObj => {
            const className = classObj.name
            let tempRegisteredModels =  [...this.registeredModels[className]]

            let classModels = this.allModels[className]
            classModels.forEach(modelObj => {
                // Будем изменять  объект с настройками т.к. он не используется в списке всех моделей
                // Обнуляем всем элементам
                modelObj.hasSettings = false
                const modelName = modelObj.name
                const modelType = modelObj.equipmentType.name

                let foundIndex = tempRegisteredModels.findIndex(value => {
                    if (value.name === modelName && value.equipmentType.name === modelType){
                        return true
                    }
                    return false
                })

                if (foundIndex !== -1) {
                    // Удалим из временного массива и пометим как уже зарегистрированное
                    modelObj.hasSettings = true
                    tempRegisteredModels.splice(foundIndex, 1)
                }
            })
        })
    }

    removeRegisteredDevice = async (device: EquipmentModelVO): Promise<any> => {
        await iEquipmentManagerLocal.unRegisterEquipment(this.userStore.session, device.id)
        // Для проверки необходимо обновить данные с сервера
        await this.getAllRegisteredModels(true)
    }

    @action
    goToAddNewDevice = (): void => {
        // TODO Переход на экран добавления оборудования
    }

    @action
    searchInputChange = (value: string): void => {
        this.filterString = value
    }

    checkPrivilege = (): boolean => {
       if (!this.userStore.havePrivilege(SETRETAILX_EQUIPMENT_ADMIN)) {
           goTo(ROOT)
           return false
       }
       return true
    }

    @action
    reset = (): void => {
        this.loaded = false
        this.allClasses = []
        this.allModels = {}
        this.registeredModels = {}
        this.filterString = ''
        this.lastUsedTabIndex = 0
    }
}

export const NEW_DEVICE_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
        routeMatcher: /^\/settings\/devices\/new$/,
        onEnter: () => {
            const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

            let leftIconClick = () => {
                // TODO Переход на экран со списком оборудования
            }

            // Если мы переходили с экрана настройки магазина = то и возвращаемся тудаже
            if (appBarStore.prevRoute && appBarStore.prevRoute.indexOf(`${TOPOLOGY}${SHOP}`) !== -1) {
                leftIconClick = () => {
                    let allRoutes = appBarStore.prevRoute.split('/')
                    let shopId = allRoutes[allRoutes.length - 1]
                    goTo(`${SETTINGS}${TOPOLOGY}${SHOP}/${shopId}`)
                }
            }

            if (appBarStore.prevRoute && appBarStore.prevRoute.indexOf(`${SETTINGS}${SHOP_SETTINGS}`) !== -1) {
                leftIconClick = () => {
                    goTo(`${SETTINGS}${SHOP_SETTINGS}`)
                }
            }

            appBarStore.updateState({
                title: t('devices.newDeviceNavBar'),
                leftIcon: LEFT_ARROW,
                onLeftIconClick: leftIconClick
            })
        },
        onLeave: () => {
            const devicesStore: DevicesStore = getStore(DEVICES_STORE)
            devicesStore.filterString = ''
        }
}
