import React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import { useTheme } from '@material-ui/core'

interface TabsWrapperProps extends Omit<DialogProps, 'fullScreen'> {
    breakpoint?: Breakpoint
}

// https://github.com/mui-org/material-ui/issues/16073
export const AdaptiveDialog: React.FunctionComponent<TabsWrapperProps> = props => {
    const { breakpoint = 'md', ...other } = props
    const largeScreen = useMediaQuery(useTheme().breakpoints.up(breakpoint))

    return (
        <Dialog
            {...other}
            fullScreen={!largeScreen}
        >
            { props.children }
        </Dialog>
    )
}
