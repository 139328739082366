import { EquipmentModelVO } from '../../../set-retail10-commons/data-structs-module/equipment-model-vo'
import { PriceTagTemplateVO } from '../../../set-retail10-commons/data-structs-module/price-tag-template-vo'
import { PrintDocType } from './print-doc-type'
import { CashVO } from '../../../set-retail10-commons/data-structs-module/cash-vo'
import { UserVO } from './user-vo'

export interface PrinterVO {
    '@class': 'ru.crystals.setretailx.printers.PrinterVO'
    id?: number
    name?: string
    address?: string
    dpi?: number
    equipmentModel?: EquipmentModelVO
    priceTags?: PriceTagTemplateVO[]
    templateIds?: string[]
    type?: string
    width?: number
    height?: number
    leftMargin?: number
    rightMargin?: number
    topMargin?: number
    bottomMargin?: number
    model?: string
    printType?: PrintDocType
    cashes?: CashVO[]
    shop?: number
    defaultFeedResolution?: number
    defaultCrossFeedResolution?: number
    users?: UserVO[]
}

export const createPrinterVO = (initialization: Partial<PrinterVO>): PrinterVO => {
    if (!initialization) return null
    
    let result: PrinterVO = {
        id: initialization.id,
        name: initialization.name,
        address: initialization.address,
        dpi: initialization.dpi,
        equipmentModel: initialization.equipmentModel,
        priceTags: initialization.priceTags,
        templateIds: initialization.templateIds,
        type: initialization.type,
        width: initialization.width,
        height: initialization.height,
        leftMargin: initialization.leftMargin,
        rightMargin: initialization.rightMargin,
        topMargin: initialization.topMargin,
        bottomMargin: initialization.bottomMargin,
        model: initialization.model,
        printType: initialization.printType,
        cashes: initialization.cashes,
        shop: initialization.shop,
        defaultFeedResolution: initialization.defaultFeedResolution,
        defaultCrossFeedResolution: initialization.defaultCrossFeedResolution,
        users: initialization.users,
        '@class': 'ru.crystals.setretailx.printers.PrinterVO',
    }
    
    return result
}
