import { action, computed, observable, runInAction, toJS } from 'mobx'
import moment from 'moment'
import { isNil } from 'lodash'
import {
    OperDay,
    READY_TO_CLOSE,
    READY_TO_OPEN
} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/oper-day'
import { cashDeskManagerLocal } from '../../../protocol/set10/cash-desk-manager-local'
import { CashDesk } from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/cash-desk'
import {
    CLOSED_NONVALID as SHIFT_CLOSED_NONVALID,
    OPEN as SHIFT_OPEN,
    Shift,
} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/shift'
import { OperDayInfo } from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/oper-day-info'
import { OPERDAY, SHIFTS } from '../../core/app-routes'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { t } from 'i18next'
import { setDateTime } from '../../../utils/date-util'
import { OPERDAY_CASH_REPAIR_BACK_PRIVILEGE, SALE_DENIED } from '../../core/privileges/privileges'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import {
    APP_BAR_STORE, APP_STORE,
    KM_3_REPORT_STORE,
    KM_6_REPORT_STORE,
    OPERDAY_STORE,
    SNACKBAR_STORE,
    USER_STORE
} from '../stores'
import { SnackbarStore } from '../snackbar-store'
import { MILLISECONDS } from '../../../utils/date/date-format'
import { withSpinner } from '../with-spinner'
import { KmReportStore } from './km-reports-store'
import { Km3PositionVO } from '../../../protocol/set10/set-retail10-commons/set-oper-day-main-cash-api/km3-position-vo'
import { Km6PositionVO } from '../../../protocol/set10/set-retail10-commons/set-oper-day-main-cash-api/km6-position-vo'
import { cashiersStatusesFacadeLocal } from '../../../protocol/set10/cashiers-statuses-facade-local'
import { reportsProcessorLocal } from '../../../protocol/set10/reports-processor-local'
import { cashiersManagerLocal } from '../../../protocol/set10/cashiers-manager-local'
import { salesManagementPropertiesService } from '../../../protocol/set10/sales-management-properties-service'
import { SET_OPERDAY } from '../../core/app-modules'
import uuid from 'uuid'
import { CashierDayVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cashier-day-vo'
import { CashierWorkPeriodVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cashier-work-period-vo'
import { fromClientToServerTime, fromServerToClientTime } from '../../utils/app-util'
import { AppStore } from '../app-store'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'

export type ShiftTab = 'title_shift_data' | 'title_cancel_checks' | 'title_taxes_and_payments'

export const SHIFT_DATA: ShiftTab = 'title_shift_data'
export const ANNULLED_CHECKS: ShiftTab = 'title_cancel_checks'
export const PAYMENT_AND_TAX_TYPES: ShiftTab = 'title_taxes_and_payments'

const sendForRepair = true
const returnFromRepair = false

export enum SHIFTS_TAB {
    CASH_DESKS = 'CASH_DESKS',
    PROBLEM_SHIFTS = 'PROBLEM_SHIFTS',
    DAY_RESULTS = 'DAY_RESULTS',
    REPORT_KM_3 = 'REPORT_KM_3',
    REPORT_KM_6 = 'REPORT_KM_6',
    CASHIERS = 'CASHIERS',
    REPORT_KO_5 = 'REPORT_KO_5',
    REPORT_FORM_25 = 'REPORT_FORM_25',
}

export class OperdayStore {

    @observable
    date: Date = setDateTime(fromClientToServerTime(new Date()), 0, MILLISECONDS)

    @observable
    operday: OperDay

    @observable
    problemShifts: Shift[] = []

    @observable
    operdayInfo: OperDayInfo

    @observable
    cash: CashDesk

    @observable
    shift: Shift

    @observable
    currentOperdayTab: SHIFTS_TAB = null

    @observable
    paginationFilter: string = ''

    @observable
    cashierRolesNames: string[] = []

    @observable
    cashierDays: CashierDayVO[] = null

    @observable
    availableTabs: SHIFTS_TAB[] = null

    @observable
    operdayTabsKey: string = null

    @computed
    get isTherePrivilege(): boolean {
        if (!this.shift) return false
        return this.userStore.havePrivilege(OPERDAY_CASH_REPAIR_BACK_PRIVILEGE) &&
                (this.shift.status === SHIFT_OPEN || this.shift.status === SHIFT_CLOSED_NONVALID)
    }

    @computed
    get shopsCount(): number {
        const cashDesks = this.operday.cashDesks
        let shopNumbers = new Map<number, boolean>()
        cashDesks.forEach(cash => shopNumbers.set(cash.shopNumber, true))
        return shopNumbers.size
    }

    @computed
    get collapsedOperdayCashDesks(): CashDesk[] {
        if (!this.operday || !this.operday.cashDesks) return null

        const cashDesks = toJS(this.operday.cashDesks)
        return cashDesks.map(cashDesk => {
            if (!cashDesk.shifts || cashDesk.shifts.length === 0) {
                cashDesk.shifts = []
            }
            else {
                cashDesk.shifts = [cashDesk.shifts[0]]
            }

            return cashDesk
        })
    }

    pollingTimer: any = null
    pollingTimeOut: number = 10 * 1000

    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private reportKm3Store: KmReportStore<Km3PositionVO> = getStore(KM_3_REPORT_STORE)
    private reportKm6Store: KmReportStore<Km6PositionVO> = getStore(KM_6_REPORT_STORE)

    startPolling = (): void => {
        const polledTabs: Set<SHIFTS_TAB> = new Set<SHIFTS_TAB>([
            SHIFTS_TAB.CASH_DESKS,
            SHIFTS_TAB.PROBLEM_SHIFTS,
            SHIFTS_TAB.DAY_RESULTS,
            SHIFTS_TAB.CASHIERS
        ])

        this.pollingTimer = setTimeout(async () => {
            await this.refetchOperdayTabInfo(polledTabs)
            this.startPolling()
        }, this.pollingTimeOut)
    }

    stopPolling = (): void => {
        clearTimeout(this.pollingTimer)
    }

    restartPolling = (): void => {
        this.stopPolling()
        this.startPolling()
    }

    refetchOperdayTabInfo = async (allowedTabs: Set<SHIFTS_TAB>, ignoreSpinner: boolean = true): Promise<void> => {
        // всегда запрашиваем данные опердня
        await this.fetchOperday(ignoreSpinner)

        // Если очень большое кол-во магазинов, то обновление затормаживает интерфейс, уменьшаем время обновления
        if (this.shopsCount > 200) {
            this.pollingTimeOut = 60 * 1000
        }

        // проверяем, нужно ли запрашивать данные для текущей вкладки
        if (!allowedTabs.has(this.currentOperdayTab)) {
            return
        }

        // В зависимости от выбранной вкладки запрашиваем определенные данные
        switch (this.currentOperdayTab) {
            // ничего не запрашиваем для первой вкладки - опердень и так запрашивается каждый раз
            case SHIFTS_TAB.CASH_DESKS:
            // для отчетов КО-5 дополнительные данные не нужны
            case SHIFTS_TAB.REPORT_KO_5:
            default:
                break
            case SHIFTS_TAB.PROBLEM_SHIFTS:
                await this.fetchProblemShifts(ignoreSpinner)
                break
            case SHIFTS_TAB.CASHIERS:
                await this.fetchCashierDays(ignoreSpinner)
                break
            case SHIFTS_TAB.DAY_RESULTS:
                await this.fetchOperdayInfo(ignoreSpinner)
                break
            case SHIFTS_TAB.REPORT_KM_3:
                await this.fetchKm3Report(ignoreSpinner)
                break
            case SHIFTS_TAB.REPORT_KM_6:
                await this.fetchKm6Report(ignoreSpinner)
                break
        }
    }

    setAvailableTabs = async (): Promise<void> => {
        const properties = await salesManagementPropertiesService.getProperties1(SET_OPERDAY)
        const property = properties['operday.shifts.tab'] || ''
        const tabsSequenceSettings = property.split('/') || []

        const alwaysAvailableTabs = [
            SHIFTS_TAB.PROBLEM_SHIFTS,
            SHIFTS_TAB.DAY_RESULTS,
            SHIFTS_TAB.REPORT_KM_3,
            SHIFTS_TAB.REPORT_KM_6,
            SHIFTS_TAB.REPORT_KO_5,
            SHIFTS_TAB.REPORT_FORM_25
        ]

        const serverConfiguredAvailableTabs = tabsSequenceSettings
            .map(tabName => {
                if (tabName === 'cashiers') return SHIFTS_TAB.CASHIERS
                if (tabName === 'cashes') return SHIFTS_TAB.CASH_DESKS
            })
            .filter(tab => !isNil(tab))

        const availableTabs = [...serverConfiguredAvailableTabs, ...alwaysAvailableTabs]

        runInAction(() => {
            this.availableTabs = availableTabs
            if (!this.currentOperdayTab) {
                this.setCurrentOperdayTab(availableTabs[0])
                this.updateOperdayTabsKey()
            }
        })
    }

    @action
    setCurrentOperdayTab = (currentOperdayTab: SHIFTS_TAB) => {
        this.currentOperdayTab = currentOperdayTab
    }

    @action
    setDate = (date: Date): void => {
        this.date = date
    }

    @action
    updateOperdayTabsKey = (): void => {
        this.operdayTabsKey = uuid()
    }

    @action
    setPaginationFilter = (value: string): void => {
        this.paginationFilter = value
    }

    fetchKm3Report = async (ignoreSpinner?: boolean): Promise<void> => {
        const request = reportsProcessorLocal.getKm3(fromServerToClientTime(this.date), this.userStore.session)
        const km3Reports = ignoreSpinner ? await request : await withSpinner(request)

        this.reportKm3Store.setKmReports(km3Reports)
        this.reportKm3Store.resetSelectedKmReports()
    }

    fetchKm6Report = async (ignoreSpinner?: boolean): Promise<void> => {
        const request = reportsProcessorLocal.getKm6(fromServerToClientTime(this.date), this.userStore.session)
        const km6Reports = ignoreSpinner ? await request : await withSpinner(request)

        this.reportKm6Store.setKmReports(km6Reports)
        this.reportKm6Store.resetSelectedKmReports()
    }

    fetchOperday = async (ignoreSpinner?: boolean): Promise<any> => {
        // TODO также приходится исправлять временную зону сервера
        const request = cashDeskManagerLocal.findCashDesks1(this.userStore.session, fromServerToClientTime(this.date))
        const operday = ignoreSpinner ? await request : await withSpinner(request)

        runInAction(() => {
            // this.operday = getFakeOperday()
            this.operday = operday
        })
    }

    fetchProblemShifts = async (ignoreSpinner?: boolean): Promise<any> => {
        const request = cashDeskManagerLocal.getProblematicShiftsJSON(this.userStore.session)
        const shifts = ignoreSpinner ? await request : await withSpinner(request)

        // TODO SFM-208 ошибки транспорта
        if (shifts) {
            shifts.forEach(shift => {
                if (!(shift.operDay instanceof Date)) {
                    // unixtime -> Date
                    shift.operDay = new Date(shift.operDay)
                }
            })
        }

        runInAction(() => {
            this.problemShifts = shifts
        })
    }

    @action
    fetchCashierDays = async (ignoreSpinner?: boolean): Promise<void> => {
        const request = cashiersStatusesFacadeLocal.getBriefCashiersDays(fromServerToClientTime(this.date))
        const cashierDays = ignoreSpinner ? await request : await withSpinner(request)
        const sortedNamesCashierDays = cashierDays.sort(sortNames)
        const sortedShiftsCashierDays = []
        sortedNamesCashierDays.forEach(cashierDay => {
            const {
                workPeriodVOS,
                ...restCashierDay
            } = cashierDay
            const sortedWorkPeriodVOS = workPeriodVOS.sort(sortShifts)
            sortedShiftsCashierDays.push(
                {
                    ...restCashierDay,
                    workPeriodVOS: sortedWorkPeriodVOS,
                }
            )
        })
        runInAction(() => {
            this.cashierDays = sortedShiftsCashierDays || []
        })
    }

    fetchOperdayInfo = async (ignoreSpinner?: boolean): Promise<any> => {
        const request = cashDeskManagerLocal.getOperDayInfo1(this.userStore.session, fromServerToClientTime(this.date))
        const info = ignoreSpinner ? await request : await withSpinner(request)

        runInAction(() => {
            this.operdayInfo = info
        })
    }

    fetchCashierRolesNames = async (): Promise<void> => {
        const roles = await cashiersManagerLocal.getRoles(this.userStore.session)
        runInAction(() => {
            this.cashierRolesNames = roles
                .filter(role =>
                    !role.rights.find(right =>
                        right.name === SALE_DENIED))
                .map(role => role.name)
        })
    }

    changeOperdayStatus = async (): Promise<any> => {
        if (!this.operday) return
        const status = this.operday.status

        switch (status) {
            case READY_TO_OPEN:
                const openedOperday = await cashDeskManagerLocal.reopenOperDay(this.userStore.session, this.operday)
                runInAction(() => {
                    this.operday = openedOperday
                })
                break
            case READY_TO_CLOSE:
                const closedOperday = await cashDeskManagerLocal.closeOperDay(this.userStore.session, this.operday)
                runInAction(() => {
                    this.operday = closedOperday
                })
                break
        }
    }

    sendMessageToCashier = (message: string, shopNumber: number, cashNumber: number): Promise<void> => {
        return cashDeskManagerLocal.sendMessageToCashier(this.userStore.session, shopNumber, cashNumber, message)
    }

    showSendCashToRepairDialog = (): void => {
        this.appStore.showDialog({
            title: t('cashPage.attention'),
            message: t('cashPage.sendCashToRepairQuestion'),
            mode: DIALOG,
            onYes: () => this.sendCashToRepair()
        })
    }

    sendCashToRepair = async (): Promise<void> => {
        if (!this.operday || !this.cash || !this.shift) return Promise.resolve()

        const result = await cashDeskManagerLocal.repair(this.userStore.session, this.cash, this.shift.number, this.operday.date, sendForRepair)

        if (result) {
            runInAction(() => {
                this.shift.repair = true
                this.snackbarStore.show({message: t('operday.sentToRepair')})
            })
        }
    }

    returnCashFromRepair = async (): Promise<void> => {
        if (!this.operday || !this.cash || !this.shift) return Promise.resolve()

        const result = await cashDeskManagerLocal.repair(this.userStore.session, this.cash, this.shift.number, this.operday.date, returnFromRepair)

        if (result) {
            runInAction(() => {
                this.shift.repair = false
                this.snackbarStore.show({message: t('operday.returnFromRepair')})
            })
        }
    }

    openCash = async (cash: CashDesk, date: Date): Promise<void> => {
        const cashFullInfo = await withSpinner(cashDeskManagerLocal.getCashDeskInfo1(
            this.userStore.session,
            toJS(cash),
            date
        ))
        if (!cashFullInfo) return

        // TODO SFM-208 ошибки транспорта
        if (cashFullInfo.shifts) {
            cashFullInfo.shifts.forEach(shift => {
                if (!(shift.operDay instanceof Date)) {
                    // unixtime -> Date
                    shift.operDay = new Date(shift.operDay)
                }
                // внесения / изъятия
                if (shift.cashInOut) {
                    shift.cashInOut.forEach(item => {
                        if (!(item.datecommit instanceof Date)) {
                            // unixtime -> Date
                            item.datecommit = new Date(item.datecommit)
                        }
                    })
                }
            })
        }

        runInAction(() => {
            this.cash = cashFullInfo
            this.shift = this.cash.shifts && this.cash.shifts.length > 0 ? this.cash.shifts[0] : null
            goTo(`${OPERDAY}${SHIFTS}/${cash.shopNumber}-${cash.number}`)
        })
    }

    leaveCashPage = (): void => {
        this.cash = null
        this.shift = null
    }

    @action
    selectShift = (shift: Shift): void => {
        this.shift = shift
    }

    @action
    reset = (): void => {
        this.date = setDateTime(fromClientToServerTime(new Date()), 0, MILLISECONDS)
        this.operday = undefined
        this.currentOperdayTab = null
        this.problemShifts = []
        this.operdayInfo = undefined
        this.availableTabs = null
        this.operdayTabsKey = null
        this.cash = undefined
        this.shift = undefined
        this.cashierRolesNames = []
        this.cashierDays = null
    }
}

export const CASH_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${OPERDAY}${SHIFTS}/[\\w-]+/?$`),
    onEnter: () => {
        const operdayStore: OperdayStore = getStore(OPERDAY_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        let cashNumber: string = ''
        if (operdayStore.cash) {
            cashNumber = operdayStore.cash.number.toString()
        }

        appBarStore.updateState({
            title: `${t('shop.cashView')} №${cashNumber}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                goTo(`${OPERDAY}${SHIFTS}`)
            }
        })
    },
    onLeave: () => {
        const operdayStore: OperdayStore = getStore(OPERDAY_STORE)
        operdayStore.leaveCashPage()
    }
}

export function dateToString(date: Date): string {
    return moment(date).format('DD-MM-YYYY')
}

export function dateStringToDate(dateString: string): Date {
    const momentDate = moment(dateString, 'DD-MM-YYYY')
    return momentDate.isValid() ? momentDate.toDate() : null
}

export function sortNames(cashierDayA: CashierDayVO, cashierDayB: CashierDayVO): number {
    const nameA = cashierFullName(cashierDayA)
    const nameB = cashierFullName(cashierDayB)
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    return 0
}

export function sortShifts(cashierWorkPeriodA: CashierWorkPeriodVO, cashierWorkPeriodB: CashierWorkPeriodVO) {
    const timeBeginA = cashierWorkPeriodA.timeBegin
    const timeBeginB = cashierWorkPeriodB.timeBegin

    return moment(timeBeginA).isBefore(timeBeginB) ? 1 : -1
}

export function cashierFullName(item: CashierDayVO): string {
    return `
        ${item.lastName ? item.lastName.toLowerCase() : ''}
        ${item.firstName ? item.firstName.toLowerCase() : ''}.
        ${item.middleName ? item.middleName.toLowerCase() : ''}.`
}
