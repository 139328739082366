export interface PassportVO {
    '@class': 'ru.crystals.cards.common.PassportVO'
    passSerie?: string
    passNumber?: string
    delivery?: string
    deliveryDate?: string
}

export const createPassportVO = (initialization: Partial<PassportVO>): PassportVO => {
    if (!initialization) return null
    
    let result: PassportVO = {
        passSerie: initialization.passSerie,
        passNumber: initialization.passNumber,
        delivery: initialization.delivery,
        deliveryDate: initialization.deliveryDate,
        '@class': 'ru.crystals.cards.common.PassportVO',
    }
    
    return result
}
