import { isNil } from 'lodash'

export type Currency = 'RUB' | 'USD' | 'EUR' | 'BYN'

export const RUB: Currency = 'RUB'
export const USD: Currency = 'USD'
export const EUR: Currency = 'EUR'
// Белорусский рубль
export const BYN: Currency = 'BYN'

export const getCurrencySymbol = (currency?: Currency) => {
    switch (currency) {
        case EUR:
            return '€'
        case USD:
            return '$'
        case BYN:
            return 'Br'
        case RUB:
            return '₽'
        default:
            return ''
    }
}

export const getCurrencyText = (currency?: Currency) => {
    switch (currency) {
        case EUR:
            return 'eur'
        case USD:
            return 'usd'
        case BYN:
        case RUB:
            return 'rub'
        default:
            return ''
    }
}

export function formatPrice(value: number): string {
    if (isNil(value)) {
        return null
    }

    return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export function formatPriceToNumber(value: string | number): number {
    let val: number = null

    if (['', null, undefined, NaN].includes(value)) {
        val = 0
    } else if (typeof value === 'string') {
        val = parseFloat(value.replace(',', '.'))
    } else {
        val = value
    }

    return val
}
