import React from 'react'
import { t } from 'i18next'
import Loyalty from '@material-ui/icons/Loyalty'
import {
    LOYALTY,
    SALES_GROUPS,
    ACTION_PRICETAGS_PRINTING,
    FINISHED_ACTIONS_WITH_PRINTED_TAGS,
    ACTIONS, ACTION_EDIT,
    CONDITIONS,
    RESULTS, SALES_GROUPS_EDIT,
    SETTINGS,
    getRouteName,
    COUPONS,
    COUPONS_EDIT,
    ACTIONS_UNLOADED_ON_SHOPS
} from '../core/app-routes'
import { PageMock } from '../../components/page-mock/page-mock'
import { RouteScheme, RETAIL, CENTRUM } from '../utils/route-modules-util'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import { LOY } from '../core/privileges/app-modules'
import { config } from '../config/config'
import {
    LOY_SEARCH_BY_CODE_AND_NAME,
    LOY_SEARCH_BY_PRODUCT,
    LOY_TIMELINE_VIEW,
    SALES_GROUPS_PRIVILEGE,
    EDIT_SALES_GROUPS,
    SETRETAILX_GOODS_ADMIN,
    LOY_EDIT,
} from '../core/privileges/privileges'
import { MenuNotification } from '../components/pages-container/notifications/menu-notification'
import {
    NOT_PRINTED_ACTION_PRICE_TAGS,
    NOT_ACTUAL_ACTION_PRICE_TAGS,
    NOT_LOADED_ACTIONS_ON_SHOPS
} from '../store/notification-store'
import { lazy } from '../utils/code-splitting'

import { LoyaltySettings } from '../pages/loyalty/settings/loyalty-settings'

export const loyaltyRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'loyaltyNavLink',
        label: getRouteName(LOYALTY),
        icon: <Loyalty/>,
    },
    path: LOYALTY,
    privileges: (privilegeManager: PrivilegeManager) => {
        return privilegeManager.haveAnyPrivilegeFromModule(LOY)
    },
    component: PageMock,
    children: [
        {
            navItemProps: {
                id: 'actions',
                label: getRouteName(ACTIONS),
            },
            path: `${LOYALTY}${ACTIONS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "actions-list" */ '../pages/loyalty/actions/actions/actions')),
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    LOY_SEARCH_BY_CODE_AND_NAME,
                    LOY_SEARCH_BY_PRODUCT,
                    LOY_TIMELINE_VIEW
                ])
            },
        },
        {
            path: `${LOYALTY}${ACTIONS}/:tab`,
            component: lazy(() =>
                import(/* webpackChunkName: "actions-list" */ '../pages/loyalty/actions/actions/actions')),
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    LOY_SEARCH_BY_CODE_AND_NAME,
                    LOY_SEARCH_BY_PRODUCT,
                    LOY_TIMELINE_VIEW
                ])
            }
        },
        {
            path: `${LOYALTY}${ACTIONS}${ACTION_EDIT}/:id${CONDITIONS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "action-edit-conditions" */ '../pages/loyalty/actions/action-edit/action-edit-conditions')),
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    LOY_SEARCH_BY_CODE_AND_NAME,
                    LOY_SEARCH_BY_PRODUCT,
                    LOY_TIMELINE_VIEW
                ])
            },
            cannotBeRedirectTarget: true
        },
        {
            path: `${LOYALTY}${ACTIONS}${ACTION_EDIT}/:id${RESULTS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "action-edit-results" */ '../pages/loyalty/actions/action-edit/results/results')),
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    LOY_SEARCH_BY_CODE_AND_NAME,
                    LOY_SEARCH_BY_PRODUCT,
                    LOY_TIMELINE_VIEW
                ])
            },
            cannotBeRedirectTarget: true
        },
        {
            path: `${LOYALTY}${ACTIONS}${ACTION_EDIT}/:id`,
            component: lazy(() =>
                import(/* webpackChunkName: "action-edit" */ '../pages/loyalty/actions/action-edit/action-edit')),
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    LOY_SEARCH_BY_CODE_AND_NAME,
                    LOY_SEARCH_BY_PRODUCT,
                    LOY_TIMELINE_VIEW
                ])
            },
            cannotBeRedirectTarget: true
        },
        {
            navItemProps: {
                id: 'salesGroups',
                label: getRouteName(SALES_GROUPS),
            },
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    SALES_GROUPS_PRIVILEGE,
                    EDIT_SALES_GROUPS,
                ])
            },
            path: `${LOYALTY}${SALES_GROUPS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "sale-groups" */ '../pages/loyalty/sale-groups/sale-groups')),
        },
        {
            path: `${LOYALTY}${SALES_GROUPS}${SALES_GROUPS_EDIT}`,
            component: lazy(() =>
                import(/* webpackChunkName: "sale-group-edit" */ '../pages/loyalty/sale-groups/sale-group-edit')),
        },
        {
            navItemProps: {
                id: 'unprintedPricetags',
                label: getRouteName(ACTION_PRICETAGS_PRINTING),
                endAdornment: <MenuNotification notificationType={NOT_PRINTED_ACTION_PRICE_TAGS}/>,
            },
            path: `${LOYALTY}${ACTION_PRICETAGS_PRINTING}`,
            component: lazy(() =>
                import(/* webpackChunkName: "action-pricetags-printing" */ '../pages/loyalty/action-pricetags-printing/action-pricetags-printing')),
            serverType: RETAIL,
        },
        {
            navItemProps: {
                id: 'finishedActionsWithPrintedTags',
                label: getRouteName(FINISHED_ACTIONS_WITH_PRINTED_TAGS),
                endAdornment: <MenuNotification notificationType={NOT_ACTUAL_ACTION_PRICE_TAGS}/>,
            },
            serverType: RETAIL,
            path: `${LOYALTY}${FINISHED_ACTIONS_WITH_PRINTED_TAGS}`,
            // tslint:disable max-line-length
            component: lazy(() => import(/* webpackChunkName: "finished-actions-price-tags" */ '../pages/loyalty/finished-actions-price-tags/finished-actions-price-tags')),
        },
        {
            navItemProps: {
                id: 'unloadedOnShops',
                label: t('set10.notLoadedToShops'),
                endAdornment: <MenuNotification notificationType={NOT_LOADED_ACTIONS_ON_SHOPS}/>,
            },
            serverType: CENTRUM,
            privileges: [SETRETAILX_GOODS_ADMIN],
            path: `${LOYALTY}${ACTIONS_UNLOADED_ON_SHOPS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "unloaded-on-shops" */ '../pages/loyalty/unloaded-on-shops/unloaded-on-shops')),
        },
        {
            navItemProps: {
                id: 'loyaltyCoupons',
                label: getRouteName(COUPONS),
            },
            path: `${LOYALTY}${COUPONS}`,
            component: lazy(() => import(/* webpackChunkName: "loyalty-coupons" */ '../pages/loyalty/loyalty-coupons/loyalty-coupons')),
        },
        {
            path: `${LOYALTY}${COUPONS}/:tab`,
            component: lazy(() =>
                import(/* webpackChunkName: "loyalty-coupons" */ '../pages/loyalty/loyalty-coupons/loyalty-coupons')),
        },
        {
            path: `${LOYALTY}${COUPONS}${COUPONS_EDIT}/:id`,
            component: lazy(() =>
                import(/* webpackChunkName: "coupon-edit" */ '../pages/loyalty/loyalty-coupons/coupon-edit/coupon-edit')),
        },
        {
            navItemProps: {
                id: 'settings',
                label: t('set10.settings'),
            },
            path: `${LOYALTY}${SETTINGS}`,
            component: LoyaltySettings,
        },
    ]
})
