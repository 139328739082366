export type APP_LOCALE = 'en_US' | 'ru_RU' | 'hy_AM'

export const RU = 'ru'
export const EN = 'en'

export const LOCALE = {
    'en_US': EN,
    'ru_RU': RU,
    'hy_AM': RU, // TODO добавить Армянский
}
