import { ValidationResult, Validator } from '../form-validation'
import { t } from 'i18next'

export const uniqueField = (exclude: Array<string | number>): Validator => {
    return (value: string | number): ValidationResult => {
        const fixedValue = typeof value === 'string' ? value.trim() : value
        if (fixedValue === '' || fixedValue === null) return { valid: true }

        if (exclude.indexOf(fixedValue) > -1) {
            return {
                valid: false,
                error: t('validation.alreadyInUse')
            }
        } else {
            return {
                valid: true
            }
        }
    }
}
