import {observable, action, computed, runInAction} from 'mobx'
import { isNil, cloneDeep } from 'lodash'
import {Check} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check'
import {checkManagerLocal} from '../../../protocol/set10/check-manager-local'
import { OPERDAY, CHECK, CHECKS, BANK_TRANSACTIONS, REFUND } from '../../core/app-routes'
import {discountsProcessingManager} from '../../../protocol/set10/discounts-processing-manager'
import {
    GetCheckDiscountsOut
} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/get-check-discounts-out'
import {CheckPosition} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check-position'
import {PositionDiscount} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/position-discount'
import {goTo, RouteChangeHandler} from '../../utils/router-util'
import {createGetCheckDiscountsIn} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/get-check-discounts-in'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import {t} from 'i18next'
import {config} from '../../config/config'
import {CounterpartyVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/counterparty-vo'
import {reportsProcessorLocal} from '../../../protocol/set10/reports-processor-local'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, CHECK_PAGE_STORE, USER_STORE } from '../stores'
import { withSpinner } from '../with-spinner'
import { fromServerToClientTime, fromClientToServerTime } from '../../utils/app-util'
import { iDiscountsManagerLocal } from '../../../protocol/set10/i-discounts-manager-local'
import { createAdvertisingAction, AdvertisingAction } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'

export type MainTab = 'productPositions' | 'payments' | 'discounts' | 'cards' | 'paymentTransactions'

export const PRODUCT_POSITIONS: MainTab = 'productPositions'
export const PAYMENTS: MainTab = 'payments'
export const DISCOUNTS: MainTab = 'discounts'
export const CARDS: MainTab = 'cards'
export const PAYMENT_TRANSACTIONS: MainTab = 'paymentTransactions'

export const INVOICE: CheckDocumentType = 'INVOICE'
export const PACKING_LIST: CheckDocumentType = 'PACKING_LIST'
export type CheckDocumentType = 'INVOICE' | 'PACKING_LIST'

export interface CheckPositionInfo {
    positionInfo: CheckPosition
    discounts: PositionDiscount[]
}

export interface DiscountInfo {
    order: number
    position: CheckPosition
    discount: PositionDiscount
}

export interface DiscountInfosGroupedByDiscount {
    [guid: number]: DiscountInfo[]
}

export class CheckPageStore {
    @observable
    checkId: number

    @observable
    check: Check

    @observable
    checkPositionFilter: string = ''

    @observable
    discounts: GetCheckDiscountsOut

    @observable
    discountsDetails: AdvertisingAction[] = null

    @observable
    selectedDiscount: number = null

    @observable
    positionDiscountsFilter: string = ''

    @observable
    checkDocumentType: CheckDocumentType = null

    private userStore: UserStore = getStore(USER_STORE)

    @computed
    get filteredCheckPosition(): CheckPosition[] {
        if (!this.check || !this.check.positions) {
            return []
        }

        const filter = this.checkPositionFilter.toLowerCase().trim()

        return this.check.positions.filter(position => {
            return position.name.toLowerCase().includes(filter)
             || position.barcode.toLowerCase().includes(filter)
             || position.item.toLowerCase().includes(filter)
        })
    }

    @computed
    get filteredPositionsWithDiscounts(): CheckPositionInfo[] {
        if (!this.check || !this.discounts) {
            return []
        }

        return this.filteredCheckPosition.map(position => {
            const info: CheckPositionInfo = {
                positionInfo: position,
                discounts: [],
            }

            info.discounts = this.discounts.positionDiscounts.filter(disount => disount.positionOrder === Number(position.positionOrder))

            return info
        })
    }

    @computed
    get positionsGroupedByDiscount(): DiscountInfosGroupedByDiscount {
        return this.discounts?.positionDiscounts.reduce((acc: DiscountInfosGroupedByDiscount, discount: PositionDiscount) => {
            const guid = discount.advertActGuid

            const position: CheckPosition = this.check.positions.find(v => {
                return v.item === discount.goodsCode && v.positionOrder === String(discount.positionOrder)
            })

            if (!acc[guid]) acc[guid] = []

            acc[guid].push({
                order: acc[guid].length + 1,
                discount,
                position
            })

            return acc
        }, {}) || {}
    }

    @action
    openCheck = (checkToFind: Check): void => {
        // TODO SFM-208 ошибки транспорта, исправим временную зону на серверную
        checkToFind = cloneDeep(checkToFind)
        if (checkToFind.date) {
            checkToFind.date = fromServerToClientTime(checkToFind.date)
        }

        this.checkId = checkToFind.id

        withSpinner(async () => {
            this.check = await checkManagerLocal.findCheck(this.userStore.session, checkToFind)

            // TODO SFM-208 ошибки транспорта
            if (this.check) {
                // Для отложенного чека может прийти date === null
                if (!isNil(this.check.date)) {
                    if (!(this.check.date instanceof Date)) {
                        // unixtime -> Date
                        this.check.date = new Date(this.check.date)
                    }
                    this.check.date = fromClientToServerTime(this.check.date)
                }
            }

            this.discounts = await discountsProcessingManager.getCheckDiscounts(this.userStore.session, createGetCheckDiscountsIn({
                check: this.check.number,
                cash: this.check.cashDeskNumber,
                shift: this.check.shiftNumber,
                shop: this.check.store,
                saleTime: fromServerToClientTime(this.check.date)
            }))

            goTo(`${OPERDAY + CHECK}/${checkToFind.id}`)

            this.fetchActionsData()
        })
    }

    @action
    fetchActionsData = async (): Promise<void> => {
        const discountGuids = Object.keys(this.positionsGroupedByDiscount)

        if (!discountGuids.length) {
            this.discountsDetails = []
            return
        }

        const ps = discountGuids.map(guid => {
            return iDiscountsManagerLocal.getAction(
                this.userStore.session,
                createAdvertisingAction({ guid: Number(guid) })
            )
        })

        const actions = await Promise.all(ps)
        this.discountsDetails = actions.filter(Boolean) || []
    }

    @action
    setSelectedDiscount = (discountGuid: number): void => {
        this.selectedDiscount = discountGuid
    }

    printProductsCheck = (): void => {
        this.openDocument('GoodsCheque')
    }

    printProductsCheckAlcochol = (): void => {
        this.openDocument('GoodsChequeFull')
    }

    printCheckNomenclature = (): void => {
        this.openDocument('PurchaseNomenclature')
    }

    printTransactionHistory = (): void => {
        this.openDocument('TransactionHistory')
    }

    printUPD = (): void => {
        this.openDocument('UPD')
    }

    @action
    openPrintPrintInvoiceDialog = (): void => {
        this.checkDocumentType = INVOICE
    }

    @action
    openPrintPackingListDialog = (): void => {
        this.checkDocumentType = PACKING_LIST
    }

    @action
    printDocumentWithCounterparty = (counterparty: CounterpartyVO): void => {
        let action: string

        if (this.checkDocumentType === INVOICE) {
            action = 'GoodsBill'
        }
        else if (this.checkDocumentType === PACKING_LIST) {
            action = 'GoodsInvoice'
        }

        let printPage: Window = window.open('', '_blank')
        printPage.document.write('Loading...')

        reportsProcessorLocal.saveCounterparty(counterparty)
            .then(counterpartyId => {
                // TODO - исправление ошибки транспорта (['java.lang.long', 35])
                let idFix: any = counterpartyId
                if (idFix.length > 1) {
                    idFix = counterpartyId[1]
                }
                this.openDocument(action, printPage, idFix)
            })
    }

    @action
    closeCounterpartyDialog = (): void => {
        this.checkDocumentType = null
    }

    @action
    updateCheckPositionFilter = (filter: string): void => {
        this.checkPositionFilter = filter
    }

    @action
    updatePositionDiscountsFilter = (filter: string): void => {
        this.positionDiscountsFilter = filter
    }

    openDocument = (action: string, printPage?: Window, counterpartyId?: number): void => {
        let url = `${config.reportsAddress}?Action=${action}&PURCHASE_ID=${this.checkId}`

        if (counterpartyId) {
            url += `&CUSTOMER_ID=${counterpartyId}`
        }
        url += '&FILE_TYPE=PDF'

        if (printPage) {
            printPage.location.replace(url)
        } else {
            window.open(url, '_blank')
        }
    }

    @action
    reset = (): void => {
        this.checkId = undefined
        this.check = undefined
        this.checkPositionFilter = ''
        this.discounts = undefined
        this.discountsDetails = null
        this.selectedDiscount = null
        this.positionDiscountsFilter = ''
        this.checkDocumentType = null
    }
}

export const CHECK_PAGE_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${OPERDAY}${CHECK}/[\\w-]+/?$`),
    onEnter: () => {
        const checkPageStore: CheckPageStore = getStore(CHECK_PAGE_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        let checkNumber: string = '?'
        if (checkPageStore.check) {
            checkNumber = checkPageStore.check.number.toString()
        } else {
            // Вызов goTo приводит к изменению routing.location.pathname, что должно триггерить авторан, следящий за роутами.
            // Этого не происходит, если авторан уже в стеке вызова.
            // Поэтому для подобного редиректа можно переносить изменения роута на следующий таск.
            setTimeout(() => goTo(`${OPERDAY}${CHECKS}`), 0)
            return
        }

        let leftIconClick = () => {
            goTo(`${OPERDAY}${CHECKS}`)
        }
        if (appBarStore.prevRoute && appBarStore.prevRoute.indexOf(`${OPERDAY}${BANK_TRANSACTIONS}`) !== -1) {
            leftIconClick = () => {
                goTo(`${OPERDAY}${BANK_TRANSACTIONS}`)
            }
        }

        appBarStore.updateState({
            title: `${t('shop.check')} №${checkNumber}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: leftIconClick
        })
    },
    onLeave: newRoute => {
        if (newRoute.search(new RegExp(`${OPERDAY}${CHECK}/\\d*${REFUND}`)) !== -1) return
        const checkPageStore: CheckPageStore = getStore(CHECK_PAGE_STORE)
        checkPageStore.reset()
    }
}
