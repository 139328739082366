import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { RouteChangeHandler, goTo } from '../../../../utils/router-util'
import { LOYALTY, ACTIONS, ACTIONS_SEARCH, ACTIONS_TIMELINE, ACTION_EDIT, NEW } from '../../../../core/app-routes'
import { AppBarStore, MENU } from '../../../../store/app-bar-store'
import { getStore } from '../../../../store/stores-repository'
import { APP_BAR_STORE, NAVIGATION_MENU_STORE, USER_STORE } from '../../../../store/stores'
import { NavigationMenuStore } from '../../../../store/navigation-menu-store'
import { t } from 'i18next'
import { inject } from 'mobx-react'
import { UserStore } from '../../../../store/user-store'
import {
    LOY_SEARCH_BY_CODE_AND_NAME,
    LOY_SEARCH_BY_PRODUCT,
    LOY_TIMELINE_VIEW
} from '../../../../core/privileges/privileges'
import { isNil } from 'lodash'
import { Tabs, TabInfo } from '@crystalservice/crystals-ui/lib/components/tabs/tabs'
import { ActionsSearch } from '../actions-search/actions-search'
import { ActionsTimeline } from '../actions-timeline/actions-timeline'

const styles = require('./actions.scss')

export type ActionsTab = 'actions-search' | 'actions-timeline'
export const ACTIONS_SEARCH_TAB: ActionsTab = 'actions-search'
export const ACTIONS_TIMELINE_TAB: ActionsTab = 'actions-timeline'
const ALL_TABS = [ACTIONS_SEARCH_TAB, ACTIONS_TIMELINE_TAB]

export interface ActionsURLParams {
    tab?: ActionsTab
}

export interface ActionsProps extends RouteComponentProps<ActionsURLParams> {
    user?: UserStore
}

interface ActionsState {
    filterShown?: boolean
}

@inject(USER_STORE)
export class Actions extends React.Component<ActionsProps, ActionsState> {
    state: ActionsState = {
        filterShown: false,
    }

    get selectedTabIndex(): number {
        let { tab = ACTIONS_SEARCH_TAB } = this.props.match.params
        if (!ALL_TABS.includes(tab)) tab = ACTIONS_SEARCH_TAB

        const indexByTab = {}
        let index = 0
        if (this.isActionsSearchAvailable) {
            indexByTab[ACTIONS_SEARCH_TAB] = index
            index++
        }

        if (this.isActionsTimelineAvailable) {
            indexByTab[ACTIONS_TIMELINE_TAB] = index
        }

        return isNil(indexByTab[tab]) ? 0 : indexByTab[tab]
    }

    get dataLabels(): TabInfo[] {
        let result = []
        if (this.isActionsSearchAvailable) {
            result.push({
                data: ACTIONS_SEARCH,
                label: t('advertisingActions.actionsList')
            })
        }

        if (this.isActionsTimelineAvailable) {
            result.push({
                data: ACTIONS_TIMELINE,
                label:  t('advertisingActions.actionsChart')
            })
        }

        return result
    }

    get pages(): React.ReactNode[] {
        const { filterShown } = this.state
        let result = []
        if (this.isActionsSearchAvailable) {
            result.push(
                <ActionsSearch
                    key="actionsSearch"
                    filterShown={filterShown}
                    onFilterToggle={this.handleToggleFilter}
                />
            )
        }

        if (this.isActionsTimelineAvailable) {
            result.push(<ActionsTimeline key="actionsTimeline" />)
        }

        return result
    }

    get isActionsSearchAvailable(): boolean {
        const { haveAnyPrivilegeFromList } = this.props.user
        return haveAnyPrivilegeFromList([LOY_SEARCH_BY_CODE_AND_NAME, LOY_SEARCH_BY_PRODUCT])
    }

    get isActionsTimelineAvailable(): boolean {
        const { havePrivilege } = this.props.user
        return havePrivilege(LOY_TIMELINE_VIEW)
    }

    onTabChange(tab: TabInfo): void {
        goTo(`${LOYALTY}${ACTIONS}${tab.data}`)
    }

    handleToggleFilter = (): void => {
        this.setState(prevState => ({ filterShown: !prevState.filterShown }))
    }

    render() {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Tabs
                    items={this.dataLabels}
                    defaultSelectedIndex={this.selectedTabIndex}
                    onTabChange={this.onTabChange}
                    tabContentClassName={styles.actionsTabContent}
                    className={styles.actionsTab}
                >
                    {this.pages}
                </Tabs>
            </div>
        )
    }
}

export default withRouter(Actions)

// Для того чтобы сохранить боковое меню и не срабатывал обработчик захода в глубокие страницы
export const ACTIONS_ROUTE_CHANGE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${LOYALTY}${ACTIONS}(${ACTIONS_SEARCH}|${ACTIONS_TIMELINE})$`),
    onEnter: (newRoute: string, prevRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
        navigationMenuStore.synchronizeWithRoute()
        appBarStore.updateState({
            title: t('set10.actionsSearch'),
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    },
}
