import { ModuleItem, NavigationAPIPoints } from '../core/iframe-modules/entities'
import { ChildProtocol } from '@crystalservice/crystals-ui/lib/iframe-modules/frame-protocol/child-protocol'
import { AppBarStore } from './app-bar-store'
import { getStore } from './stores-repository'
import { APP_BAR_STORE } from './stores'
import {
    ChangeRouteCommand,
    CommandToChildType,
    HeaderChangedMessage,
    MessageToParentType,
    RouteChangedMessage,
    SignInCommand
} from '@crystalservice/crystals-ui/lib/iframe-modules/frame-protocol/messages'
import { goTo } from '../utils/router-util'
import { action, observable } from 'mobx'

export class IframeModulesStore {
    @observable
    externalModulesEndpoints: NavigationAPIPoints[] = []

    @observable
    externalModulesNavigation: ModuleItem[] = []

    private childIframeProtocol: ChildProtocol = new ChildProtocol()
    private appBarStore: AppBarStore = getStore<AppBarStore>(APP_BAR_STORE)

    initProtocol = (iFrame: HTMLIFrameElement): void => {
        this.childIframeProtocol.init(iFrame)
        this.childIframeProtocol.on(MessageToParentType.ROUTE_CHANGED, this.handleRouteChanged)
        this.childIframeProtocol.on(MessageToParentType.APP_INITED, this.handleAppInited)
        this.childIframeProtocol.on(MessageToParentType.HEADER_CHANGED, this.handleHeaderChanged)
    }

    @action
    setExternalModulesEndpoints = (externalModulesEndpoints: NavigationAPIPoints[]) => this.externalModulesEndpoints = externalModulesEndpoints

    @action
    setExternalModulesNavigation = (externalModulesNavigation: ModuleItem[]) => this.externalModulesNavigation = externalModulesNavigation

    changeIframeRoute = (route: string): void => {
        const command: ChangeRouteCommand = {
            type: CommandToChildType.CHANGE_ROUTE,
            newRoute: route
        }
        this.childIframeProtocol.send(command)
    }

    reset = (): void => {
        this.childIframeProtocol.off(MessageToParentType.ROUTE_CHANGED, this.handleRouteChanged)
        this.childIframeProtocol.off(MessageToParentType.APP_INITED, this.handleAppInited)
        this.childIframeProtocol.off(MessageToParentType.HEADER_CHANGED, this.handleHeaderChanged)
        this.childIframeProtocol.reset()
    }

    private handleAppInited = (): void => {
        this.signIn()
    }

    private handleRouteChanged = (message: RouteChangedMessage): void => {
        goTo(message.newRoute)
    }

    private handleHeaderChanged = (message: HeaderChangedMessage): void => {
        this.appBarStore.updateState({
            title: message.newHeader,
            leftIcon: this.appBarStore.leftIcon,
            onLeftIconClick: this.appBarStore.onLeftIconClick,
            additionalContent: this.appBarStore.additionalContent,
        })
    }

    private signIn = (): void => {
        const command: SignInCommand = {
            type: CommandToChildType.SIGN_IN,
            login: 'TODO_LOGIN',
            token: 'TODO_TOKEN',
        }
        this.childIframeProtocol.send(command)
    }
}
