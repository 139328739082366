import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRICE_TAGS_PRINTING_STORE, PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { AppStore } from '../../../store/app-store'
import { t } from 'i18next'
import { printersManagerLocal } from '../../../../protocol/set10/printers-manager-local'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { Table } from '@crystalservice/crystals-ui/lib'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { Button } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import { CUSTOM_PRINTING, FROM_PRODUCT_CARD, PRICE_TAGS, PRINTING } from '../../../core/app-routes'
import { config } from '../../../config/config'

const styles = require('./price-tag-templates.scss')

export interface PriceTagTemplatesProps {
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
}

export interface PriceTagTemplatesState {
    templates: string[]
}

@inject(APP_STORE)
@inject(PRODUCT_DETAILS_STORE)
@observer
export default class PriceTagTemplates extends React.Component<PriceTagTemplatesProps, PriceTagTemplatesState> {

    mounted: boolean = false

    state: PriceTagTemplatesState = {
        templates: null
    }

    componentDidMount(): void {
        this.init()
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    init = async (): Promise<void> => {
        const { productCode } = this.props.productDetailsStore

        const templates = await printersManagerLocal.getDefaultTemplateNamesByProduct(productCode)

        if (!this.mounted) return

        this.setState({ templates })
     }

    render() {
        const { isCentrum } = this.props.app
        const { templates } = this.state
        const { productCode } = this.props.productDetailsStore

        if (!templates || !this.mounted) return <PageLoader/>

        return (
            <>
                { !isCentrum &&
                <div className={styles.printButton}>
                    <Button
                        id="priceTagTemplatesPrintButton"
                        onClick={() => {
                            window.open(`${config.baseURL + PRICE_TAGS + CUSTOM_PRINTING + FROM_PRODUCT_CARD}/${productCode}`, '_blank')
                        }}
                        color="primary"
                        startIcon={<PrintIcon />}
                    >
                        { t('common.print') }
                    </Button>
                </div>
                }

                { !!templates.length ?
                    <Table
                        id="priceTagTemplatesList"
                        withHeader
                        keyField="id"
                        items={templates}
                        columns={[
                            {
                                header: t('common.name'),
                                labelFunction: (template: string): string => {
                                    return template
                                },
                            },
                        ]}
                        adaptive={false}
                    /> :
                    <Empty
                        message={t('productDetails.priceTagTemplatesEntrance.emptyMessage')}
                    />
                }
            </>

        )
    }
}
