import { scalesHardwareConfigurationService } from './scales-hardware-configuration-service'
import { iExternalCardsManagerRemote } from './i-external-cards-manager-remote'
import { eSLConfiguration } from './esl-configuration'
import { eSLModule } from './esl-module'
import { eSLHistoryPresenter } from './esl-history-presenter'
import { eSLCoveragePresenter } from './esl-coverage-presenter'
import { salesManagementPropertiesService } from './sales-management-properties-service'
import { directivePrintingSchedulePresenter } from './directive-printing-schedule-presenter'
import { directivePrintingTopologyPresenter } from './directive-printing-topology-presenter'
import { giftomanManagerLocal } from './giftoman-manager-local'
import { pluginsManager } from './plugins-manager'
import { priceTagFormatSender } from './price-tag-format-sender'
import { priceTagFormatPresenterLocal } from './price-tag-format-presenter-local'
import { formatsTopologyPresenterLocal } from './formats-topology-presenter-local'
import { formatsProductListPresenterLocal } from './formats-product-list-presenter-local'
import { formatsSizeBindingPresenterLocal } from './formats-size-binding-presenter-local'
import { segmentArchivationService } from './segment-archivation-service'
import { segmentClientExporterBeanLocal } from './segment-client-exporter-bean-local'
import { segmentOperationFacade } from './segment-operation-facade'
import { uniqueCouponReportsFacade } from './unique-coupon-reports-facade'
import { externalSystemsSettings } from './external-systems-settings'
import { countersService } from './counters-service'
import { segmentService } from './segment-service'
import { iCouponsManagerRemote } from './i-coupons-manager-remote'
import { uniqueCouponsService } from './unique-coupons-service'
import { cardsConfigurationLocal } from './cards-configuration-local'
import { iTransportInfoLocal3 } from './i-transport-info-local3'
import { iInternalCardAccountProsessingRemote } from './i-internal-card-account-prosessing-remote'
import { iCardsManagerRemote } from './i-cards-manager-remote'
import { personnelMotivationReportManagerLocal } from './personnel-motivation-report-manager-local'
import { problematicGoodsReportManagerLocal } from './problematic-goods-report-manager-local'
import { purchasersCountReportManagerLocal } from './purchasers-count-report-manager-local'
import { cashDeskOptimizationReportManagerLocal } from './cash-desk-optimization-report-manager-local'
import { purchasesSumReportManagerLocal } from './purchases-sum-report-manager-local'
import { cashiersStatusesFacadeLocal } from './cashiers-statuses-facade-local'
import { operDayServiceLocal } from './oper-day-service-local'
import { checkManagerLocal } from './check-manager-local'
import { cashDeskManagerLocal } from './cash-desk-manager-local'
import { priceTagFacadeLocal } from './price-tag-facade-local'
import { mnemonicsPropertiesFacadeLocal } from './mnemonics-properties-facade-local'
import { previewFacadeLocal } from './preview-facade-local'
import { printingModuleConfigurator } from './printing-module-configurator'
import { formatsPrintingService } from './formats-printing-service'
import { printLogFacadeLocal } from './print-log-facade-local'
import { printingService } from './printing-service'
import { printingBoxManager } from './printing-box-manager'
import { basketService } from './basket-service'
import { notPrintedShelfLocal } from './not-printed-shelf-local'
import { productTypesManagerLocal } from './product-types-manager-local'
import { scalesInfoManager } from './scales-info-manager'
import { scalesManagerLocal2 } from './scales-manager-local2'
import { scalesManagerLocal1 } from './scales-manager-local1'
import { iTopologyEditorLocal } from './i-topology-editor-local'
import { iTopologySearcherLocal } from './i-topology-searcher-local'
import { iTopologyManagerLocal } from './i-topology-manager-local'
import { paymentTypesManager } from './payment-types-manager'
import { priceCheckerManagerLocal } from './price-checker-manager-local'
import { productManagerFinderLocal } from './product-manager-finder-local'
import { iTransportInfoLocal2 } from './i-transport-info-local2'
import { productsCounterLocal } from './products-counter-local'
import { iProductsManagerLocal } from './i-products-manager-local'
import { iEquipmentManagerLocal } from './i-equipment-manager-local'
import { erpiValidationManagerLocal } from './erpi-validation-manager-local'
import { eRPIntegrationPropertiesManagerLocal } from './erp-integration-properties-manager-local'
import { cashToServerServiceRemote } from './cash-to-server-service-remote'
import { cashMenuScheduleManagerLocal } from './cash-menu-schedule-manager-local'
import { cashManagerLocal } from './cash-manager-local'
import { printersManagerLocal } from './printers-manager-local'
import { externalSystemsManagerRemote } from './external-systems-manager-remote'
import { baseLimitRemote } from './base-limit-remote'
import { scalesProductLoadingsManagerLocal } from './scales-product-loadings-manager-local'
import { cashiersManagerLocal } from './cashiers-manager-local'
import { iUserManagementLocal } from './i-user-management-local'
import { iSessionManagerLocal } from './i-session-manager-local'
import { haspDataManagerRemote } from './hasp-data-manager-remote'
import { currencyManager } from './currency-manager'
import { registerManagerLocal } from './register-manager-local'
import { discountsProcessingCardsPropertiesManagerLocal } from './discounts-processing-cards-properties-manager-local'
import { discountsProcessingManager } from './discounts-processing-manager'
import { iTransportInfoLocal1 } from './i-transport-info-local1'
import { discountsCardsPropertiesManagerLocal } from './discounts-cards-properties-manager-local'
import { actionsPrintIntelService } from './actions-print-intel-service'
import { actionsSelectorFacade } from './actions-selector-facade'
import { iDiscountsManagerLocal } from './i-discounts-manager-local'
import { documentEditorLocal } from './document-editor-local'
import { reportsProcessorLocal } from './reports-processor-local'
import { BaseService } from './base-service'

export const ALL_SERVICES: BaseService[] = [
    reportsProcessorLocal,
    documentEditorLocal,
    iDiscountsManagerLocal,
    actionsSelectorFacade,
    actionsPrintIntelService,
    discountsCardsPropertiesManagerLocal,
    iTransportInfoLocal1,
    discountsProcessingManager,
    discountsProcessingCardsPropertiesManagerLocal,
    registerManagerLocal,
    currencyManager,
    haspDataManagerRemote,
    iSessionManagerLocal,
    iUserManagementLocal,
    cashiersManagerLocal,
    scalesProductLoadingsManagerLocal,
    baseLimitRemote,
    externalSystemsManagerRemote,
    printersManagerLocal,
    cashManagerLocal,
    cashMenuScheduleManagerLocal,
    cashToServerServiceRemote,
    eRPIntegrationPropertiesManagerLocal,
    erpiValidationManagerLocal,
    iEquipmentManagerLocal,
    iProductsManagerLocal,
    productsCounterLocal,
    iTransportInfoLocal2,
    productManagerFinderLocal,
    priceCheckerManagerLocal,
    paymentTypesManager,
    iTopologyManagerLocal,
    iTopologySearcherLocal,
    iTopologyEditorLocal,
    scalesManagerLocal1,
    scalesManagerLocal2,
    scalesInfoManager,
    productTypesManagerLocal,
    notPrintedShelfLocal,
    basketService,
    printingBoxManager,
    printingService,
    printLogFacadeLocal,
    formatsPrintingService,
    printingModuleConfigurator,
    previewFacadeLocal,
    mnemonicsPropertiesFacadeLocal,
    priceTagFacadeLocal,
    cashDeskManagerLocal,
    checkManagerLocal,
    operDayServiceLocal,
    cashiersStatusesFacadeLocal,
    purchasesSumReportManagerLocal,
    cashDeskOptimizationReportManagerLocal,
    purchasersCountReportManagerLocal,
    problematicGoodsReportManagerLocal,
    personnelMotivationReportManagerLocal,
    iCardsManagerRemote,
    iInternalCardAccountProsessingRemote,
    iTransportInfoLocal3,
    cardsConfigurationLocal,
    uniqueCouponsService,
    iCouponsManagerRemote,
    segmentService,
    countersService,
    externalSystemsSettings,
    uniqueCouponReportsFacade,
    segmentOperationFacade,
    segmentClientExporterBeanLocal,
    segmentArchivationService,
    formatsSizeBindingPresenterLocal,
    formatsProductListPresenterLocal,
    formatsTopologyPresenterLocal,
    priceTagFormatPresenterLocal,
    priceTagFormatSender,
    pluginsManager,
    giftomanManagerLocal,
    directivePrintingTopologyPresenter,
    directivePrintingSchedulePresenter,
    salesManagementPropertiesService,
    eSLCoveragePresenter,
    eSLHistoryPresenter,
    eSLModule,
    eSLConfiguration,
    iExternalCardsManagerRemote,
    scalesHardwareConfigurationService,
]
