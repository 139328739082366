import React from 'react'
import { inject, observer } from 'mobx-react'
import { ACTIONS_SEARCH_STORE, APP_STORE, USER_STORE } from '../../../../store/stores'
import { ActionsSearchStore } from '../../../../store/loyalty/actions/actions-search-store'
import { SideBarDrawer } from '@crystalservice/crystals-ui/lib/components/side-bar-drawer/side-bar-drawer'
import IconButton from '@material-ui/core/IconButton'
import { FilterIcon, ArrowStyle } from '@crystalservice/crystals-ui/lib/components/selectors/items-filtered-list/filter-icon'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { t } from 'i18next'
import { ActionsSearchFilters } from './actions-search-filters'
import Paper from '@material-ui/core/Paper'
import { NothingFound } from '../../../../components/stub-with-img/nothing-found'
import Fab from '@material-ui/core/Fab'
import { Add } from '@material-ui/icons'
import { goTo } from '../../../../utils/router-util'
import { LOYALTY, ACTIONS, ACTION_EDIT, NEW } from '../../../../core/app-routes'
import { AdvertisingAction } from '../../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { getUserShortName } from '../../../../../utils/name-util'
import classNames from 'classnames'
import moment from 'moment'
import { Sticky } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/sticky'
import { AppStore } from '../../../../store/app-store'
import { withAdjustColumns } from '@crystalservice/crystals-ui/lib/components/new-table/with-adjust-columns'
import { BaseTable, BaseTableProps } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { toJS } from 'mobx'
import { localStorageManager } from '../../../../utils/local-storage-util'
import { fromClientToServerTime } from '../../../../utils/app-util'
import { AdaptiveIconButton } from '../../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import GoToIcon from '@material-ui/icons/NavigateNext'
import { TOOLTIP_DELAY } from '../../../../../utils/default-timeouts'
import Tooltip from '@material-ui/core/Tooltip'
import {
    GeneralInteractionMethod, FIRST_ORDER, WORKS_ANY, MAXIMUM_DISCOUNT, MAXIMUM_SETS_INDEPENDENT_DISCOUNT
} from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/general-interaction-method'
import { iDiscountsManagerLocal } from '../../../../../protocol/set10/i-discounts-manager-local'
import { UserStore } from '../../../../store/user-store'
import { NotSearched } from '../../../../components/stub-with-img/not-searched'

const styles = require('./actions-search.scss')

interface ActionsSearchProps {
    actionsSearchStore?: ActionsSearchStore
    app?: AppStore
    user?: UserStore
    filterShown?: boolean
    onFilterToggle: () => void
    disableAutoSizer?: boolean
}

interface ActionsSearchState {
    actionsSearchTableColumns: string[],
    generalInteractionMethod: GeneralInteractionMethod
}

@inject(ACTIONS_SEARCH_STORE)
@inject(APP_STORE)
@inject(USER_STORE)
@observer
export class ActionsSearch extends React.Component<ActionsSearchProps, ActionsSearchState> {

    state: ActionsSearchState = {
        actionsSearchTableColumns: null,
        generalInteractionMethod: FIRST_ORDER
    }

    mounted: boolean = false

    componentDidMount(): void {
        const { fetchActions } = this.props.actionsSearchStore
        const { actionsSearchTableColumns } = this.state

        this.mounted = true

        this.fetchLoyaltyProps()

        fetchActions()
        if (!actionsSearchTableColumns) {
            this.setState({ actionsSearchTableColumns: localStorageManager.getActionsSearchTableColumns() })
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    fetchLoyaltyProps = async (): Promise<any> => {
        const { session } = this.props.user
        const loyProps = await iDiscountsManagerLocal.getLoyaltyProperties(session)

        if (!this.mounted) return

        this.setState({
            generalInteractionMethod: loyProps.generalInteractionMethod
        })
    }

    render() {
        const { actions, gotFilters } = this.props.actionsSearchStore
        const { actionsSearchTableColumns, generalInteractionMethod } = this.state
        const { filterShown, onFilterToggle, disableAutoSizer } = this.props
        const gotActions = actions && actions.length > 0

        const showPriority: boolean = (() => {
            switch (generalInteractionMethod) {
                default:
                case FIRST_ORDER:
                case WORKS_ANY:
                    return true
                case MAXIMUM_DISCOUNT:
                case MAXIMUM_SETS_INDEPENDENT_DISCOUNT:
                    return false
            }
        })()

        if (!actions) return null

        return (
            <div className={styles.actionsSearch}>
                <Paper style={{ marginBottom: 8, padding: 16 }}>
                    <ActionSearchFilterInput />
                </Paper>

                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <div
                            className={classNames(styles.tableContainer, {
                                [styles.empty]: !gotActions
                            })}
                        >
                            <IconButton
                                id="toggleFilterButton"
                                className={styles.filterButton}
                                onClick={onFilterToggle}
                            >
                                <FilterIcon
                                    selected={gotFilters}
                                    arrowStyle={filterShown ? ArrowStyle.RIGHT : ArrowStyle.LEFT}
                                />
                            </IconButton>

                            <Paper style={{ height: '100%'}}>
                                {!actions && (
                                    <NotSearched
                                        titleText=""
                                        hintText={t('set10.notSearchedTitle')}
                                    />
                                )}
                                {actions?.length === 0 && (
                                    <NothingFound/>
                                )}
                                {gotActions && (
                                    <ActionsTable
                                        disableAutoSizer={disableAutoSizer}
                                        items={toJS(actions)}
                                        onChangeColumns={newColumns => {
                                            this.setState({ actionsSearchTableColumns: newColumns})
                                            localStorageManager.setActionsSearchTableColumns(newColumns)
                                        }}
                                        defaultColumns={actionsSearchTableColumns}
                                        showPriority={showPriority}
                                    />)
                                }
                            </Paper>
                        </div>

                        <div className={styles.fabContainer}>
                            <div className={styles.ghostDiv}/>
                            <Sticky sticky={true}>
                                <div className={styles.actionPanel}>
                                    <Fab
                                        id="addActionButton"
                                        className={styles.addButton}
                                        color="primary"
                                        onClick={() => {
                                            goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}${NEW}`)
                                        }}
                                    >
                                        <Add
                                            className={styles.icon}
                                            color="primary"
                                        />
                                    </Fab>
                                </div>
                            </Sticky>
                        </div>
                    </div>

                    <SideBarDrawer
                        open={filterShown}
                        onClose={onFilterToggle}
                    >
                        <ActionsSearchFilters/>
                    </SideBarDrawer>
                </div>
            </div>
        )
    }
}

const ActionsTable: React.FC<{
    items: AdvertisingAction[],
    onChangeColumns: (value: string[]) => void,
    defaultColumns: string[]
    disableAutoSizer: boolean,
    showPriority: boolean
}> = props => {

    let WrappedTable: React.ComponentType<BaseTableProps<AdvertisingAction>> =
        withAdjustColumns<AdvertisingAction, BaseTableProps<AdvertisingAction>>({
            WrappedComponent: BaseTable,
            onChangeColumns: (columns: string[]) => {
                props.onChangeColumns(columns)
            },
            defaultColumns: props.defaultColumns
        }
    )

    const keyFunc = (item: AdvertisingAction) => item.id
    const handleEditAction = (id: number): void => {
        goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${id}`)
    }

    return (
        <WrappedTable
            withHeader
            id="actionsTable"
            keyFunction={keyFunc}
            fullHeight
            items={props.items}
            hovarableRows
            onRowClick={({ rowData }) => handleEditAction(rowData.guid)}
            disableAutoSizer={props.disableAutoSizer}
            columns={[
                {
                    header: t('actionsSearch.name'),
                    keyField: t('actionsSearch.name'),
                    renderer: item => {
                        const editorName = item.editor && (getUserShortName(item.editor) || item.editor.login)
                        const codeText = `${t('actionsSearch.code')}: ${item.externalCode || '-'} `
                        const changedByText = editorName && `${t('actionsSearch.changedBy')}: ${editorName}`

                        return (
                            <div className={styles.firstCell}>
                                <div
                                    className={classNames(
                                        styles.colorContainer,
                                        styles[item.displayStyleName]
                                    )}
                                />
                                <div className={styles.nameContainer}>
                                    <Tooltip
                                        title={item.name}
                                        disableFocusListener
                                        enterDelay={TOOLTIP_DELAY}
                                    >
                                        <div className={styles.actionName}>{item.name}</div>
                                    </Tooltip>
                                    <Tooltip
                                        title={codeText}
                                        disableFocusListener
                                        enterDelay={TOOLTIP_DELAY}
                                    >
                                        <span className={styles.additionalText}>
                                            { codeText }
                                        </span>
                                    </Tooltip>
                                    {editorName && (
                                        <Tooltip
                                            title={changedByText}
                                            disableFocusListener
                                            enterDelay={TOOLTIP_DELAY}
                                        >
                                            <span className={styles.additionalText}>
                                                { changedByText }
                                            </span>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    header: t('actionsSearch.priority'),
                    keyField: t('actionsSearch.priority'),
                    renderer: item => {
                        if (!props.showPriority && !item.worksAnytime) {
                            return <span>{ '-' }</span>
                        }

                        return (
                            <Tooltip
                                title={`${t('actionsSearch.priority')}: ${item.priority}`}
                                disableFocusListener
                                enterDelay={TOOLTIP_DELAY}
                            >
                                <span>{ String(item.priority) }</span>
                            </Tooltip>
                        )
                    },
                    columnWidth: 90
                },
                {
                    header: t('actionsSearch.type'),
                    keyField: t('actionsSearch.type'),
                    renderer: item => {
                        return (
                            <div className={styles.noWrap}>
                                {item.worksAnytime ?
                                    t('actionsSearch.worksAnytime') :
                                    t('actionsSearch.notWorksAnytime')
                                }
                            </div>
                        )
                    },
                    columnWidth: 150
                },
                {
                    header: t('actionsSearch.date'),
                    keyField: t('actionsSearch.date'),
                    renderer: item => {
                        const workPeriod = item.workPeriod
                        const startDate = workPeriod && workPeriod.start
                        const endDate = workPeriod && workPeriod.finish

                        if (!startDate) return '-'

                        const stringStartDate = moment(startDate).format('DD.MM.YY')

                        if (!endDate) {
                            return (
                                <div className={styles.noWrap}>
                                    {`${t('actionsSearch.from')} ${stringStartDate}`}
                                </div>
                            )
                        }

                        const stringEndDate = moment(endDate).format('DD.MM.YY')

                        return <div>{`${stringStartDate} - ${stringEndDate}`}</div>
                    },
                    columnWidth: 180
                },
                {
                    header: t('actionsSearch.shops'),
                    keyField: t('actionsSearch.shops'),
                    renderer: item => item.affectedShopsCount,
                    columnWidth: 100
                },
                {
                    header: t('actionsSearch.status'),
                    keyField: t('actionsSearch.status'),
                    renderer: item => getActionStatus(item),
                    columnWidth: 100
                },
                // TODO: Добавить результат, когда реализуют на backend-e (https://crystals.atlassian.net/browse/SR-3643)
                // {
                //     header: t('actionsSearch.result'),
                //     keyField: t('actionsSearch.result'),
                //     renderer: item => getActionStatus(item)
                // },
                {
                    header: '',
                    keyField: 'controlBtn',
                    renderer: item => {
                        return (
                            <AdaptiveIconButton
                                adaptive={false}
                                id={`goToAction${item.guid}`}
                                label={t('productDetails.advertisingActions.goToAction')}
                                onClick={() => handleEditAction(item.guid)}
                            >
                                <GoToIcon />
                            </AdaptiveIconButton>
                        )
                    },
                    columnWidth: 60,
                    staticColumn: true
                },
            ]}
        />
    )
}

const getActionStatus = (action: AdvertisingAction): JSX.Element => {
    const isActive = action.active
    const now = moment(fromClientToServerTime(new Date()))

    const actionStart = action.workPeriod.start && fromClientToServerTime(new Date(action.workPeriod.start))
    const actionFinish = action.workPeriod.finish && fromClientToServerTime(new Date(action.workPeriod.finish))
    const isPast = actionFinish && now.isAfter(moment(actionFinish))
    const isFuture = actionStart && now.isBefore(moment(actionStart))

    let text = ''
    let colorStyle = ''

    if (!isActive) {
        // Черновик
        text = t('actionsSearch.statuses.draft')
        colorStyle = styles.draft
    } else {
        if (isPast) {
            // Завершившаяся акция
            text = t('actionsSearch.statuses.pastAction')
            colorStyle = styles.pastAction
        } else if (isFuture) {
            // Запланированная акция
            text = t('actionsSearch.statuses.futureAction')
            colorStyle = styles.futureAction
        } else {
            // Действующая акция
            text = t('actionsSearch.statuses.currentAction')
            colorStyle = styles.currentAction
        }
    }

    return (
        <div
            className={classNames(
                styles.noWrap,
                styles.status,
                colorStyle
            )}
        >
            {text}
        </div>
    )
}

const ActionSearchFilterInput: React.FC<{ actionsSearchStore?: ActionsSearchStore }> = inject(ACTIONS_SEARCH_STORE)(
    observer(
        props => {
            const { fetchActions, nameFilter, setNameFilter } = props.actionsSearchStore
            return (
                <FilterInput
                    id="filterInput"
                    placeholder={t('actionsSearch.findByName')}
                    value={nameFilter}
                    onValueChange={setNameFilter}
                    onConfirm={fetchActions}
                    onClear={() => {
                        setNameFilter('')
                        fetchActions()
                    }}
                />
            )
        }

    )
)
