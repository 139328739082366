import { ScalesServeSectionIdVO } from './scales-serve-section-id-vo'
import { ScalesServeProductsGroupIdVO } from './scales-serve-products-group-id-vo'
import { ScalesVO } from './scales-vo'
import { PluGenerationMethod } from './plu-generation-method'
import { ScalesTemplatePluVO } from './scales-template-plu-vo'
import { SaleGroupVO } from '../data-structs-module/sale-group-vo'

export interface ScalesTemplateVO {
    '@class': 'ru.crystals.scales.vo.ScalesTemplateVO'
    id?: number
    name?: string
    serveAllProductsList?: boolean
    scalesServeSectionIds?: ScalesServeSectionIdVO[]
    scalesServeProductsGroupIds?: ScalesServeProductsGroupIdVO[]
    deleted?: boolean
    scalesSet?: ScalesVO[]
    guid?: number
    pluGenerationMethod?: PluGenerationMethod
    plues?: ScalesTemplatePluVO[]
    scalesServeSaleGroups?: SaleGroupVO[]
    forbiddenLabelCode?: string
}

export const createScalesTemplateVO = (initialization: Partial<ScalesTemplateVO>): ScalesTemplateVO => {
    if (!initialization) return null
    
    let result: ScalesTemplateVO = {
        id: initialization.id,
        name: initialization.name,
        serveAllProductsList: initialization.serveAllProductsList,
        scalesServeSectionIds: initialization.scalesServeSectionIds,
        scalesServeProductsGroupIds: initialization.scalesServeProductsGroupIds,
        deleted: initialization.deleted,
        scalesSet: initialization.scalesSet,
        guid: initialization.guid,
        pluGenerationMethod: initialization.pluGenerationMethod,
        plues: initialization.plues,
        scalesServeSaleGroups: initialization.scalesServeSaleGroups,
        forbiddenLabelCode: initialization.forbiddenLabelCode,
        '@class': 'ru.crystals.scales.vo.ScalesTemplateVO',
    }
    
    return result
}
