export interface SegmentVO {
    '@class': 'ru.crystals.discounts.SegmentVO'
    guid?: string
    name?: string
    lastChangeDate?: Date
    dateCreate?: Date
    clientsCount?: number
    active?: boolean
    createdBy?: string
    description?: string
    expirationDate?: Date
    archive?: boolean
}

export const createSegmentVO = (initialization: Partial<SegmentVO>): SegmentVO => {
    if (!initialization) return null
    
    let result: SegmentVO = {
        guid: initialization.guid,
        name: initialization.name,
        lastChangeDate: initialization.lastChangeDate,
        dateCreate: initialization.dateCreate,
        clientsCount: initialization.clientsCount,
        active: initialization.active,
        createdBy: initialization.createdBy,
        description: initialization.description,
        expirationDate: initialization.expirationDate,
        archive: initialization.archive,
        '@class': 'ru.crystals.discounts.SegmentVO',
    }
    
    return result
}
