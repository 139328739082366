import { computed, observable, runInAction, toJS, reaction } from 'mobx'
import { cloneDeep, debounce, isEqual } from 'lodash'
import { INPUT_DELAY } from '../default-timeouts'

export interface ValidationRules<T, K extends keyof T> {
    field: K
    rules: Validator[]
}

export interface Validator {
    (value: any): ValidationResult
}

export interface ValidationResult {
    valid: boolean
    error?: string
}

interface ItemsComparator<T> {
    (item1: T, item2: T): boolean
}

export class FormFieldValidator<T, K extends keyof T> {

    @observable
    item: T

    @observable
    field: K

    @observable
    rules: Validator[] = []

    @observable
    results?: ValidationResult[]

    @observable
    touched: boolean = false

    constructor(item: T, field: K, rules: Validator[]) {
        this.item = item
        this.field = field
        this.rules = rules
        this.results = this.rules.map(rule => rule(this.item[this.field]))

        reaction(
            () => this.item[this.field],
            () => {
                this.touched = true
                this.results = this.rules.map(rule => rule(this.item[this.field]))
            }
        )
    }

    @computed
    get valid(): boolean {
        return !this.results.some(result => !result.valid)
    }

    @computed
    get firstError(): string {
        let firstResult = this.results.find(result => !result.valid)
        if (firstResult) return firstResult.error
        return ''
    }
}

export class FormValidation<T> {

    @observable
    fieldValidators: Array<FormFieldValidator<T, keyof T>> = []

    @observable
    creation: boolean = false

    @observable
    modified: boolean = false

    @observable
    inputInProgress: boolean = false
    /** Функция, вычисляющая modified (по умолчанию lodash.isEqual) */
    itemsComparator: ItemsComparator<T> = isEqual

    originalItem: T

    calculateModified: () => void = debounce(() => {
        this.modified = this.creation || !this.itemsComparator(toJS(this.item), this.originalItem)
        this.inputInProgress = false
    }, INPUT_DELAY)

    @observable
    private _item: T

    @computed
    get item(): T {
        return this._item
    }

    set item(value: T) {
        runInAction(() => {
            this._item = value
            this.fieldValidators.forEach(validation => validation.item = this._item)
        })
    }

    constructor(item: T,
                fieldsRules: Array<ValidationRules<T, keyof T>>,
                creation: boolean = false,
                itemsComparator: ItemsComparator<T> = isEqual) {
        this.originalItem = cloneDeep(toJS(item))

        runInAction(() => {
            this.item = item
            this.creation = creation
            this.modified = creation
            this.inputInProgress = false
            this.itemsComparator = itemsComparator

            this.changeValidators(fieldsRules)
        })

        // Сработает при изменении полей this.item
        reaction(
            () => Object.keys(this.item).map(key => this.item[key]),
            () => {
                this.inputInProgress = true
                this.calculateModified()
            }
        )
    }

    changeValidators = (fieldsRules: Array<ValidationRules<T, keyof T>>) => {
        this.fieldValidators = fieldsRules.map(fieldRules => {
            return new FormFieldValidator<T, keyof T>(
                this.item,
                fieldRules.field,
                fieldRules.rules
            )
        })
    }

    @computed
    get valid(): boolean {
        return !this.fieldValidators.some(fieldValidator =>
            fieldValidator.results.some(result => !result.valid))
    }

    @computed
    get touched(): boolean {
        return this.fieldValidators.some(fieldValidator => fieldValidator.touched)
    }

    getValidationFor = (field: keyof T): FormFieldValidator<T, keyof T> => {
        return this.fieldValidators.find(fieldValidator => fieldValidator.field === field)
    }

    getAllValidators = (): Record<keyof T, FormFieldValidator<T, keyof T>> => {
        let keys: Array<keyof T> = this.fieldValidators.map(fieldValidator => fieldValidator.field)
        return keys.reduce((acc, key) => (
            {...acc, [key]: this.getValidationFor(key)}
        ), {}) as Record<keyof T, FormFieldValidator<T, keyof T>>
    }

    getRulesFor = (field: keyof T): Validator[] => {
        let validator = this.fieldValidators.find(fieldValidator => fieldValidator.field === field)
        return validator ? validator.rules : null
    }

    getResultsFor = (field: keyof T): ValidationResult[] => {
        let validator = this.fieldValidators.find(fieldValidator => fieldValidator.field === field)
        return validator ? validator.results : []
    }

    getFirstErrorFor = (field: keyof T): string => {
        let validator = this.fieldValidators.find(fieldValidator => fieldValidator.field === field)
        if (!validator || validator.results.length === 0) return ''

        const allErrors = validator.results.filter(result => result.error)
        return allErrors.length > 0 ? allErrors[0].error : ''
    }
}
