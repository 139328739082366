import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import {ShopDevicesStore, DialogEquipmentModel} from '../../../../store/shops/shop-devices-store'
import { SHOP_DEVICES_STORE } from '../../../../store/stores'
import {Table, GroupedItems} from '@crystalservice/crystals-ui/lib/components/table/table'
import {RIGHT} from '@crystalservice/crystals-ui/lib/components/table/column'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import {AdaptiveIconButton} from '../../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'

interface DevicesTableProps {
    models: DialogEquipmentModel[]
    shopDevicesStore?: ShopDevicesStore
}

@inject(SHOP_DEVICES_STORE)
@observer
export class DevicesTable extends React.Component<DevicesTableProps> {

    getActionElements = (device: DialogEquipmentModel) => {
        const {registerNewModel, removeRegisteredDevice} = this.props.shopDevicesStore
        const {settings, registered, name, equipmentType} = device
        let settingsMenu = null
        let id = name + equipmentType.name

        // У модели есть настройки = отображаем дополнительное меню
        if (registered && settings && settings.length) {
            settingsMenu = (
                <AdaptiveIconButton
                    id={`${id}_EditButton`}
                    label={t('common.edit')}
                    adaptive={false}
                    onClick={() => {
                        // TODO Экран настроек оборудования
                    }}
                >
                    <Edit/>
                </AdaptiveIconButton>
            )
        }

        let registerButton = registered ? (
            <AdaptiveIconButton
                id={`${id}_RemoveButton`}
                label={t('common.remove')}
                adaptive={false}
                onClick={() => removeRegisteredDevice(device)}
            >
                <Delete/>
            </AdaptiveIconButton>
        ) : (
            <AdaptiveIconButton
                id={`${id}_RegisterButton`}
                label={t('shopPage.registerModel')}
                adaptive={false}
                onClick={() => registerNewModel(device)}
            >
                <Add/>
            </AdaptiveIconButton>
        )

        return (
            <div>
                {settingsMenu}
                {registerButton}
            </div>
        )
    }

    render() {
        const {models} = this.props
        const {filterString} = this.props.shopDevicesStore

        return (
            <Table
                id="devicesDialogTable"
                withHeader
                key={filterString ? 1 : 0}
                adaptive={false}
                keyFunction={(item: DialogEquipmentModel) => item.name + item.equipmentType.name}
                items={models}
                sortingFields={['name']}
                groupingField="equipmentType.name"
                groupSortingFields={['equipmentType.localizedName']}
                groupingLabelFunction={(group: GroupedItems) => {
                    let firstItem: DialogEquipmentModel = group.firstItem
                    return firstItem.equipmentType.localizedName
                }}
                simpleGroups
                collapsible
                startCollapsed={!filterString}
                columns={[
                    {
                        padding: '0 0 0 64px',
                        header: t('common.name'),
                        labelField: 'localizedName',
                    },
                    {
                        withControls: true,
                        width: '150px',
                        labelFunction: (device: DialogEquipmentModel): string => {
                            return device.registered ? t('devices.alreadyRegistered') : ''
                        }
                    },
                    {
                        padding: '0 10px',
                        hAlign: RIGHT,
                        showOnRowHover: true,
                        withControls: true,
                        renderer: itemProps => this.getActionElements(itemProps.item)
                    }
                ]}
            />
        )
    }
}
