import React from 'react'
import { ACTION_WORKING_PERIOD_FILTER, FilterType } from '../../../core/filters/filter'
import { BaseSelectFilter } from '../components/base-select-filter'
import { DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { t } from 'i18next'
import { InternalFilterComponentProps } from '../new-filters'

export enum ACTION_WORKING_TYPES {
    EXCLUDE_FINISHED = 'excludeFinished',
    WORKING_ANYTIME = 'workingAnytime',
    WORKING_NOW = 'workingNow',
}

const getOptions = (): DefaultSelectOption[] => [
    {
        label: t('filters.workingPeriodOptions.excludeFinished'),
        value: ACTION_WORKING_TYPES.EXCLUDE_FINISHED
    },
    {
        label: t('filters.workingPeriodOptions.workingAnytime'),
        value: ACTION_WORKING_TYPES.WORKING_ANYTIME
    },
    {
        label: t('filters.workingPeriodOptions.workingNow'),
        value: ACTION_WORKING_TYPES.WORKING_NOW
    }
]

export class ActionWorkingPeriod extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_WORKING_PERIOD_FILTER

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <BaseSelectFilter
                id={id}
                defaultValue={value}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                filterType={ActionWorkingPeriod.filterType}
                options={getOptions()}
                slim={slim}
            />
        )
    }
}
