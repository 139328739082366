import { StatusCountersByShops } from './set-retail10-commons/data-structs-module/status-counters-by-shops'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class ITransportInfoLocal2 extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Products/SET-Products-TransportInfo!ru.crystals.transport.info.ITransportInfoLocal'
    }

    getCountersByShopsAndStatuses = (options?: RequestOptions): Promise<StatusCountersByShops[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCountersByShopsAndStatuses',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getCountersByStatus = (options?: RequestOptions): Promise<{ [key: number]: number }> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCountersByStatus',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const iTransportInfoLocal2: ITransportInfoLocal2 = new ITransportInfoLocal2()
