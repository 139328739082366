import { CashDesk } from './cash-desk'

export type OperDayStatus = 'OPEN' | 'CLOSED' | 'READY_TO_CLOSE' | 'READY_TO_OPEN'

export const OPEN: OperDayStatus = 'OPEN'
export const CLOSED: OperDayStatus = 'CLOSED'
export const READY_TO_CLOSE: OperDayStatus = 'READY_TO_CLOSE'
export const READY_TO_OPEN: OperDayStatus = 'READY_TO_OPEN'


export interface OperDay {
    '@class': 'ru.crystals.operday.gui.datastructs.operday.OperDay'
    initialBalance?: number
    date?: Date
    status?: OperDayStatus
    cashDesks?: CashDesk[]
    totalSum?: string
}

export const createOperDay = (initialization: Partial<OperDay>): OperDay => {
    if (!initialization) return null
    
    let result: OperDay = {
        initialBalance: initialization.initialBalance,
        date: initialization.date,
        status: initialization.status,
        cashDesks: initialization.cashDesks,
        totalSum: initialization.totalSum,
        '@class': 'ru.crystals.operday.gui.datastructs.operday.OperDay',
    }
    
    return result
}
