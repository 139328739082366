import { t } from 'i18next'

export type DayTimeUnit = 'hours' | 'minutes' | 'seconds' | 'milliseconds'

export const HOURS: DayTimeUnit = 'hours'
export const MINUTES: DayTimeUnit = 'minutes'
export const SECONDS: DayTimeUnit = 'seconds'
export const MILLISECONDS: DayTimeUnit = 'milliseconds'

export type DateTypes = 'hour' | 'day' | 'week' | 'month' | 'year'
export const HOUR: DateTypes = 'hour'
export const DAY: DateTypes = 'day'
export const WEEK: DateTypes = 'week'
export const MONTH: DateTypes = 'month'
export const YEAR: DateTypes = 'year'

export interface DateFormatRules {
    format: string,
    placeholder: string
    mask: Array<string | RegExp>
}

export const dateFormatRules: DateFormatRules = {
    format: 'DD.MM.YYYY',
    get placeholder() {
        return t('time.ddmmyyyy')
    },
    mask: [
        /\d/, /\d/, '.',
        /\d/, /\d/, '.',
        /\d/, /\d/, /\d/, /\d/
    ]
}

export function getTimeFormatRules(withHours: boolean = true, withMinutes: boolean = true,
                                   withSeconds: boolean = false): DateFormatRules {
    if ((!withHours && !withMinutes && !withSeconds)
        || (withHours && !withMinutes && withSeconds)) {
        throw new Error('Wrong params have specified for time format rules')
    }

    let format = ''
    if (withHours) format += 'HH'
    if (withMinutes) {
        if (format.length > 0) format += ':'
        format += 'mm'
    }
    if (withSeconds) {
        if (format.length > 0) format += ':'
        format += 'ss'
    }

    let placeholderLocale = ''
    if (withHours && withMinutes && withSeconds) placeholderLocale = 'time.hhmmss'
    if (withHours && withMinutes && !withSeconds) placeholderLocale = 'time.hhmm'
    if (!withHours && withMinutes && withSeconds) placeholderLocale = 'time.mmss'
    if (withHours && !withMinutes && !withSeconds) placeholderLocale = 'time.hh'
    if (!withHours && withMinutes && !withSeconds) placeholderLocale = 'time.mm'
    if (!withHours && !withMinutes && withSeconds) placeholderLocale = 'time.ss'

    let mask = []
    if (withHours) mask = [/[0-2]/, /\d/]
    if (withMinutes) {
        if (mask.length > 0) mask.push(':')
        mask = [...mask, /[0-5]/, /\d/]
    }
    if (withSeconds) {
        if (mask.length > 0) mask.push(':')
        mask = [...mask, /[0-5]/, /\d/]
    }

    return {
        format,
        get placeholder() {
            return t(placeholderLocale)
        },
        mask
    }
}
