import { CashDocumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-document-vo'
import { CashSectionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-section-vo'

export const IDENTATION = '	'

// Парсим из строки в XML дерево
export const parseXMLFromString = (xmlString: string): Document => {
    const parser = new DOMParser()
    const xmlDocument: Document = parser.parseFromString(xmlString, 'application/xml')

    if (gotXMLError(xmlDocument)) {
        return null
    }

    return xmlDocument
}

export const gotXMLError = (document: Document) => {
    if (!document) return true

    let nextElement = document?.firstElementChild

    while (nextElement) {
        if (nextElement.tagName === 'parsererror') {
            return true
        }
        nextElement = nextElement.firstElementChild
    }
    return false
}

export const createDefaultCheckForm = (documentStructure: CashDocumentVO): Document => {
    const newDocument = document.implementation.createDocument(
        null, // namespace to use
        'document', // name of the root element (or for empty document)
        null, // doctype (null for XML)
    )

    const documentTag = newDocument.firstElementChild
    documentTag.setAttribute('xmlns', 'http://crystals.ru/pos/fiscalprinter/templates/parser')
    documentTag.setAttribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance')
    documentTag.setAttribute('xsi:schemaLocation', 'http://crystals.ru/pos/fiscalprinter/templates/parser templates.xsd')

    documentStructure?.sections.forEach(item => {
        if (item.name === 'condition') {
            const newSection: Element = newDocument.createElement('collectionConditions')
            documentTag.appendChild(newSection)
            return
        }
        if (item.name === 'function') {
            const newSection: Element = newDocument.createElement('collectionFunctions')
            documentTag.appendChild(newSection)
            return
        }

        const newSection: Element = newDocument.createElement('section')
        newSection.setAttribute('id', item.name)
        documentTag.appendChild(newSection)
    })

    return newDocument
}

// Сериализуем назад из XML дерева в строку
export const serializeXMLToString = (document: Document): string => {
    const serializer = new XMLSerializer()

    let serializedPart = serializer.serializeToString(document)
    const xmlPart = '<?xml version="1.0" encoding="utf-8"?>\n'

    // Убираем лишние xmlns аттрибуты
    const documentStartIndex = serializedPart.search('<document')
    const afterDocumentPart = serializedPart.substring(documentStartIndex + 9)
    const documentEndIndex = afterDocumentPart.search('>')

    const documentFullPart = serializedPart.substring(0, documentEndIndex)
    const xmlBodyPart = serializedPart.substring(documentEndIndex)

    // В тэге <document> убирать этот тэг не надо
    serializedPart = `${documentFullPart}${xmlBodyPart.replace(/(<.*?) xmlns=".*?"(?=.*?>)/g, '$1')}`

    if (serializedPart.search('\\<\\?xml version=') !== 0) {
        return `${xmlPart}${serializedPart}`
    }

    // Добавляем перенос строки после тэга xml
    return serializedPart.substring(0, documentStartIndex) + '\n' + serializedPart.substring(documentStartIndex)
}

// Получаем секцию из чека по айди
export const getSectionById = (id: string, xmlDocument: Document): Element => {
    if (!xmlDocument) return null

    const sectionsXML = getXMLChildrenArray(xmlDocument.firstElementChild)
    return sectionsXML?.find(section => section.getAttribute('id') === id)
}

// Получаем первый тэг
export const getFirstTag = (tag: string, xmlDocument: Document): Element => {
    if (!xmlDocument) return null

    const sectionsXML = getXMLChildrenArray(xmlDocument.firstElementChild)
    return sectionsXML.find(section => section.tagName === tag)
}

// Получаем секцию из "Структуры документа" по айди
export const getSectionFromDocumentStructure = (id: string, documentStructure: CashDocumentVO): CashSectionVO => {
    return documentStructure?.sections?.find(item => item.name === id)
}

// Получаем всех наследников в виде массива
export const getXMLChildrenArray = (element: Element): Element[] => {
    const children = element?.children
    if (!children) return []

    const result = []
    const length = children.length
    for (let index = 0; index < length; index++) {
        const element = children[index]
        result.push(element)
    }
    return result
}

// Делаем копию документа, чтобы не мутировать прошлый объект напрямую
export const cloneXMLDocument = (xmlDocument: Document): Document => {
    if (!xmlDocument) return null

    const newDocument = xmlDocument.implementation.createDocument(
        null, // namespace to use
        null, // name of the root element (or for empty document)
        null, // doctype (null for XML)
    )
    const newNode = newDocument.importNode(
        xmlDocument.documentElement, // node to import
        true                         // Clone its descendants
    )
    newDocument.appendChild(newNode)

    return newDocument
}

// ------ Условия/Формулы чековых форм -------

export interface OperandElement {
    tagName: string
    value: any
    type?: string
}

export interface LineItem {
    leftElement?: OperandElement
    rightElement?: OperandElement
    operand: string
    operandOnly?: boolean
    type?: string
}

/**
 * Для рендеринга будет отрисовывать строки как массив объектов. вида
 * {
 *   leftElement
 *   rightElement
 *   operand - тип сравнения
 *   type - тип (varLogical/varArithmetic)
 *   path - путь к текущему элементу, если смотреть по индексам потомков
 * }
 * так же может быть строка которая "сравнивает" две соседних строки
 * {
 *   operand - тип сравнения
 *   operandOnly - "сравнение двух соседних строк" - будет отрисован только селект со сравнением
 * }
 */

export const convertOperandToArray = (operandNode: Element, type: string = '', operandTag: string = 'operand'): LineItem[] => {
    if (!operandNode) return []

    // Не операнд (сравнение) а сразу финальный элемент (такое бывает если в линии только один элемент)
    if (operandNode.tagName !== operandTag) {
        return [{
            leftElement: {
                tagName: operandNode.tagName,
                value: operandNode.getAttribute('value'),
                type,
            },
            operand: null,
            rightElement: null,
            type,
        }]
    }

    let conditionsArray: LineItem[] = []
    const operandChildren = getXMLChildrenArray(operandNode)

    // Элементы обернуты в varLogical/varArithmetic поэтому берем первого потомка
    const firstVar = operandChildren[0]
    const secondVar = operandChildren[1]
    const firstElement = firstVar?.firstElementChild
    const secondElement = secondVar?.firstElementChild

    const operandKey = operandNode.getAttribute('key')

    // Первый же операнд является финальным = у нас всего одна строка
    if (isOperandFinalLine(operandNode, operandTag)) {
        // Добавляем одну строку
        conditionsArray.push({
            leftElement: {
                tagName: firstElement.tagName,
                value: firstElement.getAttribute('value'),
                type: firstVar.tagName,
            },
            operand: operandKey,
            rightElement: {
                tagName: secondElement?.tagName,
                value: secondElement?.getAttribute('value'),
                type: secondVar?.tagName,
            },
            type,
        })
    } else {
        // Добавляем операции из первого элемента
        conditionsArray = conditionsArray.concat(convertOperandToArray(firstElement, firstVar?.tagName, operandTag))

        // Добавляем межстрочный операнд
        conditionsArray.push({
            operand: operandKey,
            operandOnly: true,
            type
        })

        // Добавляем линию из второго элемента
        conditionsArray = conditionsArray.concat(convertOperandToArray(secondElement, secondVar?.tagName, operandTag))
    }

    return conditionsArray
}

// Финальная линия = если уже сравниваются конечные элементы
export const isOperandFinalLine = (operandNode: Element, operandTag: string = 'operand'): boolean => {
    if (!operandNode || operandNode.tagName !== operandTag) return null

    const children = getXMLChildrenArray(operandNode)

    const firstElement = children[0]?.firstElementChild
    const secondElement = children[1]?.firstElementChild

    return firstElement?.tagName !== operandTag && secondElement?.tagName !== operandTag
}

// Обратно в XML собираем
export const convertLinesToXML = (lines: LineItem[], operandTag: string = 'operand'): Element => {
    let XMLDocument = document.implementation.createDocument(null, null, null)
    let currentElement: Element
    let appendNextElementTo: Element

    lines.forEach((line: LineItem, index: number) => {
        if (line.operandOnly) {
            // Межстрочное сравнение
            // Прошлую и следующую линию надо объединить операндом

            const operand = XMLDocument.createElement(operandTag)
            operand.setAttribute('key', line.operand)

            const leftVar = XMLDocument.createElement(line.type)
            const rightVar = XMLDocument.createElement(line.type)
            operand.appendChild(leftVar)
            operand.appendChild(rightVar)

            if (!leftVar || !currentElement) {
                return
            }
            // Добавляем прошлый элемент
            leftVar.appendChild(currentElement)

            // Операнд текущий становится верхнеуровневым самым
            currentElement = operand

            // Во второй элемент добавляем следующую строку
            appendNextElementTo = rightVar
        } else {
            let newNode: HTMLElement

            // Если нет правого элемента - то будет без операнда
            if (!line.rightElement) {
                if (!line.leftElement) return currentElement

                newNode = XMLDocument.createElement(line.leftElement.tagName)

                // Добавили настройки
                setAttributesToElement(newNode, line.leftElement)
            } else {
                newNode = XMLDocument.createElement(operandTag)

                newNode.setAttribute('key', line.operand)

                // Создаем левый элемент
                // Создали varLogical/varArithmetic
                const leftVar = XMLDocument.createElement(line.leftElement.type)
                newNode.appendChild(leftVar)

                // Создаем элемент
                const leftElement = XMLDocument.createElement(line.leftElement.tagName)
                leftVar.appendChild(leftElement)

                // Добавили настройки
                setAttributesToElement(leftElement, line.leftElement)

                // С правым элементом тоже самое делаем
                const rightVar = XMLDocument.createElement(line.rightElement.type)
                newNode.appendChild(rightVar)

                // Создаем элемент
                const rightElement = XMLDocument.createElement(line.rightElement.tagName)
                rightVar.appendChild(rightElement)

                // Добавили настройки
                setAttributesToElement(rightElement, line.rightElement)
            }

            if (appendNextElementTo) {
                appendNextElementTo.appendChild(newNode)
                appendNextElementTo = null
            } else {
                currentElement = newNode
            }
        }
    })
    return currentElement
}

const setAttributesToElement = (xmlElement: Element, operandElement: OperandElement): void => {
    if (operandElement.tagName === 'element') {
        xmlElement.setAttribute('align', 'left')
        xmlElement.setAttribute('value', operandElement.value)
        xmlElement.setAttribute('width', '0')
    } else {
        xmlElement.setAttribute('value', operandElement.value)
    }
}

// Переводим XML линии (из секций документа) в массив текстовых элементов

export interface TextLineItem {
    tagName: string
    items: TextLineItem[]
    attributes: {
        [name: string]: string
    }
    element?: Element   // Используется только для функций
}

export const convertXMLLinesToArray = (node: Element): TextLineItem => {
    if (!node) return null

    const children = getXMLChildrenArray(node)
    const items = children.map(item => convertXMLLinesToArray(item))

    // Собираем аттрибуты элемента
    const attributeNames = node.getAttributeNames()
    let attributes = {}
    attributeNames.forEach(name => {
        attributes[name] = node.getAttribute(name)
    })

    return {
        tagName: node.tagName,
        attributes,
        items,
    }
}

export const convertTextLinesToXML = (line: TextLineItem, xmlDocument?: Document): Element => {
    if (!line) return null

    // Берем из настроек только чтобы не создавать каждый раз новый документ
    const XMLDocument = xmlDocument || document.implementation.createDocument(null, null, null)
    const currentElement: Element = XMLDocument.createElement(line.tagName)

    Object.keys(line.attributes).forEach(key => {
        currentElement.setAttribute(key, line.attributes[key])
    })

    line.items.forEach(item => {
        const itemElement = convertTextLinesToXML(item, XMLDocument)
        currentElement.appendChild(itemElement)
    })

    return currentElement
}

// Добавление отступов в xml разметку
export const addIdentation = (input: string, defaultIdent: number = 0): string => {
    let result = ''
    let inputSubstring = input.replace(/\t/g, '').replace(/\n/g, '')
    let identLevel = defaultIdent
    let closingIdent = false

    while (inputSubstring.search('><') !== -1) {
        const endLineIndex = inputSubstring.search('><') + 1
        if (endLineIndex === 0) return

        /**
         * Если есть строка вида '/><' - оставляем отступ тот же
         * Если '></' - закрывающий тэг = уменьшаем отступ
         * Если просто '><' - добавляем один уровень
         */
        if (inputSubstring[endLineIndex + 1] === '\/') {
            identLevel -= 1
            closingIdent = true
        } else if (inputSubstring[endLineIndex - 2] !== '\/') {
            if (!closingIdent) {
                identLevel += 1
            }
            closingIdent = false
        }

        // Добавляем перенос строки
        result += inputSubstring.substring(0, endLineIndex) + '\n'

        // Добавляем пробелы для новой строки
        for (let index = 1; index <= identLevel; index++) {
            result += IDENTATION
        }

        inputSubstring = inputSubstring.substring(endLineIndex)
    }
    return result + inputSubstring
}

// ---- Пример ----

// Пример как можно поредактировать
const exampleEditNode = (xmlDocument: Document) => {
    let newDocument = cloneXMLDocument(xmlDocument)

    // Получим секцию header
    const section = getSectionById('header', newDocument)

    // Получим первую линию
    const firstLine = getXMLChildrenArray(section)[0]

    // Если нам надо проверить, что мы получили - то проверяем по имени тэга
    console.log(firstLine.tagName === 'line')

    // В ней первого чилдрена отредактируем
    const firstElement = getXMLChildrenArray(firstLine)[0]

    // Установив ему значение test
    firstElement.setAttribute('value', 'TEST')

    // Назад сериализуем в строку если надо
    const stringSer = serializeXMLToString(newDocument)

    // Отправляем изменения
    // onChange(stringSer)
}
