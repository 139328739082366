import { t } from 'i18next'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { SHOP_STORE } from '../../../store/stores'
import {ShopStore} from '../../../store/shops/shop-settings/shop-store'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./juristic-person-settings-dialog.scss')

interface JuristicPersonSettingsDialogProps {
    shopStore?: ShopStore
}

@inject(SHOP_STORE)
@observer
export class JuristicPersonSettingsDialog extends React.Component<JuristicPersonSettingsDialogProps> {
    render() {
        const {
            selectedJuristicPerson, isNewJuristicPerson, closeJuristicPersonSettings,
            juristicPersonValidation, updateCurrentJuristicPerson, saveJuristicPerson
        } = this.props.shopStore

        if (!selectedJuristicPerson) return null

        const {
            inn, kpp, juristicAddress, juristicPersonName, telephoneNumber,
            okpo, okdp, directorFullName, simplifiedTaxTreatment
        } = selectedJuristicPerson
        const validators = juristicPersonValidation.getAllValidators()

        return (
            <AdaptiveDialog
                id="fullScreenDialog"
                open
                onClose={closeJuristicPersonSettings}
                className={styles.dialog}
                PaperProps={{className: styles.paper}}
            >
                <DialogTitle>
                    {isNewJuristicPerson ? t('shopPage.addJuristicPerson') : t('shopPage.editJuristicPerson')}
                </DialogTitle>
                <DialogContent className={styles.content}>
                    <TextField
                        id="juristicPersonNameField"
                        autoFocus
                        required
                        label={t('shopPage.juristicPersonNameField')}
                        value={juristicPersonName}
                        errorText={validators.juristicPersonName.touched && validators.juristicPersonName.firstError}
                        onValueChange={juristicPersonName => updateCurrentJuristicPerson({juristicPersonName})}
                    />
                    <TextField
                        id="juristicPersonJuristicAddressField"
                        required
                        label={t('shopPage.juristicPersonJuristicAddressField')}
                        value={juristicAddress}
                        errorText={validators.juristicAddress.touched && validators.juristicAddress.firstError}
                        onValueChange={juristicAddress => updateCurrentJuristicPerson({juristicAddress})}
                    />
                    <TextField
                        id="juristicPersonTelephoneNumberField"
                        label={t('shopPage.juristicPersonTelephoneNumberField')}
                        value={telephoneNumber}
                        errorText={validators.telephoneNumber.touched && validators.telephoneNumber.firstError}
                        onValueChange={telephoneNumber => updateCurrentJuristicPerson({telephoneNumber})}
                    />
                    <TextField
                        id="juristicPersonDirectorFullNameField"
                        label={t('shopPage.juristicPersonDirectorFullNameField')}
                        value={directorFullName}
                        errorText={validators.directorFullName.touched && validators.directorFullName.firstError}
                        onValueChange={directorFullName => updateCurrentJuristicPerson({directorFullName})}
                    />
                    <TextField
                        id="juristicPersonINNField"
                        required
                        label={t('shopPage.juristicPersonINNField')}
                        value={inn}
                        errorText={validators.inn.touched && validators.inn.firstError}
                        onValueChange={inn => updateCurrentJuristicPerson({inn})}
                    />
                    <TextField
                        id="juristicPersonKPPField"
                        label={t('shopPage.juristicPersonKPPField')}
                        value={kpp}
                        errorText={validators.kpp.touched && validators.kpp.firstError}
                        onValueChange={kpp => updateCurrentJuristicPerson({kpp})}
                    />
                    <TextField
                        id="juristicPersonOKPOField"
                        label={t('shopPage.juristicPersonOKPOField')}
                        value={okpo}
                        errorText={validators.okpo.touched && validators.okpo.firstError}
                        onValueChange={okpo => updateCurrentJuristicPerson({okpo})}
                    />
                    <TextField
                        id="juristicPersonOKDPField"
                        label={t('shopPage.juristicPersonOKDPField')}
                        value={okdp}
                        errorText={validators.okdp.touched && validators.okdp.firstError}
                        onValueChange={okdp => updateCurrentJuristicPerson({okdp})}
                    />
                    <Checkbox
                        id="juristicPersonSimplifiedTaxField"
                        checked={simplifiedTaxTreatment}
                        onChange={e => updateCurrentJuristicPerson({simplifiedTaxTreatment: e.target.checked})}
                        color="primary"
                        label={t('shopPage.juristicPersonSimplifiedTaxField')}
                    />
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <Button
                        onClick={closeJuristicPersonSettings}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={() => saveJuristicPerson().then(() => closeJuristicPersonSettings())}
                        color="primary"
                        disabled={juristicPersonValidation.inputInProgress || !juristicPersonValidation.valid || !juristicPersonValidation.modified}
                        id="dialogSaveButton"
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
