import { ValidationRuleType } from './validation-rule-type'

export interface ValidationRule {
    '@class': 'ru.crystals.ERPIntegration.validation.ValidationRule'
    fieldName?: string
    value?: string
    ruleType?: ValidationRuleType
}

export const createValidationRule = (initialization: Partial<ValidationRule>): ValidationRule => {
    if (!initialization) return null
    
    let result: ValidationRule = {
        fieldName: initialization.fieldName,
        value: initialization.value,
        ruleType: initialization.ruleType,
        '@class': 'ru.crystals.ERPIntegration.validation.ValidationRule',
    }
    
    return result
}
