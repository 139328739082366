import axios, { Promise } from 'axios'
import { config } from '../config/config'

export enum DeviceType {
    Pirit1 = 'pirit1',
    Pirit2 = 'pirit2',
}

const serviceAddress = '/SET-Image/fiscal/image'

class ImageService {
    getImageId = (): Promise<string> => {
        const url = `${config.baseServerAddress}${serviceAddress}`
        return axios.post(url, {})
            .then(response => {
                if (response.data.error) {
                    Promise.reject(response.data.error)
                } else {
                    return response.data as string
                }
            })
    }

    uploadImage = (imageId: string, contentType: string, data: any): Promise<void> => {
        const url = `${config.baseServerAddress}${serviceAddress}/${imageId}`
        return axios.post(url, data, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(response => {
                if (response.data.error) {
                    Promise.reject(response.data.error)
                } else {
                    return null
                }
            })
    }

    downloadImage = (imageId: string, device: DeviceType, size: number): Promise<any> => {
        const url = `${config.baseServerAddress}${serviceAddress}/${imageId}/${device}/${size}`
        return axios.get(url, {
            responseType: 'arraybuffer',
        })
            .then(response => {
                if (response.data.error) {
                    Promise.reject(response.data.error)
                } else {
                    return response.data
                }
            })

    }
}

export const imageService: ImageService = new ImageService()
