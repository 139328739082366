import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import { PRODUCT_DETAILS_STORE } from '../../../store/stores'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { ProducerVO, createProducerVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/producer-vo'
import {
    GoodsCompositionVO, createGoodsCompositionVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/goods-composition-vo'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import IconButton from '@material-ui/core/IconButton'
import { Delete } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'

const styles = require('./product-producer-dialog.scss')

interface ProductProducerDialogProps {
    productDetailsStore?: ProductDetailsStore
    index: number
    onSave: (newItem: GoodsCompositionVO, index: number) => void
    onDelete?: (deletedItem: GoodsCompositionVO, index: number) => void
    onClose: () => void
}

interface ProductProducerDialogState {
    id?: number
    producerVo?: ProducerVO
    plugin?: boolean
    composition?: string
    storageConditions?: string
    foodValue?: string
    marking?: string
    active?: boolean
}

const getNewProducer = (): ProductProducerDialogState => {
    return {
        plugin: false,
        active: true,
        id: -1,
        storageConditions: '',
        composition: '',
        foodValue: '',
        producerVo: createProducerVO({
            id: -1,
            name: '',
            producer: '',
            guid: null
        })
    }
}

@inject(PRODUCT_DETAILS_STORE)
@observer
export class ProductProducerDialog extends React.Component<ProductProducerDialogProps, ProductProducerDialogState> {

    constructor(props: ProductProducerDialogProps, context: any) {
        super(props, context)

        const index = props.index
        const currentItem = props.productDetailsStore.productDetails.goodsCompositionList[index]
        const newProducer = index === -1

        if (newProducer) {
            this.state = getNewProducer()
            return
        }

        this.state = newProducer ? getNewProducer() : { ...currentItem }
    }

    render() {
        const { index, onSave, onClose, onDelete } = this.props
        const { composition, foodValue, storageConditions, producerVo } = this.state

        const newProducer = index === -1
        return (
            <Dialog
                id="productGroupsDialog"
                open
                onClose={onClose}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    {
                        newProducer ?
                            t('productDetails.generalInfo.producerAddTitle') :
                            t('productDetails.generalInfo.producerEditTitle')
                    }
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="nameInput"
                        label={t('productDetails.generalInfo.producerName')}
                        value={producerVo.name}
                        onValueChange={value => this.setState(prevState => {
                            return {
                                producerVo: {
                                    ...prevState.producerVo,
                                    name: value
                                }
                            }
                        })}
                    />
                    <TextField
                        id="producerInput"
                        label={t('productDetails.generalInfo.producer')}
                        value={producerVo.producer}
                        onValueChange={value => this.setState(prevState => {
                            return {
                                producerVo: {
                                    ...prevState.producerVo,
                                    producer: value
                                }
                            }
                        })}
                    />
                    <TextField
                        id="compositionInput"
                        label={t('productDetails.generalInfo.producerComposition')}
                        value={composition}
                        onValueChange={value => this.setState({ composition: value })}
                    />
                    <TextField
                        id="storageConditionsInput"
                        label={t('productDetails.generalInfo.producerStorageConditions')}
                        value={storageConditions}
                        onValueChange={value => this.setState({ storageConditions: value })}
                    />
                    <TextField
                        id="foodValueInput"
                        label={t('productDetails.generalInfo.producerFoodValue')}
                        value={foodValue}
                        onValueChange={value => this.setState({ foodValue: value })}
                    />
                </DialogContent>
                <DialogActions>
                    {!newProducer && onDelete && (
                        <div className={styles.deleteContainer}>
                            <Tooltip
                                title={t('common.remove')}
                                enterDelay={TOOLTIP_DELAY}
                            >
                                <IconButton
                                    id="dialogDeleteButton"
                                    onClick={() => {
                                        onDelete(createGoodsCompositionVO(this.state), index)
                                    }
                                }>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                    <Button
                        onClick={onClose}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={() => onSave(createGoodsCompositionVO(this.state), index)}
                        color="primary"
                        id="dialogSaveButton"
                    >
                        {newProducer ? t('common.create') : t('common.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
