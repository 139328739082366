
export type CertificationType = 0 | 1 | 2 | 4 | 8

// Не указана
export const NOT_SPECIFIED: CertificationType = 0

// Обязательная сертификация
export const RST_MANDOTORY: CertificationType = 1

// Добровольная сертификация
export const RST_VOLUNTARY: CertificationType = 2

// Технический регламент
export const STR: CertificationType = 4

// EAC
export const EAC: CertificationType = 8
