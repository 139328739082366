import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { NotFound } from '../../components/not-found/not-found'
import { PageMock } from '../../components/page-mock/page-mock'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import { cardsRoutesScheme } from './cards'
import { cashRoutesScheme } from './cash-module'
import { integrationRoutesScheme } from './integration'
import { loyaltyRoutesScheme } from './loyalty'
import { operdayRoutesScheme } from './operday'
import { otherRoutesScheme } from './other'
import { priceTagsRoutesScheme } from './price-tags'
import { productsRoutesScheme } from './products'
import { reportsRoutesScheme } from './reports'
import { scalesRoutesScheme } from './scales-module'
import { shopRoutesScheme } from './shop'
import { shopsRoutesScheme } from './shops'
import { staffRoutesScheme } from './staff'
import {
    NOT_FOUND,
    ROOT,
    SHOP, SHOPS,
} from '../core/app-routes'
import { RouteScheme } from '../utils/route-modules-util'
import { settingsRoutesScheme } from './settings'

const notFoundRouteScheme: RouteScheme = {
    path: NOT_FOUND,
    component: NotFound
}

/**
 * Массив схем модулей приложения
 */
export const getAppModules = (): RouteScheme => ({
    path: ROOT,
    navItemProps: {
        id: 'appMenu',
        isGroup: true,
    },
    children: [
        productsRoutesScheme(),
        shopsRoutesScheme(),
        shopRoutesScheme(),
        staffRoutesScheme(),
        operdayRoutesScheme(),
        reportsRoutesScheme(),
        priceTagsRoutesScheme(),
        cashRoutesScheme(),
        scalesRoutesScheme(),
        loyaltyRoutesScheme(),
        cardsRoutesScheme(),
        otherRoutesScheme(),
        integrationRoutesScheme(),
        settingsRoutesScheme(),
        notFoundRouteScheme
    ]
})
