import React from 'react'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'

import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'

import { LOYALTY_SETTINGS_STORE } from '../../../store/stores'
import { LoyaltySettingsStore } from '../../../store/loyalty/loyalty-settings-store'

import { LoyaltySettingsGeneral } from './loyalty-settings-general/loyalty-settings-general'
import { LoyaltySettingsActionTriggers } from './loyalty-settings-action-triggers'
import { LoyaltySettingsCoupons } from './loyalty-settings-coupons'

export interface RadioOptions<T> {
    label: string
    value: T
}

export interface LoyaltySettingsProps {
    loyaltySettingsStore?: LoyaltySettingsStore
}

@inject(LOYALTY_SETTINGS_STORE)
@observer
export class LoyaltySettings extends React.Component<LoyaltySettingsProps> {

    componentDidMount() {
        this.props.loyaltySettingsStore.fetchLoyaltySettings()
    }

    render() {
        const { settings } = this.props.loyaltySettingsStore

        if (!settings) {
            return <Empty />
        }

        return (
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <LoyaltySettingsGeneral />
                </Grid>

                <Grid item xs={12}>
                    <LoyaltySettingsActionTriggers />
                </Grid>

                <Grid item xs={12}>
                    <LoyaltySettingsCoupons />
                </Grid>
            </Grid>
        )
    }
}
