import { createCardTypeVO } from './card-type-vo'
import { createBaseVO } from './base-vo'
import { CardTypeVO } from './card-type-vo'

export interface ExternalCardsVO extends CardTypeVO {
    '@class': 'ru.crystals.cards.externalcards.ExternalCardsVO'
    percentageDiscount?: number
    processing?: string
    withoutDiscount?: boolean
    useProcessing?: boolean
}

export const createExternalCardsVO = (initialization: Partial<ExternalCardsVO>): ExternalCardsVO => {
    if (!initialization) return null
    
    let result: ExternalCardsVO = {
        ...createCardTypeVO(initialization),        ...createBaseVO(initialization),
        percentageDiscount: initialization.percentageDiscount,
        processing: initialization.processing,
        withoutDiscount: initialization.withoutDiscount,
        useProcessing: initialization.useProcessing,
        '@class': 'ru.crystals.cards.externalcards.ExternalCardsVO',
    }
    
    return result
}
