import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import classNames from 'classnames'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { ItemsSelector } from '../../../../components/selectors/items-selector/items-selector'
import { TITLE } from '../../../../components/status-paper/status-paper'
import { Tabs } from '../../../../components/tabs/tabs'
import { CLOSED_DOWN } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-status'
import { CashVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-vo'
import { EquipmentModelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { CASHDOC, PRICETAG, PrintDocType } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/print-doc-type'
import { UserVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { getUserFullName } from '../../../../utils/name-util'
import {SHOP_PRINTERS_STORE, SHOP_CASHES_STORE, APP_STORE} from '../../../store/stores'
import {ShopPrintersStore} from '../../../store/shops/shop-settings/shop-printers-store'
import {DeviceSettingsType} from '../../../core/values'
import {ShopCashesStore} from '../../../store/shops/shop-settings/shop-cashes-store'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { AppStore } from '../../../store/app-store'

const styles = require('./printer-settings-dialog.scss')

interface PrinterSettingsDialogProps {
    shopPrintersStore?: ShopPrintersStore
    shopCashesStore?: ShopCashesStore
    app?: AppStore
}

@inject(APP_STORE)
@inject(SHOP_PRINTERS_STORE)
@inject(SHOP_CASHES_STORE)
@observer
export class PrinterSettingsDialog extends React.Component<PrinterSettingsDialogProps> {

    handleModelChange = (selectedModel: DefaultSelectOption) => {
        const {updatePrinter, printerModels} = this.props.shopPrintersStore
        const model = printerModels.find(p => p.name === selectedModel.value)
        if (model) {
            updatePrinter({equipmentModel: toJS(model)})
        }
    }

    handleSizeInput = (field: string, value: any) => {
        let numberValue = Number(value)
        if (!isNaN(numberValue) && numberValue >= 0) {
            this.props.shopPrintersStore.updatePrinter({[field]: numberValue})
        }
    }

    handleSaveButton = () => {
        const {
            saveNewPrinter, savePrinter, printerSettings, printerSettingsType, closePrinterSettings
        } = this.props.shopPrintersStore
        switch (printerSettingsType) {
            case DeviceSettingsType.NEW:
                saveNewPrinter(toJS(printerSettings))
                break
            case DeviceSettingsType.EDIT:
                savePrinter(toJS(printerSettings))
                break
            default:
                break
        }
        closePrinterSettings()
    }

    handleNewModel = () => {
        const {closePrinterSettings, showPrinterDevicesDialog} = this.props.shopPrintersStore
        closePrinterSettings()
        showPrinterDevicesDialog()
    }

    getPrinterSizeInput = (field: string, maxLength: number, position: 'left' | 'center' | 'right') => {
        const {printerSettings} = this.props.shopPrintersStore
        return (
            <TextField
                id={`${field}DialogInput`}
                className={classNames(styles.sizeInput, styles[position])}
                value={printerSettings[field]}
                maxLength={maxLength}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {t('shopPage.mm')}
                        </InputAdornment>
                    )
                }}
                onChange={event => this.handleSizeInput(field, event.target.value)}
                label={t(`shopPage.${field}`)}
            />
        )
    }

    render() {
        const {
            printerSettings, closePrinterSettings, printerSettingsType, priceTags, updatePrinterType, printerSettingsCashData,
            printerValidation, printerModels, updatePrinter, printersAddresses, users, printerSettingsUserData,
        } = this.props.shopPrintersStore
        const {cashes} = this.props.shopCashesStore
        const editMode = printerSettingsType === DeviceSettingsType.EDIT
        const { isCentrum } = this.props.app

        if (!printerSettings || !printerValidation) return null

        const nameValidaton = printerValidation.getValidationFor('name')

        let saveButtonAvailable = false
        switch (printerSettingsType) {
            case DeviceSettingsType.EDIT:
                saveButtonAvailable = printerValidation.modified && printerValidation.valid
                break
            case DeviceSettingsType.NEW:
                saveButtonAvailable = printerValidation.valid
                break
            default:
                break
        }

        let dialogContent = null

        // В режиме добавления нового принтера диалог значительно проще
        if (!editMode) {
            dialogContent = (
                <DialogContent className={styles.content}>
                    <TextField
                        id="dialogNameInput"
                        className={styles.dialogInputs}
                        autoFocus
                        value={printerSettings.name}
                        onValueChange={name => updatePrinter({name})}
                        InputProps={{
                            inputProps: {
                                maxLength: 60,
                            }
                        }}
                        errorText={nameValidaton.touched && nameValidaton.firstError}
                        label={t('shopPage.printerName')}
                    />
                    {
                        printerModels.length > 0 ? (
                            <SelectInput
                                id="dialogModelInput"
                                onSelect={this.handleModelChange}
                                label={t('shopPage.deviceModel')}
                                className={styles.dialogInputs}
                                options={printerModels.map((model: EquipmentModelVO) => {
                                    return { label: model.localizedName, value: model.name }
                                })}
                                value={printerSettings.equipmentModel.name}
                            />
                        ) : (
                            <TextField
                                id="dialogNoModelInput"
                                fullWidth
                                className={styles.dialogInputs}
                                label={t('shopPage.deviceModel')}
                                value={t('shopPage.noModels')}

                                InputProps={{
                                    error: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                enterDelay={TOOLTIP_DELAY}
                                                title={t('shopPage.addNewModel')}
                                            >
                                                <IconButton
                                                  onClick={this.handleNewModel}
                                                >
                                                  <AddCircleOutline />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    }
                </DialogContent>
            )
        } else {
            // Режим редактирования текущего принтера (зависит от типа принтера (документы либо прайсчеки))
            const priceTagMode = printerSettings.printType === PRICETAG

            let tabItems = [
                t('shopPage.printerSettings'),
                priceTagMode ? t('shopPage.priceTagTemplates') : t('set10.cashes'),
                t('set10.users')
            ]

            let tab1Content = (
                <div className={styles.settings}>
                    <TextField
                        id="dialogNameInput"
                        className={styles.dialogInputs}
                        value={printerSettings.name}
                        autoFocus
                        onValueChange={name => updatePrinter({name})}
                        errorText={nameValidaton.touched && nameValidaton.firstError}
                        label={t('shopPage.printerName')}
                    />
                    <SelectInput
                        id="dialogAddressInput"
                        onSelect={selectedOption => updatePrinter({ address: selectedOption.value }) }
                        label={t('shopPage.deviceAddressing')}
                        className={styles.dialogInputs}
                        options={printersAddresses.map((address: string) => {
                            return { label: address, value: address }
                        })}
                        value={printerSettings.address || ''}
                    />
                    <SelectInput<DefaultSelectOption<PrintDocType>>
                        id="dialogPrintTypeInput"
                        onSelect={selectedOption => updatePrinterType(selectedOption.value)}
                        label={t('shopPage.printerType')}
                        className={styles.dialogInputs}
                        options={[
                            !isCentrum ? {label: t('shopPage.printerPriceTag'), value: PRICETAG} : null,
                            {label: t('shopPage.printerCashDoc'), value: CASHDOC}
                        ].filter(Boolean)}
                        value={printerSettings.printType}
                    />
                    <SelectInput
                        id="dialogModelInput"
                        onSelect={this.handleModelChange}
                        label={t('shopPage.deviceModel')}
                        className={styles.dialogInputs}
                        options={printerModels.map((model: EquipmentModelVO) => {
                            return {label: model.localizedName, value: model.name}
                        })}
                        value={printerSettings.equipmentModel.name}
                    />

                    <div className={styles.sizeLabel}>{t('shopPage.printerSize')}</div>
                    {this.getPrinterSizeInput('height', 4, 'left')}
                    {this.getPrinterSizeInput('width', 4, 'right')}
                    <div className={styles.offsetLabel}>{t('shopPage.printerOffset')}</div>
                    {this.getPrinterSizeInput('topMargin', 3, 'center')}
                    <br/>
                    {this.getPrinterSizeInput('leftMargin', 3, 'left')}
                    {this.getPrinterSizeInput('rightMargin', 3, 'right')}
                    <br/>
                    {this.getPrinterSizeInput('bottomMargin', 3, 'center')}
                </div>
            )

            let tab2Content = null
            if (priceTagMode) {
                tab2Content = (
                    <ItemsSelector
                        items={toJS(priceTags)}
                        defaultSelection={printerSettings.priceTags}
                        keyField="id"
                        labelField="name"
                        onSelectionChange={priceTags => updatePrinter({priceTags})}
                        title={t('shopPage.addedTemplates')}
                        entityName={t('shopPage.priceTagTemplates')}
                        getStatusPaperMode={items => TITLE}
                    />
                )
            } else {
                let otherPrinterNamesByCashId = printerSettingsCashData.printerNameByCashId
                tab2Content = (
                    <ItemsSelector
                        items={toJS(cashes)}
                        defaultSelection={toJS(printerSettings.cashes)}
                        disabledItems={toJS(printerSettingsCashData.allOtherCashes)}
                        keyFunction={(cash: CashVO) => cash.id.toString()}
                        labelFunction={(cash: CashVO) => {
                            let label = `${cash.number} ${cash.template.name}`

                            if (cash.status === CLOSED_DOWN) {
                                label += ` (${t('shopPage.cashClosed')})`
                            }

                            // Если привязано к другому принтеру - дописываем его имя
                            if (otherPrinterNamesByCashId[cash.id]) {
                                label += ` (${otherPrinterNamesByCashId[cash.id]})`
                            }

                            return label
                        }}
                        onSelectionChange={cashes => updatePrinter({cashes})}
                        title={t('shopPage.bindedCashes')}
                        entityName={t('shopPage.cashesStep')}
                        getStatusPaperMode={items => TITLE}
                    />
                )
            }

            let otherPrinterNames = printerSettingsUserData.printerNameByUserId
            let tab3Content = (
                <ItemsSelector
                    key="tab3"
                    items={toJS(users)}
                    defaultSelection={toJS(printerSettings.users)}
                    disabledItems={toJS(printerSettingsUserData.allOtherUsers)}
                    keyFunction={(user: UserVO) => user.id.toString()}
                    labelFunction={(user: UserVO) => {
                        let name: string = getUserFullName(user)

                        if (name.length > 0) name += ' '
                        name += `(${user.login})`

                        // Если привязано к другому принтеру - дописываем его имя
                        if (otherPrinterNames[user.id]) {
                            name += ` (${otherPrinterNames[user.id]})`
                        }

                        return name
                    }}
                    onSelectionChange={users => updatePrinter({users})}
                    title={t('shopPage.printerUsers')}
                    entityName={t('staff.users')}
                    getStatusPaperMode={items => TITLE}
                />
            )

            dialogContent = (
                <DialogContent className={classNames(styles.content, styles.fullHeight)}>
                    <Tabs
                        items={tabItems}
                        breakpoint="md"
                    >
                        {tab1Content}
                        {tab2Content}
                        {tab3Content}
                    </Tabs>
                </DialogContent>
            )
        }

        return (
            <AdaptiveDialog
                id="fullScreenDialog"
                open={Boolean(printerSettings)}
                onClose={editMode && saveButtonAvailable ? null : closePrinterSettings}
                className={editMode ? styles.editDialog : styles.dialog}
            >
                {!editMode && (
                    <DialogTitle>
                        {t('shopPage.printerTitleNew')}
                    </DialogTitle>
                )}
                {dialogContent}
                <DialogActions className={styles.actions}>
                    <Button
                        onClick={closePrinterSettings}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={this.handleSaveButton}
                        color="primary"
                        disabled={!saveButtonAvailable}
                        id="dialogSaveButton"
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
