import { observable, action, runInAction, computed, toJS } from 'mobx'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE } from '../stores'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { cloneDeep, isEqual, isArray } from 'lodash'
import { t } from 'i18next'
import { SegmentVO, createSegmentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/segment-vo'
import { segmentService } from '../../../protocol/set10/segment-service'
import { createSegmentsFilter, SegmentsFilter, SegmentStatus } from '../../../protocol/set10/set-retail10-commons/data-structs-module/segments-filter'
import { segmentOperationFacade } from '../../../protocol/set10/segment-operation-facade'
import { SideBarFiltersState, getSearchArguments } from '../../components/filters/side-bar-filters'
import { FilterProps } from '../../components/filters/new-filters'
import {
    SEGMENT_CODE, BASE_DATE_RANGE_FILTER_DELIMITER, SEGMENT_ACTION_NAME, SEGMENT_ACTION_CODE,
    SEGMENT_CREATED, SEGMENT_CHANGED, SEGMENT_STATUS, SEGMENT_LOGIN
} from '../../core/filters/filter'
import { fromClientToServerTime, fromServerToClientTime } from '../../utils/app-util'
import { getDateFromString } from '../../../utils/date-util'
import { createDatePeriod } from '../../../protocol/set10/set-retail10-commons/data-structs-module/date-period'
import { goTo } from '../../utils/router-util'
import { CARDS, SEGMENTS_SEARCH, SEGMENTS_EDIT } from '../../core/app-routes'
import { externalSystemsManagerRemote } from '../../../protocol/set10/external-systems-manager-remote'
import { segmentArchivationService } from '../../../protocol/set10/segment-archivation-service'
import { config } from '../../config/config'
import { segmentClientExporterBeanLocal } from '../../../protocol/set10/segment-client-exporter-bean-local'
import { COMPLETE, ERROR, READ_FILE } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/segment-operation-progress-status'
import { ADD, REMOVE, ARCHIVE } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/segment-operation-type-enum'
import {
    SegmentOperationProgressVO, createSegmentOperationProgressVO
} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/segment-operation-progress-vo'
import 'whatwg-fetch'

const SEGMENTATION_SERVICES = 'segmentation-services'
const PROCESSING_URL = 'processingUrl'

const OPERATION_TIMER = 1000

export class SegmentsStore {

    @observable
    segments: SegmentVO[]

    @observable
    nameFilter: string = ''

    @observable
    filtersState: SideBarFiltersState

    @observable
    editedSegment: SegmentVO

    @observable
    segmentsProcessingEnabled: boolean = false

    @observable
    operationId: number = null

    @observable
    segmentOperationProgress: SegmentOperationProgressVO = null

    @observable
    exportLink: string = null

    private timerId: number = null

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    get session(): string {
        return this.userStore.session
    }

    @computed
    get segmentsFilter(): SegmentsFilter {
        let segmentsFilter = createSegmentsFilter({})

        if (this.nameFilter) {
            segmentsFilter.nameLike = `%${this.nameFilter}%`
        }

        const shownFilters = this.filtersState ? this.filtersState.shownFilters : {}

        Object.keys(shownFilters).forEach(filterType => {
            const filterData: FilterProps = shownFilters[filterType]
            const value = filterData.value
            if (!value) return

            switch (filterType) {
                case SEGMENT_CODE:
                    segmentsFilter.guidLike = `%${value}%`
                    break
                case SEGMENT_ACTION_NAME:
                    segmentsFilter.actionNameLike = `%${value}%`
                    break
                case SEGMENT_ACTION_CODE:
                    segmentsFilter.actionGuidLike = `%${value}%`
                    break
                case SEGMENT_CREATED:
                    const createdDate: string[] = value ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER) : [null, null]
                    const createdStart = fromClientToServerTime(getDateFromString(createdDate[0]))
                    const createdEnd = fromClientToServerTime(getDateFromString(createdDate[1]))

                    segmentsFilter.createDate = createDatePeriod({
                        start: createdStart,
                        finish: createdEnd
                    })
                    break
                case SEGMENT_CHANGED:
                    const changedDate: string[] = value ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER) : [null, null]
                    const changedStart = fromClientToServerTime(getDateFromString(changedDate[0]))
                    const changedEnd = fromClientToServerTime(getDateFromString(changedDate[1]))

                    segmentsFilter.lastChangeDate = createDatePeriod({
                        start: changedStart,
                        finish: changedEnd
                    })
                    break
                case SEGMENT_STATUS:
                    segmentsFilter.segmentStatus = value as SegmentStatus
                    break
                case SEGMENT_LOGIN:
                    segmentsFilter.createdByLike = `%${value}%`
                    break
            }
        })

        return segmentsFilter
    }

    @computed
    get gotFilters(): boolean {
        return this.filtersState && getSearchArguments(this.filtersState.shownFilters).length > 0
    }

    fetchSegments = async (): Promise<void> => {
        // Без фильтров не ищем
        if (!this.gotFilters && !this.nameFilter) return

        let totalPages = 1
        let currentPage = 0
        let foundItems = []

        while (totalPages !== currentPage) {
            currentPage += 1
            const partialList = await withSpinner(
                segmentService.getSegmentsByFilter(
                    this.segmentsFilter,
                    currentPage,
                    100
                )
            )

            foundItems.push(...partialList.items)
            totalPages = partialList.numberOfPages
        }

        runInAction(() => {
            this.segments = foundItems
        })
    }

    getRegisteredServiceProviders = async (): Promise<void> => {
        const result = await externalSystemsManagerRemote.getRegisteredServiceProviders(
            this.userStore.session,
            SEGMENTATION_SERVICES,
            this.appStore.locale,
            {
                useCache: true
            }
        )

        this.segmentsProcessingEnabled = Boolean(result?.[0]?.settings?.some(
            setting => setting.name === PROCESSING_URL && setting.value
        ))
    }

    addNewSegment = async (guid: string, name: string, description: string): Promise<void> => {
        const newSegment = createSegmentVO({
            guid,
            name,
            description
        })

        const segment = await withSpinner(segmentOperationFacade.createSegment(newSegment))
        this.openSegment(segment)
    }

    @action
    openSegment = async (item: SegmentVO): Promise<void> => {
        this.editedSegment = cloneDeep(item)
        goTo(`${CARDS}${SEGMENTS_SEARCH}${SEGMENTS_EDIT}`)
    }

    @action
    updateSegmentExpirationDate = async (date: Date): Promise<void> => {
        const result = await withSpinner(segmentOperationFacade.updateSegment({
            ...toJS(this.editedSegment),
            expirationDate: fromServerToClientTime(date)
        }))

        runInAction(() => {
            this.editedSegment = result
        })
    }

    loadFile = async (file: File): Promise<string> => {
        let currentTime = this.appStore.now().getTime()
        let lastFileName = `${currentTime}.txt`

        let formData = new FormData()
        formData.append('Filename', lastFileName)
        formData.append('Filedata', file)
        formData.append('Upload', 'Submit Query')

        const response = await window.fetch(`${config.uploadAddress}?save%2Das=${currentTime}%2Etxt`, {
            method: 'POST',
            body: formData,
            mode: 'no-cors'
        })
        return lastFileName
    }

    addClients = async (file: File): Promise<void> => {
        this.closeSegmentStatus()
        const guid = this.editedSegment.guid

        // Включаем первое оповещение, на время загрузки файла
        this.segmentOperationProgress = createSegmentOperationProgressVO({
            operationType: ADD,
            status: READ_FILE
        })

        const lastFileName = await this.loadFile(file)
        const result: number | number[] = await segmentOperationFacade.addClients(guid, lastFileName) as unknown as number[]

        runInAction(() => {
            // Приходит ['java.lang.Long', 1234]
            this.operationId = isArray(result) ? result[1] : result
        })
        this.getOperationProgress()
    }

    @action
    removeClients = async (file: File): Promise<void> => {
        this.closeSegmentStatus()
        const guid = this.editedSegment.guid

        // Включаем первое оповещение, на время загрузки файла
        this.segmentOperationProgress = createSegmentOperationProgressVO({
            operationType: REMOVE,
            status: READ_FILE
        })

        const lastFileName = await this.loadFile(file)
        const result: number | number[] = await segmentOperationFacade.removeClients(guid, lastFileName) as unknown as number[]

        runInAction(() => {
            // Приходит ['java.lang.Long', 1234]
            this.operationId = isArray(result) ? result[1] : result
        })
        this.getOperationProgress()
    }

    exportClients = async (): Promise<void> => {
        this.closeSegmentStatus()
        const result = await withSpinner(segmentClientExporterBeanLocal.exportSegment(this.editedSegment.guid))

        runInAction(() => {
            this.segmentOperationProgress = null
            if (result) {
                this.exportLink = result
            }
        })
    }

    @action
    openExportedClients = (): void => {
        window.open(this.exportLink)

        const parts = this.exportLink.split('/')
        const fileName = parts[parts.length - 1]

        this.exportLink = null

        // Удаляем файл через секунду после начала загрузки (он нормально скачается даже если дольше секунды качается)
        setTimeout(() => {
            segmentClientExporterBeanLocal.deleteExportedFile(fileName)
        }, 1000)
    }

    archiveSegment = async (): Promise<void> => {
        this.closeSegmentStatus()
        const result: number | number[] = await withSpinner(
            segmentArchivationService.archiveSegment(this.editedSegment.guid)
        ) as unknown as number[]

        const operationId = isArray(result) ? result[1] : result

        runInAction(() => {
            if (operationId === -1) {
                this.editedSegment.archive = true
                this.segmentOperationProgress = createSegmentOperationProgressVO({
                    operationType: ARCHIVE,
                    status: COMPLETE
                })
            }
            else {
                runInAction(() => {
                    this.operationId = operationId
                })
                this.restartPolling()
            }
        })
    }

    @action
    closeSegmentStatus = (): void => {
        this.segmentOperationProgress = null
        this.exportLink = null
    }

    stopPolling = (): void => {
        clearInterval(this.timerId)
    }

    restartPolling = (): void => {
        this.stopPolling()
        this.timerId = setInterval(() => this.getOperationProgress(), OPERATION_TIMER)
    }

    getOperationProgress = async (): Promise<void> => {
        if (!this.operationId) return

        const result = await segmentOperationFacade.getOperationProgress(this.operationId)

        if (!result) return

        runInAction(() => {
            this.segmentOperationProgress = result

            if (result.status === COMPLETE) {
                switch (result.operationType) {
                    case ADD:
                        this.editedSegment.clientsCount += result.recordsAdded
                        break
                    case REMOVE:
                        this.editedSegment.clientsCount -= result.recordsAdded
                        break
                    case ARCHIVE:
                        this.editedSegment.archive = true
                        break
                }
            }

            if (result.status === ERROR || result.status === COMPLETE) {
                this.stopPolling()
                this.operationId = null
            } else {
                this.restartPolling()
            }
        })
    }

    @action
    setNameFilter = (filter: string): void => {
        this.nameFilter = filter
    }

    @action
    clearFilters = (filterState: SideBarFiltersState): void => {
        this.filtersState = filterState
        this.nameFilter = ''
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.filtersState = filterState
    }

    @action
    resetSegment = (): void => {
        this.editedSegment = undefined
        this.operationId = null
        this.exportLink = null
        this.segmentOperationProgress = null
        this.timerId = null
    }

    @action
    reset = (): void => {
        this.segments = undefined
        this.nameFilter = ''
        this.filtersState = undefined
        this.editedSegment = undefined
        this.segmentsProcessingEnabled = false
        this.operationId = null
        this.exportLink = null
        this.segmentOperationProgress = null
        this.timerId = null
    }
}
