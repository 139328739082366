import { observable, action, runInAction } from 'mobx'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import { IdsProductsFromTSDVO } from '../../../protocol/set10/set-retail10-server/retailx/set-products-api/ids-products-from-tsdvo'

export class CustomPrintingTsdStore {

    @observable
    tsdIds: IdsProductsFromTSDVO[] = []

    @observable
    selectedTsdIds: IdsProductsFromTSDVO[] = []

    @action
    setSelectedTsdIds = (selectedTsdId: IdsProductsFromTSDVO[]): void => {
        this.selectedTsdIds = selectedTsdId
    }

    fetchTsdIds = async (): Promise<void> => {
        const result = await productManagerFinderLocal.getAllTSDIds() || []
        runInAction(() => {
            this.selectedTsdIds = [] // обнуляем выделенные каждый раз при запросе новых с сервера
            this.tsdIds = fixDateFromServer(result)
        })
    }

    @action
    reset = (): void => {
        this.tsdIds = []
        this.selectedTsdIds = []
    }
}

// TODO SFM-208 ошибка транспорта - lastUpdate приходит как timeStamp. Явно приводим его к Date
function fixDateFromServer(tsdIds: IdsProductsFromTSDVO[]): IdsProductsFromTSDVO[] {
    return tsdIds.map(item => {
        return {
            ...item,
            date: item.date ? new Date(item.date) : item.date
        }
    })
}
