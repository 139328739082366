import { getCustomRouteName, getDeepPageRouteName, getRouteName } from '../core/app-routes'
import { AppBarStore, LEFT_ARROW, MENU } from '../store/app-bar-store'
import { CHECK_PAGE_ROUTING_HANDLER } from '../store/operday/check-page-store'
import { NON_FISCAL_DOCUMENT_ROUTING_HANDLER } from '../store/operday/non-fiscal-document-store'
import { CHECK_REFUND_ROUTING_HANDLER } from '../store/operday/check-refund-store'
import { CASH_ROUTING_HANDLER } from '../store/operday/operday-store'
import { CASHIER_PERFOMANCE_ROUTING_HANDLER } from '../store/operday/personnel-motivation-store'
import { SHOPS_SEARCH_ROUTING_HANDLER } from '../store/shops/shops-search'
import { CASHIER_SETTINGS_ROUTING_HANDLER } from '../store/staff/cashier-settings-store'
import { ROLE_SETTINGS_ROUTING_HANDLER } from '../store/staff/roles-store'
import { USER_SETTINGS_ROUTING_HANDLER } from '../store/staff/user-settings-store'
import { TOPOLOGY_CITY_ROUTING_HANDLER } from '../store/shops/city-store'
import { TOPOLOGY_REGION_ROUTING_HANDLER } from '../store/shops/region-store'
import { TOPOLOGY_REGIONS_ROUTING_HANDLER } from '../store/shops/regions-store'
import { TOPOLOGY_SHOP_ROUTING_HANDLER } from '../store/shops/shop-settings/shop-store'
import { CASH_DESK_SALES_REPORT_ROUTING_HANDLER } from '../store/operday/cash-sales-report-store'
import { VENDOR_PAGE_ROUTING_HANDLER } from '../store/vendor-page-store'
import { RouterStore } from 'mobx-react-router'
import { getStore } from '../store/stores-repository'
import { APP_BAR_STORE, NAVIGATION_MENU_STORE, ROUTING_STORE } from '../store/stores'
import { NavigationMenuStore } from '../store/navigation-menu-store'
import { PRICE_TAG_SETTINGS_ROUTING_HANDLER } from '../store/price-tags/price-tag-settings-store'
import { PRICE_TAGS_PRINTING_ROUTE_HANDLER } from '../store/price-tags/printing/printing-store'
import { PRICE_TAG_FORMAT_SETTINGS_ROUTING_HANDLER } from '../store/price-tags/price-tag-format-settings-store'
import {
    PRICE_TAG_MATRICES_SETTINGS_ROUTING_HANDLER,
    PRICE_TAG_MATRICES_SETTINGS_RETAIL_ROUTING_HANDLER
} from '../store/price-tags/price-tag-format-matrices-store'
import { PRICE_TAG_EDITOR_ROUTING_HANDLER } from '../store/price-tags/price-tags-editor-store'
import { ESL_ROUTE_CHANGE_HANDLER } from '../pages/price-tags/esl/electronic-price-tags'
import { DIRECTIVE_PRINTING_TOPOLOGIES_ROUTE_HANDLER } from '../store/price-tags/directive-printing/topologies-store'
import {
    topologySettingsRouteHandler as DIRECTIVE_PRINTING_TOPOLOGY_ROUTE_HANDLER
} from '../store/price-tags/directive-printing/topology-settings-store'
import {
    printingSchedulesRouteHandler as DIRECTIVE_PRINTING_SCHEDULES_ROUTE_HANDLER
} from '../store/price-tags/directive-printing/printing-schedules-store'
import { CASH_DEVICE_SETTINGS_ROUTING_HANDLER } from '../store/cashes/devices/cash-device-settings-store'
import { CASH_DEVICES_REGISTER_ROUTING_HANDLER } from '../store/cashes/devices/cash-devices-store'
import { CASH_TEMPLATES_ROUTING_HANDLER } from '../store/cashes/cash-schedules-store'
import { PRODUCT_DETAILS_ROUTING_HANDLER } from '../store/products/product-details-store'
import { CUSTOM_PRINTING_BASKET_ROUTE_HANDLER } from '../store/price-tags/custom-printing-basket-store'
import { ACTIONS_ROUTE_CHANGE_HANDLER } from '../pages/loyalty/actions/actions/actions'
import { ACTION_SETTINGS_HANDLER } from '../store/actions/action-settings-store'
import { SALE_GROUP_ROUTING_HANDLER } from '../store/loyalty/sale-group-edit-store'
import { PRESENT_CARDS_ROUTE_CHANGE_HANDLER } from '../pages/cards/present-cards/present-cards'
import { LOYALTY_COUPONS_ROUTE_CHANGE_HANDLER } from '../pages/loyalty/loyalty-coupons/loyalty-coupons'
import { CARDS_ROUTE_CHANGE_HANDLER } from '../store/cards/cards-search-store'
import {
    INTEGRATION_EDIT_ERP_LIST_ROUTING_HANDLER,
    INTEGRATION_ERP_VIEW_ROUTING_HANDLER,
} from '../store/integration/import-export-store'
import {
    INTEGRATION_EDIT_PROCESSING_LIST_ROUTING_HANDLER,
    INTEGRATION_PROCESSING_VIEW_ROUTING_HANDLER,
} from '../store/integration/external-processings-store'

declare var global: GlobalWithBuild

export const mainPagesRouteMatcher: RegExp = /^\/?(([\w\d-%]+\/[\w\d-%]+)|([\w\d-%]+))\/?$/
export const deepPagesRouteMatcher: RegExp = /^\/?[\w-%]+\/[\w-%]+\/(?:[\w-%]+\/?)+$/

/**
 * Возвращает baseURL из global.build
 */
export const getBaseURL = () => {
    let baseURL = global.build.baseURL || ''

    if (baseURL === '/') {
        baseURL = ''
    }

    if (baseURL) {
        if (!baseURL.startsWith('/')) {
            baseURL = `/${baseURL}`
        }
        if (baseURL.endsWith('/')) {
            baseURL = baseURL.substr(0, baseURL.length - 1)
        }
    }
    return baseURL
}

/**
 * Переходит на указанный роут, учитывает относительный путь на веб-сервере
 * @param {string} path
 */
export const goTo = (path: string, state?: any): void => {
    const routingStore: RouterStore = getStore(ROUTING_STORE)
    const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)

    routingStore.history.push(path, state)

    navigationMenuStore.synchronizeWithRoute()
}

export interface RouteChangeHandler {
    routeMatcher: RegExp
    onEnter?: (newRoute: string, prevRoute: string) => void
    onLeave?: (newRoute: string, prevRoute: string) => void
}

export const deepPageLeaveHandler = (newRoute: string, prevRoute: string) => {
    const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
    navigationMenuStore.synchronizeWithRoute()
    appBarStore.updateState({
        title: getAppBarTitleByRoute(newRoute),
        leftIcon: MENU,
        showNotifications: true,
        onLeftIconClick: () => {
            navigationMenuStore.setOpen(!navigationMenuStore.open)
        }
    })
}

/**
 * Обработчик для неглавных страниц приложения (c уровнем вложенности 3+)
 */
export const deepPageDefaultHandler: RouteChangeHandler = {
    routeMatcher: deepPagesRouteMatcher,
    onEnter: (newRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        const routeParts = newRoute.split('/')
        const parentRoute = routeParts.slice(0, routeParts.length - 1).join('/')

        appBarStore.updateState({
            title: getDeepPageRouteName(newRoute) || getAppBarTitleByRoute(newRoute),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                goTo(`${parentRoute}`)
            }
        })
    }
}

/**
 * Обработчик для корневых страниц приложения, например, "operday/", "operday/checks-search".
 * Вычисляет заголовок AppBar как роуты разделенные "-", например:
 * "operday/" -> "Операционный день"
 * "operday/checks-search" -> "Операционный день - Поиск чеков"
 */
export const mainPageRouteChangeHandler: RouteChangeHandler = {
    routeMatcher: mainPagesRouteMatcher,
    onEnter: deepPageLeaveHandler
}

export const getAppBarTitleByRoute = (route: string) => {
    if (!route) return ''

    let parts = route.split('/').map(route => `/${route}`)
    if (parts.length > 1) {
        parts = parts.slice(1)
    }
    let result: string = ''

    parts.forEach((route, index) => {
        // Названия некоторых роутов составляются не суммой названий отдельных роутов, а имеют свои названия при комбинации с разными родителями
        let name: string = getCustomRouteName(parts.slice(0, index + 1).join(''))

        if (!name) name = getRouteName(route)

        if (!name) return

        if (result.length > 0) {
            result += ' - '
        }
        result += name
    })

    return result
}

export const ROUTE_CHANGE_HANDLERS = [
    CUSTOM_PRINTING_BASKET_ROUTE_HANDLER,
    CHECK_PAGE_ROUTING_HANDLER,
    NON_FISCAL_DOCUMENT_ROUTING_HANDLER,
    CHECK_REFUND_ROUTING_HANDLER,
    CASH_ROUTING_HANDLER,
    CASHIER_SETTINGS_ROUTING_HANDLER,
    ROLE_SETTINGS_ROUTING_HANDLER,
    USER_SETTINGS_ROUTING_HANDLER,
    CASHIER_PERFOMANCE_ROUTING_HANDLER,
    PRICE_TAG_SETTINGS_ROUTING_HANDLER,
    PRICE_TAG_FORMAT_SETTINGS_ROUTING_HANDLER,
    PRICE_TAG_MATRICES_SETTINGS_ROUTING_HANDLER,
    PRICE_TAG_MATRICES_SETTINGS_RETAIL_ROUTING_HANDLER,
    TOPOLOGY_REGIONS_ROUTING_HANDLER,
    TOPOLOGY_REGION_ROUTING_HANDLER,
    TOPOLOGY_CITY_ROUTING_HANDLER,
    TOPOLOGY_SHOP_ROUTING_HANDLER,
    SHOPS_SEARCH_ROUTING_HANDLER,
    CASH_DESK_SALES_REPORT_ROUTING_HANDLER,
    VENDOR_PAGE_ROUTING_HANDLER,
    PRICE_TAGS_PRINTING_ROUTE_HANDLER,
    PRICE_TAG_EDITOR_ROUTING_HANDLER,
    ESL_ROUTE_CHANGE_HANDLER,
    DIRECTIVE_PRINTING_TOPOLOGIES_ROUTE_HANDLER,
    DIRECTIVE_PRINTING_TOPOLOGY_ROUTE_HANDLER,
    DIRECTIVE_PRINTING_SCHEDULES_ROUTE_HANDLER,
    CASH_DEVICE_SETTINGS_ROUTING_HANDLER,
    CASH_DEVICES_REGISTER_ROUTING_HANDLER,
    CASH_TEMPLATES_ROUTING_HANDLER,
    PRODUCT_DETAILS_ROUTING_HANDLER,
    ACTIONS_ROUTE_CHANGE_HANDLER,
    PRESENT_CARDS_ROUTE_CHANGE_HANDLER,
    ACTION_SETTINGS_HANDLER,
    CARDS_ROUTE_CHANGE_HANDLER,
    SALE_GROUP_ROUTING_HANDLER,
    LOYALTY_COUPONS_ROUTE_CHANGE_HANDLER,
    INTEGRATION_EDIT_ERP_LIST_ROUTING_HANDLER,
    INTEGRATION_ERP_VIEW_ROUTING_HANDLER,
    INTEGRATION_EDIT_PROCESSING_LIST_ROUTING_HANDLER,
    INTEGRATION_PROCESSING_VIEW_ROUTING_HANDLER,
    deepPageDefaultHandler,
    // Возврат на главные страницы, на которых показывается боковое меню навигации
    mainPageRouteChangeHandler,
]
