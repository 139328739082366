import React from 'react'
import { t } from 'i18next'
import { ConditionEditorProps } from '../types'
import { ActionSettingsStore } from '../../../../../../store/actions/action-settings-store'
import { ACTION_SETTINGS_STORE } from '../../../../../../store/stores'
import {
    findCondition, ClientConditions, removeCondition, updateCondition
} from '../../../../../../core/advertising-actions/action-conditions'
import { getStore } from '../../../../../../store/stores-repository'
import { get, isNil } from 'lodash'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { SetApiCardsProcessing } from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/set-api-cards-processing'
import { NOT_SPECIFIED } from '../../../../../../core/values'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'

const styles = require('../../action-edit.scss')

interface ExternalCoupon {
    providerName: string[]
    categoryGuid: string[]
}

interface ExternalCouponEditorProps extends ConditionEditorProps {
    actionSettingsStore?: ActionSettingsStore
}

interface ExternalCouponOption {
    label: string
    value: string
}

interface ExternalCouponEditorState {
    options: ExternalCouponOption[]
    providerName: string
    categoryGuid: number
}

const getExternalCardsProcessings = async (): Promise<SetApiCardsProcessing[]> => {
    const actionSettingsStore = getStore<ActionSettingsStore>(ACTION_SETTINGS_STORE)
    if (!actionSettingsStore.externalCardsProcessings) {
        await actionSettingsStore.loadExternalCards()
    }
    return actionSettingsStore.externalCardsProcessings || []
}

// Получение текста для чипа
export const getExternalCouponChipLabel = async (condition: ExternalCoupon): Promise<string> => {
    const name: string = get(condition, 'providerName[0]')
    const processing = (await getExternalCardsProcessings()).find(item => item.name === name)

    return `${t('advertisingActions.client.externalCoupon')} ${processing?.friendlyName}`
}

export class ExternalCouponEditor extends React.Component<ExternalCouponEditorProps, ExternalCouponEditorState> {
    mounted: boolean

    state: ExternalCouponEditorState = {
        options: [],
        providerName: null,
        categoryGuid: null
    }

    componentDidMount() {
        this.mounted = true
        this.fetchServices()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    fetchServices = async (): Promise<void> => {
        const options: ExternalCouponOption[] = [
            {
                label: t('common.notSpecified'),
                value: NOT_SPECIFIED
            },
            ...(await getExternalCardsProcessings())
                .map(proc => ({
                    label: proc.friendlyName,
                    value: proc.name
                }))
        ]

        if (this.mounted) {
            this.setState({ options })
        }
    }

    handleUpdateCondition = (providerName: string, categoryGuid: number): void => {
        const { value, onChange } = this.props

        this.state.providerName = providerName
        this.state.categoryGuid = categoryGuid

        if (providerName === NOT_SPECIFIED) {
            onChange(removeCondition(ClientConditions.ExternalCouponCondition, value))
            return
        }

        const newCondition: ExternalCoupon = {
            'providerName': [providerName],
            'categoryGuid': [String(isNil(categoryGuid) ? '' : categoryGuid)]
        }

        onChange(updateCondition(
            ClientConditions.ExternalCouponCondition,
            newCondition,
            value
        ))
    }

    render() {
        const { options, providerName, categoryGuid } = this.state
        const condition = findCondition(ClientConditions.ExternalCouponCondition, this.props.value)
        const externalCoupon: ExternalCoupon = condition && condition.data

        this.state.providerName = get(externalCoupon, 'providerName[0]') || NOT_SPECIFIED
        this.state.categoryGuid = get(externalCoupon, 'categoryGuid[0]') || null

        return (
            <div>
                <div style={{ marginLeft: 24, marginTop: 24}}>
                    <SelectInput<ExternalCouponOption>
                        id="externalSystem"
                        style={{ maxWidth: 300 }}
                        label={t('advertisingActions.common.externalSystem')}
                        options={options}
                        value={providerName}
                        onSelect={option => this.handleUpdateCondition(option.value, categoryGuid)}
                    />
                </div>
                <div style={{ marginLeft: 24, marginTop: 8 }}>
                    <NumberInput
                        id="categoryGuidInput"
                        className={styles.input}
                        style={{ maxWidth: 300 }}
                        dataType="number"
                        canBeNull
                        min={-9999999999}
                        label={t('advertisingActions.client.couponCategoryGuid')}
                        value={isNil(categoryGuid) ? null : Number(categoryGuid)}
                        onValueChange={value => this.handleUpdateCondition(providerName, value)}
                    />
                </div>
            </div>
        )
    }
}
