export interface CardsFilterVO {
    '@class': 'ru.crystals.cards.common.CardsFilterVO'
    number?: string
    clientId?: number
    firstName?: string
    middleName?: string
    lastName?: string
    birthDate?: Date
    phone?: string
    showOnlyDeferredClient?: boolean
    blocked?: boolean
    cardTypeId?: number
    checkNum?: number
    cashNum?: number
    gangNum?: number
    shopIndex?: number
}

export const createCardsFilterVO = (initialization: Partial<CardsFilterVO>): CardsFilterVO => {
    if (!initialization) return null
    
    let result: CardsFilterVO = {
        number: initialization.number,
        clientId: initialization.clientId,
        firstName: initialization.firstName,
        middleName: initialization.middleName,
        lastName: initialization.lastName,
        birthDate: initialization.birthDate,
        phone: initialization.phone,
        showOnlyDeferredClient: initialization.showOnlyDeferredClient,
        blocked: initialization.blocked,
        cardTypeId: initialization.cardTypeId,
        checkNum: initialization.checkNum,
        cashNum: initialization.cashNum,
        gangNum: initialization.gangNum,
        shopIndex: initialization.shopIndex,
        '@class': 'ru.crystals.cards.common.CardsFilterVO',
    }
    
    return result
}
