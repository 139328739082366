import * as React from 'react'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import { PriceTagTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'

const styles = require('./template-name-renderer.scss')

interface TemplateNameRendererProps {
    priceTag: PriceTagTemplateVO
}

export const TemplateNameRenderer: React.FunctionComponent<TemplateNameRendererProps> = (props: TemplateNameRendererProps) => {
    const { priceTag } = props

    return (
        <div className={styles.templateNameRenderer} style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <div
                className={classNames(styles.colorBlockBase, styles[priceTag.displayColor])}
            />
            <Tooltip title={`${priceTag.width} x ${priceTag.height} ${priceTag.name}`}>
                <span>{priceTag.name}</span>
            </Tooltip>
        </div>
    )
}
