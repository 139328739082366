import { ValidationResult } from '../form-validation'
import { t } from 'i18next'

/*
 * Разрешает использование только буквы и цифр (без спец. символов)
 */
export const textValidator = (value: string | number): ValidationResult => {
    if (!value && value !== 0) return { valid: true }

    const stringValue: string = String(value)
    const regExp = /^[\wа-яА-ЯёЁ ]*$/

    if (!regExp.test(stringValue)) {
        return {
            valid: false,
            error: t('validation.doesNotMatchRegExp')
        }
    } else {
        return {
            valid: true
        }
    }
}
