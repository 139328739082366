import React from 'react'
import { PRODUCTS, SEARCH, getRouteName, getCustomRouteName } from '../core/app-routes'
import { RouteScheme } from '../utils/route-modules-util'
import { lazy } from '../utils/code-splitting'
import { SETRETAILX_GOODS_SEARCH } from '../core/privileges/privileges'
import ShoppingCart from '@material-ui/icons/ShoppingCart'

export const productsRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'productsNavLink',
        label: getRouteName(PRODUCTS),
        icon: <ShoppingCart/>,
    },
    path: PRODUCTS,
    children: [
        {
            navItemProps: {
                id: 'productSearchNavLink',
                label: getCustomRouteName(`${PRODUCTS}${SEARCH}`),
            },
            path: `${PRODUCTS}${SEARCH}/:code?/:detailType?`,
            privileges: [SETRETAILX_GOODS_SEARCH],
            component: lazy(() =>
                import(/* webpackChunkName: "products-search" */ '../pages/products/product-details/product-details'))
        }
    ]
})
