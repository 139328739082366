import React from 'react'
import classNames from 'classnames'
import { BaseTableProps } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { Paper } from '@material-ui/core'
// tslint:disable-next-line:no-implicit-dependencies
import { TableRowProps } from 'react-virtualized/dist/commonjs'

const styles = require('./table-hocs.scss')

interface WithStretchedHeightState {
    maxVisibleRows: number
}

// ВАЖНО! нужно задавать maxVisibleRows = 2 при использовании этого HoC (TODO доработать)
export const withStretchedHeight = <I, T extends BaseTableProps<I>>(
    WrappedComponent: React.ComponentType<T>,
) => (
    class WithStretchedHeight extends React.Component<T, WithStretchedHeightState> {
        static defaultProps: Partial<BaseTableProps<any>> = WrappedComponent.defaultProps

        wrapperDivRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

        state: WithStretchedHeightState = {
            maxVisibleRows: this.props.maxVisibleRows
        }

        componentDidMount(): void {
            this.calculateMaxVisibleRows()
            window.addEventListener('resize', this.calculateMaxVisibleRows)
        }

        componentDidUpdate(prevProps: Readonly<T>, prevState: Readonly<WithStretchedHeightState>, snapshot?: any): void {
            this.calculateMaxVisibleRows()
        }

        componentWillUnmount(): void {
            window.removeEventListener('resize', this.calculateMaxVisibleRows)
        }

        calculateMaxVisibleRows = (): void => {
            const divElement = this.wrapperDivRef.current
            const computedStyles = window.getComputedStyle(divElement)

            if (!computedStyles) return

            const rowHeight = this.props.rowHeight || WrappedComponent.defaultProps.rowHeight
            const newMaxVisibleRows = Math.floor((parseInt(computedStyles.height, 10) / rowHeight - 1) * 100) / 100

            if (newMaxVisibleRows
                && Math.abs(newMaxVisibleRows - this.state.maxVisibleRows) > 1) {
                this.setState({
                    maxVisibleRows: newMaxVisibleRows
                })
            }
        }

        render() {
            return (
                <div
                    style={{
                        height: 'calc(100% - 25px)',
                        width: '100%'
                    }}
                    ref={this.wrapperDivRef}
                >
                    <WrappedComponent
                        {...this.props as T}
                        maxVisibleRows={this.state.maxVisibleRows}
                    />
                </div>
            )
        }
    }
)

// ВАЖНО! работает только для infinite scroll таблицы, то есть для той, при которой maxVisibleRows < items.length
export const withLoadableOnScrollRows = <I, T extends BaseTableProps<I>>(
    WrappedComponent: React.ComponentType<T>,
    //
    getLoadMoreRowsCb: () => () => void,
) => (
    class WithLoadableRows extends React.Component<T, WithStretchedHeightState> {
        static defaultProps: Partial<BaseTableProps<any>> = WrappedComponent.defaultProps

        render() {
            // нам нужно вызывать загрузку дополнительных строк до тех пор, пока не появится скролл (т.е пока maxVisibleRows >= items.length)
            // когда скролл появится (т.е когда maxVisibleRows < items.length), то дополнительные строки будут вызываться при скролле
            // tslint:disable-next-line:max-line-length
            // ВАЖНО! в колбеке getLoadMoreRowsCb() должен быть механизм, который остановит вызов запроса к бэкенду, если строк в бэкенде больше нет, иначе будет infinite loop
            if (this.props.maxVisibleRows >= this.props.items.length) {
                getLoadMoreRowsCb()()
            }

            return (
                <WrappedComponent
                    {...this.props as T}
                    onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
                        // load more rows when scroll is 1 row before end
                        if (scrollTop + clientHeight + WrappedComponent.defaultProps.rowHeight >= scrollHeight) {
                            getLoadMoreRowsCb()()
                        }

                        if (this.props.onScroll) {
                            this.props.onScroll({ clientHeight, scrollHeight, scrollTop })
                        }
                    }}
                />
            )
        }
    }
)

export const withPaper = <I, T extends BaseTableProps<I>>(
    WrappedComponent: React.ComponentType<T>,
) => (
    class WithPaper extends React.Component<T> {
        static defaultProps: Partial<BaseTableProps<any>> = WrappedComponent.defaultProps

        render() {
            return (
                <Paper>
                    <WrappedComponent
                        {...this.props as T}
                    />
                </Paper>
            )
        }
    }
)

export const withCorrectLeftPadding = <I, T extends BaseTableProps<I>>(
    WrappedComponent: React.ComponentType<T>,
) => (
    class WithCorrectLeftPadding extends React.Component<T> {
        static defaultProps: Partial<BaseTableProps<any>> = WrappedComponent.defaultProps

        render() {
            return (
                <WrappedComponent
                    {...this.props as T}
                    WrapperProps={{
                        ...(this.props.WrapperProps || {}),
                        className: classNames(styles.table, this.props.WrapperProps?.className)
                    }}
                />
            )
        }
    }
)

export const withHoverableRows = <I, T extends BaseTableProps<I>>(
    WrappedComponent: React.ComponentType<T>,
    cursorPointer: boolean
) => (
    class WithHoverableRows extends React.Component<T> {
        static defaultProps: Partial<BaseTableProps<any>> = WrappedComponent.defaultProps

        render() {
            return (
                <WrappedComponent
                    {...this.props as T}
                    rowRenderer={(rowRendererProps: TableRowProps) => {
                        return this.props.rowRenderer({
                            ...rowRendererProps,
                            className: classNames(styles.hoverableRow, rowRendererProps.className, {
                                [styles.pointerCursor]: cursorPointer
                            })
                        })
                    }}
                />
            )
        }
    }
)
