import { t } from 'i18next'
import { uniq } from 'lodash'
import * as React from 'react'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import { ACTIVE, ProductStatus } from '../../../set10/core/products/product-statuses'
import { SimpleProduct } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { createProductsFilter } from '../../../protocol/set10/set-retail10-commons/data-structs-module/products-filter'
import chunk from 'lodash/chunk'
import { ProductsFindResponse } from '../../../protocol/set10/set-retail10-commons/data-structs-module/products-find-response'
import { CHUNK_SIZE } from '../../../utils/server-util'
import { RequestOptions } from '../../../protocol/set10/base-service'
import { ListInputProps, ListInput } from '@crystalservice/crystals-ui/lib/components/inputs/list-input/list-input'
import { PIECE, ProductType, WEIGHT, WEIGHT_PIECE } from '../../../set10/core/products/product-types'

const styles = require('./products-list-input.scss')

const SUGGESTION_LIMIT = 20

// Вынес отдельно, т.к. фильтр ProductFilter использует эти же данные
export const getProductsListInputPresetProps = (sessionId: string,
                                                status: ProductStatus,
                                                productsLimit: number,
                                                productType: ProductType): ListInputProps<SimpleProduct> => ({
    showAutocomplete: true,
    AutocompleteProps: {
        fetchDataCallback: (inputValue: string) => {
            if (productType === WEIGHT) {
                return productManagerFinderLocal.getSimpleScalesProductsByFilter(
                    createProductsFilter({
                        good: `use_like:%${inputValue}%`,
                        productStatusCode: status
                    }),
                    0,
                    productsLimit
                )
            }

            return productManagerFinderLocal.getSimpleProductsByFilter(
                sessionId,
                createProductsFilter({
                    good: `use_like:%${inputValue}%`,
                    productStatusCode: status
                }),
                0,
                productsLimit
            )
        },
        suggestionsLimit: productsLimit,
        menuItemHeight: 94,
        menuItemRenderer: item => {
            return (
                <div>
                    <p>{item.name}</p>
                    <p className={styles.secondary}>{t('shop.barcode')}: {item.barCode}</p>
                    <p className={styles.secondary}>{t('shop.productCode')}: {item.code}</p>
                </div>
            )
        },
        placeholder: t('shop.product')
    },
    getAppendedSuggestionText: product => product.code,
    getEntryTypeText: count => t('components.listInput.entryType.product', {count}),
    validator: async inputValue => {
        const inputArray: string[] = uniq(inputValue.split(','))

        return Promise.all([
            withChunks({
                pieceHandler: productType === PIECE && productManagerFinderLocal.getSimpleProductsByCodes2,
                scaleHandler: productType === WEIGHT && productManagerFinderLocal.getSimpleScalesProductsByCodes,
                sessionId, inputArray, status}),
            withChunks({
                pieceHandler: productType === PIECE && productManagerFinderLocal.getSimpleProductsByBarCodes2,
                scaleHandler: productType === WEIGHT && productManagerFinderLocal.getSimpleScalesProductsByBarCodes,
                sessionId, inputArray, status}),
        ])
            .then(([codeResponse, barcodeResponse]) => {
                const codesFromCodeResponse = codeResponse ? codeResponse.foundProducts.map(p => p.code) : []
                // возвращать нужно именно p.code
                const codesFromBarcodeResponse = barcodeResponse ? barcodeResponse.foundProducts.map(p => p.code) : []
                const correct = uniq([...codesFromCodeResponse, ...codesFromBarcodeResponse])
                const correctBarcodes = barcodeResponse.foundProducts.map(p => p.barCode)

                let incorrect: string[] = inputArray
                    .filter(value => correct.indexOf(value) === -1 && correctBarcodes.indexOf(value) === -1)
                return Promise.resolve({correct, incorrect})
            })
    },
})

type PieceHandler = (sessionId: string | undefined, codes: string[], status?: number, options?: RequestOptions) => Promise<ProductsFindResponse>
type ScaleHandler = (codes: string[], options?: RequestOptions) => Promise<ProductsFindResponse>

interface WithChunksProps {
    pieceHandler: PieceHandler
    scaleHandler: ScaleHandler
    sessionId: string
    inputArray: string[]
    status: ProductStatus
}

export const withChunks = async (withChunksProps: WithChunksProps): Promise<Partial<ProductsFindResponse>> => {
    const { pieceHandler, scaleHandler, sessionId, inputArray, status } = withChunksProps
    const chunks = chunk(inputArray, CHUNK_SIZE)

    let resFoundProducts: SimpleProduct[] = []
    let resNotFoundCodes = []
    for (const codes of chunks) {
        const { foundProducts, notFoundCodes } = pieceHandler ?
            await pieceHandler(sessionId, codes, status) :
            await scaleHandler(codes)

        resFoundProducts = [...resFoundProducts, ...foundProducts]
        resNotFoundCodes = [...resNotFoundCodes, ...notFoundCodes]
    }
    return { foundProducts: resFoundProducts, notFoundCodes: resNotFoundCodes }
}

export interface ProductsListInputProps extends ListInputProps<SimpleProduct> {
    productsLimit?: number
    sessionId: string
    status?: ProductStatus
    productType?: ProductType
}

export class ProductsListInput extends React.Component<ProductsListInputProps> {
    static defaultProps: Partial<ProductsListInputProps> = {
        productsLimit: SUGGESTION_LIMIT,
        status: ACTIVE,
    }

    render() {
        const {productsLimit, onChange, sessionId, status, productType = PIECE, ...other} = this.props

        return (
            <ListInput<SimpleProduct>
                {...getProductsListInputPresetProps(sessionId, status, productsLimit, productType)}
                {...other}
                label={t('shop.product')}
                onChange={onChange}
            />
        )
    }
}
