import { RectBounds } from '../rect-bounds'
import { ControllerType, ROTATION } from '../hand-controller'
import { Vector2d } from '../../../utils/math/geom-util'
import { BaseConstraint, EditorState } from './constraint'

export class FixedRotationConstraint extends BaseConstraint<null> {

    constructor(active: boolean = false) {
        super(null, 1, active)
    }

    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d {
        if (controller === ROTATION && this.active) {
            return new Vector2d()
        } else {
            return translation
        }
    }

    filterControllers(controllers: ControllerType[]): ControllerType[] {
        return controllers.filter(c => c !== ROTATION)
    }
}
