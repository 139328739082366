import { observable, action, runInAction, toJS, computed } from 'mobx'
import { withSpinner } from '../with-spinner'
import { basketService } from '../../../protocol/set10/basket-service'
import { BasketItemVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/basket-item-vo'
import {
    ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED,
    ACTIVE,
    ProductStatus
} from '../../core/products/product-statuses'
import { localStorageManager } from '../../utils/local-storage-util'
import { FIRST_PRICE, SECOND_PRICE, THIRD_PRICE, FORTH_PRICE, FIFTH_PRICE } from '../../core/price-tags/product-properties'
import { PriceNumber } from '../../core/products/prices'
import { APP_BAR_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { RouteChangeHandler } from '../../utils/router-util'
import { CUSTOM_PRINTING, FROM_PRODUCT_CARD, PRICE_TAGS } from '../../core/app-routes'
import { t } from 'i18next'
import { AppBarStore } from '../app-bar-store'

export type CustomPrintingInputType = 0 | 1 | 2 | 3

export const BY_PRODUCT = 0
export const BY_GROUP = 1
export const BY_ACTION = 2
export const FROM_TSD = 3

// Формат данных для хранения в localStorage
export interface BasketItem {
    // Номер полки
    status: number
    // Айди объекта
    objectId: string
    // Выбран ли элемент
    selected: boolean
    // Поля, измененные в оригинальном элементе
    changes: { [fieldName: string]: any }
}

export class CustomPrintingBasketStore {

    // Регулярная полка: 99
    @observable
    regularBasketItems: BasketItem[] = []

    // Акционная полка: 123
    @observable
    actionBasketItems: BasketItem[] = []

    @observable
    currentInput: CustomPrintingInputType = BY_PRODUCT

    @observable
    isPrintingFromProductCard: boolean = false

    private userStore: UserStore = getStore(USER_STORE)

    @computed
    get userLogin(): string {
        if (!this.userStore.userInfo) return ''
        return this.userStore.userInfo.login
    }

    @action
    setPrintingFromProductCard = (value: boolean): void => {
        this.isPrintingFromProductCard = value
    }

    @action
    setCurrentInput = (value: CustomPrintingInputType): void => {
        this.currentInput = value
    }

    @action
    fillBasketItems = async (): Promise<void> => {
        if (this.isPrintingFromProductCard) return

        this.regularBasketItems = localStorageManager.getCustomPrintingBaskets(ACTIVE, this.userLogin)
        this.actionBasketItems = localStorageManager.getCustomPrintingBaskets(ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED, this.userLogin)

        await withSpinner(this.validateBasketItems())
    }

    // Метод валидирует id объектов на сервере, и оставляет только те, чьи id вернулись в ответе
    validateBasketItems = async (): Promise<void> => {
        const regularIds = this.regularBasketItems.map(item => item.objectId)
        const actionIds = this.actionBasketItems.map(item => item.objectId)

        if (regularIds.length > 0) {
            const regularResult = await basketService.validateBasket(regularIds, ACTIVE)

            runInAction(() => {
                if (regularResult.length !== this.regularBasketItems.length) {
                    this.regularBasketItems = regularResult.map(item => {
                        const oldItem = this.regularBasketItems.find(oldItem => oldItem.objectId === item.objectId)
                        return {
                            objectId: item.objectId,
                            status: item.status,
                            selected: oldItem.selected,
                            changes: {}
                        }
                    })
                }
            })
        }
        if (actionIds.length > 0) {
            const actionResult = await basketService.validateBasket(actionIds, ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED)

            runInAction(() => {
                if (actionResult.length !== this.actionBasketItems.length) {
                    this.actionBasketItems = actionResult.map(item => {
                        const oldItem = this.actionBasketItems.find(oldItem => oldItem.objectId === item.objectId)
                        return {
                            objectId: item.objectId,
                            status: item.status,
                            selected: oldItem ? oldItem.selected : true,
                            changes: {}
                        }
                    })
                }
            })
        }
    }

    // Добавление по баркоду продукта
    addProductsByBarcodes = async (productBarCodes: string[]): Promise<BasketItemVO[]> => {
        const result = await withSpinner(basketService.sortProductBarcodeByBaskets(productBarCodes)) || {}
        // return this.addItemsToBasket(result)
        return this.addItemsToBasket(flattenBasketHashMap(fixBasketHashMapFromServer(result)))
    }

    // Добавление по коду продукта
    addProducts = async (productCodes: string[]): Promise<BasketItemVO[]> => {
        const result = await withSpinner(basketService.sortProductsByBaskets(productCodes))
        return this.addItemsToBasket(result)
    }

    // Добавление по группе
    addGroupProducts = async (groupCode: string): Promise<BasketItemVO[]> => {
        const result = await withSpinner(basketService.sortProductGroupByBaskets(groupCode))
        return this.addItemsToBasket(result)
    }

    // Добавление по акции
    addActionProducts = async (actionCode: string): Promise<BasketItemVO[]> => {
        const result = await withSpinner(basketService.sortActionProductsByBaskets(actionCode))
        return this.addItemsToBasket(result)
    }

    findItemInBaskets = (item: Partial<BasketItem>) => {
        const objectId = item.objectId
        let foundItem: BasketItem

        // Ищем наш элемент в массивах
        if (item.status === ACTIVE) {
            foundItem = this.regularBasketItems.find(basketItem => basketItem.objectId === objectId)
        }
        if (item.status === ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED) {
            foundItem = this.actionBasketItems.find(basketItem => basketItem.objectId === objectId)
        }

        if (foundItem) {
            Object.keys(item).forEach(key => {
                foundItem[key] = item[key]
            })
        }

        return foundItem
    }

    /*
     * Обновляет поля элемента корзины
     * Обязательно должны присутсвовать поля: objectId, status
     */
    @action
    updateBasketItems = (items: Array<Partial<BasketItem>>): void => {
        const foundItems = items.map(this.findItemInBaskets)

        if (foundItems.some(item => item.status === ACTIVE)) {
            this.updateLocalStorage(ACTIVE)
        }
        if (foundItems.some(item => item.status === ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED)) {
            this.updateLocalStorage(ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED)
        }
    }

    @action
    updateItemsSelection = (selectedIds: string[], status: ProductStatus): void => {
        if (status === ACTIVE) {
            this.regularBasketItems.forEach(basketItem => {
                basketItem.selected = selectedIds.some(selectedId => basketItem.objectId === selectedId)
            })
            this.updateLocalStorage(ACTIVE)
        }
        if (status === ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED) {
            this.actionBasketItems.forEach(basketItem => {
                basketItem.selected = selectedIds.some(selectedId => basketItem.objectId === selectedId)
            })
            this.updateLocalStorage(ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED)
        }
    }

    /*
     * Метод анализирует полученные элементы, разделяет по полкам
     * Далее создает новый
     * Возвращает список добавленных
     */
    @action
    addItemsToBasket = (items: BasketItemVO[]): BasketItemVO[] => {
        if (this.isPrintingFromProductCard) {
            this.regularBasketItems = []
            this.actionBasketItems = []

            if (!items?.length) {
                return []
            }
        }

        const addedItems = {
            regular: [],
            action: [],
        }
        const basketTypeList = ['regular', 'action']
        /**
         * - Если добавляем в regularBasketItems/actionBasketItems по 1 элементу - помещаем его в начало массива;
         * - Если добавляем в regularBasketItems/actionBasketItems пачку элементов (кнопка ДОБАВИТЬ СПИСКОМ)
         *      - помещаем всю пачку в начало массива (порядок элементов внутри пачки не изменяем);
         */
        basketTypeList.forEach((basketType: string) => {
            const basketItemsType = basketType + 'BasketItems'
            const preparedItems = items.length > 1 && basketType !== basketTypeList[1] ? items.reverse() : items

            const regularTab = basketItemsType === 'regularBasketItems'
            const actionTab = basketItemsType === 'actionBasketItems'

            preparedItems.forEach((item: BasketItemVO) => {
                const id = item.objectId
                const newItem: BasketItem = {
                    objectId: item.objectId,
                    status: item.status,
                    selected: true,
                    changes: {}
                }

                const shouldBeAdded = item.status === ACTIVE && regularTab ||
                    item.status === ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED && actionTab

                if (shouldBeAdded) {
                    // Если элемент уже был, повторно не добавляем
                    if (!this[basketItemsType].find(basketItem => basketItem.objectId === id)) {
                        const method = !this[basketItemsType].length ? 'push' : 'unshift'

                        // В карточке товара у одного товара может быть несколько заданий на печать
                        if (this.isPrintingFromProductCard && regularTab) {
                            this[basketItemsType] = [newItem]
                        } else {
                            this[basketItemsType][method](newItem)
                        }

                        addedItems[basketType][method](item)
                    }
                }
            })
        })

        if (addedItems.regular.length > 0) this.updateLocalStorage(ACTIVE)
        if (addedItems.action.length > 0) this.updateLocalStorage(ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED)

        return [...addedItems.regular, ...addedItems.action]
    }

    updateLocalStorage = (status: ProductStatus): void => {
        if (this.isPrintingFromProductCard) return

        localStorageManager.setCustomPrintingBaskets(
            status === ACTIVE ? toJS(this.regularBasketItems) : toJS(this.actionBasketItems),
            status,
            this.userLogin
        )
    }

    @action
    removeSelectedItems = (status: ProductStatus, selectedProductIds: string[]) => {
        function filterItems(item: BasketItem): boolean {
            return !selectedProductIds.some(productId => productId === item.objectId)
        }

        if (status === ACTIVE) {
            this.regularBasketItems = this.regularBasketItems.filter(filterItems)
        } else if (status === ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED) {
            this.actionBasketItems = this.actionBasketItems.filter(filterItems)
        }

        this.updateLocalStorage(status)
    }

    @action
    reset = (): void => {
        this.regularBasketItems = []
        this.actionBasketItems = []
        this.isPrintingFromProductCard = false
    }
}

export function priceNumberToEditableProperty(priceNumber: PriceNumber): string {
    switch (priceNumber) {
        case PriceNumber.FIRST: return FIRST_PRICE
        case PriceNumber.SECOND: return SECOND_PRICE
        case PriceNumber.THIRD: return THIRD_PRICE
        case PriceNumber.FOURTH: return FORTH_PRICE
        case PriceNumber.FIFTH: return FIFTH_PRICE
    }
}

// TODO SFM-208 - HashMap приходит не в том формате
function fixBasketHashMapFromServer(data: any = {}): { [key: string]: BasketItemVO[] } {
    const result = {}

    Object.keys(data).forEach(key => {
        if (key === '@class') return

        result[key] = data[key][1]
    })

    return result
}

function flattenBasketHashMap(hashMap: { [key: string]: BasketItemVO[] }): BasketItemVO[] {
    const result = []

    Object.keys(hashMap).forEach(key => {
        result.push(...hashMap[key])
    })

    return result
}

/**
 * Обработчик для страниц /price-tags/custom-printing/:code
 */
export const CUSTOM_PRINTING_BASKET_ROUTE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${PRICE_TAGS}${CUSTOM_PRINTING}${FROM_PRODUCT_CARD}(\/[\\d-]+)$`),
    onEnter: () => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        appBarStore.updateState({
            title: `${t('set10.priceTags')} - ${t('set10.customPriceTagsPrinting')}`,
            leftIcon: null,
            showNotifications: true,
            onLeftIconClick: () => null
        })
    }
}
