export const DEFAULT_REQUEST_DELAY: number = 500
export const SNACKBAR_DURATION: number = 2500
export const SNACKBAR_EXTENDED_DURATION: number = 5000
export const SNACKBAR_HUGE_DURATION: number = 60000
export const TOOLTIP_DELAY: number = 1000
export const SHORT_TOOLTIP_DELAY: number = 600
export const INPUT_DELAY: number = 300
export const AUTOSAVE_DELAY: number = 1000
export const KM_REPORTS_REFETCH_AFTER_PRINT_DELAY: number = 2000
export const REPAINT_DELAY: number = 100
export const SCROLL_SYNC_DELAY: number = 250
