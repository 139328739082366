import { Progress } from './set-retail10-server/retailx/set-print-price-tags/progress'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class NotPrintedShelfLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-PrintPriceTags/NotPrintedShelfBean!ru.crystals.setretailx.printing.common.api.NotPrintedShelfLocal'
    }

    getTasksStatus = (sessionId: string, options?: RequestOptions): Promise<Progress[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getTasksStatus',
                params: [sessionId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

}

export const notPrintedShelfLocal: NotPrintedShelfLocal = new NotPrintedShelfLocal()
