import { ResultApplyObject } from './result-apply-object'
import { FixPriceApplyObject } from './fix-price-apply-object'
import { ProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import { CompositeApplyObject, PERCENT, FIXSUMM, FIX_PRICE } from './composite-apply-object'
import { checkSaleGroup, checkCountry, checkDepartment, checkGroup, checkManufacturer } from '../products/product-utils'
import { SetApplyObject, FIXPRICE } from './set-apply-object'
import { Row } from './row'
import { t } from 'i18next'
import { formatPrice } from '../currency-utils'

export function getPreferenceDescription(applyObject: ResultApplyObject, productDetails: ProductVO_SF, currency: string): string {
    if (applyObject instanceof FixPriceApplyObject) {
        return getFixPriceApplyObjectPreferenceDescription(applyObject, productDetails, currency)
    }

    if (applyObject instanceof CompositeApplyObject) {
        return getCompositeApplyObjectPreferenceDescription(applyObject, productDetails, currency)
    }

    if (applyObject instanceof SetApplyObject) {
        return getSetApplyObjectPreferenceDescription(applyObject, productDetails, currency)
    }

    return ''
}

function getFixPriceApplyObjectPreferenceDescription(
    fixPriceApplyObject: FixPriceApplyObject, productDetails: ProductVO_SF, currency: string
): string {
    for (const applyObject of fixPriceApplyObject.applyObjects) {
        if (applyObject.item === productDetails.code) {
            return `${t('advertisingActions.preferenceDescription.fixPrice', { price: formatPrice(applyObject.newPrice / 100) })} ${currency}`
        }
    }

    return ''
}

function getCompositeApplyObjectPreferenceDescription(
    compositeApplyObject: CompositeApplyObject, productDetails: ProductVO_SF, currency: string
): string {
    if (isProductInCompositeApplyObject(compositeApplyObject, productDetails)) {
        switch (compositeApplyObject.valueType) {
            case PERCENT:
                return t('advertisingActions.preferenceDescription.discountPercent', { percent: compositeApplyObject.value / 100 })
            case FIXSUMM:
                return `${
                    t('advertisingActions.preferenceDescription.discountValue',
                    { value: formatPrice(compositeApplyObject.value / 100) })
                } ${currency}`
            case FIX_PRICE:
                return `${
                    t('advertisingActions.preferenceDescription.fixPrice',
                    { price: formatPrice(compositeApplyObject.value / 100) })
                } ${currency}`
            default:
                return ''
        }
    }

    return ''
}

function getSetApplyObjectPreferenceDescription(setApplyObject: SetApplyObject, productDetails: ProductVO_SF, currency: string): string {
    let result: string = ''

    setApplyObject.rows.forEach(row => {
        if (isProductInRow(productDetails, row)) {
            if (row.withoutDiscount) {
                result = t('advertisingActions.preferenceDescription.withoutDiscount', { value: row.count / 1000 })
                return
            }

            switch (setApplyObject.valueType) {
                case FIXPRICE:
                    if (!isNaN(setApplyObject.setCost) && typeof setApplyObject.setCost === 'number') {
                        result = t('advertisingActions.preferenceDescription.priceOfSet',
                            {
                                value: formatPrice(setApplyObject.setCost / 100),
                                currency
                            })
                        } else {
                            result = t('advertisingActions.preferenceDescription.fixPriceInSet',
                            {
                                value: formatPrice(row.value / 100),
                                count: row.count / 1000,
                                currency
                            })
                        }
                    break
                case PERCENT:
                    result = t('advertisingActions.preferenceDescription.discountInSet',
                        {
                            value: row.value / 100, count: row.count / 1000
                        })
                    break
            }
        }
    })

    return result
}

function isProductInCompositeApplyObject(compositeApplyObject: CompositeApplyObject, product: ProductVO_SF): boolean {
    if (compositeApplyObject.forAllGoods) {
        return true
    }

    if (compositeApplyObject.item) {
        for (let item of compositeApplyObject.item) {
            if (item === product.code) {
                return true
            }
        }
    }

    if (compositeApplyObject.saleGroupId) {
        for (let saleGroupId of compositeApplyObject.saleGroupId) {
            if (checkSaleGroup(saleGroupId.toString(), product.saleGroupVos)) {
                return true
            }
        }
    }

    if (compositeApplyObject.countryId) {
        for (let countryId of compositeApplyObject.countryId) {
            if (checkCountry(countryId, product.countryVo)) {
                return true
            }
        }
    }

    if (compositeApplyObject.departId) {
        for (let departId of compositeApplyObject.departId) {
            if (checkDepartment(departId, product)) {
                return true
            }
        }
    }

    if (compositeApplyObject.groupId) {
        for (let groupId of compositeApplyObject.groupId) {
            if (checkGroup(groupId, product.group)) {
                return true
            }
        }
    }

    if (compositeApplyObject.manufacturerId) {
        for (let manufacturerId of compositeApplyObject.manufacturerId) {
            if (checkManufacturer(manufacturerId, product.manufacturerVo)) {
                return true
            }
        }
    }

    return false
}

function isProductInRow(product: ProductVO_SF, row: Row): boolean {
    if (row.item === product.code) {
        return true
    }

    if (row.markings && row.markings.includes(product.code)) {
        return true
    }

    if (checkSaleGroup(row.saleGroupId, product.saleGroupVos)) {
        return true
    }

    if (checkCountry(row.countryId, product.countryVo)) {
        return true
    }

    if (checkDepartment(row.departId, product)) {
        return true
    }

    if (checkGroup(row.groupId, product.group)) {
        return true
    }

    if (checkManufacturer(row.manufacturerId, product.manufacturerVo)) {
        return true
    }

    return false
}
