import {observable, runInAction, action} from 'mobx'
import {t} from 'i18next'
import { UserStore } from '../user-store'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE } from '../stores'
import { printersManagerLocal } from '../../../protocol/set10/printers-manager-local'
import { PriceTagTemplateVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { PRICE_TAGS, TEMPLATES } from '../../core/app-routes'
import { NEW } from '../../core/values'
import { goTo } from '../../utils/router-util'
import { fromClientToServerTime } from '../../utils/app-util'

export class PriceTagsTemplatesStore {
    @observable
    priceTags: PriceTagTemplateVO[] = null

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    fetchPriceTags = async (): Promise<void> => {
        const priceTags = await printersManagerLocal.getPriceTagTemplates(this.userStore.session) || []
        runInAction(() => {
            // FIXME: SFM-208 -> Дата приходит в unix_time, в протоколе просто Date указано
            priceTags.forEach((item: PriceTagTemplateVO) => {
                item.lastUpdate = fromClientToServerTime(item.lastUpdate)
            })

            this.priceTags = priceTags
        })
    }

    showPriceTagRemoveDialog = (priceTag: PriceTagTemplateVO): void => {
        this.appStore.showDialog({
            title: t('priceTags.removeDialogTitle'),
            message: t('priceTags.removeDialogMessage', {name: priceTag.name}),
            mode: DIALOG,
            onYes: async () => {
                const canBeRemoved = await this.checkPriceTagCanBeRemoved(priceTag)
                if (canBeRemoved) {
                    this.removePriceTag(priceTag)
                } else {
                    this.showPriceTagNestedWarning(priceTag)
                }
            }
        })
    }

    checkPriceTagCanBeRemoved = (priceTag: PriceTagTemplateVO): Promise<boolean> => {
        return printersManagerLocal.canRemovePricetagTemplate(priceTag)
    }

    showPriceTagNestedWarning = (priceTag: PriceTagTemplateVO): void => {
        this.appStore.showDialog({
            title: t('priceTags.removeDialogTitle'),
            message: t('priceTags.removeDialogNestedMessage'),
            mode: DIALOG,
            onYes: () => this.removePriceTag(priceTag)
        })
    }

    removePriceTag = async (priceTag: PriceTagTemplateVO) => {
        const removedId = await printersManagerLocal.removePriceTagTemplate(this.userStore.session, priceTag)
        runInAction(() => {
            let index = this.priceTags.findIndex(item => item.id === removedId)
            if (index !== -1) {
                this.priceTags.splice(index, 1)

                this.appStore.showSnackbar({
                    message: t('priceTags.snackBars.priceTagRemove')
                })
            }
        })
    }

    editPriceTag = (priceTag: PriceTagTemplateVO): void => {
        goTo(`${PRICE_TAGS}${TEMPLATES}/${priceTag.id}`)
    }

    addPriceTag = (): void => {
        goTo(`${PRICE_TAGS}${TEMPLATES}/${NEW}`)
    }

    copyTemplate = async (priceTag: PriceTagTemplateVO): Promise<void>  => {
        const fullPriceTagData = await printersManagerLocal.getPriceTagTemplate(this.userStore.session, priceTag.id)
        const newPriceTag = await printersManagerLocal.copyPriceTagTemplate(this.userStore.session, fullPriceTagData)
        runInAction(() => {
            newPriceTag.lastUpdate = fromClientToServerTime(newPriceTag.lastUpdate)
            this.priceTags.push(newPriceTag)
        })
        this.appStore.showSnackbar({
            message: t('priceTags.snackBars.templateCopy')
        })
    }

    copySettingsFromCentrum = async (priceTag: PriceTagTemplateVO): Promise<void>  => {
        const priceTagData: PriceTagTemplateVO = await printersManagerLocal.requestPriceTagFromCentrum(priceTag.guid)
        runInAction(() => {
            let index = this.priceTags.findIndex(item => item.guid === priceTag.guid)
            if (index !== -1) {
                priceTagData.lastUpdate = fromClientToServerTime(priceTagData.lastUpdate)
                this.priceTags[index] = priceTagData

                this.appStore.showSnackbar({
                    message: t('priceTags.snackBars.settingsFromCentrum')
                })
            }
        })
    }

    @action
    reset = (): void => {
        this.priceTags = null
    }
}
