import React, { Component, ReactNode } from 'react'
import classNames from 'classnames'
import { isNil } from 'lodash'
import {
    ACTIONS,
    CARDS,
    MONITORING,
    NotificationGroupInfo,
    NotificationInfo,
    NotificationStore,
    NotificationSubGroup,
    NotificationSubGroupInfo,
    PRODUCTS,
    NotificationType
} from '../../../store/notification-store'
import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'
import FeedbackIcon from '@material-ui/icons/Feedback'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import CreditCard from '@material-ui/icons/CreditCard'
import Loyalty from '@material-ui/icons/Loyalty'
import { inject, observer } from 'mobx-react'
import { NOTIFICATION_STORE, NAVIGATION_MENU_STORE, ROUTING_STORE } from '../../../store/stores'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import Settings from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
    getEventsCountInNotificationGroup,
    getNotificationGroupName,
    getNotificationSubGroupName,
    getNotificationTooltip,
    getNotificationTypeName
} from './notifications-util'
import { NavigationMenuStore } from '../../../store/navigation-menu-store'
import { t } from 'i18next'
import { V1NotificationInfo, V1NotificationsSubGroup } from '../../../core/iframe-modules/entities'
import { goTo } from '../../../utils/router-util'

const styles = require('./notifications.scss')
const infoIcon = require('../../../../assets/images/icons/components/old-path-info.svg')

export interface NotificationsProps {
    notificationStore?: NotificationStore
}

export interface NotificationsState {
    openMenus: {[group: string]: boolean}
}

export const getNotificationSubGroupIcon = (type: NotificationSubGroup): ReactNode => {
    switch (type) {
        case ACTIONS:
            return <Loyalty color="primary"/>
        case PRODUCTS:
            return <ShoppingCart color="primary"/>
        case CARDS:
            return <CreditCard color="primary"/>
        default:
            return <div className={styles.blankIcon}/>
    }
}

@inject(NOTIFICATION_STORE)
@observer
export class Notifications extends Component<NotificationsProps, NotificationsState> {

    state: NotificationsState = {
        openMenus: {},
    }

    popperAnchors: {[group: string]: HTMLElement } = {}

    renderGroup = (group: NotificationGroupInfo): ReactNode => {
        return (
            <Paper id={`group-${group.type}`} className={styles.mainPanel}>
                <div className={styles.groupTitleContainer}>
                    <p className={styles.groupTitle}>
                        { getNotificationGroupName(group.type) }
                    </p>
                    <Settings color="secondary"/>
                </div>
                { (group.subGroups as NotificationSubGroupInfo[]).map(subGroup => this.renderSubGroup(group, subGroup)) }
            </Paper>
        )
    }

    renderSubGroup = (group: NotificationGroupInfo, subGroup: NotificationSubGroupInfo | V1NotificationsSubGroup): ReactNode => {
        return (
            <Paper
                id={`subGroup-${group.type}-${subGroup.type}`}
                key={subGroup.type}
                className={styles.subGroupPanel}
            >
                <div className={styles.subGroupTitleContainer}>
                    {
                        (subGroup as V1NotificationsSubGroup).iconUrl
                        ? <img src={(subGroup as V1NotificationsSubGroup).iconUrl} alt={subGroup.type} />
                        : getNotificationSubGroupIcon(subGroup.type as NotificationSubGroup)
                    }
                    <p className={styles.subGroupTitle}>{
                        (subGroup as V1NotificationsSubGroup).name || getNotificationSubGroupName(subGroup.type as NotificationSubGroup)
                    }</p>
                </div>
                <MenuList className={styles.notificationList}>
                    {(subGroup.notifications as NotificationInfo[]).map(n => this.renderNotification(n))}
                </MenuList>
            </Paper>
        )
    }

    renderNotification = (notification: NotificationInfo | V1NotificationInfo): ReactNode => {
        const { type, counters } = notification
        const { openMenus } = this.state

        const notificationComponent = (
            <Tooltip title={getNotificationTooltip(type as NotificationType)} enterDelay={TOOLTIP_DELAY}>
                <div className={styles.notificationContainer}>
                    <p className={styles.notificationName}>{
                        (notification as V1NotificationInfo).name || getNotificationTypeName(type as NotificationType)
                    }:</p>
                    <p className={classNames(styles.counters)}>{counters.join(' / ')}</p>
                </div>
            </Tooltip>
        )

        return (
            <MenuItem
                key={type}
                id={`notification-${type}`}
                onClick={() => {
                    this.setState({
                        openMenus: {
                            ...openMenus,
                            [notification.group]: false,
                        }
                    })

                    if ((notification as V1NotificationInfo).redirectPath) {
                        goTo((notification as V1NotificationInfo).redirectPath)
                    } else {
                        (notification as NotificationInfo).redirect()
                    }
                }}
            >
                { notificationComponent }
            </MenuItem>
        )
    }

    render() {
        const { groupsWithExternalModules } = this.props.notificationStore
        const { openMenus } = this.state

        return (
            groupsWithExternalModules.map(group => {
                const eventsCount = getEventsCountInNotificationGroup(group)
                const Icon = group.type === MONITORING ? FeedbackIcon : NotificationsIcon
                let menuOpen = openMenus[group.type]
                if (isNil(menuOpen)) menuOpen = false

                return (
                    <div key={group.type}>
                        <IconButton
                            id={`notificationButton-${group.type}`}
                            className={styles.iconButton}
                            onClick={() => {
                                this.setState({
                                    openMenus: {
                                        ...openMenus,
                                        [group.type]: true,
                                    }
                                })
                            }}
                        >
                            {eventsCount === 0 ? (
                                <Icon className={styles.notificationButton} color="primary"/>
                            ) : (
                                <Badge
                                    badgeContent={eventsCount > 9 ? '...' : eventsCount}
                                    color="error"
                                >
                                    <Icon className={styles.notificationButton}/>
                                </Badge>
                            )}
                        </IconButton>

                        <div
                            // Якорь для меню (кнопка может на долю секунды исчезнуть при обновлении счетчиков)
                            ref={element => { this.popperAnchors[group.type] = element }}
                            className={styles.ghostDiv}
                        >
                        </div>

                        <Popper
                            open={menuOpen}
                            anchorEl={this.popperAnchors[group.type]}
                            className={styles.popper}
                            placement="bottom"
                        >
                            <ClickAwayListener
                                mouseEvent="onMouseDown"
                                onClickAway={() => this.setState({
                                    openMenus: {
                                        ...openMenus,
                                        [group.type]: false,
                                    }
                                })}
                            >
                                { this.renderGroup(group) }
                            </ClickAwayListener>
                        </Popper>
                    </div>
                )
            })
        )
    }
}
