import { observable, runInAction, computed, action, toJS } from 'mobx'
import { t } from 'i18next'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import {
    APP_STORE, PRICE_TAG_FORMAT_SETTINGS_STORE, APP_BAR_STORE
} from '../stores'
import { DIALOG, ALERT } from '../../../components/simple-dialog/simple-dialog'
import { PRICE_TAGS,  PRICE_TAG_FORMATS, NEW } from '../../core/app-routes'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { formatsTopologyPresenterLocal } from '../../../protocol/set10/formats-topology-presenter-local'
import {
    FormatsTopologyVO, createFormatsTopologyVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/formats-topology-vo'
import { LEFT_ARROW, AppBarStore } from '../app-bar-store'
import { Cancelable, debounce, isNil } from 'lodash'
import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { getUniqueNameWithNumberPostfix } from '../../../utils/name-util'
import { NodeNotFoundInTopologyError } from '../../components/topology-filter/topology-filter-util'

// Индекс вкладки "Матрица форматов" на Centrum
export const FORMAT_MATRIX_TAB_INDEX_CENTRUM: number = 3
// Индекс вкладки "Матрица форматов" на Centrum
export const FORMAT_MATRIX_TAB_INDEX_RETAIL: number = 2

export const RETAIL_TOPOLOGY_ID: number = -1

export class PriceTagFormatSettingsStore {
    @observable
    formatTopology: FormatsTopologyVO = null

    @observable
    allFormatsTopology: FormatsTopologyVO[] = []

    // Топология считается новой, до первого добавления дочернего элемента, или до нажатия кнопки "сохранить"
    @observable
    newTopology: boolean = true

    // Используется в случае, если написали дублирующее имя, чтобы вернуть прошлое сохраненное значение
    @observable
    lastValidName: string = ''

    @observable
    lastUsedTabIndex: number = 0

    // Проверяем, что имя не пустое и не дублирует уже созданную топологию
    @computed
    get validName(): boolean {
        if (!this.formatTopology) return false
        const {name, id} = this.formatTopology

        // Проверяем остальные топологии на дубликаты
        const noDuplicates = this.allFormatsTopology.every(item => {
            if (item.id === id) return true
            return item.name !== name
        })

        // Также проверяем, что строка не пустая
        const notEmpty = name.trim() !== ''

        return noDuplicates && notEmpty
    }

    @computed
    get notValidNameErrorText(): string {
        if (!this.formatTopology) return ''
        if (this.validName) return ''

        if (this.formatTopology.name.trim() === '') {
            return t('components.requiredField')
        }
        return t('priceTagsFormats.formatSettings.alreadyUsed')
    }

    saveFormatDebounce: (() => void) & Cancelable = debounce(() => {
        if (!this.formatTopology) return
        this.saveFormatTopology()
    }, AUTOSAVE_DELAY)

    private appStore: AppStore = getStore(APP_STORE)

    fetchAllTopologyData = async (): Promise<void> => {
        const allFormatsTopology = await formatsTopologyPresenterLocal.loadAll()
        runInAction(() => {
            this.allFormatsTopology = allFormatsTopology
        })
    }

    @action
    editFormatTopology = async (id: number): Promise<void> => {
        if (isNaN(id)) {
            this.handleFormatTopologyFetchError()
            return Promise.resolve()
        }

        this.newTopology = false

        // Если в списке топологий нет топологии с заданным id - запрашиваем список топологий
        if (!this.allFormatsTopology || !this.allFormatsTopology.find(item => item.id === id)) {
            await this.fetchAllTopologyData()
        }

        runInAction(() => {
            const formatTopology = this.allFormatsTopology.find(item => item.id === id)

            if (formatTopology) {
                this.formatTopology = formatTopology
                this.lastValidName = formatTopology.name
            } else {
                this.handleFormatTopologyFetchError()
            }
        })
    }

    handleFormatTopologyFetchError = (): void => {
        this.appStore.showSnackbar({message: t('priceTagsFormats.formatSettings.fetchError')})
        this.goBack()
    }

    @action
    addFormatTopology = async (): Promise<void> => {
        this.newTopology = true

        await this.fetchAllTopologyData()

        runInAction(() => {
            this.formatTopology = createFormatsTopologyVO({
                id: -1,
                topologyFilters: [],
                name: getUniqueNameWithNumberPostfix(
                    t('priceTagsFormats.formatSettings.newTopologyFormatName'),
                    toJS(this.allFormatsTopology),
                    'name'
                ),
                description: '',
            })
        })

        // При создании сразу отправляется на сервер, для получения id
        await this.saveFormatTopology(false)
    }

    @action
    editRetailTopology = async (): Promise<void> => {
        this.formatTopology = createFormatsTopologyVO({
            id: RETAIL_TOPOLOGY_ID,
        })
        this.newTopology = false
    }

    @action
    updateFormatTopology = (changes: Partial<FormatsTopologyVO>): void => {
        Object.keys(changes).forEach(key => {
            this.formatTopology[key] = changes[key]
        })

        // Сохраняем только в случае, если имя валидно и это не новая топология
        if (!this.newTopology) {
            if (this.validName) {
                this.saveFormatDebounce()
            } else {
                /*
                 * Если имя стало невалидным, отменяем сохранение
                 * Иначе возникнет ситуация, что имя не валидно, а нам показали снэкбар о сохранении
                 */
                this.saveFormatDebounce.cancel()
            }
        }
    }

    handleTopologyNodeNotFound = async (error: NodeNotFoundInTopologyError): Promise<void> => {
        this.appStore.showDialog({
            title: error.message,
            message: t('priceTagsFormats.formatSettings.nodeNotFoundErrorMessage', { id: error.nodeNotFoundForFilter.id }),
            mode: ALERT,
            okLabel: t('common.yes'),
            onOk: () => {
                const newFilters = this.formatTopology.topologyFilters.filter(filter => {
                    return filter.id !== error.nodeNotFoundForFilter.id
                })

                this.updateFormatTopology({ topologyFilters: newFilters })
            }
        })
    }

    @action
    newTopologyChanged = (): void => {
        this.newTopology = false
    }

    @action
    setLastValidName = (): void => {
        if (!this.formatTopology || this.lastValidName === '') return
        this.formatTopology.name = this.lastValidName
    }

    @action
    setLastUsedTabIndex = (index: number): void => {
        this.lastUsedTabIndex = index
    }

    saveNewFormatTopology = async (): Promise<void> => {
        await this.saveFormatTopology()
        runInAction(() => {
            this.newTopology = false
            goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}/${this.formatTopology.id}`)
        })
    }

    saveFormatTopology = async (showSnackbar: boolean = true): Promise<void> => {
        const formatTopology = await formatsTopologyPresenterLocal.save(toJS(this.formatTopology))

        // После сохранения актуализируем последнее корректное значение
        runInAction(() => {
            this.formatTopology = formatTopology
            this.lastValidName = formatTopology.name
        })

        if (showSnackbar) {
            this.appStore.showSnackbar({
                message: t('priceTagsFormats.formatSettings.formatSaved')
            })
        }
    }

    // Удалять топологию необходимо, если мы создаем новую, но не сохранили изменений
    removeUnsavedNewFormatTopology = async (): Promise<void> => {
        if (this.newTopology && this.formatTopology) {
            await formatsTopologyPresenterLocal.delete(toJS(this.formatTopology))
        }
    }

    showBackDialog = (): void => {
        // Диалог показываем только при создании новой топологии форматов (при редактировании все автосохраняется)
        if (this.newTopology) {
            this.appStore.showDialog({
                title: t('priceTagsFormats.formatSettings.backDialogTitle'),
                message: t('priceTagsFormats.formatSettings.backDialogMessage'),
                mode: DIALOG,
                onYes: this.goBack
            })
        } else {
            this.goBack()
        }
    }

    goBack = (): void => {
        goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}`)
    }

    @action
    reset = (): void => {
        // Удаляем несохраненную новую топологию
        this.removeUnsavedNewFormatTopology()

        // Отменяем сохранение, если было
        this.saveFormatDebounce.cancel()

        this.formatTopology = null
        this.allFormatsTopology = []
        this.newTopology = false
        this.lastValidName = ''
        this.lastUsedTabIndex = 0
    }
}

export const PRICE_TAG_FORMAT_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${PRICE_TAGS}${PRICE_TAG_FORMATS}/-?\\w+$`),
    onEnter: (newRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const priceTagFormatSettingsStore: PriceTagFormatSettingsStore = getStore(PRICE_TAG_FORMAT_SETTINGS_STORE)

        const matchNewRoute = newRoute.match(PRICE_TAG_FORMAT_SETTINGS_ROUTING_HANDLER.routeMatcher)
        const formatTopologyId = matchNewRoute[1]

        appBarStore.updateState({
            title: formatTopologyId === NEW
                ? t('priceTagsFormats.formatSettings.newPageTitle')
                : t('priceTagsFormats.formatSettings.pageTitle'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: priceTagFormatSettingsStore.showBackDialog
        })
    },
    onLeave: (newRoute: string) => {
        const priceTagFormatSettingsStore: PriceTagFormatSettingsStore = getStore(PRICE_TAG_FORMAT_SETTINGS_STORE)
        const { formatTopology } = priceTagFormatSettingsStore
        const formatTopologyId = formatTopology ? formatTopology.id : undefined

        const formatSettingsRegExp = new RegExp(`^${PRICE_TAGS}${PRICE_TAG_FORMATS}/([\\w-]+)`)

        // Экран редактирования матрицы форматов - на вложенном роуте, если переходим на него - не нужно вызывать reset
        if (isNil(formatTopologyId) || !formatSettingsRegExp.test(newRoute)) {
            priceTagFormatSettingsStore.reset()
        }
    }
}
