import * as React from 'react'
import {iProductsManagerLocal} from '../../../protocol/set10/i-products-manager-local'
import {SimpleProductsGroup} from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-products-group'

import { AutocompleteInput, AutocompleteInputProps } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import {t} from 'i18next'
import classNames from 'classnames'

const styles = require('./autocomplete.scss')

export interface ProductGroupsAutocompleteProps
    extends Omit<AutocompleteInputProps<SimpleProductsGroup>, 'fetchDataCallback' | 'keyField' | 'labelFunction'> {
    sessionId: string
    /**
     * Указанные айдишники не будут отображаться в найденных элементах
     */
    excludedItemsCodes?: string[]
    /**
     * К указанным элементам будет применен дополнительный стиль
     */
    markedItemsCodes?: string[]
    markedClassName?: string
}

export class ProductGroupsAutocomplete extends React.Component<ProductGroupsAutocompleteProps> {
    render() {
        const {sessionId, ref, excludedItemsCodes, markedClassName, markedItemsCodes, ...props} = this.props

        return (
            <AutocompleteInput<SimpleProductsGroup>
                {...props}
                placeholder={t('components.autocomplete.productsGroupPlaceholder')}
                fetchDataCallback={async (namePart: string) => {
                    const result = await iProductsManagerLocal.getProductGroups(sessionId, namePart)

                    if (!excludedItemsCodes) return result

                    // Не включаем из списка исключенных
                    return result.filter(item => {
                        return !excludedItemsCodes.some(code => code === item.code)
                    })
                }}
                menuItemRenderer={item => {
                    return (
                        <div className={classNames({
                            [markedClassName || styles.selectedAction]: markedItemsCodes && markedItemsCodes.some(
                                code => code === item.code
                            )
                        })}>
                            {item.name}
                        </div>
                    )
                }}
                keyField="code"
                labelField="name"
            />
        )
    }
}
