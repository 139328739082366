export type BonusDiscountType = 'CRM' | 'CFT' | 'BONUS_CFT' | 'PT' | 'BONUS_SR10' | 'BONUS_INFORMIX' | 'BONUS_ROSBANK' | 'BONUS_LOYMAX' | 'BONUS_KOPILKA' | 'BONUS_SIEBEL' | 'TOKEN_SIEBEL' | 'BONUS_AEROFLOT_CFT' | 'SET_API' | 'TOKENS_SR10' | 'TOKENS_LENTA' | 'TOKENS_SET5' | 'TOKENS_SET_API'

export const CRM: BonusDiscountType = 'CRM'
export const CFT: BonusDiscountType = 'CFT'
export const BONUS_CFT: BonusDiscountType = 'BONUS_CFT'
export const PT: BonusDiscountType = 'PT'
export const BONUS_SR10: BonusDiscountType = 'BONUS_SR10'
export const BONUS_INFORMIX: BonusDiscountType = 'BONUS_INFORMIX'
export const BONUS_ROSBANK: BonusDiscountType = 'BONUS_ROSBANK'
export const BONUS_LOYMAX: BonusDiscountType = 'BONUS_LOYMAX'
export const BONUS_KOPILKA: BonusDiscountType = 'BONUS_KOPILKA'
export const BONUS_SIEBEL: BonusDiscountType = 'BONUS_SIEBEL'
export const TOKEN_SIEBEL: BonusDiscountType = 'TOKEN_SIEBEL'
export const BONUS_AEROFLOT_CFT: BonusDiscountType = 'BONUS_AEROFLOT_CFT'
export const SET_API: BonusDiscountType = 'SET_API'
export const TOKENS_SR10: BonusDiscountType = 'TOKENS_SR10'
export const TOKENS_LENTA: BonusDiscountType = 'TOKENS_LENTA'
export const TOKENS_SET5: BonusDiscountType = 'TOKENS_SET5'
export const TOKENS_SET_API: BonusDiscountType = 'TOKENS_SET_API'
