import { createBaseVO } from './base-vo'
import { BaseVO } from './base-vo'
import { CardTypeVO } from './card-type-vo'

export interface CardRangeVO extends BaseVO {
    '@class': 'ru.crystals.cards.common.CardRangeVO'
    guid?: number
    deleted?: boolean
    virtual?: boolean
    start?: string
    finish?: string
    count?: string
    cardType?: CardTypeVO
}

export const createCardRangeVO = (initialization: Partial<CardRangeVO>): CardRangeVO => {
    if (!initialization) return null
    
    let result: CardRangeVO = {
        ...createBaseVO(initialization),
        guid: initialization.guid,
        deleted: initialization.deleted,
        virtual: initialization.virtual,
        start: initialization.start,
        finish: initialization.finish,
        count: initialization.count,
        cardType: initialization.cardType,
        '@class': 'ru.crystals.cards.common.CardRangeVO',
    }
    
    return result
}
