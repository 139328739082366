import { FormatVO } from './format-vo'
import { RegionVO } from './region-vo'

export interface ShopsRegionGroup {
    '@class': 'ru.crystals.discounts.ShopsRegionGroup'
    id?: number
    roundTheClock?: boolean
    formats?: FormatVO[]
    regions?: RegionVO[]
}

export const createShopsRegionGroup = (initialization: Partial<ShopsRegionGroup>): ShopsRegionGroup => {
    if (!initialization) return null
    
    let result: ShopsRegionGroup = {
        id: initialization.id,
        roundTheClock: initialization.roundTheClock,
        formats: initialization.formats,
        regions: initialization.regions,
        '@class': 'ru.crystals.discounts.ShopsRegionGroup',
    }
    
    return result
}
