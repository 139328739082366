import { createBaseLimitVO } from './base-limit-vo'
import { BaseLimitVO } from './base-limit-vo'
import { RegionVO } from './region-vo'
import { CityVO } from './city-vo'
import { ShopVO } from './shop-vo'

export interface AlcoholLimitVO extends BaseLimitVO {
    '@class': 'ru.crystals.alcohol.ejb.AlcoholLimitVO'
    guid?: string
    name?: string
    deleted?: boolean
    createDate?: Date
    updateDate?: Date
    minPercent?: number
    timeFromStr?: string
    timeToStr?: string
    timeFrom?: Date
    timeTo?: Date
    dateFrom?: Date
    dateTo?: Date
    dateFromStr?: string
    dateToStr?: string
    dayOfWeek?: number
    fromApperPoint?: boolean
    minLiterPrice?: number
    forAllStores?: boolean
    regions?: RegionVO[]
    cities?: CityVO[]
    shops?: ShopVO[]
}

export const createAlcoholLimitVO = (initialization: Partial<AlcoholLimitVO>): AlcoholLimitVO => {
    if (!initialization) return null
    
    let result: AlcoholLimitVO = {
        ...createBaseLimitVO(initialization),
        guid: initialization.guid,
        name: initialization.name,
        deleted: initialization.deleted,
        createDate: initialization.createDate,
        updateDate: initialization.updateDate,
        minPercent: initialization.minPercent,
        timeFromStr: initialization.timeFromStr,
        timeToStr: initialization.timeToStr,
        timeFrom: initialization.timeFrom,
        timeTo: initialization.timeTo,
        dateFrom: initialization.dateFrom,
        dateTo: initialization.dateTo,
        dateFromStr: initialization.dateFromStr,
        dateToStr: initialization.dateToStr,
        dayOfWeek: initialization.dayOfWeek,
        fromApperPoint: initialization.fromApperPoint,
        minLiterPrice: initialization.minLiterPrice,
        forAllStores: initialization.forAllStores,
        regions: initialization.regions,
        cities: initialization.cities,
        shops: initialization.shops,
        '@class': 'ru.crystals.alcohol.ejb.AlcoholLimitVO',
    }
    
    return result
}
