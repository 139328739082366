import { PriceTypeVO } from './price-type-vo'

export interface DatedPriceVO_SF {
    '@class': 'ru.crystals.setretailx.products.vo.DatedPriceVO_SF'
    id?: number
    value?: string
    oldPrice?: string
    dateStart?: Date
    dateEnd?: Date
    depart?: string
    packCount?: number
    discountIdentifier?: string
    priceType?: PriceTypeVO
}

export const createDatedPriceVO_SF = (initialization: Partial<DatedPriceVO_SF>): DatedPriceVO_SF => {
    if (!initialization) return null
    
    let result: DatedPriceVO_SF = {
        id: initialization.id,
        value: initialization.value,
        oldPrice: initialization.oldPrice,
        dateStart: initialization.dateStart,
        dateEnd: initialization.dateEnd,
        depart: initialization.depart,
        packCount: initialization.packCount,
        discountIdentifier: initialization.discountIdentifier,
        priceType: initialization.priceType,
        '@class': 'ru.crystals.setretailx.products.vo.DatedPriceVO_SF',
    }
    
    return result
}
