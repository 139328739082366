import { UserVO } from './set-retail10-commons/data-structs-module/user-vo'
import { RoleVO } from './set-retail10-commons/data-structs-module/role-vo'
import { PartialList } from './set-retail10-commons/data-structs-module/partial-list'
import { XMLPairVO } from './set-retail10-server/retailx/server-ds/xml-pair-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class CashiersManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Cashiers/SET/CashierManager!ru.crystals.setretailx.cashiers.CashiersManagerLocal'
    }

    addCashier = (sessionId: string, cashier: UserVO, options?: RequestOptions): Promise<UserVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'addCashier',
                params: [sessionId, cashier]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    addRole = (sessionId: string, cashierRole: RoleVO, options?: RequestOptions): Promise<RoleVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'addRole',
                params: [sessionId, cashierRole]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    checkBarCode = (sessionId: string, barcode: string, userId: number, shop: number, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'checkBarCode',
                params: [sessionId, barcode, userId, shop]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    checkMagneticCard = (sessionId: string, magneticCard: string, userId: number, shop: number, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'checkMagneticCard',
                params: [sessionId, magneticCard, userId, shop]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    checkMagneticKey = (sessionId: string, magneticKey: string, userId: number, shop: number, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'checkMagneticKey',
                params: [sessionId, magneticKey, userId, shop]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    checkPassword = (sessionId: string, password: string, userId: number, shop: number, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'checkPassword',
                params: [sessionId, password, userId, shop]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    checkSet10Card = (sessionId: string, set10card: string, userId: number, shop: number, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'checkSet10Card',
                params: [sessionId, set10card, userId, shop]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    checkTabNumber = (sessionId: string, tabNumber: string, userId: number, shop: number, options?: RequestOptions): Promise<boolean> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'checkTabNumber',
                params: [sessionId, tabNumber, userId, shop]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    findCashiers = (value: string, options?: RequestOptions): Promise<UserVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findCashiers',
                params: [value]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    findCashiersByValueAndShop = (value: string, shopNumber: number, options?: RequestOptions): Promise<UserVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findCashiersByValueAndShop',
                params: [value, shopNumber]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    generatePasswordAndBarcode = (tabNum: string, options?: RequestOptions): Promise<UserVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'generatePasswordAndBarcode',
                params: [tabNum]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getCashierList = (sessionId: string, namePart: string, isBlocked: boolean, roleId: number, shopNumber: number[], page: number, isEmptyPassword: boolean, options?: RequestOptions): Promise<PartialList<UserVO>> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCashierList',
                params: [sessionId, namePart, isBlocked, roleId, shopNumber, page, isEmptyPassword]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getCashiersCountByRoleId = (sessionId: string, roleId: number, options?: RequestOptions): Promise<string> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCashiersCountByRoleId',
                params: [sessionId, roleId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getPrivilegeSections = (sessionId: string, options?: RequestOptions): Promise<XMLPairVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getPrivilegeSections',
                params: [sessionId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getPrivileges = (sessionId: string, sectionName: string, options?: RequestOptions): Promise<XMLPairVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getPrivileges',
                params: [sessionId, sectionName]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getRole = (sessionId: string, roleId: number, options?: RequestOptions): Promise<RoleVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getRole',
                params: [sessionId, roleId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getRoles = (sessionId: string, options?: RequestOptions): Promise<RoleVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getRoles',
                params: [sessionId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

}

export const cashiersManagerLocal: CashiersManagerLocal = new CashiersManagerLocal()
