import React, { Component } from 'react'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { t } from 'i18next'
import Grid from '@material-ui/core/Grid'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { INT, NUMBER, NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import {
    getPrintMessage,
    getTape,
    getTapeWidth,
    getTapeWidthOptions
} from '../cheque-advertising-print-settings-modal/cheque-advertising-print-settings-modal'
import { isNil } from 'lodash'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { AutocompleteInput } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { CouponInfoVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/coupon-info-vo'
import { uniqueCouponsService } from '../../../../protocol/set10/unique-coupons-service'
import Tooltip from '@material-ui/core/Tooltip'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { Tape } from '../../../core/advertising-actions/advertising-actions'

const styles = require('./coupon-print-settings-modal.scss')

export enum BarcodeSettingsFormat {
    Discount= 'DISCOUNT',
    BarcodeNumber = 'BARCODE_NUMBER',
    NonSerialCoupon = 'NON_SERIAL_COUPON',
}

export enum DiscountType {
    Percent = 'PERCENT',
    FixSum = 'FIXSUMM',
    Calculate = 'CALCULATE',
}

export enum Delay {
    Now = 'NOW',
    Delay = 'DELAY',
}

export enum Period {
    Minutes = 'MINUTES',
    Hours = 'HOURS',
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS',
    Years = 'YEARS',
    Now = 'NOW'
}

export interface CouponPrintSettingsModalProps {
    open: boolean
    tapeWidthProp: number
    settings: CouponPrintSettingsModalState
    onSettingsChange: (value: CouponPrintSettingsModalState) => void
    handleClose: () => void
    handleSetTapeWidth: (value: number) => void
}

export interface CouponPrintSettingsModalState {
    isSetupManually: boolean
    printSlipInsideReceipt: string
    tapeWidth: Tape
    priority: string
    barcodeSettingsFormat: BarcodeSettingsFormat
    requiredClientCard: string
    onlyOneTime: string
    maxDiscount: string
    discountType: DiscountType
    discountValue: string
    delay: Delay
    startsAfterPeriodType: Period
    startsAfter: string,
    worksPeriodType: Period
    worksPeriod: string
    barCode: string
    couponTypeGuid: string
    isValidCouponTypeGuid: boolean
    isValidBarCode: boolean
    isValidDiscountValue: boolean
    isValidStartsAfter: boolean
    isValidWorksPeriod: boolean
    couponAutocompleteName: string
}

const numberOnlyReg = /^\d+$/

export class CouponPrintSettingsModal extends Component<CouponPrintSettingsModalProps, CouponPrintSettingsModalState> {

    mounted: boolean = false
    constructor(props: CouponPrintSettingsModalProps) {
        super(props)
        const { settings, tapeWidthProp } = this.props
        const { printSlipInsideReceipt, priority, requiredClientCard, onlyOneTime, maxDiscount, discountType, discountValue,
                startsAfterPeriodType, startsAfter, worksPeriodType, worksPeriod, barCode, couponTypeGuid } = settings

        this.state = {
            isSetupManually: getTape(tapeWidthProp) === Tape.TapeManual,
            printSlipInsideReceipt: printSlipInsideReceipt && printSlipInsideReceipt === 'true' ? 'true' : 'false',
            tapeWidth: tapeWidthProp ? getTape(tapeWidthProp) : Tape.Tape80,
            priority: priority ? priority : null,
            barcodeSettingsFormat: getBarcodeSettingsFormat(settings),
            requiredClientCard: requiredClientCard && requiredClientCard === 'true' ? 'true' : 'false',
            onlyOneTime: onlyOneTime && onlyOneTime === 'true' ? 'true' : 'false',
            maxDiscount: maxDiscount ? maxDiscount : '0',
            discountType: discountType ? discountType : DiscountType.Percent,
            discountValue: discountValue ? discountValue : null,
            delay: startsAfterPeriodType === Period.Now ? Delay.Now : Delay.Delay,
            startsAfterPeriodType: startsAfterPeriodType ? startsAfterPeriodType : Period.Days,
            startsAfter: startsAfter ? startsAfter : null,
            worksPeriodType: worksPeriodType ? worksPeriodType : Period.Days,
            worksPeriod: worksPeriod ? worksPeriod : null,
            barCode: barCode ? barCode : '',
            couponTypeGuid: couponTypeGuid ? couponTypeGuid : '',

            isValidCouponTypeGuid: couponTypeGuid && couponTypeGuid !== '',
            isValidBarCode: barCode && barCode !== '',
            isValidDiscountValue: !!discountValue,
            isValidStartsAfter: !!startsAfter,
            isValidWorksPeriod: !!worksPeriod,

            couponAutocompleteName: '',
        }
    }

    componentDidMount() {
        this.mounted = true

        const couponGuid = this.props.settings?.couponTypeGuid
        if (couponGuid && !isNaN(Number(couponGuid))) {
            this.fetchCouponName(Number(couponGuid))
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    fetchCouponName = async (guid: number): Promise<void> => {
        const coupon = await uniqueCouponsService.getByGuid(guid)
        if (this.mounted) {
            this.setState({
                couponAutocompleteName: coupon.name
            })
        }
    }

    validateCouponPrintSettings = () => {
        const { barcodeSettingsFormat, couponTypeGuid, barCode, discountValue, startsAfter, worksPeriod,
                startsAfterPeriodType, discountType } = this.state

        switch (barcodeSettingsFormat) {
            case BarcodeSettingsFormat.NonSerialCoupon:
                this.setState({ isValidCouponTypeGuid: !(couponTypeGuid === '' || isNil(couponTypeGuid)) })
                return !(couponTypeGuid === '' || isNil(couponTypeGuid))
            case BarcodeSettingsFormat.BarcodeNumber:
                this.setState({ isValidBarCode: barCode !== '' })
                return barCode !== ''
            case BarcodeSettingsFormat.Discount:
                const isValidDiscountValue = !(isNil(discountValue) || discountValue === '') || discountType === DiscountType.Calculate
                const isValidStartsAfter = startsAfterPeriodType === Period.Now || !(isNil(startsAfter) || startsAfter === '')
                const isValidWorksPeriod = !(isNil(worksPeriod) || worksPeriod === '')
                this.setState({
                    isValidDiscountValue,
                    isValidStartsAfter,
                    isValidWorksPeriod
                })
                return isValidDiscountValue && isValidStartsAfter && isValidWorksPeriod
            default:
                return false
        }
    }

    render() {
        const {
            isSetupManually, printSlipInsideReceipt, tapeWidth, priority, barcodeSettingsFormat, requiredClientCard,
            onlyOneTime, maxDiscount, discountType, discountValue, delay, startsAfterPeriodType, startsAfter,
            worksPeriodType, worksPeriod, barCode, couponTypeGuid, isValidCouponTypeGuid, isValidBarCode,
            isValidDiscountValue, isValidStartsAfter, isValidWorksPeriod, couponAutocompleteName,
        } = this.state
        const { open, tapeWidthProp, handleClose, onSettingsChange, handleSetTapeWidth } = this.props

        return (
            <AdaptiveDialog
                open={open}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle className={styles.dialogTitle}>
                    {t('actionResults.baseTextEditor.settings')}
                </DialogTitle>
                <DialogContent className={styles.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <SelectInput
                                options={getTapeWidthOptions()}
                                label={t('actionResults.baseTextEditor.tapeWidth')}
                                value={tapeWidth}
                                onSelect={option => {
                                    this.setState({ tapeWidth: option.value })
                                    if (option.value !== Tape.TapeManual) {
                                        handleSetTapeWidth(getTapeWidth(option.value))
                                        this.setState({ isSetupManually: false })
                                    } else {
                                        this.setState({ isSetupManually: true })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <NumberInput
                                label={t('actionResults.baseTextEditor.specifyWidth')}
                                disabled={!isSetupManually}
                                value={tapeWidthProp}
                                onValueChange={value => handleSetTapeWidth(value)}
                                valuePostfix={tapeWidthProp ? t('actionResults.baseTextEditor.millimeters') : ''}
                                min={37}
                                max={80}
                                dataType={INT}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <SelectInput
                                options={getPrintMessage()}
                                label={t('actionResults.baseTextEditor.printMessage')}
                                value={printSlipInsideReceipt}
                                onSelect={option => this.setState({ printSlipInsideReceipt: option.value })}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <NumberInput
                                label={t('actionResults.coupon.couponPriority')}
                                value={isNil(priority) || priority === '' ? null : +priority}
                                onValueChange={ value => this.setState({ priority: !isNil(value) ? `${value}` : '' })}
                                canBeNull
                                dataType={INT}
                                min={1}
                                max={9999}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogTitle className={styles.dialogTitle}>
                    {t('actionResults.coupon.barcode')}
                </DialogTitle>

                <DialogContent className={styles.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <SelectInput
                                options={getBarcodeSettings()}
                                label={t('actionResults.coupon.barcodeSettings')}
                                value={barcodeSettingsFormat}
                                onSelect={option => {
                                    this.setState({ barcodeSettingsFormat: option.value })
                                }}
                            />
                        </Grid>

                        {barcodeSettingsFormat === BarcodeSettingsFormat.Discount && <>
                            <Grid item sm={12} style={{ padding: '0 8px' }}>
                                <Checkbox
                                    color="primary"
                                    label={t('actionResults.coupon.requiredClientCard')}
                                    checked={requiredClientCard === 'true'}
                                    onValueChange={value => this.setState({ requiredClientCard: `${value}` })}
                                />
                            </Grid>

                            <Grid item sm={12} style={{ padding: '0 8px' }}>
                                <Checkbox
                                    color="primary"
                                    label={t('actionResults.coupon.onlyOneTime')}
                                    checked={onlyOneTime === 'true'}
                                    onValueChange={value => this.setState({ onlyOneTime: `${value}` })}
                                />
                            </Grid>

                            <Grid item sm={4} style={{ margin: 'auto' }}>
                                {t('actionResults.coupon.discount')}
                            </Grid>
                            <Grid item sm={3}>
                                <NumberInput
                                    disabled={discountType === DiscountType.Calculate}
                                    value={isNil(discountValue) || discountValue === '' ? null : +discountValue / 100}
                                    rounding={2}
                                    dataType={NUMBER}
                                    max={discountType === DiscountType.Percent ? 100 : null}
                                    onValueChange={value => this.setState({
                                        discountValue: !isNil(value) ? `${Math.round(value * 100)}` : '',
                                        isValidDiscountValue: value !== null
                                    })}
                                    canBeNull
                                    valuePostfix={discountType === DiscountType.Percent ? '%' : ''}
                                    error={!isValidDiscountValue}
                                />
                            </Grid>
                            <Grid item sm={5}>
                                <SelectInput
                                    options={
                                        [
                                            {label: t('actionResults.coupon.percent'), value: DiscountType.Percent},
                                            {label: t('actionResults.coupon.fixSum'), value: DiscountType.FixSum},
                                            {label: t('actionResults.coupon.calculate'), value: DiscountType.Calculate},
                                        ]
                                    }
                                    value={discountType}
                                    onSelect={option => {
                                        let newDiscountValue = discountValue

                                        switch (option.value) {
                                            case DiscountType.Calculate:
                                                newDiscountValue = null
                                                break
                                            case DiscountType.Percent:
                                                const oneHundredPercent = 10000
                                                newDiscountValue = +newDiscountValue > oneHundredPercent ? `${oneHundredPercent}` : newDiscountValue
                                                break
                                        }

                                        this.setState({
                                            discountType: option.value,
                                            discountValue: newDiscountValue
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item sm={4} style={{ margin: 'auto' }}>
                                {t('actionResults.coupon.discountSize')}
                            </Grid>
                            <Grid item sm={8}>
                                <NumberInput
                                    rounding={2}
                                    dataType={NUMBER}
                                    value={isNil(maxDiscount) || maxDiscount === '' ? 0 : +maxDiscount / 100}
                                    onValueChange={value => this.setState({
                                        maxDiscount: !isNil(value) ? `${Math.round(value * 100)}` : '0'
                                    })}
                                />
                            </Grid>

                            <Grid item sm={4} style={{ margin: 'auto' }}>
                                {t('actionResults.coupon.validAfter')}
                            </Grid>
                            <Grid item sm={8}>
                                <SelectInput
                                    options={
                                        [
                                            {label: t('actionResults.coupon.delay'), value: Delay.Delay},
                                            {label: t('actionResults.coupon.now'), value: Delay.Now},
                                        ]
                                    }
                                    value={delay}
                                    onSelect={option => {
                                        this.setState({ delay: option.value })
                                        if (option.value === Delay.Now) {
                                            this.setState({
                                                startsAfterPeriodType: Period.Now,
                                                startsAfter: null
                                            })
                                        } else {
                                            this.setState({ startsAfterPeriodType: Period.Days })
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item sm={4} />
                            <Grid item sm={3}>
                                <NumberInput
                                    disabled={delay === Delay.Now}
                                    value={isNil(startsAfter) || startsAfter === '' ? null : +startsAfter}
                                    onValueChange={value => this.setState({
                                        startsAfter: !isNil(value) ? `${value}` : '',
                                        isValidStartsAfter: value !== null
                                    })}
                                    canBeNull
                                    error={!isValidStartsAfter}
                                />
                            </Grid>
                            <Grid item sm={5}>
                                <SelectInput
                                    disabled={delay === Delay.Now}
                                    options={getPeriodType(+startsAfter)}
                                    value={startsAfterPeriodType}
                                    onSelect={option => this.setState({
                                        startsAfterPeriodType: option.value,
                                    })}
                                />
                            </Grid>

                            <Grid item sm={4} style={{ margin: 'auto' }}>
                                {t('actionResults.coupon.throughout')}
                            </Grid>
                            <Grid item sm={3}>
                                <NumberInput
                                    value={isNil(worksPeriod) || worksPeriod === '' ? null : +worksPeriod}
                                    onValueChange={value => this.setState({
                                        worksPeriod: !isNil(value) ? `${value}` : '',
                                        isValidWorksPeriod: value !== null
                                    })}
                                    canBeNull
                                    error={!isValidWorksPeriod}
                                />
                            </Grid>
                            <Grid item sm={5}>
                                <SelectInput
                                    options={getPeriodType(+worksPeriod)}
                                    value={worksPeriodType}
                                    onSelect={option => this.setState({
                                        worksPeriodType: option.value
                                    })}
                                />
                            </Grid>
                        </>}

                        {barcodeSettingsFormat === BarcodeSettingsFormat.BarcodeNumber &&
                            <Grid item sm={12}>
                                <TextField
                                    label={t('actionResults.coupon.selectBarcodeNumber')}
                                    value={barCode || ''}
                                    onValueChange={value => {
                                        if (numberOnlyReg.test(value) || !value) {
                                            this.setState({
                                                barCode: value || '',
                                                isValidBarCode: value !== null
                                            })
                                        }
                                    }}
                                    error={!isValidBarCode}
                                    maxLength={21}
                                />
                            </Grid>
                        }

                        {barcodeSettingsFormat === BarcodeSettingsFormat.NonSerialCoupon &&
                            <Grid item sm={12}>
                                <AutocompleteInput
                                    onSuggestionSelect={(coupon: CouponInfoVO) => {
                                        this.setState({
                                            couponTypeGuid: coupon ? `${coupon.guid}` : null,
                                            couponAutocompleteName: coupon?.name || '',
                                            isValidCouponTypeGuid: !!coupon
                                        })
                                    }}
                                    label={t('actionResults.coupon.couponWillBePrinted')}
                                    shownValue={couponAutocompleteName || ''}
                                    error={!isValidCouponTypeGuid}
                                    showClearButton
                                    placeholder={t('actionResults.coupon.selectNewCategory')}
                                    fetchDataCallback={mask => uniqueCouponsService.findUniqueCouponsHavingNamesLike(mask, 20)}
                                    PaperProps={{
                                        className: styles.autocomplete
                                    }}
                                    menuItemRenderer={(suggestion: CouponInfoVO) => (
                                        <Tooltip
                                            id={`coupon-category-${suggestion.guid}`}
                                            title={suggestion.name}
                                            enterDelay={TOOLTIP_DELAY}
                                        >
                                            <div>{suggestion.name}</div>
                                        </Tooltip>
                                    )}
                                    keyField="guid"
                                    labelField="name"
                                />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleClose}
                    >
                        {t('common.undo')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (this.validateCouponPrintSettings()) {
                                // В зависимости от выбранного формата настройки необходимо отправлять в результат
                                // уникальные для этого формата параметры настройки
                                const necessaryState = getNecessaryStateParams(this.state)

                                onSettingsChange({
                                    priority,
                                    printSlipInsideReceipt,
                                    requiredClientCard,
                                    onlyOneTime,
                                    maxDiscount,
                                    discountType,
                                    startsAfterPeriodType,
                                    worksPeriodType,
                                    barCode,
                                    ...necessaryState
                                })
                                handleClose()
                            }
                        }}
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}

const getPeriodType = (count: number) => {
    return [
        {
            label: count
                ? t('actionResults.coupon.timeInterval.minutes', { count })
                : t('actionResults.coupon.timeInterval.minutes'),
            value: Period.Minutes
        },
        {
            label: count
                ? t('actionResults.coupon.timeInterval.hours', { count })
                : t('actionResults.coupon.timeInterval.hours'),
            value: Period.Hours
        },
        {
            label: count
                ? t('actionResults.coupon.timeInterval.days', { count })
                : t('actionResults.coupon.timeInterval.days'),
            value: Period.Days
        },
        {
            label: count
                ? t('actionResults.coupon.timeInterval.weeks', { count })
                : t('actionResults.coupon.timeInterval.weeks'),
            value: Period.Weeks
        },
        {
            label: count
                ? t('actionResults.coupon.timeInterval.months', { count })
                : t('actionResults.coupon.timeInterval.months'),
            value: Period.Months
        },
        {
            label: count
                ? t('actionResults.coupon.timeInterval.years', { count })
                : t('actionResults.coupon.timeInterval.years'),
            value: Period.Years
        },
    ]
}

const getBarcodeSettings = () => {
    return [
        {
            label: t('actionResults.coupon.discount'),
            value: BarcodeSettingsFormat.Discount,
        },
        {
            label: t('actionResults.coupon.barcodeNumber'),
            value: BarcodeSettingsFormat.BarcodeNumber,
        },
        {
            label: t('actionResults.coupon.nonSerialCoupon'),
            value: BarcodeSettingsFormat.NonSerialCoupon,
        }
    ]
}

const getBarcodeSettingsFormat = (settings?: any): BarcodeSettingsFormat => {
    if (settings.couponTypeGuid && settings.couponTypeGuid !== '') return BarcodeSettingsFormat.NonSerialCoupon
    if (settings.barCode && settings.barCode !== '') return BarcodeSettingsFormat.BarcodeNumber
    return BarcodeSettingsFormat.Discount
}

const getNecessaryStateParams = (state: CouponPrintSettingsModalState): CouponPrintSettingsModalState => {
    const { barcodeSettingsFormat, couponTypeGuid, barCode, discountValue, startsAfter, worksPeriod } = state
    let necessaryState

    if (barcodeSettingsFormat === BarcodeSettingsFormat.NonSerialCoupon) {
        necessaryState = {
            couponTypeGuid,
            barCode: '',
            startsAfterPeriodType: Period.Days,
            requiredClientCard: 'false',
            onlyOneTime: 'false',
            maxDiscount: '0',
        }
    }

    if (barcodeSettingsFormat === BarcodeSettingsFormat.BarcodeNumber) {
        necessaryState = {
            barCode,
            startsAfterPeriodType: Period.Days,
            requiredClientCard: 'false',
            onlyOneTime: 'false',
            maxDiscount: '0',
        }
    }

    if (barcodeSettingsFormat === BarcodeSettingsFormat.Discount) {
        necessaryState = {
            discountValue,
            startsAfter,
            worksPeriod,
            barCode: '',
        }
    }

    return necessaryState
}
