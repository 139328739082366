import moment, { Moment } from 'moment'
import { DateTypes, DAY, HOUR, WEEK, MONTH, YEAR } from '../../../../../utils/date/date-format'

export interface TimelineDatePeriod {
    startDate?: Date
    endDate?: Date
    interval?: DateTypes
}

export const getStepIncrement = (interval: DateTypes): {count: number, interval: DateTypes} => {
    switch (interval) {
        case DAY:
            return {count: 2, interval: HOUR}
        case WEEK:
            return {count: 1, interval: DAY}
        case MONTH:
            return {count: 1, interval: DAY}
        case YEAR:
            return {count: 1, interval: MONTH}
        default:
            return {count: 2, interval: HOUR}
    }
}

export const getPeriodArray = (period: TimelineDatePeriod): Moment[] => {
    if (!period) return []

    const { startDate, endDate, interval, } = period

    let step = moment(startDate)
    const steps: Moment[] = []
    const {
        count,
        interval: showedInterval,
    } = getStepIncrement(interval)

    const momentEndDate = moment(endDate)

    while (step <= momentEndDate) {
        steps.push(step)
        step = step.clone().add(count, showedInterval)
    }

    return steps
}
