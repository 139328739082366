import { t } from 'i18next'
import { cloneDeep, get, isEmpty, isNil, isEqual } from 'lodash'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { iTopologyEditorLocal } from '../../../../protocol/set10/i-topology-editor-local'
import { iTopologyManagerLocal } from '../../../../protocol/set10/i-topology-manager-local'
import { iTopologySearcherLocal } from '../../../../protocol/set10/i-topology-searcher-local'
import { CityVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import { FormatVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import { JuristicPersonVO, createJuristicPersonVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/juristic-person-vo'
import { ShopVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { FormValidation } from '../../../../utils/form-validation/form-validation'
import { fieldLengthValidator } from '../../../../utils/form-validation/validators/length-validator'
import { regExpValidator } from '../../../../utils/form-validation/validators/regexp-validator'
import { requiredField } from '../../../../utils/form-validation/validators/required-field'
import { TopologyMap } from '../../../components/topology-filter/topology-map'
import {
    NOT_FOUND,
    SHOPS_SEARCH,
    TOPOLOGY,
    REGIONS,
    SHOP, SHOPS
} from '../../../core/app-routes'
import {SETRETAILX_WEIGHERTEMPLATE_ADMIN} from '../../../core/privileges/privileges'
import {
    createEmptyCity,
    createEmptyFormat,
    createEmptyRegion,
    createEmptyShop,
    getMinimalUnusedShopNumber
} from '../../../core/topology/topology-util'
import { NEW } from '../../../core/values'
import { goTo, RouteChangeHandler } from '../../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../../app-bar-store'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { StepProps } from '@material-ui/core/Step'
import { AppStore } from '../../app-store'
import { UserStore } from '../../user-store'
import { getStore } from '../../stores-repository'
import {
    APP_BAR_STORE,
    APP_STORE,
    DIALOG_STORE,
    SHOP_STORE,
    SNACKBAR_STORE,
    USER_STORE
} from '../../stores'
import { withSpinner } from '../../with-spinner'
import { SnackbarStore } from '../../snackbar-store'
import { DialogStore } from '../../dialog-store'
import { isValidURLOrIP } from '../../../utils/url-and-ip-validation'
import { SNACKBAR_EXTENDED_DURATION } from '../../../../utils/default-timeouts'

export const CHECK_CONNECTION_TO_CENTRUM_AWAIT_TIME = 10000

export type SHOPS_PAGE_STEP = 'INFORMATION_STEP' | 'JURISTIC_PERSONS_STEP' | 'CASHES_STEP' | 'SCALES_STEP' | 'PRINTERS_STEP' | 'PRICE_CHECKERS_STEP'

export const INFORMATION_STEP: SHOPS_PAGE_STEP = 'INFORMATION_STEP'
export const JURISTIC_PERSONS_STEP: SHOPS_PAGE_STEP = 'JURISTIC_PERSONS_STEP'
export const CASHES_STEP: SHOPS_PAGE_STEP = 'CASHES_STEP'
export const SCALES_STEP: SHOPS_PAGE_STEP = 'SCALES_STEP'
export const PRINTERS_STEP: SHOPS_PAGE_STEP = 'PRINTERS_STEP'
export const PRICE_CHECKERS_STEP: SHOPS_PAGE_STEP = 'PRICE_CHECKERS_STEP'

export interface ShopStep {
    name: SHOPS_PAGE_STEP,
    props: Partial<StepProps>
}

export class ShopStore {

    // Общие
    @observable
    centrumAddress: string = ''

    @observable
    newCentrumAddress: string = ''

    @observable
    newCentrumAddressIsValid: boolean = false

    @observable
    newCentrumAddressCanBeReached: boolean = false

    // TODO - в appStore теперь есть свойство connectedToCentrum. Необходимо использовать оттуда, из одного места
    @observable
    connectedToCentrum: boolean = false

    @observable
    editingConnectionToCentrum: boolean = false

    @observable
    shouldUseCentrumAddress: boolean = false

    @observable
    prevShouldUseCentrumAddress: boolean = false

    @observable
    showConnectionStatus: boolean = false

    @observable
    currentStepIndex: number = 0

    previousPageURL: string = ''

    // Информация о магазине
    @observable
    topologyMap: TopologyMap

    @observable
    shop: ShopVO

    @observable
    shopInfoValidation: FormValidation<ShopVO>

    // Форматы
    @observable
    formats: FormatVO[] = []

    @observable
    originalFormats: FormatVO[] = []

    @observable
    editingFormats: boolean = false

    @observable
    formatName: string = ''

    // Юр.лица
    @computed
    get juristicPersons(): JuristicPersonVO[] {
        if (!this.shop || !this.shop.juristicPersons) return []

        return this.shop.juristicPersons.filter(p => {
            // Строка необходима, чтобы отследить изменения в юр.лице по умолчанию
            String(p.defaultJP)
            return !p.deleted
        })
    }

    @observable
    selectedJuristicPerson: JuristicPersonVO

    @observable
    juristicPersonValidation: FormValidation<JuristicPersonVO>

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    @computed
    get steps(): ShopStep[] {
        return [
            {
                name: INFORMATION_STEP,
                props: { completed: this.informationStepIsValid && !this.shopInfoValidation.modified }
            },
            {
                name: JURISTIC_PERSONS_STEP,
                props: { disabled: !this.informationStepIsValid }
            },
            {
                name: CASHES_STEP,
                props: { disabled: !this.informationStepIsValid }
            },
            (this.appStore && !this.appStore.isCentrum && this.userStore.havePrivilege(SETRETAILX_WEIGHERTEMPLATE_ADMIN))
                ? {
                    name: SCALES_STEP,
                    props: { disabled: !this.informationStepIsValid }
                }
                : null,
            ((this.appStore && !this.appStore.isCentrum) || this.shop?.virtual)
                ? {
                    name: PRINTERS_STEP,
                    props: { disabled: !this.informationStepIsValid }
                }
                : null,
            {
                name: PRICE_CHECKERS_STEP,
                props: { disabled: !this.informationStepIsValid }
            }
        ].filter(e => e)
    }

    @computed
    get currentStep(): SHOPS_PAGE_STEP {
        return this.steps[this.currentStepIndex].name
    }

    @computed
    get lastStepIndex(): number {
        return this.steps.length - 1
    }

    @computed
    get firstStep(): boolean {
        return this.currentStepIndex === 0
    }

    @computed
    get lastStep(): boolean {
        return this.currentStepIndex === this.lastStepIndex
    }

    @computed
    get informationStepIsValid(): boolean {
        if (!this.shopInfoValidation || !get(this, 'shop.city.region') || !this.shop.format) return false
        return Boolean(
            this.shopInfoValidation.valid &&
            !this.physicalAddressError &&
            this.shop.city.id &&
            this.shop.city.region.id &&
            this.shop.format.id
        )
    }

    @computed
    get isNewShop(): boolean {
        return Boolean(this.shop) && isNil(this.shop.id)
    }

    @computed
    get formatNameIsNotUnique(): boolean {
        return Boolean(this.originalFormats.find(f => f.name === this.formatName))
    }

    @computed
    get formatNameIsTooLong(): boolean {
        return this.formatName.length > 255
    }

    @computed
    get formatsAreNotUnique(): boolean {
        const uniqueFormats = this.formats.filter((v, i, a) => a.findIndex(f => f.name === v.name) === i)
        return uniqueFormats.length !== this.formats.length
    }

    @computed
    get emptyJuristicPersonsMessage(): string {
        if (isEmpty(this.juristicPersons)) return t('shopPage.emptyJuristicPersons')
        return ''
    }

    // Отдельная валидация для адреса на чековых документах
    @computed
    get physicalAddressError(): string {
        if (this.emptyJuristicPersonsMessage) return ''
        const length = this.juristicPersons[0].physicalAddress.length
        const maxLength = 255
        return length <= maxLength ? '' : t('validation.maxLengthError', { count: maxLength })
    }

    @computed
    get isNewJuristicPerson(): boolean {
        return Boolean(this.selectedJuristicPerson) && this.selectedJuristicPerson.id === -1
    }

    @computed
    get connectionToCentrumChanged(): boolean {
        return this.shouldUseCentrumAddress !== this.prevShouldUseCentrumAddress
            || (this.shouldUseCentrumAddress && this.newCentrumAddress !== this.centrumAddress)
    }

    // Навигация по шагам и роутам
    @action
    setCurrentStep = (stepName: SHOPS_PAGE_STEP): Promise<void> => {
        if (this.informationStepIsValid && this.shopInfoValidation.modified) {
            return this.saveShop()
                .then(() => {
                    runInAction(() => {
                        const index: number = this.steps.findIndex(s => s.name === stepName)
                        this.currentStepIndex = index !== -1 ? index : 0
                    })
                })
        }
        const index: number = this.steps.findIndex(s => s.name === stepName)
        this.currentStepIndex = index !== -1 ? index : 0
        return Promise.resolve()
    }

    goToNextStep = (): void => {
        if (this.currentStepIndex + 1 > this.lastStepIndex) {
            return
        }
        this.setCurrentStep(this.steps[this.currentStepIndex + 1].name)
    }

    goToPreviousStep = (): void => {
        if (this.currentStepIndex - 1 < 0) {
            return
        }
        this.setCurrentStep(this.steps[this.currentStepIndex - 1].name)
    }

    setPreviousPage = (route: string): void => {
        this.previousPageURL = route || `${SHOPS}${SHOPS_SEARCH}`
    }

    goToPreviousPage = (): void => {
        if (this.previousPageURL === `${SHOPS}${SHOPS_SEARCH}`) {
            goTo(this.previousPageURL)
            return
        }

        if (!this.appStore.isCentrum) return

        const id = get(this, 'shop.city.id')

        if (!id) {
            goTo(`${SHOPS}${TOPOLOGY}${REGIONS}`)
            return
        }

        goTo(`${SHOPS}${TOPOLOGY}/city/${id}`)
    }

    // Подключение к центрум
    @action
    showConnectionToCentrumDialog = (): void => {
        this.editingConnectionToCentrum = true
        this.prevShouldUseCentrumAddress = Boolean(this.centrumAddress)
        this.shouldUseCentrumAddress = this.prevShouldUseCentrumAddress
        this.newCentrumAddress = this.centrumAddress
        this.newCentrumAddressIsValid = isValidURLOrIP(this.newCentrumAddress)
        this.showConnectionStatus = false
        this.newCentrumAddressCanBeReached = false
    }

    @action
    hideConnectionToCentrumDialog = (): void => {
        this.editingConnectionToCentrum = false
    }

    @action
    setShouldUseCentrumAddress = (newValue: boolean) => {
        this.shouldUseCentrumAddress = newValue
        this.showConnectionStatus = false
    }

    @action
    saveConnectionToCentrumChanges = (): Promise<void> => {
        if (!this.newCentrumAddressIsValid || !this.connectionToCentrumChanged) return
        let address = ''
        if (this.shouldUseCentrumAddress) {
            address = this.newCentrumAddress
        }

        return withSpinner(async () => {
            try {
                await this.connectToCentrum(address)
                await this.fetchCentrumAddress()
            } catch (error) {
                this.snackbarStore.show({
                    message: t('shopPage.centrumConnection.connectionFailure', { message: error.message })
                })
            }

            if (this.connectionToCentrumChanged) {
                if (address !== '') {
                    this.snackbarStore.show({
                        message: t('shopPage.centrumConnection.connected', { address }),
                        duration: SNACKBAR_EXTENDED_DURATION
                    })
                } else {
                    this.snackbarStore.show({
                        message: t('shopPage.centrumConnection.disconnected')
                    })
                }
            }

            if (this.centrumAddress) {
                await this.refreshCentrumConnectionStatus()
            } else {
                runInAction(() => {
                    this.connectedToCentrum = false
                })
            }
        })
    }

    @action
    setNewCentrumAddress = (address: string) => {
        this.newCentrumAddress = address
        this.showConnectionStatus = false
        this.newCentrumAddressIsValid = isValidURLOrIP(this.newCentrumAddress)
    }

    @action
    cancelCentrumAddressChanges = (): void => {
        this.setNewCentrumAddress(this.centrumAddress)
    }

    connectToCentrum = (address: string): Promise<void> => {
        return iTopologyEditorLocal.setCentrumUrl(address)
    }

    @action
    testConnectionToCentrum = async (address: string): Promise<void> => {
        this.showConnectionStatus = false
        try {
            await this.connectToCentrum(address)
            const connection = await this.isConnectionToCentrum()
            await this.connectToCentrum(this.centrumAddress)
            runInAction(() => {
                this.showConnectionStatus = true
                this.newCentrumAddressCanBeReached = connection
            })
        } catch (err) {
            runInAction(() => {
                this.showConnectionStatus = true
                this.newCentrumAddressCanBeReached = false
            })
        }
    }

    /**
     *  Сохраняет изменения и вызывает eventNewShop
     */
    emitNewShopEvent = (): Promise<void> => {
        return withSpinner(async () => {
            await this.saveConnectionToCentrumChanges()
            try {
                const success = await iTopologySearcherLocal.eventNewShop()
                const message = success
                    ? t('shopPage.centrumConnection.newShopSuccess')
                    : t('shopPage.centrumConnection.newShopFailure', {message: ''})
                this.snackbarStore.show({ message })
            } catch (error) {
                this.snackbarStore.show({
                    message: t('shopPage.centrumConnection.newShopFailure', {message: error.message})
                })
            }
        })
    }

    fetchCentrumAddress = async (): Promise<void> => {
        const address = await iTopologySearcherLocal.getCentrumUrl()
        runInAction(() => {
            this.centrumAddress = address
        })
    }

    refreshCentrumConnectionStatus = async (): Promise<void> => {
        const isConnected = await this.isConnectionToCentrum()
        runInAction(() => {
            this.connectedToCentrum = isConnected
        })
    }

    isConnectionToCentrum = (): Promise<boolean> => {
        return Promise.race<Promise<boolean>>([
            iTopologySearcherLocal.isConnectionToCentrum(),
            new Promise(resolve => {
                setTimeout(resolve, CHECK_CONNECTION_TO_CENTRUM_AWAIT_TIME, false)
            })
        ])
    }

    // Работа с информацией о магазине
    @action
    createShopInfoValidation = (creation: boolean = false): void => {
        this.shopInfoValidation = new FormValidation<ShopVO>(
            this.shop,
            [
                {
                    field: 'number',
                    rules: [
                        requiredField,
                        regExpValidator(/^[1-9]\d*/),
                        fieldLengthValidator({max: 5})
                    ]
                },
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        fieldLengthValidator({max: 255})
                    ]
                },
                {
                    field: 'address',
                    rules: [
                        requiredField,
                        fieldLengthValidator({max: 255})
                    ]
                },
            ],
            creation
        )
    }

    getShop = (id: number): ShopVO => {
        return toJS(this.topologyMap.shops.find(shop => shop.id === id))
    }

    fetchShopRetail = (): Promise<ShopVO> => {
        return iTopologyManagerLocal.getShops(this.userStore.session, 0, 0, 0, 0)
            .then(shops => {
                if (isEmpty(shops)) return null
                return shops[0]
            })
    }

    /**
     * При открытии страницы добавления магазина (ref === NEW), необходимо указать город,
     * к которому относится данный магазин
     */
    openShop = async (ref: string | number, city?: CityVO): Promise<void> => {
        if (this.appStore.isCentrum && !ref) {
            this.snackbarStore.show({message: t('topologyActions.shopFetchingFailure', {id: ref})})
            goTo(NOT_FOUND)
            return
        }
        if (!this.appStore.isCentrum) {
            await withSpinner(async () => {
                await this.fetchCentrumAddress()
                await this.refreshCentrumConnectionStatus()
            })
        }
        this.topologyMap = cloneDeep(toJS(this.appStore.topologyMap))
        if (ref === NEW) {
            this.addShop(city)
        } else {
            const shop: ShopVO = this.appStore.isCentrum ? this.getShop(Number(ref)) : await this.fetchShopRetail()
            this.editShop(shop)
        }
    }

    updateRegion = (id: number): void => {
        if (this.shop.city.region.id === id) return
        // Если на выбор есть только один город - установить его в качестве текущего
        let citiesInRegion: CityVO[] = get(this, 'topologyMap.cities') || []
        citiesInRegion = citiesInRegion.filter(city => city.region.id === id)
        let newCity: CityVO = citiesInRegion && citiesInRegion.length === 1 ? citiesInRegion[0] : createEmptyCity()

        newCity.region = this.topologyMap.regions.find(r => r.id === id) || createEmptyRegion()
        this.shop.city = newCity
    }

    updateCity = (id: number): void => {
        if (this.shop.city.id === id) return
        this.shop.city = this.topologyMap.cities.find(c => c.id === id) || createEmptyCity()
    }

    updateCityName = (name: string): void => {
        this.shop.city.name = name
    }

    updateFormat = (id: number): void => {
        if (this.shop.format.id === id) return
        this.shop.format = this.topologyMap.formats.find(f => f.id === id) || createEmptyFormat()
    }

    @action
    updateCurrentShop = (changes: Partial<ShopVO>): void => {
        Object.keys(changes).forEach(k => {
            this.shop[k] = changes[k]
        })
    }

    @action
    updatePhysicalAddress = (physicalAddress: string): void => {
        this.shopInfoValidation.modified = true
        this.shop.juristicPersons.forEach(p => {
            if (!p.deleted) p.physicalAddress = physicalAddress
        })
    }

    @action
    addShop = (city: CityVO): void => {
        if (!city) {
            goTo(`${SHOPS}${TOPOLOGY}${REGIONS}`)
            return
        }

        let newShop: ShopVO = createEmptyShop()
        newShop.city = city
        // Если на выбор есть только один формат - установить его в качестве текущего
        let availableFormats: FormatVO[] = get(this, 'topologyMap.formats')
        if (availableFormats && availableFormats.length === 1) newShop.format = availableFormats[0]
        // Имеет смысл показать пользователю в качестве номера магазина не 0, а первый доступный, от 1 и выше
        newShop.number = getMinimalUnusedShopNumber(get(this, 'topologyMap.shops') || [])
        this.shop = newShop

        this.createShopInfoValidation(true)
        goTo(`${SHOPS}${TOPOLOGY}${SHOP}/${NEW}`)
    }

    @action
    editShop = (shop: ShopVO): void => {
        if (!shop) {
            this.snackbarStore.show({message: t('topologyActions.shopFetchingFailure', {id: null})})
            goTo(NOT_FOUND)
            return
        }

        this.shop = shop
        this.createShopInfoValidation()
        if (this.appStore.isCentrum) goTo(`${SHOPS}${TOPOLOGY}${SHOP}/${shop.id}`)
    }

    saveShop = (creation: boolean = false): Promise<ShopVO> => {
        // Если физический адресс у юр.лиц пустой, то в юр.лица прописывается обычный адрес
        if (this.shop.juristicPersons.some(p => !p.deleted && !p.physicalAddress)) {
            this.shop.juristicPersons.forEach(p => {
                if (!p.deleted) p.physicalAddress = this.shop.address
            })
        }

        return withSpinner(
            iTopologyManagerLocal.addShop(this.userStore.session, toJS(this.shop))
                .then(shop => {
                    runInAction(() => {
                        this.editShop(shop)
                        creation ?
                            this.snackbarStore.show({message: t('topologyActions.shopCreationSuccess', {shopName: this.shop.name})}) :
                            this.snackbarStore.show({message: t('topologyActions.shopModificationSuccess', {shopName: this.shop.name})})
                    })
                    return this.appStore.fetchTopologyMap()
                        .then(() => shop)
                })
                .catch(error => {
                    creation ?
                        this.snackbarStore.show({message: t('topologyActions.shopCreationFailure')}) :
                        this.snackbarStore.show({message: t('topologyActions.shopModificationFailure')})
                    return null
                })
        )
    }

    // Форматы
    fetchFormats = (): Promise<void> => {
        return iTopologyManagerLocal.getFormats(this.userStore.session)
            .then(formats => {
                runInAction(() => {
                    this.formats = formats
                    this.originalFormats = cloneDeep(formats)
                })
            })
    }

    openFormatsSettings = async (): Promise<void> => {
        await this.fetchFormats()
        runInAction(() => {
            this.editingFormats = true
            this.formatName = ''
        })
    }

    @action
    closeFormatsSettings = (): void => {
        this.editingFormats = false
        this.formatName = ''
    }

    @action
    updateFormatName = (id: number, name: string): void => {
        if (isNil(id)) {
            this.formatName = name
        } else {
            const format = this.formats.find(f => f.id === id)
            format.name = name
        }
    }

    @action
    saveFormat = async (format: FormatVO): Promise<void> => {
        const isNew = isNil(format.id)
        if (isNew) this.formatName = ''
        // Если не было внесено изменений - нет нужды отправлять запросы на сервер
        if (!isNew && isEqual(toJS(this.formats), toJS(this.originalFormats))) {
            return
        }

        try {
            await iTopologyManagerLocal.addFormat(this.userStore.session, format)
            const message = isNew
                ? 'topologyActions.formatCreationSuccess'
                : 'topologyActions.formatModificationSuccess'
            this.snackbarStore.show({message: t(message, {formatName: format.name})})
        } catch (error) {
            const message = isNew ?
                'topologyActions.formatCreationFailure'
                : 'topologyActions.formatModificationFailure'
            this.snackbarStore.show({message: t(message, {message: error.message})})
        }
        await this.appStore.fetchTopologyMap()
        this.topologyMap = cloneDeep(toJS(this.appStore.topologyMap))
        await this.fetchFormats()
    }

    deleteFormat = async (format: FormatVO): Promise<void> => {
        const response = await iTopologyManagerLocal.deleteFormat(this.userStore.session, format.id)
        const message  = response
            ? {message: t('topologyActions.formatDeletionSuccess', {formatName: format.name})}
            : {message: t('topologyActions.formatDeletionFailure', {message: null})}
        this.snackbarStore.show(message)
        await this.appStore.fetchTopologyMap()
        this.topologyMap = cloneDeep(toJS(this.appStore.topologyMap))
        await this.fetchFormats()
    }

    @action
    rollbackFormatsChanges = (): void => {
        this.formats = cloneDeep(toJS(this.originalFormats))
    }

    @action
    createJuristicPersonValidation = (creation: boolean = false): void => {
        this.juristicPersonValidation = new FormValidation<JuristicPersonVO>(
            this.selectedJuristicPerson,
            [
                {
                    field: 'juristicPersonName',
                    rules: [
                        requiredField,
                        fieldLengthValidator({max: 50})
                    ]
                },
                {
                    field: 'juristicAddress',
                    rules: [
                        requiredField,
                        fieldLengthValidator({max: 60})
                    ]
                },
                {
                    field: 'telephoneNumber',
                    rules: [
                        fieldLengthValidator({max: 255})
                    ]
                },
                {
                    field: 'directorFullName',
                    rules: [
                        fieldLengthValidator({max: 20})
                    ]
                },
                {
                    field: 'inn',
                    rules: [
                        requiredField,
                        regExpValidator(/^\d*$/),
                        fieldLengthValidator({
                            max: 14,
                            anyOf: [9, 10, 12, 14],
                            anyOfError: t('shopPage.juristicPersonINNFieldAnyOfError'),
                            maxError: t('shopPage.juristicPersonINNFieldMaxError')
                        })
                    ]
                },
                {
                    field: 'kpp',
                    rules: [
                        regExpValidator(/^\d*$/),
                        fieldLengthValidator({max: 9, anyOf: [9]})
                    ]
                },
                {
                    field: 'okpo',
                    rules: [
                        regExpValidator(/^\d*$/),
                        fieldLengthValidator({max: 10, anyOf: [8, 10]})
                    ]
                },
                {
                    field: 'okdp',
                    rules: [
                        regExpValidator(/^\d*$/),
                        fieldLengthValidator({max: 9})
                    ]
                },
            ],
            creation
        )
    }

    createEmptyJuristicPerson = (): JuristicPersonVO => {
        let physicalAddress: string
        // Если в магазине есть действующее юр.лицо, нужно перенять его physicalAddress
        let activeJuristicPerson: JuristicPersonVO = this.juristicPersons.find(person => !person.deleted)
        if (activeJuristicPerson) {
            physicalAddress = activeJuristicPerson.physicalAddress
        } else {
            // Если юр.лиц небыло, значит physicalAddress магазина пустой и надо взять обычный адрес
            physicalAddress = this.shop.address
        }

        return createJuristicPersonVO({
            id: -1,
            inn: '',
            kpp: '',
            physicalAddress,
            juristicAddress: '',
            juristicPersonName: '',
            telephoneNumber: '',
            okpo: '',
            okdp: '',
            defaultJP: false,
            deleted: false,
            directorFullName: '',
            accountantFullName: '',
            simplifiedTaxTreatment: false
        })
    }

    @action
    openJuristicPersonSettings = (ref: string) => {
        if (ref === NEW) {
            this.selectedJuristicPerson = this.createEmptyJuristicPerson()
            this.createJuristicPersonValidation(true)
        } else {
            let id: number = Number(ref)
            this.selectedJuristicPerson = cloneDeep(this.juristicPersons.find(person => person.id === id)) || null
            if (this.selectedJuristicPerson) this.createJuristicPersonValidation()
        }
    }

    @action
    closeJuristicPersonSettings = () => {
        this.selectedJuristicPerson = null
    }

    @action
    updateCurrentJuristicPerson = (changes: Partial<JuristicPersonVO>) => {
        Object.keys(changes).forEach(k => {
            this.selectedJuristicPerson[k] = changes[k]
        })
    }

    @action
    saveJuristicPerson = (): Promise<void> => {
        return iTopologyManagerLocal.addJuristicPerson(this.userStore.session, this.shop.id, this.selectedJuristicPerson)
            .then(person => {
                let exPersonIndex = this.shop.juristicPersons.findIndex(p => p.id === person.id)
                if (exPersonIndex !== -1) {
                    runInAction(() => {
                        this.shop.juristicPersons[exPersonIndex] = person
                    })
                } else {
                    runInAction(() => {
                        this.shop.juristicPersons.push(person)
                    })
                }
            })
    }

    deleteJuristicPerson = (id: number): Promise<void> => {
        return iTopologyManagerLocal.deleteJuristicPerson(this.userStore.session, id)
            .then(response => {
                if (response) {
                    runInAction(() => {
                        const modifiedPersonIndex = this.shop.juristicPersons.findIndex(person => person.id === id)
                        this.shop.juristicPersons[modifiedPersonIndex].deleted = true
                        const name = this.shop.juristicPersons[modifiedPersonIndex].juristicPersonName

                        this.snackbarStore.show({message: t('shopPage.juristicPersonDeletionSuccess', {name})})
                    })
                } else {
                    this.snackbarStore.show({message: t('shopPage.juristicPersonDeletionFailure', {message: null})})
                }
            })
            .catch(error => {
                this.snackbarStore.show({message: t('shopPage.juristicPersonDeletionFailure', {message: error.message})})
            })
    }

    markJuristicPersonAsDefault = (id: number): Promise<void> => {
        return iTopologyManagerLocal.markJuristicPersonAsDefault(this.userStore.session, id)
            .then(defaultPerson => {
                runInAction(() => {
                    this.shop.juristicPersons.forEach(person => {
                        if (!person.deleted) person.defaultJP = person.id === defaultPerson.id
                    })
                })
            })
    }

    @action
    reset = (): void => {
        this.centrumAddress = ''
        this.newCentrumAddress = ''
        this.newCentrumAddressIsValid = false
        this.newCentrumAddressCanBeReached = false
        this.connectedToCentrum = false
        this.editingConnectionToCentrum = false
        this.shouldUseCentrumAddress = false
        this.prevShouldUseCentrumAddress = false
        this.showConnectionStatus = false
        this.currentStepIndex = 0
        this.previousPageURL = ''

        this.topologyMap = undefined
        this.shop = undefined
        this.shopInfoValidation = undefined

        this.formats = []
        this.originalFormats = []
        this.editingFormats = false
        this.formatName = ''

        this.selectedJuristicPerson = undefined
        this.juristicPersonValidation = undefined
    }
}

/**
 * Обработчик для страниц редактирования и добавления магазина
 */
export const TOPOLOGY_SHOP_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${SHOPS}${TOPOLOGY}${SHOP}/([\\w-]+)/?$`),
    onEnter: (newRoute: string, prevRoute: string) => {
        const shopStore: ShopStore = getStore(SHOP_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const dialogStore: DialogStore = getStore(DIALOG_STORE)

        shopStore.setPreviousPage(prevRoute)

        let matchNewRoute = newRoute.match(TOPOLOGY_SHOP_ROUTING_HANDLER.routeMatcher)
        let nodeId = matchNewRoute[1]

        appBarStore.updateState({
            title: nodeId === NEW ? `${t(`topologyPages.shopCreate`)}` : `${t(`topologyPages.shopEdit`)}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                if (shopStore.shopInfoValidation.modified) {
                    dialogStore.showDialog({
                        title: t('topologyPages.regionSaveChangesTitle'),
                        message: t('topologyPages.regionSaveChangesMessage'),
                        mode: DIALOG,
                        onYes: shopStore.goToPreviousPage,
                        yesLabel: t('common.exit'),
                        noLabel: t('common.cancel')
                    })
                } else {
                    shopStore.goToPreviousPage()
                }
            }
        })
    }
}
