import React, { Component, HTMLProps, ReactNode, Fragment } from 'react'
import classNames from 'classnames'
import { t } from 'i18next'

const styles = require('./product-base-info.scss')

export interface ProductBaseInfoProps extends HTMLProps<HTMLDivElement> {
    name?: string
    code?: string
    barcode?: string
    filterString?: string
}

// TODO нужно перенести в component-util.ts, для общего использования
/**
 * Выделяет первую совпавшую строку отдельным элементом с указанным css классом
 * @param {string} value
 * @param {string} filterString
 * @param {string} matchClassName
 * @returns {React.ReactNode | React.ReactNode[]}
 */
export function highlightFirstMatch(value: string, filterString: string, matchClassName?: string, exact?: boolean): ReactNode | ReactNode[] {
    if (!value || !filterString) return value

    const fixedFilterString = filterString.toLowerCase().trim()

    if (!fixedFilterString) return value

    const elements: ReactNode[] = []
    let matchIndex = value.toLowerCase().indexOf(fixedFilterString)

    if (exact) {
        matchIndex = value.toLowerCase() === fixedFilterString ? 0 : -1
    }

    let key = `${value}_${filterString}_${elements.length}`

    if (matchIndex === -1) {
        elements.push(<Fragment key={key}>{ value }</Fragment>)
    } else {
        if (matchIndex > 0) {
            elements.push(<Fragment key={key}>{ value.substring(0, matchIndex) }</Fragment>)
            key = `${value}_${filterString}_${elements.length}`
        }

        elements.push(
            <span
                key={key}
                id="searchResultHighlight"
                className={classNames(matchClassName || styles.highlightedMatch)}
            >
                { value.substr(matchIndex, fixedFilterString.length) }
            </span>
        )

        key = `${value}_${filterString}_${elements.length}`

        elements.push(<Fragment key={key}>{ value.substring(matchIndex + fixedFilterString.length) }</Fragment>)
    }

    return elements
}

export class ProductBaseInfo extends Component<ProductBaseInfoProps> {

    static defaultProps: Partial<ProductBaseInfoProps> = {
        id: '',
        filterString: '',
    }

    render() {
        const { name, code, barcode, className, filterString, ...other } = this.props

        return (
            <div
                {...other}
                className={classNames(
                    className,
                    styles.productBaseInfo
                )}
            >
                <span className={styles.productName}>
                    { highlightFirstMatch(name, filterString, styles.highlightedMatch) }
                </span>
                <span className={styles.additionalText}>
                    {t('shop.barcodeShort')}: { highlightFirstMatch(barcode, filterString, styles.highlightedMatch) }
                </span>
                <span className={styles.additionalText}>
                    {t('shop.productCodeShort')}: { highlightFirstMatch(code, filterString, styles.highlightedMatch) }
                </span>
            </div>
        )
    }
}
