import * as React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import {KEYDOWN} from '../../utils/dom/dom-events'
import { AppStore } from '../../set10/store/app-store'
import { getStore } from '../../set10/store/stores-repository'
import { APP_STORE } from '../../set10/store/stores'

const styles = require('./progress.scss')

export class Progress extends React.Component<ProgressProps, ProgressState> {

    startToId: any = null

    stopToId: any = null

    state: ProgressState = {
        visible: false
    }

    componentDidMount() {
        //window.addEventListener(KEYDOWN, this.blockKeyBoard)
        this.checkNextProps(this.props)
    }

    componentWillReceiveProps(nextProps: ProgressProps) {
        this.checkNextProps(nextProps)
    }

    componentWillUnmount() {
        //window.removeEventListener(KEYDOWN, this.blockKeyBoard)
        clearTimeout(this.startToId)
        clearTimeout(this.stopToId)
    }

    checkNextProps = (nextProps: Readonly<ProgressProps>): void => {
        clearTimeout(this.startToId)
        clearTimeout(this.stopToId)
        if (nextProps.visible) {
            this.startToId = setTimeout(() => {
                this.setState({ visible: true })
            }, nextProps.maxInvisibleTimeMs)
        } else {
            this.stopToId = setTimeout(() => {
                this.setState({ visible: false })
            }, nextProps.minVisibleTimeMs)
        }
    }
/*
TODO поддержать без сторов
    blockKeyBoard = (event: any) => {
        const appStore: AppStore = getStore(APP_STORE)
        if (appStore.loading) {
            event.preventDefault()
        }
    }
*/
    render() {
        const {visible} = this.state

        if (!visible) {
            return null
        }

        return (
            <LinearProgress className={styles.progress} />
        )
    }
}

export interface ProgressState {
    visible: boolean
}

export interface ProgressProps extends React.HTMLProps<Progress> {
    visible: boolean
    minVisibleTimeMs: number
    maxInvisibleTimeMs: number
}
