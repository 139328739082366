import React from 'react'
import { t } from 'i18next'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { inject, observer } from 'mobx-react'
import { ItemsFilteredList } from '../../../../../components/selectors/items-filtered-list/items-filtered-list'
import { AdaptiveDialog } from '../../../../../components/adaptive-dialog/adaptive-dialog'
import { ActionSettingsStore } from '../../../../store/actions/action-settings-store'
import { CashiersSearchStore } from '../../../../store/staff/cashiers-search-store'
import { AdvertisingActionParsed } from '../../../../core/advertising-actions/advertising-actions'
import { ACTION_SETTINGS_STORE, CASHIERS_SEARCH_STORE } from '../../../../store/stores'
import {
    CommonConditions
} from '../../../../core/advertising-actions/action-conditions'

const styles = require('./action-edit-roles-dialog.scss')

interface ActionEditRolesDialogProps {
    cashiersSearchStore?: CashiersSearchStore
    actionSettingsStore?: ActionSettingsStore
}

interface ActionEditRolesDialogState {
    myState: number
}

@inject(ACTION_SETTINGS_STORE)
@inject(CASHIERS_SEARCH_STORE)
@observer
export class ActionEditRolesDialog extends React.Component<ActionEditRolesDialogProps, ActionEditRolesDialogState> {
    
    render() {
        const {
            cashierRoles, 
            resetCashierRoles, 
            updateCashierRoles, 
            modifyEditedAction
        } = this.props.actionSettingsStore

        if (!Boolean(cashierRoles)) return null

        return (
            <AdaptiveDialog
                open={true}
                id="actionEditRolesDialog"
                breakpoint="sm"
                fullWidth
                maxWidth="xs"
                className={styles.dialog}
            >
                <DialogTitle>
                    {t('advertisingActions.setRolesEditTitle')}
                </DialogTitle>
                <DialogContent className={styles.content}>
                    <ItemsFilteredList
                        id="rolesList"
                        maxRows={5.5}
                        header={t('staff.allRoles')}
                        showFilter={false}
                        showStringFilter={false}
                        sortFields={['name']}
                        items={this.props.cashiersSearchStore.roles}
                        selectedItems={cashierRoles}
                        keyField="id"
                        labelField="name"
                        onSelectionChange={selection => {                            
                            updateCashierRoles(selection)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        id="cancelButton"
                        onClick={() => {
                            resetCashierRoles()
                        }}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        color="primary"
                        id="saveButton"
                        onClick={() => {
                            let changes: Partial<AdvertisingActionParsed> = {}
                            if (cashierRoles.length === 0) {
                                changes.externalConditionsParsed = []
                            } else {                           
                                changes.externalConditionsParsed = [{
                                    type: CommonConditions.CashierCondition, 
                                    data: {cashierRoles: [{long : cashierRoles.map(role => role.guid)}]}}]
                            }
                            modifyEditedAction(changes)
                            resetCashierRoles()
                        }}
                    >
                        {t('common.apply')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
