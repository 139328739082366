import { createSegmentVO, SegmentVO } from '../../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/segment-vo'
import { ClientConditions } from '../../../../../../../core/advertising-actions/action-conditions'
import _ from 'lodash'

export enum SegmentFilters {
    SegmentFlatOrFilter = 'ru.crystalservice.setv6.discounts.plugins.SegmentFlatOrFilter',
    SegmentFlatAndFilter = 'ru.crystalservice.setv6.discounts.plugins.SegmentFlatAndFilter',
    SegmentNotFilter = 'ru.crystalservice.setv6.discounts.plugins.SegmentNotFilter',
    SegmentAndFilter = 'ru.crystalservice.setv6.discounts.plugins.SegmentAndFilter',
    SegmentOrFilter = 'ru.crystalservice.setv6.discounts.plugins.SegmentOrFilter',
}

export interface SegmentFilterCondition {
    type: ClientConditions.SegmentFilterCondition
    data: Segment
}

export interface Segment {
    filter: SegmentFilter[]
}

export interface SegmentFilter {
    $?: SegmentClass
    filter?: SegmentFilter[]
    filters?: Filters[]
    guids?: GuidStrings[]
}

export const createSegmentFilter = (init: Partial<SegmentFilter>): SegmentFilter => {
    if (!init) return

    const data = {
        $: init.$,
        filter: init.filter,
        filters: init.filters,
        guids: init.guids,
    }

    return removeEmpty(data)
}

interface SegmentClass {
    class: SegmentFilters
}

interface GuidStrings {
    String: string[]
}

interface Filters {
    [SegmentFilters.SegmentFlatOrFilter]?: SegmentFilter[]
    [SegmentFilters.SegmentFlatAndFilter]?: SegmentFilter[]
    [SegmentFilters.SegmentNotFilter]?: SegmentFilter[]
    [SegmentFilters.SegmentAndFilter]?: SegmentFilter[]
    [SegmentFilters.SegmentOrFilter]?: SegmentFilter[]
}

enum BooleanOperation {
    AND = 'AND',
    OR = 'OR',
    NOT = 'NOT',
    LEFT_BRACKET = '(',
    RIGHT_BRACKET = ')',
}

export enum TagType {
    SEGMENT = 'SEGMENT',
    OPERATION = 'OPERATION',
}

export interface TagData {
    tagType: TagType
    segment?: SegmentVO
    operationType?: BooleanOperation
}

export const createTagData = (init: Partial<TagData>): TagData => {
    if (!init) return

    const data = {
        tagType: init.tagType,
        segment: init.segment,
        operationType: init.operationType,
    }

    return removeEmpty(data)
}

function removeEmpty<T>(obj: T): T {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
        else if (obj[key] === undefined) delete obj[key]
    })

    return obj
}

export const rightBracketTagData: TagData = createTagData({
    tagType: TagType.OPERATION,
    operationType: BooleanOperation.RIGHT_BRACKET,
})

export const leftBracketTagData: TagData = createTagData({
    tagType: TagType.OPERATION,
    operationType: BooleanOperation.LEFT_BRACKET
})

export const orTagData: TagData = createTagData({
    tagType: TagType.OPERATION,
    operationType: BooleanOperation.OR
})

export const andTagData: TagData = createTagData({
    tagType: TagType.OPERATION,
    operationType: BooleanOperation.AND
})

export const notTagData: TagData = createTagData({
    tagType: TagType.OPERATION,
    operationType: BooleanOperation.NOT
})

export interface FilterData {
    filterType: SegmentFilters,
    tagData: TagData,
    isFlat?: boolean,
}

export const segmentFilterTypes: FilterData[] = [
    {
        filterType: SegmentFilters.SegmentFlatOrFilter,
        tagData: orTagData,
    }, {
        filterType: SegmentFilters.SegmentFlatAndFilter,
        tagData: andTagData,
    }, {
        filterType: SegmentFilters.SegmentOrFilter,
        tagData: orTagData,
    }, {
        filterType: SegmentFilters.SegmentAndFilter,
        tagData: andTagData,
    }, {
        filterType: SegmentFilters.SegmentNotFilter,
        tagData: notTagData,
    }
]

export const convertSegmentFilterToTagsData = (sf: SegmentFilter[]): TagData[] => {
    if (!sf) return
    const segmentFilter = sf[0]
    const result = []

    const filter = segmentFilterTypes.find(filter => hasClass(segmentFilter, filter.filterType))

    if (isSegmentFlatOrFilter(segmentFilter) || isSegmentFlatAndFilter(segmentFilter)) {
        segmentFilter.guids[0].String.forEach((guid, index, array) => {
            const segmentTagData = createTagData({
                tagType: TagType.SEGMENT,
                segment: createSegmentVO({ guid }),
            })

            result.push(segmentTagData)

            if (isNotLastIndex(index, array)) {
                result.push(filter.tagData)
            }
        })
    }

    if (isSegmentNotFilter(segmentFilter)) {
        const shouldAddNotBracket = !isSegmentFlatOrFilter(segmentFilter.filter[0]) ||
            segmentFilter.filter[0].guids[0].String.length > 1

        result.push(notTagData)

        if (shouldAddNotBracket) {
            result.push(leftBracketTagData)
        }

        result.push(...convertSegmentFilterToTagsData(segmentFilter.filter))

        if (shouldAddNotBracket) {
            result.push(rightBracketTagData)
        }
    }

    if (isSegmentOrFilter(segmentFilter) || isSegmentAndFilter(segmentFilter)) {
        const filterKeys = Object.keys(segmentFilter.filters[0])

        filterKeys.forEach((filterKey, index, filterKeysCollection) => {
            const shouldAddBracketSegmentOrFilter = isSegmentAndFilter(segmentFilter) &&
                isSegmentOrFilter(segmentFilter.filters[0][filterKey].filter[0])

            if (shouldAddBracketSegmentOrFilter) {
                result.push(leftBracketTagData)
            }

            segmentFilter.filters[0][filterKey].forEach((group, index, segmentFilterCollection) => {
                const shouldAddBracketFlatOrFilter = isSegmentAndFilter(segmentFilter) &&
                    (filterKey === SegmentFilters.SegmentFlatOrFilter || isSegmentFlatOrFilter(group)) &&
                    group && group.guids[0].String.length > 1

                if (shouldAddBracketFlatOrFilter) {
                    result.push(leftBracketTagData)
                }

                result.push(...convertSegmentFilterToTagsData([
                    {
                        $: {
                            class: filterKey,
                        },
                        ...group
                    }
                ]))

                if (shouldAddBracketFlatOrFilter) {
                    result.push(rightBracketTagData)
                }

                if (isNotLastIndex(index, segmentFilterCollection)) {
                    result.push(filter.tagData)

                }
            })

            if (shouldAddBracketSegmentOrFilter) {
                result.push(rightBracketTagData)
            }

            if (isNotLastIndex(index, filterKeysCollection)) {
                result.push(filter.tagData)
            }

        })
    }

    return result
}

export const convertTagsDataToSegmentFilter = (tagsData: any[]): SegmentFilter[] => {
    let maxNestLevel: number = 0
    let currentNestLevel: number = 0

    tagsData.forEach((tagData: TagData) => {
        if (isLeftBracket(tagData)) {
            currentNestLevel++
            if (currentNestLevel > maxNestLevel) maxNestLevel = currentNestLevel
        }

        if (isRightBracket(tagData)) {
            currentNestLevel--
        }
    })

    const result = _.cloneDeep(tagsData)

    for (let n = 0; n < maxNestLevel; n++) {
        let lastLeftBracketPosition: number = -1
        let rightBracketPosition: number = -1
        for (let i = 0; i < result.length; i++) {
            const tagData = result[i]

            if (isLeftBracket(tagData)) {
                lastLeftBracketPosition = i
            }
            if (isRightBracket(tagData)) {
                rightBracketPosition = i

                if (lastLeftBracketPosition > -1 && (
                    (rightBracketPosition - lastLeftBracketPosition) > 1)) {
                    const tagsDataBetweenBrackets = result.slice(lastLeftBracketPosition + 1, rightBracketPosition)
                    const filter = getSegmentFilter(tagsDataBetweenBrackets)

                    result.splice(lastLeftBracketPosition, rightBracketPosition - lastLeftBracketPosition + 1, filter)

                    i = lastLeftBracketPosition
                }

                lastLeftBracketPosition = -1
            }
        }
    }

    return [getSegmentFilter(result)]
}

const getSegmentFilter = (value: any[]): SegmentFilter => {
    if (!value || value && value.length === 0) {
        return null
    }

    const result = _.cloneDeep(value)

    if (value.length === 1) {
        const tagData = value[0]
        if (tagData.tagType === TagType.SEGMENT) {
            return createSegmentFilter({
                $: {
                    class: SegmentFilters.SegmentFlatOrFilter
                },
                guids: [{
                    String: [tagData.segment.guid]
                }]
            })
        }
    }

    result.forEach((tagData, i) => {
        if (isNotOperation(tagData) && isNotLastIndex(i, result)) {
            const filter = getNotFilter(result[i + 1])
            result.splice(i, 2, filter)
        }
    })

    for (let i = 0; i < result.length; i++) {
        const tagData = result[i]
        if (isAndOperation(tagData) && isNotFirstIndex(i) && isNotLastIndex(i, result)) {
            const filter = getAndFilter(result[i - 1], result[i + 1])
            result.splice(i - 1, 3, filter)
            i--
        }
    }

    for (let i = 0; i < result.length; i++) {
        const tagData = result[i]
        if (isOrOperation(tagData) && isNotFirstIndex(i) && isNotLastIndex(i, result)) {
            const filter = getOrFilter(result[i - 1], result[i + 1])
            result.splice(i - 1, 3, filter)
            i--
        }
    }

    if (result.length === 1 && isSegmentFilter(result[0])) {
        return result[0]
    } else {
        return null
    }
}

const getNotFilter = (value: any): SegmentFilter => {
    const notFilter = createSegmentFilter({
        $: {
            class: SegmentFilters.SegmentNotFilter
        },
    })

    if (isSegment(value)) {
        const flatOrFilter = createSegmentFilter({
            $: {
                class: SegmentFilters.SegmentFlatOrFilter
            },
            guids: [
                { String: [value.segment.guid] }
            ]
        })

        notFilter.filter = [flatOrFilter]

        return notFilter
    }

    if (Boolean(value.$)) {
        notFilter.filter = [value]

        return notFilter
    }

    return null
}

const getOrFilter = (value1: any, value2: any): SegmentFilter => {
    const flatOrFilter = createSegmentFilter({
        $: {
            class: SegmentFilters.SegmentFlatOrFilter
        },
        guids: [{ String: []}],
    })

    const orFilter = createSegmentFilter({
        $: {
            class: SegmentFilters.SegmentOrFilter
        },
    })

    if (isTagData(value1) && isTagData(value2)) {
        if (isSegment(value1) && isSegment(value2)) {
            flatOrFilter.guids[0].String.push(value1.segment.guid, value2.segment.guid)

            return flatOrFilter
        }
    }

    if (isTagData(value1) && isSegmentFlatOrFilter(value2)) {
        if (isSegment(value1)) {
            flatOrFilter.guids[0].String.push(value1.segment.guid)

            return flatOrFilter
        }
    }

    if (isSegmentFlatOrFilter(value1) && isTagData(value2)) {
        if (isSegment(value2)) {
            flatOrFilter.guids[0].String.push(...value1.guids[0].String, value2.segment.guid)

            return flatOrFilter
        }
    }

    if (isSegmentFlatOrFilter(value1) && isSegmentFlatOrFilter(value2)) {
        flatOrFilter.guids[0].String.push(value2.guids[0].String)

        return flatOrFilter
    }

    if (isSegmentFilter(value1) && isTagData(value2)) {
        if (isSegment(value2)) {
            flatOrFilter.guids = [
                { String: [value2.segment.guid] }
            ]
            orFilter.filters = getClassLikeKeyCollection([value1, flatOrFilter])

            return orFilter
        }
    }

    if (isTagData(value1) && isSegmentFilter(value2)) {
        if (isSegment(value1)) {
            flatOrFilter.guids = [
                { String: [value1.segment.guid] }
            ]
            orFilter.filters = getClassLikeKeyCollection([flatOrFilter, value2])

            return orFilter
        }
    }

    if (isSegmentFilter(value1) && isSegmentFilter(value2)) {
        orFilter.filters = getClassLikeKeyCollection([value1, value2])

        return orFilter
    }

    return null
}

const getAndFilter = (value1: any, value2: any): SegmentFilter => {
    const flatAndFilter = createSegmentFilter({
        $: {
            class: SegmentFilters.SegmentFlatAndFilter
        },
        guids: [{ String: []}],
    })
    const flatOrFilter = createSegmentFilter({
        $: {
            class: SegmentFilters.SegmentFlatOrFilter
        },
        guids: [{ String: []}],
    })

    const andFilter = createSegmentFilter({
        $: {
            class: SegmentFilters.SegmentAndFilter
        },
    })

    if (isTagData(value1) && isTagData(value2)) {
        if (isSegment(value1) && isSegment(value2)) {
            flatAndFilter.guids[0].String.push(value1.segment.guid, value2.segment.guid)

            return flatAndFilter
        }
    }

    if (isTagData(value1) && isSegmentFlatAndFilter(value2)) {
        if (isSegment(value1)) {
            flatAndFilter.guids[0].String.push(value1.segment.guid)

            return flatAndFilter
        }
    }

    if (isSegmentFlatAndFilter(value1) && isTagData(value2)) {
        if (isSegment(value2)) {
            flatAndFilter.guids[0].String.push(...value1.guids[0].String, value2.segment.guid)

            return flatAndFilter
        }
    }

    if (isSegmentFlatAndFilter(value1) && isSegmentFlatAndFilter(value2)) {
        flatAndFilter.guids[0].String.push(value2.guids[0].String)

        return flatAndFilter
    }

    if (isSegmentFilter(value1) && isTagData(value2)) {
        if (isSegment(value2)) {
            flatOrFilter.guids = [
                { String: [value2.segment.guid] }
            ]
            andFilter.filters = getClassLikeKeyCollection([value1, flatOrFilter])

            return andFilter
        }
    }

    if (isTagData(value1) && isSegmentFilter(value2)) {
        if (isSegment(value1)) {
            flatOrFilter.guids = [
                { String: [value1.segment.guid] }
            ]
            andFilter.filters = getClassLikeKeyCollection([flatOrFilter , value2])

            return andFilter
        }
    }

    if (isSegmentFilter(value1) && isSegmentFilter(value2)) {
        andFilter.filters = getClassLikeKeyCollection([value1, value2])

        return andFilter
    }

    return null
}

const getClassLikeKeyCollection = (collection: any[]): any[] => {
    const result = [{}]

    collection.forEach(item => {
        const {
            $: valueClass,
            ...valueData
        } = item

        result[0][valueClass.class] = result[0][valueClass.class] ? [...result[0][valueClass.class], valueData] : [valueData]
    })

    return result
}

const hasClass = (item: any, classStr: string): any => {
    return item && item.$ && item.$.class === classStr
}

const isSegment = (tagData: TagData): boolean => {
    if (!tagData) return false

    return tagData.tagType === TagType.SEGMENT
}

const isOperation = (tagData: TagData): boolean => {
    if (!tagData) return false

    return tagData.tagType === TagType.OPERATION
}

const isTagData = (tagData: TagData): boolean => {
    return isSegment(tagData) || isOperation(tagData)
}

const isLeftBracket = (tagData: TagData): boolean => {
    if (!tagData) return false

    return tagData.tagType === TagType.OPERATION && tagData.operationType === BooleanOperation.LEFT_BRACKET
}

const isRightBracket = (tagData: TagData): boolean => {
    if (!tagData) return false

    return tagData.tagType === TagType.OPERATION && tagData.operationType === BooleanOperation.RIGHT_BRACKET
}

const isNotOperation = (tagData: TagData): boolean => {
    if (!tagData) return false

    return tagData.tagType === TagType.OPERATION && tagData.operationType === BooleanOperation.NOT
}

const isAndOperation = (tagData: TagData): boolean => {
    if (!tagData) return false

    return tagData.tagType === TagType.OPERATION && tagData.operationType === BooleanOperation.AND
}

const isOrOperation = (tagData: TagData): boolean => {
    if (!tagData) return false

    return tagData.tagType === TagType.OPERATION && tagData.operationType === BooleanOperation.OR
}

const isNotLastIndex = (index: number, collection: any[]): boolean => {
    return index < (collection.length - 1)
}

const isNotFirstIndex = (index: number): boolean => {
    return index > 0
}

const isSegmentFlatAndFilter = (data: any) => {
    return hasClass(data, SegmentFilters.SegmentFlatAndFilter)
}

const isSegmentFlatOrFilter = (data: any) => {
    return hasClass(data, SegmentFilters.SegmentFlatOrFilter)
}

const isSegmentOrFilter = (data: any) => {
    return hasClass(data, SegmentFilters.SegmentOrFilter)
}

const isSegmentAndFilter = (data: any) => {
    return hasClass(data, SegmentFilters.SegmentAndFilter)
}

const isSegmentNotFilter = (data: any) => {
    return hasClass(data, SegmentFilters.SegmentNotFilter)
}

const isSegmentFilter = (data: any) => {
    return isSegmentFlatAndFilter(data) ||
        isSegmentFlatOrFilter(data) ||
        isSegmentOrFilter(data) ||
        isSegmentNotFilter(data) ||
        isSegmentAndFilter(data)
}
