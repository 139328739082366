import React from 'react'
import { t } from 'i18next'
import { isNil } from 'lodash'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { ShopAutocomplete } from '../../../../../../../components/inputs/autocomplete-input/shop-autocomplete'
import { ActionSettingsStore } from '../../../../../../store/actions/action-settings-store'
import { ACTION_SETTINGS_STORE } from '../../../../../../store/stores'

const styles = require('./affected-zone-list.scss')

interface AffectedZoneListProps {
    actionSettingsStore?: ActionSettingsStore
}

@inject(ACTION_SETTINGS_STORE)
@observer
export class AffectedZoneList extends React.Component<AffectedZoneListProps> {
    render() {
        const { actionSettingsStore } = this.props
        const { editingTopologyShopsListInput, modifyEditingTopologyShopsListInput } = actionSettingsStore
        const shopsNumbersRegex = /^[\d\s,]*$/

        return (
            <Grid container style={{ padding: '0 16px'}}>
                <Grid item xs={12}>
                    <Box mb={2}>
                        <ShopAutocomplete
                            id="shopsAutocomplete"
                            slim
                            showClearButton
                            label={t('shopPage.shopNumber')}
                            suggestionsLimit={3}
                            onSuggestionSelect={selection => {
                                const shopNumber = isNil(selection) ? null : selection.number
                                const lastValue = editingTopologyShopsListInput ? editingTopologyShopsListInput + '\n' : ''
                                modifyEditingTopologyShopsListInput(`${lastValue}${shopNumber}`)
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="shopsNumbersTextField"
                        multiline
                        noClearBtn
                        rows={15}
                        style={{ padding: 0 }}
                        value={editingTopologyShopsListInput}
                        onValueChange={newValue => {
                            if (shopsNumbersRegex.test(newValue)) modifyEditingTopologyShopsListInput(newValue)
                        }}
                        placeholder={t('advertisingActions.affectedZoneDialog.enterShopsNumbers')}
                    />
                </Grid>
            </Grid>
        )
    }
}
