import { observable, action, runInAction, computed, toJS } from 'mobx'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE, SALE_GROUP_EDIT_STORE, APP_BAR_STORE, NAVIGATION_MENU_STORE } from '../stores'
import { UserStore } from '../user-store'
import { SaleGroupVO, createSaleGroupVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/sale-group-vo'
import { withSpinner } from '../with-spinner'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { LOYAL } from '../../core/sale-group-type'
import { ProductVO, createProductVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo'
import { t } from 'i18next'
import { SNACK_BAR_ERROR } from '../snackbar-store'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { LOYALTY, SALES_GROUPS, SALES_GROUPS_EDIT } from '../../core/app-routes'
import { cloneDeep, isArray, isEqual } from 'lodash'
import { SimpleProduct } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'

const createGroupProduct = (product: SimpleProduct): ProductVO => {
    return createProductVO({
        id: product.id,
        name: product.name,
        markingOfTheGood: product.code,
        price: Number(product.maxPrice) / 100
    })
}

export class SaleGroupEditStore {

    @observable
    saleGroups: SaleGroupVO[] = []

    @observable
    filterString: string = ''

    @observable
    saleGroup: SaleGroupVO

    @observable
    groupProducts: ProductVO[] = []

    @observable
    originalSaleGroup: SaleGroupVO

    @observable
    originalProducts: ProductVO[] = []

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    get session(): string {
        return this.userStore.session
    }

    @computed
    get filteredSaleGroups(): SaleGroupVO[] {
        if (!this.filterString) return this.saleGroups

        const usedFilter = this.filterString.trim().toLocaleLowerCase()
        const shownSaleGroups = this.saleGroups.filter(item => {
            return item.name?.trim().toLocaleLowerCase().indexOf(usedFilter) > -1
        })
        return shownSaleGroups
    }

    @computed
    get modified(): boolean {
        return !isEqual(toJS(this.saleGroup), toJS(this.originalSaleGroup))
            || !isEqual(toJS(this.groupProducts), toJS(this.originalProducts))
    }

    @computed
    get nameHaveSpaces(): boolean {
        if (!this.saleGroup) return false

        const name = this.saleGroup.name
        return name.search(' ') !== -1
    }

    goBack = () => {
        goTo(`${LOYALTY}${SALES_GROUPS}`)
    }

    fetchSaleGroups = async (): Promise<void> => {
        const saleGroups: SaleGroupVO[] = await withSpinner(
            iProductsManagerLocal.getSaleGroupsByType(this.userStore.session, LOYAL)
        )

        runInAction(() => {
            this.saleGroups = saleGroups
        })
    }

    @action
    updateSaleGroup = (changes: Partial<SaleGroupVO>): void => {
        Object.keys(changes).forEach(key => {
            this.saleGroup[key] = changes[key]
        })
    }

    @action
    addNewSaleGroup = (): void => {
        this.saleGroup = createSaleGroupVO({
            id: -1,
            name: '',
            amountOfProducts: 0
        })
        this.originalSaleGroup = undefined
        this.groupProducts = []
        this.originalProducts = []

        goTo(`${LOYALTY}${SALES_GROUPS}${SALES_GROUPS_EDIT}`)
    }

    @action
    editSaleGroup = async (item: SaleGroupVO): Promise<void> => {
        this.saleGroup = cloneDeep(item)
        this.originalSaleGroup = cloneDeep(item)

        goTo(`${LOYALTY}${SALES_GROUPS}${SALES_GROUPS_EDIT}`)

        const result = await withSpinner(iProductsManagerLocal.getProductsOfSaleGroup(
            this.userStore.session,
            item.code
        ))

        runInAction(() => {
            this.groupProducts = result
            this.originalProducts = cloneDeep(result)
        })
    }

    removeItem = async (item: SaleGroupVO): Promise<void> => {
        if (item.amountOfProducts > 0) {
            this.appStore.showSnackbar({ message: t('saleGroups.removeFail'), variant: SNACK_BAR_ERROR })
            return
        }

        await withSpinner(iProductsManagerLocal.removeSaleGroup(this.userStore.session, item.code))

        runInAction(() => {
            const newSaleGroups = [...this.saleGroups]

            const removeIndex = newSaleGroups.findIndex(group => group.code === item.code)
            if (removeIndex !== -1) {
                newSaleGroups.splice(removeIndex, 1)
            }

            this.saleGroups = newSaleGroups
        })
    }

    @action
    addProducts = (addedProducts: SimpleProduct | SimpleProduct[]): void => {
        let itemsToAdd: ProductVO[] = []
        if (isArray(addedProducts)) {
            itemsToAdd = addedProducts.map(createGroupProduct)
        } else {
            itemsToAdd.push(createGroupProduct(addedProducts))
        }

        itemsToAdd.forEach(newProduct => {
            if (!this.groupProducts.some(
                item => item.markingOfTheGood === newProduct.markingOfTheGood && item.id === newProduct.id
            )) {
                this.groupProducts.push(newProduct)
            }
        })
    }

    @action
    removeProducts = (removedProducts: SimpleProduct | SimpleProduct[]): void => {
        let itemsToRemove: ProductVO[] = []
        if (isArray(removedProducts)) {
            itemsToRemove = removedProducts.map(createGroupProduct)
        } else {
            itemsToRemove.push(createGroupProduct(removedProducts))
        }

        itemsToRemove.forEach(removedProduct => {
            const removedIndex = this.groupProducts.findIndex(
                item => item.markingOfTheGood === removedProduct.markingOfTheGood && item.id === removedProduct.id
            )

            if (removedIndex !== -1) {
                this.groupProducts.splice(removedIndex, 1)
            }
        })
    }

    @action
    removeAllProducts = (): void => {
        this.groupProducts = []
    }

    saveSaleGroup = async (): Promise<void> => {
        const session = this.userStore.session

        if (this.saleGroup.id === -1) {
            const newSaleGroup = await iProductsManagerLocal.addSaleGroup(session, toJS(this.saleGroup), LOYAL)

            runInAction(() => {
                this.saleGroup = newSaleGroup
                this.originalSaleGroup = cloneDeep(newSaleGroup)
            })
        } else {
            if (this.originalSaleGroup.name !== this.saleGroup.name) {
                const newSaleGroup = await iProductsManagerLocal.updateSaleGroupName(
                    session,
                    this.saleGroup.code,
                    this.saleGroup.name
                )
                runInAction(() => {
                    this.saleGroup = newSaleGroup
                    this.originalSaleGroup = cloneDeep(newSaleGroup)
                })
            }
        }

        if (this.groupProducts.length === 0 && this.originalProducts.length > 0) {
            // Удаляем всё
            await iProductsManagerLocal.removeAllProductsFromSaleGroup(
                session,
                this.saleGroup.code
            )
        } else {
            // Соханяем изменения в продуктах
            let removedProducts = []
            let addedProducts = []

            // Ищем продукты, которых не было в оригинальном наборе
            this.groupProducts.forEach(product => {
                const id = product.id
                const markingOfTheGood = product.markingOfTheGood

                const found = this.originalProducts.find(
                    originalProduct => originalProduct.id === id && originalProduct.markingOfTheGood === markingOfTheGood
                )

                if (!found) {
                    addedProducts.push(product)
                }
            })

            // Ищем продукты, которые пропали из оригинального набора
            this.originalProducts.forEach(originalProduct => {
                const id = originalProduct.id
                const markingOfTheGood = originalProduct.markingOfTheGood

                const found = this.groupProducts.find(
                    product => product.id === id && product.markingOfTheGood === markingOfTheGood
                )

                if (!found) {
                    removedProducts.push(originalProduct)
                }
            })

            if (addedProducts.length) {
                await iProductsManagerLocal.addProductsListToSaleGroup(
                    session,
                    addedProducts.map(item => item.markingOfTheGood),
                    this.saleGroup.code
                )
            }

            if (removedProducts.length) {
                await iProductsManagerLocal.removeProductsFromSaleGroup(
                    session,
                    removedProducts.map(item => item.markingOfTheGood),
                    this.saleGroup.code
                )
            }
        }

        runInAction(() => {
            this.originalProducts = toJS(this.groupProducts)
        })
    }

    @action
    setFilterString = (filter: string): void => {
        this.filterString = filter
    }

    @action
    resetEditedSaleGroup = (): void => {
        this.saleGroup = undefined
        this.originalSaleGroup = undefined
        this.groupProducts = []
        this.originalProducts = []
    }

    @action
    reset = (): void => {
        this.saleGroups = []
        this.filterString = ''
        this.saleGroup = undefined
        this.originalSaleGroup = undefined
        this.groupProducts = []
        this.originalProducts = []
    }
}

export const SALE_GROUP_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${LOYALTY}${SALES_GROUPS}${SALES_GROUPS_EDIT}`),
    onEnter: () => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const appStore: AppStore = getStore(APP_STORE)
        const saleGroupEditStore: SaleGroupEditStore = getStore(SALE_GROUP_EDIT_STORE)

        appBarStore.updateState({
            title: t('saleGroups.saleGroupTitle'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                if (saleGroupEditStore.modified) {
                    appStore.showDialog({
                        title: t('common.notSavedTitle'),
                        message: t('common.notSavedMessage'),
                        mode: DIALOG,
                        onYes: () => goTo(`${LOYALTY}${SALES_GROUPS}`)
                    })
                } else {
                    goTo(`${LOYALTY}${SALES_GROUPS}`)
                }
            }
        })
    },
    onLeave: () => {
        const saleGroupEditStore: SaleGroupEditStore = getStore(SALE_GROUP_EDIT_STORE)
        saleGroupEditStore.resetEditedSaleGroup()
    }
}
