import { CouponData } from '../advertising-actions'
import {
    Period,
    DiscountType
} from '../../../components/cash-printer-editor/coupon-print-settings-modal/coupon-print-settings-modal'

export function validateCoupon(result: CouponData): boolean {
    // Скидка
    if ((result?.discountValue?.[0] || result?.discountType?.[0] === DiscountType.Calculate)
        && result?.worksPeriod?.[0]
        && (result?.startsAfter?.[0] || result?.startsAfterPeriodType?.[0] === Period.Now)) {
        return true
    }

    // ШК
    if (result?.barCode?.[0] && result?.barCode?.[0] !== '') {
        return true
    }

    // Несерийный купон
    if (result?.couponTypeGuid?.[0]) {
        return true
    }

    return false
}
