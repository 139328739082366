import React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, ACTIONS_SEARCH_STORE, LOYALTY_SETTINGS_STORE } from '../../../../store/stores'
import { SideBarDrawer } from '@crystalservice/crystals-ui/lib/components/side-bar-drawer/side-bar-drawer'
import IconButton from '@material-ui/core/IconButton'
import { FilterIcon, ArrowStyle } from '@crystalservice/crystals-ui/lib/components/selectors/items-filtered-list/filter-icon'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { t } from 'i18next'
import { getSearchArguments } from '../../../../components/filters/side-bar-filters'
import Fab from '@material-ui/core/Fab'
import { goTo } from '../../../../utils/router-util'
import { LOYALTY, ACTIONS, ACTION_EDIT, NEW } from '../../../../core/app-routes'
import { AdvertisingAction } from '../../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import moment from 'moment'
import { Sticky } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/sticky'
import { AppStore } from '../../../../store/app-store'
import { ActionsSearchStore, WorksAnytimeOption } from '../../../../store/loyalty/actions/actions-search-store'
import { Timeline, TimelineItemProps, DEFAULT_CELL_WIDTH } from './timeline'
import { MONTH, DAY, WEEK, YEAR, DateTypes } from '../../../../../utils/date/date-format'
import { fromClientToServerTime, fromServerToClientTime } from '../../../../utils/app-util'
import { getStartOfInterval, getEndOfInterval } from '../../../../../utils/date-util'
import Button from '@material-ui/core/Button'
import { Add, ChevronLeft, ChevronRight, Today, Edit } from '@material-ui/icons'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { throttle } from 'lodash'
import { ActionsSearchFilters } from '../actions-search/actions-search-filters'
import { SCROLL_SYNC_DELAY, SHORT_TOOLTIP_DELAY } from '../../../../../utils/default-timeouts'
import { isIE11 } from '../../../../../utils/ie11-util'
import classNames from 'classnames'
import { ActionTimelineItemTooltip } from './action-timeline-item-tooltip'
import { LoyaltySettingsStore } from '../../../../store/loyalty/loyalty-settings-store'

const styles = require('./actions-timeline.scss')

interface ActionsTimelineProps {
    actionsSearchStore?: ActionsSearchStore
    loyaltySettingsStore?: LoyaltySettingsStore
    app?: AppStore
}

interface ActionsTimelineState {
    filterShown?: boolean
    scrollWidth?: number
}

@inject(ACTIONS_SEARCH_STORE)
@inject(LOYALTY_SETTINGS_STORE)
@inject(APP_STORE)
@observer
export class ActionsTimeline extends React.Component<ActionsTimelineProps, ActionsTimelineState> {

    fakeScrollRef: HTMLDivElement
    todayRef: HTMLDivElement
    headerRef: HTMLDivElement
    contentRef: HTMLDivElement

    state: ActionsTimelineState = {
        filterShown: false,
        scrollWidth: DEFAULT_CELL_WIDTH * 31
    }

    throttledUpdateScroll: ((scrollLeft: number) => void) = throttle((scrollLeft: number): void => {
        if (!this.fakeScrollRef) return
        this.fakeScrollRef.scrollLeft = scrollLeft
    }, SCROLL_SYNC_DELAY)

    componentDidMount(): void {
        const { fetchActions } = this.props.actionsSearchStore
        const { fetchSettings } = this.props.loyaltySettingsStore
        fetchActions()
        fetchSettings()
        this.setAllScrollbarsToToday()
    }

    handleEditAction = (id: number): void => {
        goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${id}`)
    }

    weekDateView = () => {
        const { selectedDate, interval } = this.props.actionsSearchStore

        const dateStart = moment(getStartOfInterval(selectedDate, interval))
        const dateEnd = moment(getEndOfInterval(selectedDate, interval))

        return (
            <>
                {`${dateStart.format('DD.MM.YYYY')} - ` +
                `${dateEnd.format('DD.MM.YYYY')}`}
            </>
        )
    }

    nextInterval = (): void => {
        const { selectedDate, interval, setDate, fetchActions } = this.props.actionsSearchStore

        setDate(moment(selectedDate).add(1, interval).toDate())
        this.setAllScrollbars(0)
    }

    prevInterval = (): void => {
        const { selectedDate, interval, setDate, fetchActions } = this.props.actionsSearchStore

        setDate(moment(selectedDate).subtract(1, interval).toDate())
        this.setAllScrollbars(0)
    }

    // Передаем скролл от внешнего фейк-скроллбара в timeline
    handleFakeScroll = (): void => {
        if (!this.fakeScrollRef) return

        const newScrollLeft = this.fakeScrollRef.scrollLeft

        if (this.todayRef) {
            this.todayRef.scrollLeft = newScrollLeft
        }
        if (this.headerRef) {
            this.headerRef.scrollLeft = newScrollLeft
        }
        if (this.contentRef) {
            this.contentRef.scrollLeft = newScrollLeft
        }
    }

    setAllScrollbars = (left: number = 0): void => {
        if (this.fakeScrollRef) {
            this.fakeScrollRef.scrollLeft = left
        }
        if (this.headerRef) {
            this.headerRef.scrollLeft = left
        }
        if (this.contentRef) {
            this.contentRef.scrollLeft = left
        }
        if (this.todayRef) {
            this.todayRef.scrollLeft = left
        }
    }

    // Устанавливаем скроллы, чтобы было видно "сегодня"
    setAllScrollbarsToToday = (): void => {
        const { scrollWidth } = this.state
        const { selectedDate, interval } = this.props.actionsSearchStore

        const nowTime = fromClientToServerTime(this.props.app.now()).getTime()
        const timelineStartTime = getStartOfInterval(selectedDate, interval).getTime()
        const timelineEndTime = getEndOfInterval(selectedDate, interval).getTime()

        const totalTime = timelineEndTime - timelineStartTime
        const timeInOnePixel = totalTime / scrollWidth

        const leftOffset = ((nowTime - timelineStartTime) / timeInOnePixel) - DEFAULT_CELL_WIDTH

        this.setAllScrollbars(leftOffset)
    }

    handleTodayButton = (): void => {
        const { setDate } = this.props.actionsSearchStore
        const now = fromClientToServerTime(this.props.app.now())

        setDate(now)
        this.setAllScrollbarsToToday()
    }

    getLabelText = (item: TimelineItemProps<AdvertisingAction>) => {
        return `[${item.data.externalCode}] ${item.data.name}`
    }

    getSecondaryText = (item: TimelineItemProps<AdvertisingAction>) => {
        const startText = moment(item.startDate).format('DD MMM YYYY')

        if (!item.endDate) {
            return `${t('common.fromStart')} ${startText}`
        }

        const endText = moment(item.endDate).format('DD MMM YYYY')
        return `${startText} - ${endText}`
    }

    render() {
        const {
            timelineFilteredActions, fetchActions, currentFiltersState, nameFilter, setNameFilter,
            selectedDate, interval, setInterval, worksAnytime, setWorksAnytime,
        } = this.props.actionsSearchStore
        const { settings } = this.props.loyaltySettingsStore
        const { filterShown, scrollWidth } = this.state

        const generalInteractionMethod = settings?.generalInteractionMethod

        const momentDate = moment(selectedDate)
        const now = moment(fromClientToServerTime(this.props.app.now()))
        const gotFiltersWithValue = currentFiltersState && getSearchArguments(currentFiltersState.shownFilters).length > 0

        return (
            <>
                <Paper style={{ padding: 16 }}>
                    <FilterInput
                        id="filterInput"
                        placeholder={t('actionsSearch.findByName')}
                        value={nameFilter}
                        onValueChange={setNameFilter}
                        onConfirm={fetchActions}
                        onClear={() => {
                            setNameFilter('')
                            fetchActions()
                        }}
                    />
                </Paper>

                <div className={styles.contentContainer}>
                    <div
                        className={classNames(styles.content, {
                            [styles.ie11Support]: isIE11
                        })}
                    >
                        <Paper className={styles.controlPanel}>
                            <Grid container spacing={2} alignItems={'center'}>
                                <Grid item>
                                    <SelectInput
                                        id="intervalSelect"
                                        value={interval}
                                        options={getIntervalOptions()}
                                        onSelect={newInterval => {
                                            setInterval(newInterval.value)
                                            this.setAllScrollbars(0)
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <SelectInput
                                        id="worksAnytime"
                                        value={worksAnytime}
                                        options={[
                                            {
                                                label: t('actionsSearch.notWorksAnytimeFull'),
                                                value: WorksAnytimeOption.FALSE
                                            },
                                            {
                                                label: t('actionsSearch.worksAnytimeFull'),
                                                value: WorksAnytimeOption.TRUE
                                            },
                                            {
                                                label: t('actionsSearch.allActions'),
                                                value: WorksAnytimeOption.ALL
                                            },
                                        ]}
                                        onSelect={option => {
                                            setWorksAnytime(option.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={'auto'} className={styles.ghostDiv} />
                                <Grid item>
                                    <Button
                                        onClick={this.handleTodayButton}
                                        className={styles.controlButton}
                                        id="todayButton"
                                        color="primary"
                                    >
                                        <Today/>
                                        <span className={styles.todayText}>{t('common.today')}</span>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <div className={styles.selectedInterval} id="selectedInterval">
                                        {interval === DAY && momentDate.format('DD MMM YYYY')}
                                        {interval === MONTH && momentDate.format('MMM YYYY')}
                                        {interval === WEEK && this.weekDateView()}
                                        {interval === YEAR && momentDate.format('YYYY')}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Tooltip
                                        title={interval === WEEK ?
                                            t('productDetails.salesPolicyEntrance.prevIntervalFemale', {
                                                interval: getIntervalTooltip()[interval]
                                            }) :
                                            t('productDetails.salesPolicyEntrance.prevIntervalMale', {
                                                interval: getIntervalTooltip()[interval]
                                            })
                                        }
                                    >
                                        <IconButton
                                            id="prevInterval"
                                            onClick={this.prevInterval}
                                            className={styles.navigateButton}
                                        >
                                            <ChevronLeft/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip
                                        title={interval === WEEK ?
                                            t('productDetails.salesPolicyEntrance.nextIntervalFemale', {
                                                interval: getIntervalTooltip()[interval]
                                            }) :
                                            t('productDetails.salesPolicyEntrance.nextIntervalMale', {
                                                interval: getIntervalTooltip()[interval]
                                            })
                                        }
                                    >
                                        <IconButton
                                            id="nextInterval"
                                            onClick={this.nextInterval}
                                            className={styles.navigateButton}
                                        >
                                            <ChevronRight/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        id="toggleFilterButton"
                                        className={styles.filterButton}
                                        onClick={() => this.setState(prevState => ({ filterShown: !prevState.filterShown }))}
                                    >
                                        <FilterIcon
                                            selected={gotFiltersWithValue}
                                            arrowStyle={filterShown ? ArrowStyle.RIGHT : ArrowStyle.LEFT}
                                        />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>

                        <div className={styles.timelineContainer} >
                            <Timeline
                                todayRef={ref => this.todayRef = ref}
                                headerRef={ref => this.headerRef = ref}
                                contentRef={ref => this.contentRef = ref}
                                onScrollLeftChange={this.throttledUpdateScroll}
                                onWidthChange={value => this.setState({ scrollWidth: value })}
                                now={now.toDate()}
                                worksAnyTime={worksAnytime}
                                items={timelineFilteredActions.map(action => {
                                    // Даты окончания может не быть (бессрочно)
                                    const endDate = action.workPeriod.finish && new Date(action.workPeriod.finish)

                                    // Для неактивных акций отображается иконка - черновик
                                    let icon
                                    if (!action.active) {
                                        icon = (
                                            <Tooltip
                                                title={t('actionsSearch.statuses.draft')}
                                                disableFocusListener
                                                enterDelay={SHORT_TOOLTIP_DELAY}
                                            >
                                                <Edit className={styles.editIcon}/>
                                            </Tooltip>
                                        )
                                    }

                                    return {
                                        id: String(action.guid),
                                        startDate: new Date(action.workPeriod.start),
                                        endDate,
                                        className: action.active ? styles[action.displayStyleName] : styles.inactive,
                                        data: action,
                                        icon,
                                        tooltip: <ActionTimelineItemTooltip action={action} generalInteractionMethod={generalInteractionMethod} />
                                    }
                                })}
                                labelFunction={this.getLabelText}
                                secondaryTextFunction={this.getSecondaryText}
                                timePeriod={{
                                    startDate: fromServerToClientTime(getStartOfInterval(selectedDate, interval)),
                                    endDate: fromServerToClientTime(getEndOfInterval(selectedDate, interval)),
                                    interval,
                                }}
                                itemOnDoubleClickFunction={item => this.handleEditAction(item.data.guid)}
                            />
                        </div>

                        <div className={styles.fabContainer}>
                            <div className={styles.ghostDiv}/>
                            <Sticky sticky={true}>
                                <div
                                    ref={ref => this.fakeScrollRef = ref}
                                    className={styles.actionPanel}
                                    onScroll={this.handleFakeScroll}
                                >
                                    <div
                                        className={styles.fakeScrollbar}
                                        style={{
                                            width: scrollWidth
                                        }}
                                    />
                                    <Fab
                                        id="addActionButton"
                                        className={styles.addButton}
                                        color="primary"
                                        onClick={() => {
                                            goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}${NEW}`)
                                        }}
                                    >
                                        <Add
                                            className={styles.icon}
                                            color="primary"
                                        />
                                    </Fab>
                                </div>
                            </Sticky>
                        </div>
                    </div>

                    <SideBarDrawer
                        normalMinWidth={2000}
                        normalClassName={styles.filters}
                        open={filterShown}
                        ModalProps={{
                            disableEnforceFocus: true
                        }}
                        onClose={() => this.setState(prevState => ({ filterShown: !prevState.filterShown }))}
                    >
                        <ActionsSearchFilters/>
                    </SideBarDrawer>
                </div>
            </>
        )
    }
}

const getIntervalOptions = (): Array<{ label: string, value: DateTypes }> => [
    {value: DAY, label: t('time.intervalOptions.day')},
    {value: WEEK, label: t('time.intervalOptions.week')},
    {value: MONTH, label: t('time.intervalOptions.month')},
    {value: YEAR, label: t('time.intervalOptions.year')},
]

const getIntervalTooltip = (): { [key: string]: string } => {
    return {
        [DAY]: t('time.intervalTooltip.day'),
        [WEEK]: t('time.intervalTooltip.week'),
        [MONTH]: t('time.intervalTooltip.month'),
        [YEAR]: t('time.intervalTooltip.year')
    }
}
