import { toJS, action, observable, computed, runInAction } from 'mobx'
import {
    AdvertisingAction,
    createAdvertisingAction,
} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { createTimestampPeriod } from '../../../protocol/set10/set-retail10-commons/data-structs-module/timestamp-period'
import { iDiscountsManagerLocal } from '../../../protocol/set10/i-discounts-manager-local'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import { UserStore } from '../../store/user-store'
import { AppStore } from '../../store/app-store'
import { getStore } from '../../store/stores-repository'
import { APP_STORE, USER_STORE, ACTION_SETTINGS_STORE, APP_BAR_STORE } from '../../store/stores'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { LOYALTY, ACTIONS, ACTION_EDIT, ACTIONS_SEARCH, ACTIONS_TIMELINE, FINISHED_ACTIONS_WITH_PRINTED_TAGS } from '../../core/app-routes'
import { COLOR_BLUE } from '../../core/color-utils'
import { isEqual, sortBy, cloneDeep } from 'lodash'
import { t } from 'i18next'
import moment from 'moment'
import {
    ClientConditions,
    Condition,
    findCondition,
    parseConditions,
    serializeConditions,
} from '../../core/advertising-actions/action-conditions'
import {
    AdvertisingActionParsed,
    serializeResults,
    parseResults,
    ActionStatus,
    getActionStatus,
} from '../../core/advertising-actions/advertising-actions'
import { InternalCardsVO as InternalCard } from '../../../protocol/set10/set-retail10-commons/data-structs-module/internal-cards-vo'
import { ExternalCardsVO as ExternalCard } from '../../../protocol/set10/set-retail10-commons/data-structs-module/external-cards-vo'
// tslint:disable-next-line
import { SetApiCardsProcessing as ExternalCardsProcessing } from '../../../protocol/set10/set-retail10-commons/data-structs-module/set-api-cards-processing'
import {
    convertSegmentFilterToTagsData,
    convertTagsDataToSegmentFilter,
} from '../../pages/loyalty/actions/action-edit/condition-editors/client/segment/segment-utils'
import { segmentService } from '../../../protocol/set10/segment-service'
import { iExternalCardsManagerRemote } from '../../../protocol/set10/i-external-cards-manager-remote'
import { AUTOMATIC } from '../../../protocol/set10/set-retail10-commons/data-structs-module/apply-mode'
import { cashManagerLocal } from '../../../protocol/set10/cash-manager-local'
import { CashTemplateVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-template-vo'
import { RoleVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/role-vo'
import { fromServerToClientTime, fromClientToServerTime } from '../../utils/app-util'
import { withSpinner } from '../with-spinner'
import { externalSystemsManagerRemote } from '../../../protocol/set10/external-systems-manager-remote'
import { ShopVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { createTopologyCondition, TopologyCondition } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition'
import { iTopologyManagerLocal } from '../../../protocol/set10/i-topology-manager-local'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import { FormatVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { getActionStatusChip } from '../../pages/loyalty/actions/action-edit/action-edit'
import { ProductsFindResponse } from '../../../protocol/set10/set-retail10-commons/data-structs-module/products-find-response'

export class ActionSettingsStore {
    @observable
    editedActionGuid: number

    @observable
    editedAction: AdvertisingActionParsed = undefined

    @observable
    actionDraft: AdvertisingAction = undefined

    @observable
    actionParent: AdvertisingAction = undefined

    @observable
    internalCards: InternalCard[] = undefined

    @observable
    externalCards: ExternalCard[] = undefined

    @observable
    externalCardsProcessings: ExternalCardsProcessing[] = undefined

    @observable
    isOpenPriorityEditor: boolean = false

    @observable
    cashTemplates: CashTemplateVO[] = null

    @observable
    cashierRoles: RoleVO[] = null

    @observable
    workingAllTime: boolean = false

    @observable
    editingTopologyConditions: TopologyCondition[] = []

    @observable
    editingTopologyShopsListInput: string = ''

    lastUsedPath: string = ''

    private origEditedAction: AdvertisingActionParsed = undefined

    private userStore: UserStore = getStore(USER_STORE)

    private appStore: AppStore = getStore(APP_STORE)

    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    private origWorkingAllTime: boolean = false

    @computed
    get setLoyaltyRestrictionsEnabled() {
        return this.appStore.editorsVisibilitySettings.actionResultQuantity
    }

    @computed
    get editedActionModified() {
        // TODO: SFM-863 Проверять на модификации вложенные XML-поля
        return !isEqual(toJS(this.editedAction), this.origEditedAction) || this.workingAllTime !== this.origWorkingAllTime
    }

    @computed
    get topologyConditions() {
        return this.editedAction?.topologyConditions
    }

    @action
    modifyTopologyConditions = (topologyConditions: TopologyCondition[]): void => {
        this.editingTopologyConditions = topologyConditions
    }

    @action
    modifyEditingTopologyShopsListInput = (shopsNumbers: string): void => {
        this.editingTopologyShopsListInput = shopsNumbers
    }

    @action
    resetTopologyConditionsChange = (): void => {
        this.editedAction.topologyConditions = this.origEditedAction.topologyConditions
    }

    loadAction = async (guid: number): Promise<AdvertisingActionParsed> => {
        const loadedAction = await iDiscountsManagerLocal.getAction(
            this.userStore.session,
            createAdvertisingAction({ guid })
        )

        if (!loadedAction) {
            goTo(`${LOYALTY}${ACTIONS}${ACTIONS_SEARCH}`)
            return
        }

        this.fetchLinkedActions(loadedAction)

        return this.parseLoadedAction(loadedAction)
    }

    fetchLinkedActions = async (action: AdvertisingAction): Promise<void> => {
        const draftActions = await iDiscountsManagerLocal.getDraftActionsByParent(
            this.userStore.session,
            action,
            0,
            1
        )

        const parentAction = await iDiscountsManagerLocal.getAction(
            this.userStore.session,
            createAdvertisingAction({ guid: action.parentGuid })
        )
        runInAction(() => {
            this.actionDraft = draftActions?.[0]
            this.actionParent = parentAction
        })
    }

    parseLoadedAction = async (action: AdvertisingAction): Promise<AdvertisingActionParsed> => {
        const externalConditionsParsed = await this.prepareSegmentCondition(
            await parseConditions(action)
        )
        const resultsParsed = await parseResults(action)
        return { ...action, externalConditionsParsed, resultsParsed }
    }

    fetchCashTemplates = async (): Promise<void> => {
        const cashTemplates = (await cashManagerLocal.getCashTemplates(this.userStore.session)) || []
        runInAction(() => {
            this.cashTemplates = cashTemplates
        })
    }

    prepareSegmentCondition = async (externalConditionsParsed: Condition[]): Promise<Condition[]> => {
        const segmentConditions = findCondition(
            ClientConditions.SegmentFilterCondition,
            externalConditionsParsed
        )

        if (segmentConditions) {
            const tagConditions = convertSegmentFilterToTagsData(
                segmentConditions && segmentConditions.data.filter
            )
            if (!tagConditions) return
            const fullSegments = await segmentService.getSegmentsByGuids(
                tagConditions
                    .filter(tagCondition => !!tagCondition.segment)
                    .map(tagCondition => tagCondition.segment.guid)
            )
            const fullTagConditions = tagConditions.map(tagCondition => {
                return {
                    ...tagCondition,
                    segment: fullSegments.find(
                        fullSegment =>
                            fullSegment.guid === (tagCondition.segment && tagCondition.segment.guid)
                    ),
                }
            })

            externalConditionsParsed.forEach((condition, index) => {
                if (condition.type === ClientConditions.SegmentFilterCondition) {
                    externalConditionsParsed[index].data = fullTagConditions
                }
            })
        }

        return externalConditionsParsed
    }

    @action
    loadInternalCards = async () => {
        const internalCards = await iCardsManagerRemote.getInternalCards(this.userStore.session, 0, 0)
        runInAction(() => {
            this.internalCards = sortBy(internalCards, ['name'])
        })
    }

    @action
    loadExternalCards = async () => {
        const processings = await iCardsManagerRemote.getSetApiCardsProcessings(this.userStore.session)
        const cards = await iExternalCardsManagerRemote.getExternalCards(this.userStore.session, 0, 0)
        runInAction(() => {
            this.externalCards = cards
            this.externalCardsProcessings = processings
        })
    }

    createActionDraft = (): AdvertisingActionParsed => {
        const now = this.appStore.now()

        let actionDraft = createAdvertisingAction({
            id: -1,
            name: t('advertisingActions.newActionName'),
            workPeriod: createTimestampPeriod({
                start: now,
                finish: moment(now)
                    .add(1, 'day')
                    .endOf('day')
                    .toDate(),
            }),
            useRestrictions: true,
            exemptFromBonusDiscounts: false,
            disableChargeOnBonuses: false,
            finalAction: false,
            worksAnytime: false,
            affectedShopsCount: 0,
            displayStyleName: COLOR_BLUE,
            labels: [],
            externalConditions: [],
            mode: AUTOMATIC,
        })

        // На ритейле сразу проставляем текущий магазин
        if (!this.appStore.isCentrum) {
            const shopsTopology: ShopVO[] = this.appStore.topologyMap?.shops
            actionDraft.topologyConditions = [
                createTopologyCondition({
                    shops: shopsTopology,
                    shopsCityGroups: [],
                    shopsRegionGroups: []
                })
            ]
        }

        return { ...actionDraft, externalConditionsParsed: [], resultsParsed: [] }
    }

    @action
    openAction = async (guid: number): Promise<AdvertisingActionParsed> => {
        const isNew = guid === -1
        const action = isNew ? this.createActionDraft() : await this.loadAction(guid)
        await this.fetchCashTemplates()
        this.editedActionGuid = guid

        return this.setAction(action)
    }

    @action
    openActionWithoutRedirect = async (guid: number): Promise<AdvertisingActionParsed> => {
        const isNew = guid === -1
        const action = isNew ? this.createActionDraft() : await this.loadAction(guid)
        await this.fetchCashTemplates()
        this.editedActionGuid = guid

        return this.setAction(action)
    }

    @action
    setAction = (action: AdvertisingActionParsed) => {
        const isNew = action.guid === -1
        if (!isNew && action.workPeriod) {
            action.workPeriod.start = fromClientToServerTime(new Date(action.workPeriod.start))

            if (action.workPeriod.finish) {
                action.workPeriod.finish = fromClientToServerTime(new Date(action.workPeriod.finish))
            }
        }
        runInAction(() => {
            const workingAllTime = this._isWorkingAllTime(action)

            this.workingAllTime = workingAllTime
            this.origWorkingAllTime = workingAllTime
            this.editedAction = action
            this.origEditedAction = toJS(this.editedAction)
            this.editingTopologyConditions = toJS(this.editedAction.topologyConditions)
        })

        return action
    }

    @action
    initCashierRoles = (): void => {
        this.cashierRoles = []
    }

    @action
    resetCashierRoles = (): void => {
        this.cashierRoles = null
    }

    @action
    updateCashierRoles = (roles: RoleVO[]): void => {
        this.cashierRoles = roles
    }

    @action
    modifyEditedAction = async (modification: Partial<AdvertisingActionParsed>): Promise<void> => {
        // В уже запущенной акции создаем драфт
        if (this.editedAction.active) {
            const status = getActionStatus(this.editedAction)

            if (status === ActionStatus.CURRENT) {
                // для всех случаев, кроме изменения даты
                if (!(Object.keys(modification).length === 1 && modification.workPeriod)) {
                    // Создаем черновик запущенной акции
                    this.editedAction = { ...this.editedAction, ...modification }
                    this.actionParent = { ...this.editedAction }

                    await withSpinner(this.saveEditedAction())

                    const actionParent = await iDiscountsManagerLocal.getAction(
                        this.userStore.session,
                        createAdvertisingAction({ guid: this.editedAction.parentGuid })
                    )

                    runInAction(() => {
                        this.actionParent = actionParent
                    })

                    this.updateAppBar(this.editedAction)
                    this.appStore.showSnackbar({
                        message: t('advertisingActions.newActionCreated')
                    })

                    return
                }
            }
        }

        this.editedAction = { ...this.editedAction, ...modification }

    }

    @action
    modifyWorkingAllTime = (workingAllTime: boolean) => {
        this.workingAllTime = workingAllTime
    }

    prepareActionForSave = async (action: AdvertisingActionParsed): Promise<AdvertisingAction> => {
        const isNew = action.id === -1

        let workPeriod = { ...action.workPeriod }
        workPeriod.start = fromServerToClientTime(workPeriod.start)
        workPeriod.finish = fromServerToClientTime(workPeriod.finish)

        let rawAction: AdvertisingActionParsed = {
            ...toJS(action),
            allNodes: Boolean(action.allNodes),
            workPeriod
        }

        if (rawAction.allNodes) {
            rawAction.topologyConditions = []
        }

        const segmentConditionsIndex = rawAction.externalConditionsParsed.findIndex(
            externalCondition => {
                return externalCondition.type === ClientConditions.SegmentFilterCondition
            }
        )

        if (segmentConditionsIndex !== -1) {
            const segmentFilter = convertTagsDataToSegmentFilter(
                toJS(rawAction.externalConditionsParsed[segmentConditionsIndex].data)
            )
            rawAction.externalConditionsParsed[segmentConditionsIndex].data = {
                filter: segmentFilter,
            }
        }

        if (isNew) {
            const newAction = await iDiscountsManagerLocal.createNewAction(this.userStore.session, null)

            const { id, guid } = newAction
            const externalConditions = serializeConditions(rawAction.externalConditionsParsed)
            const { actionResults, applyObjects } = serializeResults(rawAction)
            return Object.keys(rawAction).reduce((acc, k) => {
                if (acc[k] || k === 'resultsParsed' || k === 'externalConditionsParsed') return acc
                return {
                    ...acc,
                    [k]: rawAction[k] !== undefined ? rawAction[k] : newAction[k],
                }
            }, createAdvertisingAction({ id, guid, externalConditions, actionResults, applyObjects }))
        }

        const externalConditions = serializeConditions(rawAction.externalConditionsParsed)
        const { actionResults, applyObjects } = serializeResults(rawAction)

        delete rawAction.externalConditionsParsed
        delete rawAction.resultsParsed

        return {
            ...rawAction,
            externalConditions,
            actionResults,
            applyObjects,
        }
    }

    @action
    saveEditedAction = async () => {
        const isNew = this.editedAction.id === -1

        const action = await this.prepareActionForSave(this.editedAction)

        const savedAction = await iDiscountsManagerLocal.storeAction(this.userStore.session, action)
        const parsedAction = await this.parseLoadedAction(savedAction)
        this.editedActionGuid = parsedAction.guid
        /**
         * После сохранения надо повысить версию т.к. на бэке она уже на 1 выше
         * Если этого не сделать, и попытаться у запущенной акции два раза подряд поменять дату, то она создаст черновик
         * из-за отличия поля version
         */
        this.setAction({
            ...parsedAction,
            version: parsedAction.version + 1
        })

        if (savedAction) {
            this.appStore.showSnackbar({
                message: t('advertisingActions.actionSaved'),
                variant: 'success'
            })
        }

        if (isNew) {
            goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${parsedAction.guid}`)
        }
    }

    @action
    getProductsByCodes = async (codes: string[]): Promise<ProductsFindResponse> =>
        productManagerFinderLocal.getSimpleProductsByCodes1(this.userStore.session, codes)

    @action
    showPriorityEditor = (openPriorityEditorState: boolean) => {
        this.isOpenPriorityEditor = openPriorityEditorState
    }

    @action
    updateActionsPriority = async (params: {
        editedAction: AdvertisingAction
        actionBefore: AdvertisingAction
        actionAfter: AdvertisingAction
    }): Promise<void> => {
        const { editedAction, actionBefore, actionAfter } = params
        if (actionAfter) {
            await withSpinner(iDiscountsManagerLocal.makeActionBefore(this.userStore.session, toJS(editedAction), toJS(actionAfter)))

            return
        }

        if (actionBefore) {
            await withSpinner(iDiscountsManagerLocal.makeActionAfter(this.userStore.session, toJS(editedAction), toJS(actionBefore)))
            return
        }
    }

    _isWorkingAllTime = (action: AdvertisingActionParsed | AdvertisingAction): boolean =>
        !action?.workPeriod || !action?.workPeriod?.start || !action?.workPeriod?.finish

    deleteEditedAction = async (): Promise<void> => {
        if (this.editedAction.id !== -1) {
            await withSpinner(iDiscountsManagerLocal.deleteDraftAction(this.userStore.session, toJS(this.editedAction)))
        }
        this.closeEditedAction()
    }

    stopEditedAction = async (): Promise<void> => {
        if (this.editedAction.id) {
            await withSpinner(iDiscountsManagerLocal.makeActionInactive(this.userStore.session, toJS(this.editedAction)))
        }
        this.closeEditedAction()
    }

    @action
    setLastUsedPath = (path: string): void => {
        this.lastUsedPath = path
    }

    @action
    updatePriorityOfCurrentAction = async (): Promise<void> => {
        const action = await iDiscountsManagerLocal.getAction(
            this.userStore.session,
            this.editedAction
        )

        const priority = action.priority

        this.origEditedAction.priority = priority
        this.editedAction.priority = priority
    }

    closeEditedAction = (): void => {
        if (this.lastUsedPath) {
            goTo(this.lastUsedPath)
        } else {
            goTo(`${LOYALTY}${ACTIONS}`)
        }
        this.editedAction = undefined
        this.origEditedAction = undefined
    }

    activateEditedAction = async (): Promise<void> => {
        await withSpinner(async () => {
            const action = await this.prepareActionForSave(this.editedAction)
            await iDiscountsManagerLocal.makeActionActive(this.userStore.session, action)
        })

        this.closeEditedAction()
    }

    cloneEditedAction = async (): Promise<AdvertisingActionParsed> => {
        const action = cloneDeep(toJS(this.editedAction))
        action.parentGuid = NaN
        action.name = `${action.name} - ${t('advertisingActions.copy')}`

        const clonedAction = await withSpinner(iDiscountsManagerLocal.createNewAction(
            this.userStore.session,
            action
        ))
        const parsedAction = await this.parseLoadedAction(clonedAction)

        this.editedActionGuid = parsedAction.guid
        this.setAction(parsedAction)

        goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${clonedAction.guid}`)
        return Promise.resolve(parsedAction)
    }

    updateAppBar = (action: AdvertisingAction): void => {
        this.appBarStore.updateState({
            title: action.name,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: this.onCancelClick,
            additionalContent: getActionStatusChip(action),
        })
    }

    onCancelClick = (): void => {
        if (this.editedActionModified) {
            this.appStore.showDialog({
                title: t('advertisingActions.notSavedChangesAction'),
                message: t('advertisingActions.notSavedChangesMessage'),
                onYes: this.closeEditedAction,
                mode: DIALOG,
            })
            return
        }

        this.closeEditedAction()
    }

    reset = (): void => {
        this.editedAction = undefined
        this.origEditedAction = undefined
        this.actionDraft = undefined
        this.actionParent = undefined
        this.origWorkingAllTime = false
        this.editedActionGuid = undefined
        this.internalCards = undefined
        this.externalCards = undefined
        this.externalCardsProcessings = undefined
        this.cashTemplates = null
        this.cashierRoles = null
        this.isOpenPriorityEditor = false
        this.workingAllTime = false
        this.lastUsedPath = ''
    }
}

export const ACTION_SETTINGS_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${LOYALTY}${ACTIONS}${ACTION_EDIT}/\\w+$`),
    onEnter: (newRoute: string, prevRoute: string) => {
        const actionSettingStore: ActionSettingsStore = getStore(ACTION_SETTINGS_STORE)
        if (
            prevRoute === `${LOYALTY}${ACTIONS}${ACTIONS_SEARCH}` ||
            prevRoute === `${LOYALTY}${ACTIONS}${ACTIONS_TIMELINE}` ||
            prevRoute === `${LOYALTY}${FINISHED_ACTIONS_WITH_PRINTED_TAGS}`
        ) {
            actionSettingStore.setLastUsedPath(prevRoute)
        }
    },
    onLeave: next => {
        if (next.match(new RegExp(`^${LOYALTY}${ACTIONS}${ACTION_EDIT}/.*$`))) return
        const actionSettingsStore: ActionSettingsStore = getStore(ACTION_SETTINGS_STORE)
        actionSettingsStore.reset()
    },
}
