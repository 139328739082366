import React from 'react'
import { XMLTagBaseElement } from '../../core/price-tags/xml/xml-tag-base-element'

export interface BasePriceTagElement extends React.HTMLProps<HTMLElement> {
    element: XMLTagBaseElement
    scale: number
}

export const withPositionalStyleProps = <T extends BasePriceTagElement>(
    WrappedElement: React.ComponentType<T>
) => (
    class WithPositionalStyle extends React.Component<T> {
        render() {
            const { element, scale } = this.props
            const { a, b, c, d, tx, ty } = element.transform

            return (
                <WrappedElement
                    {...this.props as T}
                    style={{
                        zIndex: element.zOrder,
                        width: element.width * scale,
                        height: element.height * scale,
                        transform: `matrix(${a}, ${b}, ${c}, ${d}, ${tx * scale}, ${ty * scale})`,
                        transformOrigin: '0 0',
                        ...(this.props.style as object || {})
                    }}
                />
            )
        }
    }
)
