import { action, computed, observable, runInAction, toJS } from 'mobx'
import { iUserManagementLocal } from '../../../protocol/set10/i-user-management-local'
import { TopologyConditionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition-vo'
import { UserRoleVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/user-role-vo'
import { UserVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import { STAFF, USER } from '../../core/app-routes'
import { NEW } from '../../core/values'
import { goTo } from '../../utils/router-util'
import { AppStore } from '../app-store'
import { APP_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { t } from 'i18next'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { getUserFullName } from '../../../utils/name-util'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'

export const ACTIVE_USERS: string = 'activeUsers'
export const BLOCKED_USERS: string = 'blockedUsers'

export class UsersStore {
    @observable
    currentTab: string = ACTIVE_USERS
    @observable
    activeUsers: UserVO[]
    @observable
    blockedUsers: UserVO[]
    @observable
    nameFilter: string = ''
    @observable
    roleFilter: UserRoleVO = null
    @observable
    roles: UserRoleVO[]
    @observable
    topologyFilter: TopologyConditionVO[] = []
    @observable
    activeUsersPagination: PaginationState = undefined
    @observable
    blockedUsersPagination: PaginationState = undefined

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    @action
    changeActiveTab = (tab: string): Promise<UserVO[]> => {
        this.currentTab = tab
        return this.fetchUsers()
    }

    fetchAvailableRoles = (): Promise<any> => {
        if (this.appStore.isCentrum) {
            return iUserManagementLocal.getUserRoleList(this.userStore.session)
                .then(roles => {
                    runInAction(() => {
                        this.roles = roles
                    })
                })
        } else {
            return iUserManagementLocal.getUserRoles(this.userStore.session, false)
                .then(roles => {
                    runInAction(() => {
                        this.roles = roles
                    })
                })
        }
    }

    fetchData = async (): Promise<void> => {
        await Promise.all([
            this.fetchActiveUsers(),
            this.fetchBlockedUsers(),
            this.fetchAvailableRoles(),
        ])
    }

    fetchUsers = (): Promise<UserVO[]> => {
        if (this.currentTab === ACTIVE_USERS) {
            return this.fetchActiveUsers()
        } else {
            return this.fetchBlockedUsers()
        }
    }

    @action
    fetchActiveUsers = async (): Promise<UserVO[]> => {
        if (this.topologyFilter.length > 0) {
            const users = await iUserManagementLocal.getUsersForTopologyConditions(toJS(this.topologyFilter), false)
            let newUsers = []
            let usedIds = {}
            users.forEach(user => {
                if (!usedIds[user.id]) {
                    usedIds[user.id] = true
                    newUsers.push(user)
                }
            })

            this.activeUsers = newUsers
            return newUsers
        } else {
            const users = await iUserManagementLocal.getActiveUserList(this.userStore.session)
            this.activeUsers = users
            return users
        }
    }

    fetchBlockedUsers = (): Promise<UserVO[]> => {
        if (this.topologyFilter.length > 0) {
            return iUserManagementLocal.getUsersForTopologyConditions(toJS(this.topologyFilter), true)
                .then(users => {
                    // При привязке одного пользователя к нескольким магазинам сервер в ответе вернет пользователя 2 раза
                    let newUsers = []
                    let usedIds = {}
                    users.forEach(user => {
                        if (!usedIds[user.id]) {
                            usedIds[user.id] = true
                            newUsers.push(user)
                        }
                    })
                    runInAction(() => {
                        this.blockedUsers = newUsers
                    })
                    return newUsers
                })
        } else {
            return iUserManagementLocal.getBlockedUserList(this.userStore.session)
                .then(users => {
                    runInAction(() => {
                        this.blockedUsers = users
                    })
                    return users
                })
        }
    }

    @computed
    get filteredActiveUsers(): UserVO[] {
        if (!this.activeUsers) return []
        return this.filterUsers(this.activeUsers, this.nameFilter, this.roleFilter)
    }

    @computed
    get filteredBlockedUsers(): UserVO[] {
        if (!this.blockedUsers) return []
        return this.filterUsers(this.blockedUsers, this.nameFilter, this.roleFilter)
    }

    @computed
    get activeUsersPaginatorKey(): string {
        return this.filteredActiveUsers ? this.filteredActiveUsers.map(user => {
            return user.userRoles.map(userRole => userRole.id).join(';') + 'user' + user.id
        }).join(';') : ''
    }

    @computed
    get blockedUsersPaginatorKey(): string {
        return this.filteredBlockedUsers ? this.filteredBlockedUsers.map(user => {
            return user.userRoles.map(userRole => userRole.id).join(';') + 'user' + user.id
        }).join(';') : ''
    }

    @action
    changeFilters = (nameFilter: string, roleFilter: UserRoleVO): void => {
        this.nameFilter = nameFilter
        this.roleFilter = roleFilter
    }

    @action
    changeTopologyFilter = (filter: TopologyConditionVO[]): void => {
        this.topologyFilter = filter
        this.fetchUsers()
    }

    blockUser = async (blockingUser: UserVO): Promise<void> => {
        if (this.userStore.userInfo.id === blockingUser.id) {
            this.appStore.showDialog({
                title: t('staff.blockSelfTitle'),
                message: t('staff.blockSelfMessage'),
                mode: DIALOG,
                onYes: async () => {
                    await iUserManagementLocal.blockedUser(this.userStore.session, toJS(blockingUser))
                    await this.fetchUsers()
                    this.userStore.logout()
                }
            })
            return Promise.resolve()
        }

        await iUserManagementLocal.blockedUser(this.userStore.session, toJS(blockingUser))
        await this.fetchUsers()
    }

    unblockUser = async (unblockingUser: UserVO): Promise<UserVO[]> => {
        await iUserManagementLocal.updateUser(this.userStore.session, {
            ...toJS(unblockingUser),
            blockedStatus: false
        })
        return this.fetchUsers()
    }

    addNewUser = (): void => {
        goTo(`${STAFF}${USER}/${NEW}`)
    }

    openUser = (user: UserVO): void => {
        goTo(`${STAFF}${USER}/${user.id}`)
    }

    filterUsers = (users: UserVO[], nameFilter: string, roleFilter: UserRoleVO): UserVO[] => {
        const trimmedFilter = nameFilter.trim().toLowerCase()

        return users.filter(user => {
            if (trimmedFilter.length > 0) {
                const userFullName = getUserFullName(user).toLowerCase()
                if (userFullName.indexOf(trimmedFilter) === -1) {
                    return false
                }
            }

            if (roleFilter) {
                const roleFilterId = roleFilter.id

                if (user.userRoles.length === 0) return false
                if (!user.userRoles.some(role => role.id === roleFilterId)) return false
            }

            return true
        })
    }

    setActiveUsersPagination = (state: PaginationState): void => {
        this.activeUsersPagination = state
    }
    setBlockedUsersPagination = (state: PaginationState): void => {
        this.blockedUsersPagination = state
    }

    @action
    reset = (): void => {
        this.currentTab = ACTIVE_USERS
        this.activeUsers = undefined
        this.blockedUsers = undefined
        this.nameFilter = ''
        this.roleFilter = null
        this.roles = undefined
        this.topologyFilter = []
        this.activeUsersPagination = undefined
        this.blockedUsersPagination = undefined
    }
}
