import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { AppStore } from '../../../store/app-store'
import { t } from 'i18next'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { BarCodeVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/bar-code-vo'
import { Tabs } from '../../../../components/tabs/tabs'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import { SPIRIT, WEIGHT, WEIGHT_PIECE } from '../../../core/products/product-types'
import { BindingInfoVO } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/binding-info-vo'
import { Table, ColumnProps, CENTER, TOP } from '@crystalservice/crystals-ui/lib'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { formatPrice } from '../../../core/currency-utils'
import { Typography } from '@material-ui/core'
import moment from 'moment'

const MARKED_ICON = require('../../../../assets/images/icons/product/marked.svg')

const styles = require('./barcodes.scss')

export interface AlcoAndBarCodesProps {
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
    sm?: boolean
}

@inject(APP_STORE)
@inject(PRODUCT_DETAILS_STORE)
@observer
export class AlcoAndBarCodes extends React.Component<AlcoAndBarCodesProps> {
    componentDidMount(): void {
        this.initProduct()
    }

    initProduct = async (): Promise<void> => {
        const { fetchDetails, productDetails } = this.props.productDetailsStore
        if (!productDetails) await fetchDetails()
    }

    getBindingInfo = (barCode: BarCodeVO): BindingInfoVO[] => {
        const productBindings = this.props.productDetailsStore.productBindings

        return productBindings && productBindings.filter(
            binding => binding.fullBarcode === barCode.value
        )
    }

    renderBarCodes() {
        const { productDetails, deleteBarCode } = this.props.productDetailsStore
        const { showDialog } = this.props.app

        if (!productDetails.barcodes) return <PageLoader/>
        if (!productDetails.barcodes.length) return <Empty/>

        const commonColumns: ColumnProps[] = [
            {
                width: '40px',
                padding: '0px',
                hAlign: CENTER,
                renderer: ({ item }: { item: BarCodeVO }) => {
                    return item.general ? <CheckIcon className={styles.barcodeCheckIcon} /> : ''
                }
            },
            {
                header: t('productDetails.barcodesEntrance.barCode'),
                renderer: props => {
                    const barcode: BarCodeVO = props.item

                    return (
                        <div>
                            <div>{barcode.value}</div>
                            {barcode.price && (
                                <Typography
                                    color="secondary"
                                    variant="caption"
                                >
                                    {t('productDetails.barcodesEntrance.discount')}
                                </Typography>
                            )}
                        </div>
                    )
                },
                vAlign: TOP,
            },
        ]

        const weightColumns: ColumnProps[] = [
            {
                header: t('productDetails.barcodesEntrance.scaleTemplates'),
                renderer: props => {
                    const barcode: BarCodeVO = props.item
                    const bindingInfo = this.getBindingInfo(barcode)

                    return (
                        <div className={styles.bindingInfo}>
                            {bindingInfo && bindingInfo.map((item, index) => {
                                return (
                                    <div className={styles.bindingInfoItem} key={`${item.templateName}_${index}`}>
                                        {item ? item.templateName : '—'}
                                    </div>
                                )
                            })}
                        </div>
                    )
                },
                vAlign: TOP,
            },
            {
                header: t('productDetails.barcodesEntrance.scales'),
                renderer: props => {
                    const barcode: BarCodeVO = props.item
                    const bindingInfo = this.getBindingInfo(barcode)

                    return (
                        <div className={styles.bindingInfo}>
                            {bindingInfo && bindingInfo.map((item, index) => {
                                return (
                                    <div className={styles.bindingInfoItem} key={`${item.scalesNumbers}_${index}`}>
                                        {item ? item.scalesNumbers : '—'}
                                    </div>
                                )
                            })}
                        </div>
                    )
                },
                vAlign: TOP,
            },
            {
                header: t('productDetails.barcodesEntrance.pluNumber'),
                renderer: props => {
                    const barcode: BarCodeVO = props.item
                    const bindingInfo = this.getBindingInfo(barcode)

                    return (
                        <div className={styles.bindingInfo}>
                            {bindingInfo && bindingInfo.map((item, index) => {
                                return (
                                    <div className={styles.bindingInfoItem} key={`${item.pluNumber}_${index}`}>
                                        {item ? item.pluNumber : '—'}
                                    </div>
                                )
                            })}
                        </div>
                    )
                },
                vAlign: TOP,
            },
        ]
        const pieceColumns: ColumnProps[] = [
            {
                header: t('common.price'),
                labelFunction: (barcode: BarCodeVO) => barcode.price ? barcode.price.value : '—',
                vAlign: TOP,
            },
            {
                header: t('common.quantity'),
                labelFunction: (barcode: BarCodeVO) => String(barcode.amount / 1000),
                vAlign: TOP,
            },
            {
                header: t('common.name'),
                labelFunction: (barcode: BarCodeVO) => barcode.name ? barcode.name : '—',
                vAlign: TOP,
            },
            {
                header: t('productDetails.barcodesEntrance.workPeriod'),
                renderer: props => {
                    const barcode: BarCodeVO = props.item
                    const formattedStart = barcode?.price?.dateStart && moment(barcode.price.dateStart).format('DD.MM.YYYY')
                    const formattedEnd = barcode?.price?.dateEnd && moment(barcode.price.dateEnd).format('DD.MM.YYYY')

                    const start = formattedStart || 'N/A'
                    const end = formattedEnd || 'N/A'

                    return formattedStart || formattedEnd ? `${start} - ${end}` : '—'
                },
                vAlign: TOP,
            }
        ]

        const markingColumn: ColumnProps = {
            header: t('productDetails.marking'),
            padding: '0px',
            hAlign: CENTER,
            renderer: props => {
                const barcode: BarCodeVO = props.item
                return barcode.marked ? <img src={MARKED_ICON} alt="MARKED" /> : ''
            },
        }

        const deleteButton: ColumnProps = {
            renderer: props => {
                const barcode: BarCodeVO = props.item

                return (
                    <AdaptiveIconButton
                        id={`deleteBarCode${barcode.value}`}
                        label={t('common.remove')}
                        onClick={() => {
                            showDialog({
                                mode: DIALOG,
                                message: t('productDetails.barcodesEntrance.deleteBarConfirmation'),
                                onYes: () => {
                                    deleteBarCode(barcode)
                                }
                            })
                        }}
                    >
                        <DeleteIcon />
                    </AdaptiveIconButton>
                )
            },
            padding: '0 10px',
            showOnRowHover: true,
            withControls: true,
            width: '70px'
        }

        // Скрываем первую колонку на низких разрешениях экрана
        if (this.props.sm) commonColumns.shift()

        return (
            <Table
                id="barCodesTable"
                withHeader
                keyField="id"
                items={productDetails.barcodes}
                columns={(productDetails.productType === WEIGHT || productDetails.productType === WEIGHT_PIECE) ?
                    [...commonColumns, ...weightColumns, markingColumn, deleteButton] :
                    [...commonColumns, ...pieceColumns, markingColumn, deleteButton]
                }
            />
        )
    }
    renderAlcoCodes() {
        const { alcocodes } = this.props.productDetailsStore

        if (!alcocodes) return <PageLoader/>
        if (!alcocodes.length) return <Empty/>

        return (
            <Table
                id="alcoCodesTable"
                withHeader
                keyField="id"
                items={alcocodes}
                columns={[
                    {
                        header: t('productDetails.barcodesEntrance.alcoCode'),
                        labelFunction: item => item
                    }
                ]}
            />
        )
    }

    render() {
        const { productDetails } = this.props.productDetailsStore
        if (!productDetails) return null

        if (productDetails.productType !== SPIRIT) return this.renderBarCodes()

        return (
            <div>
                <Tabs
                    items={[
                        t('productDetails.barcodesEntrance.barCodes'),
                        t('productDetails.barcodesEntrance.alcoCodes')
                    ]}
                >
                    {this.renderBarCodes()}
                    {this.renderAlcoCodes()}
                </Tabs>
            </div>
        )
    }
}

export default (props: AlcoAndBarCodesProps) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  return <AlcoAndBarCodes {...props} sm={sm} />
}
