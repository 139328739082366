import { CashType } from './cash-type'

export interface MenuVO {
    '@class': 'ru.crystals.setretailx.cash.menu.MenuVO'
    id?: number
    number?: number
    xmlContent?: string
    cashTemplateId?: number
    cashTemplateGuid?: number
    name?: string
    content?: string
    contentInfo?: string
    timeFrom?: Date
    timeTo?: Date
    dateFrom?: Date
    dateTo?: Date
    dayOfWeek?: number
    guid?: string
    parentGuid?: string
    deleted?: boolean
    type?: string
    cashType?: CashType
}

export const createMenuVO = (initialization: Partial<MenuVO>): MenuVO => {
    if (!initialization) return null
    
    let result: MenuVO = {
        id: initialization.id,
        number: initialization.number,
        xmlContent: initialization.xmlContent,
        cashTemplateId: initialization.cashTemplateId,
        cashTemplateGuid: initialization.cashTemplateGuid,
        name: initialization.name,
        content: initialization.content,
        contentInfo: initialization.contentInfo,
        timeFrom: initialization.timeFrom,
        timeTo: initialization.timeTo,
        dateFrom: initialization.dateFrom,
        dateTo: initialization.dateTo,
        dayOfWeek: initialization.dayOfWeek,
        guid: initialization.guid,
        parentGuid: initialization.parentGuid,
        deleted: initialization.deleted,
        type: initialization.type,
        cashType: initialization.cashType,
        '@class': 'ru.crystals.setretailx.cash.menu.MenuVO',
    }
    
    return result
}
