import { observable, runInAction, computed, action, toJS } from 'mobx'
import { t } from 'i18next'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, PRICE_TAGS_PRINTING_STORE, } from '../stores'
import { priceTagFacadeLocal } from '../../../protocol/set10/price-tag-facade-local'
import { TaskVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/task-vo'
import { config } from '../../config/config'
import { ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED } from '../../core/products/product-statuses'
import { withSpinner } from '../with-spinner'
import {
    EMPTY_FILTER_VALUE, RUNNING_ACTIONS_FILTER
} from '../../core/filters/filter'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import uuid from 'uuid'
import { XLSX } from '../../core/file-types'
import { PRICE_TAGS, NOT_PRINTED, PRINTING, LOYALTY, ACTIONS, ACTION_EDIT } from '../../core/app-routes'
import { PriceTagsPrintingStore } from './printing/printing-store'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { fromClientToServerTime } from '../../utils/app-util'
import { SideBarFiltersState, getSearchArguments } from '../../components/filters/side-bar-filters'
import { goTo } from '../../utils/router-util'
import { chunkedLoading } from '../../core/chunked-loading'
import { DEFAULT_PRICE_TAGS_PAGINATION } from '../../core/price-tags/price-tags-util'
import isNil from 'lodash/isNil'
import { isEqual } from 'lodash'

const getDefaultFilterState = (): SideBarFiltersState => {
    return {
        shownFilters: {
            [RUNNING_ACTIONS_FILTER]: { value: EMPTY_FILTER_VALUE }
        },
    }
}

export class NotPrintedActionPriceTagsStore {
    @observable
    tasks: TaskVO[] = []

    @observable
    tasksChanges: Map<number, string> = new Map<number, string>()

    @observable
    currentFiltersState: SideBarFiltersState = getDefaultFilterState()

    @observable
    selectedTasks: number[] = []

    @observable
    pagination: PaginationState = DEFAULT_PRICE_TAGS_PAGINATION

    searchArguments: SearchArgumentVO[] = []

    @computed
    get displayedTasks(): TaskVO[] {
        return this.tasks.map(task => {
            // Применяем изменения в имени объекта
            let changes: Partial<TaskVO> = {}
            const newName = this.tasksChanges.get(task.id)
            if (newName) {
                changes.nameOfGood = newName
            }
            const selected = this.selectedTasks.includes(task.id)

            return {
                ...task,
                ...changes,
                selected
            }
        })
    }

    pollingTimer: any = null
    pollingTimeOut: number = 30 * 1000

    private appStore: AppStore = getStore(APP_STORE)
    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    startPolling = (): void => {
        clearTimeout(this.pollingTimer)
        this.pollingTimer = setTimeout(() => {
            this.fetchAllObjects(true)
            this.startPolling()
        }, this.pollingTimeOut)
    }

    stopPolling = (): void => {
        clearTimeout(this.pollingTimer)
    }

    @action
    fetchFirstData = async (): Promise<void> => {
        this.searchArguments = getSearchArguments(this.currentFiltersState.shownFilters)
        const gotTasks = this.tasks && this.tasks.length > 0
        await this.fetchAllObjects(gotTasks)
        this.startPolling()
    }

    fetchAllObjects = (fetchInBackground: boolean = false): Promise<void> => {
        const request = async () => {
            const objectIds = await priceTagFacadeLocal.getObjectsIds2(this.searchArguments, ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED)
            const length = objectIds.length

            let tasks: TaskVO[] = []
            tasks = await chunkedLoading(objectIds, objectChunkIds => {
                return priceTagFacadeLocal.getTasksByIds(objectChunkIds, ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED)
            })

            if (!fetchInBackground && tasks.length < length) {
                let ids = []
                objectIds.forEach(itemId => {
                    if (!tasks.find(task => String(task.id) === itemId)) {
                        ids.push(itemId)
                    }
                })
                this.appStore.showDialog({
                    title: t('notPrintedPriceTags.action.warningDialogTitle'),
                    message: t('notPrintedPriceTags.action.warningDialogMessage', {ids: ids.join(', ')}),
                })
            }

            // FIXME: SFM-208 -> Дата приходит в unix_time, в протоколе указано Date
            tasks.forEach(item => {
                item.activationDate = fromClientToServerTime(item.activationDate)
            })

            runInAction(() => {
                const taskChanged = !isEqual(tasks, toJS(this.tasks))
                if (taskChanged) {
                    this.tasks = tasks
                }
            })
        }
        return fetchInBackground ? request() : withSpinner(request)
    }

    @action
    applyFilters = async (searchArguments: SearchArgumentVO[]): Promise<void> => {
        this.searchArguments = searchArguments
        await this.fetchAllObjects()
        this.startPolling()
    }

    openActionPage = (actionGuid: number): void => {
        goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${actionGuid}`)
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
    }

    @action
    updateTaskName = (name: string, id: number) => {
        this.tasksChanges.set(id, name)
    }

    @action
    setSelection = (selection: number[]): void => {
        this.selectedTasks = selection
    }

    @action
    selectTasksOnPage = (newSelectedIds: number[], oldSelectedIds: number[]): void => {
        let addToSelection: number[] = [...newSelectedIds]
        let newSelectedTasks = [...this.selectedTasks]

        oldSelectedIds.forEach(oldId => {
            const newIndex = addToSelection.findIndex(newId => newId === oldId)
            if (newIndex === -1) {
                const selectedIndex = newSelectedTasks.findIndex(currentId => currentId === oldId)
                newSelectedTasks.splice(selectedIndex, 1)
            } else {
                addToSelection.splice(newIndex, 1)
            }
        })

        this.selectedTasks = [...newSelectedTasks, ...addToSelection]
    }

    handleExcelButton = async (): Promise<void> => {
        const reportWindow = window.open()

        const name = await priceTagFacadeLocal.getFileNameForPriceTagShelf(
            this.selectedTasks.map(item => String(item)),
            this.selectedTasks.length,
            ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED,
            XLSX
        )

        const baseAddress = config.reportsAddress
        const reportUrl = `${baseAddress}?Action=getFile&FILE_NAME=${name}`
        reportWindow.location.replace(reportUrl)
    }

    handleIgnorePrintButton = async (): Promise<void> => {
        await priceTagFacadeLocal.markAsPrinted(
            this.selectedTasks.map(item => String(item)),
            ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED
        )

        this.selectedTasks.forEach(id => this.tasksChanges.delete(id)) // Удалим правки по напечатанным товарам
        this.selectedTasks = [] // удалим выделенные товары

        await this.fetchAllObjects()
    }

    handlePrintButton = (): void => {
        this.printingStore.openPrinting(
            PRICE_TAGS + NOT_PRINTED + PRINTING,
            {
                productStatus: ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED,
                objectIds: this.selectedTasks.map(id => String(id)),
                substitutions: getNameSubstitutionObject(this.displayedTasks, this.tasks)
            }
        )
    }

    @action
    handleAllTasksCompleted = (): void => {
        this.selectedTasks.forEach(id => this.tasksChanges.delete(id)) // Удалим правки по напечатанным товарам
        this.selectedTasks = [] // удалим выделенные товары
        this.fetchAllObjects()
    }

    @action
    resetFilters = (filtersState: SideBarFiltersState): void => {
        this.currentFiltersState = filtersState
        this.searchArguments = getSearchArguments(filtersState.shownFilters)
    }

    @action
    setPagination = (pagination: PaginationState): void => {
        this.pagination = pagination
    }

    @action
    reset = (): void => {
        this.tasks = []
        this.tasksChanges = new Map<number, string>()
        this.currentFiltersState = getDefaultFilterState()
        this.searchArguments = getSearchArguments(this.currentFiltersState.shownFilters)
        this.selectedTasks = []
        this.pagination = DEFAULT_PRICE_TAGS_PAGINATION
        this.stopPolling()
    }
}

function getNameSubstitutionObject(tasks: TaskVO[], originalTasks: TaskVO[]): { [key: string]: { [key: string]: string } } {
    const result: { [key: string]: { [key: string]: string } } = {}

    tasks.forEach((task: TaskVO, index: number) => {
        if (task.nameOfGood !== originalTasks[index].nameOfGood) {
            result[task.id] = {
                NAME: task.nameOfGood,
                FULL_NAME: task.nameOfGood,
            }
        }
    })

    return result
}
