import { action, computed, observable, toJS, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { t } from 'i18next'
import { cloneDeep, isEqual, sortBy, uniqBy } from 'lodash'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { AppStore } from '../app-store'
import { UserStore } from '../user-store'
import { DialogStore } from '../dialog-store'
import { SNACK_BAR_INFO } from '../snackbar-store'
import { getStore } from '../stores-repository'
import {
    APP_BAR_STORE,
    APP_STORE,
    DIALOG_STORE,
    ROUTING_STORE,
    USER_STORE,
    INTEGRATION_EXTERNAL_PROCESSINGS_STORE,
} from '../stores'
import {
    INTEGRATION,
    EXTERNAL_PROCESSINGS,
    EDIT_PROCESSING_LIST,
} from '../../core/app-routes'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { externalSystemsManagerRemote } from '../../../protocol/set10/external-systems-manager-remote'
import { pluginsManager } from '../../../protocol/set10/plugins-manager'
import { ServiceProviderVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/service-provider-vo'
import { PluginServiceDescriptor } from '../../../protocol/set10/set-retail10-server/retailx/set-plugins/plugin-service-descriptor'
import { PluginProperty } from '../../../protocol/set10/set-retail10-server/retailx/set-plugins/plugin-property'
import {
    ServiceProviderSettingVO,
    createServiceProviderSettingVO,
} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/service-provider-setting-vo'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { withSpinner } from '../with-spinner'
import { ServiceProviderPropertyVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/service-provider-property-vo'

export class IntegrationExternalProcessingsStore {
    @observable
    processings: ServiceProviderVO[]

    @observable
    editableProcessing: ServiceProviderVO

    @observable
    originalPluginProperties: PluginServiceDescriptor

    @observable
    editablePluginProperties: PluginServiceDescriptor

    @observable
    isRequiredFilled: boolean = false

    @observable
    providerProperties: ServiceProviderPropertyVO[]

    /**
     * Если значение -1 = значит ничего не выбрано в списке и не стоит галочка "во всех магазинах"
     * Значение 0 = выбран режим "во всех магазинах"
     * Любое другое значение = выбран конкретный магазин
     *
     * Для ритейла не важно 0 или -1, в любом случае в свойства пропишется 0
     */
    @observable
    currentShop: number = -1

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private dialogStore: DialogStore = getStore(DIALOG_STORE)

    fetchProcessings = async (): Promise<void> => {
        const processings = await externalSystemsManagerRemote.getRegisteredServiceProviders(
            this.userStore.session, '', this.appStore.locale
        )

        runInAction(() => {
            this.processings = sortBy(processings, ['serviceType.localizedName', 'localizedName'])
        })
    }

    fetchProcessingExtendedFields = async (name: string): Promise<void> => {
        const providerProperties = await externalSystemsManagerRemote.getProviderProperties(
            name,
            this.appStore.locale
        )

        this.setProviderProperties(providerProperties)
    }

    @action
    setProviderProperties = (providerProperties: ServiceProviderPropertyVO[]): void => {
        this.providerProperties = providerProperties
    }

    fetchPluginProperties = async (name: string): Promise<void> => {
        const descriptor = await pluginsManager.getServicePlugin(name)

        this.setOriginalPluginProperties(descriptor)
    }

    findSettingByName = (name: string): ServiceProviderSettingVO => {
        return this.editableProcessing?.settings.find(item => item.name === name)
    }

    saveSettings = async (): Promise<void> => {
        let savedProccesing = { ...this.editableProcessing }

        // Обнуляем настройки только на центруме, на ритейле settingsOnShop всегда будет true
        if (this.isCentrum && this.settingsOnShopValue) {
            savedProccesing.settings = []
        }

        await externalSystemsManagerRemote.updateServiceProvider(
            this.userStore.session,
            savedProccesing.configurableTopology && this.appStore.isCentrum ?
                {
                    ...savedProccesing,
                    settings: savedProccesing.settings.filter(setting => this.currentShop === 0 ? setting.shop === 0 : setting.shop !== 0),
                    /* завязка на currentShop
                    *  потому что поле editableProcessing.validInAllStores всегда отдается сервером === false
                    * но чтобы сохранить настройки для всей сети его надо передать true */
                    validInAllStores: this.currentShop === 0
                } :
                savedProccesing,
            this.appStore.locale
        )

        if (this.editableProcessing.type === 'PLUGIN') {
            await pluginsManager.updateServicePluginSettingsTree(this.editablePluginProperties)
        }

        this.appStore.showSnackbar({
            message: t('common.settingsSaved')
        })
    }

    deleteProcessing = async (name: string, callback?: () => void): Promise<void> => {
        const processing = this.processings.find(item => item.name === name)

        if (processing) {
            this.dialogStore.showDialog({
                title: t('externalProcessings.deleteProcessingDialogTitle'),
                message: t('externalProcessings.deleteProcessingDialogMessage', { name: processing.localizedName }),
                mode: DIALOG,
                onYes: async () => {
                    const deletedProcessing = await externalSystemsManagerRemote.unregisterServiceProvider(
                        this.userStore.session, processing.id, this.appStore.locale
                    )

                    this.processings = this.processings.filter(({ name }) => name !== deletedProcessing.name)

                    this.appStore.showSnackbar({
                        message: t('externalProcessings.processingDeleted', { name: processing.localizedName }),
                        variant: SNACK_BAR_INFO,
                    })

                    if (callback) {
                        callback()
                    }
                }
            })
        }
    }

    canEditProcessing = (processing: ServiceProviderVO): boolean => {
        const { isCentrum, connectedToCentrum } = this.appStore
        const { editable, settingsOnShop } = processing

        if (!editable) {
            return false
        }

        if (!connectedToCentrum || isCentrum) {
            return true
        }

        if (!isCentrum && settingsOnShop) {
            return true
        }

        // Можно войти в процессинг в режиме ридонли
        if (!isCentrum && connectedToCentrum) {
            return true
        }

        return false
    }

    canEditProcessingList = (): boolean => {
        const { isCentrum, connectedToCentrum } = this.appStore

        if (!connectedToCentrum || isCentrum) {
            return true
        }

        return false
    }

    @computed
    get canSetShops(): boolean {
        // TODO - уточнить это условие
        return this.editableProcessing.configurableTopology &&
            this.editableProcessing.type === 'EXTENDED' &&
            this.appStore.isCentrum
    }

    getSettingValue = (name: string): string => {
        if (!this.editableProcessing) return ''

        const setting = this.canSetShops ?
            this.editableProcessing.settings.find(item => item.name === name && item.shop === this.currentShop) :
            this.findSettingByName(name)

        if (setting) {
            return setting.value
        }

        return ''
    }

    getPluginPropertyByKey = (key: string): PluginProperty => {
        if (!this.editablePluginProperties) {
            return null
        }

        // Сначала ищем в свойствах основного плагина
        const property = this.editablePluginProperties.properties.find(property => property.key === key)

        if (property) {
            return property
        }

        // Если нет, то пробуем найти в свойствах подплагинов
        if (this.editablePluginProperties.plugins.length > 0) {
            // Создаем общий массив свойств подплагинов...
            const properties: PluginProperty[] = this.editablePluginProperties.plugins.reduce(
                (acc, plugin) => ([...acc, ...plugin.properties]), []
            )

            // ...и ищем в этом списке
            const property = properties.find(property => property.key === key)

            if (property) {
                return property
            }
        }

        return null
    }

    getPluginPropertyValue = (key: string): string => {
        const property = this.getPluginPropertyByKey(key)

        if (property) {
            return property.value
        }

        return ''
    }

    getSettingValueByNameAndShop = (name: string, shop: number): string => {
        const setting = this.editableProcessing.settings.find(item => item.name === name && item.shop === shop)

        if (setting) {
            return setting.value
        }

        return ''
    }

    @action
    setIsRequiredFilled = (value: boolean): void => {
        this.isRequiredFilled = value
    }

    @action
    setDefaultSettings = (settings: Array<{name: string, value: string}>): void => {
        settings.forEach(item => {
            const setting = this.findSettingByName(item.name)

            if (!setting) {
                const newSetting = createServiceProviderSettingVO({ name: item.name, value: item.value, shop: 0 })

                this.editableProcessing.settings.push(newSetting)
            }
        })
    }

    @action
    setSettingValue = (name: string, value: string): void => {
        if (!this.editableProcessing) return

        const setting = this.canSetShops ?
            this.editableProcessing?.settings.find(item => item.name === name && item.shop === this.currentShop) :
            this.findSettingByName(name)

        if (setting) {
            setting.value = value
        } else {
            const newSetting = createServiceProviderSettingVO({
                name,
                value,
                shop: this.currentShop === -1 ? 0 : this.currentShop
            })

            this.editableProcessing.settings.push(newSetting)
        }
    }

    @action
    setCurrentShop = (value: number): void => {
        this.currentShop = value
    }

    @action
    setEditableProcessing = (value: ServiceProviderVO): void => {
        this.editableProcessing = value
        this.currentShop = -1

        // Выбран режим "во всех магазинах", или мы на ритейле
        if (value?.settings?.length && value?.settings?.every(setting => setting?.shop === 0)) {
            this.currentShop = 0
        }
    }

    @action
    setOriginalPluginProperties = (value: PluginServiceDescriptor): void => {
        this.originalPluginProperties = value

        this.setEditablePluginProperties(value)
    }

    @action
    setEditablePluginProperties = (value: PluginServiceDescriptor): void => {
        this.editablePluginProperties = value
    }

    @action
    updateEditableProcessing = (changes: Partial<ServiceProviderVO>): void => {
        Object.keys(changes).forEach(key => {
            this.editableProcessing[key] = changes[key]
        })
    }

    @action
    updateEditablePluginProperty = (key: string, value: string): void => {
        const property = this.getPluginPropertyByKey(key)

        property.value = value
    }

    // Для чекбокса "Настраивается в магазине"
    @computed
    get settingsOnShopValue(): boolean {
        return Boolean(this.editableProcessing?.settingsOnShop)
    }

    @computed
    get isControlsDisabled(): boolean {
        if (this.appStore.isCentrum) {
            return this.settingsOnShopValue
        } else {
            if (this.appStore.connectedToCentrum) {
                return !this.settingsOnShopValue
            }
            return false
        }
    }

    @computed
    get types(): any[] {
        if (this.processings) {
            return uniqBy(
                toJS(this.processings).map(processing => processing.serviceType),
                'localizedName'
            )
        }
    }

    @computed
    get isSettingsChanged(): boolean {
        if (!this.editableProcessing || !this.originalProcessing) {
            return false
        }

        return !isEqual(toJS(this.editableProcessing?.settings), toJS(this.originalProcessing?.settings))
    }

    @computed
    get isPluginPropertiesChanged(): boolean {
        if (!this.editablePluginProperties || !this.originalPluginProperties) {
            return false
        }

        return !isEqual(toJS(this.editablePluginProperties), toJS(this.originalPluginProperties))
    }

    @computed
    get isProcessingChangedExcludedSettingsAndOnShopSetting(): boolean {
        if (!this.editableProcessing || !this.originalProcessing) {
            return false
        }

        const {
            settings: settingsEditableProcessing,
            settingsOnShop: settingsOnShopEditableProcessing,
            ...restEditableProcessing
        } = this.editableProcessing

        const {
            settings: settingsOriginalProcessing,
            settingsOnShop: settingsOnShopOriginalProcessing,
            ...restOriginalProcessing
        } = this.originalProcessing

        return !isEqual(toJS(restEditableProcessing), toJS(restOriginalProcessing))
    }

    @computed
    get originalProcessing(): ServiceProviderVO {
        return this.processings?.find(item => item.name === this.editableProcessing?.name)
    }

    @computed
    get isChangedToShopSetting(): boolean {
        return this.settingsOnShopValue && !this.originalProcessing?.settingsOnShop
    }

    @computed
    get isChangedToCentrumSetting(): boolean {
        return !this.settingsOnShopValue && Boolean(this.originalProcessing?.settingsOnShop)
    }

    @computed
    get saveAvailable(): boolean {
        // Были на центруме, и включили "настройки в магазине"
        if (this.isChangedToShopSetting) {
            return true
        }

        // Были на центруме, и выключили "настройки в магазине"
        if (this.isChangedToCentrumSetting) {
            return this.isRequiredFilled
        }

        // Если ритейл и подключен к центруму, надо дополнительно проверить, разрешено ли менять этот процессинг
        if (!this.appStore.isCentrum && this.appStore.connectedToCentrum) {
            if (!this.settingsOnShopValue) {
                return false
            }
        }

        // Если не трогали галочку настройки в магазине - должны быть изменения и всё валидно
        return this.isRequiredFilled && (
            this.isProcessingChangedExcludedSettingsAndOnShopSetting ||
            this.isSettingsChanged ||
            this.isPluginPropertiesChanged
        )
    }

    @computed
    get isCentrum(): boolean {
        return this.appStore.isCentrum
    }

    reset = (): void => {
        this.processings = null
        this.editableProcessing = null
        this.isRequiredFilled = false
        this.currentShop = -1
    }
}

export const INTEGRATION_EDIT_PROCESSING_LIST_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${INTEGRATION}${EXTERNAL_PROCESSINGS}${EDIT_PROCESSING_LIST}`),
    onEnter: async () => {
        const appBar: AppBarStore = getStore(APP_BAR_STORE)
        const externalProcessingsStore: IntegrationExternalProcessingsStore = getStore(INTEGRATION_EXTERNAL_PROCESSINGS_STORE)

        if (!externalProcessingsStore.processings) {
            await externalProcessingsStore.fetchProcessings()

            appBar.updateState({
                title: t('set10.editProcessingList'),
                leftIcon: LEFT_ARROW,
                onLeftIconClick: () => goTo(`${INTEGRATION}${EXTERNAL_PROCESSINGS}`)
            })
        }
    },
}

export const INTEGRATION_PROCESSING_VIEW_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${INTEGRATION}${EXTERNAL_PROCESSINGS}/.*$`),
    onEnter: async () => {
        const appBar: AppBarStore = getStore(APP_BAR_STORE)
        const appStore: AppStore = getStore(APP_STORE)
        const routing: RouterStore = getStore(ROUTING_STORE)
        const externalProcessingsStore: IntegrationExternalProcessingsStore = getStore(INTEGRATION_EXTERNAL_PROCESSINGS_STORE)
        const processingName = routing.location.pathname.split('/').pop()

        if (!externalProcessingsStore.processings) {
            await withSpinner(externalProcessingsStore.fetchProcessings())
        }

        const processing = externalProcessingsStore.processings.find(({ name }) => name === processingName)

        if (processing && processing.type === 'PLUGIN') {
            await externalProcessingsStore.fetchPluginProperties(processing.name)
        }

        if (processing && externalProcessingsStore.canEditProcessing(processing)) {
            externalProcessingsStore.setEditableProcessing(cloneDeep(toJS(processing)))

            if (processing.type === 'EXTENDED') {
                await withSpinner(externalProcessingsStore.fetchProcessingExtendedFields(processing.name))
            }

            appBar.updateState({
                title: `${t('set10.externalProcessings')} - ${externalProcessingsStore.editableProcessing.localizedName}`,
                leftIcon: LEFT_ARROW,
                onLeftIconClick: () => {
                    const { saveAvailable } = externalProcessingsStore

                    if (saveAvailable) {
                        appStore.showDialog({
                            title: t('common.notSavedTitle'),
                            message: t('common.notSavedMessage'),
                            mode: DIALOG,
                            onYes: () => goTo(`${INTEGRATION}${EXTERNAL_PROCESSINGS}`)
                        })
                    } else {
                        goTo(`${INTEGRATION}${EXTERNAL_PROCESSINGS}`)
                    }
                }
            })
        } else {
            goTo(`${INTEGRATION}${EXTERNAL_PROCESSINGS}`)
        }
    },
    onLeave: () => {
        const externalProcessingsStore: IntegrationExternalProcessingsStore = getStore(INTEGRATION_EXTERNAL_PROCESSINGS_STORE)

        externalProcessingsStore.setEditableProcessing(null)
        externalProcessingsStore.setOriginalPluginProperties(null)
        externalProcessingsStore.setIsRequiredFilled(false)
        externalProcessingsStore.setProviderProperties(null)
    }
}
