import React from 'react'
import {
    LICENSES,
    PRODUCT_SHELVES,
    SHOP,
    SHOP_SETTINGS,
    getRouteName
} from '../core/app-routes'
import { ShopPage } from '../pages/shops/shop-settings/shop-page'
import { RouteScheme, RETAIL } from '../utils/route-modules-util'
import { SETRETAILX_CHANGE_LICENSE_KEY, SETRETAILX_COMMONSETUP_ADMIN, SETRETAILX_SHOPSETUP_ADMIN } from '../core/privileges/privileges'
import { lazy } from '../utils/code-splitting'
import Shop from '@material-ui/icons/Shop'
import { PrivilegeManager } from '../core/privileges/privilege-manager'

export const shopRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'shopNavLink',
        label: getRouteName(SHOP),
        icon: <Shop/>,
    },
    path: SHOP,
    serverType: RETAIL,
    privileges: (privilegeManager: PrivilegeManager) => {
        return privilegeManager.haveAnyPrivilegeFromList([
            SETRETAILX_SHOPSETUP_ADMIN,
            SETRETAILX_COMMONSETUP_ADMIN,
            SETRETAILX_CHANGE_LICENSE_KEY,
        ])
    },
    children: [
        {
            navItemProps: {
                id: 'shopSettingsNavLink',
                label: getRouteName(SHOP_SETTINGS),
            },
            path: `${SHOP}${SHOP_SETTINGS}`,
            privileges: [SETRETAILX_SHOPSETUP_ADMIN],
            component: ShopPage
        },
        {
            navItemProps: {
                id: 'productShelvesNavLink',
                label: getRouteName(PRODUCT_SHELVES),
            },
            path: `${SHOP}${PRODUCT_SHELVES}`,
            privileges: [SETRETAILX_COMMONSETUP_ADMIN],
            component: lazy(() =>
                import(/* webpackChunkName: "product-shelves" */ '../pages/shops/product-shelves/product-shelves'))
        },
        {
            navItemProps: {
                id: 'licensesNavLink',
                label: getRouteName(LICENSES),
            },
            path: `${SHOP}${LICENSES}`,
            privileges: [SETRETAILX_CHANGE_LICENSE_KEY],
            component: lazy(() =>
                import(/* webpackChunkName: "licenses-page" */ '../pages/shops/licenses/licenses-page'))
        }
    ]
})
