export interface SendBy {
    '@class': 'ru.crystals.cards.common.SendBy'
    byMail?: boolean
    bySMS?: boolean
    byPhone?: boolean
    byEMail?: boolean
}

export const createSendBy = (initialization: Partial<SendBy>): SendBy => {
    if (!initialization) return null
    
    let result: SendBy = {
        byMail: initialization.byMail,
        bySMS: initialization.bySMS,
        byPhone: initialization.byPhone,
        byEMail: initialization.byEMail,
        '@class': 'ru.crystals.cards.common.SendBy',
    }
    
    return result
}
