import { isNil, cloneDeep } from 'lodash'
import { XMLTagImage } from './xml/xml-tag-image'
import { Matrix2d } from '../../../utils/math/geom-util'
import { XMLAffineTransform } from './xml/xml-affine-transform'
import { BARCODE, CERTIFICATION_TYPE, XMLCustomElementType } from './xml/xml-custom-element-type'
import { MnemonicPropertiesVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'
import { XMLPriceTag } from './xml/xml-price-tag'
import { XMLTagTextBlock } from './xml/xml-tag-text-block'
import { XMLTagFormula } from './xml/xml-tag-formula'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'

const BARCODE_IMAGE: string = require('../../../assets/images/icons/price-tags/barcode-full.png')
const RST_IMAGE: string = require('../../../assets/images/icons/price-tags/rst-certification.jpg')

export const METERS_IN_INCH: number = 0.0254
export const MNEMONIC_OPEN_BRACKETS: string = '%^'
export const MNEMONIC_CLOSE_BRACKETS: string = '^%'
export const DEFAULT_DPI: number = 72

export const ROWS_PER_PAGE_VARIANTS: number[] = [20, 50, 100, 200, 500]
export const DEFAULT_PRICE_TAGS_PAGINATION: PaginationState = {
    numberOfRows: 20,
    page: 1,
    total: 0
}

export interface TextPart {
    /**
     * Текст
     */
    value: string
    /**
     * Является ли текст подстановкой - мнемоникой
     */
    isMnemonic: boolean
    /**
     * Индекс в оригинальном тексте
     */
    index: number
    /**
     * Индекс в тексте после замены подстановок
     */
    replacedIndex: number
    /**
     * Если этот текст мнемоника, то здесь будет ее идентификатор
     */
    mnemonic?: string
}

const MNEMONICS_SEARCH_REGEXP: RegExp = /%\^(\w+)\^%/g

/**
 * Правильно отображаем "подстановки" - значения, которые подставляются в ценник перед печатью
 * например, "%^StoreName^%", или "%^ShortPrintDate^%"
 * @param text
 * @param mnemonics
 */
export function parseMnemonics(text: string, mnemonics: MnemonicPropertiesVO[]): TextPart[] {
    let result: TextPart[] = []

    if (!text) {
        return result
    }

    let searchResult = MNEMONICS_SEARCH_REGEXP.exec(text)
    let replacedStartIndex = 0
    let startIndex = 0
    let endIndex = 0

    if (!searchResult) {
        result.push({
            index: 0,
            replacedIndex: 0,
            value: text,
            isMnemonic: false,
        })
        return result
    }

    while (searchResult) {
        startIndex = searchResult.index
        if (startIndex > endIndex) {
            // Между мнемониками слово
            const simpleText = text.substring(endIndex, startIndex)
            result.push({
                index: endIndex,
                replacedIndex: replacedStartIndex,
                value: simpleText,
                isMnemonic: false,
            })
            replacedStartIndex += simpleText.length
        }

        const mnemonicText: string = searchResult[0]
        const mnemonicName: string = mnemonicNameFromXmlTagText(mnemonicText)
        const mnemonicLocalizedName: string = getMnemonicLocalizedName(mnemonicName, mnemonics)

        result.push({
            index: startIndex,
            replacedIndex: replacedStartIndex,
            isMnemonic: true,
            value: mnemonicLocalizedName,
            mnemonic: mnemonicName,
        })
        replacedStartIndex += mnemonicLocalizedName.length

        endIndex = startIndex + mnemonicText.length

        searchResult = MNEMONICS_SEARCH_REGEXP.exec(text)
    }

    // в конце строки могут остаться не-мнемоники
    if (endIndex < text.length) {
        result.push({
            index: endIndex,
            replacedIndex: replacedStartIndex,
            value: text.substring(endIndex),
            isMnemonic: false,
        })
    }

    return result
}

export function mnemonicNameFromXmlTagText(xmlMnemonicText: string): string {
    if (!xmlMnemonicText) {
        return ''
    }

    return xmlMnemonicText.substring(MNEMONIC_OPEN_BRACKETS.length,
        xmlMnemonicText.length - MNEMONIC_CLOSE_BRACKETS.length)
}

export function getMnemonicLocalizedName(mnemonicName: string, mnemonics: MnemonicPropertiesVO[]): string {
    if (!mnemonicName) return ''

    return mnemonics.find(mn => mn.name === mnemonicName)?.localizedName || ''
}

/**
 * Конвертирует метры в пиксели с учетом dpi
 * @param value
 * @param dpi
 */
export function metersToPixels(value: number, dpi: number = DEFAULT_DPI): number {
    if (isNil(value) || isNil(dpi)) {
        return 0
    }
    return value * dpi / METERS_IN_INCH
}

/**
 * Конвертирует пиксели в метры с учетом dpi
 * @param value
 * @param dpi
 */
export function pixelsToMeters(value: number, dpi: number = DEFAULT_DPI): number {
    if (isNil(value) || isNil(dpi)) {
        return 0
    }

    return value * METERS_IN_INCH / dpi
}

export function imageToCSS(image: XMLTagImage): string {
    const fileExtensionRegExp: RegExp = /\.(\w+)$/g
    let extSearchResult: string[] = fileExtensionRegExp.exec(image.fileName)
    let imageFileExtension: string = extSearchResult && extSearchResult[1]

    let mimeType: string = ''

    switch (imageFileExtension) {
        case 'jpg':
        case 'jpeg':
            mimeType = 'image/jpeg'
            break
        case 'bmp':
            // TODO https://crystals.atlassian.net/browse/SFM-436
            console.warn('bmp extension is not yet supported')
            return ''
        case 'png':
        default:
            mimeType = 'image/png'
    }

    return `data:${mimeType};base64, ${image.data}`
}

export function affineTransformToMatrix(transform: XMLAffineTransform): Matrix2d {
    if (!transform) return null
    const { a, b, c, d, tx, ty } = transform
    return new Matrix2d(a, b, c, d, tx, ty)
}

export function matrixToAffineTransform(matrix: Matrix2d): XMLAffineTransform {
    if (!matrix) return null
    const { a, b, c, d, tx, ty } = matrix
    return { a, b, c, d, tx, ty }
}

export function getImageForCustomElement(type: XMLCustomElementType): string {
    switch (type) {
        case BARCODE:
            return BARCODE_IMAGE
        case CERTIFICATION_TYPE:
            return RST_IMAGE
        default:
            throw new Error(`Wrong custom element type: ${type}`)
    }
}

export function fixMnemonicFlagsInOldPriceTag(priceTag: XMLPriceTag, mnemonics: MnemonicPropertiesVO[]): XMLPriceTag {
    const fixedPriceTag: XMLPriceTag = cloneDeep(priceTag)

    /* чтобы проще искать было */
    const mnemonicMap: Map<string, MnemonicPropertiesVO> = new Map<string, MnemonicPropertiesVO>()
    mnemonics.forEach(mn => {
        mnemonicMap.set(mn.name, mn)
    })

    if (fixedPriceTag.textBlocks) {
        fixedPriceTag.textBlocks = fixedPriceTag.textBlocks.map(tb => {
            return fixTextBlock<XMLTagTextBlock>(tb, mnemonicMap)
        })
    }

    if (fixedPriceTag.formulas) {
        fixedPriceTag.formulas = fixedPriceTag.formulas.map(formula => {
            return fixTextBlock<XMLTagFormula>(formula, mnemonicMap)
        })
    }

    return fixedPriceTag
}

function fixTextBlock<T extends XMLTagTextBlock>(tb: T, mnemonicMap: Map<string, MnemonicPropertiesVO>): T {
    const mnemonicName: string = mnemonicNameFromXmlTagText(tb.text)
    const mnemonic: MnemonicPropertiesVO = mnemonicMap.get(mnemonicName)

    if (!mnemonic) {
        return tb
    }

    return {
        ...tb,
        wordWrap: isNil(tb.wordWrap) ? mnemonic.wordWrap : tb.wordWrap,
        textFit: isNil(tb.textFit) ? mnemonic.textFit : tb.textFit
    }
}
