import React from 'react'

interface AsyncWrapperProps<T = any, Y = any> {
    /** Параметр который будет передан в fetchFunctions */
    data: Y
    /**
     * Функция для получения данных
     */
    fetchFunction?: (data: Y) => Promise<T> | T
    /**
     * Значение которое будет использоваться, пока не придет ответ из fetchFunction
     */
    defaultValue?: T
    /**
     * Функция, которая получает результат асинхронной функции и возвращает то, что надо отрисовать
     */
    renderProp: (value: T) => React.ReactElement
}

interface AsyncWrapperState<T> {
    value: T
}

export class AsyncWrapper<T = any, Y = any> extends React.Component<AsyncWrapperProps<T, Y>, AsyncWrapperState<T>> {

    state: AsyncWrapperState<T> = {
        value: this.props.defaultValue
    }

    mounted: boolean

    componentDidMount() {
        this.mounted = true
        this.fetchData()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidUpdate(prevProps: AsyncWrapperProps<T, Y>, prevState: AsyncWrapperState<T>) {
        // При изменении входящего аргумента обновляем запрос
        if (prevProps.data !== this.props.data) {
            this.fetchData()
        }
    }

    fetchData = async (): Promise<void> => {
        const { fetchFunction, data } = this.props

        const result = await fetchFunction(data)

        if (this.mounted) {
            this.setState({
                value: result
            })
        }
    }

    render() {
        const { value } = this.state
        const { renderProp } = this.props

        return renderProp(value)
    }
}
