import React from 'react'
import classNames from 'classnames'
import { t } from 'i18next'

const styles = require('./items-container.scss')
const textStyles = require('../../theme/text.scss')

export interface ItemsContainerProps {
    children: React.ReactNode | React.ReactNode[]
    itemsLength: number
    /**
     * Заголовок
     */
    title?: string
    /**
     * Текст, выводимый при отсутствии элементов
     */
    noItemsText?: string
    /**
     * Текст, выводимый при отсутствии элементов
     */
    bottomText?: string
    id?: string
    className?: string
}

export class ItemsContainer extends React.Component<ItemsContainerProps> {

    static defaultProps: Partial<ItemsContainerProps> = {
        id: 'itemsContainer',
        className: '',
        title: '',
        noItemsText: '',
        bottomText: '',
    }

    render() {
        const { children, itemsLength, id, className, title, noItemsText, bottomText } = this.props

        return (
            <div className={classNames(className, styles.itemsContainer)}
                 id={id}>
                <div className={classNames(className, styles.content)}>
                    { title && (
                        <p className={styles.title}>{ title }</p>
                    ) }
                    { itemsLength === 0 && <span className={textStyles.secondaryTextM}>{ noItemsText || t('common.empty') }</span>}
                    { children }
                </div>
                { bottomText && <p className={styles.bottomText}>{ bottomText }</p> }
            </div>
        )
    }
}
