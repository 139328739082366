export interface FormatVO {
    '@class': 'ru.crystals.setretailx.topology.FormatVO'
    id?: number
    name?: string
}

export const createFormatVO = (initialization: Partial<FormatVO>): FormatVO => {
    if (!initialization) return null
    
    let result: FormatVO = {
        id: initialization.id,
        name: initialization.name,
        '@class': 'ru.crystals.setretailx.topology.FormatVO',
    }
    
    return result
}
