import React from 'react'
import { FilterType, USED_CHECK } from '../../../../core/filters/filter'
import { InternalFilterComponentProps } from '../../new-filters'
import { ComplexReceiptBase } from './complex-receipt-base'

export class UsedCheck extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = USED_CHECK

    render() {
        const { id, data, slim } = this.props

        return (
            <ComplexReceiptBase
                id="usedCheck"
                filterType={UsedCheck.filterType}
                {...this.props}
            />
        )
    }
}
