import { observable, action, computed, runInAction, toJS } from 'mobx'
import { t } from 'i18next'
import moment from 'moment'
import uuid from 'uuid'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, APP_STORE } from '../stores'
import { productTypesManagerLocal } from '../../../protocol/set10/product-types-manager-local'
import { AppStore } from '../app-store'
import { IdInfoPair } from '../../../protocol/set10/set-retail10-server/retailx/set-product-type-manager/id-info-pair'
import {
    XmlProductTypePluginVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-product-type-manager/xml-product-type-plugin-vo'
import { createPluginProperty, PluginProperty } from '../../../protocol/set10/set-retail10-server/retailx/set-product-type-manager/plugin-property'
import { goTo } from '../../utils/router-util'
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { HANDBOOKS, PRODUCT_TYPES } from '../../core/app-routes'
import { withSpinner } from '../with-spinner'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { baseLimitRemote } from '../../../protocol/set10/base-limit-remote'
import { PRODUCT_PIECE_ENTITY, PRODUCT_SPIRITS } from '../../core/product-types/product-types'
import { BaseLimitVO, createBaseLimitVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/base-limit-vo'
import {
    AlcoholLimitVO, createAlcoholLimitVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/alcohol-limit-vo'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { INTERNAL_ERROR } from '../../core/server-codes'
import { fromClientToServerTime } from '../../utils/app-util'

export interface AgeLimitVO extends Omit<BaseLimitVO, '@class'> {
    '@class': 'ru.crystals.limit.AgeLimitVO'
    minAge: number
    typeLong: number
    pluginClassName: string
}

export const createAgeLimitVO = (props: Partial<
    Omit<AgeLimitVO, '@class'> & { '@class'?: any }
>): AgeLimitVO => {
    const { minAge, typeLong, ['@class']: classProp, pluginClassName, ...rest } = props
    if (!props) {
        return null
    }

    return {
        ...(createBaseLimitVO(rest)),
        '@class': 'ru.crystals.limit.AgeLimitVO',
        minAge,
        typeLong,
        pluginClassName: pluginClassName || PRODUCT_PIECE_ENTITY
    }
}

export interface ProductTypeInfo extends IdInfoPair {
    settingsEnabled: boolean
}

export class ProductTypesStore {

    @observable
    productTypes: IdInfoPair[]

    @observable
    productTypesSettings: XmlProductTypePluginVO[]

    @observable
    filter: string = ''

    @observable
    editedProductType: string

    // Возможность изменения пока выключена
    editedProductTypeEnabled: boolean = true

    @observable
    editedProductTypeSettings: PluginProperty[]

    @observable
    originalProductTypeSettings: PluginProperty[]

    @observable
    pieceProductLimits: AgeLimitVO[]

    pieceProductLimitsOriginal: AgeLimitVO[]

    @observable
    editedPieceProductAgeLimit: AgeLimitVO = null

    @observable
    originalPieceProductAgeLimit: AgeLimitVO = null

    // Алкогольные ограничения по времени
    @observable
    alcoholLimits: AlcoholLimitVO[]

    @observable
    activeAlcoholLimits: AlcoholLimitVO[]

    @observable
    alcoholLimitsToShow: AlcoholLimitVO[]

    @observable
    originalAlcoholLimits: AlcoholLimitVO[]

    @observable
    editedAlcoholLimit: AlcoholLimitVO

    @observable
    originalAlcoholLimit: AlcoholLimitVO

    // Алкогольные ограничения по возрасту
    @observable
    alcoAgeLimits: AgeLimitVO[]

    @observable
    originalAlcoAgeLimits: AgeLimitVO[]

    @observable
    editedAlcoAgeLimit: AgeLimitVO

    @observable
    originalAlcoAgeLimit: AgeLimitVO

    @observable
    productTypeSettingTab: string = ''

    @observable
    areAllAlcoholLimitsShown: boolean = false

    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    @computed
    get settingsModified(): boolean {
        if (this.editedProductType === PRODUCT_SPIRITS) {
            if (
                !isEqual(toJS(this.alcoholLimits), toJS(this.originalAlcoholLimits)) ||
                !isEqual(toJS(this.alcoAgeLimits), toJS(this.originalAlcoAgeLimits))
            ) return true
        }

        if (this.editedProductType === PRODUCT_PIECE_ENTITY) {
            if (!isEqual(toJS(this.pieceProductLimits), toJS(this.pieceProductLimitsOriginal))) {
                return true
            }
        }

        return !isEqual(toJS(this.editedProductTypeSettings), toJS(this.originalProductTypeSettings))
    }

    @computed
    get alcoholTimeLimitModified(): boolean {
        return !isEqual(toJS(this.editedAlcoholLimit), toJS(this.originalAlcoholLimit))
    }

    @computed
    get alcoholAgeLimitModified(): boolean {
        return !isEqual(toJS(this.editedAlcoAgeLimit), toJS(this.originalAlcoAgeLimit))
    }

    @computed
    get pieceAgeLimitModified(): boolean {
        return !isEqual(toJS(this.editedPieceProductAgeLimit), toJS(this.originalPieceProductAgeLimit))
    }

    @computed
    get productTypesWithSettings(): ProductTypeInfo[] {
        if (!this.productTypes) return null

        return this.productTypes.map(item => {
            const itemId = item.id
            const settingsEnabled = this.productTypesSettings?.some(itemWithSettings => {
                // У "типа товара Одежда" настройки приходят, но настраивать ничего нельзя
                if (itemWithSettings.id === 'ProductClothingEntity') return false
                return itemWithSettings.id === itemId
            }) ?? false

            return {
                ...item,
                settingsEnabled
            }
        })
    }

    @computed
    get filteredProductTypes(): ProductTypeInfo[] {
        if (!this.filter) return this.productTypesWithSettings

        return this.productTypesWithSettings.filter(productType => {
            const trimmedFilter = this.filter.trim().toLowerCase()
            return productType.info.toLowerCase().includes(trimmedFilter)
        })
    }

    fetchProductTypes = async (): Promise<void> => {
        const productTypes = await productTypesManagerLocal.getLocalizedPluginInfos(this.appStore.locale)

        runInAction(() => {
            this.productTypes = productTypes
        })
    }

    fetchProductTypesWithProperties = async (): Promise<void> => {
        const productTypesSettings = await productTypesManagerLocal.getProductTypesWithProperties()

        runInAction(() => {
            this.productTypesSettings = productTypesSettings
        })
    }

    @action
    fetchProductTypeSettings = async (type: string): Promise<void> => {
        this.editedProductType = type
        const settings = await withSpinner(productTypesManagerLocal.getProperties1(type))

        if (!this.productTypes) {
            await this.fetchProductTypes()
        }

        runInAction(() => {
            this.editedProductTypeSettings = settings
            this.originalProductTypeSettings = cloneDeep(settings)
        })

        if (type === PRODUCT_PIECE_ENTITY) {
            await this.fetchpieceProductLimits()
        }

        if (type === PRODUCT_SPIRITS) {
            await this.fetchSpiritsProductRestrictions()
        }
    }

    fetchpieceProductLimits = async (): Promise<void> => {
        const pieceProductLimits = await withSpinner(baseLimitRemote.getAllAgeLimits() as unknown as Promise<AgeLimitVO[]>)

        runInAction(() => {
            this.pieceProductLimits = pieceProductLimits
            this.pieceProductLimitsOriginal = cloneDeep(pieceProductLimits)
        })
    }

    @action
    setProductTypeSettings = (settings: PluginProperty[]): void => {
        this.editedProductTypeSettings = settings
    }

    fetchSpiritsProductRestrictions = async (): Promise<void> => {
        const [alcoholLimits, alcoAgeLimits] = await Promise.all([
            baseLimitRemote.getAllAlcoholLimits(),
            baseLimitRemote.getAlcoAgeLimits()
        ])

        runInAction(() => {
            this.alcoholLimits = alcoholLimits as unknown as AlcoholLimitVO[]
            this.originalAlcoholLimits = cloneDeep(alcoholLimits) as unknown as AlcoholLimitVO[]
            this.activeAlcoholLimits = (cloneDeep(alcoholLimits) as unknown as AlcoholLimitVO[]).filter(limit => {
                const dateFrom = moment(fromClientToServerTime(new Date(limit.dateFrom)))
                const dateTo = moment(fromClientToServerTime(new Date(limit.dateTo)))
                const now = moment(new Date())

                if ((!limit.dateFrom && !limit.dateTo) || (now.isAfter(dateFrom) && now.isBefore(dateTo))) {
                    return limit
                }

                return null
            })
            this.alcoholLimitsToShow = this.activeAlcoholLimits

            const ageLimits = alcoAgeLimits.map(item => createAgeLimitVO({ ...item, pluginClassName: PRODUCT_SPIRITS }))
            this.alcoAgeLimits = ageLimits
            this.originalAlcoAgeLimits = cloneDeep(ageLimits)
        })
    }

    @action
    showAllAlcoholLimits = (value: boolean) => {
        this.areAllAlcoholLimitsShown = value

        if (value) {
            this.alcoholLimitsToShow = this.alcoholLimits
        } else {
            this.alcoholLimitsToShow = this.activeAlcoholLimits
        }
    }

    updateAppBar = (): void => {
        const { updateState } = this.appBarStore
        const { showDialog } = this.appStore

        const name = this.originalProductTypeSettings?.find(item => {
            return item.key === 'productTypeName'
        })?.value

        if (name) {
            updateState({
                title: name,
                leftIcon: LEFT_ARROW,
                onLeftIconClick: () => {
                    if (this.settingsModified) {
                        showDialog({
                            title: t('common.notSavedTitle'),
                            message: t('common.notSavedMessage'),
                            onYes: this.goBack,
                            mode: DIALOG,
                        })
                        return
                    }
                    this.goBack()
                },
            })
        }
    }

    updateLimitsAppBar = (): void => {
        const { updateState } = this.appBarStore
        const { showDialog } = this.appStore

        const name = this.originalProductTypeSettings?.find(item => {
            return item.key === 'productTypeName'
        })?.value

        if (name) {
            updateState({
                title: name,
                leftIcon: LEFT_ARROW,
                onLeftIconClick: () => {
                    if (
                        (this.editedAlcoholLimit && this.alcoholTimeLimitModified) ||
                        (this.editedAlcoAgeLimit && this.alcoholAgeLimitModified)
                    ) {
                        showDialog({
                            title: t('common.notSavedTitle'),
                            message: t('common.notSavedMessage'),
                            onYes: this.goBackFromLimitsPage,
                            mode: DIALOG,
                        })
                        return
                    }
                    this.goBackFromLimitsPage()
                }
            })
        }
    }

    updatePieceProductLimitAppBar = (): void => {
        const isNew = this.editedPieceProductAgeLimit.guid ? false : true

        this.appBarStore.updateState({
            leftIcon: 'leftArrow',
            title: isNew ? t('productTypes.newLimit') : this.editedPieceProductAgeLimit.name,
            onLeftIconClick: () => {
                if (this.pieceAgeLimitModified) {
                    return this.appStore.showDialog({
                        title: t('common.notSavedTitle'),
                        message: t('common.notSavedMessage'),
                        onYes: this.goBackFromLimitsPage,
                        mode: DIALOG,
                    })
                }

                this.goBackFromLimitsPage()
            }
        })
    }

    getPluginProperty = (key: string): PluginProperty => {
        if (!this.editedProductTypeSettings) return null

        return this.editedProductTypeSettings.find(item => item.key === key)
    }

    getPluginPropertyValue = (key: string): string => {
        if (!this.editedProductTypeSettings) return null

        return this.editedProductTypeSettings.find(item => item.key === key)?.value
    }

    @action
    setPluginProperty = (key: string, value: PluginProperty): void => {
        if (!this.editedProductTypeSettings) return

        const propertyIndex = this.editedProductTypeSettings.findIndex(item => item.key === key)

        if (propertyIndex !== -1) {
            this.editedProductTypeSettings[propertyIndex] = value
        } else {
            this.editedProductTypeSettings.push(value)
        }
    }

    @action
    setPluginPropertyValue = (key: string, value: string): void => {
        if (!this.editedProductTypeSettings) return

        const propertyIndex = this.editedProductTypeSettings.findIndex(item => item.key === key)

        if (propertyIndex !== -1) {
            this.editedProductTypeSettings[propertyIndex].value = value
        } else {
            this.editedProductTypeSettings.push(
                createPluginProperty({
                    key,
                    value,
                    properties: []
                })
            )
        }
    }

    @action
    setpieceProductLimits = (limits: AgeLimitVO[]): void => {
        this.pieceProductLimits = limits
    }

    @action
    setEditedProductTypeSettings = (editedProductTypeSettings: PluginProperty[]): void => {
        this.editedProductTypeSettings = editedProductTypeSettings
    }

    // Алкогольные ограничения по времени

    // Меняем весь список ограничений
    @action
    setAlcoholLimits = (allLimits: AlcoholLimitVO[], activeLimits?: AlcoholLimitVO[]): void => {
        this.alcoholLimits = allLimits
        this.activeAlcoholLimits = activeLimits
        this.alcoholLimitsToShow = this.areAllAlcoholLimitsShown ? allLimits : activeLimits
    }

    // Меняем одно отдельное ограничение
    @action
    openEditedAlcoholLimit = (limit: AlcoholLimitVO): void => {
        this.editedAlcoholLimit = cloneDeep(toJS(limit))
        this.originalAlcoholLimit = cloneDeep(toJS(limit))
    }

    @action
    updateEditedAlcoholLimit = (changes: Partial<AlcoholLimitVO>): void => {
        Object.keys(changes).forEach(key => {
            this.editedAlcoholLimit[key] = changes[key]
        })
    }

    @action
    createNewAlcoholLimit = (): void => {
        this.originalAlcoholLimit = null
        this.editedAlcoholLimit = createAlcoholLimitVO({
            guid: uuid(),
            name: '',
            deleted: false,
            createDate: new Date(),
            updateDate: new Date(),
            fromApperPoint: false,
            forAllStores: false,
            regions: [],
            cities: [],
            shops: [],
            minPercent: 0,
            timeFromStr: '',
            timeToStr: '',
            timeFrom: null,
            timeTo: null,
            dateFrom: null,
            dateTo: null,
            dateFromStr: '',
            dateToStr: '',
            dayOfWeek: 0,
            minLiterPrice: 0
        })
    }

    // Алкогольные ограничения по возрасту

    // Меняем весь список ограничений
    @action
    setAlcoAgeLimits = (limits: AgeLimitVO[]): void => {
        this.alcoAgeLimits = limits
    }

    // Меняем одно отдельное ограничение
    @action
    openEditedAlcoAgeLimit = (limit: AgeLimitVO): void => {
        this.editedAlcoAgeLimit = cloneDeep(toJS(limit))
        this.originalAlcoAgeLimit = cloneDeep(toJS(limit))
    }

    @action
    updateEditedAlcoAgeLimit = (changes: Partial<AgeLimitVO>): void => {
        if (!this.editedAlcoAgeLimit) {
            this.editedAlcoAgeLimit = this.getDefaultAlcoAgeLimit()
        }
        Object.keys(changes).forEach(key => {
            this.editedAlcoAgeLimit[key] = changes[key]
        })
    }

    getDefaultAlcoAgeLimit = () => {
        return createAgeLimitVO({
            cities: [],
            createDate: new Date(),
            updateDate: new Date(),
            deleted: false,
            forAllStores: false,
            fromApperPoint: false,
            minAge: 18,
            name: '',
            regions: [],
            shops: [],
            typeLong: 0,
            pluginClassName: PRODUCT_SPIRITS
        })
    }

    @action
    createNewAlcoAgeLimit = (): void => {
        this.originalAlcoAgeLimit = null
        this.editedAlcoAgeLimit = this.getDefaultAlcoAgeLimit()
    }

    savePluginSettings = async (): Promise<void> => {
        let editedSettings = toJS(this.editedProductTypeSettings)

        // SRTE-3389
        // отправляем на сохранение только отображаемые "действия с товаром"
        // с которыми действительно работает пользователь
        editedSettings = editedSettings.filter(item => {
            if (item.key === 'markdownPrefix' || item.key === 'prefix') {
                return item.properties?.length > 0
            } else {
                return true
            }
        })

        const settings = await productTypesManagerLocal.setPropertiesArray(
            this.editedProductType,
            editedSettings,
            true,
            {
                customCommonResponseMiddlewares: [
                    response => {
                        let error: { code?: number, message?: string } = response.data.error
                        if (error && error.code === INTERNAL_ERROR) {
                        // В случае ошибки по новой запрашиваем, чтобы актуальную инофрмацию получить
                        this.fetchProductTypeSettings(this.editedProductType)

                        this.appStore.showSnackbar({
                            message: t('productTypes.error'),
                            variant: 'warning'
                        })
                    }
                }]
            }
        )

        if (this.editedProductType === PRODUCT_PIECE_ENTITY) {
            await this.savePieceProduct()

            this.fetchpieceProductLimits()
        }

        if (this.editedProductType === PRODUCT_SPIRITS) {
            await this.saveAlcoholLimits(toJS(this.alcoholLimits), toJS(this.originalAlcoholLimits))
            await this.saveAlcoholLimits(toJS(this.alcoAgeLimits), toJS(this.originalAlcoAgeLimits))

            this.fetchSpiritsProductRestrictions()
        }

        runInAction(() => {
            this.editedProductTypeSettings = settings
        })

        this.appStore.showSnackbar({
            message: t('productTypes.saved'),
        })

        this.goBack()
    }

    savePieceProduct = async () => {
        const limitsToSave = []
        const limitsToRemove = []

        for (const restriction of this.pieceProductLimits) {
            const found = this.pieceProductLimitsOriginal.find(r => r.guid === restriction.guid)

            if (!found || !isEqual(found, restriction)) {
                limitsToSave.push(restriction)
            }
        }

        for (const restriction of this.pieceProductLimitsOriginal) {
            const found = this.pieceProductLimits.find(r => r.guid === restriction.guid)

            if (!found) {
                limitsToRemove.push(restriction)
            }
        }

        await withSpinner(Promise.all([
            ...limitsToSave.map(limit => baseLimitRemote.saveLimit(limit)),
            ...limitsToRemove.map(limit => baseLimitRemote.removeLimit({
                ...limit,
                deleted: true,
            }))
        ]))
    }

    saveAlcoholLimits = async (
        limits: Array<BaseLimitVO | AgeLimitVO>, originalLimits: Array<BaseLimitVO | AgeLimitVO>
    ): Promise<void> => {
        const limitsToSave = []
        const limitsToRemove = []

        for (const restriction of limits) {
            const found = originalLimits.find(r => r.guid === restriction.guid)

            if (!found || !isEqual(toJS(found), toJS(restriction))) {
                limitsToSave.push(restriction)
            }
        }

        for (const restriction of originalLimits) {
            const found = limits.find(r => r.guid === restriction.guid)

            if (!found) {
                limitsToRemove.push(restriction)
            }
        }

        await withSpinner(Promise.all([
            ...limitsToSave.map(restriction => baseLimitRemote.saveLimit(restriction)),
            ...limitsToRemove.map(restriction => baseLimitRemote.removeLimit({
                ...restriction,
                deleted: true
            }))
        ]))
    }

    goBack = (): void => {
        this.resetEditedProductType()
        goTo(`${HANDBOOKS}${PRODUCT_TYPES}`)
    }

    goBackFromLimitsPage = (): void => {
        this.resetLimits()

        if (this.editedProductType) {
            goTo(`${HANDBOOKS}${PRODUCT_TYPES}/${this.editedProductType}`)
        } else {
            goTo(`${HANDBOOKS}${PRODUCT_TYPES}`)
        }
    }

    setProductTypeSettingsTab = (value: string): void => {
        this.productTypeSettingTab = value
    }

    resetEditedProductType = (): void => {
        this.editedProductType = null
        this.editedProductTypeSettings = null
        this.originalProductTypeSettings = null
        this.productTypeSettingTab = ''

        this.areAllAlcoholLimitsShown = false
        this.alcoholLimits = null
        this.originalAlcoholLimits = null
        this.editedAlcoholLimit = null
        this.originalAlcoholLimit =  null
        this.alcoAgeLimits = null
        this.originalAlcoAgeLimits = null
        this.editedAlcoAgeLimit = null
        this.originalAlcoAgeLimit =  null
        this.editedPieceProductAgeLimit = null
        this.originalPieceProductAgeLimit = null
        // TODO - остальные добавить свойства
    }

    resetLimits = (): void => {
        this.editedAlcoholLimit = null
        this.originalAlcoholLimit = null
        this.editedAlcoAgeLimit = null
        this.originalAlcoAgeLimit = null
        this.editedPieceProductAgeLimit = null
        this.originalPieceProductAgeLimit = null
    }

    @action
    setFilter = (filter: string): void => {
        this.filter = filter
    }

    @action
    removePieceProductAgeLimit = ({ guid }: AgeLimitVO) => {
        this.pieceProductLimits = this.pieceProductLimits.filter(r => r.guid !== guid)
    }

    @action
    openEditedPieceProductAgeLimit = (limit: AgeLimitVO): void => {
        this.editedPieceProductAgeLimit = cloneDeep(toJS(limit))
        this.originalPieceProductAgeLimit = cloneDeep(toJS(limit))
    }

    createPieceProductAgeLimit = (): void => {
        this.originalPieceProductAgeLimit = null
        this.editedPieceProductAgeLimit = createAgeLimitVO({
            forAllStores: false,
            fromApperPoint: false,
            deleted: false,
            name: '',
            regions: [],
            cities: [],
            shops: [],
            minAge: 18,
            typeLong: 4,
            pluginClassName: PRODUCT_PIECE_ENTITY
        })
    }

    @action
    updatePieceProductAgeLimit = (update: Partial<AgeLimitVO>): void => {
        Object.entries(update).forEach(([key, value]) => {
            this.editedPieceProductAgeLimit[key] = value
        })
    }

    @action
    reset = (): void => {
        this.productTypes = null
        this.productTypesSettings = null
        this.originalProductTypeSettings = null
        this.filter = ''
        this.editedProductType = null
        this.editedProductTypeSettings = null
        this.productTypeSettingTab = ''

        this.alcoholLimits = null
        this.originalAlcoholLimits = null
        this.editedAlcoholLimit = null
        this.originalAlcoholLimit =  null
        this.alcoAgeLimits = null
        this.originalAlcoAgeLimits = null
        this.editedAlcoAgeLimit = null
        this.originalAlcoAgeLimit =  null

        this.pieceProductLimits = null
        this.pieceProductLimitsOriginal = null
        this.editedPieceProductAgeLimit = null
        this.originalPieceProductAgeLimit = null
        // TODO - остальные добавить свойства
    }
}
