export interface ProducerVO {
    '@class': 'ru.crystals.setretailx.products.vo.ProducerVO'
    id?: number
    guid?: string
    name?: string
    producer?: string
}

export const createProducerVO = (initialization: Partial<ProducerVO>): ProducerVO => {
    if (!initialization) return null
    
    let result: ProducerVO = {
        id: initialization.id,
        guid: initialization.guid,
        name: initialization.name,
        producer: initialization.producer,
        '@class': 'ru.crystals.setretailx.products.vo.ProducerVO',
    }
    
    return result
}
