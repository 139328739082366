import React from 'react'
import { t } from 'i18next'
import { isNil, cloneDeep } from 'lodash'
import {
    removeCondition, updateCondition, findCondition, BillConditions, ComparisonType, ConditionFields
} from '../../../../../../core/advertising-actions/action-conditions'
import { ConditionEditorProps } from '../types'
import { Table } from '@crystalservice/crystals-ui/lib/components'
import { MenuButton } from '@crystalservice/crystals-ui/lib/components/buttons/menu-button/menu-button'
import Button from '@material-ui/core/Button'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { Dialog, DialogTitle, DialogContent, FormLabel, Grid, DialogActions } from '@material-ui/core'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { NumberInput, NUMBER } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { Add } from '@material-ui/icons'
import { inject, observer } from 'mobx-react'
import { APP_STORE } from '../../../../../../store/stores'
import { AppStore } from '../../../../../../store/app-store'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'

export interface ExternalPayment {
    externalPaymentTypes: Array<{
        [ConditionFields.ExternalPaymentType]: ExternalPaymentType[]
    }>
}

export interface ExternalPaymentType {
    typeOfSumComparison: SumType[],
    externalPaymentCode: string[],
    amount: string[],
    anyAmount: Array<'true' | 'false'>
}

export enum SumType {
    MORE = 'MORE',
    LESS = 'LESS'
}

// Вспомогательная функция - получаем непосредственные значения
export const getExternalPaymentConditionTypes = (condition: ExternalPayment): ExternalPaymentType[] => {
    if (!condition || !condition.externalPaymentTypes) return []
    return condition.externalPaymentTypes[0][ConditionFields.ExternalPaymentType]
}

// Получение текста для чипа
export const getExternalPaymentCodeChipLabel = (condition: ExternalPayment, currency?: string): string => {
    let productCountText = ''

    const types = getExternalPaymentConditionTypes(condition)
    types.forEach(type => {
        if (productCountText) {
            productCountText += '; '
        }
        productCountText += `${t('advertisingActions.checkConditions.externalCode')} ${type.externalPaymentCode[0]} `

        if (type.anyAmount[0] === 'true') {
            productCountText += t('advertisingActions.checkConditions.sumAny')
            return
        }

        switch (type.typeOfSumComparison[0]) {
            case SumType.MORE:
                productCountText += t('advertisingActions.checkConditions.sumMore', {
                    sum: +type.amount[0] / 100,
                    currency
                })
                break
            case SumType.LESS:
                productCountText += t('advertisingActions.checkConditions.sumLess', {
                    sum: +type.amount[0] / 100,
                    currency
                })
                break
        }
    })

    return `${t('advertisingActions.checkConditions.externalPaymentChip')}: ${productCountText}`
}

const getBlankExternalPaymentType = (): ExternalPaymentType => {
    return {
        typeOfSumComparison: [SumType.MORE],
        externalPaymentCode: [null],
        amount: ['0'],
        anyAmount: ['false']
    }
}

interface ExternalPaymentEditorProps extends ConditionEditorProps {
    app?: AppStore
}

interface ExternalPaymentEditorState {
    newItem: boolean
    editedItem: ExternalPaymentType
}

@inject(APP_STORE)
@observer
export class ExternalPaymentEditor extends React.Component<ExternalPaymentEditorProps, ExternalPaymentEditorState> {

    state: ExternalPaymentEditorState = {
        newItem: false,
        editedItem: null
    }

    getExternalPayment = (): ExternalPayment => {
        const condition = findCondition(BillConditions.ExternalPaymentTypeCondition, this.props.value)
        const externalPayment: ExternalPayment = condition && condition.data
        return externalPayment
    }

    updateEditedItem = (changes: Partial<ExternalPaymentType>): void => {
        this.setState(prevState => ({
            editedItem: {
                ...prevState.editedItem,
                ...changes
            }
        }))
    }

    handleRemoveItem = (externalNumber: string): void => {
        const externalPayment = this.getExternalPayment()

        let newTypes = getExternalPaymentConditionTypes(externalPayment).filter(item => {
            return item.externalPaymentCode[0] !== externalNumber
        })

        this.handleUpdateCondition(newTypes)
    }

    handleUpdateItem = (newItem: ExternalPaymentType): void => {
        const externalPayment = this.getExternalPayment()

        let newTypes = [...getExternalPaymentConditionTypes(externalPayment)]

        const externalCode = newItem.externalPaymentCode[0]
        let typeIndex = newTypes.findIndex(item => item.externalPaymentCode[0] === externalCode)

        if (typeIndex === -1) {
            newTypes.push(newItem)
        } else {
            newTypes[typeIndex] = newItem
        }

        this.handleUpdateCondition(newTypes)
    }

    handleUpdateCondition = (newTypes: ExternalPaymentType[]): void => {
        const { value, onChange } = this.props

        // Если все поля пустые - удаляем условие
        if (!newTypes || newTypes.length === 0) {
            onChange(removeCondition(BillConditions.ExternalPaymentTypeCondition, value))
            return
        }

        const newCondition: ExternalPayment = {
            externalPaymentTypes: [
                {
                    [ConditionFields.ExternalPaymentType]: newTypes
                }
            ]
        }
        onChange(updateCondition(
            BillConditions.ExternalPaymentTypeCondition,
            newCondition,
            value
        ))
    }

    render() {
        const { currencyText } = this.props.app
        const { editedItem, newItem } = this.state

        const externalPayment = this.getExternalPayment()
        const types = getExternalPaymentConditionTypes(externalPayment)

        const editedItemAnyCount = editedItem && editedItem.anyAmount[0] === 'true'

        const readOnly = this.props.readOnly

        return (
            <div>
                <Table
                    withHeader
                    actionComponents={
                        <div style={{
                            marginRight: 8,
                            paddingTop: 16,
                            height: 68
                        }}>
                            <Button
                                id="addNewButton"
                                color="primary"
                                variant="contained"
                                disabled={readOnly}
                                onClick={() => this.setState({
                                    editedItem: getBlankExternalPaymentType(),
                                    newItem: true,
                                })}
                            >
                                <Add/>
                                {t('advertisingActions.addButton')}
                            </Button>
                        </div>
                    }
                    title={t('advertisingActions.checkConditions.addedPaymentTypes')}
                    keyFunction={(item: ExternalPaymentType) => item.externalPaymentCode[0]}
                    items={types}
                    columns={[
                        {
                            header: t('advertisingActions.checkConditions.externalPayment'),
                            labelFunction: (item: ExternalPaymentType) => item.externalPaymentCode[0]
                        },
                        {
                            header: t('advertisingActions.checkConditions.paymentSum'),
                            labelFunction: (item: ExternalPaymentType) => {
                                if (item.anyAmount[0] === 'true') {
                                    return t('advertisingActions.checkConditions.sumAny')
                                }

                                if (item.typeOfSumComparison[0] === SumType.MORE) {
                                    return t(
                                        'advertisingActions.checkConditions.sumMore',
                                        { sum: +item.amount[0] / 100, currency: currencyText }
                                    )
                                }
                                return t(
                                    'advertisingActions.checkConditions.sumLess',
                                    { sum: +item.amount[0] / 100, currency: currencyText }
                                )
                            }
                        },
                        {
                            renderer: ({ item }: { item: ExternalPaymentType }) => {
                                return (
                                    <MenuButton
                                        adaptive
                                        disabled={readOnly}
                                        buttons={[
                                            {
                                                id: `editButton_${item.externalPaymentCode[0]}`,
                                                label: t('common.edit'),
                                                onClick: () => this.setState({
                                                    // cloneDeep т.к. внутри каждого свойства массив, который надо заменить
                                                    editedItem: cloneDeep(item),
                                                    newItem: false,
                                                }),
                                                disabled: readOnly
                                            },
                                            {
                                                id: `removeButton_${item.externalPaymentCode[0]}`,
                                                label: t('common.remove'),
                                                onClick: () => this.handleRemoveItem(item.externalPaymentCode[0]),
                                                disabled: readOnly
                                            }
                                        ]}
                                    />
                                )
                            },
                            showOnRowHover: true,
                            withControls: true
                        }
                    ]}
                />
                {!types.length && (
                    <Empty/>
                )}

                {editedItem && (
                    <Dialog open maxWidth="xs" fullWidth>
                        <DialogTitle>
                            {t('advertisingActions.settings')}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="externalCodeInput"
                                        label={t('advertisingActions.checkConditions.externalPayment')}
                                        value={editedItem.externalPaymentCode[0] || ''}
                                        disabled={!newItem}
                                        maxLength={6}
                                        onValueChange={value => this.updateEditedItem({
                                            externalPaymentCode: [String(value)]
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox
                                        id="anyCountInput"
                                        color="primary"
                                        label={t('advertisingActions.checkConditions.anyLabel')}
                                        checked={editedItemAnyCount}
                                        onValueChange={checked => {
                                            this.updateEditedItem({
                                                anyAmount: [checked ? 'true' : 'false']
                                            })
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormLabel>{t('advertisingActions.checkConditions.paymentSum')}</FormLabel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <SelectInput
                                                id="typeOfSumComparisonInput"
                                                options={[
                                                    {
                                                        label: t('advertisingActions.checkConditions.moreLabel'),
                                                        value: SumType.MORE
                                                    },
                                                    {
                                                        label: t('advertisingActions.checkConditions.lessLabel'),
                                                        value: SumType.LESS
                                                    }
                                                ]}
                                                value={editedItem.typeOfSumComparison[0]}
                                                onSelect={option =>
                                                    this.updateEditedItem({
                                                        typeOfSumComparison: [option.value]
                                                    })
                                                }
                                                disabled={editedItemAnyCount}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <NumberInput
                                                id="amountInput"
                                                dataType={NUMBER}
                                                value={+editedItem.amount[0] / 100}
                                                disabled={editedItemAnyCount}
                                                onValueChange={value => this.updateEditedItem({
                                                    amount: [String(Math.round(value * 100))]
                                                })}
                                                endAdornment={<div/>}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id="cancelButton"
                                color="primary"
                                onClick={() => this.setState({ editedItem: null, newItem: null })}
                            >
                                {t('common.cancel')}
                            </Button>
                            <Button
                                id="saveButton"
                                color="primary"
                                disabled={isNil(editedItem.externalPaymentCode[0])}
                                onClick={() => {
                                    this.handleUpdateItem(editedItem)
                                    this.setState({ editedItem: null, newItem: null })
                                }}
                            >
                                {t('common.save')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        )
    }
}
