import { t } from 'i18next'
import React from 'react'
import { STORE_NUMBER_FILTER, FilterType, getFilterTypeName } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { ListInput } from '@crystalservice/crystals-ui/lib/components/inputs/list-input/list-input'

export class StoreNumber extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = STORE_NUMBER_FILTER

    entryRegExp: RegExp = /^\d*$/
    getEntryTypeHandler = (count: number) => t('components.listInput.entryType.number', {count})

    render() {
        const { id, value, onChange, slim } = this.props
        return (
            <ListInput
                id={id}
                defaultValue={value}
                onChange={value => onChange(StoreNumber.filterType, { value })}
                label={getFilterTypeName(StoreNumber.filterType)}
                entryRegExp={this.entryRegExp}
                getEntryTypeText={this.getEntryTypeHandler}
                slim={slim}
            />
        )
    }
}
