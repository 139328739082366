import { XMLTagBaseElement } from './xml-tag-base-element'
import { ALL_BARCODE_ELEMENT_TYPES, XMLBarcodeElementType } from './xml-barcode-element-type'

export interface XMLTagBarcodeElement extends XMLTagBaseElement {
    preferredBarcodeType?: XMLBarcodeElementType
}

export function isBarcodeElement(item: any): item is XMLTagBarcodeElement {
    if (!item) return false
    if ('preferredBarcodeType' in item && ALL_BARCODE_ELEMENT_TYPES.includes(item.preferredBarcodeType)) return true
    return false
}
