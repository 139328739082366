import { UserRoleVO } from './user-role-vo'

export interface UserVO {
    '@class': 'ru.crystals.setretailx.users.UserVO'
    id?: number
    login?: string
    password?: string
    blockedStatus?: boolean
    email?: string
    position?: string
    firstName?: string
    middleName?: string
    lastName?: string
    userRoles?: UserRoleVO[]
    adAuth?: boolean
}

export const createUserVO = (initialization: Partial<UserVO>): UserVO => {
    if (!initialization) return null
    
    let result: UserVO = {
        id: initialization.id,
        login: initialization.login,
        password: initialization.password,
        blockedStatus: initialization.blockedStatus,
        email: initialization.email,
        position: initialization.position,
        firstName: initialization.firstName,
        middleName: initialization.middleName,
        lastName: initialization.lastName,
        userRoles: initialization.userRoles,
        adAuth: initialization.adAuth,
        '@class': 'ru.crystals.setretailx.users.UserVO',
    }
    
    return result
}
