import { observable, runInAction, action, toJS, autorun, computed } from 'mobx'
import { t } from 'i18next'
import { eSLCoveragePresenter } from '../../../protocol/set10/esl-coverage-presenter'
import {
    ESLCoverageConditionVO,
    createESLCoverageConditionVO
} from '../../../protocol/set10/set-retail10-commons/set-esl-api/esl-coverage-condition-vo'
import {
    ESLCoverageConditionType,
    PRODUCT as ESL_PRODUCT,
    PRODUCT_GROUP as ESL_PRODUCT_GROUP,
    PRODUCT_TYPE as ESL_PRODUCT_TYPE,
    MEASURE_UNIT as ESL_MEASURE_UNIT
} from '../../../protocol/set10/set-retail10-commons/set-esl-api/esl-coverage-condition-type'
import { SimpleProductsGroup } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-products-group'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE, APP_STORE } from '../stores'
import { AppStore } from '../app-store'
import { SimpleMeasure } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-measure'
import { IdInfoPair } from '../../../protocol/set10/set-retail10-server/retailx/set-product-type-manager/id-info-pair'
import { productTypesManagerLocal } from '../../../protocol/set10/product-types-manager-local'
import { SimpleProduct } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { ProductsFindResponse } from '../../../protocol/set10/set-retail10-commons/data-structs-module/products-find-response'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import {
    ProductEntityType,
    PRODUCT,
    PRODUCTS_GROUP,
    PRODUCT_TYPE,
    MEASURE_UNIT,
    BoundProductEntity
} from '../../components/product-entities-binder/product-entities-binder'

export class PriceTagsEslCoverageStore {

    @observable
    productGroups: SimpleProductsGroup[] = []

    @observable
    products: SimpleProduct[] = []

    @observable
    measureUnits: SimpleMeasure[] = []

    @observable
    productTypes: IdInfoPair[] = []

    @observable
    coverageConditions: ESLCoverageConditionVO[] = []

    serverCoverageConditions: ESLCoverageConditionVO[] = []

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    constructor() {
        // запрашиваем продукты по кодам каждый раз при изменении coverageConditions
        autorun(() => {
            if (!this.coverageConditions) {
                return
            }

            const productCodes: string[] = this.coverageConditions
                .filter(cc => cc.type === ESL_PRODUCT)
                .map(cc => cc.value)

            if (productCodes.length > 0) {
                this.fetchProductsByCodes(productCodes)
            }
        })
    }

    @computed
    get boundEntities(): BoundProductEntity[] {
        if (!this.coverageConditions) return []

        return this.coverageConditions
            .filter(condition => Boolean(eslTypeToBinderEntity(condition.type)))
            .map(condition => {
                const productEntityType: ProductEntityType = eslTypeToBinderEntity(condition.type)
                switch (productEntityType) {
                    case PRODUCTS_GROUP: {
                        const productGroup: SimpleProductsGroup = this.productGroups
                            && this.productGroups.find(pg => pg.code === condition.value)
                        return {
                            type: productEntityType,
                            value: null,
                            label: productGroup ? productGroup.name : '',
                            code: condition.value,
                        }
                    }
                    case PRODUCT: {
                        // ищем соответствующий product, чтобы передать в binder человекочитаемый label
                        const product: SimpleProduct = this.products
                            && this.products.find(p => p.code === condition.value)
                        return {
                            type: productEntityType,
                            value: null,
                            label: product ? product.name : '',
                            code: condition.value,
                        }
                    }
                    case PRODUCT_TYPE: {
                        // ищем соответствующий productType, чтобы передать в binder человекочитаемый label
                        const productType: IdInfoPair = this.productTypes
                            && this.productTypes.find(pt => pt.id === condition.value)
                        return {
                            type: productEntityType,
                            value: null,
                            label: productType ? productType.info : '',
                            code: condition.value,
                        }
                    }
                    case MEASURE_UNIT: {
                        // ищем соответствующий measureUnit, чтобы передать в binder человекочитаемый label
                        const measureUnit: SimpleMeasure = this.measureUnits
                            && this.measureUnits.find(mu => mu.id === condition.value)
                        return {
                            type: productEntityType,
                            value: null,
                            label: measureUnit ? measureUnit.name : '',
                            code: condition.value,
                        }
                    }
                    default:
                        return null
                }
            })
    }

    fetchProductGroups = async (): Promise<void> => {
        const productGroups: SimpleProductsGroup[] = await iProductsManagerLocal.getProductGroups(
            this.userStore.session, ''
        )
        runInAction(() => {
            this.productGroups = productGroups
        })
    }

    fetchProductsByCodes = async (codes: string[]): Promise<void> => {
        const productsFindResponse: ProductsFindResponse
            = await productManagerFinderLocal.getSimpleProductsByCodes1(this.userStore.session, codes)

        if (productsFindResponse && productsFindResponse.foundProducts) {
            runInAction(() => {
                this.products = productsFindResponse.foundProducts
            })
        }
    }

    fetchMeasureUnits = async (): Promise<void> => {
        const measureUnits: SimpleMeasure[] = await iProductsManagerLocal.getSimpleMeasure(
            this.userStore.session, 0, 1000
        )
        runInAction(() => {
            this.measureUnits = measureUnits
        })
    }

    fetchProductTypes = async (): Promise<void> => {
        const productTypes: IdInfoPair[] = await productTypesManagerLocal.getLocalizedPluginInfos(null)
        runInAction(() => {
            this.productTypes = productTypes
        })
    }

    fetchCoverageConditions = async (): Promise<void> => {
        const coverageConditions = await eSLCoveragePresenter.findAll()
        runInAction(() => {
            this.coverageConditions = coverageConditions
            this.serverCoverageConditions = coverageConditions
        })
    }

    @action
    setConditionsFromEntities = (newEntities: BoundProductEntity[]): void => {
        this.coverageConditions = newEntities.map(entity => {
            return createESLCoverageConditionVO({
                type: binderEntityToEslType(entity.type as ProductEntityType),
                value: entity.code
            })
        })
        this.syncConditionsWithServer()
    }

    @action
    addCondition = (condition: ESLCoverageConditionVO): void => {
        this.coverageConditions = this.coverageConditions.concat(condition)
        this.syncConditionsWithServer()
    }

    @action
    deleteCondition = (type: ESLCoverageConditionType, value: string): void => {
        this.coverageConditions = this.coverageConditions.filter(condition => {
            return condition.type !== type && condition.value !== value
        })
        this.syncConditionsWithServer()
    }

    /*
     * Вычисляет diff между серверными и локальными coverageConditions
     * Затем последовательно отправляет save и delete запросы на сервер по conditions из diff
     */
    syncConditionsWithServer = async (): Promise<void> => {
        const conditionsToDelete: ESLCoverageConditionVO[] = this.serverCoverageConditions.filter(serverCondition => {
            return !this.coverageConditions.some(condition => {
                return serverCondition.type === condition.type && serverCondition.value === condition.value
            })
        })

        const conditionsToSave: ESLCoverageConditionVO[] = this.coverageConditions.filter(condition => {
            return !this.serverCoverageConditions.some(serverCondition => {
                return serverCondition.type === condition.type && serverCondition.value === condition.value
            })
        })

        conditionsToDelete.forEach(async condition => {
            await eSLCoveragePresenter.delete(toJS(condition))
        })

        conditionsToSave.forEach(async condition => {
            await eSLCoveragePresenter.save(toJS(condition))
        })

        if ([...conditionsToDelete, ...conditionsToSave].length > 0) {
            this.appStore.showSnackbar({ message: t('common.settingsSaved') })
            this.serverCoverageConditions = this.coverageConditions
        }

    }

    @action
    reset = () => {
        this.productGroups = []
        this.products = []
        this.measureUnits = []
        this.productTypes = []
        this.coverageConditions = []
        this.serverCoverageConditions = []
    }

}

function eslTypeToBinderEntity(eslType: ESLCoverageConditionType): ProductEntityType {
    switch (eslType) {
        case ESL_PRODUCT:
            return PRODUCT
        case ESL_PRODUCT_GROUP:
            return PRODUCTS_GROUP
        case ESL_PRODUCT_TYPE:
            return PRODUCT_TYPE
        case ESL_MEASURE_UNIT:
            return MEASURE_UNIT
        default:
            return null
    }
}

function binderEntityToEslType(binderEntity: ProductEntityType): ESLCoverageConditionType {
    switch (binderEntity) {
        case PRODUCT:
            return ESL_PRODUCT
        case PRODUCTS_GROUP:
            return ESL_PRODUCT_GROUP
        case PRODUCT_TYPE:
            return ESL_PRODUCT_TYPE
        case MEASURE_UNIT:
            return ESL_MEASURE_UNIT
        default:
            return null
    }
}
