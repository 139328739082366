import { forOwn, isNil } from 'lodash'
import { getStore } from './stores-repository'
import {
    VALIDATION_FAILED,
    NO_DEFAULT_PRICE_TAG,
    NOT_SENT_TO_CASH,
    NOT_SENT_TO_SCALES,
    ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED,
    ACTION_PRICE_TAG_EXPIRED, FAILED_SENT_TO_CASH,
    FAILED_SENT_TO_SCALES,
    LOADING_FAILED,
    PRICE_TAG_NOT_PRINTED,
    REGULAR_PRICE_ENDED,
    JUST_SAVED,
    NOT_BINDED_WITH_SCALES,
    PRICE_TAG_PRINT_ERROR,
} from '../core/products/product-statuses'
import { productsCounterLocal } from '../../protocol/set10/products-counter-local'
import { iTransportInfoLocal1 } from '../../protocol/set10/i-transport-info-local1'
import { iTransportInfoLocal2 } from '../../protocol/set10/i-transport-info-local2'
import { iTransportInfoLocal3 } from '../../protocol/set10/i-transport-info-local3'
import { observable, action, runInAction, computed } from 'mobx'
import { AppStore } from './app-store'
import { UserStore } from './user-store'
import { APP_STORE, USER_STORE, CUSTOM_PRINTING_BASKET_STORE, NAVIGATION_MENU_STORE } from './stores'
import { actionsPrintIntelService } from '../../protocol/set10/actions-print-intel-service'
import { ActionsFilter, createActionsFilter } from '../../protocol/set10/set-retail10-server/retailx/server-ds/actions-filter'
import { createDatePeriod } from '../../protocol/set10/set-retail10-commons/data-structs-module/date-period'
import {
    SETRETAILX_ESL_ADMINISTRATION,
    SETRETAILX_ESL_HAND_SEND,
    SETRETAILX_GOODS_ADMIN,
    SETRETAILX_WEIGHERTEMPLATE_ADMIN,
    SETRETAILX_PRICE_TEMPLATE_PRINT_DEFAULT,
    SETRETAILX_PRICE_TEMPLATE_PRINT_MANUALLY,
    SETRETAILX_GROUP_TEMPLATES_BY_PRINTERS_BINDING,
    SETRETAILX_PRINT_PRINT_LOG
} from '../core/privileges/privileges'
import { CASH, PRINTING, SCALES } from '../core/app/server-module'
import {
    splitNotificationByGroups, findNotificationInGroup, getNotificationGroup
} from '../components/pages-container/notifications/notifications-util'
import { eSLHistoryPresenter } from '../../protocol/set10/esl-history-presenter'
import { CustomPrintingBasketStore } from './price-tags/custom-printing-basket-store'
import { config, DEV } from '../config/config'
import { NavigationMenuStore } from './navigation-menu-store'
import { getExternalModulesNotifications } from '../utils/app-util'
import { V1NotificationsResponse, V1NotificationsSubGroup } from '../core/iframe-modules/entities'

export type NotificationGroup = 'monitoring' | 'tasks'
export const MONITORING: NotificationGroup = 'monitoring'
export const TASKS: NotificationGroup = 'tasks'

export type NotificationSubGroup = 'products' | 'cards' | 'actions'
export const PRODUCTS: NotificationSubGroup = 'products'
export const CARDS: NotificationSubGroup = 'cards'
export const ACTIONS: NotificationSubGroup = 'actions'

export type NotificationType = 'refusedProducts' | 'notLoadedProductsOnCashes' | 'notLoadedProductsOnShops' | 'notLoadedOnScales'
    | 'notBindedToScales' | 'refusedCards' | 'notBindedPriceTags' | 'notPrintedPriceTags' | 'notActualPriceTags' | 'customPriceTagsPrinting'
    | 'notLoadedCardsOnCashes' | 'notLoadedCardsOnShops' | 'notLoadedActionsOnShops' | 'notPrintedActionPriceTags' | 'notActualActionPriceTags'
    | 'notLoadedProductsToESL' | 'printErrors'

// Товары
// ITransportInfoLocal2.getCountersByStatus(Сentrum), ProductsCounterLocal.getProductCounts(Retail)
// 110
export const REFUSED_PRODUCTS: NotificationType = 'refusedProducts'
// 11 - Only Retail
export const NOT_BINDED_PRICE_TAGS: NotificationType = 'notBindedPriceTags'
// 1012 - Only Retail
export const NOT_BINDED_TO_SCALES: NotificationType = 'notBindedToScales'
// 1001 / 1003 - not loaded / fail to load - Only Retail
export const NOT_LOADED_PRODUCTS_ON_CASHES: NotificationType = 'notLoadedProductsOnCashes'
// 1002 / 1004 - not loaded / fail to load - Only Retail
export const NOT_LOADED_ON_SCALES: NotificationType = 'notLoadedOnScales'
// 12 / 122 - regular / action - Only Retail
export const NOT_PRINTED_PRICE_TAGS: NotificationType = 'notPrintedPriceTags'
// 99 / 123 - regular / action - Only Retail, Пока только на флексе
export const CUSTOM_PRICE_TAGS_PRINTING: NotificationType = 'customPriceTagsPrinting'
// 2020 / 124 - regular / action - Only Retail
export const NOT_ACTUAL_PRICE_TAGS: NotificationType = 'notActualPriceTags'
// 0 / 2 - not loaded / fail to load - Only centrum
export const NOT_LOADED_PRODUCTS_ON_SHOPS: NotificationType = 'notLoadedProductsOnShops'
// 1200 - print error - Only retail
export const PRINT_ERRORS: NotificationType = 'printErrors'

// Карты
// ITransportInfoLocal3.getCountersByStatus, карты используют аналогичные статусы, как у продуктов
// 110 - Retail
export const REFUSED_CARDS: NotificationType = 'refusedCards'
// 1001 / 1003 - not loaded / fail to load - Retail
export const NOT_LOADED_CARDS_ON_CASHES: NotificationType = 'notLoadedCardsOnCashes'
// 1001 / 1003 - not loaded / fail to load - Centrum
export const NOT_LOADED_CARDS_ON_SHOPS: NotificationType = 'notLoadedCardsOnShops'

// Лояльность
// ITransportInfoLocal1.getCountersByStatus
// 0 - Не загружены в магазины - Centrum, используется статус, как у продуктов
export const NOT_LOADED_ACTIONS_ON_SHOPS: NotificationType = 'notLoadedActionsOnShops'
// ActionsPrintIntelService.countActionsByPrintedStatus - Ненапечатанные ценники, с 00:00:00.000 до 23:59:59.000, printedOnly = false
export const NOT_PRINTED_ACTION_PRICE_TAGS: NotificationType = 'notPrintedActionPriceTags'
// ActionsPrintIntelService.countActionsByPrintedStatus - Необходимо снять ценники, с 00:00:00.000 до 23:59:59.000, printedOnly = true
export const NOT_ACTUAL_ACTION_PRICE_TAGS: NotificationType = 'notActualActionPriceTags'

// Ошибки выгрузки товаров в ESL
export const NOT_LOADED_PRODUCTS_TO_ESL: NotificationType = 'notLoadedProductsToESL'

export const MONITORING_GROUP: NotificationType[] = [
    REFUSED_PRODUCTS,
    NOT_LOADED_PRODUCTS_ON_CASHES,
    NOT_LOADED_PRODUCTS_ON_SHOPS,
    NOT_BINDED_TO_SCALES,
    NOT_LOADED_ON_SCALES,
    REFUSED_CARDS,
    NOT_LOADED_CARDS_ON_CASHES,
    NOT_LOADED_CARDS_ON_SHOPS,
    NOT_LOADED_ACTIONS_ON_SHOPS,
    NOT_LOADED_PRODUCTS_TO_ESL,
    PRINT_ERRORS,
]

export const TASKS_GROUP: NotificationType[] = [
    NOT_BINDED_PRICE_TAGS,
    NOT_PRINTED_PRICE_TAGS,
    CUSTOM_PRICE_TAGS_PRINTING,
    NOT_ACTUAL_PRICE_TAGS,
    NOT_PRINTED_ACTION_PRICE_TAGS,
    NOT_ACTUAL_ACTION_PRICE_TAGS,
]

export const PRODUCTS_GROUP: NotificationType[] = [
    REFUSED_PRODUCTS,
    NOT_LOADED_PRODUCTS_ON_CASHES,
    NOT_LOADED_PRODUCTS_ON_SHOPS,
    NOT_BINDED_TO_SCALES,
    NOT_LOADED_ON_SCALES,
    NOT_BINDED_PRICE_TAGS,
    NOT_PRINTED_PRICE_TAGS,
    CUSTOM_PRICE_TAGS_PRINTING,
    NOT_ACTUAL_PRICE_TAGS,
    NOT_LOADED_PRODUCTS_TO_ESL,
    PRINT_ERRORS,
]

export const CARDS_GROUP: NotificationType[] = [
    REFUSED_CARDS,
    NOT_LOADED_CARDS_ON_CASHES,
    NOT_LOADED_CARDS_ON_SHOPS,
]

export const ACTIONS_GROUP: NotificationType[] = [
    NOT_PRINTED_ACTION_PRICE_TAGS,
    NOT_ACTUAL_ACTION_PRICE_TAGS,
    NOT_LOADED_ACTIONS_ON_SHOPS,
]

export interface NotificationGroupInfo {
    type: NotificationGroup
    subGroups: NotificationSubGroupInfo[] | V1NotificationsSubGroup[]
}

export interface NotificationSubGroupInfo {
    type: NotificationSubGroup
    notifications: NotificationInfo[]
}

export interface NotificationInfo {
    type: NotificationType
    group: NotificationGroup
    subGroup: NotificationSubGroup
    counters: number[]
    redirect: () => void
}

export const NOTIFICATIONS_UPDATE_TIMER = 30000

export class NotificationStore {

    @observable
    groups: NotificationGroupInfo[] = []

    @observable
    externalModulesNotifications: V1NotificationsResponse = null

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
    private customPrintingBasketStore: CustomPrintingBasketStore = getStore(CUSTOM_PRINTING_BASKET_STORE)

    private timeoutId: number

    @computed
    get groupsWithExternalModules(): NotificationGroupInfo[] {
        if (!this.externalModulesNotifications?.subGroups?.length) return this.groups

        const { subGroups } = this.externalModulesNotifications

        let newGroups = [ ...this.groups ]

        const monitoringSubGroups = subGroups.filter(subGroup => subGroup.group === MONITORING)
        const tasksSubGroups = subGroups.filter(subGroup => subGroup.group === TASKS)

        if (monitoringSubGroups.length > 0) {
            let monitoringIndex = newGroups.findIndex(item => item.type === MONITORING)
            if (monitoringIndex === -1) {
                monitoringIndex = newGroups.length
                newGroups.push({
                    type: MONITORING,
                    subGroups: []
                })
            }
            const oldMonitoringGroup = newGroups[monitoringIndex]
            newGroups[monitoringIndex] = {
                type: MONITORING,
                subGroups: [
                    ...(oldMonitoringGroup.subGroups as NotificationSubGroupInfo[]),
                    ...(monitoringSubGroups as V1NotificationsSubGroup[])
                ] as NotificationSubGroupInfo[]
            }
        }

        if (tasksSubGroups.length > 0) {
            let tasksIndex = newGroups.findIndex(item => item.type === TASKS)
            if (tasksIndex === -1) {
                tasksIndex = newGroups.length
                newGroups.push({
                    type: TASKS,
                    subGroups: []
                })
            }
            const oldTasksGroup = newGroups[tasksIndex]
            newGroups[tasksIndex] = {
                type: TASKS,
                subGroups: [
                    ...(oldTasksGroup.subGroups as NotificationSubGroupInfo[]),
                    ...(tasksSubGroups as V1NotificationsSubGroup[])
                ] as NotificationSubGroupInfo[]
            }
        }

        return newGroups
    }

    getRequestOptions = (): { hideConsoleLog?: boolean } => {
        return config.environment === DEV ? { hideConsoleLog: true } : undefined
    }

    startNotificationsTimer = async (): Promise<void> => {
        await this.fetchAllNotifications()
        this.timeoutId = setTimeout(this.startNotificationsTimer, NOTIFICATIONS_UPDATE_TIMER)
    }

    fetchAllNotifications = async (): Promise<void> => {
        let productCounters = await this.fetchProductCounters()
        let cardsCounters = await this.fetchCardsCounters()
        let actionCounters = await this.fetchActionCounters()
        let eslErrorCounters = await this.fetchESLErrorCounters()
        let externalModulesNotifications = await getExternalModulesNotifications(this.appStore.locale)

        runInAction(() => {
            // Преобразовываем счетчики в нотификации
            this.groups = splitNotificationByGroups({
                ...productCounters,
                ...cardsCounters,
                ...actionCounters,
                ...eslErrorCounters
            })
            this.externalModulesNotifications = externalModulesNotifications
        })
    }

    fetchProductCounters = async (): Promise<{[key: string]: number[]}> => {
        const { havePrivilege, haveAnyPrivilegeFromList } = this.userStore
        if (!havePrivilege(SETRETAILX_GOODS_ADMIN)) {
            return Promise.resolve({})
        }

        let events: {[type: string]: number[]} = {}

        // Счетчики с сервера могут не прийти
        const counterByStatus = {
            [VALIDATION_FAILED]: 0,
            [JUST_SAVED]: 0,
            [LOADING_FAILED]: 0,
            [NOT_BINDED_WITH_SCALES]: 0,
            [NOT_SENT_TO_CASH]: 0,
            [FAILED_SENT_TO_CASH]: 0,
            [NOT_SENT_TO_SCALES]: 0,
            [FAILED_SENT_TO_SCALES]: 0,
            [NO_DEFAULT_PRICE_TAG]: 0,
            [PRICE_TAG_NOT_PRINTED]: 0,
            [ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED]: 0,
            [REGULAR_PRICE_ENDED]: 0,
            [ACTION_PRICE_TAG_EXPIRED]: 0,
            [PRICE_TAG_PRINT_ERROR]: 0,
        }

        if (this.appStore.isCentrum) {
            const result = await iTransportInfoLocal2.getCountersByStatus(this.getRequestOptions())
            forOwn(result, (count = 0, code) => {
                if (isNaN(Number(code))) return
                counterByStatus[code] = count
            })

            events[REFUSED_PRODUCTS] = [counterByStatus[VALIDATION_FAILED]]
            events[NOT_LOADED_PRODUCTS_ON_SHOPS] = [counterByStatus[JUST_SAVED], counterByStatus[LOADING_FAILED]]
        } else {
            const result = await productsCounterLocal.getProductCounts(this.getRequestOptions())
            result.forEach(({ code = 0, count = 0 }) => {
                counterByStatus[code] = count
            })

            events[REFUSED_PRODUCTS] = [counterByStatus[VALIDATION_FAILED]]
            events[NOT_BINDED_TO_SCALES] = [counterByStatus[NOT_BINDED_WITH_SCALES]]
            events[NOT_LOADED_PRODUCTS_ON_CASHES] = [counterByStatus[NOT_SENT_TO_CASH], counterByStatus[FAILED_SENT_TO_CASH]]
            events[NOT_LOADED_ON_SCALES] = [counterByStatus[NOT_SENT_TO_SCALES], counterByStatus[FAILED_SENT_TO_SCALES]]
            events[NOT_BINDED_PRICE_TAGS] = [counterByStatus[NO_DEFAULT_PRICE_TAG]]
            events[NOT_PRINTED_PRICE_TAGS] = [
                counterByStatus[PRICE_TAG_NOT_PRINTED],
                counterByStatus[ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_NOT_PRINTED]
            ]

            const { regularBasketItems, actionBasketItems } = this.customPrintingBasketStore
            events[CUSTOM_PRICE_TAGS_PRINTING] = [
                regularBasketItems && regularBasketItems.length || 0,
                actionBasketItems && actionBasketItems.length || 0
            ]

            events[NOT_ACTUAL_PRICE_TAGS] = [counterByStatus[REGULAR_PRICE_ENDED], counterByStatus[ACTION_PRICE_TAG_EXPIRED]]
            events[PRINT_ERRORS] = [counterByStatus[PRICE_TAG_PRINT_ERROR]]
        }

        // Почистим нулевые счетчики и учтем наличие серверных модулей(хотя это серьезно устарело)
        forOwn(events, (counters, type) => {
            if (!counters.find(counter => counter !== 0)) {
                delete events[type]
            }
        })

        if (!this.appStore.getModuleInfo(SCALES) || !havePrivilege(SETRETAILX_WEIGHERTEMPLATE_ADMIN)) {
            delete events[NOT_BINDED_TO_SCALES]
            delete events[NOT_LOADED_ON_SCALES]
        }

        if (!this.appStore.getModuleInfo(CASH)) {
            delete events[NOT_LOADED_PRODUCTS_ON_CASHES]
        }

        if (!this.appStore.getModuleInfo(PRINTING)) {
            delete events[NOT_BINDED_PRICE_TAGS]
            delete events[NOT_PRINTED_PRICE_TAGS]
            delete events[CUSTOM_PRICE_TAGS_PRINTING]
            delete events[NOT_ACTUAL_PRICE_TAGS]
        }

        if (!this.appStore.getModuleInfo(PRINTING) || (!havePrivilege(SETRETAILX_PRINT_PRINT_LOG))) {
            delete events[PRINT_ERRORS]
        }

        return events
    }

    fetchCardsCounters = async (): Promise<{[type: string]: number[]}> => {
        if (!this.userStore.havePrivilege(SETRETAILX_GOODS_ADMIN)) {
            return Promise.resolve({})
        }

        let events: {[type: string]: number[]} = {}

        // Счетчики с сервера могут не прийти
        const counterByStatus = {
            [VALIDATION_FAILED]: 0,
            [NOT_SENT_TO_CASH]: 0,
            [FAILED_SENT_TO_CASH]: 0,
        }

        const result = await iTransportInfoLocal3.getCountersByStatus(this.getRequestOptions())
        forOwn(result, (counter = 0, status) => {
            if (isNaN(Number(status))) return
            counterByStatus[status] = counter
        })

        if (this.appStore.isCentrum) {
            // Используются продуктовые статусы
            events[NOT_LOADED_CARDS_ON_SHOPS] = [counterByStatus[NOT_SENT_TO_CASH], counterByStatus[FAILED_SENT_TO_CASH]]
        } else {
            events[REFUSED_CARDS] = [counterByStatus[VALIDATION_FAILED]]
            events[NOT_LOADED_CARDS_ON_CASHES] = [counterByStatus[NOT_SENT_TO_CASH], counterByStatus[FAILED_SENT_TO_CASH]]
        }

        // Почистим нулевые счетчики и учтем наличие серверных модулей(хотя это серьезно устарело)
        forOwn(events, (counters, type) => {
            if (!counters.find(counter => counter !== 0)) {
                delete events[type]
            }
        })

        if (!this.appStore.getModuleInfo(CASH)) {
            delete events[NOT_LOADED_CARDS_ON_CASHES]
        }

        return events
    }

    fetchActionCounters = async (): Promise<{[type: string]: number[]}> => {
        if (!this.userStore.havePrivilege(SETRETAILX_GOODS_ADMIN)) {
            return Promise.resolve({})
        }

        let events: {[type: string]: number[]} = {}

        // Счетчики с сервера могут не прийти
        const counterByStatus = {
            [JUST_SAVED]: 0,
        }

        if (this.appStore.isCentrum) {
            const result = await iTransportInfoLocal1.getCountersByStatus(this.getRequestOptions())
            forOwn(result, (counter = 0, status) => {
                if (isNaN(Number(status))) return
                counterByStatus[status] = counter
            })

            events[NOT_LOADED_ACTIONS_ON_SHOPS] = [counterByStatus[JUST_SAVED]]
        } else {
            let dayStart = new Date()
            let dayEnd = new Date()
            dayStart.setHours(0, 0, 0, 0)
            dayEnd.setHours(23, 59, 59, 999)

            const filter: ActionsFilter = createActionsFilter({
                actionBeginDateInterval: createDatePeriod({
                    start: dayStart,
                    finish: dayEnd
                })
            })
            const session = this.userStore.session

            let notPrintedTags = await actionsPrintIntelService.countActionsByPrintedStatus(
                session, filter, false, this.getRequestOptions()
            )
            if (isNil(notPrintedTags)) notPrintedTags = 0
            events[NOT_PRINTED_ACTION_PRICE_TAGS] = [notPrintedTags]

            let notActualTags = await actionsPrintIntelService.countActionsByPrintedStatus(
                session, filter, true, this.getRequestOptions()
            )
            if (isNil(notActualTags)) notActualTags = 0
            events[NOT_ACTUAL_ACTION_PRICE_TAGS] = [notActualTags]
        }

        // Почистим нулевые счетчики
        forOwn(events, (counters, type) => {
            if (!counters.find(counter => counter !== 0)) {
                delete events[type]
            }
        })

        return events
    }

    getNotificationCounters = (type: NotificationType): number[] => {
        const groupType = getNotificationGroup(type)
        const group = this.groups.find(g => g.type === groupType)
        const notification = findNotificationInGroup(type, group)
        return notification ? notification.counters : []
    }

    fetchESLErrorCounters = async (): Promise<{[type: string]: number[]}> => {
        const havePrivileges = this.userStore.haveAnyPrivilegeFromList([SETRETAILX_ESL_ADMINISTRATION, SETRETAILX_ESL_HAND_SEND])
        if (!havePrivileges || this.appStore.isCentrum) {
            return {}
        }

        let counters = await eSLHistoryPresenter.getErrorProductsCount(this.getRequestOptions())

        // TODO SFM-208 ошибки транспорта
        if (counters) {
            counters = counters.map(counter => {
                if (Array.isArray(counter) && counter.length === 2) {
                    counter = counter[1] as any
                }
                return counter
            })
        }

        if (!counters || counters.length === 0) return {}

        // Если все счетчики нулевые - не показываем уведомление
        if (!counters.some(counter => counter > 0)) return {}

        if (counters.length < 2) counters.push(0)

        return {
            [NOT_LOADED_PRODUCTS_TO_ESL]: counters
        }
    }

    @action
    reset = (): void => {
        this.groups = []
        clearTimeout(this.timeoutId)
    }
}
