import { OperdayStore } from './operday-store'
import { getStore } from '../stores-repository'
import { OPERDAY_STORE, USER_STORE } from '../stores'
import { computed, toJS } from 'mobx'
import { Shift } from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/shift'
import { flatten } from 'lodash'
import {
    CLOSED,
    OperDayStatus,
    READY_TO_OPEN
} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/oper-day'
import { UserStore } from '../user-store'
import { reportsProcessorLocal } from '../../../protocol/set10/reports-processor-local'
import { config } from '../../config/config'

export class Ko5ReportStore {
    private operdayStore: OperdayStore = getStore(OPERDAY_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    @computed
    get shifts(): Shift[] {
        const cashDesks = toJS(this.operdayStore.operday).cashDesks

        if (!cashDesks || cashDesks.length === 0) {
            return []
        }

        return  flatten(
            cashDesks.map(cashDesk => cashDesk.shifts))
    }

    @computed
    get isOperdayClosed(): boolean {
        const status: OperDayStatus = toJS(this.operdayStore.operday).status
        return status === CLOSED || status === READY_TO_OPEN
    }

    @computed
    get canGenerateKo5Report(): boolean {
        return this.isOperdayClosed
            && this.shifts
            && this.shifts.length > 0
    }

    generateKO5Report = async (): Promise<void> => {
        // window.open сработает только если он был вызван в главном потоке
        // поэтому мы сначала открываем окно, а потом подменяем ему адрес
        const reportWindow = window.open()

        const filename = await reportsProcessorLocal.getFileForKo5(this.operdayStore.date, this.userStore.userInfo.id)
        const reportUrl = `${config.reportsAddress}?Action=getFile&FILE_NAME=${filename}`
        reportWindow.location.replace(reportUrl)
    }
}
