import { parseString, Builder as XMLBuilder } from 'xml2js'
import { t } from 'i18next'
import {
    AdvertisingAction,
    createAdvertisingAction,
} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { getCounterResultChipLabel } from '../../pages/loyalty/actions/action-edit/results/editors/counter/counter'
import { last, partition, cloneDeep } from 'lodash'
import {
    SECOND_PRICE,
    THIRD_PRICE,
    FOURTH_PRICE,
    FIFTH_PRICE,
} from '../price-tags/product-properties'
import {
    CounterMetricsType,
    CounterIndicatorType,
    CounterPeriodUnitType,
    Condition,
    ConditionFields,
} from './action-conditions'
import { AdvancedMessagesResultData, validateAdvancedMessage, validateScreenMessage } from './results/advanced-messages'
import { getBonusNameByType } from '../../pages/loyalty/actions/action-edit/results/editors/discount/editors/bonus-discount-editor/bonus-type-util'
import {
    DiscountType,
    Period
} from '../../components/cash-printer-editor/coupon-print-settings-modal/coupon-print-settings-modal'
import { validateCoupon } from './results/coupon'
import moment from 'moment'
import { fromClientToServerTime } from '../../utils/app-util'
import { validateMessages } from './results/messages'

export interface AdvertisingActionParsed extends AdvertisingAction {
    externalConditionsParsed: Condition[]
    resultsParsed: Result[]
}

export function makeAdvertisingActionParsed(input: Partial<AdvertisingActionParsed>) {
    return { ...createAdvertisingAction(input), ...input }
}

// Results

export const serializeItem = (i: Result | Condition) => {
    const xmlBuilder = new XMLBuilder({ headless: true, rootName: i.type })
    return xmlBuilder.buildObject(recursiveRemoveEmptyKeys(cloneDeep(i.data), null))
}

// removes keys with nilValues and nilvalues from array
// { key1: { key2: null, key3: 3, key4: ['', '1'] } } => { key1: { key3: 3, key4: ['1'] } }
/** !warning! mutates argument! */
function recursiveRemoveEmptyKeys(node: any, nodeKey: string): any {
    const nilValues = ['', null, undefined]

    /* TODO костыль, потенциальная возможность для возникновения багов!
    *  Сейчас на бэкенде пустые поля нужно удалять вместе с тегами
    * НО ЕСТЬ ИСКЛЮЧЕНИЯ, которые тянут на критикал (https://crystals.atlassian.net/browse/SFM-1643)
    * некоторые пустые поля нужно оставлять в виде пустых тегов, например <workPeriodRange /> */
    const nonNullableFieldKeys = [
        'workPeriodRange',
        'clientMessage',
        'operatorMessage',
        'additionalValue',
        'rows',
        'idOfCondition',
        'maxCount',
        'groupId',
        'manufacturerId',
        'saleGroupId',
        'description',
        'countryId',
        'typeProductId',
        'maxCount',
        'groupId',
        'saleGroupId',
        'description',
    ]

    if (Array.isArray(node)) {
        node = node
            .filter(item => {
                return !nilValues.some(nil => nil === item)
            })
            .map(item => recursiveRemoveEmptyKeys(item, null))

        /* TODO костыль! чтобы не удалялись из итоговой xml */
        if (nonNullableFieldKeys.some(nnKey => nnKey === nodeKey) && node.length === 0) {
            return ['']
        }

        return node
    }

    if (typeof node === 'object') {
        Object.keys(node).forEach(key => {
            if (!nonNullableFieldKeys.some(nnKey => nnKey === key) && nilValues.some(nil => nil === node[key])) {
                delete node[key]
            }

            if (typeof node[key] === 'object') {
                node[key] = recursiveRemoveEmptyKeys(node[key], key)
            }
        })

        return node
    }

    return node
}

export const serializeResults = (action: AdvertisingActionParsed): Partial<AdvertisingAction> => {
    // Discount хранится в action.applyObjects, остальные - в action.actionResults
    const [applyObjects, actionResults] = partition(action.resultsParsed, r =>
        DiscountResultTypes.includes(r.type)
    )
    return {
        actionResults: actionResults.map(serializeItem),
        applyObjects: applyObjects.map(serializeItem),
    }
}

export enum ResultType {
    AdvancedMessages = 'ru.crystalservice.setv6.discounts.plugins.AdvancedMessagesActionResult',
    Product = 'ru.crystalservice.setv6.discounts.plugins.ProductActionResult',
    ChequeAdvert = 'ru.crystalservice.setv6.discounts.plugins.ChequeAdvertResult',
    Bonus = 'ru.crystalservice.setv6.discounts.plugins.BonusActionResult',
    BonusAccrualRule = 'ru.crystalservice.setv6.discounts.plugins.BonusAccrualRule',
    TokenAccrualRule = 'ru.crystalservice.setv6.discounts.plugins.TokenAccrualRule',
    TokensDiscountApplyObject = 'ru.crystalservice.setv6.discounts.plugins.TokensDiscountApplyObject',
    ProductTokenApplyRule = 'ru.crystalservice.setv6.discounts.plugins.ProductTokenApplyRule',
    TokenRule = 'ru.crystalservice.setv6.discounts.plugins.TokenRule',
    ScannerMessages = 'ru.crystalservice.setv6.discounts.plugins.ScannerMessagesActionResult',
    Counter = 'ru.crystalservice.setv6.discounts.plugins.UpdateCounterActionResult',
    Coupon = 'ru.crystalservice.setv6.discounts.plugins.CouponActionResult',
    Discount = 'ru.crystalservice.setv6.discounts.plugins.DiscountActionResult',
    Messages = 'ru.crystalservice.setv6.discounts.plugins.MessagesActionResult',
    FixPrice = 'ru.crystalservice.setv6.discounts.plugins.FixPriceApplyObject',
    ApplyObject = 'ru.crystalservice.setv6.discounts.plugins.ApplyObject',
    ProductsSets = 'ru.crystalservice.setv6.discounts.plugins.SetApplyObject',
    ProductsSetsRow = 'ru.crystalservice.setv6.discounts.plugins.Row',
    ProductsDiscounts = 'ru.crystalservice.setv6.discounts.plugins.CompositeApplyObject',
    CompositeRow = 'ru.crystalservice.setv6.discounts.plugins.CompositeRow',
    BonusDiscount = 'ru.crystalservice.setv6.discounts.plugins.BonusDiscountApplyObject',
    ProductsRow = 'ru.crystalservice.setv6.discounts.plugins.ProductsRow',
}

export const DiscountResultTypes: ResultType[] = [
    ResultType.FixPrice,
    ResultType.ProductsSets,
    ResultType.ProductsDiscounts,
    ResultType.BonusDiscount,
    ResultType.Discount,    // Этот тип общий для нескольких вкладок
    ResultType.TokensDiscountApplyObject,
]

export interface CounterResultProduct {
    String: string[]
}

export interface CompositeObjectRows {
    String?: string[]
    long?: string[]
}

export interface ProductResultData {
    marking: string[]
    quantity: string[]
}

/** @deprecated - use ResultType.BonusAccrualRule */
export const BonusAccrualRulePlugin = 'ru.crystalservice.setv6.discounts.plugins.BonusAccrualRule'
/** @deprecated - use ResultType.ProductsRow */
export const ProductsRowPlugin = 'ru.crystalservice.setv6.discounts.plugins.ProductsRow'

export enum MessagesResultDataDisplayTime {
    AfterFiscalize = 'AFTER_FISCALIZE',
    Subtotal = 'SUBTOTAL',
}

export enum ApplyType {
    MIN_CONDITION = 'MIN_CONDITION',
    OVERFLOW = 'OVERFLOW',
    MAXIMUM_COUNT_IN_RECEIPT = 'MAXIMUM_COUNT_IN_RECEIPT',
    WHOLESALE_RESTRICTION = 'WHOLESALE_RESTRICTION',
    ALL = 'ALL',
}

export enum RoundingType {
    Round = 'ROUND',
    Positions = 'ROUND_BY_POSITIONS',
}

export enum CheckDiscountType {
    Percent = 'PERCENT',
    FixSum = 'FIXSUMM',
    DiscCard = 'DISCCARD',
    Coupon = 'COUPON',
    PaymentPercent = 'PAYMENT_TYPE_PERCENT',
    WholeSaleDiscount = 'WHOLESALE_DISCOUNT',
    SalesTaxDiscount = 'SALES_TAX_DISCOUNT',
}

export enum ProductListResultType {
    CatalogPrice = 'CATALOG_PRICE',
}

export enum ExternalLoyaltySystemType {
    ExternalLoy = 'EXTERNAL_LOYALTY',
}

export interface MessagesResultData {
    clientMessage: string[]
    clientSCOMessage: string[]
    displayTime: MessagesResultDataDisplayTime[]
    operatorMessage: string[]
}

export interface ChequeAdvertData {
    text?: string[]
    actionRestriction?: ActionRestriction[]
    clientRestriction?: ClientRestriction[]
    allowOffline?: string[],
    printSlipInsideReceipt?: string[]
}

export interface CouponData {
    printSlipInsideReceipt?: string[]
    priority?: string[]
    requiredClientCard?: string[]
    onlyOneTime?: string[]
    maxDiscount?: string[]
    discountType?: DiscountType[]
    discountValue?: string[]
    startsAfterPeriodType?: Period[]
    startsAfter?: string[]
    worksPeriodType?: Period[]
    worksPeriod?: string[]
    barCode?: string[]
    couponTypeGuid?: string[]
    text?: string[]
    barcodeType?: string[]
}

export interface ClientRestriction {
    maxValue?: string[],
    period?: string[],
    periodType?: string[],
    allPeriodOfAction?: string[]
}

export interface ActionRestriction {
    maxValue: string[]
}

export interface ScannerMessageResultData {
    clientMessage: string[]
    clientLargeMessage: string[]
}

export interface CounterResultData {
    metric?: CounterMetricsType[]
    indicator?: CounterIndicatorType[]
    periods?: string[]
    periodType?: CounterPeriodUnitType[]
    markings?: CounterResultProduct[]
}

export interface CounterResult {
    type: ResultType.Counter
    data: CounterResultData
}

export const getCalcTypeOpts = (): Array<{ value: ConditionFields; label: string }> => [
    {
        value: ConditionFields.ReceiptSumPreferencesCalculator,
        label: t('advertisingActions.calcType.ReceiptSumPreferencesCalculator'),
    },
    {
        value: ConditionFields.QuantityBasedPreferencesCalculator,
        label: t('advertisingActions.calcType.QuantityBasedPreferencesCalculator'),
    },
    {
        value: ConditionFields.SumBasedPreferencesCalculator,
        label: t('advertisingActions.calcType.SumBasedPreferencesCalculator'),
    },
]

export enum CompositeRowType {
    ITEM = 'ITEM',
    GROUP = 'GROUP',
    SALE_GROUP = 'SALE_GROUP',
}

export const getCompositeRowTypeOpts = (): Array<{ value: CompositeRowType; label: string }> => [
    { value: CompositeRowType.ITEM, label: t('advertisingActions.compositeRowType.ITEM') },
    { value: CompositeRowType.GROUP, label: t('advertisingActions.compositeRowType.GROUP') },
    { value: CompositeRowType.SALE_GROUP, label: t('advertisingActions.compositeRowType.SALE_GROUP') },
]

export interface DataEditorProps<D> {
    resultSet?: boolean
    setLoyaltyRestrictionsEnabled?: boolean
    readOnly?: boolean
    value: D
    onChange: (newData: D) => void
    onDiscountChange?: (newData: ProductsDiscountsResultData) => void
}

export type DataEditor<D> = React.FC<DataEditorProps<D>>

export interface FixPriceResultData {
    pluginName?: string[]
    applyObjects?: ApplyObject[]
}

export interface RoundingResultData {
    valueType: RoundingType[]
    value: string[]
}

export interface CheckDiscountResultData {
    valueType: CheckDiscountType[]
    value: string[]
    additionalValue: string[]
}

export interface ProductsSetsRowData {
    id: string[]
    count: string[]
    withoutDiscount: string[]
    item?: string[]
    value?: string[]
    manufacturerId?: string[]
    groupId?: string[]
    departId?: string[]
    countryId?: string[]
    typeProductId?: string[]
    saleGroupId?: string[]
    saleGroups?: Array<{
        String: string[]
    }>
    condition: string[]
    percent?: string[]
    price?: string[]
    description: string[]
    manufacturers?: CompositeObjectRows[]
    groups?: CompositeObjectRows[]
    countries?: CompositeObjectRows[]
    markings?: CompositeObjectRows[]
}

export interface ProductsDiscountsRowData {
    value?: string[]
    values?: CompositeObjectRows[]
    type: string[]
    description: string[]
}

export interface ProductsSetsRow {
    [ResultType.ProductsSetsRow]: ProductsSetsRowData[]
}

export interface ProductsDiscountsRow {
    [ResultType.CompositeRow]: ProductsDiscountsRowData[]
}

export interface ProductsSetsResultData {
    name: string[]
    maxCount: string[]
    setCost: string[]
    isSpreadDiscounts: string[]
    valueType: string[]
    wholesaleRestriction: string[]
    calcWeightProductCountAsOne: string[]
    rows: ProductsSetsRow[]
}

export enum ProductsSetsConditions {
    Any = 'ANY',
    More = 'MORE',
    Less = 'LESS',
    ProductsLess = 'PERCENT',
}

export enum ProductsSetsValueType {
    NoDiscount = '',
    FixPrice = 'FIXPRICE',
    Percent = 'PERCENT',
}

export enum ProductsDiscountsValueType {
    Percent = 'PERCENT',
    FixSum = 'FIXSUMM',
    GoodsSum = 'GOODS_SUMM',
    FixPrice = 'FIX_PRICE',
}

export interface ProductListPriceResultData {
    valueType: ProductListResultType[],
    priceType: string[]
}

export interface ExternalLoyaltySystemResultData {
    valueType: ExternalLoyaltySystemType[]
    additionalValue: string[]
}

export interface TimeUnitPeriod {
    type: PeriodTypes[]
    unitsFrom: string[]
    unitsTill: string[]
}

export interface StorageTimeCondition {
    period: TimeUnitPeriod[],
    excludeManuallyAddedPositions: string[]
}

export interface ProductLimitPeriod {
    maxValue: string[]
    periodType: string[]
    period: string[]
}

export interface ProductsDiscountsResultData {
    pluginName: string[]
    item: CompositeObjectRows[]
    manufacturerId?: CompositeObjectRows[]
    groupId?: CompositeObjectRows[]
    departId?: CompositeObjectRows[]
    countryId?: CompositeObjectRows[]
    typeProductId?: CompositeObjectRows[]
    saleGroupId?: CompositeObjectRows[]
    rows: ProductsDiscountsRow[]
    exclude?: ProductsDiscountsRow[]
    valueType: string[]
    forAllGoods?: string[]
    value: string[]
    maxCount: string[]
    calcWeightProductCountAsOne: string[]
    applyType: string[]
    applyCount: string[]
    wholesaleRestriction: string[]
    storageTimeCondition: StorageTimeCondition[]
    allowOffline: string[]
    clientRestriction: ProductLimitPeriod[]
    forGiftsOnly?: string[]
}

export interface ApplyObject {
    item?: string[]
    maxCount?: number[]
    applyCount?: number[]
    newPrice?: number[]
    wholesaleRestriction?: number[]
    applyType: ApplyType[]
}

// Bonus
export enum BonusResultAddType {
    FIXBONUS = 'FIXBONUS',
    DISCBONUS = 'DISCBONUS',
    AMOUNTPERCENT = 'AMOUNTPERCENT',
    MULTIPLEPERCENT = 'MULTIPLEPERCENT',
    BANKPERCENT = 'BANKPERCENT',
    TOKENS = 'TOKENS'
}

export enum IntervalType {
    ABSOLUTE = 'ABSOLUTE',
    RELATIVE = 'RELATIVE'
}

export enum PeriodTypes {
    NOW = 'NOW',
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    MONTHES = 'MONTHES',
    YEARS = 'YEARS',
}

export interface BonusResult {
    accountTypeName: string[]
    accountTypeId: string[]
    accountOwnerType: string[]
    intervalType: IntervalType[]
    sponsorId: string[]
    bonusType?: BonusResultAddType[]
    bonusValue?: string[]
    summa?: string[]
    exchengeRate?: string[]
    startsAfter?: string[]
    startsAfterPeriodType?: PeriodTypes[]
    worksPeriod?: string[]
    worksPeriodType?: PeriodTypes[]
    workPeriodRange?: Array<string | { start: string[], finish: string[] }>
    binPrefix?: string[]
    loyalCodes?: string[]
    accrualRules?: AccuralRules[]
    /**
     * Правила начисления фишек
     */
    tokensAccrualRules?: TokenAccuralRules[]
    rows?: ProductRows[]
}

export interface TokensDiscountApplyObject {
    productTokensApplyRules: ProductTokenApplyRulesPlugin[]
}

interface ProductTokenApplyRulesPlugin {
    [ResultType.ProductTokenApplyRule]: ProductTokenApplyRule[]
}

/**
 * Правила списания за фишки на товар
 */
export interface ProductTokenApplyRule {
    /**
     * Товар
     */
    item: string[]
    rules: TokenRulePlugin[]
}

interface TokenRulePlugin {
    [ResultType.TokenRule]: TokenRule[]
}

export interface TokenRule {
    /**
     * Количество фишек
     */
    tokenCount: string[]
    /**
     * Цена товара
     */
    price: string[]
}

export interface AccuralRules {
    [BonusAccrualRulePlugin]: AccuralRuleItem[]
}

export interface AccuralRuleItem {
    condition: Array<{
        min: string[]
        max: string[]
    }>
    percent: string[]
}

export interface TokenAccuralRules {
    [ResultType.TokenAccrualRule]: TokenAccuralRuleItem[]
}

export interface TokenAccuralRuleItem {
    /**
     * Тип значения, на которое смотрим в чеке - сумма товаром или их количество
     */
    valueType: Array<'SUM' | 'COUNT'>
    /**
     * Проверяемое правилом значение
     */
    value: string[]
    /**
     * Количество фишек к начислению за выполнение правила
     */
    accrualTokens: string[]
    /**
     * Максимальное количество фишек к начислению за выполнение правила
     */
    maxTokens: string[]
    // --- описание товаров, на которые распространяется правило
    /**
     * Флаг-признак, что данная скидка действует на весь товарный справочник.
     * Возможные значения: 'true', 'false'
     * <p/>
     * {@code null} распознается как {@code false}
     *
     * @see #rows
     * @see #exclude
     */
    forAllGoods: string[]
    /**
     * Флаг-признак, что данная скидка не действует на товары с запретом применения скидок.
     * Возможные значения: 'true', 'false'
     * <p/>
     * {@code null} распознается как {@code false}
     */
    excludeNotDiscountable: string[]
    /**
     * Набор товаров/групп продаж/товарных групп для расчета фишек к начислению
     */
    rows: ProductRows[]
    /**
     * Набор товаров/групп продаж/товарных групп ИСКЛЮЧЕННЫХ из расчета фишек к начислению
     */
    exclude: ProductRows[]
}

export interface ProductRows {
    [ProductsRowPlugin]: ProdutRow[]
}

// TODO чем отличается от ProductRow??
//  разобраться, как вариант - добавить в codegen/external-src этот джавовый класс
//  setretail10/SetRetail10_Commons/DataStructsModule/src/main/java/ru/crystalservice/setv6/discounts/plugins/ProductsRow.java
export type ProdutRow = ProductItem | GroupItem | SaleGroupItem | ListItem

export interface RowBase {
    key?: string
    index?: number
}

export interface ProductItem extends RowBase {
    item: string[]
    description: string[]
}

export interface GroupItem extends RowBase {
    groupId: string[]
    description: string[]
}

export interface SaleGroupItem extends RowBase {
    saleGroupId: string[]
    description: string[]
}

export interface ListItem extends RowBase {
    markings: Array<{
        String: string[]
    }>
    description: string[]
}

// Other

export interface BonusDiscountResultData {
    maxPercent: string[],
    minPurchaseSum?: string[],
    autoWriteOff: string[],
    saveWriteOffAmount: string[],
    bonusType: string[],
    rows: any[]
}

// TODO чем отличается от ProdutRow??
//  разобраться, как вариант - добавить в codegen/external-src этот джавовый класс
//  setretail10/SetRetail10_Commons/DataStructsModule/src/main/java/ru/crystalservice/setv6/discounts/plugins/ProductsRow.java
export interface ProductRow {
    item?: string[],
    groupId?: string[],
    saleGroupId?: string[],
    markings?: Array<{
        String: string[]
    }>,
    description?: string[]
}

export type ResultData =
    | ProductResultData
    | MessagesResultData
    | ScannerMessageResultData
    | AdvancedMessagesResultData
    | CounterResultData
    | FixPriceResultData
    | BonusResult
    | BonusDiscountResultData
    | any

export interface Result {
    type: ResultType
    data: ResultData
}

export const parseItem = (item: string) =>
    new Promise((resolve, reject) => {
        parseString(item, (err, res) => (err ? reject(err) : resolve(res)))
    })

export const parseList = (list: string[]) => Promise.all(list.map(parseItem))

export const parseResults = async (action: AdvertisingAction): Promise<Result[]> => {
    const [actionResults, applyObjects] = await Promise.all(
        [action.actionResults, action.applyObjects].map(parseList)
    )
    return [...actionResults, ...applyObjects].reduce<Result[]>((acc, result) => {
        const types = Object.keys(result) as ResultType[]
        const values = types.map(type => ({ type, data: result[type] }))
        return acc.concat(values)
    }, [])
}

const getCheckDiscountLabel = (data: CheckDiscountResultData): string => {
    switch (data.valueType?.[0]) {
        case CheckDiscountType.Percent:
            const value = Number(data.value?.[0]) || 0
            return `${t('advertisingActions.checkDiscountResults.percent')}: ${value / 100}%`
        case CheckDiscountType.PaymentPercent:
            return `${t('advertisingActions.checkDiscountResults.paymentPercent')}: ${Number(data.value?.[0]) / 100}%`
        case CheckDiscountType.DiscCard:
            return t('advertisingActions.checkDiscountResults.discCard')
        case CheckDiscountType.Coupon:
            return `${t('advertisingActions.checkDiscountResults.coupon')}: ${Number(data.value?.[0]) / 100}%`
        case CheckDiscountType.FixSum:
            return `${t('advertisingActions.checkDiscountResults.fixSum')}: ${Number(data.value?.[0]) / 100}`
        case CheckDiscountType.WholeSaleDiscount:
            const useOptCount = data.additionalValue?.[0] === 'true'
            return `${t('advertisingActions.checkDiscountResults.wholeSaleDiscount')}${
                useOptCount
                    ? `, ${t('advertisingActions.checkDiscountResults.useOptCount')}`
                    : ''
            }`
        case CheckDiscountType.SalesTaxDiscount:
            return t('advertisingActions.checkDiscountResults.salesTaxDiscount')
        default:
            return ''
    }
}

export const getProductListPriceLabel = (priceType: string): string => {
    const priceLabel = t('advertisingActions.productPricesList.price')

    switch (priceType) {
        case SECOND_PRICE:
            return `${t('advertisingActions.productPricesList.second')} ${priceLabel}`
        case THIRD_PRICE:
            return `${t('advertisingActions.productPricesList.third')} ${priceLabel}`
        case FOURTH_PRICE:
            return `${t('advertisingActions.productPricesList.fourth')} ${priceLabel}`
        case FIFTH_PRICE:
            return `${t('advertisingActions.productPricesList.fifth')} ${priceLabel}`
        default:
            return ''
    }
}

const getRoundingResultLabel = (result: Result): string => {
    const value = (Number(result?.data?.value?.[0]) / 100).toFixed(3)

    switch (result.data.valueType?.[0]) {
        case RoundingType.Round:
            return t('advertisingActions.resultChips.RoundingCheck', { value: Number(value) })
        case RoundingType.Positions:
            return t('advertisingActions.resultChips.RoundingProducts', { value: Number(value) })
        default:
            return ''
    }
}

const getDiscountResultLabel = (result: Result): string => {
    const valueType: string = result.data?.valueType?.[0]
    const chekDiscountTypes: string[] = Object.values(CheckDiscountType)
    const roundingTypes: string[] = Object.values(RoundingType)
    const productListTypes: string[] = Object.values(ProductListResultType)
    const externalLoyTypes: string[] = Object.values(ExternalLoyaltySystemType)

    if (chekDiscountTypes.includes(valueType)) {
        return getCheckDiscountLabel(result.data)
    }
    if (roundingTypes.includes(valueType)) {
        return getRoundingResultLabel(result)
    }
    if (productListTypes.includes(valueType)) {
        return getProductListPriceLabel(result.data?.priceType?.[0])
    }
    if (externalLoyTypes.includes(valueType)) {
        return t('advertisingActions.discountResults.externalLoyaltySystem')
    }

    return ''
}

export type ResultFormatter = (result: Result, action: AdvertisingActionParsed) => string

export interface ResultFormatters {
    [k: string]: ResultFormatter
}

export const resultFormatters: ResultFormatters = {
    [ResultType.Product]: (result, action) => {
        const data = result.data as ProductResultData
        return t('advertisingActions.resultChips.Product', { marking: data.marking[0] })
    },
    [ResultType.Bonus]: (result, action) => {
        if (result.data.bonusType[0] === BonusResultAddType.TOKENS) {
            return t('advertisingActions.resultChips.ETokensWithdraw')
        }
        return t('advertisingActions.resultChips.Bonus')
    },
    [ResultType.TokensDiscountApplyObject]: (result, action) => {
        return t('advertisingActions.resultChips.ETokensApply')
    },
    [ResultType.ChequeAdvert]: (result, action) => {
        return t('advertisingActions.resultChips.ChequeAdvert')
    },
    [ResultType.Coupon]: (result, action) => {
        return t('advertisingActions.resultChips.Coupon')
    },
    [ResultType.Messages]: (result, action) => {
        const data = result.data as MessagesResultData
        const clientMessage = data.clientMessage?.[0]
        const operatorMessage = data.operatorMessage?.[0]
        const clientSCOMessage = data.clientSCOMessage?.[0]
        let text = ''
        if (clientMessage || operatorMessage) {
            if (clientMessage && operatorMessage) {
                text += t('advertisingActions.resultChips.MessagesCashierBoth')
            } else if (clientMessage) {
                text += t('advertisingActions.resultChips.MessagesCashierClient')
            } else if (operatorMessage) {
                text += t('advertisingActions.resultChips.MessagesCashierOnly')
            }
            text += ' ' + t(`advertisingActions.resultChips.MessagesCashierTime.${data.displayTime}`)
        }
        if (clientSCOMessage) {
            const scoText = t('advertisingActions.resultChips.MessagesClientSCO')
            if (text) {
                text += `, ${scoText.charAt(0).toLowerCase() + scoText.substr(1)}`
            } else {
                text = scoText
            }
        }
        return text
    },
    [ResultType.ScannerMessages]: (result, action) => {
        const data: ScannerMessageResultData = result.data
        const { clientMessage, clientLargeMessage } = data

        return t('advertisingActions.resultChips.ScannerMessages', {
            scannerMessage: clientMessage[0] || clientLargeMessage[0].slice(0, 100),
        })
    },
    [ResultType.Counter]: (result, action) => {
        const data: CounterResultData = result.data

        return t('advertisingActions.resultChips.Counter', {
            counterResult: getCounterResultChipLabel(data),
        })
    },
    [ResultType.AdvancedMessages]: (result, action) => {
        return t('advertisingActions.resultChips.AdvancedMessages')
    },
    [ResultType.FixPrice]: (result, action) => {
        return t('advertisingActions.resultChips.FixPrice')
    },
    [ResultType.Discount]: (result, action) => {
        return getDiscountResultLabel(result)
    },
    [ResultType.ProductsSets]: (result, action) => {
        return t('advertisingActions.resultChips.ProductSet')
    },
    [ResultType.ProductsDiscounts]: (result, action) => {
        return t('advertisingActions.resultChips.ProductDiscount')
    },
    [ResultType.BonusDiscount]: (result, action) => {
        return `${t('advertisingActions.resultChips.BonusDiscount')} ${getBonusNameByType(result.data.bonusType[0])}`
    },
}

export type ResultValidator = (result: Result) => boolean

export interface ResultValidators {
    [k: string]: ResultValidator
}

export const resultValidators: ResultValidators = {
    [ResultType.AdvancedMessages]: (result: Result) => {
        const data = result?.data
        return validateAdvancedMessage(data)
    },
    [ResultType.Coupon]: (result: Result) => {
        const data = result?.data
        return validateCoupon(data)
    },
    [ResultType.Messages]: (result: Result) => {
        const data = result?.data
        return validateMessages(data)
    },
}

export const formatResult = (action: AdvertisingActionParsed) => (result: Result): string => {
    const format = resultFormatters[result.type]
    if (!format) return `Not implemented: ${last(result.type.split('.'))}`
    return format(result, action)
}

export const filterValidResults = (action: AdvertisingActionParsed): AdvertisingActionParsed => {
    const results = action.resultsParsed.filter(result => {
        const resultValidator = resultValidators[result.type]
        if (resultValidator) {
            return resultValidator(result)
        }
        return true
    })

    return {
        ...action,
        resultsParsed: results
    }
}

export enum ActionStatus {
    DRAFT = 'draft',            // Черновик
    DRAFT_PAST = 'draftPast',   // Завершившийся черновик
    CURRENT = 'current',        // Действующая акция
    FUTURE = 'future',          // Запланированная акция
    PAST = 'past',              // Завершиваяся акция
}

export const getActionStatus = (action: AdvertisingAction): ActionStatus => {
    const isActive = action.active
    const now = moment(fromClientToServerTime(new Date()))

    const actionStart = action.workPeriod.start
    const actionFinish = action.workPeriod.finish
    const isPast = actionFinish && now.isAfter(moment(actionFinish))
    const isFuture = actionStart && now.isBefore(moment(actionStart))

    if (!isActive) {
        if (isPast) {
            return ActionStatus.DRAFT_PAST
        }
        return ActionStatus.DRAFT
    }

    if (isPast) {
        return ActionStatus.PAST
    }
    if (isFuture) {
        return ActionStatus.FUTURE
    }
    return ActionStatus.CURRENT
}

export enum Tape {
    Tape80,
    Tape57,
    TapeManual,
}
