import React from 'react'
import { COUPON_STATUS, FilterType } from '../../../../core/filters/filter'
import { BaseSelectFilter } from '../../components/base-select-filter'
import { DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { t } from 'i18next'
import { InternalFilterComponentProps } from '../../new-filters'

export const ACTIVE_TYPE = 'ACTIVE'
export const USED_TYPE = 'USED'

const getCouponStatusOptions = (): DefaultSelectOption[] => [
    {
        label: t('loyaltyCoupons.instancesPage.active'),
        value: ACTIVE_TYPE
    },
    {
        label: t('loyaltyCoupons.instancesPage.notActive'),
        value: USED_TYPE
    },
]

export class CouponStatus extends React.PureComponent<InternalFilterComponentProps>  {
    static filterType: FilterType = COUPON_STATUS

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <BaseSelectFilter
                id={id}
                defaultValue={value}
                filterType={CouponStatus.filterType}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                options={getCouponStatusOptions()}
                slim={slim}
            />
        )
    }
}
