import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import { get, isEmpty, isNil } from 'lodash'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { MenuButton } from '@crystalservice/crystals-ui/lib/components/buttons/menu-button/menu-button'
import {
    removeCondition,
    updateCondition,
    findCondition,
    CountersAndLimitsConditions,
    ConditionFields,
    ComparisonType,
} from '../../../../../../core/advertising-actions/action-conditions'
import { ConditionEditorProps } from '../types'
import { CounterEditorDialog } from './counter-editor-dialog'
import { CounterAutocomplete } from '../../../../../../../components/inputs/autocomplete-input/counter-autocomplete'
import { AppStore } from '../../../../../../store/app-store'
import { APP_STORE } from '../../../../../../store/stores'
import { SNACK_BAR_ERROR } from '../../../../../../store/snackbar-store'
import { CounterVO } from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/counter-vo'

export interface ConditionCounter {
    counterId: string[]
    name: string[]
    comparison: ComparisonType[]
    value: number[]
    multiplier: number[]
    secondValue?: number[]
    possibleIncludeCurrentPurchase?: boolean[]
    includeCurrentPurchase?: boolean[]
}

export interface CounterCondition {
    units: Array<{
        [ConditionFields.CounterConditionUnit]: ConditionCounter[],
    }>,
}

// Получение текста для чипа
export const getCountersTriggersChipLabel = (condition: CounterCondition): string => {
    const counters = getCounters({ condition })
    let countersConditionLabel = ''

    if (!counters) return null

    counters.forEach(counter => {
        countersConditionLabel += ` ${getCounterLabel(counter)}`
    })

    return t('advertisingActions.common.countersTriggersChip', { countersConditionLabel })
}

const getExistingCondition = (value: any): CounterCondition => {
    const condition = findCondition(CountersAndLimitsConditions.CounterCondition, value)
    const existingCond: CounterCondition = get(condition, 'data', null)

    return existingCond
}

const getCounters = ({ value, condition }: { value?: any, condition?: CounterCondition }): ConditionCounter[] => {
    const existingCond = isEmpty(condition) ? getExistingCondition(value) : condition
    const counters: ConditionCounter[] = get(
        existingCond,
        `units[0]["${ConditionFields.CounterConditionUnit}"]`,
        [],
    )

    return counters
}

const getCounterLabel = (counter: ConditionCounter): string => {
    const name: string = get(counter, 'name[0]', '')
    const counterComparisonLabel = getComparisonLabel(counter)

    return `${name} - ${counterComparisonLabel}.`
}

const getComparisonLabel = (data: ConditionCounter): string => {
    const { comparison, value, secondValue, multiplier } = data

    const shownValue = value[0] / multiplier[0]
    switch (comparison[0]) {
        case ComparisonType.More:
            return `${t('advertisingActions.checkConditions.moreLabel')} ${shownValue}`
        case ComparisonType.Less:
            return `${t('advertisingActions.checkConditions.lessLabel')} ${shownValue}`
        case ComparisonType.Equal:
            return `${t('advertisingActions.checkConditions.equallyLabel')} ${shownValue}`
        case ComparisonType.Multiply:
            return `${t('advertisingActions.checkConditions.multipleLabel')} ${shownValue}`
        case ComparisonType.Between:
            return `${
                t('advertisingActions.compareBetween')
            } ${shownValue} ${t('advertisingActions.segments.and').toLowerCase()} ${value[0] / multiplier[0]}`
        default:
            return String(shownValue)
    }
}

const formatCounterIntoCondition = (counter: CounterVO): ConditionCounter => {
    const { id, name, multiplier, possibleIncludeCurrentPurchase } = counter

    let counterCondition: ConditionCounter = {
        counterId: [id],
        name: [name],
        value: [null],
        multiplier: [multiplier],
        comparison: [ComparisonType.More],
        possibleIncludeCurrentPurchase: [possibleIncludeCurrentPurchase]
    }

    return counterCondition
}

interface CounterEditorProps extends ConditionEditorProps {
    app?: AppStore
}

interface CounterEditorState {
    editableCounter: ConditionCounter
    editMode: boolean
}

@inject(APP_STORE)
@observer
export class CounterEditor extends Component<CounterEditorProps, CounterEditorState> {
    state: CounterEditorState = {
        editableCounter: null,
        editMode: false,
    }

    handleSelectCounter = (suggestion: CounterVO): void => {
        const { id } = suggestion
        const counters = getCounters({ value: this.props.value })
        const existingCounterIndex = counters.findIndex(counter => counter.counterId[0] === id)

        if (existingCounterIndex > -1) {
            const { showSnackbar } = this.props.app
            showSnackbar({
                message: t('advertisingActions.counterCondition.counterAlreadyAdded'),
                variant: SNACK_BAR_ERROR,
            })
            return
        }

        this.handleEditCounter({ id, newCounter: suggestion })
    }

    handleEditCounter = ({ id, newCounter, editMode = false }: { id: string, newCounter?: CounterVO, editMode?: boolean }): void => {
        const counters = getCounters({ value: this.props.value })
        let editableCounter: ConditionCounter = isEmpty(newCounter) ?
            counters.find(counter => counter.counterId[0] === id) :
            formatCounterIntoCondition(newCounter)

        this.setState({ editableCounter, editMode })
    }

    handleSaveCounter = (counter: ConditionCounter): void => {
        if (!counter.value || !counter.comparison) return
        const { value } = this.props
        const counters = getCounters({ value })
        const { editMode } = this.state
        const existingCounterIndex = counters.findIndex(item => item.counterId[0] === counter.counterId[0])

        if (existingCounterIndex < 0) {
            this.setState({ editableCounter: null })

            this.updateCounterCondition(counter)
        } else if (editMode) {
            this.handleSaveEditedCounter(counter)
        }
    }

    handleSaveEditedCounter = (counter: ConditionCounter): void => {
        this.setState({ editableCounter: null })
        const existingCounters = getCounters({ value: this.props.value })
        const updatedCounterIndex = existingCounters.findIndex(item => item.counterId[0] === counter.counterId[0])

        if (updatedCounterIndex > -1) {
            this.updateCounters([
                ...existingCounters.slice(0, updatedCounterIndex),
                counter,
                ...existingCounters.slice(updatedCounterIndex + 1)
            ])
        }
    }

    updateCounterCondition = (counter: ConditionCounter): void => {
        const existingCounters = getCounters({ value: this.props.value })
        const newCounters = [...existingCounters, counter]

        this.updateCounters(newCounters)
    }

    updateCounters = (counters: ConditionCounter[]): void => {
        const { onChange, value } = this.props

        const newCondition: CounterCondition = {
            units: [{
                [ConditionFields.CounterConditionUnit]: counters,
            }],
        }

        onChange(updateCondition(
            CountersAndLimitsConditions.CounterCondition,
            newCondition,
            value,
        ))
    }

    handleRemoveItem = (id: string): void => {
        const { value, onChange } = this.props
        const counters = getCounters({ value })

        const newCounters: ConditionCounter[] = counters.filter(counter => counter.counterId[0] !== id)
        if (newCounters.length === 0) {
            onChange(removeCondition(CountersAndLimitsConditions.CounterCondition, value))
        } else {
            this.updateCounters(newCounters)
        }
    }

    render() {
        const {
            editableCounter,
            editMode,
        } = this.state
        const counters = getCounters({ value: this.props.value })
        const { readOnly, action } = this.props

        return (
            <div>
                <div style={{ padding: '24px 24px 0 24px' }}>
                    <CounterAutocomplete
                        disabled={readOnly}
                        onSuggestionSelect={this.handleSelectCounter}
                        markedItemsIds={counters.map(item => item.counterId[0])}
                    />
                </div>

                <Table
                    withHeader
                    title={t('advertisingActions.counterCondition.addedCountersTriggers')}
                    keyFunction={(item: ConditionCounter) => `${item.counterId[0]}-${item.value[0]}`}
                    columns={[
                        {
                            header: t('advertisingActions.counterCondition.addedCountersTitle'),
                            labelFunction: data => data.name[0]
                        },
                        {
                            header: t('advertisingActions.counterCondition.addedCountersCount'),
                            labelFunction: getComparisonLabel
                        },
                        {
                            renderer: ({ item }: { item: ConditionCounter }) => {
                                return (
                                    <MenuButton
                                        adaptive
                                        disabled={readOnly}
                                        buttons={[
                                            {
                                                id: `editButton_${item.counterId[0]}`,
                                                label: t('common.edit'),
                                                onClick: () => this.handleEditCounter({ id: item.counterId[0], editMode: true }),
                                                disabled: readOnly
                                            },
                                            {
                                                id: `removeButton_${item.counterId[0]}`,
                                                label: t('common.remove'),
                                                onClick: () => this.handleRemoveItem(item.counterId[0]),
                                                disabled: readOnly
                                            }
                                        ]}
                                    />
                                )
                            },
                            showOnRowHover: true,
                            withControls: true
                        }
                    ]}
                    items={counters}
                />
                {!counters.length && (
                    <Empty />
                )}

                {!isEmpty(editableCounter) && (
                    <CounterEditorDialog
                        open
                        counter={editableCounter}
                        gotMasterActions={action.masterActions?.length > 0}
                        onClose={() => this.setState({ editableCounter: null })}
                        onSave={this.handleSaveCounter}
                        editMode={editMode}
                    />
                )}
            </div>
        )
    }
}
