import { XMLTagBaseElement } from './xml-tag-base-element'
import { XMLQrcodeElementType, ALL_QRCODE_ELEMENT_TYPES } from './xml-qrcode-element-type'

export interface XMLTagQrcodeElement extends XMLTagBaseElement {
    preferredQrcodeType?: XMLQrcodeElementType
}

export function isQrcodeElement(item: any): item is XMLTagQrcodeElement {
    if (!item) return false
    if ('preferredQrcodeType' in item && ALL_QRCODE_ELEMENT_TYPES.includes(item.preferredQrcodeType)) return true
    return false
}
