export interface ProductFilterList {
    '@class': 'ru.crystals.setretailx.products.common.ProductFilterList'
    item?: string[]
    manufacturerName?: string[]
    manufacturerCodes?: string[]
    groupCodes?: string[]
    groupName?: string[]
    departNumbers?: number[]
    departName?: string[]
    countryCodes?: string[]
    countryName?: string[]
    typeProductName?: string[]
    typeProductClassName?: string[]
    saleGroupCodes?: string[]
    saleGroupName?: string[]
    barCode?: string[]
    goodName?: string[]
}

export const createProductFilterList = (initialization: Partial<ProductFilterList>): ProductFilterList => {
    if (!initialization) return null
    
    let result: ProductFilterList = {
        item: initialization.item,
        manufacturerName: initialization.manufacturerName,
        manufacturerCodes: initialization.manufacturerCodes,
        groupCodes: initialization.groupCodes,
        groupName: initialization.groupName,
        departNumbers: initialization.departNumbers,
        departName: initialization.departName,
        countryCodes: initialization.countryCodes,
        countryName: initialization.countryName,
        typeProductName: initialization.typeProductName,
        typeProductClassName: initialization.typeProductClassName,
        saleGroupCodes: initialization.saleGroupCodes,
        saleGroupName: initialization.saleGroupName,
        barCode: initialization.barCode,
        goodName: initialization.goodName,
        '@class': 'ru.crystals.setretailx.products.common.ProductFilterList',
    }
    
    return result
}
