import * as React from 'react'
import moment, { Moment } from 'moment'
import classnames from 'classnames'
import { DateTypes, DAY, HOUR, MONTH, WEEK, YEAR } from '../../utils/date/date-format'

const styles = require('./calendar-row.scss')

export interface CalendarDatePeriod {
    startDate?: Date
    endDate?: Date
    interval?: DateTypes
}

export interface CalendarRowProps<T = any> {
    now: Date
    id?: string
    calendarClassName?: string
    stepsClassName?: string
    itemRenderer?: (steps: any) => React.ReactElement
    timePeriod?: CalendarDatePeriod
    rowCount?: number
}

export class CalendarRow extends React.Component<CalendarRowProps> {
    static defaultProps: Partial<CalendarRowProps> = {
        id: '',
        itemRenderer: null,
        rowCount: 6
    }

    getStepIncrement = (interval: DateTypes): {count: number, interval: DateTypes} => {
        switch (interval) {
            case DAY:
                return {count: 2, interval: HOUR}
            case WEEK:
                return {count: 1, interval: DAY}
            case MONTH:
                return {count: 2, interval: DAY}
            case YEAR:
                return {count: 1, interval: MONTH}
            default:
                return {count: 2, interval: HOUR}
        }
    }

    getPeriodArray = (): Moment[] => {
        const {
            timePeriod: {
                startDate,
                endDate,
                interval,
            },
        } = this.props

        let step = moment(startDate)
        const steps = []
        const {
            count,
            interval: showedInterval,
        } = this.getStepIncrement(interval)

        const momentEndDate = moment(endDate)

        while (step <= momentEndDate) {
            steps.push(step)
            step = step.clone().add(count, showedInterval)
        }

        return steps
    }

    calendarCell = (date: number, row: number) => {
        const {
            timePeriod: {
                interval,
            },
            rowCount,
        } = this.props
        const {
            count,
            interval: showedInterval,
        } = this.getStepIncrement(interval)

        return (
            <div
                data-calendar-date={date}
                data-calendar-row={row}
                key={`${date}-${row}`}
                className={classnames(styles.emptyCell, {
                    [styles.lastEmptyCell]: row === rowCount - 1
                })}
                data-calendar-interval={showedInterval}
                data-calendar-interval-count={count}
            />
        )
    }

    render() {
        const { itemRenderer, calendarClassName, stepsClassName, id, timePeriod, now, rowCount} = this.props
        const { interval } = timePeriod
        const steps = this.getPeriodArray()

        if (!steps) return null

        const stepsDiff = new Date(steps[1].toDate()).getTime() - new Date(steps[0].toDate()).getTime()

        return(
            <div className={classnames(calendarClassName, styles.calendarRow)} id={id}>
                <div className={classnames(stepsClassName, styles.calendarSteps)} id="calendarRowSteps">
                    {steps.map((step, stepIndex) => {
                        const stepDate = new Date(step.toDate()).getTime()

                        const fakeArr = new Array(rowCount)

                        const emptyCells = []
                        for (const [index, value] of fakeArr.entries()) {
                            emptyCells.push(this.calendarCell(stepDate, index))
                        }

                        const leftOffset = (now.getTime() - stepDate) / stepsDiff

                        return (
                            <div
                                className={styles.stepCard}
                                key={`day_${step.format()}`}
                                data-step={stepDate}
                                data-interval={interval}
                            >
                                <div className={classnames(styles.dateHeader, {
                                    [styles.dateHeaderNow]: now.getTime() >= stepDate && now.getTime() <= stepDate + stepsDiff
                                })}>
                                    { interval === DAY && (
                                        <>
                                            <div>{step.format('DD.MM.YY')}</div>
                                            <div>{step.format('HH:mm')}</div>
                                        </>
                                    )}
                                    { (interval === WEEK || interval === MONTH) && (
                                        <>
                                            <div>{step.format('DD.MM.YY')}</div>
                                            <div>{step.format('dd')}</div>
                                        </>
                                    )}
                                    { interval === YEAR && (
                                        <>
                                            <div>{step.format('YYYY')}</div>
                                            {/* чтобы выровнять ширину ячеек, потому что отличаются длины полных названий месяцев */}
                                            <div className={styles.fixWidthElement}>
                                                {moment('01-09-2019', 'DD-MM-YYYY').format('MMMM')}
                                            </div>
                                            <div>{step.format('MMMM')}</div>
                                        </>
                                    )}
                                </div>
                                <div key={`calendar-column-${stepIndex}`} className={styles.emptyColumn}>
                                    {emptyCells}
                                    { now.getTime() >= stepDate && now.getTime() <= stepDate + stepsDiff  &&
                                        <div
                                            className={styles.nowLine}
                                            style={{left: `${leftOffset * 100}%`}}
                                        />
                                    }
                                </div>
                            </div>
                        )
                    })}
                    { itemRenderer(steps) }
                </div>
            </div>
        )
    }
}
