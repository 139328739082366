import { t } from 'i18next'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { iEquipmentManagerLocal } from '../../../../protocol/set10/i-equipment-manager-local'
import { scalesManagerLocal1 } from '../../../../protocol/set10/scales-manager-local1'
import { scalesManagerLocal2 } from '../../../../protocol/set10/scales-manager-local2'
import { scalesHardwareConfigurationService } from '../../../../protocol/set10/scales-hardware-configuration-service'
import { EquipmentModelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { NEW as SCALE_NEW } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-state'
import { ScalesTemplateVO } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-template-vo'
import { createScalesVO, ScalesVO } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-vo'
import {
    ScalesHardwareConfigurationFeedback
} from '../../../../protocol/set10/set-retail10-server/retailx/set-scales-hardware-configuration-api/scales-hardware-configuration-feedback'
import { FormValidation, ValidationResult } from '../../../../utils/form-validation/form-validation'
import { requiredField } from '../../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../../utils/form-validation/validators/unique-field'
import { AppStore } from '../../app-store'
import { UserStore } from '../../user-store'
import { getStore } from '../../stores-repository'
import {
    APP_STORE,
    USER_STORE
} from '../../stores'
import { withSpinner } from '../../with-spinner'
import { DeviceSettingsType } from '../../../core/values'
import uuid from 'uuid'
import { sortBy } from 'lodash'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { fieldLengthValidator } from '../../../../utils/form-validation/validators/length-validator'

export const ScaleModelsWithLoadableHeaders = [
    'BIZERBA_SX',
    'DIGI_TCP_SM100',
    'DIGI_TCP_SM5300',
    'DIGI_CSV',
]

export class ShopScalesStore {

    // Шаг весов
    @observable
    scales: ScalesVO[] = []
    @observable
    scalesTemplates: ScalesTemplateVO[] = []
    @observable
    scalesModels: EquipmentModelVO[] = []
    @observable
    scalesSettings: ScalesVO
    @observable
    scalesValidation: FormValidation<ScalesVO>
    @observable
    scalesSettingsType: DeviceSettingsType
    @observable
    scalesSettingsCount: number
    @observable
    scalesDevicesDialogOpen: boolean = false
    @observable
    paginatorKey: string = ''
    @observable
    paginationState: PaginationState = undefined

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    @computed
    get sortedScales(): ScalesVO[]  {
        return sortBy(this.scales, 'number')
    }

    // Работа с весами
    fetchAllScalesData = (): Promise<void> => {
        return withSpinner([
            this.fetchScales,
            this.fetchScalesTemplates,
            this.fetchScalesModels,
        ])
    }

    fetchScales = (): Promise<void> => {
        return scalesManagerLocal2.getScales()
            .then(scales => {
                runInAction(() => {
                    this.scales = scales
                    this.paginatorKey = uuid()
                })
            })
    }

    fetchScalesTemplates = (): Promise<void> => {
        return scalesManagerLocal1.getScalesTemplates(this.userStore.session)
            .then(scalesTemplates => {
                runInAction(() => {
                    this.scalesTemplates = scalesTemplates
                })
            })
    }

    fetchScalesModels = (): Promise<void> => {
        return iEquipmentManagerLocal.getAllRegisteredScales(this.userStore.session, this.appStore.locale)
            .then(scalesModels => {
                runInAction(() => {
                    this.scalesModels = scalesModels
                })
            })
    }

    @computed
    get scaleModelLabelsByName() {
        let result: {[name: string]: string} = {}
        this.scalesModels.forEach((model: EquipmentModelVO) => {
            result[model.name] = model.localizedName
        })
        return result
    }

    @action
    showScalesDevicesDialog = (): void => {
        this.scalesDevicesDialogOpen = true
    }

    @action
    closeScalesDevicesDialog = (): Promise<void> => {
        this.scalesDevicesDialogOpen = false

        // При закрытии диалога надо обновить доступные модели -
        return this.fetchScalesModels()
    }

    @action
    editScales = (scales: ScalesVO): void => {
        if (scales) {
            this.scalesSettings = toJS(scales)
            this.scalesSettingsType = DeviceSettingsType.EDIT
            this.createScalesValidation()
        }
    }

    @action
    addScales = (): void => {
        this.scalesSettings = this.getBlankScaleSettings()
        this.scalesSettingsType = DeviceSettingsType.NEW
        this.createScalesValidation(true)
        this.scalesSettingsCount = 1
    }

    getBlankScaleSettings = (): ScalesVO => {
        const firstTemplate = this.scalesTemplates.length > 0 ? toJS(this.scalesTemplates[0]) : null
        const firstModel = this.scalesModels.length > 0 ? this.scalesModels[0].name : null

        return createScalesVO({
            id: -1,
            code: '',
            guid: 0,
            deleted: false,
            scalesTemplate: firstTemplate,
            addressing: '',
            model: firstModel,
            productGuids: [],
            state: SCALE_NEW,
            noLoadAddText: false,
            clearScales: false,
            printTimeOnLabel: false,
        })
    }

    @action
    createScalesValidation = (creation: boolean = false): void => {
        let allScales = this.scales

        this.scalesValidation = new FormValidation<ScalesVO>(
            this.scalesSettings,
            [
                {
                    field: 'number',
                    rules: [
                        (value: number): ValidationResult => {
                            if (!value) {
                                return {
                                    valid: false,
                                    error: t('validation.requiredField'),
                                }
                            } else {
                                return {
                                    valid: true
                                }
                            }
                        },
                        uniqueField(allScales
                            .filter(s => s.id !== this.scalesSettings.id)
                            .map(s => s.number))
                    ]
                },
                {
                    field: 'model',
                    rules: [
                        requiredField
                    ]
                },
                {
                    field: 'addressing',
                    rules: [
                        fieldLengthValidator({ max: 255 })
                    ]
                },
                {
                    field: 'scalesTemplate',
                    rules: [
                        requiredField
                    ]
                },
            ],
            creation
        )
    }

    updateScalesState = (scales: ScalesVO, freeze?: boolean) => {
        if (freeze) {
            // Законсервировать
            this.preserveScales(toJS(scales))
        } else {
            // Активировать
            let scaleSettings = toJS(scales)
            scaleSettings.state = SCALE_NEW
            this.saveScales(scaleSettings)
        }
    }

    @action
    updateScales = (changes: Partial<ScalesVO>) => {
        Object.keys(changes).forEach(k => {
            this.scalesSettings[k] = changes[k]
        })
    }

    @action
    updateScalesCount = (count: number) => {
        this.scalesSettingsCount = count
    }

    @action
    closeScalesSettings = () => {
        this.scalesSettings = null
        this.scalesSettingsType = null
        this.scalesSettingsCount = null
        this.scalesValidation = null
    }

    saveScales = (scales: ScalesVO): Promise<void> => {
        return withSpinner(scalesManagerLocal1.addScales(this.userStore.session, scales)
            .then(savedScales => {
                if (savedScales) {
                    runInAction(() => {
                        let id = savedScales.id
                        let index = this.scales.findIndex(item => item.id === id)
                        if (index !== -1) {
                            this.scales[index] = savedScales
                        }
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    saveScalesSet = (newScales: ScalesVO, count: number): Promise<void> => {
        return withSpinner(scalesManagerLocal1.addScalesSet(this.userStore.session, newScales, count)
            .then(addedScales => {
                if (addedScales && addedScales.length > 0) {
                    runInAction(() => {
                        this.scales = this.scales.concat(addedScales)
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    deleteScales = (scales: ScalesVO): Promise<void> => {
        return withSpinner(scalesManagerLocal1.deleteScales(this.userStore.session, scales.id)
            .then(result => {
                if (result) {
                    runInAction(() => {
                        const deletedId = scales.id
                        const deleteIndex = this.scales.findIndex(item => item.id === deletedId)
                        if (deleteIndex !== -1) {
                            this.scales.splice(deleteIndex, 1)
                        }
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    loadProductsToScales = (scales: ScalesVO): Promise<void> => {
        return withSpinner(scalesManagerLocal2.loadProductsToScales(this.userStore.session, scales.number))
    }

    loadHeadersToScale = (scales: ScalesVO): Promise<ScalesHardwareConfigurationFeedback> => {
        return withSpinner(scalesHardwareConfigurationService.setHeader1(scales.number,
            {
                customCommonResponseMiddlewares: [
                    response => {
                        let error = response.data.error || response.data.result.error || response.data.result.errorMessage
                        if (error) {
                            this.appStore.showDialog({
                                title: t('shopPage.loadHeadersToScale.title'),
                                message: `${t('shopPage.loadHeadersToScale.error')}.\n`
                                    + `${t('shopPage.loadHeadersToScale.scalesNotAvailable')}.\n`
                                    + `${t('shopPage.loadHeadersToScale.checkScalesSettingsAndConnection')}.`,
                                okLabel: t('common.close')
                            })
                        } else {
                            this.appStore.showDialog({
                                title: t('shopPage.loadHeadersToScale.title'),
                                message: t('shopPage.loadHeadersToScale.success'),
                                okLabel: t('common.close')
                            })
                        }
                    },
                ]
            }
        ))
    }

    preserveScales = (scales: ScalesVO): Promise<void> => {
        return withSpinner(scalesManagerLocal1.preserveScales(this.userStore.session, scales.id)
            .then(preservedScales => {
                if (preservedScales) {
                    runInAction(() => {
                        const scalesId = preservedScales.id
                        const index = this.scales.findIndex(item => item.id === scalesId)
                        if (index !== -1) {
                            this.scales[index] = preservedScales
                        }
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    @action
    setPaginationState = (paginationState: PaginationState) => {
        this.paginationState = paginationState
    }

    @action
    reset = (): void => {
        this.scales = []
        this.scalesTemplates = []
        this.scalesModels = []
        this.scalesSettings = undefined
        this.scalesValidation = undefined
        this.scalesSettingsType = undefined
        this.scalesSettingsCount = undefined
        this.scalesDevicesDialogOpen = false
        this.paginatorKey = ''
        this.paginationState = undefined
    }
}
