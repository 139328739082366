import { Cancelable, debounce, omit } from 'lodash'
import { action,  observable, runInAction, toJS } from 'mobx'
import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { erpiValidationManagerLocal } from '../../../protocol/set10/erpi-validation-manager-local'
import { createErpiValidatorVO, ErpiValidatorVO } from '../../../protocol/set10/set-retail10-server/retailx/set-erp-integration/erpi-validator-vo'
import { PURCHASES } from '../../../protocol/set10/set-retail10-server/retailx/set-erp-integration/validator-type'
import { ACTIVE, INACTIVE, ValidatorState } from '../../../protocol/set10/set-retail10-server/retailx/set-erp-integration/validator-state'
import { withSpinner } from '../with-spinner'
import { createValidationRule, ValidationRule } from '../../../protocol/set10/set-retail10-server/retailx/set-erp-integration/validation-rule'
import { PARAMETER, PRESET, REGEXP } from '../../../protocol/set10/set-retail10-server/retailx/set-erp-integration/validation-rule-type'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, APP_STORE } from '../stores'
import { t } from 'i18next'
import { AppBarStore, MENU } from '../app-bar-store'

export enum PurchasesRules {
    TAB_NUMBER = 'tabNumber',
    AUTH_CODE = 'auth.code',
    TERMINAL_NUMBER = 'terminal.number',
    CARD_NUMBER = 'card.number',
    TRANSACTIONS = 'transactions',
    AMOUNT = 'amount',
    DISCOUNT_AMOUNT = 'discountAmount',
    PURCHASE_REGISTRATION_DATE = 'purchaseRegistrationDate',
    KPP_NUMBER_VALIDATION = 'kppNumber',
    CARD_TYPE_VALIDATION = 'card.type',
}

// value if validation rule checked, but it's options is not
export const NULL_RULE = 'NULL_RULL'
// AMOUNT first checkbox
export const AMOUNT_EQUALS_POSITIONS_SUM = 'AMOUNT_EQUALS_POSITIONS_SUM'
// AMOUNT second checkbox
export const AMOUNT_EQUALS_PAYMENTS_SUM = 'AMOUNT_EQUALS_PAYMENTS_SUM'
// TRANSACTION checkbox
export const AMOUNT_OF_BANK_TRANSACTIONS = 'AMOUNT_OF_BANK_TRANSACTIONS'
// DISCOUNT_AMOUNT checkbox
export const DISCOUNT_AMOUNT_EQUALS_DISCOUNTS_SUM = 'DISCOUNT_AMOUNT_EQUALS_DISCOUNTS_SUM'

export class OperdayValidationStore {

    debounceSaveValidation: (() => void) & Cancelable = debounce(() => {
        this.saveValidators()
    }, AUTOSAVE_DELAY)

    @observable
    isValidationEnabled: boolean = false

    @observable
    validators: ErpiValidatorVO[] = []

    /**
     * данные для валидации приходят в очень неудобном для работы формате
     * поэтому при получении массива валидации с сервера - строю удобную структуру данных для редактирования
     * при отправке измененных правил валидации - привожу данные к исходной структуре
     * 
     * в дальнейшем могут появиться новые типы валидации (см. 'validator-type.ts') - предлагаю так же создавать
     * под них поля (типа purchasesRules) и приводить в удобный вид, а в методе 'saveValidators' - возвращать структуру в изначальный вид
     */

    @observable
    purchasesRules: any = {}

    private RULE_REGEX: RegExp = /\^\[?(.+?)\]?(?:\{(\d+)\})?(\+|\*)?\$/
    private RULE_REGEX_EXTENDED: RegExp = /\^(\.\{0\}\|)?\[?(.+?)\]?(?:\{(\d+)\})?(\+|\*)?\$/
    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    fetchValidation = async (): Promise<void> => {
        const [validationState, validators] = await withSpinner(Promise.all([
            erpiValidationManagerLocal.getValidationState(),
            erpiValidationManagerLocal.getValidators()
        ]))

        const rules = validators[0]?.rules
        const parsedRules = {}

        if (rules) {
            for (let rule of rules) {

                if (
                    rule.fieldName === PurchasesRules.TAB_NUMBER ||
                    rule.fieldName === PurchasesRules.TERMINAL_NUMBER
                ) {
                    const symbolsCountValue = this.getSymbolCount(rule)
                    const restrictValue = this.getRestrict(rule)

                    parsedRules[rule.fieldName] = {
                        fieldName: rule.fieldName,
                        ruleType: rule.ruleType,
                        symbolsCountValue,
                        restrictValue,
                    }
                }

                if (rule.fieldName === PurchasesRules.AUTH_CODE) {
                    // здесь используются дополнительные методы, которые работают с расширенной регуляркой
                    const symbolsCountValue = this.getSymbolsCountExtended(rule)
                    const restrictValue = this.getRestrictExtended(rule)
                    const canBeEmptyValue = this.canBeEmptyExtended(rule)

                    parsedRules[rule.fieldName] = {
                        fieldName: rule.fieldName,
                        ruleType: rule.ruleType,
                        symbolsCountValue,
                        restrictValue,
                        canBeEmptyValue,
                    }
                }

                if (
                    rule.fieldName === PurchasesRules.CARD_NUMBER ||
                    rule.fieldName === PurchasesRules.KPP_NUMBER_VALIDATION ||
                    rule.fieldName === PurchasesRules.CARD_TYPE_VALIDATION
                ) {
                    const canBeEmptyValue = this.canBeEmpty(rule)
                    const restrictValue = this.getRestrict(rule)

                    parsedRules[rule.fieldName] = {
                        fieldName: rule.fieldName,
                        ruleType: rule.ruleType,
                        canBeEmptyValue,
                        restrictValue,
                    }
                }

                if (
                    rule.fieldName === PurchasesRules.TRANSACTIONS ||
                    rule.fieldName === PurchasesRules.DISCOUNT_AMOUNT ||
                    rule.fieldName === PurchasesRules.PURCHASE_REGISTRATION_DATE
                ) {
                    parsedRules[rule.fieldName] = {
                        fieldName: rule.fieldName,
                        ruleType: rule.ruleType,
                        value: rule.value,
                    }
                }

                if (rule.fieldName === PurchasesRules.AMOUNT && !parsedRules[rule.fieldName]) {
                    const [positions, payments] = rules.filter(rule => rule.fieldName === PurchasesRules.AMOUNT)

                    parsedRules[rule.fieldName] = {
                        fieldName: rule.fieldName,
                        ruleType: rule.ruleType,
                        positionsValue: positions.value === AMOUNT_EQUALS_POSITIONS_SUM,
                        paymentsValue: payments.value === AMOUNT_EQUALS_PAYMENTS_SUM,
                    }
                }
            }
        }

        runInAction(() => {
            this.isValidationEnabled = validationState
            this.validators = validators
            this.purchasesRules = parsedRules
        })

        this.appBarStore.updateState({
            title: `${t('set10.operday')} - ${t('set10.validation')}`,
            leftIcon: MENU,
            
        })
    }

    get purchasesValidatorState(): boolean {
        const validator = this.validators.find(validator => validator.type === PURCHASES)

        if (!validator) {
            return false
        }

        return validator.state === ACTIVE
    }

    @action
    toggleValidationState = async (): Promise<void> => {
        try {
            await erpiValidationManagerLocal.setValidationState(!this.isValidationEnabled)
            this.isValidationEnabled = !this.isValidationEnabled
            this.appStore.showSnackbar({ variant: 'success', message: t('operdayValidation.notificationValidationSuccess') })
        } catch (error) {
            this.appStore.showSnackbar({ variant: 'error', message: t('operdayValidation.notificationValidationError') })
        }
    }

    @action
    togglePurchasesValidator = async (): Promise<void> => {
        if (!this.purchasesValidatorState) {
            this.validators = [
                createErpiValidatorVO({
                    type: PURCHASES,
                    state: ACTIVE,
                })
            ]
        } else {
            this.validators = this.validators.map(validator => {
                return {
                    ...validator,
                    state: this.toggleValidatorState(validator.state),
                    rules: [],
                }
            })
            this.purchasesRules = {}
        }

        this.debounceSaveValidation()
    }

    toggleValidatorState = (state: ValidatorState): ValidatorState => {
        return state === ACTIVE ? INACTIVE : ACTIVE
    }

    getSymbolCount = (rule: ValidationRule): number => {
        const result = this.RULE_REGEX.exec(rule.value)

        if (result && result.length >= 3) {
            if (!isNaN(Number(result[2]))) {
                return Number(result[2])
            }
        }

        return 0
    }

    getRestrict = (rule: ValidationRule): string => {
        const result = this.RULE_REGEX.exec(rule.value)

        if (result && result.length >= 3) {
            if (result[1]) {
                const symbols = result[1]

                if (symbols === '.+' || symbols === '.*' || symbols === '.') {
                    return ''
                }

                return symbols
            }
        }

        return ''
    }

    canBeEmpty = (rule: ValidationRule): boolean => {
        const result = this.RULE_REGEX.exec(rule.value)

        if (result && result.length >= 3) {
            return result[3] === '*'
        }

        return true
    }

    getSymbolsCountExtended = (rule: ValidationRule): number => {
        const result = this.RULE_REGEX_EXTENDED.exec(rule.value)

        if (result && result.length >= 4) {
            if (!isNaN(Number(result[3]))) {
                return Number(result[3])
            }
        }

        return 0
    }

    getRestrictExtended = (rule: ValidationRule): string => {
        const result = this.RULE_REGEX_EXTENDED.exec(rule.value)

        if (result && result.length >= 4) {
            const symbols = result[2]

            if (symbols === '.+' || symbols === '.*' || symbols === '.') {
                return ''
            }

            return symbols
        }

        return ''
    }

    canBeEmptyExtended = (rule: ValidationRule): boolean => {
        const result = this.RULE_REGEX_EXTENDED.exec(rule.value)

        if (result && result.length >= 4) {
            return result[1] === '.{0}|'
        }

        return true
    }

    @action
    togglePurchasesValidationRule = (fieldName: string): void => {
        // create new rule in this.validation[n].rules array
        // or delete existing rule in this.validation[n].rules array
        if (this.purchasesRules[fieldName]) {
            this.purchasesRules = omit(this.purchasesRules, fieldName)
        } else {
            if (
                fieldName === PurchasesRules.TAB_NUMBER ||
                fieldName === PurchasesRules.TERMINAL_NUMBER
            ) {
                this.purchasesRules = {
                    ...this.purchasesRules,
                    [fieldName]: {
                        fieldName,
                        ruleType: REGEXP,
                        symbolsCountValue: 0,
                        restrictValue: '',
                    }
                }
            }

            if (fieldName === PurchasesRules.AUTH_CODE) {
                this.purchasesRules = {
                    ...this.purchasesRules,
                    [fieldName]: {
                        fieldName,
                        ruleType: REGEXP,
                        symbolsCountValue: 0,
                        restrictValue: '',
                        canBeEmptyValue:  true,
                    }
                }
            }

            if (
                fieldName === PurchasesRules.CARD_NUMBER ||
                fieldName === PurchasesRules.KPP_NUMBER_VALIDATION ||
                fieldName === PurchasesRules.CARD_TYPE_VALIDATION
            ) {
                this.purchasesRules = {
                    ...this.purchasesRules,
                    [fieldName]: {
                        fieldName,
                        ruleType: REGEXP,
                        canBeEmptyValue: true,
                        restrictValue: '',
                    }
                }
            }

            if (
                fieldName === PurchasesRules.TRANSACTIONS ||
                fieldName === PurchasesRules.DISCOUNT_AMOUNT ||
                fieldName === PurchasesRules.PURCHASE_REGISTRATION_DATE
            ) {
                this.purchasesRules = {
                    ...this.purchasesRules,
                    [fieldName]: {
                        fieldName,
                        ruleType: fieldName === PurchasesRules.PURCHASE_REGISTRATION_DATE ? PARAMETER : PRESET,
                        value: NULL_RULE,
                    }
                }
            }

            if (fieldName === PurchasesRules.AMOUNT) {
                this.purchasesRules = {
                    ...this.purchasesRules,
                    [fieldName]: {
                        fieldName,
                        ruleType: PRESET,
                        positionsValue: false,
                        paymentsValue: false,
                    }
                }
            }
        }

        this.debounceSaveValidation()
    }

    @action
    changePurchasesRules = ({ key, value, fieldName }: { key: string, value: any, fieldName: string }): void => {
        // change value for existing rule
        this.purchasesRules = {
            ...this.purchasesRules,
            [fieldName]: {
                ...this.purchasesRules[fieldName],
                [key]: value,
            }
        }

        this.debounceSaveValidation()
    }

    @action
    saveValidators = async (): Promise<void> => {
        const coercedPurchasesRules = this.coercePurchasesRules()

        this.validators = this.validators.map(validator => {
            if (validator.type === PURCHASES) {
                return {
                    ...validator,
                    rules: coercedPurchasesRules,
                }
            }

            return validator
        })

        try {
            await erpiValidationManagerLocal.setValidators(toJS(this.validators))
            this.appStore.showSnackbar({ variant: 'success', message: t('operdayValidation.notificationValidationSuccess') })
        } catch (error) {
            this.appStore.showSnackbar({ variant: 'error', message: t('operdayValidation.notificationValidationError') })
        }
    }

    coercePurchasesRules = () => {
        const entries = Object.entries(this.purchasesRules)
        let coerced = entries.reduce((acc: any, [key, rule]: [string, any]) => {

            if (
                key === PurchasesRules.TAB_NUMBER ||
                key === PurchasesRules.TERMINAL_NUMBER
            ) {
                const { symbolsCountValue, restrictValue } = rule

                const ruleValue = restrictValue === ''
                    ? `^.{${symbolsCountValue}}$`
                    : `^[${restrictValue}]{${symbolsCountValue}}$`

                return [...acc, createValidationRule({
                    fieldName: key,
                    ruleType: REGEXP,
                    value: ruleValue,
                })]
            }

            if (key === PurchasesRules.AUTH_CODE) {
                const { symbolsCountValue, restrictValue, canBeEmptyValue } = rule
                
                let ruleValue: string

                if (canBeEmptyValue) {
                    ruleValue = restrictValue ? `^.{0}|[${restrictValue}]{${symbolsCountValue}}$` : `^.{0}|.{${symbolsCountValue}}$`
                } else {
                    ruleValue = restrictValue ? `^[${restrictValue}]{${symbolsCountValue}}$` : `^.{${symbolsCountValue}}$`
                }

                return [...acc, createValidationRule({
                    fieldName: key,
                    ruleType: REGEXP,
                    value: ruleValue,
                })]
            }

            if (key === PurchasesRules.CARD_NUMBER || key === PurchasesRules.KPP_NUMBER_VALIDATION || key === PurchasesRules.CARD_TYPE_VALIDATION) {
                const { canBeEmptyValue, restrictValue } = rule

                let ruleValue: string

                if (canBeEmptyValue) {
                    ruleValue = restrictValue === '' ? '^.*$' : `^[${restrictValue}]*$`
                }

                if (!canBeEmptyValue) {
                    ruleValue = restrictValue === '' ? '^.+$' : `^[${restrictValue}]+$`
                }

                return [...acc, createValidationRule({
                    fieldName: key,
                    ruleType: REGEXP,
                    value: ruleValue,
                })]
            }

            if (
                key === PurchasesRules.TRANSACTIONS ||
                key === PurchasesRules.DISCOUNT_AMOUNT ||
                key === PurchasesRules.PURCHASE_REGISTRATION_DATE
            ) {
                const { value } = rule

                return [...acc, createValidationRule({
                    fieldName: key,
                    ruleType: key === PurchasesRules.PURCHASE_REGISTRATION_DATE ? PARAMETER : PRESET,
                    value,
                })]
            }

            if (key === PurchasesRules.AMOUNT) {
                const { positionsValue, paymentsValue } = rule

                const positions = positionsValue ? AMOUNT_EQUALS_POSITIONS_SUM : NULL_RULE
                const payments = paymentsValue ? AMOUNT_EQUALS_PAYMENTS_SUM : NULL_RULE

                return [
                    ...acc,
                    createValidationRule({
                        fieldName: key,
                        ruleType: PRESET,
                        value: positions,
                    }),
                    createValidationRule({
                        fieldName: key,
                        ruleType: PRESET,
                        value: payments,
                    })
                ]
            }
        }, [])

        return coerced
    }

    @action
    reset = (): void => {
        this.isValidationEnabled = false
        this.validators = []
        this.purchasesRules = {}
    }
}
