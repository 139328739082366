import * as React from 'react'
import { FilterType, getFilterTypeName } from '../../../core/filters/filter'

import { NOT_SPECIFIED } from '../../../core/values'
import { t } from 'i18next'
import { SelectInputProps, DefaultSelectOption, SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

export interface BaseSelectFilterProps extends Omit<SelectInputProps, 'label' | 'onSelect' | 'ref'> {
    filterType: FilterType
    defaultValue?: string
    options?: DefaultSelectOption[]
    onFilterChange?: (filter: FilterType, value: string) => void
    /**
     * Функция используется для трансформации выбранных options в строку для фильтра, когда multiple === true
     */
    optionsToFilterValue?: (selectedOptions: DefaultSelectOption[]) => string
    /**
     * Функция используется для трансформации строки filterValue в масив values для SelectInput
     */
    filterValueToSelectedValues?: (value: string) => string[]
}

export interface BaseSelectFilterState {
    value: string
}

export class BaseSelectFilter extends React.PureComponent<BaseSelectFilterProps, BaseSelectFilterState> {
    static defaultProps: Partial<BaseSelectFilterProps> = {
        onFilterChange: () => null,
        optionsToFilterValue: (selectedOptions: DefaultSelectOption[]) =>
            selectedOptions.map(o => o.value).join(','),
        filterValueToSelectedValues: (value: string) => value ? value.split(',') : []
    }

    state: BaseSelectFilterState = {
        value: this.props.defaultValue || ''
    }

    addNotSpecifiedOption = (options: DefaultSelectOption[]): DefaultSelectOption[] => {
        // Если есть опции, но среди них нет NOT_SPECIFIED - добавляем
        return options
            && options.length > 0
            && !options.some(option => option.value === NOT_SPECIFIED)
                ? [{ label: t('common.notSpecified'), value: NOT_SPECIFIED }, ...options]
                : options
    }

    render() {
        const {
            fetchFunction, defaultValue, filterType, onFilterChange, options,
            optionsToFilterValue, filterValueToSelectedValues, multiple, ...other
        } = this.props
        const {value} = this.state

        return (
            <SelectInput
                value={multiple ? filterValueToSelectedValues(value) : value}
                options={multiple ? options : this.addNotSpecifiedOption(options)}
                fetchFunction={
                    fetchFunction
                        ? async () => {
                            const options = await fetchFunction()
                            return multiple ? options : this.addNotSpecifiedOption(options)
                        }
                        : undefined
                }
                {...other}
                label={getFilterTypeName(filterType)}
                multiple={multiple}
                onSelect={selectedOption => {
                    const selectedValue = selectedOption.value !== NOT_SPECIFIED
                        ? selectedOption.value
                        : ''
                    this.setState({ value: selectedValue })
                    onFilterChange(filterType, selectedValue)
                }}
                onMultipleSelect={(selectedOptions: DefaultSelectOption[]) => {
                    const selectedValue = optionsToFilterValue(selectedOptions)
                    this.setState({ value: selectedValue })
                    onFilterChange(filterType, selectedValue)
                }}
            />
        )
    }
}
