import { t } from 'i18next'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { cashManagerLocal } from '../../../../protocol/set10/cash-manager-local'
import { cashToServerServiceRemote } from '../../../../protocol/set10/cash-to-server-service-remote'
import { iEquipmentManagerLocal } from '../../../../protocol/set10/i-equipment-manager-local'
import { ACTIVE as CASH_ACTIVE, CLOSED_DOWN as CASH_CLOSED } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-status'
import { CashTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-template-vo'
import {CashVO, createCashVO} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-vo'
import { EquipmentModelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { FormValidation, ValidationResult } from '../../../../utils/form-validation/form-validation'
import { requiredField } from '../../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../../utils/form-validation/validators/unique-field'
import { AppStore } from '../../app-store'
import { UserStore } from '../../user-store'
import { getStore } from '../../stores-repository'
import {
    APP_STORE,
    SHOP_STORE,
    USER_STORE
} from '../../stores'
import { withSpinner } from '../../with-spinner'
import {DeviceSettingsType} from '../../../core/values'
import {ShopStore} from './shop-store'
import uuid from 'uuid'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { sortBy } from 'lodash'

export class ShopCashesStore {

    // Шаг касс
    @observable
    cashes: CashVO[] = []
    @observable
    cashesTemplates: CashTemplateVO[] = []
    @observable
    keyboards: EquipmentModelVO[] = []
    @observable
    cashSettings: CashVO
    @observable
    cashValidation: FormValidation<CashVO>
    @observable
    cashSettingsType: DeviceSettingsType
    @observable
    cashSettingsCount: number
    @observable
    paginatorKey: string = ''
    @observable
    paginationState: PaginationState = undefined

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private shopStore: ShopStore = getStore(SHOP_STORE)

    @computed
    get sortedCashes(): CashVO[]  {
        return sortBy(this.cashes, 'number')
    }

    // Cashes page

    fetchAllCashesData = async (): Promise<void> => {
        await withSpinner(async () => {
            await this.fetchCashes()
            await this.fetchCashesTemplates()
            await this.fetchCashesKeyboards()
        })
    }

    fetchCashes = (): Promise<void> => {
        if (this.appStore.isCentrum) {
            if (!this.shopStore.shop || !this.shopStore.shop.number) {
                return Promise.resolve()
            }
            return cashManagerLocal.getCashesInShop(this.userStore.session, this.shopStore.shop.number)
            .then(cashes => {
                runInAction(() => {
                    this.cashes = cashes
                    this.paginatorKey = uuid()
                })
            })
        } else {
            return cashManagerLocal.getCashes(this.userStore.session)
            .then(cashes => {
                runInAction(() => {
                    this.cashes = cashes
                    this.paginatorKey = uuid()
                })
            })
        }
    }

    fetchCashesTemplates = (): Promise<void> => {
        return cashManagerLocal.getCashTemplates(this.userStore.session)
            .then(cashesTemplates => {
                runInAction(() => {
                    this.cashesTemplates = cashesTemplates
                })
            })
    }

    fetchCashesKeyboards = (): Promise<void> => {
        return iEquipmentManagerLocal.getAllRegisteredEquipmentModelsOfClassAndType(this.userStore.session, 'Cash', 'keyboard', this.appStore.locale)
            .then(keyboards => {
                runInAction(() => {
                    this.keyboards = keyboards
                })
            })
    }

    @computed
    get cashLayouts(): string[] {
        let result: string[] = []
        if (this.keyboards.length === 0) {
            result.push('Стандартная')
        } else {
            this.keyboards.forEach((model: EquipmentModelVO) => {
                if (model.settings && model.settings[0]) {
                    result.push(model.settings[0].name)
                }
            })
        }

        return result
    }

    @action
    editCash = (cash: CashVO): void => {
        if (cash) {
            this.cashSettings = toJS(cash)
            this.cashSettingsType = DeviceSettingsType.EDIT
            this.createCashValidation()
        }
    }

    @action
    addCash = (): void => {
        this.cashSettings = this.getBlankCashSettings()
        this.cashSettingsType = DeviceSettingsType.NEW
        this.createCashValidation(true)
        this.cashSettingsCount = 1
    }

    getBlankCashSettings = (): CashVO => {
        const firstTemplate = this.cashesTemplates.length > 0 ? toJS(this.cashesTemplates[0]) : null
        const firstLayout = this.cashLayouts[0]

        return createCashVO({
            id: -1,
            shopNumber: this.shopStore.shop.number,
            template: firstTemplate,
            layoutName: firstLayout,
        })
    }

    @action
    createCashValidation = (creation: boolean = false): void => {
        this.cashValidation = new FormValidation<CashVO>(
            this.cashSettings,
            [
                {
                    field: 'number',
                    rules: [
                        (value: number): ValidationResult => {
                            if (!value) {
                                return {
                                    valid: false,
                                    error: t('validation.requiredField'),
                                }
                            } else {
                                return {
                                    valid: true
                                }
                            }
                        },
                        uniqueField(this.cashes
                            .filter(с => с.id !== this.cashSettings.id)
                            .map(с => с.number))
                    ]
                },
                {
                    field: 'layoutName',
                    rules: [
                        requiredField
                    ]
                },
                {
                    field: 'template',
                    rules: [
                        requiredField
                    ]
                },
            ],
            creation
        )
    }

    @action
    updateCashStatus = (cash: CashVO, close?: boolean) => {
        if (close) {
            // Законсервировать
            cash.status = CASH_CLOSED
        } else {
            // Активировать
            cash.status = CASH_ACTIVE
        }
        this.saveCash(cash)
    }

    @action
    updateCash = (changes: Partial<CashVO>) => {
        Object.keys(changes).forEach(k => {
            this.cashSettings[k] = changes[k]
        })
    }

    @action
    updateCashCount = (count: number) => {
        this.cashSettingsCount = count
    }

    @action
    closeCashSettings = () => {
        this.cashSettings = null
        this.cashSettingsType = null
        this.cashSettingsCount = null
        this.cashValidation = null
    }

    deleteCash = (cash: CashVO): Promise<void> => {
        return withSpinner(cashManagerLocal.deleteCash(this.userStore.session, cash.id)
            .then(result => {
                if (result) {
                    runInAction(() => {
                        const deletedId = cash.id
                        const deleteIndex = this.cashes.findIndex(item => item.id === deletedId)
                        if (deleteIndex !== -1) {
                            this.cashes.splice(deleteIndex, 1)
                        }
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    notifyNewCash = (cash: CashVO): Promise<number> => {
        return cashToServerServiceRemote.newCashNotify(this.shopStore.shop.number, cash.number)
    }

    saveCashParameters = (cash: CashVO): Promise<void> => {
        return cashManagerLocal.updateCashParams(cash, true)
    }

    saveCash = (cash: CashVO): Promise<void> => {
        //TODO - возможно надо будет запускать saveCashParameters, пока он не работает
        return withSpinner(cashManagerLocal.addCash2(this.userStore.session, cash)
            .then(addedCash => {
                if (addedCash) {
                    runInAction(() => {
                        let id = addedCash.id
                        let index = this.cashes.findIndex(item => item.id === id)
                        if (index !== -1) {
                            this.cashes[index] = addedCash
                        }
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    saveNewCashes = (cash: CashVO, count: number = 1): Promise<void> => {
        return withSpinner(cashManagerLocal.addCashesToShop(this.userStore.session, cash.template.id, cash.layoutName, cash.shopNumber, count)
            .then(addedCashes => {
                if (addedCashes && addedCashes.length > 0) {
                    runInAction(() => {
                        this.cashes = this.cashes.concat(addedCashes)
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    @action
    setPaginationState = (paginationState: PaginationState) => {
        this.paginationState = paginationState
    }

    @action
    reset = (): void => {
        this.cashes = []
        this.cashesTemplates = []
        this.keyboards = []
        this.cashSettings = undefined
        this.cashValidation = undefined
        this.cashSettingsType = undefined
        this.cashSettingsCount = undefined
        this.paginatorKey = ''
        this.paginationState = undefined
    }
}
