import { RectBounds } from '../rect-bounds'
import { ControllerType, ROTATION } from '../hand-controller'
import { Vector2d } from '../../../utils/math/geom-util'
import { BaseConstraint, EditorState } from './constraint'

export interface SnapToGridConstraintOptions {
    gridStep: number
}

export class SnapToGridConstraint extends BaseConstraint<SnapToGridConstraintOptions> {

    constructor(options: SnapToGridConstraintOptions, scale: number = 1, active: boolean = false) {
        super(options, scale, active)
    }

    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d {
        if (!this.active || controller === ROTATION) return translation

        const gridStep = this.options.gridStep * this.scale
        let controllerPos = bounds.getControllerPos(controller)
        let targetPos = controllerPos.add(translation)
        let fixedTranslation = translation.clone()

        if (targetPos.x !== 0) {
            fixedTranslation.x = Math.round(targetPos.x / gridStep) * gridStep - controllerPos.x
        }

        if (targetPos.y !== 0) {
            fixedTranslation.y = Math.round(targetPos.y / gridStep) * gridStep - controllerPos.y
        }

        return fixedTranslation
    }
}
