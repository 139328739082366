export interface TimeInterval {
    '@class': 'ru.crystals.setretailx.printing.directive.vo.TimeInterval'
    startTime?: number
    endTime?: number
}

export const createTimeInterval = (initialization: Partial<TimeInterval>): TimeInterval => {
    if (!initialization) return null
    
    let result: TimeInterval = {
        startTime: initialization.startTime,
        endTime: initialization.endTime,
        '@class': 'ru.crystals.setretailx.printing.directive.vo.TimeInterval',
    }
    
    return result
}
