import React from 'react'
import { PageMock } from '../../components/page-mock/page-mock'
import {
    ACTIONS_AND_DISCOUNTS,
    CASHES_OPTIMIZATION,
    FINANCIAL,
    OTHER,
    PERSONNEL_MOTIVATION,
    REPORTS,
    SHOP_WORK,
    ACTIVITY_TIME,
    POSITION_PER_MINUTE,
    CASHIER_PERFOMANCE,
    SALES_REPORT,
    CASH_CUSTOMERS,
    SUMS_BY_CUSTOMERS,
    UNKNOWN_PRODUCTS,
    UNREADABLE_BARCODES,
    CUSTOMERS_BY_DAYS_WEEK, CASHDESK_SALES_REPORT,
    CASHIERS_ACTIVITY,
    getRouteName,
} from '../core/app-routes'
import {RouteScheme, RETAIL} from '../utils/route-modules-util'
import { lazy } from '../utils/code-splitting'
import {
    OPERDAY_ADVERTISING_REPORTS, OPERDAY_FINANCIAL_REPORTS, OPERDAY_OTHER_REPORTS,
    OPERDAY_REPORT_MARKET_WORK_PRIVELEGE, OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE,
    OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE
} from '../core/privileges/privileges'
import Assessment from '@material-ui/icons/Assessment'
import { PrivilegeManager } from '../core/privileges/privilege-manager'

export const reportsRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'reportsNavLink',
        label: getRouteName(REPORTS),
        icon: <Assessment/>,
    },
    path: REPORTS,
    privileges: (privilegeManager: PrivilegeManager) => {
        return privilegeManager.haveAnyPrivilegeFromList([
            OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE,
            OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE,
            OPERDAY_REPORT_MARKET_WORK_PRIVELEGE,
            OPERDAY_ADVERTISING_REPORTS,
            OPERDAY_OTHER_REPORTS,
            OPERDAY_FINANCIAL_REPORTS
        ])
    },
    children: [
        {
            navItemProps: {
                id: 'personalMotivationReportsNavLink',
                label: getRouteName(PERSONNEL_MOTIVATION),
            },
            path: `${REPORTS}${PERSONNEL_MOTIVATION}`,
            privileges: [OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE],
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "personnel-motivation-reports" */ '../pages/operday/reports/personnel-motivation/personnel-motivation-reports'))
        },
        {
            path: `${REPORTS}${PERSONNEL_MOTIVATION}${ACTIVITY_TIME}`,
            privileges: [OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "activity-time-report" */ '../pages/operday/reports/personnel-motivation/activity-time-report'))
        },
        {
            path: `${REPORTS}${PERSONNEL_MOTIVATION}${POSITION_PER_MINUTE}`,
            privileges: [OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE],
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "position-per-minute-report" */ '../pages/operday/reports/personnel-motivation/position-per-minute-report'))
        },
        {
            path: `${REPORTS}${PERSONNEL_MOTIVATION}${CASHIER_PERFOMANCE}/:number`,
            cannotBeRedirectTarget: true,
            privileges: [OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "cashier-perfomance" */ '../pages/operday/reports/personnel-motivation/cashier-perfomance'))
        },
        {
            navItemProps: {
                id: 'cashesOptimizationReportsNavLink',
                label: getRouteName(CASHES_OPTIMIZATION),
            },
            path: `${REPORTS}${CASHES_OPTIMIZATION}`,
            privileges: [OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE],
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "cashes-optimization-reports" */ '../pages/operday/reports/cashes-optimization/cashes-optimization-reports'))
        },
        {
            path: `${REPORTS}${CASHES_OPTIMIZATION}${SALES_REPORT}`,
            privileges: [OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE],
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "cash-sales-report" */ '../pages/operday/reports/cashes-optimization/cash-sales-report'))
        },
        {
          path: `${REPORTS}${CASHES_OPTIMIZATION}${CASHDESK_SALES_REPORT}/:number`,
          privileges: [OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE],
          component: lazy(() =>
              // tslint:disable-next-line
              import(/* webpackChunkName: "cash-desk-sales-report" */ '../pages/operday/reports/cashes-optimization/cash-desk-sales-report'))
        },
        {
            path: `${REPORTS}${CASHES_OPTIMIZATION}${CASH_CUSTOMERS}`,
            privileges: [OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "cash-customers-report" */ '../pages/operday/reports/cashes-optimization/cash-customers-report'))
        },
        {
            navItemProps: {
                id: 'shopWorkReportsNavLink',
                label: getRouteName(SHOP_WORK),
            },
            path: `${REPORTS}${SHOP_WORK}`,
            privileges: [OPERDAY_REPORT_MARKET_WORK_PRIVELEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "shop-work-reports" */ '../pages/operday/reports/shop-work/shop-work-reports'))
        },
        {
            path: `${REPORTS}${SHOP_WORK}${SUMS_BY_CUSTOMERS}`,
            privileges: [OPERDAY_REPORT_MARKET_WORK_PRIVELEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "sums-by-customer-report" */ '../pages/operday/reports/shop-work/sums-by-customer-report'))
        },
        {
            path: `${REPORTS}${SHOP_WORK}${UNKNOWN_PRODUCTS}`,
            privileges: [OPERDAY_REPORT_MARKET_WORK_PRIVELEGE],
            component: lazy(() =>
            import(/* webpackChunkName: "unknown-products-report" */ '../pages/operday/reports/shop-work/unknown-products-report'))
        },
        {
            path: `${REPORTS}${SHOP_WORK}${UNREADABLE_BARCODES}`,
            privileges: [OPERDAY_REPORT_MARKET_WORK_PRIVELEGE],
            component: lazy(() =>
            import(/* webpackChunkName: "unreadable-barcodes-report" */ '../pages/operday/reports/shop-work/unreadable-barcodes-report'))
        },
        {
            path: `${REPORTS}${SHOP_WORK}${CUSTOMERS_BY_DAYS_WEEK}`,
            privileges: [OPERDAY_REPORT_MARKET_WORK_PRIVELEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "customers-by-days-week-report" */ '../pages/operday/reports/shop-work/customers-by-days-week-report'))
        },
        {
            path: `${REPORTS}${SHOP_WORK}${CASHIERS_ACTIVITY}`,
            privileges: [OPERDAY_REPORT_MARKET_WORK_PRIVELEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "cashiers-activity-report" */ '../pages/operday/reports/shop-work/cashiers-activity-report'))
        },
        {
            navItemProps: {
                id: 'reportsByActionsAndDiscountsNavLink',
                label: getRouteName(ACTIONS_AND_DISCOUNTS),
            },
            path: `${REPORTS}${ACTIONS_AND_DISCOUNTS}`,
            privileges: [OPERDAY_ADVERTISING_REPORTS],
            component: lazy(() =>
                import(/* webpackChunkName: "advertisingreports" */ '../pages/operday/reports/advertisment-reports/advertising-reports'))
        },
        {
            navItemProps: {
                id: 'financialReportsNavLink',
                label: getRouteName(FINANCIAL),
            },
            path: `${REPORTS}${FINANCIAL}`,
            privileges: [OPERDAY_FINANCIAL_REPORTS],
            component: lazy(() =>
                import(/* webpackChunkName: "financial-reports" */ '../pages/operday/reports/financial-reports/financial-reports'))
        },
        {
            navItemProps: {
                id: 'otherReportsNavLink',
                label: getRouteName(OTHER),
            },
            path: `${REPORTS}${OTHER}`,
            privileges: [OPERDAY_OTHER_REPORTS],
            component: lazy(() =>
                import(/* webpackChunkName: "other-reports" */ '../pages/operday/reports/other-reports/other-reports'))
        },
    ]
})
