import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import { NavigationMenu } from '../navigation-menu/navigation-menu'
import { AppBar } from './app-bar/app-bar'
import { APP_STORE } from '../../store/stores'
import { ERROR } from '../../../components/simple-dialog/simple-dialog'
import { AppStore } from '../../store/app-store'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

const styles = require('./pages-container.scss')

interface PagesContainerProps {
    app?: AppStore
    mobile?: boolean
}

@inject(APP_STORE)
@observer
export class InnerPagesContainer extends React.Component<PagesContainerProps> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error(error)
        console.error('errorInfo: ', errorInfo)

        const { modulesDescriptors, ...serverInfo } = this.props.app.serverInfo
        const { showDialog } = this.props.app

        showDialog({
            title: t('common.commonErrorTitle'),
            message: t('common.commonRuntimeErrorMessage', {
                error: JSON.stringify({
                    error: {
                        name: error.name,
                        message: error.message,
                        stack: error.stack
                    },
                    serverInfo,
                    route: window.location.pathname,
                    errorInfo
                }),
                interpolation: { escapeValue: false }
            }),
            mode: ERROR
        })
    }

    render() {
        return (
            <div id="pagesContainer" className={styles.pagesContainer}>
                <NavigationMenu />
                <div className={styles.pageWithAppBar}>
                    <AppBar />
                    <div id="pageContent" className={styles.pageContent}>
                        <div className={classNames(styles.page, {
                            [styles.pageMobile]: this.props.mobile
                        })}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const PagesContainer: React.FC<PagesContainerProps> = props => {
    const theme = useTheme()
    const mobile = useMediaQuery(() => theme.breakpoints.down('xs'))
    return <InnerPagesContainer {...props} mobile={mobile} />
}
