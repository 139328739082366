import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRODUCT_DETAILS_STORE, PRODUCT_SALES_POLICY_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import moment from 'moment'
import { AppStore } from '../../../store/app-store'
import { t } from 'i18next'
import _ from 'lodash'
import { PriceVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-vo'
import { formatPrice } from '../../../core/currency-utils'
import { NotSearched } from '../../../components/stub-with-img/not-searched'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import NavigateNext from '@material-ui/icons/NavigateNext'
import { ProductSalesPolicyStore } from '../../../store/products/product-sales-policy-store'
import { toJS } from 'mobx'
import { fromClientToServerTime } from '../../../utils/app-util'
import { RIGHT, TOP, Table } from '@crystalservice/crystals-ui/lib'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import {
    SelectInput,
    DefaultSelectOption
} from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { highlightFirstMatch } from '../../../../components/product-base-info/product-base-info'

export interface PricesShopsListProps {
    productSalesPolicyStore?: ProductSalesPolicyStore
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
}

const styles = require('./prices-shops-list.scss')

@inject(APP_STORE)
@inject(PRODUCT_DETAILS_STORE)
@inject(PRODUCT_SALES_POLICY_STORE)
@observer
export default class PricesShopsList extends React.Component<PricesShopsListProps> {

    sortingF = (priceA: PriceVO, priceB: PriceVO) => {
        if (priceA.department > priceB.department) return 1
        if (priceA.department < priceB.department) return -1
        if (priceA.number > priceB.number) return 1
        if (priceA.number < priceB.number) return -1

        return 0
    }

    priceNumberFilter = (price: PriceVO) => {
        const { selectedPrices } = this.props.productSalesPolicyStore

        return selectedPrices.includes(price.number)
    }

    render() {
        const { productDetails: activeProduct } = this.props.productDetailsStore
        const {
            setSelectedShop, setSelectedPrices, selectedPrices, shopFilterValue, setShopFilterValue, pricesOptions
        } = this.props.productSalesPolicyStore

        const { prices } = activeProduct

        if (!prices.length) return <Empty/>

        const shops = _.groupBy(prices.filter(price => !!price.shop), 'shop.number')

        const filteredShops = Object.keys(shops)
            .map(key => shops[key].filter(this.priceNumberFilter).sort(this.sortingF))
            .filter((prices: PriceVO[]) =>
                prices.every(price =>
                    (price.shop.name &&
                    price.shop.name.toLowerCase().indexOf(shopFilterValue.toLowerCase()) > -1) ||
                    (price.shop.number &&
                    String(price.shop.number).indexOf(shopFilterValue) > -1)
                )
            )

        return (
            <div className={styles.pricesShopList}>
                <div className={styles.shopListControlPanel}>
                    <div className={styles.virtualRow}>
                        <SelectInput<DefaultSelectOption<number>>
                            id="shopPricesInput"
                            multiple
                            value={toJS(selectedPrices)}
                            onMultipleSelect={selectedOptions => {
                                const updatedOptions = selectedOptions.map(option => option.value)
                                if (!updatedOptions.length) return

                                setSelectedPrices(updatedOptions)
                            }}
                            options={pricesOptions}
                            className={styles.pricesSelect}
                        />
                    </div>
                    <div className={styles.virtualRow}>
                        <TextField
                            id="shopNameFilterInput"
                            label={t('productDetails.salesPolicyEntrance.shopFilterPlaceholder')}
                            value={shopFilterValue}
                            onValueChange={value => setShopFilterValue(value)}
                            className={styles.shopFilterInput}
                        />
                    </div>
                </div>
                {!!filteredShops.length && (
                    <Table
                        id="pricesShopsList"
                        withHeader
                        keyField="id"
                        items={filteredShops}
                        className={styles.shopsTable}
                        columns={[
                            {
                                header: t('productDetails.salesPolicyEntrance.shop'),
                                renderer: (props: { item: PriceVO[] }) => {
                                    return highlightFirstMatch(
                                        props.item[0].shop.name,
                                        shopFilterValue,
                                        styles.highlighted
                                    )
                                },
                                vAlign: TOP,
                            },
                            {
                                header: t('productDetails.salesPolicyEntrance.shopNumber'),
                                renderer: (props: { item: PriceVO[] }) => {
                                    return highlightFirstMatch(
                                        String(props.item[0].shop.number),
                                        shopFilterValue,
                                        styles.highlighted
                                    )
                                },
                                vAlign: TOP,
                            },
                            {
                                header: t('productDetails.salesPolicyEntrance.department'),
                                tdClassName: styles.mdShow,
                                renderer: props => {
                                    const prices: PriceVO[] = props.item

                                    return prices.sort(this.sortingF).map(price => {
                                        return (
                                            <div key={`department-name-${price.id}`}>
                                                {price.department.name}
                                            </div>
                                        )
                                    })
                                },
                            },
                            {
                                header: t('productDetails.salesPolicyEntrance.price'),
                                tdClassName: styles.mdShow,
                                renderer: props => {
                                    const prices: PriceVO[] = props.item

                                    return prices.map(price => {
                                        return (
                                            <div key={`price-number-${price.id}`}>
                                                {`${t('common.price')} ${price.number}`}
                                            </div>
                                        )
                                    })
                                },
                            },
                            {
                                header: t('productDetails.salesPolicyEntrance.value'),
                                tdClassName: styles.mdShow,
                                renderer: props => {
                                    const prices: PriceVO[] = props.item

                                    return prices.map(price => {
                                        return (
                                            <div key={`price-value-${price.id}`}>
                                                {formatPrice(price.value / 100)}
                                            </div>
                                        )
                                    })
                                },
                            },
                            {
                                header: t('productDetails.salesPolicyEntrance.packCount'),
                                tdClassName: styles.mdShow,
                                renderer: props => {
                                    const prices: PriceVO[] = props.item

                                    return prices.map(price => {
                                        return (
                                            <div key={`price-pack-сount-${price.id}`}>
                                                {price.packCount ? price.packCount : '–'}
                                            </div>
                                        )
                                    })
                                },
                            },
                            {
                                header: t('productDetails.salesPolicyEntrance.deadlines'),
                                tdClassName: styles.mdShow,
                                renderer: props => {
                                    const prices: PriceVO[] = props.item

                                    return prices.map(price => {
                                        return (
                                            <div key={`price-dates-${price.id}`}>
                                                {`${moment(fromClientToServerTime(price.beginDate)).format('DD.MM.YYYY (HH:mm)')}`}
                                                {` – `}
                                                { price.endDate ? (
                                                    <>
                                                        {moment(fromClientToServerTime(price.endDate)).format('DD.MM.YYYY (HH:mm)')}
                                                    </>
                                                ) : (
                                                    `${t('productDetails.indefinitely')}`
                                                )}
                                            </div>
                                        )
                                    })
                                },
                            },
                            {
                                renderer: props => {
                                    const prices: PriceVO[] = props.item

                                    return (
                                        <AdaptiveIconButton
                                            id={`goToButton${prices[0].shop.number}`}
                                            label={t('productDetails.salesPolicyEntrance.toCharts')}
                                            onClick={() => setSelectedShop(prices[0].shop.number)}
                                        >
                                            <NavigateNext />
                                        </AdaptiveIconButton>
                                    )
                                },
                                hAlign: RIGHT,
                                padding: '0 10px',
                                showOnRowHover: true,
                                withControls: true,
                                width: '70px'
                            }
                        ]}
                        onRowDoubleClick={(prices: PriceVO[]) => {
                            setSelectedShop(prices[0].shop.number)
                        }}
                    />
                )}
                {filteredShops.length === 0 &&
                    <NotSearched
                        titleText={t('productDetails.salesPolicyEntrance.notSearched')}
                        hintText=""
                    />
                }
            </div>
        )
    }
}
