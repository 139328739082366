import React from 'react'
import { inject, observer } from 'mobx-react'
import { PRODUCT_DETAILS_STORE, USER_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { t } from 'i18next'
import { getInfoRow } from './product-info-row'
import { get, isNil } from 'lodash'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {
    NOT_SPECIFIED, RST_MANDOTORY, RST_VOLUNTARY, STR, EAC, CertificationType
} from '../../../core/products/certification-types'
import { ProductGroupsDialog } from './product-groups-dialog'
import { UserStore } from '../../../store/user-store'
import { SETRETAILX_GOODS_CHANGING } from '../../../core/privileges/privileges'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { ProductVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import { CIGGY } from '../../../core/products/product-types'
import { LabeledData } from '@crystalservice/crystals-ui/lib/components/labeled-data/labeled-data'
import { PluginPropertyKeys } from '../../../core/products/product-info'
import { Add, Remove } from '@material-ui/icons'
import { ProductRecyclingInput } from './product-recycling-input'
import {
    MarkTypeVO, FOOTWEAR, LIGHT_INDUSTRY, TOBACCO, MILK, PERFUMES, PHOTO, TYRES
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/mark-type-vo'
import { getMarkTypeName } from '../../../core/products/mark-types'

const styles = require('./product-info.scss')

const RST_MANDOTORY_ICON = require('../../../../assets/images/icons/product/rst_mandotory.svg')
const RST_VOLUNTARY_ICON = require('../../../../assets/images/icons/product/rst_voluntary.svg')
const STR_ICON = require('../../../../assets/images/icons/product/str.svg')
const EAC_ICON = require('../../../../assets/images/icons/product/eac.svg')

const EXCISE_PRODUCT = require('../../../../assets/images/icons/product/excise-product.svg')
const PYRO_PRODUCT = require('../../../../assets/images/icons/product/pyro-product.svg')
const CHILD_PRODUCT = require('../../../../assets/images/icons/product/children-products.svg')
const TABACCO_PRODUCT = require('../../../../assets/images/icons/product/tabacco-product.svg')
const ENERGY_PRODUCT = require('../../../../assets/images/icons/product/energy-product.svg')

const MARK_FOOTWEAR_ICON = require('../../../../assets/images/icons/product/mark_footwear.svg')
const MARK_TOBACCO_ICON = require('../../../../assets/images/icons/product/mark_tobacco.svg')
const MARK_LIGHT_INDUSTRY_ICON = require('../../../../assets/images/icons/product/mark_light_industry.svg')
const MARK_MILK_ICON = require('../../../../assets/images/icons/product/mark_milk.svg')
const MARK_PERFUMES_ICON = require('../../../../assets/images/icons/product/mark_perfumes.svg')
const MARK_PHOTO_ICON = require('../../../../assets/images/icons/product/mark_photo.svg')
const MARK_TYRES_ICON = require('../../../../assets/images/icons/product/mark_tyres.svg')

export interface ProductGeneralInfoProps {
    productDetailsStore?: ProductDetailsStore
    user?: UserStore
}

export interface ProductGeneralInfoState {
    groupsDialogOpen: boolean
}

interface CertificationOption {
    label: string,
    value: CertificationType,
    icon?: any
}

@inject(PRODUCT_DETAILS_STORE)
@inject(USER_STORE)
@observer
export class ProductGeneralInfo extends React.Component<ProductGeneralInfoProps, ProductGeneralInfoState> {

    state: ProductGeneralInfoState = {
        groupsDialogOpen: false
    }

    getProductType = (productDetails: ProductVO_SF): JSX.Element => {
        return (
            <div className={styles.productType}>
                {productDetails.child && (
                    <img src={CHILD_PRODUCT} alt="CHILD_PRODUCT" />
                )}
                {productDetails.energy && (
                    <img src={ENERGY_PRODUCT} alt="ENERGY_PRODUCT" />
                )}
                {productDetails.pyro && (
                    <img src={PYRO_PRODUCT} alt="PYRO_PRODUCT" />
                )}
                {productDetails.productType === CIGGY && (
                    <img src={TABACCO_PRODUCT} alt="TABACCO_PRODUCT" />
                )}
                {productDetails.excise && (
                    <img src={EXCISE_PRODUCT} alt="EXCISE_PRODUCT" />
                )}
                <div>{t(`productDetails.productTypes.${productDetails.productType}`)}</div>
            </div>
        )
    }

    getCertificationInput = () => {
        const {
            productDetails, editProductDetails, updateProductDetails
        } = this.props.productDetailsStore
        const { havePrivilege } = this.props.user

        const options = [
            {
                label: t('productDetails.certificationType.notSpecified'),
                value: NOT_SPECIFIED
            },
            {
                label: t('productDetails.certificationType.rstMandotory'),
                value: RST_MANDOTORY,
                icon: <img src={RST_MANDOTORY_ICON} alt="RST_MANDOTORY_ICON" />
            },
            {
                label: t('productDetails.certificationType.rstVoluntary'),
                value: RST_VOLUNTARY,
                icon: <img src={RST_VOLUNTARY_ICON} alt="RST_VOLUNTARY_ICON" />
            },
            {
                label: t('productDetails.certificationType.str'),
                value: STR,
                icon: <img src={STR_ICON} alt="STR_ICON" />
            },
            {
                label: t('productDetails.certificationType.eac'),
                value: EAC,
                icon: <img src={EAC_ICON} alt="EAC_ICON" />
            },
        ]

        const readOnly = !havePrivilege(SETRETAILX_GOODS_CHANGING)
        if (readOnly) {
            const option = options.find(item => item.value === productDetails.certificationType)
            const shownValue = option ? option.label : options[0].label
            return (
                <LabeledData
                    id="certificationTextInput"
                    value={shownValue}
                    className={styles.selectInput}
                />
            )
        }
        return (
            <SelectInput<CertificationOption>
                id="certificationSelectInput"
                options={options}
                slim
                className={styles.selectInput}
                value={productDetails.certificationType || NOT_SPECIFIED}
                onSelect={selectedOption => {
                    editProductDetails({ certificationType: selectedOption.value})
                    updateProductDetails()
                }}
                labelFunction={(item: CertificationOption): any => {
                    return (
                        <div className={styles.selectOption}>
                            {item.icon || (<div className={styles.blankIcon} />)}
                            <div className={styles.selectLabel}>{item.label}</div>
                        </div>
                    )
                }}
            />
        )
    }

    getMarkType = (markTypeVO: MarkTypeVO): JSX.Element => {
        const getImgSrc = (markTypeVO: MarkTypeVO): string => {
            switch (markTypeVO) {
                case FOOTWEAR:
                    return MARK_FOOTWEAR_ICON
                case TOBACCO:
                    return MARK_TOBACCO_ICON
                case LIGHT_INDUSTRY:
                    return MARK_LIGHT_INDUSTRY_ICON
                case MILK:
                    return MARK_MILK_ICON
                case PERFUMES:
                    return MARK_PERFUMES_ICON
                case PHOTO:
                    return MARK_PHOTO_ICON
                case TYRES:
                    return MARK_TYRES_ICON
                default:
                    return ''
            }
        }

        const src = getImgSrc(markTypeVO)

        return (
            <div className={styles.markType}>
                { src && <img src={src} alt={markTypeVO} /> }
                <div>{getMarkTypeName(markTypeVO)}</div>
            </div>
        )
    }

    render() {
        const {
            productDetails,
            shortCode,
            addShortCode,
            deleteShortCode,
            fetchDetails
        } = this.props.productDetailsStore

        const consignment = productDetails?.pluginProperties.find(property =>
            property.key === PluginPropertyKeys.CONSIGNMENT)
        const weighingToSaleDelay = productDetails?.pluginProperties.find(property =>
            property.key === PluginPropertyKeys.WEIGHING_TO_SALE_DELAY)

        return (
            <div className={styles.generalInfo}>
                {this.state.groupsDialogOpen &&
                    <ProductGroupsDialog
                        onClose={() => this.setState({ groupsDialogOpen: false })}
                    />
                }
                { (Boolean(productDetails.productGroup) || Boolean(productDetails.group)) && (
                    <>
                        <div className={styles.groupLabel}>{t('productDetails.generalInfo.productGroup')}</div>
                        {getInfoRow(
                            productDetails.productGroup,
                            <Button
                                id="productGroupButton"
                                color="primary"
                                onClick={() => this.setState({ groupsDialogOpen: true })}
                            >
                                {t('productDetails.generalInfo.details')}
                            </Button>
                        )}
                    </>
                )}
                <div className={styles.groupLabel}>{t('productDetails.generalInfo.general')}</div>

                {getInfoRow(
                    t('productDetails.generalInfo.fullName'),
                    productDetails.fullName
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.shortCode'),
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            { shortCode ? shortCode : <span style={{ opacity: 0.5 }}>{`(${t('common.none')})`}</span> }
                        </div>
                        { shortCode &&
                            <Button
                                onClick={async () => {
                                    await deleteShortCode()
                                    fetchDetails()
                                }}
                                startIcon={<Remove />}
                                color="primary"
                                size="small"
                                className={styles.shortCodeButton}
                            >
                                { t('common.remove') }
                            </Button>
                        }
                        { !shortCode &&
                            <Button
                                onClick={async () => {
                                    await addShortCode()
                                    fetchDetails()
                                }}
                                startIcon={<Add />}
                                color="primary"
                                size="small"
                                className={styles.shortCodeButton}
                            >
                                { t('common.add') }
                            </Button>
                        }
                    </div>
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.certificationType'),
                    this.getCertificationInput()
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.recyclingType'),
                    <ProductRecyclingInput />
                )}
                { get(productDetails, 'section') && getInfoRow(
                    t('productDetails.generalInfo.section'),
                    get(productDetails, 'section')
                ) }
                {getInfoRow(
                    t('productDetails.generalInfo.measure'),
                    get(productDetails, 'measure.name')
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.nsp'),
                    productDetails.valueAddedTax ? `${productDetails.valueAddedTax}%` : ''
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.manufacturer'),
                    productDetails.manufacturer
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.country'),
                    productDetails.country
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.markType'),
                    this.getMarkType(productDetails.markTypeVO)
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.productType'),
                    this.getProductType(productDetails)
                )}
                {Boolean(productDetails.ageLimit) && getInfoRow(
                    t('productDetails.generalInfo.ageLimit'),
                    productDetails.ageLimit
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.consignment'),
                    isNil(consignment) ? '' : consignment.value === 'true' ? t('common.yes') : t('common.no')
                )}
                {getInfoRow(
                    t('productDetails.generalInfo.weighingToSaleDelay'),
                    weighingToSaleDelay ? `${weighingToSaleDelay.value} ${t('common.minutes')}` : ''
                )}
            </div>
        )
    }
}
