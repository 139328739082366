import { Gender } from './gender'
import { RoleVO } from './role-vo'

export interface UserVO {
    '@class': 'ru.crystals.pos.user.UserVO'
    id?: number
    deleted?: boolean
    guid?: number
    tabNumber?: string
    firstName?: string
    middleName?: string
    lastName?: string
    gender?: Gender
    passwordUnique?: string
    accessTime?: string
    blocked?: boolean
    barcode?: string
    magneticCard?: string
    set10Card?: string
    magneticKey?: string
    role?: RoleVO
    inn?: string
    shop?: number
    jobTitle?: string
    tabNum?: number
}

export const createUserVO = (initialization: Partial<UserVO>): UserVO => {
    if (!initialization) return null
    
    let result: UserVO = {
        id: initialization.id,
        deleted: initialization.deleted,
        guid: initialization.guid,
        tabNumber: initialization.tabNumber,
        firstName: initialization.firstName,
        middleName: initialization.middleName,
        lastName: initialization.lastName,
        gender: initialization.gender,
        passwordUnique: initialization.passwordUnique,
        accessTime: initialization.accessTime,
        blocked: initialization.blocked,
        barcode: initialization.barcode,
        magneticCard: initialization.magneticCard,
        set10Card: initialization.set10Card,
        magneticKey: initialization.magneticKey,
        role: initialization.role,
        inn: initialization.inn,
        shop: initialization.shop,
        jobTitle: initialization.jobTitle,
        tabNum: initialization.tabNum,
        '@class': 'ru.crystals.pos.user.UserVO',
    }
    
    return result
}
