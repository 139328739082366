import { SimpleDepartment } from './simple-department'
import { SimpleProductsGroup } from './simple-products-group'
import { PriceTagActionIdent } from './price-tag-action-ident'
import { PriceTagSizeLabelVO } from './price-tag-size-label-vo'

export interface PriceTagTemplateVO {
    '@class': 'ru.crystals.setretailx.products.vo.PriceTagTemplateVO'
    id?: string
    guid?: string
    extCode?: string
    name?: string
    displayColor?: string
    data?: string
    type?: string
    width?: number
    height?: number
    serveAllProductsList?: boolean
    actionable?: boolean
    cutLineNecessary?: boolean
    rotateNecessary?: boolean
    deleted?: boolean
    serveDepartments?: SimpleDepartment[]
    serveProductsGroups?: SimpleProductsGroup[]
    serveProducts?: string[]
    lastUpdate?: Date
    serveActionIdents?: PriceTagActionIdent[]
    notStandard?: boolean
    sizeLabel?: PriceTagSizeLabelVO
    count?: number
    subPriceTagTemplates?: PriceTagTemplateVO[]
}

export const createPriceTagTemplateVO = (initialization: Partial<PriceTagTemplateVO>): PriceTagTemplateVO => {
    if (!initialization) return null
    
    let result: PriceTagTemplateVO = {
        id: initialization.id,
        guid: initialization.guid,
        extCode: initialization.extCode,
        name: initialization.name,
        displayColor: initialization.displayColor,
        data: initialization.data,
        type: initialization.type,
        width: initialization.width,
        height: initialization.height,
        serveAllProductsList: initialization.serveAllProductsList,
        actionable: initialization.actionable,
        cutLineNecessary: initialization.cutLineNecessary,
        rotateNecessary: initialization.rotateNecessary,
        deleted: initialization.deleted,
        serveDepartments: initialization.serveDepartments,
        serveProductsGroups: initialization.serveProductsGroups,
        serveProducts: initialization.serveProducts,
        lastUpdate: initialization.lastUpdate,
        serveActionIdents: initialization.serveActionIdents,
        notStandard: initialization.notStandard,
        sizeLabel: initialization.sizeLabel,
        count: initialization.count,
        subPriceTagTemplates: initialization.subPriceTagTemplates,
        '@class': 'ru.crystals.setretailx.products.vo.PriceTagTemplateVO',
    }
    
    return result
}
