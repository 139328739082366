import { observable, action, runInAction, computed, toJS } from 'mobx'
import { t } from 'i18next'
import { compact, flatten, isEqual, cloneDeep } from 'lodash'
import moment from 'moment'
import { USER_STORE, APP_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { AppStore } from '../app-store'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import { PresentCardsVO, createPresentCardsVO } from '../../../protocol/set10/set-retail10-server/cards/set-cards-present-cards-ds/present-cards-vo'
import { PresentCardsStatisticVO } from '../../../protocol/set10/set-retail10-server/cards/set-cards-present-cards/present-cards-statistic-vo'
import { CardRangeVO, createCardRangeVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/card-range-vo'
import { CardVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/card-vo'
import { createCardsFilterVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cards-filter-vo'
import { ActionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/action-vo'
import { withSpinner } from '../with-spinner'
import { cardNumberToServerValidValue, } from '../../utils/string-util'
import { goTo } from '../../utils/router-util'
import { CARDS, PRESENT_CARDS, PRESENT_CARDS_CATEGORIES, PRESENT_CARDS_CATEGORY_EDIT } from '../../core/app-routes'
import { SNACKBAR_EXTENDED_DURATION } from '../../../utils/default-timeouts'
import { CardBlockingReason } from '../../../protocol/set10/set-retail10-commons/data-structs-module/card-blocking-reason'
import { addLeadingZero } from '../../core/cards/cards-util'
import { PresentCards, Create } from '../../core/cards/card-constants'
import {
    INTERNAL_ERROR,
    INTERSECTION_RANGES,
    CARD_IS_ACTIVATED,
    INVALID_RANGE
} from '../../core/server-codes'

export interface ExtendedPresentCardStatistics extends PresentCardsStatisticVO {
    cardId: number
}

export class PresentCardsStore {

    @observable
    presentCards: PresentCardsVO[]

    @observable
    presentCardsStatistics: ExtendedPresentCardStatistics[] = []

    @observable
    cards: CardVO[]

    @observable
    selectedCards: CardVO[] = []

    @observable
    searchCategoryName: string = ''

    @observable
    editingPresentCardCategory: PresentCardsVO = undefined

    @observable
    editingPresentCard: CardVO = undefined

    @observable
    editingPresentCardActions: ActionVO[] = []

    @observable
    editingPresentCardRanges: CardRangeVO[] = []

    @observable
    activationErrorMessage: string = null

    @observable
    isActivationSuccesful: boolean = false

    @observable
    extendCardReason: string = ''

    @observable
    extendCardExpiration: Date = null

    @observable
    rangeError: boolean = false

    @observable
    newCard: CardVO = undefined

    private lastUsedPath: string = ''
    private userStore: UserStore = getStore<UserStore>(USER_STORE)
    private appStore: AppStore = getStore<AppStore>(APP_STORE)
    private debounceTimer: any = null
    private originalPresentCardCategory: PresentCardsVO = undefined

    @computed
    get editedPresentCardCategoryModified() {
        return !isEqual(toJS(this.editingPresentCardCategory), toJS(this.originalPresentCardCategory))
    }

    @action
    openPresentCardCategory = async (id: number): Promise<void> => {
        const isNew = id === null
        let editingCard: PresentCardsVO

        if (isNew) {
            editingCard = this.createDraftPresentCard()
        } else {
            await this.fetchPresentCards()
            editingCard = this.presentCards?.filter(card => card.id === id)?.[0]
        }

        this.setEditingCardCategory(editingCard)
    }

    @action
    openPresentCard = async (cardNumber: string): Promise<void> => {
        const isNew = cardNumber === 'new'
        let editingCard: CardVO

        this.reset()

        if (isNew) {
            this.editingPresentCard = null
        } else {
            await this.fetchCardsByNumber(cardNumber)
            editingCard = this.cards?.filter(card => card.number === cardNumber)?.[0]
            if (!editingCard) return
            await this.fetchActionsByCard(editingCard)
        }

        runInAction(() => {
            this.editingPresentCard = editingCard
        })
    }

    @computed
    get minExpirationDay(): Date {
        return moment(this.editingPresentCard.expirationDate)
            .add(1, 'd')
            .startOf('d')
            .toDate()
    }

    @action
    openProlongationDialog = (): void => {
        this.setExtendCardExpiration(this.minExpirationDay)
    }

    @action
    closeProlongationDialog = (): void => {
        this.setExtendCardExpiration(null)
        this.setExtendCardReason('')
    }

    @action
    extendCard = async (): Promise<void> => {
        const extendedCard = await withSpinner(
            iCardsManagerRemote.extendCard(
                this.userStore.session,
                this.editingPresentCard,
                this.extendCardExpiration,
                toJS(this.userStore.userInfo),
                this.extendCardReason,
            )
        )

        runInAction(() => {
            this.editingPresentCard = extendedCard
        })
    }

    @action
    setExtendCardReason = (value: string) => {
        this.extendCardReason = value
    }

    @action
    setExtendCardExpiration = (value: Date) => {
        this.extendCardExpiration = value
    }

    createDraftPresentCard = (): PresentCardsVO => {
        return createPresentCardsVO({
            id: null,
            amount: 0,
            maxAmount: 0,
            multiplicity: 0,
            withoutFinishDate: true,
            name: t('presentCards.newPresentCard'),
        })
    }

    @action
    setCardNumberAndFetch = (cardNumber: string): void => {
        if (this.debounceTimer !== null) clearTimeout(this.debounceTimer)

        if (cardNumber.trim().length === 0) {
            runInAction(() => {
                this.cards = undefined
                this.selectedCards = []
            })
        } else {
            this.debounceTimer = setTimeout(() => this.fetchCardsByNumber(cardNumber), 300)
        }
    }

    @computed
    get presentCardsFiltered(): PresentCardsVO[] {
        return this.presentCards?.filter(card => card.name.toLowerCase().includes(this.searchCategoryName.toLowerCase())) || []
    }

    @action
    setSearchCategoryName = (searchString: string): void => {
        this.searchCategoryName = searchString
    }

    @action
    fetchCardsByNumbersList = async (cardNumbers: string): Promise<void> => {
        const cardsPromises = cardNumbers.split(',').map(cardNumber => this.fetchCardsByNumber(cardNumber.trim()))
        const cards = await withSpinner(Promise.all(cardsPromises))

        runInAction(() => {
            this.cards = compact(flatten(cards)) || []
        })
    }

    @action
    fetchPresentCardStatistics = async (id: number): Promise<ExtendedPresentCardStatistics> => {
        const statistics: PresentCardsStatisticVO = await iCardsManagerRemote.getPresentCardsStatistic(
            this.userStore.session,
            id,
            {
                customCommonResponseMiddlewares: []
            }
        )
        return ({
            cardId: id,
            ...statistics,
        })
    }

    @action
    fetchPresentCards = async (): Promise<void> => {
        const cards = await withSpinner(iCardsManagerRemote.getPresentCards(this.userStore.session, 0, 0))
        runInAction(() => {
            this.presentCards = cards || []
        })

        try {
            const statistics: ExtendedPresentCardStatistics[] = await Promise.all(cards.map(card => this.fetchPresentCardStatistics(card.id)))

            runInAction(() => {
                this.presentCardsStatistics = statistics
            })
        } catch {
            this.presentCardsStatistics = []
        }
    }

    @action
    fetchActionsByCard = async (card: CardVO): Promise<void> => {
        const actions = await withSpinner(iCardsManagerRemote.getActionsByCard(this.userStore.session, card))
        runInAction(() => {
            this.editingPresentCardActions = actions
        })
    }

    @action
    fetchCardsByNumber = async (cardNumber: string): Promise<CardVO[]> => {
        const cards = await withSpinner(iCardsManagerRemote.getCardsByFilter2(
            this.userStore.session,
            createCardsFilterVO({
                number: cardNumberToServerValidValue(cardNumber)
            }),
            0,
            30
        ))
        const presentCards = cards?.filter(card => card.cardTypeVO.classType === PresentCards)

        runInAction(() => {
            this.cards = presentCards || []
            this.selectedCards = []
        })
        return presentCards
    }

    @action
    activatePresentCards = async (start: string, count: number): Promise<void> => {
        await withSpinner(
            iCardsManagerRemote.activatePresentCards(
                this.userStore.session,
                cardNumberToServerValidValue(start),
                count,
                toJS(this.userStore.userInfo),
                {
                    customCommonResponseMiddlewares: [
                        response => {
                            const error: { code?: number, message?: string } = response.data.error

                            if (error && error.code === INTERNAL_ERROR) {
                                if (error.message === INVALID_RANGE) {
                                    this.setActivationErrorMessage(t('presentCards.activateByRangeDialog.msg.error.invalidRange'))

                                    return
                                }

                                if (error.message === CARD_IS_ACTIVATED) {
                                    this.setActivationErrorMessage(t('presentCards.activateByRangeDialog.msg.error.cardIsActivated'))

                                    return
                                }

                                this.setActivationErrorMessage(t('presentCards.activateByRangeDialog.msg.error.failure'))
                            } else {
                                this.setActivationErrorMessage(null)
                                this.setIsActivationSuccesful(true)
                            }
                    }]
                }
            )
        )
    }

    @action
    setActivationErrorMessage = (error: string) => {
        this.activationErrorMessage = error
    }

    @action
    setIsActivationSuccesful = (value: boolean) => {
        this.isActivationSuccesful = value
    }

    @action
    fetchCardRanges = async (): Promise<void> => {
        const ranges = await withSpinner(iCardsManagerRemote.getCardRanges1(this.userStore.session, toJS(this.editingPresentCardCategory), 0, 30))
        this.editingPresentCardRanges = ranges
    }

    @action
    activateSelectedCards = async (): Promise<void> => {
        const cardNumbers = this.selectedCards.filter(item => item.status === Create).map(item => item.number)
        await withSpinner(iCardsManagerRemote.activatePresentCardList(this.userStore.session, cardNumbers, toJS(this.userStore.userInfo)))
    }

    @action
    setSelectedCards = (cards: CardVO[]): void => {
        this.selectedCards = cards
    }

    isRangeAlreadyUsed = (start: string, finish: string): boolean =>
        this.editingPresentCardRanges?.some(range =>
            (start.length === range.start.length) &&
            ((start >= range.start && start <= range.finish) || (finish >= range.start && finish <= range.finish)))

    @action
    addCardsRange = async ({ rangeStart, rangeEnd }: { rangeStart: string, rangeEnd: string }): Promise<void> => {
        // Добавляем нули, если необходимо
        let start = rangeStart
        let finish = rangeEnd
        const startLength = rangeStart.length
        const endLength = rangeEnd.length

        if (startLength > endLength) {
            finish = addLeadingZero(finish, startLength)
        }
        if (startLength < endLength) {
            start = addLeadingZero(start, endLength)
        }

        // Проверяем среди своих диапазонов повторы
        if (this.isRangeAlreadyUsed(start, finish)) {
            this.rangeError = true
            return
        }

        // Если новая карта - её сначала надо сохранить
        if (this.editingPresentCardCategory.id === null) {
            await this.savePresentCard()
        }

        // Создаем новый диапазон
        const newCardRange: CardRangeVO = createCardRangeVO({
            start,
            finish,
            // @ts-ignore
            count: ['java.math.BigInteger', String(Number(rangeEnd) - Number(rangeStart) + 1)],
            cardType: toJS(this.editingPresentCardCategory),
        })

        await withSpinner(iCardsManagerRemote.addCardRange1(
            this.userStore.session,
            newCardRange,
            {
                customCommonResponseMiddlewares: [
                    response => {
                        let error: { code?: number, message?: string } = response.data.error
                        if (error && error.code === INTERNAL_ERROR && error.message === INTERSECTION_RANGES) {
                            this.rangeError = true
                        }
                    },
                ]
            }
        ))
        this.fetchCardRanges()
    }

    @action
    deleteCardRange = async (range: CardRangeVO): Promise<void> => {
        await withSpinner(iCardsManagerRemote.removeCardRange1(this.userStore.session, toJS(range)))
        this.fetchCardRanges()
    }

    @action
    setLastUsedPath = (path: string): void => {
        this.lastUsedPath = path
    }

    @action
    setEditingCardCategory = (card: PresentCardsVO): void => {
        this.reset()
        this.editingPresentCardCategory = card
        this.originalPresentCardCategory = cloneDeep(card)
        this.fetchCardRanges()
    }

    @action
    resetRangeError = (): void => {
        this.rangeError = false
    }

    @action
    modifyEditingCard = (modification: Partial<PresentCardsVO>): void => {
        this.editingPresentCardCategory = { ...this.editingPresentCardCategory, ...modification }
    }

    closeEditingCard = (): void => {
        if (this.lastUsedPath) {
            goTo(this.lastUsedPath)
        } else {
            goTo(`${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORIES}`)
        }

        this.editingPresentCardCategory = null
        this.originalPresentCardCategory = null
    }

    @action
    savePresentCard = async (): Promise<void> => {
        let result: PresentCardsVO
        result = await withSpinner(
            iCardsManagerRemote.storePresentCards(this.userStore.session, toJS(this.editingPresentCardCategory))
        )
        if (this.editingPresentCardCategory.id === null) {
            goTo(`${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORIES}${PRESENT_CARDS_CATEGORY_EDIT}/${result.id}`)
        }
        runInAction(() => {
            this.editingPresentCardCategory = result
            this.originalPresentCardCategory = cloneDeep(result)
        })
    }

    checkReplacingCard = async (cardNumber: string): Promise<CardVO> => {
        if (!cardNumber) return

        const result = await withSpinner(iCardsManagerRemote.getCardsByFilter2(
            this.userStore.session,
            createCardsFilterVO({
                number: cardNumber,
            }),
            0,
            100,
        ))

        if (result?.length > 0) {
            runInAction(() => {
                this.newCard = result[0]
            })
        }

        return result?.[0]
    }

    @action
    removeNewCard = (): void => {
        this.newCard = null
    }

    replacePresentCard = async (cause: string = '', comment: string = ''): Promise<void> => {
        let newCard = toJS(this.newCard)

        if (!newCard.status) newCard.status = 'Active'

        const card = await withSpinner(iCardsManagerRemote.changePresentCard(
            this.userStore.session,
            toJS(this.editingPresentCard),
            newCard,
            this.userStore.userInfo,
            cause ? cause as CardBlockingReason : 'ANOTHER',
            comment,
        ))

        this.appStore.showSnackbar({
            message: t('presentCards.replaceDone', {
                category: card.cardTypeVO.name,
                oldNumber: this.editingPresentCard.number,
                newNumber: newCard.number,
            }),
            variant: 'success',
            duration: SNACKBAR_EXTENDED_DURATION
        })

        this.openPresentCard(this.editingPresentCard.number)

        runInAction(() => {
            this.newCard = null
        })
    }

    blockPresentCard = async (cause: CardBlockingReason, comment: string): Promise<void> => {
        await withSpinner(iCardsManagerRemote.blockCard1(
            this.userStore.session,
            toJS(this.editingPresentCard),
            cause,
            comment,
            this.userStore.userInfo,
        ))

        this.appStore.showSnackbar({
            message: t('presentCards.blockDone', {
                category: this.editingPresentCard.cardTypeVO.name,
                number: this.editingPresentCard.number,
            }),
            variant: 'error',
            duration: SNACKBAR_EXTENDED_DURATION,
        })

        this.openPresentCard(this.editingPresentCard.number)

        runInAction(() => {
            this.newCard = null
        })
    }

    @action
    resetSearchData = (): void => {
        this.lastUsedPath = ''
        this.editingPresentCardCategory = null
        this.originalPresentCardCategory = null
    }

    @action
    reset = (): void => {
        this.lastUsedPath = ''
        this.presentCards = undefined
        this.presentCardsStatistics = []
        this.cards = undefined
        this.selectedCards = []
        this.searchCategoryName = ''
        this.editingPresentCardCategory = undefined
        this.editingPresentCard = undefined
        this.editingPresentCardActions = []
        this.editingPresentCardRanges = []
        this.rangeError = false
        this.newCard = undefined
    }

}
