import { XMLTagTextBlock } from './xml-tag-text-block'
import { ALL_FORMULA_TYPES, FormulaType } from './xml-formula-type'
import { XmlFormulaProperty } from './xml-formula-property'

export interface XMLTagFormula extends XMLTagTextBlock {
    uid?: string
    type?: FormulaType
    properties?: XmlFormulaProperty[]
    children?: XMLTagFormula[]
}

export function isFormula(item: any): item is XMLTagFormula {
    if (!item) return false
    if ('type' in item && ALL_FORMULA_TYPES.includes(item.type)) return true
    return false
}
