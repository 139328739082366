import {RegionVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import {getTopologyNodeName, isSameNodes, isShopInsideFormats} from '../../core/topology/topology-util'
import {FormatVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import {CityVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import {TreeItem} from '../../../components/tree-view/tree-view'
import {ShopVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import {TopologyBundleVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-bundle-vo'
import {TopologyNodeVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-node-vo'
import {
    CITY,
    EnTopologyLevel,
    REGION,
    SHOP
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/en-topology-level'

export class TopologyMap {

    bundle: TopologyBundleVO
    formats: FormatVO[]
    regions: RegionVO[]
    cities: CityVO[]
    shops: ShopVO[]
    shopByNumber: Map<number, ShopVO> = new Map<number, ShopVO>()
    tree: TreeItem[]

    static fromTopologyBundle(bundle: TopologyBundleVO): TopologyMap {
        let map: TopologyMap = new TopologyMap()
        map.bundle = bundle
        map.formats = bundle.formats
        map.regions = bundle.regions
        map.cities = bundle.cities
        map.shops = bundle.shops

        // TODO: Временное решение (с сервера приходят некорректные данные)
        // Пересчет некорректных значений поля shopsCount в: map.cities[cityId], map.cities[cityId].region,
        // а также в map.shops[shopId].city, map.shops[shopId].city.region
        // Сначала правим map.cities, данные по регионам берем из map.regions, там данные корректные
        map.cities?.forEach(city => {
            city.shopsCount = map.shops?.filter(shop => shop.city.id === city.id).length
            city.region = map.regions?.find(region => region.id === city.region.id)
        })
        // Затем правим map.shops
        map.shops?.forEach(shop => {
            shop.city = map.cities?.find(city => city.id === shop.city.id)
        })

        map.shops?.forEach(shop => {
            map.shopByNumber.set(shop.number, shop)
        })

        map.tree = map.toTree()

        return map
    }

    toTree = (formats: FormatVO[] = null, only24h: boolean = false): TreeItem[] => {
        let result: TreeItem[] = []
        let regions: RegionVO[] = this.getFilteredRegions(formats, only24h)

        let addedRegions: { [key: number]: TreeItem } = {}

        regions?.forEach(region => {
            let cities: CityVO[] = this.getFilteredCities(region, formats, only24h)

            cities?.forEach(city => {
                let shops: ShopVO[] = this.getShopsInCity(city, formats, only24h)
                if (shops.length > 0) {
                    let regionItem: TreeItem = null
                    if (!addedRegions[city.region.id]) {
                        regionItem = {
                            item: region,
                            key: region.id.toString(),
                            labelFunction: getTopologyNodeName,
                            children: []
                        }
                        addedRegions[city.region.id] = regionItem
                        result.push(regionItem)
                    } else {
                        regionItem = addedRegions[city.region.id]
                    }

                    regionItem.children.push({
                        item: city,
                        key: city.id.toString(),
                        labelFunction: getTopologyNodeName,
                        children: shops?.map(shop => {
                            return {
                                item: shop,
                                key: shop.id.toString(),
                                labelFunction: this.getShopLabel,
                            }
                        })
                    })
                }
            })
        })

        return result
    }

    getShopLabel = (shop: ShopVO): string => {
        return shop.number + ' ' + getTopologyNodeName(shop)
    }

    getFilteredRegions = (formats: FormatVO[] = null, only24h: boolean = false): RegionVO[] => {
        let result: RegionVO[] = []
        let processedRegions: { [key: number]: RegionVO } = {}

        for (let shop of this.shops) {
            if ((formats && isShopInsideFormats(shop, formats)) && (!only24h || shop.twentyFourHour)) {
                let region = shop.city.region
                if (!processedRegions[region.id]) {
                    result.push(region)
                    processedRegions[region.id] = region
                }
            }
        }

        return result
    }

    getFilteredCities = (region: RegionVO, formats: FormatVO[] = null, only24h: boolean = false): CityVO[] => {
        let result: CityVO[] = []
        let citiesInRegion: CityVO[] = this.getCitiesInRegion(region)

        for (let city of citiesInRegion) {
            let shopsInCity: ShopVO[] = this.getShopsInCity(city, formats, only24h)
            if (shopsInCity.length > 0) {
                result.push(city)
            }
        }

        return result
    }

    getShopsInCity = (city: CityVO, formats: FormatVO[] = null, only24h: boolean = false): ShopVO[] => {
        if (!formats) formats = []

        let shopsInCity: ShopVO[] = []
        let processedShops: { [key: number]: ShopVO } = {}

        for (let shop of this.shops) {
            if (isSameNodes(shop.city, city)) {
                if (only24h && !shop.twentyFourHour) {
                    continue
                }
                if (formats.length && !isShopInsideFormats(shop, formats)) {
                    continue
                }
                if (!processedShops[shop.id]) {
                    shopsInCity.push(shop)
                    processedShops[shop.id] = shop
                }
            }
        }

        return shopsInCity
    }

    getShopsInRegion = (region: RegionVO, formats: FormatVO[] = null, only24h: boolean = false): ShopVO[] => {
        if (!formats) formats = []
        let shopsInRegion: ShopVO[] = []
        let processedShops: { [key: number]: ShopVO } = {}
        let cities: CityVO[] = this.getCitiesInRegion(region)

        for (let city of cities) {

            let shopsInCity: ShopVO[] = this.getShopsInCity(city, formats, only24h)

            for (let shop of shopsInCity) {
                if (!processedShops[shop.id]) {
                    shopsInRegion.push(shop)
                    processedShops[shop.id] = shop
                }
            }
        }
        return shopsInRegion
    }

    getCitiesInRegion = (region: RegionVO): CityVO[] => {
        let citiesInRegion: CityVO[] = []
        let processedCities: { [key: number]: CityVO } = {}

        for (let city of this.cities) {
            if (isSameNodes(city.region, region)) {
                if (!processedCities[city.id]) {
                    citiesInRegion.push(city)
                    processedCities[city.id] = city
                }
            }
        }
        return citiesInRegion
    }

    getRegionById = (id: number): RegionVO => {
        if (!this.regions) return null
        return this.regions?.find(region => region.id === id)
    }

    getCityById = (id: number): CityVO => {
        if (!this.cities) return null
        return this.cities?.find(city => city.id === id)
    }

    getShopById = (id: number): ShopVO => {
        if (!this.shops) return null
        return this.shops?.find(shop => shop.id === id)
    }

    getFormatById = (id: number): FormatVO => {
        if (!this.formats) return null
        return this.formats?.find(format => format.id === id)
    }

    getNode = (level: EnTopologyLevel, id: number): TopologyNodeVO => {
        let map: {[key: string]: TopologyNodeVO[]} = {}
        map[REGION] = this.regions
        map[CITY] = this.cities
        map[SHOP] = this.shops

        let collection: TopologyNodeVO[] = map[level]
        if (!collection) return null

        return collection?.find(node => node.id === id)
    }
}
