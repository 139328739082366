import { TypeOfComparison } from './type-of-comparison'
import { IntervalType } from './interval-type'
import { TimeRange } from './time-range'
import { PeriodType } from './period-type'
import { TimestampPeriod } from './timestamp-period'
import { InternalCardsVO } from './internal-cards-vo'

export interface ConditionChangeVO {
    '@class': 'ru.crystals.cards.internalcards.ConditionChangeVO'
    id?: number
    amount?: number
    typeOfComparison?: TypeOfComparison
    oneCheck?: boolean
    month?: number
    year?: number
    intervalType?: IntervalType
    worksPeriod?: number
    timeRange?: TimeRange
    worksPeriodType?: PeriodType
    workPeriodRange?: TimestampPeriod
    changeCard?: boolean
    operatorMessage?: string
    cardCategory?: InternalCardsVO
    newCardCategory?: InternalCardsVO
}

export const createConditionChangeVO = (initialization: Partial<ConditionChangeVO>): ConditionChangeVO => {
    if (!initialization) return null
    
    let result: ConditionChangeVO = {
        id: initialization.id,
        amount: initialization.amount,
        typeOfComparison: initialization.typeOfComparison,
        oneCheck: initialization.oneCheck,
        month: initialization.month,
        year: initialization.year,
        intervalType: initialization.intervalType,
        worksPeriod: initialization.worksPeriod,
        timeRange: initialization.timeRange,
        worksPeriodType: initialization.worksPeriodType,
        workPeriodRange: initialization.workPeriodRange,
        changeCard: initialization.changeCard,
        operatorMessage: initialization.operatorMessage,
        cardCategory: initialization.cardCategory,
        newCardCategory: initialization.newCardCategory,
        '@class': 'ru.crystals.cards.internalcards.ConditionChangeVO',
    }
    
    return result
}
