import { ValidatorType } from './validator-type'
import { ValidatorState } from './validator-state'
import { ValidationRule } from './validation-rule'

export interface ErpiValidatorVO {
    '@class': 'ru.crystals.ERPIntegration.validation.vo.ErpiValidatorVO'
    type?: ValidatorType
    state?: ValidatorState
    rules?: ValidationRule[]
}

export const createErpiValidatorVO = (initialization: Partial<ErpiValidatorVO>): ErpiValidatorVO => {
    if (!initialization) return null
    
    let result: ErpiValidatorVO = {
        type: initialization.type,
        state: initialization.state,
        rules: initialization.rules,
        '@class': 'ru.crystals.ERPIntegration.validation.vo.ErpiValidatorVO',
    }
    
    return result
}
