export const DEV = 'development'
export const PROD = 'production'
export const TEST = 'test'

export class Config {
    environment: string = DEV
    serverHost: string = '127.0.0.1'
    serverPort: string = '8090'
    externalModules: Array<{ serverURL?: string }> = []
    /**
     * Если приложение доступно по относительному пути, то этот путь указывается в момент сборки c помощью этого параметра.
     * Например, http://{ip}:{port}/{base-url}/app
     * @type {string}
     */
    baseURL: string = ''

    get baseServerAddress(): string {
        return `${window.location.protocol}//${this.serverHost}:${this.serverPort}`
    }

    get serverAddress(): string {
        return `${this.baseServerAddress}/SetXRMI/json-rpc/`
    }

    get reportsAddress(): string {
        return `${this.baseServerAddress}/SetXRMI/ReportsProcessorServlet`
    }

    get uploadAddress(): string {
        return `${this.baseServerAddress}/SetXRMI/upload`
    }

    get webRoot(): string {
        return window.location.origin
    }

    get isProduction(): boolean {
        return this.environment === PROD
    }
}

export const config = new Config()
