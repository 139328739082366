import { action, observable, runInAction, computed } from 'mobx'
import { getStore } from '../stores-repository'
import { USER_STORE } from '../stores'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import { InternalCardsVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/internal-cards-vo'

export class CardsCategoriesStore {
    @observable
    filterString: string = ''

    @observable
    categories: InternalCardsVO[]

    private userStore: UserStore = getStore(USER_STORE)

    @computed
    get filteredCategories(): InternalCardsVO[] {
        if (!this.categories) {
            return null
        }

        const filter: string = this.filterString.toLowerCase().trim()

        return this.categories.filter(category => {
            return category.name.toLowerCase().trim().includes(filter)
        })
    }

    fetchCategories = async (): Promise<void> => {
        const result = await withSpinner(iCardsManagerRemote.getInternalCards(
            this.userStore.session,
            0,
            10000
        )) || []

        runInAction(() => {
            this.categories = result.sort((a: InternalCardsVO, b: InternalCardsVO) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })
        })
    }

    @action
    setFilterString = (value: string) => {
        this.filterString = value
    }

    @action
    reset = (): void => {
        this.categories = null
        this.filterString = ''
    }
}
