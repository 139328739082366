import { createCouponsVO } from './coupons-vo'
import { createCardTypeVO } from './card-type-vo'
import { createBaseVO } from './base-vo'
import { CouponsVO } from './coupons-vo'

export interface UniqueCouponVO extends CouponsVO {
    '@class': 'ru.crystals.cards.coupons.vo.UniqueCouponVO'
    creationDate?: Date
}

export const createUniqueCouponVO = (initialization: Partial<UniqueCouponVO>): UniqueCouponVO => {
    if (!initialization) return null
    
    let result: UniqueCouponVO = {
        ...createCouponsVO(initialization),        ...createCardTypeVO(initialization),        ...createBaseVO(initialization),
        creationDate: initialization.creationDate,
        '@class': 'ru.crystals.cards.coupons.vo.UniqueCouponVO',
    }
    
    return result
}
