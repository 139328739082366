import { Check } from './set-retail10-commons/set-oper-day-api/check'
import { SearchArgumentVO } from './set-retail10-commons/data-structs-module/search-argument-vo'
import { SearchResultVO } from './set-retail10-commons/set-oper-day-api/search-result-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class CheckManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-OperDay/SET/CheckManager!ru.crystals.operday.gui.flexendpoints.check.CheckManagerLocal'
    }

    findCheck = (sessionId: string, check: Check, options?: RequestOptions): Promise<Check> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findCheck',
                params: [sessionId, check]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    findChecks1 = (sessionId: string, checkQuery: SearchArgumentVO[], options?: RequestOptions): Promise<Check[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findChecks',
                params: [sessionId, checkQuery]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    findChecks2 = (sessionId: string, checkQuery: SearchArgumentVO[], queryLimit: number, options?: RequestOptions): Promise<SearchResultVO<Check>> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findChecks',
                params: [sessionId, checkQuery, queryLimit]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    findChecksByBarcode = (sessionId: string, checkQuery: SearchArgumentVO[], options?: RequestOptions): Promise<SearchResultVO<Check>> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findChecksByBarcode',
                params: [sessionId, checkQuery]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    findTransactions = (sessionId: string, checkQuery: SearchArgumentVO[], queryLimit: number, options?: RequestOptions): Promise<SearchResultVO<Check>> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findTransactions',
                params: [sessionId, checkQuery, queryLimit]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const checkManagerLocal: CheckManagerLocal = new CheckManagerLocal()
