import { createFormatsAbstractVO } from './formats-abstract-vo'
import { FormatsAbstractVO } from './formats-abstract-vo'
import { ProductListConditionVO } from './product-list-condition-vo'

export interface FormatsProductListVO extends FormatsAbstractVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsProductListVO'
    uuid?: string
    priority?: number
    productsCount?: number
    conditions?: ProductListConditionVO[]
    editable?: boolean
}

export const createFormatsProductListVO = (initialization: Partial<FormatsProductListVO>): FormatsProductListVO => {
    if (!initialization) return null
    
    let result: FormatsProductListVO = {
        ...createFormatsAbstractVO(initialization),
        uuid: initialization.uuid,
        priority: initialization.priority,
        productsCount: initialization.productsCount,
        conditions: initialization.conditions,
        editable: initialization.editable,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsProductListVO',
    }
    
    return result
}
