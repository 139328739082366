import { ShortProductVO_SF } from './set-retail10-commons/data-structs-module/short-product-vo-sf'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class ScalesProductLoadingsManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Scales-GUI/SET/ScalesProductLoadingsManager/GUI!ru.crystals.scales.gui.ScalesProductLoadingsManagerLocal'
    }

    addToBannedListForTemplate = (newBannedProductMarkings: string[], templateGUID: number, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'addToBannedListForTemplate',
                params: [newBannedProductMarkings, templateGUID]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    findEnabledProductsForTemplate = (templateGUID: number, arg: string, maxCount: number, options?: RequestOptions): Promise<ShortProductVO_SF[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findEnabledProductsForTemplate',
                params: [templateGUID, arg, maxCount]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getBannedProductsForTemplate = (templateGUID: number, options?: RequestOptions): Promise<ShortProductVO_SF[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getBannedProductsForTemplate',
                params: [templateGUID]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    removeFromBannedListForTemplate = (bannedProducts: string[], templateGUID: number, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'removeFromBannedListForTemplate',
                params: [bannedProducts, templateGUID]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const scalesProductLoadingsManagerLocal: ScalesProductLoadingsManagerLocal = new ScalesProductLoadingsManagerLocal()
