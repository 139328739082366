export interface GetCheckDiscountsIn {
    '@class': 'ru.crystalservice.setv6.discounts.common.vo.GetCheckDiscountsIn'
    shop?: number
    shift?: number
    check?: number
    cash?: number
    saleTime?: Date
}

export const createGetCheckDiscountsIn = (initialization: Partial<GetCheckDiscountsIn>): GetCheckDiscountsIn => {
    if (!initialization) return null
    
    let result: GetCheckDiscountsIn = {
        shop: initialization.shop,
        shift: initialization.shift,
        check: initialization.check,
        cash: initialization.cash,
        saleTime: initialization.saleTime,
        '@class': 'ru.crystalservice.setv6.discounts.common.vo.GetCheckDiscountsIn',
    }
    
    return result
}
