import { EquipmentTypeVO } from './equipment-type-vo'
import { EquipmentSettingVO } from './equipment-setting-vo'

export interface EquipmentModelVO {
    '@class': 'ru.crystals.setretailx.equipment.EquipmentModelVO'
    id?: number
    name?: string
    equipmentType?: EquipmentTypeVO
    hasSettings?: boolean
    packageName?: string
    settings?: EquipmentSettingVO[]
    uuid?: string
    localizedName?: string
}

export const createEquipmentModelVO = (initialization: Partial<EquipmentModelVO>): EquipmentModelVO => {
    if (!initialization) return null
    
    let result: EquipmentModelVO = {
        id: initialization.id,
        name: initialization.name,
        equipmentType: initialization.equipmentType,
        hasSettings: initialization.hasSettings,
        packageName: initialization.packageName,
        settings: initialization.settings,
        uuid: initialization.uuid,
        localizedName: initialization.localizedName,
        '@class': 'ru.crystals.setretailx.equipment.EquipmentModelVO',
    }
    
    return result
}
