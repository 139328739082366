import { t } from 'i18next'
import {
    ShiftOpenDocumentVO, createShiftOpenDocumentVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/shift-open-document-vo'
import {
    ShiftCloseDocumentVO, createShiftCloseDocumentVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/shift-close-document-vo'

export interface ShiftOpenDocumentWithLabel extends ShiftOpenDocumentVO {
    label: string
}

export interface ShiftCloseDocumentWithLabel extends ShiftCloseDocumentVO {
    label: string
}

// захардкоженные documentId документов открытия
export function getAvailableOpenDocuments(): ShiftOpenDocumentWithLabel[] {
    return [
        {
            ...createShiftOpenDocumentVO({
                documentId: 1,
                id: null
            }),
            label: t('cashTemplates.openClose.xReport'),
        },
        {
            ...createShiftOpenDocumentVO({
                documentId: 2,
                id: null
            }),
            label: t('cashTemplates.openClose.moneyEntry'),
        },
        {
            ...createShiftOpenDocumentVO({
                documentId: 7,
                id: null,
            }),
            label: t('cashTemplates.openClose.changedPrice')
        }
    ]
}

export const getZReport = (): ShiftCloseDocumentWithLabel => ({
    ...createShiftCloseDocumentVO({
        documentId: 4,
        id: null
    }),
    label: t('cashTemplates.openClose.zReport'),
})

// захардкоженные documentId документов закрытия
export function getAvailableCloseDocuments(): ShiftCloseDocumentWithLabel[] {
    return [
        {
            ...createShiftCloseDocumentVO({
                documentId: 1,
                id: null
            }),
            label: t('cashTemplates.openClose.xReport'),

        },
        {
            ...createShiftCloseDocumentVO({
                documentId: 3,
                id: null
            }),
            label: t('cashTemplates.openClose.moneyRemoval'),

        },
        getZReport(),
        {
            ...createShiftCloseDocumentVO({
                documentId: 5,
                id: null
            }),
            label: t('cashTemplates.openClose.sectionReport'),

        },
        {
            ...createShiftCloseDocumentVO({
                documentId: 6,
                id: null
            }),
            label: t('cashTemplates.openClose.controlTape'),

        }
    ]
}
