import { observable, action, runInAction, toJS, computed } from 'mobx'
import { PRODUCTS, SALES_POLICY, SEARCH } from '../../core/app-routes'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import { ACTIVE } from '../../core/products/product-statuses'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import {
    APP_BAR_STORE,
    NAVIGATION_MENU_STORE,
    USER_STORE,
    PRODUCT_DETAILS_STORE,
    PRODUCT_DETAILS_ACTIONS_STORE, PRODUCT_RESTRICTIONS_STORE, PRODUCT_SALES_POLICY_STORE, APP_STORE
} from '../stores'
import { withSpinner } from '../with-spinner'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { NavigationMenuStore } from '../navigation-menu-store'
import { AppBarStore, MENU } from '../app-bar-store'
import { t } from 'i18next'
import { ProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { scalesManagerLocal2 } from '../../../protocol/set10/scales-manager-local2'
import { BarCodeVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/bar-code-vo'
import { BindingInfoVO } from '../../../protocol/set10/set-retail10-commons/set-scales-commons/binding-info-vo'
import { SpiritsBottleVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/spirits-bottle-vo'
import { SPIRIT, WEIGHT, WEIGHT_PIECE } from '../../core/products/product-types'
import { fromClientToServerTime } from '../../utils/app-util'
import { SimpleWholesaleLevel } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-wholesale-level'
import { getProductDetailsTabList } from '../../pages/products/product-details/product-details'
import { config } from '../../config/config'
import { AppStore } from '../app-store'

export const SECTION_URL = `${PRODUCTS}${SEARCH}`

export class ProductDetailsStore {
    @observable
    productDetails: ProductVO_SF = null

    @observable
    productCode: string = null

    @observable
    detailType: string = SALES_POLICY

    @observable
    productName: string = null

    @observable
    productBarCode: string = null

    @observable
    productBindings: BindingInfoVO[] = []

    @observable
    alcocodes: string[] = []

    @observable
    alcoholKit: SpiritsBottleVO[] = null

    @observable
    wholesaleLevels: SimpleWholesaleLevel[] = null

    @computed
    get shortCode(): string {
        return this.productDetails?.barcodes.find((barcode: BarCodeVO) => barcode.type === 'SHORT_TYPE')?.value || ''
    }

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    fetchDetails = async (): Promise<void> => {
        const productDetails = await withSpinner(iProductsManagerLocal.getProductDetails1(
            this.userStore.session,
            this.productCode,
            ACTIVE
        ))

        const wholesaleLevels = productDetails && await iProductsManagerLocal.getWholesaleLevelsForProduct(
            this.productCode,
            fromClientToServerTime(new Date()),
            ACTIVE
        )

        // Получаем дополнительные данные, исходя из типа продукта
        if (productDetails) {
            switch (productDetails.productType) {
                case SPIRIT:
                    await this.fetchAlcoholKit()
                    await this.fetchAlcoCodes()
                    break
                case WEIGHT:
                case WEIGHT_PIECE:
                    await this.fetchProductBindings()
                    break
            }
        }

        const availableTabs = getProductDetailsTabList(productDetails, this.alcoholKit, wholesaleLevels, this.appStore.isCentrum)
            .filter(i => !i.hidden)
        const foundTab = availableTabs.find(i => i.urlParam === this.detailType)
        let newDetailType = foundTab ? foundTab.urlParam : null
        if (!newDetailType) {
            newDetailType = availableTabs.length > 0 ? availableTabs[0].urlParam : null
        }
        const typeChanged = this.detailType !== newDetailType

        runInAction(() => {
            this.detailType = newDetailType
            this.productDetails = {
                recyclingCode: undefined, // может не приходить с сервера и тогда не будет observable
                ...productDetails
            }
            this.productName = productDetails?.name
            this.productBarCode = productDetails?.barcode
            this.wholesaleLevels = wholesaleLevels
        })

        if (typeChanged) {
            this.goToTarget()
        }
        this.updateTitle()
    }

    fetchAlcoCodes = async (): Promise<void> => {
        const alcocodes = await productManagerFinderLocal.getAlcoCodes(this.productCode)
        runInAction(() => {
            this.alcocodes = alcocodes
        })
    }

    fetchProductBindings = async (): Promise<void> => {
        const productBindings = await scalesManagerLocal2.getBindingInfoByProduct(
            this.userStore.session,
            this.productCode
        )
        runInAction(() => {
            this.productBindings = productBindings
        })
    }

    fetchAlcoholKit = async (): Promise<void> => {
        const alcoholKit = await productManagerFinderLocal.getSpiritSet(this.productCode)
        if (alcoholKit && !alcoholKit.length) return

        runInAction(() => {
            this.alcoholKit = alcoholKit
        })
    }

    @action
    setProduct = (code: string, name: string = null, barCode: string = null): void => {
        this.productCode = code
        this.productName = name
        this.productBarCode = barCode
        this.fetchDetails()
    }

    @action
    updateTitle = (): void => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
        appBarStore.updateState({
            title: `${t('productDetails.commonTitle')}${this.productName ? ` - ${this.productName}` : ''}`,
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    }

    @action
    editProductDetails = (changes: Partial<ProductVO_SF>): void => {
        Object.keys(changes).forEach(key => {
            this.productDetails[key] = changes[key]
        })
    }

    @action
    deleteBarCode = async (deletedBarCode: BarCodeVO): Promise<void> => {
        await iProductsManagerLocal.deleteBarCode(
            this.userStore.session,
            this.productCode,
            deletedBarCode.value
        )
        const barcodes = this.productDetails.barcodes.filter(
            barCode => barCode.value !== deletedBarCode.value
        )
        this.editProductDetails({ barcodes })
    }

    updateProductProducer = async (): Promise<void> => {
        let result = await iProductsManagerLocal.updateProductProducer(toJS(this.productDetails))

        runInAction(() => {
            const resultId = result.id

            // Обновляем active статус
            result.active = true
            this.productDetails.goodsCompositionList.forEach(item => item.active = false)

            // Ищем индекс элемента в старом списке. Это будет либо -1 (для нового элемента) либо актуальный айди
            const newCompositionIndex = this.productDetails.goodsCompositionList.findIndex(
                item => item.id === -1 || item.id === resultId
            )
            if (newCompositionIndex !== -1) {
                this.productDetails.goodsCompositionList[newCompositionIndex] = result
            }
            this.productDetails.goodsComposition = result
        })
    }

    updateProductDetails = async (): Promise<void> => {
        await iProductsManagerLocal.saveProductDetails(this.userStore.session, toJS(this.productDetails))
    }

    @action
    goToTarget = (): void => {
        goTo(`${SECTION_URL}/${this.productCode}${this.detailType}`)
    }

    @action
    setDetailType = (type: string): void => {
        this.detailType = type
    }

    goToProduct = (productCode: string): void => {
        goTo(`${PRODUCTS}${SEARCH}/${productCode}${SALES_POLICY}`)
    }

    addShortCode = async (): Promise<void> => {
        await iProductsManagerLocal.addShortBarCode(this.userStore.session, this.productCode)
    }

    deleteShortCode = async (): Promise<void> => {
        await iProductsManagerLocal.deleteBarCode(this.userStore.session, this.productCode, this.shortCode)
    }

    @action
    reset = (): void => {
        this.productDetails = null
        this.productCode = null
        this.productName = null
        this.productBarCode = null
        this.productBindings = []
        this.alcocodes = []
        this.alcoholKit = null
    }
}

export const PRODUCT_DETAILS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${SECTION_URL}`),
    onLeave: newRoute => {
        if (newRoute.search(new RegExp(PRODUCTS)) !== -1) return

        const productStores = [PRODUCT_DETAILS_STORE, PRODUCT_DETAILS_ACTIONS_STORE, PRODUCT_RESTRICTIONS_STORE, PRODUCT_SALES_POLICY_STORE]
        productStores.forEach(store => {
            const storeInstance: any = getStore(store)
            storeInstance.reset()
        })
    }
}
