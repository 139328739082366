export interface NotBindedProductsFilter {
    '@class': 'ru.crystals.setretailx.products.NotBindedProductsFilter'
    productCode?: string
    departId?: number
    productGroupName?: string
}

export const createNotBindedProductsFilter = (initialization: Partial<NotBindedProductsFilter>): NotBindedProductsFilter => {
    if (!initialization) return null
    
    let result: NotBindedProductsFilter = {
        productCode: initialization.productCode,
        departId: initialization.departId,
        productGroupName: initialization.productGroupName,
        '@class': 'ru.crystals.setretailx.products.NotBindedProductsFilter',
    }
    
    return result
}
