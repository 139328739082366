export interface PriceTagActionIdent {
    '@class': 'ru.crystals.setretailx.products.vo.PriceTagActionIdent'
    actionIdent?: string
    id?: number
    identifier?: string
}

export const createPriceTagActionIdent = (initialization: Partial<PriceTagActionIdent>): PriceTagActionIdent => {
    if (!initialization) return null
    
    let result: PriceTagActionIdent = {
        actionIdent: initialization.actionIdent,
        id: initialization.id,
        identifier: initialization.identifier,
        '@class': 'ru.crystals.setretailx.products.vo.PriceTagActionIdent',
    }
    
    return result
}
