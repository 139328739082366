export interface PrintingParamsVO {
    '@class': 'ru.crystals.setretailx.printers.PrintingParamsVO'
    templateId?: string
    amountToPrint?: number
    printerId?: number
}

export const createPrintingParamsVO = (initialization: Partial<PrintingParamsVO>): PrintingParamsVO => {
    if (!initialization) return null
    
    let result: PrintingParamsVO = {
        templateId: initialization.templateId,
        amountToPrint: initialization.amountToPrint,
        printerId: initialization.printerId,
        '@class': 'ru.crystals.setretailx.printers.PrintingParamsVO',
    }
    
    return result
}
