import { createBrowserHistory } from 'history'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { getStore, putStore } from './stores-repository'
import {
    ALL_STORES,
    APP_BAR_STORE,
    APP_STORE,
    CASH_SALES_REPORT_STORE,
    CHECK_PAGE_STORE,
    CHECK_REFUND_PAGE_STORE,
    CHECKS_SEARCH_STORE,
    NON_FISCAL_DOCUMENTS_LIST_STORE,
    NON_FISCAL_DOCUMENT_STORE,
    CITY_STORE,
    DEVICES_STORE,
    DIALOG_STORE,
    FAVORITE_FILTERS_STORE,
    KM_3_REPORT_STORE,
    KM_6_REPORT_STORE,
    LOGIN_STORE,
    NAVIGATION_MENU_STORE,
    NOTIFICATION_STORE,
    OPERDAY_SETTINGS_STORE,
    OPERDAY_VALIDATION_STORE,
    OPERDAY_STORE,
    PERSONNEL_MOTIVATION_STORE,
    REGION_STORE,
    REGIONS_STORE,
    ROLES_STORE,
    ROUTING_STORE,
    SHOP_STORE,
    SHOP_CASHES_STORE,
    SHOP_PRINTERS_STORE,
    SHOP_SCALES_STORE,
    SHOP_PRICE_CHECKERS_STORE,
    SHOPS_SEARCH_STORE,
    SNACKBAR_STORE,
    TRANSACTIONS_SEARCH_STORE,
    UPD_REGISTRY_STORE,
    USER_SETTINGS_STORE,
    USER_STORE,
    USERS_STORE,
    VENDOR_PAGE_STORE,
    CASHIERS_ROLES_STORE,
    PRODUCT_SHELVES_STORE,
    LICENSES_STORE,
    CASHIERS_SEARCH_STORE,
    CASHIER_SETTINGS_STORE,
    SHOP_DEVICES_STORE,
    PRICE_TAG_SETTINGS_STORE,
    PRICE_TAGS_TEMPLATES_STORE,
    PRICE_TAGS_MODULE_SETTINGS_STORE,
    PRICE_TAG_FORMATS_TOPOLOGIES_STORE,
    PRICE_TAGS_ESL_CONFIGURATION_STORE,
    PRICE_TAGS_ESL_COVERAGE_STORE,
    PRICE_TAG_TEMPLATE_BINDING_STORE,
    PRICE_TAG_FORMAT_SETTINGS_STORE,
    PRICE_TAG_FORMAT_MATRICES_STORE,
    NOT_BINDED_TO_PRICE_TAGS_PRODUCTS_STORE,
    PRICE_TAGS_PRODUCTS_LISTS_STORE,
    PRICE_TAG_FORMAT_SIZE_LABELS_STORE,
    PRICE_TAGS_PRINTING_STORE,
    PRICE_TAGS_PRINTING_PREVIEW_STORE,
    PRINT_BY_BINDED_PRICE_TAGS_SELECTION_STORE,
    PRINT_BY_PRICE_TAGS_SELECTION_STORE,
    PRINT_BY_FORMATS_SELECTION_STORE,
    NOT_PRINTED_ACTION_PRICE_TAGS_STORE,
    NOT_PRINTED_REGULAR_PRICE_TAGS_STORE,
    NOT_ACTUAL_ACTION_PRICE_TAGS_STORE,
    NOT_ACTUAL_REGULAR_PRICE_TAGS_STORE,
    CUSTOM_PRINTING_ACTION_PRICE_TAGS_STORE,
    CUSTOM_PRINTING_REGULAR_PRICE_TAGS_STORE,
    CUSTOM_PRINTING_BASKET_STORE,
    PRICE_TAGS_EDITOR_STORE,
    ESL_UPLOAD_ERRORS_STORE,
    DIRECTIVE_PRINTING_TOPOLOGIES_STORE,
    DIRECTIVE_PRINTING_TOPOLOGY_SETTINGS_STORE,
    DIRECTIVE_PRINTING_SCHEDULES_STORE,
    PRINTING_HISTORY_STORE,
    CUSTOM_PRINTING_TSD_STORE,
    CASH_TEMPLATES_STORE,
    CASH_DEVICES_STORE,
    CASH_DEVICE_SETTINGS_STORE,
    PAYMENT_TYPES_STORE,
    PAYMENT_TYPE_SETTINGS_STORE,
    PAYMENT_TYPE_BANKS_STORE,
    BANKS_STORE,
    NOT_LOADED_TO_CASHES_STORE,
    PRODUCT_LISTS_STORE,
    PRODUCT_RESTRICTIONS_STORE,
    CASH_SCHEDULES_STORE,
    PRODUCT_DETAILS_STORE,
    KO_5_REPORT_STORE,
    PRODUCT_SALES_POLICY_STORE,
    PRODUCT_DETAILS_ACTIONS_STORE,
    ACTIONS_SEARCH_STORE,
    ACTION_SETTINGS_STORE,
    SALE_GROUP_EDIT_STORE,
    SEGMENTS_STORE,
    LOYALTY_COUPONS_STORE,
    LOYALTY_COUPONS_INSTANCES_STORE,
    CASH_PRINTER_IMAGE_STORE,
    PRESENT_CARDS_STORE,
    FORM25_REPORT_STORE,
    BONUS_ACCOUNTS_STORE,
    LOYALTY_SETTINGS_STORE,
    CARDS_SEARCH_STORE,
    EXTERNAL_CARDS_STORE,
    OTHER_DEPARTMENTS_STORE,
    PRODUCT_TYPES_STORE,
    CARDS_CATEGORIES_STORE,
    CARDS_CATEGORY_DETAILS_STORE,
    INTEGRATION_IMPORT_EXPORT_STORE,
    INTEGRATION_EXTERNAL_PROCESSINGS_STORE,
    CARDS_SETTINGS_STORE,
    IFRAME_MODULES_STORE,
    CHECK_FORMS_STORE,
    SCALE_TEMPLATES_LIST_STORE,
    SCALES_DEVICES_STORE,
    SCALES_PRODUCT_LISTS_STORE,
    SCALES_PRODUCT_EDIT_STORE,
} from './stores'
import { AppStore } from './app-store'
import { AppBarStore } from './app-bar-store'
import { UserStore } from './user-store'
import { NavigationMenuStore } from './navigation-menu-store'
import { LoginStore } from './login-store'
import { ChecksSearchStore } from './operday/checks-search-store'
import { NonFiscalDocumentsListStore } from './operday/non-fiscal-documents-list-store'
import { NonFiscalDocumentStore } from './operday/non-fiscal-document-store'
import { CheckPageStore } from './operday/check-page-store'
import { CheckRefundStore } from './operday/check-refund-store'
import { OperdayStore } from './operday/operday-store'
import { RolesStore } from './staff/roles-store'
import { UsersStore } from './staff/users-store'
import { UserSettingsStore } from './staff/user-settings-store'
import { DevicesStore } from './devices/devices-store'
import { RegionsStore } from './shops/regions-store'
import { RegionStore } from './shops/region-store'
import { CityStore } from './shops/city-store'
import { SnackbarStore } from './snackbar-store'
import { DialogStore } from './dialog-store'
import { ShopsSearchStore } from './shops/shops-search'
import { KmReportStore } from './operday/km-reports-store'
import { Km3PositionVO } from '../../protocol/set10/set-retail10-commons/set-oper-day-main-cash-api/km3-position-vo'
import { Km6PositionVO } from '../../protocol/set10/set-retail10-commons/set-oper-day-main-cash-api/km6-position-vo'
import { UPDRegistryStore } from './operday/upd-registry-store'
import { OperdaySettingsStore } from './operday/operday-settings-store'
import { OperdayValidationStore } from './operday/operday-validation-store'
import { TransactionsSearchStore } from './operday/transactions-search-store'
import { NotificationStore } from './notification-store'
import { PersonnelMotivationStore } from './operday/personnel-motivation-store'
import { CashSalesReportStore } from './operday/cash-sales-report-store'
import { VendorPageStore } from './vendor-page-store'
import { CashiersRolesStore } from './staff/cashiers-roles-store'
import { ProductShelvesStore } from './shops/product-shelves-store'
import { CashierSettingsStore } from './staff/cashier-settings-store'
import { CashiersSearchStore } from './staff/cashiers-search-store'
import { LicensesStore } from './shops/licenses-store'
import { ShopDevicesStore } from './shops/shop-devices-store'
import { ShopStore } from './shops/shop-settings/shop-store'
import { ShopCashesStore } from './shops/shop-settings/shop-cashes-store'
import { ShopPrintersStore } from './shops/shop-settings/shop-printers-store'
import { ShopScalesStore } from './shops/shop-settings/shop-scales-store'
import { ShopPriceCheckersStore } from './shops/shop-settings/shop-price-checkers-store'
import { getBaseURL } from '../utils/router-util'
import { PriceTagSettingsStore } from './price-tags/price-tag-settings-store'
import { FavoriteFiltersStore } from './operday/favorite-filters-store'
import { PriceTagsTemplatesStore } from './price-tags/price-tags-templates-store'
import { PriceTagsModuleSettingsStore } from './price-tags/price-tags-module-settings-store'
import { PriceTagFormatsTopologiesStore } from './price-tags/price-tag-formats-topologies-store'
import { PriceTagsEslConfigurationStore } from './price-tags/price-tags-esl-configuration-store'
import { PriceTagsEslCoverageStore } from './price-tags/price-tags-esl-coverage-store'
import { PriceTagTemplatesBindingStore } from './price-tags/price-tag-templates-binding-store'
import { PriceTagFormatSettingsStore } from './price-tags/price-tag-format-settings-store'
import { PriceTagFormatMatricesStore } from './price-tags/price-tag-format-matrices-store'
import { NotBindedToPriceTagsProductsStore } from './price-tags/not-binded-to-price-tags-products-store'
import { PriceTagsProductsListsStore } from './price-tags/price-tags-products-lists-store'
import { PriceTagFormatSizeLabelsStore } from './price-tags/price-tag-format-size-labels-store'
import { PriceTagsPrintingStore } from './price-tags/printing/printing-store'
import { PriceTagsPrintingPreviewStore } from './price-tags/printing/printing-preview-store'
import { PrintByBindedPriceTagsSelectionStore } from './price-tags/printing/print-by-binded-price-tags-selection-store'
import { PrintByPriceTagsSelectionStore } from './price-tags/printing/print-by-price-tags-selection-store'
import { PrintByFormatsSelectionStore } from './price-tags/printing/print-by-formats-selection-store'
import { NotPrintedActionPriceTagsStore } from './price-tags/not-printed-action-price-tags-store'
import { NotPrintedRegularPriceTagsStore } from './price-tags/not-printed-regular-price-tags-store'
import { NotActualActionPriceTagsStore } from './price-tags/not-actual-action-price-tags-store'
import { NotActualRegularPriceTagsStore } from './price-tags/not-actual-regular-price-tags-store'
import { CustomPrintingRegularPriceTagsStore } from './price-tags/custom-printing-regular-price-tags-store'
import { CustomPrintingActionPriceTagsStore } from './price-tags/custom-printing-action-price-tags-store'
import { CustomPrintingBasketStore } from './price-tags/custom-printing-basket-store'
import { PriceTagsEditorStore } from './price-tags/price-tags-editor-store'
import { ESLUploadErrorsStore } from './price-tags/esl-upload-errors-store'
import { PrintingHistoryStore } from './price-tags/printing-history-store'
import { TopologiesStore } from './price-tags/directive-printing/topologies-store'
import { TopologySettingsStore } from './price-tags/directive-printing/topology-settings-store'
import { PrintingSchedulesStore } from './price-tags/directive-printing/printing-schedules-store'
import { CustomPrintingTsdStore } from './price-tags/custom-printing-tsd-store'
import { CashTemplatesStore } from './cashes/cash-templates/cash-templates-store'
import { CashDevicesStore } from './cashes/devices/cash-devices-store'
import { CashDeviceSettingsStore } from './cashes/devices/cash-device-settings-store'
import { PaymentTypesStore } from './cashes/payment-types/payment-types-store'
import { PaymentTypeSettingsStore } from './cashes/payment-types/payment-type-settings-store'
import { PaymentTypeBanksStore } from './cashes/payment-types/payment-type-banks-store'
import { BanksStore } from './cashes/banks-store'
import { NotLoadedToCashesStore } from './cashes/not-loaded-to-cashes-store'
import { ProductsListsStore } from './cashes/product-lists-store'
import { CashSchedulesStore } from './cashes/cash-schedules-store'
import { ProductDetailsStore } from './products/product-details-store'
import { ProductRestrictionsStore } from './products/product-restrictions-store'
import { Ko5ReportStore } from './operday/ko5-report-store'
import { DIALOG } from '../../components/simple-dialog/simple-dialog'
import { ProductSalesPolicyStore } from './products/product-sales-policy-store'
import { ProductDetailsActionsStore } from './products/product-details-actions-store'
import { ActionsSearchStore } from './loyalty/actions/actions-search-store'
import { ActionSettingsStore } from './actions/action-settings-store'
import { SaleGroupEditStore } from './loyalty/sale-group-edit-store'
import { SegmentsStore } from './loyalty/segments-store'
import { LoyaltyCouponsStore } from './loyalty/loyalty-coupons-store'
import { LoyaltyCouponsInstancesStore } from './loyalty/loyalty-coupons-instances-store'
import { CashPrinterImageStore } from './loyalty/actions/cash-printer-image-store'
import { PresentCardsStore } from './cards/present-cards-store'
import { BonusAccountsStore } from './cards/bonus-accounts-store'
import { Form25ReportStore } from './operday/form25-report-store'
import { CardsSearchStore } from './cards/cards-search-store'
import { LoyaltySettingsStore } from './loyalty/loyalty-settings-store'
import { ExternalCardsStore } from './cards/external-cards-store'
import { OtherDepartmentsStore } from './other/departments'
import { ProductTypesStore } from './other/product-types-store'
import { CardsCategoriesStore } from './cards/cards-categories-store'
import { CardsCategoryDetailsStore } from './cards/cards-category-details-store'
import { IntegrationImportExportStore } from './integration/import-export-store'
import { IntegrationExternalProcessingsStore } from './integration/external-processings-store'
import { CardsSettingsStore } from './cards/cards-settings-store'
import { IframeModulesStore } from './iframe-modules-store'
import { CheckFormsStore } from './cashes/check-forms-store'
import { ScaleTemplatesListStore } from './scales/scale-templates/scale-templates-list-store'
import { ScalesDevicesStore } from './scales-module/scales-devices-store'
import { ScalesProductListsStore } from './scales-module/scales-product-lists-store'
import { ScalesProductEditStore } from './scales-module/scales-product-edit-store'

const browserHistory = createBrowserHistory({
    basename: getBaseURL(),
    // Кастомизируем диалог (по умолчанию используется нативный window.confirm)
    // Вызов коллбэка done с false отменяет переход на новый урл, с true - продолжает.
    getUserConfirmation: (message, done) => {
        const dialogStore: DialogStore = getStore(DIALOG_STORE)
        dialogStore.showDialog({ mode: DIALOG, message, onYes: () => done(true), onNo: () => done(false) })
    }
})
putStore(ROUTING_STORE, new RouterStore())
export const history = syncHistoryWithStore(browserHistory, getStore(ROUTING_STORE))

putStore(SNACKBAR_STORE, new SnackbarStore())
putStore(DIALOG_STORE, new DialogStore())
putStore(APP_STORE, new AppStore())
putStore(APP_BAR_STORE, new AppBarStore())
putStore(USER_STORE, new UserStore())
putStore(NAVIGATION_MENU_STORE, new NavigationMenuStore())
putStore(LOGIN_STORE, new LoginStore())
putStore(CHECK_PAGE_STORE, new CheckPageStore())
putStore(CHECKS_SEARCH_STORE, new ChecksSearchStore())
putStore(NON_FISCAL_DOCUMENTS_LIST_STORE, new NonFiscalDocumentsListStore())
putStore(NON_FISCAL_DOCUMENT_STORE, new NonFiscalDocumentStore())
putStore(CHECK_REFUND_PAGE_STORE, new CheckRefundStore())
putStore(ROLES_STORE, new RolesStore())
putStore(USERS_STORE, new UsersStore())
putStore(USER_SETTINGS_STORE, new UserSettingsStore())
putStore(DEVICES_STORE, new DevicesStore())
putStore(REGIONS_STORE, new RegionsStore())
putStore(REGION_STORE, new RegionStore())
putStore(CITY_STORE, new CityStore())
putStore(SHOP_STORE, new ShopStore())
putStore(SHOP_CASHES_STORE, new ShopCashesStore())
putStore(SHOP_PRINTERS_STORE, new ShopPrintersStore())
putStore(SHOP_SCALES_STORE, new ShopScalesStore())
putStore(SHOP_PRICE_CHECKERS_STORE, new ShopPriceCheckersStore())
putStore(SHOPS_SEARCH_STORE, new ShopsSearchStore())
putStore(KM_3_REPORT_STORE, new KmReportStore<Km3PositionVO>(3))
putStore(KM_6_REPORT_STORE, new KmReportStore<Km6PositionVO>(6))
putStore(OPERDAY_STORE, new OperdayStore())
putStore(FAVORITE_FILTERS_STORE, new FavoriteFiltersStore())
putStore(UPD_REGISTRY_STORE, new UPDRegistryStore())
putStore(OPERDAY_SETTINGS_STORE, new OperdaySettingsStore())
putStore(OPERDAY_VALIDATION_STORE, new OperdayValidationStore())
putStore(TRANSACTIONS_SEARCH_STORE, new TransactionsSearchStore())
putStore(PERSONNEL_MOTIVATION_STORE, new PersonnelMotivationStore())
putStore(CASH_SALES_REPORT_STORE, new CashSalesReportStore())
putStore(VENDOR_PAGE_STORE, new VendorPageStore())
putStore(CASHIER_SETTINGS_STORE, new CashierSettingsStore())
putStore(CASHIERS_SEARCH_STORE, new CashiersSearchStore())
putStore(CASHIERS_ROLES_STORE, new CashiersRolesStore())
putStore(PRODUCT_SHELVES_STORE, new ProductShelvesStore())
putStore(LICENSES_STORE, new LicensesStore())
putStore(SHOP_DEVICES_STORE, new ShopDevicesStore())
putStore(PRICE_TAGS_TEMPLATES_STORE, new PriceTagsTemplatesStore())
putStore(PRICE_TAGS_EDITOR_STORE, new PriceTagsEditorStore())
putStore(PRICE_TAG_SETTINGS_STORE, new PriceTagSettingsStore())
putStore(PRICE_TAG_TEMPLATE_BINDING_STORE, new PriceTagTemplatesBindingStore())
putStore(PRICE_TAGS_MODULE_SETTINGS_STORE, new PriceTagsModuleSettingsStore())
putStore(PRICE_TAG_FORMATS_TOPOLOGIES_STORE, new PriceTagFormatsTopologiesStore())
putStore(PRICE_TAGS_ESL_CONFIGURATION_STORE, new PriceTagsEslConfigurationStore())
putStore(PRICE_TAGS_ESL_COVERAGE_STORE, new PriceTagsEslCoverageStore())
putStore(PRICE_TAG_FORMAT_SETTINGS_STORE, new PriceTagFormatSettingsStore())
putStore(PRICE_TAG_FORMAT_MATRICES_STORE, new PriceTagFormatMatricesStore())
putStore(NOT_BINDED_TO_PRICE_TAGS_PRODUCTS_STORE, new NotBindedToPriceTagsProductsStore())
putStore(PRICE_TAGS_PRODUCTS_LISTS_STORE, new PriceTagsProductsListsStore())
putStore(PRICE_TAG_FORMAT_SIZE_LABELS_STORE, new PriceTagFormatSizeLabelsStore())
putStore(PRICE_TAGS_PRINTING_STORE, new PriceTagsPrintingStore())
putStore(PRICE_TAGS_PRINTING_PREVIEW_STORE, new PriceTagsPrintingPreviewStore())
putStore(PRINT_BY_BINDED_PRICE_TAGS_SELECTION_STORE, new PrintByBindedPriceTagsSelectionStore())
putStore(PRINT_BY_PRICE_TAGS_SELECTION_STORE, new PrintByPriceTagsSelectionStore())
putStore(PRINT_BY_FORMATS_SELECTION_STORE, new PrintByFormatsSelectionStore())
putStore(NOT_PRINTED_ACTION_PRICE_TAGS_STORE, new NotPrintedActionPriceTagsStore())
putStore(NOT_PRINTED_REGULAR_PRICE_TAGS_STORE, new NotPrintedRegularPriceTagsStore())
putStore(NOT_ACTUAL_ACTION_PRICE_TAGS_STORE, new NotActualActionPriceTagsStore())
putStore(NOT_ACTUAL_REGULAR_PRICE_TAGS_STORE, new NotActualRegularPriceTagsStore())
putStore(CUSTOM_PRINTING_BASKET_STORE, new CustomPrintingBasketStore())
putStore(CUSTOM_PRINTING_REGULAR_PRICE_TAGS_STORE, new CustomPrintingRegularPriceTagsStore())
putStore(CUSTOM_PRINTING_ACTION_PRICE_TAGS_STORE, new CustomPrintingActionPriceTagsStore())
putStore(CUSTOM_PRINTING_TSD_STORE, new CustomPrintingTsdStore())
putStore(ESL_UPLOAD_ERRORS_STORE, new ESLUploadErrorsStore())
putStore(NOTIFICATION_STORE, new NotificationStore())
putStore(DIRECTIVE_PRINTING_TOPOLOGIES_STORE, new TopologiesStore())
putStore(DIRECTIVE_PRINTING_TOPOLOGY_SETTINGS_STORE, new TopologySettingsStore())
putStore(DIRECTIVE_PRINTING_SCHEDULES_STORE, new PrintingSchedulesStore())
putStore(PRINTING_HISTORY_STORE, new PrintingHistoryStore())
putStore(CASH_TEMPLATES_STORE, new CashTemplatesStore())
putStore(CASH_DEVICES_STORE, new CashDevicesStore())
putStore(CASH_DEVICE_SETTINGS_STORE, new CashDeviceSettingsStore())
putStore(PAYMENT_TYPES_STORE, new PaymentTypesStore())
putStore(PAYMENT_TYPE_SETTINGS_STORE, new PaymentTypeSettingsStore())
putStore(PAYMENT_TYPE_BANKS_STORE, new PaymentTypeBanksStore())
putStore(BANKS_STORE, new BanksStore())
putStore(NOT_LOADED_TO_CASHES_STORE, new NotLoadedToCashesStore())
putStore(PRODUCT_LISTS_STORE, new ProductsListsStore())
putStore(CASH_SCHEDULES_STORE, new CashSchedulesStore())
putStore(PRODUCT_DETAILS_STORE, new ProductDetailsStore())
putStore(PRODUCT_RESTRICTIONS_STORE, new ProductRestrictionsStore())
putStore(KO_5_REPORT_STORE, new Ko5ReportStore())
putStore(PRODUCT_SALES_POLICY_STORE, new ProductSalesPolicyStore())
putStore(PRODUCT_DETAILS_ACTIONS_STORE, new ProductDetailsActionsStore())
putStore(ACTIONS_SEARCH_STORE, new ActionsSearchStore())
putStore(ACTION_SETTINGS_STORE, new ActionSettingsStore())
putStore(SALE_GROUP_EDIT_STORE, new SaleGroupEditStore())
putStore(SEGMENTS_STORE, new SegmentsStore())
putStore(LOYALTY_COUPONS_STORE, new LoyaltyCouponsStore())
putStore(LOYALTY_COUPONS_INSTANCES_STORE, new LoyaltyCouponsInstancesStore())
putStore(CASH_PRINTER_IMAGE_STORE, new CashPrinterImageStore())
putStore(PRESENT_CARDS_STORE, new PresentCardsStore())
putStore(BONUS_ACCOUNTS_STORE, new BonusAccountsStore())
putStore(FORM25_REPORT_STORE, new Form25ReportStore())
putStore(LOYALTY_SETTINGS_STORE, new LoyaltySettingsStore())
putStore(CARDS_SEARCH_STORE, new CardsSearchStore())
putStore(EXTERNAL_CARDS_STORE, new ExternalCardsStore())
putStore(OTHER_DEPARTMENTS_STORE, new OtherDepartmentsStore())
putStore(PRODUCT_TYPES_STORE, new ProductTypesStore())
putStore(CARDS_CATEGORIES_STORE, new CardsCategoriesStore())
putStore(CARDS_CATEGORY_DETAILS_STORE, new CardsCategoryDetailsStore())
putStore(INTEGRATION_IMPORT_EXPORT_STORE, new IntegrationImportExportStore())
putStore(INTEGRATION_EXTERNAL_PROCESSINGS_STORE, new IntegrationExternalProcessingsStore())
putStore(CARDS_SETTINGS_STORE, new CardsSettingsStore())
putStore(IFRAME_MODULES_STORE, new IframeModulesStore())
putStore(CHECK_FORMS_STORE, new CheckFormsStore())
putStore(SCALE_TEMPLATES_LIST_STORE, new ScaleTemplatesListStore())
putStore(SCALES_DEVICES_STORE, new ScalesDevicesStore())
putStore(SCALES_PRODUCT_LISTS_STORE, new ScalesProductListsStore())
putStore(SCALES_PRODUCT_EDIT_STORE, new ScalesProductEditStore())

export const createStore = (): { [name: string]: any } => {
    let store = {}
    ALL_STORES.forEach(storeName => {
        store[storeName] = getStore(storeName)
    })
    return store
}
