import React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import { NumberInputWithSpinners } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input-with-spinners'
import { TimePicker } from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/time-picker/time-picker'

import { LOYALTY_SETTINGS_STORE } from '../../../../store/stores'
import { LoyaltySettingsProps } from '../loyalty-settings'

const styles = require('../loyalty-settings.scss')

@inject(LOYALTY_SETTINGS_STORE)
@observer
export class LoyaltySettingsActionTerms extends React.Component<LoyaltySettingsProps> {

    render() {
        const { minutes, days, editMinutes, editDays } = this.props.loyaltySettingsStore

        return (
            <div className={styles.actionTerms}>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.maxTimeAdvActionLoaded')}
                        </span>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <TimePicker
                            id="maxTimeAdvActionLoadedInput"
                            value={minutes || 0}
                            onValueChange={value => editMinutes(value.units)}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.timeStoringAdvActions')}
                        </span>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <NumberInputWithSpinners
                            id="timeStoringAdvActionsInput"
                            value={days || 0}
                            onValueChange={value => editDays(value)}
                            step={1}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
