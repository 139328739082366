import { cloneDeep, debounce, isEmpty, isEqual, isNil } from 'lodash'
import { action, computed, observable, toJS } from 'mobx'
import { INPUT_DELAY } from '../default-timeouts'

export interface AsyncValidationFieldInit<T> {
    /**
     * Значение для инициализации (при создании)
     */
    defaultValue: T
    /**
     * Асинхронный вызов, который вернет текст ошибки для текущего значения (пустую строку, если ошибки нет)
     */
    asyncCheckCallback?: (value: T) => Promise<string>
    /**
     * Является ли поле обязательным, по умолчанию - нет
     */
    required?: boolean
    /**
     * Позволяет вызвать asyncCheckCallback при инициализации (для проверки defaultValue)
     */
    initialCheck?: boolean
    /**
     *
     */
    onValueChange?: (value: T) => void
}

export interface IAsyncValidationField<T> {
    /**
     * Содержит ли поле непустое значение
     */
    containsValue: boolean
    /**
     * Текст ошибки
     */
    errorText: string
    /**
     * Содержит ли поле ошибку
     */
    hasError: boolean
    /**
     * Pending === true во время ожидания результата asyncCheckCallback
     */
    pending: boolean
    /**
     * Является ли поле обязательным, по умолчанию - нет
     */
    required: boolean
    /**
     * Была ли произведена модификация поля с момента инициализации
     */
    touched: boolean
    value: T
    /**
     * Позволяет обновить значение поля извне
     */
    updateValue: (newValue: T) => Promise<void>
}

export class AsyncValidationField<T> implements IAsyncValidationField<T> {
    @observable
    errorText: string
    @observable
    pending: boolean
    @observable
    required: boolean
    @observable
    touched: boolean
    @observable
    modified: boolean
    @observable
    originalValue: T
    @observable
    private _value: T

    /**
     * Перед вызовом _asyncCheckCallback значение _lastCheckId увеличивается на 1.
     * Когда приходит результат вызова, переданное значение сравнивается с текущим.
     * Если значения различаются, значит был сделан ещё один вызов и результаты уже не актуальны.
     */
    private _lastCheckId: number = 0
    private _asyncCheckCallback: (value: T) => Promise<string>
    private _defaultErrorMessage: string = 'Field is not valid'
    private _onValueChange: (value: T) => void

    private _delayedAsyncCheckCallback: (lastCheckId: number, resolve: () => void) => Promise<void> = debounce(
        (lastCheckId: number, resolve: () => void) => {
            return this._asyncCheckCallback(this._value)
                .then((errorText: string) => {
                    if (this._lastCheckId === lastCheckId) {
                        this.errorText = errorText
                        this.pending = false
                    }
                    resolve()
                })
                .catch(error => {
                    if (this._lastCheckId === lastCheckId) {
                        this.errorText = error.message || this._defaultErrorMessage
                        this.pending = false
                    }
                    resolve()
                })
        },
        INPUT_DELAY
    )

    constructor(initialization: AsyncValidationFieldInit<T>) {
        this._value = cloneDeep(toJS(initialization.defaultValue))
        // Сохранить defaultValue для последующего сравнения и вычисления значения modified
        this.originalValue = cloneDeep(toJS(initialization.defaultValue))
        this._asyncCheckCallback = initialization.asyncCheckCallback || (() => Promise.resolve(''))
        this._onValueChange = initialization.onValueChange

        this.required = initialization.required || false
        this.touched = false
        this.modified = false
        this.pending = false
        this.errorText = ''

        if (initialization.initialCheck) {
            this.updateValue(initialization.defaultValue)
        }
    }

    @computed
    get containsValue(): boolean {
        if (isNil(this._value)) return false

        switch (typeof this._value) {
            case 'number':
                return !isNaN(Number(this._value))
            case 'object':
                return !isEmpty(this._value)
            case 'string':
            case 'symbol':
                return String(this._value) !== ''
            default:
                return false
        }
    }

    @computed
    get hasError(): boolean {
        return this.errorText !== ''
    }

    @computed
    get value(): T {
        return this._value
    }

    set value(newValue: T) {
        if (this._onValueChange) this._onValueChange(newValue)

        this._updateValue(newValue, ++this._lastCheckId)
    }

    updateValue(newValue: T): Promise<void> {
        if (this._onValueChange) this._onValueChange(newValue)

        return this._updateValue(newValue, ++this._lastCheckId)
    }

    @action
    private _updateValue(newValue: T, lastCheckId: number): Promise<void> {
        this.pending = true
        this._value = newValue
        this.modified = !isEqual(this._value, this.originalValue)
        this.touched = true

        return new Promise(resolve => this._delayedAsyncCheckCallback(lastCheckId, resolve))
    }
}

export type AsyncFormObject<T> = {
    [K in keyof T]?: AsyncValidationField<T[K]>
}

export type FormObjectValues<T> = {
    [K in keyof T]?: any
}

export interface IAsyncFormValidation<T> {
    form: AsyncFormObject<T>
    formValues: FormObjectValues<T>
    originalFormValues: FormObjectValues<T>
    modified: boolean
    valid: boolean
}

export class AsyncFormValidation<T> implements IAsyncFormValidation<T> {
    @observable
    _form: AsyncFormObject<T>

    constructor(form: AsyncFormObject<T>) {
        this._form = form
    }

    @computed
    get form(): AsyncFormObject<T> {
        return this._form
    }

    @computed
    get valid(): boolean {
        const keys: string[] = Object.keys(this._form)

        return keys.every(key => !this._form[key].hasError && !this._form[key].pending)
            && keys.filter(key => this._form[key].required)
                .every(key => this._form[key].containsValue)
    }

    @computed
    get modified(): boolean {
        const keys: string[] = Object.keys(this._form)

        return keys.some(key => this._form[key].modified)
    }

    @computed
    get originalFormValues(): AsyncFormObject<T> {
        const keys: string[] = Object.keys(this._form)

        return keys.reduce(
            (acc, key) => (
                {
                    ...acc,
                    [key]: this._form[key].originalValue
                }
            ),
            {}
        )
    }

    @computed
    get formValues(): AsyncFormObject<T> {
        const keys: string[] = Object.keys(this._form)

        return keys.reduce(
            (acc, key) => (
                {
                    ...acc,
                    [key]: this._form[key].value
                }
            ),
            {}
        )
    }
}
