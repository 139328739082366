import { AccountOwnerType } from '../data-structs-module/account-owner-type'
import { BonusAccountsType } from './bonus-accounts-type'
import { BonusAccountVO } from './bonus-account-vo'

export interface BonusAccountTypeVO {
    '@class': 'ru.crystals.cards.internalcards.classes.BonusAccountTypeVO'
    bonusAccountTypeId?: number
    bonusAccountsTypeName?: string
    bonusAccountTypeCode?: number
    ownerType?: AccountOwnerType
    accountsType?: BonusAccountsType
    writeOffAllAmountAtOnce?: boolean
    entirePurchase?: boolean
    maxPaymentPercent?: number
    currencyCourse?: number
    bonusCourse?: number
    bonusList?: BonusAccountVO[]
}

export const createBonusAccountTypeVO = (initialization: Partial<BonusAccountTypeVO>): BonusAccountTypeVO => {
    if (!initialization) return null
    
    let result: BonusAccountTypeVO = {
        bonusAccountTypeId: initialization.bonusAccountTypeId,
        bonusAccountsTypeName: initialization.bonusAccountsTypeName,
        bonusAccountTypeCode: initialization.bonusAccountTypeCode,
        ownerType: initialization.ownerType,
        accountsType: initialization.accountsType,
        writeOffAllAmountAtOnce: initialization.writeOffAllAmountAtOnce,
        entirePurchase: initialization.entirePurchase,
        maxPaymentPercent: initialization.maxPaymentPercent,
        currencyCourse: initialization.currencyCourse,
        bonusCourse: initialization.bonusCourse,
        bonusList: initialization.bonusList,
        '@class': 'ru.crystals.cards.internalcards.classes.BonusAccountTypeVO',
    }
    
    return result
}
