import { t } from 'i18next'
import { camelCase } from 'lodash'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Stepper from '@material-ui/core/Stepper'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { NEW } from '../../../core/values'
import { AppStore } from '../../../store/app-store'
import {
    CASHES_STEP,
    INFORMATION_STEP,
    JURISTIC_PERSONS_STEP,
    PRICE_CHECKERS_STEP,
    PRINTERS_STEP,
    SCALES_STEP,
    ShopStore
} from '../../../store/shops/shop-settings/shop-store'
import { CashesStep } from './cashes-step'
import { InformationStep } from './information-step'
import { JuristicPersonsStep } from './juristic-persons-step'
import { PriceCheckersStep } from './price-checkers-step'
import { PrintersStep } from './printers-step'
import { ScalesStep } from './scales-step'
import { md } from '../../../../utils/css/breakpoints'
import { SettingsActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/settings-action-panel'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import {ShopCashesStore} from '../../../store/shops/shop-settings/shop-cashes-store'
import {ShopPrintersStore} from '../../../store/shops/shop-settings/shop-printers-store'
import {ShopScalesStore} from '../../../store/shops/shop-settings/shop-scales-store'
import {ShopPriceCheckersStore} from '../../../store/shops/shop-settings/shop-price-checkers-store'
import {
    APP_STORE, SHOP_STORE, SHOP_CASHES_STORE, SHOP_PRINTERS_STORE, SHOP_SCALES_STORE, SHOP_PRICE_CHECKERS_STORE
} from '../../../store/stores'

const styles = require('./shop-page.scss')

export interface ShopPageURLParams {
    id?: string
}

export interface ShopPageProps extends RouteComponentProps<ShopPageURLParams>, React.HTMLProps<HTMLDivElement> {
    app?: AppStore
    shopStore?: ShopStore
    shopCashesStore?: ShopCashesStore
    shopPrintersStore?: ShopPrintersStore
    shopScalesStore?: ShopScalesStore
    shopPriceCheckersStore?: ShopPriceCheckersStore
}

@inject(APP_STORE)
@inject(SHOP_STORE)
@inject(SHOP_CASHES_STORE)
@inject(SHOP_PRINTERS_STORE)
@inject(SHOP_SCALES_STORE)
@inject(SHOP_PRICE_CHECKERS_STORE)
@observer
export class ShopPage extends React.Component<ShopPageProps> {
    componentDidMount() {
        const id: string = this.props.match.params.id
        let {shop, goToPreviousPage, openShop} = this.props.shopStore
        if (id === NEW) {
            if (!shop || !shop.city || !shop.city.id) {
                goToPreviousPage()
            }
        } else {
            if (!shop) openShop(id)
        }
    }

    componentWillUnmount() {
        this.props.shopStore.reset()
        this.props.shopCashesStore.reset()
        this.props.shopPrintersStore.reset()
        this.props.shopScalesStore.reset()
        this.props.shopPriceCheckersStore.reset()
    }

    renderStepper = (): React.ReactNode => {
        const {
            steps, currentStepIndex, setCurrentStep
        } = this.props.shopStore
        const {screenWidth} = this.props.app

        return (
            <Stepper
                className={styles.stepper}
                activeStep={currentStepIndex}
                nonLinear
                orientation={screenWidth >= md ? 'horizontal' : 'vertical'}
            >
                {steps.map(step => (
                    <Step id={`step${step.name}`} key={step.name} {...step.props}>
                        <StepButton
                            onClick={() => setCurrentStep(step.name)}
                        >
                            {t(`shopPage.${camelCase(step.name)}`)}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        )
    }

    // На Retail: Информация -> Юр.лица -> Кассы -> Весы -> Принтеры -> Прайс-чекеры
    // На Centrum: Информация -> Юр.лица -> Кассы -> Прайс-чекеры
    renderCurrentStepContent = (): React.ReactNode => {
        switch (this.props.shopStore.currentStep) {
            case INFORMATION_STEP:
                return <InformationStep/>
            case JURISTIC_PERSONS_STEP:
                return <JuristicPersonsStep/>
            case CASHES_STEP:
                return <CashesStep/>
            case SCALES_STEP:
                return <ScalesStep/>
            case PRINTERS_STEP:
                return <PrintersStep/>
            case PRICE_CHECKERS_STEP:
                return <PriceCheckersStep/>
            default:
                return null
        }
    }

    renderActionPanel = (): React.ReactNode => {
        const {
            connectedToCentrum, goToPreviousPage, goToPreviousStep, goToNextStep, currentStepIndex, steps,
            firstStep, saveShop, isNewShop, informationStepIsValid, shopInfoValidation
        } = this.props.shopStore

        const lastStep = (currentStepIndex + 1) ===  steps.length

        return (
            <SettingsActionPanel
                saveEnabled={!lastStep && (
                        connectedToCentrum || informationStepIsValid && !shopInfoValidation.inputInProgress
                    )
                }
                onSave={shopInfoValidation.modified ? () => saveShop(isNewShop) : goToNextStep}
                onCancel={firstStep ? goToPreviousPage : goToPreviousStep}
                saveLabel={shopInfoValidation.modified ? t('common.save') : t('common.next')}
                cancelLabel={firstStep ? t('common.cancel') : t('common.back')}
            />
        )
    }

    render() {
        if (!this.props.shopStore.shop) return <Empty message={t('shopPage.loading')}/>

        return (
            <div
                id="shopPage"
                className={styles.shopPage}
            >
                <Paper className={styles.stepContainer}>
                    {this.renderStepper()}
                    <Divider/>
                    {this.renderCurrentStepContent()}
                </Paper>
                {this.renderActionPanel()}
            </div>
        )
    }
}
