import { EnTopologyLevel } from './en-topology-level'

export interface TopologyAdressVO {
    '@class': 'ru.crystals.setretailx.topology.TopologyAdressVO'
    nodeId?: number
    nodeLevel?: EnTopologyLevel
}

export const createTopologyAdressVO = (initialization: Partial<TopologyAdressVO>): TopologyAdressVO => {
    if (!initialization) return null
    
    let result: TopologyAdressVO = {
        nodeId: initialization.nodeId,
        nodeLevel: initialization.nodeLevel,
        '@class': 'ru.crystals.setretailx.topology.TopologyAdressVO',
    }
    
    return result
}
