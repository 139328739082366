import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = require('./page-loader.scss')

export const PageLoader: React.SFC<{}> = () => {
    return (
        <div className={styles.pageLoader}>
            <div className={styles.spinner}>
                <CircularProgress/>
            </div>
        </div>
    )
}

PageLoader.displayName = 'PageLoader' // for enzyme
