import { observable, action, runInAction, toJS } from 'mobx'
import { Cancelable, debounce } from 'lodash'
import { t } from 'i18next'
import { LoyaltyPropertiesVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/loyalty-properties-vo'
import { iDiscountsManagerLocal } from '../../../protocol/set10/i-discounts-manager-local'
import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { AppStore } from '../app-store'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE } from '../stores'
import { cardsConfigurationLocal } from '../../../protocol/set10/cards-configuration-local'
import { CardsConfigurationProperties } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cards-configuration-properties'
import { discountsProcessingCardsPropertiesManagerLocal } from '../../../protocol/set10/discounts-processing-cards-properties-manager-local'
import {
    DiscountsProcessingCardsPropertiesVO
} from '../../../protocol/set10/set-retail10-server/discounts/set-processing-discount/discounts-processing-cards-properties-vo'

export class CardsSettingsStore {
    @observable
    loyaltySettings: LoyaltyPropertiesVO

    @observable
    cardsSettings: CardsConfigurationProperties

    @observable
    discountSettings: DiscountsProcessingCardsPropertiesVO

    debouncedSaveLoyaltySettings: (() => void) & Cancelable = debounce(() => {
        this.saveLoyaltySettings()
    }, AUTOSAVE_DELAY)

    debouncedSaveCardsSettings: (() => void) & Cancelable = debounce(() => {
        this.saveCardsSettings()
    }, AUTOSAVE_DELAY)

    debouncedSaveDiscountSettings: (() => void) & Cancelable = debounce(() => {
        this.saveDiscountSettings()
    }, AUTOSAVE_DELAY)

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    fetchAllSettings = async (): Promise<void> => {
        this.fetchCardsSettings()
        this.fetchLoyaltySettings()
        this.fetchDiscountSettings()
    }

    fetchLoyaltySettings = async (): Promise<void> => {
        const { session } = this.userStore

        const loyaltySettings = await iDiscountsManagerLocal.getLoyaltyProperties(session)

        runInAction(() => {
            this.loyaltySettings = loyaltySettings
        })
    }

    fetchCardsSettings = async (): Promise<void> => {
        const cardsSettings = await cardsConfigurationLocal.getProperties()

        runInAction(() => {
            this.cardsSettings = cardsSettings
        })
    }

    fetchDiscountSettings = async (): Promise<void> => {
        const discountSettings = await discountsProcessingCardsPropertiesManagerLocal.getCardsPropertiesVO()

        runInAction(() => {
            this.discountSettings = discountSettings
        })
    }

    @action
    updateLoyaltySettings = (changes: Partial<LoyaltyPropertiesVO>): void => {
        Object.keys(changes).forEach(key => {
            this.loyaltySettings[key] = changes[key]
        })

        this.debouncedSaveLoyaltySettings()
    }

    @action
    updateCardsSettings = (changes: Partial<CardsConfigurationProperties>): void => {
        Object.keys(changes).forEach(key => {
            this.cardsSettings[key] = changes[key]
        })

        this.debouncedSaveCardsSettings()
    }

    @action
    updateDiscountSettings = (changes: Partial<DiscountsProcessingCardsPropertiesVO>): void => {
        Object.keys(changes).forEach(key => {
            this.discountSettings[key] = changes[key]
        })

        this.debouncedSaveDiscountSettings()
    }

    saveLoyaltySettings = async (): Promise<void> => {
        // TODO:
        await iDiscountsManagerLocal.setLoyaltyProperties(this.userStore.session, toJS(this.loyaltySettings))

        this.appStore.showSnackbar({message: t('cardsSettings.saved')})
    }

    saveCardsSettings = async (): Promise<void> => {
        await cardsConfigurationLocal.setProperties(toJS(this.cardsSettings))

        this.appStore.showSnackbar({message: t('cardsSettings.saved')})
    }

    saveDiscountSettings = async (): Promise<void> => {
        await discountsProcessingCardsPropertiesManagerLocal.setCardsPropertiesVO(toJS(this.discountSettings))

        this.appStore.showSnackbar({message: t('cardsSettings.saved')})
    }

    reset = () => {
        this.loyaltySettings = null
        this.cardsSettings = null
        this.discountSettings = null
    }
}
