import { ActiveType } from './active-type'
import { ApplyMode } from '../../../set-retail10-commons/data-structs-module/apply-mode'
import { DatePeriod } from '../../../set-retail10-commons/data-structs-module/date-period'
import { ActionPricetagType } from '../../../set-retail10-commons/data-structs-module/action-pricetag-type'
import { PluginType } from '../../../set-retail10-commons/data-structs-module/plugin-type'
import { ActorId } from './actor-id'
import { CardsCondition } from './cards-condition'
import { BooleanEnum } from './boolean-enum'

export interface ActionsFilter {
    '@class': 'ru.crystals.discounts.ActionsFilter'
    timestamp?: Date
    activeType?: ActiveType
    mode?: ApplyMode
    good?: string
    name?: string
    keyWord?: string
    excludeActions?: number[]
    independentOnly?: boolean
    actionBeginDateInterval?: DatePeriod
    actionFinishDateInterval?: DatePeriod
    pricetagTypes?: ActionPricetagType[]
    period?: DatePeriod
    pluginType?: PluginType
    secondaryResultTypes?: string[]
    parentGuid?: number
    partOfShopNumberOrName?: string
    extCode?: string
    lastChanges?: DatePeriod
    shopNumbers?: number[]
    author?: ActorId
    editor?: ActorId
    cardsCondition?: CardsCondition
    hasCouponCondition?: BooleanEnum
    color?: string
    couponNumber?: string
    couponCategoryGuids?: number[]
    segmentGuid?: string
    label?: string
}

export const createActionsFilter = (initialization: Partial<ActionsFilter>): ActionsFilter => {
    if (!initialization) return null
    
    let result: ActionsFilter = {
        timestamp: initialization.timestamp,
        activeType: initialization.activeType,
        mode: initialization.mode,
        good: initialization.good,
        name: initialization.name,
        keyWord: initialization.keyWord,
        excludeActions: initialization.excludeActions,
        independentOnly: initialization.independentOnly,
        actionBeginDateInterval: initialization.actionBeginDateInterval,
        actionFinishDateInterval: initialization.actionFinishDateInterval,
        pricetagTypes: initialization.pricetagTypes,
        period: initialization.period,
        pluginType: initialization.pluginType,
        secondaryResultTypes: initialization.secondaryResultTypes,
        parentGuid: initialization.parentGuid,
        partOfShopNumberOrName: initialization.partOfShopNumberOrName,
        extCode: initialization.extCode,
        lastChanges: initialization.lastChanges,
        shopNumbers: initialization.shopNumbers,
        author: initialization.author,
        editor: initialization.editor,
        cardsCondition: initialization.cardsCondition,
        hasCouponCondition: initialization.hasCouponCondition,
        color: initialization.color,
        couponNumber: initialization.couponNumber,
        couponCategoryGuids: initialization.couponCategoryGuids,
        segmentGuid: initialization.segmentGuid,
        label: initialization.label,
        '@class': 'ru.crystals.discounts.ActionsFilter',
    }
    
    return result
}
