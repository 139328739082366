import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { BaseTable, BaseTableProps } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { LOYALTY_COUPONS_STORE } from '../../../../store/stores'
import { LoyaltyCouponsStore, MAX_COUPONS_PER_PAGE } from '../../../../store/loyalty/loyalty-coupons-store'
import { AdaptiveIconButton } from '../../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import GoToIcon from '@material-ui/icons/NavigateNext'
import { goTo } from '../../../../utils/router-util'
import { LOYALTY, COUPONS, COUPONS_EDIT, NEW } from '../../../../core/app-routes'
import { Coupon } from '../../../../core/coupons/coupon-util'
import { fromClientToServerTime } from '../../../../utils/app-util'
import { NothingFound } from '../../../../components/stub-with-img/nothing-found'
import { withLoadableOnScrollRows } from '../../../../../components/table-hocs'
import { getStore } from '../../../../store/stores-repository'

const styles = require('./loyalty-coupons-list.scss')

const CouponsTable = withLoadableOnScrollRows<Coupon, BaseTableProps<Coupon>>(
    BaseTable,
    () => getStore<LoyaltyCouponsStore>(LOYALTY_COUPONS_STORE).debouncedFetchCouponsOnScroll
)

interface LoyaltyCouponsListProps {
    loyaltyCouponsStore?: LoyaltyCouponsStore
    testing?: boolean
}

@inject(LOYALTY_COUPONS_STORE)
@observer
export class LoyaltyCouponsList extends React.Component<LoyaltyCouponsListProps> {
    componentDidMount() {
        this.props.loyaltyCouponsStore?.fetchCoupons()
    }

    handleCouponClick = (id: number): void => {
        const { resetCoupon } = this.props.loyaltyCouponsStore
        resetCoupon()
        goTo(`${LOYALTY}${COUPONS}${COUPONS_EDIT}/${id}`)
    }

    render() {
        const { testing, loyaltyCouponsStore } = this.props
        const {
            coupons,
            couponNameFilter,
            hasNextPage,
            setNameFilter,
            resetCoupon,
        } = loyaltyCouponsStore

        return (
            <>
                <Paper style={{ padding: 16, paddingBottom: 8, marginBottom: 8 }}>
                    <FilterInput
                        id="filterInput"
                        placeholder={t('loyaltyCoupons.findByName')}
                        value={couponNameFilter}
                        onValueChange={setNameFilter}
                    />
                    <Grid container spacing={2}>
                        <Box ml={1} mt={1} mb={1} style={{ display: 'flex' }}>
                            <p className={styles.couponsLabel}>
                                {
                                    coupons.length <= MAX_COUPONS_PER_PAGE && !hasNextPage
                                        ? t('loyaltyCoupons.foundCategories', { count: coupons.length })
                                        : t('loyaltyCoupons.foundMoreThanCategories', { count: MAX_COUPONS_PER_PAGE })
                                }
                            </p>
                        </Box>
                    </Grid>
                </Paper>

                <Paper style={{ flex: 1, display: 'flex', flexFlow: 'column' }}>
                    {!coupons.length && (
                        <NothingFound />
                    )}

                    {!!coupons.length && (
                        <>
                            <div style={{ flex: 1 }}>
                                <CouponsTable
                                    id="couponsListTable"
                                    disableAutoSizer={testing}
                                    keyFunction={(item: Coupon) => item.id}
                                    items={coupons}
                                    fullHeight
                                    onRowClick={({ rowData }) => this.handleCouponClick(rowData.guid)}
                                    columns={[
                                        {
                                            header: t('loyaltyCoupons.couponsTable.name'),
                                            keyField: 'name',
                                            renderer: item => item.name,
                                            columnWidth: 400,
                                            padding: '0 0 0 14px',
                                        },
                                        {
                                            header: t('loyaltyCoupons.couponsTable.type'),
                                            keyField: 'serial',
                                            renderer: item => {
                                                if (item.serial) return t('loyaltyCoupons.couponsTable.serial')
                                                return t('loyaltyCoupons.couponsTable.notSerial')
                                            },
                                        },
                                        {
                                            header: t('loyaltyCoupons.couponsTable.activationDate'),
                                            keyField: 'activationDate',
                                            renderer: item => {
                                                if (item.idlePeriod || item.validPeriod) {
                                                    return (
                                                        <p style={{ margin: 0 }}>
                                                            {t('loyaltyCoupons.editCoupon.floatingInterval')}
                                                        </p>
                                                    )
                                                }

                                                if (!item.activationDate) {
                                                    return (
                                                        <p className={styles.noPeriodLabel}>
                                                            {t('loyaltyCoupons.couponsTable.noPeriod')}
                                                        </p>
                                                    )
                                                }
                                                const date = fromClientToServerTime(new Date(item.activationDate))
                                                return moment(date).format('DD.MM.YYYY HH:mm')
                                            },
                                        },
                                        {
                                            header: t('loyaltyCoupons.couponsTable.expirationDate'),
                                            keyField: 'expirationDate',
                                            renderer: item => {
                                                if (!item.expirationDate) return null
                                                const date = fromClientToServerTime(new Date(item.expirationDate))
                                                return moment(date).format('DD.MM.YYYY HH:mm')
                                            },
                                        },
                                        {
                                            header: '',
                                            keyField: 'controlBtn',
                                            renderer: item => (
                                                <AdaptiveIconButton
                                                    adaptive={false}
                                                    id={`goToCoupon${item.guid}`}
                                                    label={t('loyaltyCoupons.couponsTable.goToCoupon')}
                                                    onClick={() => this.handleCouponClick(item.guid)}
                                                >
                                                    <GoToIcon />
                                                </AdaptiveIconButton>
                                            ),
                                            columnWidth: 60,
                                            staticColumn: true,
                                        },
                                    ]}
                                />
                            </div>
                        </>
                    )}
                </Paper>

                <Fab
                    id="addButton"
                    className={styles.addButton}
                    onClick={() => {
                        resetCoupon()
                        goTo(`${LOYALTY}${COUPONS}${COUPONS_EDIT}${NEW}`)
                    }}
                    color="primary"
                >
                    <AddIcon className={styles.icon} color="primary" />
                </Fab>
            </>
        )
    }
}
