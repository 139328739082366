export interface OperDayPropertiesVO {
    '@class': 'ru.crystals.operday.gui.datastructs.operday.OperDayPropertiesVO'
    checkAmountDisplayedInSearchCheckMode?: number
    periodOnLineConversion?: number
    storagePeriodOfOperatingDayReportData?: number
    nextDayTimeFromMidnightInMinutes?: number
    reportsProcessingEnabled?: boolean
}

export const createOperDayPropertiesVO = (initialization: Partial<OperDayPropertiesVO>): OperDayPropertiesVO => {
    if (!initialization) return null
    
    let result: OperDayPropertiesVO = {
        checkAmountDisplayedInSearchCheckMode: initialization.checkAmountDisplayedInSearchCheckMode,
        periodOnLineConversion: initialization.periodOnLineConversion,
        storagePeriodOfOperatingDayReportData: initialization.storagePeriodOfOperatingDayReportData,
        nextDayTimeFromMidnightInMinutes: initialization.nextDayTimeFromMidnightInMinutes,
        reportsProcessingEnabled: initialization.reportsProcessingEnabled,
        '@class': 'ru.crystals.operday.gui.datastructs.operday.OperDayPropertiesVO',
    }
    
    return result
}
