import * as React from 'react'
import { t } from 'i18next'
import classNames from 'classnames'
import { getKey, itemToLabel } from '../../../utils/components/components-util'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { Table, SELECT_ALL } from '@crystalservice/crystals-ui/lib/components/table/table'
import Delete from '@material-ui/icons/Delete'
import { NORMAL, StatusPaper, StatusPaperMode } from '../../status-paper/status-paper'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'

const styles = require('./items-selector.scss')

export interface ItemsSelectorProps extends React.HTMLProps<HTMLElement> {
    /**
     * Элементы, которые будут доступны для выделения
     */
    items: any[]
    /**
     * Заголовок, который будет показан в режиме TITLE
     */
    title?: string
    /**
     * Название сущности, с которой мы работаем, например: "Пользователи"
     */
    entityName?: string
    /**
     * Подсказка, которая отображается в строке поиска, например: "Введите имя пользователя"
     */
    filterPlaceholder?: string
    /**
     * Текст, который выводится, когда не найдено ни одного элемента при поиске, например: "Не найдено ни одного пользователя"
     */
    noItemsText?: string
    /**
     * Поле объекта, по которому будет определяться уникальный ключ для react и для сравнения элементов
     */
    keyField?: string
    /**
     * Функция которая будет вычислять уникальный ключ для react и для сравнения элементов
     * @param item
     * @returns {string}
     */
    keyFunction?: (item: any) => string
    /**
     * Поле объекта, из которого будет вычисляться строковое представление объектов
     */
    labelField?: string
    /**
     * Функция, которая будет вычисляться строковое представление объектов
     * @param item
     * @returns {string}
     */
    labelFunction?: (item: any) => string
    /**
     * Список полей объекта, по которым будет производится сортировка элементов
     */
    sortFields?: string[]
    /**
     * Функция для сортировки элементов
     * @param a
     * @param b
     * @returns {number}
     */
    sortFunction?: (a: any, b: any) => number
    /**
     * Элементы, которые должны быть выделены по-умолчанию
     */
    defaultSelection?: any[]
    /**
     * Элементы, недоступные для редактирования, могут быть выделены
     */
    disabledItems?: any[]
    /**
     * Показывать ли строку поиска
     */
    showFilter?: boolean
    /**
     * Показывать ли плашку со статусом выделения
     */
    showStatus?: boolean
    /**
     * Вычисляет текст сообщения, который выводится на плашке со статусом, например: "Выбрано пользователей: 0"
     * @param {any[]} items
     * @returns {string}
     */
    getAddedItemsText?: (selection: any[]) => string
    /**
     * Вычисляет режим отображения плашки со статусом, например, если не выбрано элементов можно вернуть статус ERROR
     * @param {any[]} items
     * @returns {StatusPaperMode}
     */
    getStatusPaperMode?: (items: any[]) => StatusPaperMode
    /**
     * Вызывается при изменении выделения элементов
     * @param {any[]} selection
     */
    onSelectionChange?: (selection: any[]) => void
}

export interface ItemsSelectorState {
    filter?: string
    filteredItems?: any[]
    selection?: any[]
    filteredSelection?: any[]
}

export class ItemsSelector extends React.Component<ItemsSelectorProps, ItemsSelectorState> {

    static defaultProps: Partial<ItemsSelectorProps> = {
        id: 'itemsSelector',
        defaultSelection: [],
        disabledItems: [],
        title: '',
        entityName: '',
        filterPlaceholder: t('components.elementsFilterPrompt'),
        noItemsText: t('components.elementsNotFound'),
        showFilter: true,
        showStatus: true,
        onSelectionChange: () => null,
        getStatusPaperMode: () => NORMAL
    }

    constructor(props: ItemsSelectorProps, context: any) {
        super(props, context)

        if (!props.keyField && !props.keyFunction) {
            throw new Error(`Property "keyField" or "keyFunction" have not specified for ItemsSelector`)
        }

        this.state = {
            filteredItems: props.items,
            selection: props.defaultSelection,
            filteredSelection: props.defaultSelection,
            filter: '',
        }
    }

    getItemKey = (item: any): React.Key => {
        const { keyField, keyFunction } = this.props
        return getKey(item, keyField, keyFunction)
    }

    isItemDisabled = (item: any): boolean => {
        const { disabledItems } = this.props
        const key = this.getItemKey(item)
        return disabledItems.some(disabledItem => key === this.getItemKey(disabledItem))
    }

    isItemSelected = (item: any): boolean => {
        const { selection } = this.state
        const key = this.getItemKey(item)
        return selection.some(selectedItem => key === this.getItemKey(selectedItem))
    }

    filterItems = (items: any[], filter: string): any[] => {
        const { labelField, labelFunction } = this.props

        if (!filter) return items

        let labels: string[] = items.map(item => itemToLabel(item, labelField, labelFunction))

        let filteredItems: any[] = []

        labels.forEach((label, index) => {
            if (label && label.trim().toLocaleLowerCase().indexOf(filter.trim().toLowerCase()) > -1) {
                filteredItems.push(items[index])
            }
        })

        return filteredItems
    }

    changeSelection = (items: any[], selected: boolean): void => {
        const { onSelectionChange } = this.props
        const { selection, filter } = this.state

        let newSelection = selection.concat()

        if (selected) {
            newSelection = newSelection.concat(items
                .filter(item => !this.isItemDisabled(item) && !this.isItemSelected(item)))
        } else {
            items.forEach(item => {
                if (this.isItemDisabled(item)) return

                const key = this.getItemKey(item)
                let index = newSelection.findIndex(selectedItem => this.getItemKey(selectedItem) === key)
                if (index > -1) {
                    newSelection.splice(index, 1)
                }
            })
        }

        this.setState({
            selection: newSelection,
            filteredSelection: this.filterItems(newSelection, filter),
        })
        onSelectionChange(newSelection)
    }

    render() {
        const {
            id, className, labelField, labelFunction, sortFields, sortFunction, keyFunction, keyField,
            onSelectionChange, entityName, title, getAddedItemsText, getStatusPaperMode, showStatus,
            showFilter, filterPlaceholder, disabledItems, items, noItemsText
        } = this.props
        const { filter, selection, filteredItems, filteredSelection } = this.state

        let availableSelectedItems: any[] = selection.filter(item => !this.isItemDisabled(item))

        return (
            <div
                id={id}
                className={classNames(styles.itemsSelector, className)}
            >
                {
                    showStatus && (
                        <StatusPaper
                            id={`${id}StatusPaper`}
                            title={title}
                            message={getAddedItemsText
                                ? getAddedItemsText(selection)
                                : t('components.elementsAdded', {count: selection.length })
                            }
                            mode={getStatusPaperMode(selection)}
                            actionComponents={ availableSelectedItems.length > 0 && (
                                <Tooltip title={t('common.removeAll')}>
                                    <IconButton
                                        id={`${id}DeleteAllButton`}
                                        onClick={() => this.changeSelection(filteredSelection.filter(item =>
                                            !this.isItemDisabled(item)), false)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        />
                    )
                }

                {
                    showFilter && (
                        <FilterInput
                            id={`${id}FilterInput`}
                            placeholder={filterPlaceholder}
                            className={styles.filterInput}
                            value={filter}
                            onValueChange={filter => this.setState({
                                filter,
                                filteredItems: this.filterItems(items, filter),
                                filteredSelection: this.filterItems(selection, filter),
                            })}
                        />
                    )
                }

                {
                    (showFilter || showStatus) && <Divider/>
                }

                {
                    filteredItems.length > 0 && (
                        <Table
                            id={`${id}ItemsList`}
                            withHeader
                            adaptive={false}
                            items={filteredItems}
                            disabledItems={disabledItems}
                            selectable
                            selectionMode={SELECT_ALL}
                            selectedItems={filteredSelection}
                            sortingFields={sortFields}
                            keyFunction={keyFunction}
                            keyField={keyField}
                            sortingFunction={sortFunction}
                            onRowSelect={(item: any, selected: boolean) => this.changeSelection([item], selected)}
                            onAllRowSelect={selected => this.changeSelection(filteredItems, selected)}
                            columns={[
                                {
                                    labelField,
                                    labelFunction,
                                    header: entityName,
                                }
                            ]}
                        />
                    )
                }

                {
                    filteredItems.length === 0 && <Empty message={noItemsText}/>
                }

            </div>
        )
    }

}
