import React from 'react'
import { FilterType, ACTION_CARDS } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { t } from 'i18next'
import { BaseSelectFilter } from '../components/base-select-filter'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { iCardsManagerRemote } from '../../../../protocol/set10/i-cards-manager-remote'

export interface ActionCardsProps extends InternalFilterComponentProps<ActionCardsData> {
    session: string
}

interface ActionCardsData {
    checked: boolean
    value: string
}

export class ActionCards extends React.PureComponent<ActionCardsProps> {
    static filterType: FilterType = ACTION_CARDS

    render() {
        const { id, data, onChange, session, slim } = this.props

        const state = data || { checked: false, value: '' }

        return (
            <div>
                <div style={{ paddingLeft: 4 }}>
                    <Checkbox
                        checked={state.checked}
                        fitInputHeight={false}
                        label={t('filters.actionCardsCheckbox')}
                        onValueChange={checked => {
                            onChange(ActionCards.filterType, {
                                value: '[]',
                                data: {
                                    ...state,
                                    checked
                                }
                            })
                        }}
                        color="primary"
                        slim={slim}
                    />
                </div>
                <BaseSelectFilter
                    id={id}
                    defaultValue={state.value}
                    disabled={state.checked}
                    onFilterChange={(type: FilterType, newValue: string) => {
                        onChange(type, {
                            value: newValue,
                            data: {
                                ...state,
                                value: newValue
                            }
                        })
                    }}
                    multiple
                    filterType={ActionCards.filterType}
                    fetchFunction={async () => {
                        const cards = await iCardsManagerRemote.getInternalCards(session, 0, 10000, { useCache: true })

                        return cards.map(card => ({ label: card.name, value: String(card.guid) }))
                    }}
                    disabledWhenNoOptions
                    emptyOptionsComment={t('filters.registeredBanksEmpty')}
                    slim={slim}
                />
            </div>
        )
    }
}
