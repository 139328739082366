import { UploadedProductsVO } from './set-retail10-commons/set-scales-commons/uploaded-products-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class ScalesInfoManager extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Scales-Tech/SET/ScalesInfoManager/Tech!ru.crystals.scales.tech.ScalesInfoManager'
    }

    getLoadedProducts = (scalesId: number, options?: RequestOptions): Promise<UploadedProductsVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getLoadedProducts',
                params: [scalesId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    updateDateOfManufacture = (markings: string[], newDate: string, templateId: number, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'updateDateOfManufacture',
                params: [markings, newDate, templateId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const scalesInfoManager: ScalesInfoManager = new ScalesInfoManager()
