import { observable, action, runInAction, autorun } from 'mobx'
import { isNil, sortBy, get } from 'lodash'
import { PRICE_TAGS_PRINTING_STORE } from '../../stores'
import { getStore } from '../../stores-repository'
import { PriceTagTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { PriceTagsPrintingStore } from './printing-store'
import {
    PriceTagsFiltersState,
    ANY_SIZE_TEMPLATE_ID
} from '../../../pages/price-tags/printing/price-tags-filters'

export class PrintByPriceTagsSelectionStore {

    @observable
    filters: PriceTagsFiltersState = null

    @observable
    filteredPriceTags: PriceTagTemplateVO[] = []

    @observable
    tableKey: string = ''

    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    constructor() {
        autorun(() => {
            const { allPriceTags, shelfParams, selectedAllPriceTagsCountMap, changeAllPriceTagCountSelection, actionable } = this.printingStore
            if (!shelfParams || !allPriceTags) {
                return
            }
            const filteredPriceTags = getFilteredSelectablePriceTags(
                allPriceTags,
                this.filters,
                actionable
            )

            let nextTableKey: string = filteredPriceTags.map(pt => pt.id).join(';')

            runInAction(() => {
                if (nextTableKey !== this.tableKey) {
                    this.tableKey = nextTableKey
                    selectedAllPriceTagsCountMap.forEach((value: number, key: string) => {
                        if (!filteredPriceTags.some(pt => pt.id === key)) {
                            changeAllPriceTagCountSelection(key, 0)
                        }
                    })
                }
                this.filteredPriceTags = filteredPriceTags
            })
        })
    }

    @action
    setFilters = (newFilters: PriceTagsFiltersState): void => {
        this.filters = newFilters
    }

    @action
    reset = (): void => {
        this.filters = null
        this.filteredPriceTags = []
        this.tableKey = ''
    }
}

function getFilteredSelectablePriceTags(allPriceTags: PriceTagTemplateVO[], filters: PriceTagsFiltersState,
                                        actionable: boolean): PriceTagTemplateVO[] {
    if (!allPriceTags) {
        return []
    }

    let filteredPriceTags: PriceTagTemplateVO[] = allPriceTags.filter(priceTag => priceTag.actionable === actionable)

    if (!filters) {
        return filteredPriceTags
    }

    const { priceTagName, selectedPriceTagSize } = filters

    if (priceTagName) {
        filteredPriceTags = filteredPriceTags.filter(priceTag => {
            return priceTag.name.toLowerCase().includes(priceTagName.toLowerCase().trim())
        })
    }
    if (selectedPriceTagSize.value !== ANY_SIZE_TEMPLATE_ID) {
        if (selectedPriceTagSize.priceTag) {
            filteredPriceTags = filteredPriceTags.filter(priceTag => {
                return priceTag.width === selectedPriceTagSize.priceTag.width
                    && priceTag.height === selectedPriceTagSize.priceTag.height
            })
        } else if (selectedPriceTagSize.priceTagSizeLabel) {
            filteredPriceTags = filteredPriceTags.filter(priceTag => {
                return get(priceTag, 'sizeLabel.id') === selectedPriceTagSize.priceTagSizeLabel.id
            })
        }
    }

    return filteredPriceTags
}
