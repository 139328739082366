import React from 'react'
import { getFilterTypeName, FilterType, ACTION_EDITOR } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { AutocompleteInput } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { t } from 'i18next'
import { iUserManagementLocal } from '../../../../protocol/set10/i-user-management-local'
import { UserVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import { getUserFullName } from '../../../../utils/name-util'

const styles = require('./action-author.scss')

export class ActionEditor extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_EDITOR

    render() {
        const { id, data, onChange, slim } = this.props

        const shownValue = data || ''

        return (
            <AutocompleteInput
                id={id}
                slim={slim}
                onSuggestionSelect={(user: UserVO) => {
                    onChange(ActionEditor.filterType, {
                        value: user && user.login,
                        data: getUserFullName(user)
                    })
                }}
                label={getFilterTypeName(ActionEditor.filterType)}
                shownValue={shownValue}
                showClearButton
                placeholder={t('filters.actionUserPlaceholder')}
                fetchDataCallback={mask => iUserManagementLocal.getUsersByNameOrLoginLike(mask)}
                suggestionsLimit={20}
                keyField="id"
                labelFunction={getUserFullName}
                menuItemRenderer={(user: UserVO) => {
                    return (
                        <div>
                            <div>{getUserFullName(user)}</div>
                            <div className={styles.secondaryText}>{user.login}</div>
                        </div>
                    )
                }}
            />
        )
    }
}
