import { RegionVO } from './region-vo'
import { CityVO } from './city-vo'
import { ShopVO } from './shop-vo'

export interface BaseLimitVO {
    '@class': 'ru.crystals.limit.BaseLimitVO' | 'ru.crystals.alcohol.ejb.AlcoholLimitVO'
    guid?: string
    name?: string
    deleted?: boolean
    createDate?: Date
    updateDate?: Date
    fromApperPoint?: boolean
    forAllStores?: boolean
    regions?: RegionVO[]
    cities?: CityVO[]
    shops?: ShopVO[]
}

export const createBaseLimitVO = (initialization: Partial<BaseLimitVO>): BaseLimitVO => {
    if (!initialization) return null
    
    let result: BaseLimitVO = {
        guid: initialization.guid,
        name: initialization.name,
        deleted: initialization.deleted,
        createDate: initialization.createDate,
        updateDate: initialization.updateDate,
        fromApperPoint: initialization.fromApperPoint,
        forAllStores: initialization.forAllStores,
        regions: initialization.regions,
        cities: initialization.cities,
        shops: initialization.shops,
        '@class': 'ru.crystals.limit.BaseLimitVO',
    }
    
    return result
}
