export const CARDS_MASK_FORMAT: string = '____ ____ ____ ____'
export const MESSAGE_NOT_SAFE_SYMBOLS: RegExp = /[^A-Za-zА-ЯЁа-яё0-9._~(){}\[\]'"\/%<>=№#$&^!*:@,;+?\-|\n ]/gmi

/* '5555 5555 5555 5555' => '5555555555555555' */
export const cardNumberToServerValidValue = (cardNumber: string): string => {
    const INVALID_SYMBOLS_REGEXP: RegExp = /\s|-/g
    return cardNumber.replace(INVALID_SYMBOLS_REGEXP, '')
}

/* '5555555555555555' => '5555 5555 5555 5555' */
export const cardNumberToFrontFormattedValue = (cardNumber: string): string => {
    let result = CARDS_MASK_FORMAT

    cardNumberToServerValidValue(cardNumber)
        .split('')
        .forEach(symbol => {
            result = result.replace('_', symbol)
        })

    /* карта может быть короче 16ти символов */
    result = result.replace(/_/g, '')

    return result.trim()
}

/**
 * Добавляем разделение через тире для некоторых знаков
 */
export const formatPhoneNumber = (phone: string): string => {
    let newPhone: string = ''
    let onlyNumbers = phone.replace(/[\s-+]/g, '')

    if (phone[0] === '+') {
        newPhone += '+'
    }
    const delimetersArray = [1, 3, 2, 2, 3]

    delimetersArray.forEach((delimeterCount: number, index: number) => {
        newPhone += onlyNumbers.slice(0, delimeterCount)
        onlyNumbers = onlyNumbers.slice(delimeterCount)

        if (onlyNumbers && index + 1 !== delimetersArray.length) {
            newPhone += '-'
        }
    })

    return newPhone
}

/**
 * Удаляет из строки недопустимые символы, оставляет только символы, которые можно ввести с клавиатуры
 * Из пробельных символов оставляет только обычный пробел и перенос строки
 */
export const toSafeMessageText = (value: string): string => {
    return value.replace(MESSAGE_NOT_SAFE_SYMBOLS, '')
}
