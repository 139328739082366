import React from 'react'
import { t } from 'i18next'
import classNames from 'classnames'
import { getKey, itemToLabel } from '../../../utils/components/components-util'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import Divider from '@material-ui/core/Divider'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { highlightFirstMatch } from '../../product-base-info/product-base-info'
import ListItemText from '@material-ui/core/ListItemText'
import { sortBy } from 'lodash'
import { observer } from 'mobx-react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import { MenuButton } from '@crystalservice/crystals-ui/lib/components/buttons/menu-button/menu-button'
import { FilterIcon } from '@crystalservice/crystals-ui/lib/components/selectors/items-filtered-list/filter-icon'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./items-filtered-list.scss')

export type FilterType = 'all' | 'checked' | 'unchecked'
export const SHOW_ALL: FilterType = 'all'
export const SHOW_CHECKED: FilterType = 'checked'
export const SHOW_UNCHECKED: FilterType = 'unchecked'

const ITEM_HEIGHT = 49

export interface ItemsFilteredListProps<T = any> {
    id?: string
    className?: string
    /**
     * Элементы, которые будут доступны для выделения
     */
    items: T[]
    /**
     * Выделенные элементы
     */
    selectedItems: T[]

    /**
     * Надпись в заголовке, по умолчанию: Наименование
     */
    header?: string
    /**
     * Максимальное кол-во рядов. По умолчанию 6. При значении 0 = не ограниченно
     */
    maxRows?: number
    /**
     * Отображение фильтра
     */
    showFilter?: boolean
    /**
     * Отображение стрового фильтра
     */
    showStringFilter?: boolean
    /**
     * Строка которая будет использована для фильтрации. Для режима showStringFilter = false
     */
    customStringFilter?: string
    /**
     * CSS-классы, применяемый к тексу, который совпадает со строкой поиска. По умолчанию: оранжевый фон
     */
    highlightedTextClasses?: string
    /**
     * Строка поиска будет только подсвечивать элементы, не пряча их.
     */
    useFilterOnlyToHighlight?: boolean

    /**
     * Поле объекта, по которому будет определяться уникальный ключ для react и для выделения элементов
     */
    keyField?: string
    /**
     * Функция которая будет вычислять уникальный ключ для react и для выделения элементов
     * @param item
     * @returns {string}
     */
    keyFunction?: (item: T) => string
    /**
     * Поле объекта, из которого будет вычисляться строковое представление объектов
     */
    labelField?: string
    /**
     * Функция, которая будет вычисляться строковое представление объектов
     * @param item
     * @returns {string}
     */
    labelFunction?: (item: T) => string
    /**
     * Функция, которая возвращает дополнительный элемент, отображаемый в ListItem
     * @param item
     * @returns {React.ReactNode}
     */
    secondaryTextFunction?: (item: T) => React.ReactNode
    /**
     * Список полей объекта, по которым будет производится сортировка элементов
     */
    sortFields?: string[]
    /**
     * Функция для сортировки элементов
     * @param a
     * @param b
     * @returns {number}
     */
    sortFunction?: (a: T, b: T) => number

    /**
     * Текст, который выводится, когда не найдено ни одного элемента при поиске, например: "Не найдено ни одного пользователя"
     */
    noItemsText?: string
    /**
     * Вызывается при изменении выделения элементов
     * @param {T[]} selection
     */
    onSelectionChange: (selection: T[]) => void

    /**
     * Позволяет не учитывать элемент для отображения состояния "Выбрать все"
     */
    selectAllIgnores?: (item: T) => boolean
}

export interface ItemsFilteredListState {
    filterString?: string
    filterType?: FilterType
}

@observer
export class ItemsFilteredList<T> extends React.Component<ItemsFilteredListProps<T>, ItemsFilteredListState> {
    static defaultProps: Partial<ItemsFilteredListProps> = {
        id: 'itemsFilteredList',
        header: t('shop.name'),
        noItemsText: t('components.elementsNotFound'),
        maxRows: 6,
        showFilter: true,
        showStringFilter: true,
        customStringFilter: '',
        highlightedTextClasses: styles.highlightedMatch
    }

    state: ItemsFilteredListState = {
        filterString: '',
        filterType: SHOW_ALL
    }

    constructor(props: ItemsFilteredListProps, context: any) {
        super(props, context)

        if (!props.keyField && !props.keyFunction) {
            throw new Error(`Property "keyField" or "keyFunction" are not specified for ItemsSelector`)
        }
    }

    getItemKey = (item: T): React.Key => {
        const { keyField, keyFunction } = this.props
        return getKey(item, keyField, keyFunction)
    }

    getItemLabel = (item: T): string => {
        const { labelField, labelFunction } = this.props
        return itemToLabel(item, labelField, labelFunction)
    }

    sortItems = (items: T[]): T[] => {
        const { sortFields, sortFunction } = this.props

        if (sortFunction) {
            return items.concat().sort(sortFunction)
        }

        if (sortFields) {
            return sortBy(items, sortFields)
        }

        return items
    }

    changeSelection = (item: T, selected: boolean): void => {
        const { onSelectionChange, selectedItems } = this.props
        const itemKey = this.getItemKey(item)

        let newSelection = selectedItems.concat()
        if (selected) {
            newSelection.push(item)
        } else {
            const index = newSelection.findIndex(selectedItem => {
                if (this.getItemKey(selectedItem) === itemKey) {
                    return true
                }
            })

            newSelection.splice(index, 1)
        }

        onSelectionChange(newSelection)
    }

    selectAllHandler = () => {
        const { onSelectionChange, selectedItems, items, selectAllIgnores } = this.props

        const affectedItems = selectAllIgnores
            ? selectedItems.filter(item => !selectAllIgnores(item))
            : selectedItems
        if (affectedItems.length > 0) {
            // Если были элементы - снимаем всем
            onSelectionChange([])
        } else {
            // Если пусто, всем проставляем (даже тем, которые не отображены из-за фильтра)
            onSelectionChange(items.concat())
        }
    }

    render() {
        const {
            id,
            className,
            items,
            selectedItems,
            noItemsText,
            header,
            maxRows,
            showFilter,
            customStringFilter,
            showStringFilter,
            highlightedTextClasses,
            useFilterOnlyToHighlight,
            secondaryTextFunction
        } = this.props
        const { filterString, filterType } = this.state

        let listStyle: React.CSSProperties = {}
        if (maxRows > 0) {
            listStyle.maxHeight = ITEM_HEIGHT * maxRows
        }

        // Делаем объект для проверки выделенных элементов
        let selectedIds = {}
        selectedItems.forEach(item => {
            const key = this.getItemKey(item)
            selectedIds[key] = true
        })

        // Фильтруем элементы по типу фильтра
        let filteredItems = items
        if (filterType !== SHOW_ALL) {
            filteredItems = filteredItems.filter(item => {
                const itemKey = this.getItemKey(item)
                const selected = selectedItems.find(selectedItem => {
                    const selectedItemKey = this.getItemKey(selectedItem)
                    return selectedItemKey === itemKey
                })
                return filterType === SHOW_CHECKED ? selected : !selected
            })
        }

        // Фильтруем элементы по строке
        let usedFilter = showStringFilter ? filterString : customStringFilter
        if (usedFilter && !useFilterOnlyToHighlight) {
            usedFilter = usedFilter.trim().toLocaleLowerCase()
            filteredItems = filteredItems.filter(item => {
                const label = this.getItemLabel(item)
                return (
                    label &&
                    label
                        .trim()
                        .toLocaleLowerCase()
                        .indexOf(usedFilter) > -1
                )
            })
        }

        // Сортируем элементы
        filteredItems = this.sortItems(filteredItems)

        return (
            <div id={id} className={classNames(styles.itemsSelector, className)}>
                <Grid container className={styles.headerContainer}>
                    <Grid item xs={12} sm={5}>
                        <ListItem className={styles.item}>
                            <ListItemIcon className={styles.checkbox}>
                                <Checkbox
                                    id={`${id}_selectAll`}
                                    checked={selectedItems.length > 0}
                                    indeterminate={
                                        selectedItems.length > 0 && selectedItems.length !== items.length
                                    }
                                    tabIndex={-1}
                                    color="primary"
                                    onClick={this.selectAllHandler}
                                    nodeMode
                                />
                            </ListItemIcon>
                            <ListItemText disableTypography primary={header} />
                        </ListItem>
                    </Grid>
                    {showFilter && (
                        <Grid
                            item
                            xs={12}
                            sm={showStringFilter ? 4 : 7}
                            container
                            justify="flex-end"
                            alignItems="center"
                            wrap="nowrap"
                        >
                            {filterType !== SHOW_ALL && (
                                <Chip
                                    id={`${id}_removeFilterChip`}
                                    label={
                                        filterType === SHOW_CHECKED
                                            ? t('components.itemsFilteredList.active')
                                            : t('components.itemsFilteredList.notActive')
                                    }
                                    className={styles.smallChip}
                                    onDelete={() => this.setState({ filterType: SHOW_ALL })}
                                />
                            )}
                            <MenuButton
                                id={`${id}_filterMenu`}
                                iconComponent={
                                    <FilterIcon selected={filterType !== SHOW_ALL}/>
                                }
                                buttons={[
                                    {
                                        id: `${id}_option_checked`,
                                        label: t('components.itemsFilteredList.active'),
                                        onClick: () => this.setState({ filterType: SHOW_CHECKED })
                                    },
                                    {
                                        id: `${id}_option_unchecked`,
                                        label: t('components.itemsFilteredList.notActive'),
                                        onClick: () => this.setState({ filterType: SHOW_UNCHECKED })
                                    }
                                ]}
                            />
                        </Grid>
                    )}
                    {showStringFilter && (
                        <Grid item xs={12} sm={3} container alignItems="center">
                            <FilterInput
                                id={`${id}_filterInput`}
                                placeholder={t('common.search')}
                                value={filterString}
                                onValueChange={filterString => this.setState({ filterString })}
                            />
                        </Grid>
                    )}
                </Grid>

                <Divider />

                <List disablePadding dense className={styles.list} style={listStyle}>
                    {filteredItems.map(item => {
                        const key = this.getItemKey(item)
                        const selected = Boolean(selectedIds[key])
                        return (
                            <ListItem
                                id={`${id}_item_${key}`}
                                key={key}
                                button
                                divider
                                className={styles.item}
                                onClick={() => this.changeSelection(item, !selected)}
                            >
                                <ListItemIcon className={styles.checkbox}>
                                    <Checkbox
                                        checked={selected}
                                        tabIndex={-1}
                                        disableRipple
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography
                                    primary={highlightFirstMatch(
                                        this.getItemLabel(item),
                                        usedFilter,
                                        highlightedTextClasses
                                    )}
                                    secondary={secondaryTextFunction ? secondaryTextFunction(item) : null}
                                />
                            </ListItem>
                        )
                    })}
                </List>

                {filteredItems.length === 0 && <Empty message={noItemsText} />}
            </div>
        )
    }
}
