import { ESLCoverageConditionType } from './esl-coverage-condition-type'

export interface ESLCoverageConditionVO {
    '@class': 'ru.crystals.setretailx.esl.coverage.vo.ESLCoverageConditionVO'
    id?: number
    type?: ESLCoverageConditionType
    value?: string
}

export const createESLCoverageConditionVO = (initialization: Partial<ESLCoverageConditionVO>): ESLCoverageConditionVO => {
    if (!initialization) return null
    
    let result: ESLCoverageConditionVO = {
        id: initialization.id,
        type: initialization.type,
        value: initialization.value,
        '@class': 'ru.crystals.setretailx.esl.coverage.vo.ESLCoverageConditionVO',
    }
    
    return result
}
