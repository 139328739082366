import React from 'react'
import { PageMock } from '../../components/page-mock/page-mock'
import {
    DATA,
    EDIT_ERP_LIST,
    EDIT_PROCESSING_LIST,
    EXTERNAL_PROCESSINGS,
    IMPORT,
    IMPORT_EXPORT,
    INTEGRATION,
    REFUSED_PRODUCTS,
    SETTINGS,
    VENDORS,
    getRouteName,
} from '../core/app-routes'
import { RouteScheme } from '../utils/route-modules-util'
import { lazy } from '../utils/code-splitting'
import SettingsEthernet from '@material-ui/icons/SettingsEthernet'
import { config } from '../config/config'
import { SETRETAILX_ERP_ADMIN, SETRETAILX_EXTERNALSYSTEMS_ADMIN } from '../core/privileges/privileges'
import { MenuNotification } from '../components/pages-container/notifications/menu-notification'
import {
    REFUSED_PRODUCTS as REFUSED_PRODUCTS_NOTIFICATIONS,
} from '../store/notification-store'

export const integrationRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'integrationNavLink',
        label: getRouteName(INTEGRATION),
        icon: <SettingsEthernet/>,
    },
    path: INTEGRATION,
    children: [
        {
            navItemProps: {
                id: 'integrationVendorsNavLink',
                label: getRouteName(VENDORS),
            },
            path: `${INTEGRATION}${VENDORS}`,
            privileges: [],
            component: lazy(() => import(/* webpackChunkName: "vendors" */ '../pages/vendors/vendors')),
        },
        {
            path: `${INTEGRATION}${VENDORS}/:vendor`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "vendor-page" */ '../pages/vendors/vendor-page/vendor-page'))
        },
        {
            navItemProps: {
                id: 'integrationImportExportNavLink',
                label: getRouteName(IMPORT_EXPORT),
            },
            privileges: [SETRETAILX_ERP_ADMIN],
            path: `${INTEGRATION}${IMPORT_EXPORT}`,
            component: lazy(() =>
                import(/* webpackChunkName: "erp-list" */ '../pages/integration/import-export/erp-list'))
        },
        {
            privileges: [SETRETAILX_ERP_ADMIN],
            cannotBeRedirectTarget: true,
            path: `${INTEGRATION}${IMPORT_EXPORT}${EDIT_ERP_LIST}`,
            component: lazy(() =>
                import(/* webpackChunkName: "edit-erp-list" */ '../pages/integration/import-export/edit-erp-list'))
        },
        {
            path: `${INTEGRATION}${IMPORT_EXPORT}/:erp`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "erp-view" */ '../pages/integration/import-export/erp-view'))
        },
        {
            navItemProps: {
                id: 'integrationExternalProcessingsNavLink',
                label: getRouteName(EXTERNAL_PROCESSINGS),
            },
            privileges: [SETRETAILX_EXTERNALSYSTEMS_ADMIN],
            path: `${INTEGRATION}${EXTERNAL_PROCESSINGS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "processing-list" */ '../pages/integration/external-processings/processing-list'))
        },
        {
            path: `${INTEGRATION}${EXTERNAL_PROCESSINGS}${EDIT_PROCESSING_LIST}`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_ERP_ADMIN],
            component: lazy(() =>
                import(/* webpackChunkName: "edit-processing-list" */ '../pages/integration/external-processings/edit-processing-list'))
        },
        {
            path: `${INTEGRATION}${EXTERNAL_PROCESSINGS}/:processing`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "processing-view" */ '../pages/integration/external-processings/processing-view'))
        },
        {
            navItemProps: {
                id: 'integrationRefusedProductsNavLink',
                label: getRouteName(REFUSED_PRODUCTS),
                endAdornment: <MenuNotification notificationType={REFUSED_PRODUCTS_NOTIFICATIONS}/>,
            },
            path: `${INTEGRATION}${REFUSED_PRODUCTS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "rejected-products" */ '../pages/integration/rejected-products/rejected-products'))
        },
    ]
})
