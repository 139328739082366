import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { ShopAutocomplete } from '../../../../components/inputs/autocomplete-input/shop-autocomplete'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import {
    DATE_OD_MANUFACTURE_CHANGING,
    DATE_OF_MANUFACTURE_LOADING,
    PRICE_DISPLAYING,
    PriceCheckerType
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-checker-type'
import { ScalesTemplateVO } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-template-vo'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { AppStore } from '../../../store/app-store'
import {APP_STORE, SHOP_SCALES_STORE, SHOP_PRICE_CHECKERS_STORE} from '../../../store/stores'
import {ShopPriceCheckersStore} from '../../../store/shops/shop-settings/shop-price-checkers-store'
import {DeviceSettingsType} from '../../../core/values'
import {ShopScalesStore} from '../../../store/shops/shop-settings/shop-scales-store'
import {config} from '../../../config/config'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { SCALES_MODULE, TEMPLATES } from '../../../core/app-routes'
import { goTo } from '../../../utils/router-util'

const styles = require('./cash-settings-dialog.scss')

interface PriceCheckerSettingsDialogProps {
    shopPriceCheckersStore?: ShopPriceCheckersStore
    shopScalesStore?: ShopScalesStore
    app?: AppStore
}

interface PriceCheckerSettingsDialogState {
    autocompleteOpen: boolean
}

@inject(SHOP_PRICE_CHECKERS_STORE)
@inject(SHOP_SCALES_STORE)
@inject(APP_STORE)
@observer
export class PriceCheckerSettingsDialog extends React.Component<PriceCheckerSettingsDialogProps, PriceCheckerSettingsDialogState> {

    state: PriceCheckerSettingsDialogState = {
        autocompleteOpen: false
    }

    handleSaveButton = () => {
        const { savePriceChecker, priceCheckerSettings } = this.props.shopPriceCheckersStore
        savePriceChecker(toJS(priceCheckerSettings))
        this.handleCloseDalog()
    }

    handleNewModel = () => {
        const {closePriceCheckerSettings, showPriceCheckersDevicesDialog} = this.props.shopPriceCheckersStore
        closePriceCheckerSettings()
        showPriceCheckersDevicesDialog()
    }

    handleNewTemplate = () => {
        this.props.shopPriceCheckersStore.closePriceCheckerSettings()
        goTo(`${SCALES_MODULE}${TEMPLATES}`)
    }

    handleCloseDalog = () => {
        this.props.shopPriceCheckersStore.closePriceCheckerSettings()
        this.setState({autocompleteOpen: false})
    }

    getTemplateInput = () => {
        const {priceCheckerSettings, updatePriceChecker, priceCheckerSettingsType} = this.props.shopPriceCheckersStore
        const {scalesTemplates} = this.props.shopScalesStore

        if (priceCheckerSettings.scalesTemplateId !== -1) {
            return (
                <SelectInput<DefaultSelectOption<number>>
                    id="dialogTemplateInput"
                    onSelect={selectedOption => updatePriceChecker({ scalesTemplateId: selectedOption.value })}
                    label={t('shopPage.deviceTemplate')}
                    className={styles.dialogInputs}
                    options={scalesTemplates.map((template: ScalesTemplateVO) => {
                        return { label: template.name, value: template.id }
                    })}
                    value={priceCheckerSettings.scalesTemplateId}
                />
            )
        }
        return (
            <TextField
                id="dialogNoTemplateInput"
                fullWidth
                className={styles.dialogInputs}
                label={t('shopPage.deviceTemplate')}
                value={t('shopPage.noScalesTemplates')}
                required={priceCheckerSettingsType === DeviceSettingsType.NEW}

                InputProps={{
                    error: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip
                                enterDelay={TOOLTIP_DELAY}
                                title={t('shopPage.addNewTemplate')}
                            >
                                <IconButton
                                  onClick={this.handleNewTemplate}
                                >
                                  <AddCircleOutline />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
            />
        )
    }

    render() {
        const {autocompleteOpen} = this.state
        const {
            priceCheckerSettings, priceCheckerSettingsType,
            priceCheckerValidation, priceCheckersModels, updatePriceChecker, updatePriceCheckerType,
        } = this.props.shopPriceCheckersStore
        const {isCentrum} = this.props.app
        const editMode = priceCheckerSettingsType === DeviceSettingsType.EDIT

        if (!priceCheckerSettings || !priceCheckerValidation) return null

        const numberValidaton = priceCheckerValidation.getValidationFor('number')
        const nameValidaton = priceCheckerValidation.getValidationFor('name')
        const macValidation = priceCheckerValidation.getValidationFor('mac')

        let saveButtonAvailable = priceCheckerValidation.modified && priceCheckerValidation.valid

        return (
            <AdaptiveDialog
                id="fullScreenDialog"
                open={Boolean(priceCheckerSettings)}
                onClose={priceCheckerValidation.modified ? null : this.handleCloseDalog}
                className={styles.dialog}
            >
                <DialogTitle>
                    {editMode ? t('shopPage.priceCheckerTitleEdit') : t('shopPage.priceCheckerTitleNew')}
                </DialogTitle>
                <DialogContent className={styles.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <NumberInput
                            id="dialogNumberInput"
                            value={priceCheckerSettings.number}
                            label={t('shopPage.deviceNumber')}
                            required={!editMode}
                            autoFocus
                            maxLength={20}
                            onValueChange={value => updatePriceChecker({number: value})}
                            errorText={numberValidaton.touched && numberValidaton.firstError}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="dialogNameInput"
                            value={priceCheckerSettings.name}
                            label={t('common.name')}
                            errorText={nameValidaton.touched && nameValidaton.firstError}
                            onValueChange={name => updatePriceChecker({name})}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    {
                        priceCheckersModels.length > 0 ? (
                            <SelectInput<DefaultSelectOption<number>>
                                id="dialogModelInput"
                                onSelect={selectedOption => updatePriceChecker({ equipmentModelId: selectedOption.value })}
                                label={t('shopPage.deviceModel')}
                                required={!editMode}
                                className={styles.dialogInputs}
                                options={priceCheckersModels.map(model => {
                                    return {label: model.localizedName, value: model.id}
                                })}
                                value={priceCheckerSettings.equipmentModelId}
                            />
                        ) : (
                            <TextField
                                id="dialogNoModelInput"
                                fullWidth
                                required
                                className={styles.dialogInputs}
                                label={t('shopPage.deviceModel')}
                                value={t('shopPage.noModels')}

                                InputProps={{
                                    error: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                enterDelay={TOOLTIP_DELAY}
                                                title={t('shopPage.addNewModel')}
                                            >
                                                <IconButton
                                                  onClick={this.handleNewModel}
                                                >
                                                  <AddCircleOutline />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="dialogMacInput"
                            value={priceCheckerSettings.mac}
                            label={t('shopPage.macAddress')}
                            required={!editMode}
                            onValueChange={mac => updatePriceChecker({mac})}
                            errorText={macValidation.touched && macValidation.firstError}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectInput<DefaultSelectOption<PriceCheckerType>>
                            id="dialogTypeInput"
                            onSelect={selectedOption => updatePriceCheckerType(selectedOption.value)}
                            label={t('shopPage.priceCheckerType')}
                            disabled={isCentrum}
                            className={styles.dialogInputs}
                            options={[
                                {label: t('shopPage.priceCheckerDisplay'), value: PRICE_DISPLAYING},
                                {label: t('shopPage.priceCheckerChanging'), value: DATE_OD_MANUFACTURE_CHANGING},
                                {label: t('shopPage.priceCheckerLoading'), value: DATE_OF_MANUFACTURE_LOADING}
                            ]}
                            value={priceCheckerSettings.type}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            isCentrum && (autocompleteOpen ? (
                                <ShopAutocomplete
                                    id="shopNumberAutocomplete"
                                    autoFocus
                                    label={t('shopPage.shopNumber')}
                                    suggestionsLimit={3}
                                    onSuggestionSelect={selection => updatePriceChecker({shopNumber: selection.number})}
                                    onClose={() => this.setState({autocompleteOpen: false})}
                                />
                            ) : (
                                <TextField
                                    id="shopNumberInput"
                                    label={t('shopPage.shopNumber')}
                                    value={priceCheckerSettings.shopNumber !== -1 ? priceCheckerSettings.shopNumber : ''}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                  onClick={() => this.setState({autocompleteOpen: true})}
                                                >
                                                  <EditIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )
                        )}
                        {
                            !isCentrum && priceCheckerSettings.type === DATE_OF_MANUFACTURE_LOADING && this.getTemplateInput()
                        }
                    </Grid>
                </Grid>
            </DialogContent>
                <DialogActions className={styles.actions}>
                    <Button
                        onClick={this.handleCloseDalog}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={this.handleSaveButton}
                        color="primary"
                        disabled={!saveButtonAvailable}
                        id="dialogSaveButton"
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
