import React from 'react'
import {
    CUSTOM_PRINTING,
    DIRECTIVE_PRINTING,
    ELECTRONIC_PRICE_TAGS,
    NOT_ACTUAL,
    NOT_BINDED,
    NOT_PRINTED,
    PRICE_TAG_FORMATS,
    PRICE_TAGS,
    PRINTING,
    SETTINGS,
    TEMPLATES,
    FORMAT_MATRIX_SETTINGS,
    TOPOLOGIES,
    SCHEDULES,
    HISTORY,
    getRouteName,
    getCustomRouteName, FROM_PRODUCT_CARD
} from '../core/app-routes'
import {
    SETRETAILX_ESL_ADMINISTRATION,
    SETRETAILX_MODULESCONFIG,
    SETRETAILX_PRICE_TEMPLATE_ADMIN,
    SETRETAILX_PRICE_TEMPLATE_PRICETAG_FORMAT_ADMINISTRATION,
    SETRETAILX_ESL_HAND_SEND,
    SETRETAILX_DIRECTIVE_PRINTING_VISIBLE_PRIVELEGE,
    SETRETAILX_PRINT_PRINT_LOG
} from '../core/privileges/privileges'
import { RETAIL, RouteScheme, CENTRUM } from '../utils/route-modules-util'
import { lazy } from '../utils/code-splitting'
import { AppStore } from '../store/app-store'
import { getStore } from '../store/stores-repository'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import { APP_STORE } from '../store/stores'
import Print from '@material-ui/icons/Print'
import { MenuNotification } from '../components/pages-container/notifications/menu-notification'
import {
    NOT_PRINTED_PRICE_TAGS, CUSTOM_PRICE_TAGS_PRINTING, NOT_ACTUAL_PRICE_TAGS, PRINT_ERRORS
} from '../store/notification-store'

const priceTagFormatsModuleAvailable = (privilegeManager: PrivilegeManager) => {
    const appStore = getStore<AppStore>(APP_STORE)
    if (!appStore || !appStore.printingModuleProperties) return false

    const moduleActive = appStore.printingModuleProperties.formatModuleEnabled
    if (!moduleActive) return false

    const gotPrivileges = privilegeManager.havePrivilege(SETRETAILX_PRICE_TEMPLATE_ADMIN)
        && privilegeManager.havePrivilege(SETRETAILX_PRICE_TEMPLATE_PRICETAG_FORMAT_ADMINISTRATION)

    return gotPrivileges
}

export const priceTagsRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'priceTagsNavLink',
        label: getRouteName(PRICE_TAGS),
        icon: <Print/>,
    },
    path: PRICE_TAGS,
    children: [
        {
            navItemProps: {
                id: 'priceTagPrintingNotPrintedNavLink',
                label: getCustomRouteName(`${PRICE_TAGS}${NOT_PRINTED}`),
                endAdornment: <MenuNotification notificationType={NOT_PRINTED_PRICE_TAGS}/>,
            },
            path: `${PRICE_TAGS}${NOT_PRINTED}`,
            serverType: RETAIL,
            component: lazy(() =>
                import(/* webpackChunkName: "not-printed-price-tags" */ '../pages/price-tags/not-printed-price-tags/not-printed-price-tags'))
        },
        {
            navItemProps: {
                id: 'priceTagPrintingCustomPrintingNavLink',
                label: getRouteName(CUSTOM_PRINTING),
                endAdornment: <MenuNotification notificationType={CUSTOM_PRICE_TAGS_PRINTING}/>,
            },
            path: `${PRICE_TAGS}${CUSTOM_PRINTING}`,
            serverType: RETAIL,
            component: lazy(() => import(/* webpackChunkName: "custom-printing" */ '../pages/price-tags/custom-printing/custom-printing'))
        },
        {
            path: `${PRICE_TAGS}${CUSTOM_PRINTING}${FROM_PRODUCT_CARD}/:code`,
            serverType: RETAIL,
            component: lazy(() =>
                import(/* webpackChunkName: "custom-printing-from-product-card" */ '../pages/price-tags/custom-printing/custom-printing'))
        },
        {
            navItemProps: {
                id: 'priceTagPrintingNotActualNavLink',
                label: getCustomRouteName(`${PRICE_TAGS}${NOT_ACTUAL}`),
                endAdornment: <MenuNotification notificationType={NOT_ACTUAL_PRICE_TAGS}/>,
            },
            path: `${PRICE_TAGS}${NOT_ACTUAL}`,
            serverType: RETAIL,
            component: lazy(() =>
                import(/* webpackChunkName: "not-actual-price-tags" */ '../pages/price-tags/not-actual-price-tags/not-actual-price-tags')),
        },
        {
            path: `${PRICE_TAGS}/:shelf${PRINTING}`,
            serverType: RETAIL,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "not-printed-price-tags" */ '../pages/price-tags/printing/printing')),
        },
        {
            navItemProps: {
                id: 'priceTagPrintingHistoryNavLink',
                label: getCustomRouteName(`${PRICE_TAGS}${HISTORY}`),
                endAdornment: <MenuNotification notificationType={PRINT_ERRORS}/>,
            },
            path: `${PRICE_TAGS}${HISTORY}`,
            serverType: RETAIL,
            privileges: [SETRETAILX_PRINT_PRINT_LOG],
            component: lazy(() =>
                import(/* webpackChunkName: "printing-history" */ '../pages/price-tags/printing-history/printing-history'))
        },
        {
            navItemProps: {
                id: 'priceTagTemplatesNavLink',
                label: getRouteName(TEMPLATES),
            },
            path: `${PRICE_TAGS}${TEMPLATES}`,
            privileges: [SETRETAILX_PRICE_TEMPLATE_ADMIN],
            component: lazy(() =>
                import(/* webpackChunkName: "price-tags-templates" */ '../pages/price-tags/price-tags-templates/price-tags-templates')),
        },
        {
            path: `${PRICE_TAGS}${TEMPLATES}/:id`,
            privileges: [SETRETAILX_PRICE_TEMPLATE_ADMIN],
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "price-tag-settings" */ '../pages/price-tags/price-tag-settings/price-tag-settings')),

        },
        {
            path: `${PRICE_TAGS}${TEMPLATES}/:id/editor`,
            privileges: [SETRETAILX_PRICE_TEMPLATE_ADMIN],
            cannotBeRedirectTarget: true,
            component: lazy(() =>  import(/* webpackChunkName: "price-tag-editor" */ '../pages/price-tags/editor/price-tags-editor'))
        },
        {
            navItemProps: {
                id: 'priceTagFormatsNavLink',
                label: getRouteName(PRICE_TAG_FORMATS),
            },
            path: `${PRICE_TAGS}${PRICE_TAG_FORMATS}`,
            serverType: CENTRUM,
            privileges: priceTagFormatsModuleAvailable,
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tag-formats-topologies" */ '../pages/price-tags/price-tag-formats-topologies/price-tag-formats-topologies')),
        },
        {
            path: `${PRICE_TAGS}${PRICE_TAG_FORMATS}/:id`,
            serverType: CENTRUM,
            privileges: priceTagFormatsModuleAvailable,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tag-format-settings" */ '../pages/price-tags/price-tag-format-settings/price-tag-format-settings')),
        },
        {
            navItemProps: {
                id: 'priceTagFormatsNavLink',
                label: getRouteName(PRICE_TAG_FORMATS),
            },
            // На ритейле нет списка топологий, сразу идут настройки форматов
            path: `${PRICE_TAGS}${PRICE_TAG_FORMATS}`,
            serverType: RETAIL,
            privileges: priceTagFormatsModuleAvailable,
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tag-format-settings-retail" */ '../pages/price-tags/price-tag-format-settings/price-tag-format-settings-retail')),
        },
        {
            path: `${PRICE_TAGS}${PRICE_TAG_FORMATS}/:formatId${FORMAT_MATRIX_SETTINGS}/:matrixId`,
            serverType: CENTRUM,
            privileges: priceTagFormatsModuleAvailable,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tag-format-matrix-settings" */ '../pages/price-tags/price-tag-format-settings/price-tag-format-matrices/matrix-settings')),
        },
        // На ритейле в URL нет formatId
        {
            path: `${PRICE_TAGS}${PRICE_TAG_FORMATS}${FORMAT_MATRIX_SETTINGS}/:matrixId`,
            serverType: RETAIL,
            privileges: priceTagFormatsModuleAvailable,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tag-format-matrix-settings" */ '../pages/price-tags/price-tag-format-settings/price-tag-format-matrices/matrix-settings')),
        },
        {
            navItemProps: {
                id: 'directivePrintingNavLink',
                label: getRouteName(DIRECTIVE_PRINTING),
            },
            path: `${PRICE_TAGS}${DIRECTIVE_PRINTING}${TOPOLOGIES}`,
            serverType: CENTRUM,
            privileges: [SETRETAILX_DIRECTIVE_PRINTING_VISIBLE_PRIVELEGE],
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tags-directive-printing-topologies" */ '../pages/price-tags/directive-printing/topologies/topologies'))
        },
        {
            path: `${PRICE_TAGS}${DIRECTIVE_PRINTING}${TOPOLOGIES}/:id`,
            serverType: CENTRUM,
            privileges: [SETRETAILX_DIRECTIVE_PRINTING_VISIBLE_PRIVELEGE],
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tags-directive-printing-topology-settings" */ '../pages/price-tags/directive-printing/topology-settings/topology-settings'))
        },
        {
            path: `${PRICE_TAGS}${DIRECTIVE_PRINTING}${SCHEDULES}/:topologyId`,
            serverType: CENTRUM,
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tags-directive-printing-schedules" */ '../pages/price-tags/directive-printing/printing-schedules/printing-schedules'))
        },
        {
            navItemProps: {
                id: 'directivePrintingNavLink',
                label: getRouteName(DIRECTIVE_PRINTING),
            },
            path: `${PRICE_TAGS}${DIRECTIVE_PRINTING}${SCHEDULES}`,
            serverType: RETAIL,
            component: lazy(() =>
                // tslint:disable-next-line
                import(/* webpackChunkName: "price-tags-directive-printing-schedules" */ '../pages/price-tags/directive-printing/printing-schedules/printing-schedules'))
        },
        {
            navItemProps: {
                id: 'electronicPriceTagsNavLink',
                label: getRouteName(ELECTRONIC_PRICE_TAGS),
            },
            path: `${PRICE_TAGS}${ELECTRONIC_PRICE_TAGS}`,
            privileges: (privilegeManager: PrivilegeManager) =>
                privilegeManager.haveAnyPrivilegeFromList([SETRETAILX_ESL_ADMINISTRATION, SETRETAILX_ESL_HAND_SEND]),
            component: lazy(() =>
                import(/* webpackChunkName: "electronic-price-tags" */ '../pages/price-tags/esl/electronic-price-tags'))
        },
        {
            path: `${PRICE_TAGS}${ELECTRONIC_PRICE_TAGS}/:tab`,
            privileges: (privilegeManager: PrivilegeManager) =>
                privilegeManager.haveAnyPrivilegeFromList([SETRETAILX_ESL_ADMINISTRATION, SETRETAILX_ESL_HAND_SEND]),
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "electronic-price-tags" */ '../pages/price-tags/esl/electronic-price-tags'))
        },
        {
            path: `${PRICE_TAGS}${NOT_BINDED}`,
            serverType: RETAIL,
            component: lazy(() =>
                import(/* webpackChunkName: "not-binded-products" */ '../pages/price-tags/products-with-not-binded-price-tags/not-binded-products'))
        },
        {
            navItemProps: {
                id: 'priceTagSettingsNavLink',
                label: getRouteName(SETTINGS),
            },
            path: `${PRICE_TAGS}${SETTINGS}`,
            privileges: [SETRETAILX_MODULESCONFIG],
            component: lazy(() =>
                import(/* webpackChunkName: "price-tags-modules-settings" */ '../pages/price-tags/settings/price-tags-module-settings'))
        }
    ]
})
