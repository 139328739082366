import { t } from 'i18next'
import {
    BonusDiscountType, CRM, CFT, PT, BONUS_INFORMIX, BONUS_SR10, BONUS_LOYMAX, BONUS_CFT, BONUS_AEROFLOT_CFT, BONUS_SIEBEL, SET_API
} from '../../../../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/bonus-discount-type'

export const getBonusNameByType = (type: BonusDiscountType): string => {
    switch (type) {
        case CRM:
            return t('advertisingActions.bonusDiscountResult.crm')
        case CFT:
            return t('advertisingActions.bonusDiscountResult.sberbank')
        case PT:
            return t('advertisingActions.bonusDiscountResult.plasTek')
        case BONUS_INFORMIX:
            return t('advertisingActions.bonusDiscountResult.bonusInformix')
        case BONUS_SR10:
            return t('advertisingActions.bonusDiscountResult.sr10')
        case BONUS_LOYMAX:
            return t('advertisingActions.bonusDiscountResult.loymax')
        case BONUS_CFT:
            return t('advertisingActions.bonusDiscountResult.cft')
        case BONUS_AEROFLOT_CFT:
            return t('advertisingActions.bonusDiscountResult.aeroflot')
        case BONUS_SIEBEL:
            return t('advertisingActions.bonusDiscountResult.siebel')
        case SET_API:
            return t('advertisingActions.bonusDiscountResult.setAPI')
        default:
            return ''
    }
}
