import { SalesOnCashDesk } from './sales-on-cash-desk'

export interface TotalSalesOnCashDesks {
    '@class': 'ru.crystals.operday.reports.gui.datastructs.TotalSalesOnCashDesks'
    totalProceeds?: number
    totalPurchasers?: number
    salesOnCashDesk?: SalesOnCashDesk[]
}

export const createTotalSalesOnCashDesks = (initialization: Partial<TotalSalesOnCashDesks>): TotalSalesOnCashDesks => {
    if (!initialization) return null
    
    let result: TotalSalesOnCashDesks = {
        totalProceeds: initialization.totalProceeds,
        totalPurchasers: initialization.totalPurchasers,
        salesOnCashDesk: initialization.salesOnCashDesk,
        '@class': 'ru.crystals.operday.reports.gui.datastructs.TotalSalesOnCashDesks',
    }
    
    return result
}
