export interface JuristicPersonVO {
    '@class': 'ru.crystals.setretailx.topology.JuristicPersonVO'
    id?: number
    inn?: string
    kpp?: string
    physicalAddress?: string
    juristicAddress?: string
    juristicPersonName?: string
    telephoneNumber?: string
    okpo?: string
    okdp?: string
    defaultJP?: boolean
    deleted?: boolean
    directorFullName?: string
    accountantFullName?: string
    simplifiedTaxTreatment?: boolean
}

export const createJuristicPersonVO = (initialization: Partial<JuristicPersonVO>): JuristicPersonVO => {
    if (!initialization) return null
    
    let result: JuristicPersonVO = {
        id: initialization.id,
        inn: initialization.inn,
        kpp: initialization.kpp,
        physicalAddress: initialization.physicalAddress,
        juristicAddress: initialization.juristicAddress,
        juristicPersonName: initialization.juristicPersonName,
        telephoneNumber: initialization.telephoneNumber,
        okpo: initialization.okpo,
        okdp: initialization.okdp,
        defaultJP: initialization.defaultJP,
        deleted: initialization.deleted,
        directorFullName: initialization.directorFullName,
        accountantFullName: initialization.accountantFullName,
        simplifiedTaxTreatment: initialization.simplifiedTaxTreatment,
        '@class': 'ru.crystals.setretailx.topology.JuristicPersonVO',
    }
    
    return result
}
