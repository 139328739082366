import React, { FC } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { t } from 'i18next'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { isNil } from 'lodash'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { PeriodUnitType } from '../../../../../../../core/advertising-actions/action-conditions'
import InfoIcon from '@material-ui/icons/Info'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import {
    ActionRestriction,
    ChequeAdvertData,
    ClientRestriction
} from '../../../../../../../core/advertising-actions/advertising-actions'

const styles = require('./restrictions.scss')

const DAYS = 'DAYS'
const WEEKS = 'WEEKS'
const MONTHS = 'MONTHS'
const MAX_VALUE = 'maxValue'
const PERIOD = 'period'
export const TRUE = 'true'
export const FALSE = 'false'

interface RestrictionsProps {
    readOnly: boolean
    editorKey: number,
    clientRestriction?: Partial<ClientRestriction>,
    actionRestriction?: Partial<ActionRestriction>,
    allowOffline?: string,
    onChangeRestrictions: (changes: Partial<ChequeAdvertData>) => void
}

export const Restrictions: FC<RestrictionsProps> = ({
    readOnly,
    editorKey,
    clientRestriction,
    actionRestriction,
    allowOffline,
    onChangeRestrictions
}) => {
    const getAllPeriodOfAction = (type: string, value: number): string[] => {
        if (type === MAX_VALUE && value) {
            if (clientRestriction?.period?.[0] && clientRestriction?.period?.[0] !== '') return [FALSE]
            else return [TRUE]
        }

        if (type === PERIOD) {
            if (!value && clientRestriction?.maxValue?.[0]) return [TRUE]
            return [FALSE]
        }

        return
    }

    const getPeriodType = (period: number): string[] => {
        if (!period) {
            return ['']
        } else if (period && clientRestriction?.periodType?.[0]) {
            return [clientRestriction?.periodType?.[0]]
        } else {
            return [PeriodUnitType.Days]
        }
    }

    return (
        <Box p={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className={styles.header}>
                    {t('actionResults.chequeAdvertising.byClient')}
                </Grid>

                <Grid item sm={2} className={styles.text}>
                    {t('actionResults.chequeAdvertising.isActing')}
                </Grid>
                <Grid item sm={2}>
                    <NumberInput
                        key={editorKey}
                        placeholder="∞"
                        canBeNull
                        min={1}
                        slim
                        disabled={readOnly}
                        value={isNil(clientRestriction?.maxValue) || clientRestriction?.maxValue?.[0] === ''
                            ? null
                            : +clientRestriction?.maxValue?.[0]}
                        onValueChange={value => {
                            onChangeRestrictions({
                                clientRestriction: [{
                                    ...clientRestriction,
                                    maxValue: isNil(value) ? [''] : [value.toString()],
                                    allPeriodOfAction: getAllPeriodOfAction(MAX_VALUE, value)
                                }]
                            })
                        }}
                    />
                </Grid>
                <Grid item sm={8} className={styles.text}>
                    {t('actionResults.chequeAdvertising.once', { count: +clientRestriction?.maxValue?.[0] })}
                </Grid>

                <Grid item sm={2} className={styles.text}>
                    {t('actionResults.chequeAdvertising.forEvery', { count: +clientRestriction?.period?.[0] })}
                </Grid>
                <Grid item sm={2}>
                    <NumberInput
                        key={editorKey}
                        placeholder="∞"
                        canBeNull
                        min={1}
                        slim
                        disabled={readOnly}
                        value={isNil(clientRestriction?.period) || clientRestriction?.period?.[0] === ''
                            ? null
                            : +clientRestriction?.period?.[0]}
                        onValueChange={value => {
                            onChangeRestrictions({
                                clientRestriction: [{
                                    ...clientRestriction,
                                    period: isNil(value) ? [''] : [value.toString()],
                                    periodType: getPeriodType(value),
                                    allPeriodOfAction: getAllPeriodOfAction(PERIOD, value)
                                }]
                            })
                        }}
                    />
                </Grid>
                <Grid item sm={2}>
                    <SelectInput
                        key={editorKey}
                        slim
                        disabled={readOnly}
                        options={getDaysOptions(+clientRestriction?.period?.[0])}
                        value={clientRestriction?.periodType?.[0] || [PeriodUnitType.Days]}
                        onSelect={option => {
                            onChangeRestrictions({
                                clientRestriction: [{
                                    ...clientRestriction,
                                    periodType: [option.value]
                                }]
                            })
                        }}
                    />
                </Grid>
                <Grid item sm={6} className={styles.text}>
                    {t('actionResults.chequeAdvertising.fromStartAction')}
                </Grid>

                <Grid item sm={1} className={styles.infoIcon}>
                    <InfoIcon />
                </Grid>
                <Grid item sm={9} className={styles.infoText}>
                    <span className={styles.attentionText}>
                        {t('actionResults.chequeAdvertising.attention')}
                    </span>
                    <span>
                        {t('actionResults.chequeAdvertising.info')}
                    </span>
                </Grid>

                <Grid item xs={12} sm={12} className={styles.header}>
                    {t('actionResults.chequeAdvertising.byAction')}
                </Grid>

                <Grid item sm={4} className={styles.text}>
                    {t('actionResults.chequeAdvertising.maxNumberOfTimes')}
                </Grid>
                <Grid item sm={2}>
                    <NumberInput
                        key={editorKey}
                        slim
                        placeholder="∞"
                        canBeNull
                        min={1}
                        disabled={readOnly}
                        value={isNil(actionRestriction?.maxValue) || actionRestriction?.maxValue?.[0] === ''
                            ? null
                            : +actionRestriction?.maxValue?.[0]}
                        onValueChange={value => {
                            onChangeRestrictions({
                                actionRestriction: [{
                                    maxValue: isNil(value) ? [''] : [value.toString()]
                                }]
                            })
                        }}
                    />
                </Grid>
                <Grid item sm={6} className={styles.text}>
                    {t('actionResults.chequeAdvertising.allPeriod')}
                </Grid>

                <Grid item sm={11}>
                    <Checkbox
                        disabled={readOnly}
                        color="primary"
                        label={t('actionResults.chequeAdvertising.providePreference')}
                        checked={allowOffline === TRUE}
                        onValueChange={value => {
                            onChangeRestrictions({
                                allowOffline: [value.toString()]
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const getDaysOptions = (count: number) => {
    return [
        {
            label: count
                ? t('actionResults.chequeAdvertising.timeInterval.days', { count })
                : t('actionResults.chequeAdvertising.timeInterval.days'),
            value: DAYS
        },
        {
            label: count
                ? t('actionResults.chequeAdvertising.timeInterval.weeks', { count })
                : t('actionResults.chequeAdvertising.timeInterval.weeks'),
            value: WEEKS
        },
        {
            label: count
                ? t('actionResults.chequeAdvertising.timeInterval.months', { count })
                : t('actionResults.chequeAdvertising.timeInterval.months'),
            value: MONTHS
        }
    ]
}
