export interface ServiceProviderSettingVO {
    '@class': 'ru.crystals.setretailx.external.datatypes.serviceproviders.vo.ServiceProviderSettingVO'
    id?: number
    name?: string
    value?: string
    required?: boolean
    shop?: number
    custom?: boolean
}

export const createServiceProviderSettingVO = (initialization: Partial<ServiceProviderSettingVO>): ServiceProviderSettingVO => {
    if (!initialization) return null
    
    let result: ServiceProviderSettingVO = {
        id: initialization.id,
        name: initialization.name,
        value: initialization.value,
        required: initialization.required,
        shop: initialization.shop,
        custom: initialization.custom,
        '@class': 'ru.crystals.setretailx.external.datatypes.serviceproviders.vo.ServiceProviderSettingVO',
    }
    
    return result
}
