import React from 'react'
import {
    BANK_TRANSACTIONS,
    CHECK,
    CHECKS,
    NON_FISCAL_DOCUMENTS,
    OPERDAY,
    REFUND,
    SETTINGS,
    SHIFTS,
    getRouteName,
    UPD_REGISTRY, CASHIERS,
} from '../core/app-routes'
import { lazy } from '../utils/code-splitting'
import { RouteScheme } from '../utils/route-modules-util'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import {
    OPERDAY_CHECK_TRANSACTIONS,
    SETRETAILX_MODULESCONFIG,
    REVIEW_UPD_REGISTRY_PRIVILEGE,
    OPERDAY_CHECK_VIEW_PRIVILEGE,
    OPERDAY_OPERDAY_WORK_PRIVILEGE,
    OPERDAY_PROBLEMATIC_SHIFTS_WORK_PRIVILEGE,
    OPERDAY_DAY_TOTALS_WORK_PRIVILEGE,
    OPERDAY_REPORT_KM_3_PRIVILEGE,
    OPERDAY_REPORT_KM_6_PRIVILEGE,
    WORK_SHIFT_CASHIERS,
    OPERDAY_NON_FISCAL_DOCUMENTS_ISSUE_AN_ORDER,
    VALIDATION_MODULE,
} from '../core/privileges/privileges'
import LocalMall from '@material-ui/icons/LocalMall'

export const OPERDAY_PRIVILEGE_LIST: string[] = [
    OPERDAY_OPERDAY_WORK_PRIVILEGE,
    OPERDAY_PROBLEMATIC_SHIFTS_WORK_PRIVILEGE,
    OPERDAY_DAY_TOTALS_WORK_PRIVILEGE,
    OPERDAY_REPORT_KM_3_PRIVILEGE,
    OPERDAY_REPORT_KM_6_PRIVILEGE,
    WORK_SHIFT_CASHIERS,
]

export const operdayRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'operdayNavLink',
        label: getRouteName(OPERDAY),
        icon: <LocalMall/>,
    },
    path: OPERDAY,
    privileges: (privilegeManager: PrivilegeManager) => {
        return privilegeManager.haveAnyPrivilegeFromList([
            OPERDAY_CHECK_VIEW_PRIVILEGE,
            OPERDAY_CHECK_TRANSACTIONS,
            REVIEW_UPD_REGISTRY_PRIVILEGE,
            SETRETAILX_MODULESCONFIG,
            ...OPERDAY_PRIVILEGE_LIST
        ])
    },
    children: [
        {
            navItemProps: {
                id: 'operdayChecksSearchNavLink',
                label: getRouteName(CHECKS),
            },
            path: `${OPERDAY}${CHECKS}`,
            privileges: [OPERDAY_CHECK_VIEW_PRIVILEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "check-search" */ '../pages/operday/checks-search/checks-search'))
        },
        {
            path: `${OPERDAY}${CHECK}/:id`,
            privileges: [OPERDAY_CHECK_VIEW_PRIVILEGE],
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "check-page" */ '../pages/operday/check-page/check-page'))
        },
        {
            path: `${OPERDAY}${CHECK}/:id${REFUND}`,
            privileges: [OPERDAY_CHECK_VIEW_PRIVILEGE],
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "check-refund-page" */ '../pages/operday/check-refund-page/check-refund-page'))
        },
        {
            navItemProps: {
                id: 'operdayNonFiscalDocumentsSearchNavLink',
                label: getRouteName(NON_FISCAL_DOCUMENTS),
            },
            path: `${OPERDAY}${NON_FISCAL_DOCUMENTS}`,
            privileges: [OPERDAY_NON_FISCAL_DOCUMENTS_ISSUE_AN_ORDER],
            component: lazy(() =>
                import(/* webpackChunkName: "check-non-fiscal-documents" */ '../pages/operday/non-fiscal-documents/non-fiscal-documents-list'))
        },
        {
            path: `${OPERDAY}${NON_FISCAL_DOCUMENTS}/:id`,
            privileges: [OPERDAY_NON_FISCAL_DOCUMENTS_ISSUE_AN_ORDER],
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "check-non-fiscal-document" */ '../pages/operday/non-fiscal-documents/non-fiscal-document'))
        },
        {
            navItemProps: {
                id: 'operdayShiftsNavLink',
                label: getRouteName(SHIFTS),
            },
            path: `${OPERDAY}${SHIFTS}`,
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList(OPERDAY_PRIVILEGE_LIST)
            },
            component: lazy(() =>
                import(/* webpackChunkName: "operday-shifts" */ '../pages/operday/shifts/operday-info'))
        },
        {
            path: `${OPERDAY}${SHIFTS}/:ref`,
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList(OPERDAY_PRIVILEGE_LIST)
            },
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "cash-page" */ '../pages/operday/cash-page/cash-page'))
        },
        {
            path: `${OPERDAY}${SHIFTS}${CASHIERS}/:date/:tabNum`,
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList(OPERDAY_PRIVILEGE_LIST)
            },
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "cashier-page" */ '../pages/operday/shifts/cashier-page/cashier-page'))
        },
        {
            navItemProps: {
                id: 'operdayTransactionsSearchNavLink',
                label: getRouteName(BANK_TRANSACTIONS),
            },
            path: `${OPERDAY}${BANK_TRANSACTIONS}`,
            privileges: [OPERDAY_CHECK_TRANSACTIONS],
            component: lazy(() =>
                import(/* webpackChunkName: "transactions-search" */ '../pages/operday/transactions-search/transactions-search'))
        },
        {
            navItemProps: {
                id: 'operdayUPDRegistryNavLink',
                label: getRouteName(UPD_REGISTRY),
            },
            path: `${OPERDAY}${UPD_REGISTRY}`,
            privileges: [REVIEW_UPD_REGISTRY_PRIVILEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "upd-registry" */ '../pages/operday/upd-registry/upd-registry-search'))
        },
        {
            path: `${OPERDAY}${UPD_REGISTRY}/:id`,
            cannotBeRedirectTarget: true,
            privileges: [REVIEW_UPD_REGISTRY_PRIVILEGE],
            component: lazy(() =>
                import(/* webpackChunkName: "upd-registry-editing" */ '../pages/operday/upd-registry/upd-registry-editing'))
        },
        {
            navItemProps: {
                id: 'operdaySettingsNavLink',
                label: getRouteName(SETTINGS),
            },
            path: `${OPERDAY}${SETTINGS}`,
            privileges: [SETRETAILX_MODULESCONFIG],
            component: lazy(() =>
                import(/* webpackChunkName: "operday-settings" */ '../pages/operday/settings/settings'))
        },
        {
            path: `${OPERDAY}/:tab`,
            privileges: [SETRETAILX_MODULESCONFIG, VALIDATION_MODULE],
            component: lazy(() =>
                import(/* webpackChunkName: "operday-settings" */ '../pages/operday/settings/settings'))
        }
    ]
})
