import { ComplexReceiptSearchArgument } from './complex-receipt-search-argument'

export interface SearchArgumentsVO {
    '@class': 'ru.crystals.cards.coupons.vo.SearchArgumentsVO'
    names?: string[]
    partOfCouponName?: string
    numbers?: string[]
    usedReceipt?: ComplexReceiptSearchArgument
    distributionReceipt?: ComplexReceiptSearchArgument
    isUsed?: boolean
    dateOfUsageFrom?: Date
    dateOfUsageTo?: Date
    dateOfDistributionFrom?: Date
    dateOfDistributionTo?: Date
    maxCount?: number
    description?: string
    clientGuids?: number[]
}

export const createSearchArgumentsVO = (initialization: Partial<SearchArgumentsVO>): SearchArgumentsVO => {
    if (!initialization) return null
    
    let result: SearchArgumentsVO = {
        names: initialization.names,
        partOfCouponName: initialization.partOfCouponName,
        numbers: initialization.numbers,
        usedReceipt: initialization.usedReceipt,
        distributionReceipt: initialization.distributionReceipt,
        isUsed: initialization.isUsed,
        dateOfUsageFrom: initialization.dateOfUsageFrom,
        dateOfUsageTo: initialization.dateOfUsageTo,
        dateOfDistributionFrom: initialization.dateOfDistributionFrom,
        dateOfDistributionTo: initialization.dateOfDistributionTo,
        maxCount: initialization.maxCount,
        description: initialization.description,
        clientGuids: initialization.clientGuids,
        '@class': 'ru.crystals.cards.coupons.vo.SearchArgumentsVO',
    }
    
    return result
}
