import * as React from 'react'
import { t } from 'i18next'
import {inject, observer} from 'mobx-react'
import {Table} from '@crystalservice/crystals-ui/lib/components/table/table'
import {RIGHT, TEXT} from '@crystalservice/crystals-ui/lib/components/table/column'
import Button from '@material-ui/core/Button'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import {toJS} from 'mobx'
import Divider from '@material-ui/core/Divider'
import AddCircle from '@material-ui/icons/AddCircle'
import {PrinterSettingsDialog} from './printer-settings-dialog'
import {DialogStore} from '../../../store/dialog-store'
import {PrinterVO} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/printer-vo'
import {AdaptiveIconButton} from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {DIALOG} from '../../../../components/simple-dialog/simple-dialog'
import {CASHDOC} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/print-doc-type'
import { DIALOG_STORE, SHOP_PRINTERS_STORE } from '../../../store/stores'
import {ShopDevicesDialog} from './devices/shop-devices-dialog'
import { DevicesClasses } from '../../../core/devices-classes'
import {ShopPrintersStore} from '../../../store/shops/shop-settings/shop-printers-store'
import { Paginator } from '@crystalservice/crystals-ui/lib/components/table/paginator'

const styles = require('./printers-step.scss')

export interface PrintersStepProps {
    shopPrintersStore?: ShopPrintersStore
    dialogStore?: DialogStore
}

@inject(SHOP_PRINTERS_STORE)
@inject(DIALOG_STORE)
@observer
export class PrintersStep extends React.Component<PrintersStepProps> {

    componentDidMount(): void {
        const {fetchAllPrintersData} = this.props.shopPrintersStore
        fetchAllPrintersData()
    }

    showDeleteDialog = (printer: PrinterVO) => {
        this.props.dialogStore.showDialog({
            mode: DIALOG,
            title: t('shopPage.printerConfirmTitle'),
            message: t('shopPage.printerConfirmMessage'),
            onYes: () => this.props.shopPrintersStore.deletePrinter(printer)
        })
    }

    render() {
        const {
            sortedPrinters, editPrinter, addPrinter, showPrinterDevicesDialog, printerDevicesDialogOpen,
            closePrinterDevicesDialog, paginatorKey, paginationState, setPaginationState,
        }  = this.props.shopPrintersStore

        const addButtons = (
            <>
                <Button
                    id="addPrinterButton"
                    className={styles.addButton}
                    onClick={addPrinter}
                >
                    <AddCircle
                        className={styles.icon}
                        color="primary"
                    />
                    <span>
                        {t('shopPage.addPrinter')}
                    </span>
                </Button>

                <Button
                    id="registerDeviceButton"
                    className={styles.addButton}
                    onClick={showPrinterDevicesDialog}
                >
                    <AddCircle
                        className={styles.icon}
                        color="primary"
                    />
                    <span>
                        {t('shopPage.registerModel')}
                    </span>
                </Button>
            </>
        )

        return (
            <div
                id="printerPage"
                className={styles.printersStepContent}
            >
                <ShopDevicesDialog
                    deviceClass={DevicesClasses.PrinterDeviceClass}
                    title={t('devices.registeredPrinters')}
                    open={printerDevicesDialogOpen}
                    onClose={closePrinterDevicesDialog}
                />
                <PrinterSettingsDialog/>

                <p className={styles.header}>
                    {t('shopPage.printersStep')}
                </p>

                {sortedPrinters.length === 0 ? (
                    <div>
                        <Divider/>
                        <Empty message={t('shopPage.noAddedDevices')}/>
                        <Divider className={styles.emptyDivider}/>
                        {addButtons}
                    </div>
                ) : (
                    <Paginator
                        defaultItems={toJS(sortedPrinters)}
                        additionalControls={addButtons}
                        key={paginatorKey}
                        defaultPagination={paginationState}
                        onPageItemsChange={setPaginationState}
                    >
                        <Table
                            id="printersTable"
                            withHeader
                            collapsible
                            keyField="id"
                            columns={[
                                {
                                    header: t('common.name'),
                                    labelField: 'name',
                                    dataType: TEXT,
                                    width: '20%',
                                    padding: '16px 16px 16px 24px',
                                },
                                {
                                    header: t('shopPage.deviceModel'),
                                    labelFunction: (printer: PrinterVO) => printer.equipmentModel.localizedName,
                                    dataType: TEXT,
                                    width: '20%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.deviceAddressing'),
                                    labelField: 'address',
                                    dataType: TEXT,
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.priceTagTemplate'),
                                    renderer: itemProps => {
                                        let printer: PrinterVO = itemProps.item
                                        let priceTagName = null
                                        if (printer.printType === CASHDOC) {
                                            return <div>{t('shopPage.printerCashDoc')}</div>
                                        }
                                        let length = printer.priceTags && printer.priceTags.length
                                        if (length === 0) {
                                            return <div>{t('shopPage.printerNoPriceTag')}</div>
                                        }
                                        if (length < 5) {
                                            return (
                                                <div className={styles.tallCell}>
                                                    {
                                                        printer.priceTags.map((item, index) => {
                                                            return <p key={index}>{item.name}</p>
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        return <div>{t('shopPage.tooManyTags', {count: length})}</div>
                                    },
                                    dataType: TEXT,
                                    padding: '16px',
                                },
                                {
                                    width: '120px',
                                    padding: '0 10px',
                                    hAlign: RIGHT,
                                    showOnRowHover: true,
                                    withControls: true,
                                    renderer: itemProps => {
                                        let printer: PrinterVO = itemProps.item
                                        return (
                                            <div className={styles.adaptiveIcons}>
                                                <AdaptiveIconButton
                                                    id={`printer${printer.id}EditButton`}
                                                    label={t('common.edit')}
                                                    onClick={() => editPrinter(printer)}
                                                >
                                                    <EditIcon />
                                                </AdaptiveIconButton>
                                                <AdaptiveIconButton
                                                    id={`printer${printer.id}RemoveButton`}
                                                    label={t('common.remove')}
                                                    onClick={() => this.showDeleteDialog(printer)}
                                                >
                                                    <DeleteIcon />
                                                </AdaptiveIconButton>
                                            </div>
                                        )
                                    }
                                }
                            ]}
                        />
                    </Paginator>
                )}
            </div>
        )
    }
}
