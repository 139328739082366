import React, { ReactNode } from 'react'
import { observer, inject } from 'mobx-react'
import { t } from 'i18next'
import { Box, Typography, Paper, Grid, Button, Divider } from '@material-ui/core'
import { ActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/action-panel'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { PRICE_TAGS_ESL_CONFIGURATION_STORE, USER_STORE } from '../../../store/stores'
import { UserStore } from '../../../store/user-store'
import { PriceTagsEslConfigurationStore } from '../../../store/price-tags/price-tags-esl-configuration-store'
import {
    ESLDrivers,
    SesImagotagDriverOptions,
    HanshowDriverOptions,
    ZkongHTTPSDriverOptions,
    changesCheckingPeriods,
    ESLSesImagotag,
    ESLHanshow,
    ESLHanshowHTTPS,
    ESLZkong
} from '../../../core/price-tags/esl-configuration'
import {
    PropertyVO,
    createPropertyVO
} from '../../../../protocol/set10/set-retail10-server-frontend/codegen/external-src/set10-integration/set10dto/property-vo'
import { SETRETAILX_ESL_ADMINISTRATION, SETRETAILX_ESL_HAND_SEND } from '../../../core/privileges/privileges'
import { ProductsListInput } from '../../../../components/inputs/list-input/products-list-input'
import { toJS } from 'mobx'

const styles = require('./price-tags-esl-configuration.scss')

export interface PriceTagsEslConfigurationProps {
    priceTagsEslConfigurationStore?: PriceTagsEslConfigurationStore
    user?: UserStore
}

export const NOT_SELECTED: string = 'notSelected'
const MS_IN_MINUTE: number = 60 * 1000

const Row = ({ children, ...other }) => {
    return (
        <Grid
            item
            container
            spacing={2}
            alignItems="center"
            { ...other }
        >
            { children }
        </Grid>
    )
}

const Label: React.FC = ({ children }) => {
    return (
        <Typography variant="body2" color="textSecondary">
            { children }
        </Typography>
    )
}

@inject(PRICE_TAGS_ESL_CONFIGURATION_STORE)
@inject(USER_STORE)
@observer
export class PriceTagsEslConfiguration extends React.Component<PriceTagsEslConfigurationProps> {

    componentDidMount(): void {
        const { fetchConfiguration } = this.props.priceTagsEslConfigurationStore
        fetchConfiguration()
    }

    componentWillUnmount(): void {
        const { reset } = this.props.priceTagsEslConfigurationStore
        reset()
    }

    getInputValue = (key: SesImagotagDriverOptions | HanshowDriverOptions | ZkongHTTPSDriverOptions): string => {
        const { editedConfiguration } = this.props.priceTagsEslConfigurationStore

        let property: PropertyVO = editedConfiguration.driverConfig
            && editedConfiguration.driverConfig.find(p => p.key === key)
        if (!property) {
            return ''
        }
        return property.value
    }

    driverConfigPropChangeHandler = (value: string, key: SesImagotagDriverOptions | HanshowDriverOptions | ZkongHTTPSDriverOptions) => {
        const { editedConfiguration, editConfiguration } = this.props.priceTagsEslConfigurationStore

        let newDriverConfig: PropertyVO[] = toJS(editedConfiguration.driverConfig) || []
        // удаляем текущее значение
        newDriverConfig = newDriverConfig.filter(p => p.key !== key)
        // ставим новое значение, если инпут не пустой
        if (value) {
            newDriverConfig.push(createPropertyVO({
                key,
                value
            }))
        }

        editConfiguration({
            driverConfig: newDriverConfig
        })
    }

    renderESLSesImagotagForm = () => {
        const { editedConfiguration } = this.props.priceTagsEslConfigurationStore

        return (
            <Grid id="sesImagotagSettings" item container xs={12} spacing={2} direction="column">
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverAddress') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="sesImagotagURLInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(SesImagotagDriverOptions.URL)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, SesImagotagDriverOptions.URL)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.domain') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="sesImagotagDomainInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(SesImagotagDriverOptions.DOMAIN)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, SesImagotagDriverOptions.DOMAIN)}
                        />
                    </Grid>
                </Row>
            </Grid>
        )
    }

    renderESLHanshowForm = () => {
        const { editedConfiguration } = this.props.priceTagsEslConfigurationStore

        return (
            <Grid id="hanshowSettings" item container xs={12} spacing={2} direction="column">
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.ftpServerAddress') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowURLInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.FTP_URL)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.FTP_URL)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverUser') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowLoginInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.LOGIN)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.LOGIN)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverUserPassword') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowPasswordInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.PASSWORD)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.PASSWORD)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.uploadPath') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowUploadPathInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.UPLOAD_PATH)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.UPLOAD_PATH)}
                        />
                    </Grid>
                </Row>
            </Grid>
        )
    }

    renderESLHanshowHTTPSForm = () => {
        const { editedConfiguration } = this.props.priceTagsEslConfigurationStore

        return (
            <Grid id="hanshowHTTPSSettings" item container xs={12} spacing={2} direction="column">
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverAddress') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowHTTPSURLInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.URL)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.URL)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverUser') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowHTTPSLoginInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.USER_NAME)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.USER_NAME)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverUserPassword') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowHTTPSPasswordInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.PASSWORD)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.PASSWORD)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.storeCode') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowStoreCodeInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.STORE_CODE)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.STORE_CODE)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.customerStoreCode') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="hanshowCustomerStoreCodeInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(HanshowDriverOptions.CUSTOMER_STORE_CODE)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, HanshowDriverOptions.CUSTOMER_STORE_CODE)}
                        />
                    </Grid>
                </Row>
            </Grid>
        )
    }

    renderESLZkongHTTPSForm = () => {
        const { editedConfiguration } = this.props.priceTagsEslConfigurationStore

        return (
            <Grid id="zkongHTTPSettings" item container xs={12} spacing={2} direction="column">
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverAddress') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="zkongHTTPHostInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(ZkongHTTPSDriverOptions.HOST)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, ZkongHTTPSDriverOptions.HOST)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverUser') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="zkongHTTPAccountInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(ZkongHTTPSDriverOptions.ACCOUNT)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, ZkongHTTPSDriverOptions.ACCOUNT)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.serverUserPassword') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="zkongHTTPPasswordInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(ZkongHTTPSDriverOptions.PASSWORD)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, ZkongHTTPSDriverOptions.PASSWORD)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.agencyId') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="zkongHTTPAgencyIdInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(ZkongHTTPSDriverOptions.AGENCY_ID)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, ZkongHTTPSDriverOptions.AGENCY_ID)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.merchantId') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="zkongHTTPMerchantIdInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(ZkongHTTPSDriverOptions.MERCHANT_ID)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, ZkongHTTPSDriverOptions.MERCHANT_ID)}
                        />
                    </Grid>
                </Row>
                <Row>
                    <Grid item xs={12} md={5}>
                        <Label>
                            { t('priceTagsEsl.storeId') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField
                            id="zkongHTTPStoreIdInput"
                            disabled={editedConfiguration.invalidateAll}
                            value={this.getInputValue(ZkongHTTPSDriverOptions.STORE_ID)}
                            onValueChange={value => this.driverConfigPropChangeHandler(value, ZkongHTTPSDriverOptions.STORE_ID)}
                        />
                    </Grid>
                </Row>
            </Grid>
        )
    }

    renderESLSettings = (): ReactNode => {
        const { editedConfiguration, editConfiguration, invalidateAll } = this.props.priceTagsEslConfigurationStore
        const { havePrivilege } = this.props.user

        if (!havePrivilege(SETRETAILX_ESL_ADMINISTRATION)) return null

        return (
            <>

                {!editedConfiguration.invalidateAll && (
                    <Row>
                        <Grid item xs={12} md={5}>
                            <Label>
                                { t('priceTagsEsl.firstProductsUploading') }
                            </Label>
                        </Grid>
                        <Grid item container xs={12} md={7} justify="flex-start">
                            <Grid item>
                                <Button
                                    id="uploadProductsButton"
                                    color="primary"
                                    onClick={() => invalidateAll()}
                                >
                                    { t('priceTagsEsl.launch') }
                                </Button>
                            </Grid>
                        </Grid>
                    </Row>
                )}

                { this.renderManualUploadControls() }
                { this.renderUploadProgressMessage() }

                {editedConfiguration.driver && (
                    <>
                        <Row>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Row>
                        <Row>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">{ t('priceTagsEsl.eslSystemSettings') }</Typography>
                            </Grid>
                        </Row>
                    </>
                )}

                { editedConfiguration.driver === ESLDrivers.ESLSesImagotag && this.renderESLSesImagotagForm() }
                { editedConfiguration.driver === ESLDrivers.ESLHanshow && this.renderESLHanshowForm() }
                { editedConfiguration.driver === ESLDrivers.ESLHanshowHTTPS && this.renderESLHanshowHTTPSForm() }
                { editedConfiguration.driver === ESLDrivers.ESLZkong && this.renderESLZkongHTTPSForm() }

                <Row>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Row>

                <Row>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{ t('priceTagsEsl.commonSettings') }</Typography>
                    </Grid>
                </Row>

                <Row>
                    <Grid item xs={12} md={8}>
                        <Label>
                            { t('priceTagsEsl.eslChangeCheckingPeriod') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectInput<number>
                            id="longTimeoutSelect"
                            disabled={editedConfiguration.invalidateAll}
                            options={changesCheckingPeriods}
                            label={t('priceTagsEsl.minutesShort')}
                            value={editedConfiguration.longTimeout / MS_IN_MINUTE}
                            onSelect={selectedOption => {
                                editConfiguration({ longTimeout: selectedOption * MS_IN_MINUTE || changesCheckingPeriods[0] })
                            }}
                        />
                    </Grid>
                </Row>

                <Row>
                    <Grid item xs={12} md={8}>
                        <Label>
                            { t('priceTagsEsl.packageSize') }
                        </Label>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <NumberInput
                            disabled={editedConfiguration.invalidateAll}
                            id="packageSizeInput"
                            min={0}
                            max={1000000}
                            value={editedConfiguration.packageSizeForSend}
                            onValueChange={packageSizeForSend => editConfiguration({ packageSizeForSend })}
                        />
                    </Grid>
                </Row>

                <Row>
                    <Grid item xs={12}>
                        <Checkbox
                            disabled={editedConfiguration.invalidateAll}
                            id="preventDefaultPrintingCheckBox"
                            label={t('priceTagsEsl.eslPreventDefaultPrinting')}
                            className={styles.checkbox}
                            checked={editedConfiguration.coverageEnabled}
                            onValueChange={coverageEnabled => editConfiguration({ coverageEnabled })}
                        />
                    </Grid>
                </Row>

                <Row>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary">
                            { t('priceTagsEsl.needToRebootServerHint') }
                        </Typography>
                    </Grid>
                </Row>
            </>
        )
    }

    renderManualUploadControls = (): ReactNode => {
        const {
            editedConfiguration, updateProductsForManualUpload, launchManualUpload
        } = this.props.priceTagsEslConfigurationStore
        const { session } = this.props.user

        if (editedConfiguration.invalidateAll) return null

        return (
            <Row>
                <Grid item xs={12} md={5}>
                    <Label>
                        { t('priceTagsEsl.productsUploadToESL') }
                    </Label>
                </Grid>
                <Grid item container xs={12} md={7} justify="flex-start">
                    <Grid item>
                        <ProductsListInput
                            id="manualUploadListInput"
                            sessionId={session}
                            resetAfterValidation
                            CustomView={props => (
                                <Button
                                    id="manualUploadProductsButton"
                                    className={styles.uploadButton}
                                    color="primary"
                                    onClick={props.onClick}
                                >
                                    { t('priceTagsEsl.selectProducts') }
                                </Button>
                            )}
                            EditDialogProps={{
                                applyButtonLabel: t('priceTagsEsl.launchUpload')
                            }}
                            onChange={updateProductsForManualUpload}
                            onAllDialogsHide={launchManualUpload}
                        />
                    </Grid>
                </Grid>
            </Row>
        )
    }

    renderUploadProgressMessage = (): ReactNode => {
        const { editedConfiguration } = this.props.priceTagsEslConfigurationStore

        if (!editedConfiguration.invalidateAll) {
            return null
        }

        return (
            <Row>
                <Grid item xs={12}>
                    <Typography variant="body2" className={styles.productsLoadingHint}>
                        { t('priceTagsEsl.productsUploadInProcess') }
                    </Typography>
                </Grid>
            </Row>
        )
    }

    render() {
        const {
            editedConfiguration, editConfiguration, modified,
            saveConfiguration, cancelConfiguration
        } = this.props.priceTagsEslConfigurationStore
        const { havePrivilege, haveAnyPrivilegeFromList } = this.props.user

        if (!haveAnyPrivilegeFromList([SETRETAILX_ESL_ADMINISTRATION, SETRETAILX_ESL_HAND_SEND])) {
            return null
        }

        if (!editedConfiguration) {
            return (
                <Paper className={styles.priceTagsEslConfiguration}>
                    <Empty />
                </Paper>
            )
        }

        return (
            <Paper id="priceTagsEslConfiguration" className={styles.priceTagsEslConfiguration}>
                <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
                    <Box maxWidth={685} width={1} mx="auto" p={4}>
                        <Grid container spacing={2} direction="column">
                            <Row>
                                <Grid item xs={12} md={5}>
                                    <Typography variant="body2" color="textSecondary">
                                        { t('priceTagsEsl.eslSystem') }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <SelectInput<DefaultSelectOption<string | ESLDrivers>>
                                        id="driverSelect"
                                        disabled={editedConfiguration.invalidateAll}
                                        readonly={!havePrivilege(SETRETAILX_ESL_ADMINISTRATION) && havePrivilege(SETRETAILX_ESL_HAND_SEND)}
                                        options={[
                                            {
                                                value: NOT_SELECTED,
                                                label: t('priceTagsEsl.notSelected')
                                            },
                                            {
                                                value: ESLDrivers.ESLHanshow,
                                                label: ESLHanshow
                                            },
                                            {
                                                value: ESLDrivers.ESLHanshowHTTPS,
                                                label: ESLHanshowHTTPS
                                            },
                                            {
                                                value: ESLDrivers.ESLSesImagotag,
                                                label: ESLSesImagotag
                                            },
                                            {
                                                value: ESLDrivers.ESLZkong,
                                                label: ESLZkong
                                            }
                                        ]}
                                        value={editedConfiguration.driver || NOT_SELECTED}
                                        onSelect={selectedOption => {
                                            // На сервере невыбранный driver должен быть в виде пустой строки
                                            let newDriverValue: string = selectedOption.value === NOT_SELECTED
                                                ? ''
                                                : selectedOption.value
                                            editConfiguration({
                                                driver: newDriverValue,
                                                // обнуляем конфигурацию драйвера при переключении драйвера
                                                driverConfig: []
                                            })
                                        }}
                                    />
                                </Grid>
                            </Row>

                            { this.renderESLSettings() }

                            {
                                !havePrivilege(SETRETAILX_ESL_ADMINISTRATION) && havePrivilege(SETRETAILX_ESL_HAND_SEND) && (
                                    <>
                                        { this.renderManualUploadControls() }
                                        { this.renderUploadProgressMessage() }
                                    </>
                                )
                            }

                        </Grid>
                    </Box>
                </Box>

                <ActionPanel
                    color="secondary"
                >
                    <Box flexGrow={1} />
                    <Button
                        id="resetButton"
                        color="primary"
                        disabled={!modified}
                        onClick={() => cancelConfiguration()}
                    >
                        { t('common.cancelChanges') }
                    </Button>
                    <Button
                        id="saveButton"
                        color="primary"
                        variant="contained"
                        disabled={!modified}
                        onClick={() => saveConfiguration()}
                    >
                        { t('common.save') }
                    </Button>
                </ActionPanel>
            </Paper>
        )
    }
}
