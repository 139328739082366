import { ACTIVE, PRICE_TAG_NOT_PRINTED, ProductStatus } from '../../../core/products/product-statuses'
import { ProductVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import {
    GridData,
    PADDING_BOTTOM,
    PADDING_LEFT,
    PADDING_RIGHT,
    PADDING_TOP,
    ProductPriceChart, SalesPrice,
    salesPrices,
    warningIcon
} from './prices-charts'
import { t } from 'i18next'
import moment from 'moment'
import { fromClientToServerTime } from '../../../utils/app-util'

interface SpecificParams {
    priceStatus: ProductStatus,
    countablePrice: SalesPrice,
    isAlarmNotActivePrice: boolean,
    isExpiredPrice: boolean,
}

export function getProductPriceCharts(
    productDetails: Partial<ProductVO_SF>,
    gridData: GridData,
    priceStatus: ProductStatus,
    now: Date,
    selectedShop: number,
    startOfInterval: Date,
    endOfInterval: Date,
    department: string
): ProductPriceChart[] {
    const { grid, columnsCount, columnWidth } = gridData
    const priceCharts = []

    const pricesIdsByShop = productDetails && productDetails.prices && productDetails.prices.reduce((acc, price) => {
        if (price.shop && price.shop.number === selectedShop || !selectedShop) {
            acc.push(price.id)
        }
        return acc
    }, [])

    salesPrices.forEach((countablePrice: SalesPrice, priceNumber: number) => {
        if (!productDetails || !productDetails[countablePrice.name]) return

        const prices = productDetails[countablePrice.name].filter(price => !priceStatus || pricesIdsByShop.includes(price.id))

        prices
            .filter(price => !price.depart || price.depart === department)
            .forEach(price => {
            const serverPriceDateEnd = fromClientToServerTime(price.dateEnd)
            const serverPriceDateStart = fromClientToServerTime(price.dateStart)

            const calcDateEnd = serverPriceDateEnd && serverPriceDateEnd.getTime()
            const isAlarmNotActivePrice = priceStatus === PRICE_TAG_NOT_PRINTED && serverPriceDateStart < now
            const isExpiredPrice = Boolean(calcDateEnd) && calcDateEnd < now.getTime()

            const {
                cellInterval,
                countInterval,
            } = grid[0]
            // показывает "количество времени" в одной ячейке - не надо приводить к серверному времени
            const cellWidthInTime = moment(new Date(grid[0].date))
                .diff(moment(new Date(grid[0].date)).subtract(countInterval, cellInterval))

            const startingCell = grid.find(cell => {
                // + cellWidthInTime, чтобы график отрисовался в последний период диапазона
                const cellDate = fromClientToServerTime(new Date(cell.date)).getTime() + cellWidthInTime

                const endDateApprove =
                    cellDate < calcDateEnd ||
                    !calcDateEnd ||
                    // для случая, когда цена активна на диапазон меньший, чем ширина ячейки
                    (cellDate - cellWidthInTime) < calcDateEnd

                return cellDate >= serverPriceDateStart.getTime() &&
                    endDateApprove &&
                    cell.row === priceNumber
            })

            if (!startingCell) return

            const {
                elData: { top, left },
            } = startingCell

            const serverStartingCellDate = fromClientToServerTime(new Date(startingCell.date)).getTime()

            const cellStartOffsetInTime = serverStartingCellDate - serverPriceDateStart.getTime()
            const cellEndOffsetInTime = (!calcDateEnd ? Infinity : calcDateEnd) - serverStartingCellDate

            const calendarWidth = columnsCount * columnWidth
            const leftOffset = left - columnWidth * cellStartOffsetInTime / cellWidthInTime

            const rightOffset = columnWidth * cellEndOffsetInTime / cellWidthInTime

            const isOverEnd = !calcDateEnd || calcDateEnd > endOfInterval.getTime()
            const isOverStart = serverPriceDateStart.getTime() < startOfInterval.getTime()

            let width = 0

            if (leftOffset <= 0 && rightOffset < calendarWidth) {
                width = rightOffset
            }

            if (leftOffset <= 0 && rightOffset > calendarWidth) {
                width = calendarWidth
            }

            if (leftOffset >= 0 && rightOffset < calendarWidth) {
                width = left - leftOffset + rightOffset
            }

            if ((leftOffset >= 0 && rightOffset > calendarWidth) || (leftOffset + width > calendarWidth)) {
                width = calendarWidth - leftOffset
            }

            const roundedWidth = Math.floor(width)

            /* gaussRound - чтобы минимизировать случаи пустого пикселя между графиками*/
            const paddingLeft = roundedWidth < PADDING_LEFT ? roundedWidth : PADDING_LEFT
            const paddingRight = (roundedWidth - paddingLeft) < PADDING_RIGHT ? roundedWidth - paddingLeft : PADDING_RIGHT

            const commonChartData = {
                priceData: price,
                style: {
                    top,
                    left: leftOffset < 0 ? 0 : Math.floor(leftOffset),
                    width: `${roundedWidth}px`,
                    borderTopRightRadius: isOverEnd ? '0px' : '4px',
                    borderBottomRightRadius: isOverEnd ? '0px' : '4px',
                    borderTopLeftRadius: isOverStart ? '0px' : '4px',
                    borderBottomLeftRadius: isOverStart ? '0px' : '4px',
                    paddingLeft: `${paddingLeft}px`,
                    paddingTop: `${PADDING_TOP}px`,
                    paddingRight: `${paddingRight}px`,
                    paddingBottom: `${PADDING_BOTTOM}px`,
                },
            }

            const specificParams = {
                priceStatus,
                countablePrice,
                isAlarmNotActivePrice,
                isExpiredPrice,
            }
            const specificData = getSpecificData(specificParams)

            priceCharts.push({
                priceData: commonChartData.priceData,
                priceIcon: specificData.priceIcon,
                priceStatus: specificData.priceStatus,
                style: {
                    ...commonChartData.style,
                    ...specificData.style,
                }
            })
        })
    })

    return priceCharts
}

function getSpecificData({
    priceStatus,
    countablePrice,
    isAlarmNotActivePrice,
    isExpiredPrice,
}: SpecificParams): Partial<ProductPriceChart> {
    switch (priceStatus) {
        case ACTIVE:
            return getActiveChartData(countablePrice, isExpiredPrice)
        case PRICE_TAG_NOT_PRINTED:
            return getNotActiveChartData(countablePrice, isAlarmNotActivePrice)
        default: // ограничения
            return getRestrictionChartData(countablePrice)
    }
}

function getActiveChartData(
    countablePrice: SalesPrice,
    isExpiredPrice: boolean,
): Partial<ProductPriceChart> {
    return {
        priceIcon: countablePrice.icon,
        priceStatus: t('productDetails.salesPolicyEntrance.activePrice'),
        style: {
            backgroundColor: isExpiredPrice ? '#BDBDBD' : countablePrice.color,
            height: '44px',
            marginTop: '6px',
            opacity: 1,
        },
    }
}

function getNotActiveChartData(
    countablePrice: SalesPrice,
    isAlarmNotActivePrice: boolean,
): Partial<ProductPriceChart> {
    return {
        priceIcon: isAlarmNotActivePrice ? warningIcon : countablePrice.icon,
        priceStatus: t('productDetails.salesPolicyEntrance.notActivePrice'),
        style: {
            backgroundColor: isAlarmNotActivePrice ? '#EB5757' : '#BDBDBD',
            height: '38px',
            marginTop: '9px',
            opacity: 1,
            zIndex: isAlarmNotActivePrice && 1,
        },
    }
}

function getRestrictionChartData(
    countablePrice: SalesPrice,
): Partial<ProductPriceChart> {
    return {
        priceIcon: countablePrice.icon,
        style: {
            backgroundColor: countablePrice.color,
            height: '44px',
            marginTop: '6px',
            opacity: 1,
        },
    }
}
