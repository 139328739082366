import React, { Suspense } from 'react'
import { PageLoader } from '../../components/loaders/page-loader'

export const createLoader = (loadableImport: () => Promise<any>): React.ReactType => {
    // Данный класс позволяет избежать повторной загрузки при ререндере
    return class Loader extends React.PureComponent<{}> {
        render() {
            const Component = React.lazy(loadableImport)
            return (
                <Suspense
                    fallback={<PageLoader/>}
                >
                    <Component/>
                </Suspense>
            )
        }
    }
}

/**
 * Загружает компонент из дефолтного экспорта.
 * Пример:
 * lazy(() => import('../pages/operday/cashes/operday-info'))
 * @param {() => Promise<any>} loadableImport
 * @returns {any}
 */

export const lazy = (loadableImport: () => Promise<any>): any => {
    const Loader = createLoader(loadableImport)
    return () => <Loader/>
}
