import { observable, action, computed, autorun } from 'mobx'
import { getStore } from '../stores-repository'
import { APP_STORE, PRODUCT_DETAILS_STORE, USER_STORE } from '../stores'
import { ProductDetailsStore } from './product-details-store'
import { AppStore } from '../app-store'
import { ProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { PRICE_TAG_NOT_PRINTED } from '../../core/products/product-statuses'
import { createDatedPriceVO_SF, DatedPriceVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/dated-price-vo-sf'
import { formatPrice } from '../../core/currency-utils'
import { salesPrices } from '../../pages/products/sales-policy/prices-charts'
import { PriceVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-vo'
import { t } from 'i18next'
import { UserStore } from '../user-store'
import { sortBy } from 'lodash'
import {
    DISCOUNT_PERCENT
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-type-vo'

export class ProductSalesPolicyStore {

    productDetailsStore: ProductDetailsStore = getStore(PRODUCT_DETAILS_STORE)
    appStore: AppStore = getStore(APP_STORE)
    userStore: UserStore = getStore(USER_STORE)

    @observable
    selectedShop: number = null

    @observable
    productDetailsNotActive: Partial<ProductVO_SF> = null

    @observable
    selectedPrices: number[] = []

    @observable
    shopFilterValue: string = ''

    @computed
    get pricesOptions(): Array<{ label: string, value: number }> {
        const { productDetails: activeProduct } = this.productDetailsStore
        const options = []

        if (!activeProduct || !activeProduct.prices) return []

        activeProduct.prices.forEach(price => {
            if (options.every(option => option.value !== price.number && Boolean(price.shop))) {
                options.push({
                    value: price.number,
                    label: t('productDetails.salesPolicyEntrance.priceOption', {number: price.number})
                })
            }
        })

        return sortBy(options, 'value')
    }

    constructor() {
        autorun(() => {
            this.selectedPrices = this.pricesOptions.map(option => option.value).sort()
        })
    }

    @action
    setSelectedShop = (newSelectedShop: number): void => {
        this.selectedShop = newSelectedShop
    }

    @action
    setSelectedPrices = (newSelectedPrices: number[]): void => {
        this.selectedPrices = newSelectedPrices
    }

    @action
    setShopFilterValue = (shopFilterValue: string): void => {
        this.shopFilterValue = shopFilterValue
    }

    @action
    fetchNotActiveProductDetails = async (): Promise<void> => {
        const { productCode } = this.productDetailsStore
        const { session: sessionId } = this.userStore

        const notActivePrices = await iProductsManagerLocal.getPricesByProductMarkingAndStatus(sessionId, productCode, PRICE_TAG_NOT_PRINTED)
        this.productDetailsNotActive = { ...this.priceVOToDatedPriceVOSF(notActivePrices), prices: notActivePrices }
    }

    @action
    reset = (): void => {
        this.selectedShop = null
        this.productDetailsNotActive = null
        this.selectedPrices = null
        this.shopFilterValue = ''
    }

    priceVOToDatedPriceVOSF = (prices: PriceVO[]): {[key: string]: DatedPriceVO_SF} => {
        if (!prices) return {}

        return prices.reduce((acc, price) => {
            const item = createDatedPriceVO_SF({
                id: price.id,
                value: price?.value ? formatPrice(price.value / 100) : '-',
                dateStart: price.beginDate,
                dateEnd: price.endDate,
                depart: price.department && price.department.name,
                packCount: price.packCount,
                discountIdentifier: price.discountIdentifier,
                priceType: price.priceType
            })

            const key = salesPrices[price.number - 1].name

            if (acc[key]) {
                acc[key].push(item)
            } else {
                acc[key] = [item]
            }

            return acc
        }, {})
    }
}
