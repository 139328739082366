import { AverageSalesOnCashDesk } from './set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-cash-desk-optimization-plugin/average-sales-on-cash-desk'
import { TimePeriod } from './set-retail10-server/oper-day/set-oper-day/time-period'
import { CashPeriod } from './set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-cash-desk-optimization-plugin/cash-period'
import { Trend } from './set-retail10-server/oper-day/set-oper-day-reports/trend'
import { TotalSalesOnCashDesks } from './set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-cash-desk-optimization-plugin/total-sales-on-cash-desks'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class CashDeskOptimizationReportManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-OperDay-Reports-CashDeskOptimizationPlugin/SET/OperDay/Reports/Plugins/CashDeskOptimizationReportManager!ru.crystals.operday.reports.gui.flexendpoints.CashDeskOptimizationReportManagerLocal'
    }

    getAverageSalesOnCashDesk = (sessionId: string, period: TimePeriod, options?: RequestOptions): Promise<AverageSalesOnCashDesk> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getAverageSalesOnCashDesk',
                params: [sessionId, period]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getCashDesksUtilization = (sessionId: string, period: TimePeriod, options?: RequestOptions): Promise<CashPeriod[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCashDesksUtilization',
                params: [sessionId, period]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getSalesOnCashDeckByTime = (sessionId: string, intervalsCount: number, period: TimePeriod, intervalType: string, cashNum: number, options?: RequestOptions): Promise<Trend[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getSalesOnCashDeckByTime',
                params: [sessionId, intervalsCount, period, intervalType, cashNum]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getSalesOnCashDesks = (sessionId: string, period: TimePeriod, options?: RequestOptions): Promise<TotalSalesOnCashDesks> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getSalesOnCashDesks',
                params: [sessionId, period]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const cashDeskOptimizationReportManagerLocal: CashDeskOptimizationReportManagerLocal = new CashDeskOptimizationReportManagerLocal()
