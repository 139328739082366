export interface FormatsCellVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsCellVO'
    id?: number
    formatId?: number
    sizeId?: number
    sizeUUID?: string
    listId?: number
    listUUID?: string
    priceTags?: string[]
}

export const createFormatsCellVO = (initialization: Partial<FormatsCellVO>): FormatsCellVO => {
    if (!initialization) return null
    
    let result: FormatsCellVO = {
        id: initialization.id,
        formatId: initialization.formatId,
        sizeId: initialization.sizeId,
        sizeUUID: initialization.sizeUUID,
        listId: initialization.listId,
        listUUID: initialization.listUUID,
        priceTags: initialization.priceTags,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsCellVO',
    }
    
    return result
}
