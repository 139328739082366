import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import { observable, runInAction, toJS } from 'mobx'
import { iTopologyManagerLocal } from '../../../protocol/set10/i-topology-manager-local'
import { RegionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import { SHOPS, TOPOLOGY, REGIONS } from '../../core/app-routes'
import { RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, MENU } from '../app-bar-store'
import { AppStore } from '../app-store'
import { NavigationMenuStore } from '../navigation-menu-store'
import { SnackbarStore } from '../snackbar-store'
import { APP_BAR_STORE, APP_STORE, NAVIGATION_MENU_STORE, SNACKBAR_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'

export class RegionsStore {
    @observable
    regions: RegionVO[] = []

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    getRegions = (): void => {
        this.regions = cloneDeep(toJS(this.appStore.topologyMap.regions))
    }

    deleteRegion = (regionId: number): Promise<void> => {
        return iTopologyManagerLocal.deleteRegion(this.userStore.session, regionId)
            .then(response => {
                if (response) {
                    return this.appStore.fetchTopologyMap()
                        .then(() => {
                            runInAction(() => {
                                let regionPosition = this.regions.findIndex(region => region.id === regionId)
                                let regionName = this.regions.splice(regionPosition, 1)[0].name
                                this.snackbarStore.show({message: t('topologyActions.regionDeletionSuccess', {regionName})})
                            })
                        })
                } else {
                    this.snackbarStore.show({message: t('topologyActions.regionDeletionFailure', {message: null})})
                }
            })
            .catch(error => {
                this.snackbarStore.show({message: t('topologyActions.regionDeletionFailure', {message: error.message})})
            })
    }

    reset = (): void => {
        this.regions = []
    }
}

/**
 * Обработчик для страницы /settings/topology/regions
 */
export const TOPOLOGY_REGIONS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${SHOPS}${TOPOLOGY}${REGIONS}/?$`),
    onEnter: () => {
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        navigationMenuStore.synchronizeWithRoute()
        appBarStore.updateState({
            title: `${t('set10.topology')} - ${t('set10.topologyRegions')}`,
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    }
}
