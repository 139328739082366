import { GiftomanStatusVO } from './set-retail10-server/retailx/giftoman-integration/giftoman-status-vo'
import { HistoricalPurchaseExportStatusVO } from './set-retail10-server/retailx/giftoman-integration/historical-purchase-export-status-vo'
import { HistoricalPurchaseExportParamsVO } from './set-retail10-server/retailx/giftoman-integration/historical-purchase-export-params-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class GiftomanManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/Giftoman-Integration/GiftomanManager!ru.crystals.giftoman.GiftomanManagerLocal'
    }

    getGiftomanStatus = (options?: RequestOptions): Promise<GiftomanStatusVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getGiftomanStatus',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getHistoricalPurchaseExportStatus = (options?: RequestOptions): Promise<HistoricalPurchaseExportStatusVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getHistoricalPurchaseExportStatus',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    shopAuthentication = (registrationKey: string, options?: RequestOptions): Promise<GiftomanStatusVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'shopAuthentication',
                params: [registrationKey]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    startHistoricalPurchaseExport1 = (days: number, options?: RequestOptions): Promise<HistoricalPurchaseExportStatusVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'startHistoricalPurchaseExport',
                params: [days]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    startHistoricalPurchaseExport2 = (start: Date, end: Date, options?: RequestOptions): Promise<HistoricalPurchaseExportStatusVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'startHistoricalPurchaseExport',
                params: [start, end]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    startHistoricalPurchaseExport3 = (paramsVO: HistoricalPurchaseExportParamsVO, options?: RequestOptions): Promise<HistoricalPurchaseExportStatusVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'startHistoricalPurchaseExport',
                params: [paramsVO]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const giftomanManagerLocal: GiftomanManagerLocal = new GiftomanManagerLocal()
