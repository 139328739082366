import * as React from 'react'
import classNames from 'classnames'
import {get, isEqual, sortBy} from 'lodash'

const styles = require('./tree-view.scss')

export const START_DEPTH: number = 1
export const MIN_DEPTH: number = 2

export class TreeView extends React.Component<TreeViewProps, TreeViewState> {

    static defaultProps: Partial<TreeViewProps> = {
        id: 'treeView',
        multipleSelection: false,
        depth: START_DEPTH,
        maxDepth: START_DEPTH,
        deselectable: true,
        items: [],
        defaultSelection: [],
        onItemSelectionChange: () => null,
        onTreeSelectionChange: () => null,
    }

    state: TreeViewState = {
        selection: [],
        items: [],
    }

    componentDidMount(): void {
        const {defaultSelection, items, deselectable, depth, onItemSelectionChange} = this.props

        let changes: TreeViewState = {}

        changes.items = this.sortItems(items)

        if (depth === START_DEPTH && defaultSelection) {
            changes.selection = defaultSelection

            if (!deselectable && defaultSelection.length === 0 && items.length > 0) {
                changes.selection = [items[0]]

                onItemSelectionChange(items[0], true, depth)
            }
        }

        this.setState(changes)
    }

    componentWillReceiveProps(nextProps: Readonly<TreeViewProps>, nextContext: any): void {
        if (!isEqual(this.props.items, nextProps.items)) {
            this.setState({items: this.sortItems(nextProps.items)})
        }
    }

    sortItems = (items: TreeItem[]): TreeItem[] => {
        if (!items) return []

        const {sortFields, sortFunction} = this.props

        if (!sortFunction && !sortFields) return items

        let result: TreeItem[] = items.concat()

        if (sortFunction) {
            result.sort(sortFunction)
        } else if (sortFields && sortFields.length > 0) {
            result = sortBy(items, sortFields.map(field => `item.${field}`))
        }

        return result
    }

    itemToLabel = (item: TreeItem): string => {
        const {labelField, labelFunction} = this.props
        if (!item.item) return ''

        if (item.labelFunction) return item.labelFunction(item.item)
        if (labelFunction) return labelFunction(item.item)

        let value: any = item.item

        if (item.labelField) {
            value = get(item.item, item.labelField)
        }
        if (labelField) {
            value = get(item.item, labelField)
        }
        if (!value) return ''

        if (typeof value === 'string') {
            return value
        }

        return value.toLocaleString()
    }

    getTreeDepth = (items: TreeItem[], parentDepth: number = START_DEPTH - 1): number => {
        let depth: number = parentDepth

        if (items) {
            if (depth < parentDepth + 1) {
                depth = parentDepth + 1
            }
            items.forEach(item => {
                let childDepth: number = this.getTreeDepth(item.children, parentDepth + 1)
                if (childDepth > depth) {
                    depth = childDepth
                }
            })
        }

        if (parentDepth === START_DEPTH - 1 && depth < MIN_DEPTH) {
            depth = MIN_DEPTH
        }

        return depth
    }

    selectionChangeHandler = (item: TreeItem, selected: boolean, depth: number): void => {
        const {multipleSelection, deselectable, depth: currentDepth, onItemSelectionChange, selection: propsSelection} = this.props
        const {selection: stateSelection, items} = this.state

        if (currentDepth === START_DEPTH) {
            let selection: Selection = new Selection(stateSelection, items, currentDepth)
            let itemDepthSelection: TreeItem[] = selection.getSelectionIn(depth)

            let newSelection: TreeItem[] = stateSelection.concat()

            if (!selected && !deselectable && depth === START_DEPTH && itemDepthSelection.length === 1) {
                // Нельзя снимать выделение с последнего элемента первого уровня
                return
            }

            if (selected) {
                if (!multipleSelection) {
                    // В одиночном режиме нужно снять выделение с элементов на том же уровне и выше
                    itemDepthSelection.forEach(selectedItem => {
                        newSelection.splice(newSelection.indexOf(selectedItem), 1)
                        onItemSelectionChange(selectedItem, false, depth)

                        let children: TreeItemSelection[] = selection.getSelectedChildren(selectedItem)
                        children.forEach(selectedChild => {
                            newSelection.splice(newSelection.indexOf(selectedChild.item), 1)
                            onItemSelectionChange(selectedChild.item, false, selectedChild.depth)
                        })
                    })
                }

                newSelection.push(item)
                onItemSelectionChange(item, true, depth)
            } else {
                let childrenSelection: TreeItemSelection[] = selection.getSelectedChildren(item)

                childrenSelection.forEach(selectedChild => {
                    newSelection.splice(newSelection.indexOf(selectedChild.item), 1)
                    onItemSelectionChange(selectedChild.item, false, selectedChild.depth)
                })

                newSelection.splice(newSelection.indexOf(item), 1)
                onItemSelectionChange(item, false, depth)
            }

            this.setState({selection: newSelection})
        } else {
            onItemSelectionChange(item, !(propsSelection && propsSelection.indexOf(item) > -1), depth)
        }
    }

    render() {
        const {
            className, labelField, labelFunction, multipleSelection, depth,
            maxDepth, selection: propsSelection, sortFields, sortFunction,
            onItemSelectionChange, deselectable, onTreeSelectionChange, defaultSelection, ...other
        } = this.props
        const {selection: stateSelection, items} = this.state

        let selectedItems: TreeItem[] = depth === START_DEPTH ? stateSelection : propsSelection
        if (!selectedItems) selectedItems = []
        let selection: Selection = new Selection(selectedItems, items, depth)

        let childrenFromSelection: TreeItem[] = []

        selection.getSelectionIn(depth)
            .forEach(item => {
                if (item.children) {
                    childrenFromSelection = childrenFromSelection.concat(item.children)
                }
            })

        const rows: React.ReactNode[] = []
        let treeDepth: number = maxDepth === 1 ? this.getTreeDepth(items) : maxDepth
        const tableWidth: string = `${100 / (treeDepth - depth + 1)}%`

        items.forEach((item, index) => {
            let lastItem: boolean = index === items.length - 1
            let selected: boolean = selectedItems.indexOf(item) > -1
            let label: string = this.itemToLabel(item)
            let key: string = item.key ? item.key : label

            rows.push(
                <div
                    id={`${other.id}Item${index}`}
                    key={key}
                    className={classNames(styles.item, {
                        [styles.selected]: selected,
                        [styles.bottomBorder]: !lastItem,
                    })}
                    onClick={() => this.selectionChangeHandler(item, !selected, depth)}
                >
                    <span className={styles.label}>{label}</span>
                </div>
            )
        })

        return (
            <div
                {...other}
                className={classNames(className, styles.treeView)}
            >
                <div
                    className={styles.table}
                    style={{width: tableWidth}}
                >
                    {rows}
                </div>
                {
                    depth < treeDepth && (
                        <TreeView
                            id={`${other.id}Child${depth + 1}`}
                            deselectable
                            multipleSelection={multipleSelection}
                            depth={depth + 1}
                            maxDepth={treeDepth}
                            items={childrenFromSelection}
                            selection={selectedItems}
                            labelField={labelField}
                            labelFunction={labelFunction}
                            sortFields={sortFields}
                            sortFunction={sortFunction}
                            onItemSelectionChange={this.selectionChangeHandler}
                        />
                    )
                }
            </div>
        )
    }
}

export interface TreeViewProps extends React.HTMLProps<HTMLDivElement> {
    items: TreeItem[]
    labelField?: string
    labelFunction?: (item: any) => string
    multipleSelection?: boolean
    deselectable?: boolean
    onItemSelectionChange?: (item: TreeItem, selected: boolean, depth: number) => void
    onTreeSelectionChange?: (selection: TreeItemSelection[]) => void
    sortFields?: string[]
    sortFunction?: (a: TreeItem, b: TreeItem) => number
    defaultSelection?: TreeItem[]
    // internal
    selection?: TreeItem[]
    depth?: number,
    maxDepth?: number
}

export interface TreeViewState {
    selection?: TreeItem[]
    items?: TreeItem[]
}

export interface TreeItem<T = any> {
    item: T
    children?: TreeItem[]
    key?: string
    labelField?: string
    labelFunction?: (item: T) => string
}

export interface TreeItemSelection {
    item: TreeItem
    selected?: boolean
    depth?: number
}

export class Selection {

    private itemsByDepth: Map<number, TreeItem[]> = new Map<number, TreeItem[]>()
    private depthByItem: Map<TreeItem, number> = new Map<TreeItem, number>()

    constructor(public selection: TreeItem[],
                public tree: TreeItem[],
                public startDepth: number = START_DEPTH) {

        const parseTree = (items: TreeItem[], depth: number): void => {
            if (!this.itemsByDepth.has(depth)) {
                this.itemsByDepth.set(depth, [])
            }
            let list: TreeItem[] = this.itemsByDepth.get(depth)
            list = list.concat(items)
            this.itemsByDepth.set(depth, list)

            items.forEach(item => {

                this.depthByItem.set(item, depth)

                if (item.children) {
                    parseTree(item.children, depth + 1)
                }
            })
        }

        parseTree(tree, startDepth)
    }

    getSelectionIn = (depth: number): TreeItem[] => {
        if (!this.itemsByDepth.has(depth)) return []
        let items: TreeItem[] = this.itemsByDepth.get(depth)
        return items.filter(item => this.selection.indexOf(item) > -1)
    }

    getSelectedChildren = (item: TreeItem): TreeItemSelection[] => {
        if (!item.children) return []
        if (!this.depthByItem.has(item)) return []

        let depth: number = this.depthByItem.get(item)

        let selectedChildren: TreeItemSelection[] = this.getSelectionIn(depth + 1)
            .filter(child => item.children.indexOf(child) > -1)
            .map(child => {
                return {
                    item: child,
                    depth: depth + 1,
                    selected: true
                }
            })

        let selectedGrandChildren: TreeItemSelection[] = []
        selectedChildren.forEach(selectedItem => {
            selectedGrandChildren = selectedGrandChildren.concat(this.getSelectedChildren(selectedItem.item))
        })

        selectedChildren.concat(selectedGrandChildren)

        return selectedChildren
    }
}
