import React from 'react'
import {
    CITY,
    LICENSES,
    PRODUCT_SHELVES,
    REGION, REGIONS,
    SHOP,
    SHOPS,
    SHOPS_SEARCH,
    TOPOLOGY,
    getRouteName
} from '../core/app-routes'
import {
    SETRETAILX_SHOPSETUP_ADMIN, SETRETAILX_CHANGE_LICENSE_KEY, SETRETAILX_COMMONSETUP_ADMIN
} from '../core/privileges/privileges'
import { ShopsSearch } from '../pages/shops/shops-search/shops-search'
import { CityPage } from '../pages/shops/topology/city-page'
import { RegionPage } from '../pages/shops/topology/region-page'
import { RegionsPage } from '../pages/shops/topology/regions-page'
import { ShopPage } from '../pages/shops/shop-settings/shop-page'
import { CENTRUM, RouteScheme } from '../utils/route-modules-util'
import { lazy } from '../utils/code-splitting'
import Shop from '@material-ui/icons/Shop'
import { PrivilegeManager } from '../core/privileges/privilege-manager'

export const shopsRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'shopsNavLink',
        label: getRouteName(SHOPS),
        icon: <Shop/>,
    },
    path: SHOPS,
    serverType: CENTRUM,
    privileges: (privilegeManager: PrivilegeManager) => {
        return privilegeManager.haveAnyPrivilegeFromList([
            SETRETAILX_SHOPSETUP_ADMIN,
            SETRETAILX_COMMONSETUP_ADMIN,
            SETRETAILX_CHANGE_LICENSE_KEY,
        ])
    },
    children: [
        {
            navItemProps: {
                id: 'topologyNavLink',
                label: getRouteName(TOPOLOGY),
            },
            path: `${SHOPS}${TOPOLOGY}${REGIONS}`,
            privileges: [SETRETAILX_SHOPSETUP_ADMIN],
            component: RegionsPage
        },
        {
            path: `${SHOPS}${TOPOLOGY}${REGION}/:id`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_SHOPSETUP_ADMIN],
            component: RegionPage
        },
        {
            path: `${SHOPS}${TOPOLOGY}${CITY}/:id`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_SHOPSETUP_ADMIN],
            component: CityPage
        },
        {
            path: `${SHOPS}${TOPOLOGY}${SHOP}/:id`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_SHOPSETUP_ADMIN],
            component: ShopPage
        },
        {
            navItemProps: {
                id: 'shopsSearchNavLink',
                label: getRouteName(SHOPS_SEARCH),
            },
            path: `${SHOPS}${SHOPS_SEARCH}`,
            privileges: [SETRETAILX_SHOPSETUP_ADMIN],
            component: ShopsSearch
        },
        {
            navItemProps: {
                id: 'productShelvesNavLink',
                label: getRouteName(PRODUCT_SHELVES),
            },
            path: `${SHOPS}${PRODUCT_SHELVES}`,
            privileges: [SETRETAILX_COMMONSETUP_ADMIN],
            component: lazy(() =>
                import(/* webpackChunkName: "product-shelves" */ '../pages/shops/product-shelves/product-shelves'))
        },
        {
            navItemProps: {
                id: 'licensesNavLink',
                label: getRouteName(LICENSES),
            },
            path: `${SHOPS}${LICENSES}`,
            privileges: [SETRETAILX_CHANGE_LICENSE_KEY],
            component: lazy(() =>
                import(/* webpackChunkName: "licenses-page" */ '../pages/shops/licenses/licenses-page'))
        }
    ]
})
