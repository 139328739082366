import { action, autorun, computed, observable, runInAction } from 'mobx'
import { isNil, sortBy } from 'lodash'
import {Check} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check'
import { checkManagerLocal } from '../../../protocol/set10/check-manager-local'
import { CHECK, CHECKS, OPERDAY, REFUND } from '../../core/app-routes'
import {CheckPosition} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check-position'
import { reportsProcessorLocal } from '../../../protocol/set10/reports-processor-local'
import {
    createRefundPositionPropertiesVO,
    RefundPositionPropertiesVO
} from '../../../protocol/set10/set-retail10-commons/set-report-api/refund-position-properties-vo'
import {
    createRefundRequestVO,
    RefundRequestVO
} from '../../../protocol/set10/set-retail10-commons/set-report-api/refund-request-vo'
import {
    createRefundPositionParametersVO,
    RefundPositionParametersVO
} from '../../../protocol/set10/set-retail10-commons/set-report-api/refund-position-parameters-vo'
import { config } from '../../config/config'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { t } from 'i18next'
import { createRefundRequestPositionVO } from '../../../protocol/set10/set-retail10-commons/set-report-api/refund-request-position-vo'
import { NOT_SPECIFIED, NotSpecified } from '../../core/values'
import { UserStore } from '../user-store'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, APP_STORE, CHECK_REFUND_PAGE_STORE, USER_STORE } from '../stores'
import { withSpinner } from '../with-spinner'

export type ClientRequirement = 'RETURN_CASH' | 'EXCHANGE' | 'MAKE_REPAIRS' | 'SALE_BY_PRICE'
export const RETURN_CASH: ClientRequirement = 'RETURN_CASH'
export const EXCHANGE: ClientRequirement = 'EXCHANGE'
export const MAKE_REPAIRS: ClientRequirement = 'MAKE_REPAIRS'
// Дополнительное требование, выделенного в отдельный тип возврата
export const SALE_BY_PRICE: ClientRequirement = 'SALE_BY_PRICE'

export const clientRequirements: ClientRequirement[] = [RETURN_CASH, EXCHANGE, MAKE_REPAIRS]

export type RefundReason = 'DEFECT' | 'CASHIER_ERROR' | 'SELLER_ERROR' | 'BUYER_ERROR' | 'WRONG_PRICE'
    | 'PRODUCER_ERROR' | 'TECHNICAL_ERROR' | 'WRONG_PRICE_IN_CHECK' | 'CALCULATION_FORM_ERROR' | 'DISCOUNTS_ERROR'
    | 'PRODUCT_SELECT_ERROR' | 'LENTA_SCAN_REFUND' | 'LENTA_PRO_REFUND'
//Брак
export const DEFECT: RefundReason = 'DEFECT'
// Ошибка кассира
export const CASHIER_ERROR: RefundReason = 'CASHIER_ERROR'
// Ошибка продавца
export const SELLER_ERROR: RefundReason = 'SELLER_ERROR'
export const BUYER_ERROR: RefundReason = 'BUYER_ERROR'
// Неверная цена
export const WRONG_PRICE: RefundReason = 'WRONG_PRICE'
// Ошибка производителя
export const PRODUCER_ERROR: RefundReason = 'PRODUCER_ERROR'
// Техническая ошибка
export const TECHNICAL_ERROR: RefundReason = 'TECHNICAL_ERROR'
// Дополнительная причина, выделенного в отдельный тип возврата
export const WRONG_PRICE_IN_CHECK: RefundReason = 'WRONG_PRICE_IN_CHECK'
// Ошибка формы расчета
export const CALCULATION_FORM_ERROR: RefundReason = 'CALCULATION_FORM_ERROR'
// Ошибка расчета скидок и рекламных акций
export const DISCOUNTS_ERROR: RefundReason = 'DISCOUNTS_ERROR'
// Ошибка выбора товара по характеристике
export const PRODUCT_SELECT_ERROR: RefundReason = 'PRODUCT_SELECT_ERROR'
// Возврат товаров Лента-скан
export const LENTA_SCAN_REFUND: RefundReason = 'LENTA_SCAN_REFUND'
// Возврат товаров из чека Лента-ПРО
export const LENTA_PRO_REFUND: RefundReason = 'LENTA_PRO_REFUND'

export type ClientAction = 'MONEY_RECEIVED' | 'EXCHANGE_OF_GOODS_PRODUCED' | 'GOODS_RECEIVED'
export const MONEY_RECEIVED: ClientAction = 'MONEY_RECEIVED'
export const EXCHANGE_OF_GOODS_PRODUCED: ClientAction = 'EXCHANGE_OF_GOODS_PRODUCED'
export const GOODS_RECEIVED: ClientAction = 'GOODS_RECEIVED'

export const clientActions = [
    MONEY_RECEIVED,
    EXCHANGE_OF_GOODS_PRODUCED,
    GOODS_RECEIVED,
]

export type RefundRequestType = 'RefundShortRequest' | 'RefundFullRequest' | 'RefundSellRequest'
// Полная форма на возврат
export const REFUND_FULL_REQUEST: RefundRequestType = 'RefundFullRequest'
// Короткая форма на возврат
export const REFUND_SHORT_REQUEST: RefundRequestType = 'RefundShortRequest'
// Заявление на возврат/продажу
export const REFUND_SELL_REQUEST: RefundRequestType = 'RefundSellRequest'

export const getClientRequirementText = (requestType: RefundRequestType, requirements: ClientRequirement): string => {
    if (requestType === REFUND_FULL_REQUEST) {
        return t(`checkRefund.${requirements}`)
    } else if (requestType === REFUND_SELL_REQUEST) {
        return t(`checkRefund.${SALE_BY_PRICE}`)
    } else {
        return ''
    }
}

export const getRefundReasonText = (requestType: RefundRequestType, reason: RefundReason | NotSpecified): string => {
    if (requestType === REFUND_SHORT_REQUEST && reason) {
        if (reason === NOT_SPECIFIED) {
            return t('common.notSpecified')
        }
        return t(`checkRefund.${reason}`)
    } else if (requestType === REFUND_SELL_REQUEST) {
        return t(`checkRefund.${WRONG_PRICE_IN_CHECK}`)
    } else {
        return ''
    }

}

export const getClientActionText = (action: ClientAction): string => {
    return t(`checkRefund.${action}`)
}

export class CheckRefundStore {

    @observable
    refundPositions: RefundPosition[] = []

    @observable
    check: Check

    @observable
    filter: string = ''

    @observable
    requestType: RefundRequestType = REFUND_FULL_REQUEST

    @observable
    fullRefund: boolean = false

    @observable
    clientRequirement: ClientRequirement = RETURN_CASH

    @observable
    refundReason: RefundReason = null

    @observable
    endSumForRefund: number = 0

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    constructor() {
        autorun(() => {
            if (this.selectedRefundPositions.length === 0) return

            let refundPositionsParameters: RefundPositionParametersVO[] = this.selectedRefundPositions.map((refundPosition: RefundPosition) => {
                return createRefundPositionParametersVO({
                    restCost: refundPosition.refundPosition.restCost,
                    restQuantity: Math.round(refundPosition.availableQuantity * 1000),
                    refundQuantity: Math.round(refundPosition.refundQuantity * 1000)
                })
            })

            reportsProcessorLocal.getEndSumForRefund(refundPositionsParameters)
                .then(endSumForRefund => {
                    runInAction(() => {
                        let sum = endSumForRefund as any
                        // TODO транспорт присылает массив ["java.lang.Long", 1087]
                        sum = sum && sum.length > 1 ? sum[1] : 0

                        this.endSumForRefund = sum
                    })
                })
        })
    }

    @computed
    get refundReasons(): RefundReason[] {
        const { lentaBuild } = this.appStore

        if (lentaBuild) {
            return [
                DEFECT,
                CASHIER_ERROR,
                CALCULATION_FORM_ERROR,
                DISCOUNTS_ERROR,
                PRODUCT_SELECT_ERROR,
                SELLER_ERROR,
                WRONG_PRICE,
                PRODUCER_ERROR,
                TECHNICAL_ERROR,
                LENTA_SCAN_REFUND,
                LENTA_PRO_REFUND
            ]
        } else {
            return [
                DEFECT,
                CASHIER_ERROR,
                SELLER_ERROR,
                BUYER_ERROR,
                WRONG_PRICE,
                PRODUCER_ERROR,
                TECHNICAL_ERROR
            ]
        }
    }

    @computed
    get filteredRefundPositions(): RefundPosition[] {
        if (!this.check || !this.check.positions) {
            return []
        }

        const fixedFilter = this.filter.toLowerCase().trim()

        return this.refundPositions.filter(position => {
            return position.checkPosition.name.toLowerCase().includes(fixedFilter)
                || position.checkPosition.barcode.toLowerCase().includes(fixedFilter)
                || position.checkPosition.item.toLowerCase().includes(fixedFilter)
        })
    }

    @computed
    get selectedRefundPositions(): RefundPosition[] {
        return this.refundPositions.filter(position => {
            return position.refundQuantity > 0
        })
    }

    @computed
    get disabledRefundPositions(): RefundPosition[] {
        return this.refundPositions.filter(position => this.fullRefund || position.availableQuantity === 0)
    }

    @action
    changeFullRefundSelection = (selected: boolean): void => {
        if (this.requestType === REFUND_SELL_REQUEST) {
            // Для "Возврата/продажи" нет опции "Вернуть весь чек"
            return
        }
        this.fullRefund = selected
        if (selected) {
            this.refundPositions.forEach(p => p.refundQuantity = p.availableQuantity)
        }
    }

    @action
    editRefundQuantity = (position: RefundPosition, quantity: number): void => {
        if (isNil(quantity) || isNaN(quantity)) return

        let refundPosition = this.refundPositions.find(p => {
            return p.checkPosition.positionOrder === position.checkPosition.positionOrder
        })

        refundPosition.refundQuantity = quantity > position.availableQuantity ? position.refundQuantity : quantity
    }

    @action
    changePositionSelection = (position: RefundPosition, selected: boolean): void => {
        let refundPosition = this.refundPositions.find(p => {
            return p.checkPosition.positionOrder === position.checkPosition.positionOrder
        })

        refundPosition.refundQuantity = selected ? refundPosition.availableQuantity : 0
    }

    @action
    changePositionSelectionAll = (selected: boolean): void => {
        if (this.fullRefund) return
        this.filteredRefundPositions.forEach(item => {
            item.refundQuantity = selected ? item.availableQuantity : 0
        })
    }

    @action
    openCheckRefund = (checkToFind: Check): void => {
        withSpinner(Promise.all([
            checkManagerLocal.findCheck(this.userStore.session, checkToFind),
            reportsProcessorLocal.getAvailableCountsForPurchasePositions(checkToFind.id)
        ])
            .then(([check, refundPositions]) => {
                if (!refundPositions || refundPositions.length === 0) {
                    this.appStore.showDialog({
                        message: t('checkRefund.noAvailablePositionToRefund')
                    })
                    return
                }
                runInAction(() => {
                    this.check = check
                    let positions = check.positions.map(checkPosition => {
                        let refundPosition = refundPositions.find(
                            position => position.order === Number(checkPosition.positionOrder))

                        if (!refundPosition) {
                            refundPosition = createRefundPositionPropertiesVO({
                                restCost: 0,
                                isPartyRefundAvailable: false,
                                order: Number(checkPosition.positionOrder),
                                isWeightType: false,
                                count: 0,
                            })
                        }

                        return {
                            checkPosition,
                            refundPosition,
                            availableQuantity: refundPosition ? refundPosition.count / 1000 : 0,
                            // Если частичный возврат не разрешен, то сразу заполняем максимальное значение
                            refundQuantity: 0,
                        }
                    })
                    this.refundPositions = sortBy(positions, ['refundPosition.order'])
                    goTo(`${OPERDAY + CHECK}/${checkToFind.id}${REFUND}`)
                })
            }))
    }

    printRefundApplication = (): void => {
        let printPage: Window = window.open('', '_blank')
        printPage.document.write('Loading...')

        reportsProcessorLocal.getRefundRequestId(this.createRefundRequest())
            .then(refundRequestId => {
                // TODO транспорт присылает массива ["java.lang.Long", 1087]
                let id = refundRequestId as any

                if (isNil(id) || ('length' in id && id.length < 2)) {
                    return
                }

                printPage.location.replace(config.reportsAddress +
                    '?Action=' + this.requestType +
                    '&REFUND_REQUEST_ID=' + String(id[1]) +
                    '&FILE_TYPE=PDF')
            })
    }

    createRefundRequest = (): RefundRequestVO => {
        return createRefundRequestVO({
            cashNumber: this.check.cashDeskNumber,
            userId: this.userStore.userInfo.id,
            fullRefund: this.fullRefund,
            typeRequest: this.requestType,
            purchaseDate: this.check.date,
            shiftNumber: this.check.shiftNumber,
            purchaseNumber: this.check.number,
            purchaseId: this.check.id,
            fullAmount: this.endSumForRefund,
            fullCount: this.selectedRefundPositions
                .map(p => p.refundQuantity)
                .reduce((sum, quantity) => sum + quantity * 1000, 0),
            paymentType: this.check.payments.map(t => t.name).join(', '),
            positions: this.selectedRefundPositions
                .map(p => createRefundRequestPositionVO({
                    count: Math.round(p.refundQuantity * 1000),
                    positionOrder: p.refundPosition.order,
                    barCode: p.checkPosition.barcode,
                    goodsCode: p.checkPosition.item,
                    goodsName: p.checkPosition.name,
                    measure: p.checkPosition.unit,
                    precision: p.checkPosition.precision,
                    restCost: p.refundPosition.restCost,
                    restQuantity: Math.round(p.availableQuantity * 1000),
                    // Скопировано из старой версии, скорее всего это такой хитрый способ превратить рубли в копейки
                    cost: Number(p.checkPosition.price.split(/\D/).join(''))
                })),
            refundReason: getRefundReasonText(this.requestType, this.refundReason),
            // В старой версии этот параметр выбирался по соответствующему индексу refundReason в refundReasons из clientActions
            refundAction: this.requestType === REFUND_FULL_REQUEST
                ? getClientActionText(clientActions[clientRequirements.indexOf(this.clientRequirement)])
                : '',
            clientRequest: getClientRequirementText(this.requestType, this.clientRequirement),
        })
    }

    @action
    changeRequestType = (type: RefundRequestType): void => {
        this.requestType = type
        if (this.requestType === REFUND_SELL_REQUEST) {
            // Для "Возврата/продажи" нет опции "Вернуть весь чек"
            this.fullRefund = false
        }
    }

    @action
    changeClientsRequirement = (clientsRequirement: ClientRequirement): void => {
        this.clientRequirement = clientsRequirement
    }

    @action
    changeRefundReason = (refundReason: RefundReason): void => {
        this.refundReason = refundReason
    }

    @action
    updateFilter = (filter: string): void => {
        this.filter = filter
    }

    @action
    goBack = () => {
        goTo(`${OPERDAY + CHECK}/${this.check.id}`)
    }

    @action
    reset = (): void => {
        this.refundPositions = []
        this.check = undefined
        this.filter = ''
        this.requestType = REFUND_FULL_REQUEST
        this.fullRefund = false
        this.clientRequirement = RETURN_CASH
        this.refundReason = null
        this.endSumForRefund = 0
    }
}

export interface RefundPosition {
    checkPosition: CheckPosition
    refundPosition: RefundPositionPropertiesVO
    availableQuantity: number
    refundQuantity: number
}

export const CHECK_REFUND_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${OPERDAY}${CHECK}/[\\w-]+${REFUND}/?$`),
    onEnter: () => {
        const checkRefundStore: CheckRefundStore = getStore(CHECK_REFUND_PAGE_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        let checkNumber: string = '?'
        if (checkRefundStore.check) {
            checkNumber = checkRefundStore.check.number.toString()
        } else {
            // Вызов goTo приводит к изменению routing.location.pathname, что должно триггерить авторан, следящий за роутами.
            // Этого не происходит, если авторан уже в стеке вызова.
            // Поэтому для подобного редиректа можно переносить изменения роута на следующий таск.
            setTimeout(() => goTo(`${OPERDAY}${CHECKS}`), 0)
            return
        }

        appBarStore.updateState({
            title: `${t('checkPage.checkRefunding')} №${checkNumber}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: checkRefundStore.goBack
        })
    },
    onLeave: () => {
        const checkRefundStore: CheckRefundStore = getStore(CHECK_REFUND_PAGE_STORE)
        checkRefundStore.reset()
    }
}
