import * as React from 'react'
import { AutocompleteInput } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { last, without } from 'lodash'
import Chip from '@material-ui/core/Chip'
import { actionLabelsRegExp } from '../../../utils/form-validation/text-regexp-utils'
import { iDiscountsManagerLocal } from '../../../protocol/set10/i-discounts-manager-local'
import Paper from '@material-ui/core/Paper'
import { toJS } from 'mobx'

const styles = require('./action-labels-autocomplete.scss')

const ACTION_LABEL_MAX_LENGTH = 30

export interface ActionLabelsAutocompleteProps {
    value: string[]
    onChange: (value: string[]) => void
    disabled?: boolean
    canCreateNewLabels?: boolean
}

export interface ActionLabelsAutocompleteState {
    isOpen: boolean
    inputValue: string
}

export class ActionLabelsAutocomplete extends React.Component<ActionLabelsAutocompleteProps, ActionLabelsAutocompleteState> {
    state: ActionLabelsAutocompleteState = {
        isOpen: false,
        inputValue: '',
    }

    mounted: boolean = false

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleAddLabel = (label: string): void => {
        const { value, onChange } = this.props

        if (!value.some(item => item === label)) {
            onChange(value.concat(label))
        }
    }

    render() {
        const { value, onChange, disabled, canCreateNewLabels } = this.props
        const { isOpen, inputValue } = this.state
        const setIsOpen = isOpen => this.setState({ isOpen })

        return (
            <div className={styles.relative}>
                <AutocompleteInput<string>
                    id="actionLabelsInput"
                    slim
                    fullWidth
                    maxLength={ACTION_LABEL_MAX_LENGTH}
                    entryRegExp={actionLabelsRegExp}
                    disabled={disabled}
                    fetchDataCallback={input => {
                        const term = last(input.split(',')).trim()
                        return iDiscountsManagerLocal.findLabelsLike(term, 10, toJS(value) || [])
                    }}
                    onValueChange={inputValue => {
                        this.setState({ inputValue })
                    }}
                    onSuggestionSelect={label => {
                        this.handleAddLabel(label)
                        this.setState({ inputValue: '' })
                    }}
                    onMissingSuggestionSelect={canCreateNewLabels ? label => {
                        this.handleAddLabel(label)
                        this.setState({ inputValue: '' })
                    } : undefined}
                    onFocus={() => {
                        setIsOpen(true)
                    }}
                    onClose={() => {
                        // 700 мс чтобы успеть нажать на кнопку удаления чипа
                        setTimeout(() => {
                            if (this.mounted) {
                                setIsOpen(false)
                                this.setState({ inputValue: '' })
                            }
                        }, 700)
                    }}
                />
                {!isOpen && value.length > 0 && (
                    <div className={styles.labelsHolder}>
                        {value.map(label => (
                            <Chip
                                color="primary"
                                key={label}
                                label={label}
                                size="small"
                                className={styles.actionLabel}
                                onDelete={disabled ? undefined : () => {
                                    onChange(without(value, label))
                                }}
                            />
                        ))}
                    </div>
                )}
                {isOpen && !inputValue && value.length > 0 && (
                    <Paper className={styles.labelsHolderOpen}>
                        {value.map(label => (
                            <Chip
                                color="primary"
                                key={label}
                                label={label}
                                size="small"
                                className={styles.actionLabel}
                                onDelete={disabled ? undefined : () => {
                                    onChange(without(value, label))
                                }}
                            />
                        ))}
                    </Paper>
                )}
            </div>
        )
    }
}

export default ActionLabelsAutocomplete
