import { TopologyFilterVO } from '../../../set-retail10-commons/data-structs-module/topology-filter-vo'

export interface FormatsTopologyVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsTopologyVO'
    id?: number
    name?: string
    description?: string
    topologyFilters?: TopologyFilterVO[]
}

export const createFormatsTopologyVO = (initialization: Partial<FormatsTopologyVO>): FormatsTopologyVO => {
    if (!initialization) return null
    
    let result: FormatsTopologyVO = {
        id: initialization.id,
        name: initialization.name,
        description: initialization.description,
        topologyFilters: initialization.topologyFilters,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsTopologyVO',
    }
    
    return result
}
