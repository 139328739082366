import { action, observable } from 'mobx'
import { paymentTypesManager } from '../../../../protocol/set10/payment-types-manager'
import { IdInfoPair } from '../../../../protocol/set10/set-retail10-server/retailx/set-payment-type-manager/id-info-pair'
import {
    PluginProperty
} from '../../../../protocol/set10/set-retail10-server/retailx/set-payment-type-manager/plugin-property'
import { getStore } from '../../stores-repository'
import { APP_STORE } from '../../stores'
import { AppStore } from '../../app-store'
import { last } from 'lodash'

export type PaymentType = IdInfoPair

export type PaymentTypeProp = PluginProperty

// SR-3670: У кастомных типов оплаты не подрезаем идентификатор
export const getPaymentTypeId = (payment: PaymentType): string => {
    if (!payment) return null
    return payment.custom ? payment.id : last(payment.id.split('.'))
}

export class PaymentTypesStore {
    @observable
    paymentTypes: PaymentType[] = undefined

    @observable
    isLoading: boolean = false

    private appStore: AppStore = getStore(APP_STORE)

    @action
    fetchPaymentTypes = async (force: boolean = false): Promise<void> => {
        if (this.paymentTypes && !force) return
        this.isLoading = true
        const paymentTypes = await paymentTypesManager.getLocalizedPluginInfos(this.appStore.locale)
        this.setPaymentTypes(paymentTypes)
    }

    @action
    setPaymentTypes = (paymentTypes: PaymentType[]): void => {
        this.paymentTypes = paymentTypes
        this.isLoading = false
    }

    getPaymentType = (paymentTypeId: string): PaymentType => {
        return this.paymentTypes?.find(paymentType => paymentType.id === paymentTypeId)
    }

    @action
    reset = (): void => {
        this.paymentTypes = undefined
    }
}
