import React from 'react'
import { getFilterTypeName, FilterType, ACTION_SEGMENT } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { AutocompleteInput } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { t } from 'i18next'
import { segmentService } from '../../../../protocol/set10/segment-service'
import { SegmentVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/segment-vo'

const styles = require('./action-segment.scss')

export class ActionSegment extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_SEGMENT

    render() {
        const { id, data, onChange, slim } = this.props

        const shownValue = data || ''
        return (
            <AutocompleteInput
                id={id}
                slim={slim}
                onSuggestionSelect={(segment: SegmentVO) => {
                    onChange(ActionSegment.filterType, {
                        value: segment ? String(segment.guid) : null,
                        data: segment ? segment.name : null
                    })
                }}
                label={getFilterTypeName(ActionSegment.filterType)}
                shownValue={shownValue}
                showClearButton
                placeholder={t('filters.actionSegmentPlaceholder')}
                fetchDataCallback={mask => segmentService.getSegmentsByNameOrCodeLike(mask, 20)}
                suggestionsLimit={20}
                keyField="guid"
                labelField="name"
                menuItemRenderer={(segment: SegmentVO) => {
                    return (
                        <div>
                            <div>{segment.name}</div>
                            <div className={styles.secondaryText}>{segment.guid}</div>
                        </div>
                    )
                }}
            />
        )
    }
}
