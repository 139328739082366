import { Tab } from './tab'
import { CashDesk } from './cash-desk'
import { CashInAndOutVO } from './cash-in-and-out-vo'

export type ShiftStatus = 'OPEN' | 'CLOSED' | 'CLOSED_FIXDOC' | 'CLOSED_NONVALID'

export const OPEN: ShiftStatus = 'OPEN'
export const CLOSED: ShiftStatus = 'CLOSED'
export const CLOSED_FIXDOC: ShiftStatus = 'CLOSED_FIXDOC'
export const CLOSED_NONVALID: ShiftStatus = 'CLOSED_NONVALID'


export interface Shift {
    '@class': 'ru.crystals.operday.gui.datastructs.operday.Shift'
    number?: number
    status?: ShiftStatus
    openDate?: Date
    closeDate?: Date
    duration?: number
    description?: string
    repair?: boolean
    fiscalNum?: string
    amountPerShift?: string
    amountPerShiftL?: number
    tabs?: Tab[]
    operDay?: Date
    cashDesk?: CashDesk
    fio?: string
    cashInOut?: CashInAndOutVO[]
}

export const createShift = (initialization: Partial<Shift>): Shift => {
    if (!initialization) return null
    
    let result: Shift = {
        number: initialization.number,
        status: initialization.status,
        openDate: initialization.openDate,
        closeDate: initialization.closeDate,
        duration: initialization.duration,
        description: initialization.description,
        repair: initialization.repair,
        fiscalNum: initialization.fiscalNum,
        amountPerShift: initialization.amountPerShift,
        amountPerShiftL: initialization.amountPerShiftL,
        tabs: initialization.tabs,
        operDay: initialization.operDay,
        cashDesk: initialization.cashDesk,
        fio: initialization.fio,
        cashInOut: initialization.cashInOut,
        '@class': 'ru.crystals.operday.gui.datastructs.operday.Shift',
    }
    
    return result
}
