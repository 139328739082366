import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRODUCT_DETAILS_STORE, PRODUCT_RESTRICTIONS_STORE, PRODUCT_SALES_POLICY_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { AppStore } from '../../../store/app-store'
import PricesCharts from './prices-charts'
import PricesShopsList from './prices-shops-list'
import { ProductSalesPolicyStore } from '../../../store/products/product-sales-policy-store'
import { ProductRestrictionsStore } from '../../../store/products/product-restrictions-store'

export interface SalesPolicyProps {
    app?: AppStore
    productDetailsStore?: ProductDetailsStore
    productSalesPolicyStore?: ProductSalesPolicyStore
    productRestrictionsStore?: ProductRestrictionsStore
}

@inject(APP_STORE)
@inject(PRODUCT_DETAILS_STORE)
@inject(PRODUCT_SALES_POLICY_STORE)
@inject(PRODUCT_RESTRICTIONS_STORE)
@observer
export default class SalesPolicy extends React.Component<SalesPolicyProps> {

    componentDidMount(): void {
        this.initProduct()
    }

    initProduct = async (): Promise<void> => {
        const { fetchNotActiveProductDetails } = this.props.productSalesPolicyStore

        await fetchNotActiveProductDetails()
    }

    render() {
        const { isCentrum } = this.props.app
        const { productDetails: activeProduct } = this.props.productDetailsStore
        const { selectedShop } = this.props.productSalesPolicyStore

        if (!activeProduct) return null

        const showPriceCharts = !isCentrum || selectedShop !== null

        return (
            <>
                {showPriceCharts ? (
                    <PricesCharts/>
                ) : (
                    <PricesShopsList/>
                )}
            </>
        )
    }
}
