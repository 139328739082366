import { Row } from './row'

export class SetApplyObject {
    name: string
    setCost: number
    maxCount: number
    valueType: ValueType
    useSecondPrice: boolean
    isSpreadDiscounts: boolean
    wholesaleRestriction: number
    calcWeightProductCountAsOne: boolean
    rows: Row[]

    constructor(initialization: Partial<SetApplyObject>) {
        this.name = initialization.name
        this.setCost = initialization.setCost
        this.maxCount = initialization.maxCount
        this.valueType = initialization.valueType
        this.useSecondPrice = initialization.useSecondPrice
        this.isSpreadDiscounts = initialization.isSpreadDiscounts
        this.wholesaleRestriction = initialization.wholesaleRestriction
        this.calcWeightProductCountAsOne = initialization.calcWeightProductCountAsOne
        this.rows = initialization.rows
    }
}

export type ValueType = 'PERCENT' | 'FIXPRICE'
export const PERCENT: ValueType = 'PERCENT'
export const FIXPRICE: ValueType = 'FIXPRICE'
