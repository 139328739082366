import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import * as React from 'react'
import {TOOLTIP_DELAY} from '../../../utils/default-timeouts'
import Button, {ButtonProps} from '@material-ui/core/Button'
import { AdaptiveContainer } from '@crystalservice/crystals-ui/lib/components/adaptive-container/adaptive-container'

const styles = require('./adaptive-icon-button.scss')

export class AdaptiveIconButton extends React.Component<AdaptiveIconButtonProps, any> {

    static defaultProps: Partial<AdaptiveIconButtonProps> = {
        id: 'adaptiveIconButton',
        adaptive: true,
        showTooltip: true,
        adaptiveClassName: '',
    }

    renderWideScreenButton = () => {
        const {
            label, adaptive, children, className, showTooltip, adaptiveClassName, id, size, disabled, onClick,
            ...other
        } = this.props

        const iconButton = (
            <IconButton
                className={classNames(className, {
                    [styles.withPointerEvents]: showTooltip
                })}
                id={`${id}IconButton`}
                component={disabled ? 'a' : 'button'}
                disabled={disabled}
                onClick={disabled ? undefined : onClick}
                {...other}
            >
                {children}
            </IconButton>
        )

        return showTooltip && label ? (
            <Tooltip
                title={label}
                disableFocusListener
                enterDelay={TOOLTIP_DELAY}
            >
                { iconButton }
            </Tooltip>
            ) : iconButton
    }

    renderNarrowScreenButton = () => {
        const { label, adaptive, children, className, showTooltip, adaptiveClassName, id, size, ...other } = this.props

        return (
            <Button
                className={classNames(
                    adaptiveClassName,
                    styles.labelButton
                )}
                id={`${id}TextButton`}
                {...other}
            >
                {label}
            </Button>
        )
    }

    render() {
        const { adaptive } = this.props

        return adaptive ? (
            <AdaptiveContainer
                breakpoint="md"
                wideRenderer={this.renderWideScreenButton}
                narrowRenderer={this.renderNarrowScreenButton}
            />
        ) : this.renderWideScreenButton()
    }
}

export interface AdaptiveIconButtonProps extends ButtonProps {
    adaptive?: boolean
    label: string
    showTooltip?: boolean
    adaptiveClassName?: string
}
