import * as React from 'react'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { isNil } from 'lodash'
import { observer, inject } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AddCircle from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { LEFT, RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { SettingsActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/settings-action-panel'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { NotSearched } from '../../../components/stub-with-img/not-searched'
import { NEW } from '../../../core/values'
import { CityVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import { getTimeZoneLabel } from '../../../../utils/time-zones'
import { CityStore } from '../../../store/shops/city-store'
import { RegionStore } from '../../../store/shops/region-store'
import { DialogStore} from '../../../store/dialog-store'
import { CITY_STORE, DIALOG_STORE, REGION_STORE } from '../../../store/stores'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'

const styles = require('./region-page.scss')

export interface RegionPageURLParams {
    id?: string
}

export interface RegionPageProps extends RouteComponentProps<RegionPageURLParams>, React.HTMLProps<HTMLDivElement> {
    regionStore?: RegionStore
    cityStore?: CityStore
    dialogStore?: DialogStore
}

@inject(CITY_STORE)
@inject(REGION_STORE)
@inject(DIALOG_STORE)
@observer
export class RegionPage extends React.Component<RegionPageProps> {

    componentDidMount() {
        const id: string = this.props.match.params.id
        const {region, openRegion} = this.props.regionStore
        if (!region) openRegion(id)
    }

    componentWillUnmount() {
        this.props.regionStore.reset()
    }

    editCity = (cityId: number) => {
        const {validation, saveRegion, isNewRegion} = this.props.regionStore
        const {openCity} = this.props.cityStore
        if (validation.modified) {
            saveRegion(isNewRegion)
                .then(() => openCity(cityId))
        } else {
            openCity(cityId)
        }
    }

    addCity = () => {
        const {region, validation, saveRegion, isNewRegion} = this.props.regionStore
        const {openCity} = this.props.cityStore
        if (validation.modified) {
            saveRegion(isNewRegion)
                .then(newRegion => openCity(NEW, newRegion))
        } else {
            openCity(NEW, toJS(region))
        }
    }

    saveRegion = () => {
        const {isNewRegion, saveRegion} = this.props.regionStore
        saveRegion(isNewRegion)
    }

    showDeletionDialog = (node: CityVO) => {
        this.props.dialogStore.showDialog({
            title: t('topologyPages.cityDeletionTitle'),
            message: t('topologyPages.cityDeletionMessage', {
                cityName: node.name
            }),
            mode: DIALOG,
            onYes: () => this.props.regionStore.deleteCity(node.id)
        })
    }

    render() {
        const {
            region, cities, updateCurrentRegion, validation, goToPreviousPage, isNewRegion
        } = this.props.regionStore

        if (!region) return null

        const {id, name} = region

        const nameValidator = validation.getValidationFor('name')

        return (
            <div
                id="regionPage"
                className={styles.regionPage}
            >
                <Paper className={styles.container}>
                    <Grid
                        container
                        spacing={2}
                        className={styles.regionNodeInfo}
                        alignItems="center"
                    >
                        <Grid item sm={12} className={styles.regionNameField}>
                            <TextField
                                id="regionNameField"
                                label={t('topologyPages.regionNameField')}
                                value={name}
                                fullWidth
                                errorText={nameValidator.touched && nameValidator.firstError}
                                onValueChange={name => updateCurrentRegion({ name })}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <div className={styles.middleSection}>
                        {cities.length > 0 ? (
                            <>
                                <Table
                                    id="citiesList"
                                    className={styles.table}
                                    items={toJS(cities)}
                                    keyFunction={(city: CityVO): string => `${city.id}`}
                                    title={t('topologyPages.citiesTableTitle')}
                                    withHeader
                                    collapsible
                                    onRowDoubleClick={city => this.editCity(city.id)}
                                    columns={[
                                        {
                                            header: t('topologyPages.citiesColumnName'),
                                            hAlign: LEFT,
                                            labelField: 'name',
                                            width: '100%',
                                        },
                                        {
                                            header: t('topologyPages.citiesColumnTimeZone'),
                                            hAlign: RIGHT,
                                            labelFunction: (item: CityVO) => getTimeZoneLabel(item.timeZone),
                                            width: '136px',
                                        },
                                        {
                                            header: t('topologyPages.citiesColumnShopsCount'),
                                            hAlign: RIGHT,
                                            labelField: 'shopsCount',
                                            width: '104px',
                                        },
                                        {
                                            renderer: props => {
                                                const city: CityVO = props.item
                                                const id: number = city.id
                                                return (
                                                    <div className={styles.actionButtons}>
                                                        <AdaptiveIconButton
                                                            id={`city${id}EditButton`}
                                                            label={t('common.edit')}
                                                            onClick={() => this.editCity(city.id)}
                                                        >
                                                            <EditIcon/>
                                                        </AdaptiveIconButton>
                                                        <AdaptiveIconButton
                                                            id={`city${id}DeleteButton`}
                                                            label={t('common.remove')}
                                                            onClick={() => this.showDeletionDialog(city)}
                                                        >
                                                            <DeleteIcon/>
                                                        </AdaptiveIconButton>
                                                    </div>
                                                )
                                            },
                                            hAlign: RIGHT,
                                            padding: '0 10px',
                                            showOnRowHover: true,
                                            withControls: true,
                                            width: '136px'
                                        },
                                    ]}
                                />
                                <Divider className={styles.divider}/>
                            </>
                        ) : (
                            !isNewRegion &&
                                <NotSearched
                                    className={styles.stub}
                                    titleText={t('topologyPages.emptyCities')}
                                    hintText=""
                                />
                        )}
                        <div className={styles.addButtonContainer}>
                            <Button
                                id="addCityButton"
                                className={styles.addButton}
                                disabled={!validation.valid}
                                onClick={this.addCity}
                            >
                                <AddCircle
                                    className={styles.icon}
                                    color="primary"
                                />
                                <span>
                                    {t('topologyPages.addCity')}
                                </span>
                            </Button>
                        </div>
                    </div>
                </Paper>
                <SettingsActionPanel
                    id={`region${isNil(id) ? NEW : id}SettingsActionPanel`}
                    saveEnabled={validation.modified && validation.valid}
                    onSave={this.saveRegion}
                    onCancel={goToPreviousPage}
                />
            </div>
        )
    }
}
