import { observable, action, runInAction } from 'mobx'
import { ACTIVE } from '../../core/products/product-statuses'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { PRODUCT_DETAILS_STORE, USER_STORE } from '../stores'
import { t } from 'i18next'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { DatedValueVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/dated-value-vo-sf'
import { Cancelable, debounce } from 'lodash'
import { DEFAULT_REQUEST_DELAY } from '../../../utils/default-timeouts'
import { ProductDetailsStore } from './product-details-store'
import { ProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'

export type RestrictionType = 'minPrice' | 'discount' | 'disallowForSale'

export const RESTRICTION_MIN_PRICE = 'minPrice'
export const RESTRICTION_DISCOUNT = 'discount'
export const RESTRICTION_DISALLOW_FOR_SALE = 'disallowForSale'

export interface Restriction {
    id: string
    type: RestrictionType
    label: string
    price?: number
    percent?: number
    startDate?: Date
    endDate?: Date
    sinceTime?: Date,
    tillTime?: Date,
}

interface DateRange {
    startDate?: Date,
    endDate?: Date
}

export interface FetchRestrictionsParams extends DateRange {
    chartsMode: boolean,
}

const getDefaultDateRange = (): DateRange => ({
    startDate: new Date(),
    endDate: null
})

export class ProductRestrictionsStore {
    @observable
    dateRange: DateRange = getDefaultDateRange()

    @observable
    showOnlyActive: boolean = true

    @observable
    restrictions: Restriction[] = null

    @observable
    productRestrictions: Partial<ProductVO_SF> = null

    fetchRestrictionsDebouncedCallback: (() => Promise<void>) & Cancelable = debounce(
        () => this.fetchRestrictions(),
        DEFAULT_REQUEST_DELAY
    )

    private productDetailsStore: ProductDetailsStore = getStore(PRODUCT_DETAILS_STORE)

    private userStore: UserStore = getStore(USER_STORE)

    fetchRestrictions = async (params: FetchRestrictionsParams = {
        startDate: null,
        endDate: null,
        chartsMode: null
    }): Promise<void> => {
        const { productCode } = this.productDetailsStore
        const {
            startDate: customStartDate,
            endDate: customEndDate,
            chartsMode
        } = params

        let startDate: Date
        let endDate: Date

        if (!this.showOnlyActive) {
            startDate = this.dateRange.startDate
            endDate = this.dateRange.endDate
        }

        const productInfo = await iProductsManagerLocal.getProductRestrictions(
            this.userStore.session,
            productCode,
            ACTIVE,
            customStartDate || startDate,
            customEndDate || endDate,
            chartsMode ? false : this.showOnlyActive
        )

        if (!productInfo) return

        let restrictions: Restriction[] = []

        if (productInfo.minPrices) {
            productInfo.minPrices.forEach((item: DatedValueVO_SF, index: number) => {
                restrictions.push({
                    id: RESTRICTION_MIN_PRICE + index,
                    type: RESTRICTION_MIN_PRICE,
                    label: t('productDetails.restrictions.minPrice'),
                    price: +item.value / 100, // здесь только целые числа - значение в копейках
                    startDate: item.beginDate,
                    endDate: item.date,
                    sinceTime: item.sinceTime,
                    tillTime: item.tillTime,
                })
            })
        }

        if (productInfo.maxDiscounts) {
            productInfo.maxDiscounts.forEach((item: DatedValueVO_SF, index: number) => {
                const fractionDigits = item.value.length - 4 // '0.01'.length === 4
                const percent = +(Number(item.value) * 100).toFixed(fractionDigits >= 0 ? fractionDigits : 0)

                restrictions.push({
                    id: RESTRICTION_DISCOUNT + index,
                    type: RESTRICTION_DISCOUNT,
                    label: t('productDetails.restrictions.maxDiscount'),
                    percent,
                    startDate: item.beginDate,
                    endDate: item.date,
                    sinceTime: item.sinceTime,
                    tillTime: item.tillTime,
                })
            })
        }

        if (productInfo.productSales) {
            productInfo.productSales.forEach((item: DatedValueVO_SF, index: number) => {
                restrictions.push({
                    id: RESTRICTION_DISALLOW_FOR_SALE + index,
                    type: RESTRICTION_DISALLOW_FOR_SALE,
                    label: t('productDetails.restrictions.disallowForSale'),
                    startDate: item.beginDate,
                    endDate: item.date,
                    sinceTime: item.sinceTime,
                    tillTime: item.tillTime,
                })
            })
        }

        runInAction(() => {
            this.restrictions = restrictions
            this.productRestrictions = {
                minPrices: productInfo.minPrices,
                maxDiscounts: productInfo.maxDiscounts,
                productSales: productInfo.productSales,
            }
        })
    }

    @action
    setDateRange = (startDate: Date, endDate: Date): void => {
        this.dateRange = {
            startDate,
            endDate
        }

        this.fetchRestrictionsDebouncedCallback()
    }

    @action
    setShowOnlyActive = (value: boolean): void => {
        this.showOnlyActive = value

        this.fetchRestrictionsDebouncedCallback()
    }

    @action
    reset = (): void => {
        this.dateRange = getDefaultDateRange()
        this.showOnlyActive = true
        this.restrictions = null
        this.productRestrictions = null
    }
}
