import React, { Component } from 'react'
import { AdvertisingAction } from '../../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import moment from 'moment'
import { t } from 'i18next'
import { getActionStatus, ActionStatus } from '../../../../core/advertising-actions/advertising-actions'
import classNames from 'classnames'
import {
    GeneralInteractionMethod,
    MAXIMUM_DISCOUNT,
    MAXIMUM_SETS_INDEPENDENT_DISCOUNT,
} from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/general-interaction-method'

const styles = require('./action-timeline-item-tooltip.scss')

export interface ActionTimelineItemTooltipProps {
    action: AdvertisingAction
    generalInteractionMethod: GeneralInteractionMethod
}

const getIntervalText = (action: AdvertisingAction): string => {
    const startText = moment(action.workPeriod.start).format('DD MMM YYYY')

    if (!action.workPeriod.finish) {
        return `${t('common.fromStart')} ${startText}`
    }

    const endText = moment(action.workPeriod.finish).format('DD MMM YYYY')
    return `${startText} - ${endText}`
}

const getActionStatusLabel = (action: AdvertisingAction): JSX.Element => {
    const status = getActionStatus(action)
    let text = ''
    let color = ''

    switch (status) {
        case ActionStatus.DRAFT:
        case ActionStatus.DRAFT_PAST:
            text = t('actionsSearch.statuses.draft')
            color = styles.draft
            break
        case ActionStatus.CURRENT:
            text = t('actionsSearch.statuses.currentAction')
            color = styles.currentAction
            break
        case ActionStatus.PAST:
            text = t('actionsSearch.statuses.pastAction')
            color = styles.pastAction
            break
        case ActionStatus.FUTURE:
            text = t('actionsSearch.statuses.futureAction')
            color = styles.futureAction
            break
    }

    return <span className={classNames(styles.info, color)}>{text}</span>
}

const showActionPriority = (action: AdvertisingAction, generalInteractionMethod: GeneralInteractionMethod) => {
    return action.priority && (
        action.worksAnytime && (
            generalInteractionMethod === MAXIMUM_DISCOUNT || generalInteractionMethod === MAXIMUM_SETS_INDEPENDENT_DISCOUNT
        )
    )
}

export class ActionTimelineItemTooltip extends Component<ActionTimelineItemTooltipProps> {
    render() {
        const { action, generalInteractionMethod } = this.props

        return (
            <div className={styles.container}>
                <div>
                    <span className={styles.info}>
                        {action.name}
                    </span>
                </div>
                <div>
                    <span className={styles.label}>
                        {t('advertisingActions.code')}:
                    </span>
                    <span className={styles.info}>
                        {action.externalCode}
                    </span>
                </div>
                <div>
                    <span className={styles.label}>
                        {t('advertisingActions.type')}:
                    </span>
                    <span className={styles.info}>
                        {action.worksAnytime ? t('actionsSearch.worksAnytime') :
                                               t('actionsSearch.notWorksAnytime')}
                    </span>
                </div>
                <div>
                    <span className={styles.label}>
                        {t('advertisingActions.workPeriod')}:
                    </span>
                    <span className={styles.info}>
                        {getIntervalText(action)}
                    </span>
                </div>
                <div>
                    <span className={styles.label}>
                        {t('advertisingActions.shopsCount')}:
                    </span>
                    <span className={styles.info}>
                        {action.affectedShopsCount}
                    </span>
                </div>
                {showActionPriority(action, generalInteractionMethod) &&
                <div>
                    <span className={styles.label}>
                        {t('advertisingActions.priority')}:
                    </span>
                    <span className={styles.info}>
                        {action.priority}
                    </span>
                </div>
                }
                <div>
                    <span className={styles.label}>
                        {t('advertisingActions.status')}:
                    </span>
                    {getActionStatusLabel(action)}
                </div>
            </div>
        )
    }
}
