import { observable, runInAction, computed, action, IReactionDisposer, reaction } from 'mobx'
import { t } from 'i18next'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, FAVORITE_FILTERS_STORE, PRICE_TAGS_PRINTING_STORE, CUSTOM_PRINTING_BASKET_STORE } from '../stores'
import { priceTagFacadeLocal } from '../../../protocol/set10/price-tag-facade-local'
import { TaskVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/task-vo'
import { config } from '../../config/config'
import { ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED } from '../../core/products/product-statuses'
import { withSpinner } from '../with-spinner'
import { Filter, getFiltersSearchArguments, FilterType, RUNNING_ACTIONS_FILTER, EMPTY_FILTER_VALUE } from '../../core/filters/filter'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import uuid from 'uuid'
import { XLSX } from '../../core/file-types'
import { CUSTOM_PRINTING_ACTION_PRICE_TAGS_FILTERS } from '../../core/filters/favorite-filters'
import { FavoriteFiltersStore } from '../operday/favorite-filters-store'
import { isNil, uniqBy } from 'lodash'
import { PriceTagsPrintingStore, ShelfParams } from './printing/printing-store'
import { PRICE_TAGS, PRINTING, CUSTOM_PRINTING, LOYALTY, ACTIONS, ACTION_EDIT } from '../../core/app-routes'
import { CustomPrintingBasketStore, BasketItem } from './custom-printing-basket-store'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { fromClientToServerTime } from '../../utils/app-util'
import { SideBarFiltersState, getSearchArguments } from '../../components/filters/side-bar-filters'
import { goTo } from '../../utils/router-util'
import { chunkedLoading } from '../../core/chunked-loading'
import { DEFAULT_PRICE_TAGS_PAGINATION } from '../../core/price-tags/price-tags-util'

const getDefaultFilterState = (): SideBarFiltersState => {
    return {
        shownFilters: {
            [RUNNING_ACTIONS_FILTER]: { value: EMPTY_FILTER_VALUE }
        },
    }
}

export class CustomPrintingActionPriceTagsStore {
    @observable
    products: TaskVO[] = []

    @observable
    currentFiltersState: SideBarFiltersState = getDefaultFilterState()

    @observable
    pagination: PaginationState = DEFAULT_PRICE_TAGS_PAGINATION

    searchArguments: SearchArgumentVO[] = getSearchArguments(getDefaultFilterState().shownFilters)

    private basketStore: CustomPrintingBasketStore = getStore(CUSTOM_PRINTING_BASKET_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    private reactionDisposer: IReactionDisposer = null

    @computed
    get selectedProducts(): string[] {
        return this.displayedProducts
            .filter(item => item.selected)
            .map(item => String(item.id))
    }

    @computed
    get displayedProducts(): TaskVO[] {
        const { actionBasketItems } = this.basketStore
        if (!this.products || !actionBasketItems) {
            return []
        }

        return this.products.map(product => {
            const basketItem = actionBasketItems.find(basketItem => basketItem.objectId === String(product.id))

            if (!basketItem) return null

            // Применяем к продукту изменения
            return {
                ...product,
                ...basketItem.changes,
                selected: basketItem.selected,
            }
        }).filter(item => Boolean(item))
    }

    setReaction = (): void => {
        this.reactionDisposer = reaction(
            () => this.basketStore.actionBasketItems.map(item => item.objectId),
            () => this.fetchProducts()
        )
    }

    disposeReaction = (): void => {
        if (this.reactionDisposer) {
            this.reactionDisposer()
        }
        this.reactionDisposer = null
    }

    fetchProducts = (): Promise<void> => {
        const basketIds = this.basketStore.actionBasketItems.map(item => item.objectId)
        if (basketIds.length === 0) return

        return withSpinner(async () => {
            const objectIds =
                await priceTagFacadeLocal.getObjectsIds1(basketIds, this.searchArguments, ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED) || []
            const length = objectIds.length

            let products: TaskVO[] = []
            // Если objectIds === [], то запрос на сервер возвращает ошибку.
            if (length > 0) {
                products = await chunkedLoading(objectIds, objectChunkIds => {
                    return priceTagFacadeLocal.getTasksByIds(objectChunkIds, ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED)
                })
                products = fixProductsOrder(products, objectIds)
            }

            if (products.length < length) {
                let ids = []
                objectIds.forEach(itemId => {
                    if (!products.find(task => String(task.id) === itemId)) {
                        ids.push(itemId)
                    }
                })
                this.appStore.showDialog({
                    title: t('customPrinting.action.warningDialogTitle'),
                    message: t('customPrinting.action.warningDialogMessage', {ids: ids.join(', ')}),
                })
            }

            // FIXME: SFM-208 -> Дата приходит в unix_time, в протоколе указано Date
            products.forEach(item => {
                item.printingDate = fromClientToServerTime(item.printingDate)
            })

            runInAction(() => {
                // Если один товар добавлен в две акции, то getTasksByIds вернёт дубликаты. Удаляем их.
                this.products = uniqBy(products, 'id')
                this.pagination.total = products.length
                this.pagination.page = 1
            })
        })
    }

    @action
    applyFilters = (searchArguments: SearchArgumentVO[]): Promise<void> => {
        this.searchArguments = searchArguments
        return this.fetchProducts()
    }

    openActionPage = (actionGuid: number): void => {
        goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${actionGuid}`)
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
    }

    @action
    updateProductName = (nameOfGood: string, product: TaskVO) => {
        const productId = String(product.id)
        const basketItem: BasketItem = {
            objectId: productId,
            status: ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED,
            selected: this.selectedProducts.some(item => item === productId),
            changes: {
                nameOfGood
            }
        }

        this.basketStore.updateBasketItems([basketItem])
    }

    @action
    selectProductsOnPage = (newSelectedIds: string[], oldSelectedIds: string[]): void => {
        const { updateBasketItems, actionBasketItems } = this.basketStore

        updateBasketItems(actionBasketItems.map(item => {
            let selected = item.selected

            // Элемент появился в новом выдилении
            if (newSelectedIds.includes(item.objectId)) {
                selected = true
            } else {
                // Раньше был выделен, теперь перестал = убираем
                if (oldSelectedIds.includes(item.objectId)) {
                    selected = false
                }
            }

            return {
                ...item,
                selected,
            }
        }))
    }

    deleteSelectedProducts = (): void => {
        this.basketStore.removeSelectedItems(
            ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED,
            this.selectedProducts
        )
    }

    handleExcelButton = async (): Promise<void> => {
        const reportWindow = window.open()

        const name = await priceTagFacadeLocal.getFileNameForPriceTagShelf(
            this.selectedProducts,
            this.selectedProducts.length,
            ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED,
            XLSX
        )

        const baseAddress = config.reportsAddress
        const reportUrl = `${baseAddress}?Action=getFile&FILE_NAME=${name}`
        reportWindow.location.replace(reportUrl)
    }

    handlePrintButton = (): void => {
        this.printingStore.openPrinting(
            PRICE_TAGS + CUSTOM_PRINTING + PRINTING,
            {
                productStatus: ADDITIONAL_OR_REPLACEMENT_PRICE_TAG_PRINTED,
                objectIds: this.selectedProducts,
                substitutions: getProductSubstitutionObject(this.basketStore.actionBasketItems, this.products),
                handlePrint: this.removeCurrentlyPrintedProductsFromBasket
            }
        )
    }

    @action
    resetFilters = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
        this.searchArguments = getSearchArguments(filterState.shownFilters)
        this.fetchProducts()
    }

    @action
    setPagination = (pagination: PaginationState): void => {
        this.pagination = pagination
    }

    @action
    removeCurrentlyPrintedProductsFromBasket = (shelfParamsOfCurrentlyPrintedProducts: ShelfParams): void => {
        const { productStatus, objectIds } = shelfParamsOfCurrentlyPrintedProducts

        if (shelfParamsOfCurrentlyPrintedProducts) {
            this.basketStore.removeSelectedItems(productStatus, objectIds)
        }
    }

    handleAllTasksCompleted = (): void => {
        this.fetchProducts()
    }

    @action
    reset = (): void => {
        this.products = []
        this.currentFiltersState = getDefaultFilterState()
        this.searchArguments = getSearchArguments(this.currentFiltersState.shownFilters)
        this.pagination = DEFAULT_PRICE_TAGS_PAGINATION
        this.disposeReaction()
    }
}

function getProductSubstitutionObject(basketItems: BasketItem[], products: TaskVO[]): { [key: string]: { [key: string]: string } } {
    const result: { [key: string]: { [key: string]: string } } = {}

    basketItems
        .filter(item => item.selected)
        .filter(item => products && products.some(p => String(p.id) === item.objectId))
        .filter(item => item.changes && Object.keys(item.changes).length > 0)
        .forEach(item => {
            const originalItem = products.find(p => String(p.id) === item.objectId)
            const newName = item.changes.nameOfGood
            if (newName !== originalItem.nameOfGood) {
                result[item.objectId] = {
                    NAME: newName,
                    FULL_NAME: newName,
                }
            }
        })

    return result
}

// TODO SFM-208 const products = await priceTagFacadeLocal.getTasksByIds(objectIds...)
// здесь products вернётся не в том порядке, что objectIds. Фиксим это
function fixProductsOrder(products: TaskVO[], objectIds: string[]): TaskVO[] {
    return [...products].sort((a: TaskVO, b: TaskVO) => {
        const aIndex = objectIds.findIndex(id => Number(id) === a.id)
        const bIndex = objectIds.findIndex(id => Number(id) === b.id)

        return aIndex - bIndex
    })
}
