import { isNil } from 'lodash'

/**
 * Форматирует целое число в строку, обрезая десятичную часть
 * @param value
 */
export function formatInt(value: number): string {
    if (isNil(value)) return ''
    return parseInt(String(value), 10).toString()
}

/**
 * Форматирует число в строку, с указанным количеством знаков после запятой
 * @param value
 * @param minimumFractionDigits
 * @param maximumFractionDigits
 */
export function formatNumber(value: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 2): string {
    if (isNil(value)) return ''
    return value.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits }).replace(/\s/g, '')
}
