export interface RefundPositionParametersVO {
    '@class': 'ru.crystals.report.vo.RefundPositionParametersVO'
    restCost?: number
    restQuantity?: number
    refundQuantity?: number
}

export const createRefundPositionParametersVO = (initialization: Partial<RefundPositionParametersVO>): RefundPositionParametersVO => {
    if (!initialization) return null
    
    let result: RefundPositionParametersVO = {
        restCost: initialization.restCost,
        restQuantity: initialization.restQuantity,
        refundQuantity: initialization.refundQuantity,
        '@class': 'ru.crystals.report.vo.RefundPositionParametersVO',
    }
    
    return result
}
