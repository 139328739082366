import { createFormatsAbstractVO } from './formats-abstract-vo'
import { FormatsAbstractVO } from './formats-abstract-vo'
import { SizeBindingConditionVO } from './size-binding-condition-vo'

export interface FormatsSizeBindingVO extends FormatsAbstractVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsSizeBindingVO'
    uuid?: string
    maxHeight?: number
    maxWidth?: number
    productsCount?: number
    defaultSize?: boolean
    includedItems?: SizeBindingConditionVO[]
    excludedItems?: SizeBindingConditionVO[]
    conditions?: SizeBindingConditionVO[]
}

export const createFormatsSizeBindingVO = (initialization: Partial<FormatsSizeBindingVO>): FormatsSizeBindingVO => {
    if (!initialization) return null
    
    let result: FormatsSizeBindingVO = {
        ...createFormatsAbstractVO(initialization),
        uuid: initialization.uuid,
        maxHeight: initialization.maxHeight,
        maxWidth: initialization.maxWidth,
        productsCount: initialization.productsCount,
        defaultSize: initialization.defaultSize,
        includedItems: initialization.includedItems,
        excludedItems: initialization.excludedItems,
        conditions: initialization.conditions,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsSizeBindingVO',
    }
    
    return result
}
