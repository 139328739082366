import * as React from 'react'
import {t} from 'i18next'
import {StubWithImg, StubWithImgProps} from './stub-with-img'

const NO_ITEMS_STUB = require('../../../assets/stubs/no-items-stub.svg')

export interface NotCreatedProps {
    titleText?: string
    hintText?: string
    width?: number
    className?: string
}

export class NotCreated extends React.Component<NotCreatedProps> {

    render() {
        const {titleText = t('set10.pageMockTitle'), hintText, width, className} = this.props
        return (
            <StubWithImg
                titleText={titleText}
                hintText={hintText}
                width={width}
                className={className}
                image={(
                    <img src={NO_ITEMS_STUB}/>
                )}
            />
        )
    }
}
