import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import { isNil } from 'lodash'
import { formatCardNumber } from '../../../../core/cards/cards-util'
import { AdaptiveDialog } from '../../../../../components/adaptive-dialog/adaptive-dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { INT, NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { PRESENT_CARDS_STORE } from '../../../../store/stores'
import { PresentCardsStore } from '../../../../store/cards/present-cards-store'

const styles = require('./activate-by-range-dialog.scss')

const CARD_NUMBER_REGEXP: RegExp = /^[\d ]*$/

interface ActivateByRangeDialogProps {
    presentCardsStore?: PresentCardsStore
    open: boolean
    onClose: () => void
}

interface ActivateByRangeDialogState {
    firstCardNumber: string
    count: number
}

@inject(PRESENT_CARDS_STORE)
@observer
export class ActivateByRangeDialog extends React.Component<ActivateByRangeDialogProps, ActivateByRangeDialogState> {
    state: ActivateByRangeDialogState = {
        firstCardNumber: '',
        count: null,
    }

    isCanActivate = (): boolean => {
        const { firstCardNumber, count } = this.state

        return firstCardNumber !== '' && !isNil(count)
    }

    render() {
        const { firstCardNumber, count } = this.state
        const { open, presentCardsStore } = this.props
        const {
            activationErrorMessage,
            isActivationSuccesful,
            activatePresentCards,
        } = presentCardsStore

        return (
            <AdaptiveDialog
                className={styles.activateByRangeDialog}
                open={open}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    {t('presentCards.activateByRangeDialog.title')}
                </DialogTitle>

                <DialogContent>
                    {isActivationSuccesful && <>
                        <p id="activateByRangeDialog_activationSuccessfulMessage">
                            {t('presentCards.activateByRangeDialog.msg.successful')}
                        </p>
                    </>}

                    {!isActivationSuccesful && <>
                        {activationErrorMessage && (
                            <p
                                id="activateByRangeDialog_activationErrorMessage"
                                className={styles.activationErrorMessage}
                            >
                                {activationErrorMessage}
                            </p>
                        )}

                        <TextField
                            id="activateByRangeDialog_firstCardNumber_textField"
                            className={styles.textInput}
                            label={t('presentCards.activateByRangeDialog.firstCardNumber')}
                            value={formatCardNumber(firstCardNumber)}
                            onValueChange={firstCardNumber => {
                                if (!CARD_NUMBER_REGEXP.test(firstCardNumber)) {
                                    return
                                }

                                this.setState({ firstCardNumber })
                            }}
                        />

                        <NumberInput
                            id="activateByRangeDialog_count_textField"
                            dataType={INT}
                            label={t('presentCards.activateByRangeDialog.count')}
                            max={1000}
                            min={1}
                            value={count}
                            onValueChange={count => this.setState({ count })}
                        />
                    </>}
                </DialogContent>

                <DialogActions>
                    {isActivationSuccesful && <>
                        <Button
                            color="primary"
                            id="activateByRangeDialog_okButton"
                            onClick={this.props.onClose}
                        >
                            {t('common.ok')}
                        </Button>
                    </>}

                    {!isActivationSuccesful && <>
                        <Button
                            color="primary"
                            id="activateByRangeDialog_cancelButton"
                            onClick={this.props.onClose}
                        >
                            {t('common.cancel')}
                        </Button>
                        <Button
                            color="primary"
                            id="activateByRangeDialog_saveButton"
                            disabled={!this.isCanActivate()}
                            onClick={() => activatePresentCards(firstCardNumber, count)}
                        >
                            {t('presentCards.activateByRangeDialog.activateButton')}
                        </Button>
                    </>}
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
