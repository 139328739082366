import { RoleVO } from './role-vo'

export interface RoleRightVO {
    '@class': 'ru.crystals.pos.user.RoleRightVO'
    guid?: number
    right?: string
    name?: string
    privilegeSectionCode?: string
    keyPosition?: number
    role?: RoleVO
}

export const createRoleRightVO = (initialization: Partial<RoleRightVO>): RoleRightVO => {
    if (!initialization) return null
    
    let result: RoleRightVO = {
        guid: initialization.guid,
        right: initialization.right,
        name: initialization.name,
        privilegeSectionCode: initialization.privilegeSectionCode,
        keyPosition: initialization.keyPosition,
        role: initialization.role,
        '@class': 'ru.crystals.pos.user.RoleRightVO',
    }
    
    return result
}
