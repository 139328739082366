import { DraftBlockType, DraftHandleValue, EditorChangeType } from 'draft-js'
import { PeriodType } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/period-type'

export interface ImageData {
    imageId: string,
    pirit1Source: string,
    pirit2Source: string,
    alignment: AlignBlockType
}

export enum ReplacementType {
    FullName = 'Customer',
    PurchaseDate = 'PurchaseDate',
    DiscountInAction = 'DiscountSum',
    DiscountInCheck = 'TotalDiscount',
    DiscountCard = 'InternalCard',
    Image = 'Image',
    CouponBarcode = 'CouponBarcode',
    CouponDiscount = 'CouponDiscount',
    CouponName = 'CouponName',
    CouponDescription = 'CouponDescription',
    CouponStartDate = 'CouponStartDate',
    CouponEndDate = 'CouponEndDate',
    GiftsCount = 'giftsCount'
}

export const DATE_REPLACEMENTS = [
    ReplacementType.PurchaseDate,
    ReplacementType.CouponStartDate,
    ReplacementType.CouponEndDate,
]

export type AlignBlockType = 'left' | 'center' | 'right'
export type FontBlockType = 'normal' | 'double'

export const LEFT_ALIGN: AlignBlockType = 'left'
export const CENTER_ALIGN: AlignBlockType = 'center'
export const RIGHT_ALIGN: AlignBlockType = 'right'

export const NORMAL_FONT: FontBlockType = 'normal'
export const DOUBLE_FONT: FontBlockType = 'double'

export const REPLACEMENT = 'REPLACEMENT'
export const REPLACEMENT_KEY = 'replacementKey:'

export const ATOMIC: DraftBlockType = 'atomic'

export const HANDLED: DraftHandleValue = 'handled'
export const NOT_HANDLED: DraftHandleValue = 'not-handled'

export const DELETE_CHARACTER: EditorChangeType = 'delete-character'
export const CHANGE_BLOCK_DATA: EditorChangeType = 'change-block-data'
export const CHANGE_BLOCK_TYPE: EditorChangeType = 'change-block-type'
export const REMOVE_RANGE: EditorChangeType = 'remove-range'
export const INSERT_CHARACTERS: EditorChangeType = 'insert-characters'
export const APPLY_ENTITY: EditorChangeType = 'apply-entity'

export const MOVE = 'move'
export const TEXT = 'text'

export const PIXELS_IN_MM = 5.4

export interface SizeSettings {
    maxSize?: number
}

export enum NameFormat {
    FullName = 'L_F_M',
    FirstSecondName = 'F_M',
    FirstLastName = 'F_L',
    FistName = 'F'
}

export enum DateFormat {
    DateFormat1 = 'D MMMM YYYY',
    DateFormat2 = 'D MMMM YY',
    DateFormat3 = 'DD.MM.YYYY',
    DateFormat4 = 'DD.MM.YY',
}

export interface NameSettings {
    nameFormat: NameFormat,
    alternative?: string
}

export interface DateSettings {
    dateFormat: DateFormat
}

export interface Settings {
    sizeSettings: SizeSettings,
    nameSettings?: NameSettings,
    dateSettings?: DateSettings
}

export interface BarcodeSettings {
    discountType?: DiscountType,
    discountValue?: number,
    startsAfterPeriodType?: PeriodType,
    startsAfter?: number,
    requiredClientCard?: boolean,
    worksPeriodType?: PeriodType,
    worksPeriod?: number,
    barCode?: string,
    couponTypeGuid?: number,
    onlyOneTime?: boolean,
    maxDiscount?: string,
    barcodeType?: BarcodeType,
    couponPriority?: number,
}

export enum BarcodeType {
    Code39 = 'CODE39',
    Qr = 'QR'
}

export enum DiscountType {
    Percent = 'PERCENT',
    FixSum = 'FIXSUMM',
    Calculate = 'CALCULATE'
}

export enum SettingsModalType {
    Default = 'DEFAULT',
    Coupon = 'COUPON'
}
