export interface ProductVO {
    '@class': 'ru.crystals.pos.catalog.ProductVO'
    id?: number
    markingOfTheGood?: string
    name?: string
    price?: number
}

export const createProductVO = (initialization: Partial<ProductVO>): ProductVO => {
    if (!initialization) return null
    
    let result: ProductVO = {
        id: initialization.id,
        markingOfTheGood: initialization.markingOfTheGood,
        name: initialization.name,
        price: initialization.price,
        '@class': 'ru.crystals.pos.catalog.ProductVO',
    }
    
    return result
}
