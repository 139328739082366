export type XMLVisibilityConditionType = 'VISIBLE' | 'HIDDEN' | 'SCALES_BUTTON' | 'PRODUCT_TYPE' | 'PRODUCT_GROUP'
    | 'PRODUCT_CODE' | 'PRODUCTS_LIST' | 'FORMULA' | 'WHOLESALE_LEVEL' | 'PRINTING_FOR_100_GRAM'

export const VISIBLE: XMLVisibilityConditionType = 'VISIBLE'
export const HIDDEN: XMLVisibilityConditionType = 'HIDDEN'
export const SCALES_BUTTON: XMLVisibilityConditionType = 'SCALES_BUTTON'
export const PRODUCT_TYPE: XMLVisibilityConditionType = 'PRODUCT_TYPE'
export const PRODUCT_GROUP: XMLVisibilityConditionType = 'PRODUCT_GROUP'
export const PRODUCT_CODE: XMLVisibilityConditionType = 'PRODUCT_CODE'
export const PRODUCTS_LIST: XMLVisibilityConditionType = 'PRODUCTS_LIST'
export const FORMULA: XMLVisibilityConditionType = 'FORMULA'
export const WHOLESALE_LEVEL: XMLVisibilityConditionType = 'WHOLESALE_LEVEL'
export const PRINTING_FOR_100_GRAM: XMLVisibilityConditionType = 'PRINTING_FOR_100_GRAM'
