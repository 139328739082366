import { t } from 'i18next'
import React from 'react'
import { productTypesManagerLocal } from '../../../protocol/set10/product-types-manager-local'
import { IdInfoPair } from '../../../protocol/set10/set-retail10-server/retailx/set-product-type-manager/id-info-pair'
import { SelectInputProps, DefaultSelectOption, SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

interface ProductTypeSelectProps extends SelectInputProps<DefaultSelectOption> {
    cleanAfterSelect?: boolean
    defaultValue?: string
}

interface ProductTypeSelectState {
    value: string
}

export class ProductTypeSelect extends React.PureComponent<ProductTypeSelectProps, ProductTypeSelectState> {

    state: ProductTypeSelectState = {
        value: this.props.defaultValue || ''
    }

    render() {
        const { onSelect, cleanAfterSelect, defaultValue, ref, ...other } = this.props
        const { value } = this.state

        return (
            <SelectInput
                id="productTypesSelectInput"
                label={t('set10.productTypes')}
                fetchFunction={async () => {
                    const productTypes: IdInfoPair[] = await productTypesManagerLocal
                        .getLocalizedPluginInfos(null, { useCache: true })

                    return productTypes.map(type => ({
                        label: type.info,
                        value: type.id
                    }))
                }}
                value={value}
                onSelect={selectedOption => {
                    if (!cleanAfterSelect) {
                        this.setState({ value: selectedOption.value })
                    }
                    else {
                        this.setState({value: ''})
                    }
                    onSelect(selectedOption)
                }}
                {...other}
            />
        )
    }
}
