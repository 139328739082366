
export const DEFAULT_FONTS = 'Roboto, Helvetica, sans-serif'

export const checkFontAvailable = (fontName: string): boolean => {
    // Строка включает в себя самую широкую и самые узкие буквы (w и i)
    const TEST_STRING = 'wWwBbSsIiwWw'

    let result = false
    let containerA: HTMLSpanElement = document.createElement('span')
    let containerB: HTMLSpanElement = document.createElement('span')

    containerA.textContent = TEST_STRING
    containerB.textContent = TEST_STRING

    // Сравниваем высоту и ширину = у разных шрифтов она будет отличаться
    const checkSizes = (): boolean => {
        return containerA.offsetWidth === containerB.offsetWidth
            && containerA.offsetHeight === containerB.offsetHeight
    }

    const setStyles = (container: HTMLSpanElement): void => {
        container.style.margin = '0'
        container.style.padding = '0'
        container.style.fontSize = '32px'
        container.style.position = 'absolute'
        container.style.zIndex = '-1'
    }
    setStyles(containerA)
    setStyles(containerB)

    const root = document.getElementsByTagName('body')[0]

    root.appendChild(containerA)
    root.appendChild(containerB)

    // Сначала будем сравнивать шрифт monospace
    containerA.style.fontFamily = fontName + ', monospace'
    containerB.style.fontFamily = 'monospace'

    // На случай, сели размер шрифта совпал с monospace - проверяем со вторым шрифтом
    if (checkSizes()) {
        containerA.style.fontFamily = fontName + ', Arial'
        containerB.style.fontFamily = 'Arial'
        result = !checkSizes()
    } else {
        result = true
    }

    // Удаляем элементы
    containerA.outerHTML = ''
    containerB.outerHTML = ''

    return result
}
