import React from 'react'
import { PageMock } from '../../components/page-mock/page-mock'
import {
    NOT_BINDED,
    NOT_LOADED,
    PRODUCT_LISTS,
    SCALES_MODULE,
    TEMPLATES,
    getRouteName,
    getCustomRouteName,
    SCALES_LISTS,
    PRODUCT_LIST_EDIT,
    EDIT,
    } from '../core/app-routes'
import { RouteScheme, RETAIL, CENTRUM } from '../utils/route-modules-util'
import { config } from '../config/config'
import {
    SETRETAILX_WEIGHERTEMPLATE_ADMIN,
    SETRETAILX_PRINT_PLUES_LIST,
    SETRETAILX_SCALES_TEMPLATE_BAN_LIST,
    SETRETAILX_SCALESSALEGROUPS,
    EDIT_SALES_GROUPS
} from '../core/privileges/privileges'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import { MenuNotification } from '../components/pages-container/notifications/menu-notification'
import { NOT_BINDED_TO_SCALES, NOT_LOADED_ON_SCALES } from '../store/notification-store'
import { lazy } from '../utils/code-splitting'
import { ScaleDevices } from '../pages/scales-module/scale-devices/scale-devices'

export const scalesRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'scalesNavLink',
        label: getRouteName(SCALES_MODULE),
        icon: <img src={require('../../assets/images/icons/scales/scale-balance.svg')} alt="scales"/>,
    },
    path: SCALES_MODULE,
    serverType: RETAIL,
    children: [
        // {
        //     navItemProps: {
        //         id: 'scalesDevicesNavLink',
        //         label: getCustomRouteName(`${SCALES_MODULE}${DEVICES}`),
        //     },
        //     path: `${SCALES_MODULE}${DEVICES}`,
        //     privileges: [SETRETAILX_EQUIPMENT_ADMIN],
        //     component: ScaleDevices
        // },
        {
            navItemProps: {
                id: 'scalesTemplatesNavLink',
                label: getCustomRouteName(`${SCALES_MODULE}${TEMPLATES}`),
            },
            path: `${SCALES_MODULE}${TEMPLATES}`,
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    SETRETAILX_WEIGHERTEMPLATE_ADMIN,
                    SETRETAILX_PRINT_PLUES_LIST,
                    SETRETAILX_SCALES_TEMPLATE_BAN_LIST,
                ])
            },
            component: lazy(() =>
                import(/* webpackChunkName: "scale-templates-list" */
                    '../pages/scales-module/scale-templates/scale-templates-list'
                )),
        },
        {
            cannotBeRedirectTarget: true,
            path: `${SCALES_MODULE}${TEMPLATES}${EDIT}/:id`,
            privileges: (privilegeManager: PrivilegeManager) => {
                return privilegeManager.haveAnyPrivilegeFromList([
                    SETRETAILX_WEIGHERTEMPLATE_ADMIN,
                    SETRETAILX_PRINT_PLUES_LIST,
                    SETRETAILX_SCALES_TEMPLATE_BAN_LIST,
                ])
            },
            component: lazy(() =>
                import(/* webpackChunkName: "scale-template-edit" */
                    '../pages/scales-module/scale-templates/scale-template-edit'
                )),
        },
        {
            navItemProps: {
                id: 'scalesProductListsNavLink',
                label: getCustomRouteName(`${SCALES_MODULE}${PRODUCT_LISTS}`),
            },
            path: `${SCALES_MODULE}${SCALES_LISTS}`,
            privileges: [SETRETAILX_SCALESSALEGROUPS, EDIT_SALES_GROUPS],
            component: lazy(
                () => import(/* webpackChunkName: "scales-product-lists" */ '../pages/scales-module/product-lists/scales-product-lists')
            ),
        },
        {
            path: `${SCALES_MODULE}${SCALES_LISTS}${PRODUCT_LIST_EDIT}/:id`,
            privileges: [EDIT_SALES_GROUPS],
            component: lazy(
                () => import(/* webpackChunkName: "product-list-edit" */ '../pages/scales-module/product-lists/product-list-edit'),
            )
        },
        {
            navItemProps: {
                id: 'scalesNotBindedNavLink',
                label: getCustomRouteName(`${SCALES_MODULE}${NOT_BINDED}`),
                endAdornment: <MenuNotification notificationType={NOT_BINDED_TO_SCALES}/>,
            },
            serverType: RETAIL,
            path: `${SCALES_MODULE}${NOT_BINDED}`,
            component: lazy(
                () => import(/* webpackChunkName: "unrelated-on-scales" */
                    '../pages/scales-module/unrelated-on-scales/unrelated-on-scales'),
            )
        },
        {
            navItemProps: {
                id: 'scalesNotLoadedNavLink',
                label: getCustomRouteName(`${SCALES_MODULE}${NOT_LOADED}`),
                endAdornment: <MenuNotification notificationType={NOT_LOADED_ON_SCALES}/>,
            },
            serverType: RETAIL,
            path: `${SCALES_MODULE}${NOT_LOADED}`,
            component: lazy(
                () => import(/* webpackChunkName: "unloaded-on-scales" */
                    '../pages/scales-module/unloaded-on-scales/unloaded-on-scales'),
            )
        },
    ]
})
