import * as React from 'react'
import classNames from 'classnames'

const styles = require('./stub-with-img.scss')

export interface StubWithImgProps extends React.HTMLProps<HTMLDivElement> {
    titleText: string
    hintText?: string
    width?: number
    image: React.ReactNode
}

export const StubWithImg: React.SFC<StubWithImgProps> = (props: StubWithImgProps): React.ReactElement<StubWithImgProps> => {
    let { titleText, width = 200, hintText = '', image, className = '',  ...other } = props

    return (
        <div className={classNames(styles.stubWithImg, className)} {...other}>
            <div className={styles.inner}>
                <div className={styles.image} style={width ? {width} : null}>
                    { image }
                </div>
                <div className={styles.text}>
                    {titleText && (
                        <h2 className={styles.titleText} id="stubWithImgTitle">
                            { titleText }
                        </h2>
                    )}
                    <p className={styles.hintText} id="stubWithImgHint">
                        { hintText }
                    </p>
                </div>
            </div>
        </div>
    )
}
