import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Person from '@material-ui/icons/Person'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { UserStore } from '../../../store/user-store'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Button from '@material-ui/core/Button'
import { USER_STORE } from '../../../store/stores'

const styles = require('./user-menu.scss')

export interface UserMenuProps extends React.HTMLProps<HTMLDivElement> {
    user?: UserStore
}

export interface UserMenuState {
    menuOpen: boolean
}

@inject(USER_STORE)
@observer
export class UserMenu extends React.Component<UserMenuProps, UserMenuState> {

    state: UserMenuState = {
        menuOpen: false,
    }

    renderMenu = (): React.ReactNode => {
        const {menuOpen} = this.state
        if (!menuOpen) return null

        const {user} = this.props
        const {userInfo, logout} = user

        let userName = ''

        if (userInfo.firstName) {
            userName = userInfo.firstName
        }

        if (userInfo.lastName) {
            if (userName.length > 0) userName += ' '
            userName += userInfo.lastName
        }

        let showUserInfo = !!userName || userInfo.position

        return (
            <div>
                <Paper className={styles.menuContent}>
                    <div className={styles.userInfo}>
                        <div className={styles.avatarIconContainer}>
                            <Person className={styles.avatarIcon}/>
                        </div>
                        {showUserInfo ?
                            <div>
                                <p className={styles.nameLabel}>{userName}</p>
                                <p className={styles.positionLabel}>{userInfo.position}</p>
                            </div>
                            : null
                        }
                    </div>
                    <Divider/>
                    <div className={styles.actionButtons}>
                        <Button
                            id={`exitButton`}
                            variant="text"
                            onClick={() => {
                                this.setState({
                                    menuOpen: false
                                })
                                logout()
                            }}
                        >

                            {t('set10.exit')}
                        </Button>
                    </div>
                </Paper>
            </div>
        )
    }

    render() {
        const { user, className, ...other } = this.props
        return (
            <ClickAwayListener onClickAway={() => this.setState({menuOpen: false})}>
                <div className={classNames(className, styles.userMenu)} {...other}>
                    <IconButton
                        id="userMenu"
                        className={styles.appBarIcon}
                        onClick={() => {
                            this.setState({
                                menuOpen: true
                            })
                        }}
                    >
                        <Person fontSize="small"/>
                    </IconButton>

                    {this.renderMenu()}
                </div>
            </ClickAwayListener>
        )
    }
}
