import * as React from 'react'
import {t} from 'i18next'
import {StubWithImg, StubWithImgProps} from './stub-with-img'

const ITEMS_NOT_FOUND_STUB = require('../../../assets/stubs/items-not-found-stub.svg')

export interface NothingFoundProps {
    titleText?: string
    hintText?: string
    width?: number
    className?: string
}

export class NothingFound extends React.Component<NothingFoundProps> {

    render() {
        const {titleText = t('set10.nothingFoundTitle'), hintText = t('set10.nothingFoundText'), width, className} = this.props
        return (
            <StubWithImg
                titleText={titleText}
                hintText={hintText}
                width={width}
                className={className}
                image={(
                    <img src={ITEMS_NOT_FOUND_STUB}/>
                )}
            />
        )
    }
}
