import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRODUCT_DETAILS_STORE, PRODUCT_RESTRICTIONS_STORE, PRODUCT_SALES_POLICY_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { ACTIVE, PRICE_TAG_NOT_PRINTED } from '../../../core/products/product-statuses'
import { fromClientToServerTime } from '../../../utils/app-util'
import moment, { Moment } from 'moment'
import { AppStore } from '../../../store/app-store'
import { t } from 'i18next'
import { CalendarRow } from '../../../../components/calendar-row/calendar-row'
import Tooltip from '@material-ui/core/Tooltip'
import { createDatedPriceVO_SF, DatedPriceVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/dated-price-vo-sf'
import { ProductSalesPolicyStore } from '../../../store/products/product-sales-policy-store'
import IconButton from '@material-ui/core/IconButton'
import { ChevronLeft, ChevronRight, Today } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { getProductPriceCharts } from './prices-charts-utils'
import { ProductRestrictionsStore } from '../../../store/products/product-restrictions-store'
import { DatedValueVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/dated-value-vo-sf'
import { formatPrice } from '../../../core/currency-utils'
import { ProductVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo-sf'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { getEndOfInterval, getStartOfInterval } from '../../../../utils/date-util'
import { DateTypes, DAY, MONTH, WEEK, YEAR } from '../../../../utils/date/date-format'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import classNames from 'classnames'
import { Tabs } from '@crystalservice/crystals-ui/lib/components/tabs/tabs'
import { uniq } from 'lodash'
import {
    DISCOUNT_PERCENT,
    DISCOUNT_VALUE,
    PRICE
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-type-vo'

export interface PricesChartsProps {
    productSalesPolicyStore?: ProductSalesPolicyStore
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
    productRestrictionsStore?: ProductRestrictionsStore
}

export interface PricesChartsState {
    productPriceCharts: ProductPriceChart[]
    resizeColumnWidth: number
    selectedDate: Moment
    interval: DateTypes
    activeDepartment: string
}

export interface ProductPriceChart {
    priceData: Partial<DatedPriceVO_SF>
    priceIcon: string
    priceStatus: string
    style: React.CSSProperties
}

const price1 = require('../../../../assets/images/icons/product/price-1.svg')
const price2 = require('../../../../assets/images/icons/product/price-2.svg')
const price3 = require('../../../../assets/images/icons/product/price-3.svg')
const price4 = require('../../../../assets/images/icons/product/price-4.svg')
const price5 = require('../../../../assets/images/icons/product/price-5.svg')
const restrictionIcon = require('../../../../assets/images/icons/product/restriction.svg')
export const warningIcon = require('../../../../assets/images/icons/product/warning.svg')

export const PADDING_LEFT = 12
export const PADDING_TOP = 6
export const PADDING_RIGHT = 12
export const PADDING_BOTTOM = 6

export interface SalesPrice {
    name: string,
    color: string,
    icon: string,
    rowNumber?: number
}

export const salesPrices: SalesPrice[] = [
    {
        name: 'firstPrices',
        color: '#27AE60',
        icon: price1,
    }, {
        name: 'secondPrices',
        color: '#F2C94C',
        icon: price2,
    }, {
        name: 'thirdPrices',
        color: '#F8ACAC',
        icon: price3,
    }, {
        name: 'fourthPrices',
        color: '#6A9ADC',
        icon: price4,
    }, {
        name: 'fifthPrices',
        color: '#6A9ADC',
        icon: price5,
    }, {
        name: 'minPrices',
        color: '#9C9C9C',
        icon: restrictionIcon,
    }, {
        name: 'maxDiscounts',
        color: '#9C9C9C',
        icon: restrictionIcon,
    },
]

export interface GridData extends GridColumn {
    grid: GridElement[]
}

export interface GridColumn {
    columnsCount: number
    columnWidth: number
}

export interface GridElement {
    date: number
    row: number // номер строки
    cellInterval: DateTypes // интервалы, используемые для деления ячеек календаря
    countInterval: number // одна ячейка календаря может содержать несколько дней или часов
    elData: GridElementElementData
}

export interface GridElementElementData {
    top: number
    left: number
}

const styles = require('./prices-charts.scss')

@inject(APP_STORE)
@inject(PRODUCT_DETAILS_STORE)
@inject(PRODUCT_SALES_POLICY_STORE)
@inject(PRODUCT_RESTRICTIONS_STORE)
@observer
export default class PricesCharts extends React.Component<PricesChartsProps, PricesChartsState> {

    mounted: boolean = false
    offset: number = null

    constructor(props: PricesChartsProps) {
        super(props)

        this.state = {
            productPriceCharts: null,
            resizeColumnWidth: null,
            selectedDate: moment(fromClientToServerTime(this.props.app.now())),
            interval: MONTH,
            activeDepartment: this.departments?.[0],
        }
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.updateDimensions)
        this.mounted = true

        this.updateRestrictions()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
        this.mounted = false
    }

    componentDidUpdate(prevProps: Readonly<PricesChartsProps>, prevState: Readonly<PricesChartsState>, snapshot?: any): void {
        const {
            selectedDate: prevSelectedDate,
            interval: prevInterval,
        } = prevState

        const {
            selectedDate,
            interval,
        } = this.state

        const intervalIsShifted = prevSelectedDate.toDate().getTime() !== selectedDate.toDate().getTime()
        const intervalIsChanged = prevInterval !== interval

        if (intervalIsShifted || intervalIsChanged) {
            this.updateRestrictions()
        }
    }

    updateDimensions = (): void => {
        const gridColumn = this.getGridColumn()
        if (!gridColumn) return

        const { columnWidth } = gridColumn

        if (!this.mounted) return

        this.setState({
            resizeColumnWidth: columnWidth
        })

        this.updateCharts()
    }

    updateRestrictions = async (): Promise<void> => {
        const { selectedDate, interval } = this.state

        const startOfInterval = getStartOfInterval(selectedDate.toDate(), interval)
        const endOfInterval = getEndOfInterval(selectedDate.toDate(), interval)

        const { fetchRestrictions } = this.props.productRestrictionsStore
        await fetchRestrictions({
            startDate: startOfInterval,
            endDate: endOfInterval,
            chartsMode: true
        })

        this.updateCharts()
    }

    getGridColumn = (): GridColumn => {
        const calendarColumns = document.querySelectorAll(`[data-step]`) as NodeListOf<HTMLElement>
        const calendarColumn = calendarColumns[0]
        if (!calendarColumn) return
        const calendarColumnStyles = window.getComputedStyle(calendarColumn)

        return {
            columnsCount: calendarColumns.length,
            columnWidth: calendarColumn.getBoundingClientRect().width +
                parseInt(calendarColumnStyles.marginLeft || '0px', 10) +
                parseInt(calendarColumnStyles.marginRight || '0px', 10),
        }
    }

    updateCharts = (): void => {
        const { productDetails: productDetailsActive } = this.props.productDetailsStore
        const { productDetailsNotActive, selectedShop } = this.props.productSalesPolicyStore
        const { activeDepartment } = this.state

        const gridData = this.getCalendarGridData()
        if (!gridData) return

        const now = fromClientToServerTime(this.props.app.now())
        const { selectedDate, interval } = this.state

        const startOfInterval = getStartOfInterval(selectedDate.toDate(), interval)
        const endOfInterval = getEndOfInterval(selectedDate.toDate(), interval)

        const { productRestrictions } = this.props.productRestrictionsStore

        const activePriceCharts = getProductPriceCharts(
            productDetailsActive,
            gridData,
            ACTIVE,
            now,
            selectedShop,
            startOfInterval,
            endOfInterval,
            activeDepartment,
        )

        const notActivePriceCharts = getProductPriceCharts(
            productDetailsNotActive,
            gridData,
            PRICE_TAG_NOT_PRINTED,
            now,
            selectedShop,
            startOfInterval,
            endOfInterval,
            activeDepartment,
        )

        const restrictionCharts = getProductPriceCharts(
            datedValueVOSFToDatedPriceVOSF({
                ...productRestrictions,
                minPrices: sortRestrictionByPrice(productRestrictions?.minPrices),
            }),
            gridData,
            null,
            now,
            selectedShop,
            startOfInterval,
            endOfInterval,
            activeDepartment,
        )

        if (!this.mounted) return

        this.setState({
            productPriceCharts: [
                ...activePriceCharts,
                ...notActivePriceCharts,
                ...restrictionCharts,
            ],
        })
    }

    getCalendarGridData = (): GridData => {
        let calendarCells = document.querySelectorAll(`[data-calendar-date]`) as unknown as HTMLElement[]
        if (!calendarCells || !calendarCells.length) return

        const { columnsCount, columnWidth } = this.getGridColumn()

        calendarCells = Array.from(calendarCells)

        const grid = []
        calendarCells.forEach((cell: HTMLElement) => {
            if (!cell) return null
            const gridElement = {
                date: +cell.getAttribute('data-calendar-date'),
                row: +cell.getAttribute('data-calendar-row'),
                cellInterval: cell.getAttribute('data-calendar-interval'),
                countInterval: +cell.getAttribute('data-calendar-interval-count'),
                elData: {
                    top: cell.offsetTop + cell.parentElement.offsetTop,
                    left: cell.offsetLeft + cell.parentElement.offsetLeft,
                },
            }
            grid.push(gridElement)
        })

        return {
            grid,
            columnsCount,
            columnWidth,
        }
    }

    updateMousePosition = (e: any): void => {
        e.persist()
        this.offset = e.clientX - e.target.getBoundingClientRect().x
    }

    itemRenderer = (): React.ReactElement => {
        const { productPriceCharts } = this.state

        if (!productPriceCharts) return null

        return (
            <>
                {productPriceCharts.map(price => {
                    const {
                        priceData,
                        priceIcon,
                        priceStatus,
                        style: priceStyles,
                    } = price

                    const startDate = moment(fromClientToServerTime(priceData.dateStart)).format('DD MMM YYYY HH:mm')
                    const endDate = priceData.dateEnd ?
                        moment(fromClientToServerTime(priceData.dateEnd)).format('DD MMM YYYY HH:mm') :
                        `${t('productDetails.indefinitely')}`

                    return (
                        <Tooltip
                            title={
                                <div className={styles.priceTooltip}>
                                    <img className={styles.priceIcon} src={priceIcon}/>
                                    <div className={styles.priceContent}>
                                        <div className={styles.priceTitle}>
                                            <span className={styles.priceRegularData}>
                                                {priceStatus ?
                                                    `${priceData.depart}: ${priceData.priceType === PRICE
                                                        ?
                                                        t('productDetails.salesPolicyEntrance.price')
                                                        :
                                                        t('productDetails.salesPolicyEntrance.discount')
                                                    } `
                                                :
                                                    priceData.value.includes('%') ?
                                                        `${t('productDetails.salesPolicyEntrance.maxDiscountRestriction')}: `
                                                    :
                                                        `${t('productDetails.salesPolicyEntrance.minPriceRestriction')}: `
                                                }
                                            </span>
                                            {priceData?.priceType === PRICE && <span className={styles.priceBoldData}>
                                                {priceData.value}
                                            </span>}
                                            {priceData?.priceType === DISCOUNT_PERCENT && <span>
                                                {priceData.value}
                                                {t('productDetails.salesPolicyEntrance.firstPricePercent')}
                                            </span>}
                                            {priceData?.priceType === DISCOUNT_VALUE && <span>
                                                    {priceData.value}
                                                    {t('productDetails.salesPolicyEntrance.firstPriceValue')}
                                            </span>}
                                        </div>
                                        { priceData?.oldPrice && (
                                            <p>
                                                <span className={styles.priceRegularData}>
                                                    { `${t('productDetails.salesPolicyEntrance.oldPrice')}: ` }
                                                </span>
                                                <span className={styles.priceBoldData}>
                                                    { priceData.oldPrice }
                                                </span>
                                            </p>
                                        ) }
                                        {priceData.discountIdentifier &&
                                            <div>
                                                <span className={styles.priceRegularData}>
                                                    {`${t('productDetails.salesPolicyEntrance.discountIdentifier')}: `}
                                                </span>
                                                <span className={styles.priceBoldData}>{priceData.discountIdentifier}</span>
                                            </div>
                                        }
                                        {priceStatus &&
                                            <div className={styles.priceStatus}>
                                                <span className={styles.priceRegularData}>
                                                    {`${t('productDetails.salesPolicyEntrance.status')}: `}
                                                </span>
                                                <span className={styles.priceBoldData}>{priceStatus}</span>
                                            </div>
                                        }
                                        <div className={styles.priceDates}>
                                            <span className={styles.priceRegularData}>{`${t('productDetails.salesPolicyEntrance.active')}: `}</span>
                                            <span className={styles.priceBoldData}>
                                                {`${startDate} – ${endDate}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                            key={priceData.id}
                            placement="bottom-start"
                            classes={{ tooltip: styles.priceTooltipMaxWidth, tooltipPlacementBottom: styles.priceTooltipMargin }}
                            PopperProps={{
                                popperOptions: {
                                    modifiers: {
                                        offset: {
                                            enabled: true,
                                            offset: 10,
                                            fn: data => {
                                                data.offsets.popper.left += this.offset ? this.offset : 0
                                                return data
                                            },
                                        }
                                    }
                                }
                            }}
                        >
                            <div
                                className={styles.priceChart}
                                style={priceStyles}
                                id={`priceChart-${priceData.id}`}
                                onMouseEnter={this.updateMousePosition}
                                onMouseLeave={() => this.offset = null}
                            >
                                {/* priceStyles.paddingRight - уменьшается, когда содержимое графика цены не помещается внутри*/}
                                { parseInt(priceStyles.paddingRight.toString(), 10) === PADDING_RIGHT && (
                                    <img className={styles.priceIcon} src={priceIcon}/>
                                )}
                                <div className={styles.priceContent}>
                                    <div className={styles.priceTitle}>
                                        {priceStatus ? (
                                            <>
                                                {`${priceData.depart}. ${priceData.priceType === PRICE
                                                    ?
                                                    t('productDetails.salesPolicyEntrance.price')
                                                    :
                                                    t('productDetails.salesPolicyEntrance.discount')
                                                } `}
                                            </>
                                        ) : (
                                            <>{priceData.value.includes('%') ? (
                                                    <>{`${t('productDetails.salesPolicyEntrance.maxDiscountRestriction')} ${priceData.value}`}</>
                                                ) : (
                                                    <>{`${t('productDetails.salesPolicyEntrance.minPriceRestriction')} ${priceData.value}`}</>
                                                )}
                                            </>
                                        )}
                                        {priceData?.priceType === PRICE && <span className={styles.priceBoldData}>
                                            {priceData.value}
                                        </span>}
                                        {priceData?.priceType === DISCOUNT_PERCENT && <span>
                                            {priceData.value}
                                            {t('productDetails.salesPolicyEntrance.firstPricePercent')}
                                        </span>}
                                        {priceData?.priceType === DISCOUNT_VALUE && <span>
                                            {priceData.value}
                                            {t('productDetails.salesPolicyEntrance.firstPriceValue')}
                                        </span>}
                                    </div>
                                    <div className={styles.priceDates}>
                                        {`${startDate} – ${endDate}`}
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    )
                })}
            </>
        )
    }

    weekDateView = () => {
        const { selectedDate, interval } = this.state

        const sdStart = moment(getStartOfInterval(selectedDate.toDate(), interval))
        const sdEnd = moment(getEndOfInterval(selectedDate.toDate(), interval))

        return (
            <>
                {`${sdStart.format('DD.MM.YYYY')} - ` +
                `${sdEnd.format('DD.MM.YYYY')}`}
            </>
        )
    }

    nextInterval = (): void => {
        const { selectedDate } = this.state

        this.setState({
            selectedDate: selectedDate.clone().add(1, this.state.interval)
        })
    }

    prevInterval = (): void => {
        const { selectedDate } = this.state

        this.setState({
            selectedDate: selectedDate.clone().subtract(1, this.state.interval)
        })
    }

    handleTabChange = (newValue: string): void => {
        this.setState({ activeDepartment: newValue }, this.updateCharts)
    }

    get departments() {
        const { productDetails } = this.props?.productDetailsStore

        return salesPrices?.reduce((result: string[], salePrice: SalesPrice) => {
            const prices = productDetails?.[salePrice.name] || []
            const priceDepartments = prices.map(countablePrice => countablePrice.depart)
            result.push(...priceDepartments)

            return uniq(result)
        }, []).filter(Boolean).sort()
    }

    render() {
        const now = moment(fromClientToServerTime(this.props.app.now())).toDate()
        const { productCode, productDetails } = this.props.productDetailsStore
        const { setSelectedShop } = this.props.productSalesPolicyStore
        const { productRestrictions } = this.props.productRestrictionsStore
        const { selectedDate, interval } = this.state
        const { isCentrum } = this.props.app

        if (!productRestrictions) return <PageLoader/>

        let calendarRowCount = 6

        if (productRestrictions.minPrices.length) {
            calendarRowCount = 7
        }

        if (productRestrictions.maxDiscounts.length) {
            calendarRowCount = 8
        }

        const isLikondActive = productDetails.likond &&
            (!productDetails.likond.beginDate || now >= productDetails.likond.beginDate) &&
            (!productDetails.likond.endDate || now <= productDetails.likond.endDate)

        const priceTabContent = (
            <div className={styles.priceCharts} key={productCode}>
                <div className={styles.priceChartsControlPanel}>
                    { isCentrum &&
                        <IconButton
                            id="goToShopList"
                            onClick={() => {
                                setSelectedShop(null)
                            }}
                            size="small"
                            className={styles.goToShopListButton}
                        >
                            <ChevronLeft/>
                        </IconButton>
                    }
                    <div className={styles.expirationDate}>
                        {productDetails.likond && (
                            <>
                                <div className={styles.expirationDateTitle}>
                                    {t('productDetails.salesPolicyEntrance.expirationDate')}
                                </div>
                                <div
                                    className={classNames(styles.expirationDateValue, {
                                        [styles.expirationDateValue__notActive]: !isLikondActive
                                    })}
                                    id="expirationDate">
                                    <span>
                                        {moment(fromClientToServerTime(productDetails.likond.beginDate)).format('DD.MM.YYYY HH:mm')}
                                        –
                                        {moment(fromClientToServerTime(productDetails.likond.endDate)).format('DD.MM.YYYY HH:mm')}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.virtualRow}>
                        <Button
                            onClick={() => {
                                this.setState({ selectedDate: moment(fromClientToServerTime(this.props.app.now())) })
                            }}
                            id="todayButton"
                            color="primary"
                        >
                            <Today/>
                            <span className={styles.todayText}>{t('common.today')}</span>
                        </Button>
                        <SelectInput
                            value={interval}
                            options={getIntervalOptions()}
                            onSelect={interval => {
                                this.setState({interval: interval.value})
                            }}
                            className={styles.intervalSelect}
                        />
                    </div>
                    <div className={styles.virtualRow}>
                        <div className={styles.selectedInterval} id="selectedInterval">
                            {interval === DAY && selectedDate.format('DD MMM YYYY')}
                            {interval === MONTH && selectedDate.format('MMM YYYY')}
                            {interval === WEEK && this.weekDateView()}
                            {interval === YEAR && selectedDate.format('YYYY')}
                        </div>
                        <Tooltip
                            title={interval === WEEK ?
                                t('productDetails.salesPolicyEntrance.prevIntervalFemale', {
                                    interval: getIntervalTooltip()[interval]
                                }) :
                                t('productDetails.salesPolicyEntrance.prevIntervalMale', {
                                    interval: getIntervalTooltip()[interval]
                                })
                            }
                        >
                            <IconButton
                                id="prevIntervalSalesPolicy"
                                onClick={() => {
                                    this.prevInterval()
                                }}
                                size="small"
                                className={styles.navigateButton}
                            >
                                <ChevronLeft/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={interval === WEEK ?
                                t('productDetails.salesPolicyEntrance.nextIntervalFemale', {
                                    interval: getIntervalTooltip()[interval]
                                }) :
                                t('productDetails.salesPolicyEntrance.nextIntervalMale', {
                                    interval: getIntervalTooltip()[interval]
                                })
                            }
                        >
                            <IconButton
                                id="nextIntervalSalesPolicy"
                                onClick={() => {
                                    this.nextInterval()
                                }}
                                size="small"
                                className={styles.navigateButton}
                            >
                                <ChevronRight/>
                            </IconButton>
                        </Tooltip>
                    </div>

                </div>
                <CalendarRow
                    id="pricesChartsCalendar"
                    now={now}
                    itemRenderer={this.itemRenderer}
                    timePeriod={{
                        startDate: getStartOfInterval(selectedDate.toDate(), interval),
                        endDate: getEndOfInterval(selectedDate.toDate(), interval),
                        interval,
                    }}
                    rowCount={calendarRowCount > 5 ? calendarRowCount : 6}
                />
            </div>
        )

        if (this.departments.length === 1) {
            return priceTabContent
        }

        return (
            <Tabs
                id="priceChartTabs"
                breakpoint="lg"
                onTabChange={this.handleTabChange}
                items={this.departments}
            >
                {priceTabContent}
            </Tabs>
        )
    }
}

const getIntervalOptions = (): Array<{ label: string, value: DateTypes }> => [
    {value: DAY, label: t('time.intervalOptions.day')},
    {value: WEEK, label: t('time.intervalOptions.week')},
    {value: MONTH, label: t('time.intervalOptions.month')},
    {value: YEAR, label: t('time.intervalOptions.year')},
]

const getIntervalTooltip = (): { [key: string]: string } => {
    return {
        [DAY]: t('time.intervalTooltip.day'),
        [WEEK]: t('time.intervalTooltip.week'),
        [MONTH]: t('time.intervalTooltip.month'),
        [YEAR]: t('time.intervalTooltip.year')
    }
}

export const datedValueVOSFToDatedPriceVOSF = (restrictions: Partial<ProductVO_SF>): {[key: string]: DatedPriceVO_SF[]} => {
    if (!restrictions) return {}

    const restrictionTypes = Object.keys(restrictions)

    const result = {}

    restrictionTypes.forEach(type =>
        result[type] = restrictions[type].reduce((acc, restriction: DatedValueVO_SF) => {
            acc.push(createDatedPriceVO_SF({
                id: restriction.id,
                value: getValueByType(restriction.value, type),
                dateStart: restriction.beginDate,
                dateEnd: restriction.date,
                depart: null, // ограничение не зависят от отделов
            }))

            return acc
        }, [])
    )

    return result
}

export const sortRestrictionByPrice = (restriction: DatedValueVO_SF[]): DatedValueVO_SF[] => {
    return restriction.sort((restA, restB) =>
        Number(restA.value) - Number(restB.value)
    )
}

export const getValueByType = (value: string, type: string): string => {
    switch (type) {
        case 'minPrices':
            return formatPrice(Math.round(Number(value) * 100) / 10000)
        case 'maxDiscounts':
            const fractionDigits = value.length - 4 // '0.01'.length === 4

            return `${(Number(value) * 100).toFixed(fractionDigits >= 0 ? fractionDigits : 0)}%`
    }
}
