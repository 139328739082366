import { AverageCashierPerformance } from './set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-personnel-motivation-plugin/average-cashier-performance'
import { TimePeriod } from './set-retail10-server/oper-day/set-oper-day/time-period'
import { CashierPerformance } from './set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-personnel-motivation-plugin/cashier-performance'
import { Trend } from './set-retail10-server/oper-day/set-oper-day-reports/trend'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class PersonnelMotivationReportManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-OperDay-Reports-PersonnelMotivationPlugin/SET/OperDay/Reports/Plugins/PersonnelMotivationReportManager!ru.crystals.operday.reports.gui.flexendpoints.PersonnelMotivationReportManagerLocal'
    }

    getAverageCashierPerformance = (sessionId: string, timePeriod: TimePeriod, options?: RequestOptions): Promise<AverageCashierPerformance> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getAverageCashierPerformance',
                params: [sessionId, timePeriod]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getCashierPerformance = (sessionId: string, timePeriod: TimePeriod, options?: RequestOptions): Promise<CashierPerformance[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCashierPerformance',
                params: [sessionId, timePeriod]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getDetailedCashierPerformance = (sessionId: string, timePeriod: TimePeriod, intervalsCount: number, intervalType: string, cashierPerformance: CashierPerformance, options?: RequestOptions): Promise<Trend[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getDetailedCashierPerformance',
                params: [sessionId, timePeriod, intervalsCount, intervalType, cashierPerformance]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

}

export const personnelMotivationReportManagerLocal: PersonnelMotivationReportManagerLocal = new PersonnelMotivationReportManagerLocal()
