import * as React from 'react'
import {iTopologyManagerLocal} from '../../../protocol/set10/i-topology-manager-local'
import {ShopVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { AutocompleteInput, AutocompleteInputProps } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import {t} from 'i18next'

export const ShopAutocomplete = (props: Partial<AutocompleteInputProps<ShopVO>>) => {
    const { ref, ...other } = props
    return (
        <AutocompleteInput<ShopVO>
            {...other}
            fetchDataCallback={mask => iTopologyManagerLocal.searchShopsByMask(mask, props.suggestionsLimit || 0)}
            keyField="id"
            labelFunction={shop => `${t('components.shopName')}: ${shop.name}, ${t('components.cityName')}: ${shop.city.name}`}
        />
    )
}
