import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Input from '@material-ui/core/Input'
import DeleteIcon from '@material-ui/icons/Delete'
import classNames from 'classnames'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { LEFT, RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import {
    createFormatVO,
    FormatVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import { SHOP_STORE } from '../../../store/stores'
import {ShopStore} from '../../../store/shops/shop-settings/shop-store'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'

const styles = require('./format-settings-dialog.scss')

interface FormatNameEditorProps {
    item: FormatVO,
    onEditEnd: () => void,
    shopStore?: ShopStore
}

@inject(SHOP_STORE)
class FormatNameEditor extends React.Component<FormatNameEditorProps> {
    handleInputBlur = () => {
        const {item, onEditEnd, shopStore} = this.props
        const {saveFormat, formatsAreNotUnique, rollbackFormatsChanges} = shopStore
        const format: FormatVO = item

        if (formatsAreNotUnique) {
            rollbackFormatsChanges()
        } else {
            saveFormat(format)
        }
        onEditEnd()
    }

    render() {
        const {item, shopStore} = this.props
        const {updateFormatName, formatsAreNotUnique} = shopStore
        const format: FormatVO = item

        return (
            <div
                key="formatNameField"
                className={classNames([
                    styles.editor,
                    styles.saveFormatContainer
                ])}
            >
                <Input
                    autoFocus
                    id="formatNameField"
                    className={styles.input}
                    fullWidth
                    required
                    placeholder={t('shopPage.formatName')}
                    value={format.name}
                    onChange={e => updateFormatName(format.id, e.target.value)}
                    onBlur={this.handleInputBlur}
                />
                <Button
                    id="saveFormatButton"
                    disabled={formatsAreNotUnique}
                    className={styles.button}
                    onClick={this.handleInputBlur}
                >
                    {t('common.save')}
                </Button>
            </div>
        )
    }
}

interface FormatSettingsDialogProps {
    shopStore?: ShopStore
}

@inject(SHOP_STORE)
@observer
export class FormatSettingsDialog extends React.Component<FormatSettingsDialogProps> {
    inputRef: any = null

    render() {
        const {
            closeFormatsSettings, deleteFormat, editingFormats, formatName,
            formatNameIsNotUnique, formatNameIsTooLong, formats, saveFormat, updateFormatName
        } = this.props.shopStore

        if (!editingFormats) return null

        return (
            <AdaptiveDialog
                id="fullScreenDialog"
                open
                onClose={closeFormatsSettings}
                className={styles.dialog}
                PaperProps={{className: styles.paper}}
            >
                <DialogTitle>{ t('shopPage.formatsEdition') }</DialogTitle>
                <div className={styles.addFormatForm}>
                    <p className={styles.formatNameLabel}>{t('shopPage.formatNameLabel')}</p>
                    <TextField
                        id="formatNameField"
                        className={styles.formatNameField}
                        label={t('shopPage.formatName')}
                        value={String(formatName)}
                        errorText={formatNameIsTooLong && t('shopPage.formatNameIsTooLong')
                            || formatNameIsNotUnique && t('shopPage.formatNameIsNotUnique')
                            || ''
                        }
                        onValueChange={formatName => updateFormatName(null, formatName)}
                    />
                    <Button
                        id="addFormatButton"
                        className={styles.button}
                        disabled={formatName === ''
                            || formatNameIsTooLong
                            || formatNameIsNotUnique
                        }
                        onClick={() => saveFormat(createFormatVO({name: formatName}))}
                    >
                        {t('common.create')}
                    </Button>
                </div>
                <Table
                    id="formatsList"
                    className={styles.table}
                    items={toJS(formats)}
                    keyField="id"
                    withHeader
                    collapsible
                    visibleOverflow={false}
                    columns={[
                        {
                            editable: true,
                            withControls: true,
                            editor: FormatNameEditor,
                            header: t('shopPage.formatsColumnName'),
                            hAlign: LEFT,
                            labelField: 'name',
                            width: '90%'
                        },
                        {
                            renderer: props => {
                                const format: FormatVO = props.item
                                const id: number = format.id
                                return (
                                    <div className={styles.actionButtons}>
                                        <AdaptiveIconButton
                                            id={`format${id}DeleteButton`}
                                            label={t('common.remove')}
                                            onClick={() => deleteFormat(format)}
                                        >
                                            <DeleteIcon/>
                                        </AdaptiveIconButton>
                                    </div>
                                )
                            },
                            hAlign: RIGHT,
                            padding: '0 10px',
                            showOnRowHover: true,
                            withControls: true,
                        },
                    ]}
                />
                <DialogActions className={styles.dialogActions}>
                    <Button
                        onClick={closeFormatsSettings}
                        color="primary"
                        id="dialogCloseButton"
                    >
                        {t('common.close')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
