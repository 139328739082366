import {observable, action, runInAction} from 'mobx'
import {reportsProcessorLocal} from '../../../protocol/set10/reports-processor-local'
import {KmVO} from '../../../protocol/set10/set-retail10-commons/set-oper-day-main-cash-api/km-vo'
import {UserVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/user-vo'
import {getUserShortName} from '../../../utils/name-util'
import {config} from '../../config/config'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE } from '../stores'

export class KmReportStore<T> {

    type: 3 | 6

    @observable
    kmReports: Array<KmVO<T>> = []

    @observable
    selectedKmReports: Array<KmVO<T>> = []

    private userStore: UserStore = getStore(USER_STORE)

    constructor(type: 3 | 6) {
        this.type = type
    }

    @action
    resetSelectedKmReports = () => {
        this.selectedKmReports = []
    }

    @action
    setKmReports = (kmReports: Array<KmVO<T>>) => {
        this.kmReports = kmReports as Array<KmVO<T>>
    }

    changeKmReportCashier = (report: KmVO<T>, newCashier: UserVO): Promise<void> => {
        let promise
        if (this.type === 3) {
            promise = reportsProcessorLocal.setTabNumberForKm3(report.id.toString(), newCashier.tabNumber)
        } else {
            promise = reportsProcessorLocal.setTabNumberForKm6(report.id.toString(), newCashier.tabNumber)
        }
        return promise.then(result => {
            if (result) {
                runInAction(() => {
                    let reportId = report.id
                    let index = this.kmReports.findIndex(item => item.id === reportId)
                    if (index !== -1) {
                        this.kmReports[index].cashierName = getUserShortName(newCashier)
                        this.kmReports[index].tabNumber = newCashier.tabNumber
                    }
                })
            }
        })
    }

    @action
    selectAllKmNotPrinted = () => {
        let newSelected: Array<KmVO<T>> = []
        this.kmReports.forEach(report => {
            if (!report.printed) {
                newSelected.push(report)
            }
        })
        this.selectedKmReports = newSelected
    }

    @action
    selectKmReport = (report: KmVO<T>, checked: boolean) => {
        if (checked) {
            this.selectedKmReports.push(report)
        } else {
            let reportId = report.id
            let index = this.selectedKmReports.findIndex(item => item.id === reportId)
            if (index !== -1) {
                this.selectedKmReports.splice(index, 1)
            }
        }
    }

    printKmReports = () => {
        let reportIds = this.selectedKmReports.map(report => report.id).join(',')
        let userId = this.userStore.userInfo.id
        let type = this.type.toString()
        let baseAddress = config.reportsAddress
        let reportUrl = `${baseAddress}?Action=KM${type}&KM${type}_ID=${reportIds}&FILE_TYPE=PDF&SEND_TO_PRINT_USER_ID=${userId}`
        window.open(reportUrl)
    }

    @action
    reset = (): void => {
        this.kmReports = []
        this.selectedKmReports = []
    }
}
