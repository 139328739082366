import {observable, runInAction, computed, action} from 'mobx'
import {t} from 'i18next'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE } from '../stores'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { PRICE_TAGS,  PRICE_TAG_FORMATS } from '../../core/app-routes'
import { NEW } from '../../core/values'
import { goTo } from '../../utils/router-util'
import { formatsTopologyPresenterLocal } from '../../../protocol/set10/formats-topology-presenter-local'
import { priceTagFormatPresenterLocal } from '../../../protocol/set10/price-tag-format-presenter-local'
import { withSpinner } from '../with-spinner'
import {
    FormatsTopologyVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/formats-topology-vo'
import {
    PriceTagFormatVO, createPriceTagFormatVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-vo'

/*
 * Пустой формат используется для добавления в таблицу топологии, в которой не создано форматов
 * т.к. таблица построена на группировке (группа = топология форматов)
 */
const createEmptyFormat = (id: number, name: string): PriceTagFormatVO => createPriceTagFormatVO({
    id: -1,
    name: t('priceTagsFormats.formatsList.emptyTopology'),
    topologyId: id,
    topologyName: name
})

export class PriceTagFormatsTopologiesStore {
    @observable
    formatsTopology: FormatsTopologyVO[] = []

    @observable
    priceTagFormats: PriceTagFormatVO[] = []

    /*
     * Для таблицы нужен особенный формат, учитывающий все данные.
     * Если у топологии не было форматов, создаем для неё пустой, фейковый
     */
    @computed
    get formatsTableData(): PriceTagFormatVO[] {
        // Отмечаем топологии, где есть хотя бы 1 формат
        let topologiesWithFormats = {}

        // Берем все текущие форматы (отмечаем в какой топологии)
        let formatsTableData: PriceTagFormatVO[]
        formatsTableData = this.priceTagFormats.map(item => {
            topologiesWithFormats[item.topologyId] = true
            return item
        })

        // Если в какой-то топологии не было формата - создаем пустой
        this.formatsTopology.forEach(topology => {
            if (!topologiesWithFormats[topology.id]) {
                formatsTableData.push(createEmptyFormat(topology.id, topology.name))
            }
        })

        return formatsTableData
    }

    private appStore: AppStore = getStore(APP_STORE)

    fetchAllData = async (): Promise<void> => {
        const [formatsTopology, priceTagFormats] = await withSpinner(
            Promise.all([
                formatsTopologyPresenterLocal.loadAll(),
                priceTagFormatPresenterLocal.loadAll()
            ])
        )

        runInAction(() => {
            this.formatsTopology = formatsTopology
            this.priceTagFormats = priceTagFormats
        })
    }

    showRemoveDialog = (id: number): void => {
        const formatTopology: FormatsTopologyVO = this.formatsTopology.find(item => item.id === id)
        if (!formatTopology) return

        this.appStore.showDialog({
            title: t('priceTagsFormats.formatsList.removeDialogTitle'),
            message: t('priceTagsFormats.formatsList.removeDialogMessage', {name: formatTopology.name}),
            onYes: () => this.removeTopology(formatTopology),
            mode: DIALOG
        })
    }

    removeTopology = async (formatTopology: FormatsTopologyVO): Promise<void> => {
        await formatsTopologyPresenterLocal.delete(formatTopology)
        await this.fetchAllData()
        this.appStore.showSnackbar({
            message: t('priceTagsFormats.formatsList.topologyRemoved', {name: formatTopology.name})
        })
    }

    addNewTopology = (): void => {
        goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}/${NEW}`)
    }

    editTopology = (id: number): void => {
        goTo(`${PRICE_TAGS}${PRICE_TAG_FORMATS}/${id}`)
    }

    @action
    reset = (): void => {
        this.priceTagFormats = []
        this.formatsTopology = []
    }
}
