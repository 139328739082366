export interface TopologyNodeVO {
    '@class': 'ru.crystals.setretailx.topology.TopologyNodeVO' | 'ru.crystals.setretailx.topology.ShopVO' | 'ru.crystals.setretailx.topology.CityVO' | 'ru.crystals.setretailx.topology.RegionVO'
    id?: number
}

export const createTopologyNodeVO = (initialization: Partial<TopologyNodeVO>): TopologyNodeVO => {
    if (!initialization) return null
    
    let result: TopologyNodeVO = {
        id: initialization.id,
        '@class': 'ru.crystals.setretailx.topology.TopologyNodeVO',
    }
    
    return result
}
