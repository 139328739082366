import { action, observable, runInAction, toJS } from 'mobx'
import {
    isEqual,
    cloneDeep,
    isNil,
    throttle,
    Cancelable,
    isArray
} from 'lodash'
import {
    ConditionalPrintingScheduleVO,
    createConditionalPrintingScheduleVO
} from '../../../../protocol/set10/set-retail10-server/retailx/set-directive-printing/conditional-printing-schedule-vo'
import { directivePrintingSchedulePresenter } from '../../../../protocol/set10/directive-printing-schedule-presenter'
import { goTo, RouteChangeHandler } from '../../../utils/router-util'
import { SCHEDULES, TOPOLOGIES, PRICE_TAGS, DIRECTIVE_PRINTING } from '../../../core/app-routes'
import { getUniqueNameWithNumberPostfix } from '../../../../utils/name-util'
import { AppBarStore, LEFT_ARROW } from '../../app-bar-store'
import { t } from 'i18next'
import { AppStore } from '../../app-store'
import {
    DAILY,
    PERIODICAL
} from '../../../../protocol/set10/set-retail10-server/retailx/set-directive-printing/print-schedule-type'
import uuid from 'uuid'
import { hoursToMinutes } from '../../../../utils/date/date-conversion-util'
import { createTimeInterval } from '../../../../protocol/set10/set-retail10-server/retailx/set-directive-printing/time-interval'
import { PrintReason } from '../../../../protocol/set10/set-retail10-server/retailx/set-directive-printing/print-reason'
import { getStore } from '../../../../set10/store/stores-repository'
import {
    APP_BAR_STORE,
    DIRECTIVE_PRINTING_SCHEDULES_STORE,
    APP_STORE,
} from '../../stores'
import { withSpinner } from '../../with-spinner'
import { DEFAULT_REQUEST_DELAY } from '../../../../utils/default-timeouts'
import { DialogStore } from '../../../../set10/store/dialog-store'
import { DIALOG_STORE } from '../../../../set10/store/stores'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'

export class PrintingSchedulesStore {
    @observable
    schedules: ConditionalPrintingScheduleVO[]

    @observable
    originalSchedules: ConditionalPrintingScheduleVO[] = []

    @observable
    topologyId: number = null

    addNewScheduleDebounced: (() => Promise<any>) & Cancelable = throttle(
        async (): Promise<any> => this.addNewSchedule(),
        DEFAULT_REQUEST_DELAY
    )

    @action
    reset = (): void => {
        this.topologyId = null
        this.schedules = null
        this.originalSchedules = []
    }

    @action
    openSchedules = (topologyId: number): void => {
        this.topologyId = topologyId
        goTo(`${PRICE_TAGS}${DIRECTIVE_PRINTING}${SCHEDULES}${Boolean(topologyId) ? `/${topologyId}` : ''}`)
    }

    fetchSchedules = async (topologyId: number): Promise<any> => {
        return withSpinner(
            directivePrintingSchedulePresenter
                .loadAll(topologyId)
                .then(schedules => {
                    schedules.sort((s1, s2) => {
                        if (s1.name > s2.name) {
                            return 1
                        } else if (s1.name < s2.name) {
                            return -1
                        } else {
                            return 0
                        }
                    })

                    // TODO ошибка в транспорте: https://crystals.atlassian.net/browse/SFM-208
                    schedules.forEach(schedule => {
                        schedule.reasons = schedule.reasons.map(item => {
                            return isArray(item)
                                ? (item[1] as PrintReason)
                                : item
                        })
                    })

                    runInAction(() => {
                        this.topologyId = topologyId
                        if (!this.schedules) {
                            // Только для инициализации, в последующие моменты нужно сохранять текущие изменения для всех графиков до сохранения
                            this.schedules = schedules
                        }
                        this.originalSchedules = cloneDeep(toJS(schedules))
                    })
                })
        )
    }

    @action
    updateSchedule = (
        scheduleUuid: string,
        changes: Partial<ConditionalPrintingScheduleVO>
    ): void => {
        let schedule = this.schedules.find(s => s.uuid === scheduleUuid)
        if (!schedule) return

        let index = this.schedules.indexOf(schedule)

        schedule = {
            ...toJS(schedule),
            ...changes
        }

        this.schedules.splice(index, 1, schedule)
    }

    @action
    saveSchedule = async (schedule: ConditionalPrintingScheduleVO): Promise<any> => {
        schedule.name = schedule.name.trim()
        return withSpinner(async () => {
            const savedSchedule = await directivePrintingSchedulePresenter.save(toJS(schedule))
            // TODO ошибка в транспорте: https://crystals.atlassian.net/browse/SFM-208
            savedSchedule.reasons = savedSchedule.reasons.map(item => {
                return isArray(item) ? (item[1] as PrintReason) : item
            })

            runInAction(() => {
                // Здесь новому графику сервер присваивает id
                let oldSchedule = this.schedules.find(
                    s => s.uuid === schedule.uuid
                )
                let index = -1
                if (oldSchedule) {
                    index = this.schedules.indexOf(oldSchedule)
                    this.schedules.splice(index, 1, savedSchedule)
                } else {
                    // График новый
                    this.schedules.push(savedSchedule)
                }
                // Сохраняем изменненный график как эталонный
                oldSchedule = this.originalSchedules.find(
                    s => s.uuid === schedule.uuid
                )
                if (oldSchedule) {
                    index = this.originalSchedules.indexOf(oldSchedule)
                    this.originalSchedules.splice(
                        index,
                        1,
                        cloneDeep(savedSchedule)
                    )
                } else {
                    // График новый
                    this.originalSchedules.push(
                        cloneDeep(savedSchedule)
                    )
                }
            })
        }
        )

    }

    @action
    deleteSchedule = async (
        schedule: ConditionalPrintingScheduleVO
    ): Promise<void> => {
        const index = this.schedules.findIndex(s => s.uuid === schedule.uuid)
        this.schedules.splice(index, 1)

        // Если график не сохраняли, то не нужно отсылать запрос на сервер
        const alreadySaved = this.originalSchedules.some(
            s => s.uuid === schedule.uuid
        )
        if (alreadySaved) {
            await withSpinner(async () => {
                await directivePrintingSchedulePresenter.delete(toJS(schedule))
                await this.fetchSchedules(this.topologyId)
            })
        }
    }

    addNewSchedule = (): ConditionalPrintingScheduleVO => {
        // Не сохраняем на сервер до подтверждения
        const schedule = createConditionalPrintingScheduleVO({
            uuid: uuid(),
            name: getUniqueNameWithNumberPostfix(
                t('directivePrinting.printingSchedule'),
                this.schedules,
                'name'
            ),
            reasons: [],
            days: [],
            type: DAILY,
            topologyId: this.topologyId,
            printTime: 8 * 60,
            intervalStep: 30,
            reasonInterval: createTimeInterval({
                startTime: 0,
                endTime: 23 * 60 + 59
            }),
            periodInterval: createTimeInterval({
                startTime: -30,
                endTime: 30
            })
        })

        this.schedules.push(schedule)

        return schedule
    }

    isScheduleChanged = (scheduleUuid: string): boolean => {
        if (!scheduleUuid) return false

        let current = this.schedules.find(s => s.uuid === scheduleUuid)
        let original = this.originalSchedules.find(s => s.uuid === scheduleUuid)

        return !isEqual(toJS(current), toJS(original))
    }
}

export const printingSchedulesRouteHandler: RouteChangeHandler = {
    routeMatcher: new RegExp(`${PRICE_TAGS}${DIRECTIVE_PRINTING}${SCHEDULES}`),
    onEnter: () => {
        const appStore: AppStore = getStore(APP_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const printingSchedulesStore: PrintingSchedulesStore = getStore(
            DIRECTIVE_PRINTING_SCHEDULES_STORE
        )
        const dialogStore: DialogStore = getStore(DIALOG_STORE)

        appBarStore.updateState({
            title: t('directivePrinting.directivePrintingSchedules'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                const next = appStore.isCentrum
                    ? `${PRICE_TAGS}${DIRECTIVE_PRINTING}${TOPOLOGIES}`
                    : `/`
                if (
                    isEqual(
                        toJS(printingSchedulesStore.schedules),
                        toJS(printingSchedulesStore.originalSchedules)
                    )
                ) {
                    printingSchedulesStore.reset()
                    goTo(next)
                } else {
                    dialogStore.showDialog({
                        title: t('directivePrinting.schedulesSaveChangesTitle'),
                        message: t('directivePrinting.saveChangesMessage'),
                        mode: DIALOG,
                        onYes: () => {
                            printingSchedulesStore.reset()
                            goTo(next)
                        },
                        yesLabel: t('common.exit'),
                        noLabel: t('common.cancel')
                    })
                }
            }
        })
    },
    onLeave: () => {
        const printingSchedulesStore: PrintingSchedulesStore = getStore(
            DIRECTIVE_PRINTING_SCHEDULES_STORE
        )
        printingSchedulesStore.reset()
    }
}

export const getScheduleIntervalError = (
    schedule: ConditionalPrintingScheduleVO
): string => {
    if (schedule.type !== PERIODICAL) return ''

    const RANGE_MIN: number = 0
    const RANGE_MAX: number = hoursToMinutes(24)

    let interval: number = schedule.intervalStep
    let start: number = schedule.reasonInterval.startTime
    let end: number = schedule.reasonInterval.endTime
    let period: number = end - start

    if (interval <= 0) {
        return t('directivePrinting.negativeIntervalMessage')
    }
    if (interval > period) {
        return t('directivePrinting.tooBigIntervalMessage')
    }
    if (period <= RANGE_MIN) {
        return t('directivePrinting.negativeRangeMessage')
    }

    return ''
}

export const getScheduleSearchIntervalError = (
    schedule: ConditionalPrintingScheduleVO
): string => {
    if (schedule.type !== PERIODICAL || !schedule || !schedule.periodInterval) {
        return ''
    }

    let start: number = schedule.periodInterval.startTime
    if (isNil(start)) start = -Number.MAX_SAFE_INTEGER
    let end: number = schedule.periodInterval.endTime
    let period: number = end - start

    if (period <= 0) {
        return t('directivePrinting.incorrectIntervalMessage')
    }

    return ''
}

export const haveErrorInSchedule = (
    schedule: ConditionalPrintingScheduleVO
): boolean => {
    if (!schedule) return false

    if (
        getScheduleIntervalError(schedule) ||
        getScheduleSearchIntervalError(schedule)
    ) {
        return true
    }

    return false
}
