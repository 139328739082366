import { t } from 'i18next'
import { observable, action, runInAction, toJS, computed } from 'mobx'
import { cloneDeep } from 'lodash'
import { TopologyMap } from '../../components/topology-filter/topology-map'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import {
    TOPOLOGY, CITY,
    REGIONS, NOT_FOUND, SHOPS
} from '../../core/app-routes'
import { NEW } from '../../core/values'
import { createEmptyCity } from '../../core/topology/topology-util'
import { CityVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import { iTopologyManagerLocal } from '../../../protocol/set10/i-topology-manager-local'
import { ShopVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { RegionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import { iTopologyEditorLocal } from '../../../protocol/set10/i-topology-editor-local'
import { SHOP } from '../../../protocol/set10/set-retail10-commons/data-structs-module/en-topology-level'
import { createTopologyAdressVO, TopologyAdressVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-adress-vo'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { AppStore } from '../app-store'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { SnackbarStore } from '../snackbar-store'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import {
    APP_BAR_STORE,
    APP_STORE,
    CITY_STORE,
    DIALOG_STORE,
    SNACKBAR_STORE,
    USER_STORE
} from '../stores'
import { DialogStore } from '../dialog-store'
import { withSpinner } from '../with-spinner'

export class CityStore {
    @observable
    topologyMap: TopologyMap = null

    @observable
    city: CityVO = null

    @observable
    shops: ShopVO[] = []

    @observable
    validation: FormValidation<CityVO>

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    @computed
    get isNewCity(): boolean {
        return this.city && !this.city.id
    }

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<CityVO>(
            this.city,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        fieldLengthValidator({max: 255}),
                        uniqueField(this.topologyMap.cities
                            .filter(c => c.id !== this.city.id)
                            .map(c => c.name))
                    ]
                }
            ],
            creation
        )
    }

    getCity = (cityId: number): CityVO => {
        return toJS(this.topologyMap.cities.find(city => city.id === cityId))
    }

    getShops = (cityId: number): ShopVO[] => {
        return toJS(this.topologyMap.shops.filter(shop => shop.city.id === cityId))
    }

    /**
     * При открытии страницы добавления города (ref === NEW), необходимо указать регион,
     * к которому относится данный город
     */
    openCity = (ref: string | number, region?: RegionVO): Promise<void> => {
        if (!ref) {
            this.snackbarStore.show({message: t('topologyActions.cityFetchingFailure', {id: ref})})
            goTo(NOT_FOUND)
            return Promise.resolve()
        }
        this.topologyMap = cloneDeep(toJS(this.appStore.topologyMap))
        if (ref === NEW) {
            if (!region) {
                goTo(`${SHOPS}${TOPOLOGY}${REGIONS}`)
                return
            }
            this.addCity(region)
        } else {
            this.shops = this.getShops(Number(ref))
            this.editCity(this.getCity(Number(ref)), ref.toString())
        }
    }

    deleteShop = (shopId: number): Promise<void> => {
        const adress: TopologyAdressVO = createTopologyAdressVO({
            nodeId: shopId,
            nodeLevel: SHOP
        })

        return iTopologyEditorLocal.deleteNode(adress)
            .then(() => {
                return this.appStore.fetchTopologyMap()
                    .then(() => {
                        runInAction(() => {
                            let shopPosition = this.shops.findIndex(shop => shop.id === shopId)
                            let shopName = this.shops.splice(shopPosition, 1)[0].name
                            this.snackbarStore.show({message: t('topologyActions.shopDeletionSuccess', {shopName})})
                        })
                    })
            })
            .catch(error => {
                this.snackbarStore.show({message: t('topologyActions.shopDeletionFailure', {message: error.message || ''})})
            })
    }

    @action
    updateCurrentCity = (changes: Partial<CityVO>): void => {
        Object.keys(changes).forEach(key => {
            this.city[key] = changes[key]
        })
    }

    @action
    addCity = (region: RegionVO): void => {
        if (!region) {
            goTo(`${SHOPS}${TOPOLOGY}${REGIONS}`)
            return
        }

        let newCity: CityVO = createEmptyCity()
        newCity.region = region
        this.city = newCity
        this.createValidation(true)
        goTo(`${SHOPS}${TOPOLOGY}${CITY}/${NEW}`)
    }

    @action
    editCity = (city: CityVO, ref?: string): void => {
        if (!city) {
            this.snackbarStore.show({message: t('topologyActions.cityFetchingFailure', { id: ref })})
            goTo(NOT_FOUND)
            return
        }

        this.city = city
        this.createValidation()
        goTo(`${SHOPS}${TOPOLOGY}${CITY}/${city.id}`)
    }

    saveCity = (creation: boolean = false): Promise<CityVO> => {
        return withSpinner(
            iTopologyManagerLocal.addCity(this.userStore.session, toJS(this.city))
                .then(city => {
                    runInAction(() => {
                        this.editCity(city)
                        creation ?
                            this.snackbarStore.show({message: t('topologyActions.cityCreationSuccess', {cityName: this.city.name})}) :
                            this.snackbarStore.show({message: t('topologyActions.cityModificationSuccess', {cityName: this.city.name})})
                    })
                    return this.appStore.fetchTopologyMap()
                        .then(() => city)
                })
                .catch(error => {
                    creation ?
                        this.snackbarStore.show({message: t('topologyActions.cityCreationFailure')}) :
                        this.snackbarStore.show({message: t('topologyActions.cityModificationFailure')})
                    return null
                })
        )
    }

    goToPreviousPage = (): void => {
        let id = this.city && this.city.region && this.city.region.id

        if (!id) {
            goTo(`${SHOPS}${TOPOLOGY}${REGIONS}`)
            return
        }
        goTo(`${SHOPS}${TOPOLOGY}/region/${id}`)
    }

    @action
    reset = (): void => {
        this.topologyMap = null
        this.city = null
        this.shops = []
        this.validation = undefined
    }
}

/**
 * Обработчик для страниц редактирования и добавления города
 */
export const TOPOLOGY_CITY_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${SHOPS}${TOPOLOGY}${CITY}/([\\w-]+)/?$`),
    onEnter: (newRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const dialogStore: DialogStore = getStore(DIALOG_STORE)
        const cityStore: CityStore = getStore(CITY_STORE)

        let matchNewRoute = newRoute.match(TOPOLOGY_CITY_ROUTING_HANDLER.routeMatcher)
        let nodeId = matchNewRoute[1]

        appBarStore.updateState({
            title: nodeId === NEW ? `${t(`topologyPages.cityCreate`)}` : `${t(`topologyPages.cityEdit`)}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                if (cityStore.validation.modified) {
                    dialogStore.showDialog({
                        title: t('topologyPages.citySaveChangesTitle'),
                        message: t('topologyPages.citySaveChangesMessage'),
                        mode: DIALOG,
                        onYes: cityStore.goToPreviousPage,
                        yesLabel: t('common.exit'),
                        noLabel: t('common.cancel')
                    })
                } else {
                    cityStore.goToPreviousPage()
                }
            }
        })
    }
}
