import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Grid, Box } from '@material-ui/core'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { PriceTagTemplateVO } from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { ACTION_SETTINGS_STORE, PRICE_TAGS_TEMPLATES_STORE, APP_STORE, USER_STORE } from '../../../../../store/stores'
import { ActionSettingsStore } from '../../../../../store/actions/action-settings-store'
import { PriceTagsTemplatesStore } from '../../../../../store/price-tags/price-tags-templates-store'
import { AppStore } from '../../../../../store/app-store'
import { PriceTagsTemplatesTable } from '../../../../price-tags/price-tags-templates/price-tags-templates-table'
import { TOOLTIP_DELAY } from '../../../../../../utils/default-timeouts'
import { priceTagFormatPresenterLocal } from '../../../../../../protocol/set10/price-tag-format-presenter-local'
import { FormatsSelect, FormatsMap, FormatsItem } from './formats-select'
import { ACTION } from '../../../../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-type'
import { UserStore } from '../../../../../store/user-store'
import { LOY_EDIT } from '../../../../../core/privileges/privileges'
import { RadioInput } from '@crystalservice/crystals-ui/lib/components/inputs/radio-input/radio-input'

const styles = require('./set-price-tag-print-editor.scss')

enum PriceTagType {
    Product = 'PRODUCT',
    Action = 'ACTION',
}

enum ProductPriceTagType {
    Additional = 'ADDITIONAL',
    Replacement = 'REPLACEMENT',
}

interface SetPriceTagPrintEditorProps {
    actionSettingsStore?: ActionSettingsStore
    priceTagsTemplatesStore?: PriceTagsTemplatesStore
    app?: AppStore
    user?: UserStore
    onClose: () => void
}

interface SetPriceTagPrintEditorState {
    setPriceTagEnabled: boolean
    priceTagProductTypeEnabled: boolean
    priceTagActionTypeEnabled: boolean
    productPriceTagType: ProductPriceTagType
    templates: PriceTagTemplateVO[]
    actionFormatsByExternalCodes: FormatsMap
}

@inject(ACTION_SETTINGS_STORE)
@inject(USER_STORE)
@inject(PRICE_TAGS_TEMPLATES_STORE)
@inject(APP_STORE)
@observer
export class SetPriceTagPrintEditor extends React.Component<SetPriceTagPrintEditorProps, SetPriceTagPrintEditorState> {

    constructor(props: SetPriceTagPrintEditorProps, context: any) {
        super(props, context)
        const { actionSettingsStore } = this.props
        const { editedAction } = actionSettingsStore

        this.state = {
            templates: actionSettingsStore?.editedAction?.pricetagTemplates || [],
            setPriceTagEnabled: Boolean(editedAction?.pricetagTypes?.length),
            priceTagProductTypeEnabled: this.isPriceTagProductTypeEnabled(),
            priceTagActionTypeEnabled: editedAction?.pricetagTypes?.includes(PriceTagType.Action),
            productPriceTagType: editedAction?.pricetagTypes?.includes(ProductPriceTagType.Replacement)
                ? ProductPriceTagType.Replacement
                : ProductPriceTagType.Additional,
            actionFormatsByExternalCodes: new Map<string, FormatsItem>(),
        }
    }

    componentDidMount() {
        const { formatModuleEnabled } = this.props.app.printingModuleProperties

        this.props.priceTagsTemplatesStore?.fetchPriceTags()
        if (formatModuleEnabled) {
            this.fetchFormats()
        }
    }

    componentWillUnmount() {
        this.props.priceTagsTemplatesStore?.reset()
    }

    fetchFormats = async () => {
        const templateFormatExtCodes: string[] = this.props.actionSettingsStore?.editedAction?.templateFormatExtCodes || []

        const newFormatsMap = this.state.actionFormatsByExternalCodes
        const formats = await priceTagFormatPresenterLocal.loadAll() || []

        formats.forEach(format => {
            if (format.type !== ACTION) return
            if (!format.externalCode) return

            newFormatsMap.set(format.externalCode, {
                externalCode: format.externalCode,
                selected: templateFormatExtCodes.includes(format.externalCode),
                items: [
                    ...(newFormatsMap.get(format.externalCode) || []),
                    format
                ]
            })
        })

        this.setState({
            actionFormatsByExternalCodes: newFormatsMap,
        })
    }

    isPriceTagProductTypeEnabled = (): boolean => {
        const types: string[] = Object.values(ProductPriceTagType)
        return this.props.actionSettingsStore?.editedAction?.pricetagTypes?.some(pricetagType => types.includes(pricetagType))
    }

    handleSave = (): void => {
        const { actionSettingsStore, onClose } = this.props
        const {
            setPriceTagEnabled,
            priceTagActionTypeEnabled,
            priceTagProductTypeEnabled,
            productPriceTagType,
            templates,
            actionFormatsByExternalCodes
        } = this.state
        let pricetagTypes: string[] = priceTagProductTypeEnabled ? [String(productPriceTagType)] : []

        if (!setPriceTagEnabled) {
            actionSettingsStore?.modifyEditedAction({
                pricetagTemplates: [],
                pricetagTypes: [],
                templateFormatExtCodes: []
            })
        }

        if (priceTagActionTypeEnabled) {
            pricetagTypes.push(PriceTagType.Action)
        }

        const newTemplateFormatExtCodes = []
        actionFormatsByExternalCodes.forEach((formatsMapValue, formatsMapKey) => {
            if (formatsMapValue.selected) {
                newTemplateFormatExtCodes.push(formatsMapKey)
            }
        })

        actionSettingsStore?.modifyEditedAction({
            pricetagTemplates: templates,
            pricetagTypes,
            templateFormatExtCodes: newTemplateFormatExtCodes
        })
        onClose()
    }

    render() {
        const { onClose, priceTagsTemplatesStore } = this.props
        const {
            setPriceTagEnabled,
            priceTagProductTypeEnabled,
            priceTagActionTypeEnabled,
            productPriceTagType,
            templates,
            actionFormatsByExternalCodes,
        } = this.state
        const { havePrivilege } = this.props.user

        const editPrivilege = havePrivilege(LOY_EDIT)

        const { formatModuleEnabled } = this.props.app.printingModuleProperties

        const actionPriceTags = priceTagsTemplatesStore.priceTags?.filter(item => item.actionable && item.extCode)

        return (
            <Dialog
                maxWidth="md"
                fullWidth
                open
                id="setPriceTagEditor"
                disableBackdropClick
                onClose={onClose}
                className={styles.dialog}
            >
                <DialogTitle>{t('advertisingActions.setPriceTag.setPriceTagTitle')}</DialogTitle>
                <DialogContent>
                    <Checkbox
                        color="primary"
                        id="priceTagCheckbox"
                        label= {t('advertisingActions.setPriceTag.printPriceTagsForAction')}
                        checked={setPriceTagEnabled}
                        onValueChange={(newValue: boolean) => { this.setState({ setPriceTagEnabled: newValue }) }}
                    />

                    <Box mt={2}>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <Checkbox
                                    color="primary"
                                    id="priceTagProductCheckbox"
                                    label={t('advertisingActions.setPriceTag.product')}
                                    disabled={!setPriceTagEnabled}
                                    checked={priceTagProductTypeEnabled}
                                    onValueChange={(newValue: boolean) => { this.setState({ priceTagProductTypeEnabled: newValue }) }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Checkbox
                                    color="primary"
                                    id="priceTagActionCheckbox"
                                    label={t('advertisingActions.setPriceTag.action')}
                                    disabled={!setPriceTagEnabled}
                                    checked={priceTagActionTypeEnabled}
                                    onValueChange={(newValue: boolean) => { this.setState({ priceTagActionTypeEnabled: newValue }) }}
                                />
                            </Grid>

                            <Box ml={3}>
                                <div className={styles.radioRow}>
                                    <RadioInput
                                        id="permanentRadio"
                                        value={ProductPriceTagType.Replacement}
                                        checked={productPriceTagType === ProductPriceTagType.Replacement}
                                        onValueChange={(newValue: ProductPriceTagType) => this.setState({ productPriceTagType: newValue })}
                                        disabled={!setPriceTagEnabled || !priceTagProductTypeEnabled}
                                        label={t('advertisingActions.setPriceTag.replacing')}
                                    />
                                    <Tooltip
                                        title={t('advertisingActions.setPriceTag.replacingTooltipLabel')}
                                        disableFocusListener
                                        enterDelay={TOOLTIP_DELAY}
                                    >
                                        <InfoIcon className={styles.tooltipIcon} />
                                    </Tooltip>
                                </div>
                                <div className={styles.radioRow}>
                                    <RadioInput
                                        id="scheduledRadio"
                                        value={ProductPriceTagType.Additional}
                                        checked={productPriceTagType === ProductPriceTagType.Additional}
                                        onValueChange={(newValue: ProductPriceTagType) => this.setState({ productPriceTagType: newValue })}
                                        disabled={!setPriceTagEnabled || !priceTagProductTypeEnabled}
                                        label={t('advertisingActions.setPriceTag.additional')}
                                    />
                                    <Tooltip
                                        title={t('advertisingActions.setPriceTag.additionalTooltipLabel')}
                                        disableFocusListener
                                        enterDelay={TOOLTIP_DELAY}
                                    >
                                        <InfoIcon className={styles.tooltipIcon} />
                                    </Tooltip>
                                </div>
                            </Box>
                        </Grid>
                    </Box>

                    { setPriceTagEnabled && priceTagProductTypeEnabled && !formatModuleEnabled && (
                        <Box mt={2} ml={-3} mr={-3}>
                            <PriceTagsTemplatesTable
                                items={actionPriceTags}
                                onSelected={(_templates: PriceTagTemplateVO[]) => this.setState({ templates: _templates })}
                                selected={templates}
                            />
                        </Box>
                    ) }

                    { setPriceTagEnabled && priceTagProductTypeEnabled && formatModuleEnabled && (
                        <Box mt={2} ml={-3} mr={-3}>
                            <FormatsSelect
                                formatsMap={actionFormatsByExternalCodes}
                                onFormatsMapChanged={newMap => this.setState({
                                    actionFormatsByExternalCodes: newMap
                                })}
                            />
                        </Box>
                    ) }
                </DialogContent>
                <DialogActions>
                    <Button
                        id="closeSetPriceTagPrintEditorButton"
                        onClick={onClose}
                        color="primary"
                    >
                        {t('common.cancel')}
                    </Button>

                    <Button
                        id="saveSetPriceTagPrintEditorButton"
                        onClick={this.handleSave}
                        disabled={!editPrivilege}
                        color="primary"
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
