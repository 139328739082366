import { CouponsVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/coupons-vo'

export const PROCESSING_URL = 'processing.url'

export interface Coupon extends CouponsVO {
    serial: boolean
}

export enum CouponIntervalType {
    Dates = 'date',
    Floating = 'floating',
}

export enum CouponType {
    Serial = 'serial',
    Unique = 'unique',
}

export enum AreaOfEffectType {
    Item = 'ITEM',
    Receipt = 'RECEIPT',
}

export enum IntervalMetricsType {
    Hours = 'hour',
    Days = 'day',
    Weeks = 'week',
    Months = 'month',
    Years = 'year',
}

export enum BatchStatus {
    Completed = 'COMPLETED',
    Process = 'PROCESS',
    Error = 'ERROR',
}

export enum BatchDownloadFileType {
    Text = 'TXT',
    Excel = 'SXLSX',
}
