import React from 'react'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Settings from '@material-ui/icons/Settings'

const styles = require('./action-edit-block.scss')

interface ActionEditBlockProps {
    label: string
    labelAtTop?: boolean
    handlerId?: string
    handler?: () => void
    handlerDisabled?: boolean
    renderChildren?: () => JSX.Element
}

export const ActionEditBlock: React.FC<ActionEditBlockProps> = ({
    label, labelAtTop, children, handlerId, handler, handlerDisabled, renderChildren
}) => (
    <Grid item xs={12} md={12} container className={styles.editorSection}>
        <Grid
            item
            xs={12}
            md={handler ? 3 : 4}
            className={classNames({
                [styles.editorLabel]: true,
                [styles.labelWithSelfAlign]: labelAtTop,
            })}
        >
            <span>
                {label}
            </span>
        </Grid>
        {!renderChildren && (
            <Grid item xs={handler ? 11 : 12} md={8}>
                {children}
            </Grid>
        )}
        {renderChildren && renderChildren()}
        {handler && (
            <Grid item xs={1} md={1} style={{ alignSelf: 'flex-start' }}>
                <div className={styles.actionEditSettings}>
                    <div style={{ width: 48 }}>
                        <IconButton id={handlerId} disabled={handlerDisabled} onClick={handler}>
                            <Settings />
                        </IconButton>
                    </div>
                </div>
            </Grid>
        )}
    </Grid>
)
