import { observable, action, runInAction, computed, toJS } from 'mobx'
import {
    createActionsFilter,
    ActionsFilter
} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/actions-filter'
import { ACTIVE } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/active-type'
import { AdvertisingAction } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { SideBarFiltersState, getSearchArguments } from '../../../components/filters/side-bar-filters'
import { createDatePeriod } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/date-period'
import { FilterProps } from '../../../components/filters/new-filters'
import { withSpinner } from '../../with-spinner'
import { PluginType } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/plugin-type'
import { createActorId } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/actor-id'
import { BooleanEnum } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/boolean-enum'
import { createCardsCondition } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/cards-condition'
import { AppStore } from '../../app-store'
import { getStore } from '../../stores-repository'
import { APP_STORE, USER_STORE } from '../../stores'
import { fromClientToServerTime } from '../../../utils/app-util'
import { UserStore } from '../../user-store'
import {
    ACTION_START_DATE_FILTER,
    BASE_DATE_RANGE_FILTER_DELIMITER,
    ACTION_END_DATE_FILTER,
    ACTION_LAST_CHANGED_FILTER,
    ACTION_WORKING_PERIOD_FILTER,
    ACTION_CODE_FILTER,
    STORE_NUMBER_FILTER,
    ACTION_DISCOUNT_TYPE,
    ACTION_SECONDARY_RESULT,
    ACTION_LABEL,
    ACTION_COLOR,
    ACTION_AUTHOR,
    ACTION_EDITOR,
    ACTION_COUPON_USE,
    ACTION_COUPON_CODE,
    ACTION_COUPON_CATEGORY,
    ACTION_SEGMENT,
    PRODUCT_ITEM_OR_BARCODE_FILTER,
    ACTION_CARDS
} from '../../../core/filters/filter'
import { getDateFromString } from '../../../../utils/date-util'
import { ACTION_WORKING_TYPES } from '../../../components/filters/new-modules/action-working-period'
import { DateTypes, MONTH } from '../../../../utils/date/date-format'
import orderBy from 'lodash/orderBy'
import { actionsSelectorFacade } from '../../../../protocol/set10/actions-selector-facade'

export enum WorksAnytimeOption {
    TRUE = 'true',
    FALSE = 'false',
    ALL = 'all'
}

const getDefaultFilterState = (): SideBarFiltersState => {
    return {
        shownFilters: {
            [ACTION_WORKING_PERIOD_FILTER]: {
                value: ACTION_WORKING_TYPES.EXCLUDE_FINISHED
            }
        }
    }
}

export class ActionsSearchStore {

    appStore: AppStore = getStore(APP_STORE)
    userStore: UserStore = getStore(USER_STORE)

    @observable
    actions: AdvertisingAction[]

    @observable
    currentFiltersState: SideBarFiltersState = getDefaultFilterState()

    @observable
    selectedDate: Date = fromClientToServerTime(this.appStore.now())

    @observable
    interval: DateTypes = MONTH

    @observable
    nameFilter: string = ''

    @observable
    worksAnytime: WorksAnytimeOption = WorksAnytimeOption.ALL

    @computed
    get timelineFilteredActions(): AdvertisingAction[] {
        if (!this.actions) return []

        if (this.worksAnytime === WorksAnytimeOption.ALL) {
            return this.actions.sort((action1, action2) => {
                if (action1.worksAnytime !== action2.worksAnytime) {
                    return action1.worksAnytime ? 1 : -1
                }

                return action1.priority - action2.priority
            })
        }

        const worksAnytime = this.worksAnytime === WorksAnytimeOption.TRUE
        return this.actions
            .filter(action => action.worksAnytime === worksAnytime)
            .sort((action1, action2) => action1.priority - action2.priority)
    }

    @computed
    get gotFilters(): boolean {
        return this.currentFiltersState && getSearchArguments(this.currentFiltersState.shownFilters).length > 0
    }

    @computed
    get actionsFilter(): ActionsFilter {
        let actionsFilter = createActionsFilter({
            keyWord: this.nameFilter || '%',
        })

        const shownFilters = this.currentFiltersState ? this.currentFiltersState.shownFilters : {}
        Object.keys(shownFilters).forEach(filterType => {
            const filterData: FilterProps = shownFilters[filterType]
            const value = filterData.value
            if (!value) return

            switch (filterType) {
                case ACTION_START_DATE_FILTER:
                    const startValues: string[] = value ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER) : [null, null]

                    actionsFilter.actionBeginDateInterval = createDatePeriod({
                        start: fromClientToServerTime(getDateFromString(startValues[0])),
                        finish: fromClientToServerTime(getDateFromString(startValues[1])),
                    })
                    break
                case ACTION_END_DATE_FILTER:
                    const endValues: string[] = value ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER) : [null, null]

                    actionsFilter.actionFinishDateInterval = createDatePeriod({
                        start: fromClientToServerTime(getDateFromString(endValues[0])),
                        finish: fromClientToServerTime(getDateFromString(endValues[1])),
                    })
                    break
                case ACTION_LAST_CHANGED_FILTER:
                    const lastChangesValues: string[] = value ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER) : [null, null]

                    actionsFilter.lastChanges = createDatePeriod({
                        start: fromClientToServerTime(getDateFromString(lastChangesValues[0])),
                        finish: fromClientToServerTime(getDateFromString(lastChangesValues[1])),
                    })
                    break
                case ACTION_WORKING_PERIOD_FILTER:
                    switch (value) {
                        case ACTION_WORKING_TYPES.WORKING_NOW:
                            actionsFilter.period = createDatePeriod({
                                start: fromClientToServerTime(this.appStore.now()),
                                finish: fromClientToServerTime(this.appStore.now()),
                            })
                            actionsFilter.activeType = ACTIVE
                            break
                        case ACTION_WORKING_TYPES.EXCLUDE_FINISHED:
                            actionsFilter.period = createDatePeriod({
                                start: fromClientToServerTime(this.appStore.now())
                            })
                            break
                        case ACTION_WORKING_TYPES.WORKING_ANYTIME:
                            actionsFilter.period = createDatePeriod({})
                            break
                        default:
                            break
                    }
                    break
                case ACTION_CODE_FILTER:
                    actionsFilter.extCode = value
                    break
                case STORE_NUMBER_FILTER:
                    const shopNumbers = value.split(',')
                    actionsFilter.shopNumbers = shopNumbers.map(item => Number(item))
                    break
                case ACTION_DISCOUNT_TYPE:
                    actionsFilter.pluginType = value as PluginType
                    break
                case ACTION_SECONDARY_RESULT:
                    actionsFilter.secondaryResultTypes = value.split(',')
                    break
                case ACTION_LABEL:
                    actionsFilter.label = value
                    break
                case ACTION_COLOR:
                    actionsFilter.color = value
                    break
                case ACTION_AUTHOR:
                    actionsFilter.author = createActorId({ login: value })
                    break
                case ACTION_EDITOR:
                    actionsFilter.editor = createActorId({ login: value })
                    break
                case ACTION_COUPON_USE:
                    actionsFilter.hasCouponCondition = value as BooleanEnum
                    break
                case ACTION_COUPON_CODE:
                    actionsFilter.couponNumber = value
                    break
                case ACTION_COUPON_CATEGORY:
                    actionsFilter.couponCategoryGuids = [Number(value)]
                    break
                case ACTION_SEGMENT:
                    actionsFilter.segmentGuid = value
                    break
                case PRODUCT_ITEM_OR_BARCODE_FILTER:
                    actionsFilter.good = value
                    break
                case ACTION_CARDS:
                    actionsFilter.cardsCondition = createCardsCondition({
                        cardTypeGuids: value === '[]' ? [] : value.split(',').map(Number)
                    })
                    break
                default:
                    break
            }
        })

        return actionsFilter
    }

    fetchActions = async (): Promise<void> => {
        const result = await withSpinner(actionsSelectorFacade.getActionsByFilterWithOffset(
            this.actionsFilter,
            [],
            0,
            1000
        ))

        runInAction(() => {
            this.actions = orderBy(result, ['worksAnytime', 'priority'], ['asc', 'asc'])
        })
    }

    @action
    clearFilters = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
        this.nameFilter = ''
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
    }

    @action
    setNameFilter = (nameFilter: string): void => {
        this.nameFilter = nameFilter
    }

    @action
    setDate = (selectedDate: Date): void => {
        this.selectedDate = selectedDate
    }

    @action
    setInterval = (interval: DateTypes): void => {
        this.interval = interval
    }

    @action
    setWorksAnytime = (worksAnytime: WorksAnytimeOption): void => {
        this.worksAnytime = worksAnytime
    }

    @action
    reset = (): void => {
        this.actions = undefined
        this.currentFiltersState = getDefaultFilterState()
        this.nameFilter = ''
        this.worksAnytime = WorksAnytimeOption.FALSE
        this.interval = MONTH
        this.selectedDate = fromClientToServerTime(this.appStore.now())
    }
}
