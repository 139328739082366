import { t } from 'i18next'
import { action, observable, computed, toJS, runInAction } from 'mobx'
import uuid from 'uuid'
import {
    SearchArgumentVO, createSearchArgumentVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { RegularPriceChangeVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/regular-price-change-vo'
import { getStore } from '../stores-repository'
import { PRICE_TAGS_PRINTING_STORE, DIALOG_STORE } from '../stores'
import {
    ENDED_PRICES_BY_NOW_FILTER, EMPTY_FILTER_VALUE, END_DATE_FILTER
} from '../../core/filters/filter'
import { priceTagFacadeLocal } from '../../../protocol/set10/price-tag-facade-local'
import { REGULAR_PRICE_ENDED } from '../../core/products/product-statuses'
import { XLSX } from '../../core/file-types'
import { config } from '../../config/config'
import { PriceTagsPrintingStore } from './printing/printing-store'
import { DialogStore } from '../dialog-store'
import { PRICE_TAGS, NOT_ACTUAL, PRINTING } from '../../core/app-routes'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { SideBarFiltersState, getSearchArguments } from '../../components/filters/side-bar-filters'
import { ALERT } from '../../../components/simple-dialog/simple-dialog'
import { chunkedLoading } from '../../core/chunked-loading'
import { withSpinner } from '../with-spinner'
import { DEFAULT_PRICE_TAGS_PAGINATION } from '../../core/price-tags/price-tags-util'
import isNil from 'lodash/isNil'
import { isEqual } from 'lodash'

const getDefaultFilterState = (): SideBarFiltersState => {
    return {
        shownFilters: {
            [ENDED_PRICES_BY_NOW_FILTER]: { value: EMPTY_FILTER_VALUE }
        },
    }
}

export class NotActualRegularPriceTagsStore {

    @observable
    searchArguments: SearchArgumentVO[] = getSearchArguments(getDefaultFilterState().shownFilters)

    @observable
    currentFiltersState: SideBarFiltersState = getDefaultFilterState()

    @observable
    priceChangeIds: string[] = null

    @observable
    priceChanges: RegularPriceChangeVO[] = null

    @observable
    selectedPriceChangeIds: number[] = []

    @observable
    tasksInProgress: string[] = []

    @observable
    pagination: PaginationState = DEFAULT_PRICE_TAGS_PAGINATION

    pollingTimer: any = null
    pollingTimeOut: number = 30 * 1000

    private dialogStore: DialogStore = getStore(DIALOG_STORE)
    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    @computed
    get displayedPriceChanges(): RegularPriceChangeVO[] {
        return this.priceChanges?.map(item => {
            // Применяем изменения в имени объекта
            const selected = this.selectedPriceChangeIds.includes(item.priceId)

            return {
                ...item,
                selected
            }
        }) || []
    }

    startPolling = (): void => {
        clearTimeout(this.pollingTimer)
        this.pollingTimer = setTimeout(() => {
            this.fetchPriceChangeIds(true)
            this.startPolling()
        }, this.pollingTimeOut)
    }

    stopPolling = (): void => {
        clearTimeout(this.pollingTimer)
    }

    @action
    fetchFirstData = async (): Promise<void> => {
        this.applyFilters(getSearchArguments(this.currentFiltersState.shownFilters))
    }

    @action
    applyFilters = async (searchArguments: SearchArgumentVO[]): Promise<void> => {
        /*
        * Если пользователь не указал даты в фильтрах, нужно обязательно добавить фильтр EndedPricesByNow(С завершеными
        * ценами до текущего момента в течении 24 часов)
        */
        const hasDateFilter = searchArguments.some(
            item => item.type === ENDED_PRICES_BY_NOW_FILTER || item.type === END_DATE_FILTER
        )

        if (!hasDateFilter) {
            searchArguments.push(createSearchArgumentVO({ type: ENDED_PRICES_BY_NOW_FILTER, value: 'true'}))
        }

        this.searchArguments = searchArguments
        await this.fetchPriceChangeIds()
        this.startPolling()
    }

    @action
    clearFilters = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
        this.searchArguments = getSearchArguments(this.currentFiltersState.shownFilters)
    }

    @action
    setSelectedPriceChangeIds = (selectedIds: number[]) => {
        this.selectedPriceChangeIds = selectedIds
    }

    @action
    setSelectedPriceChangeOnPage = (newSelectedIds: number[], oldSelectedIds: number[]): void => {
        let addToSelection: number[] = [...newSelectedIds]
        let newSelectedProducts = [...this.selectedPriceChangeIds]

        oldSelectedIds.forEach(oldId => {
            const newIndex = addToSelection.findIndex(newId => newId === oldId)
            if (newIndex === -1) {
                const selectedIndex = newSelectedProducts.findIndex(currentId => currentId === oldId)
                newSelectedProducts.splice(selectedIndex, 1)
            } else {
                addToSelection.splice(newIndex, 1)
            }
        })

        this.selectedPriceChangeIds = [...newSelectedProducts, ...addToSelection]
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
    }

    fetchPriceChangeIds = async (fetchInBackground: boolean = false): Promise<void> => {
        const request = async () => {
            const priceChangeIds = await priceTagFacadeLocal.getObjectsIds2(toJS(this.searchArguments), REGULAR_PRICE_ENDED)

            let priceChanges: RegularPriceChangeVO[] = []

            priceChanges = await chunkedLoading(priceChangeIds, priceChangeChunkIds => {
                return priceTagFacadeLocal.getRegularPriceChangesByIds(priceChangeChunkIds)
            })

            // Возможна ситуация, когда getObjectsIds2 вернет неверные id,
            // по которым не будет возвращена полная информация в getProducts1.
            // Показываем пользователю предупреждение.
            const notFoundIds: string[] = priceChangeIds.filter(id => {
                return !priceChanges.some(p => String(p.priceId) === id)
            })

            if (notFoundIds.length > 0) {
                this.dialogStore.showDialog({
                    message: t('notPrintedPriceTags.regular.notFoundIds', {
                        elements: notFoundIds.join(', ')
                    }),
                    mode: ALERT,
                })
            }

            runInAction(() => {
                const pricesChanged = !isEqual(priceChanges, toJS(this.priceChanges))
                if (pricesChanged) {
                    this.priceChangeIds = priceChangeIds
                    this.priceChanges = priceChanges
                }
            })
        }
        return fetchInBackground ? request() : withSpinner(request)
    }

    @action
    setPagination = (pagination: PaginationState): void => {
        this.pagination = pagination
    }

    @action
    reset = (): void => {
        this.currentFiltersState = getDefaultFilterState()
        this.searchArguments = getSearchArguments(this.currentFiltersState.shownFilters)
        this.priceChangeIds = null
        this.selectedPriceChangeIds = []
        this.tasksInProgress = []
        this.pagination = DEFAULT_PRICE_TAGS_PAGINATION
        this.stopPolling()
    }

    downloadExcelHandler = async (): Promise<void> => {
        const reportWindow = window.open()
        const fileName: string = await priceTagFacadeLocal.getFileNameForPriceTagShelf(
            this.selectedPriceChangeIds.map(item => String(item)),
            this.priceChangeIds.length,
            REGULAR_PRICE_ENDED,
            XLSX
        )

        const reportUrl: string = `${config.reportsAddress}?Action=getFile&FILE_NAME=${fileName}`
        // window.open сработает только если он был вызван в главном потоке
        // поэтому мы сначала открываем окно, а потом подменяем ему адрес
        reportWindow.location.replace(reportUrl)
    }

    printPriceTags = (): void => {
        this.printingStore.openPrinting(
            PRICE_TAGS + NOT_ACTUAL + PRINTING,
            {
                productStatus: REGULAR_PRICE_ENDED,
                objectIds: this.selectedPriceChangeIds.map(item => String(item))
            }
        )
    }

    handleAllTasksCompleted = (): void => {
        this.setSelectedPriceChangeIds([])
        this.fetchPriceChangeIds()
    }
}
