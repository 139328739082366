import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import {ShopDevicesStore} from '../../../../store/shops/shop-devices-store'
import { SHOP_DEVICES_STORE } from '../../../../store/stores'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import Divider from '@material-ui/core/Divider'
import { DevicesTable } from './devices-table'
import { AdaptiveDialog } from '../../../../../components/adaptive-dialog/adaptive-dialog'
import { DeviceClassType } from '../../../../core/devices-classes'

const styles = require('./shop-devices-dialog.scss')

interface ShopDevicesDialogProps {
    deviceClass: DeviceClassType
    title: string
    open: boolean
    onClose: () => void
    shopDevicesStore?: ShopDevicesStore
}

@inject(SHOP_DEVICES_STORE)
@observer
export class ShopDevicesDialog extends React.Component<ShopDevicesDialogProps> {

    constructor(props: ShopDevicesDialogProps) {
        super(props)

        const { fetchAllDataOfClass } = props.shopDevicesStore
        const { deviceClass } = props

        fetchAllDataOfClass(deviceClass)
    }

    componentWillUnmount() {
        this.props.shopDevicesStore.searchInputChange('')
    }

    render() {
        const { deviceClass, title, open, onClose } = this.props
        const {
            filterString, searchInputChange, getFilteredModels
        } = this.props.shopDevicesStore

        let filteredModels = getFilteredModels(deviceClass)

        return (
            <AdaptiveDialog
                id="fullScreenDevicesDialog"
                open={open}
                onClose={onClose}
                className={styles.dialog}
                PaperProps={{
                    className: styles.paper
                }}
                maxWidth="md"
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <FilterInput
                    id="filterInput"
                    placeholder={t('components.elementsFilterPrompt')}
                    className={styles.filterInput}
                    value={filterString}
                    autoFocus
                    onValueChange={searchInputChange}
                />
                <Divider/>
                <DialogContent className={styles.content}>
                    {filteredModels.length > 0 ? (
                        <DevicesTable models={filteredModels} />
                    ) : (
                        <Empty/>
                    )}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <Button
                        onClick={onClose}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.close')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
