import React from 'react'
import { FilterType, CREATED_CHECK } from '../../../../core/filters/filter'
import { InternalFilterComponentProps } from '../../new-filters'
import { ComplexReceiptBase } from './complex-receipt-base'

export class CreatedCheck extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = CREATED_CHECK

    render() {
        const { id, data, slim } = this.props

        return (
            <ComplexReceiptBase
                id="createdCheck"
                filterType={CreatedCheck.filterType}
                {...this.props}
            />
        )
    }
}
