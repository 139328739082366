import React, { Component } from 'react'
import { ConditionEditorProps } from '../types'
import Grid from '@material-ui/core/Grid'
import { t } from 'i18next'
import { NumberInput, NUMBER } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { isNil } from 'lodash'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import {
    PeriodUnitType,
    findCondition,
    CountersAndLimitsConditions, removeCondition, updateCondition, DiscountSumCondition
} from '../../../../../../core/advertising-actions/action-conditions'
import { ClientRestriction } from '../../../../../../core/advertising-actions/advertising-actions'
import { FALSE, TRUE } from '../../results/editors/cheque-advert/restrictions'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { FormLabel } from '@material-ui/core'

const styles = require('./discount-sum-editor.scss')

const DAYS = 'DAYS'
const WEEKS = 'WEEKS'
const MONTHS = 'MONTHS'

const getDaysOptions = (count: number) => {
    return [
        {
            label: count
                ? t('actionResults.chequeAdvertising.timeInterval.days', { count })
                : t('actionResults.chequeAdvertising.timeInterval.days'),
            value: DAYS
        },
        {
            label: count
                ? t('actionResults.chequeAdvertising.timeInterval.weeks', { count })
                : t('actionResults.chequeAdvertising.timeInterval.weeks'),
            value: WEEKS
        },
        {
            label: count
                ? t('actionResults.chequeAdvertising.timeInterval.months', { count })
                : t('actionResults.chequeAdvertising.timeInterval.months'),
            value: MONTHS
        }
    ]
}

const getPeriodLabel = (period: PeriodUnitType, count: number): string => {
    switch (period) {
        case PeriodUnitType.Days:
            return t('advertisingActions.countersAndLimitsCond.days', { count })
        case PeriodUnitType.Weeks:
            return t('advertisingActions.countersAndLimitsCond.weeks', { count })
        case PeriodUnitType.Months:
            return t('advertisingActions.countersAndLimitsCond.months', { count })
        default:
            return ''
    }
}

// Получение текста для чипа
export const getDiscountSumChipLabel = (condition: DiscountSumCondition): string => {
    const clientMaxValue = condition?.clientRestriction?.[0]?.maxValue?.[0] || null
    const period = condition?.clientRestriction?.[0]?.period?.[0]
    const periodType = condition?.clientRestriction?.[0]?.periodType?.[0] as PeriodUnitType

    let label: string = ''

    if (clientMaxValue) {
        label += `${label.length > 0 ? '; ' : ''}${t('advertisingActions.countersAndLimitsCond.discountRestrictionChip',
            { maxValue: Number(clientMaxValue) / 100 })}`

        if (period && Number(period) > 0) {
            const periodLabel = getPeriodLabel(periodType, Number(period))

            label += ` ${t('advertisingActions.countersAndLimitsCond.clientPeriod', {
                count: Number(period),
                periodType: periodLabel.toLowerCase(),
            })}`
        } else {
            label += ` ${t('advertisingActions.countersAndLimitsCond.clientWholePeriod')}`
        }

        if (condition.allowOffline?.[0] === TRUE) {
            label += ` ${t('advertisingActions.countersAndLimitsCond.allowOffline')}`
        }
    }

    return label
}

interface DiscountSumEditorState {
    statePeriod: number
    statePeriodType: string | PeriodUnitType
    stateAllowOffline: boolean
}

export class DiscountSumEditor extends Component<ConditionEditorProps, DiscountSumEditorState> {

    constructor(props: ConditionEditorProps, context: any) {
        super(props, context)

        const existingCondition = findCondition(CountersAndLimitsConditions.DiscountSumCondition, this.props.value)
        const condition: DiscountSumCondition = existingCondition?.data
        const clientRestriction: ClientRestriction = condition?.clientRestriction?.[0]

        const allPeriod = clientRestriction?.allPeriodOfAction?.[0] === 'true' ||
            isNil(clientRestriction?.period) ||
            clientRestriction?.period?.[0] === ''
        const period = allPeriod ? null : +clientRestriction?.period?.[0]

        const periodType = clientRestriction?.periodType?.[0] || PeriodUnitType.Days

        const allowOffline = condition?.allowOffline?.[0] === TRUE

        this.state = {
            statePeriod: period,
            statePeriodType: periodType,
            stateAllowOffline: allowOffline
        }
    }

    handleChangeCondition = (newCondition: DiscountSumCondition): void => {
        const { value, onChange } = this.props

        if (!newCondition?.clientRestriction?.[0]?.maxValue?.[0]) {
            onChange(removeCondition(CountersAndLimitsConditions.DiscountSumCondition, value))
            return
        }

        if (!newCondition.clientRestriction[0].period[0]) {
            delete newCondition.clientRestriction[0].periodType

            newCondition.clientRestriction[0].period = ['0']
            newCondition.clientRestriction[0].allPeriodOfAction = [TRUE]
        }

        if (!newCondition.allowOffline?.[0] || newCondition.allowOffline?.[0] === FALSE) {
            delete newCondition.allowOffline
        }

        onChange(updateCondition(
            CountersAndLimitsConditions.DiscountSumCondition,
            newCondition,
            value,
        ))
    }

    render() {
        const { stateAllowOffline, statePeriod, statePeriodType } = this.state
        const existingCondition = findCondition(CountersAndLimitsConditions.DiscountSumCondition, this.props.value)
        const condition: DiscountSumCondition = existingCondition?.data

        const clientRestriction: ClientRestriction = condition?.clientRestriction?.[0]

        let maxValue = isNil(clientRestriction?.maxValue) || clientRestriction?.maxValue?.[0] === ''
            ? null
            : +clientRestriction?.maxValue?.[0] / 100

        const allPeriod = clientRestriction?.allPeriodOfAction?.[0] === 'true'
        let period = allPeriod ? null : +clientRestriction?.period?.[0]
        let periodType = allPeriod ? statePeriodType : clientRestriction?.periodType?.[0]
        let allowOffline = condition?.allowOffline?.[0] === TRUE

        if (!condition) {
            period = statePeriod
            periodType = statePeriodType
            allowOffline = stateAllowOffline
        }

        const readOnly = this.props.readOnly

        return (
            <div className={styles.container}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormLabel style={{ fontSize: 14 }}>{t('advertisingActions.countersAndLimitsCond.forActionForEachClient')}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <NumberInput
                            placeholder="∞"
                            label={t('advertisingActions.countersAndLimitsCond.sumRestriction')}
                            dataType={NUMBER}
                            canBeNull
                            disabled={readOnly}
                            value={maxValue}
                            onValueChange={value => {
                                const fixedValue = !value ? null : Math.round(value * 100).toString()

                                this.handleChangeCondition({
                                    allowOffline: [String(allowOffline)],
                                    clientRestriction: [{
                                        maxValue: [fixedValue],
                                        period: !isNil(period) && [period.toString()],
                                        periodType: [periodType.toString()]
                                    }]
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <NumberInput
                            placeholder="∞"
                            label={t('actionResults.chequeAdvertising.forEvery', { count: period })}
                            canBeNull
                            InputLabelProps={{
                                shrink: true
                            }}
                            min={1}
                            disabled={readOnly}
                            value={period}
                            onValueChange={value => {
                                const fixedValue = isNil(value) ? null : value.toString()
                                const fixedMaxValue = isNil(maxValue) ? null : Math.round(maxValue * 100).toString()

                                this.setState({ statePeriod: value })
                                this.handleChangeCondition({
                                    allowOffline: [String(allowOffline)],
                                    clientRestriction: [{
                                        maxValue: [fixedMaxValue],
                                        period: [fixedValue],
                                        periodType: [periodType.toString()]
                                    }]
                                })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectInput
                            label={t('advertisingActions.countersAndLimitsCond.interval')}
                            options={getDaysOptions(+clientRestriction?.period?.[0])}
                            value={periodType}
                            disabled={readOnly}
                            onSelect={option => {
                                const fixedMaxValue = isNil(maxValue) ? null : Math.round(maxValue * 100).toString()

                                this.setState({ statePeriodType: option.value })
                                this.handleChangeCondition({
                                    allowOffline: [String(allowOffline)],
                                    clientRestriction: [{
                                        maxValue: [fixedMaxValue],
                                        period: [!isNil(period) && period.toString()],
                                        periodType: [option.value]
                                    }]
                                })
                            }}
                            helperText={t('actionResults.chequeAdvertising.fromStartAction')}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Checkbox
                            checked={allowOffline}
                            disabled={readOnly}
                            onValueChange={value => {
                                this.setState({ stateAllowOffline: value })
                                this.handleChangeCondition({
                                    ...condition,
                                    allowOffline: [String(value)]
                                })}
                            }
                            label={t('advertisingActions.productRestriction.providePreference')}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
