import React from 'react'
import { ACTION_COUPON_USE, FilterType } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { FALSE, TRUE } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/boolean-enum'
import { t } from 'i18next'
import { DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { BaseSelectFilter } from '../components/base-select-filter'

const getOptions = (): DefaultSelectOption[] => [
    {
        label: t('filters.actionCouponUseTrue'),
        value: TRUE
    },
    {
        label: t('filters.actionCouponUseFalse'),
        value: FALSE
    }
]

export class ActionCouponUse extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_COUPON_USE

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <BaseSelectFilter
                id={id}
                defaultValue={value}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                filterType={ActionCouponUse.filterType}
                options={getOptions()}
                slim={slim}
            />
        )
    }
}
