import React from 'react'
import { inject } from 'mobx-react'
import { ACTIONS_SEARCH_STORE, APP_STORE, USER_STORE } from '../../../../store/stores'
import { ActionsSearchStore } from '../../../../store/loyalty/actions/actions-search-store'
import { SideBarFilters, SideBarFiltersState } from '../../../../components/filters/side-bar-filters'
import { ACTIONS_SEARCH_FILTERS } from '../../../../core/filters/favorite-filters'
import { ProductCodeOrBarcode } from '../../../../components/filters/new-modules/product-code-or-barcode'
import { FilterGroup } from '../../../../components/filters/components/filter-group'
import { AppStore } from '../../../../store/app-store'
import { ACTIVE } from '../../../../core/products/product-statuses'
import { t } from 'i18next'
import { UserStore } from '../../../../store/user-store'
import { ActionStartDate } from '../../../../components/filters/new-modules/action-start-date'
import { ActionEndDate } from '../../../../components/filters/new-modules/action-end-date'
import { LoyActionLastChangedDate } from '../../../../components/filters/new-modules/date-range-filters-factory'
import { ActionWorkingPeriod } from '../../../../components/filters/new-modules/action-working-period'
import { ActionCode } from '../../../../components/filters/new-modules/action-code'
import { StoreNumber } from '../../../../components/filters/new-modules/store-number'
import { ActionDiscountType } from '../../../../components/filters/new-modules/action-discount-type'
import { ActionSecondaryResult } from '../../../../components/filters/new-modules/action-secondary-result'
import { ActionLabel } from '../../../../components/filters/new-modules/action-label'
import { ActionColor } from '../../../../components/filters/new-modules/action-color'
import { ActionAuthor } from '../../../../components/filters/new-modules/action-author'
import { ActionEditor } from '../../../../components/filters/new-modules/action-editor'
import { ActionCouponUse } from '../../../../components/filters/new-modules/action-coupon-use'
import { ActionCouponCode } from '../../../../components/filters/new-modules/action-coupon-code'
import { ActionCouponCategory } from '../../../../components/filters/new-modules/action-coupon-category'
import { ActionSegment } from '../../../../components/filters/new-modules/action-segment'
import { ActionCards } from '../../../../components/filters/new-modules/action-cards'
const styles = require('./actions-search.scss')

interface ActionsSearchFiltersProps {
    actionsSearchStore?: ActionsSearchStore
    user?: UserStore
    app?: AppStore
}

@inject(ACTIONS_SEARCH_STORE)
@inject(USER_STORE)
@inject(APP_STORE)
export class ActionsSearchFilters extends React.Component<ActionsSearchFiltersProps> {

    render() {
        const { fetchActions, clearFilters, currentFiltersState, setFiltersState, gotFilters } = this.props.actionsSearchStore
        const { session } = this.props.user

        return (
            <SideBarFilters
                containerClassName={styles.filter}
                favoriteFiltersKey={ACTIONS_SEARCH_FILTERS}
                defaultState={currentFiltersState}
                onStateChange={setFiltersState}
                onApply={() => {
                    fetchActions()
                    this.props.app.scrollToTop()
                }}
                onClear={(state: SideBarFiltersState) => {
                    clearFilters(state)
                    this.props.app.scrollToTop()
                }}
                withPaper
                applyDisabled={!gotFilters}
            >
                <FilterGroup name={t('actionsSearch.filterGroup.workingPeriod')}>
                    <ActionWorkingPeriod defaultFavorite/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.lastChanged')}>
                    <LoyActionLastChangedDate/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.date')}>
                    <ActionEndDate/>
                    <ActionStartDate/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.product')}>
                    <ProductCodeOrBarcode session={session} productStatus={ACTIVE} defaultFavorite/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.users')}>
                    <ActionAuthor/>
                    <ActionEditor/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.card')}>
                    <ActionCards session={session}/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.coupon')}>
                    <ActionCouponUse/>
                    <ActionCouponCode/>
                    <ActionCouponCategory/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.type')}>
                    <ActionDiscountType/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.secondaryResult')}>
                    <ActionSecondaryResult/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.segment')}>
                    <ActionSegment/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.code')}>
                    <ActionCode/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.shopNumber')}>
                    <StoreNumber/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.color')}>
                    <ActionColor/>
                </FilterGroup>
                <FilterGroup name={t('actionsSearch.filterGroup.label')}>
                    <ActionLabel/>
                </FilterGroup>
            </SideBarFilters>
        )
    }
}
