import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { PRESENT_CARDS_STORE } from '../../../../store/stores'
import { PresentCardsStore } from '../../../../store/cards/present-cards-store'
import { CardsCategoriesTable } from './cards-categories-table'
import { goTo } from '../../../../utils/router-util'
import { CARDS, PRESENT_CARDS, PRESENT_CARDS_CATEGORIES, PRESENT_CARDS_CATEGORY_EDIT } from '../../../../core/app-routes'

const styles = require('./present-cards-categories.scss')

interface PresentCardsCategoriesProps {
    presentCardsStore?: PresentCardsStore
}

@inject(PRESENT_CARDS_STORE)
@observer
export class PresentCardsCategories extends React.Component<PresentCardsCategoriesProps> {
    componentDidMount() {
        this.props.presentCardsStore?.fetchPresentCards()
    }

    render() {
        const { searchCategoryName, setSearchCategoryName } = this.props.presentCardsStore

        return (
            <>
                <Paper style={{ marginBottom: 8, padding: 16 }}>
                    <FilterInput
                        id="cardCategorySearchInput"
                        placeholder={t('common.search')}
                        value={searchCategoryName}
                        onValueChange={filterString => setSearchCategoryName(filterString)}
                        searchIconPosition="start"
                    />
                </Paper>

                <Paper style={{ flex: 1 }}>
                    <CardsCategoriesTable />
                </Paper>

                <Fab
                    id="addPresentCardCategory"
                    className={styles.addButton}
                    color="primary"
                    onClick={() => goTo(`${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORIES}${PRESENT_CARDS_CATEGORY_EDIT}/new`)}
                >
                    <AddIcon className={styles.addIcon} />
                </Fab>
            </>
        )
    }
}
