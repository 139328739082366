import { omit, isNil } from 'lodash'
import { t } from 'i18next'
import { ConditionFields, PeriodUnitType } from '../action-conditions'

export enum Time {
    AfterFiscalize = 'AFTER_FISCALIZE',
    Subtotal = 'SUBTOTAL',
}

export interface AdvancedMessagesResultData {
    limit?: string[]
    calculator: Calculator[]
    notifications: Notifications[]
    slipMessage: string[]
    printSlipInsideReceipt?: string[]
    requireScan?: string[]  // Сканирование штрихкода подарка
    addAsProducts?: string[] // Добавлять подарок в чек как товар
    barcodes?: [{ String: string[] }]
    actionRestriction?: [{ maxValue: string[] }]
    clientRestriction?: [{
        periodType: PeriodUnitType[]
        period: string[]
        allPeriodOfAction?: string[]
        maxValue: string[]
    }]
}

export interface Notifications {
    $: { class: 'list' }
    [ConditionFields.ActionResultNotification]?: NotificationsData[]
}

export interface NotificationsData {
    clientMessage: string[]
    operatorMessage: string[]
    time: Time[]
}

export interface Calculator {
    $: { class: ConditionFields.RootPreferencesCalculator }
    children: CalculatorChildren[]
}

export interface CalculatorChildren {
    $: { class: 'list' }
    [ConditionFields.ReceiptSumPreferencesCalculator]: Rule[]
    [ConditionFields.QuantityBasedPreferencesCalculator]: Rule[]
    [ConditionFields.SumBasedPreferencesCalculator]: Rule[]
}

export interface Rule {
    divider: string[]
    multiplier: string[]
    idOfCondition?: string[]
    limit?: string[]
    includeRows?: string[] | Array<{ [ConditionFields.CompositeRow]: CompositeRow }>
    excludeRows?: string[] | Array<{ [ConditionFields.CompositeRow]: CompositeRow }>
    forAllGoods?: string[]
    checkDiscountableFlag: string[]
}

export type TypedRule = Rule & { type: ConditionFields, key: string }

export interface CompositeRow {
    value: string[]
    values: Array<{ String: string[] }>
    type: string[]
    description: string[]
}

export function makeNotifications(data?: NotificationsData): Notifications {
    let notifications: Notifications = { $: { class: 'list' } }
    if (data) notifications[ConditionFields.ActionResultNotification] = [data]
    return notifications
}

export function makeNotificationsData(data?: Partial<NotificationsData>) {
    return {
        time: [Time.Subtotal],
        operatorMessage: [''],
        clientMessage: ['\n'],
        ...data,
    }
}

export function makeCalculator(children?: CalculatorChildren): Calculator {
    return {
        $: { class: ConditionFields.RootPreferencesCalculator },
        children: [makeCalculatorChildren(children)],
    }
}

export function makeCalculatorChildren(children?: Omit<CalculatorChildren, '$'>): CalculatorChildren {
    return {
        $: { class: 'list' },
        ...children,
    }
}

export function formatRule(rule: TypedRule): string {
    let term: string
    switch (rule.type) {
        case ConditionFields.ReceiptSumPreferencesCalculator:
            term = t('advertisingActions.forEachReceipt', { count: +rule.divider[0] / 100 })
            break
        case ConditionFields.QuantityBasedPreferencesCalculator:
            term = t('advertisingActions.forEachProduct', { count: +rule.divider[0] / 1000 })
            break
        case ConditionFields.SumBasedPreferencesCalculator:
            term = t('advertisingActions.forEachSum', { count: +rule.divider[0] / 100 })
            break
    }
    term += ' ' + t('advertisingActions.give', { count: +rule.multiplier[0] })
    if (rule.limit) {
        term += t('advertisingActions.noMoreThan', { count: +rule.limit[0] })
    }
    if (!(isNil(rule.idOfCondition?.[0]) || rule.idOfCondition?.[0] === '')) {
        term += ` (${rule.idOfCondition[0]})`
    }
    return term
}

export function getTypedRules(type: ConditionFields, data: AdvancedMessagesResultData): TypedRule[] {
    return (data.calculator[0].children[0]?.[type] ?? []).map((rule, key) => ({
        ...rule,
        type,
        key: `${type}-${key}`,
    }))
}

export function makeRawRule(rule: TypedRule) {
    return omit(rule, ['type', 'key'])
}

export function validateAdvancedMessage(result: AdvancedMessagesResultData): boolean {
    // Хотябы 1 калькулятор должен быть заполнен
    const calculators = result.calculator?.[0]?.children?.[0]
    const gotCalculator = calculators && Object.keys(calculators).some(key => {
        if (key === '$') return false
        return calculators[key]?.length > 0
    })
    if (!calculators || !gotCalculator) return false

    // Таб - сообщения на экране
    if (!validateScreenMessage(result)) return false

    return true
}

export function validateScreenMessage(result: AdvancedMessagesResultData): boolean {
    const notification = result.notifications?.[0]?.[ConditionFields.ActionResultNotification]?.[0]

    // Если включена галочка - обязательно надо хотябы один штрих-код
    const requireScan = result.requireScan?.[0] === 'true'
    const gotBarcodes = result.barcodes?.[0]?.String?.[0]?.length
    if (requireScan && !gotBarcodes) return false

    // Эта часть может быть не заполнена (если не включены штрих-коды)
    if (!notification && !requireScan) return true

    // Но если она заполнена, текст кассира должен быть заполнен
    if (!notification?.operatorMessage?.[0]) return false

    return true
}
