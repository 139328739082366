import MUIAppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import MenuIcon from '@material-ui/icons/Menu'
import Switch from '@material-ui/core/Switch'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { AppBarIcon, AppBarStore, LEFT_ARROW, MENU } from '../../../store/app-bar-store'
import { Notifications } from '../notifications/notifications'
import { UserMenu } from '../user-menu/user-menu'
import { APP_BAR_STORE, APP_STORE } from '../../../store/stores'
import { AppStore } from '../../../store/app-store'
import { config } from '../../../config/config'
import { AvailableThemes } from '../../../core/themes'

const styles = require('./app-bar.scss')

export interface AppBarProps extends React.HTMLProps<AppBar> {
    appBar?: AppBarStore
    app?: AppStore
}

@inject(APP_BAR_STORE)
@inject(APP_STORE)
@observer
export class AppBar extends React.Component<AppBarProps> {
    getIcon = (icon: AppBarIcon): any => {
        switch (icon) {
            case MENU:
                return <MenuIcon />
            case LEFT_ARROW:
                return <KeyboardBackspace />
            default:
                return null
        }
    }

    handleChangeTheme = () => {
        const { theme, setTheme } = this.props.app
        setTheme(theme === AvailableThemes.LIGHT_THEME ? AvailableThemes.DARK_THEME : AvailableThemes.LIGHT_THEME)
    }

    render() {
        const { leftIcon, title, onLeftIconClick, additionalContent, showNotifications } = this.props.appBar
        const { theme } = this.props.app
        const isProd = config.isProduction

        return (
            <MUIAppBar className={styles.appBar} position="static" color="primary">
                <Toolbar>
                    <IconButton
                        id="appBarLeftButton"
                        className={styles.actionButton}
                        onClick={onLeftIconClick}
                    >
                        {this.getIcon(leftIcon)}
                    </IconButton>
                    <span className={classNames(styles.title)}>{title}</span>
                    {additionalContent}
                    <div className={styles.flexGrow1} />
                    {!isProd && (<Switch onClick={this.handleChangeTheme} checked={theme === AvailableThemes.DARK_THEME} />)}
                    {showNotifications && <Notifications />}
                    <UserMenu className={styles.userMenu} />
                </Toolbar>
            </MUIAppBar>
        )
    }
}
