import React from 'react'
import { FilterType, ACTION_CODE_FILTER, getFilterTypeName } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'

export class ActionCode extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_CODE_FILTER

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <TextField
                id={id}
                label={getFilterTypeName(ActionCode.filterType)}
                value={value}
                onValueChange={(value: string) => {
                    onChange(ActionCode.filterType, { value })
                }}
                slim={slim}
            />
        )
    }
}
