import { t } from 'i18next'
import { forOwn } from 'lodash'
import { goTo } from '../../../utils/router-util'
import { config } from '../../../config/config'
import {
    ACTIONS,
    ACTIONS_GROUP,
    CARDS,
    CARDS_GROUP,
    CUSTOM_PRICE_TAGS_PRINTING,
    MONITORING,
    MONITORING_GROUP,
    NOT_ACTUAL_ACTION_PRICE_TAGS,
    NOT_ACTUAL_PRICE_TAGS,
    NOT_BINDED_PRICE_TAGS,
    NOT_BINDED_TO_SCALES,
    NOT_LOADED_ACTIONS_ON_SHOPS,
    NOT_LOADED_CARDS_ON_CASHES,
    NOT_LOADED_CARDS_ON_SHOPS,
    NOT_LOADED_ON_SCALES,
    NOT_LOADED_PRODUCTS_ON_CASHES,
    NOT_LOADED_PRODUCTS_ON_SHOPS,
    NOT_LOADED_PRODUCTS_TO_ESL,
    NOT_PRINTED_ACTION_PRICE_TAGS,
    NOT_PRINTED_PRICE_TAGS,
    NotificationGroup,
    NotificationGroupInfo,
    NotificationInfo,
    NotificationSubGroup,
    NotificationSubGroupInfo,
    NotificationType,
    PRODUCTS,
    PRODUCTS_GROUP, REFUSED_CARDS,
    REFUSED_PRODUCTS,
    TASKS,
    TASKS_GROUP,
    PRINT_ERRORS
} from '../../../store/notification-store'
import {
    CUSTOM_PRINTING,
    NOT_ACTUAL,
    NOT_BINDED,
    NOT_PRINTED,
    ELECTRONIC_PRICE_TAGS,
    PRICE_TAGS,
    HISTORY,
    CASH_MODULE,
    NOT_LOADED_TO_CASHES,
    NOT_LOADED_TO_SHOPS,
    LOYALTY,
    FINISHED_ACTIONS_WITH_PRINTED_TAGS,
    ACTION_PRICETAGS_PRINTING,
    ACTIONS_UNLOADED_ON_SHOPS,
    CARDS_UNLOADED_ON_SHOPS,
    CARDS_UNLOADED_ON_CASHES,
    CARDS as CARDS_ROUTE,
    INTEGRATION,
    SCALES_MODULE,
    NOT_LOADED,
    REFUSED_PRODUCTS as REFUSED_PRODUCTS_ROUTE
} from '../../../core/app-routes'
import { ERRORS_TAB } from '../../../pages/price-tags/esl/electronic-price-tags'

/**
 * Возвращает группу, в которую входит нотификация
 * @param notificationType
 */
export function getNotificationGroup(notificationType: NotificationType): NotificationGroup {
    if (!notificationType) return null
    if (MONITORING_GROUP.indexOf(notificationType) > -1) {
        return MONITORING
    } else if (TASKS_GROUP.indexOf(notificationType) > -1) {
        return TASKS
    } else {
        throw new Error(`Not found group for notification with type: ${notificationType}`)
    }
}

/**
 * Возвращает подгруппу, в которую входит нотификация
 * @param notificationType
 */
export function getNotificationSubGroup(notificationType: NotificationType): NotificationSubGroup {
    if (!notificationType) return null
    if (PRODUCTS_GROUP.indexOf(notificationType) > -1) {
        return PRODUCTS
    } else if (CARDS_GROUP.indexOf(notificationType) > -1) {
        return CARDS
    } else if (ACTIONS_GROUP.indexOf(notificationType) > -1) {
        return ACTIONS
    } else {
        throw new Error(`Not found subgroup for notification with type: ${notificationType}`)
    }
}

/**
 * Разделяет указанные счетчики нотификации по группам
 * @param notificationCounters
 */
export function splitNotificationByGroups(notificationCounters: {[type: string]: number[]}): NotificationGroupInfo[] {
    const monitoring: NotificationGroupInfo = { type: MONITORING, subGroups: [] }
    const tasks: NotificationGroupInfo = { type: TASKS, subGroups: [] }

    const groupByType = {
        [MONITORING]: monitoring,
        [TASKS]: tasks,
    }

    function findSubGroup(list: NotificationSubGroupInfo[], type: NotificationSubGroup): NotificationSubGroupInfo {
        let subGroup = list.find(g => g.type === type)
        if (!subGroup) {
            subGroup = { type, notifications: [] }
            list.push(subGroup)
        }
        return subGroup
    }

    forOwn(notificationCounters, (counters, type: NotificationType) => {
        const group: NotificationGroupInfo = groupByType[getNotificationGroup(type)]
        const subGroup: NotificationSubGroupInfo = findSubGroup(group.subGroups as NotificationSubGroupInfo[], getNotificationSubGroup(type))

        const route = getNotificationRedirectRoute(type)

        let redirect = () => null
        if (route) redirect = () => goTo(route)

        subGroup.notifications.push({
            type,
            group: group.type,
            subGroup: subGroup.type,
            counters,
            redirect,
        })
    })

    let result = []
    if (monitoring.subGroups.length > 0) {
        result.push(monitoring)
    }
    if (tasks.subGroups.length > 0) {
        result.push(tasks)
    }

    return result
}

/**
 * Возвращает роут для нотификации, если экран находится в текущем проекте
 * @param notificationType
 */
export function getNotificationRedirectRoute(notificationType: NotificationType): string {
    if (!notificationType) return null
    switch (notificationType) {
        case NOT_BINDED_PRICE_TAGS:
            return `${PRICE_TAGS}${NOT_BINDED}`
        case REFUSED_PRODUCTS:
            return `${INTEGRATION}${REFUSED_PRODUCTS_ROUTE}`
        case NOT_BINDED_TO_SCALES:
            return `${SCALES_MODULE}${NOT_BINDED}`
        case NOT_LOADED_ON_SCALES:
            return `${SCALES_MODULE}${NOT_LOADED}`
        case NOT_PRINTED_PRICE_TAGS:
            return `${PRICE_TAGS}${NOT_PRINTED}`
        case CUSTOM_PRICE_TAGS_PRINTING:
            return `${PRICE_TAGS}${CUSTOM_PRINTING}`
        case NOT_ACTUAL_PRICE_TAGS:
            return `${PRICE_TAGS}${NOT_ACTUAL}`
        case NOT_LOADED_PRODUCTS_TO_ESL:
            return `${PRICE_TAGS}${ELECTRONIC_PRICE_TAGS}/${ERRORS_TAB}`
        case NOT_LOADED_ACTIONS_ON_SHOPS:
            return `${LOYALTY}${ACTIONS_UNLOADED_ON_SHOPS}`
        case PRINT_ERRORS:
            return PRICE_TAGS + HISTORY
        case NOT_LOADED_PRODUCTS_ON_CASHES:
            return `${CASH_MODULE}${NOT_LOADED_TO_CASHES}`
        case NOT_LOADED_PRODUCTS_ON_SHOPS:
            return `${CASH_MODULE}${NOT_LOADED_TO_SHOPS}`
        case NOT_PRINTED_ACTION_PRICE_TAGS:
            return `${LOYALTY}${ACTION_PRICETAGS_PRINTING}`
        case NOT_ACTUAL_ACTION_PRICE_TAGS:
            return `${LOYALTY}${FINISHED_ACTIONS_WITH_PRINTED_TAGS}`
        case NOT_LOADED_CARDS_ON_CASHES:
            return `${CARDS_ROUTE}${CARDS_UNLOADED_ON_CASHES}`
        case NOT_LOADED_CARDS_ON_SHOPS:
            return `${CARDS_ROUTE}${CARDS_UNLOADED_ON_SHOPS}`
        default:
            return null
    }
}

/**
 * Считает количество событий в группе нотификаций для счетчиков
 * @param group
 */
export function getEventsCountInNotificationGroup(group: NotificationGroupInfo): number {
    if (!group || !group.subGroups) return 0

    let count = 0
    group.subGroups.forEach(subGroup => {
        if (!subGroup.notifications) return
        count += subGroup.notifications.length
    })

    return count
}

/**
 * Возвращает локализованное название группы нотификаций
 * @param group
 */
export function getNotificationGroupName(group: NotificationGroup): string {
    switch (group) {
        default:
            return ''
        case MONITORING:
            return t('notifications.monitoring')
        case TASKS:
            return t('notifications.tasks')
    }
}

/**
 * Возвращает локализованное название подгруппы нотификаций
 * @param subGroup
 */
export function getNotificationSubGroupName(subGroup: NotificationSubGroup): string {
    switch (subGroup) {
        default:
            return ''
        case PRODUCTS:
            return t('notifications.products')
        case CARDS:
            return t('notifications.cards')
        case ACTIONS:
            return t('notifications.actions')
    }
}

/**
 * Возвращает локализованное название нотификации
 * @param notificationType
 */
export function getNotificationTypeName(notificationType: NotificationType): string {
    if (!notificationType) return ''
    return t(`notifications.${notificationType}`)
}

/**
 * Возвращает текст подсказки для нотификации, если он есть
 * @param notificationType
 */
export function getNotificationTooltip(notificationType: NotificationType): string {
    if (!notificationType) return ''
    switch (notificationType) {
        case NOT_PRINTED_PRICE_TAGS:
        case CUSTOM_PRICE_TAGS_PRINTING:
        case NOT_ACTUAL_PRICE_TAGS:
            return t('notifications.regularOrAction')
        case NOT_LOADED_PRODUCTS_ON_CASHES:
        case NOT_LOADED_PRODUCTS_ON_SHOPS:
        case NOT_LOADED_ON_SCALES:
        case NOT_LOADED_CARDS_ON_CASHES:
        case NOT_LOADED_CARDS_ON_SHOPS:
        case NOT_LOADED_PRODUCTS_TO_ESL:
            return t('notifications.connectionOrValidationError')
        default:
            return ''
    }
}

/**
 * Находит нотификацию в указанной группе
 * @param notificationType
 * @param group
 */
export function findNotificationInGroup(notificationType: NotificationType, group: NotificationGroupInfo): NotificationInfo {
    if (!group || !group.subGroups) return null

    for (let subGroup of group.subGroups) {
        if (!subGroup.notifications) continue
        for (let notification of subGroup.notifications) {
            if (notification.type === notificationType) {
                return notification as NotificationInfo
            }
        }
    }

    return null
}
