export interface RefundRequestPositionVO {
    '@class': 'ru.crystals.report.vo.RefundRequestPositionVO'
    positionOrder?: number
    barCode?: string
    goodsCode?: string
    goodsName?: string
    measure?: string
    amountInPackage?: number
    count?: number
    countStr?: string
    cost?: number
    restCost?: number
    restQuantity?: number
    amount?: number
    precision?: number
}

export const createRefundRequestPositionVO = (initialization: Partial<RefundRequestPositionVO>): RefundRequestPositionVO => {
    if (!initialization) return null
    
    let result: RefundRequestPositionVO = {
        positionOrder: initialization.positionOrder,
        barCode: initialization.barCode,
        goodsCode: initialization.goodsCode,
        goodsName: initialization.goodsName,
        measure: initialization.measure,
        amountInPackage: initialization.amountInPackage,
        count: initialization.count,
        countStr: initialization.countStr,
        cost: initialization.cost,
        restCost: initialization.restCost,
        restQuantity: initialization.restQuantity,
        amount: initialization.amount,
        precision: initialization.precision,
        '@class': 'ru.crystals.report.vo.RefundRequestPositionVO',
    }
    
    return result
}
