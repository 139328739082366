import { action, computed, observable, runInAction } from 'mobx'
import { PRODUCT_ITEM_OR_BARCODE_FILTER, CHECK_TYPE_FILTER, NON_FISCAL_FILTER } from '../../core/filters/filter'
import { Check } from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check'
import { withSpinner } from '../with-spinner'
import { checkManagerLocal } from '../../../protocol/set10/check-manager-local'
import {
    createSearchArgumentVO,
    SearchArgumentVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE } from '../stores'
import { reportsProcessorLocal } from '../../../protocol/set10/reports-processor-local'
import { XLSX } from '../../core/file-types'
import { config } from '../../config/config'
import { isNil } from 'lodash'
import { fromClientToServerTime } from '../../utils/app-util'
import { Filters, FiltersState } from '../../components/filters/new-filters'
import { getDefaultFilterState } from './checks-search-store'

export class NonFiscalDocumentsListStore {

    @observable
    barcode: string = ''
    @observable
    foundDocuments: Check[] = []
    @observable
    maxCount: number

    currentFiltersState: FiltersState = getDefaultFilterState()

    private userStore: UserStore = getStore(USER_STORE)

    @action
    reset = (): void => {
        this.barcode = ''
        this.foundDocuments = []
        this.maxCount = undefined
    }

    @action
    setFiltersState = (state: FiltersState) => {
        this.currentFiltersState = state
    }

    @action
    updateSearchValue = (value: string): void => {
        this.barcode = value
    }

    @computed
    get searchArguments(): SearchArgumentVO[] {
        if (!this.currentFiltersState) return []
        return Filters.getSearchArguments(this.currentFiltersState.shownFilters)
    }

    downloadExcelHandler = () => {
        const reportWindow = window.open()
        const advancedArguments = [
            createSearchArgumentVO({ type: CHECK_TYPE_FILTER, value: NON_FISCAL_FILTER }),
            ...this.searchArguments
        ]

        if (this.barcode.length) {
            advancedArguments.push(createSearchArgumentVO({ type: PRODUCT_ITEM_OR_BARCODE_FILTER, value: this.barcode }))
        }

        reportsProcessorLocal.getFileNameForPurchasePositions(advancedArguments, XLSX).then(filename => {
            // window.open сработает только если он был вызван в главном потоке
            // поэтому мы сначала открываем окно, а потом подменяем ему адресс
            const reportUrl = `${config.reportsAddress}?Action=getFile&FILE_NAME=${filename}`
            reportWindow.location.replace(reportUrl)
        })
    }

    @action
    findDocuments = async (searchArguments?: SearchArgumentVO[]): Promise<void> => {
        this.foundDocuments = []

        const newSearchArguments = [
            createSearchArgumentVO({ type: CHECK_TYPE_FILTER, value: NON_FISCAL_FILTER }),
            this.barcode && createSearchArgumentVO({ type: PRODUCT_ITEM_OR_BARCODE_FILTER, value: this.barcode }),
            ...searchArguments || false
        ].filter(Boolean)
        const result = await withSpinner(checkManagerLocal.findChecks2(this.userStore.session, newSearchArguments, 40))

        if (!result.values) return

        runInAction(() => {
            this.maxCount = result.maxCount
            this.foundDocuments = result.values
            fixTransportErrors(this.foundDocuments)
        })
    }

    @action
    resetFiltersAndResults = (filterState: FiltersState): void => {
        this.barcode = ''
        this.foundDocuments = []
        this.maxCount = undefined
        this.currentFiltersState = filterState
    }
}

// TODO SFM-208 ошибки транспорта
const fixTransportErrors = (documentsList: any[]) => {
    if (!documentsList) return

    documentsList.forEach(document => {
        // Для отложенного чека может прийти date === null
        if (!isNil(document.date)) {
            if (!(document.date instanceof Date)) {
                // unixtime -> Date
                document.date = new Date(document.date)
            }
            document.date = fromClientToServerTime(document.date)
        }
    })
}
