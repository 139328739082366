import { UserVO } from './user-vo'
import { SegmentOperationTypeEnum } from './segment-operation-type-enum'
import { SegmentOperationProgressStatus } from './segment-operation-progress-status'

export interface SegmentOperationProgressVO {
    '@class': 'ru.crystals.cards.SegmentOperationProgressVO'
    id?: number
    date?: Date
    user?: UserVO
    segmentGuid?: string
    operationType?: SegmentOperationTypeEnum
    file?: string
    recordsAdded?: number
    recordsToSpam?: number
    status?: SegmentOperationProgressStatus
    errorDescription?: string
}

export const createSegmentOperationProgressVO = (initialization: Partial<SegmentOperationProgressVO>): SegmentOperationProgressVO => {
    if (!initialization) return null
    
    let result: SegmentOperationProgressVO = {
        id: initialization.id,
        date: initialization.date,
        user: initialization.user,
        segmentGuid: initialization.segmentGuid,
        operationType: initialization.operationType,
        file: initialization.file,
        recordsAdded: initialization.recordsAdded,
        recordsToSpam: initialization.recordsToSpam,
        status: initialization.status,
        errorDescription: initialization.errorDescription,
        '@class': 'ru.crystals.cards.SegmentOperationProgressVO',
    }
    
    return result
}
