import React from 'react'
import { t } from 'i18next'
import { isNil } from 'lodash'
import {
    removeCondition, updateCondition, findCondition, BillConditions, Condition
} from '../../../../../../core/advertising-actions/action-conditions'
import { ConditionEditorProps } from '../types'
import { NumberInput, NUMBER } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./purchase-amount-editor.scss')

export enum PurchaseAmountType {
    MORE = 'MORE',
    BETWEEN = 'BETWEEN',
    LESS = 'LESS'
}

export interface PurchaseAmount {
    summa: string[]
    maxValue: string[]
    typeOfComparison: PurchaseAmountType[]
    useNominalSum?: string[]
}

export const getPurchaseAmountChipLabel = (condition: PurchaseAmount): string => {
    const purchaseAmountText = t(`advertisingActions.checkConditions.${condition.typeOfComparison[0]}`, {
        summa: Number(condition.summa[0]) / 100,
        maxValue: Number(condition.maxValue[0]) / 100
    })

    const useNominalSum = condition.useNominalSum?.[0] === 'false' ? false : true
    let useNominalSumText = useNominalSum ? ` (${t('advertisingActions.checkConditions.useNominalSum')})` : ''

    return `${t('advertisingActions.checkConditions.purchaseAmount')}: ${purchaseAmountText}${useNominalSumText}`
}

interface PurchaseAmountEditorState {
    /**
     * Запоминаем прошлый флаг наличия условия.
     * Необходимо чтобы управлять галкой в случае, если извне удаляется условие (в чипах)
     * И чтобы мы могли потом отжать эту галочку (называется "Любое")
     */
    prevConditionFlag: boolean
    /**
     * Состояние галочки "Любое" (любое значение) = выключенный фильтр
     */
    anyAmount: boolean
    /**
     * Значение в state используется:
     * 1. Когда были заданы значения, и галочкой мы выключили фильтр. Если включить опять - подтянутся значения из state
     * 2. В момент когда мы ввели неккоректное значение - надо видеть то, что мы ввели. Но в фильтр передавать это нельзя.
     * В итоге мы всегда отображаем значение из state. Но в нормальных случаях оно совпадает с props value
     */
    stateMore: number
    stateLess: number
    stateUseNominalSum: boolean
}

export class PurchaseAmountEditor extends React.Component<ConditionEditorProps, PurchaseAmountEditorState> {

    constructor(props: ConditionEditorProps, context: any) {
        super(props, context)

        const condition = findCondition(BillConditions.PurchaseAmountCondition, props.value)

        // Условие не задано
        if (!condition) {
            this.state = {
                prevConditionFlag: false,
                anyAmount: true,
                stateMore: null,
                stateLess: null,
                stateUseNominalSum: false
            }
            return
        }

        const { more, less, useNominalSum } = this.getConditionValues(condition)

        this.state = {
            prevConditionFlag: true,
            anyAmount: false,
            stateMore: more,
            stateLess: less,
            stateUseNominalSum: useNominalSum
        }
    }

    getConditionValues = (condition: Condition): { more: number, less: number, useNominalSum: boolean } => {
        const purchaseAmount: PurchaseAmount = condition ? condition.data : {
            summa: [null],
            maxValue: [null],
            typeOfComparison: PurchaseAmountType.MORE
        }

        let useNominalSum = null
        if (condition) {
            useNominalSum = purchaseAmount.useNominalSum?.[0] === 'false' ? false : true
        }

        let more: number = null
        let less: number = null

        switch (purchaseAmount.typeOfComparison[0]) {
            case PurchaseAmountType.MORE:
                more = purchaseAmount.summa[0] && Number(purchaseAmount.summa[0]) / 100
                break
            case PurchaseAmountType.BETWEEN:
                more = purchaseAmount.summa[0] && Number(purchaseAmount.summa[0]) / 100
                less = purchaseAmount.maxValue[0] && Number(purchaseAmount.maxValue[0]) / 100
                break
            case PurchaseAmountType.LESS:
                less = purchaseAmount.summa[0] && Number(purchaseAmount.summa[0]) / 100
                break
        }

        return { more, less, useNominalSum }
    }

    static getDerivedStateFromProps(props: ConditionEditorProps, state: PurchaseAmountEditorState) {
        const nextConditionFlag = !!findCondition(BillConditions.PurchaseAmountCondition, props.value)

        if (state.prevConditionFlag && !nextConditionFlag) {
            return {
                prevConditionFlag: false,
                anyAmount: true
            }
        }
        return null
    }

    handleMoreChange = (newValue: number): void => {
        this.handleUpdateCondition(newValue, this.state.stateLess, this.state.stateUseNominalSum)
        this.setState({
            stateMore: newValue
        })
    }

    handleLessChange = (newValue: number): void => {
        this.handleUpdateCondition(this.state.stateMore, newValue, this.state.stateUseNominalSum)
        this.setState({
            stateLess: newValue
        })
    }

    handleUseNominalSumChange = (newValue: boolean): void => {
        this.handleUpdateCondition(this.state.stateMore, this.state.stateLess, newValue)
        this.setState({
            stateUseNominalSum: newValue
        })
    }

    handleUpdateCondition = (more: number, less: number, useNominalSum: boolean): void => {
        const { value, onChange } = this.props

        if (isNil(more) && isNil(less)) {
            this.handleRemoveCondition()
            return
        }

        /**
         * Не прокидываем изменение, если нарушается логика "больше меньше"
         * Изменение будет только в state
         */
        if (!(isNil(more) || isNil(less)) && more > less) return

        let summa: number = 0
        let maxValue: number = 0
        let typeOfComparison: PurchaseAmountType = PurchaseAmountType.MORE

        if (!isNil(less)) {
            // Задано меньше или больше+меньше
            if (!isNil(more)) {
                // Задано больше+меньше
                summa = Math.round(more * 100)
                maxValue = Math.round(less * 100)
                typeOfComparison = PurchaseAmountType.BETWEEN
            } else {
                // Задано меньше
                summa = Math.round(less * 100)
                typeOfComparison = PurchaseAmountType.LESS
            }
        } else {
            // Задано только больше
            summa = Math.round(more * 100)
        }

        let newCondition: PurchaseAmount = {
            summa: [String(summa)],
            maxValue: [String(maxValue)],
            typeOfComparison: [typeOfComparison],
        }

        if (!useNominalSum) {
            newCondition.useNominalSum = ['false']
        }

        this.setState({
            prevConditionFlag: true
        })
        onChange(updateCondition(
            BillConditions.PurchaseAmountCondition,
            newCondition,
            value
        ))
    }

    handleRemoveCondition = (): void => {
        const { value, onChange } = this.props
        this.setState({
            prevConditionFlag: false
        })
        onChange(removeCondition(BillConditions.PurchaseAmountCondition, value))
    }

    render() {
        const condition = findCondition(BillConditions.PurchaseAmountCondition, this.props.value)
        const { more, less, useNominalSum } = this.getConditionValues(condition)
        const { anyAmount, stateMore, stateLess, stateUseNominalSum } = this.state

        let errorWithoutHelperText
        const error = !isNil(stateMore) && !isNil(stateLess) && stateMore > stateLess
        errorWithoutHelperText = { error }

        let useNominalSumLabel = t('advertisingActions.checkConditions.useNominalSum')
        useNominalSumLabel = useNominalSumLabel[0].toUpperCase() + useNominalSumLabel.slice(1)

        const readOnly = this.props.readOnly

        return (
            <div className={styles.purchaseAmount}>
                <Checkbox
                    id="activeCheckbox"
                    color="primary"
                    className={styles.checkbox}
                    label={t('advertisingActions.checkConditions.anyLabel')}
                    checked={anyAmount}
                    disabled={readOnly}
                    onValueChange={checked => {
                        if (checked) {
                            this.handleRemoveCondition()
                            this.setState({
                                anyAmount: true
                            })
                        } else {
                            // Если в state были значения - задаем их
                            if (!isNil(stateMore) || !isNil(stateLess)) {
                                this.handleUpdateCondition(stateMore, stateLess, stateUseNominalSum)
                            }

                            this.setState({
                                anyAmount: false
                            })
                        }
                    }}
                />
                <NumberInput
                    id="moreInput"
                    className={styles.input}
                    dataType={NUMBER}
                    rounding={2}
                    disabled={anyAmount || readOnly}
                    canBeNull
                    label={t('advertisingActions.checkConditions.moreEqualLabel')}
                    value={!isNil(stateMore) ? stateMore : more}
                    onValueChange={this.handleMoreChange}
                    {...errorWithoutHelperText}
                />
                <NumberInput
                    id="lessInput"
                    style={{ marginBottom: 8 }}
                    className={styles.input}
                    dataType={NUMBER}
                    rounding={2}
                    disabled={anyAmount || readOnly}
                    canBeNull
                    label={t('advertisingActions.checkConditions.lessLabel')}
                    value={!isNil(stateLess) ? stateLess : less}
                    onValueChange={this.handleLessChange}
                    errorText={error ? t('advertisingActions.checkConditions.moreLessError') : null}
                />
                <Checkbox
                    id="useNominalSumCheckbox"
                    color="primary"
                    disabled={anyAmount || readOnly}
                    className={styles.checkbox}
                    label={useNominalSumLabel}
                    checked={useNominalSum || stateUseNominalSum}
                    onValueChange={checked => {
                        this.handleUseNominalSumChange(checked)
                    }}
                />
            </div>
        )
    }
}
