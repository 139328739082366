import { observable, action, runInAction, computed, toJS } from 'mobx'
import { t } from 'i18next'
import { isEqual, cloneDeep } from 'lodash'
import { getStore } from '../stores-repository'
import { USER_STORE } from '../stores'
import { UserStore } from '../user-store'
import { goTo } from '../../utils/router-util'
import { CARDS, CARDS_SEARCH, EXTERNAL_CARDS, EXTERNAL_CARDS_EDIT } from '../../core/app-routes'
import { withSpinner } from '../with-spinner'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import { iExternalCardsManagerRemote } from '../../../protocol/set10/i-external-cards-manager-remote'
import { ExternalCardsVO, createExternalCardsVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/external-cards-vo'
import { CardRangeVO, createCardRangeVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/card-range-vo'
import { INTERNAL_ERROR, INTERSECTION_RANGES } from '../../core/server-codes'

export class ExternalCardsStore {

    @observable
    externalCards: ExternalCardsVO[]

    @observable
    editingExternalCard: ExternalCardsVO

    @observable
    editingExternalCardRanges: CardRangeVO[] = []

    @observable
    rangeError: boolean = false

    private originalEditingExternalCard: ExternalCardsVO
    private userStore: UserStore = getStore<UserStore>(USER_STORE)

    @computed
    get editingExternalCardModified() {
        return !isEqual(toJS(this.editingExternalCard), toJS(this.originalEditingExternalCard))
    }

    fetchExternalCards = async (): Promise<ExternalCardsVO[]> => {
        const externalCards: ExternalCardsVO[] = await withSpinner(iExternalCardsManagerRemote.getExternalCards(
            this.userStore.session,
            0,
            1000,
        ))

        runInAction(() => {
            this.externalCards = externalCards || []
        })

        return externalCards || []
    }

    openExternalCard = async (id: number): Promise<void> => {
        const isNew = id === -1
        const editingExternalCard: ExternalCardsVO = isNew ? this.createDraftExternalCard() : await this.getExternalCardById(id)

        this.reset()

        runInAction(() => {
            this.editingExternalCard = editingExternalCard
            this.originalEditingExternalCard = cloneDeep(editingExternalCard)
        })
    }

    getExternalCardById = async (id: number): Promise<ExternalCardsVO> => {
        let externalCards: ExternalCardsVO[] = this.externalCards

        if (!this.externalCards?.length) {
            externalCards = await this.fetchExternalCards()
        }

        const editingExternalCard = externalCards.find(card => card.id === id)

        this.fetchExternalCardRanges(editingExternalCard)

        return editingExternalCard
    }

    fetchExternalCardRanges = async (externalCard: ExternalCardsVO): Promise<void> => {
        const ranges: CardRangeVO[] = await withSpinner(iCardsManagerRemote.getCardRanges1(
            this.userStore.session,
            toJS(externalCard),
            0,
            1000,
        ))

        runInAction(() => {
            this.editingExternalCardRanges = ranges || []
        })
    }

    @action
    modifyEditingExternalCard = (modification: Partial<ExternalCardsVO>): void => {
        Object.keys(modification).forEach(key => this.editingExternalCard[key] = modification[key])
    }

    createDraftExternalCard = (): ExternalCardsVO => {
        return createExternalCardsVO({
            id: -1,
            name: t('externalCards.edit.newExternalCard'),
            percentageDiscount: 0,
        })
    }

    @action
    saveEditedExternalCard = async (): Promise<void> => {
        const isNew = this.editingExternalCard.id === -1
        let savedExternalCard: ExternalCardsVO

        if (isNew) {
            savedExternalCard = await withSpinner(iExternalCardsManagerRemote.createExternalCards(
                this.userStore.session,
                toJS(this.editingExternalCard),
            ))
            goTo(`${CARDS}${CARDS_SEARCH}${EXTERNAL_CARDS}${EXTERNAL_CARDS_EDIT}/${savedExternalCard.id}`)
        } else {
            savedExternalCard = await withSpinner(iExternalCardsManagerRemote.storeExternalCards(
                this.userStore.session,
                toJS(this.editingExternalCard),
            ))
        }

        runInAction(() => {
            this.editingExternalCard = savedExternalCard
            this.originalEditingExternalCard = savedExternalCard
        })
    }

    closeEditedExternalCard = async (): Promise<void> => {
        goTo(`${CARDS}${CARDS_SEARCH}${EXTERNAL_CARDS}`)

        this.editingExternalCard = undefined
        this.originalEditingExternalCard = undefined
        this.editingExternalCardRanges = []
    }

    isRangeAlreadyUsed = (start: string, finish: string): boolean =>
        this.editingExternalCardRanges.some(range =>
            (start.length === range.start.length) &&
            ((start >= range.start && start <= range.finish) || (finish >= range.start && finish <= range.finish)))

    @action
    addExternalCardRange = async (start: string, finish: string): Promise<void> => {
        const isNewCard = this.editingExternalCard.id === -1

        if (this.isRangeAlreadyUsed(start, finish)) {
            this.rangeError = true
            return
        }

        if (isNewCard) {
            await this.saveEditedExternalCard()
        }

        const newCardRange: CardRangeVO = createCardRangeVO({
            start,
            finish,
            // @ts-ignore
            count: ['java.math.BigInteger', String(Number(finish) - Number(start) + 1)],
            cardType: toJS(this.editingExternalCard),
        })

        await withSpinner(iCardsManagerRemote.addCardRange1(
            this.userStore.session,
            newCardRange,
            {
                customCommonResponseMiddlewares: [
                    response => {
                        let error: { code?: number, message?: string } = response.data.error
                        if (error && error.code === INTERNAL_ERROR && error.message === INTERSECTION_RANGES) {
                            this.rangeError = true
                        }
                    },
                ]
            }
        ))
        this.fetchExternalCardRanges(this.editingExternalCard)
    }

    removeExternalCardRange = async (cardRange: CardRangeVO): Promise<void> => {
        await withSpinner(iCardsManagerRemote.removeCardRange1(this.userStore.session, cardRange))
        this.fetchExternalCardRanges(this.editingExternalCard)
    }

    @action
    resetRangeError = (): void => {
        this.rangeError = false
    }

    @action
    reset = (): void => {
        this.externalCards = undefined
        this.editingExternalCard = undefined
        this.originalEditingExternalCard = undefined
        this.rangeError = false
    }
}
