import { RectBounds } from '../rect-bounds'
import { BOTTOM_LEFT, CENTER, ControllerType, LEFT, ROTATION, TOP, TOP_LEFT, TOP_RIGHT } from '../hand-controller'
import { convertMatrix, Vector2d } from '../../../utils/math/geom-util'
import { BaseConstraint, EditorState } from './constraint'
import { getResizeForAction } from '../editor-util'

export class FixedProportionsConstraint extends BaseConstraint<null> {

    constructor(active: boolean = false) {
        super(null, 1, active)
    }

    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d {
        if (!this.active || [CENTER, ROTATION].includes(controller) || translation.isZero()) return translation

        const convertedMatrix = convertMatrix(bounds.matrix)
        const resize = getResizeForAction(bounds, controller, translation)

        let fixedResize = saveProportions(bounds, resize)

        if (!resize.equals(fixedResize)) {
            // Если ограничение сработало, просто вернем вектор изменения размера
            // Для некоторых контроллеров направление противоположно изменению размера объекта
            const INVERT_X = [TOP_LEFT, LEFT, BOTTOM_LEFT]
            const INVERT_Y = [TOP_LEFT, TOP, TOP_RIGHT]

            if (INVERT_X.includes(controller)) fixedResize.x = -fixedResize.x
            if (INVERT_Y.includes(controller)) fixedResize.y = -fixedResize.y

            fixedResize = fixedResize.rotate(convertedMatrix.rotation)

            return fixedResize
        } else {
            return translation
        }
    }

    constraintSize(bounds: RectBounds, controller: ControllerType, translation: Vector2d, resize: Vector2d, editorState: EditorState): Vector2d {
        if (!this.active || [CENTER, ROTATION].includes(controller) || resize.isZero()) return resize

        const fixedResize = saveProportions(bounds, resize)

        return fixedResize
    }
}

function saveProportions(bounds: RectBounds, resize: Vector2d): Vector2d {
    const targetWidth = bounds.width + resize.x
    const targetHeight = bounds.height + resize.y

    const fixedResize = resize.clone()

    let targetSize = resize.x
    // Наибольшее изменение по оси приоритетнее
    if (Math.abs(resize.x) > Math.abs(resize.y)) {
        targetSize = targetWidth
    } else {
        targetSize = targetHeight
    }

    if (targetWidth !== targetSize) {
        fixedResize.x = targetSize - bounds.height
    } else {
        fixedResize.y = targetSize - bounds.width
    }

    return fixedResize
}
