import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { EquipmentModelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { FROZEN, TEMPLATE_CHANGED } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-state'
import { ScalesTemplateVO } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-template-vo'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { SHOP_SCALES_STORE } from '../../../store/stores'
import { ShopScalesStore } from '../../../store/shops/shop-settings/shop-scales-store'
import { DeviceSettingsType } from '../../../core/values'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { config } from '../../../config/config'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { goTo } from '../../../utils/router-util'
import { SCALES_MODULE, TEMPLATES } from '../../../core/app-routes'

const styles = require('./scales-settings-dialog.scss')

interface ScalesSettingsDialogProps {
    shopScalesStore?: ShopScalesStore
}

@inject(SHOP_SCALES_STORE)
@observer
export class ScalesSettingsDialog extends React.Component<ScalesSettingsDialogProps> {

    handleTemplateChange = (selectedTemplate: DefaultSelectOption<number>) => {
        const {updateScales, scalesTemplates} = this.props.shopScalesStore
        const template = scalesTemplates.find(t => t.id === selectedTemplate.value)
        if (template) {
            updateScales({
                scalesTemplate: toJS(template),
                state: TEMPLATE_CHANGED
            })
        }
    }

    handleSaveButton = () => {
        const {saveScalesSet, saveScales, scalesSettings, scalesSettingsType, scalesSettingsCount, closeScalesSettings} = this.props.shopScalesStore
        switch (scalesSettingsType) {
            case DeviceSettingsType.NEW:
                saveScalesSet(toJS(scalesSettings), scalesSettingsCount)
                break
            case DeviceSettingsType.EDIT:
                saveScales(toJS(scalesSettings))
                break
            default:
                break
        }
        closeScalesSettings()
    }

    handleNewModel = () => {
        const {closeScalesSettings, showScalesDevicesDialog} = this.props.shopScalesStore
        closeScalesSettings()
        showScalesDevicesDialog()

    }

    handleNewTemplate = () => {
        this.props.shopScalesStore.closeScalesSettings()
        goTo(`${SCALES_MODULE}${TEMPLATES}`)
    }

    render() {
        const {
            scalesSettings, closeScalesSettings, scalesSettingsType, scalesModels, updateScales,
            scalesSettingsCount, scalesValidation, scalesTemplates, updateScalesCount,
        } = this.props.shopScalesStore
        const editMode = scalesSettingsType === DeviceSettingsType.EDIT

        if (!scalesSettings || !scalesValidation) return null

        const numberValidaton = scalesValidation.getValidationFor('number')
        const modelValidation = scalesValidation.getValidationFor('model')
        const adressValidation = scalesValidation.getValidationFor('addressing')
        const templateValidation = scalesValidation.getValidationFor('scalesTemplate')

        let saveButtonAvailable = false
        switch (scalesSettingsType) {
            case DeviceSettingsType.EDIT:
                saveButtonAvailable = scalesValidation.modified && scalesValidation.valid
                break
            case DeviceSettingsType.NEW:
                saveButtonAvailable = modelValidation.valid && templateValidation.valid
                break
            default:
                break
        }

        const frozen = scalesSettings.state === FROZEN

        let customControls = []
        const showLoadAddText = editMode && (scalesSettings.model === 'BIZERBA_BC_II' ||
            scalesSettings.model === 'TOLEDO' || scalesSettings.model === 'TOLEDO_362')
        if (showLoadAddText) {
            customControls.push(
                <Checkbox
                    key="noLoadAddText"
                    id="dialogNoLoadAddTextInput"
                    checked={scalesSettings.noLoadAddText}
                    className={styles.dialogInputs}
                    label={t('shopPage.disableAddText')}
                    onValueChange={noLoadAddText => updateScales({noLoadAddText})}
                />
            )
        }
        if (editMode && scalesSettings.model === 'ACLAS_SELFSERVICE') {
            customControls.push(
                <Checkbox
                    key="clearScales"
                    id="dialogClearScalesInput"
                    checked={scalesSettings.clearScales}
                    className={styles.dialogInputs}
                    label={t('shopPage.clearScales')}
                    onValueChange={clearScales => updateScales({clearScales})}
                />
            )
            customControls.push(
                <Checkbox
                    key="printTimeOnLabel"
                    id="dialogPrintTimeOnLabelInput"
                    checked={scalesSettings.printTimeOnLabel}
                    className={styles.dialogInputs}
                    label={t('shopPage.printTimeOnLabel')}
                    onValueChange={printTimeOnLabel => updateScales({printTimeOnLabel})}
                />
            )
        }

        return (
            <AdaptiveDialog
                id="fullScreenDialog"
                open={Boolean(scalesSettings)}
                onClose={editMode && saveButtonAvailable ? null : closeScalesSettings}
                className={styles.dialog}
            >
                <DialogTitle>
                    {editMode ? t('shopPage.scaleTitleEdit') : t('shopPage.scaleTitleNew')}
                </DialogTitle>
                <DialogContent className={styles.content}>
                    {!editMode && (
                        <NumberInput
                            id="dialogAmountInput"
                            fullWidth
                            autoFocus
                            min={1}
                            max={999}
                            className={styles.dialogInputs}
                            value={scalesSettingsCount}
                            onValueChange={updateScalesCount}
                            label={t('shop.amount')}
                        />
                    )}
                    {editMode && (
                        <NumberInput
                            id="dialogNumberInput"
                            fullWidth
                            autoFocus
                            min={1}
                            max={999}
                            className={styles.dialogInputs}
                            value={scalesSettings.number}
                            label={t('shopPage.deviceNumber')}
                            onValueChange={value => updateScales({number: value})}
                            errorText={numberValidaton.touched && numberValidaton.firstError}
                        />
                    )}
                    {
                        scalesModels.length > 0 ? (
                            <SelectInput
                                id="dialogModelInput"
                                onSelect={selectedOption => updateScales({ model: selectedOption.value })}
                                label={t('shopPage.deviceModel')}
                                className={styles.dialogInputs}
                                options={scalesModels.map((model: EquipmentModelVO) => {
                                    return { label: model.localizedName, value: model.name }
                                })}
                                value={scalesSettings.model}
                            />
                        ) : (
                            <TextField
                                id="dialogNoModeldInput"
                                fullWidth
                                className={styles.dialogInputs}
                                label={t('shopPage.deviceModel')}
                                value={t('shopPage.noModels')}

                                InputProps={{
                                    error: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                enterDelay={TOOLTIP_DELAY}
                                                title={t('devices.newDeviceNavBar')}
                                            >
                                                <IconButton
                                                  onClick={this.handleNewModel}
                                                >
                                                  <AddCircleOutline />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    }
                    {editMode && (
                        <TextField
                            id="dialogAddressingInput"
                            className={styles.dialogInputs}
                            value={scalesSettings.addressing || ''}
                            label={t('shopPage.deviceAddressing')}
                            errorText={adressValidation.firstError}
                            onValueChange={addressing => updateScales({addressing})}
                        />
                    )}
                    {
                        scalesTemplates.length > 0 ? (
                            <SelectInput<DefaultSelectOption<number>>
                                id="dialogTemplateInput"
                                onSelect={this.handleTemplateChange}
                                label={t('shopPage.deviceTemplate')}
                                disabled={frozen}
                                className={styles.dialogInputs}
                                options={scalesTemplates.map((template: ScalesTemplateVO) => {
                                    return {label: template.name, value: template.id}
                                })}
                                value={scalesSettings.scalesTemplate.id}
                            />
                        ) : (
                            <TextField
                                id="dialogNoTemplateInput"
                                fullWidth
                                className={styles.dialogInputs}
                                label={t('shopPage.deviceTemplate')}
                                value={t('shopPage.noScalesTemplates')}

                                InputProps={{
                                    error: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                enterDelay={TOOLTIP_DELAY}
                                                title={t('shopPage.addNewTemplate')}
                                            >
                                                <IconButton
                                                  onClick={this.handleNewTemplate}
                                                >
                                                  <AddCircleOutline />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    }
                    {
                        customControls
                    }
                    {editMode && (
                        <div className={styles.state}>
                            <InputLabel className={styles.stateLabel}>
                                {t('shopPage.deviceState')}
                            </InputLabel>
                            <Typography className={styles.stateValue} color={frozen ? 'textSecondary' : 'primary'}>
                                {frozen ? t('shopPage.scaleFrozen') : t('shopPage.scaleActive')}
                            </Typography>
                        </div>
                    )}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    <Button
                        onClick={closeScalesSettings}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={this.handleSaveButton}
                        color="primary"
                        disabled={!saveButtonAvailable}
                        id="dialogSaveButton"
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
