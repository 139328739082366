
export type buttonType = 'kbdAlphaNumeric' | 'kbdControl' | 'kbdFunction' |
'kbdGoods' | 'kbdManualAdvAction' | 'kbdPayment' | 'kbdSaleGroup'

export const KBD_ALPHA_NUMERIC = 'kbdAlphaNumeric'
export const KBD_FUNCTION = 'kbdFunction' // кнопка с назначенной на нее функцией
export const KBD_CONTROL = 'kbdControl' // управляющие клавиши
export const KBD_MANUAL_ADV_ACTION = 'kbdManualAdvAction' // кнопка с назначенной на нее ручной скидкой
export const KBD_PAYMENT = 'kbdPayment' // кнопка с назначенным на нее типом оплаты
export const KBD_SALE_GROUP = 'kbdSaleGroup' // кнопка с назначенным на нее товарным списком
export const KBD_GOODS = 'kbdGoods' // кнопка с назначенным на нее товаром
export const KBD_QWERTY = 'qwerty' // Тип qwerty-клавиатур

export interface ScanCode {
    X: string,
    Y: string,
    _: string,
}

export interface ButtonCommon {
    ScanCode: ScanCode[],
    Ch: string,
}

export interface ButtonFunction extends ButtonCommon {
    Command: string,
}

export interface ButtonDiscount extends ButtonCommon {
    AdvActionGuid: {
        name: string,
        _: string,
    }
}

export interface ButtonPayment extends ButtonCommon {
    PaymentName: string,
    BankName: string,
}

export interface ButtonSaleGroup extends ButtonCommon {
    SaleGroupCode: {
        name: string,
        _: string,
    },
}

export interface ButtonGoods extends ButtonCommon {
    GoodsCode: {
        name: string,
        _: string,
    }
}

export interface KeyLockMapPosition {
    key: string,
    value: string,
}

export interface KeyboardKbdVo {
    'Keyboard-data': {
        Model: string,
        CountX: string, // Заполняется данными из map.xml
        CountY: string,
        keyLockMap: {
            position: KeyLockMapPosition[],
        },
        [KBD_ALPHA_NUMERIC]?: ButtonCommon[],
        [KBD_FUNCTION]?: ButtonFunction[],
        [KBD_CONTROL]?: ButtonFunction[],
        [KBD_MANUAL_ADV_ACTION]?: ButtonDiscount[],
        [KBD_PAYMENT]?: ButtonPayment[],
        [KBD_SALE_GROUP]?: ButtonSaleGroup[],
        [KBD_GOODS]?: ButtonGoods[],
    }
}
