import { isNil } from 'lodash'

export function degToRad(deg: number): number {
    if (isNil(deg)) return 0
    return Math.PI / 180 * deg
}

export function radToDeg(rad: number): number {
    if (isNil(rad)) return 0
    return 180 * rad / Math.PI
}

export function normalizeAngle(rad: number): number {
    if (isNil(rad)) return 0
    while (rad < 0 || rad > Math.PI * 2) {
        if (rad < 0) {
            rad += Math.PI * 2
        } else {
            rad -= Math.PI * 2
        }
    }
    return rad
}

// Из 65280 сделает #00ff00
export function intToHexColor(int: number): string {
    return `#${('000000' + int.toString(16)).slice(-6)}`
}

// Из #00ff00 сделает 65280
export function hexColorToInt(hexColor: string): number {
    return parseInt(hexColor.slice(-6), 16)
}

export function gaussRound(num: number, decimalPlaces?: number) {
    const d = decimalPlaces || 0
    const m = Math.pow(10, d)
    const n = +(d ? num * m : num).toFixed(8)
    const i = Math.floor(n)
    const f = n - i
    const e = 1e-8
    const r = (f > 0.5 - e && f < 0.5 + e) ?
            ((i % 2 === 0) ? i : i + 1) : Math.round(n)
    return d ? r / m : r
}
