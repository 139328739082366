import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { sortBy, uniqBy } from 'lodash'
import { t } from 'i18next'
import Grid, { GridSize } from '@material-ui/core/Grid'
import { PriceTagsPrintingStore } from '../../../store/price-tags/printing/printing-store'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { PRICE_TAGS_PRINTING_STORE } from '../../../store/stores'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import {
    PriceTagTemplateVO,
    createPriceTagTemplateVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { PriceTagSizeLabelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-size-label-vo'

const styles = require('./price-tags-filters.scss')

export const ANY_SIZE_TEMPLATE_ID: string = '-1'

function createOptionWithSizeAny(): SizeSelectorOption {
    return {
        label: t('priceTagsPrinting.any'),
        value: ANY_SIZE_TEMPLATE_ID,
        priceTag: createPriceTagTemplateVO({
            id: ANY_SIZE_TEMPLATE_ID,
            width: 0,
            height: 0
        })
    }
}

interface SizeSelectorOption {
    label: string
    value: string
    priceTag?: PriceTagTemplateVO
    priceTagSizeLabel?: PriceTagSizeLabelVO
}

export interface PriceTagsFiltersState {
    priceTagName: string
    printerName: string
    formatName: string
    selectedPriceTagSize: SizeSelectorOption
}

interface PriceTagsFiltersProps {
    priceTagsPrintingStore?: PriceTagsPrintingStore
    onStateChange: (newState: PriceTagsFiltersState) => void
    filters: Array<keyof PriceTagsFiltersState>
}

@inject(PRICE_TAGS_PRINTING_STORE)
@observer
export class PriceTagsFilters extends React.Component<PriceTagsFiltersProps, PriceTagsFiltersState> {

    state: PriceTagsFiltersState = {
        priceTagName: '',
        printerName: '',
        formatName: '',
        selectedPriceTagSize: createOptionWithSizeAny(),
    }

    setState = <K extends keyof PriceTagsFiltersState>(state: (Pick<PriceTagsFiltersState, K>)): void => {
        super.setState(state, () => this.props.onStateChange(this.state))
    }

    getSizeSelectorOptions(): SizeSelectorOption[] {
        const { allPriceTags, priceTagSizeLabels, actionable } = this.props.priceTagsPrintingStore

        let result: SizeSelectorOption[] = []

        result.push(createOptionWithSizeAny())

        if (priceTagSizeLabels) {
            priceTagSizeLabels.forEach(ptLabel => {
                result.push({
                    label: ptLabel.name,
                    value: String(ptLabel.id),
                    priceTagSizeLabel: ptLabel
                })
            })
        }

        if (allPriceTags) {
            let filteredUniquePriceTagOptions: SizeSelectorOption[] = []

            allPriceTags.forEach(priceTag => {
                if (!priceTag.actionable === actionable) {
                    return
                }

                if (!filteredUniquePriceTagOptions.some(resultOption => resultOption.priceTag.id === priceTag.id)) {
                    filteredUniquePriceTagOptions.push({
                        label: `${priceTag.width} x ${priceTag.height}`,
                        value: priceTag.id,
                        priceTag
                    })
                }
            })

            filteredUniquePriceTagOptions = sortBy(filteredUniquePriceTagOptions, 'priceTag.width', 'priceTag.height')

            result = [...result, ...filteredUniquePriceTagOptions]
        }

        return uniqBy(result, 'label')
    }

    render() {
        const { priceTagName, printerName, formatName, selectedPriceTagSize } = this.state

        const SM: GridSize = 12 / this.props.filters.length as GridSize

        return (
            <Grid
                container
                spacing={2}
                alignItems="center"
                className={styles.priceTagsFilters}
            >
                { this.props.filters.map(filterType => {
                    switch (filterType) {
                        default:
                            return null
                        case 'priceTagName':
                            return (
                                <Grid item xs={12} sm={SM} key={filterType}>
                                    <TextField
                                        id="priceTagNameInput"
                                        label={t('priceTagsPrinting.priceTagNameLabel')}
                                        value={priceTagName}
                                        onValueChange={value => this.setState({ priceTagName: value })}
                                    />
                                </Grid>
                            )
                        case 'printerName':
                            return (
                                <Grid item xs={12} sm={SM} key={filterType}>
                                    <TextField
                                        id="printerNameInput"
                                        label={t('priceTagsPrinting.printerNameLabel')}
                                        value={printerName}
                                        onValueChange={value => this.setState({ printerName: value })}
                                    />
                                </Grid>
                            )
                        case 'formatName':
                            return (
                                <Grid item xs={12} sm={SM} key={filterType}>
                                    <TextField
                                        id="formatNameInput"
                                        label={t('priceTagsPrinting.formatNameLabel')}
                                        value={formatName}
                                        onValueChange={value => this.setState({ formatName: value })}
                                    />
                                </Grid>
                            )
                        case 'selectedPriceTagSize':
                            return (
                                <Grid item xs={12} sm={SM} key={filterType}>
                                    <SelectInput<SizeSelectorOption>
                                        id="priceTagSizeSelector"
                                        labelField="label"
                                        valueField="value"
                                        disabled={false}
                                        options={this.getSizeSelectorOptions()}
                                        label={t('priceTagsPrinting.priceTagSizeSelectorLabel')}
                                        value={selectedPriceTagSize.value}
                                        onSelect={(value: SizeSelectorOption): void => this.setState({ selectedPriceTagSize: value })}
                                    />
                                </Grid>
                            )
                    }
                }) }
            </Grid>
        )
    }
}
