import { t } from 'i18next'
import { Cancelable, debounce } from 'lodash'
import { action, observable, runInAction, toJS } from 'mobx'
import uuid from 'uuid'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { ProductTTSettingsVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-tt-settings-vo'
import { ValidationFieldVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/validation-field-vo'
import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { AppStore } from '../app-store'
import { APP_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'

export type ProductShelvesSteps = 'validation' | 'priceTagBind' | 'priceTagPrint' | 'sendToCash' | 'sendToScales'

export class ProductShelvesStore {

    @observable
    currentStep: ProductShelvesSteps = 'validation'

    @observable
    shelvesSettings: ProductTTSettingsVO

    @observable
    validationFields: ValidationFieldVO[] = []

    @observable
    attributesKey: string = uuid()

    debouncedSaveSettings: (() => void) & Cancelable = debounce(() => {
        this.saveSettings()
    }, AUTOSAVE_DELAY)

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    @action
    setCurrentStep = (step: ProductShelvesSteps): void => {
        this.currentStep = step
    }

    @action
    editShelvesSettings = (changes: Partial<ProductTTSettingsVO>): void => {
        Object.keys(changes).forEach(k => {
            this.shelvesSettings[k] = changes[k]
        })

        this.debouncedSaveSettings()
    }

    fetchAllData = async (): Promise<void> => {
        await this.fetchShelvesSettings()
        await this.fetchValidationFields()
    }

    fetchShelvesSettings = (): Promise<void> => {
        return iProductsManagerLocal.getTechProcessSettings2(this.userStore.session, this.appStore.locale)
            .then(result => {
                runInAction(() => {
                    this.shelvesSettings = result
                })
            })
    }

    fetchValidationFields = (): Promise<void> => {
        return iProductsManagerLocal.getValidationFields2(this.userStore.session, this.appStore.locale)
            .then(result => {
                runInAction(() => {
                    this.validationFields = result
                    this.attributesKey = uuid()
                })
            })
    }

    saveSettings = (): Promise<void> => {
        return iProductsManagerLocal.storeTechProcessSettings(this.userStore.session, toJS(this.shelvesSettings))
            .then(result => {
                this.appStore.showSnackbar({message: t('set10.settingsSaved')})
            })
    }

    @action
    reset = (): void => {
        this.currentStep = 'validation'
        this.shelvesSettings = undefined
        this.validationFields = []
        this.attributesKey = uuid()
    }
}
