import React from 'react'
import {
    AutocompleteInput
} from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { t } from 'i18next'
import classNames from 'classnames'
import { CounterVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/counter-vo'
import { countersService } from '../../../protocol/set10/counters-service'

const styles = require('./autocomplete.scss')

interface CounterAutocompleteProps {
    onSuggestionSelect: (suggestion: any) => void
    /**
     * Указанные айдишники не будут отображаться в найденных элементах
     */
    excludedItemsIds?: string[]
    /**
     * К указанным акциям будет применен дополнительный стиль
     */
    markedItemsIds?: string[]
    markedClassName?: string
    disabled?: boolean
}

export const CounterAutocomplete = (props: CounterAutocompleteProps) => (
    <AutocompleteInput<CounterVO>
        placeholder={t('components.autocomplete.counterPlaceholder')}
        fetchDataCallback={async (name: string) => {
            const result = await countersService.getCountersByLikeName(name)

            if (!props.excludedItemsIds) return result

            // Не включаем акции из списка исключенных
            return result.filter(item => {
                return !props.excludedItemsIds.some(id => id === item.id)
            })
        }}
        keyField="id"
        labelField="name"
        onSuggestionSelect={props.onSuggestionSelect}
        disabled={props.disabled}
        menuItemRenderer={item => {
            const { markedItemsIds, markedClassName } = props
            return (
                <div className={classNames({
                    [markedClassName || styles.selectedAction]: markedItemsIds && markedItemsIds.some(
                        id => id === item.id
                    )
                })}>
                    {item.name}
                </div>
            )
        }}
    />
)
