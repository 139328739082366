export interface FormatsAbstractVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsAbstractVO' | 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsSizeBindingVO' | 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsProductListVO' | 'ru.crystals.setretailx.print.formats.presenter.vo.PriceTagFormatVO'
    id?: number
    topologyId?: number
    name?: string
    description?: string
}

export const createFormatsAbstractVO = (initialization: Partial<FormatsAbstractVO>): FormatsAbstractVO => {
    if (!initialization) return null
    
    let result: FormatsAbstractVO = {
        id: initialization.id,
        topologyId: initialization.topologyId,
        name: initialization.name,
        description: initialization.description,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.FormatsAbstractVO',
    }
    
    return result
}
