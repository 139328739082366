import { EquipmentModelVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { t } from 'i18next'

export const KEYBOARD_NAME = 'keyboard'
export const NFC_NAME = 'nfcReader'
export const MSR_NAME = 'externalMSR'
export const DRAWER_NAME = 'cashDrawer'
export const DISPLAY_NAME = 'customerDisplay'
export const SCALE_NAME = 'scale'
export const PRINTER_NAME = 'fiscalPrinter'

export const CASH_CLASS = 'Cash'

export const NFC_DECIMAL = 'DECIMAL'
export const NFC_HEX = 'HEX'

export interface EquipmentModel extends EquipmentModelVO {
    /**
     * Зарегистрировано ли оборудование
     */
    registered?: boolean
    /**
     * Поле для клавиатур. Их может быть зарегистрированно несколько. Для режима AllModelsByGroup
     */
    ids?: number[]
}

export type GroupName = string
export type ModelName = string

export type RegisteredModelsByGroup = Map<GroupName, Map<ModelName, EquipmentModel[]>>
export type AllModelsByGroup = Map<GroupName, Map<ModelName, EquipmentModel>>

// Интерфейс для отображения групп на странице регистрации оборудования

export interface DeviceListTypeGroup {
    /**
     * Заголовок (можно передать пустую строку, чтобы было без заголовка)
     */
    label: string,
    /**
     * Условие попадания оборудования в группу.
     * Параметр 'name' должен начинаться с данной строки.
     * Если searchString = пустая строка - попадут все модели, что остались
     */
    searchString: string | string[],
    /**
     * В некоторых группах, вместо чекбокса используется switch
     * обычно это группы без заголовка с 1 элементом,
     * но из-за возможности поиска группа с 1 элементом не свегда будет switch
     */
    useSwitch?: boolean,
    /**
     * Ширина группы (сколько чекбоксов в ширину влазит). По умолчанию 1
     */
    columnsNumber?: 1 | 2,
}

export const getDeviceListGroups = (groupName: string): DeviceListTypeGroup[] => {
    switch (groupName) {
        case MSR_NAME:
            return [
                {
                    label: t('cashDevices.groups.forSimpleCashes'),
                    searchString: ['wincor', 'posiflex'],
                },
                {
                    label: t('cashDevices.groups.forTouchCashes'),
                    searchString: '',
                    columnsNumber: 2,
                }
            ]
        case DRAWER_NAME:
            return [
                {
                    label: '',
                    searchString: '',
                }
            ]
        case NFC_NAME:
            return [
                {
                    label: '',
                    searchString: '',
                }
            ]
        case DISPLAY_NAME:
            return [
                {
                    label: t('cashDevices.groups.csi'),
                    searchString: 'csi',
                },
                {
                    label: t('cashDevices.groups.postifex'),
                    searchString: 'posiflex',
                },
                {
                    label: t('cashDevices.groups.ncr'),
                    searchString: 'ncr',
                },
                {
                    label: '',
                    searchString: 'viaFiscalPrinter',
                    useSwitch: true
                },
                {
                    label: t('cashDevices.groups.other'),
                    searchString: '',
                    columnsNumber: 2
                }
            ]
        case SCALE_NAME:
            return [
                {
                    label: t('cashDevices.groups.cas'),
                    searchString: 'cas',
                },
                {
                    label: t('cashDevices.groups.magellan'),
                    searchString: 'magellan',
                },
                {
                    label: t('cashDevices.groups.shtrih'),
                    searchString: 'shtrih',
                },
                {
                    label: t('cashDevices.groups.other'),
                    searchString: '',
                    columnsNumber: 2
                }
            ]
        case PRINTER_NAME:
            return [
                {
                    label: t('cashDevices.groups.axiohm'),
                    searchString: 'nonfiscalmode_axiohm',
                },
                {
                    label: t('cashDevices.groups.fprint'),
                    searchString: 'fprint',
                },
                {
                    label: t('cashDevices.groups.a7'),
                    searchString: 'atol',
                },
                {
                    label: t('cashDevices.groups.pirit'),
                    searchString: ['pirit', 'envd_pirit', 'nonfiscalmode_pirit'],
                },
                {
                    label: t('cashDevices.groups.sp'),
                    searchString: 'sp',
                },
                {
                    label: t('cashDevices.groups.shtrih'),
                    searchString: 'shtrih',
                },
                {
                    label: t('cashDevices.groups.noPrinter'),
                    searchString: 'nonfiscalmode_withoutprinter',
                    useSwitch: true,
                },
                {
                    label: t('cashDevices.groups.other'),
                    searchString: '',
                }
            ]
        case KEYBOARD_NAME:
            return [
                {
                    label: t('cashDevices.groups.hengYu'),
                    searchString: 'hengyu',
                },
                {
                    label: '',
                    searchString: 'qwerty',
                    useSwitch: true,
                },
                {
                    label: t('cashDevices.groups.ncr'),
                    searchString: 'ncr',
                },
                {
                    label: t('cashDevices.groups.preh'),
                    searchString: 'preh',
                },
                {
                    label: t('cashDevices.groups.vikiPos'),
                    searchString: 'vikipos',
                },
                {
                    label: t('cashDevices.groups.other'),
                    searchString: '',
                    columnsNumber: 2
                }
            ]
        default:
            return [
                {
                    label: '',
                    searchString: '',
                }
            ]
    }
}

// Преднастроенные варианты положения ключей

export type KEY_POSITION_TYPE = 'key1' | 'key2'

export const KEY_POSITION_ONE = 'key1'
export const KEY_POSITION_ONE_ARRAY = [
    { key: '4', value: '1' },
    { key: '3', value: '1' },
    { key: '0', value: '1' },
    { key: '1', value: '1' },
    { key: '2', value: '2' },
]

export const KEY_POSITION_TWO = 'key2'
export const KEY_POSITION_TWO_ARRAY = [
    { key: '4', value: '1' },
    { key: '3', value: '2' },
    { key: '0', value: '2' },
    { key: '1', value: '2' },
    { key: '2', value: '2' },
]

export const calculateKeyPosition = (array: Array<{key: string, value: string}>): KEY_POSITION_TYPE => {
    if (array && array[2] && array[2].value === '1') {
        return KEY_POSITION_ONE
    }
    return KEY_POSITION_TWO
}
