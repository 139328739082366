import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { AppStore } from '../../../store/app-store'
import { t } from 'i18next'
import { Table } from '@crystalservice/crystals-ui/lib'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { PageLoader } from '../../../../components/loaders/page-loader'

export interface SalesGroupsProps {
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
}

@inject(APP_STORE)
@inject(PRODUCT_DETAILS_STORE)
@observer
export default class SalesGroups extends React.Component<SalesGroupsProps> {

    render() {
        const { productDetails } = this.props.productDetailsStore

        if (!productDetails.salesGroups) return <PageLoader/>
        if (!productDetails.salesGroups.length) return <Empty/>

        return (
            <Table
                id="salesGroupsList"
                withHeader
                keyField="id"
                items={productDetails.salesGroups}
                columns={[
                    {
                        header: t('common.name'),
                        labelFunction: (salesGroup: string): string => {
                            return salesGroup
                        },
                    },
                ]}
                adaptive={false}
            />
        )
    }
}
