import { action, observable } from 'mobx'
import { isNil } from 'lodash'
import { isEndNode, isFolder, Item } from '../../components/nav-bar/nav-bar'
import { AppStore, DEFAULT_SCREEN_WIDTH } from './app-store'
import { goTo } from '../utils/router-util'
import { RouterStore } from 'mobx-react-router'
import { getStore } from './stores-repository'
import { APP_STORE, ROUTING_STORE } from './stores'
import { RouteScheme } from '../utils/route-modules-util'
import { lg } from '../../utils/css/breakpoints'

export class NavigationMenuStore {
    @observable
    open: boolean = false

    @observable
    selectedItem: Item = null

    @observable
    navBarItemGroup: Item

    @observable
    expandedItems: Item[] = []

    private appStore: AppStore = getStore(APP_STORE)
    private routingStore: RouterStore = getStore(ROUTING_STORE)

    constructor() {
        if (this.appStore && this.appStore.screenWidth !== DEFAULT_SCREEN_WIDTH) {
            this.openIfScreenWideEnough()
        }
    }

    @action
    init = (availableAppRoutes: RouteScheme) => {
        this.navBarItemGroup = createNavBarItemGroup(availableAppRoutes)
    }

    @action
    openIfScreenWideEnough = (): void => {
        if (this.appStore.screenWidth > lg) {
            this.open = true
        }
    }

    @action
    setExpandedItems = (expandedItems: Item[]): void => {
        this.expandedItems = expandedItems
    }

    @action
    synchronizeWithRoute = (): void => {
        let route = this.routingStore.location.pathname

        this.selectedItem = this.findSelectedItem(route, this.navBarItemGroup)

        if (!this.selectedItem) {
            return
        }

        // Получим родителей выбранного пункта меню, которых необходимо добавить в expandedItems
        const expandableParents = this.getItemExpandableParents(this.selectedItem, this.navBarItemGroup)

        if (expandableParents && expandableParents.length > 0) {
            // Не добавляем повторно узлы в expandedItems
            expandableParents.forEach(item => {
                if (!this.expandedItems.find(existingItem => existingItem.id === item.id)) this.expandedItems.push(item)
            })
        }
    }

    findSelectedItem = (route: string, parentItem: Item): Item => {
        if (!parentItem) return null

        if (isEndNode(parentItem)) {
            const {hidden = false, selectable = true} = parentItem
            const isParentRoute = parentItem.data?.path &&
                parentItem.data?.path.split('/').filter(i => i.indexOf('?') === -1).every(path => route.split('/').includes(path))

            return selectable && !hidden && isParentRoute
                ? parentItem
                : null
        } else {
            let selectedItem: Item = null
            parentItem.children.forEach(child => {
                if (selectedItem) return
                selectedItem = this.findSelectedItem(route, child)
            })
            return selectedItem
        }
    }

    getItemExpandableParents = (searchedItem: Item, parentItem: Item): Item[] => {
        if (!parentItem) return null

        const {children} = parentItem

        if (children && children.length > 0) {
            // Если searchedItem находится среди потомков
            if (children.some(item => item === searchedItem)) {
                // То если parentItem - папка - возвращаем его в массиве (папку необходимо будет развернуть)
                return isFolder(parentItem) ? [parentItem] : []
            }

            // Рекурсивно проверим потомков
            const descendants: Item[][] = children.map(item => this.getItemExpandableParents(searchedItem, item))
            const descendantIndex: number = descendants.findIndex(item => item.length > 0)

            if (descendantIndex !== -1) {
                return isFolder(parentItem) ? [...descendants[descendantIndex], parentItem] : [...descendants[descendantIndex]]
            }
        }

        return []
    }

    selectMenuItem = (item: Item): void => {
        if (!isNil(item.data?.path)) {
            goTo(item.data?.path.split('/').filter(i => i.indexOf('?') === -1).join('/'))
        }
    }

    @action
    setOpen = (open: boolean): void => {
        this.open = open
    }

    @action
    reset = (): void => {
        this.open = false
        this.selectedItem = null
        this.navBarItemGroup = undefined
        this.expandedItems = []
    }
}

const createNavBarItemGroup = (routeScheme: RouteScheme): Item => {
    if (!routeScheme.navItemProps) {
        // Меню может быть у потомков
        if (routeScheme.children) {
            let childrenMenus = routeScheme.children.map(child => createNavBarItemGroup(child)).filter(Boolean)

            // Если среди потомков одно меню, используем его
            if (childrenMenus.length === 1) {
                return childrenMenus[0]
            }
        }

        return null
    }

    let newItem: Item = { ...routeScheme.navItemProps }

    newItem.data = { path: routeScheme.path }
    if (routeScheme.children && routeScheme.children.length > 0) {
        let newChildren = routeScheme.children.map(child => createNavBarItemGroup(child)).filter(Boolean)
        if (newChildren.length > 0) {
            newItem.children = newChildren
        } else {
            // Если должны быть потомки, но они все были скрыты из-за привилегий, то и папку показывать не надо
            return null
        }
    }

    return newItem
}
