import React, { Component, HTMLProps, ReactType, ReactNode } from 'react'
import classNames from 'classnames'
import Divider from '@material-ui/core/Divider'
import {ExpandButton} from '../../../components/buttons/expand-button/expand-button'
import {TreeItem, TreeView} from '../../../components/tree-view/tree-view'
import Button from '@material-ui/core/Button'
import {TopologyMap} from './topology-map'
import {FormatVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import {getHighestTopologyLevel, getNodesWithLevel, isSameFormats} from '../../core/topology/topology-util'
import {TopologyNodeVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-node-vo'
import {Table} from '@crystalservice/crystals-ui/lib/components/table/table'
import {
    createHierarchicalNode,
    getHierarchyDescription,
    mergeNodeToList
} from './topology-filter-util'
import {t} from 'i18next'
import {RIGHT} from '@crystalservice/crystals-ui/lib/components/table/column'
import {EnTopologyLevel} from '../../../protocol/set10/set-retail10-commons/data-structs-module/en-topology-level'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Delete from '@material-ui/icons/Delete'
import { SimpleDialog } from '../../../components/simple-dialog/simple-dialog'
import { ListInput } from '@crystalservice/crystals-ui/lib/components/inputs/list-input/list-input'
import { AdaptiveIconButton } from '../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./topology-filter.scss')

export type TopologyFilterMode = 'filter' | 'coverage'

/**
 * В этом режиме пустой список топологических узлов означает - выбрана вся сеть
 */
export const FILTER: TopologyFilterMode = 'filter'
/**
 * В этом режиме пустой список топологических узлов означает - ничего не выбрано
 */
export const COVERAGE: TopologyFilterMode = 'coverage'

export interface TopologyFilterProps extends HTMLProps<TopologyFilter> {
    map: TopologyMap
    mode?: TopologyFilterMode
    withPaper?: boolean
    use24hFlag?: boolean
    selection?: TopologyNode[]
    defaultSelection?: TopologyNode[]
    onFiltersChange?: (filters: TopologyNode[]) => void
    title?: string
    titleFunction?: (filters: TopologyNode[]) => string
    addButtonLabel?: string
    startExpanded?: boolean
    showExpandButton?: boolean
    addNodesByRequest?: boolean
    canAddNodes?: (nodes: TopologyNode[]) => boolean
    showAddListButton?: boolean
}

export interface TopologyFilterState {
    selection?: TopologyNode[]
    selectedFormats?: FormatVO[]
    only24h?: boolean
    expanded?: boolean
    conflictMsg?: string
}

export class TopologyNode {

    constructor(public node: TopologyNodeVO,
                public formats: FormatVO[],
                public parent: TopologyNode = null,
                public only24h: boolean = false) {
    }

    clone = (): TopologyNode => {
        let formats: FormatVO[] = this.formats
        if (!formats) {
            formats = []
        }
        return new TopologyNode(this.node, formats.concat(), this.parent, this.only24h)
    }
}

export class TopologyFilter extends Component<TopologyFilterProps, TopologyFilterState> {

    static defaultProps: Partial<TopologyFilterProps> = {
        id: '',
        withPaper: false,
        use24hFlag: true,
        selection: [],
        defaultSelection: [],
        onFiltersChange: () => null,
        startExpanded: false,
        showExpandButton: true,
        mode: FILTER,
        addNodesByRequest: false,
        canAddNodes: () => true,
        showAddListButton: true,
    }

    state: TopologyFilterState = {
        expanded: this.props.startExpanded,
        only24h: false,
        selectedFormats: [],
        selection: [],
        conflictMsg: '',
    }

    topologyTreeSelection: TopologyNodeVO[] = []

    componentDidMount(): void {
        const {defaultSelection, map} = this.props

        this.setState({
            selection: defaultSelection,
            selectedFormats: map.formats.concat()
        })
    }

    mergeNewNodesToSelectedNodes = (nodes: TopologyNodeVO[]): void => {
        const {onFiltersChange, map} = this.props
        const {selectedFormats, only24h, selection} = this.state
        let conflicts: string[] = []
        let newSelection: TopologyNode[] = selection.concat()

        for (let node of nodes) {
            let hierarchicalNode: TopologyNode = createHierarchicalNode(map, node, selectedFormats, only24h)
            let mergeResult: string[] = mergeNodeToList(hierarchicalNode, newSelection)
            conflicts = conflicts.concat(mergeResult)
        }

        this.setState({selection: newSelection})

        if (conflicts.length > 0) {
            if (conflicts.length > 10) {
                conflicts = conflicts.splice(0, 10)
            }
            let msg: string = this.getMergeErrorMessage(conflicts)
            this.setState({conflictMsg: msg})
        }

        onFiltersChange(newSelection)
    }

    getMergeErrorMessage = (conflicts: string[]): string => {
        return `${t('topology.topologyNodesAddingConflicts')}\n${conflicts.join('\n')}`
    }

    getTitle = () => {
        const {title, titleFunction, mode} = this.props
        const {selection} = this.state

        if (titleFunction) {
            return titleFunction(selection)
        } else if (title) {
            return title
        } else if (selection.length === 0) {
            if (mode === FILTER) {
                return `${t('topology.coverageArea')}: ${t('topology.entireStoresChain')}`
            } else {
                return `${t('topology.coverageArea')}: ${t('topology.noShopsSelected')}`
            }
        } else {
            return t('topology.coverageArea')
        }
    }

    expandCollapseHandler = (expanded: boolean): void => {
        this.setState({expanded})
    }

    formatSelectHandler = (format: FormatVO, selected: boolean): void => {
        let selection: FormatVO[] = this.state.selectedFormats.concat()
        if (selected) {
            selection.push(format)
        } else {
            let index: number = selection.indexOf(format)
            selection.splice(index, 1)
        }
        this.setState({
            selectedFormats: selection
        })
    }

    removeNodeHandler = (node: TopologyNode): void => {
        const {onFiltersChange} = this.props

        let selection: TopologyNode[] = this.state.selection.concat()
        selection.splice(selection.indexOf(node), 1)

        this.setState({selection})
        onFiltersChange(selection)
    }

    topologyTreeItemSelectionChange = (item: TreeItem, selected: boolean, depth: number): void => {
        if (selected) {
            this.topologyTreeSelection.push(item.item)
        } else {
            this.topologyTreeSelection.splice(this.topologyTreeSelection.indexOf(item.item), 1)
        }
    }

    addNodesFromSelection = (): void => {
        const highestLevel: EnTopologyLevel = getHighestTopologyLevel(this.topologyTreeSelection)
        const nodes: TopologyNodeVO[] = getNodesWithLevel(this.topologyTreeSelection, highestLevel)

        this.addNodesHandler(nodes)
    }

    addNodesHandler = (nodes: TopologyNodeVO[]): void => {
        const { canAddNodes, addNodesByRequest, map } = this.props
        const { selectedFormats, only24h } = this.state

        if (nodes.length === 0) return

        if (addNodesByRequest) {
            let hierarchicalNodes: TopologyNode[] = nodes.map(node =>
                createHierarchicalNode(map, node, selectedFormats, only24h))

            if (canAddNodes(hierarchicalNodes)) {
                this.mergeNewNodesToSelectedNodes(nodes)
            }
        } else {
            this.mergeNewNodesToSelectedNodes(nodes)
        }
    }

    renderTopologyTree = (): ReactNode => {
        const {id, map, addButtonLabel, showAddListButton} = this.props
        const {selectedFormats, only24h} = this.state

        if (!map) return null

        let tree: TreeItem[] = map.toTree(selectedFormats, only24h)

        return (
            <div>
                <Divider/>
                {this.renderFilters()}
                <Divider/>
                <TreeView
                    id={`${id}TopologyMap`}
                    className={styles.topologyTree}
                    items={tree}
                    multipleSelection
                    onItemSelectionChange={this.topologyTreeItemSelectionChange}
                />
                <Divider/>
                <div className={styles.buttonContainer}>
                    {
                        showAddListButton && (
                            <ListInput
                                CustomView={props => (
                                    <Button
                                        className={styles.addListButton}
                                        onClick={props.onClick}
                                    >
                                        {t('topology.addList')}
                                    </Button>
                                )}
                                getEntryTypeText={count => t('components.listInput.entryType.shopNumber', {count})}
                                onChange={(value: string) => {
                                    if (!value) return

                                    const values: string[] = value.split(',')
                                    const nodes: TopologyNodeVO[] = values.map(value =>
                                        this.props.map.shops.find(shop => String(shop.number) === value)
                                    )

                                    this.addNodesHandler(nodes)
                                }}
                                resetAfterValidation
                                validator={(value: string) => {
                                    const values: string[] = value.split(',')
                                    let correct: string[] = []
                                    let incorrect: string[] = []

                                    values.forEach(value =>
                                        this.props.map.shops.find(shop => String(shop.number) === value)
                                            ? correct.push(value)
                                            : incorrect.push(value)
                                    )

                                    return Promise.resolve({correct, incorrect})
                                }}
                            />
                        )
                    }
                    <Button
                        id={`${id}AddButton`}
                        variant="contained"
                        color="primary"
                        disabled={tree.length === 0}
                        onClick={this.addNodesFromSelection}
                    >
                        {addButtonLabel || t('topology.addToCoverageArea')}
                    </Button>
                </div>
            </div>
        )
    }

    renderFilters = (): ReactNode => {
        const {id, map, use24hFlag} = this.props
        const {selectedFormats, only24h} = this.state

        if (!map || !map.formats || map.formats.length === 0) return null

        return (
            <div className={styles.filtersContainer}>
                <div className={styles.formats}>
                    {
                        map.formats.map(format => {
                            let selected: boolean = selectedFormats.some(selectedFormat =>
                                isSameFormats(format, selectedFormat))
                            return (
                                <Checkbox
                                    checked={selected}
                                    className={styles.checkBox}
                                    onChange={event => this.formatSelectHandler(format, event.target.checked)}
                                    label={format.name}
                                    id={`${id}Format${format.id}`}
                                    key={format.name}
                                />
                            )
                        })
                    }
                </div>
                {use24hFlag && (
                    <Checkbox
                        id={`${id}Only24h`}
                        checked={only24h}
                        label={t('topology.only24h')}
                        onChange={event => this.setState({only24h: event.target.checked})}
                    />
                )}
            </div>
        )
    }

    renderSelection = (): ReactNode => {
        const {id} = this.props
        const {selection} = this.state

        if (!selection || selection.length === 0) return null

        return (
            <div>
                <Divider/>
                <Table
                    id={`${id}AddedNodes`}
                    keyFunction={getHierarchyDescription}
                    items={selection}
                    columns={[
                        {
                            labelFunction: getHierarchyDescription
                        },
                        {
                            width: '100px',
                            padding: '0 10px',
                            hAlign: RIGHT,
                            showOnRowHover: true,
                            renderer: props => {
                                let node: TopologyNode = props.item
                                return (
                                    <AdaptiveIconButton
                                        id={`${id}Node${node.node.id}RemoveButton`}
                                        label={t('common.remove')}
                                        onClick={() => this.removeNodeHandler(node)}
                                    >
                                        <Delete/>
                                    </AdaptiveIconButton>
                                )
                            }
                        }
                    ]}
                />
            </div>
        )
    }

    render() {
        const {className, withPaper, id, showExpandButton} = this.props
        const {expanded, conflictMsg} = this.state

        const Container: ReactType = withPaper ? Paper : 'div'

        return (
            <Container
                id={id}
                className={classNames(className, styles.topologyFilter)}
            >
                <SimpleDialog
                    id={`${id}ConflictAlert`}
                    title={t('topology.topologyNodesAddingConflictTitle')}
                    className={styles.dialog}
                    message={conflictMsg}
                    open={Boolean(conflictMsg)}
                    onOk={() => this.setState({conflictMsg: ''})}
                />

                <div className={styles.header}>
                    <p className={styles.title}>
                        {this.getTitle()}
                    </p>

                    {showExpandButton ? <ExpandButton
                        id={`${id}ExpandButton`}
                        expanded={expanded}
                        onStateChange={this.expandCollapseHandler}
                    /> : <IconButton disabled/>}
                </div>
                {expanded && this.renderTopologyTree()}
                {this.renderSelection()}
            </Container>
        )
    }
}
