import { t } from 'i18next'
import { Cancelable, debounce } from 'lodash'
import { action, observable, runInAction, toJS } from 'mobx'
import uuid from 'uuid'
import { operDayServiceLocal } from '../../../protocol/set10/oper-day-service-local'
import { createOperDayPropertiesVO, OperDayPropertiesVO } from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/oper-day-properties-vo'
import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE } from '../stores'

export class OperdaySettingsStore {

    @observable
    settings: OperDayPropertiesVO = createOperDayPropertiesVO({})

    debouncedSaveSettings: (() => void) & Cancelable = debounce(() => {
        this.saveSettings()
    }, AUTOSAVE_DELAY)

    @observable
    timeInputKey: string = uuid()

    private appStore: AppStore = getStore(APP_STORE)

    fetchSettings = (): Promise<void> => {
        return operDayServiceLocal.getOperDayProperties()
            .then(result => {
                runInAction(() => {
                    this.settings = result
                    this.timeInputKey = uuid()
                })
            })
    }

    @action
    editSettings = (changes: Partial<OperDayPropertiesVO>): void => {
        Object.keys(changes).forEach(k => {
            this.settings[k] = changes[k]
        })

        this.debouncedSaveSettings()
    }

    saveSettings = (): Promise<void> => {
        return operDayServiceLocal.setOperDayProperties(toJS(this.settings))
            .then(result => {
                this.appStore.showSnackbar({message: t('operdaySettings.saved')})
            })
    }

    @action
    reset = (): void => {
        this.settings = createOperDayPropertiesVO({})
        this.timeInputKey = uuid()
    }
}
