import React from 'react'
import { LOYALTY_COUPONS_INSTANCES_STORE } from '../../../../store/stores'
import { inject, observer } from 'mobx-react'
import Paper from '@material-ui/core/Paper'
import { NothingFound } from '../../../../components/stub-with-img/nothing-found'
import { LoyaltyCouponsInstancesStore } from '../../../../store/loyalty/loyalty-coupons-instances-store'
import { t } from 'i18next'
import { BaseTable } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { ReportOrderVO } from '../../../../../protocol/set10/set-retail10-commons/set-report/report-order-vo'
import { getUserShortName } from '../../../../../utils/name-util'
import moment from 'moment'
import { openReport } from '../../../../core/operday/report-utils'
import { last } from 'lodash'
import Button from '@material-ui/core/Button'
import { PageLoader } from '../../../../../components/loaders/page-loader'

export interface LoyaltyCouponsInstancesProps {
    loyaltyCouponsInstancesStore?: LoyaltyCouponsInstancesStore
}

@inject(LOYALTY_COUPONS_INSTANCES_STORE)
@observer
export class LoyaltyCouponsReports extends React.Component<LoyaltyCouponsInstancesProps> {

    componentDidMount() {
        const { fetchAllReports } = this.props.loyaltyCouponsInstancesStore
        fetchAllReports()
    }

    handleOpenReport = (fileName: string): void => {
        const shortFileName = last(fileName.split('/'))

        openReport('getFileStream', null, { 'FILE_NAME': shortFileName })
    }

    render() {
        const {
            allReports
        } = this.props.loyaltyCouponsInstancesStore

        return (
            <Paper style={{ height: '100%' }}>
                {!allReports && (
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <PageLoader/>
                    </div>
                )}
                {allReports?.length === 0 && (
                    <NothingFound/>
                )}
                {allReports?.length > 0 && (
                    <BaseTable
                        items={allReports}
                        fullHeight
                        keyFunction={(item: ReportOrderVO) => item.id}
                        columns={[
                            {
                                header: t('loyaltyCoupons.reportsPage.user'),
                                keyField: 'user',
                                renderer: item => getUserShortName(item.user),
                                padding: '0 0 0 14px',
                            },
                            {
                                header: t('loyaltyCoupons.reportsPage.usedFilters'),
                                keyField: 'usedFilters',
                                renderer: item => item.description,
                            },
                            {
                                columnWidth: 150,
                                header: t('loyaltyCoupons.reportsPage.dateTime'),
                                keyField: 'dateTime',
                                renderer: item => moment(item.startedAt).format('DD.MM.YY HH:mm'),
                            },
                            {
                                columnWidth: 150,
                                header: '',
                                keyField: 'controlBtn',
                                renderer: item => {
                                    const type = item.fileFormat?.toUpperCase()

                                    return (
                                        <Button
                                            color="primary"
                                            onClick={() => this.handleOpenReport(item.fileName)}
                                        >
                                            {t('common.download')}&nbsp;{type}
                                        </Button>
                                    )
                                },
                            },
                        ]}
                    />
                )}
            </Paper>
        )
    }
}
