import * as React from 'react'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { observer, inject } from 'mobx-react'
import { isNil } from 'lodash'
import { RouteComponentProps } from 'react-router'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import AddCircle from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { LEFT, RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { SettingsActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/settings-action-panel'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { NEW } from '../../../core/values'
import { ShopVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { timeZones } from '../../../../utils/time-zones'
import { CityStore } from '../../../store/shops/city-store'
import { DialogStore} from '../../../store/dialog-store'
import { NotSearched } from '../../../components/stub-with-img/not-searched'
import { CITY_STORE, DIALOG_STORE, SHOP_STORE } from '../../../store/stores'
import {ShopStore} from '../../../store/shops/shop-settings/shop-store'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

const styles = require('./city-page.scss')

export interface CityPageURLParams {
    id?: string
}

export interface CityPageProps extends RouteComponentProps<CityPageURLParams>, React.HTMLProps<HTMLDivElement> {
    cityStore?: CityStore
    shopStore?: ShopStore
    dialogStore?: DialogStore
}

@inject(SHOP_STORE)
@inject(CITY_STORE)
@inject(DIALOG_STORE)
@observer
export class CityPage extends React.Component<CityPageProps> {
    componentDidMount() {
        const id: string = this.props.match.params.id
        let {city, goToPreviousPage, openCity} = this.props.cityStore
        if (id === NEW) {
            if (!city || !city.region) {
                goToPreviousPage()
            }
        } else {
            if (!city) openCity(id)
        }
    }

    componentWillUnmount() {
        this.props.cityStore.reset()
    }

    editShop = (shopId: number) => {
        const {validation, saveCity, isNewCity} = this.props.cityStore
        const {openShop} = this.props.shopStore
        if (validation.modified) {
            saveCity(isNewCity)
                .then(() => openShop(shopId))
        } else {
            openShop(shopId)
        }
    }

    addShop = () => {
        const {city, validation, saveCity, isNewCity} = this.props.cityStore
        const {openShop} = this.props.shopStore
        if (validation.modified) {
            saveCity(isNewCity)
                .then(newCity => openShop(NEW, newCity))
        } else {
            openShop(NEW, toJS(city))
        }
    }

    saveCity = () => {
        const {isNewCity, saveCity} = this.props.cityStore
        saveCity(isNewCity)
    }

    showDeletionDialog = (node: ShopVO) => {
        this.props.dialogStore.showDialog({
            title: t('topologyPages.shopDeletionTitle'),
            message: t('topologyPages.shopDeletionMessage', {
                shopName: node.name
            }),
            mode: DIALOG,
            onYes: () => this.props.cityStore.deleteShop(node.id)
        })
    }

    render() {
        const {
            city, shops, updateCurrentCity, goToPreviousPage, validation, isNewCity
        } = this.props.cityStore

        if (!city || !validation) return null

        const {id, name, timeZone} = city

        const nameValidator = validation.getValidationFor('name')

        return (
            <div
                id="cityPage"
                className={styles.cityPage}
            >
                <Paper className={styles.container}>
                    <Grid
                        container
                        spacing={2}
                        className={styles.cityNodeInfo}
                    >
                        <Grid item sm={12} md={6} className={styles.cityNameField}>
                            <TextField
                                id="cityNameField"
                                label={t('topologyPages.cityNameField')}
                                value={name || ''}
                                fullWidth
                                errorText={nameValidator.touched && nameValidator.firstError}
                                onValueChange={name => updateCurrentCity({name})}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} className={styles.cityTimeZone}>
                            <SelectInput
                                id="cityTimeZone"
                                label={t('topologyPages.cityTimeZone')}
                                value={timeZone || ''}
                                fullWidth
                                onSelect={selectedOption => updateCurrentCity({ timeZone: selectedOption.value })}
                                options={timeZones.map(timeZone => (
                                    {
                                        label: timeZone.label,
                                        value: timeZone.value
                                    }
                                ))}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                    <div className={styles.middleSection}>
                        {shops.length > 0 ? (
                            <>
                                <Table
                                    id="shopsList"
                                    className={styles.table}
                                    items={toJS(shops)}
                                    keyFunction={(shop: ShopVO): string => `${shop.id}`}
                                    title={t('topologyPages.shopsTableTitle')}
                                    withHeader
                                    collapsible
                                    onRowDoubleClick={shop => this.editShop(shop.id)}
                                    columns={[
                                        {
                                            header: t('topologyPages.shopsColumnName'),
                                            hAlign: LEFT,
                                            labelField: 'name',
                                            width: '45%',
                                        },
                                        {
                                            header: t('topologyPages.shopsColumnNumber'),
                                            hAlign: RIGHT,
                                            labelField: 'number',
                                            width: '45%',
                                        },
                                        {
                                            renderer: props => {
                                                const shop: ShopVO = props.item
                                                const id: number = shop.id
                                                return (
                                                    <div className={styles.actionButtons}>
                                                        <AdaptiveIconButton
                                                            id={`shop${id}EditButton`}
                                                            label={t('common.edit')}
                                                            onClick={() => this.editShop(shop.id)}
                                                        >
                                                            <EditIcon/>
                                                        </AdaptiveIconButton>
                                                        <AdaptiveIconButton
                                                            id={`shop${id}DeleteButton`}
                                                            label={t('common.remove')}
                                                            onClick={() => this.showDeletionDialog(shop)}
                                                        >
                                                            <DeleteIcon/>
                                                        </AdaptiveIconButton>
                                                    </div>
                                                )
                                            },
                                            hAlign: RIGHT,
                                            padding: '0 10px',
                                            showOnRowHover: true,
                                            withControls: true,
                                            width: '136px'
                                        },
                                    ]}
                                />
                                <Divider className={styles.divider}/>
                            </>
                        ) : (
                            !isNewCity &&
                                <NotSearched
                                    className={styles.stub}
                                    titleText={t('topologyPages.emptyShops')}
                                    hintText=""
                                />
                        )}
                        <div className={styles.addButtonContainer}>
                            <Button
                                id="addShopButton"
                                className={styles.addButton}
                                disabled={!validation.valid}
                                onClick={this.addShop}
                            >
                                <AddCircle
                                    className={styles.icon}
                                    color="primary"
                                />
                                <span>
                                    {t('topologyPages.addShop')}
                                </span>
                            </Button>
                        </div>
                    </div>
                </Paper>
                <SettingsActionPanel
                    id={`city${isNil(id) ? NEW : id}SettingsActionPanel`}
                    saveEnabled={validation.modified && validation.valid}
                    onSave={this.saveCity}
                    onCancel={goToPreviousPage}
                />
            </div>
        )
    }
}
