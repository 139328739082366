import React from 'react'
import { FilterType, CREATED_CHECK } from '../../../../core/filters/filter'
import { InternalFilterComponentProps } from '../../new-filters'
import { t } from 'i18next'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { ComplexReceiptStringValue, getComplexReceiptNumbers } from '../../../../core/filters/coupon-filters'

interface ComplexReceiptBaseProps extends InternalFilterComponentProps {
    filterType: FilterType
}

export class ComplexReceiptBase extends React.PureComponent<ComplexReceiptBaseProps> {

    regExp: RegExp = new RegExp(/^([0-9]+,?[0-9]?)*$/)

    handleValueChange = (changes: ComplexReceiptStringValue): void => {
        const { data, onChange, filterType } = this.props

        const lastValue = data || {}
        let newValue: ComplexReceiptStringValue = {
            ...lastValue,
            ...changes
        }

        const gotValues = !!getComplexReceiptNumbers(newValue)
        onChange(filterType, {
            value: gotValues ? 'true' : '',
            data: newValue
        })
    }

    render() {
        const { id, data, slim } = this.props

        const values: ComplexReceiptStringValue = {
            shops: '',
            cashes: '',
            shifts: '',
            purchases: '',
            ...data
        }

        return (
            <div>
                <TextField
                    id={`${id}_shop`}
                    slim={slim}
                    label={t('shop.shop')}
                    value={values.shops}
                    onValueChange={value => {
                        if (this.regExp.test(value)) {
                            this.handleValueChange({
                                shops: value
                            })
                        }
                    }}
                />
                <TextField
                    id={`${id}_cashes`}
                    slim={slim}
                    label={t('shop.cash')}
                    value={values.cashes}
                    onValueChange={value => {
                        if (this.regExp.test(value)) {
                            this.handleValueChange({
                                cashes: value
                            })
                        }
                    }}
                />
                <TextField
                    id={`${id}_shifts`}
                    slim={slim}
                    label={t('shop.shift')}
                    value={values.shifts}
                    onValueChange={value => {
                        if (this.regExp.test(value)) {
                            this.handleValueChange({
                                shifts: value
                            })
                        }
                    }}
                />
                <TextField
                    id={`${id}_check`}
                    slim={slim}
                    label={t('shop.check')}
                    value={values.purchases}
                    onValueChange={value => {
                        if (this.regExp.test(value)) {
                            this.handleValueChange({
                                purchases: value
                            })
                        }
                    }}
                />
            </div>
        )
    }
}
