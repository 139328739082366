export interface PropertyVO {
    '@class': 'ru.crystals.set10dto.PropertyVO'
    key?: string
    value?: string
}

export const createPropertyVO = (initialization: Partial<PropertyVO>): PropertyVO => {
    if (!initialization) return null
    
    let result: PropertyVO = {
        key: initialization.key,
        value: initialization.value,
        '@class': 'ru.crystals.set10dto.PropertyVO',
    }
    
    return result
}
