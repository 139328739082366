export enum ErpName {
    SetWallet = 'export.wallet',
    ShopEvolution = 'export.shopevolution',
    Giftoman = 'export.giftoman',
    Protocol585 = 'export.585',
    Kesko = 'export.kesko',
    SetRetail5 = 'export.setv5',
    ESB = 'export.esb',
    SetRetail10File = 'export.file',
    SetRetail10WSClient = 'export.set10wsclient',
    SetRetail10WebService = 'export.webservice',
}
