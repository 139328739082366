
// Типы карт

export const InternalCards = 'InternalCardsVO'
export const PresentCards = 'PresentCardsVO'

// Статус карты
export type CardStatus = 'Create' | 'Active' | 'Blocked' | 'Inactive' | 'Used' |
    'PreActive' | 'PreUsed' | 'PreDeactive' | 'NeedReturn' | 'NeedTakeAway'

export const Create: CardStatus = 'Create'
export const Active: CardStatus = 'Active'
export const Blocked: CardStatus = 'Blocked'
export const Inactive: CardStatus = 'Inactive'
export const Used: CardStatus = 'Used'
export const PreActive: CardStatus = 'PreActive'
export const PreUsed: CardStatus = 'PreUsed'
export const PreDeactive: CardStatus = 'PreDeactive'
export const NeedReturn: CardStatus = 'NeedReturn'
export const NeedTakeAway: CardStatus = 'NeedTakeAway'

// Пол
export type Sex = 'Male' | 'Female' | 'Not_Specified'

export const Male: Sex = 'Male'
export const Female: Sex = 'Female'
export const NotSpecified: Sex = 'Not_Specified'

// Уведомление клиента о покупке

export enum ReceiptFeedbackTypes {
    None = 'NONE',
    ByPhone = 'BY_PHONE',
    ByMail = 'BY_EMAIL'
}

// Тип смартфона

export enum SmartphoneTypes {
    IOS = 'IOS',
    Android = 'ANDROID',
    Samsung = 'SAMSUNG'
}

// BonusHistory - transaction type

export enum TransactionTypes {
    CHARGE = 'CHARGE',
    CHARGE_PAYMENT_RETURN = 'CHARGE_PAYMENT_RETURN',
    ACTIVATING = 'ACTIVATING',
    BLOCKING = 'BLOCKING',
    UNLOCKING = 'UNLOCKING',
    CANCELING = 'CANCELING',
    WRITE_OFF = 'WRITE_OFF',
    WRITE_OFF_BY_RETURN = 'WRITE_OFF_BY_RETURN',
    WRITE_OFF_BLOCKED = 'WRITE_OFF_BLOCKED'
}

// Card Operations

export enum OperationSource {
    UNKNOWN = 'UNKNOWN',
    CASH = 'CASH',
    SERVER = 'SERVER',
    ERP = 'ERP'
}

// Card Actions

export enum CardActions {
    Create = 'Create',
    Activate = 'Activate',
    Blocked = 'Blocked',
    Used = 'Used',
    Changed = 'Changed',
    Inactive = 'Inactive',
    UNDO_USE = 'UNDO_USE',
    EXTEND = 'EXTEND',
    PARTIAL_USE = 'PARTIAL_USE',
}

// Причина блокировки карты

export enum CardBlockingReason {
    ANOTHER = 'ANOTHER',
    LOSS = 'LOSS',
    REFUSAL = 'REFUSAL',
    ROBBERY = 'ROBBERY',
    CALL_SECURITY = 'CALL_SECURITY'
}
