import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import { PRODUCT_DETAILS_STORE } from '../../../store/stores'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { ProductGroupVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/product-group-vo-sf'
import { NavigateNext } from '@material-ui/icons'

const styles = require('./product-groups-dialog.scss')

interface ProductGroupsDialogProps {
    productDetailsStore?: ProductDetailsStore
    onClose: () => void
}

@inject(PRODUCT_DETAILS_STORE)
@observer
export class ProductGroupsDialog extends React.Component<ProductGroupsDialogProps> {
    render() {
        const { onClose } = this.props
        const { productDetails } = this.props.productDetailsStore

        // Наполняем группы в обратном порядке
        let groups: string[] = []
        const fillGroups = (group: ProductGroupVO_SF) => {
            groups.push(group.name)
            if (group.parentGroup) {
                fillGroups(group.parentGroup)
            }
        }
        fillGroups(productDetails.group)
        groups.reverse()

        const length = groups.length

        return (
            <Dialog
                id="productGroupsDialog"
                open
                onClose={onClose}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>
                    {t('productDetails.generalInfo.productGroup')}
                </DialogTitle>
                <DialogContent className={styles.breadCrumbContainer}>
                    {groups.map((label: string, index: number) => {
                        const lastIndex = (index + 1) === length
                        return (
                            <Fragment key={index}>
                                <div
                                    className={styles.breadcrumbText}
                                >
                                    {label}
                                </div>
                                {!lastIndex && (
                                    <NavigateNext
                                        fontSize="small"
                                        className={styles.breadcrumbArrow}
                                    />
                                )}
                            </Fragment>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="primary"
                        id="dialogOkButton"
                    >
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
