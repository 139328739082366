import { parseString, Builder as XMLBuilder } from 'xml2js'
import moment from 'moment'
import { t } from 'i18next'
import { AdvertisingAction } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { formatTimeUnitsInto2Digit } from '../../../utils/date-util'
import {
    MANUAL,
    UNCONDITIONAL,
    AUTOMATIC,
    BY_CONDITION,
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/apply-mode'
import { getPurchaseAmountChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/bill/purchase-amount-editor'
import { getExternalPaymentCodeChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/bill/external-payment-editor'
import { getProductCountChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/bill/product-count-in-bill-editor'
import { getMasterActionsChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/common/master-actions-editor'
import { getActionResultsQuantityChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/common/action-results-quantity-editor'
import { getCountersTriggersChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/common/counter-editor'
import { getExternalCouponChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/client/external-coupon-editor'
import { getExternalSystemChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/common/external-system-editor'
import {
    andTagData,
    leftBracketTagData,
    notTagData,
    orTagData,
    rightBracketTagData,
    SegmentFilters,
} from '../../pages/loyalty/actions/action-edit/condition-editors/client/segment/segment-utils'
import { isEqual, last } from 'lodash'
import { getWeekDays } from '../../pages/loyalty/actions/action-edit/condition-editors/time/days-of-week-editor'
import { getStore } from '../../store/stores-repository'
import { ActionSettingsStore } from '../../store/actions/action-settings-store'
import { CashiersSearchStore } from '../../store/staff/cashiers-search-store'
import { ACTION_SETTINGS_STORE, PAYMENT_TYPES_STORE, CASHIERS_SEARCH_STORE } from '../../store/stores'
import get from 'lodash/get'
import { PaymentTypesStore, getPaymentTypeId } from '../../store/cashes/payment-types/payment-types-store'
import { ClientRestriction } from './advertising-actions'
import { getDiscountSumChipLabel } from '../../pages/loyalty/actions/action-edit/condition-editors/common/discount-sum-editor'

export enum ConditionFields {
    ActionHours = 'ru.crystalservice.setv6.discounts.plugins.TimePeriod',
    ActionResultNotification = 'ru.crystalservice.setv6.discounts.plugins.ActionResultNotification',
    ActionResultsCondition = 'ru.crystalservice.setv6.discounts.plugins.ActionResultsQuantityCondition',
    AgeInterval = 'ru.crystalservice.setv6.discounts.plugins.AgeInterval',
    CashesNumbers = 'ru.crystalservice.setv6.discounts.plugins.CashNumCondition',
    Category = 'ru.crystalservice.setv6.discounts.plugins.Category',
    CompositeRow = 'ru.crystalservice.setv6.discounts.plugins.CompositeRow',
    CounterCondition = 'ru.crystalservice.setv6.discounts.plugins.CounterCondition',
    CounterConditionUnit = 'ru.crystalservice.setv6.discounts.plugins.CounterConditionUnit',
    Country = 'ru.crystalservice.setv6.discounts.plugins.Country',
    DatePeriod = 'ru.crystals.discounts.DatePeriod',
    Depart = 'ru.crystalservice.setv6.discounts.plugins.Depart',
    ExternalPaymentType = 'ru.crystalservice.setv6.discounts.plugins.ExternalPaymentType',
    FullName = 'ru.crystalservice.setv6.discounts.plugins.FullName',
    Group = 'ru.crystalservice.setv6.discounts.plugins.Group',
    Manufacturer = 'ru.crystalservice.setv6.discounts.plugins.Manufacturer',
    ProductCountConditionUnit = 'ru.crystalservice.setv6.discounts.plugins.ProductCountConditionUnit',
    QuantityBasedPreferencesCalculator = 'ru.crystalservice.setv6.discounts.plugins.QuantityBasedPreferencesCalculator',
    ReceiptSumPreferencesCalculator = 'ru.crystalservice.setv6.discounts.plugins.ReceiptSumPreferencesCalculator',
    RootPreferencesCalculator = 'ru.crystalservice.setv6.discounts.plugins.RootPreferencesCalculator',
    SaleGroup = 'ru.crystalservice.setv6.discounts.plugins.SaleGroup',
    SetApiCardsProcessing = 'ru.crystalservice.setv6.discounts.plugins.SetApiCardsProcessing',
    SumBasedPreferencesCalculator = 'ru.crystalservice.setv6.discounts.plugins.SumBasedPreferencesCalculator',
}

export type ConditionDataList<F extends ConditionFields, D> = Array<{ [f in F]: D[] }>

export enum ClientConditions {
    CashNumCondition = 'ru.crystalservice.setv6.discounts.plugins.CashNumCondition',
    ClientGuidCondition = 'ru.crystalservice.setv6.discounts.plugins.ClientGuidCondition',
    InternalCardsCondition = 'ru.crystalservice.setv6.discounts.plugins.InternalCardsCondition',
    CouponsCondition = 'ru.crystalservice.setv6.discounts.plugins.CouponsCondition',
    BirthdayCondition = 'ru.crystalservice.setv6.discounts.plugins.BirthdayCondition',
    AgeCondition = 'ru.crystalservice.setv6.discounts.plugins.AgeCondition',
    CompletedProfileCondition = 'ru.crystalservice.setv6.discounts.plugins.CompletedProfileCondition',
    SexCondition = 'ru.crystalservice.setv6.discounts.plugins.SexCondition',
    NameCondition = 'ru.crystalservice.setv6.discounts.plugins.NameCondition',
    ClientAddressCondition = 'ru.crystalservice.setv6.discounts.plugins.ClientAddressCondition',
    BirthdayRangeCondition = 'ru.crystalservice.setv6.discounts.plugins.BirthdayRangeCondition',
    ExternalCardsCondition = 'ru.crystalservice.setv6.discounts.plugins.ExternalCardsCondition',
    ExternalCouponCondition = 'ru.crystalservice.setv6.discounts.plugins.ExternalCouponCondition',
    SegmentFilterCondition = 'ru.crystalservice.setv6.discounts.plugins.SegmentFilterCondition',
}

export enum ProductConditions {
    CountryCondition = 'ru.crystalservice.setv6.discounts.plugins.CountryCondition',
    DepartCondition = 'ru.crystalservice.setv6.discounts.plugins.DepartCondition',
    GoodsCountCondition = 'ru.crystalservice.setv6.discounts.plugins.GoodsCountCondition',
    GroupCondition = 'ru.crystalservice.setv6.discounts.plugins.GroupCondition',
    ManufacturerCondition = 'ru.crystalservice.setv6.discounts.plugins.ManufacturerCondition',
    SaleGroupCondition = 'ru.crystalservice.setv6.discounts.plugins.SaleGroupCondition',
}

export enum TopologyConditions {
    Main = 'ru.crystals.discounts.TopologyCondition',
    ShopsCityGroups = 'ru.crystals.discounts.ShopsCityGroup',
    ShopsRegionGroups = 'ru.crystals.discounts.ShopsRegionGroup',
}

export enum ComparisonType {
    Less = 'LESS',
    More = 'MORE',
    Equal = 'EQUAL',
    Between = 'BETWEEN',
    Multiply = 'MULTIPLY',
}

export enum CounterMetricsType {
    Checks = 'CHEQUES',
    ActiveDays = 'ACTIVE_DAYS',
    Products = 'PRODUCTS',
}

export enum CounterIndicatorType {
    Sum = 'SUMM',
    Count = 'COUNT',
}

export enum CounterPeriodUnitType {
    Hours = 'HOURS',
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS',
}

export enum PeriodUnitType {
    Days = 'DAYS',
    Weeks = 'WEEKS',
    Months = 'MONTHS',
}

export interface SimpleConditionData {
    name: string[]
    code: string[]
    count: string[]
    typeOfComparison: ComparisonType[]
}

export type CountryData = SimpleConditionData

export interface ProductCountryCondition {
    conditions: ConditionDataList<ConditionFields.Country, CountryData>
}

export type ManufacturerData = SimpleConditionData

export interface ProductManufacturerCondition {
    conditions: ConditionDataList<ConditionFields.Manufacturer, ManufacturerData>
}

export interface DepartData {
    name: string[]
    number: string[]
    count: string[]
    typeOfComparison: ComparisonType[]
}

export interface ProductDepartCondition {
    conditions: ConditionDataList<ConditionFields.Depart, DepartData>
}

export interface SaleGroupConditionData {
    amount: string[]
    code: string[]
    count: string[]
    name: string[]
    typeOfComparison: ComparisonType[]
    typeOfSumComparison: ComparisonType[]
    uniqueGoods: string[]
}

export interface ProductSaleGroupCondition {
    conditions: ConditionDataList<ConditionFields.SaleGroup, SaleGroupConditionData>
    withOr: string[]
}

export interface GroupConditionData {
    amount: string[]
    code: string[]
    count: string[]
    name: string[]
    typeOfComparison: ComparisonType[]
    typeOfSumComparison: ComparisonType[]
    uniqueGoods: string[]
}

export interface CashesNumbersData {
    includes: string[]
    excludes: string[]
}

export interface CommonCashesNumbersCondition {
    type: CommonConditions.CashNumCondition,
    data: CashesNumbersData
}

export interface ProductGroupCondition {
    conditions: ConditionDataList<ConditionFields.Group, GroupConditionData>
    withOr: string[]
}

export interface ActionHoursConditionData {
    hourStart: string[]
    minuteStart: string[]
    secondStart: string[]
    hourEnd: string[]
    minuteEnd: string[]
    secondEnd: string[]
}

export interface ActionHoursCondition {
    type: TimeConditions.HoursActionCondition
    data: {
        timePeriods: [{
            [ConditionFields.ActionHours]: ActionHoursConditionData[]
        }]
    }
}

export interface ActionResultsQuantity {
    actionRestriction: Array<{
        maxValue: string[]
    }>
    clientRestriction: Array<{
        periodType: PeriodUnitType[]
        maxValue: string[]
        allPeriodOfAction: string[]
        period: string[]
    }>
    allowOffline: string[]
}

export interface DiscountSumCondition {
    clientRestriction: ClientRestriction[],
    allowOffline: string[],
}

export enum BillConditions {
    ExternalPaymentTypeCondition = 'ru.crystalservice.setv6.discounts.plugins.ExternalPaymentTypeCondition',
    PositionCountCondition = 'ru.crystalservice.setv6.discounts.plugins.PositionCountCondition',
    ProductCountCondition = 'ru.crystalservice.setv6.discounts.plugins.ProductCountCondition',
    PurchaseAmountCondition = 'ru.crystalservice.setv6.discounts.plugins.PurchaseAmountCondition',
    PaymentTypeCondition = 'ru.crystalservice.setv6.discounts.plugins.PaymentTypeCondition',
    BankLoyaltyCodeCondition = 'ru.crystalservice.setv6.discounts.plugins.BankLoyaltyCodeCondition',
}

export enum TimeConditions {
    DaysOfTheWeekCondition = 'ru.crystalservice.setv6.discounts.plugins.DaysOfTheWeekCondition',
    HoursActionCondition = 'ru.crystalservice.setv6.discounts.plugins.HoursActionCondition',
}

export enum CommonConditions {
    MasterActions = 'masterActions',
    ExternalSystemResolutionCondition = 'ru.crystalservice.setv6.discounts.plugins.ExternalSystemResolutionCondition',
    CashNumCondition = 'ru.crystalservice.setv6.discounts.plugins.CashNumCondition',
    CashTemplates = 'ru.crystalservice.setv6.discounts.plugins.CashTemplateCondition',
    CashierCondition = 'ru.crystalservice.setv6.discounts.plugins.CashierCondition',
    ExternalSystemChooseCondition = 'ru.crystalservice.setv6.discounts.plugins.ExternalSystemChooseCondition',
}

export enum CountersAndLimitsConditions {
    ActionResultsQuantityCondition = 'ru.crystalservice.setv6.discounts.plugins.ActionResultsQuantityCondition',
    CounterCondition = 'ru.crystalservice.setv6.discounts.plugins.CounterCondition',
    DiscountSumCondition = 'ru.crystalservice.setv6.discounts.plugins.DiscountSumCondition',
}

export enum SexType {
    Male = 'MALE',
    Female = 'FEMALE',
}

export enum TagType {
    Operation = 'OPERATION',
    Segment = 'SEGMENT',
}

export type ConditionType =
    | ClientConditions
    | SegmentFilters
    | ProductConditions
    | BillConditions
    | TimeConditions
    | CommonConditions
    | CountersAndLimitsConditions

const formatComparisonTerm = (value: string): string => {
    switch (value) {
        case ComparisonType.Equal:
            return t('advertisingActions.comparisonEqual')
        case ComparisonType.More:
            return t('advertisingActions.comparisonMoreOrEqual')
        case ComparisonType.Less:
            return t('advertisingActions.comparisonLess')
    }
}

export const getSelectedCashTemplates = (condition: Condition): string[] => {
    return get(condition, 'data.cashTemplates[0].long', null)
}

// TODO: Прописать типы, которым нужно форматирование сравнения "больше/меньше"
export type ComparisonConditionData = SimpleConditionData & SaleGroupConditionData

export const formatComparison = (field: string, divider: number, anyMsg: string) => (
    data: Partial<ComparisonConditionData>
): string => {
    const typeOfComparison = data.typeOfComparison[0]
    const value: string = data[field][0]
    if (value === '0' && typeOfComparison === ComparisonType.More) {
        return t(anyMsg)
    } else {
        return `${formatComparisonTerm(typeOfComparison)} ${+value / divider}`
    }
}

export const formatCount = formatComparison('count', 1000, 'advertisingActions.anyCount')

export const formatAmount = formatComparison('amount', 100, 'advertisingActions.anyAmount')

export const formatName = (data: SimpleConditionData) => data.name[0]

export const formatGroupCondition = (key: ConditionFields, prefix: string, data: any) => {
    const groups = data.conditions[0][key].filter(f => f.name[0] !== '')
    const chunks = groups.map(group => {
        const typeOfComparison = group.typeOfComparison[0]
        if (typeOfComparison === ComparisonType.More && group.count[0] === '0') {
            return t('advertisingActions.anyCountChunk', { name: group.name[0] })
        } else {
            const countTerm = formatComparisonTerm(typeOfComparison)
            const sumTerm = formatComparisonTerm(group.typeOfSumComparison[0])
            return t('advertisingActions.groupChunk', {
                name: group.name[0],
                countTerm,
                count: +group.count[0] / 1000,
                uniqueGoods:
                    group.uniqueGoods[0] === 'true' ? ` ${t('advertisingActions.uniqueGoodsChunk')},` : '',
                sumTerm,
                amount: +group.amount[0] / 100,
            })
        }
    })

    const withOrValue = data?.withOr?.[0] || 'true'

    return t(prefix, {
        groups: chunks.join(withOrValue === 'true' ? ` ${t('advertisingActions.orDivider')} ` : ', '),
    })
}

export const parseConditions = async (action: AdvertisingAction): Promise<Condition[]> => {
    const externalConditions = await Promise.all(
        action.externalConditions?.map(cond => {
            return new Promise((resolve, reject) => {
                parseString(cond, (err, res) => (err ? reject(err) : resolve(res)))
            })
        })
    )
    return externalConditions.reduce<Condition[]>((acc, cond) => {
        const types = Object.keys(cond) as ConditionType[]
        const values = types.map(type => ({ type, data: cond[type] }))
        return acc.concat(values)
    }, [])
}

export const serializeConditions = (conditions: Condition[]) => {
    return conditions.map(cond => {
        const xmlBuilder = new XMLBuilder({ headless: true, rootName: cond.type })
        return xmlBuilder.buildObject(cond.data)
    })
}

export const findCondition = (type: ConditionType, conditions: Condition[]): Condition => {
    return conditions.find(cond => cond.type === type)
}

export const updateCondition = <T = any>(
    type: ConditionType,
    data: Partial<T>,
    conditions: Condition[]
): Condition[] => {
    const prevCond = findCondition(type, conditions)
    return prevCond
        ? conditions.map(cond => (cond === prevCond ? { ...cond, data } : cond))
        : conditions.concat({ type, data })
}

export const removeCondition = (type: ConditionType, conditions: Condition[]): Condition[] => {
    return conditions.filter(cond => cond.type !== type)
}

export const getComparisonOpts = (): Array<{ value: ComparisonType; label: string }> => [
    {
        value: ComparisonType.More,
        label: t('advertisingActions.compareMoreOrEqual'),
    },
    {
        value: ComparisonType.Less,
        label: t('advertisingActions.compareLess'),
    },
    {
        value: ComparisonType.Equal,
        label: t('advertisingActions.compareEqual'),
    },
]

export const getComparisonMoreLessOpts = (): Array<{ value: ComparisonType, label: string }> => [
    {
        value: ComparisonType.More,
        label: t('advertisingActions.compareMoreOrEqual'),
    },
    {
        value: ComparisonType.Less,
        label: t('advertisingActions.compareLess'),
    },
]

export const getAdvancedComparisonOpts = (): Array<{ value: ComparisonType, label: string }> => [
    {
        value: ComparisonType.More,
        label: t('advertisingActions.compareMore'),
    },
    {
        value: ComparisonType.Less,
        label: t('advertisingActions.compareLess'),
    },
    {
        value: ComparisonType.Equal,
        label: t('advertisingActions.compareEqual'),
    },
    {
        value: ComparisonType.Multiply,
        label: t('advertisingActions.checkConditions.multipleLabel'),
    },
    {
        value: ComparisonType.Between,
        label: t('advertisingActions.compareBetween'),
    },
]

export const getCounterMetricsOpts = (): Array<{ value: CounterMetricsType; label: string }> => [
    {
        value: CounterMetricsType.Checks,
        label: t('advertisingActions.counterMetrics.checks'),
    },
    {
        value: CounterMetricsType.ActiveDays,
        label: t('advertisingActions.counterMetrics.activeDays'),
    },
    {
        value: CounterMetricsType.Products,
        label: t('advertisingActions.counterMetrics.products'),
    },
]

export const getCounterIndicatorOpts = (): Array<{ value: CounterIndicatorType; label: string }> => [
    {
        value: CounterIndicatorType.Count,
        label: t('advertisingActions.counterMetrics.count'),
    },
    {
        value: CounterIndicatorType.Sum,
        label: t('advertisingActions.counterMetrics.sum'),
    },
]

export const getCounterPeriodUnitsOpts = (): Array<{ value: CounterPeriodUnitType; label: string }> => [
    {
        value: CounterPeriodUnitType.Hours,
        label: t('advertisingActions.counterMetricsUnits.hours'),
    },
    {
        value: CounterPeriodUnitType.Days,
        label: t('advertisingActions.counterMetricsUnits.days'),
    },
    {
        value: CounterPeriodUnitType.Weeks,
        label: t('advertisingActions.counterMetricsUnits.weeks'),
    },
    {
        value: CounterPeriodUnitType.Months,
        label: t('advertisingActions.counterMetricsUnits.months'),
    },
]

export const getActionResultsQuantityPeriodUnitsOpts = (count: number): Array<{ value: PeriodUnitType; label: string }> => [
    {
        value: PeriodUnitType.Days,
        label: t('advertisingActions.countersAndLimitsCond.days', { count }),
    },
    {
        value: PeriodUnitType.Weeks,
        label: t('advertisingActions.countersAndLimitsCond.weeks', { count }),
    },
    {
        value: PeriodUnitType.Months,
        label: t('advertisingActions.countersAndLimitsCond.months', { count }),
    },
]

export const getPeriodUnitLabel = (type: PeriodUnitType, count: number): string => {
    switch (type) {
        case PeriodUnitType.Days:
            return t('advertisingActions.countersAndLimitsCond.days', { count })
        case PeriodUnitType.Weeks:
            return t('advertisingActions.countersAndLimitsCond.weeks', { count })
        case PeriodUnitType.Months:
            return t('advertisingActions.countersAndLimitsCond.months', { count })
        default:
            return ''
    }
}

export const getConditionsModeOptions = () => [
    { value: MANUAL, label: t('advertisingActions.conditionsMode.MANUAL') },
    { value: UNCONDITIONAL, label: t('advertisingActions.conditionsMode.UNCONDITIONAL') },
    { value: AUTOMATIC, label: t('advertisingActions.conditionsMode.AUTOMATIC') },
    { value: BY_CONDITION, label: t('advertisingActions.conditionsMode.BY_CONDITION') },
]

export const getOperationOptions = () => {
    return [
        {
            name: t('advertisingActions.segments.and'),
            value: andTagData,
        },
        {
            name: t('advertisingActions.segments.or'),
            value: orTagData,
        },
        {
            name: t('advertisingActions.segments.not'),
            value: notTagData,
        },
        {
            name: '(',
            value: leftBracketTagData,
        },
        {
            name: ')',
            value: rightBracketTagData,
        },
    ]
}

export const isSelectedDay = (day: string) => day === 'true'

export const toSelectedDays = (daysOf: string[]) => {
    const result = []

    daysOf.map((day, index) => {
        if (!isSelectedDay(day)) {
            return
        }
        result.push(index)
    })
    return result
}

// --- Определение текста чипов

export type ConditionData = any

export interface Condition {
    type: ConditionType
    data: ConditionData
}

export type ConditionFormatter = (condition: Condition, args?: any) => string | Promise<string>

export interface ConditionFormatters {
    [k: string]: ConditionFormatter
}

export const conditionFormatters: ConditionFormatters = {
    [ClientConditions.ClientGuidCondition]: ({ data, type }: Condition) => {
        return data.condition[0] === 'INCLUDE'
            ? t('advertisingActions.client.guidChipFor', { guid: data.suffixes[0].String.join(', ') })
            : t('advertisingActions.client.guidChipExclude', { guid: data.suffixes[0].String.join(', ') })
    },
    [ClientConditions.InternalCardsCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.client.internalCard')} ${
            !data.categories[0]
                ? t('advertisingActions.client.notUsedF')
                : data.categories[0][ConditionFields.Category].map(cat => cat.name.join(', ')).join(', ')
        }`
    },
    [ClientConditions.CouponsCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.client.coupon')} ${
            data.categories[0]
                ? data.categories[0][ConditionFields.Category].map(cat => cat.name.join(', ')).join(', ')
                : data.couponNumber[0]
                ? data.couponNumber[0]
                : t('advertisingActions.client.notUsedM')
        }`
    },
    [ClientConditions.BirthdayCondition]: ({ data, type }: Condition) => {
        return data.daysBefore === '0' && data.daysAfter === '0'
            ? t('advertisingActions.client.birthday')
            : `${t('advertisingActions.client.beforeBirthday', {
                  count: Number(data.daysBefore),
              })}${t('advertisingActions.client.afterBirthday', { count: Number(data.daysAfter) })}`
    },
    [CommonConditions.ExternalSystemChooseCondition]: ({ data, type }: Condition) => {
        return t('advertisingActions.client.sap')
    },
    [ClientConditions.CompletedProfileCondition]: ({ data, type }: Condition) => {
        return data.expectedProfileStatus[0] === 'true'
            ? t('advertisingActions.client.profileFull')
            : t('advertisingActions.client.profileNotFull')
    },
    [ClientConditions.SexCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.client.sex')} ${
            data.sex[0] === SexType.Female
                ? t('advertisingActions.client.female')
                : t('advertisingActions.client.male')
        }`
    },
    [ClientConditions.NameCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.client.buyerName')} ${data.fullNames[0][ConditionFields.FullName]
            .map(name => `${name.firstName || ''}${` ${name.patronym}` || ''}${` ${name.lastName}` || ''}`)
            .join(', ')}`
    },
    [ClientConditions.ClientAddressCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.client.address')} ${data.district[0]}, ${data.street[0]}`
    },
    [ClientConditions.BirthdayRangeCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.client.birthdayRange')} ${data.dateRanges[0][
            ConditionFields.DatePeriod
        ].map(
            ({ start, finish }) =>
                `${t('advertisingActions.client.from')} ${moment(new Date(start[0])).format('DD.MM')}
                ${t('advertisingActions.client.to')} ${moment(new Date(finish[0])).format('DD.MM')}`
        )}`
    },
    [ClientConditions.ExternalCardsCondition]: ({ data, type }: Condition) => {
        const cards = data.categories[0] ?
            data.categories[0][ConditionFields.Category] :
            []

        const setApiCardsProcessings = data.setApiCardsProcessings[0] ?
            data.setApiCardsProcessings[0][ConditionFields.SetApiCardsProcessing] :
            []

        const cardNames = cards
            .map(card => card.name[0])
            .concat(
                setApiCardsProcessings
                    .map(apiCard => apiCard.friendlyName)
            )

        return `${t('advertisingActions.client.externalCard')} ${
            !cardNames.length
                ? t('advertisingActions.client.noCard')
                : cardNames.join(', ')
        }`
    },
    [ClientConditions.ExternalCouponCondition]: ({ data, type }: Condition) => {
        return getExternalCouponChipLabel(data)
    },
    [ClientConditions.AgeCondition]: ({ data, type }: Condition) => {
        const { begin, end } = data.intervals[0][ConditionFields.AgeInterval][0]
        return t('advertisingActions.client.age', { begin: begin[0], end: end[0] })
    },
    [BillConditions.PaymentTypeCondition]: async ({ data, type }: Condition) => {
        const paymentTypesStore = getStore<PaymentTypesStore>(PAYMENT_TYPES_STORE)
        await paymentTypesStore.fetchPaymentTypes()

        const payment = paymentTypesStore.paymentTypes.find(item => {
            return getPaymentTypeId(item) === data.paymentType[0]
        })
        if (!payment) {
            return t('advertisingActions.client.payment')
        }
        return `${t('advertisingActions.client.payment')}: ${payment.info}`
    },
    [BillConditions.BankLoyaltyCodeCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.checkConditions.bankLoyaltyCode')}: ${data.loyalCodes[0]}`
    },
    [ClientConditions.SegmentFilterCondition]: ({ data, type }: Condition) => {
        return `${t('advertisingActions.segmentCombination')}: ${data
            .map(item => {
                if (item.tagType === TagType.Operation) {
                    const operationOption = getOperationOptions().find(
                        operation => operation.value.operationType === item.operationType
                    )
                    return operationOption && operationOption.name
                }
                if (item.tagType === TagType.Segment) {
                    return `"${item.segment && item.segment.name}"`
                }
            })
            .join(' ')}`
    },
    [ProductConditions.CountryCondition]: ({ data, type }: Condition) => {
        const countries = (data as ProductCountryCondition).conditions[0][ConditionFields.Country]
        const countryChunks = countries.map(country => {
            if (country.count[0] === '0' && country.typeOfComparison[0] === ComparisonType.More) {
                return `${country.name[0]} ${t('advertisingActions.product.anyAmount')}`
            } else {
                const typeOfComparison = country.typeOfComparison[0]
                const term =
                    typeOfComparison === ComparisonType.Equal
                        ? t('advertisingActions.comparisonEqual')
                        : typeOfComparison === ComparisonType.More
                        ? t('advertisingActions.comparisonMore')
                        : t('advertisingActions.comparisonLess')
                return `${country.name[0]} - ${term} ${+country.count[0] / 1000}`
            }
        })
        return `${t('advertisingActions.product.country')} ${countryChunks.join(', ')}`
    },
    [ProductConditions.ManufacturerCondition]: ({ data, type }: Condition) => {
        const manufacturers = (data as ProductManufacturerCondition).conditions[0][
            ConditionFields.Manufacturer
        ].filter(f => f.name[0] !== '')
        const manufacturerChunks = manufacturers.map(manufacturer => {
            if (manufacturer.count[0] === '0' && manufacturer.typeOfComparison[0] === ComparisonType.More) {
                return `${manufacturer.name[0]} ${t('advertisingActions.product.anyAmount')}`
            } else {
                const typeOfComparison = manufacturer.typeOfComparison[0]
                const term =
                    typeOfComparison === ComparisonType.Equal
                        ? t('advertisingActions.comparisonEqual')
                        : typeOfComparison === ComparisonType.More
                        ? t('advertisingActions.comparisonMore')
                        : t('advertisingActions.comparisonLess')
                return `${manufacturer.name[0]} - ${term} ${+manufacturer.count[0] / 1000}`
            }
        })
        return `${t('advertisingActions.product.manufacturer')} ${manufacturerChunks.join(', ')}`
    },
    [ProductConditions.DepartCondition]: ({ data, type }: Condition) => {
        const departs = (data as ProductDepartCondition).conditions[0][ConditionFields.Depart].filter(
            f => f.name[0] !== ''
        )
        const departChunks = departs.map(depart => {
            if (depart.count[0] === '0' && depart.typeOfComparison[0] === ComparisonType.More) {
                return `${depart.name[0]} ${t('advertisingActions.product.anyAmount')}`
            } else {
                const typeOfComparison = depart.typeOfComparison[0]
                const term =
                    typeOfComparison === ComparisonType.Equal
                        ? t('advertisingActions.comparisonEqual')
                        : typeOfComparison === ComparisonType.More
                        ? t('advertisingActions.comparisonMore')
                        : t('advertisingActions.comparisonLess')
                return `${depart.name[0]} - ${term} ${+depart.count[0] / 1000}`
            }
        })
        return `${t('advertisingActions.product.department')} ${departChunks.join(', ')}`
    },
    [ProductConditions.SaleGroupCondition]: ({ data, type }: Condition) => {
        return formatGroupCondition(ConditionFields.SaleGroup, 'advertisingActions.saleGroupChip', data)
    },
    [ProductConditions.GroupCondition]: ({ data, type }: Condition) => {
        return formatGroupCondition(ConditionFields.Group, 'advertisingActions.productGroupChip', data)
    },
    [ProductConditions.GoodsCountCondition]: ({ data, type }: Condition) => {
        const cond = formatComparisonTerm(data.typeOfComparison[0])
        return t('advertisingActions.productsCountLabel', {
            cond,
            count: data?.count?.[0] ? Number(data.count[0]) / 1000 : 0,
            rang: data?.rang?.[0] ? Number(data.rang[0]) / 1000 : 0,
        })
    },
    [BillConditions.PurchaseAmountCondition]: ({ data, type }: Condition) => {
        return getPurchaseAmountChipLabel(data)
    },
    [BillConditions.ExternalPaymentTypeCondition]: ({ data, type }: Condition, currency: string) => {
        return getExternalPaymentCodeChipLabel(data, currency)
    },
    [BillConditions.ProductCountCondition]: ({ data, type }: Condition) => {
        return getProductCountChipLabel(data)
    },
    [BillConditions.PositionCountCondition]: ({ data, type }: Condition) => {
        const condition = formatComparisonTerm(data.typeOfComparison[0])
        return `${t('advertisingActions.checkConditions.numberOfPositionsChip')} ${condition} ${
            data.count[0] ? data.count[0] : ''
        }`
    },
    [CommonConditions.MasterActions]: ({ data, type }: Condition) => {
        return getMasterActionsChipLabel(data)
    },
    [CommonConditions.ExternalSystemResolutionCondition]: ({ data, type }: Condition) => {
        return getExternalSystemChipLabel(data)
    },
    [CommonConditions.CashierCondition]: ({ data, type }: Condition) => {
        const { roles } = getStore<CashiersSearchStore>(CASHIERS_SEARCH_STORE)
        return data.cashierRoles[0].long.map(guid => {
            const cashierRole = roles.find(
                cashierRole => cashierRole.guid === +guid
            )

            return cashierRole.name
        })
        .join(', ')
    },
    [CommonConditions.CashNumCondition]: ({ data, type }: Condition) => {
        const { includes, excludes } = data

        if (includes[0]) {
            return `${t('advertisingActions.conditionLabels.onlyOnSelectedCashesLabel')}: ${
                includes[0]
            }`
        } else {
            return `${t('advertisingActions.conditionLabels.exceptSelectedCashesLabel')}: ${
                excludes[0]
            }`
        }
    },
    [CommonConditions.CashTemplates]: ({ data, type }: Condition) => {
        const { cashTemplates } = getStore<ActionSettingsStore>(ACTION_SETTINGS_STORE)
        const selectedTemplates = getSelectedCashTemplates({ data, type: null })

        let chipText = `${t('advertisingActions.common.cashTemplates')}`

        if (selectedTemplates && selectedTemplates.length) {
            chipText +=
                ': ' +
                selectedTemplates
                    .map(templateId => {
                        const cashTemplate = cashTemplates.find(
                            cashTemplate => cashTemplate.guid === +templateId
                        )

                        return cashTemplate.name
                    })
                    .join(', ')
        }

        return chipText
    },
    [CountersAndLimitsConditions.ActionResultsQuantityCondition]: ({ data, type }: Condition) => {
        return getActionResultsQuantityChipLabel(data)
    },
    [CountersAndLimitsConditions.DiscountSumCondition]: ({ data, type }: Condition) => {
        return getDiscountSumChipLabel(data)
    },
    [CountersAndLimitsConditions.CounterCondition]: ({ data, type }: Condition) => {
        return getCountersTriggersChipLabel(data)
    },
    [TimeConditions.HoursActionCondition]: ({ data, type }: Condition) => {
        const { hourStart, minuteStart, hourEnd, minuteEnd } = data.timePeriods[0][ConditionFields.ActionHours][0]
        const {
            hourStart: secondHourStart,
            minuteStart: secondMinuteStart,
            hourEnd: secondHourEnd,
            minuteEnd: secondMinuteEnd
        } = data.timePeriods[0][ConditionFields.ActionHours]?.[1] || {}
        const startTime = `${formatTimeUnitsInto2Digit(hourStart?.[0])}:${formatTimeUnitsInto2Digit(minuteStart?.[0])}`
        const endTime = `${formatTimeUnitsInto2Digit(hourEnd?.[0])}:${formatTimeUnitsInto2Digit(minuteEnd?.[0])}`

        const secondStartTime = `${formatTimeUnitsInto2Digit(secondHourStart?.[0])}:${formatTimeUnitsInto2Digit(secondMinuteStart?.[0])}`
        const secondEndTime = `${formatTimeUnitsInto2Digit(secondHourEnd?.[0])}:${formatTimeUnitsInto2Digit(secondMinuteEnd?.[0])}`

        let chipText = `${
            t('advertisingActions.conditionLabels.actionHours')
        } ${startTime} ${t('advertisingActions.conditionLabels.actionHoursTo')} ${endTime}`

        if (!secondHourStart && !secondMinuteStart && !secondHourEnd && !secondMinuteEnd) {
            return chipText
        }

        return `${chipText}; ${
            t('common.fromStart')
        } ${secondStartTime} ${
            t('common.toEnd')
        } ${secondEndTime}`
    },
    [TimeConditions.DaysOfTheWeekCondition]: ({ data, type }: Condition) => {
        const daysOfTheWeek = data.days[0].boolean

        switch (true) {
            case isEqual(toSelectedDays(daysOfTheWeek), [0, 1, 2, 3, 4, 5, 6]):
                return t('advertisingActions.dayOfTheWeeksCondition.anyDay')
            case isEqual(toSelectedDays(daysOfTheWeek), [1, 2, 3, 4, 5]):
                return t('advertisingActions.dayOfTheWeeksCondition.onWeekdays')
            case isEqual(toSelectedDays(daysOfTheWeek), [0, 6]):
                return t('advertisingActions.dayOfTheWeeksCondition.onTheWeekend')
            default:
                const result = []
                getWeekDays().forEach(day => {
                    if (isSelectedDay(daysOfTheWeek[day.index])) {
                        result.push(day.label.toLowerCase())
                    }
                })
                return `${t('advertisingActions.dayOfTheWeeksCondition.inDays')} ${result.join(', ')}`
        }
    },
}

export const formatCondition = (condition: Condition, args?: any): string | Promise<string> => {
    const format = conditionFormatters[condition.type]
    if (!format) return `Not implemented: ${last(condition.type.split('.'))}`
    return format(condition, args)
}
