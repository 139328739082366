import React from 'react'
import { t } from 'i18next'
import { Link } from '@material-ui/core'

export const LicenseDisableAlertMessage: React.FC<{}> = () => {
    return (
        <div key="messageContainer">
            <p key="paragraph1">{ t('externalProcessings.licenseDisableAlertMessage1') }</p>
            <p key="paragraph2" style={{ marginTop: '8px' }}>{ t('externalProcessings.licenseDisableAlertMessage2') }</p>
            <p key="paragraph3" style={{ marginTop: '8px' }}>
                <Link href="https://www.crystals.ru/news/update" target="_blank" style={{ color: 'inherit' }}>
                    { t('externalProcessings.licenseDisableAlertMessage3') }
                </Link>
            </p>
        </div>
    )
}
