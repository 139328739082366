import { OperationVO } from './set-retail10-commons/data-structs-module/operation-vo'
import { TimestampPeriod } from './set-retail10-commons/data-structs-module/timestamp-period'
import { BonusAccountVO } from './set-retail10-commons/set-cards-internal-cards/bonus-account-vo'
import { BonusAccountTransVO } from './set-retail10-commons/set-cards-internal-cards/bonus-account-trans-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class IInternalCardAccountProsessingRemote extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Cards-InternalCards/SET/Cards/InternalCards/ProcessingManager!ru.crystals.cards.internalcards.IInternalCardAccountProsessingRemote'
    }

    getAccumulationHistoryByCard = (period: TimestampPeriod, cardNumber: string, options?: RequestOptions): Promise<OperationVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getAccumulationHistoryByCard',
                params: [period, cardNumber]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getActiveBonusAccounts = (cardNumber: string, options?: RequestOptions): Promise<BonusAccountVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getActiveBonusAccounts',
                params: [cardNumber]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getBonusAccountHistory = (cardNumber: string, start: Date, finish: Date, options?: RequestOptions): Promise<BonusAccountTransVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getBonusAccountHistory',
                params: [cardNumber, start, finish]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getCardAccumulationBalance = (cardNumber: string, options?: RequestOptions): Promise<number> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCardAccumulationBalance',
                params: [cardNumber]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const iInternalCardAccountProsessingRemote: IInternalCardAccountProsessingRemote = new IInternalCardAccountProsessingRemote()
