import * as React from 'react'
import { t } from 'i18next'
import {inject, observer} from 'mobx-react'
import {Table} from '@crystalservice/crystals-ui/lib/components/table/table'
import {RIGHT, TEXT, NUMBER} from '@crystalservice/crystals-ui/lib/components/table/column'
import { MenuButton } from '@crystalservice/crystals-ui/lib/components/buttons/menu-button/menu-button'
import Button from '@material-ui/core/Button'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import {toJS} from 'mobx'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AddCircle from '@material-ui/icons/AddCircle'
import {CashSettingsDialog} from './cash-settings-dialog'
import {CashVO} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-vo'
import {CLOSED_DOWN} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-status'
import {DialogStore} from '../../../store/dialog-store'
import {AppStore} from '../../../store/app-store'
import {DIALOG} from '../../../../components/simple-dialog/simple-dialog'
import {APP_STORE, DIALOG_STORE, SHOP_STORE, SHOP_CASHES_STORE} from '../../../store/stores'
import {ShopCashesStore} from '../../../store/shops/shop-settings/shop-cashes-store'
import {ShopStore} from '../../../store/shops/shop-settings/shop-store'
import { Paginator } from '@crystalservice/crystals-ui/lib/components/table/paginator'

const styles = require('./cashes-step.scss')

export interface CashesStepProps {
    shopCashesStore?: ShopCashesStore
    shopStore?: ShopStore
    dialogStore?: DialogStore
    app?: AppStore
}

@inject(APP_STORE)
@inject(SHOP_STORE)
@inject(SHOP_CASHES_STORE)
@inject(DIALOG_STORE)
@observer
export class CashesStep extends React.Component<CashesStepProps> {

    componentDidMount(): void {
        const {fetchAllCashesData} = this.props.shopCashesStore
        fetchAllCashesData()
    }

    showDeleteDialog = (cash: CashVO) => {
        this.props.dialogStore.showDialog({
            mode: DIALOG,
            title: t('shopPage.cashConfirmTitle'),
            message: t('shopPage.cashConfirmMessage'),
            onYes: () => this.props.shopCashesStore.deleteCash(cash)
        })
    }

    render() {
        const {
            sortedCashes, editCash, notifyNewCash, addCash, updateCashStatus, paginatorKey, setPaginationState, paginationState,
        }  = this.props.shopCashesStore
        const {connectedToCentrum, shop: { virtual }} = this.props.shopStore
        const {isCentrum} = this.props.app

        // Нельзя добавлять кассу если мы на ритейле и подключены к центруму
        const canAddNewCash = isCentrum || !connectedToCentrum

        const addButton = canAddNewCash ? (
            <Button
                id="addCashButton"
                className={styles.addButton}
                onClick={addCash}
            >
                <AddCircle
                    className={styles.icon}
                    color="primary"
                />
                <span>
                    {t('shopPage.addCash')}
                </span>
            </Button>
        ) : null

        return (
            <div
                id="cashesPage"
                className={styles.cashesStepContent}
            >
                <CashSettingsDialog/>

                <p className={styles.header}>
                    {t('shopPage.cashesStep')}
                </p>

                {sortedCashes.length === 0 ? (
                    <div>
                        <Divider/>
                        <Empty message={t('shopPage.noAddedDevices')}/>
                        <Divider className={styles.emptyDivider}/>
                        {addButton}
                    </div>
                ) : (
                    <Paginator
                        defaultItems={toJS(sortedCashes)}
                        additionalControls={addButton}
                        key={paginatorKey}
                        defaultPagination={paginationState}
                        onPageItemsChange={setPaginationState}
                    >
                        <Table
                            id="cashesTable"
                            withHeader
                            collapsible
                            keyField="id"
                            columns={[
                                {
                                    header: t('shopPage.deviceNumber'),
                                    labelField: 'number',
                                    dataType: NUMBER,
                                    width: '10%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.deviceTemplate'),
                                    labelFunction: (item: CashVO) => item.template.name,
                                    dataType: TEXT,
                                    width: '30%',
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.cashLayout'),
                                    labelField: 'layoutName',
                                    dataType: TEXT,
                                    padding: '16px',
                                },
                                {
                                    header: t('shopPage.cashStatus'),
                                    renderer: itemProps => {
                                        let cash: CashVO = itemProps.item
                                        let closed = cash.status === CLOSED_DOWN
                                        return (
                                            <Typography
                                                color={closed ? 'textSecondary' : 'primary'}
                                                className={styles.statusCell}
                                            >
                                                {closed ? t('shopPage.cashClosed') : t('shopPage.cashActive')}
                                            </Typography>
                                        )
                                    },
                                    width: '20%',
                                    padding: '16px',
                                },
                                {
                                    width: '10%',
                                    padding: '0 10px',
                                    hAlign: RIGHT,
                                    showOnRowHover: true,
                                    withControls: true,
                                    renderer: itemProps => {
                                        let cash: CashVO = itemProps.item
                                        let closed = cash.status === CLOSED_DOWN
                                        return (
                                            <MenuButton
                                                id={`cash${cash.id}MenuButton`}
                                                adaptive
                                                labelsContainerClassName={styles.menuButton}
                                                buttons={[
                                                    {
                                                        id: `cash${cash.id}EditButton`,
                                                        label: t('common.edit'),
                                                        onClick: () => editCash(cash)
                                                    },
                                                    {
                                                        id: `cash${cash.id}NotifyNewButton`,
                                                        label: t('shopPage.notifyNewCash'),
                                                        onClick: () => notifyNewCash(cash),
                                                        disabled: closed || isCentrum && !virtual
                                                    },
                                                    {
                                                        id: `cash${cash.id}CloseButton`,
                                                        label: closed ? t('shopPage.cashOpen') : t('shopPage.cashClose'),
                                                        onClick: () => {
                                                            closed ? updateCashStatus(cash, false) : updateCashStatus(cash, true)
                                                        },
                                                        disabled: isCentrum
                                                    },
                                                    {
                                                        id: `cash${cash.id}RemoveButton`,
                                                        label: t('common.remove'),
                                                        onClick: () => this.showDeleteDialog(cash)
                                                    },
                                                ]}
                                            />
                                        )
                                    }
                                }
                            ]}
                        />
                    </Paginator>
                )}
            </div>
        )
    }
}
