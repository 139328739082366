import { action, observable, runInAction } from 'mobx'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { USER_STORE, APP_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { SCALES } from '../../core/sale-group-type'
import { SaleGroupVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/sale-group-vo'
import { cloneDeep } from 'lodash'
import { withSpinner } from '../with-spinner'
import { AppStore } from '../app-store'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { SNACK_BAR_ERROR } from '../snackbar-store'
import { t } from 'i18next'

export class ScalesProductListsStore {

    @observable
    listsForScales: SaleGroupVO[] = []

    @observable
    filteredListsForScales: SaleGroupVO[] = []

    @observable
    listsForScalesFilter: string = null

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    fetchProductLists = async () => {
        const listsForScales = await withSpinner(
            iProductsManagerLocal.getSaleGroupsByType(this.userStore.session, SCALES)
        )

        runInAction(() => {
            this.listsForScales = listsForScales

            this.filteredListsForScales = cloneDeep(listsForScales)
        })
    }

    @action
    handleFilterScales = (value: string) => {
        this.listsForScalesFilter = value
        this.filteredListsForScales = this.listsForScales.filter(list => list.name.toLowerCase().trim().includes(this.listsForScalesFilter))
    }

    @action
    handleDeleteList = async (code: string) => {
        await iProductsManagerLocal.removeSaleGroup(this.userStore.session, code)
        await this.fetchProductLists()
    }

    @action
    openDeleteModal = (list: SaleGroupVO) => {
        if (list.amountOfProducts > 0) {
            this.appStore.showSnackbar({
                message: t('scalesModuleProductLists.removeNotificationFailed'),
                variant: SNACK_BAR_ERROR,
            })
        } else {
            this.appStore.showDialog({
                id: 'removeListDialog',
                title: t('scalesModuleProductLists.removeListTitle'),
                message: t('scalesModuleProductLists.removeListMessage', { listName: list.name }),
                mode: DIALOG,
                onYes: () => this.handleDeleteList(list.code),
            })
        }
    }

    @action
    reset = (): void => {
        this.listsForScales = []
        this.filteredListsForScales = []
        this.listsForScalesFilter = null
    }
}
