import { t } from 'i18next'
import { action, computed, observable, runInAction } from 'mobx'
import { haspDataManagerRemote } from '../../../protocol/set10/hasp-data-manager-remote'
import { LicenseInfoVO } from '../../../protocol/set10/set-retail10-commons/set-hasp-commons/license-info-vo'
import { AppStore } from '../app-store'
import { APP_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { withSpinner } from '../with-spinner'

const SET_ID_CHARS_COUNT = 20
const SET_ID_SIGNATURE_SUBSTRING = 18

export class LicensesStore {
    @observable
    licenseInfo: LicenseInfoVO

    @observable
    emptySetId: boolean = true

    @computed
    get setIdValid(): boolean {
        if (!this.licenseInfo) return false

        const setId = this.licenseInfo.setId

        if (!setId) return false

        if (setId.length !== SET_ID_CHARS_COUNT) return false

        let signature = Number(setId.substring(SET_ID_SIGNATURE_SUBSTRING))
        let sum = 0
        for (let i = 0; i < SET_ID_SIGNATURE_SUBSTRING; i++) {
            sum += setId.charCodeAt(i)
        }

        if (Math.round(sum / SET_ID_SIGNATURE_SUBSTRING) !== signature) return false

        return true
    }

    private appStore: AppStore = getStore(APP_STORE)

    @action
    editLicenseInfo = (changes: Partial<LicenseInfoVO>): void => {
        Object.keys(changes).forEach(k => {
            this.licenseInfo[k] = changes[k]
        })
    }

    fetchLicenseInfo = (): Promise<void> => {
        return haspDataManagerRemote.getLicenseInfo()
            .then(result => {
                runInAction(() => {
                    this.licenseInfo = result
                    this.emptySetId = !Boolean(result.setId)
                })
            })
    }

    validateLicenseData = (): Promise<void> => {
        if (!this.licenseInfo) return Promise.resolve()

        return withSpinner(haspDataManagerRemote.validateLicenseData(this.licenseInfo.serialNumber, this.licenseInfo.setId)
            .then(result => {
                runInAction(() => {
                    this.licenseInfo = result
                    this.emptySetId = !Boolean(result.setId)
                })

                if (result.errorMessage) {
                    this.appStore.showDialog({
                        title: t('components.error'),
                        message: result.errorMessage
                    })
                }
            }))
    }

    showSetIdDialog = (): void => {
        this.appStore.showDialog({
            title: t('licenses.setIdDialogHeader'),
            message: t('licenses.setIdDialogText')
        })
    }

    showLicenseDialog = (): void => {
        this.appStore.showDialog({
            title: t('licenses.licenseDialogHeader'),
            message: t('licenses.licenseDialogText')
        })
    }

    @action
    reset = (): void => {
        this.licenseInfo = undefined
        this.emptySetId = true
    }
}
