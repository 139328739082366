import { TopologyNodeVO } from './topology-node-vo'
import { FormatVO } from './format-vo'

export interface TopologyConditionVO {
    '@class': 'ru.crystals.setretailx.topology.TopologyConditionVO'
    node?: TopologyNodeVO
    formats?: FormatVO[]
}

export const createTopologyConditionVO = (initialization: Partial<TopologyConditionVO>): TopologyConditionVO => {
    if (!initialization) return null
    
    let result: TopologyConditionVO = {
        node: initialization.node,
        formats: initialization.formats,
        '@class': 'ru.crystals.setretailx.topology.TopologyConditionVO',
    }
    
    return result
}
