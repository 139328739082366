import React from 'react'
import { FilterType, NAME_PART } from '../../../../core/filters/filter'
import { InternalFilterComponentProps } from '../../new-filters'
import { t } from 'i18next'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'

export class NamePart extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = NAME_PART

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <TextField
                id={id}
                slim={slim}
                label={t('loyaltyCoupons.instancesPage.filters.namePart')}
                value={value}
                onValueChange={value => onChange(NamePart.filterType, { value })}
            />
        )
    }
}
