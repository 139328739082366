import { toJS, computed, action, observable } from 'mobx'
import {
    createPluginProperty,
    PluginProperty
} from '../../../../protocol/set10/set-retail10-server/retailx/set-payment-type-manager/plugin-property'
import { getStore } from '../../stores-repository'
import { PAYMENT_TYPE_SETTINGS_STORE } from '../../stores'
import { PaymentTypeSettingsStore } from './payment-type-settings-store'
import { isEqual } from 'lodash'
import { t } from 'i18next'

export type Bank = PluginProperty

export type BankProduct = PluginProperty

export class PaymentTypeBanksStore {

    @observable
    editedBank: Bank = undefined

    @observable
    isNewBank: boolean = false

    @observable
    editedBankProduct: BankProduct

    @observable
    isNewBankProduct: boolean = false

    private paymentTypeSettingsStore: PaymentTypeSettingsStore = getStore(PAYMENT_TYPE_SETTINGS_STORE)
    private origBank: Bank = undefined
    private origBankProduct: BankProduct = undefined
    private origBankProductIndex: number = undefined

    @computed
    get banks(): Bank[] {
        const { paymentTypeProps } = this.paymentTypeSettingsStore
        if (!paymentTypeProps) return []
        return paymentTypeProps.filter(prop => prop.key === 'bankName')
    }

    @computed
    get bankChanged(): boolean {
        return this.editedBank ? !isEqual(toJS(this.editedBank), this.origBank) : false
    }

    @computed
    get bankProductChanged(): boolean {
        return this.editedBankProduct ? !isEqual(toJS(this.editedBankProduct), this.origBankProduct) : false
    }

    // Bank

    @action
    openBank = (bank: Bank) => {
        this.setBank(bank)
    }

    @action
    closeBank = () => {
        this.reset()
    }

    @action
    addBank = () => {
        const bank: Bank = createPluginProperty({
            key: 'bankName',
            value: t('paymentTypes.newBankName'),
            properties: []
        })
        this.setIsNewBank(true)
        this.openBank(bank)
    }

    @action
    deleteBank = (bank: Bank) => {
        this.paymentTypeSettingsStore.deletePaymentTypeProp(bank)
    }

    @action
    saveBank = async () => {
        if (this.isNewBank) this.paymentTypeSettingsStore.addPaymentTypeProp(this.editedBank)
        await this.paymentTypeSettingsStore.savePaymentType()
        this.closeBank()
    }

    @action
    updateBankName = (value: string) => {
        this.editedBank.value = value
    }

    // Bank products

    @action
    openBankProduct = (bankProduct: BankProduct) => {
        this.origBankProductIndex = this.editedBank.properties.indexOf(bankProduct)
        this.setBankProduct(toJS(bankProduct))
    }

    @action
    closeBankProduct = () => {
        this.resetBankProduct()
    }

    @action
    addBankProduct = () => {
        const bankProduct: BankProduct = createPluginProperty({
            key: 'bankProduct',
            properties: [],
            value: t('paymentTypes.newProductName')
        })
        this.setIsNewBankProduct(true)
        this.openBankProduct(bankProduct)
    }

    @action
    updateBankProductName = (name: string) => {
        this.editedBankProduct.value = name
    }

    @action
    saveBankProduct = () => {
        if (this.isNewBankProduct) {
            this.editedBank.properties.push(this.editedBankProduct)
        } else {
            this.editedBank.properties[this.origBankProductIndex] = this.editedBankProduct
        }
        this.closeBankProduct()
    }

    @action
    deleteBankProduct = (bankProduct: BankProduct) => {
        this.editedBank.properties = this.editedBank.properties.filter(prop => prop.value !== bankProduct.value)
    }

    // (Re)setters

    @action
    setBank = (bank: Bank) => {
        this.editedBank = bank
        this.origBank = toJS(bank)
    }

    @action
    setIsNewBank = (isNewBank: boolean) => {
        this.isNewBank = isNewBank
    }

    @action
    setBankProduct = (bankProduct: BankProduct) => {
        this.editedBankProduct = bankProduct
        this.origBankProduct = toJS(bankProduct)
    }

    @action
    setIsNewBankProduct = (isNewBankProduct: boolean) => {
        this.isNewBankProduct = isNewBankProduct
    }

    @action
    reset = () => {
        this.resetBank()
        this.resetBankProduct()
    }

    @action
    resetBank = () => {
        this.setIsNewBank(false)
        this.setBank(undefined)
    }

    @action
    resetBankProduct = () => {
        this.setBankProduct(undefined)
        this.setIsNewBankProduct(false)
        this.origBankProductIndex = undefined
    }
}
