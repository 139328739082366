import { parseString, Builder, OptionsV2 } from 'xml2js'
import { isArray, isObject } from 'lodash'
import { XMLPriceTag } from './xml/xml-price-tag'
import { parseNumbers } from 'xml2js/lib/processors'
import { fromBase64, toBase64 } from '../../../utils/serialization/base64-util'

const ROOT_NAME = 'priceTag'

/**
 * Десериализирует XML представление ценника в структуру XMLPriceTag
 * @param base64
 */
export async function deserializePriceTagXML(base64: string): Promise<XMLPriceTag> {
    return new Promise((resolve, reject) => {
        const parserOptions: OptionsV2 = {
            emptyTag: null,
            explicitArray: false,
            valueProcessors: [
                parseNumbers,
                parseTextElement,
                parseNANValue,
                parseBooleans
            ]
        }

        parseString(fromBase64(base64), parserOptions, (error: Error, data: any) => {
            if (error) {
                return reject(error)
            }

            resolve(fixPriceTagAfterXMLParser(data.priceTag))
        })
    })
}

/**
 * Сериализирует структуру XMLPriceTag в XML
 * @param priceTag
 */
export function serializePriceTagXML(priceTag: XMLPriceTag): string {
    const builder = new Builder({
        rootName: ROOT_NAME,
    })

    // Готовим объект к особенностям сериализации
    const fixedPriceTag = fixPriceTagForXMLBuilder(priceTag)
    const xml = builder.buildObject(fixedPriceTag)
    return toBase64(xml)
}

/**
 * Процессор для xml2js для декодирования тегов <text/> из base64
 * @param value
 * @param name
 */
const parseTextElement = (value: any, name: string): string => {
    if (!value) return value
    // Текст сначала расшифровываем из base64
    if (name === 'text') {
        return fromBase64(value)
    }

    return value
}

/**
 * Процессор для xml2js для превращения 'false' в false
 * @param value
 */
const parseBooleans = (value: any): boolean => {
    if (value === 'false') {
        return false
    }

    if (value === 'true') {
        return true
    }

    return value
}

const parseNANValue = (value: string): string => {
    if (value === 'NaN') return null
    return value
}

/**
 * Череда десериализаций base64 -> xml -> js приводит к появлению формата, не соответсвующего XMLPriceTag
 * Необходимо проверить все узлы, которые имеют имя item -> они должны стать массивами
 * (Если в массиве был 1 элемент, он станет объектом после десериализации из xml)
 * @param data
 */
function fixPriceTagAfterXMLParser(data: any): any {
    const dataIsObject = isObject(data)
    const dataIsArray = isArray(data)

    // Если примитив, возвращаем назад
    if (!dataIsArray && !dataIsObject) return data

    // Для массива исправляем все элементы массива
    if (dataIsArray) {
        return data.map(item => fixPriceTagAfterXMLParser(item))
    }

    // Если объект -> проверяем на наличие item, если нет смотрим по каждому свойству
    const objectKeys = Object.keys(data)

    // Если было свойство item -> возвращаем без него
    if (objectKeys.some(key => key === 'item')) {
        const fixedArray = fixPriceTagAfterXMLParser(data.item)
        // Если был не массив, возвращаем обернув в массив
        if (!isArray(data.item)) {
            return [fixedArray]
        }
        return fixedArray
    }

    // Для объекта прогоняем по каждому свойству
    objectKeys.forEach(key => {
        data[key] = fixPriceTagAfterXMLParser(data[key])
    })
    return data
}

/**
 * Для создания корректной xml строки из js-объекта, необходимы доработки:
 * 1. все элементы массива в xml на стороне сервера обернуты в тэг <item>
 * 2. поле text кодируется в base64
 */
function fixPriceTagForXMLBuilder(data: any): any {
    const dataIsObject = isObject(data)
    const dataIsArray = isArray(data)

    // Если примитив, возвращаем назад
    if (!dataIsArray && !dataIsObject) return data

    // Для массива прогоняем по каждому элементу и добавляем <item>
    if (dataIsArray) {
        const newArray = data.map(item => fixPriceTagForXMLBuilder(item))

        // Добавляем объект с полем item, таким образом все элементы массива станут с тэгом item
        return {item: newArray}
    }

    // Для объекта прогоняем по каждому свойству
    let newObject = {...data}
    Object.keys(newObject).forEach(key => {
        const prop = newObject[key]

        if (key === 'text') {
            // Для текстовой строки кодируем её обратно
            if (typeof prop === 'string') {
                newObject[key] = toBase64(prop)
                return
            }
        }

        newObject[key] = fixPriceTagForXMLBuilder(prop)
    })
    return newObject
}
