import {t} from 'i18next'
import {
    CITY,
    EnTopologyLevel,
    REGION,
    SHOP
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/en-topology-level'
import {
    getFormatNodeName,
    getNodeLevel,
    getTopologyNodeName,
    isSameFormats,
    isShop,
    getSameNodeInList as getSameNativeNodeInList, isRegion, isCity,
} from '../../core/topology/topology-util'
import {ShopVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import {TopologyNode} from './topology-filter'
import {FormatVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import {CityVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import {TopologyNodeVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-node-vo'
import {TopologyMap} from './topology-map'
import {
    createTopologyFilterVO,
    TopologyFilterVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-filter-vo'
import {
    createTopologyConditionVO,
    TopologyConditionVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition-vo'

export const isSameNodes = (node1: TopologyNode, node2: TopologyNode): boolean => {
    if (!node1 || !node2) return false
    return getNodeLevel(node1.node) === getNodeLevel(node2.node) &&
        node1.node.id === node2.node.id
}

export type NodesInheritance = 'noInheritance' | 'haveInheritance' | 'conflictInFormats'
// Нет родственных отношений между узлами
export const NO_INHERITANCE: NodesInheritance = 'noInheritance'
// Между узлами есть родственные отношения
export const HAVE_INHERITANCE: NodesInheritance = 'haveInheritance'
// Узлы родственные, но есть конфликт в указанных форматах сети
export const CONFLICT_IN_FORMATS: NodesInheritance = 'conflictInFormats'

export interface TopologyNodeInheritanceInfo {
    node: TopologyNode
    parent: TopologyNode
    inheritance: NodesInheritance
}

export const isNodeInParent = (child: TopologyNode, parent: TopologyNode): NodesInheritance => {
    // Если не указан ни один формат сети, то не могут быть определены родственные отношения
    if (!child || !parent || (!isShop(child.node) && (!child.formats || child.formats.length === 0)) ||
        (!isShop(parent.node) && (!parent.formats || parent.formats.length === 0))) {
        return NO_INHERITANCE
    }

    if (isShop(parent.node)) return NO_INHERITANCE

    let currentNode: TopologyNode = child
    let foundInParentChain: boolean = false

    while (currentNode && currentNode.parent) {
        if (isSameNodes(currentNode.parent, parent)) {
            foundInParentChain = true
            break
        }
        currentNode = currentNode.parent
    }

    if (foundInParentChain) {
        let childCopy: TopologyNode = child.clone()
        if (isShop(child.node)) {
            //для магазина может быть указан только один формат
            //и нет вариаций режима работы
            let shop: ShopVO = child.node as ShopVO
            childCopy.formats = [shop.format]
            //24 часовой режим более узкий, входит в "любые режимы"
            childCopy.only24h = shop.twentyFourHour
        }
        //первым делом сравниваем режимы работы
        //вторым этапом сравниваем форматы сети
        if (!childCopy.only24h && parent.only24h) {
            //!childCopy.only24h не входит в parent.only24h
            return NO_INHERITANCE
        } else {
            //Ищем форматы, которых нет в parent
            let formatsToMerge: FormatVO[] = getFormatsToMerge(childCopy, parent)
            if (formatsToMerge.length === 0) {
                //нет конфликтов
                return HAVE_INHERITANCE
            } else if (formatsToMerge.length !== childCopy.formats.length) {
                //есть конфликты, но так же есть общие форматы
                return CONFLICT_IN_FORMATS
            } else {
                return NO_INHERITANCE
            }
        }
    } else {
        return NO_INHERITANCE
    }
}

export const mergeNodeToList = (node: TopologyNode, list: TopologyNode[]): string[] => {
    let conflicts: string[] = []
    let nodeCopy: TopologyNode = node.clone()

    if (isShop(node.node)) {
        // для магазина может быть указан только один формат
        // и нет вариаций режима работы
        let shop: ShopVO = node.node
        nodeCopy.formats = [shop.format]
        // 24 часовой режим более узкий, входит в "любые режимы"
        nodeCopy.only24h = shop.twentyFourHour
    }

    let sameNode: TopologyNode = getSameNodeInList(nodeCopy, list)
    let sameNodeCopy: TopologyNode = null

    if (sameNode) {
        sameNodeCopy = sameNode.clone()
        if (!nodeCopy.only24h && nodeCopy.only24h !== sameNodeCopy.only24h) {
            sameNodeCopy.only24h = nodeCopy.only24h
        }
        mergeFormatsInto(nodeCopy.formats, sameNodeCopy.formats)
        sameNode = sameNodeCopy
    } else {
        sameNodeCopy = nodeCopy
    }

    let parentNodes: TopologyNodeInheritanceInfo[] = getParentNodesInList(sameNodeCopy, list)
    if (parentNodes.length > 2) {
        // Не может быть больше 2 родительских узлов региона и города.
        console.warn('There are more than 2 parent topology nodes found, check this situation')
    }

    parentNodes.sort(compareNodesByTopologyLevel)

    let nodeCollapsed: boolean = false
    // Данный узел может либо полностью, либо частично быть поглощен одним из предков
    for (let parentInfo of parentNodes) {
        // информация о конфликтах в parentNodes может устареть во время прохода по коллекции
        let parent: TopologyNode = parentInfo.parent
        if (parent.only24h && !sameNodeCopy.only24h) {
            // несовместимые режимы работы - поглощение невозможно
            continue
        } else {
            // ищем разницу в форматах сети
            let formats: FormatVO[] = getFormatsToMerge(sameNodeCopy, parent)
            if (formats.length === 0) {
                let msg: string = t('topology.includedIn', {
                    child: getTopologyNodeName(sameNodeCopy.node),
                    parent: getTopologyNodeName(parent.node),
                })
                conflicts.push(msg)
                //Данный узел полностью поглащен одним из предков
                if (sameNode) {
                    list.splice(list.indexOf(getSameNodeInList(sameNode, list)), 1)
                }
                nodeCollapsed = true
                break
            }
            else {
                // часть форматов сети уже указана в предке, указываем только те, которые не указаны в предке
                sameNodeCopy.formats = formats
            }
        }
    }
    if (!nodeCollapsed) {
        if (sameNode) {
            // удаляем такой же узел в списке, чтобы потом добавить обновленную версию
            list.splice(list.indexOf(getSameNodeInList(sameNode, list)))
        }
        // удаляем все дочерние узлы, которые будут поглощены обновленным узлом
        removeAllChildNodes(sameNodeCopy, list)
        list.push(sameNodeCopy)
    }
    return conflicts
}

export const getParentNodesInList = (child: TopologyNode, list: TopologyNode[]): TopologyNodeInheritanceInfo[] => {
    let result: TopologyNodeInheritanceInfo[] = []
    for (let node of list) {
        let inheritance: NodesInheritance = isNodeInParent(child, node)
        if (inheritance !== NO_INHERITANCE) {
            result.push({node: child, parent: node, inheritance})
        }
    }
    return result
}

export const removeAllChildNodes = (parent: TopologyNode, list: TopologyNode[]): void => {
    if (!parent || !list || list.length === 0 || isShop(parent.node)) return

    let listCopy: TopologyNode[] = list.concat()

    for (let node of listCopy) {
        let inheritance: NodesInheritance = isNodeInParent(node, parent)
        if (inheritance === HAVE_INHERITANCE) {
            list.splice(list.indexOf(node), 1)
        }
        else if (inheritance === CONFLICT_IN_FORMATS) {
            let formatsToMerge: FormatVO[] = getFormatsToMerge(node, parent)
            removeFormatsExcept(node.formats, formatsToMerge)
        }
    }
}

export const getSameNodeInList = (node: TopologyNode, list: TopologyNode[]): TopologyNode => {
    if (!node || !list) return null
    for (let targetNode of list) {
        if (isSameNodes(node, targetNode)) {
            return targetNode
        }
    }
    return null
}

export const haveSameFormatInList = (format: FormatVO, list: FormatVO[]): boolean => {
    if (!format || !list) return false
    for (let targetFormat of list) {
        if (isSameFormats(format, targetFormat)) {
            return true
        }
    }
    return false
}

/**
 * Возвращает список форматов сети из node, которых не хватает в target
 * @param node
 * @param target
 * @return
 */
export const getFormatsToMerge = (node: TopologyNode, target: TopologyNode): FormatVO[] => {
    let result: FormatVO[] = []
    for (let format of node.formats) {
        if (!haveSameFormatInList(format, target.formats)) {
            result.push(format)
        }
    }
    return result
}

export const mergeFormatsInto = (formatsToMerge: FormatVO[], toList: FormatVO[]): void => {
    if (!formatsToMerge || !toList) return

    for (let format of formatsToMerge) {
        if (!haveSameFormatInList(format, toList)) {
            toList.push(format)
        }
    }
}

export const removeFormatsExcept = (list: FormatVO[], except: FormatVO[]): void => {
    let listCopy: FormatVO[] = list.concat()

    for (let format of listCopy) {
        let isException: boolean = false
        for (let exception of except) {
            if (isSameFormats(format, exception)) {
                isException = true
                break
            }
        }
        if (!isException) {
            list.splice(list.indexOf(format), 1)
        }
    }
}

/**
 * Сортирует узлы по уровню топологии от региона до магазина
 * @param {TopologyNode} node1
 * @param {TopologyNode} node2
 * @returns {number}
 */
export const compareNodesByTopologyLevel = (node1: any, node2: any): number => {
    const priority: string[] = [REGION, CITY, SHOP]
    let index1: number = priority.indexOf(getNodeLevel(node1.node))
    let index2: number = priority.indexOf(getNodeLevel(node2.node))
    return index1 - index2
}

export const getHierarchyDescription = (node: TopologyNode): string => {
    if (!node) return ''
    let nodes: TopologyNode[] = []
    let currentNode: TopologyNode = node
    while (currentNode) {
        nodes.push(currentNode)
        currentNode = currentNode.parent
    }
    let result: string = ''
    for (let i: number = nodes.length - 1; i >= 0; i--) {
        currentNode = nodes[i]
        if (result.length > 0) result += ' - '
        result += getTopologyNodeName(currentNode.node)
    }
    let lastNode: TopologyNode = nodes[0]
    if (!isShop(lastNode.node) && lastNode.formats.length > 0) {
        result += ' - ' + getFormatsDescription(lastNode.formats)
    }
    if (!isShop(lastNode.node) && lastNode.only24h) {
        result += ', ' + t('topology.only24h')
    }
    return result
}

export const getFormatsDescription = (formats: FormatVO[]): string => {
    let result: string = ''
    for (let format of formats) {
        if (result.length > 0) result += ', '
        result += getFormatNodeName(format)
    }
    return result
}

export const createHierarchicalNode = (map: TopologyMap, node: TopologyNodeVO, formats: FormatVO[],
                                       only24H: boolean = false): TopologyNode => {
    let parentChain: EnTopologyLevel[] = [REGION, CITY, SHOP]
    let childLevel: EnTopologyLevel = null

    for (let i: number = 0; i < parentChain.length; i++) {
        let level: EnTopologyLevel = parentChain[i]
        if (getNodeLevel(node) === level) {
            if (i < parentChain.length - 1) {
                childLevel = parentChain[parentChain.length - 1]
            }
            parentChain = parentChain.splice(0, i)
            break
        }
    }

    let hierarchicalNode: TopologyNode = new TopologyNode(node, formats, null, only24H)
    let currentNode: TopologyNode = hierarchicalNode

    for (let i: number = parentChain.length - 1; i >= 0; i--) {
        let selectedParentNode: TopologyNodeVO
        let level: EnTopologyLevel = parentChain[i]
        if (level === CITY) {
            let shop: ShopVO = currentNode.node as ShopVO
            selectedParentNode = getSameNativeNodeInList(shop.city, map.cities)
            if (!selectedParentNode) {
                selectedParentNode = shop.city
            }
        } else if (level === REGION) {
            let city: CityVO = currentNode.node as CityVO
            selectedParentNode = getSameNativeNodeInList(city.region, map.regions)
            if (!selectedParentNode) {
                selectedParentNode = city.region
            }
        }
        let parent: TopologyNode = new TopologyNode(selectedParentNode, formats)
        currentNode.parent = parent
        currentNode.only24h = only24H
        currentNode = parent
    }
    return hierarchicalNode
}

export const convertConditionsToNodesOfMap = (conditions: TopologyConditionVO[], map: TopologyMap): TopologyNode[] => {
    if (!conditions) return []

    return conditions
        .map(condition => {
            let node: TopologyNodeVO = null
            let nodeId: number = condition.node.id

            if (isRegion(condition.node)) {
                node = map.getRegionById(nodeId)
            } else if (isCity(condition.node)) {
                node = map.getCityById(nodeId)
            } else if (isShop(condition.node)) {
                node = map.getShopById(nodeId)
            } else {
                throw Error(`Can not recognize topology node type with id: ${condition.node.id}`)
            }

            if (!node) {
                // Некорректный топологический узел(например, удаленный)
                return null
            }

            let formats: FormatVO[] = condition.formats.map(format => map.getFormatById(format.id))

            // В TopologyConditionVO нет параметра 'только круглостуточные'
            let topologyNode: TopologyNode = createHierarchicalNode(map, node, formats, false)

            return topologyNode
        })
        .filter(node => Boolean(node))
}

export const convertHierarchicalNodesToConditions = (nodes: TopologyNode[]): TopologyConditionVO[] => {
    if (!nodes) return []
    return nodes.map(node => {
        return createTopologyConditionVO({
            node: node.node,
            formats: node.formats,
        })
    })
}

export const createTopologyHierarchyForCityVO = (city: CityVO, formats: FormatVO[],
                                                 twentyFourHour: boolean = false): TopologyNode => {
    let regionParent: TopologyNode = new TopologyNode(city.region, formats)
    let result: TopologyNode = new TopologyNode(city, formats, regionParent, twentyFourHour)
    return result
}

// custom error for SFM-745
export class NodeNotFoundInTopologyError extends Error {
    // tslint:disable-next-line:variable-name
    __proto__: Error // for instanceof to work properly (https://github.com/Microsoft/TypeScript/issues/13965)

    nodeNotFoundForFilter: TopologyFilterVO

    constructor(nodeNotFoundForFilter: TopologyFilterVO) {
        const trueProto = new.target.prototype

        super(t('priceTagsFormats.formatSettings.nodeNotFoundErrorTitle', {
            id: nodeNotFoundForFilter.id,
            level: nodeNotFoundForFilter.level
        }))
        this.nodeNotFoundForFilter = nodeNotFoundForFilter

        this.__proto__ = trueProto
    }
}

export const convertTopologyFiltersToTopologyHierarchies = (filters: TopologyFilterVO[],
                                                            map: TopologyMap): TopologyNode[] => {
    if (!filters || !map) return []

    let result: TopologyNode[] = []

    filters.forEach(filter => {
        if (filter.level === REGION) {
            const region: TopologyNodeVO = map.getNode(REGION, filter.id)
            if (!region) {
                throw new NodeNotFoundInTopologyError(filter)
            }

            result.push(new TopologyNode(
                region,
                filter.formats,
                null,
                filter.onlyTwentyFourHours
            ))
        } else if (filter.level === CITY) {
            const city: TopologyNodeVO = map.getNode(CITY, filter.id)
            if (!city) {
                throw new NodeNotFoundInTopologyError(filter)
            }

            result.push(createTopologyHierarchyForCityVO(
                city as CityVO,
                filter.formats,
                filter.onlyTwentyFourHours
            ))
        } else if (filter.level === SHOP) {
            const shop: ShopVO = map.getNode(SHOP, filter.id) as ShopVO

            if (!shop) {
                throw new NodeNotFoundInTopologyError(filter)
            }

            let cityParent: TopologyNode = createTopologyHierarchyForCityVO(
                shop.city,
                [shop.format]
            )
            result.push(new TopologyNode(
                shop,
                [shop.format],
                cityParent,
                shop.twentyFourHour
            ))
        }
    })

    return result
}

export const convertTopologyHierarchiesToTopologyFilters = (hierarchies: TopologyNode[]): TopologyFilterVO[] => {
    let result: TopologyFilterVO[] = []

    hierarchies.forEach(node => {
        let filter: TopologyFilterVO = createTopologyFilterVO({
            id: node.node.id,
            level: getNodeLevel(node.node),
            formats: node.formats,
            onlyTwentyFourHours: node.only24h
        })
        result.push(filter)
    })

    return result
}

export const getShopsInMapByFilters = (filters: TopologyFilterVO[], map: TopologyMap): ShopVO[] => {
    if (!filters || !map) return []

    let result: ShopVO[] = []

    filters.forEach(filter => {
        let shops: ShopVO[] = []
        if (filter.level === REGION) {
            shops = map.getShopsInRegion(map.getRegionById(filter.id), filter.formats, filter.onlyTwentyFourHours)
        } else if (filter.level === CITY) {
            shops = map.getShopsInCity(map.getCityById(filter.id), filter.formats, filter.onlyTwentyFourHours)
        } else if (filter.level === SHOP) {
            shops = [map.getShopById(filter.id)]
        }

        result = result.concat(shops.filter(shop => {
            return !result.some(addedShop => addedShop.id === shop.id)
        }))
    })

    return result
}
