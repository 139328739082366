export type WholesaleLevelFormulaReturnType = 'RUB' | 'COP' | 'FULL_PRICE' | 'PERCENT' | 'COUNT'

export const WHOLESALE_RUB: WholesaleLevelFormulaReturnType = 'RUB'
export const WHOLESALE_COP: WholesaleLevelFormulaReturnType = 'COP'
export const FULL_PRICE: WholesaleLevelFormulaReturnType = 'FULL_PRICE'
export const PERCENT: WholesaleLevelFormulaReturnType = 'PERCENT'
export const COUNT: WholesaleLevelFormulaReturnType = 'COUNT'

export type FormulaReturnType = 'DEFAULT' | 'RUB' | 'COP'

export const DEFAULT: FormulaReturnType = 'DEFAULT'
export const RUB: FormulaReturnType = 'RUB'
export const COP: FormulaReturnType = 'COP'
