import React from 'react'
import {
    BANKS,
    CASH_MODULE,
    CHECK_FORMS,
    DEVICES,
    NOT_LOADED_TO_CASHES,
    NOT_LOADED_TO_SHOPS,
    PAYMENT_TYPES,
    PRODUCT_LISTS,
    TEMPLATES,
    REGISTER,
    SETTINGS,
    CASH_SCHEDULES,
    getRouteName,
    getCustomRouteName,
    EDIT_XML,
    NEW,
    EDITOR,
    CONDITIONS,
    FUNCTIONS,
} from '../core/app-routes'
import { RouteScheme, CENTRUM, RETAIL } from '../utils/route-modules-util'
import {
    SETRETAILX_TYPESGONFIG,
    SETRETAILX_CASHTEMPLATE_ADMIN,
    SETRETAILX_EXTERNALSYSTEMS_ADMIN,
    SETRETAILX_EQUIPMENT_ADMIN,
    SETRETAILX_TOUCH_MENU_SCHEDULES_ADMIN,
    SETRETAILX_CHECK_DOC_EDITING,
    SETRETAILX_SALEGROUPS, SETRETAILX_GOODS_ADMIN
} from '../core/privileges/privileges'
import { lazy } from '../utils/code-splitting'
import { t } from 'i18next'
import { MenuNotification } from '../components/pages-container/notifications/menu-notification'
import { NOT_LOADED_PRODUCTS_ON_CASHES, NOT_LOADED_PRODUCTS_ON_SHOPS } from '../store/notification-store'
import { config } from '../config/config'

export const cashRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'cashesNavLink',
        label: getRouteName(CASH_MODULE),
        icon: <img src={require('../../assets/images/icons/cash/desktop-classic.svg')} alt="cashes"/>,
    },
    path: CASH_MODULE,
    children: [
        {
            navItemProps: {
                id: 'cashDevicesNavLink',
                label: getCustomRouteName(`${CASH_MODULE}${DEVICES}`),
            },
            path: `${CASH_MODULE}${DEVICES}`,
            privileges: [SETRETAILX_EQUIPMENT_ADMIN],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "cash-devices-list" */ '../pages/cashes/devices/cash-devices-list'
                )
            )
        },
        {
            path: `${CASH_MODULE}${DEVICES}${REGISTER}`,
            privileges: [SETRETAILX_EQUIPMENT_ADMIN],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "cash-devices-register" */ '../pages/cashes/devices/cash-devices-register'
                )
            )
        },
        {
            path: `${CASH_MODULE}${DEVICES}${SETTINGS}/:name`,
            privileges: [SETRETAILX_EQUIPMENT_ADMIN],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "cash-device-settings" */ '../pages/cashes/devices/settings/cash-device-settings'
                )
            )
        },
        {
            navItemProps: {
                id: 'cashTemplatesNavLink',
                label: getCustomRouteName(`${CASH_MODULE}${TEMPLATES}`),
            },
            path: `${CASH_MODULE}${TEMPLATES}`,
            privileges: [SETRETAILX_CASHTEMPLATE_ADMIN],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "cash-templates" */ '../pages/cashes/cash-templates/cash-templates'
                )
            )
        },
        {
            path: `${CASH_MODULE}${TEMPLATES}/:id`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_CASHTEMPLATE_ADMIN],
            component: lazy(() =>
                // tslint:disable-next-line:max-line-length
                import(
                    /* webpackChunkName: "cash-template-settings" */ '../pages/cashes/cash-templates/cash-template-settings/cash-template-settings'
                )
            )
        },
        {
            path: `${CASH_MODULE}${CASH_SCHEDULES}`,
            privileges: [SETRETAILX_TOUCH_MENU_SCHEDULES_ADMIN],
            navItemProps: {
                id: 'setTouchSchedulesNavLink',
                label: t('cashMenusSchedules.setTouchSchedulesNavLabel'),
            },
            component: lazy(() =>
                import(/* webpackChunkName: "cash-menu-schedules" */ '../pages/cashes/cash-menu-schedules/cash-menu-schedules')),
        },
        {
            path: `${CASH_MODULE}${CASH_SCHEDULES}/:scheduleId`,
            privileges: [SETRETAILX_TOUCH_MENU_SCHEDULES_ADMIN],
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "cash-menu-schedule-settings" */ '../pages/cashes/cash-menu-schedules/cash-menu-schedule-settings')),
        },
        {
            path: `${CASH_MODULE}${CASH_SCHEDULES}/:scheduleId/:menuId`,
            privileges: [SETRETAILX_TOUCH_MENU_SCHEDULES_ADMIN],
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "set-touch-menu-settings" */ '../pages/cashes/cash-menu-schedules/set-touch-menu-settings')),
        },
        {
            navItemProps: {
                id: 'checkFormsNavLink',
                label: getRouteName(CHECK_FORMS),
            },
            path: `${CASH_MODULE}${CHECK_FORMS}`,
            privileges: [SETRETAILX_CHECK_DOC_EDITING],
            component: lazy(() =>
                import(/* webpackChunkName: "check-forms-list" */ '../pages/cashes/check-forms/check-forms-list')),
        },
        {
            path: `${CASH_MODULE}${CHECK_FORMS}${NEW}`,
            privileges: [SETRETAILX_CHECK_DOC_EDITING],
            component: lazy(() =>
                import(/* webpackChunkName: "new-check-form-list" */ '../pages/cashes/check-forms/new-check-form-list')),
        },
        {
            path: `${CASH_MODULE}${CHECK_FORMS}${EDITOR}/:id`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_CHECK_DOC_EDITING],
            component: lazy(() =>
                import(/* webpackChunkName: "check-form-editor" */ '../pages/cashes/check-forms/check-form-editor/check-form-editor')),
        },
        {
            path: `${CASH_MODULE}${CHECK_FORMS}${EDITOR}/:id${CONDITIONS}`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_CHECK_DOC_EDITING],
            component: lazy(() =>
                import(/* webpackChunkName: "conditions-editor" */ '../pages/cashes/check-forms/check-form-editor/conditions-editor')),
        },
        {
            path: `${CASH_MODULE}${CHECK_FORMS}${EDITOR}/:id${FUNCTIONS}`,
            cannotBeRedirectTarget: true,
            privileges: [SETRETAILX_CHECK_DOC_EDITING],
            component: lazy(() =>
                import(/* webpackChunkName: "functions-editor" */ '../pages/cashes/check-forms/check-form-editor/functions-editor')),
        },
        {
            navItemProps: {
                id: 'paymentTypesNavLink',
                label: getRouteName(PAYMENT_TYPES),
            },
            path: `${CASH_MODULE}${PAYMENT_TYPES}`,
            privileges: [SETRETAILX_TYPESGONFIG],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "cash-payment-types" */ '../pages/cashes/payment-types/payment-types'
                )
            )
        },
        {
            path: `${CASH_MODULE}${PAYMENT_TYPES}/:paymentTypeId`,
            privileges: [SETRETAILX_TYPESGONFIG],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "cash-payment-types" */ '../pages/cashes/payment-types/payment-type-settings'
                )
            )
        },
        {
            navItemProps: {
                id: 'banksNavLink',
                label: getCustomRouteName(`${CASH_MODULE}${BANKS}`),
            },
            path: `${CASH_MODULE}${BANKS}`,
            privileges: [SETRETAILX_EXTERNALSYSTEMS_ADMIN],
            component: lazy(() => import(/* webpackChunkName: "banks" */ '../pages/cashes/banks/banks'))
        },
        {
            navItemProps: {
                id: 'cashProductListsNavLink',
                label: getRouteName(PRODUCT_LISTS),
            },
            path: `${CASH_MODULE}${PRODUCT_LISTS}`,
            privileges: [SETRETAILX_SALEGROUPS],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "product_lists" */ '../pages/cashes/cashes-product-lists/cashes-product-lists'
                )
            )
        },
        {
            path: `${CASH_MODULE}${PRODUCT_LISTS}/:listCode`,
            privileges: [SETRETAILX_SALEGROUPS],
            component: lazy(() =>
                import(
                    /* webpackChunkName: "product_list_settings" */ '../pages/cashes/cashes-product-lists/cashes-product-list-settings'
                )
            )
        },
        {
            navItemProps: {
                id: 'notLoadedToShopsNavLink',
                label: getRouteName(NOT_LOADED_TO_SHOPS),
                endAdornment: <MenuNotification notificationType={NOT_LOADED_PRODUCTS_ON_SHOPS}/>,
            },
            path: `${CASH_MODULE}${NOT_LOADED_TO_SHOPS}`,
            privileges: [SETRETAILX_GOODS_ADMIN],
            serverType: CENTRUM,
            component: lazy(() =>
                import(
                    /* webpackChunkName: "not-loaded-to-shops" */ '../pages/cashes/not-loaded-to-shops/not-loaded-to-shops'
                )
            )
        },
        {
            navItemProps: {
                id: 'notLoadedToCashesNavLink',
                label: getRouteName(NOT_LOADED_TO_CASHES),
                endAdornment: <MenuNotification notificationType={NOT_LOADED_PRODUCTS_ON_CASHES}/>,
            },
            path: `${CASH_MODULE}${NOT_LOADED_TO_CASHES}`,
            privileges: [SETRETAILX_GOODS_ADMIN],
            serverType: RETAIL,
            component: lazy(() =>
                import(
                    /* webpackChunkName: "not-loaded-to-cashes" */ '../pages/cashes/not-loaded-to-cashes/not-loaded-to-cashes'
                )
            )
        }
    ]
})
