import { CashMenuScheduleType } from './cash-menu-schedule-type'
import { MenuVO } from './menu-vo'

export interface CashMenuScheduleVO {
    '@class': 'ru.crystals.setretailx.cash.menu.CashMenuScheduleVO'
    guid?: number
    name?: string
    type?: CashMenuScheduleType
    menus?: MenuVO[]
}

export const createCashMenuScheduleVO = (initialization: Partial<CashMenuScheduleVO>): CashMenuScheduleVO => {
    if (!initialization) return null
    
    let result: CashMenuScheduleVO = {
        guid: initialization.guid,
        name: initialization.name,
        type: initialization.type,
        menus: initialization.menus,
        '@class': 'ru.crystals.setretailx.cash.menu.CashMenuScheduleVO',
    }
    
    return result
}
