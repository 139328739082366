export type CardType = 'COUPON_CARD' | 'PRESENT_CARD' | 'INTERNAL_CARD' | 'EXTERNAL_CARD' | 'BONUS_SBERBANK' | 'BONUS_CFT' | 'BONUS_PLASTEK' | 'CFT_GIFT_CARD' | 'CFT_EGC' | 'BONUS_AEROFLOT_CFT'

export const COUPON_CARD: CardType = 'COUPON_CARD'
export const PRESENT_CARD: CardType = 'PRESENT_CARD'
export const INTERNAL_CARD: CardType = 'INTERNAL_CARD'
export const EXTERNAL_CARD: CardType = 'EXTERNAL_CARD'
export const BONUS_SBERBANK: CardType = 'BONUS_SBERBANK'
export const BONUS_CFT: CardType = 'BONUS_CFT'
export const BONUS_PLASTEK: CardType = 'BONUS_PLASTEK'
export const CFT_GIFT_CARD: CardType = 'CFT_GIFT_CARD'
export const CFT_EGC: CardType = 'CFT_EGC'
export const BONUS_AEROFLOT_CFT: CardType = 'BONUS_AEROFLOT_CFT'


export interface Card {
    '@class': 'ru.crystals.operday.gui.datastructs.check.Card'
    cardID?: string
    cardName?: string
    cardHolder?: string
    accountNumber?: string
    accountName?: string
    num?: string
    type?: CardType
    location?: string
    dateTime?: string
    cardIDType?: number
    online?: boolean
}

export const createCard = (initialization: Partial<Card>): Card => {
    if (!initialization) return null
    
    let result: Card = {
        cardID: initialization.cardID,
        cardName: initialization.cardName,
        cardHolder: initialization.cardHolder,
        accountNumber: initialization.accountNumber,
        accountName: initialization.accountName,
        num: initialization.num,
        type: initialization.type,
        location: initialization.location,
        dateTime: initialization.dateTime,
        cardIDType: initialization.cardIDType,
        online: initialization.online,
        '@class': 'ru.crystals.operday.gui.datastructs.check.Card',
    }
    
    return result
}
