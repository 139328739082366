import { PriceCheckerType } from './price-checker-type'

export interface PriceCheckerVO {
    '@class': 'ru.crystals.setretailx.pricecheker.vo.PriceCheckerVO'
    number?: number
    mac?: string
    name?: string
    ipAddress?: string
    type?: PriceCheckerType
    equipmentModelId?: number
    active?: boolean
    scalesTemplateId?: number
    id?: number
    shopNumber?: number
    dateOfLastRequest?: Date
}

export const createPriceCheckerVO = (initialization: Partial<PriceCheckerVO>): PriceCheckerVO => {
    if (!initialization) return null
    
    let result: PriceCheckerVO = {
        number: initialization.number,
        mac: initialization.mac,
        name: initialization.name,
        ipAddress: initialization.ipAddress,
        type: initialization.type,
        equipmentModelId: initialization.equipmentModelId,
        active: initialization.active,
        scalesTemplateId: initialization.scalesTemplateId,
        id: initialization.id,
        shopNumber: initialization.shopNumber,
        dateOfLastRequest: initialization.dateOfLastRequest,
        '@class': 'ru.crystals.setretailx.pricecheker.vo.PriceCheckerVO',
    }
    
    return result
}
