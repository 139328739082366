import React from 'react'
import { t } from 'i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

import {
    ComparisonType,
    getAdvancedComparisonOpts,
} from '../../../../../../core/advertising-actions/action-conditions'
import { ConditionCounter } from './counter-editor'
import { isNil } from 'lodash'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./counter-editor.scss')

interface CounterEditorDialogProps {
    open: boolean
    counter?: ConditionCounter
    onClose: () => void
    onSave: (counter: ConditionCounter) => void
    editMode: boolean
    gotMasterActions?: boolean
}

interface CounterEditorDialogState {
    counter: ConditionCounter
    error?: string
}

export class CounterEditorDialog extends React.Component<CounterEditorDialogProps, CounterEditorDialogState> {
    constructor(props: CounterEditorDialogProps, context: any) {
        super(props, context)
        const { counter } = this.props

        this.state = {
            counter,
            error: null,
        }
    }

    handleCountersTriggersChange = (value: number): void => {
        this.setState(prevState => ({
            counter: {
                ...prevState.counter,
                value: [value * prevState.counter.multiplier[0]],
            },
            error: null,
        }))
    }

    handleCountersTriggersToChange = (secondValue: number): void => {
        this.setState(prevState => ({
            counter: {
                ...prevState.counter,
                secondValue: [secondValue * prevState.counter.multiplier[0]],
            },
            error: null,
        }))
    }

    handleTypeOfComparisonSelectChange = (comparison: ComparisonType): void => {
        this.setState(prevState => {
            let newCounter = {
                ...prevState.counter,
                comparison: [comparison],
            }

            if (comparison === ComparisonType.Between) {
                newCounter.secondValue = []
            }
            return {
                counter: newCounter,
                error: null,
            }
        })
    }

    handleIncludeCurrentPurchaseChange = (value: boolean): void => {
        this.setState(prevState => ({
            counter: {
                ...prevState.counter,
                includeCurrentPurchase: [value],
            },
            error: null,
        }))
    }

    handleSaveClick = (): void => {
        const { onSave } = this.props
        const { counter } = this.state
        const { value, secondValue, comparison, includeCurrentPurchase } = counter

        if (!this.isCounterValid({ value, secondValue, comparison })) return

        let editedCounter: ConditionCounter = {
            ...this.props.counter,
            value,
            comparison: comparison[0] ? comparison : [ComparisonType.More],
        }

        if (includeCurrentPurchase?.[0]) {
            editedCounter.includeCurrentPurchase = includeCurrentPurchase
        }

        if (comparison[0] === ComparisonType.Between) {
            editedCounter.secondValue = secondValue
        }

        onSave(editedCounter)
    }

    isCounterValid = ({ value, secondValue, comparison }: { value: number[], secondValue: number[], comparison: string[] }): boolean => {
        if (!value[0] && value[0] !== 0) {
            this.setState({ error: t('advertisingActions.counterCondition.counterConditionDialogError') })
            return false
        } else if (comparison[0] === ComparisonType.Between && (!value[0] || !secondValue?.[0])) {
            this.setState({ error: t('advertisingActions.counterCondition.counterConditionDialogError') })
            return false
        } else if (comparison[0] === ComparisonType.Between && value[0] >= secondValue?.[0]) {
            this.setState({ error: t('advertisingActions.checkConditions.moreLessError') })
            return false
        }

        return true
    }

    render() {
        const { open, onClose, gotMasterActions } = this.props
        const {
            counter,
            error,
        } = this.state
        const {
            value, secondValue, comparison, multiplier, possibleIncludeCurrentPurchase, includeCurrentPurchase
        } = counter

        if (!this.props.counter || !open) return null

        const { name } = this.props.counter

        const shownValue = (isNaN(value[0]) || isNil(value[0])) ? null : value[0] / multiplier[0]
        const shownSecondValue = (isNaN(secondValue?.[0]) || isNil(secondValue?.[0])) ? null : secondValue[0] / multiplier[0]

        return (
            <Dialog open={open} maxWidth="xs">
                <DialogTitle>{t('advertisingActions.settings')} {name[0]}</DialogTitle>
                <DialogContent style={{ minWidth: 320 }}>
                    <SelectInput
                        className={styles.input}
                        options={getAdvancedComparisonOpts()}
                        value={comparison[0] || ComparisonType.Equal}
                        onSelect={opt => this.handleTypeOfComparisonSelectChange(opt.value)}
                    />
                    <NumberInput
                        key="count"
                        id="count"
                        className={styles.input}
                        canBeNull
                        label={
                            comparison[0] !== ComparisonType.Between ?
                                t('advertisingActions.counterCondition.counterConditionCount') :
                                t('advertisingActions.counterCondition.counterConditionCountFrom')
                        }
                        value={shownValue}
                        onValueChange={this.handleCountersTriggersChange}
                    />
                    {comparison[0] === ComparisonType.Between && (
                        <NumberInput
                            key="secondValue"
                            id="secondValue"
                            className={styles.input}
                            canBeNull
                            label={t('advertisingActions.counterCondition.counterConditionCountTo')}
                            value={shownSecondValue}
                            onValueChange={this.handleCountersTriggersToChange}
                        />
                    )}
                    {error && (
                        <p className={styles.counterConditionEditorError}>{error}</p>
                    )}

                    {possibleIncludeCurrentPurchase?.[0] && gotMasterActions && (
                        <Checkbox
                            id="includeCurrentPurchaseInput"
                            label={t('advertisingActions.counterCondition.includeCurrentPurchase')}
                            checked={Boolean(includeCurrentPurchase?.[0])}
                            onValueChange={this.handleIncludeCurrentPurchaseChange}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => onClose()}>
                        {t('common.cancel')}
                    </Button>
                    <Button color="primary" onClick={() => this.handleSaveClick()}>
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
