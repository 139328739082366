export type ReportOrderStatusEnum = 'NEW' | 'COLLECT_DATA' | 'CREATING_INDICES' | 'WRITE_DOWN' | 'INTERRUPTING' | 'INTERRUPTED' | 'ERROR' | 'COMPLETE'

export const NEW: ReportOrderStatusEnum = 'NEW'
export const COLLECT_DATA: ReportOrderStatusEnum = 'COLLECT_DATA'
export const CREATING_INDICES: ReportOrderStatusEnum = 'CREATING_INDICES'
export const WRITE_DOWN: ReportOrderStatusEnum = 'WRITE_DOWN'
export const INTERRUPTING: ReportOrderStatusEnum = 'INTERRUPTING'
export const INTERRUPTED: ReportOrderStatusEnum = 'INTERRUPTED'
export const ERROR: ReportOrderStatusEnum = 'ERROR'
export const COMPLETE: ReportOrderStatusEnum = 'COMPLETE'
