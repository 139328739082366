import * as React from 'react'
import classNames from 'classnames'

const styles = require('./status-paper.scss')

export type StatusPaperMode = 'title' | 'normal' | 'error'
export const TITLE: StatusPaperMode = 'title'
export const NORMAL: StatusPaperMode = 'normal'
export const ERROR: StatusPaperMode = 'error'

export interface StatusPaperProps extends React.HTMLProps<HTMLDivElement> {
    /**
     * Заголовок, который выводится в режиме TITLE
     */
    title?: string
    /**
     * Сообщение, которое выводится в режимах: NORMAL, ERROR
     */
    message?: string
    /**
     * Режим отображения плашки.
     * TITLE - выводится заголовок, задник не подсвечивается, например: "Выбор пользователей", удобно для первого захода на форму редактирования
     * NORMAL - выводится сообщение на заднике серого цвета, например: "Выбрано пользователей: 12"
     * ERROR - выводится сообщение на заднике красного цвета, например: "Не выбрано ни одного пользователя"
     */
    mode?: StatusPaperMode
    /**
     * Дополнительные компоненты, которые выводятся справа, например, кнопки удаления или что-то еще
     */
    actionComponents?: React.ReactNode
}

export class StatusPaper extends React.Component<StatusPaperProps> {

    static defaultProps: Partial<StatusPaperProps> = {
        id: 'statusPaper',
        title: '',
        message: '',
        mode: TITLE,
    }

    render() {
        const {
            id, className, title, message, mode, actionComponents, ...other
        } = this.props

        return (
            <div
                id={id}
                className={classNames(className, styles.statusPaper, {
                    [styles.error]: mode === ERROR,
                    [styles.normal]: mode === NORMAL,
                })}
                {...other}
            >
                {
                    mode === TITLE && (
                        <p className={styles.title}>
                            {title}
                        </p>
                    )
                }
                {
                    mode !== TITLE && (
                        <p className={styles.message}>
                            {message}
                        </p>
                    )
                }
                {
                    actionComponents && (
                        <div>
                            {actionComponents}
                        </div>
                    )
                }
            </div>
        )
    }
}
