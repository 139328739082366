import { PrivilegeVO } from './privilege-vo'

export interface UserRoleVO {
    '@class': 'ru.crystals.setretailx.users.UserRoleVO'
    id?: number
    roleName?: string
    centrum?: boolean
    privilegeVOList?: PrivilegeVO[]
    guid?: string
}

export const createUserRoleVO = (initialization: Partial<UserRoleVO>): UserRoleVO => {
    if (!initialization) return null
    
    let result: UserRoleVO = {
        id: initialization.id,
        roleName: initialization.roleName,
        centrum: initialization.centrum,
        privilegeVOList: initialization.privilegeVOList,
        guid: initialization.guid,
        '@class': 'ru.crystals.setretailx.users.UserRoleVO',
    }
    
    return result
}
