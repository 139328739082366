import {action, observable, runInAction, computed} from 'mobx'
import {RESIZE} from '../../utils/dom/dom-events'
import {TopologyMap} from '../components/topology-filter/topology-map'
import { iTopologyManagerLocal } from '../../protocol/set10/i-topology-manager-local'
import { AppDialogProps, DialogStore } from './dialog-store'
import {t} from 'i18next'
import { SnackbarStore, SnackData } from './snackbar-store'
import { InformationAboutServerVO } from '../../protocol/set10/set-retail10-commons/data-structs-module/information-about-server-vo'
import { ModuleDescriptorVO } from '../../protocol/set10/set-retail10-commons/data-structs-module/module-descriptor-vo'
import { externalSystemsSettings } from '../../protocol/set10/external-systems-settings'
import {getCurrencySymbol, getCurrencyText, Currency} from '../core/currency-utils'
import { getStore } from './stores-repository'
import { DIALOG_STORE, SNACKBAR_STORE } from './stores'
import {LicenseInfoVO} from '../../protocol/set10/set-retail10-commons/set-hasp-commons/license-info-vo'
import { iTopologySearcherLocal } from '../../protocol/set10/i-topology-searcher-local'
import { ERROR } from '../../components/simple-dialog/simple-dialog'
import { printingModuleConfigurator } from '../../protocol/set10/printing-module-configurator'
import {
    PrintingModulePropertiesVO
} from '../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/printing-module-properties-vo'
import { ServerModule } from '../core/app/server-module'
import { TopologyBundleVO } from '../../protocol/set10/set-retail10-commons/data-structs-module/topology-bundle-vo'
import { APP_LOCALE } from '../../locale/languages'
import { localStorageManager } from '../utils/local-storage-util'
import { AvailableThemes, AvailableThemesKeys } from '../core/themes'

export const DEFAULT_SCREEN_WIDTH: number = 1024
export const DEFAULT_SCREEN_HEIGHT: number = 768
export const CHECK_CONNECTION_TO_CENTRUM_AWAIT_TIME = 10000

interface EditorsVisibilitySettings {
    actionResultQuantity: boolean
    isLoyaltyCounterEnabled: boolean
}

// Стор не должен содержать пользовательских данных
export class AppStore {
    @observable
    screenWidth: number = DEFAULT_SCREEN_WIDTH

    @observable
    screenHeight: number = DEFAULT_SCREEN_HEIGHT

    @observable
    serverInfo: InformationAboutServerVO

    @observable
    isCentrum: boolean = false

    @observable
    connectedToCentrum: boolean = false

    @observable
    topologyMap: TopologyMap

    @observable
    loading: boolean = false

    @observable
    currencySymbol: string = ''

    @observable
    currencyText: string = ''

    @observable
    licenseInfo: LicenseInfoVO

    @observable
    printingModuleProperties: PrintingModulePropertiesVO

    @observable
    serverTimezoneOffset: number = 0

    @observable
    editorsVisibilitySettings: EditorsVisibilitySettings = {
        actionResultQuantity: false,
        isLoyaltyCounterEnabled: false,
    }

    @observable
    theme: AvailableThemesKeys = AvailableThemes.LIGHT_THEME

    private dialogStore: DialogStore = getStore(DIALOG_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    constructor() {
        window.addEventListener(RESIZE, this.resizeHandler)

        this.resizeHandler()
    }

    fetchEditorsVisibilitySettings = async (): Promise<void> => {
        const promises = [
            this.isLoyaltyRestrictionsEnabled(),
            this.isLoyaltyCounterEnabled(),
        ]
        const allData = await Promise.all(promises)
        const _editorsVisibilitySettings: EditorsVisibilitySettings = this.editorsVisibilitySettings

        allData?.forEach(data => _editorsVisibilitySettings[data.key] = data.value)
        runInAction(() => {
            this.editorsVisibilitySettings = _editorsVisibilitySettings
        })
    }

    isLoyaltyRestrictionsEnabled = async (): Promise<{ key: string, value: boolean }> => {
        const value = await externalSystemsSettings.enabled('set-loyalty-restrictions')
        return { key: 'actionResultQuantity', value }
    }

    isLoyaltyCounterEnabled = async (): Promise<{ key: string, value: boolean }> => {
        const value = await externalSystemsSettings.enabled('set-loyalty-counters')
        return { key: 'isLoyaltyCounterEnabled', value }
    }

    @computed
    get locale(): APP_LOCALE {
        return (this.serverInfo && this.serverInfo.serverLocale as APP_LOCALE) || 'ru_RU'
    }

    now = (): Date => { // for mocking in tests
        return new Date()
    }

    getModuleInfo = (module: ServerModule): ModuleDescriptorVO => {
        if (!this.serverInfo || !this.serverInfo.modulesDescriptors) return null
        return this.serverInfo.modulesDescriptors.find(m => m.id === module)
    }

    resizeHandler = (event?: Event) => {
        this.screenWidth = window.innerWidth
        this.screenHeight = window.innerHeight
    }

    fetchTopologyMap = (): Promise<void> => {
        return iTopologyManagerLocal.getTopologyBundle()
            .then(bundle => {
                this.setTopologyMapFromBundle(bundle)
            })
    }

    @action
    setTopologyMapFromBundle = (bundle: TopologyBundleVO): void => {
        this.topologyMap = TopologyMap.fromTopologyBundle(bundle)
    }

    getPrintingModuleSettings = async (): Promise<void> => {
        const settings = await printingModuleConfigurator.getProperties1()
        runInAction(() => {
            this.printingModuleProperties = settings
        })
    }

    @action
    loadingStart = () => {
        if (this.loading) return
        this.loading = true
    }

    @action
    loadingStop = () => {
        if (!this.loading) return
        this.loading = false
    }

    @action
    setIsCentrum = (value: boolean): void => {
        this.isCentrum = value
        if (!value) {
            this.refreshCentrumConnectionStatus()
        }
    }

    @action
    setCurrencySymbol = (currency?: Currency) => {
        this.currencySymbol = getCurrencySymbol(currency)
        const currencyTextKey = getCurrencyText(currency)
        this.currencyText = currencyTextKey
            ? t(`common.currencyText.${currencyTextKey}`)
            : currencyTextKey
    }

    @action
    setTheme = (theme: AvailableThemesKeys = 'LIGHT_THEME') => {
        this.theme = theme
        localStorageManager.setTheme(theme)
    }

    showDialog = (dialogProps: AppDialogProps) => {
        this.dialogStore.showDialog(dialogProps)
    }

    showErrorDialog = (message: string, title: string = '') => {
        this.dialogStore.showDialog({
            title: title || t('common.commonErrorTitle'),
            mode: ERROR,
            message
        })
    }

    showSnackbar = (snackData: SnackData): void => {
        this.snackbarStore.show(snackData)
    }

    scrollToTop = (): void => {
        const pageContent = document.getElementById('pageContent')
        if (pageContent) pageContent.scrollTop = 0
    }

    refreshCentrumConnectionStatus = async (): Promise<void> => {
        if (this.isCentrum) return Promise.resolve()

        const result = await Promise.race<Promise<boolean>>([
            iTopologySearcherLocal.isConnectionToCentrum(),
            new Promise(resolve => {
                setTimeout(resolve, CHECK_CONNECTION_TO_CENTRUM_AWAIT_TIME, false)
            })
        ])

        runInAction(() => {
            this.connectedToCentrum = result
        })
    }

    get lentaBuild(): boolean {
        return this.serverInfo?.serverBuildType === 'lenta'
    }
}
