import * as React from 'react'
import {t} from 'i18next'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ReportProblem from '@material-ui/icons/ReportProblem'
import classNames from 'classnames'

const styles = require('./simple-dialog.scss')

export type DialogMode = 'dialog' | 'alert' | 'blocker' | 'error' | 'dialogCancelable'

export const DIALOG: DialogMode = 'dialog'
export const DIALOG_CANCELABLE: DialogMode = 'dialogCancelable'
export const ALERT: DialogMode = 'alert'
export const ERROR: DialogMode = 'error'
export const BLOCKER: DialogMode = 'blocker'

export interface SimpleDialogProps extends DialogProps {
    message: string | JSX.Element
    /**
     * dialog: кнопки Yes/No;
     * alert: кнопка Ok;
     * error: кнопка Ok и иконка ошибки в заголовке
     * blocker: без кнопок
     */
    mode?: DialogMode
    onYes?, onNo?, onOk?, onCancel?: () => void
    title?: string
    titleIcon?: React.ReactNode
    yesLabel?, noLabel?, okLabel?: string
}

export class SimpleDialog extends React.Component<SimpleDialogProps> {
    static defaultProps: Partial<SimpleDialogProps> = {
        id: 'simpleDialog',
        mode: ALERT,
        yesLabel: '',
        noLabel: '',
        okLabel: '',
        onYes: () => null,
        onNo: () => null,
        onCancel: () => null,
        onOk: () => null,
    }

    renderDialogActionsByMode = (mode: DialogMode): React.ReactNode => {
        const {id, onYes, onNo, onOk, yesLabel, noLabel, okLabel, onCancel} = this.props
        switch (mode) {
            case DIALOG:
                return (
                    <DialogActions id={`${id}-dialogActons`}>
                        <Button id={`${id}-noButton`} onClick={onNo} color="primary" autoFocus>
                            {noLabel || t('common.no')}
                        </Button>
                        <Button id={`${id}-yesButton`} onClick={onYes} color="primary">
                            {yesLabel || t('common.yes')}
                        </Button>
                    </DialogActions>
                )
            case DIALOG_CANCELABLE:
                return (
                    <DialogActions id={`${id}-dialogActons`}>
                        <Button id={`${id}-cancelButton`} onClick={onCancel} autoFocus color="primary">
                            {noLabel || t('common.undo')}
                        </Button>
                        <div style={{ flex: 1 }}/>
                        <Button id={`${id}-noButton`} onClick={onNo} color="primary">
                            {noLabel || t('common.no')}
                        </Button>
                        <Button id={`${id}-yesButton`} onClick={onYes} color="primary">
                            {yesLabel || t('common.yes')}
                        </Button>
                    </DialogActions>
                )
            case ALERT:
            case ERROR:
                return (
                    <DialogActions id={`${id}-dialogActons`}>
                        <Button id={`${id}-okButton`} onClick={onOk} color="primary" autoFocus>
                            {okLabel || t('common.ok')}
                        </Button>
                    </DialogActions>
                )
            case BLOCKER:
            default:
                return null
        }
    }

    render() {
        const {id, open, message, mode, title, onYes, onNo, onOk, yesLabel, noLabel, okLabel, titleIcon, ref, ...other} = this.props
        const errorMode = mode === ERROR
        let text = title
        let icon = titleIcon

        if (!text && errorMode) {
            text = t('components.error')
        }

        if (!icon && errorMode) {
            icon = <ReportProblem />
        }

        return (
            <Dialog
                id={id}
                open={open}
                {...other}
            >
                {text &&
                    <DialogTitle
                        id={`${id}-title`}
                        className={classNames(styles.title, {
                            [styles.error]: errorMode
                        })}
                        disableTypography
                    >
                        {icon}
                        <div className={styles.titleText}>
                            {text}
                        </div>
                    </DialogTitle>
                }
                <DialogContent id={`${id}-content`}>
                    <DialogContentText
                        id={`${id}-message`}
                        className={styles.message}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                {this.renderDialogActionsByMode(mode)}
            </Dialog>
        )
    }
}
