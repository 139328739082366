export interface CashPrintFormVO {
    '@class': 'ru.crystals.setretailx.cash.CashPrintFormVO'
    id?: number
    documentName?: string
    count?: number
    xmlRepresentation?: string
    deleted?: boolean
    valid?: boolean
    sentToCash?: boolean
}

export const createCashPrintFormVO = (initialization: Partial<CashPrintFormVO>): CashPrintFormVO => {
    if (!initialization) return null
    
    let result: CashPrintFormVO = {
        id: initialization.id,
        documentName: initialization.documentName,
        count: initialization.count,
        xmlRepresentation: initialization.xmlRepresentation,
        deleted: initialization.deleted,
        valid: initialization.valid,
        sentToCash: initialization.sentToCash,
        '@class': 'ru.crystals.setretailx.cash.CashPrintFormVO',
    }
    
    return result
}
