import React from 'react'
import { t } from 'i18next'
import Paper from '@material-ui/core/Paper'

import { LoyaltySettingsActionTerms } from './loyalty-settings-action-terms'
import { LoyaltySettingsGeneralOptions } from './loyalty-settings-general-options'

const styles = require('../loyalty-settings.scss')

export class LoyaltySettingsGeneral extends React.Component {
    render() {
        return (
            <Paper className={styles.general}>

                <div className={styles.header}>{t('loyaltySettings.generalSettings')}</div>

                <LoyaltySettingsActionTerms />

                <LoyaltySettingsGeneralOptions />

            </Paper>
        )
    }
}
