import React from 'react'
import { ConditionEditorProps } from '../types'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import {
    findCondition, isSelectedDay,
    removeCondition,
    TimeConditions,
    updateCondition
} from '../../../../../../core/advertising-actions/action-conditions'
import { t } from 'i18next'

const defaultValuesDaysOfTheWeek = ['false', 'false', 'false', 'false', 'false', 'false', 'false']

export const getWeekDays = () => [
    {
        id: 'Monday',
        label: t('common.weekdays.monday'),
        index: 1
    },
    {
        id: 'Tuesday',
        label: t('common.weekdays.tuesday'),
        index: 2
    },
    {
        id: 'Wednesday',
        label: t('common.weekdays.wednesday'),
        index: 3
    },
    {
        id: 'Thursday',
        label: t('common.weekdays.thursday'),
        index: 4
    },
    {
        id: 'Friday',
        label: t('common.weekdays.friday'),
        index: 5
    },
    {
        id: 'Saturday',
        label: t('common.weekdays.saturday'),
        index: 6
    },
    {
        id: 'Sunday',
        label: t('common.weekdays.sunday'),
        index: 0
    }
]

export class DaysOfTheWeekEditor extends React.Component<ConditionEditorProps> {
    updateOrRemoveChip = (newDaysOfWeek: string[]) => {
        const { value, onChange } = this.props
        let selectedDaysCounter = 0

        for (const day of newDaysOfWeek) {
            if (isSelectedDay(day)) {
                selectedDaysCounter++
            }
        }

        if (!selectedDaysCounter) {
            return onChange(removeCondition(TimeConditions.DaysOfTheWeekCondition, value))
        }

        return onChange(updateCondition(
            TimeConditions.DaysOfTheWeekCondition,
            { days: [{ boolean: newDaysOfWeek }] },
            value
        ))
    }

    render() {
        const { value, readOnly } = this.props
        const existingCondition = findCondition(TimeConditions.DaysOfTheWeekCondition, value)
        const daysOfTheWeek = existingCondition ? existingCondition.data.days[0].boolean : [...defaultValuesDaysOfTheWeek]
        return (
            <div style={{ padding: '16px 24px 24px 24px' }}>
                {getWeekDays().map(day => {
                    return (
                        <Checkbox
                            id={day.id}
                            color="primary"
                            key={day.id}
                            label={day.label}
                            disabled={readOnly}
                            checked={isSelectedDay(daysOfTheWeek[day.index])}
                            onValueChange={flag => {
                                const newDaysOfWeek = daysOfTheWeek
                                newDaysOfWeek.splice(day.index, 1, `${flag}`)
                                this.updateOrRemoveChip(newDaysOfWeek)
                            }}
                        />
                    )
                })}
            </div>
        )
    }
}
