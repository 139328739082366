import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { t } from 'i18next'
import React from 'react'
import uuid from 'uuid'
import {
    FilterType, getFilterTypeName, EMPTY_FILTER_VALUE, toSearchArgument
} from '../../core/filters/filter'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { observer, inject } from 'mobx-react'
import { FAVORITE_FILTERS_STORE } from '../../store/stores'
import { FavoriteFiltersStore } from '../../store/operday/favorite-filters-store'
import { FiltersComponentInstance } from '../../core/filters/favorite-filters'
import Tooltip from '@material-ui/core/Tooltip'
import { TOOLTIP_DELAY } from '../../../utils/default-timeouts'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import { FILTER_GROUP } from './components/filter-group'
import { toJS } from 'mobx'
import { FilterProps } from './new-filters'
import Paper from '@material-ui/core/Paper'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { ActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/action-panel'
import { isIE11 } from '../../../utils/ie11-util'

const styles = require('./side-bar-filters.scss')

/**
 * Получение searchArguments из объекта shownFilters, который должен быть получен из state
 */
export const getSearchArguments = (shownFilters: {[key in FilterType]?: FilterProps}) => {
    if (!shownFilters) return []

    let searchArguments: SearchArgumentVO[] = []

    Object.keys(shownFilters).forEach((key: FilterType) => {
        const filter = shownFilters[key]
        let value = filter.value
        if (value) {
            if (value === EMPTY_FILTER_VALUE) {
                value = undefined
            }
            searchArguments = searchArguments.concat(toSearchArgument(key, value))
        }
    })
    return searchArguments
}

export interface SideBarFiltersProps {
    id?: string
    /**
     * Заголовок над фильтрами
     */
    title?: string
    /**
     * Использовать ли Paper в качестве контейнера
     */
    withPaper?: boolean
    /**
     * Стиль для контейнера
     */
    containerClassName?: string
    /**
     * Дефолтное состояние фильтров, необязательно задавать все свойства.
     * Если свойство не задано, то в него подставится дефолтное значение.
     */
    defaultState?: SideBarFiltersState
    /**
     * Элемент, отображаемый между заголовком и фильтрами
     */
    actionElement?: React.ReactElement<any>
    /**
     * Режим отображения верхнего бара.
     * В режиме 'full' отображаются только favorite фильтры, или те, которые имеют введенное значение
     * При отключенном экспандере (или спрятанном баре) отображаются все фильтры
     */
    topBarMode?: 'full' | 'hideExpander' | 'hidden'
    /**
     * Ключ для работы с избранными фильтрами в local storage. Если не указан - избранные фильтры отключены
     */
    favoriteFiltersKey?: FiltersComponentInstance

    onApply: (searchArguments: SearchArgumentVO[]) => void
    applyDisabled?: boolean
    onClear?: (state: SideBarFiltersState) => void
    onStateChange?: (state: SideBarFiltersState) => void

    /**
     * Инжектится через mobx
     */
    favoriteFiltersStore?: FavoriteFiltersStore
}

export interface SideBarFiltersState {
    key?: string
    /**
     * Отображать только избранные фильтры
     */
    showOnlyFavorite?: boolean
    /**
     * Статус сворачивания отдельных групп. В качестве ключа индекс среди children компонента
     */
    closedGroups?: Map<string, boolean>
    /**
     * Показанные фильтры, эти фильтры показываются в "show Favorite" состоянии с указанным значением
     */
    shownFilters?: {[key in FilterType]?: FilterProps}
    /**
     * Строка поиска по фильтрам
     */
    searchString?: string
}

@inject(FAVORITE_FILTERS_STORE)
@observer
export class SideBarFilters extends React.Component<SideBarFiltersProps, SideBarFiltersState> {

    static defaultProps: Partial<SideBarFiltersProps> = {
        id: 'filter',
        onClear: () => null,
        onStateChange: () => null,
        topBarMode: 'full',
    }

    constructor(props: SideBarFiltersProps, context: any) {
        super(props, context)

        const { defaultState, favoriteFiltersKey, onStateChange } = this.props

        // toJS на случай, если в пропс передали observable
        let state = toJS(defaultState) || {}

        let closedGroups = new Map<string, boolean>()
        if (state.closedGroups) {
            // Map после toJS станет обычным объектом, поэтому пересоздаем Map
            defaultState.closedGroups.forEach((value, key) => {
                closedGroups.set(key, value)
            })
        }
        state.closedGroups = closedGroups

        let shownFilters: {[key in FilterType]?: FilterProps} = state.shownFilters || {}

        if (favoriteFiltersKey) {
            const { defaultFilters } = this.props.favoriteFiltersStore

            defaultFilters.get(favoriteFiltersKey).forEach(filter => {
                // TODO: Хранение в favoriteFilter надо изменить
                // Стоит сделать хранение флага isFavorite причем конкретно true/false/null
                // null - пользователь не трогал эту звездочку
                // false - пользователь умышленно выключил звездочку (в этом случае не сработает defaultFilter)
                const type = filter.type
                if (!shownFilters[type]) {
                    shownFilters[type] = {}
                }
                shownFilters[type].isFavorite = true
            })
        }
        state.shownFilters = shownFilters

        this.state = state
        onStateChange(state)
    }

    expandHandler = (): void => {
        const { onStateChange } = this.props
        this.setState({
            showOnlyFavorite: false,
        },
        () => onStateChange(this.state))
    }

    collapseHandler = (): void => {
        const { onStateChange } = this.props
        this.setState(prevState => {
            // Обновляем флаг alwaysShown. Он должен остаться у фильтров с выбранным значением
            let shownFilters = prevState.shownFilters
            Object.keys(shownFilters).forEach((key: FilterType) => {
                const filter = shownFilters[key]
                filter.alwaysShown = Boolean(filter.value)
            })

            // Раскрываем все доступные группы
            let closedGroups = new Map<string, boolean>()

            return {
                showOnlyFavorite: true,
                closedGroups,
                shownFilters
            }
        },
        () => onStateChange(this.state))
    }

    favoriteChangeHandler = (type: FilterType, favorite: boolean): void => {
        const { changeDefaultFilters } = this.props.favoriteFiltersStore
        const { favoriteFiltersKey } = this.props

        let newFavorites: FilterType[] = []
        if (favorite) {
            newFavorites.push(type)
        }

        const shownFilters = this.state.shownFilters
        Object.keys(shownFilters).forEach((key: FilterType) => {
            // Изменяемому фильтру уже поставили статус
            if (key === type) {
                return
            }

            // Остальным фильтрам оставляем какой был
            if (shownFilters[key].isFavorite) {
                newFavorites.push(key)
            }
        })

        changeDefaultFilters(favoriteFiltersKey, newFavorites)

        this.filterChangeHandler(type, { isFavorite: favorite })
    }

    filterChangeHandler = (type: FilterType, changes: FilterProps): void => {
        const { onStateChange } = this.props

        this.setState(prevState => {
            let newState = prevState
            let filter = newState.shownFilters[type] || {}

            Object.keys(changes).forEach(key => {
                filter[key] = changes[key]
            })
            newState.shownFilters[type] = filter

            return newState
        },
        () => onStateChange(this.state))
    }

    applyHandler = (): void => {
        const { onApply } = this.props
        const { shownFilters } = this.state

        const searchArguments = getSearchArguments(shownFilters)

        onApply(searchArguments)

        // Нужно для перерисовки фильтров (в основном input'ов), в которых пользователь ввёл некорректное значение
        // Например, если пользователь ввёл некорректные даты в фильтре checkTime, это будет обработано как если бы фильтр был не задан
        this.setState({ key: uuid() })
    }

    clearHandler = (): void => {
        const { onStateChange, onClear } = this.props
        this.setState(prevState => {
            let shownFilters = prevState.shownFilters

            Object.keys(shownFilters).forEach(key => {
                // Обнуляем все значения
                shownFilters[key].value = null
                shownFilters[key].data = null
                shownFilters[key].wasShown = null
            })

            return {
                // Из-за того что многие фильтры uncontrolled. Необходимо их полностью обновлять
                key: uuid(),
                shownFilters
            }
        },
        () => {
            onStateChange(this.state)
            onClear(this.state)
        })
    }

    renderFavoriteButton = (type: FilterType, favorite: boolean) => {
        const { favoriteFiltersKey } = this.props
        if (!favoriteFiltersKey) return

        return (
            <div
                className={classNames(styles.favoriteButton, {
                    [styles.active]: favorite
                })}
            >
                <Tooltip
                    id={`${type}-favoriteTooltip`}
                    title={
                        favorite
                            ? t('filters.removeFilterFromFavorites')
                            : t('filters.addFilterToFavorites')
                    }
                    enterDelay={TOOLTIP_DELAY}
                >
                    <div className={styles.tooltipContainer}>
                        {favorite
                            /* IconButton не использовал намеренно, т.к. он предполагает показ "ореола" при наведении */
                            ? <Star
                                id={`${type}-removeFavorite`}
                                onClick={() => this.favoriteChangeHandler(type, false)}
                            />
                            : <StarBorder
                                id={`${type}-addFavorite`}
                                onClick={() => this.favoriteChangeHandler(type, true)}
                            />
                        }
                    </div>
                </Tooltip>
            </div>
        )
    }

    handleGroupExpandChange = (id: string, expanded: boolean): void => {
        const { onStateChange } = this.props

        this.setState(prevState => {
            let newClosedGroups = prevState.closedGroups || new Map<string, boolean>()
            newClosedGroups.set(id, !expanded)

            return {
                closedGroups: newClosedGroups
            }
        },
        () => {
            onStateChange(this.state)
        })
    }

    renderFilters = (children: React.ReactNode): React.ReactNode[] => {
        const { shownFilters, closedGroups } = this.state
        const childrenArray = React.Children.toArray(children)
        let filters: React.ReactNode[] = []

        // Проверяем все элементы из children, и добавляем им нужные props
        childrenArray.forEach((element: any, index: number) => {
            const filterType = element.type.filterType
            const id = String(index)

            if (filterType === FILTER_GROUP)  {
                // Группа фильтров
                const groupName = element.props.name
                const closed = closedGroups && closedGroups.get(id)

                // Отрисовываем только непустые группы
                if (element.props.children && element.props.children.length > 0) {
                    filters.push(React.cloneElement(element, {
                        key: groupName,
                        id,
                        // Фильтры внутри группы тоже надо обработать
                        children: this.renderFilters(element.props.children),
                        expandable: true,
                        expanded: !closed,
                        onExpandChange: this.handleGroupExpandChange
                    }))
                }
            } else {
                const filterState = shownFilters[filterType] || {}

                filters.push(
                    <Grid
                        key={filterType}
                        className={classNames(styles.filterModuleContainer, {
                            [styles.ie11Support]: isIE11
                        })}
                        item xs={12}
                    >
                        {
                            React.cloneElement(element, {
                                ...filterState,
                                id: filterType,
                                slim: true,
                                // Подставляем базовое значение т.к. некоторые фильтры не работают с null
                                value: filterState.value || '',
                                onChange: this.filterChangeHandler,
                            })
                        }
                        {
                            this.renderFavoriteButton(filterType, filterState.isFavorite)
                        }
                    </Grid>
                )
            }
        })

        return filters
    }

    // Функция фильтрует группы и отдельные фильтры по всем признакам, включая строку поиска
    filterShownChildren = (children: React.ReactNode): React.ReactNode => {
        const { searchString, shownFilters, showOnlyFavorite } = this.state
        const trimmedSearchString = searchString && searchString.trim().toLowerCase()

        let newChildrenArray = []
        React.Children.toArray(children).forEach((element: any) => {
            const filterType = element.type.filterType

            if (filterType === FILTER_GROUP) {
                // Если часть группы входит в строку, то все фильтры отображаем
                const trimmedName = element.props?.name?.trim().toLowerCase()
                if (trimmedSearchString && trimmedName.indexOf(trimmedSearchString) !== -1) {
                    const elementChildren = React.Children.toArray(element.props.children)
                    newChildrenArray.push(React.cloneElement(element, {
                        children: elementChildren
                    }))
                } else {
                    // Строка поиска не задана, либо не найдена - ищет отдельно каждый элемент
                    const elementChildren = this.filterShownChildren(element.props.children)
                    newChildrenArray.push(React.cloneElement(element, {
                        children: elementChildren
                    }))
                }
            } else {
                if (!filterType) {
                    // Все фильтры должны содержать в себе "static filterType"
                    console.warn('Filter without type: ', element.type.name)
                    return
                }
                const filterState = shownFilters[filterType] || {}

                // В режиме SideBarFilter не используется defaultFavorite (свойство отдельного фильтра)
                const favoriteFilter = filterState.isFavorite

                /**
                 * Отображается если :
                 * 1. Развернутый режим
                 * 2. Фильтр помечен как favorite
                 * 3. alwaysShown - в момент сворачивания у фильтра было значение
                 * 4. у фильтра есть значение
                 * 5. фильтр найден через поисковую строку
                 */
                const filterName = getFilterTypeName(filterType)
                const filterFound = trimmedSearchString && filterName.trim().toLowerCase().indexOf(trimmedSearchString) !== -1
                if (!showOnlyFavorite || favoriteFilter || filterState.alwaysShown || filterState.value || filterFound) {
                    // Фильтрацию дополнительно проводим по строке поиска
                    if (!trimmedSearchString || filterFound) {
                        newChildrenArray.push(element)
                    }
                }
            }
        })

        return newChildrenArray
    }

    render() {
        const {
            title = t('filters.filter'), topBarMode, id, children, withPaper, actionElement, applyDisabled, containerClassName,
        } = this.props
        const { showOnlyFavorite, key, searchString } = this.state

        const filteredItems = this.filterShownChildren(children)

        const content = (
            <>
                { topBarMode !== 'hidden' && (
                    <>
                        <div className={styles.titleContainer}>
                            <span className={styles.title}>{title}</span>
                            {topBarMode === 'full' &&
                                <Button
                                    color={showOnlyFavorite ? 'primary' : 'secondary'}
                                    className={styles.expandButton}
                                    onClick={showOnlyFavorite ? this.expandHandler : this.collapseHandler }
                                >
                                    <Star />
                                    {t('filters.favorite')}
                                </Button>
                            }
                        </div>
                        <FilterInput
                            id="searchFilter"
                            className={styles.filterInput}
                            placeholder={t('filters.searchFilter')}
                            value={searchString || ''}
                            onValueChange={value => this.setState({ searchString: value })}
                            slim
                        />
                        <Divider className={styles.divider}/>
                    </>
                )}

                {actionElement && (
                    <>
                        <div className={styles.actionElement}>
                            {actionElement}
                        </div>
                        <Divider className={styles.divider}/>
                    </>
                )}

                <Grid
                    container
                    spacing={0}
                    className={styles.modulesContainer}
                >
                    { this.renderFilters(filteredItems) }
                </Grid>

                <ActionPanel>
                    <Button
                        color="primary"
                        id={`${id}ClearButton`}
                        className={styles.actionButton}
                        variant="text"
                        onClick={this.clearHandler}
                    >
                        {t('common.clear')}
                    </Button>
                    <Button
                        color="primary"
                        id={`${id}ApplyButton`}
                        className={styles.actionButton}
                        variant="contained"
                        onClick={this.applyHandler}
                        disabled={applyDisabled}
                    >
                        {t('common.apply')}
                    </Button>
                </ActionPanel>
            </>
        )

        return withPaper ? (
            <Paper id={id} key={key} className={classNames(containerClassName, styles.sideBarFilter)}>
                {content}
            </Paper>
        ) : (
            <div id={id} key={key} className={classNames(containerClassName, styles.sideBarFilter)}>
                {content}
            </div>
        )
    }
}
