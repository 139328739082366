import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { PRODUCT_DETAILS_STORE, PRODUCT_DETAILS_ACTIONS_STORE, APP_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { ProductDetailsActionsStore } from '../../../store/products/product-details-actions-store'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { t } from 'i18next'
import { AdvertisingAction } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { NavigateNext } from '@material-ui/icons'
import { toJS } from 'mobx'
import { ResultApplyObject } from '../../../core/advertising-actions/result-apply-object'
import { deserializeApplyObject } from '../../../core/advertising-actions/serialize-utils'
import { getPreferenceDescription } from '../../../core/advertising-actions/description-util'
import { CityVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import { RegionVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import Tooltip from '@material-ui/core/Tooltip'
import { AppStore } from '../../../store/app-store'
import { PeriodSelector } from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/period-selector/period-selector'
import { ColumnProps, RIGHT, Table } from '@crystalservice/crystals-ui/lib'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { getMomentDate } from '@crystalservice/crystals-ui/lib/utils/date-util'
import { Paginator } from '@crystalservice/crystals-ui/lib/components/table/paginator'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import {
    ANY, COMPOSITE_APPLY_OBJECT, FIX_PRICE_APPLY_OBJECT, SET_APPLY_OBJECT
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/plugin-type'

const styles = require('./promotions.scss')

export interface PromotionsProps {
    productDetailsStore?: ProductDetailsStore,
    productDetailsActionsStore?: ProductDetailsActionsStore,
    app?: AppStore
}

@inject(PRODUCT_DETAILS_STORE)
@inject(PRODUCT_DETAILS_ACTIONS_STORE)
@inject(APP_STORE)
@observer
export default class Promotions extends React.Component<PromotionsProps> {
    componentDidMount(): void {
        const { fetchActions } = this.props.productDetailsActionsStore
        fetchActions()
    }

    getTopologyLabel(action: AdvertisingAction): string {
        if (action.allNodes) {
            return t('topology.allShops').toLowerCase()
        }

        let cities: CityVO[] = []
        let regions: RegionVO[] = []
        let shopsCount: number = 0
        let result: string = ''

        action.topologyConditions.forEach(topologyCondition => {
            shopsCount += topologyCondition.shops.length

            topologyCondition.shopsCityGroups.forEach(shopCityGroup => {
                cities = cities.concat(shopCityGroup.cities)
            })

            topologyCondition.shopsRegionGroups.forEach(shopsRegionGroup => {
                regions = regions.concat(shopsRegionGroup.regions)
            })
        })

        if (regions.length > 0) {
            result += regions.map(region => region.name).join(', ')
        }

        if (cities.length > 0) {
            if (result) {
                result += ', '
            }

            result += cities.map(city => city.name).join(', ')
        }

        if (shopsCount > 0) {
            if (result) {
                result += ', '
            }

            result += `${shopsCount} ${t('components.listInput.entryType.shop', { count: shopsCount })}`
        }

        return result
    }

    getPreferenceDescriptionLabel(action: AdvertisingAction): string {
        const { productDetails } = this.props.productDetailsStore
        const { currencyText } = this.props.app

        if (!action.applyObjects) {
            return ''
        }

        const applyObjectList: ResultApplyObject[] = action.applyObjects.map(applyObjectString => deserializeApplyObject(applyObjectString))
        return applyObjectList.reduce((acc, applyObject) => {
            if (applyObject) {
                acc = acc ? acc + ' ' : acc
                acc += getPreferenceDescription(applyObject, productDetails, currencyText)
            }
            return acc
        }, '')
    }

    getActionDurationLabel(action: AdvertisingAction): string {
        if (action.worksAnytime) {
            return t('productDetails.advertisingActions.worksAnytime')
        }

        if (action.workPeriod.finish) {
            return t(
                'productDetails.advertisingActions.fromTo',
                {
                    from: this.formatDate(action.workPeriod.start),
                    to: this.formatDate(action.workPeriod.finish)
                })
        } else {
            return t('productDetails.advertisingActions.from', { from: this.formatDate(action.workPeriod.start) })
        }
    }

    formatDate(date: Date): string {
        return getMomentDate(date).format('DD.MM.YYYY HH:mm')
    }

    getTableColumns(): ColumnProps[] {
        const { goToAction } = this.props.productDetailsActionsStore
        const { isCentrum } = this.props.app

        let columns: ColumnProps[] = [
            {
                header: t('productDetails.advertisingActions.action'),
                renderer: props => {
                    const action: AdvertisingAction = props.item
                    return (
                        <div>
                            <p>{action.name}</p>
                            <p className={styles.secondary}>{this.getActionDurationLabel(action)}</p>
                        </div>
                    )
                }
            },
            {
                header: t('productDetails.advertisingActions.preference'),
                renderer: props => {
                    const action: AdvertisingAction = props.item
                    return <span>{this.getPreferenceDescriptionLabel(action)}</span>
                },
            },
            {
                width: '70px',
                padding: '0 10px',
                hAlign: RIGHT,
                withControls: true,
                showOnRowHover: false,
                renderer: props => {
                    const action: AdvertisingAction = props.item
                    return (
                        <AdaptiveIconButton
                            id={`goToActionButton${action.id}`}
                            label={t('productDetails.advertisingActions.goToAction')}
                            onClick={() => goToAction(action)}
                        >
                            <NavigateNext/>
                        </AdaptiveIconButton>
                    )
                }

            }
        ]

        if (isCentrum) {
            const topologyColumn = {
                header: t('productDetails.advertisingActions.topology'),
                renderer: props => {
                    const action: AdvertisingAction = props.item
                    const label = this.getTopologyLabel(action)

                    if (label.length < 30) {
                        return (
                            <span>
                                {label}
                            </span>
                        )
                    } else {
                        return (
                            <Tooltip title={label}>
                                <span>
                                    {`${label.substr(0, 30)}...`}
                                </span>
                            </Tooltip>
                        )
                    }

                }
            }
            columns.splice(2, 0, topologyColumn)
        }

        return columns
    }

    render() {
        const {
            actions, periodType, period, changePeriod, changeShowOnlyActiveActions, showOnlyActiveActions,
            paginationState, paginatorKey, setPaginationState, goToAction, setPluginType, pluginType
        } = this.props.productDetailsActionsStore

        return (
            <div className={styles.promotions}>
                <Grid
                    container
                    className={styles.promotionsFilter}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <PeriodSelector
                            disabled={showOnlyActiveActions}
                            periodChange={changePeriod}
                            selectedPeriod={period}
                            selectedPeriodType={periodType}
                            className={styles.filterItem}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox
                            className={styles.filterItem}
                            label={t('productDetails.advertisingActions.showOnlyActive')}
                            checked={showOnlyActiveActions}
                            onValueChange={value => changeShowOnlyActiveActions(value)}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectInput
                            value={pluginType}
                            options={[
                                {
                                    label: t('productDetails.advertisingActions.allDiscountTypes'),
                                    value: ANY,
                                },
                                {
                                    label: t('productDetails.advertisingActions.set'),
                                    value: SET_APPLY_OBJECT,
                                },
                                {
                                    label: t('productDetails.advertisingActions.fixPrice'),
                                    value: FIX_PRICE_APPLY_OBJECT,
                                },
                                {
                                    label: t('productDetails.advertisingActions.discountForProduct'),
                                    value: COMPOSITE_APPLY_OBJECT,
                                },
                            ]}
                            onSelect={option => setPluginType(option.value)}
                        />
                    </Grid>
                </Grid>

                <Divider className={styles.divider}/>

                {actions.length === 0 &&
                    <Empty/>
                }
                {actions.length > 0 &&
                    <Paginator
                        defaultItems={toJS(actions)}
                        key={paginatorKey}
                        defaultPagination={paginationState}
                        onPageItemsChange={setPaginationState}
                    >
                        <Table
                            id="productActionsTable"
                            keyField="id"
                            withHeader
                            onRowDoubleClick={action => goToAction(action)}
                            columns={this.getTableColumns()}
                        />
                    </Paginator>
                }
            </div>
        )
    }
}
