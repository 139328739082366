import React from 'react'
import { inject, observer } from 'mobx-react'
import { LOYALTY_COUPONS_INSTANCES_STORE } from '../../../../store/stores'
import { t } from 'i18next'
import { BaseTable } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { LoyaltyCouponsInstancesStore } from '../../../../store/loyalty/loyalty-coupons-instances-store'
import { UniqueCouponExemplarVO } from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/unique-coupon-exemplar-vo'
import moment from 'moment'
import { getUserFullName } from '../../../../../utils/name-util'

const styles = require('./loyalty-coupons-instances.scss')

interface LoyaltyCouponsInstancesProps {
    loyaltyCouponsInstancesStore?: LoyaltyCouponsInstancesStore
    disableAutoSizer?: boolean
}

@inject(LOYALTY_COUPONS_INSTANCES_STORE)
@observer
export class LoyaltyCouponsInstancesTable extends React.Component<LoyaltyCouponsInstancesProps> {

    render() {
        const { coupons } = this.props.loyaltyCouponsInstancesStore

        return (
            <BaseTable
                items={coupons}
                maxVisibleRows={8}
                keyFunction={(item: UniqueCouponExemplarVO) => item.number}
                columns={[
                    {
                        padding: '0 0 0 14px',
                        header: t('loyaltyCoupons.instancesPage.name'),
                        keyField: 'name',
                        renderer: item => {
                            return (
                                <div className={styles.nameContainer}>
                                    <div className={styles.actionName}>{item.number}</div>
                                    <span className={styles.additionalText}>{item.name}</span>
                                </div>
                            )
                        },
                        columnWidth: 200
                    },
                    {
                        header: t('loyaltyCoupons.instancesPage.workPeriod'),
                        keyField: 'activationDate',
                        renderer: item => {
                            const startDate = item.activationDate ? new Date(item.activationDate) : null
                            const endDate = item.expirationDate ? new Date(item.expirationDate) : null

                            if (!startDate && !endDate) {
                                return (
                                    <p className={styles.noPeriodLabel}>
                                        {t('loyaltyCoupons.couponsTable.noPeriod')}
                                    </p>
                                )
                            }

                            const stringStartDate = moment(startDate).format('DD.MM.YY')

                            if (!endDate) {
                                return (
                                    <div className={styles.noWrap}>
                                        {`${t('common.fromStart')} ${stringStartDate}`}
                                    </div>
                                )
                            }

                            const stringEndDate = moment(endDate).format('DD.MM.YY')

                            return <div>{`${stringStartDate} — ${stringEndDate}`}</div>
                        },
                    },
                    {
                        header: t('loyaltyCoupons.instancesPage.usedBy'),
                        keyField: 'lastName',
                        renderer: getUserFullName,
                    },
                    {
                        header: t('loyaltyCoupons.instancesPage.createDate'),
                        keyField: 'createDate',
                        renderer: item => {
                            const date = new Date(item.createDate)
                            const stringDate = moment(date).format('DD.MM.YYYY')

                            const created = `${t('loyaltyCoupons.instancesPage.createDate')}, ${stringDate}`

                            if (!item.usedDate) {
                                return (
                                    <div>
                                        {created}
                                    </div>
                                )
                            }

                            let usedText
                            if (item.usedDate) {
                                const usedDate = new Date(item.usedDate)
                                const stringUsedDate = moment(usedDate).format('DD.MM.YYYY')

                                const receipt = item.usedReceipt
                                const shopsText = `${receipt.shopNumber}\\${receipt.cashNumber}\\${receipt.shiftNumber}\\${receipt.purchaseNumber}`

                                usedText = `\r\n${shopsText} ${stringUsedDate}`
                            }

                            return (
                                <div>
                                    <div>{created}</div>
                                    <div>{usedText}</div>
                                </div>
                            )
                        },
                    },
                    {
                        header: t('loyaltyCoupons.instancesPage.status'),
                        keyField: 'status',
                        renderer: item => item.usedDate ? (
                            <div className={styles.notActive}>{t('loyaltyCoupons.instancesPage.notActive')}</div>
                         ) : (
                            <div className={styles.active}>{t('loyaltyCoupons.instancesPage.active')}</div>
                         ),
                    },
                ]}
            />
        )
    }
}
