import { createCardTypeVO } from './card-type-vo'
import { createBaseVO } from './base-vo'
import { CardTypeVO } from './card-type-vo'

export interface CouponsVO extends CardTypeVO {
    '@class': 'ru.crystals.cards.coupons.vo.CouponsVO' | 'ru.crystals.cards.coupons.vo.UniqueCouponVO'
    hasExpirationDate?: boolean
    activationDate?: Date
    expirationDate?: Date
    nameForClient?: string
    description?: string
    needShowMessage?: boolean
    multiUse?: boolean
    showCouponMessages?: boolean
    idlePeriod?: number
    validPeriod?: number
    areaOfEffect?: string
}

export const createCouponsVO = (initialization: Partial<CouponsVO>): CouponsVO => {
    if (!initialization) return null
    
    let result: CouponsVO = {
        ...createCardTypeVO(initialization),        ...createBaseVO(initialization),
        hasExpirationDate: initialization.hasExpirationDate,
        activationDate: initialization.activationDate,
        expirationDate: initialization.expirationDate,
        nameForClient: initialization.nameForClient,
        description: initialization.description,
        needShowMessage: initialization.needShowMessage,
        multiUse: initialization.multiUse,
        showCouponMessages: initialization.showCouponMessages,
        idlePeriod: initialization.idlePeriod,
        validPeriod: initialization.validPeriod,
        areaOfEffect: initialization.areaOfEffect,
        '@class': 'ru.crystals.cards.coupons.vo.CouponsVO',
    }
    
    return result
}
