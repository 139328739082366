export interface PriceTagSizeLabelVO {
    '@class': 'ru.crystals.setretailx.products.vo.PriceTagSizeLabelVO'
    id?: number
    name?: string
    uuid?: string
    deleted?: boolean
    lastUpdate?: Date
}

export const createPriceTagSizeLabelVO = (initialization: Partial<PriceTagSizeLabelVO>): PriceTagSizeLabelVO => {
    if (!initialization) return null
    
    let result: PriceTagSizeLabelVO = {
        id: initialization.id,
        name: initialization.name,
        uuid: initialization.uuid,
        deleted: initialization.deleted,
        lastUpdate: initialization.lastUpdate,
        '@class': 'ru.crystals.setretailx.products.vo.PriceTagSizeLabelVO',
    }
    
    return result
}
