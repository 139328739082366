import { t } from 'i18next'
import React from 'react'
import { COUPON_NAME, FilterType, getFilterTypeName } from '../../../../core/filters/filter'
import { InternalFilterComponentProps } from '../../new-filters'
import { ChipAutocomplete } from '../../../../../components/inputs/chip-autocomplete-input/chip-autocomplete-input'
import { uniqueCouponsService } from '../../../../../protocol/set10/unique-coupons-service'

export class CouponNames extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = COUPON_NAME

    render() {
        const { id, value, onChange, slim } = this.props
        return (
            <ChipAutocomplete
                id={id}
                slim={slim}
                placeholder={t('loyaltyCoupons.instancesPage.filters.fullName')}
                fetchFunction={async (searchString: string) => {
                    const result = await uniqueCouponsService.findActiveCouponInfoWithNamesLike(searchString, 50)
                    return result.map(coupon => coupon.name)
                }}
                value={value ? value.split(',') : []}
                onChange={(value: string[]) => onChange(CouponNames.filterType, { value: value.join(',') })}
            />
        )
    }
}
