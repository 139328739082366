import {observable, runInAction, computed, action, toJS} from 'mobx'
import {t} from 'i18next'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import {
    APP_STORE, PRICE_TAG_FORMAT_SETTINGS_STORE,
} from '../stores'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { get } from 'lodash'
import { getUniqueNameWithNumberPostfix } from '../../../utils/name-util'
import { formatsSizeBindingPresenterLocal } from '../../../protocol/set10/formats-size-binding-presenter-local'
import { PriceTagFormatSettingsStore } from './price-tag-format-settings-store'
import {
    FormatsSizeBindingVO, createFormatsSizeBindingVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/formats-size-binding-vo'
import uuid from 'uuid'
import { withSpinner } from '../with-spinner'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import {
    BoundProductEntity, ProductEntityType, PRODUCT as PRODUCT_BINDER, PRODUCTS_GROUP as PRODUCTS_GROUP_BINDER
} from '../../components/product-entities-binder/product-entities-binder'
import {
    SizeBindingConditionVO, createSizeBindingConditionVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/size-binding-condition-vo'
import {
    SizeBindingConditionType, PRODUCT, PRODUCTS_GROUP
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/size-binding-condition-type'

// FIXME: SFM-533
// У размера ценника должно быть еще свойство conditions, которое отсутствует в протоколе
export interface FormatsSizeBindingVOWithCondition extends FormatsSizeBindingVO {
    conditions: SizeBindingConditionVO[]
}

export class PriceTagFormatSizeLabelsStore {
    @observable
    sizeLabels: FormatsSizeBindingVOWithCondition[] = []

    @observable
    sizeLabelSettings: FormatsSizeBindingVOWithCondition = null

    @observable
    lastWidth: number = 0

    @observable
    lastHeight: number = 0

    @observable
    validation: FormValidation<FormatsSizeBindingVOWithCondition> = null

    @computed
    get includedEntities(): BoundProductEntity[] {
        if (!this.sizeLabelSettings || !this.sizeLabelSettings.includedItems) return []

        return this.sizeLabelSettings.includedItems.map((condition: SizeBindingConditionVO) => {
            return {
                type: sizeBindingTypeToBinderEntity(condition.type),
                value: String(condition.printCount),
                label: condition.name,
                code: condition.code,
            }
        })
    }

    @computed
    get excludedEntities(): BoundProductEntity[] {
        if (!this.sizeLabelSettings || !this.sizeLabelSettings.excludedItems) return []

        return this.sizeLabelSettings.excludedItems.map((condition: SizeBindingConditionVO) => {
            return {
                type: sizeBindingTypeToBinderEntity(condition.type),
                value: null,
                label: condition.name,
                code: condition.code,
            }
        })
    }

    @computed
    get readOnly(): boolean {
        return !this.appStore.isCentrum && this.appStore.connectedToCentrum
    }

    @computed
    get topologyId(): number {
        return get(this.priceTagFormatSettingsStore, 'formatTopology.id')
    }

    private appStore: AppStore = getStore(APP_STORE)
    private priceTagFormatSettingsStore: PriceTagFormatSettingsStore = getStore(PRICE_TAG_FORMAT_SETTINGS_STORE)

    fetchAllSizeLabels = async (): Promise<void> => {
        const formatsTopology = await withSpinner(formatsSizeBindingPresenterLocal.loadAll(this.topologyId))
        runInAction(() => {
            // У размера ценника должно быть еще свойство conditions, которое отсутствует в протоколе
            this.sizeLabels = formatsTopology as FormatsSizeBindingVOWithCondition[]
        })
    }

    requestFromCentrum = async (): Promise<void> => {
        const newSettings = await withSpinner(formatsSizeBindingPresenterLocal.requestFromCentrum(this.sizeLabelSettings.uuid))
        // SRTS-95 на ритейле topologyId = -1, поэтому удаляем, чтобы верно отрабатывалось изменение настроек
        delete newSettings.topologyId
        this.updateSizeLabel(newSettings)
    }

    @action
    addSizeLabel = (): void => {
        // У размера ценника должно быть еще свойство conditions, которое отсутствует в протоколе
        this.sizeLabelSettings = {
            ...createFormatsSizeBindingVO({
                id: -1,
                uuid: uuid(),
                name: getUniqueNameWithNumberPostfix(
                    t('priceTagsFormats.sizeLabels.newSizeLabel'),
                    toJS(this.sizeLabels),
                    'name'
                ),
                description: '',
                maxHeight: 0,
                maxWidth: 0,
                productsCount: 0,
                includedItems: [],
                excludedItems: [],
                defaultSize: false,
                topologyId: this.topologyId,
            }),
            conditions: [],
        }
        this.lastHeight = 0
        this.lastWidth = 0
        this.createValidation(true)
    }

    @action
    editSizeLabel = (sizeLabel: FormatsSizeBindingVOWithCondition): void => {
        this.sizeLabelSettings = toJS(sizeLabel)
        this.lastHeight = sizeLabel.maxHeight
        this.lastWidth = sizeLabel.maxWidth
        this.createValidation()
    }

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<FormatsSizeBindingVOWithCondition>(
            this.sizeLabelSettings,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        uniqueField(this.sizeLabels
                            .filter(u => u.uuid !== this.sizeLabelSettings.uuid)
                            .map(u => u.name))
                    ]
                }
            ],
            creation
        )
    }

    @action
    updateSizeLabel = (changes: Partial<FormatsSizeBindingVOWithCondition>): void => {
        Object.keys(changes).forEach(key => {
            this.sizeLabelSettings[key] = changes[key]
        })
    }

    @action
    updatePrintCount = (item: BoundProductEntity, value: string): void => {
        const { includedItems, excludedItems} = this.sizeLabelSettings

        const itemCode = item.code
        const itemName = item.label
        const index = includedItems.findIndex(iItem => iItem.code === itemCode && iItem.name === itemName)

        if (index === -1) return

        if (!isNaN(Number(value))) {
            includedItems[index].printCount = Number(value)
        }

        this.updateSizeLabel({
            includedItems,
            conditions: includedItems.concat(excludedItems)
        })
    }

    setIncludedConditionsFromEntities = (newEntities: BoundProductEntity[]): void => {
        let newExcludedItems = toJS(this.sizeLabelSettings.excludedItems)

        const newIncludedItems = newEntities.map(entity => {
            // Если сущность есть в исключениях, оттуда надо убрать
            const entityCode = entity.code
            const index = newExcludedItems.findIndex(item => item.code === entityCode)
            if (index !== -1) {
                newExcludedItems.splice(index, 1)
            }

            return createSizeBindingConditionVO({
                // на сервер нужно отправлять именно id -1
                id: -1,
                sizeBindingId: this.sizeLabelSettings.id,
                type: binderEntityToSizeBindingType(entity.type as ProductEntityType),
                code: entity.code,
                name: entity.label,
                excluded: false,
                printCount: 1
            })
        })

        this.updateSizeLabel({
            includedItems: newIncludedItems,
            excludedItems: newExcludedItems,
            conditions: newIncludedItems.concat(newExcludedItems)
        })
    }

    setExcludedConditionsFromEntities = (newEntities: BoundProductEntity[]): void => {
        let newIncludedItems = toJS(this.sizeLabelSettings.includedItems)

        const newExcludedItems = newEntities.map(entity => {
            // Если сущность есть в добавленных, оттуда надо убрать
            const entityCode = entity.code
            const index = newIncludedItems.findIndex(item => item.code === entityCode)
            if (index !== -1) {
                newIncludedItems.splice(index, 1)
            }

            return createSizeBindingConditionVO({
                // на сервер нужно отправлять именно id -1
                id: -1,
                sizeBindingId: this.sizeLabelSettings.id,
                type: binderEntityToSizeBindingType(entity.type as ProductEntityType),
                code: entity.code,
                name: entity.label,
                excluded: true
            })
        })

        this.updateSizeLabel({
            includedItems: newIncludedItems,
            excludedItems: newExcludedItems,
            conditions: newIncludedItems.concat(newExcludedItems)
        })
    }

    @action
    closeSizeLabelsSettings = (): void => {
        this.sizeLabelSettings = null
    }

    checkForSizeChangeBeforeSave = (): void => {
        if (!this.sizeLabelSettings) return

        if (this.sizeLabelSettings.maxHeight < this.lastHeight
            || this.sizeLabelSettings.maxWidth < this.lastWidth
        ) {
            this.appStore.showDialog({
                title: t('priceTagsFormats.sizeLabels.sizeChangedDialogTitle'),
                message: t('priceTagsFormats.sizeLabels.sizeChangedDialogMessage'),
                onYes: this.saveSizeLabelSettings,
                mode: DIALOG
            })
        } else {
            this.saveSizeLabelSettings()
        }
    }

    saveSizeLabelSettings = async (): Promise<void> => {
        await withSpinner(formatsSizeBindingPresenterLocal.save(toJS(this.sizeLabelSettings)))
        await this.fetchAllSizeLabels()

        this.sizeLabelSettings = null
        this.appStore.showSnackbar({
            message: t('priceTagsFormats.sizeLabels.sizeLabelSaved')
        })
    }

    showRemoveDialog = (sizeLabel: FormatsSizeBindingVOWithCondition): void => {
        this.appStore.showDialog({
            title: t('priceTagsFormats.sizeLabels.removeDialogTitle'),
            message: t('priceTagsFormats.sizeLabels.removeDialogMessage', {name: sizeLabel.name}),
            onYes: () => this.removeSizeLabel(sizeLabel),
            mode: DIALOG
        })
    }

    removeSizeLabel = async (sizeLabel: FormatsSizeBindingVOWithCondition): Promise<void> => {
        await withSpinner(formatsSizeBindingPresenterLocal.delete(toJS(sizeLabel)))
        await this.fetchAllSizeLabels()
    }

    @action
    reset = (): void => {
        this.sizeLabels = []
        this.sizeLabelSettings = null
        this.validation = null
        this.lastHeight = 0
        this.lastWidth = 0
    }
}

function sizeBindingTypeToBinderEntity(productListType: SizeBindingConditionType): ProductEntityType {
    switch (productListType) {
        case PRODUCTS_GROUP:
            return PRODUCTS_GROUP_BINDER
        case PRODUCT:
            return PRODUCT_BINDER
        default:
            return null
    }
}

function binderEntityToSizeBindingType(binderEntity: ProductEntityType): SizeBindingConditionType {
    switch (binderEntity) {
        case PRODUCTS_GROUP_BINDER:
            return PRODUCTS_GROUP
        case PRODUCT_BINDER:
            return PRODUCT
        default:
            return null
    }
}
