import { MenuVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/menu-vo'
import moment from 'moment'
import { uniqBy, sortBy } from 'lodash'
import { bitMaskToWeekDays, weekDaysToBitMask } from '../../../../utils/date/date-conversion-util'
import { getDaysOfWeek } from '../../week-utils'

export class MenusCollisionCheckResult {
    menusToDelete: MenuVO[] = [] // устаревшие меню, которые нужно удалить
    menusToCreate: MenuVO[] = [] // если удаляемое меню действует несколько дней, то создаём его клон со всеми днями, кроме удаляемых
    menusWithDateCollisions: Map<number, MenuVO[][]> = new Map<number, MenuVO[][]>()

    get needAction(): boolean {
        return this.menusWithDateCollisions.size > 0
            || [...this.menusToDelete, ...this.menusToCreate].length > 0
    }
}

/*
* 1. Если menuB действует в те же дни, что и menuA, а между датой начала menuB и сегодня нет дней действия menuA,
* то создаём menuB, удаляем menuA
* 2. Если menuB более релевантно menuA в один из дней (в среду), но menuA действует несоклько дней (в среду и четверг)
* то содаём menuB, удаляем menuA, создаём menuC, который действует в оставшиеся дни (четверг)
* 3. Если найдены несколько menuVO с одинаковой dateFrom - отдаём их заказчику в menusWithDateCollisions
* */
export class MenusCollisionChecker {

    now: Date

    constructor(now: Date) {
        this.now = now // чтобы не было явной зависимости от AppStore
    }

    check = (menus: MenuVO[]): MenusCollisionCheckResult => {
        const actualMenusMap: Map<number, MenuVO[]> = this.getActualMenusMap(menus)

        const menusWithCollisions = this.findMenusWithDateCollisions(actualMenusMap)
        if (menusWithCollisions.size > 0) {
            const result = new MenusCollisionCheckResult()
            result.menusWithDateCollisions = menusWithCollisions

            return result
        }

        return this.getDeletedStaleMenus(actualMenusMap)
    }

    // для удобства распределяем все меню по дням недели; MenuVO[] в ответе отсортированы по дате
    private getActualMenusMap = (menus: MenuVO[]): Map<number, MenuVO[]> => {

        const result = new Map<number, MenuVO[]>()
        getDaysOfWeek().forEach(dayOfWeek => { // initialization
            result.set(dayOfWeek.day, [])
        })
        const sortedMenus = this.sortMenusByDate(menus)

        sortedMenus.forEach(menu => {
            const menuDays: number[] = bitMaskToWeekDays(menu.dayOfWeek)

            menuDays.forEach(day => {
                const mapValue = result.get(day)
                mapValue.push(menu)
                result.set(day, mapValue)
            })
        })

        return result
    }

    // рассчитывает menusWithDateCollisions
    private findMenusWithDateCollisions = (menusMap: Map<number, MenuVO[]>): Map<number, MenuVO[][]> => {
        const result = new Map<number, MenuVO[][]>()

        menusMap.forEach((dayMenus, day) => {
            const dateToMenuMap = new Map<string, MenuVO[]>()
            dayMenus.forEach(menu => {
                const key = menu.dateFrom.toISOString()
                dateToMenuMap.set(key, [...(dateToMenuMap.get(key) || []), menu])
            })

            const menusWithCollisionsMatrix: MenuVO[][] = []

            dateToMenuMap.forEach(dateMenus => {
                if (dateMenus.length > 1) {
                    /* если не сортировать, то порядок в node v10 и node v12 разный; в тестах порядок важен */
                    menusWithCollisionsMatrix.push(sortBy(dateMenus, ['id']))
                }
            })

            if (menusWithCollisionsMatrix.length > 0) {
                result.set(day, menusWithCollisionsMatrix)
            }
        })

        return result
    }

    // рассчитывает menusToDelete и menusToCreate
    private getDeletedStaleMenus = (menusMap: Map<number, MenuVO[]>): MenusCollisionCheckResult => {
        const result: MenusCollisionCheckResult = new MenusCollisionCheckResult()

        menusMap.forEach((dayMenus, day) => {
            const nearestDates: Date[] = uniqBy(
                dayMenus.map(menu => {
                    const date = this.isSameOrBefore(this.today(), menu.dateFrom) ? menu.dateFrom : this.today()
                    return this.getNearestDateOfRequestedWeek(day, date)
                }),
                date => Number(date)
            )

            const staleMenus = nearestDates.reduce((prevStaleMenus, date) => {
                return this.getMenusActualForADate(date, prevStaleMenus).others
            }, dayMenus)

            staleMenus.forEach(staleMenu => {
                // это меню будет удалено, поскольку для данного day есть более релевантные меню
                // но оно может действовать в другие дни - для них нужно создать копию этого меню
                const uncoveredDays: number[] = bitMaskToWeekDays(staleMenu.dayOfWeek)
                    .filter(menuDay => menuDay !== day)
                    .filter(menuDay => { // выбираем дни, в которые данное меню будет самым релевантным
                        if (!menusMap.has(menuDay)) {
                            return false
                        }

                        return this
                            .findOnlyMenusThatWillEverBeActual(menuDay, menusMap.get(menuDay))
                            .some(actualMenu => actualMenu.id === staleMenu.id)
                    })

                if (uncoveredDays.length === 0) return

                result.menusToCreate.push({
                    ...staleMenu,
                    dayOfWeek: weekDaysToBitMask(uncoveredDays)
                })
            })

            result.menusToDelete.push(...staleMenus)

        })

        result.menusToDelete = uniqBy(result.menusToDelete, menu => menu.id)
        result.menusToCreate = uniqBy(result.menusToCreate, menu => menu.id)
            .map(menu => ({ ...menu, id: null }))

        return result
    }

    private findOnlyMenusThatWillEverBeActual = (dayOfWeek: number, menus: MenuVO[]): MenuVO[] => {

        return this.sortMenusByDate(menus).filter((currentMenu: MenuVO) => {
            const nearestDate = this.getNearestDateOfRequestedWeek(dayOfWeek, currentMenu.dateFrom)
            const menusForADate = this.getMenusActualForADate(nearestDate, menus)

            return menusForADate.mostActual.id === currentMenu.id
        })
    }

    private getMenusActualForADate = (date: Date, menus: MenuVO[]): { mostActual: MenuVO, others: MenuVO[] } => {
        const futureDates = []
        const actualDates = []

        this.sortMenusByDate(menus).forEach(menu => {
            if (menu.dateFrom > date) {
                futureDates.push(menu)
            } else {
                actualDates.push(menu)
            }
        })

        const [mostActual, ...otherActual] = actualDates
        return {
            mostActual,
            others: [...futureDates, ...otherActual]
        }
    }

    private sortMenusByDate = (menus: MenuVO[]): MenuVO[] => {
        return [...menus].sort((menuA, menuB) => this.isSameOrBefore(menuA.dateFrom, menuB.dateFrom) ? 1 : -1)
    }

    private isSameOrBefore = (dateA: Date, dateB: Date): boolean => { // dateA <= dateB ? true : false
        return moment(dateA).startOf('day')
            .isSameOrBefore(
                moment(dateB).startOf('day')
            )
    }

    private getNearestDateOfRequestedWeek = (dayOfWeek: number, dateFrom: Date = this.now): Date => {
        const requestedDayThisWeek = moment(dateFrom).day(dayOfWeek).toDate()

        return this.isSameOrBefore(dateFrom, requestedDayThisWeek)
            ? requestedDayThisWeek
            : moment(requestedDayThisWeek).add(1, 'week').toDate()
    }

    private today = (): Date => moment(this.now).startOf('day').toDate()
}
