import React, { Component, HTMLProps, ReactNode } from 'react'
import { t } from 'i18next'
import { ESLUploadErrorsStore } from '../../../store/price-tags/esl-upload-errors-store'
import Paper from '@material-ui/core/Paper'
import Replay from '@material-ui/icons/Replay'
import Block from '@material-ui/icons/Block'
import { AutocompleteInput } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { ProductBaseInfo } from '../../../../components/product-base-info/product-base-info'
import { DATE, RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { inject, observer } from 'mobx-react'
import { DIALOG_STORE, ESL_UPLOAD_ERRORS_STORE } from '../../../store/stores'
import { ALL_ITEM_IDS, Paginator } from '@crystalservice/crystals-ui/lib/components/table/paginator'
import { ESLHistoryConditionVO } from '../../../../protocol/set10/set-retail10-commons/set-esl-api/esl-history-condition-vo'
import Tooltip from '@material-ui/core/Tooltip'
import { DialogStore } from '../../../store/dialog-store'
import { ERROR } from '../../../../components/simple-dialog/simple-dialog'

const styles = require('./esl-upload-errors.scss')

export interface ESLUploadErrorsProps extends HTMLProps<HTMLDivElement> {
    eslUploadErrorsStore?: ESLUploadErrorsStore
    dialogStore?: DialogStore
}

@inject(ESL_UPLOAD_ERRORS_STORE)
@inject(DIALOG_STORE)
@observer
export class ESLUploadErrors extends Component<ESLUploadErrorsProps> {

    componentDidMount(): void {
        const { update } = this.props.eslUploadErrorsStore
        update()
    }

    componentWillUnmount(): void {
        const { stopPolling } = this.props.eslUploadErrorsStore
        stopPolling()
    }

    renderErrors = (): ReactNode => {
        const {
            filterConditions, findConditionsByIds, launchReupload, configuration, conditionsTableKey, updatePagination, pagination
        } = this.props.eslUploadErrorsStore

        if (filterConditions.length === 0 || !configuration) {
            return <Empty/>
        }

        return (
            <Paginator<ESLHistoryConditionVO>
                defaultPagination={pagination}
                idField="item"
                itemIds={filterConditions}
                mode={ALL_ITEM_IDS}
                getItemsByIds={findConditionsByIds}
                key={conditionsTableKey}
                onNotFoundItemsError={this.conditionSearchErrorHandler}
                onPageItemsChange={updatePagination}
            >
                <Table
                    id="eslUploadErrorsList"
                    withHeader
                    title={t('priceTagsEsl.productsList')}
                    columns={[
                        {
                            header: t('shop.name'),
                            renderer: (props: any) => {
                                const product: ESLHistoryConditionVO = props.item
                                return (
                                    <ProductBaseInfo
                                        name={product.productName}
                                        code={product.item}
                                        barcode={product.barCode}
                                    />
                                )
                            }
                        },
                        {
                            header: t('priceTagsEsl.uploadDate'),
                            labelField: 'lastExportTime',
                            dataType: DATE,
                            dateFormat: 'DD.MM.YYYY HH:mm',
                            hAlign: RIGHT,
                        },
                        {
                            header: t('priceTagsEsl.uploadError'),
                            labelField: 'messageException',
                            hAlign: RIGHT,
                            tdClassName: styles.error
                        },
                        {
                            width: '80px',
                            hAlign: RIGHT,
                            padding: '8px 12px 8px 16px',
                            showOnRowHover: true,
                            withControls: true,
                            renderer: props => {
                                if (configuration.invalidateAll) {
                                    return (
                                        <Tooltip title={t('priceTagsEsl.uploadAlreadyLaunched')}>
                                            <Block color="secondary"/>
                                        </Tooltip>
                                    )
                                }

                                const condition: ESLHistoryConditionVO = props.item

                                return (
                                    <AdaptiveIconButton
                                        id={`reuploadButton-${condition.item}`}
                                        label={t('priceTagsEsl.upload')}
                                        onClick={() => launchReupload(condition)}
                                    >
                                        <Replay color="secondary"/>
                                    </AdaptiveIconButton>
                                )
                            }
                        }
                    ]}
                />
            </Paginator>
        )
    }

    conditionSearchHandler = (condition: ESLHistoryConditionVO): void => {
        const { setFoundCondition, clearFoundCondition } = this.props.eslUploadErrorsStore

        if (condition) {
            setFoundCondition(condition)
        } else {
            clearFoundCondition()
        }
    }

    conditionSearchInputHandler = (event: any): void => {
        const { clearFoundCondition, foundCondition} = this.props.eslUploadErrorsStore
        if (!event.target.value && foundCondition) {
            clearFoundCondition()
        }
    }

    conditionSearchErrorHandler = (conditions: string[]): void => {
        const { showDialog } = this.props.dialogStore
        showDialog({
            mode: ERROR,
            message: `${t('priceTagsEsl.productsNotFound')}:\n${conditions.join(', ')}`
        })
    }

    render() {
        const { searchCondition, allConditionsIds } = this.props.eslUploadErrorsStore

        if (!allConditionsIds || allConditionsIds.length === 0) {
            return (
                <Paper>
                    <Empty/>
                </Paper>
            )
        }

        return (
            <div>
                <Paper className={styles.topPanel}>
                    <p className={styles.searchLabel}>{t('priceTagsEsl.searchByProducts')}</p>
                    <AutocompleteInput
                        id="eslErrorsSearchAutocomplete"
                        className={styles.autocomplete}
                        keepInputValueAfterSelection
                        placeholder={t('priceTagsEsl.productsSearchPlaceholder')}
                        labelField="productName"
                        showClearButton
                        fetchDataCallback={searchCondition}
                        onSuggestionSelect={this.conditionSearchHandler}
                        onChange={this.conditionSearchInputHandler}
                    />
                </Paper>

                <Paper className={styles.tableContainer}>
                    {this.renderErrors()}
                </Paper>
            </div>
        )
    }
}
