
export interface ComplexReceiptStringValue {
    shifts?: string
    shops?: string
    purchases?: string
    cashes?: string
}

export interface ComplexReceiptNumberValue {
    shifts?: number[]
    shops?: number[]
    purchases?: number[]
    cashes?: number[]
}

export const getComplexReceiptNumbers = (receipt: ComplexReceiptStringValue): ComplexReceiptNumberValue => {
    let result: ComplexReceiptNumberValue = {}

    Object.keys(receipt).forEach(key => {
        let item = receipt[key]

        if (item !== '') {
            let keyResult: number[] = []

            item.split(',').forEach(part => {
                if (!isNaN(part) && part !== '') {
                    keyResult.push(Number(part))
                }
            })

            result[key] = keyResult
        }
    })

    if (Object.keys(result).length === 0) return null
    return result
}
