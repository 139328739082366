import React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import { uniqBy } from 'lodash'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Tabs, TabInfo } from '@crystalservice/crystals-ui/lib/components/tabs/tabs'

import { APP_STORE, ACTION_SETTINGS_STORE } from '../../../../../store/stores'
import { AppStore } from '../../../../../store/app-store'
import { ActionSettingsStore } from '../../../../../store/actions/action-settings-store'
import { AffectedZoneTable, formatTopologyConditions } from './affected-zone-table/affected-zone-table'
import { AffectedZoneList } from './affected-zone-list/affected-zone-list'
import { iTopologyManagerLocal } from '../../../../../../protocol/set10/i-topology-manager-local'
import { ShopVO } from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { DIALOG } from '../../../../../../components/simple-dialog/simple-dialog'
import {
    TopologyCondition, createTopologyCondition
} from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition'
import { toJS } from 'mobx'
import { withSpinner } from '../../../../../store/with-spinner'
import { SNACKBAR_EXTENDED_DURATION } from '../../../../../../utils/default-timeouts'

const styles = require('./affected-zone-editor.scss')

type AffectedZoneEditorTabType = 'table' | 'list'
const AFFECTED_ZONE_TABLE_TAB: AffectedZoneEditorTabType = 'table'
const AFFECTED_ZONE_LIST_TAB: AffectedZoneEditorTabType = 'list'

export interface AffectedZoneEditorTab {
    type: AffectedZoneEditorTabType
    component: React.ReactNode
}

const ALL_TABS: AffectedZoneEditorTab[] = [
    {
        type: AFFECTED_ZONE_TABLE_TAB,
        component: <AffectedZoneTable key="affectedZoneTable" />,
    },
    {
        type: AFFECTED_ZONE_LIST_TAB,
        component: <AffectedZoneList key="affectedZoneList" />,
    },
]

interface AffectedZoneEditorProps {
    onClose: () => void
    app?: AppStore
    testing?: boolean
    actionSettingsStore?: ActionSettingsStore
}

interface AffectedZoneEditorState {
    activeTab: AffectedZoneEditorTabType
}

@inject(ACTION_SETTINGS_STORE)
@inject(APP_STORE)
@observer
export class AffectedZoneEditor extends React.Component<AffectedZoneEditorProps, AffectedZoneEditorState> {
    state: AffectedZoneEditorState = {
        activeTab: AFFECTED_ZONE_TABLE_TAB,
    }

    componentDidMount() {
        const { modifyTopologyConditions, editedAction } = this.props.actionSettingsStore
        modifyTopologyConditions(toJS(editedAction.topologyConditions))
    }

    get dataLabels(): TabInfo[] {
        return [
            {
                data: AFFECTED_ZONE_TABLE_TAB,
                label: t('advertisingActions.affectedZoneDialog.fromList'),
            },
            {
                data: AFFECTED_ZONE_LIST_TAB,
                label: t('advertisingActions.affectedZoneDialog.byList'),
            },
        ]
    }

    handleSave = async (): Promise<void> => {
        const { actionSettingsStore, onClose, app } = this.props
        const { activeTab } = this.state
        const {
            editingTopologyShopsListInput,
            editingTopologyConditions,
            modifyEditedAction,
        } = actionSettingsStore

        if (activeTab === AFFECTED_ZONE_LIST_TAB) {
            const shopsNumbers: number[] = editingTopologyShopsListInput
                .split(/[ ,\n]+/)
                .filter(num => num !== '')
                .map(num => Number(num))
            const shops: ShopVO[] = await withSpinner(iTopologyManagerLocal.getShopsByNumbers(shopsNumbers))
            const notFetchedShopsNumbers: number[] = shopsNumbers
                .filter(num => !shops.map(shop => shop.number)
                .some(shop => shop === num))

            if (notFetchedShopsNumbers.length > 0) {
                app?.showDialog({
                    title: t('advertisingActions.affectedZoneDialog.result'),
                    message: (
                        <span key="message">
                            <span key="1">{`${t('advertisingActions.affectedZoneDialog.shopsAdded')}: ${shops.length}`}</span>
                            <br key="2"/>
                            <span key="3">
                                {`${
                                    t('advertisingActions.affectedZoneDialog.error')
                                }: ${
                                    notFetchedShopsNumbers.length
                                } (${
                                    notFetchedShopsNumbers.join(', ')
                                })`}
                            </span>
                        </span>
                    ),
                    mode: DIALOG,
                    onYes: () => this.handleApplyShops(shops),
                    yesLabel: t('common.add'),
                    noLabel: t('common.cancel'),
                })
            } else {
                this.handleApplyShops(shops)
            }

            return
        }

        modifyEditedAction({
            allNodes: false,
            topologyConditions: editingTopologyConditions,
        })
        onClose()
    }

    handleApplyShops = (shops: ShopVO[]): void => {
        const { onClose, actionSettingsStore, app } = this.props
        const {
            editingTopologyConditions,
            modifyTopologyConditions,
            modifyEditedAction,
            modifyEditingTopologyShopsListInput,
            resetTopologyConditionsChange,
        } = actionSettingsStore
        const { showSnackbar, topologyMap } = app

        // Исправляем хранение топологии в виде городов/регионов. Оставляем только в виде магазинов
        const topologyConditions: TopologyCondition[] = formatTopologyConditions(
            toJS(editingTopologyConditions),
            topologyMap.toTree(topologyMap.formats, false)
        )

        const updatedTopologyConditions: TopologyCondition[] = [createTopologyCondition({
            ...topologyConditions?.[0],
            shops: uniqBy([...(topologyConditions?.[0]?.shops || []), ...shops], shop => shop.number),
        })]

        modifyEditingTopologyShopsListInput('')
        modifyEditedAction({
            allNodes: false,
            topologyConditions: updatedTopologyConditions,
        })
        modifyTopologyConditions(updatedTopologyConditions)

        showSnackbar({
            message: (
                <>
                    <span key="1">{t('advertisingActions.affectedZoneDialog.shopsAddedSuccessfully')}</span>
                    <a
                        key="2"
                        className={styles.linkButton}
                        onClick={resetTopologyConditionsChange}
                    >
                        {t('common.cancel')}
                    </a>
                </>
            ),
            duration: SNACKBAR_EXTENDED_DURATION
        })

        onClose()
    }

    render() {
        const { onClose } = this.props
        const { activeTab } = this.state
        const selectedTabIndex: number = ALL_TABS.findIndex(tab => tab.type === activeTab)

        return (
            <Dialog
                id="affectedZoneEditor"
                open
                className={styles.dialog}
            >
                <DialogTitle className={styles.dialogTitle}>
                    {t('advertisingActions.affectedZone')}
                </DialogTitle>
                <DialogContent className={styles.dialogContent}>
                    <Tabs
                        className={styles.tabs}
                        tabsContainerClassName={styles.container}
                        tabsClassName={styles.tab}
                        tabContentClassName={styles.content}
                        items={this.dataLabels}
                        defaultSelectedIndex={selectedTabIndex}
                        onTabChange={(tab: TabInfo) => this.setState({ activeTab: tab.data })}
                    />
                    <div className={styles.tabContent}>
                        {ALL_TABS[selectedTabIndex].component}
                    </div>
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button
                        id="closeAffectedZoneEditorButton"
                        onClick={onClose}
                        color="primary"
                    >
                        {t('common.close')}
                    </Button>
                    <Button
                        id="saveAffectedZoneEditorButton"
                        disabled={false}
                        onClick={this.handleSave}
                        color="primary"
                        style={{ marginRight: 4 }}
                    >
                        {t('common.apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
