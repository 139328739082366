export interface PluginProperty {
    '@class': 'ru.crystals.setretailx.paymenttype.register.config.xml.datatypes.PluginProperty'
    properties?: PluginProperty[]
    value?: string
    key?: string
}

export const createPluginProperty = (initialization: Partial<PluginProperty>): PluginProperty => {
    if (!initialization) return null
    
    let result: PluginProperty = {
        properties: initialization.properties,
        value: initialization.value,
        key: initialization.key,
        '@class': 'ru.crystals.setretailx.paymenttype.register.config.xml.datatypes.PluginProperty',
    }
    
    return result
}
