import * as React from 'react'
import { XMLTagSubTemplate } from '../../../core/price-tags/xml/xml-tag-sub-template'
import { metersToPixels } from '../../../core/price-tags/price-tags-util'

const styles = require('./price-tag-subtemplate-preview.scss')

const FONT_SIZE_M = 14

export interface PriceTagSubtemplatePreviewProps extends React.HTMLProps<HTMLDivElement> {
    element: XMLTagSubTemplate
    name: string
    scale: number
}

export class PriceTagSubtemplatePreview extends React.Component<PriceTagSubtemplatePreviewProps> {

    render() {
        const { element, name, scale, ...other } = this.props

        return (
            <div
                {...other}
                className={styles.subTemplatePreview}
                style={{
                    fontSize: FONT_SIZE_M * scale / metersToPixels(0.001),
                    ...(this.props.style as object || {})
                }}
            >
                { name }
            </div>
        )
    }
}
