import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Add from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { UserRoleVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/user-role-vo'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { RolesStore } from '../../../store/staff/roles-store'
import { ROLES_STORE } from '../../../store/stores'
import Fab from '@material-ui/core/Fab'

const styles = require('./roles.scss')

@inject(ROLES_STORE)
@observer
export class Roles extends React.Component<RolesProps> {

    componentDidMount(): void {
        const {getRoles} = this.props.rolesStore
        getRoles()
    }

    goToRoleHandler = (role: UserRoleVO): void => {
        const {openRole} = this.props.rolesStore
        openRole(role)
    }

    addNewRoleHandler = (): void => {
        const {addNewRole} = this.props.rolesStore
        addNewRole()
    }

    render() {
        const {roles} = this.props.rolesStore

        return (
            <div
                id="rolesPage"
                className={styles.roles}
            >
                { !roles || roles.length === 0 ? (
                    <Paper>
                        <p className={styles.title}>{t('staff.allRoles')}</p>
                        <Empty id="rolesPageEmpty" />
                    </Paper>
                ) : (
                    <Table
                        id="rolesList"
                        withPaper
                        keyField="id"
                        title={t('staff.allRoles')}
                        items={toJS(roles)}
                        sortingFields={['roleName']}
                        onRowDoubleClick={this.goToRoleHandler}
                        columns={[
                            {
                                labelField: 'roleName'
                            },
                            {
                                padding: '0 10px',
                                hAlign: RIGHT,
                                showOnRowHover: true,
                                withControls: true,
                                renderer: props => {
                                    let role: UserRoleVO = props.item
                                    return (
                                        <AdaptiveIconButton
                                            id={`roleEditButton${role.id}`}
                                            label={t('common.edit')}
                                            showTooltip
                                            onClick={() => this.goToRoleHandler(role)}
                                        >
                                            <Edit />
                                        </AdaptiveIconButton>
                                    )
                                }
                            }
                        ]}
                    />
                ) }
                <Fab
                    id="addRoleButton"
                    className={styles.addButton}
                    color="primary"
                    onClick={this.addNewRoleHandler}
                >
                    <Add className={styles.addIcon}/>
                </Fab>
            </div>
        )
    }
}

export interface RolesProps extends React.HTMLProps<HTMLDivElement> {
    rolesStore?: RolesStore
}
