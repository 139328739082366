// tslint:disable:no-string-literal
import { parseString, Builder, OptionsV2 } from 'xml2js'
import { escapeRegExp } from 'lodash'
import { DeserializedMenuVO, CashMenuContent, XmlButton } from './deserialized-menu-vo'
import { MenuVO, createMenuVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/menu-vo'

const ATTR_KEY: string = '$'
const CHILD_KEY: string = '$$'
const TAG_KEY: string = '#name'
/* библиотека xml стала ругаться на символы (⌐□_□) */
const PSEUDO_UNIQUE_DELIMITER: string = 'PSEUDO_UNIQUE_DELIMITER'

/**
 * Десериализирует XML-строку MenuVO.xmlContent в DeserializedMenuVO
 * @param menu
 */
export async function deserializeCashMenuXML(menu: MenuVO): Promise<any | Error> { // возвращает DeserializedMenuVO
    return new Promise((resolve, reject) => {
        const parserOptions: OptionsV2 = {
            attrkey: ATTR_KEY,
            childkey: CHILD_KEY,
            emptyTag: null,
            explicitArray: false,
            mergeAttrs: false,
            explicitChildren: true,
            preserveChildrenOrder: true,
            valueProcessors: [
                name => {
                    return name
                }
            ],
        }

        parseString(menu.xmlContent, parserOptions, (error: Error, data: any) => {
            if (error) {
                return reject(error)
            }

            data = normalizeDeSerializedData(data)

            let result: DeserializedMenuVO = {
                ...menu,
                cashMenuContent: data
            }

            delete result['@class']
            delete result['xmlContent']

            resolve(result)
        })
    })
}

/**
 * Сериализирует структуру DeserializedMenuVO в XML
 * @param deserializedMenu
 */
export function serializeCashMenuToXML(deserializedMenu: DeserializedMenuVO): MenuVO {
    const builder = new Builder({
        headless: true,
        attrkey: ATTR_KEY,
    })

    const res = {
        ...deserializedMenu
    } as any

    const preparedContent = prepareMenuContentBeforeSerialization(res.cashMenuContent)

    res.xmlContent = builder.buildObject(preparedContent)
    res.xmlContent = res.xmlContent.replace(new RegExp(`${escapeRegExp(PSEUDO_UNIQUE_DELIMITER)}\\d{1,}`, 'g'), '')

    return createMenuVO(res)
}

function normalizeDeSerializedData(data: any): CashMenuContent { // на входе структура с $: { ...attributes }, $$: { ...children }
    return {
        ...data,
        cashMenu: {
            ...data.cashMenu[ATTR_KEY],
            children: data.cashMenu[CHILD_KEY].map(recursiveNormalizeButton)
        }
    }
}

function recursiveNormalizeButton(data: any): XmlButton {
    const res = {
        ...data[ATTR_KEY],
        type: data[TAG_KEY]
    }

    if (data[CHILD_KEY]) {
        res.children = data[CHILD_KEY].map(recursiveNormalizeButton)
    }

    return res
}

/*
* чтобы всё корректно сериализовалось, текущей библиотеке нужно подать на вход примерно такую структуру:
cashMenu: {
    ...
    'menu___0': {
        ...
        'command___0': {
            $: {
                name: 'Редактирование чека',
                code: 'command_chkEdit',
                id: '1'
            }
        },
        'menu___1': {
            ...
        }
        'payment___2': {
            ...
        },
    }
}
* а затем вырезать из готовой строки с xml лишние "___0"
* */
function prepareMenuContentBeforeSerialization(content: CashMenuContent): any {
    const { children, ...other } = content.cashMenu
    const res: any = {
        cashMenu: {
            [ATTR_KEY]: {
                ...other
            }
        }
    }

    if (children) {
        children.forEach((child, index) => {
            res.cashMenu[`${child.type}${PSEUDO_UNIQUE_DELIMITER}${index}`] = recursivePrepareButton(child)
        })
    }

    return res
}

function recursivePrepareButton(button: XmlButton): any {
    const { children, type, ...other } = button

    const res: any = {
        [ATTR_KEY]: {
            ...other
        }
    }

    if (children) {
        children.forEach((child, index) => {
            res[`${child.type}${PSEUDO_UNIQUE_DELIMITER}${index}`] = recursivePrepareButton(child)
        })
    }

    return res
}
