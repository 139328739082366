// Блокировка карт
export const CARDS_BLOCK_CARD_PRIVILEGE = 'CARDS_BLOCK_CARD_PRIVILEGE'
// Замена карт
export const CARDS_CHANGE_CARD_PRIVILEGE = 'CARDS_CHANGE_CARD_PRIVILEGE'
// Купоны, добавление купонов
export const CARDS_COUPONS_EDIT_PRIVILEGE = 'CARDS_COUPONS_EDIT_PRIVILEGE'
// Внешние карты, добавление внешних карт
export const CARDS_EXTERNAL_CARDS_EDIT_PRIVILEGE = 'CARDS_EXTERNAL_CARDS_EDIT_PRIVILEGE'
// Поиск карт и работа с экземплярами карт
export const CARDS_INFODESK_PRIVILEGE = 'CARDS_INFODESK_PRIVILEGE'
// Просмотр анкет и карт клиентов
export const CARDS_INTERNAL_AND_CLIENT_VIEW_PRIVILEGE = 'CARDS_INTERNAL_AND_CLIENT_VIEW_PRIVILEGE'
// Работа с бонусными счетами
export const CARDS_INTERNAL_CARDS_BONUSACCOUNT_EDIT_PRIVILEGE = 'CARDS_INTERNAL_CARDS_BONUSACCOUNT_EDIT_PRIVILEGE'
// Редактирование кредитного лимита внутренних карт
export const CARDS_INTERNAL_CARDS_CREDIT_LIMIT_EDIT_PRIVILEGE = 'CARDS_INTERNAL_CARDS_CREDIT_LIMIT_EDIT_PRIVILEGE'
// Работа с категориями внутренних карт
export const CARDS_INTERNAL_CARDS_EDIT_PRIVILEGE = 'CARDS_INTERNAL_CARDS_EDIT_PRIVILEGE'
// Создание новых карт
export const CARDS_INTERNAL_CARDS_INSERT_PRIVILEGE = 'CARDS_INTERNAL_CARDS_INSERT_PRIVILEGE'
// Редактирование существующих и добавление новых диапазонов внутренних карт
export const CARDS_INTERNAL_CARDS_RANGE_EDIT_PRIVILEGE = 'CARDS_INTERNAL_CARDS_RANGE_EDIT_PRIVILEGE'
// Редактирование существующих анкет для владельцев карт
export const CARDS_INTERNAL_CLIENT_EDIT_PRIVILEGE = 'CARDS_INTERNAL_CLIENT_EDIT_PRIVILEGE'
// Добавление новых анкет для владельцев карт
export const CARDS_INTERNAL_CLIENT_INSERT_PRIVILEGE = 'CARDS_INTERNAL_CLIENT_INSERT_PRIVILEGE'
// Работа с отложенными анкетами
export const CARDS_INTERNAL_DEFERRED_CLIENT_PRIVILEGE = 'CARDS_INTERNAL_DEFERRED_CLIENT_PRIVILEGE'
// Создание новых подчиненных карт
export const CARDS_INTERNAL_SLAVECARDS_INSERT_PRIVILEGE = 'CARDS_INTERNAL_SLAVECARDS_INSERT_PRIVILEGE'
// Ручное добавление начислений
export const CARDS_INTERNAL_CARDS_ADD_OPERATION_PRIVILEGE = 'CARDS_INTERNAL_CARDS_ADD_OPERATION_PRIVILEGE'
// Активация подарочных карт
export const CARDS_PRESENT_CARDS_ACTIVATION_PRIVILEGE = 'CARDS_PRESENT_CARDS_ACTIVATION_PRIVILEGE'
// Подарочные карты, просмотр подарочных карт
export const CARDS_PRESENT_CARDS_VIEW_PRIVILEGE = 'CARDS_PRESENT_CARDS_VIEW_PRIVILEGE'
// Подарочные карты, добавление подарочных карт
export const CARDS_PRESENT_CARDS_EDIT_PRIVILEGE = 'CARDS_PRESENT_CARDS_EDIT_PRIVILEGE'
// Продление срока действия подарочной карты
export const CARDS_PRESENT_CARDS_EXTEND_PRIVILEGE = 'CARDS_PRESENT_CARDS_EXTEND_PRIVILEGE'
// Редактирование и добавление диапазонов внутренних карт
export const CARDS_PRESENT_CARDS_RANGE_EDIT_PRIVILEGE = 'CARDS_PRESENT_CARDS_RANGE_EDIT_PRIVILEGE'
// Поиск карт по номеру чека
export const CARDS_SEARCH_CARDS_BY_CHECK = 'CARDS_SEARCH_CARDS_BY_CHECK'
// Поиск сегментов
export const CARDS_SEARCH_SEGMENTS = 'CARDS_SEARCH_SEGMENTS'
// Пользователь ЦО
export const CENTRUM_USER = 'CENTRUM_USER'
// Редактирование заголовка счетов-фактур и УПД
export const EDIT_HEADER_UPD_IN_REGISTRY_PRIVILEGE = 'EDIT_HEADER_UPD_IN_REGISTRY_PRIVILEGE'
// Редактирование состава счетов-фактур и УПД
export const EDIT_ROWS_UPD_IN_REGISTRY_PRIVILEGE = 'EDIT_ROWS_UPD_IN_REGISTRY_PRIVILEGE'
// Изменение очередности
export const LOY_CHANGE_ACTIONS_PRIORITY = 'LOY_CHANGE_ACTIONS_PRIORITY'
// Заведение рекламных акций
export const LOY_EDIT = 'LOY_EDIT'
// Поиск акций по коду и названию
export const LOY_SEARCH_BY_CODE_AND_NAME = 'LOY_SEARCH_BY_CODE_AND_NAME'
// Поиск акций по товару
export const LOY_SEARCH_BY_PRODUCT = 'LOY_SEARCH_BY_PRODUCT'
// Запуск рекламных акций
export const LOY_START = 'LOY_START'
// Остановка рекламных акций
export const LOY_STOP = 'LOY_STOP'
// Работа с акциями через таймлайн
export const LOY_TIMELINE_VIEW = 'LOY_TIMELINE_VIEW'
// Создание новых меток
export const LOY_CREATE_ACTION_LABEL = 'LOY_CREATE_ACTION_LABEL'
// Просмотр рекламных отчетов в операционном дне
export const OPERDAY_ADVERTISING_REPORTS = 'OPERDAY_ADVERTISING_REPORTS'
// Ремонт касс, возврат касс из ремонта
export const OPERDAY_CASH_REPAIR_BACK_PRIVILEGE = 'OPERDAY_CASH_REPAIR_BACK_PRIVILEGE'
// Поиск и просмотр банковских транзакций
export const OPERDAY_CHECK_TRANSACTIONS = 'OPERDAY_CHECK_TRANSACTIONS'
// Поиск и просмотр чеков, печать сопроводительных документов
export const OPERDAY_CHECK_VIEW_PRIVILEGE = 'OPERDAY_CHECK_VIEW_PRIVILEGE'
// Поиск и просмотр нефискальных документов
export const OPERDAY_NON_FISCAL_DOCUMENTS_ISSUE_AN_ORDER = 'OPERDAY_NON_FISCAL_DOCUMENTS_ISSUE_AN_ORDER'
// Работа с вкладкой Итоги дня
export const OPERDAY_DAY_TOTALS_WORK_PRIVILEGE = 'OPERDAY_DAY_TOTALS_WORK_PRIVILEGE'
// Просмотр финансовых отчетов в операционном дне
export const OPERDAY_FINANCIAL_REPORTS = 'OPERDAY_FINANCIAL_REPORTS'
// Закрытие операционного дня
export const OPERDAY_OPERDAY_CLOSE_PRIVILEGE = 'OPERDAY_OPERDAY_CLOSE_PRIVILEGE'
// Работа с вкладкой Кассы
export const OPERDAY_OPERDAY_WORK_PRIVILEGE = 'OPERDAY_OPERDAY_WORK_PRIVILEGE'
// Работа с вкладкой Кассиры
export const WORK_SHIFT_CASHIERS = 'WORK_SHIFT_CASHIERS'
// Просмотр прочих отчетов в операционном дне
export const OPERDAY_OTHER_REPORTS = 'OPERDAY_OTHER_REPORTS'
// Печать счет фактуры
export const OPERDAY_PRINT_GOODS_BILL_PRIVILEGE = 'OPERDAY_PRINT_GOODS_BILL_PRIVILEGE'
// Печать товарного чека
export const OPERDAY_PRINT_GOODS_CHEQUE_PRIVILEGE = 'OPERDAY_PRINT_GOODS_CHEQUE_PRIVILEGE'
// Печать товарного чека (алкоголь)
export const OPERDAY_PRINT_GOODS_CHEQUE_WITH_ALCOHOL_PRIVILEGE = 'OPERDAY_PRINT_GOODS_CHEQUE_WITH_ALCOHOL_PRIVILEGE'
// Печать товарной накладной
export const OPERDAY_PRINT_GOODS_INVOICE_PRIVILEGE = 'OPERDAY_PRINT_GOODS_INVOICE_PRIVILEGE'
// Печать номенклатуры чека
export const OPERDAY_PRINT_PURCHASE_NOMENCLATURE_PRIVILEGE = 'OPERDAY_PRINT_PURCHASE_NOMENCLATURE_PRIVILEGE'
// Печать УПД
export const OPERDAY_PRINT_UPD_PRIVILEGE = 'OPERDAY_PRINT_UPD_PRIVILEGE'
// Работа с вкладкой Проблемные смены
export const OPERDAY_PROBLEMATIC_SHIFTS_WORK_PRIVILEGE = 'OPERDAY_PROBLEMATIC_SHIFTS_WORK_PRIVILEGE'
// Отчеты секции "Оптимизация работы кассового узла"
export const OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE = 'OPERDAY_REPORT_CASH_WORK_OPTIMIZATION_PRIVILEGE'
// Просмотр и печать КМ-3
export const OPERDAY_REPORT_KM_3_PRIVILEGE = 'OPERDAY_REPORT_KM-3_PRIVILEGE'
// Просмотр и печать КМ-6
export const OPERDAY_REPORT_KM_6_PRIVILEGE = 'OPERDAY_REPORT_KM-6_PRIVILEGE'
// Отчеты секции "Работа магазина"
export const OPERDAY_REPORT_MARKET_WORK_PRIVELEGE = 'OPERDAY_REPORT_MARKET_WORK_PRIVELEGE'
// Отчеты по мотивации персонала
export const OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE = 'OPERDAY_REPORT_PERSONAL_MOTIVATION_PRIVILEGE'
// Работа с вкладкой главной кассы
export const MAIN_CASH_DESK_DOCUMENTS_PRIVILEGE = 'MAIN_CASH_DESK_DOCUMENTS_PRIVILEGE'
// Просмотр реестра счетов-фактур и УПД
export const REVIEW_UPD_REGISTRY_PRIVILEGE = 'REVIEW_UPD_REGISTRY_PRIVILEGE'
// Администрирование вкладки "Кассиры"
export const SETRETAILX_CASHIERS_ADMIN = 'SETRETAILX_CASHIERS_ADMIN'
// Изменение лицензионного ключа
export const SETRETAILX_CHANGE_LICENSE_KEY = 'SETRETAILX_CHANGE_LICENSE_KEY'
// Редактирование шаблонов кассовых документов
export const SETRETAILX_CHECK_DOC_EDITING = 'SETRETAILX_CHECK_DOC_EDITING'
// Выбор принтера для печати
export const SETRETAILX_CHOICE_PRINTER_FOR_PRICETAG = 'SETRETAILX_CHOICE_PRINTER_FOR_PRICETAG'
// Администрирование шаблонов касс
export const SETRETAILX_CASHTEMPLATE_ADMIN = 'SETRETAILX_CashTemplate_ADMIN'
// Администрирование общих настроек
export const SETRETAILX_COMMONSETUP_ADMIN = 'SETRETAILX_CommonSetup_ADMIN'
// Администрирование вкладки "Оборудование"
export const SETRETAILX_EQUIPMENT_ADMIN = 'SETRETAILX_EQUIPMENT_ADMIN'
// Администрирование ERP
export const SETRETAILX_ERP_ADMIN = 'SETRETAILX_ERP_ADMIN'
// Администрирование внешних систем
export const SETRETAILX_EXTERNALSYSTEMS_ADMIN = 'SETRETAILX_EXTERNALSYSTEMS_ADMIN'
// Группировка шаблонов по привязке принтеров
export const SETRETAILX_GROUP_TEMPLATES_BY_PRINTERS_BINDING = 'SETRETAILX_GROUP_TEMPLATES_BY_PRINTERS_BINDING'
// Администрирование товаров
export const SETRETAILX_GOODS_ADMIN = 'SETRETAILX_Goods_ADMIN'
// Управление товарами в ассортименте
export const SETRETAILX_GOODS_ASSORTMENT = 'SETRETAILX_Goods_ASSORTMENT'
// Удаление штриховых кодов
export const SETRETAILX_GOODS_BARCODE_DELETING = 'SETRETAILX_Goods_BARCODE_DELETING'
// Изменение данных товара
export const SETRETAILX_GOODS_CHANGING = 'SETRETAILX_Goods_Changing'
// Ручное изменение даты изготовления
export const SETRETAILX_GOODS_DATEOFMANUFACTURE_CHANGE = 'SETRETAILX_Goods_DateOfManufacture_Change'
// Заведение уценок
export const SETRETAILX_GOODS_MARKDOWNS = 'SETRETAILX_Goods_MARKDOWNS'
// Формирование упаковок
export const SETRETAILX_GOODS_PACKAGES = 'SETRETAILX_Goods_PACKAGES'
// Назначение шаблонов ценников
export const SETRETAILX_GOODS_PRICETAGS = 'SETRETAILX_Goods_PRICETAGS'
// Утверждение цен без проведения печати ценников
export const SETRETAILX_GOODS_PRICE_WITHOUT_PRICETAGS_PRINTING = 'SETRETAILX_Goods_PRICE_WITHOUT_PRICETAGS_PRINTING'
// Печать и утверждение ценников с полки Не напечатанных ценников
export const SETRETAILX_PRICE_TEMPLATE_PRINT_FOR_PRICE_APPROVE = 'SETRETAILX_PriceTemplate_PRINT_FOR_PRICE_APPROVE'
// Фильтр товары на остатках
export const SETRETAILX_PRICE_TEMPLATE_PRINT_IN_STOCK_FILTER = 'SETRETAILX_PriceTemplate_PRINT_IN_STOCK_FILTER'
// Печать ценников по умолчанию
export const SETRETAILX_PRICE_TEMPLATE_PRINT_DEFAULT = 'SETRETAILX_PriceTemplate_PRINT_DEFAULT'
// Печать ценников вручную
export const SETRETAILX_PRICE_TEMPLATE_PRINT_MANUALLY = 'SETRETAILX_PriceTemplate_PRINT_MANUALLY'
// Проведение перемаркировки
export const SETRETAILX_GOODS_RELABELING = 'SETRETAILX_Goods_RELABELING'
// Поиск товаров со стартового экрана
export const SETRETAILX_GOODS_SEARCH = 'SETRETAILX_Goods_SEARCH'
// Работа с короткими кодами
export const SETRETAILX_GOODS_SHORTCODE = 'SETRETAILX_Goods_SHORTCODE'
// Администрирование настроек модулей
export const SETRETAILX_MODULESCONFIG = 'SETRETAILX_ModulesConfig'
// Печать списка PLU
export const SETRETAILX_PRINT_PLUES_LIST = 'SETRETAILX_PRINT_PLUES_LIST'
// Администрирование шаблонов ценников
export const SETRETAILX_PRICETEMPLATE_ADMIN = 'SETRETAILX_PriceTemplate_ADMIN'
// Изменение названия товара в меню перед печатью ценника
export const SETRETAILX_PRICETEMPLATE_CHANGE_NAME_OF_GOOD_BEFORE_PRINTING = 'SETRETAILX_PriceTemplate_CHANGE_NAME_OF_GOOD_BEFORE_PRINTING'
// Изменение цен в меню перед печатью ценников
export const SETRETAILX_PRICETEMPLATE_CHANGE_PRICE_BEFORE_PRINTING = 'SETRETAILX_PriceTemplate_CHANGE_PRICE_BEFORE_PRINTING'
// Печать ценников по умолчанию
export const SETRETAILX_PRICETEMPLATE_PRINT_DEFAULT = 'SETRETAILX_PriceTemplate_PRINT_DEFAULT'
// Печать и утверждение ценников с полки "Не напечатанных ценников"
export const SETRETAILX_PRICETEMPLATE_PRINT_FOR_PRICE_APPROVE = 'SETRETAILX_PriceTemplate_PRINT_FOR_PRICE_APPROVE'
// Выбор шаблонов для печати
export const SETRETAILX_PRICETEMPLATE_PRINT_MANUALLY = 'SETRETAILX_PriceTemplate_PRINT_MANUALLY'
// Работа со списками разрешённых и запрещённых товаров
export const SETRETAILX_SCALES_TEMPLATE_BAN_LIST = 'SETRETAILX_SCALES_TEMPLATE_BAN_LIST'
// Списки товаров для кассового меню
export const SETRETAILX_SALEGROUPS = 'SETRETAILX_SaleGroups'
// Списки товаров для весов
export const SETRETAILX_SCALESSALEGROUPS = 'SETRETAILX_ScalesSaleGroups'
// Администрирование настроек магазина
export const SETRETAILX_SHOPSETUP_ADMIN = 'SETRETAILX_ShopSetup_ADMIN'
// Администрирование настроек типов товаров и оплат
export const SETRETAILX_TYPESGONFIG = 'SETRETAILX_TypesGonfig'
// Администрирование вкладки "Пользователи"
export const SETRETAILX_USER_ADMIN = 'SETRETAILX_USER_ADMIN'
// Администрирование шаблонов весов
export const SETRETAILX_WEIGHERTEMPLATE_ADMIN = 'SETRETAILX_WeigherTemplate_ADMIN'
// Администрирование кассиров только по своему магазину
export const SETRETAILX_CASHIERS_VISIBILITY = 'SETRETAILX_CASHIERS_VISIBILITY'
// Администрирование шаблонов ценников
export const SETRETAILX_PRICE_TEMPLATE_ADMIN = 'SETRETAILX_PriceTemplate_ADMIN'
// Администрирование форматов шаблонов ценников
export const SETRETAILX_PRICE_TEMPLATE_PRICETAG_FORMAT_ADMINISTRATION = 'SETRETAILX_PriceTemplate_PRICETAG_FORMAT_ADMINISTRATION'
// Назначение размеру ценника названия
export const SETRETAILX_PRICE_TEMPLATE_PRICE_TAG_SIZE_LABELS = 'SETRETAILX_PriceTemplate_PRICE_TAG_SIZE_LABELS'
// Администрирование электронных ценников
export const SETRETAILX_ESL_ADMINISTRATION = 'SETRETAILX_ESL_ADMINISTRATION'
// Просмотр группы продаж
export const SALES_GROUPS_PRIVILEGE = 'SALES_GROUPS'
// Редактирование группы продаж
export const EDIT_SALES_GROUPS = 'EDIT_SALES_GROUPS'
// Отображение директивной печати
export const SETRETAILX_DIRECTIVE_PRINTING_VISIBLE_PRIVELEGE = 'SETRETAILX_DirectivePrinting_DIRECTIVE_PRINTING_VISIBLE_PRIVELEGE'
// Выбор форматов ценников для печати вручную
export const SETRETAILX_PRICE_TEMPLATE_FORMATS_MANUAL_SELECTION = 'SETRETAILX_PriceTemplate_FORMATS_MANUAL_SELECTION'
//Изменение цены в меню печати перед печатью ценника
export const SETRETAILX_PRICE_TEMPLATE_CHANGE_PRICE_BEFORE_PRINTING = 'SETRETAILX_PriceTemplate_CHANGE_PRICE_BEFORE_PRINTING'
//Изменение названия товара в меню перед печатью ценника
export const SETRETAILX_PRICE_TEMPLATE_CHANGE_NAME_OF_GOOD_BEFORE_PRINTING = 'SETRETAILX_PriceTemplate_CHANGE_NAME_OF_GOOD_BEFORE_PRINTING'
// Ручная выгрузка товаров в ESL
export const SETRETAILX_ESL_HAND_SEND = 'SETRETAILX_ESL_HAND_SEND'
// Управление сроком действия сегментов
export const SEGMENTS_EXPIRATION_DATE_MANAGEMENT = 'SEGMENTS_EXPIRATION_DATE_MANAGEMENT'
// Администрирование ролей кассиров
export const SETRETAILX_CASHIER_ROLES_ADMIN = 'SETRETAILX_CASHIER_ROLES_ADMIN'
// Печать из Истории печати
export const SETRETAILX_PRINT_PRINT_LOG = 'SETRETAILX_PRINT_PrintLog'
// Закрытие смены с сервера
export const CLOSE_SHIFT_CASH_FROM_SERVER = 'CLOSE_SHIFT_CASH_FROM_SERVER'
// Расчет кассира
export const FUNCTION_CASHIER_CALCULATION = 'FUNCTION_CASHIER_CALCULATION'
// Запрет продажи
export const SALE_DENIED = 'SALE_DENIED'
// Настройка расписаний меню тач-касс
export const SETRETAILX_TOUCH_MENU_SCHEDULES_ADMIN = 'SETRETAILX_TOUCH_MENU_SCHEDULES_ADMIN'
//Редактирование производителя товара
export const EDIT_PRODUCT_PRODUCER = 'EDIT_PRODUCT_PRODUCER'
// Привилегия, применяемая ко всем роутам, которым не назначено привелегий
export const DEFAULT_PRIVILEGE = 'SETRETAILX_PRIVILEGE_FOR_UNCLOSED_FUNCTIONALITY'
// Настройка модуля валидации
export const VALIDATION_MODULE = 'VALIDATION_MODULE'
// Фильтрация данных в Операционном дне центрального офиса по топологии пользователя
export const DATA_FILTERED_BY_USER_TOPOLOGY = 'DATA_FILTERED_BY_USER_TOPOLOGY'
