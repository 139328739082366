import { PrintingBoxVO } from './set-retail10-server/retailx/set-print-price-tags/printing-box-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class PrintingBoxManager extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-PrintPriceTags/PrintingBoxManagerBean!ru.crystals.setretailx.printing.protocol.manager.PrintingBoxManager'
    }

    check = (taskUUID: string, options?: RequestOptions): Promise<PrintingBoxVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'check',
                params: [taskUUID]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    checkShelf = (shelf: number, options?: RequestOptions): Promise<string[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'checkShelf',
                params: [shelf]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    execute = (taskUUID: string, command: string, options?: RequestOptions): Promise<PrintingBoxVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'execute',
                params: [taskUUID, command]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const printingBoxManager: PrintingBoxManager = new PrintingBoxManager()
