import React from 'react'
import { t } from 'i18next'
import moment from 'moment'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { BaseTable, BaseTableProps } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { withSelectionAsColumn } from '@crystalservice/crystals-ui/lib/components/new-table/with-selection-as-column'
import { TemplateNameRenderer } from './template-name-renderer'
import { PriceTagTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'

interface PriceTagsTemplatesTableProps {
    items: PriceTagTemplateVO[]
    onSelected: (templates: PriceTagTemplateVO[]) => void
    selected: PriceTagTemplateVO[]
}

const TableWithSelection = withSelectionAsColumn<PriceTagTemplateVO, BaseTableProps<PriceTagTemplateVO>>(BaseTable)

export const PriceTagsTemplatesTable: React.FC<PriceTagsTemplatesTableProps> = props => {
    const { selected, onSelected, items } = props
    const selectedPriceTagsTemplates = selected.map(priceTagsTemplate => priceTagsTemplate.id)

    if (!items || !items.length) {
        return (
            <Empty message={t('priceTags.noPriceTags')}/>
        )
    }

    const keyFunc = (item: PriceTagTemplateVO) => item.id

    return (
        <TableWithSelection
            items={items}
            selectedKeys={selectedPriceTagsTemplates}
            onSelectionChange={(keys: string[]) => {
                if (keys.length === selectedPriceTagsTemplates.length) return
                const priceTagsTempates = items.filter(priceTagsTemplate => keys.includes(priceTagsTemplate.id))

                onSelected(priceTagsTempates)
            }}
            WrapperProps={{ height: '100%' }}
            keyFunction={keyFunc}
            maxVisibleRows={6}
            id="priceTagTemplatesTable"
            columns={[
                {
                    header: t('priceTags.priceTags'),
                    keyField: 'name',
                    renderer: properties => <TemplateNameRenderer priceTag={properties} />,
                    columnWidth: 600,
                },
                {
                    header: t('priceTags.size'),
                    keyField: 'size',
                    renderer: (item: PriceTagTemplateVO) => `${item.width} x ${item.height}`,
                },
                {
                    header: t('priceTags.changeDate'),
                    keyField: 'lastUpdate',
                    renderer: (item: PriceTagTemplateVO) => {
                        const { lastUpdate } = item
                        return lastUpdate ? moment(lastUpdate).format('DD.MM.YYYY HH:mm') : ''
                    },
                },
            ]}
        />
    )
}
