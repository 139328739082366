import { MessagesResultData } from '../advertising-actions'

export function validateMessages(result: MessagesResultData): boolean {
    // Сообщение кассиру
    if (result?.operatorMessage?.[0]) {
        return true
    }

    // Сообщение покупателю
    if (result?.clientSCOMessage?.[0]) {
        return true
    }

    return false
}
