import React from 'react'
import { RouterStore } from 'mobx-react-router'
import { IFRAME_MODULES_STORE, ROUTING_STORE } from '../../store/stores'
import { inject, observer } from 'mobx-react'
import { IframeModulesStore } from '../../store/iframe-modules-store'
import { ModuleItem } from '../../core/iframe-modules/entities'

interface IframeContainerProps {
    iframeModulesStore?: IframeModulesStore
    routing?: RouterStore
}

interface IframeContainerState {
    activeModuleUrl: string
}

@inject(ROUTING_STORE)
@inject(IFRAME_MODULES_STORE)
@observer
export class IframeContainer extends React.Component<IframeContainerProps, IframeContainerState> {

    frameRef: React.RefObject<HTMLIFrameElement> = React.createRef<HTMLIFrameElement>()

    state: IframeContainerState = {
        activeModuleUrl: ''
    }

    componentDidMount() {
        // при первом рендере ставим moduleUrl этой страницы
        const { externalModulesNavigation } = this.props.iframeModulesStore
        const { location } = this.props.routing
        const activeModule: ModuleItem = recursiveFindModule(externalModulesNavigation, location.pathname)
        if (!activeModule) {
            return
        }

        const newModulePath: string = toModulePath(activeModule.modulePathPattern, location.pathname)

        if (!newModulePath) {
            return
        }

        this.setState({
            activeModuleUrl: activeModule.moduleBaseUrl + newModulePath
        })
    }

    componentWillReceiveProps(nextProps: Readonly<IframeContainerProps>, nextContext: any) {
        // при последующих просто пушим на нужный modulePath чтобы избежать полной перезагрузки страницы в iframe
        const { externalModulesNavigation } = nextProps.iframeModulesStore
        const { location } = nextProps.routing

        const activeModule: ModuleItem = recursiveFindModule(externalModulesNavigation, location.pathname)
        if (!activeModule) {
            return
        }

        const newModulePath: string = toModulePath(activeModule.modulePathPattern, location.pathname)

        if (!newModulePath) {
            return
        }

        this.props.iframeModulesStore.changeIframeRoute(newModulePath)
    }

    componentWillUnmount() {
        this.props.iframeModulesStore.reset()
    }

    render() {
        const { initProtocol } = this.props.iframeModulesStore

        if (!this.state.activeModuleUrl) {
            return <div>...</div>
        }

        return (
            <div style={{
                margin: '-24px',
                height: 'calc(100% + 42px)',
            }}>
                <iframe
                    src={this.state.activeModuleUrl}
                    ref={this.frameRef}
                    onLoad={() => {
                        initProtocol(this.frameRef.current)
                    }}
                    sandbox={[
                        'allow-downloads-without-user-activation',
                        'allow-forms',
                        'allow-modals',
                        'allow-orientation-lock',
                        'allow-pointer-lock',
                        'allow-popups',
                        'allow-popups-to-escape-sandbox',
                        'allow-presentation',
                        'allow-same-origin',
                        'allow-scripts',
                        'allow-storage-access-by-user-activation',
                        'allow-top-navigation',
                        'allow-top-navigation-by-user-activation',
                        'allow-downloads'
                    ].join(' ')}
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none'
                    }}
                    // title={activeModule.name}
                />
            </div>
        )
    }
}

function toModulePatternRegex(pattern: string): RegExp {
    return new RegExp(pattern.replace(/:[^/]+/g, '[^/]+') + '$')
}

function toModulePath(modulePattern: string, path: string): string {
    const match = path.match(toModulePatternRegex(modulePattern))
    if (!match) {
        return ''
    }
    return match[0]
}

function recursiveFindModule(modules: ModuleItem[], path: string): ModuleItem {
    for (let module of modules) {
        if (toModulePatternRegex(module.parentPathPattern).test(path)) {
            return module
        }
        const foundChild = recursiveFindModule(module.children, path)

        if (foundChild) {
            return foundChild
        }
    }

    return null
}
