export enum PluginPropertyKeys {
    MRC_CODE = 'mrc',
    DATE_CODE = 'date-of-manufacture',
    PRODUCER_CODE = 'producer',
    COMPOSITION_CODE = 'composition',
    FOOD_VALUE_CODE = 'food-value',
    STORAGE_CONDITIONS_CODE = 'storage-conditions',

    CONSIGNMENT = 'consignment',
    WEIGHING_TO_SALE_DELAY = 'weighing-to-sale-delay'
}
