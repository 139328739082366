import { ProductListConditionType } from './product-list-condition-type'

export interface ProductListConditionVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.ProductListConditionVO'
    id?: number
    productListId?: number
    type?: ProductListConditionType
    value?: string
    name?: string
    identifier?: string
}

export const createProductListConditionVO = (initialization: Partial<ProductListConditionVO>): ProductListConditionVO => {
    if (!initialization) return null
    
    let result: ProductListConditionVO = {
        id: initialization.id,
        productListId: initialization.productListId,
        type: initialization.type,
        value: initialization.value,
        name: initialization.name,
        identifier: initialization.identifier,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.ProductListConditionVO',
    }
    
    return result
}
