import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { t } from 'i18next'
import React from 'react'
import uuid from 'uuid'
import { ExpandButton } from '../../../components/buttons/expand-button/expand-button'
import { FilterType, toSearchArgument, EMPTY_FILTER_VALUE } from '../../core/filters/filter'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { observer, inject } from 'mobx-react'
import { FAVORITE_FILTERS_STORE } from '../../store/stores'
import { FavoriteFiltersStore } from '../../store/operday/favorite-filters-store'
import { FiltersComponentInstance } from '../../core/filters/favorite-filters'
import Tooltip from '@material-ui/core/Tooltip'
import { TOOLTIP_DELAY } from '../../../utils/default-timeouts'
import Star from '@material-ui/icons/Star'
import StarBorder from '@material-ui/icons/StarBorder'
import { FILTER_GROUP } from './components/filter-group'
import { toJS } from 'mobx'

const styles = require('./new-filters.scss')

export interface FiltersProps {
    id?: string
    /**
     * Заголовок над фильтрами
     */
    title?: string
    /**
     * Текст на нижнем баре с результатами поиска, например: "Найдено чеков: 1023"
     */
    searchResultText?: string
    /**
     * Дефолтное состояние фильтров, необязательно задавать все свойства.
     * Если свойство не задано, то в него подставится дефолтное значение.
     */
    defaultState?: FiltersState
    /**
     * Элемент, отображаемый на нижней панели действий
     */
    actionElement?: React.ReactElement<any>
    /**
     * Режим отображения верхнего бара.
     * В режиме 'full' отображаются только favorite фильтры, или те, которые имеют введенное значение
     * При отключенном экспандере (или спрятанном баре) отображаются все фильтры
     */
    topBarMode?: 'full' | 'hideExpander' | 'hidden'
    /**
     * Ключ для работы с избранными фильтрами в local storage. Если не указан - избранные фильтры отключены
     */
    favoriteFiltersKey?: FiltersComponentInstance

    onApply: (searchArguments: SearchArgumentVO[]) => void
    onClear?: (state: FiltersState) => void
    onStateChange?: (state: FiltersState) => void

    favoriteFiltersStore?: FavoriteFiltersStore
}

export interface FiltersState {
    key?: string
    /**
     * Развернут ли полный список фильтров
     */
    expanded?: boolean
    /**
     * Показанные фильтры, эти фильтры показываются в свернутом состоянии с указанным значением
     */
    shownFilters?: {[key in FilterType]?: FilterProps}
}

export interface FilterProps<T = any> {
    /**
     * Выбранное значение в строковом виде.
     * Фильтр должен уметь получить полную информацию об объекте по строке.
     * Пустая строка или null означают, что ничего не выбрано.
     */
    value?: string
    /**
     * Фильтр может сохранить полную информацию о выбранном объекте, чтобы не перезапрашивать ее постоянно
     */
    data?: T
    /**
     * Выбран ли фильтр как избранный
     */
    isFavorite?: boolean
    /**
     * При значении true - фильтр отображается в свернутом виде, даже если нет значения или favorite.
     * Пересчитывается при сворачивании
     * Необходим для случаев, когда фильтр отображался по значению, но мы стерли значение.
     */
    alwaysShown?: boolean
}

export interface InternalFilterComponentProps<T = any> extends FilterProps<T> {
    id?: string
    slim?: boolean
    onChange?: (type: FilterType, value: FilterProps<T>) => void

    /**
     * Описательное свойство. Не используется в самом компоненте, только для анализа в родительском компоненте.
     * Означает что фильтр должен быть отображен в свернутом состоянии, если пользователь не выбирал избранные под себя.
     */
    defaultFavorite?: boolean
}

@inject(FAVORITE_FILTERS_STORE)
@observer
export class Filters extends React.Component<FiltersProps, FiltersState> {

    static defaultProps: Partial<FiltersProps> = {
        id: 'filter',
        onClear: () => null,
        onStateChange: () => null,
        topBarMode: 'full',
    }

    /**
     * Получение searchArguments из объекта shownFilters, который должен быть получен из state
     */
    static getSearchArguments = (shownFilters: {[key in FilterType]?: FilterProps}) => {
        if (!shownFilters) return []

        let searchArguments: SearchArgumentVO[] = []

        Object.keys(shownFilters).forEach((key: FilterType) => {
            const filter = shownFilters[key]
            let value = filter.value
            if (value) {
                if (value === EMPTY_FILTER_VALUE) {
                    value = undefined
                }
                searchArguments = searchArguments.concat(toSearchArgument(key, value))
            }
        })
        return searchArguments
    }

    constructor(props: FiltersProps, context: any) {
        super(props, context)

        const { defaultState, favoriteFiltersKey, onStateChange } = this.props

        // toJS на случай, если в пропс передали observable
        let state = toJS(defaultState) || {}
        let shownFilters: {[key in FilterType]?: FilterProps} = state.shownFilters || {}

        if (favoriteFiltersKey) {
            const { defaultFilters } = this.props.favoriteFiltersStore

            defaultFilters.get(favoriteFiltersKey).forEach(filter => {
                // TODO: Хранение в favoriteFilter надо изменить
                // Стоит сделать хранение флага isFavorite причем конкретно true/false/null
                // null - пользователь не трогал эту звездочку
                // false - пользователь умышленно выключил звездочку (в этом случае не сработает defaultFilter)
                const type = filter.type
                if (!shownFilters[type]) {
                    shownFilters[type] = {}
                }
                shownFilters[type].isFavorite = true
            })
        }
        state.shownFilters = shownFilters

        this.state = state
        onStateChange(state)
    }

    expandHandler = (): void => {
        const { onStateChange } = this.props
        this.setState({
            expanded: true,
        },
        () => onStateChange(this.state))
    }

    collapseHandler = (): void => {
        const { onStateChange } = this.props
        this.setState(prevState => {
            // Обновляем флаг alwaysShown. Он должен остаться у фильтров с выбранным значением
            let shownFilters = prevState.shownFilters
            Object.keys(shownFilters).forEach((key: FilterType) => {
                const filter = shownFilters[key]
                filter.alwaysShown = Boolean(filter.value)
            })

            return {
                expanded: false,
                shownFilters
            }
        },
        () => onStateChange(this.state))
    }

    favoriteChangeHandler = (type: FilterType, favorite: boolean): void => {
        const { changeDefaultFilters } = this.props.favoriteFiltersStore
        const { favoriteFiltersKey } = this.props

        let newFavorites: FilterType[] = []
        if (favorite) {
            newFavorites.push(type)
        }

        const shownFilters = this.state.shownFilters
        Object.keys(shownFilters).forEach((key: FilterType) => {
            // Изменяемому фильтру уже поставили статус
            if (key === type) {
                return
            }

            // Остальным фильтрам оставляем какой был
            if (shownFilters[key].isFavorite) {
                newFavorites.push(key)
            }
        })

        changeDefaultFilters(favoriteFiltersKey, newFavorites)

        this.filterChangeHandler(type, { isFavorite: favorite })
    }

    filterChangeHandler = (type: FilterType, changes: FilterProps): void => {
        const { onStateChange } = this.props

        this.setState(prevState => {
            let newState = prevState
            let filter = newState.shownFilters[type] || {}

            Object.keys(changes).forEach(key => {
                filter[key] = changes[key]
            })
            newState.shownFilters[type] = filter

            return newState
        },
        () => onStateChange(this.state))
    }

    applyHandler = (): void => {
        const { onApply } = this.props
        const { shownFilters } = this.state

        const searchArguments = Filters.getSearchArguments(shownFilters)

        onApply(searchArguments)

        // Нужно для перерисовки фильтров (в основном input'ов), в которых пользователь ввёл некорректное значение
        // Например, если пользователь ввёл некорректные даты в фильтре checkTime, это будет обработано как если бы фильтр был не задан
        this.setState({ key: uuid() })

        this.collapseHandler()
    }

    clearHandler = (): void => {
        const { onStateChange, onClear } = this.props
        this.setState(prevState => {
            let shownFilters = prevState.shownFilters

            Object.keys(shownFilters).forEach(key => {
                // Обнуляем все значения
                shownFilters[key].value = null
                shownFilters[key].data = null
                shownFilters[key].wasShown = null
            })

            return {
                // Из-за того что многие фильтры uncontrolled. Необходимо их полностью обновлять
                key: uuid(),
                expanded: false,
                shownFilters
            }
        },
        () => {
            onStateChange(this.state)
            onClear(this.state)
        })
    }

    renderFavoriteButton = (type: FilterType, favorite: boolean) => {
        const { favoriteFiltersKey } = this.props
        const { expanded } = this.state
        if (!favoriteFiltersKey || !expanded) return

        return (
            <div
                className={classNames(styles.favoriteButton, {
                    [styles.active]: favorite
                })}
            >
                <Tooltip
                    id={`${type}-favoriteTooltip`}
                    title={
                        favorite
                            ? t('filters.removeFilterFromFavorites')
                            : t('filters.addFilterToFavorites')
                    }
                    enterDelay={TOOLTIP_DELAY}
                >
                    <div className={styles.tooltip}>
                        {favorite
                            /* IconButton не использовал намеренно, т.к. он предполагает показ "ореола" при наведении */
                            ? <Star
                                id={`${type}-removeFavorite`}
                                onClick={() => this.favoriteChangeHandler(type, false)}
                            />
                            : <StarBorder
                                id={`${type}-addFavorite`}
                                onClick={() => this.favoriteChangeHandler(type, true)}
                            />
                        }
                    </div>
                </Tooltip>
            </div>
        )
    }

    renderFilters = (children: React.ReactNode): React.ReactNode[] => {
        const { topBarMode } = this.props
        const { expanded, shownFilters } = this.state
        const childrenArray = React.Children.toArray(children)
        let filters: React.ReactNode[] = []

        // Проверяем все элементы из children, и добавляем ими нужные props
        childrenArray.forEach((element: any) => {
            if (element.type.filterType === FILTER_GROUP)  {
                // Группа фильтров - отображается только в развернутом режиме
                if (expanded) {
                    // Если развернуто - отображаем группу
                    filters.push(React.cloneElement(element, {
                        key: element.props.name,
                         // Фильтры внутри группы тоже надо обработать
                        children: this.renderFilters(element.props.children)
                    }))
                } else {
                    // Если свернуто - отображаем только фильтры
                    filters = filters.concat(this.renderFilters(element.props.children))
                }
            } else {
                const type: FilterType = element.type.filterType
                if (!type) {
                    // Все фильтры должны содержать в себе "static filterType"
                    console.warn('Filter without type: ', element.type.name)
                    return
                }
                const filterState = shownFilters[type] || {}

                // Значение isFavorite установленное пользователем имеет более высокий приоритет
                let favoriteFilter = element.props.defaultFavorite
                if (filterState.hasOwnProperty('isFavorite')) {
                    favoriteFilter = filterState.isFavorite
                }

                /**
                 * Отображается если:
                 * 1. Развернутый режим
                 * 2. Фильтр помечен как favorite (через state или defaultFavorite)
                 * 3. alwaysShown - в момент сворачивания у фильтра было значение
                 * 4. у фильтра есть значение
                 * 5. В режиме без экспандера видны все фильтры
                 */
                if (expanded || favoriteFilter || filterState.alwaysShown || filterState.value || topBarMode !== 'full') {
                    filters.push(
                        <Grid
                            key={type} className={styles.filterModuleContainer}
                            item xs={12} sm={6} lg={4}
                        >
                            {
                                React.cloneElement(element, {
                                    ...filterState,
                                    id: type,
                                    // Подставляем базовое значение т.к. некоторые фильтры не работают с null
                                    value: filterState.value || '',
                                    onChange: this.filterChangeHandler,
                                })
                            }
                            {
                                this.renderFavoriteButton(type, favoriteFilter)
                            }
                        </Grid>
                    )
                }
            }
        })

        return filters
    }

    render() {
        const {
            title, searchResultText, actionElement, topBarMode, id, children
        } = this.props
        const { expanded, key } = this.state

        return (
            <div id={id} key={key}>
                { topBarMode !== 'hidden' && (
                    <div className={styles.titleContainer}>
                        <span className={styles.title}>{title}</span>
                        {topBarMode === 'full' && (
                            <ExpandButton
                                id={`${id}ExpandButton`}
                                expanded={expanded}
                                onExpand={this.expandHandler}
                                onCollapse={this.collapseHandler}
                            />
                        )}
                    </div>
                )}

                <Grid
                    container
                    spacing={2}
                    className={styles.modulesContainer}
                >
                    { this.renderFilters(children) }
                </Grid>

                <Divider/>

                <div className={styles.actionButtonsPanel}>
                    <div>
                        {actionElement}
                    </div>
                    <div
                        id={`${id}ResultText`}
                        className={classNames(styles.resultMsg, {
                            [styles.firstItem]: !Boolean(actionElement)
                        })}
                    >
                        {searchResultText}
                    </div>
                    <Button
                        id={`${id}ClearButton`}
                        className={styles.actionButton}
                        color="primary"
                        variant="text"
                        onClick={this.clearHandler}
                    >
                        {t('common.clear')}
                    </Button>
                    <Button
                        color="primary"
                        id={`${id}ApplyButton`}
                        className={styles.actionButton}
                        variant="contained"
                        onClick={this.applyHandler}
                    >
                        {t('common.apply')}
                    </Button>
                </div>
            </div>
        )
    }
}
