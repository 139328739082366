import React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import Paper from '@material-ui/core/Paper'
import { RadioGroupInput } from '@crystalservice/crystals-ui/lib/components/inputs/radio-input/radio-group-input'

import { LOYALTY_SETTINGS_STORE } from '../../../store/stores'
import {
    WORKS_ANY,
    MAXIMUM_DISCOUNT,
    FIRST_ORDER,
    MAXIMUM_SETS_INDEPENDENT_DISCOUNT,
    GeneralInteractionMethod
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/general-interaction-method'

import { RadioOptions, LoyaltySettingsProps } from './loyalty-settings'

const styles = require('./loyalty-settings.scss')

const actionsPrinciplesOptions = (): Array<RadioOptions<GeneralInteractionMethod>> => [
    {
        label: t('loyaltySettings.firstOrderPrinciple'),
        value: FIRST_ORDER,
    },
    {
        label: t('loyaltySettings.worksAnyPrinciple'),
        value: WORKS_ANY,
    },
    {
        label: t('loyaltySettings.maximumDiscountPrinciple'),
        value: MAXIMUM_DISCOUNT,
    },
    {
        label: t('loyaltySettings.maximumSetsIndependentDiscountPrinciple'),
        value: MAXIMUM_SETS_INDEPENDENT_DISCOUNT,
    }
]

const Component: React.FunctionComponent<LoyaltySettingsProps> = ({ loyaltySettingsStore }) => {
    const { editSettings, settings } = loyaltySettingsStore
    return (
        <Paper className={styles.actionTriggers}>

            <div className={styles.header}>{t('loyaltySettings.auctionPrinciples')}</div>

            <RadioGroupInput
                id="actionsPrinciplesOptionsRadio"
                options={actionsPrinciplesOptions()}
                value={settings?.generalInteractionMethod}
                onValueChange={value => editSettings({ generalInteractionMethod: value as GeneralInteractionMethod })}
            />
        </Paper>
    )
}

export const LoyaltySettingsActionTriggers = inject(LOYALTY_SETTINGS_STORE)(observer(Component))
