import { createTopologyNodeVO } from './topology-node-vo'
import { TopologyNodeVO } from './topology-node-vo'

export interface RegionVO extends TopologyNodeVO {
    '@class': 'ru.crystals.setretailx.topology.RegionVO'
    name?: string
    shopsCount?: number
}

export const createRegionVO = (initialization: Partial<RegionVO>): RegionVO => {
    if (!initialization) return null
    
    let result: RegionVO = {
        ...createTopologyNodeVO(initialization),
        name: initialization.name,
        shopsCount: initialization.shopsCount,
        '@class': 'ru.crystals.setretailx.topology.RegionVO',
    }
    
    return result
}
