import { t } from 'i18next'
import { observable, action, runInAction, toJS, computed } from 'mobx'
import { cloneDeep } from 'lodash'
import { TopologyMap } from '../../components/topology-filter/topology-map'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import {
    TOPOLOGY,
    NOT_FOUND, REGION, SHOPS, REGIONS
} from '../../core/app-routes'
import { NEW } from '../../core/values'
import { createEmptyRegion } from '../../core/topology/topology-util'
import { RegionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import { iTopologyManagerLocal } from '../../../protocol/set10/i-topology-manager-local'
import { CityVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import { AppStore } from '../app-store'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { SnackbarStore } from '../snackbar-store'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import {
    APP_BAR_STORE,
    APP_STORE,
    DIALOG_STORE,
    REGION_STORE,
    SNACKBAR_STORE,
    USER_STORE
} from '../stores'
import { DialogStore } from '../dialog-store'
import { withSpinner } from '../with-spinner'

export class RegionStore {
    @observable
    topologyMap: TopologyMap = null

    @observable
    region: RegionVO = null

    @observable
    cities: CityVO[] = []

    @observable
    validation: FormValidation<RegionVO>

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    @computed
    get isNewRegion(): boolean {
        return this.region && !this.region.id
    }

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<RegionVO>(
            this.region,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        fieldLengthValidator({max: 255}),
                        uniqueField(this.topologyMap.regions
                            .filter(r => r.id !== this.region.id)
                            .map(r => r.name))
                    ]
                }
            ],
            creation
        )
    }

    getRegion = (regionId: number): RegionVO => {
        return toJS(this.topologyMap.regions.find(region => region.id === regionId))
    }

    getCities = (regionId: number): CityVO[] => {
        return toJS(this.topologyMap.cities.filter(city => city.region.id === regionId))
    }

    openRegion = (ref: string | number): Promise<void> => {
        if (!ref) {
            this.snackbarStore.show({message: t('topologyActions.regionFetchingFailure', {id: ref})})
            goTo(NOT_FOUND)
            return Promise.resolve()
        }
        this.topologyMap = cloneDeep(toJS(this.appStore.topologyMap))
        if (ref === NEW) {
            this.addRegion()
        } else {
            this.cities = this.getCities(Number(ref))
            this.editRegion(this.getRegion(Number(ref)))
        }
    }

    @action
    addRegion = (): void => {
        this.region = createEmptyRegion()
        this.cities = []
        this.createValidation(true)
        goTo(`${SHOPS}${TOPOLOGY}${REGION}/${NEW}`)
    }

    @action
    editRegion = (region: RegionVO): void => {
        if (!region) {
            goTo(NOT_FOUND)
            return
        }

        this.region = region
        this.createValidation()
        goTo(`${SHOPS}${TOPOLOGY}${REGION}/${region.id}`)
    }

    deleteCity = (cityId: number): Promise<void> => {
        return iTopologyManagerLocal.deleteCity(this.userStore.session, cityId)
            .then(response => {
                if (response) {
                    return this.appStore.fetchTopologyMap()
                        .then(() => {
                            runInAction(() => {
                                let cityPosition = this.cities.findIndex(city => city.id === cityId)
                                let cityName = this.cities.splice(cityPosition, 1)[0].name
                                this.snackbarStore.show({message: t('topologyActions.cityDeletionSuccess', {cityName})})
                            })
                        })
                } else {
                    this.snackbarStore.show({message: t('topologyActions.cityDeletionFailure', {message: null})})
                }
            })
            .catch(error => {
                this.snackbarStore.show({message: t('topologyActions.cityDeletionFailure', {message: error.message})})
            })
    }

    @action
    updateCurrentRegion = (changes: Partial<RegionVO>): void => {
        Object.keys(changes).forEach(key => {
            this.region[key] = changes[key]
        })
    }

    saveRegion = (creation: boolean = false): Promise<RegionVO> => {
        return withSpinner(
            iTopologyManagerLocal.addRegion(this.userStore.session, toJS(this.region))
                .then(region => {
                    runInAction(() => {
                        this.editRegion(region)
                        creation ?
                            this.snackbarStore.show({message: t('topologyActions.regionCreationSuccess', {regionName: this.region.name})}) :
                            this.snackbarStore.show({message: t('topologyActions.regionModificationSuccess', {regionName: this.region.name})})
                    })
                    return this.appStore.fetchTopologyMap()
                        .then(() => region)
                })
                .catch(error => {
                    creation ?
                        this.snackbarStore.show({message: t('topologyActions.regionCreationFailure')}) :
                        this.snackbarStore.show({message: t('topologyActions.regionModificationFailure')})
                    return null
                })
        )
    }

    goToPreviousPage = (): void => {
        goTo(`${SHOPS}${TOPOLOGY}${REGIONS}`)
    }

    @action
    reset = (): void => {
        this.topologyMap = null
        this.region = null
        this.cities = []
        this.validation = undefined
    }
}

/**
 * Обработчик для страниц редактирования и добавления региона
 */
export const TOPOLOGY_REGION_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${SHOPS}${TOPOLOGY}${REGION}/([\\w-]+)/?$`),
    onEnter: (newRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const dialogStore: DialogStore = getStore(DIALOG_STORE)
        const regionStore: RegionStore = getStore(REGION_STORE)

        let matchNewRoute = newRoute.match(TOPOLOGY_REGION_ROUTING_HANDLER.routeMatcher)
        let nodeId = matchNewRoute[1]

        appBarStore.updateState({
            title: nodeId === NEW ? `${t(`topologyPages.regionCreate`)}` : `${t(`topologyPages.regionEdit`)}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                if (regionStore.validation.modified) {
                    dialogStore.showDialog({
                        title: t('topologyPages.regionSaveChangesTitle'),
                        message: t('topologyPages.regionSaveChangesMessage'),
                        mode: DIALOG,
                        onYes: regionStore.goToPreviousPage,
                        yesLabel: t('common.exit'),
                        noLabel: t('common.cancel')
                    })
                } else {
                    regionStore.goToPreviousPage()
                }
            }
        })
    }
}
