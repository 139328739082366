import React from 'react'
import { t } from 'i18next'
import { get, isEmpty, isNil } from 'lodash'
import {
    ResultType,
    Result,
    CounterResultData,
} from '../../../../../../../core/advertising-actions/advertising-actions'
import {
    CounterMetricsType,
    CounterIndicatorType,
    CounterPeriodUnitType,
    getCounterMetricsOpts,
    getCounterIndicatorOpts,
    getCounterPeriodUnitsOpts,
} from '../../../../../../../core/advertising-actions/action-conditions'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { Grid } from '@material-ui/core'
import { CounterProducts } from './counter-products'
import { ResultEditorProps } from '../../results'

const styles = require('./counter.scss')

export const getCounterResultChipLabel = (data: CounterResultData): string => {
    const { metric, indicator, periods, periodType } = data
    const metricsLabel = getCounterMetricsLabel(metric[0])
    const indicatorLabel = getCounterIndicatorLabel(indicator[0])
    const unitsLabel = getCounterUnitsLabel(periodType[0]).toLowerCase()
    const forEveryLabel = t('advertisingActions.countersResults.forEvery').toLowerCase()

    return `${metricsLabel} ${indicatorLabel} ${forEveryLabel} ${periods ? periods[0] : '0'} ${unitsLabel}`
}

const getCounterMetricsLabel = (metric: CounterMetricsType): string => {
    switch (metric) {
        case CounterMetricsType.Checks:
            return t('advertisingActions.counterMetrics.checks')
        case CounterMetricsType.ActiveDays:
            return t('advertisingActions.counterMetrics.activeDays')
        case CounterMetricsType.Products:
            return t('advertisingActions.counterMetrics.products')
        default:
            return ''
    }
}

const getCounterIndicatorLabel = (indicator: CounterIndicatorType): string => {
    switch (indicator) {
        case CounterIndicatorType.Count:
            return t('advertisingActions.counterMetrics.count')
        case CounterIndicatorType.Sum:
            return t('advertisingActions.counterMetrics.sum')
        default:
            return ''
    }
}

const getCounterUnitsLabel = (periodType: CounterPeriodUnitType): string => {
    switch (periodType) {
        case CounterPeriodUnitType.Hours:
            return t('advertisingActions.counterMetricsUnits.hours')
        case CounterPeriodUnitType.Days:
            return t('advertisingActions.counterMetricsUnits.days')
        case CounterPeriodUnitType.Weeks:
            return t('advertisingActions.counterMetricsUnits.weeks')
        case CounterPeriodUnitType.Months:
            return t('advertisingActions.counterMetricsUnits.months')
        default:
            return ''
    }
}

export const Counter: React.FC<ResultEditorProps> = ({ value, onChange, readOnly }) => {
    const getCounterResultData = (): CounterResultData => {
        const { resultsParsed } = value
        const existingAction = resultsParsed.find(action => action.type === ResultType.Counter)

        const metric = get(existingAction, 'data.metric', [CounterMetricsType.Checks])
        const indicator = get(existingAction, 'data.indicator', [CounterIndicatorType.Count])
        const periods = get(existingAction, 'data.periods', null)
        const periodType = get(existingAction, 'data.periodType', [CounterPeriodUnitType.Hours])
        const markings = get(existingAction, 'data.markings', [])

        return { metric, indicator, periods, periodType, markings }
    }

    const handleChangeCondition = (opts: CounterResultData): void => {
        const { resultsParsed } = value
        const existingAction = resultsParsed.find(action => action.type === ResultType.Counter)

        if (!isEmpty(existingAction)) {
            handleUpdateCounterResult(opts)
        } else {
            handleAddCounterResult(opts)
        }
    }

    const handleUpdateCounterResult = (opts: CounterResultData): void => {
        const { resultsParsed } = value
        const existingActionIndex = resultsParsed.findIndex(action => action.type === ResultType.Counter)
        const existingAction = resultsParsed[existingActionIndex]
        const { data } = existingAction
        const { metric, indicator, periods, periodType, markings } = opts

        const counterResult: Result = {
            type: ResultType.Counter,
            data: {
                metric: isEmpty(metric) ? data.metric : metric,
                indicator: isEmpty(indicator) ? data.indicator : indicator,
                periods: isEmpty(periods) ? data.periods : periods,
                periodType: isEmpty(periodType) ? data.periodType : periodType,
                markings: isNil(markings) ? data.markings : markings,
            },
        }

        onChange({
            ...value,
            resultsParsed: [
                ...value.resultsParsed.slice(0, existingActionIndex),
                counterResult,
                ...value.resultsParsed.slice(existingActionIndex + 1),
            ],
        })
    }

    const handleAddCounterResult = (opts: CounterResultData): void => {
        const { metric, indicator, periods, periodType, markings } = opts
        const counterResult: Result = {
            type: ResultType.Counter,
            data: {
                metric: metric || [CounterMetricsType.Checks],
                indicator: indicator || [CounterIndicatorType.Count],
                periods,
                periodType: periodType || [CounterPeriodUnitType.Hours],
                markings,
            },
        }

        onChange({
            ...value,
            resultsParsed: [
                ...value.resultsParsed,
                counterResult,
            ],
        })
    }

    const handleRemoveMarking = (identifier: string): void => {
        const { markings } = getCounterResultData()
        const updatedMarkings = markings[0].String.filter(marking => marking !== identifier)
        handleChangeCondition({ markings: [{ String: updatedMarkings }] })
    }

    const counterResult = getCounterResultData()
    const isProductsMetrics = counterResult.metric[0] === CounterMetricsType.Products

    return (
        <div className={styles.counterResult}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={3}>
                    <SelectInput
                        id="counterMetric"
                        options={getCounterMetricsOpts()}
                        value={counterResult.metric[0]}
                        onSelect={opt => {
                            handleChangeCondition({ metric: [opt.value] })
                        }}
                        disabled={readOnly}
                    />
                </Grid>

                {counterResult.metric?.[0] !== CounterMetricsType.ActiveDays && (
                    <Grid item xs={12} md={3}>
                        <SelectInput
                            id="counterIndicator"
                            label={t('advertisingActions.countersResults.metrics')}
                            options={getCounterIndicatorOpts()}
                            value={counterResult.indicator[0]}
                            onSelect={opt => {
                                handleChangeCondition({ indicator: [opt.value] })
                            }}
                            disabled={readOnly}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={2}>
                    <NumberInput
                        id="counterPeriods"
                        canBeNull
                        label={t('advertisingActions.countersResults.forEvery')}
                        value={counterResult.periods ? Number(counterResult.periods[0]) : null}
                        onValueChange={value => {
                            handleChangeCondition({ periods: [String(value)] })
                        }}
                        disabled={readOnly}
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <SelectInput
                        id="counterPeriodType"
                        label={t('advertisingActions.countersResults.units')}
                        options={getCounterPeriodUnitsOpts()}
                        value={counterResult.periodType[0]}
                        onSelect={opt => {
                            handleChangeCondition({ periodType: [opt.value] })
                        }}
                        disabled={readOnly}
                    />
                </Grid>

                <Grid item xs={12} md={2}>
                    <p className={styles.hint}>{t('advertisingActions.countersResults.fromActionStart')}</p>
                </Grid>
            </Grid>

            {isProductsMetrics && (
                <CounterProducts
                    markings={get(counterResult, 'markings[0].String', [])}
                    onAddMarking={handleChangeCondition}
                    onRemoveMarking={handleRemoveMarking}
                    readOnly={readOnly}
                />
            )}

            {!isProductsMetrics && (
                <p className={styles.hint}>{t('advertisingActions.countersResults.everyCustomerHint')}</p>
            )}
        </div>
    )
}
