import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { ExpandButton } from '../../../../components/buttons/expand-button/expand-button'
import classNames from 'classnames'

const styles = require('./filter-group.scss')

export interface FilterGroupProps {
    id?: string
    name: string
    /**
     * Возможность сворачивать группу
     */
    expandable?: boolean
    /**
     * Признак свернутости группы
     */
    expanded?: boolean
    /**
     * Коллбэк при клике по кнопке сворачивания/разворачивания группы
     */
    onExpandChange?: (id: string, expanded: boolean) => void
}

export interface FilterGroupState {
    expanded: boolean
}

export const FILTER_GROUP = 'FiltersGroup'

// Группа для использования в новом фильтре
export class FilterGroup extends React.PureComponent<FilterGroupProps, FilterGroupState> {
    static filterType: string = FILTER_GROUP

    static defaultProps: Partial<FilterGroupProps> = {
        onExpandChange: () => null,
    }

    handleToggleExpand = (): void => {
        const { id, expanded, onExpandChange } = this.props
        onExpandChange(id, !expanded)
    }

    render() {
        const { id, name, children, expandable, expanded, onExpandChange } = this.props

        let showItems = !expandable || expanded
        return (
            <div className={expandable ? styles.expandableFiltersGroup : styles.filtersGroup} >
                <div className={styles.titleContainer} onClick={this.handleToggleExpand}>
                    <div className={styles.groupName}>
                        {name}{expandable ? ':' : ''}
                    </div>
                    {expandable && (
                        <ExpandButton
                            id={`ExpandButton_${id}`}
                            expanded={expanded}
                        />
                    )}
                </div>
                {showItems && (
                    <Grid
                        className={styles.groupGridContainer}
                        container
                        spacing={2}
                        alignItems="center"
                    >
                        {children}
                    </Grid>
                )}

                <Divider
                    className={classNames(styles.divider, {
                        [styles.expanded]: expanded
                    })}
                />
            </div>
        )
    }
}
