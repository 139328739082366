import { observable, action, runInAction, toJS, computed } from 'mobx'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, APP_STORE, USER_STORE } from '../stores'
import { cashManagerLocal } from '../../../protocol/set10/cash-manager-local'
import { CashPrintFormVO, createCashPrintFormVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-print-form-vo'
import { withSpinner } from '../with-spinner'
import { cloneDeep, isEqual } from 'lodash'
import { goTo } from '../../utils/router-util'
import { CASH_MODULE, CHECK_FORMS, EDITOR } from '../../core/app-routes'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { t } from 'i18next'
import { AppStore } from '../app-store'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { CashDocumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-document-vo'
import { createDefaultCheckForm, parseXMLFromString, serializeXMLToString } from '../../core/check-form-editor/check-form-editor-util'

export class CheckFormsStore {
    @observable
    checkForms: CashPrintFormVO[] = null

    @observable
    editedCheckForm: CashPrintFormVO = null

    @observable
    xmlDocument: Document = null

    @observable
    xmlModified: boolean = false

    @observable
    originalEditedCheckForm: CashPrintFormVO = null

    @observable
    documentStructure: CashDocumentVO = null

    @observable
    cashDocuments: CashDocumentVO[] = null

    @observable
    cashDocumentsNames: Map<string, string> = null

    @observable
    tabIndex: number = 0

    @observable
    xmlEditorMounted: boolean = false

    private userStore: UserStore = getStore(USER_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    @computed
    get checkFormModified(): boolean {
        if (!this.editedCheckForm || !this.originalEditedCheckForm) return false

        if (this.xmlModified) return true

        return !isEqual(toJS(this.editedCheckForm), toJS(this.originalEditedCheckForm))
    }

    @computed
    get availableSections(): Array<{ label: string, value: string }> {
        if (!this.documentStructure) return []

        const notAvailableSections = ['logo', 'function', 'condition']

        return this.documentStructure.sections.map(item => {
            if (notAvailableSections.includes(item.name)) return null
            return {
                label: item.localName,
                value: item.name
            }
        }).filter(Boolean)
    }

    fetchAll = async (): Promise<void> => {
        if (!this.cashDocuments) {
            await Promise.all([
                this.fetchCashDocuments(),
                this.fetchCheckForms()
            ])
        } else {
            await this.fetchCheckForms()
        }
    }

    fetchCheckForms = async (): Promise<void> => {
        const checkForms = await withSpinner(cashManagerLocal.getPrintForms(this.userStore.session)) || []
        runInAction(() => {
            this.checkForms = checkForms
        })
    }

    fetchCheckForm = async (id: string): Promise<void> => {
        if (isNaN(Number(id))) {
            this.goBack()
        }

        if (!this.cashDocumentsNames) {
            await withSpinner(this.fetchCashDocuments())
        }

        const checkForm = await withSpinner(cashManagerLocal.getPrintForm(this.userStore.session, Number(id)))
        if (!checkForm) {
            this.goBack()
        }

        const documentStructure = await withSpinner(cashManagerLocal.getCashDocumentStructure(
            this.userStore.session, this.appStore.locale, checkForm.documentName
        ))

        runInAction(() => {
            this.editedCheckForm = checkForm
            this.originalEditedCheckForm = cloneDeep(checkForm)
            this.documentStructure = documentStructure
            this.xmlModified = false
        })
        this.refreshEditedXML()

        if (!this.xmlDocument) {
            this.xmlDocument = createDefaultCheckForm(documentStructure)
            runInAction(() => {
                this.xmlModified = true
            })
        }
    }

    fetchCashDocuments = async (): Promise<void> => {
        const cashDocuments = await withSpinner(cashManagerLocal.getCashDocuments(this.userStore.session, this.appStore.locale))

        runInAction(() => {
            this.cashDocuments = cashDocuments

            const namesMap = new Map<string, string>()
            cashDocuments.forEach(item => {
                namesMap.set(item.name, item.localName)
            })

            this.cashDocumentsNames = namesMap
        })
    }

    // Конвертируем строку в xmlDocument, с которым потом работаем
    @action
    refreshEditedXML = (): void => {
        if (!this.editedCheckForm) return

        this.xmlDocument = parseXMLFromString(this.editedCheckForm.xmlRepresentation)
    }

    // Конвертируем xmlDocument в строку, в которой надо сохранять чековую форму
    @action
    convertXMLToString = (): void => {
        if (!this.xmlDocument) return

        this.editedCheckForm.xmlRepresentation = serializeXMLToString(this.xmlDocument)
    }

    sendToCashes = async (cashForm: CashPrintFormVO): Promise<void> => {
        if (this.checkFormModified) {
            await this.saveCheckForm()
        }

        const returnedCashForm = await withSpinner(cashManagerLocal.sendPrintFormToCash(this.userStore.session, cashForm.id))

        const index = this.checkForms?.findIndex(item => item.id === cashForm.id)
        if (index >= 0) {
            runInAction(() => {
                let newCheckForms = [...this.checkForms]
                newCheckForms[index] = returnedCashForm

                this.checkForms = newCheckForms
            })
        }

        runInAction(() => {
            if (this.checkFormModified) {
                this.xmlModified = false
            }
            this.editedCheckForm.sentToCash = returnedCashForm.sentToCash
            this.editedCheckForm.valid = returnedCashForm.valid
            this.originalEditedCheckForm = cloneDeep(this.editedCheckForm)
        })
        this.refreshEditedXML()

        this.appStore.showSnackbar({
            message: t('checkForms.sentToCash')
        })
    }

    showRemoveDialog = (cashForm: CashPrintFormVO): void => {
        const { showDialog } = this.appStore

        const name = this.cashDocumentsNames?.get(cashForm.documentName)
        showDialog({
            title: t('checkForms.removeDialogTitle', { name }),
            message: t('checkForms.removeDialogMessage', { name }),
            onYes: () => this.removeCheckForm(cashForm),
            mode: DIALOG,
        })
    }

    removeCheckForm = async (cashForm: CashPrintFormVO): Promise<void> => {
        await cashManagerLocal.deletePrintForm(this.userStore.session, cashForm.id)

        const index = this.checkForms?.findIndex(item => item.id === cashForm.id)
        if (index !== -1) {
            runInAction(() => {
                let newCheckForms = [...this.checkForms]
                newCheckForms.splice(index, 1)

                this.checkForms = newCheckForms
            })
        }
    }

    showSendToCashesDialog = (callback: () => void): void => {
        const { showDialog } = this.appStore

        showDialog({
            title: t('checkForms.sendToCasheDialogTitle'),
            message: t('checkForms.sendToCashDialogMessage', { name: this.documentStructure.localName }),
            onYes: callback,
            mode: DIALOG,
        })
    }

    showLoadDefaultDialog = (cashForm: CashPrintFormVO, onYesCallback: () => void): void => {
        this.appStore.showDialog({
            title: t('checkForms.showLoadDefaultTitle'),
            message: t('checkForms.showLoadDefaultMessage'),
            mode: DIALOG,
            onYes: async () => {
                await this.loadDefaultPrintFormXML(cashForm)
                if (onYesCallback) {
                    onYesCallback()
                }
            }
        })
    }

    loadDefaultPrintFormXML = async (cashForm: CashPrintFormVO): Promise<void> => {
        let result = await withSpinner(cashManagerLocal.loadDefaultPrintFormXML(this.userStore.session, cashForm.id))

        const index = this.checkForms?.findIndex(item => item.id === result.id)
        if (index >= 0) {
            runInAction(() => {
                this.checkForms[index] = result
            })
        }

        // Если была отправлена на кассы, надо снять статус
        if (result.sentToCash) {
            result = await withSpinner(cashManagerLocal.addPrintForm(
                this.userStore.session, toJS({
                    ...result,
                    sentToCash: false
                })
            ))
        }

        runInAction(() => {
            this.editedCheckForm = {
                ...result,
                sentToCash: false,
                valid: true
            }
            this.originalEditedCheckForm = cloneDeep(this.editedCheckForm)
            this.xmlModified = false
        })

        this.refreshEditedXML()
        if (!this.xmlDocument) {
            this.xmlDocument = createDefaultCheckForm(this.documentStructure)
            runInAction(() => {
                this.xmlModified = true
            })
        }

        this.appStore.showSnackbar({
            message: t('checkForms.checkFormSaved')
        })

        return Promise.resolve()
    }

    saveCheckForm = async (): Promise<void> => {
        // Сохраняем секции в xml
        this.convertXMLToString()

        const savedCashForm = await withSpinner(cashManagerLocal.addPrintForm(
            this.userStore.session, toJS(this.editedCheckForm)
        ))

        const index = this.checkForms?.findIndex(item => item.id === savedCashForm.id)
        if (index >= 0) {
            runInAction(() => {
                let newCheckForms = [...this.checkForms]
                newCheckForms[index] = savedCashForm

                this.checkForms = newCheckForms
            })
        }
        runInAction(() => {
            this.editedCheckForm.sentToCash = savedCashForm.sentToCash
            this.editedCheckForm.valid = savedCashForm.valid
            this.originalEditedCheckForm = cloneDeep(this.editedCheckForm)
            this.xmlModified = false
        })
        this.refreshEditedXML()

        this.appStore.showSnackbar({
            message: t('checkForms.checkFormSaved')
        })
    }

    addNewCashDocument = async (cashDocument: CashDocumentVO): Promise<void> => {
        const newCheckForm = createCashPrintFormVO({
            documentName: cashDocument.name
        })
        const result = await withSpinner(cashManagerLocal.addPrintForm(this.userStore.session, newCheckForm))

        runInAction(() => {
            this.checkForms.push(result)
        })

        this.appStore.showSnackbar({
            message: t('checkForms.checkFormAdded')
        })
    }

    @action
    editCheckForm = (changes: Partial<CashPrintFormVO>): void => {
        Object.keys(changes).forEach(key => {
            this.editedCheckForm[key] = changes[key]
        })

        // При изменении формы разрешаем повторную отправку
        this.editedCheckForm.sentToCash = false
        this.editedCheckForm.valid = true
    }

    @action
    editXMLDocument = (xmlDocument: Document): void => {
        this.xmlDocument = xmlDocument
        this.xmlModified = true
    }

    setTabIndex = (index: number): void => {
        this.tabIndex = index
    }

    goBack = (): void => {
        goTo(`${CASH_MODULE}${CHECK_FORMS}`)
        this.resetEditedCheckForm()
    }

    goToCheckForm = (): void => {
        goTo(`${CASH_MODULE}${CHECK_FORMS}${EDITOR}/${this.editedCheckForm?.id}`)
    }

    @action
    setXmlModified = (value: boolean): void => {
        this.xmlModified = value
    }

    @action
    setXmlEditorMounted = (value: boolean): void => {
        this.xmlEditorMounted = value
    }

    @action
    resetEditedCheckForm = (): void => {
        this.editedCheckForm = null
        this.originalEditedCheckForm = null
        this.documentStructure = null
    }

    @action
    reset = (): void => {
        this.checkForms = null
        this.editedCheckForm = null
        this.xmlEditorMounted = null
        this.xmlDocument = null
        this.xmlModified = false
        this.originalEditedCheckForm = null
        this.documentStructure = null
        this.cashDocuments = null
        this.cashDocumentsNames = null
    }
}
