import { action, observable, runInAction, toJS } from 'mobx'
import { Cancelable, debounce } from 'lodash'
import { t } from 'i18next'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE } from '../stores'
import { printingModuleConfigurator } from '../../../protocol/set10/printing-module-configurator'
import { PrintingModulePropertiesVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/printing-module-properties-vo'
import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { priceTagFormatSender } from '../../../protocol/set10/price-tag-format-sender'

export class PriceTagsModuleSettingsStore {

    @observable
    settings: PrintingModulePropertiesVO

    debouncedSaveSettings: (() => void) & Cancelable = debounce(() => {
        this.saveSettings()
    }, AUTOSAVE_DELAY)

    private appStore: AppStore = getStore(APP_STORE)

    fetchSettings = async (): Promise<any> => {
        const settings = await printingModuleConfigurator.getProperties1()
        runInAction(() => {
            this.settings = settings
        })
    }

    requestFormatsFromCentrum = (): Promise<any> => {
        return priceTagFormatSender.requestAllFromCentrum()
    }

    @action
    editSettings = (changes: Partial<PrintingModulePropertiesVO>): void => {
        Object.keys(changes).forEach((k: keyof PrintingModulePropertiesVO) => {
            // workflow for https://github.com/microsoft/TypeScript/issues/31663
            (this.settings[k] as any) = changes[k]
        })
        this.debouncedSaveSettings()
    }

    saveSettings = async (): Promise<void> => {
        const saveResult: boolean = await printingModuleConfigurator.setProperties(toJS(this.settings))
        if (saveResult === true){
            this.appStore.showSnackbar({ message: t('priceTagsModuleSettings.settingsSaved') })
        }
    }

    @action
    reset = (): void => {
        this.settings = undefined
    }
}
