import React, { ReactElement } from 'react'
import {
    affineTransformToMatrix, getImageForCustomElement, imageToCSS, matrixToAffineTransform, parseMnemonics
} from '../../../core/price-tags/price-tags-util'
import uuid from 'uuid'
import { isCustomElement, XMLTagCustomElement } from '../../../core/price-tags/xml/xml-tag-custom-element'
import { isTextBlock, XMLTagTextBlock } from '../../../core/price-tags/xml/xml-tag-text-block'
import { isImage, XMLTagImage } from '../../../core/price-tags/xml/xml-tag-image'
import { isSubTemplate, XMLTagSubTemplate } from '../../../core/price-tags/xml/xml-tag-sub-template'
import { PriceTagTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { isFormula, XMLTagFormula } from '../../../core/price-tags/xml/xml-tag-formula'
import { getFormulaDescription } from '../../../core/price-tags/price-tag-formulas-util'
import { XMLTagBaseElement, XMLTagElement } from '../../../core/price-tags/xml/xml-tag-base-element'
import { EditorItem } from '../../../../components/wysiwyg/wysiwyg-editor'
import { CERTIFICATION_TYPE } from '../../../core/price-tags/xml/xml-custom-element-type'
import { FixedProportionsConstraint } from '../../../../components/wysiwyg/constraints/fixed-proportions-constraint'
import { FixedSizeConstraint } from '../../../../components/wysiwyg/constraints/fixed-size-constraint'
import { FixedRotationConstraint } from '../../../../components/wysiwyg/constraints/fixed-rotation-constraint'
import { isBarcodeElement, XMLTagBarcodeElement } from '../../../core/price-tags/xml/xml-tag-barcode-element'
import { isQrcodeElement, XMLTagQrcodeElement } from '../../../core/price-tags/xml/xml-tag-qrcode-element'
import { PriceTagTextPreview } from '../../../components/price-tag-template-preview/price-tag-text-preview/price-tag-text-preview'
// tslint:disable-next-line:max-line-length
import { PriceTagSubtemplatePreview } from '../../../components/price-tag-template-preview/price-tag-subtemplate-preview/price-tag-subtemplate-preview'
import { PriceTagImagePreview } from '../../../components/price-tag-template-preview/price-tag-image-preview/price-tag-image-preview'
import { t } from 'i18next'
import { MnemonicPropertiesVO } from '../../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'

const BARCODE_IMAGE: string = require('../../../../assets/images/icons/price-tags/barcode-full.png')
const QR_CODE_IMAGE: string = require('../../../../assets/images/icons/price-tags/qr_code.png')

export function getRendererForImage(element: XMLTagImage, key: string = null, scale: number = 1): ReactElement<{}> {
    if (!key) key = uuid()

    return (
        <div id={key}>
            <PriceTagImagePreview
                element={element}
                scale={scale}
                alt={element.fileName}
                src={imageToCSS(element)}
            />
        </div>
    )
}

export function getRendererForCustomElement(element: XMLTagCustomElement, key: string = null, scale: number = 1): ReactElement<{}> {
    if (!key) key = uuid()
    return (
        <div id={key}>
            <PriceTagImagePreview
                element={element}
                scale={scale}
                alt={element.type}
                src={getImageForCustomElement(element.type)}
            />
        </div>
    )
}

export function getRendererForQrcode(element: XMLTagQrcodeElement, key: string = null, scale: number = 1): ReactElement<{}> {
    if (!key) key = uuid()
    return (
        <div id={key}>
            <PriceTagImagePreview
                element={element}
                scale={scale}
                src={QR_CODE_IMAGE}
                alt={t('priceTags.editor.qrcode')}
            />
        </div>
    )
}

export function getRendererForBarcode(element: XMLTagBarcodeElement, key: string = null, scale: number = 1): ReactElement<{}> {
    if (!key) key = uuid()
    return (
        <div id={key}>
            <PriceTagImagePreview
                element={element}
                scale={scale}
                src={BARCODE_IMAGE}
                alt={t('priceTags.editor.barcode')}
            />
        </div>
    )
}

export function getRendererForTextBlock(
    element: XMLTagTextBlock,
    mnemonics: MnemonicPropertiesVO[],
    key: string = null,
    scale: number = 1
): ReactElement<{}> {
    if (!key) key = uuid()

    return (
        <div id={key}>
            <PriceTagTextPreview
                parts={parseMnemonics(element.text, mnemonics)}
                element={element}
                scale={scale}
            />
        </div>
    )
}

export function getRendererForFormula(
    element: XMLTagFormula,
    priceMnemonics: MnemonicPropertiesVO[],
    key: string = null,
    scale: number = 1
): ReactElement<{}> {
    if (!key) key = uuid()

    return (
        <div id={key}>
            <PriceTagTextPreview
                element={element}
                scale={scale}
                parts={getFormulaDescription(element, priceMnemonics)}
            />
        </div>
    )
}

export function getRendererForSubTemplate(
    element: XMLTagSubTemplate,
    key: string = null,
    scale: number = 1,
    priceTags: PriceTagTemplateVO[] = [],
): ReactElement<{}> {
    if (!key) key = uuid()

    const priceTag = priceTags.find(p => p.guid === element.guid)
    if (!priceTag) return null

    return (
        <div id={key}>
            <PriceTagSubtemplatePreview
                element={element}
                scale={scale}
                name={priceTag.name}
            />
        </div>
    )
}

export function getRendererForElement(element: XMLTagBaseElement,
                                      mnemonics: MnemonicPropertiesVO[],
                                      priceTags: PriceTagTemplateVO[] = [],
                                      key: string = null,
                                      scale: number = 1): ReactElement<{}> {
    if (isTextBlock(element)) {
        return getRendererForTextBlock(element, mnemonics, key, scale)
    } else if (isImage(element)) {
        return getRendererForImage(element, key, scale)
    } else if (isCustomElement(element)) {
        return getRendererForCustomElement(element, key, scale)
    }  else if (isQrcodeElement(element)) {
        return getRendererForQrcode(element, key, scale)
    }  else if (isBarcodeElement(element)) {
        return getRendererForBarcode(element, key, scale)
    } else if (isFormula(element)) {
        return getRendererForFormula(element, mnemonics, key, scale)
    } else if (isSubTemplate(element)) {
        return getRendererForSubTemplate(element, key, scale, priceTags)
    } else {
        throw new Error(`Can not recognize price tag xml element: ${element}`)
    }
}

export function createEditorItem(element: XMLTagBaseElement,
                                 mnemonics: MnemonicPropertiesVO[],
                                 priceTags: PriceTagTemplateVO[] = [],
                                 key: string = null,
                                 scale: number = 1): EditorItem {
    if (!isTextBlock(element)
        && !isImage(element)
        && !isCustomElement(element)
        && !isQrcodeElement(element)
        && !isBarcodeElement(element)
        && !isFormula(element)
        && !isSubTemplate(element)) {
        throw new Error(`Can not recognize price tag xml element: ${element}`)
    }

    if (!key) key = uuid()

    const result: EditorItem = {
        key,
        data: element,
        width: element.width,
        height: element.height,
        matrix: affineTransformToMatrix(element.transform),
        zIndex: element.zOrder,
        withBorder: false,
        element: getRendererForElement(element, mnemonics, priceTags, key, scale),
        constraints: [],
    }

    if (isTextBlock(element)) {
        result.withBorder = true
    } else if (isCustomElement(element)) {
        result.constraints = element.type === CERTIFICATION_TYPE ? [new FixedProportionsConstraint(true)] : []
    } else if (isQrcodeElement(element)) {
        result.constraints = [new FixedProportionsConstraint(true)]
    } else if (isFormula(element)) {
        result.withBorder = true
    } else if (isSubTemplate(element)) {
        result.withBorder = true
        result.constraints = [
            new FixedSizeConstraint(true),
            new FixedRotationConstraint(true),
        ]
    }

    return result
}

export function exportEditorItem(item: EditorItem): XMLTagElement {
    function copyBaseValues(element: XMLTagBaseElement, item: EditorItem) {
        element.width = item.width
        element.height = item.height
        element.transform = matrixToAffineTransform(item.matrix)
        element.zOrder = item.zIndex
    }

    const result: XMLTagElement = {...item.data}
    copyBaseValues(result, item)

    return result
}
