import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import Tooltip from '@material-ui/core/Tooltip'
import Delete from '@material-ui/icons/Delete'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { TreeItem, TreeView } from '../../../../components/tree-view/tree-view'
import { PrivilegeVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/privilege-vo'
import { StringPairVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/string-pair-vo'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { SettingsActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/settings-action-panel'
import { AppStore } from '../../../store/app-store'
import { RolesStore } from '../../../store/staff/roles-store'
import { APP_STORE, ROLES_STORE } from '../../../store/stores'
import { withSpinner } from '../../../store/with-spinner'
import { RadioGroupInput } from '@crystalservice/crystals-ui/lib/components/inputs/radio-input/radio-group-input'

const styles = require('./role-settings.scss')

@inject(ROLES_STORE)
@inject(APP_STORE)
@observer
export class RoleSettings extends React.Component<RoleSettingsProps> {

    componentDidMount(): void {
        const {
            privilegeHierarchy,
            getServerPrivileges,
            getEditingRoleData,
            role
        } = this.props.rolesStore

        const requests = []
        // При прямом переходе еще не будет данных о роли
        if (!role) requests.push(getEditingRoleData(this.props.match.params.id))
        // При первом входе на страницу не будет данных о привилегиях
        if (!privilegeHierarchy) requests.push(getServerPrivileges)

        if (requests.length) withSpinner(requests)
    }

    privilegeSelectHandler = (item: TreeItem, selected: boolean, depth: number): void => {
        const {selectPrivilege} = this.props.rolesStore
        const PRIVILEGE_DEPTH: number = 3
        if (depth === PRIVILEGE_DEPTH) {
            selectPrivilege(item.item, selected)
        }
    }

    addPrivilegeHandler = (): void => {
        const {privileges, role, editRole, selectedPrivileges} = this.props.rolesStore
        let addingPrivileges: PrivilegeVO[] = privileges.filter(existPrivilege => {
            let inSelection = selectedPrivileges.some(
                selectedPrivilege => selectedPrivilege.key === existPrivilege.name)

            if (!inSelection) return false

            let alreadyAdded = role.privilegeVOList.some(addedPrivilege => addedPrivilege.id === existPrivilege.id)

            return !alreadyAdded
        })

        if (addingPrivileges.length > 0) {
            editRole({
                privilegeVOList: toJS(role.privilegeVOList).concat(addingPrivileges)
            })
        }
    }

    nameChangeHandler = (value: string): void => {
        const {editRole} = this.props.rolesStore

        editRole({ roleName: value })
    }

    roleTypeChangeHandler = (value: string): void => {
        const {editRole} = this.props.rolesStore
        editRole({centrum: value === 'centrum'})
    }

    clearRoleHandler = (): void => {
        const {editRole} = this.props.rolesStore
        editRole({privilegeVOList: []})
    }

    deletePrivilegeHandler = (privilege: PrivilegeVO): void => {
        const {editRole, role} = this.props.rolesStore
        let newList: PrivilegeVO[] = role.privilegeVOList.concat()
        let deletingPrivilege: PrivilegeVO = newList.find(addedPrivilege => addedPrivilege.id === privilege.id)
        if (deletingPrivilege) {
            newList.splice(newList.indexOf(deletingPrivilege), 1)
            editRole({privilegeVOList: newList})
        }
    }

    renderRolePrivileges = (privileges: PrivilegeVO[], privilegeHierarchy: TreeItem[]) => {
        if (!privileges || privileges.length === 0) {
            return (
                <Paper>
                    <p className={styles.tableTitle}>{t('staff.roleContent')}</p>
                    <Divider/>
                    <Empty id="addedPrivilegesListEmpty"/>
                </Paper>
            )
        }

        return (
            <Table
                id="addedPrivilegesList"
                className={styles.privilegeTable}
                keyField="id"
                title={t('staff.roleContent')}
                items={toJS(privileges)}
                groupingField="moduleName"
                groupingLabelFunction={group => {
                    let moduleName: string = group.group
                    let localizedName: string = ''

                    privilegeHierarchy.forEach(item => {
                        let pair: StringPairVO = item.item
                        if (pair.key === moduleName) {
                            localizedName = pair.value
                        }
                    })

                    return localizedName
                }}
                sortingFields={['description']}
                collapsible
                collapseAllEnabled
                actionComponents={
                    <Tooltip
                        title={t('staff.clearRoleContent')}
                        enterDelay={TOOLTIP_DELAY}
                    >
                        <IconButton
                            id="clearRoleContentButton"
                            onClick={this.clearRoleHandler}
                        >
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                }
                columns={[
                    {
                        labelField: 'description'
                    },
                    {
                        showOnRowHover: true,
                        withControls: true,
                        hAlign: RIGHT,
                        padding: '0 10px 0 0',
                        renderer: props => {
                            let privilege: PrivilegeVO = props.item
                            return (
                                <AdaptiveIconButton
                                    id={`privilegeRemoveButton${privilege.id}`}
                                    label={t('common.remove')}
                                    showTooltip
                                    onClick={() => this.deletePrivilegeHandler(privilege)}
                                >
                                    <Delete />
                                </AdaptiveIconButton>
                            )
                        }
                    }
                ]}
            />
        )
    }

    render() {
        const {
            role, privilegeHierarchy, selectedPrivileges, newRole, saveRoleAndExit,
            cancelChanges, validation
        } = this.props.rolesStore
        const {isCentrum} = this.props.app

        if (!role || !privilegeHierarchy) return null

        let canAddPrivilege: boolean = false
        if (selectedPrivileges) {
            canAddPrivilege = selectedPrivileges.some(selectedPrivilege =>
                !role.privilegeVOList.some(addedPrivilege => selectedPrivilege.key === addedPrivilege.name))
        }

        const nameValidator = validation.getValidationFor('roleName')

        return (
            <div
                id="roleSettingsPage"
                className={styles.roleSettings}
            >
                <Paper className={styles.topPanel}>
                    <Grid spacing={2} container>
                        <Grid item xs={12} md={4}>
                            <TextField
                                id="roleNameInput"
                                className={styles.nameInput}
                                label={t('staff.roleName')}
                                errorText={nameValidator.firstError}
                                value={role.roleName}
                                fullWidth
                                onValueChange={this.nameChangeHandler}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} className={styles.roleGroup}>
                        {
                            newRole && isCentrum && (
                                <RadioGroupInput
                                    className={styles.radioGroup}
                                    value={role.centrum ? 'centrum' : 'retail'}
                                    onValueChange={this.roleTypeChangeHandler}
                                    options={[
                                        {
                                            value: 'centrum',
                                            label: t('staff.centrumRole'),
                                        },
                                        {
                                            value: 'retail',
                                            label: t('staff.retailRole'),
                                        },
                                    ]}
                                />
                            )
                        }
                        {
                            !newRole && isCentrum && (
                                <InputLabel id="roleTypeLabel"
                                    className={styles.roleType}
                                >
                                    {role.centrum ? t('staff.centrumRole') : t('staff.retailRole')}
                                </InputLabel>
                            )
                        }
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={styles.middlePanel}>
                    <TreeView
                        id="privilegesCategories"
                        className={styles.privilegeHierarchy}
                        items={toJS(privilegeHierarchy)}
                        multipleSelection
                        labelField="value"
                        sortFields={['value']}
                        onItemSelectionChange={this.privilegeSelectHandler}
                    />
                    <Divider/>
                    <div className={styles.buttonContainer}>
                        <Button
                            id="addToRoleButton"
                            variant="contained"
                            className={styles.addButton}
                            disabled={!canAddPrivilege}
                            onClick={this.addPrivilegeHandler}
                        >
                            {t('staff.addToRole')}
                        </Button>
                    </div>
                </Paper>

                {this.renderRolePrivileges(role.privilegeVOList, privilegeHierarchy)}

                <SettingsActionPanel
                    saveEnabled={validation.valid && validation.modified}
                    onSave={saveRoleAndExit}
                    onCancel={cancelChanges}
                />

            </div>
        )
    }
}

export interface RoleSettingsURLParams {
    id?: string
}

export interface RoleSettingsProps extends RouteComponentProps<RoleSettingsURLParams>, React.HTMLProps<HTMLDivElement> {
    app?: AppStore
    rolesStore?: RolesStore
}
