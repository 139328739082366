import React from 'react'
import { t } from 'i18next'
import classNames from 'classnames'
import { without, flatten, flattenDeep, uniqBy, isNil } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { ShopVO } from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import {
    TopologyCondition, createTopologyCondition
} from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition'
import { TreeItem } from '../../../../../../../components/tree-view/tree-view'
import { FormatVO } from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import { highlightFirstMatch } from '../../../../../../../components/product-base-info/product-base-info'
import { RegionVO } from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'

const styles = require('./topology-panels.scss')

const getNodeSearchStringDepth = (tree: TreeItem, searchString: string): number => {
    if (!searchString) return -1
    if (tree.item.name.toLowerCase().indexOf(searchString.toLowerCase().trim()) > -1) return 0

    const isAnyCitySatisfies = tree.children.some(city =>
        city.item.name.toLowerCase().indexOf(searchString.toLowerCase().trim()) > -1)
    if (isAnyCitySatisfies) return 1

    const isAnyShopSatisfies = tree.children.some(city =>
        city.children.some(shop => {
            return `№${shop.item.number} ${shop.item.name}`
                .toLowerCase()
                .search(searchString.toLowerCase().trim()) !== -1
        })
    )
    if (isAnyShopSatisfies) return 2

    return -1
}

const getAllShops = (topologyMap: TreeItem[]): ShopVO[] =>
    flattenDeep<ShopVO>(topologyMap?.map(region => region.children?.map(city => city.children?.map(shop => shop.item as ShopVO))))

const isAllShopsSelected = (topologyConditions: TopologyCondition[], topologyMap: TreeItem[]): boolean => {
    const allShopsCount: number = getAllShops(topologyMap).length
    const allSelectedShopsCount: number = topologyConditions?.[0]?.shops?.length

    return allShopsCount === allSelectedShopsCount
}

interface TopologyPanelsProps {
    topologyMap: Array<TreeItem<RegionVO>>
    topologyConditions: TopologyCondition[]
    selectedFormats: FormatVO[]
    topologySearchString: string
    onConditionChange: (condition: TopologyCondition[]) => void
}

interface TopologyPanelsState {
    expandedRegionIds: number[]
    expandedCityNames: string[]
}

export class TopologyPanels extends React.Component<TopologyPanelsProps, TopologyPanelsState> {
    constructor(props: TopologyPanelsProps) {
        super(props)

        this.state = {
            expandedRegionIds: [],
            expandedCityNames: [],
        }
    }

    handleCheckAll = (checked: boolean): void => {
        const { topologyConditions, onConditionChange } = this.props
        const allShops: ShopVO[] = getAllShops(this.getTopologyMapFilteredByFormats())

        let newShops = []
        if (checked) {
            newShops = uniqBy([
                ...(topologyConditions?.[0]?.shops || []),
                ...allShops
            ], shop => shop.id)
        } else {
            topologyConditions?.[0]?.shops?.forEach(shop => {
                const shopId = shop.id
                // Главной галочкой мы удаляем только текущие отфильтрованные (видимые) магазины
                if (!allShops.some(filteredShop => filteredShop.id === shopId)) {
                    newShops.push(shop)
                }
            })
        }

        const newTopologyConditions: TopologyCondition[] = [createTopologyCondition({
            ...topologyConditions?.[0],
            shops: newShops
        })]
        onConditionChange(newTopologyConditions)
    }

    getRegionShopsCount = (item: TreeItem): number => {
        let count = 0

        item.children?.forEach(city => {
            count += city.children?.length
        })

        return count
    }

    getTopologyConditionsFilteredByFormats = (): TopologyCondition[] => {
        const { topologyConditions, selectedFormats } = this.props

        if (!topologyConditions?.[0]?.shops?.length) return []

        const filteredShops = topologyConditions[0].shops.filter(shop => {
            const shopFormatId = shop.format?.id
            if (isNil(shopFormatId)) return true
            return selectedFormats.some(format => shopFormatId === format.id)
        })

        return [
            {
                ...topologyConditions[0],
                shops: filteredShops
            }
        ]
    }

    getTopologyMapFilteredByFormats = (): TreeItem[] => {
        const { topologyMap, selectedFormats } = this.props

        if (!topologyMap?.length) return []

        let filteredTopology: TreeItem[] = []

        topologyMap.forEach(regionTreeItem => {
            let regionValidCities = []
            let validShopsCount = 0

            // Проверяем все города
            regionTreeItem.children?.forEach(cityTreeItem => {
                let cityValidShops = []

                // Проверяем все магазины
                cityTreeItem.children.forEach(shopTreeItem => {
                    const shopFormatId = shopTreeItem.item.format?.id
                    if (isNil(shopFormatId) || selectedFormats.some(format => shopFormatId === format.id)) {
                        cityValidShops.push(shopTreeItem)
                        validShopsCount += 1
                    }
                })

                // Добавляем только те города, где есть валидные магазины
                if (cityValidShops.length) {
                    regionValidCities.push({
                        ...cityTreeItem,
                        children: cityValidShops
                    })
                }
            })

            // Добавляем только те регионы, где есть валидные города
            if (regionValidCities.length) {
                filteredTopology.push({
                    ...regionTreeItem,
                    item: {
                        ...regionTreeItem.item,
                        shopsCount: validShopsCount
                    },
                    children: regionValidCities,
                })
            }
        })

        return filteredTopology
    }

    getSelectedRegionShopsCount = (item: TreeItem, topologyConditions: TopologyCondition[]): number =>
        topologyConditions?.[0]?.shops?.filter(shop => shop.city?.region?.id === item.item.id).length

    renderPanel = (region: TreeItem<RegionVO>, index: number): JSX.Element => {
        const { topologySearchString, topologyConditions, onConditionChange } = this.props
        const { expandedCityNames, expandedRegionIds } = this.state
        const nodeSearchStringDepth = getNodeSearchStringDepth(region, topologySearchString)

        const filteredTopologyConditions = this.getTopologyConditionsFilteredByFormats()

        const regionId = region.item.id

        const isRegionSelected: boolean = this.getRegionShopsCount(region) === this.getSelectedRegionShopsCount(
            region, filteredTopologyConditions
        )
        const anyShopInRegionSelected = filteredTopologyConditions?.[0]?.shops?.some(
            shop => shop.city.region.id === regionId
        )

        return (
            <Grid item xs={12} key={`expansionPanel-${regionId}`}>
                <ExpansionPanel
                    id={`expandedRegionCheckbox-${regionId}`}
                    expanded={nodeSearchStringDepth > 0 || expandedRegionIds.includes(regionId)}
                    onChange={(_, expanded: boolean) => {
                        if (expanded) {
                            this.setState(prevState => ({
                                expandedRegionIds: [...prevState.expandedRegionIds, regionId],
                            }))
                            return
                        }

                        this.setState(prevState => ({
                            expandedRegionIds: without(prevState.expandedRegionIds, regionId),
                        }))
                    }}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Checkbox
                            label={region.item.name}
                            highlightedLabelText={topologySearchString}
                            highlightedLabelClasses={styles.highlighted}
                            highlightedLabelId="searchResultHighlight"
                            checked={anyShopInRegionSelected || false}
                            indeterminate={!isRegionSelected && anyShopInRegionSelected}
                            onClick={(e: any) => {
                                e.stopPropagation()
                                const allShopsOfRegion: ShopVO[] = flatten(region.children?.map(city => city.children?.map(shop => shop.item)))

                                const newTopologyConditions: TopologyCondition[] = [createTopologyCondition({
                                    ...topologyConditions?.[0],
                                    shops: e.target.checked
                                        ? uniqBy([
                                            ...(topologyConditions?.[0]?.shops || []), ...allShopsOfRegion
                                        ], shop => shop.id)
                                        : topologyConditions?.[0]?.shops?.filter(shop =>
                                            !allShopsOfRegion.some(_shop => _shop.id === shop.id)
                                        ),
                                    shopsCityGroups: [],
                                    shopsRegionGroups: [],
                                })]

                                onConditionChange(newTopologyConditions)
                            }}
                            onFocus={(e: any) => e.stopPropagation()}
                            nodeMode
                        />
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <Grid container>
                            {region.children?.map(city => {
                                const cityId = city.item.id

                                const isCitySelected: boolean =
                                    city.children?.length === filteredTopologyConditions[0]?.shops?.filter(shop =>
                                        shop.city?.id === cityId).length

                                const anyShopInCitySelected = filteredTopologyConditions[0]?.shops?.some(
                                    shop => shop.city?.id === cityId
                                )

                                return (
                                    <Grid item xs={12} key={`cityExpansionPanel-${regionId}-${cityId}`}>
                                        <ExpansionPanel
                                            id={`cityExpansionPanel-${regionId}-${cityId}`}
                                            expanded={
                                                (nodeSearchStringDepth > 1 && city.children?.some(shop => {
                                                    const searchString = topologySearchString.toLowerCase().trim()
                                                    return `№${shop.item.number} ${shop.item.name}`
                                                        .toLowerCase()
                                                        .search(searchString) !== -1
                                                })) || (
                                                    (nodeSearchStringDepth > 0 || expandedRegionIds.includes(regionId)) &&
                                                    expandedCityNames.includes(city.item?.name)
                                                )
                                            }
                                            onChange={(_: any, expanded: boolean) => {
                                                if (expanded) {
                                                    this.setState(prevState => ({
                                                        expandedCityNames: [...prevState.expandedCityNames, city.item?.name],
                                                    }))
                                                    return
                                                }

                                                this.setState(prevState => ({
                                                    expandedCityNames: without(prevState.expandedCityNames, city.item?.name),
                                                }))
                                            }}
                                        >
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Checkbox
                                                    id={`expandCityCheckbox-${city.item?.name}`}
                                                    label={city.item?.name}
                                                    highlightedLabelText={topologySearchString}
                                                    highlightedLabelClasses={styles.highlighed}
                                                    highlightedLabelId="searchResultHighlight"
                                                    onClick={(e: any) => {
                                                        e.stopPropagation()
                                                        const allCityShops = city.children.map(treeItem => treeItem.item) || []
                                                        const newTopologyConditions: TopologyCondition[] = [
                                                            createTopologyCondition({
                                                                ...topologyConditions?.[0],
                                                                shops: e.target.checked
                                                                    ? uniqBy([
                                                                        ...(topologyConditions?.[0]?.shops || []),
                                                                        ...city.children?.map(_city => _city.item)
                                                                    ], shop => shop.id)
                                                                    : [
                                                                        ...topologyConditions?.[0]?.shops?.filter(shop =>
                                                                            !allCityShops.some(
                                                                                cityShop => cityShop.id === shop.id
                                                                            )
                                                                        ),
                                                                    ],
                                                                shopsCityGroups: [],
                                                                shopsRegionGroups: [],
                                                            })
                                                        ]

                                                        onConditionChange(newTopologyConditions)
                                                    }}
                                                    onFocus={(e: any) => e.stopPropagation()}
                                                    checked={anyShopInCitySelected || false}
                                                    indeterminate={
                                                        !(isCitySelected || isRegionSelected) && anyShopInCitySelected
                                                    }
                                                    nodeMode
                                                />
                                            </ExpansionPanelSummary>

                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    {city.children?.map(shop => {
                                                        return this.renderShopDetails(shop, isCitySelected, isRegionSelected)
                                                    })}
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        )
    }

    renderShopDetails = (shop: TreeItem, isCitySelected: boolean, isRegionSelected: boolean): JSX.Element => {
        const { topologyConditions, topologySearchString, onConditionChange } = this.props

        const filteredTopologyConditions = this.getTopologyConditionsFilteredByFormats()

        const isShopSelected = filteredTopologyConditions?.[0]?.shops?.some(_shop => _shop.id === shop.item.id)

        const item: ShopVO = shop.item
        return (
            <Grid item xs={12} key={`${item.number}`} style={{ paddingLeft: 8 }}>
                <Checkbox
                    id={`expandShopCheckbox-${item?.number}`}
                    className={styles.shopCheckbox}
                    onValueChange={checked => {
                        const newTopologyConditions: TopologyCondition[] = [createTopologyCondition({
                            ...topologyConditions?.[0],
                            shops: checked
                                ? [...(topologyConditions?.[0]?.shops || []), item]
                                : topologyConditions?.[0]?.shops?.filter(_shop => _shop.id !== shop.item.id),
                            shopsCityGroups: [],
                            shopsRegionGroups: [],
                        })]
                        onConditionChange(newTopologyConditions)
                    }}
                    checked={isShopSelected || isCitySelected || isRegionSelected}
                    label={`№${item.number} ${item.name}`}
                    highlightedLabelText={topologySearchString}
                />
            </Grid>
        )
    }

    render() {
        const filteredTopology = this.getTopologyMapFilteredByFormats()
        const filteredTopologyConditions = this.getTopologyConditionsFilteredByFormats()

        const areAllShopsSelected = isAllShopsSelected(filteredTopologyConditions, filteredTopology)
        const anyShopIsSelected = filteredTopologyConditions?.[0]?.shops?.length > 0

        return (
            <Grid container className={styles.panels}>
                <Grid item xs={12} style={{ paddingLeft: 13 }}>
                    <Checkbox
                        id="checkAllRegionsCheckbox"
                        className={styles.allRegion}
                        checked={anyShopIsSelected}
                        indeterminate={anyShopIsSelected && !areAllShopsSelected}
                        onValueChange={this.handleCheckAll}
                        label={t('advertisingActions.affectedZoneDialog.allRegions')}
                        nodeMode
                    />
                </Grid>
                {filteredTopology?.map((region, index) => this.renderPanel(region, index))}
            </Grid>
        )
    }
}

const ExpansionPanel = withStyles({
    root: {
        boxShadow: 'none',
        width: '100%',
        margin: 0,
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0',
            width: '100%',
        },
    },
    expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        minHeight: 36,
        '&$expanded': {
            minHeight: 36,
        },
    },
    content: {
        margin: 0,
        '&$expanded': {
            margin: 0,
        }
    },
    expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
        width: '100%',
    }
})(MuiExpansionPanelDetails)
