import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../../store/app-store'
import { ACTIVE_USERS, BLOCKED_USERS, UsersStore } from '../../../store/staff/users-store'
import Paper from '@material-ui/core/Paper'
import { Tabs, TabInfo } from '../../../../components/tabs/tabs'
import { t } from 'i18next'
import Fab from '@material-ui/core/Fab'
import Add from '@material-ui/icons/Add'
import { TopologyFilter } from '../../../components/topology-filter/topology-filter'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { createUserRoleVO, UserRoleVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/user-role-vo'
import { convertHierarchicalNodesToConditions } from '../../../components/topology-filter/topology-filter-util'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { APP_STORE, USERS_STORE } from '../../../store/stores'
import { ActiveUsersList } from './active-users-list'
import { BlockedUsersList } from './blocked-users-list'

const styles = require('./users.scss')

@inject(APP_STORE)
@inject(USERS_STORE)
@observer
export class Users extends React.Component<UsersProps> {

    componentDidMount(): void {
        const { fetchData } = this.props.usersStore
        fetchData()
    }

    tabChangeHandler = (tab: TabInfo): void => {
        const { changeActiveTab } = this.props.usersStore
        changeActiveTab(tab.data)
    }

    addNewUserHandler = (): void => {
        const {addNewUser} = this.props.usersStore
        addNewUser()
    }

    renderFilters = (): React.ReactNode => {
        const { isCentrum, topologyMap } = this.props.app
        const { nameFilter, roleFilter, changeFilters, roles, changeTopologyFilter } = this.props.usersStore

        // добавляется элемент "все роли" первым элементом селекта
        const allRolesItem: UserRoleVO = createUserRoleVO({id: -1000, roleName: t('staff.allRoles')})
        const rolesForFilter: UserRoleVO[] = roles ? roles.concat() : []
        rolesForFilter.splice(0, 0, allRolesItem)

        return (
            <div className={styles.filters}>
                <Paper className={styles.userFilters}>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} className={styles.header}>
                            <p className={styles.headerText}>{t('staff.searchUser')}</p>
                        </Grid>
                        <Grid item xs={12} className={styles.inputGrids}>
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                className={styles.inputGrids}
                            >
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        id="userNameInput"
                                        className={styles.nameFilter}

                                        fullWidth
                                        label={t('staff.inputUserNameOrSurname')}
                                        value={nameFilter}
                                        onChange={event => changeFilters(event.target.value, roleFilter)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectInput<DefaultSelectOption<number>>
                                        id="userRoleSelect"
                                        label={t('staff.userRole')}
                                        onSelect={selectedRole => {
                                            let roleId = selectedRole.value
                                            changeFilters(nameFilter,
                                                roleId !== allRolesItem.id ? roles.find(role => role.id === roleId) : null
                                            )
                                        }}
                                        value={roleFilter ? roleFilter.id : ''}
                                        options={rolesForFilter.map(role => {
                                            return {label: role.roleName, value: role.id}
                                        })}
                                        className={styles.roleSelect}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {isCentrum && (
                            <Grid item xs={12}>
                                <Divider/>
                                <TopologyFilter
                                    id="userCoverageFilter"
                                    map={topologyMap}
                                    title={t('staff.filteringByShops')}
                                    addButtonLabel={t('staff.addToFilter')}
                                    onFiltersChange={nodes => changeTopologyFilter(convertHierarchicalNodesToConditions(nodes))}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </div>
        )
    }

    render() {
        return (
            <div
                id="usersListPage"
                className={styles.users}
            >
                {this.renderFilters()}

                <Tabs
                    id="usersByBlockingTabs"
                    className={styles.tabs}
                    items={[
                        {
                            label: t('common.active'),
                            data: ACTIVE_USERS
                        },
                        {
                            label: t('common.blocked'),
                            data: BLOCKED_USERS
                        }
                    ]}
                    onTabChange={this.tabChangeHandler}
                >
                    <ActiveUsersList />
                    <BlockedUsersList />
                </Tabs>

                <Fab
                    id="addUserButton"
                    className={styles.addButton}
                    color="primary"
                    onClick={this.addNewUserHandler}
                >
                    <Add className={styles.addIcon}/>
                </Fab>
            </div>
        )
    }
}

export interface UsersProps extends React.HTMLProps<HTMLDivElement>{
    app?: AppStore
    usersStore?: UsersStore
}
