import { observable, action } from 'mobx'
import { imageService, DeviceType } from '../../../services/image-service'
import { bytesToBase64 } from '../../../../utils/serialization/base64-util'
import { CENTER_ALIGN, AlignBlockType, ImageData } from '../../../components/cash-printer-editor/utils/types'
import { withSpinner } from '../../with-spinner'

const TAPE_WIDTH_DEFAULT_VALUE = 80

export class CashPrinterImageStore {
    @observable
    tapeWidth: number = TAPE_WIDTH_DEFAULT_VALUE

    @observable
    downloadedImages: Array<{ tapeWidth: number, imageData: ImageData }> = []

    @action
    downloadImage = async (imageId: string, defaultAlignment: AlignBlockType = CENTER_ALIGN) => {
        await withSpinner(async () => {
            const pirit2Image = await imageService.downloadImage(imageId, DeviceType.Pirit2, this.tapeWidth)
            const pirit2Source = `data:image/png;base64,${bytesToBase64(new Uint8Array(pirit2Image))}`

            const pirit1Image = await imageService.downloadImage(imageId, DeviceType.Pirit1, this.tapeWidth)
            const pirit1Source = `data:image/png;base64,${bytesToBase64(new Uint8Array(pirit1Image))}`

            this.downloadedImages.push({
                imageData: {
                    imageId,
                    pirit1Source,
                    pirit2Source,
                    alignment: defaultAlignment
                },
                tapeWidth: this.tapeWidth,

            })
        })
    }

    @action
    replaceImage = async (imageId: string) => {
        await withSpinner(async () => {
            this.downloadedImages = this.downloadedImages.filter(image => image.imageData.imageId !== imageId)
            await this.downloadImage(imageId)
        })
    }

    @action
    setTapeWidth = (tapeWidth: number) => {
        this.tapeWidth = tapeWidth
        this.downloadMissingImages()
    }

    downloadMissingImages = () => {
        const imageIds = new Set(this.downloadedImages.map(image => image.imageData.imageId))
        imageIds.forEach(imageId => {
            if (this.downloadedImages.findIndex(image =>
                image.imageData.imageId === imageId && image.tapeWidth === this.tapeWidth) === -1) {
                this.downloadImage(imageId)
            }
        })
    }

    getImage = (images: Array<{ tapeWidth: number, imageData: ImageData }>, imageId: string): ImageData => {
        const image = images.find(value => value.imageData.imageId === imageId && value.tapeWidth === this.tapeWidth)
        if (image) {
            return image.imageData
        }

        return null
    }

    @action
    resetTapeWidth = () => {
        this.tapeWidth = TAPE_WIDTH_DEFAULT_VALUE
    }
}
