import createMuiTheme, { Theme } from '@material-ui/core/styles/createMuiTheme'
import common from '@material-ui/core/colors/common'

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        overlay: {
            type1: React.CSSProperties['color'],
            type2: React.CSSProperties['color'],
            type3: React.CSSProperties['color'],
            type4: React.CSSProperties['color'],
        }
    }

    interface PaletteOptions {
        overlay: {
            type1: React.CSSProperties['color'],
            type2: React.CSSProperties['color'],
            type3: React.CSSProperties['color'],
            type4: React.CSSProperties['color'],
        }
    }
}

export enum AvailableThemes {
    DARK_THEME = 'DARK_THEME',
    LIGHT_THEME = 'LIGHT_THEME',
}
export type AvailableThemesKeys = keyof typeof AvailableThemes

const PRIMARY_COLOR: string = '#0091EA'
const PRIMARY_COLOR_DARK: string = '#0277BD'
const PRIMARY_COLOR_DARK_THEME: string = '#007ECB'

const SECONDARY_COLOR: string = '#929EAA'
const SECONDARY_COLOR_DARK: string = '#687785'

const ERROR_COLOR: string = '#DD2C00'

const CHECKBOX_COLOR: string = '#607D8B'

export const themes: Record<Partial<AvailableThemesKeys>, Theme> = {
    DARK_THEME: createMuiTheme({
        palette: {
            type: 'dark',
            primary: {
                main: PRIMARY_COLOR_DARK_THEME,
                contrastText: common.white,
            },
            secondary: {
                main: SECONDARY_COLOR,
                dark: SECONDARY_COLOR_DARK,
                contrastText: common.white,
            },
            error: {
                main: ERROR_COLOR,
            },
            // success missing
            background: {
                paper: '#333639',
                default: '#26292c',
            },
            text: {
                primary: common.white,
                secondary: '#84878a',
                disabled: '#65686a',
            },
            overlay: {
                type1: '#515459',
                type2: '#414548',
                type3: '#383C41',
                type4: '#3B5060',
            },
            divider: '#414549',
        },
        overrides: {
            // В данный момент нет возможности модифицировать цвет рамки только в состоянии focused
            // (https://github.com/mui-org/material-ui/pull/13105)
            // т.к. MuiNotchedOutline - private
            // Поэтому до появления такой возможности, цвет рамки будет 'rgba(0, 0, 0, 0.54)' во всех состояниях
            MuiOutlinedInput: {
                notchedOutline: {
                    borderColor: 'rgba(0, 0, 0, 0.54)',
                }
            },
            MuiFormControl: {
                root: {
                    // На IE11 проблемы с дефолтным display: inline-flex
                    display: 'flex'
                }
            },
            MuiInput: {
                'root': {
                    '&$disabled': {
                        color: 'rgba(0, 0, 0, 0.87)'
                    }
                },
                underline: {
                    '&:before': {
                        borderBottom: `1px solid ${SECONDARY_COLOR}`
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottom: `2px solid ${PRIMARY_COLOR}`
                    },
                    '&:after': {
                        borderBottom: `2px solid ${PRIMARY_COLOR}`
                    },
                    '&$disabled:before': {
                        borderBottomStyle: 'dashed',
                    },
                }
            },
            MuiCheckbox: {
                colorSecondary: {
                    '&$checked': {
                        color: CHECKBOX_COLOR,
                    },
                },
            },
            MuiButton: {
                contained: {
                    'background-color': SECONDARY_COLOR,
                    'color': 'white',
                    '&:hover': {
                        'background-color': SECONDARY_COLOR_DARK
                    },
                },
                textSecondary: {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            },
            MuiSvgIcon: {
                colorSecondary: {
                    color: 'rgba(0, 0, 0, 0.54)'
                },
                colorPrimary: {
                    color: PRIMARY_COLOR
                },
            },
            MuiPaper: {
                rounded: {
                    borderRadius: '2px'
                }
            }
        },
        typography: {
            body1: {
                fontSize: '1rem'
            },
            body2: {
                fontSize: '1rem'
            },
            fontSize: 14,
            htmlFontSize: 14,
            h4: {
                fontSize: '1.715rem',
            },
            h5: {
                fontSize: '1.428rem',
            },
            h6: {
                fontSize: '1.142rem',
            },
            button: {
                fontSize: '1rem',
                lineHeight: '1.142rem',
            },
        }
    }),
    LIGHT_THEME: createMuiTheme({
        palette: {
            type: 'light',
            primary: {
                main: PRIMARY_COLOR,
                dark: PRIMARY_COLOR_DARK,
                contrastText: common.white,
            },
            secondary: {
                main: SECONDARY_COLOR,
                dark: SECONDARY_COLOR_DARK,
                contrastText: common.white,
            },
            error: {
                main: ERROR_COLOR,
            },
            // success missing
            background: {
                default: '#eceef0',
                paper: common.white,
            },
            text: {
                primary: '#1D2023',
                secondary: '#7A7C7E',
                disabled: '#C0C3C6',
            },
            overlay: {
                type1: '#DEE0E3',
                type2: '#F1F3F4',
                type3: '#F6F8F9',
                type4: '#EAF0F3',
            },
            divider: '#d0d3d6',
        },
        overrides: {
            // В данный момент нет возможности модифицировать цвет рамки только в состоянии focused
            // (https://github.com/mui-org/material-ui/pull/13105)
            // т.к. MuiNotchedOutline - private
            // Поэтому до появления такой возможности, цвет рамки будет 'rgba(0, 0, 0, 0.54)' во всех состояниях
            MuiOutlinedInput: {
                notchedOutline: {
                    borderColor: 'rgba(0, 0, 0, 0.54)',
                }
            },
            MuiFormControl: {
                root: {
                    // На IE11 проблемы с дефолтным display: inline-flex
                    display: 'flex'
                }
            },
            MuiInput: {
                'root': {
                    '&$disabled': {
                        color: 'rgba(0, 0, 0, 0.87)'
                    }
                },
                underline: {
                    '&:before': {
                        borderBottom: `1px solid ${SECONDARY_COLOR}`
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottom: `2px solid ${PRIMARY_COLOR}`
                    },
                    '&:after': {
                        borderBottom: `2px solid ${PRIMARY_COLOR}`
                    },
                    '&$disabled:before': {
                        borderBottomStyle: 'dashed',
                    },
                }
            },
            MuiCheckbox: {
                colorSecondary: {
                    '&$checked': {
                        color: CHECKBOX_COLOR,
                    },
                },
            },
            MuiButton: {
                contained: {
                    'background-color': SECONDARY_COLOR,
                    'color': 'white',
                    '&:hover': {
                        'background-color': SECONDARY_COLOR_DARK
                    },
                },
                textSecondary: {
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            },
            MuiSvgIcon: {
                colorSecondary: {
                    color: 'rgba(0, 0, 0, 0.54)'
                },
                colorPrimary: {
                    color: PRIMARY_COLOR
                },
            },
            MuiPaper: {
                rounded: {
                    borderRadius: '2px'
                }
            }
        },
        typography: {
            body1: {
                fontSize: '1rem'
            },
            body2: {
                fontSize: '1rem'
            },
            fontSize: 14,
            htmlFontSize: 14,
            h4: {
                fontSize: '1.715rem',
            },
            h5: {
                fontSize: '1.428rem',
            },
            h6: {
                fontSize: '1.142rem',
            },
            button: {
                fontSize: '1rem',
                lineHeight: '1.142rem',
            },
        }
    })
}
