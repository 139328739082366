import * as React from 'react'
import { inject, observer } from 'mobx-react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { CrystalsAppContainer } from '@crystalservice/crystals-ui/lib/components/crystals-app-container/crystals-app-container'
import { AppStore } from '../../store/app-store'
import { Progress } from '../../../components/progress/progress'
import { SimpleDialog } from '../../../components/simple-dialog/simple-dialog'
import { DialogStore } from '../../store/dialog-store'
import { APP_STORE, DIALOG_STORE } from '../../store/stores'
import { SnackbarManager } from '../pages-container/snackbar-manager/snackbar-manager'
import { LOCALE } from '../../../locale/languages'
import { themes } from '../../core/themes'

const styles = require('./app-container.scss')
@inject(APP_STORE)
@inject(DIALOG_STORE)
@observer
export class AppContainer extends React.Component<AppContainerProps> {
    render() {
        const {loading, locale, theme} = this.props.app
        const {open, dialogProps} = this.props.dialogStore

        return (
            <CrystalsAppContainer theme={themes[theme]}
                localeSettings={{
                    lng: LOCALE[locale] as 'ru' | 'en',
                    initializeLib: false,
                    debug: false
                }}
            >
                <CssBaseline/>
                <div className={styles.appContainer}>
                    <Progress
                        visible={loading}
                        minVisibleTimeMs={400}
                        maxInvisibleTimeMs={100}
                    />
                    {this.props.children}
                    <SimpleDialog
                        {...dialogProps}
                        open={open}
                    />
                    <SnackbarManager/>
                </div>
            </CrystalsAppContainer>
        )
    }
}

export interface AppContainerProps extends React.HTMLProps<AppContainer> {
    app?: AppStore
    dialogStore?: DialogStore
}
