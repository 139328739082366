import {t} from 'i18next'

// Общие роуты
export const NOT_FOUND: string = '/404' // Не найдено
export const NEW: string = '/new'
export const ROOT: string = '/'
export const LOGIN: string = '/login'
export const SETTINGS: string = '/settings' // Настройки
export const NOT_BINDED: string = '/not-binded' // Не привязанные
export const NOT_LOADED: string = '/not-loaded' // Не загруженные
export const DEVICES: string = '/devices' // Оборудование
export const TEMPLATES: string = '/templates' // Шаблоны
export const PRODUCT_LISTS: string = '/product-lists' // Списки товаров
export const OTHER: string = '/other' // Прочее
export const REFUSED: string = '/refused' // Отклоненные
export const SEARCH: string = '/search' // Поиск
export const EDITOR: string = '/editor' // Редактор
export const EDIT: string = '/edit' // Редактирование сущности

// Товары
export const SALES_POLICY: string = '/sales-policy'
export const PROMOTIONS: string = '/promotions'
export const LIMITS: string = '/limits'
export const PRODUCT_INFO: string = '/product-info'
export const PRODUCT_SALES_GROUPS: string = '/sales-groups'
export const PRICE_TAG_TEMPLATES: string = '/price-tag-templates'
export const BARCODES: string = '/barcodes'
export const KIT_STRUCTURE: string = '/kit-structure'
export const ALCOHOL_KIT_STRUCTURE: string = '/alcohol-kit-structure'
export const ALCOHOL_LIMITS: string = '/alcohol-limits'
export const WHOLESALE_LEVELS: string = '/wholesale-steps'

// Магазин
export const SHOP: string = '/shop'
export const SHOP_SETTINGS: string = '/shop-settings'
export const PRODUCT_SHELVES: string = '/product-shelves'
export const LICENSES: string = '/licenses'

// Магазины(Centrum)
export const SHOPS: string = '/shops'
export const TOPOLOGY: string = '/topology'
export const REGIONS: string = '/regions'
export const REGION: string = '/region'
export const CITY: string = '/city'
export const SHOPS_SEARCH: string = '/shops-search'

// Кассовый модуль
export const CASH_MODULE: string = '/cash-module'
export const CASH_SCHEDULES: string = '/schedules'
export const CHECK_FORMS: string = '/check-forms'
export const FUNCTIONS: string = '/functions'
export const PAYMENT_TYPES: string = '/payment-types'
export const BANKS: string = '/banks'
export const REGISTER: string = '/register'
export const NOT_LOADED_TO_SHOPS: string = '/not-loaded-to-shops'
export const NOT_LOADED_TO_CASHES: string = '/not-loaded-to-cashes'
export const EDIT_XML: string = '/edit-xml'

// Весовой модуль
export const SCALES_MODULE: string = '/scales-module'
export const CASHES_LISTS: string = '/cashes-lists'
export const SCALES_LISTS: string = '/scales-lists'
export const PRODUCT_LIST_EDIT: string = '/edit'
export const PIECE_RESTRICTION: string = '/piece-restriction'

// Справочники
export const HANDBOOKS: string = '/handbooks'
export const DEPARTMENTS: string = '/departments'
export const PRODUCT_TYPES: string = '/product-types'
export const ALCOHOL_TIME_LIMITS: string = '/alcohol-time-limits'
export const ALCOHOL_AGE_LIMITS: string = '/alcohol-age-limits'
export const COUNTERPARTIES: string = '/counterparties'
export const PRODUCERS: string = '/producers'

// Товары
export const PRODUCTS: string = '/products'
// Временные роуты для совместимости с текущей флексовой версией
export const NOT_LOADED_ON_CASHES: string = '/not-loaded-on-cashes'
export const WITHOUT_SCALES: string = '/without-scales'
export const NOT_LOADED_ON_SCALES: string = '/not-loaded-on-scales'
export const NOT_LOADED_ON_SHOPS: string = '/not-loaded-on-shops'

// Персонал
export const STAFF: string = '/staff'
export const USERS: string = '/users'
export const USER: string = '/user'
export const USER_ROLES: string = '/user-roles'
export const USER_ROLE: string = '/user-role'
export const SELLERS: string = '/sellers'
export const CASHIERS: string = '/cashiers'
export const CASHIER: string = '/cashier'
export const CASHIERS_ROLES: string = '/cashiers-roles'

// Интеграция
export const INTEGRATION: string = '/integration'
export const IMPORT_EXPORT: string = '/import-export'
export const EDIT_ERP_LIST: string = '/edit-erp-list'
export const EXTERNAL_PROCESSINGS: string = '/external-processings'
export const EDIT_PROCESSING_LIST: string = '/edit-processing-list'
export const REFUSED_PRODUCTS: string = '/refused-products'
export const VENDORS: string = '/vendors'
export const DATA: string = '/data'
export const EXPORT: string = '/export'
export const IMPORT: string = '/import'

// Ценники
export const PRICE_TAGS: string = '/price-tags'
export const PRINTING: string = '/printing'
export const PRICE_TAG_FORMATS: string = '/price-tag-formats'
export const FORMAT_MATRIX_SETTINGS: string = '/format-matrix-settings'
export const DIRECTIVE_PRINTING: string = '/directive-printing'
export const ELECTRONIC_PRICE_TAGS: string = '/electronic-price-tags'
export const NOT_PRINTED: string = '/not-printed'
export const CUSTOM_PRINTING: string = '/custom-printing'
export const FROM_PRODUCT_CARD: string = '/from-product-card'
export const REGULAR: string = '/regular'
export const ACTION: string = '/action'
export const NOT_ACTUAL: string = '/not-actual'
export const TOPOLOGIES: string = '/topologies'
export const SCHEDULES: string = '/printing-schedules'
export const HISTORY: string = '/history'

// Операционный день
export const OPERDAY: string = '/operday'
export const CHECKS: string = '/checks'
export const CHECK: string = '/check'
export const NON_FISCAL_DOCUMENTS: string = '/non-fiscal-documents'
export const REFUND: string = '/refund'
export const SHIFTS: string = '/shifts'
export const BANK_TRANSACTIONS: string = '/bank-transactions'
export const UPD_REGISTRY: string = '/upd-registry'
export const GENERAL: string = '/general'
export const VALIDATION: string = '/validation'

// Отчеты
export const REPORTS: string = '/reports'
export const PERSONNEL_MOTIVATION: string = '/personnel-motivation'
export const ACTIVITY_TIME: string = '/activity-time'
export const POSITION_PER_MINUTE: string = '/position-per-minute'
export const CASHIER_PERFOMANCE: string = '/cashier-perfomance'

export const CASHES_OPTIMIZATION: string = '/cashes-optimization'
export const SALES_REPORT: string = '/sales-report'
export const CASHDESK_SALES_REPORT: string = '/cashdesk-sales-report'
export const CASH_CUSTOMERS: string = '/cash-customers'

export const SHOP_WORK: string = '/shop-work'
export const SUMS_BY_CUSTOMERS: string = '/sums-by-customers'
export const UNKNOWN_PRODUCTS: string = '/unknown-products'
export const UNREADABLE_BARCODES: string = '/unreadable-barcodes'
export const CUSTOMERS_BY_DAYS_WEEK: string = '/customers-by-days-week'
export const CASHIERS_ACTIVITY: string = '/cashiers-activity'

export const ACTIONS_AND_DISCOUNTS: string = '/actions-and-discounts'
export const FINANCIAL: string = '/financial'

// Лояльность
export const LOYALTY: string = '/loyalty'
export const ACTIONS: string = '/actions'
export const ACTIONS_SEARCH: string = '/actions-search'
export const ACTIONS_TIMELINE: string = '/actions-timeline'
export const ACTION_EDIT: string = '/action-edit'
export const SALES_GROUPS: string = '/sales-groups'
export const SALES_GROUPS_EDIT: string = '/edit'
export const ACTION_PRICETAGS_PRINTING: string = '/action-pricetags-printing'
export const FINISHED_ACTIONS_WITH_PRINTED_TAGS: string = '/finished-actions-with-printed-tags'
export const ACTIONS_UNLOADED_ON_SHOPS: string = '/actions-unloaded-on-shops'
export const NEW_ACTION: string = '/new-action'
export const CONDITIONS: string = '/conditions'
export const RESULTS: string = '/results'
export const COUPONS_LIST: string = '/list'
export const COUPONS_INSTANCES: string = '/instances'
export const COUPONS_REPORTS: string = '/reports'
export const COUPONS_EDIT: string = '/coupon-edit'

// Карты
export const CARDS: string = '/cards'
export const CARDS_SEARCH: string = '/cards-search'
export const CARDS_NORMAL: string = '/cards-normal'
export const COUPONS_SEARCH: string = '/coupons-search'
export const CARD_CATEGORIES: string = '/card-categories'
export const BONUS_ACCOUNTS: string = '/bonus-accounts'
export const PRESENT_CARDS: string = '/present-cards'
export const EXTERNAL_CARDS: string = '/external-cards'
export const EXTERNAL_CARDS_EDIT: string = '/edit'
export const COUPONS: string = '/coupons'
export const SEGMENTS_SEARCH: string = '/segments-search'
export const SEGMENTS_EDIT: string = '/edit'
export const CARDS_UNLOADED_ON_SHOPS: string = '/cards-unloaded-on-shops'
export const CARDS_UNLOADED_ON_CASHES: string = '/cards-unloaded-on-cashes'
export const CARDS_DECLINED_ON_IMPORT: string = '/cards-declined-on-import'
export const PRESENT_CARDS_LIST: string = '/list'
export const PRESENT_CARDS_CATEGORIES: string = '/categories'
export const PRESENT_CARDS_CATEGORY_EDIT: string = '/edit'

// tslint:disable
/**
 * Возвращает кастомное название составного роута
 * @param {string} route
 * @returns {string}
 */
export const getCustomRouteName = (route: string): string => {
    switch (route) {
        case `${PRODUCTS}${SEARCH}`:
            return t('products.productsSearch')
        case `${PRODUCTS}${REFUSED}`:
            return t('products.validationFailed')
        case `${PRODUCTS}${NOT_LOADED_ON_SCALES}`:
            return t('products.notSentToScales')
        case `${PRODUCTS}${NOT_LOADED_ON_CASHES}`:
            return t('products.notSentToCash')
        case `${PRODUCTS}${NOT_LOADED_ON_SHOPS}`:
            return t('products.justSaved')
        case `${PRODUCTS}${WITHOUT_SCALES}`:
            return t('products.notBindedWithScales')
        case `${SCALES_MODULE}${NOT_BINDED}`:
            return t('set10.notBindedToScales')
        case `${SCALES_MODULE}${NOT_LOADED}`:
            return t('set10.notLoadedToScales')
        case `${PRICE_TAGS}${NOT_BINDED}`:
            return t('set10.notBindedPriceTags')
        case `${PRICE_TAGS}${NOT_PRINTED}`:
            return t('set10.notPrintedPriceTags')
        case `${PRICE_TAGS}${NOT_ACTUAL}`:
            return t('set10.notActualPriceTags')
        case `${PRICE_TAGS}${HISTORY}`:
            return t('set10.printingHistory')
        case `${REPORTS}${SHOP_WORK}${SUMS_BY_CUSTOMERS}`:
            return t('set10.sumsByCustomers')
        case `${REPORTS}${SHOP_WORK}${CUSTOMERS_BY_DAYS_WEEK}`:
            return t('set10.customersByDaysWeek')
        case `${REPORTS}${SHOP_WORK}${UNREADABLE_BARCODES}`:
            return t('set10.unreadableBarcodes')
        case `${REPORTS}${SHOP_WORK}${UNKNOWN_PRODUCTS}`:
            return t('set10.unknownProducts')
        case `${REPORTS}${SHOP_WORK}${CASHIERS_ACTIVITY}`:
           return t('set10.cashiersActivity')
        case `${CASH_MODULE}${DEVICES}`:
            return t('set10.cashDevices')
        case `${CASH_MODULE}${DEVICES}${REGISTER}`:
            return t('set10.cashDevicesRegister')
        case `${CASH_MODULE}${TEMPLATES}`:
            return t('set10.cashTemplates')
        case `${CASH_MODULE}${BANKS}`:
            return t('set10.banks')
        case `${SCALES_MODULE}${DEVICES}`:
            return t('set10.scalesDevices')
        case `${SCALES_MODULE}${TEMPLATES}`:
            return t('set10.scalesTemplates')
        case `${SCALES_MODULE}${PRODUCT_LISTS}`:
            return t('set10.scalesProductLists')
        case `${VENDORS}`:
            return t('set10.integrations')
        default:
            return ''
    }
}

/**
 * Возвращает названия для роутов 3+ уровня вложенности
 * @param {string} route
 * @returns {string}
 */
export const getDeepPageRouteName = (route: string): string => {
    switch (true) {
        case new RegExp(`${OPERDAY}${UPD_REGISTRY}\/(\\w+)`).test(route):
            return t('set10.updRegistryEditing')
        case new RegExp(`${REPORTS}${PERSONNEL_MOTIVATION}${POSITION_PER_MINUTE}`).test(route):
            return t('set10.positionPerMinute')
        case new RegExp(`${REPORTS}${PERSONNEL_MOTIVATION}${ACTIVITY_TIME}`).test(route):
            return t('set10.activityTime')
        case new RegExp(`${REPORTS}${SHOP_WORK}${SUMS_BY_CUSTOMERS}`).test(route):
            return t('set10.sumsByCustomers')
        case new RegExp(`${REPORTS}${SHOP_WORK}${CUSTOMERS_BY_DAYS_WEEK}`).test(route):
            return t('set10.customersByDaysWeek')
        case new RegExp(`${REPORTS}${SHOP_WORK}${UNREADABLE_BARCODES}`).test(route):
            return t('set10.unreadableBarcodes')
        case new RegExp(`${REPORTS}${SHOP_WORK}${UNKNOWN_PRODUCTS}`).test(route):
            return t('set10.unknownProducts')
        case new RegExp(`${REPORTS}${SHOP_WORK}${CASHIERS_ACTIVITY}`).test(route):
            return t('set10.cashiersActivity')
        case new RegExp(`${REPORTS}${CASHES_OPTIMIZATION}${CASH_CUSTOMERS}`).test(route):
            return t('set10.cashCustomers')
        case new RegExp(`${REPORTS}${CASHES_OPTIMIZATION}${SALES_REPORT}`).test(route):
            return t('set10.salesReport')
        case new RegExp(`${STAFF}${CASHIERS}\/(\\w+)`).test(route):
            return t('set10.cashierSettings')
        case new RegExp(`${CASH_MODULE}${DEVICES}${SETTINGS}\/(\\w+)`).test(route):
            return t('set10.deviceSettings')
        default:
            return ''
    }
}

/**
 * Возвращает название роута для главных страниц
 * @param {string} route
 * @returns {string}
 */
export const getRouteName = (route: string): string => {
    switch (route) {
        case NOT_FOUND:
            return t('set10.pageNotFound')
        case SETTINGS:
            return t('set10.settings')
        case DEVICES:
            return t('set10.devices')
        case TEMPLATES:
            return t('set10.templates')
        case PRODUCT_LISTS:
            return t('set10.productLists')

        case SHOPS:
            return t('set10.shops')
        case TOPOLOGY:
            return t('set10.topology')
        case SHOPS_SEARCH:
            return t('set10.shopsSearch')
        case SHOP:
            return t('set10.shop')
        case SHOP_SETTINGS:
            return t('set10.shopSettings')
        case PRODUCT_SHELVES:
            return t('set10.productShelves')
        case LICENSES:
            return t('set10.licenses')

        case CASH_MODULE:
            return t('set10.cashModule')
        case CHECK_FORMS:
            return t('set10.checkForms')
        case PAYMENT_TYPES:
            return t('set10.paymentTypes')
        case BANKS:
            return t('set10.banks')
        case NOT_LOADED_TO_SHOPS:
            return t('set10.notLoadedToShops')
        case NOT_LOADED_TO_CASHES:
            return t('set10.notLoadedToCashes')

        case SCALES_MODULE:
            return t('set10.scalesModule')

        case HANDBOOKS:
            return t('set10.handbooks')
        case DEPARTMENTS:
            return t('set10.departments')
        case PRODUCT_TYPES:
            return t('set10.productTypes')
        case EXTERNAL_PROCESSINGS:
            return t('set10.externalProcessings')
        case COUNTERPARTIES:
            return t('set10.counterparties')
        case PRODUCERS:
            return t('set10.producers')

        case PRODUCTS:
            return t('set10.products')

        case STAFF:
            return t('set10.staff')
        case USERS:
            return t('set10.users')
        case USER_ROLES:
            return t('set10.roles')
        case SELLERS:
            return t('set10.sellers')
        case CASHIERS:
            return t('set10.cashiers')
        case CASHIERS_ROLES:
            return t('set10.cashierRoles')

        case INTEGRATION:
            return t('set10.integration')
        case IMPORT_EXPORT:
            return t('set10.importExport')
        case EDIT_ERP_LIST:
            return t('set10.editErpList')
        case REFUSED_PRODUCTS:
            return t('set10.refusedProducts')
        case VENDORS:
            return t('set10.integrationPlugins')
        case DATA:
            return t('set10.data')
        case EXPORT:
            return t('set10.export')
        case IMPORT:
            return t('set10.import')

        case PRICE_TAGS:
            return t('set10.priceTags')
        case PRINTING:
            return t('set10.printing')
        case PRICE_TAG_FORMATS:
            return t('set10.priceTagFormats')
        case DIRECTIVE_PRINTING:
            return t('set10.directivePrinting')
        case ELECTRONIC_PRICE_TAGS:
            return t('set10.electronicPriceTags')
        case CUSTOM_PRINTING:
            return t('set10.customPriceTagsPrinting')
        case NOT_PRINTED:
            return t('set10.notPrintedPriceTags')
        case NOT_ACTUAL:
            return t('set10.notActualPriceTags')
        case REGULAR:
            return t('set10.regular')
        case ACTION:
            return t('set10.action')

        case OPERDAY:
            return t('set10.operday')
        case CHECKS:
            return t('set10.checks')
        case NON_FISCAL_DOCUMENTS:
            return t('set10.nonFiscalDocuments')
        case SHIFTS:
            return t('set10.shifts')
        case BANK_TRANSACTIONS:
            return t('set10.bankTransactions')
        case UPD_REGISTRY:
            return t('set10.updRegistry')

        case REPORTS:
            return t('set10.reports')
        case PERSONNEL_MOTIVATION:
            return t('set10.personnelMotivation')
        case CASHES_OPTIMIZATION:
            return t('set10.cashesOptimization')
        case SHOP_WORK:
            return t('set10.shopWork')
        case ACTIONS_AND_DISCOUNTS:
            return t('set10.byActionsAndDiscounts')
        case FINANCIAL:
            return t('set10.financial')
        case OTHER:
            return t('set10.other')

        case LOYALTY:
            return t('set10.loyalty')
        case ACTIONS_SEARCH:
            return t('set10.actionsSearch')
        case ACTIONS:
            return t('set10.actions')
        case SALES_GROUPS:
            return t('set10.salesGroups')
        case ACTION_PRICETAGS_PRINTING:
            return t('set10.actionPricetagsPrinting')
        case FINISHED_ACTIONS_WITH_PRINTED_TAGS:
            return t('set10.finishedActionsWithPrintedTags')
        case ACTIONS_UNLOADED_ON_SHOPS:
            return t('set10.notLoadedToShops')
        case NEW_ACTION:
            return t('set10.newAction')
        case CARDS:
            return t('set10.cards')
        case CARDS_SEARCH:
            return t('set10.cardsSearch')
        case COUPONS_SEARCH:
            return t('set10.couponsSearch')
        case CARD_CATEGORIES:
            return t('set10.cardCategories')
        case BONUS_ACCOUNTS:
            return t('set10.bonusAccounts')
        case PRESENT_CARDS:
            return t('set10.presentCards')
        case EXTERNAL_CARDS:
            return t('set10.externalCards')
        case COUPONS:
            return t('set10.coupons')
        case SEGMENTS_SEARCH:
            return t('set10.segmentsSearch')
        case CARDS_UNLOADED_ON_SHOPS:
            return t('set10.notLoadedToShops');
        case CARDS_UNLOADED_ON_CASHES:
            return t('set10.notLoadedToCashes')
        case CARDS_DECLINED_ON_IMPORT:
            return t('set10.declinedOnImport')

        default:
            return ''
    }
}
