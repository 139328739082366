import {
    observable,
    action,
    runInAction,
    computed,
    toJS
} from 'mobx'
import moment from 'moment'
import {
    isEmpty,
    isEqual,
    isNil,
    cloneDeep,
    debounce,
    Cancelable
} from 'lodash'
import { USER_STORE, APP_STORE, DIALOG_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { AppStore } from '../app-store'
import { DialogStore } from '../dialog-store'
import { CouponsVO, createCouponsVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/coupons-vo'
import { UniqueCouponVO, createUniqueCouponVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/unique-coupon-vo'
import { CardRangeVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/card-range-vo'
import {
    UniqueCouponBatchVO,
    createUniqueCouponBatchVO,
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/unique-coupon-batch-vo'
import { goTo } from '../../utils/router-util'
import { LOYALTY, COUPONS } from '../../core/app-routes'
import { withSpinner } from '../with-spinner'
import { iCouponsManagerRemote } from '../../../protocol/set10/i-coupons-manager-remote'
import { uniqueCouponsService } from '../../../protocol/set10/unique-coupons-service'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import { fromClientToServerTime, markAsServerDate } from '../../utils/app-util'
import {
    getSecondsFromDateTypeUnits,
    getUnitsFromSeconds,
    Period
} from '../../../utils/date-util'
import { config } from '../../config/config'
import {
    Coupon,
    IntervalMetricsType,
    BatchDownloadFileType,
    CouponIntervalType,
    PROCESSING_URL,
    AreaOfEffectType
} from '../../core/coupons/coupon-util'
import { salesManagementPropertiesService } from '../../../protocol/set10/sales-management-properties-service'
import { t } from 'i18next'
import { INTERNAL_ERROR, INTERSECTION_RANGES } from '../../core/server-codes'
import { INPUT_DELAY } from '../../../utils/default-timeouts'

export const MAX_COUPONS_PER_PAGE: number = 50

export class LoyaltyCouponsStore {

    @observable
    couponNameFilter: string = ''

    @observable
    coupons: Coupon[] = []

    @observable
    hasNextPage: boolean = false

    @observable
    batches: UniqueCouponBatchVO[] = []

    @observable
    ranges: CardRangeVO[] = []

    @observable
    editingCoupon: Coupon = undefined

    @observable
    intervalType: CouponIntervalType = CouponIntervalType.Dates

    @observable
    idleValue: number = 1

    @observable
    validPeriodValue: number = 1

    @observable
    idleType: IntervalMetricsType = IntervalMetricsType.Hours

    @observable
    validPeriodType: IntervalMetricsType = IntervalMetricsType.Hours

    @observable
    isNewCoupon: boolean = false

    @observable
    couponsProcessingEnabled: boolean = false

    debouncedFetchCouponsOnNameFilter: (() => void) & Cancelable = debounce(() => {
        this.coupons = []
        this.fetchCoupons()
    }, INPUT_DELAY)

    debouncedFetchCouponsOnScroll: (() => void) & Cancelable = debounce(() => {
        if (this.hasNextPage) {
            this.fetchCoupons()
        }
    }, INPUT_DELAY)

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private dialogStore: DialogStore = getStore(DIALOG_STORE)
    private originalCoupon: Coupon = undefined

    @computed
    get editedCouponModified() {
        return !isEqual(this.editingCoupon, this.originalCoupon)
    }

    fetchCouponProperties = async (): Promise<void> => {
        const properties = await salesManagementPropertiesService.getProperties1('COUPONING')

        const couponsProcessingEnabled = Object.keys(properties).some(key => key === PROCESSING_URL)
        if (couponsProcessingEnabled && properties[PROCESSING_URL]) {
            runInAction(() => {
                this.couponsProcessingEnabled = true
            })
        }
    }

    fetchCoupons = async (): Promise<void> => {
        const coupons: CouponsVO[] = await withSpinner(
            iCouponsManagerRemote.getAllCouponsPaging(
                this.couponNameFilter, this.coupons.length, MAX_COUPONS_PER_PAGE + 1
            )
        )

        runInAction(() => {
            this.coupons = [
                ...this.coupons,
                ...this.formatCouponsVOIntoCoupon(coupons.slice(0, MAX_COUPONS_PER_PAGE))
            ]
            this.hasNextPage = coupons.length > MAX_COUPONS_PER_PAGE
        })
    }

    openCoupon = async (guid: number): Promise<void> => {
        const isNew = guid === -1
        const coupon = isNew ? this.createCoupon() : await this.loadCoupon(guid)
        const idle: Period = getUnitsFromSeconds(coupon.idlePeriod)
        const validPeriod: Period = getUnitsFromSeconds(coupon.validPeriod)

        if (!isNew) {
            if (coupon.activationDate) {
                coupon.activationDate = fromClientToServerTime(new Date(coupon.activationDate))
            }
            if (coupon.expirationDate) {
                coupon.expirationDate = fromClientToServerTime(new Date(coupon.expirationDate))
            }
        }

        runInAction(() => {
            this.isNewCoupon = isNew
            this.originalCoupon = cloneDeep(coupon)
            this.editingCoupon = coupon

            this.intervalType = idle.value || validPeriod.value ? CouponIntervalType.Floating : CouponIntervalType.Dates

            this.idleValue = this.intervalType === CouponIntervalType.Floating ? idle.value : 1
            this.idleType = idle.type as IntervalMetricsType

            this.validPeriodValue = this.intervalType === CouponIntervalType.Floating ? validPeriod.value : 1
            this.validPeriodType = validPeriod.type as IntervalMetricsType

            this.ranges = []
            this.batches = []
        })
    }

    fetchCouponBatches = async (): Promise<void> => {
        if (this.editingCoupon.id === -1) return

        const batches = await withSpinner(uniqueCouponsService.getBatches(this.editingCoupon.guid))
        runInAction(() => {
            this.batches = batches
        })
    }

    fetchCouponRanges = async (): Promise<void> => {
        if (this.editingCoupon.id === -1) return

        const ranges = await withSpinner(iCardsManagerRemote.getCardRanges1(
            this.userStore.session,
            toJS(this.editingCoupon),
            0,
            1000
        ))
        runInAction(() => {
            this.ranges = ranges
        })
    }

    removeCouponRange = async (range: CardRangeVO): Promise<void> => {
        await withSpinner(iCardsManagerRemote.removeCardRange1(this.userStore.session, range))
        this.fetchCouponRanges()
    }

    addCouponRange = async (range: CardRangeVO): Promise<void> => {

        await withSpinner(iCardsManagerRemote.addCardRange1(
            this.userStore.session,
            range,
            {
                customCommonResponseMiddlewares: [
                    response => {
                        let error: { code?: number, message?: string } = response.data.error
                        if (error && error.code === INTERNAL_ERROR && error.message === INTERSECTION_RANGES) {
                            this.appStore.showSnackbar(
                                { message: t('cardsCategories.rangeExists'), variant: 'warning' }
                            )
                        }
                    },
                ]
            }
        ))
        this.fetchCouponRanges()
    }

    createCouponBatch = async ({ name, count }: { name: string, count: number }): Promise<void> => {
        const newBatch = createUniqueCouponBatchVO({
            name,
            size: count,
            number: this.batches.length + 1,
            couponGuid: this.editingCoupon.guid,
            authorFirstName: this.userStore.userInfo?.firstName || '',
            authorSecondName: this.userStore.userInfo?.lastName || '',
        })
        await uniqueCouponsService.addBatch(newBatch)
        this.fetchCouponBatches()
    }

    createCouponBatchWithFile = async ({ name, count, fileData }: {
        name: string,
        count: number,
        fileData: Uint8Array,
    }): Promise<void> => {
        const newBatch = createUniqueCouponBatchVO({
            name,
            size: count,
            number: this.batches.length + 1,
            couponGuid: this.editingCoupon.guid,
        })
        const result = await withSpinner(uniqueCouponsService.addBatchFromFile(newBatch, Array.from(fileData)))
        if (result.messageText?.length) {
            this.dialogStore.showDialog({
                message: result.messageText,
            })
        }
        this.fetchCouponBatches()
    }

    @action
    modifyEditingCoupon = (modification: Partial<Coupon>) => {
        this.editingCoupon = { ...this.editingCoupon, ...modification }
    }

    @action
    setNameFilter = (value: string): void => {
        this.couponNameFilter = value
        this.debouncedFetchCouponsOnNameFilter()
    }

    @action
    modifyIntervalType = (value: CouponIntervalType) => {
        this.intervalType = value

        if (value === CouponIntervalType.Dates) {
            if (!this.editingCoupon.activationDate || !this.editingCoupon.expirationDate) {
                const now = this.appStore.now()

                const activationDate = markAsServerDate(now)
                activationDate.setMinutes(0)

                const expirationDate = markAsServerDate(moment(now).add(1, 'week').toDate())
                expirationDate.setMinutes(0)

                this.editingCoupon.activationDate = activationDate
                this.editingCoupon.expirationDate = expirationDate
                this.editingCoupon.hasExpirationDate = true
            }
        } else {
            // Если было пусто, надо заполнить значения, иначе при сохранении поля останутся пустыми
            if (isNil(this.editingCoupon.idlePeriod) || isNil(this.editingCoupon.validPeriod)) {
                this.editingCoupon.idlePeriod = getSecondsFromDateTypeUnits(this.idleValue, this.idleType)
                this.editingCoupon.validPeriod = getSecondsFromDateTypeUnits(this.idleValue, this.idleType)
            }
        }
    }

    @action
    modifyIdlePeriod = ({ value, type }: { value: number, type: IntervalMetricsType }) => {
        const idlePeriod = getSecondsFromDateTypeUnits(value, type)
        this.idleValue = value
        this.idleType = type
        this.modifyEditingCoupon({ idlePeriod })
    }

    @action
    modifyValidPeriod = ({ value, type }: { value: number, type: IntervalMetricsType }) => {
        const validPeriod = getSecondsFromDateTypeUnits(value, type)
        this.validPeriodValue = value
        this.validPeriodType = type
        this.modifyEditingCoupon({ validPeriod })
    }

    downloadBatch = (batchNumbers: number[], fileType?: BatchDownloadFileType) => {
        let url = `${config.reportsAddress}?Action=GetCouponReport&BATCH_COUPON_GUID=${this.editingCoupon.guid}`
        const numbers = batchNumbers.join(',')
        url += `&BATCH_NUMBERS=${numbers}`

        if (fileType) {
            url += `&FILE_TYPE=${fileType}`
        } else {
            url += `&FILE_TYPE=${BatchDownloadFileType.Text}`
        }

        window.open(url, '_blank')
    }

    closeEditingCoupon = (): void => {
        this.resetCoupon()
        goTo(`${LOYALTY}${COUPONS}`)
    }

    loadCoupon = async (guid: number): Promise<Coupon> => {
        const coupon: CouponsVO = await withSpinner(iCouponsManagerRemote.getCouponByGuid(guid))
        const uniqueCoupon: UniqueCouponVO = await withSpinner(uniqueCouponsService.getByGuid(guid))

        if (isEmpty(coupon) && !isEmpty(uniqueCoupon)) {
            return {
                ...uniqueCoupon,
                serial: false,
            }
        } else if (!isEmpty(coupon)) {
            return {
                ...coupon,
                serial: true,
            }
        }

        return undefined
    }

    createCoupon = (): Coupon => {
        const now = this.appStore.now()

        const activationDate = markAsServerDate(now)
        activationDate.setMinutes(0)

        const expirationDate = markAsServerDate(moment(now).add(1, 'week').toDate())
        expirationDate.setMinutes(0)

        const coupon: Coupon = {
            ...createCouponsVO({
                id: -1,
                activationDate,
                expirationDate,
                hasExpirationDate: true,
                showCouponMessages: true,
                areaOfEffect: AreaOfEffectType.Receipt
            }),
            serial: true,
        }

        return coupon
    }

    saveEditedCoupon = async () => {
        const isNew = this.editingCoupon.id === -1

        // При сохранении купона надо удалять лишние значения (если выбраны интервалы - удалить даты)
        const { serial, ...editingCoupon } = toJS(this.editingCoupon)
        let savedCoupon: CouponsVO = editingCoupon

        if (this.intervalType === CouponIntervalType.Dates) {
            savedCoupon.idlePeriod = null
            savedCoupon.validPeriod = null
        } else {
            savedCoupon.activationDate = null
            savedCoupon.expirationDate = null
            savedCoupon.hasExpirationDate = true
        }

        if (!serial) {
            savedCoupon.multiUse = false
            savedCoupon.showCouponMessages = false
            savedCoupon.needShowMessage = false
        }

        if (isNew) {
            let newCoupon: CouponsVO

            if (serial) {
                newCoupon = await iCouponsManagerRemote.createCoupons(this.userStore.session, savedCoupon)
            } else {
                const { '@class': c, ...coupon } = savedCoupon
                const newUniqueCoupon = createUniqueCouponVO(coupon)
                newCoupon = await uniqueCouponsService.create(newUniqueCoupon)
            }

            this.openCoupon(newCoupon.guid)
        } else {
            if (serial) {
                await iCouponsManagerRemote.storeCoupons(this.userStore.session, savedCoupon)
            } else {
                const { '@class': c, ...coupon } = savedCoupon
                const newUniqueCoupon = createUniqueCouponVO({ ...coupon })
                await uniqueCouponsService.save(newUniqueCoupon)
            }
            this.openCoupon(this.editingCoupon.guid)
        }
    }

    @action
    resetCoupon = (): void => {
        this.editingCoupon = undefined
        this.originalCoupon = undefined
        this.isNewCoupon = false
        this.batches = []
        this.ranges = []
        this.intervalType = CouponIntervalType.Dates
        this.idleValue = 1
        this.validPeriodValue = 1
        this.idleType = IntervalMetricsType.Hours
        this.validPeriodType = IntervalMetricsType.Hours
    }

    @action
    reset = (): void => {
        this.couponNameFilter = ''
        this.coupons = []
        this.editingCoupon = undefined
        this.originalCoupon = undefined
        this.isNewCoupon = false
        this.batches = []
        this.ranges = []
        this.intervalType = CouponIntervalType.Dates
        this.idleValue = 1
        this.validPeriodValue = 1
        this.idleType = IntervalMetricsType.Hours
        this.validPeriodType = IntervalMetricsType.Hours
        this.couponsProcessingEnabled = false
        this.hasNextPage = false
    }

    private formatCouponsVOIntoCoupon = (coupons: CouponsVO[]): Coupon[] => {
        return coupons.map(coupon => {
            return {
                ...coupon,
                serial: this.getCouponSerial(coupon)
            }
        })
    }

    private getCouponSerial = (coupon: CouponsVO): boolean => {
        if (coupon.classType === 'CouponsVO') {
            return true
        }

        if (coupon.classType === 'UniqueCouponVO') {
            return false
        }

        return null
    }
}
