import { observable, action, autorun, runInAction, computed } from 'mobx'
import { PriceTagTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { XMLPriceTag } from '../../../core/price-tags/xml/xml-price-tag'
import { deserializePriceTagXML } from '../../../core/price-tags/serialize-utils'
import { printersManagerLocal } from '../../../../protocol/set10/printers-manager-local'
import { UserStore } from '../../user-store'
import { getStore } from '../../stores-repository'
import { USER_STORE } from '../../stores'
import { PrinterVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/printer-vo'
import { MnemonicGroupVO } from '../../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-group-vo'
import { MnemonicPropertiesVO } from '../../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'
import { mnemonicsPropertiesFacadeLocal } from '../../../../protocol/set10/mnemonics-properties-facade-local'

export class PriceTagsPrintingPreviewStore {

    @observable
    priceTag: PriceTagTemplateVO = null

    @observable
    priceTagXmlData: XMLPriceTag = null

    @observable
    printer: PrinterVO = null

    @observable
    mnemonicGroups: MnemonicGroupVO[] = []

    private userStore: UserStore = getStore(USER_STORE)

    constructor() {
        autorun(() => {
            if (this.priceTag && this.priceTag.data) {
                this.createPriceTagXMLData(this.priceTag.data)
            }
        })
    }

    @computed
    get mnemonics(): MnemonicPropertiesVO[] {
        return this.mnemonicGroups.reduce((prevMnems: MnemonicPropertiesVO[], currentGroup: MnemonicGroupVO) => {
            return [
                ...prevMnems,
                ...currentGroup.mnemonicProperties
            ]
        }, [])
    }

    fetchMnemonicGroups = async (): Promise<void> => {
        const mnemonicGroups = await mnemonicsPropertiesFacadeLocal.getAllByGroups({ useCache: true }) || []

        runInAction(() => {
            this.mnemonicGroups = mnemonicGroups
        })
    }

    @action
    openPriceTagWithPrinter = (priceTagId: string, printer: PrinterVO): void => {
        this.printer = printer
        this.fetchPriceTag(priceTagId)
    }

    @action
    reset = (): void => {
        this.priceTag = null
        this.priceTagXmlData = null
        this.printer = null
        this.mnemonicGroups = []
    }

    private createPriceTagXMLData = async (encodedData: string): Promise<void> => {
        const priceTagXmlData = await deserializePriceTagXML(encodedData)
        runInAction(() => {
            this.priceTagXmlData = priceTagXmlData
        })
    }

    private fetchPriceTag = async (id: string): Promise<void> => {
        const priceTag = await printersManagerLocal.getPriceTagTemplate(this.userStore.session, id)
        runInAction(() => {
            this.priceTag = priceTag
        })
    }
}
