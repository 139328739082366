import React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { NumberInputWithSpinners } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input-with-spinners'

import { LOYALTY_SETTINGS_STORE } from '../../../store/stores'
import { LoyaltySettingsProps } from './loyalty-settings'
import { PageLoader } from '../../../../components/loaders/page-loader'
import Divider from '@material-ui/core/Divider'
import { isNil } from 'lodash'

const styles = require('./loyalty-settings.scss')

@inject(LOYALTY_SETTINGS_STORE)
@observer
export class LoyaltySettingsCoupons extends React.Component<LoyaltySettingsProps> {

    render() {
        const { settings, discountSettings, editSettings, editDiscountSettings } = this.props.loyaltySettingsStore

        if (!settings || !discountSettings) {
            return <PageLoader/>
        }

        return (
            <Paper className={styles.coupons}>

                <div className={styles.header}>{t('loyaltySettings.couponsSettings')}</div>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.couponPrefixesCash')}
                        </span>
                    </Grid>

                    <Grid item xs={4} md={2}>
                        <NumberInputWithSpinners
                            id="couponPrefixesCashInput"
                            value={Number(settings?.cashGeneratedUniqueCouponPrefix) || 0}
                            onValueChange={value => editSettings({ cashGeneratedUniqueCouponPrefix: `${value}` })}
                            step={1}
                            min={10}
                            max={99}
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.couponPrefixesServer')}
                        </span>
                    </Grid>

                    <Grid item xs={4} md={2}>
                        <NumberInputWithSpinners
                            id="couponPrefixesServerInput"
                            value={Number(settings?.serverGeneratedUniqueCouponPrefix) || 0}
                            onValueChange={value => editSettings({ serverGeneratedUniqueCouponPrefix: `${value}` })}
                            step={1}
                            min={10}
                            max={99}
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.couponPrefixesWS')}
                        </span>
                    </Grid>

                    <Grid item xs={4} md={2}>
                        <NumberInputWithSpinners
                            id="couponPrefixesWSInput"
                            value={Number(settings?.wsGeneratedUniqueCouponPrefix) || 0}
                            onValueChange={value => editSettings({ wsGeneratedUniqueCouponPrefix: `${value}` })}
                            step={1}
                            min={10}
                            max={99}
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.maxCountOfGeneratedCouponsInCheck')}
                        </span>
                    </Grid>

                    <Grid item xs={4} md={2}>
                        <NumberInputWithSpinners
                            id="maxCountOfGeneratedCouponsInCheckInput"
                            value={discountSettings?.maxCountOfGeneratedCouponsInCheck || 0}
                            onValueChange={value => editDiscountSettings({ maxCountOfGeneratedCouponsInCheck: value })}
                            step={1}
                            min={0}
                        />
                    </Grid>

                </Grid>

                <Divider style={{ margin: '16px -24px' }}/>

                <div className={styles.secondaryHeader}>{t('loyaltySettings.prefixTitle')}</div>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.couponPrefixWithoutReuseControl')}
                        </span>
                    </Grid>

                    <Grid item xs={4} md={2}>
                        <NumberInputWithSpinners
                            id="couponPrefixWithoutReuseControlInput"
                            canBeNull
                            value={discountSettings?.couponPrefixWithoutReuseControl ?
                                Number(discountSettings?.couponPrefixWithoutReuseControl) : null
                            }
                            onValueChange={value => editDiscountSettings({
                                couponPrefixWithoutReuseControl: isNil(value) ? '' : `${value}`
                            })}
                            min={0}
                            step={1}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} md={4}>
                        <span className={styles.inputLabel}>
                            {t('loyaltySettings.couponPrefixWithReuseControl')}
                        </span>
                    </Grid>

                    <Grid item xs={4} md={2}>
                        <NumberInputWithSpinners
                            id="couponPrefixWithReuseControlInput"
                            canBeNull
                            value={discountSettings?.couponPrefixWithReuseControl ?
                                Number(discountSettings?.couponPrefixWithReuseControl) : null
                            }
                            onValueChange={value => editDiscountSettings({
                                couponPrefixWithReuseControl: isNil(value) ? '' : `${value}`
                            })}
                            min={0}
                            step={1}
                        />
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}
