import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'

import { Chips } from '../../../../../components/displaying/chips'
import {
    TopologyCondition, createTopologyCondition
} from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition'
import { ShopsRegionGroup } from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/shops-region-group'
import { ShopsCityGroup } from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/shops-city-group'
import { ShopVO } from '../../../../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'

import { ACTION_SETTINGS_STORE, APP_STORE } from '../../../../../store/stores'
import { ActionSettingsStore } from '../../../../../store/actions/action-settings-store'
import { AppStore } from '../../../../../store/app-store'
import classNames from 'classnames'
import { squashShopsIntoCities, squashCitiesIntoRegions } from '../../../../../core/topology/topology-util'

const styles = require('./affected-zone-results.scss')

interface AffectedZoneResultsProps {
    className?: string
    emptyClassName?: string
    chipClassName?: string
    disabled?: boolean
    actionSettingsStore?: ActionSettingsStore
    app?: AppStore
}

interface AffectedZoneRegionChip {
    label: string
    id: number
}

interface AffectedZoneCityChip {
    label: string
    id: number
}

interface AffectedZoneShopChip {
    label: string
    id: number
}

const getAffectedZoneRegionChips = (regionGroups: ShopsRegionGroup[]): AffectedZoneRegionChip[] => {
    if (!regionGroups || !regionGroups.length) return []
    return regionGroups.map((regionGroup: ShopsRegionGroup) => ({
        label: regionGroup?.regions?.[0].name,
        id: regionGroup?.regions?.[0].id,
    }))
}

const getAffectedZoneCityChips = (cityGroups: ShopsCityGroup[]): AffectedZoneCityChip[] => {
    if (!cityGroups || !cityGroups.length) return []
    return cityGroups.map((cityGroup: ShopsCityGroup) => {
        const label = t('topology.chipCityLabel', { city: cityGroup?.cities?.[0].name })

        return ({
            label,
            id: cityGroup?.cities?.[0].id,
        })
    })
}

const getAffectedZoneShopChips = (shops: ShopVO[]): AffectedZoneShopChip[] => {
    if (!shops || !shops.length) return []
    return shops.map((shop: ShopVO) => {
        const label = t('topology.chipShopLabel', { city: shop?.city?.name, shop: shop?.name })

        return ({
            label,
            id: shop?.id,
        })
    })
}

const getRegionGroups = (topologyConditions: TopologyCondition[]): ShopsRegionGroup[] => {
    if (!topologyConditions || !topologyConditions.length) return []
    return topologyConditions[0].shopsRegionGroups
}

const getCityGroups = (topologyConditions: TopologyCondition[]): ShopsCityGroup[] => {
    if (!topologyConditions || !topologyConditions.length) return []
    return topologyConditions[0].shopsCityGroups
}

const getShops = (topologyConditions: TopologyCondition[]): ShopVO[] => {
    if (!topologyConditions || !topologyConditions.length) return []
    return topologyConditions[0].shops
}

@inject(ACTION_SETTINGS_STORE)
@inject(APP_STORE)
@observer
export class AffectedZoneResults extends React.Component<AffectedZoneResultsProps> {
    handleDeleteRegionCondition = (item: AffectedZoneRegionChip): void => {
        const { actionSettingsStore } = this.props
        const { editedAction } = actionSettingsStore
        const topologyConditions = toJS(editedAction.topologyConditions)

        const updatedTopologyConditions: TopologyCondition[] = [createTopologyCondition({
            ...topologyConditions[0],
            shopsRegionGroups: topologyConditions?.[0]?.shopsRegionGroups?.filter(
                region => region?.regions?.[0]?.id !== item.id
            ),
            shopsCityGroups: topologyConditions?.[0]?.shopsCityGroups?.filter(
                city => city.cities[0].region.id !== item.id
            ),
            shops: topologyConditions?.[0]?.shops?.filter(shop => shop.city.region.id !== item.id)
        })]

        actionSettingsStore.modifyEditedAction({ topologyConditions: updatedTopologyConditions })
    }

    handleDeleteCityCondition = (item: AffectedZoneCityChip): void => {
        const { actionSettingsStore } = this.props
        const { editedAction } = actionSettingsStore
        const topologyConditions = toJS(editedAction.topologyConditions)

        const updatedTopologyConditions: TopologyCondition[] = [createTopologyCondition({
            ...topologyConditions[0],
            shopsCityGroups: topologyConditions?.[0]?.shopsCityGroups?.filter(city => city?.cities?.[0]?.id !== item.id),
            shops: topologyConditions?.[0]?.shops?.filter(shop => shop.city.id !== item.id)
        })]

        actionSettingsStore.modifyEditedAction({ topologyConditions: updatedTopologyConditions })
    }

    handleDeleteShopCondition = (item: AffectedZoneShopChip): void => {
        const { actionSettingsStore } = this.props
        const { editedAction } = actionSettingsStore
        const topologyConditions = toJS(editedAction.topologyConditions)

        const updatedTopologyConditions: TopologyCondition[] = [createTopologyCondition({
            ...topologyConditions[0],
            shops: topologyConditions?.[0]?.shops?.filter(shop => shop?.id !== item.id)
        })]

        actionSettingsStore.modifyEditedAction({ topologyConditions: updatedTopologyConditions })
    }

    render() {
        const { actionSettingsStore, disabled, className, emptyClassName, chipClassName } = this.props
        const { topologyMap } = this.props.app

        const { topologyConditions, editedAction } = actionSettingsStore

        if (!editedAction) return null

        const allNodes = editedAction.allNodes

        let regions = getRegionGroups(topologyConditions)
        let cities = getCityGroups(topologyConditions)
        let shops = getShops(topologyConditions)

        const { shops: shopsLeft, cityGroups } = squashShopsIntoCities({
            shops,
            cities,
            topologyMap,
        })

        const { cityGroups: citiesLeft, regions: reagionsLeft } = squashCitiesIntoRegions({
            cities: cityGroups,
            regions,
            topologyMap,
        })

        const regionChips = getAffectedZoneRegionChips(reagionsLeft)
        const cityChips = getAffectedZoneCityChips(citiesLeft)
        const shopChips = getAffectedZoneShopChips(shopsLeft)

        const isThereAnyChip = regionChips?.length || cityChips?.length || shopChips?.length

        return (
            <div
                className={classNames(className, {
                    [emptyClassName]: !isThereAnyChip
                })}
            >
                <div
                    id="affectedZoneText"
                    className={styles.centeredText}
                >
                    {allNodes && t('topology.entireStores')}
                    {!allNodes && (!topologyConditions?.length || !isThereAnyChip) && t('topology.zoneNotSet')}
                </div>

                {!allNodes && regionChips?.length > 0 && (
                    <Chips
                        id="region-chip"
                        items={regionChips}
                        className={chipClassName}
                        format={(item: AffectedZoneRegionChip) => item.label}
                        onDelete={disabled ? undefined : this.handleDeleteRegionCondition}
                    />
                )}
                {!allNodes && cityChips?.length > 0 && (
                    <Chips
                        id="city-chip"
                        items={cityChips}
                        className={chipClassName}
                        format={(item: AffectedZoneCityChip) => item.label}
                        onDelete={disabled ? undefined : this.handleDeleteCityCondition}
                    />
                )}
                {!allNodes && shopChips?.length > 0 && (
                    <Chips
                        id="shop-chip"
                        items={shopChips}
                        className={chipClassName}
                        format={(item: AffectedZoneShopChip) => item.label}
                        onDelete={disabled ? undefined : this.handleDeleteShopCondition}
                    />
                )}
            </div>
        )
    }
}
