import { SimpleProductInDepartment } from './simple-product-in-department'

export interface SimpleDepartment {
    '@class': 'ru.crystals.setretailx.products.common.SimpleDepartment'
    number?: number
    name?: string
    description?: string
    products?: SimpleProductInDepartment[]
    identifier?: number
}

export const createSimpleDepartment = (initialization: Partial<SimpleDepartment>): SimpleDepartment => {
    if (!initialization) return null
    
    let result: SimpleDepartment = {
        number: initialization.number,
        name: initialization.name,
        description: initialization.description,
        products: initialization.products,
        identifier: initialization.identifier,
        '@class': 'ru.crystals.setretailx.products.common.SimpleDepartment',
    }
    
    return result
}
