
export type ProductType = 'ProductWeightEntityBillet' | 'ProductPieceEntityBillet' | 'ProductCiggyEntityBillet' |
    'ProductSpiritsEntityBillet' | 'ProductPieceWeightEntityBillet' | 'ProductJewelEntityBillet' |
    'ProductGiftCardEntityBillet' | 'ProductCFTGiftCardEntityBillet' | 'ProductSiebelGiftCardEntityBillet' |
    'ProductLicenseKeyEntityBillet' | 'ProductDiscountCardEntityBillet' | 'ProductBonusPointsEntityBillet' |
    'ProductKitEntityBillet'

// Весовой
export const WEIGHT: ProductType = 'ProductWeightEntityBillet'

// Штучный
export const PIECE: ProductType = 'ProductPieceEntityBillet'

// Табак
export const CIGGY: ProductType = 'ProductCiggyEntityBillet'

// Алкоголь
export const SPIRIT: ProductType = 'ProductSpiritsEntityBillet'

// Штучно - весовой
export const WEIGHT_PIECE: ProductType = 'ProductPieceWeightEntityBillet'

// Ювелирное изделие
export const JEWEL: ProductType = 'ProductJewelEntityBillet'

// Подарочная карта
export const PRESENT_CARD: ProductType = 'ProductGiftCardEntityBillet'

// Подарочная карта CFT
export const PRESENT_CARD_CFT: ProductType = 'ProductCFTGiftCardEntityBillet'

// Подарочная карта Siebel
export const PRESENT_CARD_SIEBEL: ProductType = 'ProductSiebelGiftCardEntityBillet'

// Электронный лицензионный ключ
export const LICENSE_KEY: ProductType = 'ProductLicenseKeyEntityBillet'

// Дисконтная карта
export const DISCOUNT_CARD: ProductType = 'ProductDiscountCardEntityBillet'

// Зачисление бонусных баллов
export const BONUS_POINTS: ProductType = 'ProductBonusPointsEntityBillet'

// Комплект
export const KIT: ProductType = 'ProductKitEntityBillet'
