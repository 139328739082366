import {
    BOTTOM, BOTTOM_LEFT,
    BOTTOM_RIGHT, CENTER,
    ControllerType,
    LEFT,
    RIGHT,
    ROTATION,
    ROTATION_CONTROLLER_OFFSET,
    TOP,
    TOP_LEFT,
    TOP_RIGHT
} from './hand-controller'
import { convertMatrix, Vector2d } from '../../utils/math/geom-util'
import { RectBounds } from './rect-bounds'

/**
 * Возвращает позицию в прямоугольнике относительно указанной точки
 * @param controllerType
 * @param width
 * @param height
 * @param relativeTo
 */
export function getControllerPositionInRectangle(controllerType: ControllerType, width: number, height: number,
                                                 relativeTo: 'center' | 'leftTop' = 'center'): Vector2d {
    let x = 0
    let y = 0
    let pivotPoint = new Vector2d(
        relativeTo === 'center' ? 0 : width / 2,
        relativeTo === 'center' ? 0 : height / 2,
    )
    switch (controllerType) {
        case CENTER:
        // Возвращаем левую верхнюю точку, т.к. перемещение совершается относительно нее
        case TOP_LEFT:
        case LEFT:
        case BOTTOM_LEFT:
            x = pivotPoint.x - width / 2
            break
        case TOP:
        case BOTTOM:
            x = pivotPoint.x
            break
        case TOP_RIGHT:
        case RIGHT:
        case BOTTOM_RIGHT:
            x = pivotPoint.x + width / 2
            break
        case ROTATION:
            x = pivotPoint.x + width / 2 + ROTATION_CONTROLLER_OFFSET
            break
    }

    switch (controllerType) {
        case CENTER:
        // Возвращаем левую верхнюю точку, т.к. перемещение совершается относительно нее
        case TOP_LEFT:
        case TOP:
        case TOP_RIGHT:
            y = pivotPoint.y - height / 2
            break
        case LEFT:
        case RIGHT:
        case ROTATION:
            y = pivotPoint.y
            break
        case BOTTOM_LEFT:
        case BOTTOM:
        case BOTTOM_RIGHT:
            y = pivotPoint.y + height / 2
            break
    }

    return new Vector2d(x, y)
}

export function getControllerResizeDirections(controllerType: ControllerType): { directionX: Vector2d, directionY: Vector2d } {
    let directionX
    let directionY
    switch (controllerType) {
        case TOP:
            directionY = new Vector2d(0, -1)
            break
        case TOP_RIGHT:
            directionX = new Vector2d(1, 0)
            directionY = new Vector2d(0, -1)
            break
        case RIGHT:
            directionX = new Vector2d(1, 0)
            break
        case BOTTOM_RIGHT:
            directionX = new Vector2d(1, 0)
            directionY = new Vector2d(0, 1)
            break
        case BOTTOM:
            directionY = new Vector2d(0, 1)
            break
        case BOTTOM_LEFT:
            directionX = new Vector2d(-1, 0)
            directionY = new Vector2d(0, 1)
            break
        case LEFT:
            directionX = new Vector2d(-1, 0)
            break
        case TOP_LEFT:
            directionX = new Vector2d(-1, 0)
            directionY = new Vector2d(0, -1)
            break
        case CENTER:
        case ROTATION:
            break
        default:
            throw new Error(`Wrong controller type: ${controllerType}`)
    }

    return { directionX, directionY }
}

export function getResizeForAction(bounds: RectBounds, controllerType: ControllerType, translate: Vector2d): Vector2d {
    // Вращение и перемещение не оказывают влияние на размеры
    if ([ROTATION, CENTER].includes(controllerType)) return new Vector2d()

    const convertedMatrix = convertMatrix(bounds.matrix)
    // Некоторые хендлеры могут работать в двух направления.
    // Окончательный эффект определяется суммой по этим направлениям.
    let { directionX, directionY} = getControllerResizeDirections(controllerType)

    if (directionX) {
        directionX = directionX.rotate(convertedMatrix.rotation)
    }

    if (directionY) {
        directionY = directionY.rotate(convertedMatrix.rotation)
    }

    const getResizeByDirection = (direction: Vector2d): number => {
        const scaleDirection = translate.projectionOn(direction)
        // > 0 - увеличение размера, < 0 - уменьшение
        const sign = direction.sameDirection(scaleDirection) ? 1 : -1

        return scaleDirection.length * sign
    }

    let sx = directionX ? getResizeByDirection(directionX) : 0
    let sy = directionY ? getResizeByDirection(directionY) : 0

    // Отражение не поддерживается
    if (bounds.width + sx < 0) sx = -bounds.width
    if (bounds.height + sy < 0) sy = -bounds.height

    const resizeVector = new Vector2d(sx, sy)

    return resizeVector
}

export function getMoveForAction(bounds: RectBounds, controllerType: ControllerType, translate: Vector2d): Vector2d {
    // Вращение не оказывает влияние на перемещение
    if (controllerType === ROTATION) return new Vector2d()

    if (controllerType === CENTER) {
        // Простое перемещение без нюансов
        return translate
    }

    // Остальные обработчики влияют на размер фигуры и, возможно, на перемещение

    const resizeVector = getResizeForAction(bounds, controllerType, translate)

    const convertedMatrix = convertMatrix(bounds.matrix)

    // Некоторые обработчики могут работать в двух направления.
    // Окончательный эффект определяется суммой по этим направлениям.
    let { directionX, directionY} = getControllerResizeDirections(controllerType)

    if (directionX) {
        directionX = directionX.rotate(convertedMatrix.rotation)
    }

    if (directionY) {
        directionY = directionY.rotate(convertedMatrix.rotation)
    }

    // Некоторый обработчики оказывают воздействие на перемещение элемента при изменении размера
    let tx = 0
    let ty = 0

    const repositionByDirection = (direction: Vector2d, resize: number): void => {
        const projectionOnDirection = translate
            .projectionOn(direction)
            .normalize()
            .multiplyByScalar(Math.abs(resize))
        tx += projectionOnDirection.x
        ty += projectionOnDirection.y
    }

    if ([TOP_LEFT, LEFT, BOTTOM_LEFT].indexOf(controllerType) > -1 && resizeVector.x !== 0) {
        repositionByDirection(directionX, resizeVector.x)
    }

    if ([TOP_LEFT, TOP, TOP_RIGHT].indexOf(controllerType) > -1 && resizeVector.y !== 0) {
        repositionByDirection(directionY, resizeVector.y)
    }

    const reposition = new Vector2d(tx, ty)

    return reposition
}

export function getRotationForAction(bounds: RectBounds, controllerType: ControllerType, translate: Vector2d): number {
    // Только обработчик вращения
    if (controllerType !== ROTATION) return 0

    const convertedMatrix = convertMatrix(bounds.matrix)
    const handlePosition = getControllerPositionInRectangle(controllerType, bounds.width, bounds.height, 'center')
        .rotate(convertedMatrix.rotation)

    const translateVector = handlePosition.add(translate)
    const dangle = handlePosition.angleFrom(translateVector)

    return dangle
}
