import { ValidationResult, Validator } from '../form-validation'
import { t } from 'i18next'

export const DEFAULT_MAX_LENGTH: number = 255

export interface LengthValidatorOptions {
    min?: number
    max?: number
    fixed?: number
    anyOf?: number[]
    minError?: string
    maxError?: string
    fixedError?: string
    anyOfError?: string
}

export const fieldLengthValidator = (options: LengthValidatorOptions): Validator =>
    (value: any): ValidationResult => {

    if (!value && value !== 0) {
        return { valid: true }
    }

    let length
    if (typeof value === 'number') {
        length = String(value).length
    } else {
        length = value.length
    }

    let error: string

    if (options.min !== undefined && length < options.min) {
        error = options.minError || t('validation.minLengthError', { count: options.min })
    } else if (options.max !== undefined && length > options.max) {
        error = options.maxError || t('validation.maxLengthError', { count: options.max })
    } else if (options.fixed !== undefined && length !== options.fixed) {
        error = options.fixedError || t('validation.fixedLengthError', { count: options.fixed })
    } else if (options.anyOf !== undefined && options.anyOf.length > 0 && options.anyOf.indexOf(length) === -1) {
        error = options.anyOfError || t('validation.anyOfLengthError', { counts: options.anyOf.
            join(', ').
            replace(/, (?=[^, ]*$)/, t('validation.or'))})
    }

    return {
        error,
        valid: !error
    }
}
