import { ValidationResult, Validator } from '../form-validation'
import { t } from 'i18next'

export const regExpValidator = (regExp: RegExp): Validator => {
    return (value: string | number): ValidationResult => {
        if (!value && value !== 0) return { valid: true }

        let stringValue: string = String(value)

        if (!regExp.test(stringValue)) {
            return {
                valid: false,
                error: t('validation.doesNotMatchRegExp')
            }
        } else {
            return {
                valid: true
            }
        }
    }
}

// Строка может содержать '.' и '-', но не может с них начинаться или заканчиваться
export const textWithDotsValidator = (value: string): ValidationResult => {
    const regExpCompare = regExpValidator(/^[\wа-яА-ЯёЁ]+[\wа-яА-ЯёЁ\-\. ]*[\wа-яА-ЯёЁ]+$/)
    return regExpCompare(value ? value.trim() : value)
}
