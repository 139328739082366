import React from 'react'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { flattenDeep } from 'lodash'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'

import { APP_STORE, ACTION_SETTINGS_STORE } from '../../../../../../store/stores'
import { AppStore } from '../../../../../../store/app-store'
import { ActionSettingsStore } from '../../../../../../store/actions/action-settings-store'
import { iTopologyManagerLocal } from '../../../../../../../protocol/set10/i-topology-manager-local'
import { FormatVO } from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import {
    TopologyCondition, createTopologyCondition
} from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/topology-condition'

import { TopologyPanels } from './topology-panels'
import { TreeItem } from '../../../../../../../components/tree-view/tree-view'
import { ShopVO } from '../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'

const styles = require('./affected-zone-table.scss')

// Пересобирает топологию. Все регионы и города впревращает в Магазины
export const formatTopologyConditions = (topologyConditions: TopologyCondition[], topologyMap: TreeItem[]): TopologyCondition[] => {
    const shopsFromSelectedRegions: ShopVO[] = flattenDeep<ShopVO>(topologyMap?.filter(region =>
        topologyConditions?.[0]?.shopsRegionGroups?.some(_region =>
            _region.regions?.[0]?.id === region.item.id)).map(region => region.children?.map(city => city.children.map(shop => shop.item as ShopVO))))
    const shopsFromSelectedCities: ShopVO[] = flattenDeep<ShopVO>(topologyMap?.map(region =>
        region.children?.filter(city =>
            topologyConditions?.[0]?.shopsCityGroups?.some(_city => city.item.id === _city?.cities?.[0]?.id)).map(city =>
                city.children.map(shop => shop.item as ShopVO))))

    const newTopologyConditions: TopologyCondition[] = [createTopologyCondition({
        ...topologyConditions?.[0],
        shops: [...(topologyConditions?.[0]?.shops || []), ...shopsFromSelectedCities.concat(shopsFromSelectedRegions)],
        shopsRegionGroups: [],
        shopsCityGroups: [],
    })]

    return newTopologyConditions
}

const getAllShopsCount = (topologyMap: TreeItem[]): number =>
    flattenDeep<ShopVO>(topologyMap?.map(region => region.children?.map(city => city.children?.map(shop => shop.item as ShopVO)))).length

interface AffectedZoneTableProps {
    app?: AppStore
    actionSettingsStore?: ActionSettingsStore
}

interface AffectedZoneTableState {
    selectedFormats: FormatVO[]
    searchString: string
    topologyMap: TreeItem[]
}

@inject(APP_STORE)
@inject(ACTION_SETTINGS_STORE)
@observer
export class AffectedZoneTable extends React.Component<AffectedZoneTableProps, AffectedZoneTableState> {

    constructor(props: AffectedZoneTableProps, context: any) {
        super(props, context)

        const selectedFormats: FormatVO[] = props.app.topologyMap?.formats || []
        const topologyMap = this.getTopologyMap(selectedFormats)

        this.state = { selectedFormats, topologyMap, searchString: '' }
    }

    getTopologyConditions = (): TopologyCondition[] =>
        toJS(this.props.actionSettingsStore?.editedAction?.topologyConditions) || null

    getTopologyMap = (formats: FormatVO[]): TreeItem[] =>
        this.props.app?.topologyMap?.toTree(formats, false)

    handleFormatsChange = (newFormats: number[]): void => {
        const { topologyMap } = this.props.app
        const formats = topologyMap?.formats?.filter(format => newFormats.includes(format.id))

        this.setState({ selectedFormats: formats })
    }

    render() {
        const { app, actionSettingsStore } = this.props
        const {
            selectedFormats,
            searchString,
            topologyMap,
        } = this.state
        const { editingTopologyConditions } = actionSettingsStore
        const topologyConditions: TopologyCondition[] = formatTopologyConditions(toJS(editingTopologyConditions), topologyMap)
        const selectedShopsCount = topologyConditions?.[0]?.shops?.length
        const allShopsCount = getAllShopsCount(topologyMap)

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div id="topologyCondFormatSelect" style={{ padding: '0 16px'}}>
                    <SelectInput
                        multiple
                        slim
                        id="topologyCondFormatSelect2"
                        label={t('topology.shopsFormat')}
                        options={app?.topologyMap?.formats?.map(format => ({ label: format.name, value: format.id }))}
                        value={selectedFormats.map(format => format.id)}
                        onMultipleSelect={selectedOption => {
                            const newValue = selectedOption.map(o => o.value)

                            this.handleFormatsChange(newValue)
                        }}
                    />
                </div>

                <div style={{ padding: '0 16px'}}>
                    <Box mt={2}>
                        <FilterInput
                            id="shopsSearchInput"
                            placeholder={t('advertisingActions.affectedZoneDialog.searchByNameOrNumber')}
                            slim
                            value={searchString}
                            onValueChange={filter => this.setState({ searchString: filter })}
                        />
                    </Box>
                    <span id="topologyShopsCountLabel" className={styles.hint}>
                        <span>{t('advertisingActions.affectedZoneDialog.all')} {allShopsCount}; </span>
                        <span>{t('advertisingActions.affectedZoneDialog.choosen')} {selectedShopsCount}</span>
                    </span>
                </div>

                <div style={{ flex: 1, overflow: 'auto' }}>
                    <Box mt={1}>
                        <TopologyPanels
                            topologyConditions={topologyConditions}
                            topologyMap={topologyMap}
                            topologySearchString={searchString}
                            selectedFormats={selectedFormats}
                            onConditionChange={(condition: TopologyCondition[]) =>
                                this.props.actionSettingsStore?.modifyTopologyConditions(condition)
                            }
                        />
                    </Box>
                </div>
            </div>
        )
    }
}
