import { observable, action, runInAction, computed, toJS } from 'mobx'
import { t } from 'i18next'
import { UserStore } from '../../user-store'
import { getStore } from '../../stores-repository'
import {
    USER_STORE, SNACKBAR_STORE, APP_STORE, CASH_DEVICES_STORE, APP_BAR_STORE
} from '../../stores'
import { SnackbarStore } from '../../snackbar-store'
import { AppStore } from '../../app-store'
import { EquipmentModelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { iEquipmentManagerLocal } from '../../../../protocol/set10/i-equipment-manager-local'
import { withSpinner } from '../../with-spinner'
import {
    AllModelsByGroup, RegisteredModelsByGroup, EquipmentModel, CASH_CLASS, KEYBOARD_NAME
} from '../../../core/cash-devices/cash-devices-utils'
import { goTo, RouteChangeHandler } from '../../../utils/router-util'
import { CASH_MODULE, DEVICES, REGISTER } from '../../../core/app-routes'
import { LEFT_ARROW, AppBarStore } from '../../app-bar-store'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { isEqual } from 'lodash'

const findIndexByName = (array: EquipmentModel[], item: EquipmentModel) => {
    return array.findIndex(arrayItem => {
        return arrayItem.name === item.name &&
        arrayItem.equipmentType.name === item.equipmentType.name
    })
}

export class CashDevicesStore {

    @observable
    models: EquipmentModelVO[]

    @observable
    registeredModels: EquipmentModelVO[]

    @observable
    originalRegisteredModels: EquipmentModelVO[]

    @observable
    filterString: string = ''

    @observable
    registerGroup: string = ''

    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    @computed
    get modified(): boolean {
        if (!this.registeredModels) return false

        // Упрощенное сравнение, чтобы не прогонять isEqual
        if (this.registeredModels.length !== this.originalRegisteredModels.length) return true

        let currentModels = {}
        let originalModels = {}

        this.registeredModels.forEach(model => {
            currentModels[model.name] = true
        })

        this.originalRegisteredModels.forEach(model => {
            originalModels[model.name] = true
        })

        return !isEqual(currentModels, originalModels)
    }

    /**
     * Структура для отображения оборудования
     * На первом уровне идет объект, где каждый параметр = имя группы
     * Каждая группа это объект, где каждый параметр = имя модели
     * В этом объекте находится массив с моделями, все из которых имеют одинаковое имя
     *
     * Это необходимо, т.к. клавиатуры могут быть зарегестрированы с несколькими раскладками
     * В нашей таблице они будут представлены одним рядом таблицы, в данных сервера это разные объекты
     * (У большинства элементом в массиве будет 1 элемент, у клавиатур может быть больше)
     */
    @computed
    get registeredModelsByGroup(): RegisteredModelsByGroup {
        if (!this.registeredModels) return null

        let groups: RegisteredModelsByGroup = new Map()

        this.registeredModels.forEach(model => {
            const group = model.equipmentType

            // Создаем объект группы
            if (!groups.has(group.name)) {
                groups.set(group.name, new Map())
            }
            const groupObject = groups.get(group.name)

            // Создаем объект модели
            if (!groupObject.has(model.name)) {
                groupObject.set(model.name, [])
            }

            groupObject.get(model.name).push(model)
        })

        return groups
    }

    /**
     * Преобразует в структуру аналогично registeredModelsByGroup
     * Но уже все модели доступные (плюс в них проставлен флаг .registered)
     * Но (!) и клавиатуры не будут дублироваться
     * У клавиатур будет свойство ids: number[], если их несколько
     */
    @computed
    get allModelsByGroup(): AllModelsByGroup {
        if (!this.getFilteredModels) return null

        let groups: AllModelsByGroup = new Map()

        this.getFilteredModels.forEach(model => {
            const group = model.equipmentType

            // Создаем объект группы
            if (!groups.has(group.name)) {
                groups.set(group.name, new Map())
            }
            const groupObject = groups.get(group.name)

            groupObject.set(model.name, model)
        })

        return groups
    }

    @computed
    get getFilteredModels(): EquipmentModel[] {
        if (!this.models || !this.registeredModels) return []

        // Сначала собираем объект, который хранит все модели, а уже зарегистрированные помечены
        const allModelsWithRegistered = this.models.map(item => {
            const deviceModel: EquipmentModel = { ...item, registered: false, ids: null }
            const foundItemIndex = findIndexByName(this.registeredModels, item)

            if (foundItemIndex !== -1) {
                // Пометим как уже зарегистрированное
                deviceModel.registered = true
                // В списке всех моделей нет айдишников, добавляем айдишники уже зарегистрированных сущностей
                deviceModel.id = this.registeredModels[foundItemIndex].id

                if (deviceModel.equipmentType.name === KEYBOARD_NAME) {
                    // Клавиатуры могут иметь несколько экземпляров
                    const keyBoards = this.registeredModels.filter(registered => {
                        return registered.name === item.name
                            && registered.equipmentType.name === item.equipmentType.name
                    })

                    const keyBoardsIds = keyBoards.map(keyBoard => keyBoard.id)

                    if (keyBoardsIds.length > 1) {
                        deviceModel.ids = keyBoardsIds
                    }
                }
            }
            return deviceModel
        })

        const filterString = this.filterString.trim().toLowerCase()
        if (filterString === '') {
            return allModelsWithRegistered
        }

        // Фильтруем поисковой строкой
        const filteredModels: EquipmentModel[] = allModelsWithRegistered.filter(model => {
            const modelName = model.localizedName.toLowerCase()
            // Нашли в имени модели - добавляем
            return modelName.indexOf(filterString) !== -1
        })

        return filteredModels
    }

    fetchAllData = async (): Promise<void> => {
        // Спиннер показываем, только если список пустой
        if (!this.models || !this.registeredModels) {
            await withSpinner([
                this.fetchCashRegisteredModels(),
                this.fetchCashModels()
            ])
        } else {
            await Promise.all([
                this.fetchCashRegisteredModels(),
                this.fetchCashModels()
            ])
        }
    }

    fetchCashModels = async (): Promise<void> => {
        const models = await iEquipmentManagerLocal.getAllEquipmentModelsOfClass(
            this.userStore.session, CASH_CLASS, this.appStore.locale, { useCache: true }
        )
        runInAction(() => {
            this.models = models || []
        })
    }

    fetchCashRegisteredModels = async (): Promise<void> => {
        const registeredModels = await iEquipmentManagerLocal.getAllRegisteredEquipmentModelsOfClass(
            this.userStore.session, CASH_CLASS, this.appStore.locale
        )
        runInAction(() => {
            this.registeredModels = registeredModels || []
            this.originalRegisteredModels = registeredModels || []
        })
    }

    // Метод для удаления из экрана со списком уже зарегестрированных моделей (где можно войти в редактирование клавиатур)
    removeSingleRegisteredModel = async (model: EquipmentModel): Promise<void> => {
        await withSpinner(async () => {
            const result = await iEquipmentManagerLocal.unRegisterEquipment(this.userStore.session, model.id)
            await this.fetchCashRegisteredModels()

            if (result) {
                this.snackbarStore.show({message: t('cashDevices.deviceRemoved', {model: model.localizedName})})
            }
        })
    }

    toggleRegisteredState = (model: EquipmentModel): void => {
        if (model.registered) {
            if (model.ids && model.ids.length > 1) {
                this.showRemoveKeyboardDialog(model)
            } else {
                this.removeModels(model)
            }
        } else {
            this.addModels(model)
        }
    }

    showRemoveKeyboardDialog = (model: EquipmentModel): void => {
        this.appStore.showDialog({
            title: t('cashDevices.keyboardDialogTitle'),
            message: t('cashDevices.keyboardDialogMessage'),
            mode: DIALOG,
            onYes: () => this.removeModels(model)
        })
    }

    @action
    addModels = (model: EquipmentModel): void => {
        // Если клавиатур было несколько - надо вернуть данные о всех id
        if (model.equipmentType.name === KEYBOARD_NAME) {
            const sameModels = this.originalRegisteredModels.filter(originalModel => {
                return originalModel.name === model.name
                    && originalModel.equipmentType.name === KEYBOARD_NAME
            })

            // Если в originalRegistered были уже эти клавиатуры - возвращаем их
            if (sameModels.length > 0) {
                sameModels.forEach(keyboardModel => {
                    this.registeredModels.push(keyboardModel)
                })
                return
            }
        }

        // Добавляем в зарегистрированные модели
        this.registeredModels.push(model)
    }

    @action
    removeModels = (model: EquipmentModel): void => {
        // Если это клавиатура, то может быть несколько зарегестрированных элементов
        const modelIds = model.ids || [model.id]

        modelIds.forEach(id => {
            // Удаляем из registered
            const foundItemIndex = findIndexByName(this.registeredModels, model)
            if (foundItemIndex !== -1) {
                this.registeredModels.splice(foundItemIndex, 1)
            }
        })
    }

    saveNewModels = async (): Promise<void> => {
        await withSpinner(async () => {
            for (const model of this.registeredModels) {
                // Ищем элементы, которых не было в оригинальных моделях -> их надо добавить
                const equipmentType = model.equipmentType
                const classType = equipmentType.equipmentClass.name
                const typeName = equipmentType.name
                const modelName = model.name

                const wasRegistered = this.originalRegisteredModels.some(originalModel => {
                    return originalModel.name === modelName
                        && originalModel.equipmentType.name === typeName
                })

                if (!wasRegistered) {
                    await iEquipmentManagerLocal.registerAndGetModel(
                        this.userStore.session,
                        classType,
                        typeName,
                        modelName,
                        this.appStore.locale
                    )
                }
            }

            for (const model of this.originalRegisteredModels) {
                // Ищем элементы, которых уже нет в registered -> их надо удалять
                const typeName = model.equipmentType.name
                const modelName = model.name

                const isRegistered = this.registeredModels.some(registeredModel => {
                    return registeredModel.name === modelName
                        && registeredModel.equipmentType.name === typeName
                })

                if (!isRegistered) {
                    await iEquipmentManagerLocal.unRegisterEquipment(this.userStore.session, model.id)
                }
            }

            await this.fetchCashRegisteredModels()
        })
        this.snackbarStore.show({message: t('cashDevices.deviceAdded')})
    }

    @action
    resetRegisterState = (): void => {
        this.registeredModels = this.originalRegisteredModels
        this.filterString = ''
        this.registerGroup = ''
    }

    @action
    cancelRegisteringModels = (): void => {
        this.resetRegisterState()
        goTo(`${CASH_MODULE}${DEVICES}`)
    }

    updateNavMenu = (): void => {
        this.appBarStore.updateState({
            title: t('set10.cashDevicesRegister'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: this.cancelRegisteringModels
        })
    }

    @action
    setFilterString = (value: string): void => {
        this.filterString = value
    }

    @action
    setRegisterGroup = (value: string): void => {
        this.registerGroup = value
    }

    @action
    reset = (): void => {
        this.models = null
        this.registeredModels = null
        this.originalRegisteredModels = null
        this.filterString = ''
        this.registerGroup = ''
    }
}

export const CASH_DEVICES_REGISTER_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${CASH_MODULE}${DEVICES}${REGISTER}$`),
    onEnter: () => {
        const cashDevicesStore: CashDevicesStore = getStore(CASH_DEVICES_STORE)
        cashDevicesStore.updateNavMenu()
    },
    onLeave: () => {
        const cashDevicesStore: CashDevicesStore = getStore(CASH_DEVICES_STORE)
        cashDevicesStore.resetRegisterState()
    }
}
