import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { ListInput } from '@crystalservice/crystals-ui/lib/components/inputs/list-input/list-input'
import { ActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/action-panel'
import { PRESENT_CARDS_STORE, USER_STORE } from '../../../../store/stores'
import { PresentCardsStore } from '../../../../store/cards/present-cards-store'
import { PresentCardsListTable } from './present-cards-list-table'
import { Create } from '../../../../core/cards/card-constants'
import { formatCardNumber } from '../../../../core/cards/cards-util'
import { ActivateByRangeDialog } from './activate-by-range-dialog'
import { CARDS_PRESENT_CARDS_ACTIVATION_PRIVILEGE } from '../../../../core/privileges/privileges'
import { UserStore } from '../../../../store/user-store'

export enum CardsSearchType {
    ByOne = 'BY_ONE',
    ByList = 'BY_LIST',
}

const getPresentCardSearchTypeOpts = (): DefaultSelectOption[] => [
    { value: CardsSearchType.ByOne, label: t('presentCards.list.searchType.byOne') },
    { value: CardsSearchType.ByList, label: t('presentCards.list.searchType.byList') },
]

interface PresentCardsListProps {
    presentCardsStore?: PresentCardsStore
    user?: UserStore
}

interface PresentCardsListState {
    searchType: CardsSearchType
    filterString: string
    listValue: string
    lastSearchType: CardsSearchType
    isActivateByRangeDialogOpen: boolean
}

@inject(PRESENT_CARDS_STORE)
@inject(USER_STORE)
@observer
export class PresentCardsList extends React.Component<PresentCardsListProps, PresentCardsListState> {
    state: PresentCardsListState = {
        searchType: CardsSearchType.ByOne,
        filterString: '',
        listValue: '',
        lastSearchType: CardsSearchType.ByOne,
        isActivateByRangeDialogOpen: false,
    }

    render() {
        const {
            setCardNumberAndFetch,
            resetSearchData,
            selectedCards,
            activateSelectedCards,
            fetchCardsByNumbersList,
            fetchCardsByNumber,
            setIsActivationSuccesful,
        } = this.props.presentCardsStore

        const {
            searchType,
            filterString,
            listValue,
            lastSearchType,
            isActivateByRangeDialogOpen,
        } = this.state

        const gotCardsToActivate = selectedCards.some(item => item.status === Create)

        const { havePrivilege } = this.props.user
        const canActivate: boolean = havePrivilege(CARDS_PRESENT_CARDS_ACTIVATION_PRIVILEGE)

        return (
            <>
                <Paper style={{ marginBottom: 8, padding: 16 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <SelectInput
                                id="presentCardsSearchTypeSelect"
                                label={t('presentCards.list.search')}
                                value={searchType}
                                options={getPresentCardSearchTypeOpts()}
                                onSelect={opt => {
                                    this.setState({
                                        searchType: opt.value as CardsSearchType,
                                    }, () => {
                                        resetSearchData()
                                    })
                                }}
                            />
                        </Grid>

                        {searchType === CardsSearchType.ByOne && (
                            <Grid item xs={9}>
                                <FilterInput
                                    id="presentCardsSearchInput"
                                    placeholder={t('presentCards.list.cardsNumber')}
                                    value={formatCardNumber(filterString)}
                                    onValueChange={filterString => {
                                        const regExp = /^[\d ]*$/
                                        if (!regExp.test(filterString)) return

                                        this.setState({ filterString })
                                    }}
                                    onConfirm={filterString => {
                                        this.setState({ lastSearchType: CardsSearchType.ByOne })
                                        setCardNumberAndFetch(filterString)
                                    }}
                                    searchIconPosition="start"
                                />
                            </Grid>
                        )}

                        {searchType === CardsSearchType.ByList && (
                            <Grid item xs={9}>
                                <ListInput
                                    label={t('presentCards.list.searchList')}
                                    showAutocomplete
                                    getAppendedSuggestionText={shop => shop.toString()}
                                    getEntryTypeText={count => t('components.listInput.entryType.card', {count})}
                                    onChange={newValue => {
                                        this.setState({
                                            listValue: newValue,
                                            lastSearchType: CardsSearchType.ByList
                                        })
                                        fetchCardsByNumbersList(newValue)
                                    }}
                                    CustomView={props => (
                                        <Button color="primary" onClick={props.onClick}>
                                            <AddIcon /> {t('presentCards.list.searchList')}
                                        </Button>
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Paper>

                <Paper style={{ flex: 1 }}>
                    <PresentCardsListTable disabled={!canActivate}/>
                </Paper>
                {canActivate && (
                <ActionPanel color="secondary">
                    <Button
                        color="primary"
                        onClick={() => this.setState({ isActivateByRangeDialogOpen: true })}
                    >
                        {t('presentCards.activateByRangeDialog.openButton')}
                    </Button>

                    <div style={{ flex: 1 }}/>

                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!gotCardsToActivate}
                        onClick={async () => {
                            await activateSelectedCards()

                            if (lastSearchType === CardsSearchType.ByOne) {
                                fetchCardsByNumber(filterString)
                            } else {
                                fetchCardsByNumbersList(listValue)
                            }
                        }}
                    >
                        {t('presentCards.list.activateSelected')}
                    </Button>
                </ActionPanel>
                )}
                <ActivateByRangeDialog
                    open={isActivateByRangeDialogOpen}
                    onClose={() => {
                        this.setState({ isActivateByRangeDialogOpen: false })
                        setIsActivationSuccesful(false)
                    }}
                />
            </>
        )
    }
}
