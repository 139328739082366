/**
 * Хранилище сторов, для разрыва зависимостей es6 модулей
 * @type {Map<string, any>}
 */
import { config, TEST } from '../config/config'

const storesRepository: Map<string, any> = new Map<string, any>()

export const getStore = <T>(store: string): T => {
    if (!storesRepository.has(store)) {
        throw new Error(`No store in repository with name: ${store}, check initialization pipeline`)
    }
    return storesRepository.get(store)
}

export const putStore = <T>(store: string, instance: T): T => {
    if (storesRepository.has(store) && config.environment !== TEST) {
        // Сейчас архитектура не предусматривает замену  стора на лету
        throw new Error(`Duplicate creation for store: ${store}`)
    }
    storesRepository.set(store, instance)
    return instance
}

export const clearRepository = (): void => {
    if (config.environment !== TEST) {
        throw new Error('Repository clearing is only for test purposes')
    }
    storesRepository.clear()
}
