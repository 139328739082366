import React from 'react'
import { FilterType, ACTION_COLOR } from '../../../core/filters/filter'
import { BaseSelectFilter } from '../components/base-select-filter'
import { InternalFilterComponentProps } from '../new-filters'
import { getColorsAsOptions } from '../../../core/color-utils'
import { DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import classNames from 'classnames'

const styles = require('./action-color.scss')

export class ActionColor extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_COLOR

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <BaseSelectFilter
                id={id}
                defaultValue={value}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                filterType={ActionColor.filterType}
                options={getColorsAsOptions()}
                slim={slim}
                labelFunction={(item: DefaultSelectOption): any => {
                    return (
                        <div className={styles.selectOption}>
                            <div
                                className={classNames(
                                    styles.colorContainer,
                                    styles[item.value]
                                )}
                            />
                            <div className={styles.selectLabel}>{item.label}</div>
                        </div>
                    )
                }}
            />
        )
    }
}
