import { action, observable, runInAction } from 'mobx'
import { Check } from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { NON_FISCAL_DOCUMENTS, OPERDAY } from '../../core/app-routes'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { APP_BAR_STORE, NON_FISCAL_DOCUMENT_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { t } from 'i18next'
import { cloneDeep, isNil } from 'lodash'
import { fromServerToClientTime } from '../../utils/app-util'
import { withSpinner } from '../with-spinner'
import { checkManagerLocal } from '../../../protocol/set10/check-manager-local'
import { UserStore } from '../user-store'
import { openReport } from '../../core/operday/report-utils'

export class NonFiscalDocumentStore {
    @observable
    document: Check
    @observable
    documentId: number
    private userStore: UserStore = getStore(USER_STORE)

    @action
    reset = (): void => {
        this.document = undefined
        this.documentId = undefined
    }

    @action
    openDocument = async (document: Check): Promise<void> => {
        document = cloneDeep(document)
        this.documentId = document.id

        if (document.date) {
            document.date = fromServerToClientTime(document.date)
        }

        const result = await withSpinner(checkManagerLocal.findCheck(this.userStore.session, document))

        runInAction(() => {
            if (!result) return

            this.document = result
            // Для нефискального документа может прийти date === null
            if (!isNil(this.document.date)) {
                if (!(this.document.date instanceof Date)) {
                    // unixtime -> Date
                    this.document.date = new Date(this.document.date)
                }
                this.document.date = fromServerToClientTime(this.document.date)
            }
        })

        goTo(`${OPERDAY + NON_FISCAL_DOCUMENTS}/${document.id}`)
    }

    printNonFiscalDocument = (): void => {
        openReport('GoodsCheque', null, {['PURCHASE_ID']: this.documentId})
    }
}

export const NON_FISCAL_DOCUMENT_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${OPERDAY}${NON_FISCAL_DOCUMENTS}/[\\w-]+/?$`),
    onEnter: () => {
        const nonFiscalDocumentStore: NonFiscalDocumentStore = getStore(NON_FISCAL_DOCUMENT_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        let documentNumber: string = '?'
        if (nonFiscalDocumentStore.document) {
            documentNumber = nonFiscalDocumentStore.document.number.toString()
        } else {
            // Вызов goTo приводит к изменению routing.location.pathname, что должно триггерить авторан, следящий за роутами.
            // Этого не происходит, если авторан уже в стеке вызова.
            // Поэтому для подобного редиректа можно переносить изменения роута на следующий таск.
            setTimeout(() => goTo(`${OPERDAY}${NON_FISCAL_DOCUMENTS}`), 0)
            return
        }

        let leftIconClick = () => {
            goTo(`${OPERDAY}${NON_FISCAL_DOCUMENTS}`)
        }

        appBarStore.updateState({
            title: `${t('nonFiscalDocuments.nonFiscalDocument')} №${documentNumber}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: leftIconClick
        })
    }
}
