import React from 'react'
import { observable, action, runInAction, toJS } from 'mobx'
import sortBy from 'lodash/sortBy'
import { t } from 'i18next'
import { UserStore } from '../user-store'
import { SnackbarStore } from '../snackbar-store'
import { getStore } from '../stores-repository'
import { USER_STORE, SNACKBAR_STORE, APP_STORE } from '../stores'
import { externalSystemsManagerRemote } from '../../../protocol/set10/external-systems-manager-remote'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { getUniqueNameWithNumberPostfix2, postfixRegexWithBrackets } from '../../../utils/name-util'
import { AppStore } from '../app-store'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import { BankVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/bank-vo'

export class BanksStore {
    @observable
    allBanks: BankVO[] = null

    @observable
    banks: BankVO[] = null

    @observable
    bankTemplate: BankVO

    @observable
    addBankDialogOpened: boolean = false

    @observable
    deleteBankDialogOpened: boolean = false

    @observable
    validation: FormValidation<BankVO> = null

    @observable
    bankIdForDelete: number = null

    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    @action
    updateBankName = (id: number, registeredName: string): void => {
        const banks = toJS(this.banks)
        const bankIndex = banks.findIndex(bank => bank.id === id)

        banks[bankIndex].registeredName = registeredName
        this.banks = this.sortBanks(banks)
    }

    @action
    deleteFromBanks = (id: number): void => {
        const banks = toJS(this.banks)
        const bankIndex = banks.findIndex(bank => bank.id === id)

        banks.splice(bankIndex, 1)
        this.banks = banks
    }

    @action
    addToBanks = (bank: BankVO): void => {
        const banks = toJS(this.banks)

        banks.push(bank)
        this.banks = this.sortBanks(banks)
    }

    @action
    updateBankTemplate = (changes: Partial<BankVO>): void => {
        Object.keys(changes).forEach(key => {
            this.bankTemplate[key] = changes[key]
        })
    }

    // Получение списка зарегистрированных банков
    fetchRegisteredBanks = async (): Promise<void> => {
        const banks = await externalSystemsManagerRemote.getRegisteredBanks(this.userStore.session, this.appStore.locale) || []
        runInAction(() => {
            this.banks = this.sortBanks(banks)
        })
    }

    // Получение списка всех доступных банков
    fetchAllBanks = async (): Promise<void> => {
        const allBanks = await externalSystemsManagerRemote.getAllBanks(this.userStore.session, this.appStore.locale) || []
        runInAction(() => {
            this.allBanks = allBanks
        })
    }

    // Удаление банка из зарегистрированных
    unregisterBank = async (bankId: number): Promise<BankVO> => {
        return externalSystemsManagerRemote.unregisterBank(this.userStore.session, bankId, this.appStore.locale)
    }

    // Регистрация банка
    registerBank1 = async (bankName: string): Promise<BankVO> => {
        return externalSystemsManagerRemote.registerBank1(this.userStore.session, bankName, this.appStore.locale)
    }

    // Сохранение настроек банка
    updateBank = async (bank: BankVO): Promise<BankVO> => {
        return externalSystemsManagerRemote.updateBank(this.userStore.session, bank, this.appStore.locale)
    }

    editName = async (template: BankVO, name: string) => {
        const data = toJS(template)
        data.registeredName = name
        try {
            const updatedData = await this.updateBank(data)
            if (updatedData.registeredName === data.registeredName) {
                this.snackbarStore.show({
                    message: t('banks.bankSavedMessage', { name }),
                })
            } else {
                this.snackbarStore.show({
                    message: t('banks.bankSavedError'),
                })
            }
            this.updateBankName(updatedData.id, updatedData.registeredName)
        } catch (err) {
            this.snackbarStore.show({
                message: t('banks.bankSavedError'),
            })
        }
    }

    openAddBankDialog = (): void => {
        this.createDefaultBankTemplate()
        this.createValidation()
        runInAction(() => {
            this.addBankDialogOpened = true
        })
    }
    closeAddBankDialog = (): void => {
        runInAction(() => {
            this.addBankDialogOpened = false
        })
    }

    openDeleteBankDialog = (bankId: number): void => {
        runInAction(() => {
            this.deleteBankDialogOpened = true
            this.bankIdForDelete = bankId
        })
    }
    closeDeleteBankDialog = (): void => {
        runInAction(() => {
            this.deleteBankDialogOpened = false
            this.bankIdForDelete = null
        })
    }

    deleteBank = async (): Promise<void> => {
        try {
            const deletedBank = await this.unregisterBank(this.bankIdForDelete)
            this.deleteFromBanks(this.bankIdForDelete)
            this.snackbarStore.show({
                message: t('banks.bankDeletedMessage', { name: deletedBank.registeredName }),
            })
        } catch (e) {
            this.snackbarStore.show({
                message: t('banks.bankDeletedErrorMessage'),
            })
        }
        this.closeDeleteBankDialog()
    }

    addBank = async (): Promise<void> => {
        const {
            registeredName,
            name,
        } = this.bankTemplate
        const newBank = await this.registerBank1(name)
        this.addToBanks(newBank)
        this.editName(newBank, registeredName)
        this.closeAddBankDialog()
    }

    @action
    reset = (): void => {
        this.allBanks = null
        this.banks = null
        this.bankTemplate = null
        this.addBankDialogOpened = false
        this.deleteBankDialogOpened = false
        this.validation = null
        this.bankIdForDelete = null
    }

    @action
    private createValidation = (): void => {
        this.validation = new FormValidation<BankVO>(
            this.bankTemplate,
            [
                {
                    field: 'registeredName',
                    rules: [
                        requiredField,
                        uniqueField(this.banks.map(bank => bank.registeredName))
                    ]
                }, {
                    field: 'name',
                    rules: [
                        requiredField,
                    ]
                }
            ],
            false,
        )
    }

    @action
    private createDefaultBankTemplate = (): void => {
        this.bankTemplate = {
            registeredName: getUniqueNameWithNumberPostfix2(
                this.banks.map(t => t.registeredName),
                t('banks.defaultBankName'),
                postfixRegexWithBrackets
            ),
            name: 'sberbank',
            '@class': 'ru.crystals.setretailx.external.datatypes.banks.BankVO',
        }
    }

    private sortBanks = (banks: BankVO[]): BankVO[] => {
        return sortBy(banks, 'registeredName')
    }
}
