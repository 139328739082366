import { XMLTagBaseElement } from './xml-tag-base-element'
import { ALL_CUSTOM_ELEMENT_TYPES, XMLCustomElementType, BARCODE } from './xml-custom-element-type'

export interface XMLTagCustomElement extends XMLTagBaseElement {
    type?: XMLCustomElementType
}

export function isCustomElement(item: any): item is XMLTagCustomElement {
    if (!item) return false
    if ('type' in item && ALL_CUSTOM_ELEMENT_TYPES.includes(item.type)) return true
    return false
}

export function isLegacyBarcode(item: any) {
    return isCustomElement(item) && item.type === BARCODE
}
