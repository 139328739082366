import React, { CSSProperties } from 'react'
import classNames from 'classnames'

const styles = require('./timeline.scss')

interface TimelineItemProps {
    id: string
    icon?: JSX.Element
    label?: string
    secondaryText?: string
    itemLeft: number
    itemWidth: number
    className?: string
    innerStyle?: CSSProperties
    onDoubleClick?: () => void
    onShowInfoTooltip?: () => void
    onHideInfoTooltip?: () => void
}

export class TimelineItem extends React.Component<TimelineItemProps> {
    render() {
        const {
            id, icon, label, className, innerStyle, itemLeft, itemWidth, onDoubleClick, secondaryText,
            onShowInfoTooltip, onHideInfoTooltip
        } = this.props

        return (
            <div
                id={`item_${id}`}
                className={styles.timelineItem}
                style={{ paddingLeft: itemLeft }}
                >
                <div
                    className={styles.itemRealSizes}
                    style={{ width: itemWidth }}
                    onMouseEnter={() => onShowInfoTooltip()}
                    onMouseLeave={onHideInfoTooltip}
                >
                    {/** Элемент без текста, который отображает реальный размер даже во время ховера */}
                    <div className={styles.expandableBlock}>
                        <div className={classNames(styles.inner, className)} style={innerStyle}>
                        </div>
                    </div>
                    <div id={`itemInner_${id}`} className={styles.expandableBlock} onDoubleClick={onDoubleClick}>
                        <div className={classNames(styles.inner, className)} style={innerStyle}>
                            <div>
                                {icon}
                            </div>
                            <div className={styles.textArea}>
                                <p>{label}</p>
                                <p>{secondaryText}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
