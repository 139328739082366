import { RectBounds } from '../rect-bounds'
import { ControllerType, ROTATION } from '../hand-controller'
import { convertMatrix, Vector2d } from '../../../utils/math/geom-util'
import { BaseConstraint, EditorState } from './constraint'
import { normalizeAngle } from '../../../utils/math/math-util'
import { getRotationForAction } from '../editor-util'

export interface SnapToAngleConstraintOptions {
    angles: number[]
}

export class SnapToAnglesConstraint extends BaseConstraint<SnapToAngleConstraintOptions> {

    constructor(options: SnapToAngleConstraintOptions, scale: number = 1, active: boolean = false) {
        super(options, scale, active)
    }

    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d {
        if (!this.active || controller !== ROTATION) return translation

        let dangle = getRotationForAction(bounds, controller, translation)
        const convertedMatrix = convertMatrix(bounds.matrix)
        dangle = snapRotationToAngles(convertedMatrix.rotation, dangle, this.options.angles)

        const prevHandleDirection = bounds.getControllerPos(controller).subtract(bounds.center)
        const targetHandleDirection = prevHandleDirection.rotate(dangle)

        return targetHandleDirection.subtract(prevHandleDirection)
    }
}

export function snapRotationToAngles(rotation: number, dangle: number, snapAngles: number[], snapPrecision: number = Math.PI / 36): number {
    let targetAngle = rotation + dangle
    let fixedDAngle = dangle

    const normalizedRotation = normalizeAngle(rotation)
    for (let snapAngle of snapAngles) {
        const normalizedSnapAngle = normalizeAngle(snapAngle)
        const normalizedTargetAngle = normalizeAngle(targetAngle)

        if (Math.abs(normalizedSnapAngle - normalizedTargetAngle) <= snapPrecision) {
            fixedDAngle = normalizedSnapAngle - normalizedRotation
            break
        }

        if (Math.abs(normalizedSnapAngle + Math.PI * 2 - normalizedTargetAngle) <= snapPrecision) {
            fixedDAngle = normalizedSnapAngle - normalizedRotation
            break
        }
    }

    if (Math.abs(fixedDAngle) > Math.PI) {
        fixedDAngle -= Math.sign(fixedDAngle) * Math.PI * 2
    }

    return fixedDAngle
}
