import { createFormatsAbstractVO } from './formats-abstract-vo'
import { FormatsAbstractVO } from './formats-abstract-vo'
import { PriceTagFormatType } from './price-tag-format-type'
import { FormatsCellVO } from './formats-cell-vo'

export interface PriceTagFormatVO extends FormatsAbstractVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.PriceTagFormatVO'
    uuid?: string
    type?: PriceTagFormatType
    description?: string
    cells?: FormatsCellVO[]
    externalCode?: string
    defaultFormat?: boolean
    topologyName?: string
    markings?: string[]
}

export const createPriceTagFormatVO = (initialization: Partial<PriceTagFormatVO>): PriceTagFormatVO => {
    if (!initialization) return null
    
    let result: PriceTagFormatVO = {
        ...createFormatsAbstractVO(initialization),
        uuid: initialization.uuid,
        type: initialization.type,
        description: initialization.description,
        cells: initialization.cells,
        externalCode: initialization.externalCode,
        defaultFormat: initialization.defaultFormat,
        topologyName: initialization.topologyName,
        markings: initialization.markings,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.PriceTagFormatVO',
    }
    
    return result
}
