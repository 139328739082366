import React from 'react'
import { observable, action, runInAction, toJS, computed } from 'mobx'
import escapeRegExp from 'lodash/escapeRegExp'
import { t } from 'i18next'
import { UserStore } from '../user-store'
import { SnackbarStore } from '../snackbar-store'
import { getStore } from '../stores-repository'
import { USER_STORE, SNACKBAR_STORE, APP_STORE } from '../stores'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { CashExtensionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-extension-vo'
import { AppStore } from '../app-store'

export class NotLoadedToCashesStore {
    @observable
    cashes: CashExtensionVO[] = null

    @observable
    filter: string = ''

    @observable
    isResending: boolean = false

    @computed
    get filteredCashes(): CashExtensionVO[] {
        const { isCentrum } = this.app
        let cashes = toJS(this.cashes) || []
        const regExpStr = escapeRegExp(this.filter.toLowerCase())
        const regExp = new RegExp(regExpStr)
        cashes = cashes.filter(cash => {
            const shopNumber = cash.shopNumber ? cash.shopNumber.toString() : ''
            const address = cash.address ? cash.address.toLowerCase() : ''
            const cashNumber = cash.number ? cash.number.toString() : ''
            const template = cash.template ? cash.template.toLowerCase() : ''

            if (isCentrum) {
                return regExp.test(shopNumber + address)
            }
            return regExp.test(cashNumber + template)
        })

        return cashes
    }

    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private app: AppStore = getStore(APP_STORE)

    // загрузка касс с ошибками
    getCashesWithNotLoadedProducts = async (): Promise<void> => {
        const cashes = await iProductsManagerLocal.getCashesWithNotLoadedProducts(this.userStore.session) || []
        runInAction(() => {
            this.cashes = cashes
        })
    }

    // переотправка товаров
    resendFailedProducts = async (cashNumber: number): Promise<void> => {
        return iProductsManagerLocal.resendFailedProducts(this.userStore.session, cashNumber)
    }

    @action
    resendAll = async (): Promise<void> => {
        const cashNumbers = this.filteredCashes
            .filter(cash => cash.failedAmount > 0)
            .map(cash => cash.number)
        if (!cashNumbers.length) {
            return
        }
        this.isResending = true
        for (const cashNumber of cashNumbers) {
            try {
                await this.resendFailedProducts(cashNumber)
            } catch (e) {
                this.isResending = false
                this.snackbarStore.show({
                    message: t('notLoadedToCashes.failResend', {number: cashNumber}),
                })
            }
        }
        this.snackbarStore.show({
            message: t('notLoadedToCashes.successResend'),
        })
        this.isResending = false
        await this.getCashesWithNotLoadedProducts()
    }

    @action
    setFilter = (value: string): void => {
        this.filter = value
    }

    @action
    reset = (): void => {
        this.cashes = null
        this.filter = ''
        this.isResending = false
    }
}
