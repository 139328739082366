export interface SaleGroupVO {
    '@class': 'ru.crystals.pos.catalog.SaleGroupVO'
    id?: number
    code?: string
    name?: string
    amountOfProducts?: number
    scaleTemplates?: string[]
}

export const createSaleGroupVO = (initialization: Partial<SaleGroupVO>): SaleGroupVO => {
    if (!initialization) return null
    
    let result: SaleGroupVO = {
        id: initialization.id,
        code: initialization.code,
        name: initialization.name,
        amountOfProducts: initialization.amountOfProducts,
        scaleTemplates: initialization.scaleTemplates,
        '@class': 'ru.crystals.pos.catalog.SaleGroupVO',
    }
    
    return result
}
