import { observable, action, runInAction, computed, toJS } from 'mobx'
import { t } from 'i18next'
import { UserStore } from '../../user-store'
import { getStore } from '../../stores-repository'
import {
    USER_STORE,
    SNACKBAR_STORE,
    APP_STORE,
    APP_BAR_STORE,
    CASH_DEVICE_SETTINGS_STORE,
    CASH_DEVICES_STORE
} from '../../stores'
import { SnackbarStore } from '../../snackbar-store'
import { AppStore } from '../../app-store'
import { RouteChangeHandler, goTo } from '../../../utils/router-util'
import { CASH_MODULE, DEVICES, SETTINGS } from '../../../core/app-routes'
import {
    Xml2Js,
    isXml,
    keyboardKbd2Xml,
    fixXmlWithoutSpaces,
    parseKeyboardProps,
    KeyboardCell,
    KeyboardXmlSettings,
    KeyboardButtonTemplate
} from '../../../core/cash-keyboards/keyboard-utils'

import { KeyboardKbdVo, ButtonFunction } from '../../../core/cash-keyboards/keyboard-kbd-vo'
import { AppBarStore, LEFT_ARROW } from '../../app-bar-store'
import {
    createEquipmentModelVO,
    EquipmentModelVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { CashDevicesStore } from './cash-devices-store'
import {
    EquipmentSettingVO,
    createEquipmentSettingVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-setting-vo'
import { isEqual, cloneDeep } from 'lodash'
import { iEquipmentManagerLocal } from '../../../../protocol/set10/i-equipment-manager-local'
import { withSpinner } from '../../with-spinner'

export interface Device {
    /** Уникальное имя устройства */
    name: string

    /** "Модели" устройства */
    models: DeviceModel[]

    /** Тип устройства (клавиатура, сканер, etc) */
    equipmentType: string
}

export interface DeviceModel {
    /** Структурированные данные о модели (название, etc) из CashDevicesStore */
    data: EquipmentModelVO

    /** Дополнительные настройки модели, переконвертированные из xml */
    xmlSettings: any[]

    keyboardCells?: KeyboardCell[]

    keyboardButtonTemplates?: KeyboardButtonTemplate[]
}

export class CashDeviceSettingsStore {
    /** Редактируемое устройство */
    @observable
    editedDevice: Device = undefined

    /** Индекс редактируемой "модели" текущего устройства */
    @observable
    editedModelIndex: number = 0

    @observable
    keyPositionDialogOpen: boolean = false

    @computed
    get editedModel(): DeviceModel {
        if (!this.editedDevice || this.editedDevice.models.length === 0) return null
        return this.editedDevice.models[this.editedModelIndex]
    }

    @computed
    get modified(): boolean {
        if (this.editedDevice && this.editedDevice.models.some(model => model.data.id === -1)) return true
        if (this.removedDevicesIds.length > 0) return true
        return !isEqual(toJS(this.editedDevice), this.originalDevice)
    }

    private originalDevice: Device = undefined
    private deviceModelCopy: DeviceModel = undefined // Используется для создания модели, если все были удалены
    private removedDevicesIds: number[] = []

    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    private cashDevicesStore: CashDevicesStore = getStore(CASH_DEVICES_STORE)

    fetchAllData = async () => {
        const { models, registeredModels, fetchAllData } = this.cashDevicesStore
        if (!models || !registeredModels) await fetchAllData()
    }

    /**
     * Получает полные данные устройства по его имени.
     * Парсит XML в дополнительных настройках "моделей" (если имеется).
     */
    getDevice = async (name: string): Promise<Device> => {
        const deviceModels = this.cashDevicesStore.registeredModels
            .filter(device => device.name === name)
            .map(device => toJS(device))

        if (!deviceModels.length) return undefined

        // Для каждой "модели" получаем дополнительные настройки.
        const models: DeviceModel[] = await Promise.all(
            deviceModels.map(async data => {
                const rawSettings = await iEquipmentManagerLocal.getAllSettings(data.id)
                const xmlSettings = await Promise.all(rawSettings.map(this.parseXmlSettings))

                let model: DeviceModel = { data, xmlSettings }
                if (data.equipmentType.name === 'keyboard') {
                    model = { ...model, ...parseKeyboardProps(xmlSettings as KeyboardXmlSettings) }
                }
                return model
            })
        )
        const equipmentType = models[0].data.equipmentType.name

        return { name, models, equipmentType }
    }

    parseXmlSettings = async (settings: string): Promise<any> => {
        if (isXml(settings)) {
            try {
                const parsedSetting = await Xml2Js(settings)
                return parsedSetting
            } catch (err) {
                const fixedResult = await fixXmlWithoutSpaces(settings)
                return fixedResult
            }
        }
        return null
    }

    parseKeyboardData2Xml = async (xmlSettings: any): Promise<string> => {
        return keyboardKbd2Xml(xmlSettings)
    }

    openDevice = async (name: string): Promise<void> => {
        await this.fetchAllData()
        const device = await this.getDevice(name)
        if (!device) return this.goBack()

        this.updateNavMenu(device.models[0].data.localizedName)

        runInAction(() => {
            this.editedModelIndex = 0
            this.editedDevice = device
            this.originalDevice = toJS(this.editedDevice)
            this.deviceModelCopy = toJS(this.editedDevice.models[0])
        })
    }

    @action
    updateModelXmlSettings = (settingsIndex: number, changes: any): void => {
        /**
         * Настройки имеются в двух местах:
         * 1 - currentDeviceModel.data.settings[0] - так они приходят вместе с моделью, и так их надо отдавать
         * 2 - currentDeviceModel.xmlSettings[0-1] - это дополнительные настройки, тут мы их будем редактировать
         * а при сохранении парсить в xml и подставлять в первый объект (data.settings)
         */
        const xmlSettings = this.editedModel.xmlSettings
        xmlSettings[settingsIndex] = {
            ...xmlSettings[settingsIndex],
            ...changes
        }
    }

    @action
    updateModelSettings = (changes: Partial<EquipmentSettingVO>): void => {
        // Имя раскладки редактируется непосредственно в currentDeviceModel.data.settings
        if (typeof name !== undefined) {
            this.editedModel.data.settings[0] = {
                ...this.editedModel.data.settings[0],
                ...changes
            }
        }
    }

    modelModified = (index: number): boolean => {
        return !isEqual(toJS(this.editedDevice.models[index]), this.originalDevice.models[index])
    }

    modelXmlModified = (index: number): boolean => {
        return !isEqual(
            toJS(this.editedDevice.models[index].xmlSettings[1]),
            this.originalDevice.models[index].xmlSettings[1]
        )
    }

    @action
    deleteLayout = (): void => {
        const removedModel = this.editedDevice.models.splice(this.editedModelIndex, 1)
        this.originalDevice.models.splice(this.editedModelIndex, 1)

        if (removedModel[0].data.id !== -1) {
            this.removedDevicesIds.push(removedModel[0].data.id)
        }

        if (this.editedModelIndex >= this.editedDevice.models.length) {
            this.editedModelIndex = this.editedModelIndex - 1
        }
    }

    addLayout = async (): Promise<void> => {
        const defaultSettings = await withSpinner(
            iEquipmentManagerLocal.getDefaultPropertiesFileAsString(this.deviceModelCopy.data.id)
        )

        const data = createEquipmentModelVO({
            ...this.deviceModelCopy.data,
            id: -1,
            uuid: undefined,
            settings: [
                createEquipmentSettingVO({
                    name: t('cashDevices.newLayout'),
                    value: defaultSettings
                })
            ]
        })

        const settings1 = this.deviceModelCopy.xmlSettings[0]
        const settings2 = await this.parseXmlSettings(defaultSettings)

        const model = {
            data,
            xmlSettings: [settings1, settings2],
            ...parseKeyboardProps([settings1, settings2] as KeyboardXmlSettings)
        }

        runInAction(() => {
            this.editedDevice.models.push(model)
            this.originalDevice.models.push(cloneDeep(model))
        })
    }

    @action
    copyLayout = (): void => {
        const model = toJS(this.editedDevice.models[this.editedModelIndex])

        model.data.settings[0].name = `${model.data.settings[0].name} - копия`
        model.data.id = -1
        model.data.uuid = undefined

        this.editedDevice.models.push(model)
        this.originalDevice.models.push(model)
    }

    restoreLayout = async (): Promise<void> => {
        const defaultSettings = await withSpinner(
            iEquipmentManagerLocal.getDefaultPropertiesFileAsString(this.deviceModelCopy.data.id)
        )
        const xmlSettings = await this.parseXmlSettings(defaultSettings)

        this.updateModelSettings({ value: defaultSettings })
        this.updateModelXmlSettings(1, xmlSettings)
    }

    @action
    saveDevice = async (): Promise<void> => {
        let requests = []
        await this.editedDevice.models.forEach((model: DeviceModel, index: number) => {
            // ID -1 будет у новых моделей
            if (model.data.id === -1) {
                // Если новая модель, то сначала надо получить ответ, а потом используя ответ изменить настройки
                requests.push(async () => {
                    const response = await this.saveNewModelRequest(model)

                    if (!response) return

                    let settings: EquipmentSettingVO = {
                        ...toJS(model.data.settings[0]),
                        id: response.settings[0].id,
                        uuid: response.settings[0].uuid
                    }

                    // Xml настройки парсим только если они изменялись
                    if (this.modelXmlModified(index)) {
                        settings.value = await keyboardKbd2Xml(model.xmlSettings[1])
                    }

                    await iEquipmentManagerLocal.updateSetting(this.userStore.session, settings)
                })
            } else {
                const modified = this.modelModified(index)
                if (!modified) return

                // Дальше обновляем настройки
                let settings = toJS(model.data.settings[0])

                requests.push(async () => {
                    // Xml настройки парсим только если они изменялись
                    if (this.modelXmlModified(index)) {
                        settings.value = await this.parseKeyboardData2Xml(toJS(model.xmlSettings[1]))
                    }

                    return iEquipmentManagerLocal.updateSetting(this.userStore.session, settings)
                })
            }
        })

        // Ищем удаленные модели
        this.removedDevicesIds.forEach(modelId => {
            requests.push(() => this.saveRemovedModelRequest(modelId))
        })

        await withSpinner(Promise.all(requests.map(request => request())))

        this.snackbarStore.show({ message: t('common.settingsSaved') })
        this.editedDevice = null // Prevent confirmation by unsetting `modified`
        this.goBack()
    }

    saveRemovedModelRequest = async (modelId: number): Promise<void> => {
        await iEquipmentManagerLocal.unRegisterEquipment(this.userStore.session, modelId)
    }

    saveNewModelRequest = async (model: DeviceModel): Promise<EquipmentModelVO> => {
        const result = await iEquipmentManagerLocal.registerAndGetModel(
            this.userStore.session,
            model.data.equipmentType.equipmentClass.name,
            model.data.equipmentType.name,
            model.data.name,
            this.appStore.locale
        )
        return result
    }

    @action
    goBack = (): void => {
        goTo(`${CASH_MODULE}${DEVICES}`)
    }

    updateNavMenu = (deviceName?: string): void => {
        this.appBarStore.updateState({
            title: deviceName ? `${t('set10.deviceSettings')}: ${deviceName}` : t('set10.deviceSettings'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => this.goBack()
        })
    }

    @action
    setEditedModelIndex = (editedModelIndex: number) => {
        this.editedModelIndex = editedModelIndex
    }

    @action
    setKeyPositionDialogOpen = (status: boolean): void => {
        this.keyPositionDialogOpen = status
    }

    @action
    addButton = (button: ButtonFunction): void => {
        const kbd: KeyboardKbdVo = this.editedModel.xmlSettings[1]
        if (!kbd['Keyboard-data'].kbdFunction) kbd['Keyboard-data'].kbdFunction = []
        kbd['Keyboard-data'].kbdFunction.push(button)
    }

    @action
    reset = (): void => {
        this.editedDevice = undefined
        this.originalDevice = undefined
        this.editedModelIndex = 0
        this.keyPositionDialogOpen = false
        this.removedDevicesIds = []
    }
}

export const CASH_DEVICE_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${CASH_MODULE}${DEVICES}${SETTINGS}/[\\w-]+/?$`),
    onEnter: () => {
        const cashDeviceSettingsStore: CashDeviceSettingsStore = getStore(CASH_DEVICE_SETTINGS_STORE)
        cashDeviceSettingsStore.updateNavMenu()
    },
    onLeave: () => {
        const cashDeviceSettingsStore: CashDeviceSettingsStore = getStore(CASH_DEVICE_SETTINGS_STORE)
        cashDeviceSettingsStore.reset()
    }
}
