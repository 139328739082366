import { t } from 'i18next'
import { action, observable, runInAction, computed } from 'mobx'
import { isNil } from 'lodash'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { checkManagerLocal } from '../../../protocol/set10/check-manager-local'
import { operDayServiceLocal } from '../../../protocol/set10/oper-day-service-local'
import { reportsProcessorLocal } from '../../../protocol/set10/reports-processor-local'
import { createSearchArgumentVO, SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import {SearchResultVO} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/search-result-vo'
import {Check} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/check'
import { FiltersState, Filters } from '../../components/filters/new-filters'
import { config } from '../../config/config'
import { XLSX } from '../../core/file-types'
import { CHECK_BARCODE_FILTER, CHECK_TIME_FILTER, CHECK_TYPE_FILTER, CHECKS_TYPES_ALL } from '../../core/filters/filter'
import { AppStore } from '../app-store'
import { APP_STORE, CHECK_PAGE_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { CheckPageStore } from './check-page-store'
import { fromClientToServerTime } from '../../utils/app-util'
import { BaseDateRangeFilter } from '../../components/filters/components/base-date-range-filter'
import moment from 'moment'

export const MAX_EXCEL_ENTRIES = 50000

export const getDefaultFilterState = (): FiltersState => {
    const startDate: string = moment(fromClientToServerTime(new Date())).startOf('day').format(BaseDateRangeFilter.dateFormat)
    const endDate: string = moment(fromClientToServerTime(new Date())).endOf('day').format(BaseDateRangeFilter.dateFormat)

    return {
        shownFilters: {
            [CHECK_TIME_FILTER]: {
                value: `${startDate}${BaseDateRangeFilter.dateDelimiter}${endDate}`
            }
        }
    }
}

export class ChecksSearchStore {

    maxDisplayedChecks: number = 40

    @observable
    searchResult: SearchResultVO<Check>

    @observable
    foundChecks: Check[] = []

    @observable
    barcode: string = ''

    currentFiltersState: FiltersState = getDefaultFilterState()

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private checkPageStore: CheckPageStore = getStore(CHECK_PAGE_STORE)

    @computed
    get searchArguments(): SearchArgumentVO[] {
        if (!this.currentFiltersState) return []
        return Filters.getSearchArguments(this.currentFiltersState.shownFilters)
    }

    @action
    setFiltersState = (state: FiltersState) => {
        this.currentFiltersState = state
    }

    @action
    updateBarcode = (value: string): void => {
        this.barcode = value
    }

    fetchSettings = (): Promise<void> => {
        return operDayServiceLocal.getOperDayProperties()
            .then(result => {
                runInAction(() => {
                    this.maxDisplayedChecks = result.checkAmountDisplayedInSearchCheckMode
                })
            })
    }

    findChecks = (searchArguments: SearchArgumentVO[]) => {
        const advancedSearchArguments = [
            createSearchArgumentVO({ type: CHECK_TYPE_FILTER, value: CHECKS_TYPES_ALL }),
            ...searchArguments
        ]

        return withSpinner(checkManagerLocal.findChecks2(this.userStore.session, advancedSearchArguments, this.maxDisplayedChecks))
            .then(result => {
                runInAction(() => {
                    // Очищаем поле быстрого поиска по штрих-коду, чтобы не вводить в заблуждение пользователя - оно не участвует в общем поиске
                    this.barcode = ''
                    this.searchResult = result
                    this.foundChecks = result.values
                    // TODO SFM-208 ошибки транспорта
                    if (this.foundChecks) {
                        this.foundChecks.forEach(check => {
                            // Для отложенного чека может прийти date === null
                            if (!isNil(check.date)) {
                                if (!(check.date instanceof Date)) {
                                    // unixtime -> Date
                                    check.date = new Date(check.date)
                                }
                                check.date = fromClientToServerTime(check.date)
                            }
                        })
                    }
                })
            })
    }

    quickFindChecks = async (): Promise<void> => {
        const result = await withSpinner(checkManagerLocal.findChecksByBarcode(
            this.userStore.session,
            [
                createSearchArgumentVO({ type: CHECK_TYPE_FILTER, value: CHECKS_TYPES_ALL }),
                createSearchArgumentVO({ type: CHECK_BARCODE_FILTER, value: this.barcode })
            ]
        ))

        if (result.values && result.values.length === 1) {
            runInAction(() => {
                this.barcode = ''
            })
            const check = result.values[0]
            // TODO SFM-208 ошибки транспорта
            // Для отложенного чека может прийти date === null
            if (!isNil(check.date)) {
                if (!(check.date instanceof Date)) {
                    // unixtime -> Date
                    check.date = new Date(check.date)
                }
                check.date = fromClientToServerTime(check.date)
            }
            this.checkPageStore.openCheck(check)
        } else {
            runInAction(() => {
                this.searchResult = result
                this.foundChecks = result.values
                // TODO SFM-208 ошибки транспорта
                if (this.foundChecks) {
                    this.foundChecks.forEach(check => {
                        // Для отложенного чека может прийти date === null
                        if (!isNil(check.date)) {
                            if (!(check.date instanceof Date)) {
                                // unixtime -> Date
                                check.date = new Date(check.date)
                            }
                            check.date = fromClientToServerTime(check.date)
                        }
                    })
                }
            })
        }
    }

    @action
    resetFiltersAndResults = (filterState: FiltersState): void => {
        this.foundChecks = []
        this.searchResult = undefined
        this.currentFiltersState = filterState
        this.barcode = ''
    }

    @action
    exportPurchasePayments = () => {
        if (this.searchResult.maxCount > MAX_EXCEL_ENTRIES) {
            this.appStore.showDialog({
                title: t('checksSearch.tooMuchChecksHeader'),
                message: t('checksSearch.tooMuchChecksText', {count: MAX_EXCEL_ENTRIES}),
                mode: DIALOG,
                onYes: this.exportPurchasePaymentsToExcel
            })
        }
        else {
            this.exportPurchasePaymentsToExcel()
        }
    }

    @action
    exportPurchasePositions = () => {
        if (this.searchResult.maxCount > MAX_EXCEL_ENTRIES) {
            this.appStore.showDialog({
                title: t('checksSearch.tooMuchChecksHeader'),
                message: t('checksSearch.tooMuchChecksText', {count: MAX_EXCEL_ENTRIES}),
                mode: DIALOG,
                onYes: this.exportPurchasePositionsToExcel
            })
        }
        else {
            this.exportPurchasePositionsToExcel()
        }
    }

    exportPurchasePaymentsToExcel = () => {
        const reportWindow = window.open()
        reportsProcessorLocal.getFileNameForPurchasePayments([
            createSearchArgumentVO({type: CHECK_TYPE_FILTER, value: CHECKS_TYPES_ALL}),
            ...this.searchArguments
        ], XLSX).then(filename => {
            // window.open сработает только если он был вызван в главном потоке
            // поэтому мы сначала открываем окно, а потом подменяем ему адрес
            const reportUrl = `${config.reportsAddress}?Action=getFile&FILE_NAME=${filename}`
            reportWindow.location.replace(reportUrl)
        })
    }

    exportPurchasePositionsToExcel = () => {
        const reportWindow = window.open()
        reportsProcessorLocal.getFileNameForPurchasePositions([
            createSearchArgumentVO({type: CHECK_TYPE_FILTER, value: CHECKS_TYPES_ALL}),
            ...this.searchArguments
        ], XLSX).then(filename => {
            // window.open сработает только если он был вызван в главном потоке
            // поэтому мы сначала открываем окно, а потом подменяем ему адресс
            const reportUrl = `${config.reportsAddress}?Action=getFile&FILE_NAME=${filename}`
            reportWindow.location.replace(reportUrl)
        })
    }

    @action
    reset = (): void => {
        this.barcode = ''
        this.maxDisplayedChecks = 40
        this.resetFiltersAndResults(getDefaultFilterState())
    }
}
