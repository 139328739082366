import {t} from 'i18next'
import {isEmpty, isNil} from 'lodash'
import {action, computed, observable, runInAction, toJS} from 'mobx'
import {TreeItem} from '../../../components/tree-view/tree-view'
import {cashiersManagerLocal} from '../../../protocol/set10/cashiers-manager-local'
import {createRoleRightVO, RoleRightVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/role-right-vo'
import {createRoleVO, RoleVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/role-vo'
import {XMLPairVO} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/xml-pair-vo'
import {getUniqueNameWithNumberPostfix} from '../../../utils/name-util'
import {CASHIERS_ROLES, STAFF} from '../../core/app-routes'
import {ANY_POSITION, KeyPosition, POSITION_1, POSITION_2} from '../../core/staff/cash-key-positions'
import {NEW} from '../../core/values'
import {goTo} from '../../utils/router-util'
import {SnackbarStore} from '../snackbar-store'
import {SNACKBAR_STORE, USER_STORE} from '../stores'
import {getStore} from '../stores-repository'
import {UserStore} from '../user-store'
import {withSpinner} from '../with-spinner'
import {FormValidation} from '../../../utils/form-validation/form-validation'
import {uniqueField} from '../../../utils/form-validation/validators/unique-field'
import {textWithDotsValidator} from '../../../utils/form-validation/validators/regexp-validator'
import {fieldLengthValidator} from '../../../utils/form-validation/validators/length-validator'
import {requiredField} from '../../../utils/form-validation/validators/required-field'

const NAME_MAX_LENGTH: number = 30

interface SelectedPrivilege extends XMLPairVO {
    section: XMLPairVO
}

export class CashiersRolesStore {
    @observable
    roles: RoleVO[] = []
    @observable
    currentRole: RoleVO
    @observable
    privilegeHierarchy: TreeItem[] = []
    @observable
    selectedPrivileges: SelectedPrivilege[] = []
    @observable
    cashiersUsedByRoleCount: number = 0
    @observable
    validation: FormValidation<RoleVO>

    @computed
    get privileges(): RoleRightVO[] {
        if (!this.currentRole || !this.currentRole.rights) return []

        return this.currentRole.rights
    }

    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    fetchRoles = async (): Promise<void> => {
        // TODO getRoles возвращает код привилегии вместо имени в result[roleId].rights[anyRightId].name
        const roles: RoleVO[] = await withSpinner(cashiersManagerLocal.getRoles(this.userStore.session))
        runInAction('setCashiersRoles', () => {
            this.roles = roles
        })
    }

    fetchRoleById = (id: number): Promise<RoleVO> => {
        return withSpinner(async () => {
            const role = await cashiersManagerLocal.getRole(this.userStore.session, id)
            await this.fetchCashiersUsedByRole(id)
            return role
        })
    }

    fetchCashiersUsedByRole = async (roleId: number) => {
        const count = await cashiersManagerLocal.getCashiersCountByRoleId(this.userStore.session, roleId)
        runInAction(() => {
            this.cashiersUsedByRoleCount = Number(count)
        })
    }

    fetchPrivilegesHierarchy = (): Promise<void> => {
        return withSpinner(
            async () => {
                const privilegeSections: XMLPairVO[] = await cashiersManagerLocal.getPrivilegeSections(this.userStore.session)
                const privileges: XMLPairVO[][] = await Promise.all(privilegeSections.map(
                    // TODO в getPrivileges аргумент называется sectionName, хотя по факту это sectionCode
                    section => cashiersManagerLocal.getPrivileges(this.userStore.session, section.code)
                ))

                runInAction('setPrivilegesHierarchy', () => {
                    this.privilegeHierarchy = privilegeSections.map((section, index) => (
                        {
                            item: section,
                            children: privileges[index].map(p => (
                                {
                                    item: {
                                        ...p,
                                        section
                                    },
                                }
                            ))
                        }
                    ))
                })
            }
        )
    }

    openRole = async (ref: string | number): Promise<void> => {
        if (isNil(ref)) {
            this.snackbarStore.show({message: t('staff.roleFetchingFailure', {id: ref})})
            goTo(`${STAFF}${CASHIERS_ROLES}`)
            return Promise.resolve()
        }

        if (isEmpty(this.roles)) {
            await this.fetchRoles()
        }

        await this.fetchPrivilegesHierarchy()

        if (ref === NEW) {
            this.addRole()
        } else {
            const role: RoleVO = await this.fetchRoleById(Number(ref))
            this.editRole(role)
        }
    }

    @action
    editRole = (role: RoleVO): void => {
        if (!role) {
            goTo(`${STAFF}${CASHIERS_ROLES}`)
            return
        }

        this.currentRole = role
        this.createValidation()
        goTo(`${STAFF}${CASHIERS_ROLES}/${role.id}`)
    }

    @action
    addRole = (): void => {
        const newRoleName: string = getUniqueNameWithNumberPostfix(
            t('staff.defaultCashierRoleName'),
            toJS(this.roles),
            'name'
        )

        this.currentRole = createRoleVO({
            id: -1,
            name: newRoleName,
            rights: [],
            users: []
        })
        this.createValidation(true)
        goTo(`${STAFF}${CASHIERS_ROLES}/${NEW}`)
    }

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<RoleVO>(
            this.currentRole,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        fieldLengthValidator({
                            max: NAME_MAX_LENGTH
                        }),
                        textWithDotsValidator,
                        uniqueField(this.roles
                            .filter(r => r.id !== this.currentRole.id)
                            .map(r => r.name))
                    ],
                }
            ],
            creation
        )
    }

    @action
    changeRoleName = (value: string): void => {
        if (!this.currentRole) return
        this.currentRole.name = value
    }

    getKeyPositionOptions = (): Array<{ label: string, value: KeyPosition }> => {
        return [
            {
                label: t('staff.keyAnyPosition'),
                value: ANY_POSITION
            },
            {
                label: t('staff.keyPosition1'),
                value: POSITION_1
            },
            {
                label: t('staff.keyPosition2'),
                value: POSITION_2
            }
        ]
    }

    @action
    changeKeyPosition = async (privilege: RoleRightVO, keyPosition: KeyPosition): Promise<void> => {
        const index = this.currentRole.rights.findIndex(item => item.right === privilege.right)
        if (index > -1) {
            this.currentRole.rights[index].keyPosition = keyPosition
            this.validation.modified = true
        }
    }

    @action
    selectPrivilege = (privilege: SelectedPrivilege, selected: boolean): void => {
        const existingPrivilege = this.selectedPrivileges.find(p => p.code === privilege.code)

        if (selected && !existingPrivilege) {
            this.selectedPrivileges.push(privilege)
        }
        if (!selected && existingPrivilege) {
            this.selectedPrivileges.splice(this.selectedPrivileges.indexOf(existingPrivilege), 1)
        }
    }

    saveRoleAndExit = async (): Promise<void> => {
        const role = toJS(this.currentRole)
        role.name = role.name.trim()

        await cashiersManagerLocal.addRole(this.userStore.session, role)

        this.snackbarStore.show({message: t('staff.roleUpdatingSuccess')})
        goTo(`${STAFF}${CASHIERS_ROLES}`)
    }

    cancelChanges = (): void => {
        goTo(`${STAFF}${CASHIERS_ROLES}`)
    }

    @action
    addSelectedPrivilegesToCurrentRole = (): void => {
        const { guid, rights: currentRights } = this.currentRole

        this.selectedPrivileges.forEach(selectedItem => {
            const selectedItemCode = selectedItem.code
            // Если роль не была выбрана ранее - добавляем
            if (!currentRights.some(item => item.right === selectedItemCode)) {
                this.currentRole.rights.push(createRoleRightVO({
                    guid,
                    keyPosition: null,
                    name: selectedItem.name,
                    privilegeSectionCode: selectedItem.section.code,
                    right: selectedItemCode,
                    role: null,
                }))
                this.validation.modified = true
            }
        })
    }

    @action
    removePrivilegeFromCurrentRole = async (privilege: RoleRightVO): Promise<void> => {
        const index = this.currentRole.rights.findIndex(item => item.right === privilege.right)
        if (index > -1) {
            this.currentRole.rights.splice(index, 1)
            this.validation.modified = true
        }
    }

    @action
    removeAllPrivilegesFromCurrentRole = async (): Promise<void> => {
        this.currentRole.rights = []
        this.validation.modified = true
    }

    @action
    resetRoles = (): void => {
        this.roles = []
    }

    @action
    resetPrivileges = (): void => {
        this.currentRole = undefined
        this.cashiersUsedByRoleCount = 0
        this.privilegeHierarchy = []
        this.selectedPrivileges = []
    }

    @action
    reset = (): void => {
        this.resetRoles()
        this.resetPrivileges()
    }
}
