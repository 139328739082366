import uuid from 'uuid'
import { t } from 'i18next'
import {
    createSearchArgumentVO,
    SearchArgumentVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { isNil } from 'lodash'

export type FilterType = (
    // По чеку
    'CheckNumber' | 'CheckSum' | 'CheckType' | 'CheckTypeTransaction' | 'CheckTime' | 'CashDeckNumber' | 'DocumentNumber'
        | 'ShiftNumber' | 'StoreNumber' | 'TabNumber'
        | 'CHECK,REFUND_CHECK,CANCEL_CHECK,DEFERRED_CHECK,EXPENSE_RECEIPT,RETURN_EXPENSE_RECEIPT' | 'NONFISCAL'
        // Оплаты
        | 'AuthCode' | 'BankCardNumber' | 'BankId' | 'BonusCardNumber' | 'ChildrenCardNumber' | 'GiftCardNumber' | 'PaymentSum'
        | 'PaymentType' | 'ResponseCode' | 'ResultServerCode' | 'TerminalNumber' | 'TransactionPaymentSum'
        // Скидки
        | 'DiscountSum' | 'DiscountCardNumber' | 'PositionDiscountSum'
        // Позиции
        | 'CheckBarCode' | 'ERPCode' | 'PositionSum' | 'PositionQuantity' | 'ProductItemOrBarcode' | 'ProductType'
        // Контрагент
        | 'BuyerInn' | 'BuyerName' | 'BuyerKpp' | 'DebtorNumber'
        // Ценники
        | 'ActionPriceTagType' | 'ActionStartDate' | 'ActionEndDate' | 'ActualPriceNumbers' | 'AdvertisingAction'
        | 'AdvertisingActionEnding' | 'ChangedPriceNumber' | 'EndedActionsByNow' | 'EndedPricesByNow'
        | 'ExcludedActualPriceNumbers' | 'FuturePrices' | 'InStock' | 'MeasureCodes' | 'NearlyPriceChanging'
        | 'NearlyActions' | 'OnlyNewProducts' | 'PricesEndDate' | 'PriceStartDate' | 'PriceNumber' | 'PriceTagsPrintingDate' | 'ProductGroup'
        | 'ProductsWithAdditionalPriceTags' | 'ProductsWithoutActions' | 'RunningActions' | 'SaleRestrictions' | 'Product' | 'Department'
        | 'SectionCodes' | 'StartActionAfter' | 'ProductGroupAutocomplete' | 'ProductAutocomplete' | 'DepartmentAutocomplete'
        | 'PriceTagTemplateName' | 'ActualPricesDates' | 'ActualPriceDateStart' | 'ActualPriceDateEnd' | 'PrintStatus'
        | 'ConfirmationType' | 'ProductStatus' | 'PrintTasksFromLogWithError'
        // Для составных фильтров
        | 'CheckCommitBeginDate' | 'CheckCommitEndDate' | 'EndDate' | 'StartDate' |
        // Лояльность
        'ActionLastChangedDate' | 'ActionWorkingPeriod' | 'ActionCode' | 'ActionDiscountType' | 'ActionSecondaryResult' |
        'ActionLabel' | 'ActionColor' | 'ActionAuthor' | 'ActionEditor' | 'ActionCouponUse' | 'ActionCouponCode' |
        'ActionCouponCatergory' | 'ActionSegment' | 'ActionCards' | 'PartFilter' | 'CouponBarcode' | 'CouponName' |
        'UsedCheck' | 'CreatedCheck' | 'CouponStatus' | 'UsedDate' | 'CreatedDate' |
        // Сегменты
        'SegmentCode' | 'SegmentActionName' | 'SegmentActionCode' | 'SegmentCreated' |
        'SegmentChanged' | 'SegmentStatus' | 'SegmentLogin'
)

export type FiltersObject = {
    [key in FilterType]?: string
}

export type FiltersStateObject = {
    [key in FilterType]?: {[name: string]: any}
}

export interface FilterTypeWithOptions {
    type: FilterType,
    // TODO дополнительные опции, которые прокидываются в каждый FilterModule
    // нужно поправить в рамках https://crystals.atlassian.net/browse/SFM-513
    options?: any
}

export interface FilterGroup {
    name: string
    filters: Array<FilterType | FilterTypeWithOptions>
}

export const getFilterType = (filter: FilterType | FilterTypeWithOptions): FilterType => {
    return isFilterTypeWithOptions(filter) ? (filter as FilterTypeWithOptions).type : filter as FilterType
}

export const isFilterTypeWithOptions = (filter: FilterType | FilterTypeWithOptions): boolean => {
    return !isNil((filter as FilterTypeWithOptions).type)
}

/**
 * Преобразовывает группы фильтров в массив фильтров
 * @param filtersGroups массив групп фильтров
 */
export const getAllFiltersFromFilterGroups = (filtersGroups: FilterGroup[]): Array<FilterType | FilterTypeWithOptions> => {
    if (!filtersGroups) return []

    return filtersGroups.reduce(
        (acc, group) => [
            ...acc,
            ...group.filters
        ],
        []
    )
}

// По чеку
export const CHECK_NUMBER_FILTER: FilterType = 'CheckNumber'
export const CHECK_SUM_FILTER: FilterType = 'CheckSum'
export const CHECK_TYPE_FILTER: FilterType = 'CheckType'
export const CHECK_TYPE_TRANSACTION_FILTER: FilterType = 'CheckTypeTransaction'
export const CHECKS_TYPES_ALL: FilterType = 'CHECK,REFUND_CHECK,CANCEL_CHECK,DEFERRED_CHECK,EXPENSE_RECEIPT,RETURN_EXPENSE_RECEIPT'
export const NON_FISCAL_FILTER: FilterType = 'NONFISCAL'
// CHECK_TIME_FILTER не используется на сервере - вместо него отправляются 'CheckCommitBeginDate' и 'CheckCommitEndDate'
export const CHECK_TIME_FILTER: FilterType = 'CheckTime'
export const CASH_DECK_NUMBER_FILTER: FilterType = 'CashDeckNumber'
export const DOCUMENT_NUMBER_FILTER: FilterType = 'DocumentNumber'
export const SHIFT_NUMBER_FILTER: FilterType = 'ShiftNumber'
export const STORE_NUMBER_FILTER: FilterType = 'StoreNumber'
export const TAB_NUMBER_FILTER: FilterType = 'TabNumber'
// Оплаты
export const AUTH_CODE_FILTER: FilterType = 'AuthCode'
export const BANK_CARD_NUMBER_FILTER: FilterType = 'BankCardNumber'
export const BANK_ID_FILTER: FilterType = 'BankId'
export const BONUS_CARD_NUMBER_FILTER: FilterType = 'BonusCardNumber'
export const CHILD_CARD_NUMBER_FILTER: FilterType = 'ChildrenCardNumber'
export const GIFT_CARD_NUMBER_FILTER: FilterType = 'GiftCardNumber'
export const PAYMENT_SUM_FILTER: FilterType = 'PaymentSum'
export const PAYMENT_TYPE_FILTER: FilterType = 'PaymentType'
export const RESPONSE_CODE_FILTER: FilterType = 'ResponseCode'
export const RESULT_SERVER_CODE_FILTER: FilterType = 'ResultServerCode'
export const TERMINAL_NUMBER_FILTER: FilterType = 'TerminalNumber'
export const TRANSACTION_PAYMENT_SUM_FILTER: FilterType = 'TransactionPaymentSum'
// Скидки
export const DISCOUNT_SUM_FILTER: FilterType = 'DiscountSum'
export const DISCOUNT_CARD_NUMBER_FILTER: FilterType = 'DiscountCardNumber'
export const POSITION_DISCOUNT_SUM_FILTER: FilterType = 'PositionDiscountSum'
// Позиции
export const CHECK_BARCODE_FILTER: FilterType = 'CheckBarCode'
export const ERP_CODE_FILTER: FilterType = 'ERPCode'
export const POSITION_SUM_FILTER: FilterType = 'PositionSum'
export const POSITION_QUANTITY_FILTER: FilterType = 'PositionQuantity'
export const PRODUCT_ITEM_OR_BARCODE_FILTER: FilterType = 'ProductItemOrBarcode'
export const PRODUCT_TYPE_FILTER: FilterType = 'ProductType'
// Контрагент
export const BUYER_INN_FILTER: FilterType = 'BuyerInn'
export const BUYER_NAME_FILTER: FilterType = 'BuyerName'
export const BUYER_KPP_FILTER: FilterType = 'BuyerKpp'
export const DEBTOR_NUMBER_FILTER: FilterType = 'DebtorNumber'
// Ценники
export const ACTION_PRICE_TAG_TYPE_FILTER: FilterType = 'ActionPriceTagType'
// ACTION_START_DATE_FILTER не используется на сервере - вместо него отправляются 'StartDate' и 'EndDate'
export const ACTION_START_DATE_FILTER: FilterType = 'ActionStartDate'
// ACTION_END_DATE_FILTER не используется на сервере - вместо него отправляются 'StartDate' и 'EndDate'
export const ACTION_END_DATE_FILTER: FilterType = 'ActionEndDate'
export const ACTUAL_PRICE_NUMBERS_FILTER: FilterType = 'ActualPriceNumbers'
export const ADVERTISING_ACTION: FilterType = 'AdvertisingAction'
export const ADVERTISING_ACTION_ENDING_FILTER: FilterType = 'AdvertisingActionEnding'
export const CHANGED_PRICE_NUMBER_FILTER: FilterType = 'ChangedPriceNumber'
export const ENDED_ACTIONS_BY_NOW_FILTER: FilterType = 'EndedActionsByNow'
export const ENDED_PRICES_BY_NOW_FILTER: FilterType = 'EndedPricesByNow'
export const EXCLUDED_ACTUAL_PRICE_NUMBERS_FILTER: FilterType = 'ExcludedActualPriceNumbers'
export const FUTURE_PRICES_FILTER: FilterType = 'FuturePrices'
export const IN_STOCK_FILTER: FilterType = 'InStock'
export const MEASURE_CODES_FILTER: FilterType = 'MeasureCodes'
export const NEARLY_ACTIONS_FILTER: FilterType = 'NearlyActions'
export const NEARLY_PRICE_CHANGING_FILTER: FilterType = 'NearlyPriceChanging'
export const ONLY_NEW_PRODUCTS_FILTER: FilterType = 'OnlyNewProducts'
// PRICES_END_DATE_FILTER не используется на сервере - вместо него отправляются 'StartDate' и 'EndDate'
export const PRICES_END_DATE_FILTER: FilterType = 'PricesEndDate'
export const PRICES_START_DATE_FILTER: FilterType = 'PriceStartDate'
export const PRICE_NUMBER_FILTER: FilterType = 'PriceNumber'
// PRICE_TAGS_PRINTING_DATE_FILTER не используется на сервере - вместо него отправляются 'StartDate' и 'EndDate'
export const PRICE_TAGS_PRINTING_DATE_FILTER: FilterType = 'PriceTagsPrintingDate'
// 'ActualPricesDates' не используется на сервере - вместо него отправляются 'ActualPriceDateStart' и 'ActualPriceDateEnd'
export const ACTUAL_PRICES_DATES_FILTER: FilterType = 'ActualPricesDates'
export const ACTUAL_PRICE_DATE_START_FILTER: FilterType = 'ActualPriceDateStart'
export const ACTUAL_PRICE_DATE_END_FILTER: FilterType = 'ActualPriceDateEnd'
// FIXME сервер выкидывает ошибку для этой константы, возможно этот фильтр совсем не работает
export const PRODUCT_GROUP_FILTER: FilterType = 'ProductGroup'
export const PRODUCT_FILTER: FilterType = 'Product'
export const DEPARTMENT_FILTER: FilterType = 'Department'
export const PRODUCT_GROUP_AUTOCOMPLETE: FilterType = 'ProductGroupAutocomplete'
export const PRODUCT_AUTOCOMPLETE: FilterType = 'ProductAutocomplete'
export const DEPARTMENT_AUTOCOMPLETE: FilterType = 'DepartmentAutocomplete'
export const PRODUCTS_WITH_ADDITIONAL_PRICE_TAGS_FILTER: FilterType = 'ProductsWithAdditionalPriceTags'
export const PRODUCTS_WITHOUT_ACTIONS_FILTER: FilterType = 'ProductsWithoutActions'
export const RUNNING_ACTIONS_FILTER: FilterType = 'RunningActions'
export const SALE_RESTRICTIONS_FILTER: FilterType = 'SaleRestrictions'
export const SECTION_CODES_FILTER: FilterType = 'SectionCodes'
export const START_ACTION_AFTER_FILTER: FilterType = 'StartActionAfter'
export const PRICE_TAG_TEMPLATE_NAME_FILTER: FilterType = 'PriceTagTemplateName'
export const PRINT_STATUS_FILTER: FilterType = 'PrintStatus'
export const PRINT_CONFIRMATION_TYPE: FilterType = 'ConfirmationType'
export const PRINT_TASKS_FROM_LOG_WITH_ERROR: FilterType = 'PrintTasksFromLogWithError'
export const PRINT_SHELF: FilterType = 'ProductStatus' // здесь выбираем именно полку печати, но на сервер отправиляем как type ProductStatus
// Для составных фильтров
export const CHECK_COMMIT_BEGIN_DATE_FILTER: FilterType = 'CheckCommitBeginDate'
export const CHECK_COMMIT_END_DATE_FILTER: FilterType = 'CheckCommitEndDate'
export const END_DATE_FILTER: FilterType = 'EndDate'
export const START_DATE_FILTER: FilterType = 'StartDate'
// Для раздела Loyalty фильтры отправляются на сервер в другой структуре
export const ACTION_LAST_CHANGED_FILTER: FilterType = 'ActionLastChangedDate'
export const ACTION_WORKING_PERIOD_FILTER: FilterType = 'ActionWorkingPeriod'
export const ACTION_CODE_FILTER: FilterType = 'ActionCode'
export const ACTION_DISCOUNT_TYPE: FilterType = 'ActionDiscountType'
export const ACTION_SECONDARY_RESULT: FilterType = 'ActionSecondaryResult'
export const ACTION_LABEL: FilterType = 'ActionLabel'
export const ACTION_COLOR: FilterType = 'ActionColor'
export const ACTION_AUTHOR: FilterType = 'ActionAuthor'
export const ACTION_EDITOR: FilterType = 'ActionEditor'
export const ACTION_COUPON_USE: FilterType = 'ActionCouponUse'
export const ACTION_COUPON_CODE: FilterType = 'ActionCouponCode'
export const ACTION_COUPON_CATEGORY: FilterType = 'ActionCouponCatergory'
export const ACTION_SEGMENT: FilterType = 'ActionSegment'
export const ACTION_CARDS: FilterType = 'ActionCards'

// Сегменты
export const SEGMENT_CODE: FilterType = 'SegmentCode'
export const SEGMENT_ACTION_NAME: FilterType = 'SegmentActionName'
export const SEGMENT_ACTION_CODE: FilterType = 'SegmentActionCode'
export const SEGMENT_CREATED: FilterType = 'SegmentCreated'
export const SEGMENT_CHANGED: FilterType = 'SegmentChanged'
export const SEGMENT_STATUS: FilterType = 'SegmentStatus'
export const SEGMENT_LOGIN: FilterType = 'SegmentLogin'

// Для раздела Loyalty-coupons
export const NAME_PART: FilterType = 'PartFilter'
export const COUPON_BARCODE: FilterType = 'CouponBarcode'
export const COUPON_NAME: FilterType = 'CouponName'
export const USED_CHECK: FilterType = 'UsedCheck'
export const CREATED_CHECK: FilterType = 'CreatedCheck'
export const COUPON_STATUS: FilterType = 'CouponStatus'
export const USED_DATE: FilterType = 'UsedDate'
export const CREATED_DATE: FilterType = 'CreatedDate'

// Если значение фильтра равно этому значению, toSearchArgument устанавливает value в undefined
export const EMPTY_FILTER_VALUE: string = 'EmptyFilterValue\u0003'
// Разделитель для фильтра BaseDateRangeFilter
export const BASE_DATE_RANGE_FILTER_DELIMITER: string = '/'

export class Filter {

    constructor(public type: FilterType,
                public value: string = '',
                public uid?: string) {
        if (!uid) { this.uid = uuid() }
    }

    // Всегда возвращает массив, т.к. некоторые фильтры отправляются на сервер по частям
    toSearchArgument = (): SearchArgumentVO[] => {
        switch (this.type) {
            case CHECK_TIME_FILTER:
                {
                    const values: string[] = this.value !== ''
                        ? this.value.split(BASE_DATE_RANGE_FILTER_DELIMITER)
                        : ['', '']
                    return [
                        createSearchArgumentVO({
                            type: CHECK_COMMIT_BEGIN_DATE_FILTER,
                            value: values[0]
                        }),
                        createSearchArgumentVO({
                            type: CHECK_COMMIT_END_DATE_FILTER,
                            value: values[1]
                        }),
                    ]
                }
            case ACTION_START_DATE_FILTER:
            case ACTION_END_DATE_FILTER:
            case PRICES_END_DATE_FILTER:
            case PRICES_START_DATE_FILTER:
            case PRICE_TAGS_PRINTING_DATE_FILTER:
                {
                    const values: string[] = this.value !== ''
                        ? this.value.split(BASE_DATE_RANGE_FILTER_DELIMITER)
                        : ['', '']
                    return [
                        createSearchArgumentVO({
                            type: START_DATE_FILTER,
                            value: values[0]
                        }),
                        createSearchArgumentVO({
                            type: END_DATE_FILTER,
                            value: values[1]
                        }),
                    ]
                }
            case ACTUAL_PRICES_DATES_FILTER:
                {
                    const values: string[] = this.value !== ''
                        ? this.value.split(BASE_DATE_RANGE_FILTER_DELIMITER)
                        : ['', '']
                    return [
                        createSearchArgumentVO({
                            type: ACTUAL_PRICE_DATE_START_FILTER,
                            value: values[0]
                        }),
                        createSearchArgumentVO({
                            type: ACTUAL_PRICE_DATE_END_FILTER,
                            value: values[1]
                        }),
                    ]
                }
            case PRODUCT_GROUP_AUTOCOMPLETE:
                return [
                    createSearchArgumentVO({
                        type: PRODUCT_GROUP_FILTER,
                        value: this.value !== EMPTY_FILTER_VALUE
                            ? this.value
                            : undefined,
                    })
                ]
            case PRODUCT_AUTOCOMPLETE:
                return [
                    createSearchArgumentVO({
                        type: PRODUCT_ITEM_OR_BARCODE_FILTER,
                        value: this.value !== EMPTY_FILTER_VALUE
                            ? this.value
                            : undefined,
                    })
                ]
            case DEPARTMENT_AUTOCOMPLETE:
                return [
                    createSearchArgumentVO({
                        type: DEPARTMENT_FILTER,
                        value: this.value !== EMPTY_FILTER_VALUE
                            ? this.value
                            : undefined,
                    })
                ]
            default:
                return [
                    createSearchArgumentVO({
                        type: this.type,
                        value: this.value !== EMPTY_FILTER_VALUE
                            ? this.value
                            : undefined,
                    })
                ]
        }
    }
}

/**
 * Преобразует массив фильтров в массив аргументов поиска
 */
export const getFiltersSearchArguments = (filters: Filter[]): SearchArgumentVO[] => {
    return filters.reduce(
        (acc, filter) => [...acc, ...filter.toSearchArgument()],
        []
    )
}

export const getFilterTypeName = (type: FilterType): string => {
    switch (type) {
        // По чеку
        case CHECK_NUMBER_FILTER:
            return t('filters.checkNumber')
        case CHECK_SUM_FILTER:
            return t('filters.checkSum')
        case CHECK_TYPE_FILTER:
        case CHECK_TYPE_TRANSACTION_FILTER:
            return t('filters.checkType')
        case CHECK_TIME_FILTER:
            return t('filters.checkTime')
        case CASH_DECK_NUMBER_FILTER:
            return t('filters.cashNumber')
        case DOCUMENT_NUMBER_FILTER:
            return t('filters.documentNumber')
        case SHIFT_NUMBER_FILTER:
            return t('filters.shiftNumber')
        case STORE_NUMBER_FILTER:
            return t('filters.storeNumber')
        case TAB_NUMBER_FILTER:
            return t('filters.tabNumber')
        // Оплаты
        case AUTH_CODE_FILTER:
            return t('filters.authorizationCode')
        case BANK_CARD_NUMBER_FILTER:
            return t('filters.bankCardNumber')
        case BANK_ID_FILTER:
            return t('filters.bankId')
        case BONUS_CARD_NUMBER_FILTER:
            return t('filters.bonusCardNumber')
        case CHILD_CARD_NUMBER_FILTER:
            return t('filters.childCardNumber')
        case GIFT_CARD_NUMBER_FILTER:
            return t('filters.giftCardNumber')
        case PAYMENT_SUM_FILTER:
            return t('filters.paymentSum')
        case PAYMENT_TYPE_FILTER:
            return t('filters.paymentType')
        case RESPONSE_CODE_FILTER:
            return t('filters.processingCenterResponseCode')
        case RESULT_SERVER_CODE_FILTER:
            return t('filters.serverResponseCode')
        case TERMINAL_NUMBER_FILTER:
            return t('filters.terminalNumber')
        case TRANSACTION_PAYMENT_SUM_FILTER:
            return t('filters.transactionPaymentSum')
        // Скидки
        case DISCOUNT_SUM_FILTER:
            return t('filters.discountSum')
        case DISCOUNT_CARD_NUMBER_FILTER:
            return t('filters.discountCardNumber')
        case POSITION_DISCOUNT_SUM_FILTER:
            return t('filters.positionDiscountSum')
        // Позиции
        case CHECK_BARCODE_FILTER:
            return t('filters.checkBarcode')
        case ERP_CODE_FILTER:
            return t('filters.productERPCode')
        case POSITION_SUM_FILTER:
            return t('filters.positionSum')
        case POSITION_QUANTITY_FILTER:
            return t('filters.positionQuantity')
        case PRODUCT_ITEM_OR_BARCODE_FILTER:
            return t('filters.productCodeOrBarcode')
        case PRODUCT_TYPE_FILTER:
            return t('filters.productType')
        // Контрагент
        case BUYER_INN_FILTER:
            return t('filters.buyerInn')
        case BUYER_NAME_FILTER:
            return t('filters.buyerName')
        case BUYER_KPP_FILTER:
            return t('filters.buyerKpp')
        case DEBTOR_NUMBER_FILTER:
            return t('filters.debtorNumber')
        // Ценники
        case ACTION_PRICE_TAG_TYPE_FILTER:
            return t('filters.actionPriceTagType')
        case ACTION_START_DATE_FILTER:
            return t('filters.actionStartDate')
        case ACTION_END_DATE_FILTER:
            return t('filters.actionEndDate')
        case ACTUAL_PRICE_NUMBERS_FILTER:
            return t('filters.actualPriceNumbers')
        case ADVERTISING_ACTION:
            return t('filters.advertisingAction')
        case ADVERTISING_ACTION_ENDING_FILTER:
            return t('filters.advertisingActionEnding')
        case CHANGED_PRICE_NUMBER_FILTER:
            return t('filters.changedPriceNumber')
        case ENDED_ACTIONS_BY_NOW_FILTER:
            return t('filters.endedActionsByNow')
        case ENDED_PRICES_BY_NOW_FILTER:
            return t('filters.endedPricesByNow')
        case EXCLUDED_ACTUAL_PRICE_NUMBERS_FILTER:
            return t('filters.excludedActualPriceNumbers')
        case FUTURE_PRICES_FILTER:
            return t('filters.futurePrices')
        case IN_STOCK_FILTER:
            return t('filters.inStock')
        case MEASURE_CODES_FILTER:
            return t('filters.measureCodes')
        case NEARLY_ACTIONS_FILTER:
            return t('filters.nearlyActions')
        case NEARLY_PRICE_CHANGING_FILTER:
            return t('filters.nearlyPriceChanging')
        case ONLY_NEW_PRODUCTS_FILTER:
            return t('filters.onlyNewProducts')
        case PRICE_NUMBER_FILTER:
            return t('filters.priceNumber')
        case PRICES_END_DATE_FILTER:
            return t('filters.pricesEndDate')
        case PRICES_START_DATE_FILTER:
            return t('filters.pricesStartDate')
        case PRICE_TAGS_PRINTING_DATE_FILTER:
            return t('filters.priceTagsPrintingDate')
        case ACTUAL_PRICES_DATES_FILTER:
            return t('filters.actualPricesDate')
        case PRODUCT_GROUP_FILTER:
        case PRODUCT_GROUP_AUTOCOMPLETE:
            return t('filters.productGroup')
        case PRODUCT_AUTOCOMPLETE:
        case PRODUCT_FILTER:
            return t('filters.product')
        case DEPARTMENT_AUTOCOMPLETE:
            return t('filters.department')
        case PRICE_TAG_TEMPLATE_NAME_FILTER:
            return t('filters.priceTagTemplateName')
        case PRINT_STATUS_FILTER:
            return t('filters.printStatus')
        case PRINT_CONFIRMATION_TYPE:
            return t('filters.printConfirmationType')
        case PRINT_SHELF:
            return t('filters.printShelf')
        case PRODUCTS_WITH_ADDITIONAL_PRICE_TAGS_FILTER:
            return t('filters.productsWithAdditionalPriceTags')
        case PRODUCTS_WITHOUT_ACTIONS_FILTER:
            return t('filters.productsWithoutActions')
        case RUNNING_ACTIONS_FILTER:
            return t('filters.runningActions')
        case SALE_RESTRICTIONS_FILTER:
            return t('filters.saleRestrictions')
        case SECTION_CODES_FILTER:
            return t('filters.sectionCodes')
        case START_ACTION_AFTER_FILTER:
            return t('filters.startActionAfter')
        case PRINT_TASKS_FROM_LOG_WITH_ERROR:
            return t('filters.notAvailableForPrint')
        // Сегменты
        case SEGMENT_CREATED:
            return t('segments.created')
        case SEGMENT_CHANGED:
            return t('segments.changed')
        case SEGMENT_STATUS:
            return t('segments.status')
        // Лояльность
        case ACTION_LAST_CHANGED_FILTER:
            return t('filters.actionLastChangedDate')
        case ACTION_WORKING_PERIOD_FILTER:
            return t('filters.actionWorkingPeriod')
        case ACTION_CODE_FILTER:
            return t('filters.actionCode')
        case ACTION_DISCOUNT_TYPE:
            return t('filters.actionDiscountType')
        case ACTION_SECONDARY_RESULT:
            return t('filters.actionSecondaryResult')
        case ACTION_LABEL:
            return t('filters.actionLabel')
        case ACTION_COLOR:
            return t('filters.actionColor')
        case ACTION_AUTHOR:
            return t('filters.actionAuthor')
        case ACTION_EDITOR:
            return t('filters.actionEditor')
        case ACTION_COUPON_USE:
            return t('filters.actionCouponUse')
        case ACTION_COUPON_CODE:
            return t('filters.actionCouponCode')
        case ACTION_COUPON_CATEGORY:
            return t('filters.actionCouponCategory')
        case ACTION_SEGMENT:
            return t('filters.actionSegment')
        case ACTION_CARDS:
            return t('filters.actionCards')
        case USED_DATE:
            return t('loyaltyCoupons.instancesPage.filters.usedDate')
        case CREATED_DATE:
            return t('loyaltyCoupons.instancesPage.filters.createdDate')
        case COUPON_STATUS:
            return t('loyaltyCoupons.instancesPage.filters.status')
        case COUPON_BARCODE:
            return t('loyaltyCoupons.instancesPage.filters.barcode')
        default:
            return type
    }
}

// Функция для нового фильтра.
// Всегда возвращает массив, т.к. некоторые фильтры отправляются на сервер по частям
export const toSearchArgument = (filterType: FilterType, value: string): SearchArgumentVO[] => {
    switch (filterType) {
        case CHECK_TIME_FILTER:
            {
                const values: string[] = value
                    ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER)
                    : ['', '']
                return [
                    createSearchArgumentVO({
                        type: CHECK_COMMIT_BEGIN_DATE_FILTER,
                        value: values[0]
                    }),
                    createSearchArgumentVO({
                        type: CHECK_COMMIT_END_DATE_FILTER,
                        value: values[1]
                    }),
                ]
            }
        case ACTION_START_DATE_FILTER:
        case ACTION_END_DATE_FILTER:
        case PRICES_END_DATE_FILTER:
        case PRICES_START_DATE_FILTER:
        case PRICE_TAGS_PRINTING_DATE_FILTER:
            {
                const values: string[] = value
                    ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER)
                    : ['', '']
                return [
                    createSearchArgumentVO({
                        type: START_DATE_FILTER,
                        value: values[0]
                    }),
                    createSearchArgumentVO({
                        type: END_DATE_FILTER,
                        value: values[1]
                    }),
                ]
            }
        case ACTUAL_PRICES_DATES_FILTER:
            {
                const values: string[] = value
                    ? value.split(BASE_DATE_RANGE_FILTER_DELIMITER)
                    : ['', '']
                return [
                    createSearchArgumentVO({
                        type: ACTUAL_PRICE_DATE_START_FILTER,
                        value: values[0]
                    }),
                    createSearchArgumentVO({
                        type: ACTUAL_PRICE_DATE_END_FILTER,
                        value: values[1]
                    }),
                ]
            }
        case PRODUCT_GROUP_AUTOCOMPLETE:
            return [
                createSearchArgumentVO({
                    type: PRODUCT_GROUP_FILTER,
                    value: value || undefined,
                })
            ]
        case PRODUCT_AUTOCOMPLETE:
            return [
                createSearchArgumentVO({
                    type: PRODUCT_ITEM_OR_BARCODE_FILTER,
                    value: value || undefined,
                })
            ]
        case DEPARTMENT_AUTOCOMPLETE:
            return [
                createSearchArgumentVO({
                    type: DEPARTMENT_FILTER,
                    value: value || undefined,
                })
            ]
        default:
            return [
                createSearchArgumentVO({
                    type: filterType,
                    value: value || undefined,
                })
            ]
    }
}
