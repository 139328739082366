import { XMLTagBaseElement } from './xml-tag-base-element'
import { XMLFontStyle } from './xml-font-style'
import { XMLFontWeight } from './xml-font-weight'
import { XMLTextAlign } from './xml-text-align'
import { XMLTextDecoration } from './xml-text-decoration'

export enum TextStrikedKind {
    NONE = 0,
    FROM_LEFT_TOP_TO_RIGHT_BOTTOM = 1,
    FROM_LEFT_BOTTOM_TO_RIGHT_TOP = 2,
    HORIZONTAL = 4,
    CROSS = 8,
}

export interface XMLTagTextBlock extends XMLTagBaseElement {
    color?: number
    fontFamily?: string
    fontSize?: number
    fontStyle?: XMLFontStyle
    fontWeight?: XMLFontWeight
    striked?: TextStrikedKind
    text?: string
    textAlign?: XMLTextAlign
    textDecoration?: XMLTextDecoration
    htmlText?: string
    wordWrap?: boolean
    textFit?: boolean
}

export function isTextBlock(item: any): item is XMLTagTextBlock {
    if (!item) return false
    if ('color' in item && 'fontFamily' in item && 'fontSize' in item && 'fontStyle' in item && 'fontWeight' in item && 'striked' in item
        && 'text' in item && 'textAlign' in item && 'textDecoration' in item && !('type' in item)) return true
    return false
}
