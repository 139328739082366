export interface ProductsFilter {
    '@class': 'ru.crystals.setretailx.products.common.ProductsFilter'
    good?: string
    productStatusCode?: number
    manufacturerName?: string
    manufacturerCode?: string
    groupCode?: string
    groupName?: string
    departNumber?: number
    departName?: string
    countryCode?: string
    countryName?: string
    typeProductId?: number
    typeProductName?: string
    saleGroupCode?: string
    saleGroupName?: string
}

export const createProductsFilter = (initialization: Partial<ProductsFilter>): ProductsFilter => {
    if (!initialization) return null
    
    let result: ProductsFilter = {
        good: initialization.good,
        productStatusCode: initialization.productStatusCode,
        manufacturerName: initialization.manufacturerName,
        manufacturerCode: initialization.manufacturerCode,
        groupCode: initialization.groupCode,
        groupName: initialization.groupName,
        departNumber: initialization.departNumber,
        departName: initialization.departName,
        countryCode: initialization.countryCode,
        countryName: initialization.countryName,
        typeProductId: initialization.typeProductId,
        typeProductName: initialization.typeProductName,
        saleGroupCode: initialization.saleGroupCode,
        saleGroupName: initialization.saleGroupName,
        '@class': 'ru.crystals.setretailx.products.common.ProductsFilter',
    }
    
    return result
}
