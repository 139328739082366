export interface SearchArgumentVO {
    '@class': 'ru.crystals.operday.vo.SearchArgumentVO'
    type?: string
    value?: string
}

export const createSearchArgumentVO = (initialization: Partial<SearchArgumentVO>): SearchArgumentVO => {
    if (!initialization) return null
    
    let result: SearchArgumentVO = {
        type: initialization.type,
        value: initialization.value,
        '@class': 'ru.crystals.operday.vo.SearchArgumentVO',
    }
    
    return result
}
