import { observable, action, runInAction, toJS, computed } from 'mobx'
import { SaleGroupVO, createSaleGroupVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/sale-group-vo'
import { cloneDeep, isEqual } from 'lodash'
import { goTo } from '../../utils/router-util'
import { SCALES_MODULE, CASHES_LISTS, SCALES_LISTS } from '../../core/app-routes'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE, APP_STORE, APP_BAR_STORE } from '../stores'
import { ProductVO, createProductVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/product-vo'
import { MENU, SaleGroupType } from '../../core/sale-group-type'
import { SimpleProduct } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { AppStore } from '../app-store'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { t } from 'i18next'
import { config } from '../../config/config'
import { toBase64 } from '../../../utils/serialization/base64-util'

export class ScalesProductEditStore {

    @observable
    saleGroup: SaleGroupVO

    @observable
    originalSaleGroup: SaleGroupVO

    @observable
    groupProducts: ProductVO[] = []

    @observable
    originalGroupProducts: ProductVO[] = []

    saleGroupType: SaleGroupType

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    get session(): string {
        return this.userStore.session
    }

    @computed
    get modified(): boolean {
        return !isEqual(toJS(this.groupProducts), toJS(this.originalGroupProducts)) ||
               !isEqual(toJS(this.saleGroup), toJS(this.originalSaleGroup))
    }

    @computed
    get nameHaveSpaces(): boolean {
        if (!this.saleGroup) return false

        const name = this.saleGroup.name
        return name.search(' ') !== -1
    }

    goBack = () => {
        goTo(`${SCALES_MODULE}${this.saleGroupType === MENU ? CASHES_LISTS : SCALES_LISTS}`)
    }

    updateAppBar = () => {
        this.appBarStore.updateState({
            title: this.saleGroup.id === -1 ? t('scalesModuleProductLists.createGroup') : this.saleGroup.name,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                if (this.modified || this.saleGroup.id === -1) {
                    this.appStore.showDialog({
                        title: t('common.notSavedTitle'),
                        message: t('common.notSavedMessage'),
                        mode: DIALOG,
                        onYes: () => this.goBack()
                    })
                } else {
                    this.goBack()
                }
            }
        })
    }

    createSaleGroupDraft = (type: SaleGroupType) => {
        this.saleGroup = createSaleGroupVO({
            id: -1,
            name: '',
            amountOfProducts: 0,
        })

        runInAction(() => {
            this.saleGroupType = type
            this.originalSaleGroup = null
            this.groupProducts = []
            this.originalGroupProducts = []
        })

        this.updateAppBar()
    }

    fetchEditedGroup = async (id: string, type: SaleGroupType) => {
        const [saleGroups, groupProducts] = await Promise.all([
            iProductsManagerLocal.getSaleGroupsByType(
                this.userStore.session,
                type,
            ),
            iProductsManagerLocal.getProductsOfSaleGroup(
                this.userStore.session,
                id,
            ),
        ])

        const editedSaleGroup = saleGroups.find(group => group.code === id)

        if (!editedSaleGroup) {
            this.goBack()
        }

        runInAction(() => {
            this.saleGroupType = type
            this.saleGroup = editedSaleGroup
            this.originalSaleGroup = editedSaleGroup

            this.groupProducts = groupProducts
            this.originalGroupProducts = groupProducts
        })

        this.updateAppBar()
    }

    @action
    updateSaleGroup = (changes: Partial<SaleGroupVO>): void => {
        Object.keys(changes).forEach(key => {
            this.saleGroup[key] = changes[key]
        })
    }

    @action
    addProduct = (product: SimpleProduct) => {
        const newProduct = createProductVO({
            id: product.id,
            name: product.name,
            markingOfTheGood: product.code,
            price: Number(product.maxPrice) / 100,
        })

        const alreadyAdded = this.groupProducts.some(item => item.markingOfTheGood === newProduct.markingOfTheGood)

        if (!alreadyAdded) {
            this.groupProducts.push(newProduct)
        }
    }

    @action
    removeProduct = (product: ProductVO) => {
        this.groupProducts = this.groupProducts.filter(item => item.markingOfTheGood !== product.markingOfTheGood)
    }

    @action
    addMultipleProducts = (products: SimpleProduct[]) => {
        const addedProducts = products.map(product => createProductVO({
            id: product.id,
            name: product.name,
            markingOfTheGood: product.code,
            price: Number(product.maxPrice) / 100,
        }))

        addedProducts.forEach(product => {
            const alreadyAdded = this.groupProducts.some(item => item.markingOfTheGood === product.markingOfTheGood)

            if (!alreadyAdded) {
                this.groupProducts.push(product)
            }
        })
    }

    @action
    removeMultipleProducts = (products: SimpleProduct[]) => {
        const removedProducts = products.map(product => createProductVO({
            id: product.id,
            name: product.name,
            markingOfTheGood: product.code,
            price: Number(product.maxPrice) / 100,
        }))

        this.groupProducts = this.groupProducts.filter(item => {
            const found = removedProducts.find(removedProduct => removedProduct.markingOfTheGood === item.markingOfTheGood)
            return !found ? item : null
        })
    }

    @action
    removeAllProducts = (): void => {
        this.groupProducts = []
    }

    @action
    saveGroupProduts = async () => {
        if (this.saleGroup.id === -1) {
            const createdSaleGroup = await iProductsManagerLocal.addSaleGroup(this.session, toJS(this.saleGroup), this.saleGroupType)

            this.saleGroup = createdSaleGroup
            this.originalSaleGroup = cloneDeep(createdSaleGroup)
        } else {
            if (this.saleGroup.name !== this.originalSaleGroup.name) {
                const updatedSaleGroup = await iProductsManagerLocal.updateSaleGroupName(this.session, this.saleGroup.code, this.saleGroup.name)

                this.saleGroup = updatedSaleGroup
                this.originalSaleGroup = cloneDeep(updatedSaleGroup)
            }
        }

        if (this.groupProducts.length === 0 && this.originalGroupProducts.length > 0) {
            await iProductsManagerLocal.removeAllProductsFromSaleGroup(this.session, this.saleGroup.code)
        } else {
            const productsToAdd = this.groupProducts.filter(item => {
                const found = this.originalGroupProducts.find(originalItem => originalItem.markingOfTheGood === item.markingOfTheGood)
                return !found ? item : null
            })

            if (productsToAdd.length > 0) {
                await iProductsManagerLocal.addProductsListToSaleGroup(
                    this.userStore.session,
                    productsToAdd.map(item => item.markingOfTheGood),
                    this.saleGroup.code,
                )
            }

            const productsToRemove = this.originalGroupProducts.filter(item => {
                const found = this.groupProducts.find(originalItem => originalItem.markingOfTheGood === item.markingOfTheGood)
                return !found ? item : null
            })

            if (productsToRemove.length > 0) {
                await iProductsManagerLocal.removeProductsFromSaleGroup(
                    this.userStore.session,
                    productsToRemove.map(item => item.markingOfTheGood),
                    this.saleGroup.code,
                )
            }
        }

        this.originalGroupProducts = toJS(this.groupProducts)
    }

    @action
    downloadToExcel = () => {
        const newWindow = window.open()

        const encodedCode = toBase64(this.saleGroup.code)
        const url = `${config.reportsAddress}?Action=SALEGROUPREPORT&SG_CODE=${encodedCode}&FILE_TYPE=XLS`

        newWindow.location.replace(url)
    }

    @action
    reset = () => {
        this.saleGroup = null
        this.originalSaleGroup = null
        this.groupProducts = []
        this.originalGroupProducts = []
        this.saleGroupType = null
    }
}
