import { t } from 'i18next'
import { observable, action, runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { goTo, RouteChangeHandler } from '../utils/router-util'
import { NOT_FOUND, VENDORS, INTEGRATION } from '../core/app-routes'
import { LEFT_ARROW, AppBarStore } from './app-bar-store'
import { APP_BAR_STORE, ROUTING_STORE, VENDOR_PAGE_STORE } from './stores'
import { getStore } from './stores-repository'

export interface Vendor {
    name: string
    route: string
}

export const MOBI_VENDOR: Vendor = {
    route: '/md',
    name: 'Моби.Деньги',
}

export const EURO_BANK_CREDIT_VENDOR: Vendor = {
    route: '/ceb',
    name: 'Кредит Европа Банк'
}

export const STOLOTO_VENDOR: Vendor = {
    route: '/sl',
    name: 'Столото'
}

export const vendors: Vendor[] = [EURO_BANK_CREDIT_VENDOR, MOBI_VENDOR, STOLOTO_VENDOR]

export class VendorPageStore {
    @observable
    vendor: Vendor = null

    @action
    openVendor = (vendor: Vendor) => {
        this.vendor = vendor
        goTo(`${INTEGRATION}${VENDORS}${vendor.route}`)
    }

    @action
    reset = (): void => {
        this.vendor = null
    }
}

export const VENDOR_PAGE_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${INTEGRATION}${VENDORS}/[\\w-]+/?$`),
    onEnter: () => {
        const vendorPage: VendorPageStore = getStore(VENDOR_PAGE_STORE)
        const routing: RouterStore = getStore(ROUTING_STORE)

        if (!vendorPage.vendor) {
            let vendorRoute: string = routing.location.pathname.split(VENDORS)[1]
            let vendor: Vendor = vendors.find(v => v.route === vendorRoute)
            if (!vendor) {
                // Вызов goTo приводит к изменению routing.location.pathname, что должно триггерить авторан, следящий за роутами.
                // Этого не происходит, если авторан уже в стеке вызова.
                // Поэтому для подобного редиректа можно переносить изменения роута на следующий таск.
                setTimeout(() => goTo(NOT_FOUND), 0)
                return
            }
            runInAction(() => {
                vendorPage.vendor = vendor
            })
        }

        const appBar: AppBarStore = getStore(APP_BAR_STORE)
        appBar.updateState({
            title: `${t('vendors.integrations')} - ${vendorPage.vendor.name}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => goTo(`${INTEGRATION}${VENDORS}`)
        })
    },
    onLeave: () => {
        const vendorPage: VendorPageStore = getStore(VENDOR_PAGE_STORE)
        vendorPage.reset()
    }
}
