import { RectBounds } from '../rect-bounds'
import { BOTTOM, BOTTOM_LEFT, BOTTOM_RIGHT, ControllerType, LEFT, RIGHT, TOP, TOP_LEFT, TOP_RIGHT } from '../hand-controller'
import { Vector2d } from '../../../utils/math/geom-util'
import { BaseConstraint, EditorState } from './constraint'

export class FixedSizeConstraint extends BaseConstraint<null> {

    constructor(active: boolean = false) {
        super(null, 1, active)
    }

    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d {
        if ([TOP_LEFT, TOP, TOP_RIGHT, RIGHT, BOTTOM_RIGHT, BOTTOM, BOTTOM_LEFT, LEFT].includes(controller) && this.active) {
            return new Vector2d()
        } else {
            return translation
        }
    }

    filterControllers(controllers: ControllerType[]): ControllerType[] {
        return controllers.filter(c => ![TOP_LEFT, TOP, TOP_RIGHT, RIGHT, BOTTOM_RIGHT, BOTTOM, BOTTOM_LEFT, LEFT].includes(c))
    }
}
