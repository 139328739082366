import { observable, action } from 'mobx'
import { iSessionManagerLocal } from '../../protocol/set10/i-session-manager-local'
import {postAuthLogic} from '../utils/app-util'
import { UserStore } from './user-store'
import { getStore } from './stores-repository'
import { USER_STORE } from './stores'
import { internalServerErrorMiddleware } from '../utils/service-middlewares'
import { withSpinner } from './with-spinner'
import { encodeNonLatinSymbols } from '../../utils/name-util'

export class LoginStore {
    @observable
    login: string = ''
    @observable
    password: string = ''
    @observable
    wrongPassword: boolean = false

    private userStore: UserStore = getStore(USER_STORE)

    updateLogin = (login: string): void => {
        this.login = login
        this.wrongPassword = false
    }

    updatePassword = (password: string): void => {
        this.password = password
        this.wrongPassword = false
    }

    submit = () => {
        return withSpinner(iSessionManagerLocal.login(encodeNonLatinSymbols(this.login), encodeNonLatinSymbols(this.password), 'MAIN', {
            requestMiddlewares: [
                config => {
                    // Делать encodeURIComponent необходимо, т.к. axios делает btoa, который не поддерживает символы кириллицы
                    config.auth = {
                        username: encodeNonLatinSymbols(this.login),
                        password: encodeNonLatinSymbols(this.password)
                    }
                }
            ],
            customCommonResponseMiddlewares: [
                internalServerErrorMiddleware
            ]
        }))
            .then(session => {
                console.info('login result: ', session)

                this.userStore.setActiveSession(session)

                return postAuthLogic()
            })
            .catch(error => {
                console.error(error)
                // если ввели неправильные login/password, то логин оставляем, а пароль сбрасываем
                this.password = ''
                this.wrongPassword = true
            })
    }

    @action
    reset = (): void => {
        this.login = ''
        this.password = ''
        this.wrongPassword = false
    }
}
