import { ShopVO } from './shop-vo'
import { ShopsCityGroup } from './shops-city-group'
import { ShopsRegionGroup } from './shops-region-group'

export interface TopologyCondition {
    '@class': 'ru.crystals.discounts.TopologyCondition'
    id?: number
    shops?: ShopVO[]
    shopsCityGroups?: ShopsCityGroup[]
    shopsRegionGroups?: ShopsRegionGroup[]
}

export const createTopologyCondition = (initialization: Partial<TopologyCondition>): TopologyCondition => {
    if (!initialization) return null
    
    let result: TopologyCondition = {
        id: initialization.id,
        shops: initialization.shops,
        shopsCityGroups: initialization.shopsCityGroups,
        shopsRegionGroups: initialization.shopsRegionGroups,
        '@class': 'ru.crystals.discounts.TopologyCondition',
    }
    
    return result
}
