import React from 'react'
import { FilterType, ACTION_DISCOUNT_TYPE } from '../../../core/filters/filter'
import { BaseSelectFilter } from '../components/base-select-filter'
import { DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { t } from 'i18next'
import { InternalFilterComponentProps } from '../new-filters'
import {
    COMPOSITE_APPLY_OBJECT, SET_APPLY_OBJECT, FIX_PRICE_APPLY_OBJECT, DISCOUNT_APPLY_OBJECT, BONUS_DISCOUNT_APPLY_OBJECT
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/plugin-type'

const getOptions = (): DefaultSelectOption[] => [
    {
        label: t('actionsSearch.results.discountForGood'),
        value: COMPOSITE_APPLY_OBJECT
    },
    {
        label: t('actionsSearch.results.discountForSet'),
        value: SET_APPLY_OBJECT
    },
    {
        label: t('actionsSearch.results.fixPrice'),
        value: FIX_PRICE_APPLY_OBJECT
    },
    {
        label: t('actionsSearch.results.bonusesAsDiscount'),
        value: BONUS_DISCOUNT_APPLY_OBJECT
    },
    {
        label: t('actionsSearch.results.calculation'),
        value: DISCOUNT_APPLY_OBJECT
    }
]

export class ActionDiscountType extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_DISCOUNT_TYPE

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <BaseSelectFilter
                id={id}
                defaultValue={value}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                filterType={ActionDiscountType.filterType}
                options={getOptions()}
                slim={slim}
            />
        )
    }
}
