export interface ShiftCloseDocumentVO {
    '@class': 'ru.crystals.setretailx.cash.templates.ShiftCloseDocumentVO'
    id?: number
    documentId?: number
}

export const createShiftCloseDocumentVO = (initialization: Partial<ShiftCloseDocumentVO>): ShiftCloseDocumentVO => {
    if (!initialization) return null
    
    let result: ShiftCloseDocumentVO = {
        id: initialization.id,
        documentId: initialization.documentId,
        '@class': 'ru.crystals.setretailx.cash.templates.ShiftCloseDocumentVO',
    }
    
    return result
}
