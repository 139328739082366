
export const PRODUCT_BONUS_POINTS = 'ProductBonusPointsEntity'
export const PRODUCT_SPIRITS = 'ProductSpiritsEntity'
export const PRODUCT_CFT_GIFT_CARD = 'ProductCFTGiftCardEntity'
export const PRODUCT_GIFT_CARD_ENTITY = 'ProductGiftCardEntity'
export const PRODUCT_JEWEL_ENTITY = 'ProductJewelEntity'
export const PRODUCT_CIGGY_ENTITY = 'ProductCiggyEntity'
export const PRODUCT_PIECE_ENTITY = 'ProductPieceEntity'
export const PRODUCT_PIECE_WEIGHT_ENTITY = 'ProductPieceWeightEntity'
export const PRODUCT_WEIGHT_ENTITY = 'ProductWeightEntity'
export const PRODUCT_METRIC_ENTITY = 'ProductMetricEntity'
export const PRODUCT_MOBILE_PAY_ENTITY = 'ProductMobilePayEntity'
export const PRODUCT_DISCOUNT_CARD_ENTITY = 'ProductDiscountCardEntity'
export const PRODUCT_SIEBEL_GIFT_CARD_ENTITY = 'ProductSiebelGiftCardEntity'
