import { extendObservable, action } from 'mobx'

export const DEFAULT_HISTORY_MANAGER_LENGTH: number = 20

export class HistoryManager<T = any> {
    protected _items: T[] = []
    protected _currentIndex: number = -1
    protected _maxLength: number

    constructor(maxLength: number = DEFAULT_HISTORY_MANAGER_LENGTH) {
        this.setMaxLengthImpl(maxLength)
    }

    /**
     * Максимальная длина "истории"
     */
    get maxLength(): number {
        return this.getMaxLengthImpl()
    }

    set maxLength(value: number) {
        this.setMaxLengthImpl(value)
    }

    /**
     * Текущая длина "истории"
     */
    get length(): number {
        return this.getLengthImpl()
    }

    /**
     * Текущий элемент
     */
    get currentItem(): T {
        return this.getCurrentItemImpl()
    }

    get isSetToLastItem(): boolean {
        return this.getIsSetToLastItemImpl()
    }

    get isSetToFirstItem(): boolean {
        return this.getIsSetToFirstItemImpl()
    }

    /**
     * Все элементы "истории"
     */
    get items(): T[] {
        return this.getItemsImpl()
    }

    /**
     * Индекс текущего элемента
     */
    get currentIndex(): number {
        return this.getCurrentIndexImpl()
    }

    /**
     * Добавляет элемент в "историю" на позицию, следующую за currentIndex.
     * Все элементы после currentIndex - отбрасываются.
     * Первый добавленный элемент считается начальным (нулевым) и удаляется только через метод clear
     * Для полного сброса следует использовать метод clear.
     */
    addItem = (item: T): void => {
        /*
         * Выбран элемент с индексом 2
         *        _
         * [0, 1, 2, 3, 4]
         *
         * После вызова addItem(5) должно быть:
         *           _
         * [0, 1, 2, 5]
         */
        const poppedElementsCount: number = this._items.length - 1 - this._currentIndex

        if (poppedElementsCount > 0) {
            this._items.splice(this._currentIndex + 1, poppedElementsCount)
        }

        if (this._items.length === this._maxLength) {
            // Удаляем элемент, следующий за начальным (начальный остаётся)
            this._items.splice(1, 1)
        }

        this._items.push(item)
        this._currentIndex = this._items.length - 1
    }

    /**
     * Устанавливает элемент с заданным index в качестве текущего
     */
    setToItem = (index: number): void => {
        if (this._items.length > 0) {
            let correctedIndex = Math.floor(index)

            correctedIndex = Math.max(correctedIndex, 0)
            correctedIndex = Math.min(correctedIndex, this._items.length - 1)

            this._currentIndex = correctedIndex
        }
    }

    /**
     * Устанавливает предыдущий элемент в качестве текущего
     */
    setToPreviousItem = (): void => {
        if (this._currentIndex > 0) {
            this.setToItem(this._currentIndex - 1)
        }
    }

    /**
     * Устанавливает следующий элемент в качестве текущего
     */
    setToNextItem = (): void => {
        if (this._currentIndex < this._items.length - 1) {
            this.setToItem(this._currentIndex + 1)
        }
    }

    /**
     * Очищает "историю"
     */
    clear = (): void => {
        this._items = []
        this._currentIndex = -1
    }

    /* Имплементации методов вынесены отдельно для того,
    чтобы была возможность переопределить геттеры как computed,
    используя extendObservable
    https://github.com/mobxjs/mobx/issues/527
    */
    protected getMaxLengthImpl(): number {
        return this._maxLength
    }

    protected setMaxLengthImpl(value: number) {
        let correctedValue = Math.floor(value)

        correctedValue = Math.max(correctedValue, 1)
        correctedValue = Math.min(correctedValue, Number.MAX_SAFE_INTEGER)

        this._maxLength = correctedValue
        // Если maxLength изменилось в меньшую сторону, необходимо удалить лишние элементы из массива
        this.truncItems()
    }

    protected getLengthImpl(): number {
        return this._items
            ? this._items.length
            : 0
    }

    protected getCurrentItemImpl(): T {
        return this._currentIndex !== -1
            ? this._items[this._currentIndex]
            : undefined
    }

    protected getIsSetToLastItemImpl(): boolean {
        if (this._currentIndex === -1) return false

        return this._currentIndex === this.getLengthImpl() - 1
    }

    protected getIsSetToFirstItemImpl(): boolean {
        if (this._currentIndex === -1) return false

        return this._currentIndex === 0
    }

    protected getItemsImpl(): T[] {
        return this._items
    }

    protected getCurrentIndexImpl(): number {
        return this._currentIndex !== -1
            ? this._currentIndex
            : undefined
    }

    /**
     * Обрезает массив хранимых элементов, если текущая длина "истории" больше, чем максимальная
     */
    private truncItems(): void {
        // Удаляем элементы от начала массива
        const removedElementsCount: number = this._items.length - this._maxLength

        if (removedElementsCount > 0) {
            this._items.splice(0, removedElementsCount)
            this._currentIndex = Math.max(this._currentIndex - removedElementsCount, 0)
        }
    }
}

// Класс, который делает поля и методы HistoryManager observable - именно его стоит использовать с mobx
export class ObservableHistoryManager<T = any> extends HistoryManager<T> {
    constructor(maxLength: number = DEFAULT_HISTORY_MANAGER_LENGTH) {
        super(maxLength)

        extendObservable(this, {
            _items: this._items,
            _currentIndex: this._currentIndex,
            _maxLength: this._maxLength,
            maxLength: this.maxLength,

            /* Из документации к методу extendObservable из mobx версии 3:
            If a value of the propertyMap is a getter function, a computed property will be introduced. */
            get length() { return this.getLengthImpl() },
            get historyOverwritten() { return this.getHistoryOverwrittenImpl() },
            get currentItem() { return this.getCurrentItemImpl() },
            get isSetToLastItem() { return this.getIsSetToLastItemImpl() },
            get isSetToFirstItem() { return this.getIsSetToFirstItemImpl() },
            get items() { return this.getItemsImpl() },
            get currentIndex() { return this.getCurrentIndexImpl() },

            addItem: action(this.addItem),
            setToItem: action(this.setToItem),
            setToPreviousItem: action(this.setToPreviousItem),
            setToNextItem: action(this.setToNextItem),
            clear: action(this.clear),
        })
    }
}
