export interface BaseVO {
    '@class': 'ru.crystals.cards.common.BaseVO' | 'ru.crystals.cards.common.OperationVO' | 'ru.crystals.cards.common.CardTypeVO' | 'ru.crystals.cards.common.InternalCardsVO' | 'ru.crystals.cards.presentcards.PresentCardsVO' | 'ru.crystals.cards.coupons.vo.CouponsVO' | 'ru.crystals.cards.coupons.vo.UniqueCouponVO' | 'ru.crystals.cards.externalcards.ExternalCardsVO' | 'ru.crystals.cards.common.CardRangeVO' | 'ru.crystals.cards.common.CardVO' | 'ru.crystals.cards.common.ClientVO' | 'ru.crystals.cards.common.ActionVO'
    id?: number
}

export const createBaseVO = (initialization: Partial<BaseVO>): BaseVO => {
    if (!initialization) return null
    
    let result: BaseVO = {
        id: initialization.id,
        '@class': 'ru.crystals.cards.common.BaseVO',
    }
    
    return result
}
