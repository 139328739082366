import { observable, action, runInAction, toJS, computed } from 'mobx'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE, APP_BAR_STORE, NAVIGATION_MENU_STORE } from '../stores'
import { UserStore } from '../user-store'
import { RouteChangeHandler } from '../../utils/router-util'
import { MENU, AppBarStore } from '../app-bar-store'
import { t } from 'i18next'
import { NavigationMenuStore } from '../navigation-menu-store'
import { CARD_CATEGORIES, CARDS, CARDS_SEARCH, CARDS_NORMAL, EXTERNAL_CARDS } from '../../core/app-routes'
import { withSpinner } from '../with-spinner'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import { createCardsFilterVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cards-filter-vo'
import { CardVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/card-vo'
import { ClientVO, createClientVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/client-vo'
import { NotSpecified, ReceiptFeedbackTypes, Active, CardBlockingReason, InternalCards } from '../../core/cards/card-constants'
import { createClientAddress } from '../../../protocol/set10/set-retail10-commons/data-structs-module/client-address'
import { createPassportVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/passport-vo'
import { createSendBy } from '../../../protocol/set10/set-retail10-commons/data-structs-module/send-by'
import { BonusAccountVO } from '../../../protocol/set10/set-retail10-commons/set-cards-internal-cards/bonus-account-vo'
import { createTimestampPeriod } from '../../../protocol/set10/set-retail10-commons/data-structs-module/timestamp-period'
import { BonusAccountTransVO } from '../../../protocol/set10/set-retail10-commons/set-cards-internal-cards/bonus-account-trans-vo'
import { DateTypes, WEEK, MONTH, YEAR } from '../../../utils/date/date-format'
import { fromClientToServerTime } from '../../utils/app-util'
import { ActionVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/action-vo'
import { OperationVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/operation-vo'
import { iInternalCardAccountProsessingRemote } from '../../../protocol/set10/i-internal-card-account-prosessing-remote'
import { SNACKBAR_EXTENDED_DURATION } from '../../../utils/default-timeouts'
import { CardsSearchState } from '../../pages/cards/cards-search/cards-search'
import { formatPrice } from '../../core/currency-utils'
import { BONUSES } from '../../../protocol/set10/set-retail10-server/cards/set-cards-internal-cards/card-info'
import {
    BonusAccountsBalanceAndTypeVO
} from '../../../protocol/set10/set-retail10-server/cards/set-cards-internal-cards/bonus-accounts-balance-and-type-vo'
import { cloneDeep, set, isEqual } from 'lodash'
import { emailRegExp } from '../../../utils/form-validation/text-regexp-utils'
import { reportsProcessorLocal } from '../../../protocol/set10/reports-processor-local'
import { CounterpartyVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/counterparty-vo'

const getDefaultCardSearchState = (): CardsSearchState => ({
    extendeadSearch: false,
    filterString: '',
    cardNumber: '',
    fullName: '',
    day: null,
    month: null,
    year: null,
})

export class CardsSearchStore {

    appStore: AppStore = getStore(APP_STORE)
    userStore: UserStore = getStore(USER_STORE)

    @observable
    cardSearchState: CardsSearchState = getDefaultCardSearchState()

    @observable
    card: CardVO

    @observable
    editedClient: ClientVO

    @observable
    originalClient: ClientVO

    @observable
    period: DateTypes = MONTH

    @observable
    accumulationBalance: string = (0).toFixed(2)

    @observable
    bonusBalance: string = (0).toFixed(2)

    @observable
    cardBonusAccounts: BonusAccountVO[]

    @observable
    bonusAccountsBalances: BonusAccountsBalanceAndTypeVO[]

    @observable
    cardBonusHistory: BonusAccountTransVO[]

    @observable
    cardOperations: OperationVO[]

    @observable
    cardActions: ActionVO[]

    @observable
    linkedCards: CardVO[]

    // Переменная для "замены карты"
    @observable
    newCard: CardVO

    @observable
    foundCards: CardVO[]

    @observable
    counterParty: CounterpartyVO

    @computed
    get cardProfileValid(): boolean {
        if (!this.card?.cardTypeVO?.personalized) return true
        return Boolean(this.card.clientVO)
    }

    @computed
    get canSaveNewClient(): boolean {
        return Boolean(this.editedClient?.firstName && this.editedClient?.lastName)
    }

    // Ищем в базовом варианте сразу по номеру и фамилии
    fetchCardsByNumberAndName = async (filterString: string): Promise<void> => {
        runInAction(() => {
            this.foundCards = null
        })

        // Без параметров возвращает слишком много карт
        if (!filterString.trim()) return

        // Если цифры есть в строке, то ищем но номеру, иначе по фамилии
        const isCardNumber = filterString?.search(/\d/) !== -1

        let result: CardVO[]
        if (isCardNumber) {
            const filterWithoutSpaces = filterString.replace(/ /g, '')
            result = await withSpinner(iCardsManagerRemote.getCardsByFilter1(
                this.userStore.session,
                createCardsFilterVO({
                    number: filterWithoutSpaces,
                }),
                0,
                1000
            ))
        } else {
            result = await withSpinner(iCardsManagerRemote.getCardsByFilter1(
                this.userStore.session,
                createCardsFilterVO({
                    lastName: `use_like:%${filterString}%`
                }),
                0,
                1000
            ))
        }

        // Оставляем только внутренние карты
        result = result.filter(item => item.cardTypeVO.classType === InternalCards)

        // Если найдено несколько экземпляров, показываем список
        // Если одна карта, сразу переходим к ней
        runInAction(() => {
            if (result.length === 0) {
                this.foundCards = []
                this.card = null
                return
            }

            if (result.length > 1) {
                this.foundCards = result
                this.card = null
            } else {
                this.setCard(result[0])
            }
        })
    }

    fetchCardsExtended = async (
        cardNumber: string,
        fullName: string,
        day: number,
        month: number,
        year: number,
    ): Promise<void> => {
        runInAction(() => {
            this.foundCards = null
        })

        let date: Date
        if (year && month && day) {
            date = new Date(year, month - 1, day, 12)
        }

        // Без параметров возвращает слишком много карт
        if (!cardNumber && !fullName && !date) {
            return
        }

        let filter = createCardsFilterVO({
            number: cardNumber || undefined,
            lastName: fullName ? `use_like:%${fullName}%` : undefined,
            birthDate: date || undefined
        })

        let result = await withSpinner(iCardsManagerRemote.getCardsByFilter1(
            this.userStore.session,
            filter,
            0,
            1000
        ))

        // Оставляем только внутренние карты
        result = result.filter(item => item.cardTypeVO.classType === InternalCards)

        /**
         * Если поиск был комбинированный, то надо отфильтровать все "пустые карты"
         * Из-за особенностей бэка, он увидит, что номер карты есть в категории,
         * а из-за фамили нужную карту не найдет, и предложит создать новую
         */
        if (fullName && date) {
            result = result.filter(item => item.guid)
        }

        // Если найдено несколько экземпляров, показываем список
        // Если одна карта, сразу переходим к ней
        runInAction(() => {
            if (result.length === 0) {
                this.foundCards = []
                this.card = null
                return
            }

            if (result.length > 1) {
                this.foundCards = result
                this.card = null
            } else {
                this.setCard(result[0])
            }
        })
    }

    fetchBonusHistory = async (): Promise<void> => {
        let dateStart = new Date()
        const now = new Date()

        switch (this.period) {
            case WEEK:
                dateStart.setDate(dateStart.getDate() - 7)
                break
            case MONTH:
                dateStart.setMonth(dateStart.getMonth() - 1)
                break
            case YEAR:
                dateStart.setFullYear(dateStart.getFullYear() - 1)
                break
        }

        const bonusHistory = await withSpinner(iInternalCardAccountProsessingRemote.getBonusAccountHistory(
            this.card.number,
            fromClientToServerTime(dateStart),
            fromClientToServerTime(now)
        ))

        runInAction(() => {
            this.cardBonusHistory = bonusHistory
        })
    }

    fetchBonusAccounts = async (): Promise<void> => {
        const bonusAccounts = await iCardsManagerRemote.getBonusAccountsByCard(this.userStore.session, toJS(this.card))

        // Бэкенд не умеет воспринимать Enum. Данный способ подсказвает бэку, что использован именно Enum
        const cardBonusesInfo: any = {
            '@class': 'ru.crystals.cards.internalcards.entities.enums.CardInfo',
            value: BONUSES
        }

        const specificInfo = await iCardsManagerRemote.getSpecificCardInformationByNumber(
            this.card.number,
            [cardBonusesInfo]
        )

        runInAction(() => {
            this.cardBonusAccounts = bonusAccounts || []
            this.bonusAccountsBalances = specificInfo?.bonusAccountsBalanceAndTypeVO || []
        })
    }

    fetchActions = async (): Promise<void> => {
        const cardActions = await iCardsManagerRemote.getActionsByCard(
            this.userStore.session,
            toJS(this.card)
        )

        runInAction(() => {
            this.cardActions = cardActions
        })
    }

    fetchOperations = async (): Promise<void> => {
        let dateStart = new Date()
        const now = new Date()

        switch (this.period) {
            case WEEK:
                dateStart.setDate(dateStart.getDate() - 7)
                break
            case MONTH:
                dateStart.setMonth(dateStart.getMonth() - 1)
                break
            case YEAR:
                dateStart.setFullYear(dateStart.getFullYear() - 1)
                break
        }

        const operations = await withSpinner(iInternalCardAccountProsessingRemote.getAccumulationHistoryByCard(
            createTimestampPeriod({
                start: fromClientToServerTime(dateStart),
                finish: fromClientToServerTime(now)
            }),
            this.card.number
        ))

        runInAction(() => {
            this.cardOperations = operations
        })
    }

    fetchCardAccumulationBalance = async (): Promise<void> => {
        const accumulationBalance = await iInternalCardAccountProsessingRemote.getCardAccumulationBalance(this.card.number)
        const fixedBalance = accumulationBalance[1]

        runInAction(() => {
            if (fixedBalance && fixedBalance > 0) {
                this.accumulationBalance = formatPrice(fixedBalance / 100)
            } else {
                this.accumulationBalance = formatPrice(0)
            }
        })
    }

    fetchBonusBalance = async (): Promise<void> => {
        const activeAccounts = await iInternalCardAccountProsessingRemote.getActiveBonusAccounts(this.card.number)

        const value = activeAccounts.reduce((prevValue: number, item: BonusAccountVO) => {
            return prevValue + Number(item.balance)
        }, 0)

        runInAction(() => {
            this.bonusBalance = value.toLocaleString()
        })
    }

    fetchLinkedCards = async (): Promise<void> => {
        const linkedCards = await iCardsManagerRemote.getCardsByFilter1(
            this.userStore.session,
            createCardsFilterVO({
                clientId: this.card.clientVO.id
            }),
            null,
            null
        )

        runInAction(() => {
            this.linkedCards = this.sortLinkedCards(linkedCards || [])
        })
    }

    fetchCounterParty = async (): Promise<void> => {
        const counterParty = await withSpinner(reportsProcessorLocal.findCounterparty1(this.card.counterpartyId))

        runInAction(() => {
            this.counterParty = counterParty
        })
    }

    // Сортируем карты с учетом вложенности подчиненных, вложенные должны идти после родительских
    sortLinkedCards = (cards: CardVO[]): CardVO[] => {
        let newCards = cards.sort((a: CardVO, b: CardVO) => {
            const numberA = a.mainCard ? a.mainCard.number : a.number
            const numberB = b.mainCard ? b.mainCard.number : b.number

            // Сначала сортируем вместе связанные карты (родитель или подчиненная одного номера)
            if (numberA < numberB) return -1
            if (numberA > numberB) return 1

            // Если номер А и Б одинаковый, но одна из карт основная (без mainCard), выводим выше основную
            if (a.mainCard && !b.mainCard) return 1
            if (b.mainCard && !a.mainCard) return -1

            // Тут мы окажемся, если у обоих есть главная карта (mainCard) и она одинаковая
            if (a.number < b.number) return -1
            if (a.number > b.number) return 1

            return 0
        })

        return newCards
    }

    @action
    setCard = (card: CardVO): void => {
        if (!card) return
        // Обнуляем чтобы ничего не осталось от прошлой карты
        this.reset()

        // В строку поиска подставляем номер карты
        this.cardSearchState = {
            extendeadSearch: false,
            filterString: card.number,
            cardNumber: '',
            fullName: '',
            day: null,
            month: null,
            year: null,
        }

        this.card = card

        this.editedClient = cloneDeep(card.clientVO)
        this.originalClient = cloneDeep(card.clientVO)

        if (!this.editedClient && card.cardTypeVO.personalized) {
            this.editedClient = this.getDefaultClient()
        }

        this.fetchBonusAccounts()
        this.fetchActions()
        this.fetchCardAccumulationBalance()
        this.fetchBonusBalance()
        if (card.counterpartyId) {
            this.fetchCounterParty()
        }

        if (card.clientVO) {
            this.fetchLinkedCards()
        }
    }

    addAccumulation = async (operation: OperationVO): Promise<void> => {
        await withSpinner(iCardsManagerRemote.addAccumulation(
            this.userStore.session,
            this.card.number,
            operation
        ))
    }

    checkNewCard = async (cardNumber: string): Promise<CardVO> => {
        if (!cardNumber) return

        const result = await withSpinner(iCardsManagerRemote.getCardsByFilter1(
            this.userStore.session,
            createCardsFilterVO({
                number: cardNumber,
            }),
            0,
            1000
        ))

        if (result?.length > 0) {
            runInAction(() => {
                this.newCard = result[0]
            })
        }

        return result?.[0]
    }

    replaceCard = async (cause: string = '', comment: string = ''): Promise<CardVO> => {
        let newCard = toJS(this.newCard)

        newCard.status = this.card.status

        if (!newCard.status) {
            newCard.status = Active
        }
        newCard.activationDate = fromClientToServerTime(new Date())

        const card = await withSpinner(iCardsManagerRemote.changeInternalCard(
            this.userStore.session,
            {
                ...toJS(this.card),
                id: 0,
                guid: 0,
            },
            newCard,
            cause,
            comment,
        ))

        this.appStore.showSnackbar({
            message: t('cardsSearch.replaceCard.replaceDone', {
                category: card.cardTypeVO.name,
                oldNumber: card.number,
                newNumber: newCard.number
            }),
            variant: 'success',
            duration: SNACKBAR_EXTENDED_DURATION,
        })

        runInAction(() => {
            this.card = null
            this.newCard = null
        })

        return card
    }

    blockCard = async (cause: CardBlockingReason, comment: string): Promise<void> => {
        await withSpinner(iCardsManagerRemote.blockCard1(
            this.userStore.session,
            toJS(this.card),
            cause,
            comment,
            this.userStore.userInfo
        ))

        this.appStore.showSnackbar({
            message: t('cardsSearch.blockCard.cardBlocked', {
                category: this.card.cardTypeVO.name,
                number: this.card.number,
            }),
            variant: 'error',
            duration: SNACKBAR_EXTENDED_DURATION,
        })

        runInAction(() => {
            this.card = null
            this.newCard = null
        })
    }

    addNewCard = async (): Promise<CardVO> => {
        let newCard = toJS(this.newCard)
        newCard.clientVO = toJS(this.card.clientVO)
        newCard.activationDate = fromClientToServerTime(new Date())

        return this.createNewCard(newCard)
    }

    addNewSubordinateCard = async (): Promise<CardVO> => {
        let newCard = toJS(this.newCard)
        newCard.clientVO = toJS(this.card.clientVO)
        newCard.mainCard = toJS(this.card)
        newCard.activationDate = fromClientToServerTime(new Date())

        return this.createNewCard(newCard)
    }

    createNewCard = async (newCard: CardVO): Promise<CardVO> => {
        const card = await withSpinner(iCardsManagerRemote.createNewCard(
            this.userStore.session,
            newCard,
        ))

        this.appStore.showSnackbar({
            message: t('cardsSearch.addNewCard.cardCreated', {
                category: newCard.cardTypeVO.name,
                number: newCard.number
            }),
            variant: 'success',
            duration: SNACKBAR_EXTENDED_DURATION,
        })

        this.removeNewCard()

        return card
    }

    @action
    removeNewCard = (): void => {
        this.newCard = null
    }

    @action
    getDefaultClient = (): ClientVO => {
        return createClientVO({
            id: -1,
            guid: null,
            deleted: false,
            firstName: '',
            middleName: '',
            lastName: '',
            clientType: 'PRIVATE',
            birthDate: '',
            sex: NotSpecified,
            marital: false,
            auto: false,
            clientAddress: createClientAddress({
                zip: '',
                city: '',
                district: '',
                region: '',
                districtArea: '',
                other: '',
                street: '',
                house: '',
                building: '',
                appartment: ''
            }),
            passport: createPassportVO({
                passSerie: '',
                passNumber: '',
                delivery: '',
                deliveryDate: ''
            }),
            phone: '',
            email: '',
            sendBy: createSendBy({
                byMail: false,
                bySMS: false,
                byPhone: false,
                byEMail: false
            }),
            mobilePhone: '',
            mobileOperator: '',
            shopNumber: '',
            sendCatalog: false,
            childrenAge: '',
            isCompleted: false,
            bonusBalance: null,
            receiptFeedbackType: ReceiptFeedbackTypes.None,
            smartphoneType: null,
            wantsECard: false
        })
    }

    @action
    updateEditedCardClient = (value: any, path: string): void => {
        let changes = { ...this.editedClient }
        set(changes, path, value)

        const updatedProp = path.split('.')[0]

        this.editedClient[updatedProp] = changes[updatedProp]
    }

    saveEditedCardClient = async (): Promise<void> => {
        const { session } = this.userStore

        let savedClient = toJS(this.editedClient)

        if (!savedClient.isCompleted && savedClient.firstName && savedClient.lastName) {
            savedClient.isCompleted = true
        }

        if (savedClient.email && !emailRegExp.test(savedClient.email)) {
            savedClient.email = ''
        }

        let newClient: ClientVO
        if (!this.originalClient) {
            const result = await withSpinner(iCardsManagerRemote.addClientAndSetToCard(
                session,
                {
                    ...toJS(this.card),
                    clientVO: undefined
                },
                savedClient
            ))
            newClient = result?.clientVO
            runInAction(() => {
                this.card = result
            })
        } else {
            newClient = await withSpinner(iCardsManagerRemote.storeClient(
                session,
                savedClient
            ))
            runInAction(() => {
                this.card.clientVO = newClient
            })
        }

        runInAction(() => {
            this.editedClient = cloneDeep(newClient)
            this.originalClient = cloneDeep(newClient)
        })

        this.appStore.showSnackbar({
            message: t('cardsSearch.clientInfo.clientSaved'),
            variant: 'success'
        })
    }

    @action
    setCardSearchState = (changes: Partial<CardsSearchState>) => {
        Object.keys(changes).forEach(key => {
            this.cardSearchState[key] = changes[key]
        })
    }

    @action
    setPeriod = (period: DateTypes) => {
        this.period = period
    }

    @action
    reset = (): void => {
        this.foundCards = undefined
        this.card = undefined
        this.newCard = undefined
        this.linkedCards = undefined
        this.cardBonusAccounts = undefined
        this.bonusAccountsBalances = undefined
        this.cardBonusHistory = undefined
        this.cardOperations = undefined
        this.cardActions = undefined
        this.bonusBalance = undefined
        this.accumulationBalance = undefined
        this.period = MONTH
        this.editedClient = undefined
        this.originalClient = undefined
    }

    @action
    clearSearch = (): void => {
        this.cardSearchState = getDefaultCardSearchState()
        this.foundCards = undefined
        this.card = undefined
    }
}

// Для того чтобы сохранить боковое меню и не срабатывал обработчик захода в глубокие страницы
export const CARDS_ROUTE_CHANGE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${CARDS}${CARDS_SEARCH}(${CARDS_NORMAL}|${CARD_CATEGORIES}|${EXTERNAL_CARDS})$`),
    onEnter: (newRoute: string, prevRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)

        appBarStore.updateState({
            title: t('set10.cards'),
            leftIcon: MENU,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    },
}
