import React from 'react'
import uuid from 'uuid'

type DimensionsForFit = 'width' | 'height' | 'width-and-height'

interface TextFitProps extends React.HTMLProps<HTMLDivElement> {
    children: React.ReactNode | React.ReactNode[]
    minFontSize: number
    step: number
    dimensionsForFit: DimensionsForFit
    /**
     * Если этот флаг true, то компонент не будет перерендериваться при изменении children
     * В этом случае задать условия перерендера компонента можно в key из места вызова
     * Часто это нужно для производительности
     */
    ignoreChildrenUpdate: boolean
    secondCheckTO?: number
}

/* вероятно, мы хотим, чтобы компонент тянулся по высоте/ширине родителя
* для этого нужно в месте использования родителю задать display: flex, а самому TextFit прокинуть стиль flex: 1 */
export class TextFit extends React.Component<TextFitProps> {

    static defaultProps: Partial<TextFitProps> = {
        secondCheckTO: 300
    }

    outerId: string = null
    innerId: string = null

    timer1: any = null
    timer2: any = null

    initialFontSize: number = null

    mounted: boolean = false

    constructor(props: TextFitProps) {
        super(props)
        this.outerId = uuid()
        this.innerId = uuid()
    }

    shouldComponentUpdate(nextProps: Readonly<TextFitProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        if (nextProps.ignoreChildrenUpdate) {
            return ['minFontSize', 'step', 'dimensionsForFit', 'ignoreChildrenUpdate'].some(key => {
                return nextProps[key] !== this.props[key]
            })
        }

        return true
    }

    componentDidMount() {
        this.mounted = true
        /* TODO this.outer == undefined в тестах */
        this.outer.style.opacity = '0' // to prevent flickering while adjusting

        this.setInitialFontSize()
        this.scheduleAdjustFontSize()
    }

    componentWillUnmount() {
        this.mounted = false

        clearTimeout(this.timer1)
        clearTimeout(this.timer2)
    }

    componentDidUpdate(prevProps: Readonly<TextFitProps>, prevState: Readonly<{}>, snapshot?: any): void {
        this.outer.style.fontSize = `${this.initialFontSize}px`
        this.outer.style.opacity = '0' // to prevent flickering while adjusting

        this.scheduleAdjustFontSize()
    }

    setInitialFontSize() {
        this.initialFontSize = this.currentFontSize
    }

    scheduleAdjustFontSize = () => {
        clearTimeout(this.timer1)
        this.timer1 = setTimeout(() => {
            this.adjustFontSize()
        })

        /* вторая проверка adjustFontSize нужна для того, чтобы все children элемента успели срендериться
        * после этого вводные данные для textAdjust часто меняются  */
        clearTimeout(this.timer2)
        this.timer2 = setTimeout(() => {
            this.adjustFontSize()
        }, this.props.secondCheckTO)
    }

    adjustFontSize = () => {
        // to prevent infinite loop when
        const outerFontSize = parseInt(this.outer.style.fontSize, 10)
        if (outerFontSize && outerFontSize !== this.currentFontSize) {
            this.outer.style.opacity = 'inherit'
            return
        }

        if (this.currentFontSize <= this.props.minFontSize) {
            this.outer.style.opacity = 'inherit'
            return
        }

        if (this.doesElementFit()) {
            this.outer.style.opacity = 'inherit'
            return
        }

        if (!this.mounted) {
            return
        }

        this.outer.style.fontSize = `${this.currentFontSize - this.props.step}px`

        this.adjustFontSize()
    }

    doesElementFit = (): boolean => {
        const { dimensionsForFit } = this.props

        const outerStyles = window.getComputedStyle(this.outer)
        const innerStyles = window.getComputedStyle(this.inner)

        switch (dimensionsForFit) {
            default:
            case 'height':
                return parseInt(outerStyles.height, 10)
                    >= parseInt(innerStyles.height, 10)
            case 'width':
                return parseInt(outerStyles.width, 10)
                    >= parseInt(innerStyles.width, 10)
            case 'width-and-height':
                return parseInt(outerStyles.height, 10)
                    >= parseInt(innerStyles.height, 10)
                    && parseInt(outerStyles.width, 10)
                    >= parseInt(innerStyles.width, 10)
        }
    }

    get outer(): HTMLDivElement {
        return document.getElementById(this.outerId) as HTMLDivElement
    }

    get inner(): HTMLDivElement {
        return document.getElementById(this.innerId) as HTMLDivElement
    }

    get currentFontSize(): number {
        return parseInt(window.getComputedStyle(this.outer).fontSize, 10)
    }

    render() {
        const { children, minFontSize, step, dimensionsForFit, ignoreChildrenUpdate, secondCheckTO, ...other } = this.props

        return (
            <div
                {...other}
                id={this.outerId}
                style={{
                    fontSize: 'inherit',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    ...(other.style || {})
                }}
            >
                <div
                    id={this.innerId}
                    style={{
                        /* to make its width stretched according to children width */
                        float: 'left',
                        width: 'auto'
                    }}
                >
                    { children }
                </div>
            </div>
        )
    }
}
