import {EquipmentSettingVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-setting-vo'

export const CHWAY_NAME: KeyboardName = 'ChWay KB8078'
export const GEG_NAME: KeyboardName = 'GEG Kraftway KB-78M-12'
export const HENG_60_NAME: KeyboardName = 'Heng Yu S60C'
export const HENG_78A_NAME: KeyboardName = 'Heng Yu S78A'
export const HENG_78D_NAME: KeyboardName = 'Heng Yu S78D'
export const HENG_112_NAME: KeyboardName = 'Heng Yu S112a'
export const NCR_5932_NAME: KeyboardName = 'Ncr 5932'
export const NCR_KB78_NAME: KeyboardName = 'NCR KB78M'
export const PARNTER_KB78_NAME: KeyboardName = 'Partner-KB78'
export const POSIFLEX_NAME: KeyboardName = 'posiflex'
export const POSUA_NAME: KeyboardName = 'Posua-64'
export const POSUA_96_NAME: KeyboardName = 'Posua-96'
export const POSUA_96_QWERTY_NAME: KeyboardName = 'Posua-96-qwerty'
export const POSUA_128: KeyboardName = 'Posua-128'
export const MCI_80_NAME: KeyboardName = 'preh2_mci80'
export const PREH_NAME: KeyboardName = 'PREH'
export const CSI_MCI_84_NAME: KeyboardName = 'csi_mci84'
export const MCI_96_NAME: KeyboardName = 'preh_mci96usb'
export const QWERTY_NAME: KeyboardName = 'qwerty'
export const PP306_NAME: KeyboardName = 'Штрих PP306'
export const SPARK_NAME: KeyboardName = 'Spark-KB2078'
export const VIKIPOS_NAME: KeyboardName = 'VIKIPOS'
export const MSI_60_NAME: KeyboardName = 'vikipos_msi60'
export const WINCOR_NAME: KeyboardName = 'Wincor-Nixdorf-84'

export type KeyboardName = 'ChWay KB8078' | 'GEG Kraftway KB-78M-12' | 'Heng Yu S60C' | 'Heng Yu S78A' | 'Heng Yu S78D' | 'Heng Yu S112a' |
    'Heng Yu S112a' | 'Ncr 5932' | 'NCR KB78M' | 'Partner-KB78' | 'posiflex' | 'Posua-64' | 'Posua-96' | 'Posua-96-qwerty' | 'Posua-128' |
    'preh2_mci80' | 'PREH' | 'csi_mci84' | 'preh_mci96usb' | 'qwerty' | 'Штрих PP306' | 'Spark-KB2078' | 'VIKIPOS' | 'vikipos_msi60' |
    'Wincor-Nixdorf-84'

export const CHWAY_LAYUOT: KeyboardLayout = 'assets/chWay_kb8078-bg.png'
export const GEG_LAYUOT: KeyboardLayout = 'assets/geg.png'
export const HENG_60_LAYOUT: KeyboardLayout = 'assets/heng-yu-s60c-bg.png'
export const HENG_78A_LAYOUT: KeyboardLayout = 'assets/heng-yu-s78x-bg.png'
export const HENG_78D_LAYOUT: KeyboardLayout = 'assets/heng-yu-s78x-bg.png'
export const HENG_112_LAYOUT: KeyboardLayout = 'assets/heng-yu-s112a-bg.png'
export const NCR_5932_LAYOUT: KeyboardLayout = 'assets/ncr5932-bg.png'
export const NCR_KB78_LAYOUT: KeyboardLayout = 'assets/ncr-kb78m-bg.png'
export const PARNTER_KB78_LAYOUT: KeyboardLayout = 'assets/heng-yu-s78x-bg.png'
export const POSIFLEX_LAYOUT: KeyboardLayout = 'assets/posiflex_kb6610-bg.png'
export const POSUA_LAYOUT: KeyboardLayout = 'assets/posua-bg.png'
export const POSUA_96_LAYOUT: KeyboardLayout = 'assets/posua_96-bg.png'
export const POSUA_128_LAYOUT: KeyboardLayout = 'assets/posua_128-bg.png'
export const MCI_80_LAYOUT: KeyboardLayout = 'assets/preh_mc80wx_downsize.png'
export const PREH_LAYOUT: KeyboardLayout = 'assets/preh.png'
export const CSI_MCI84_LAYOUT: KeyboardLayout = 'assets/preh.png'
export const MCI_96_LAYOUT: KeyboardLayout = 'assets/preh_mci96_downsize.png'
export const QWERTY_LAYOUT: KeyboardLayout = 'assets/qwerty-bg.png'
export const PP306_LAYOUT: KeyboardLayout = 'assets/shtrih_pp306-112m_black_bg.png'
export const SPARK_LAYOUT: KeyboardLayout = 'assets/heng-yu-s78x-bg.png'
export const VIKIPOS_LAYOUT: KeyboardLayout = 'assets/vikipos_mci84-bg.png'
export const MSI_60_LAYOUT: KeyboardLayout = 'assets/vikipos_msi60-bg.png'
export const WINCOR_LAYOUT: KeyboardLayout = 'assets/wincor-bg.png'

export type KeyboardLayout = 'assets/chWay_kb8078-bg.png' | 'assets/geg.png' | 'assets/heng-yu-s60c-bg.png' | 'assets/heng-yu-s78x-bg.png' |
    'assets/heng-yu-s78x-bg.png' | 'assets/heng-yu-s112a-bg.png' | 'assets/ncr5932-bg.png' | 'assets/ncr-kb78m-bg.png' |
    'assets/heng-yu-s78x-bg.png' | 'assets/posiflex_kb6610-bg.png' | 'assets/posua-bg.png' | 'assets/posua_96-bg.png' |
    'assets/posua_128-bg.png' | 'assets/preh_mc80wx_downsize.png' | 'assets/preh.png' | 'assets/preh_mci96_downsize.png' |
    'assets/qwerty-bg.png' | 'assets/shtrih_pp306-112m_black_bg.png' | 'assets/heng-yu-s78x-bg.png' |
    'assets/vikipos_mci84-bg.png' | 'assets/vikipos_msi60-bg.png' | 'assets/wincor-bg.png'

export const CHWAY_MODEL: KeyboardModel = 'chWay_kb8078'
export const GEG_MODEL: KeyboardModel = 'geg_kraftwaykb78m12'
export const HENG_60_MODEL: KeyboardModel = 'hengyu_s60c'
export const HENG_78A_MODEL: KeyboardModel = 'hengyu_s78a'
export const HENG_78D_MODEL: KeyboardModel = 'hengyu_s78d'
export const HENG_112_MODEL: KeyboardModel = 'hengyu_s112a'
export const NCR_5932_MODEL: KeyboardModel = 'ncr_5932'
export const NCR_KB78_MODEL: KeyboardModel = 'ncr_kb78m'
export const PARNTER_KB78_MODEL: KeyboardModel = 'partner_kb78'
export const POSIFLEX_MODEL: KeyboardModel = 'posiflex_kb6610'
export const POSUA_MODEL: KeyboardModel = 'posua_64'
export const POSUA_96_MODEL: KeyboardModel = 'posua_96'
export const POSUA_96_QWERTY_MODEL: KeyboardModel = 'posua_96_qwerty'
export const POSUA_128_MODEL: KeyboardModel = 'posua_128'
export const MCI_80_MODEL: KeyboardModel = 'preh2_mci80'
export const PREH_MODEL: KeyboardModel = 'preh_mci84'
export const CSI_MCI_84_MODEL: KeyboardModel = 'csi_mci84'
export const MCI_96_MODEL: KeyboardModel = 'preh_mci96usb'
export const QWERTY_MODEL: KeyboardModel = 'qwerty'
export const PP306_MODEL: KeyboardModel = 'shtrih_pp306'
export const SPARK_MODEL: KeyboardModel = 'spark_kb2078'
export const VIKIPOS_MODEL: KeyboardModel = 'vikipos_mci84'
export const MSI_60_MODEL: KeyboardModel = 'vikipos_msi60'
export const WINCOR_MODEL: KeyboardModel = 'wincor_ta85p'
export const NCR_XR7_MODEL: KeyboardModel = 'ncr_xr7'

export type KeyboardModel = 'chWay_kb8078' | 'geg_kraftwaykb78m12' | 'hengyu_s60c' | 'hengyu_s78a' | 'hengyu_s78d' | 'hengyu_s112a' | 'ncr_5932' |
    'ncr_kb78m' | 'partner_kb78' | 'posiflex_kb6610' | 'posua_64' | 'posua_96' | 'posua_96_qwerty' | 'posua_128' | 'preh2_mci80' | 'preh_mci84' |
    'csi_mci84' | 'preh_mci96usb' | 'qwerty' | 'shtrih_pp306' | 'spark_kb2078' | 'vikipos_mci84' | 'vikipos_msi60' | 'wincor_ta85p' | 'ncr_xr7'

export type KeyboardButtonStyle = 'Disabled_round' | 'Disabled_square'

export const ROUND: KeyboardButtonStyle = 'Disabled_round'
export const SQUARE: KeyboardButtonStyle = 'Disabled_square'

export interface KeyboardKeyPosition {
    xmin: string,
    xmax: string,
    ymin: string,
    ymax: string,
    formx: string,
    formy: string,
}

export interface MainButton {
    x?: string, // Координаты кнопки
    y?: string,
    scanCode?: string, // Идентификатор кнопки. Если кнопка занимает несколько посадочных мест, то все их коды она сохраняет в себе в файле kbd.xml
    style?: KeyboardButtonStyle, // Стиль кнопки
    ch?: string, // Текст на кнопке, многострочный
    text?: string,
    maxChars?: string,  // Максимальное кол-во знаков в тексте кнопки
    kbdAlphaNumeric?: boolean,
    width?: string, // габариты, если кнопка нестандартного размера
    height?: string,
}

export interface TemplateButton {
    width: string,
    height: string,
    maxCount: string,
    id: string,
    pazzle: string, // Сколько ячеек занимает кнопка, свойство явно избыточное
}

export interface KeyboardButtons {
    CountX: string,
    CountY: string,
    main: {
        width: string,
        height: string,
        kbdAlphaNumeric: boolean,
        button: MainButton[],
    }
    template: {
        maxCount?: string,
        button: TemplateButton[]
    }
}

export interface KeyboardOption {
    name?: string, // Название свойства
    label?: string, // Локализация
}

export interface KeyboardMapVo extends Omit<EquipmentSettingVO, 'value' | '@class'> {
    keyboard: {
        name: KeyboardName,
        layout: KeyboardLayout,
        model: KeyboardModel,
        description: string,
        keyposition: KeyboardKeyPosition,
        buttons: KeyboardButtons,
        options?: {
            option?: KeyboardOption[],
        },
    }
}
