import React from 'react'
import { LOYALTY_COUPONS_INSTANCES_STORE } from '../../../../store/stores'
import { inject, observer } from 'mobx-react'
import IconButton from '@material-ui/core/IconButton'
import { FilterIcon, ArrowStyle } from '@crystalservice/crystals-ui/lib/components/selectors/items-filtered-list/filter-icon'
import Paper from '@material-ui/core/Paper'
import { NothingFound } from '../../../../components/stub-with-img/nothing-found'
import { SideBarDrawer } from '@crystalservice/crystals-ui/lib/components/side-bar-drawer/side-bar-drawer'
import Button from '@material-ui/core/Button'
import { ActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/action-panel'
import { LoyaltyCouponsInstancesTable } from './loyalty-coupons-instances-table'
import { LoyaltyCouponsInstancesFilters } from './loyalty-coupons-instances-filter'
import { LoyaltyCouponsInstancesStore } from '../../../../store/loyalty/loyalty-coupons-instances-store'
import { t } from 'i18next'
import { SaveAlt } from '@material-ui/icons'
import { MenuButton } from '@crystalservice/crystals-ui/lib/components/buttons/menu-button/menu-button'
import { XLSX, TXT } from '../../../../core/file-types'
import { ListInput } from '@crystalservice/crystals-ui/lib/components/inputs/list-input/list-input'
import { getFilterTypeName } from '../../../../core/filters/filter'
import { CouponBarcode } from '../../../../components/filters/new-modules/loyalty-coupons/coupon-barcode'
import moment from 'moment'
import { withSpinner } from '../../../../store/with-spinner'
import { openReport } from '../../../../core/operday/report-utils'
import { last } from 'lodash'
import { COMPLETE } from '../../../../../protocol/set10/set-retail10-commons/set-report/report-order-status-enum'
import { NotSearched } from '../../../../components/stub-with-img/not-searched'

const styles = require('./loyalty-coupons-instances.scss')

export interface LoyaltyCouponsInstancesProps {
    loyaltyCouponsInstancesStore?: LoyaltyCouponsInstancesStore
    disableAutoSizer?: boolean
}

@inject(LOYALTY_COUPONS_INSTANCES_STORE)
@observer
export class LoyaltyCouponsInstances extends React.Component<LoyaltyCouponsInstancesProps> {

    entryRegExp: RegExp = /^\d*$/
    getEntryTypeHandler = (count: number) => t('loyaltyCoupons.instancesPage.filters.coupon', {count})

    handleOpenReport = (fileName: string): void => {
        const shortFileName = last(fileName.split('/'))

        openReport('getFileStream', null, { 'FILE_NAME': shortFileName })
    }

    render() {
        const {
            coupons, filterShown, onFilterToggle, barcodeFilter, setBarcodeFilter, fetchCoupons,
            gotFilters, createReport, reportStatus, discardReport, cancelReportStatus
        } = this.props.loyaltyCouponsInstancesStore

        const gotCoupons = coupons && coupons.length > 0

        let reportDate = null
        let reportPercent = null
        let reportFile = null

        if (reportStatus) {
            if (reportStatus.startedAt) {
                reportDate = moment(new Date(reportStatus?.startedAt)).format('HH:mm DD.MM.YYYY')
            }

            reportPercent = Math.round((reportStatus.dataCollectorsComplete / reportStatus.dataCollectors) * 100)
            reportPercent = Math.min(reportPercent, 100)

            if (reportStatus.status === COMPLETE && reportStatus.fileName) {
                reportFile = reportStatus.fileName
            }
        }

        return (
            <>
                <Paper style={{ padding: 16 }}>
                    <ListInput
                        id={'barcodeInput'}
                        autoFocus
                        defaultValue={barcodeFilter}
                        onChange={setBarcodeFilter}
                        label={getFilterTypeName(CouponBarcode.filterType)}
                        entryRegExp={this.entryRegExp}
                        getEntryTypeText={this.getEntryTypeHandler}
                        onConfirm={fetchCoupons}
                    />
                </Paper>

                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        {reportStatus && (
                            <div className={styles.statusBar}>
                                <div>
                                    {`${t('loyaltyCoupons.instancesPage.reportStatus')} ${reportDate}`}
                                </div>

                                <div className={styles.statusLine}>
                                    <div className={styles.filled} style={{ width: `${reportPercent}%`}}/>
                                </div>
                                <div>{`${reportPercent}%`}</div>

                                {reportFile ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => {
                                            cancelReportStatus()
                                            this.handleOpenReport(reportFile)
                                        }}
                                    >
                                        {t('common.download')}
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        style={{ marginLeft: 'auto' }}
                                        onClick={discardReport}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                )}
                            </div>
                        )}
                        <div className={styles.tableContainer}>
                            <IconButton
                                id="toggleFilterButton"
                                className={styles.filterButton}
                                onClick={onFilterToggle}
                            >
                                <FilterIcon
                                    selected={gotFilters}
                                    arrowStyle={filterShown ? ArrowStyle.RIGHT : ArrowStyle.LEFT}
                                />
                            </IconButton>

                            <Paper>
                                {!coupons && (
                                    <NotSearched
                                        hintText={t('set10.notSearchedTitle')}
                                    />
                                )}
                                {coupons?.length === 0 && (
                                    <NothingFound/>
                                )}
                                {gotCoupons && (
                                    <LoyaltyCouponsInstancesTable
                                        disableAutoSizer={this.props.disableAutoSizer}
                                    />
                                )}
                                <ActionPanel color="secondary">
                                    <div style={{ flex: 1 }} />
                                    <MenuButton
                                        id="reportButton"
                                        disabled={!gotCoupons}
                                        menuButtonRenderer={(
                                            <Button color="primary" disabled={!gotCoupons}>
                                                <div className={styles.reportIcon}>
                                                    <SaveAlt/>
                                                </div>
                                                {t('loyaltyCoupons.instancesPage.makeReport')}
                                            </Button>
                                        )}
                                        buttons={[
                                            {
                                                id: 'open_txt_report',
                                                label: t('loyaltyCoupons.instancesPage.textReport'),
                                                disabled: !gotCoupons,
                                                onClick: () => withSpinner(createReport(TXT))
                                            },
                                            {
                                                id: 'open_xlsx_report',
                                                label: t('loyaltyCoupons.instancesPage.excelReport'),
                                                disabled: !gotCoupons,
                                                onClick: () => withSpinner(createReport(XLSX))
                                            }
                                        ]}
                                    />
                                </ActionPanel>
                            </Paper>
                        </div>
                    </div>

                    <SideBarDrawer
                        open={filterShown}
                        onClose={onFilterToggle}
                    >
                        <LoyaltyCouponsInstancesFilters/>
                    </SideBarDrawer>
                </div>
            </>
        )
    }
}
