export interface RefundPositionPropertiesVO {
    '@class': 'ru.crystals.report.vo.RefundPositionPropertiesVO'
    order?: number
    count?: number
    isPartyRefundAvailable?: boolean
    isWeightType?: boolean
    restCost?: number
}

export const createRefundPositionPropertiesVO = (initialization: Partial<RefundPositionPropertiesVO>): RefundPositionPropertiesVO => {
    if (!initialization) return null
    
    let result: RefundPositionPropertiesVO = {
        order: initialization.order,
        count: initialization.count,
        isPartyRefundAvailable: initialization.isPartyRefundAvailable,
        isWeightType: initialization.isWeightType,
        restCost: initialization.restCost,
        '@class': 'ru.crystals.report.vo.RefundPositionPropertiesVO',
    }
    
    return result
}
