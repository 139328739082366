import { PriceTagTemplateVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { printersManagerLocal } from '../../../protocol/set10/printers-manager-local'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE, PRICE_TAG_SETTINGS_STORE } from '../stores'
import { action, computed, observable, runInAction } from 'mobx'
import {
    ALL_PRODUCTS_LIST,
    BindingType,
    EXTERNAL_CODE,
    SELECTED_PRODUCTS
} from '../../core/price-tags/binding-type'
import { Cancelable, debounce, isEmpty } from 'lodash'
import { INPUT_DELAY } from '../../../utils/default-timeouts'
import {
    BoundProductEntity, PRODUCT,
    DEPARTMENT, PRODUCTS_GROUP, PRICE_TAG_IDENTIFIER
} from '../../components/product-entities-binder/product-entities-binder'
import { SimpleDepartment } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-department'
import { SimpleProductsGroup } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-products-group'
import { PriceTagActionIdent } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-action-ident'
import { SimpleProduct } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { ShortProductVO_SF } from '../../../protocol/set10/set-retail10-commons/data-structs-module/short-product-vo-sf'
import { productManagerFinderLocal } from '../../../protocol/set10/product-manager-finder-local'
import { ACTIVE } from '../../core/products/product-statuses'
import { ProductsFindResponse } from '../../../protocol/set10/set-retail10-commons/data-structs-module/products-find-response'
import { PriceTagSettingsStore } from './price-tag-settings-store'

export class PriceTagTemplatesBindingStore {

    @observable
    selectedRadioGroupValue: BindingType

    @observable
    isExternalCodeInUse: boolean = false

    @observable
    newExternalCode: string

    @observable
    boundProductEntities: BoundProductEntity[]
    @observable
    boundProductsInTemplate: BoundProductEntity[]

    debouncedCheckExternalCode: ((externalCode: string) => void) & Cancelable = debounce(async externalCode => {
            const result = await printersManagerLocal.doesExternalCodeExistForAnotherPricetag(this.userStore.session,
                externalCode, this.priceTagTemplate)

            runInAction(() => {
                this.isExternalCodeInUse = result
            })
        },
        INPUT_DELAY)

    private userStore: UserStore = getStore(USER_STORE)
    private priceTagSettingsStore: PriceTagSettingsStore = getStore(PRICE_TAG_SETTINGS_STORE)

    @computed
    get saveButtonAvailable(): boolean {
        switch (this.selectedRadioGroupValue) {
            case ALL_PRODUCTS_LIST:
                return !this.priceTagTemplate.serveAllProductsList
            case EXTERNAL_CODE:
                // Если мы редактируем акционный ценник, то в этом поле можно сохранить пустое значение
                // Если регулярный, то нельзя
                if (this.priceTagTemplate.actionable) {
                    return !this.isExternalCodeInUse && this.newExternalCode !== undefined && (this.newExternalCode !== this.priceTagTemplate.extCode)
                } else {
                    return !this.isExternalCodeInUse && !!this.newExternalCode && (this.newExternalCode !== this.priceTagTemplate.extCode)
                }
            case SELECTED_PRODUCTS:
                return this.boundProductEntities !== this.boundProductsInTemplate
        }

        return false
    }

    @computed
    get externalCode(): string {
        if (!this.priceTagTemplate) {
            return ''
        }

        const oldExternalCode = this.priceTagTemplate.extCode || ''
        return this.newExternalCode !== undefined ? this.newExternalCode : oldExternalCode
    }

    get sessionId(): string {
        return this.userStore.session
    }

    @computed
    get priceTagTemplate(): PriceTagTemplateVO {
        return this.priceTagSettingsStore.priceTagSettings
    }

    @action
    openPriceTagTemplate = async (): Promise<void> => {
        if (this.priceTagTemplate.actionable) {
            this.selectedRadioGroupValue = EXTERNAL_CODE
        } else {
            if (this.priceTagTemplate.serveAllProductsList) {
                this.selectedRadioGroupValue = ALL_PRODUCTS_LIST
            } else if (this.priceTagTemplate.extCode) {
                this.selectedRadioGroupValue = EXTERNAL_CODE
            } else {
                this.selectedRadioGroupValue = SELECTED_PRODUCTS
                const result = await this.getBoundEntitiesFromPriceTagTemplate(this.priceTagTemplate)
                runInAction(() => {
                    this.boundProductEntities = result
                    this.boundProductsInTemplate = this.boundProductEntities
                })
            }
        }
    }

    @action
    onRadioButtonChange = (value: BindingType): void => {
        this.selectedRadioGroupValue = value
    }

    @action
    onExternalCodeInputChange = (value: string): void => {
        this.newExternalCode = value
        if (this.priceTagTemplate.extCode === value || value === '') {
            this.isExternalCodeInUse = false
        } else {
            this.debouncedCheckExternalCode(value)
        }
    }

    @action
    onEntitiesChanged = (value: BoundProductEntity[]): void => {
        this.boundProductEntities = value
        this.boundProductsInTemplate = value
    }

    @action
    reset = (): void => {
        this.selectedRadioGroupValue = undefined
        this.isExternalCodeInUse = false
        this.newExternalCode = undefined
        this.boundProductEntities = []
        this.boundProductsInTemplate = []
        this.debouncedCheckExternalCode.cancel()
    }

    saveChanges = () => {
        let changes: Partial<PriceTagTemplateVO> = {
            serveProducts: [],
            serveDepartments: [],
            serveProductsGroups: [],
            serveActionIdents: [],
            extCode: null,
            serveAllProductsList: false
        }

        switch (this.selectedRadioGroupValue) {
            case ALL_PRODUCTS_LIST:
                changes.serveAllProductsList = true
                break
            case EXTERNAL_CODE:
                changes.extCode = this.externalCode
                break
            case SELECTED_PRODUCTS:
                this.saveBoundProductEntitiesToTemplate(changes)
                break
            default:
                return
        }

        this.priceTagSettingsStore.editPriceTag(changes)
    }

    saveBoundProductEntitiesToTemplate(template: Partial<PriceTagTemplateVO>): void {
        if (!this.boundProductEntities) {
            return
        }

        this.boundProductEntities.forEach(boundProductEntity => {
            switch (boundProductEntity.type) {
                case PRODUCT:
                    template.serveProducts.push(
                        (boundProductEntity.value as SimpleProduct | ShortProductVO_SF).code)
                    break
                case DEPARTMENT:
                    template.serveDepartments.push(boundProductEntity.value as SimpleDepartment)
                    break
                case PRODUCTS_GROUP:
                    template.serveProductsGroups.push(boundProductEntity.value as SimpleProductsGroup)
                    break
                case PRICE_TAG_IDENTIFIER:
                    template.serveActionIdents.push(boundProductEntity.value as PriceTagActionIdent)
                    break
            }
        })

        this.boundProductsInTemplate = this.boundProductEntities
    }

    doesPriceTagTemplateHaveServingItems = (): boolean => {
        return !isEmpty(this.priceTagTemplate.serveProducts)
            || !isEmpty(this.priceTagTemplate.serveProductsGroups)
            || !isEmpty(this.priceTagTemplate.serveDepartments)
            || !isEmpty(this.priceTagTemplate.serveActionIdents)
    }

    getBoundEntitiesFromPriceTagTemplate = async (priceTagTemplate: PriceTagTemplateVO): Promise<BoundProductEntity[]> => {
        let result: BoundProductEntity[] = []

        if (priceTagTemplate.serveProducts && priceTagTemplate.serveProducts.length > 0) {
            let response = await this.getProductsByCodes(priceTagTemplate.serveProducts)
            const products = response.foundProducts

            products.forEach(product => {
                result.push({
                    label: product.name,
                    code: product.code,
                    type: PRODUCT,
                    value: product
                })
            })
        }

        priceTagTemplate.serveDepartments.forEach(department => {
            result.push({
                label: department.name,
                code: String(department.number),
                type: DEPARTMENT,
                value: department
            })
        })

        priceTagTemplate.serveProductsGroups.forEach(productsGroup => {
            result.push({
                label: productsGroup.name,
                code: productsGroup.code,
                type: PRODUCTS_GROUP,
                value: productsGroup
            })
        })

        priceTagTemplate.serveActionIdents.forEach(actionIdent => {
            result.push({
                label: actionIdent.actionIdent,
                code: '',
                type: PRICE_TAG_IDENTIFIER,
                value: actionIdent
            })
        })

        return Promise.resolve(result)
    }

    getProductsByCodes = async (codes: string[]): Promise<ProductsFindResponse> => {
        const result = await productManagerFinderLocal.getSimpleProductsByCodes2(
            this.sessionId, codes, ACTIVE)

        return result
    }
}
