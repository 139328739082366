import React from 'react'
import { inject, observer } from 'mobx-react'
import { t } from 'i18next'
import GoToIcon from '@material-ui/icons/NavigateNext'
import { BaseTable } from '@crystalservice/crystals-ui/lib/components/new-table/base-table'
import { AdaptiveIconButton } from '../../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { PresentCardsVO } from '../../../../../protocol/set10/set-retail10-server/cards/set-cards-present-cards-ds/present-cards-vo'
import { PRESENT_CARDS_STORE } from '../../../../store/stores'
import { PresentCardsStore, ExtendedPresentCardStatistics } from '../../../../store/cards/present-cards-store'
import { goTo } from '../../../../utils/router-util'
import { CARDS, PRESENT_CARDS, PRESENT_CARDS_CATEGORY_EDIT, PRESENT_CARDS_CATEGORIES } from '../../../../core/app-routes'
import { NotSearched } from '../../../../components/stub-with-img/not-searched'
import { NothingFound } from '../../../../components/stub-with-img/nothing-found'
import { highlightFirstMatch } from '../../../../../components/product-base-info/product-base-info'
import { formatNumber } from '../../../../../utils/format-util'

const styles = require('./present-cards-categories.scss')

interface CardsCategoriesTableProps {
    presentCardsStore?: PresentCardsStore
}

export const CardsCategoriesTable: React.FC<CardsCategoriesTableProps> = inject(PRESENT_CARDS_STORE)(
    observer(
        props => {
            const keyFunc = (item: PresentCardsVO) => item.id
            const {
                presentCards, presentCardsFiltered, presentCardsStatistics, setLastUsedPath, setEditingCardCategory, searchCategoryName
            } = props.presentCardsStore

            const getStatisticsByCardId = (id: number): ExtendedPresentCardStatistics => {
                return presentCardsStatistics.find(statistics => statistics.cardId === id)
            }

            return (
                <>
                    {!presentCards && (
                        <NotSearched
                            titleText=""
                            hintText={t('set10.notSearchedTitle')}
                        />
                    )}
                    {presentCards?.length === 0 && (
                        <NothingFound
                            titleText={t('presentCards.noCardCategories')}
                            hintText=""
                        />
                    )}
                    {presentCards?.length > 0 && presentCardsFiltered?.length === 0 && (
                        <NothingFound/>
                    )}
                    {presentCardsFiltered?.length > 0 && (
                        <BaseTable<PresentCardsVO>
                            keyFunction={keyFunc}
                            items={presentCardsFiltered}
                            fullHeight
                            hovarableRows
                            onRowClick={({ rowData }: {rowData: PresentCardsVO}) => {
                                setEditingCardCategory(rowData)
                                setLastUsedPath(`${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORIES}`)
                                goTo(`${CARDS}${PRESENT_CARDS}${PRESENT_CARDS_CATEGORIES}${PRESENT_CARDS_CATEGORY_EDIT}/${rowData.id}`)
                            }}
                            columns={[
                                {
                                    padding: '0 0 0 14px',
                                    header: t('presentCards.list.table.name'),
                                    keyField: 'name',
                                    renderer: item => highlightFirstMatch(
                                        item.name, searchCategoryName, styles.highlightedMatch
                                    ),
                                    columnWidth: 250,
                                    VirtualizedColumnProps: {
                                        flexGrow: 1,
                                    },
                                },
                                {
                                    header: t('presentCards.list.table.nominal'),
                                    keyField: 'amount',
                                    renderer: item => formatNumber(item.amount / 100),
                                    columnWidth: 200
                                },
                                {
                                    header: t('presentCards.list.table.activated'),
                                    keyField: 'activated',
                                    renderer: item => getStatisticsByCardId(item.id)?.activePresentCards || 0,
                                    columnWidth: 150
                                },
                                {
                                    header: t('presentCards.list.table.redeemed'),
                                    keyField: 'redeemed',
                                    renderer: item => getStatisticsByCardId(item.id)?.usedPresentCards || 0,
                                    columnWidth: 150
                                },
                                {
                                    header: t('presentCards.list.table.released'),
                                    keyField: 'released',
                                    renderer: item => getStatisticsByCardId(item.id)?.issuedPresentCards || 0,
                                    columnWidth: 150
                                },
                                {
                                    header: '',
                                    keyField: 'controlBtn',
                                    renderer: item => {
                                        return (
                                            <AdaptiveIconButton
                                                adaptive={false}
                                                id={`goToCategory${item.id}`}
                                                label={t('presentCards.list.openCategory')}
                                            >
                                                <GoToIcon />
                                            </AdaptiveIconButton>
                                        )
                                    },
                                    columnWidth: 60,
                                    showOnHover: true,
                                },
                            ]}
                        />
                    )}
                </>
            )
        }
    )
)
