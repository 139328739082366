import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

const styles = require('./product-info.scss')

export const getInfoRow = (label: string, value: any) => {
    return (
        <>
            <Grid container spacing={2} className={styles.row}>
                <Grid item xs={12} sm={4} className={styles.label}>
                    {label}
                </Grid>
                <Grid item xs={12} sm={8} className={styles.value}>
                    {value}
                </Grid>
            </Grid>
            <Divider className={styles.rowDivider}/>
        </>
    )
}
