import moment from 'moment'
import { isNil } from 'lodash'
import {
    DateTypes,
    DayTimeUnit,
    HOURS,
    MILLISECONDS,
    MINUTES,
    SECONDS,
    HOUR,
    DAY,
    WEEK,
    MONTH,
    YEAR,
} from './date/date-format'
import { fromClientToServerTime } from '../set10/utils/app-util'
import { DateRange } from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/date-range-picker/date-range-picker'

export const MILLISECONDS_IN_DAY: number = 86400000
export const MILLISECONDS_IN_SECOND: number = 1000
export const SECONDS_IN_MINUTE: number = 60
export const SECONDS_IN_HOUR: number = SECONDS_IN_MINUTE * 60
export const SECONDS_IN_DAY: number = SECONDS_IN_HOUR * 24
export const SECONDS_IN_WEEK: number = SECONDS_IN_DAY * 7
export const SECONDS_IN_MONTH: number = SECONDS_IN_DAY * 30
export const SECONDS_IN_YEAR: number = SECONDS_IN_MONTH * 12

export interface Period {
    value: number
    type: DateTypes
}

/**
 * Вспомогательный класс, который позволяет легко получить представление времени из определенных единиц:
 * часов, минут, секунд
 */
export class TimeInfo {

    hours: number = 0
    minutes: number = 0
    seconds: number = 0

    constructor(
        public units: number = 0,
        public unitsType: DayTimeUnit = MINUTES
    ) {
        if (isNil(units)) {
            return
        }
        // на что нужно умножить unit, чтобы получить часы
        let hoursMultiplier = 1
        switch (unitsType) {
            case MINUTES:
                hoursMultiplier = 1 / 60
                break
            case SECONDS:
                hoursMultiplier = 1 / 3600
                break
            case MILLISECONDS:
                hoursMultiplier = 1 / 3600 / 1000
                break
        }

        this.hours = getHoursFromUnits(units, this.unitsType)
        this.minutes = getMinutesFromUnits(units - Math.round(this.hours / hoursMultiplier), this.unitsType)
        this.seconds = getSecondsFromUnits(
            units - Math.round(this.hours / hoursMultiplier) - Math.round(this.minutes / hoursMultiplier / 60), this.unitsType)
    }

    toDate = (): Date => {
        let date = moment().startOf('hours')
        date.hours(this.hours)
        date.minutes(this.minutes)
        date.seconds(this.seconds)
        return date.toDate()
    }

    format = (withHours: boolean = true, withMinutes: boolean = true, withSeconds: boolean = false,
              always2digit: boolean = true): string => {
        return formatTimeFromUnits(this.units, this.unitsType, withHours, withMinutes, withSeconds, always2digit)
    }
}

export function getMomentDate(date: Date): moment.Moment {
    return date ? moment(date) : null
}

export function getDayStart(date?: Date | moment.Moment) {
    return moment(date || new Date())
        .startOf('day')
}

export function getDayEnd(date?: Date | moment.Moment) {
    /* в старой версии для конца дня используется 23:59:59 000 */
    return moment(date || new Date())
        .endOf('day')
        .set({ millisecond: 0 })
}

export function setDateTime(date: Date, time: number, timeUnit: DayTimeUnit): Date {
    let newDate: moment.Moment = moment(date)
    newDate.startOf('day').set({
        [timeUnit]: time
    })

    return newDate.toDate()
}

export function convertInputValueToDate(
    inputValue: string,
    dateFormat: moment.MomentFormatSpecification,
    strict: boolean = false
): moment.Moment {
    const momentDate = moment(inputValue, dateFormat, strict)

    return momentDate.isValid() ? momentDate : null
}

export function checkOutsideRange(
    date: moment.Moment,
    isDateDisabled?: (date: Date) => boolean,
    disabledDateRanges?: DateRange[]
): boolean {
    let startOfDate = getDayStart(date)

    if (isDateDisabled) {
        return isDateDisabled(date.toDate())
    }

    if (disabledDateRanges) {
        let isDisabled: boolean = false

        disabledDateRanges.forEach((dateRange: DateRange) => {
            if (dateRange.start && dateRange.end) {
                if (startOfDate.isSameOrAfter(getDayStart(moment(dateRange.start)))
                    && startOfDate.isSameOrBefore(getDayStart(moment(dateRange.end)))) {
                    isDisabled = true
                    return
                }
            }

            if (!dateRange.start) {
                if (startOfDate.isSameOrBefore(getDayStart(moment(dateRange.end)))) {
                    isDisabled = true
                    return
                }
            }

            if (!dateRange.end) {
                if (startOfDate.isSameOrAfter(getDayStart(moment(dateRange.start)))) {
                    isDisabled = true
                    return
                }
            }

            if (!dateRange.start && !dateRange.end) {
                isDisabled = true
            }
        })

        return isDisabled
    }

    return false
}

export function getStartOfYearDate() {
    let date = fromClientToServerTime(new Date())
    date.setMonth(0)
    date.setDate(1)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export function setOneDayBefore(date: Date) {
    if (!date) return

    date.setDate(date.getDate() - 1)
    return date
}

export function refineTimeFromDate(targetDate: moment.Moment, controlDate: moment.Moment | Date): moment.Moment {
    if (!targetDate) {
        return null
    }
    if (!controlDate) {
        return targetDate
    }

    let control: moment.Moment = moment(controlDate)

    let clone = targetDate.clone()
    clone.set({
        hour: control.hour(),
        minute: control.minute(),
        second: control.second(),
        millisecond: control.millisecond()
    })

    return clone
}

export function getShortTimeStringFromMinutes(minutes: number, hourAlways2digit: boolean = false): string {
    if (isNil(minutes)) return ''

    // Спереди добваляем ноль, чтобы занимало два символа
    let hours = Math.floor(minutes / 60).toString()
    if (hourAlways2digit && hours.length < 2) {
        hours = `0${hours}`
    }

    let min = Math.floor(minutes % 60).toString()
    if (min.length < 2) {
        min = `0${min}`
    }
    return `${hours}:${min}`
}

/**
 * Находит кол-во единиц времени (ч, мин или сек) из отформатированной строки, и возвращает в указанных единицах времени.
 * Формат времени может быть:
 * 1)Ч+:ММ:СС - секунды
 * 2)Ч+:ММ - минуты
 * 3)Ч+ч - часы
 * @param {string} value
 * @param {DayTimeUnit} unitsType
 * @param {boolean} withHours
 * @param {boolean} withMinutes
 * @param {boolean} withSeconds
 * @returns {number}
 */
export function getTimeUnitsFromString(value: string, unitsType: DayTimeUnit = MINUTES,
                                       withHours: boolean = true, withMinutes: boolean = true,
                                       withSeconds: boolean = false): number {
    if (!value || !unitsType) return 0

    const sign: number = value.startsWith('-') ? -1 : 1
    if (sign < 0) {
        value = value.substring(1)
    }

    let splittedValue = value.split(':')

    let currentIndex = 0
    let hours: number = 0
    if (withHours) {
        hours = Number(splittedValue[currentIndex])
        currentIndex++
    }

    let minutes: number = 0
    if (withMinutes && splittedValue.length > currentIndex) {
        minutes = Number(splittedValue[currentIndex])
        currentIndex++
    }

    let seconds: number = 0
    if (withSeconds && splittedValue.length > currentIndex) {
        seconds = Number(splittedValue[currentIndex])
        currentIndex++
    }

    let milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000
    let multiplier = 1

    switch (unitsType) {
        case HOURS:
            multiplier = 3600 * 1000
            break
        case MINUTES:
            multiplier = 60 * 1000
            break
        case SECONDS:
            multiplier = 1000
            break
    }

    const result = Math.floor(milliseconds / multiplier)

    if (result === 0) return 0

    return sign * result
}

export function formatTimeFromUnits(units: number, unitsType: DayTimeUnit,
                                    withHours: boolean = true, withMinutes: boolean = true,
                                    withSeconds: boolean = false, always2digit: boolean = true): string {
    if (withHours && !withMinutes && withSeconds) {
        withMinutes = true
    }

    if (isNil(units) || isNil(unitsType)) {
        units = 0
        unitsType = MINUTES
    }

    // на что нужно умножить unit, чтобы получить часы
    let hoursMultiplier = 1
    switch (unitsType) {
        case MINUTES:
            hoursMultiplier = 1 / 60
            break
        case SECONDS:
            hoursMultiplier = 1 / 3600
            break
        case MILLISECONDS:
            hoursMultiplier = 1 / 3600 / 1000
            break
    }

    const sign = Math.sign(units)
    const value = Math.abs(units)

    let hours = withHours ? getHoursFromUnits(value, unitsType) : 0
    let minutes = withMinutes ? getMinutesFromUnits(value - Math.round(hours / hoursMultiplier), unitsType) : 0
    let seconds = withSeconds ? getSecondsFromUnits(value - Math.round(hours / hoursMultiplier)
        - Math.round(minutes / hoursMultiplier / 60), unitsType) : 0

    let zeroValue = true
    let result = ''
    if (withHours) {
        let hoursString = String(hours)
        if (hoursString.length < 2 && always2digit) {
            hoursString = `0${hoursString}`
        }
        result += hoursString
        if (hours > 0) {
            zeroValue = false
        }
    }

    if (withMinutes) {
        let minutesString = String(minutes)
        if (minutesString.length < 2 && (withHours || always2digit)) {
            minutesString = `0${minutesString}`
        }
        if (result.length > 0) result += ':'
        result += minutesString
        if (minutes > 0) {
            zeroValue = false
        }
    }

    if (withSeconds) {
        let secondsString = String(seconds)
        if (secondsString.length < 2 && (withMinutes || always2digit)) {
            secondsString = `0${secondsString}`
        }
        if (result.length > 0) result += ':'
        result += secondsString
        if (seconds > 0) {
            zeroValue = false
        }
    }

    return `${sign < 0 && !zeroValue ? '-' : ''}${result}`
}

export function getHoursFromUnits(units: number, unitsType: DayTimeUnit): number {
    if (isNil(units) || isNil(unitsType)) return 0

    const sign = Math.sign(units)
    const value = Math.abs(units)

    let result = 0

    switch (unitsType) {
        case MINUTES:
            result = Math.floor(value / 60)
            break
        case SECONDS:
            result = Math.floor(value / 3600)
            break
        case MILLISECONDS:
            result = Math.floor(value / 3600 / 1000)
            break
        case HOURS:
        default: result = value
    }

    if (result === 0) return 0

    return sign * result
}

export function getMinutesFromUnits(units: number, unitsType: DayTimeUnit): number {
    if (isNil(units) || isNil(unitsType)) return 0

    const sign = Math.sign(units)
    const value = Math.abs(units)

    let result = 0

    switch (unitsType) {
        case HOURS:
            result = value * 60
            break
        case SECONDS:
            result = Math.floor(value / 60)
            break
        case MILLISECONDS:
            result = Math.floor(value / 60 / 1000)
            break
        case MINUTES:
        default: result = value
    }

    if (result === 0) return 0

    return sign * result
}

export function getSecondsFromUnits(units: number, unitsType: DayTimeUnit): number {
    if (isNil(units) || isNil(unitsType)) return 0

    const sign = Math.sign(units)
    const value = Math.abs(units)

    let result = 0

    switch (unitsType) {
        case HOURS:
            result = value * 3600
            break
        case MINUTES:
            result = value * 60
            break
        case MILLISECONDS:
            result = Math.floor(value / 1000)
            break
        case SECONDS:
        default: result = value
    }

    if (result === 0) return 0

    return sign * result
}

export const getSecondsFromDateTypeUnits = (units: number, type: DateTypes): number => {
    if (isNil(units) || isNil(type)) return 0

    switch (type) {
        case HOUR:
            return units * SECONDS_IN_HOUR
        case DAY:
            return units * SECONDS_IN_DAY
        case WEEK:
            return units * SECONDS_IN_WEEK
        case MONTH:
            return units * SECONDS_IN_MONTH
        case YEAR:
            return units * SECONDS_IN_YEAR
        default:
            return 0
    }
}

const ALL_VALUES_IN_SECONDS = [
    SECONDS_IN_HOUR,
    SECONDS_IN_DAY,
    SECONDS_IN_WEEK,
    SECONDS_IN_MONTH,
    SECONDS_IN_YEAR,
]

export const getUnitsFromSeconds = (seconds: number): Period => {
    let periodTypeIndex: number
    for (let i: number = 0; i < ALL_VALUES_IN_SECONDS.length; i++) {
        const secondsInPeriod = ALL_VALUES_IN_SECONDS[i]
        if (seconds / secondsInPeriod >= 1 && seconds % secondsInPeriod === 0) {
            periodTypeIndex = i
        }
    }

    const result: number = seconds / ALL_VALUES_IN_SECONDS[periodTypeIndex]

    switch (periodTypeIndex) {
        case 0:
            return { type: HOUR, value: result }
        case 1:
            return { type: DAY, value: result }
        case 2:
            return { type: WEEK, value: result }
        case 3:
            return { type: MONTH, value: result }
        case 4:
            return { type: YEAR, value: result }
        default:
            return { type: HOUR, value: 0 }
    }
}

export function convertTimeToUnits(date: Date, unitsType: DayTimeUnit): number {
    if (!date || !unitsType) return 0

    let multiplier = 1
    switch (unitsType) {
        case HOURS:
            multiplier = 3600 * 1000
            break
        case MINUTES:
            multiplier = 60 * 1000
            break
        case SECONDS:
            multiplier = 1000
            break
    }

    return Math.floor((date.getHours() * 3600 * 1000 + date.getMinutes() * 60 * 1000 + date.getSeconds() * 1000) / multiplier)
}

export function copyTimeBetweenDates(copyTo: Date, copyFrom: Date): Date {
    let newDate = new Date(copyTo)
    newDate.setHours(copyFrom.getHours())
    newDate.setMinutes(copyFrom.getMinutes())
    newDate.setSeconds(copyFrom.getSeconds())
    newDate.setMilliseconds(copyFrom.getMilliseconds())
    return newDate
}

// Считает, сколько дней попало между датами, включая день начала и день конца периода
export function countDaysBetween(dateStart: Date, dateEnd: Date): number {
    let resultTime = dateEnd.getTime() - dateStart.getTime()

    return Math.floor(resultTime / MILLISECONDS_IN_DAY) + 1
}

export function getTimeFromDate(data: Date): string {
    return moment(fromClientToServerTime(data)).isValid() ?
        moment(fromClientToServerTime(data)).format('HH:mm') : ''
}

export function getDurationFromString(duration: string): string {
    return moment(duration, 'HH:mm:ss.SSS').isValid() ?
        moment(duration, 'HH:mm:ss.SSS').format('HH:mm') : ''
}

export function getDayOfWeekName(dayOfWeek: number, format: string = 'dddd'): string {
    const name = moment().day(dayOfWeek).format(format)
    return name[0].toUpperCase() + name.slice(1)
}

export const getStartOfInterval = (someDate: Date, interval: DateTypes): Date => {
    return moment(someDate).startOf(interval).toDate()
}

export const getEndOfInterval = (someDate: Date, interval: DateTypes): Date => {
    return moment(someDate).endOf(interval).toDate()
}

export const getDateFromString = (someDate: string): Date => {
    const momentDate = moment(someDate)
    return momentDate.isValid() ? momentDate.toDate() : null
}

export interface DayMonth {
    month: string
    day: string
}

export const parseDayMonth = (input: string): DayMonth => {
    const date = moment(input, 'YYYY-MM-DD')
    return {
        month: date.format('MM'),
        day: date.format('DD')
    }
}

export const serializeDayMonth = ({day, month}: DayMonth): string => {
    return moment(`2000-${month}-${day}`).format('YYYY-MM-DD 00:00:00.0 UTC')
}

export const formatTimeUnitsInto2Digit = (time: string): string => {
    if (!time?.length) return '00'
    if (time.length < 2) return `0${time}`
    return time
}
