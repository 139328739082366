import { XMLTagBaseElement } from './xml-tag-base-element'

export interface XMLTagSubTemplate extends XMLTagBaseElement {
    guid?: string
}

export function isSubTemplate(item: any): item is XMLTagSubTemplate {
    if (!item) return false
    if ('guid' in item) return true
    return false
}
