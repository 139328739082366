import React from 'react'
import { inject } from 'mobx-react'
import { withRouter, RouteComponentProps } from 'react-router'
import { t } from 'i18next'
import { Tabs, TabInfo } from '@crystalservice/crystals-ui/lib/components/tabs/tabs'
import { goTo, RouteChangeHandler } from '../../../utils/router-util'
import { APP_BAR_STORE, NAVIGATION_MENU_STORE, USER_STORE } from '../../../store/stores'
import { CARDS, PRESENT_CARDS, PRESENT_CARDS_LIST, PRESENT_CARDS_CATEGORIES } from '../../../core/app-routes'
import { AppBarStore, MENU } from '../../../store/app-bar-store'
import { NavigationMenuStore } from '../../../store/navigation-menu-store'
import { getStore } from '../../../store/stores-repository'
import { PresentCardsList } from './present-cards-list/present-cards-list'
import { PresentCardsCategories } from './present-cards-categories/present-cards-categories'
import { CARDS_PRESENT_CARDS_EDIT_PRIVILEGE } from '../../../core/privileges/privileges'
import { UserStore } from '../../../store/user-store'

const styles = require('./present-cards.scss')

export type PresentCardsTabType = 'list' | 'categories'
export const PRESENT_CARDS_LIST_TAB: PresentCardsTabType = 'list'
export const PRESENT_CARDS_CATEGORIES_TAB: PresentCardsTabType = 'categories'

const ALL_TABS: PresentCardsTab[] = [
    {
        type: PRESENT_CARDS_LIST_TAB,
        component: <PresentCardsList key="cardsList" />,
    },
    {
        type: PRESENT_CARDS_CATEGORIES_TAB,
        component: <PresentCardsCategories key="presentCardsCategoriesList" />,
    },
]

export interface PresentCardsTab {
    type: PresentCardsTabType
    component: React.ReactNode
    privilege?: string
}

export interface PresentCardsURLParams {
    tab?: PresentCardsTabType
}

export interface PresentCardsProps extends RouteComponentProps<PresentCardsURLParams> {
    user?: UserStore
}

@inject(USER_STORE)
export class PresentCards extends React.Component<PresentCardsProps> {

    get dataLabels(): TabInfo[] {
        return [
            {
                data: PRESENT_CARDS_LIST,
                label: t('presentCards.cards'),
            },
            {
                data: PRESENT_CARDS_CATEGORIES,
                label: t('presentCards.cardsCategories'),
            },
        ]
    }

    get selectedTabIndex(): number {
        const { tab = PRESENT_CARDS_LIST_TAB } = this.props.match.params
        const index = ALL_TABS.findIndex(t => t.type === tab)

        if (index === -1) return 0

        return index
    }

    onTabChange = (tab: TabInfo): void => {
        goTo(`${CARDS}${PRESENT_CARDS}${tab.data}`)
    }

    render() {
        const { havePrivilege } = this.props.user
        const canEdit: boolean = havePrivilege(CARDS_PRESENT_CARDS_EDIT_PRIVILEGE)
        const items: TabInfo[] = this.dataLabels.filter(info => info.data !== PRESENT_CARDS_CATEGORIES || canEdit)
        return (
            <>
                <Tabs
                    items={items}
                    defaultSelectedIndex={this.selectedTabIndex}
                    onTabChange={this.onTabChange}
                    className={styles.tabs}
                    tabContentClassName={styles.tabContent}
                >
                    {ALL_TABS.filter(tab => tab.type !== PRESENT_CARDS_CATEGORIES || canEdit).map(tab => tab.component)}
                </Tabs>
            </>
        )
    }
}

export default withRouter(PresentCards)

export const PRESENT_CARDS_ROUTE_CHANGE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${CARDS}${PRESENT_CARDS}(${PRESENT_CARDS_LIST}|${PRESENT_CARDS_CATEGORIES})$`),
    onEnter: (newRoute: string, prevRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
        navigationMenuStore.synchronizeWithRoute()
        appBarStore.updateState({
            title: `${t('set10.cards')} - ${t('set10.presentCards')}`,
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    },
}
