import { t } from 'i18next'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import AddCircle from '@material-ui/icons/AddCircle'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { MenuButton } from '@crystalservice/crystals-ui/lib/components/buttons/menu-button/menu-button'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { LEFT, RIGHT, TEXT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { JuristicPersonVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/juristic-person-vo'
import { NotSearched } from '../../../components/stub-with-img/not-searched'
import { NEW } from '../../../core/values'
import { AppStore } from '../../../store/app-store'
import { DialogStore } from '../../../store/dialog-store'
import { JuristicPersonSettingsDialog } from './juristic-person-settings-dialog'
import { APP_STORE, DIALOG_STORE, SHOP_STORE } from '../../../store/stores'
import {ShopStore} from '../../../store/shops/shop-settings/shop-store'

const styles = require('./juristic-persons-step.scss')

export interface JuristicPersonsStepProps {
    app?: AppStore
    dialogStore?: DialogStore
    shopStore?: ShopStore
}

@inject(APP_STORE)
@inject(DIALOG_STORE)
@inject(SHOP_STORE)
@observer
export class JuristicPersonsStep extends React.Component<JuristicPersonsStepProps> {
    render() {
        const {
            connectedToCentrum, juristicPersons, openJuristicPersonSettings,
            deleteJuristicPerson, markJuristicPersonAsDefault
        } = this.props.shopStore

        return (
            <div className={styles.juristicPersonsStepContent}>
                <JuristicPersonSettingsDialog/>
                {juristicPersons.length > 0 ? (
                    <>
                        <Table
                            id="juristicPersonsList"
                            className={styles.table}
                            items={toJS(juristicPersons)}
                            keyField="id"
                            title={t('shopPage.juristicPersonsTableTitle')}
                            withHeader
                            collapsible
                            onRowDoubleClick={person => {
                                if (connectedToCentrum) return
                                openJuristicPersonSettings(String(person.id))
                            }}
                            columns={[
                                {
                                    dataType: TEXT,
                                    header: t('shopPage.juristicPersonsColumnName'),
                                    hAlign: LEFT,
                                    labelField: 'juristicPersonName',
                                },
                                {
                                    dataType: TEXT,
                                    header: t('shopPage.juristicPersonsColumnJuristicAddress'),
                                    hAlign: RIGHT,
                                    labelField: 'juristicAddress',
                                },
                                {
                                    dataType: TEXT,
                                    header: t('shopPage.juristicPersonsColumnINN'),
                                    hAlign: RIGHT,
                                    labelField: 'inn',
                                    width: '130px'
                                },
                                {
                                    dataType: TEXT,
                                    header: t('shopPage.juristicPersonsColumnTelephoneNumber'),
                                    hAlign: RIGHT,
                                    labelField: 'telephoneNumber',
                                    width: '160px'
                                },
                                {
                                    dataType: TEXT,
                                    header: t('shopPage.juristicPersonsColumnDefault'),
                                    hAlign: RIGHT,
                                    labelFunction:
                                        (jp: JuristicPersonVO) => jp.defaultJP
                                            ? t('common.yes')
                                            : '',
                                },
                                {
                                    dataType: TEXT,
                                    header: t('shopPage.juristicPersonsColumnSimplifiedTax'),
                                    hAlign: RIGHT,
                                    labelFunction:
                                        (jp: JuristicPersonVO) => jp.simplifiedTaxTreatment
                                            ? t('common.yes')
                                            : '',
                                },
                                {
                                    renderer: props => {
                                        const juristicPerson: JuristicPersonVO = props.item
                                        const id: number = juristicPerson.id
                                        return (
                                            <MenuButton
                                                id={`juristicPerson${id}MenuButton`}
                                                adaptive
                                                buttons={[
                                                    {
                                                        id: `juristicPerson${id}MarkAsDefaultButton`,
                                                        label: t('shopPage.markJuristicPersonAsDefault'),
                                                        disabled: connectedToCentrum,
                                                        onClick: () => markJuristicPersonAsDefault(id)
                                                    },
                                                    {
                                                        id: `juristicPerson${id}EditButton`,
                                                        label: t('common.edit'),
                                                        disabled: connectedToCentrum,
                                                        onClick: () => openJuristicPersonSettings(String(id))
                                                    },
                                                    {
                                                        id: `juristicPerson${id}DeleteButton`,
                                                        label: t('common.remove'),
                                                        disabled: connectedToCentrum,
                                                        onClick: () => {
                                                            this.props.dialogStore.showDialog({
                                                                title: t('shopPage.juristicPersonDeletionTitle'),
                                                                message: t('shopPage.juristicPersonDeletionMessage', {
                                                                    name: juristicPerson.juristicPersonName
                                                                }),
                                                                mode: DIALOG,
                                                                onYes: () => deleteJuristicPerson(id)
                                                            })
                                                        }
                                                    }
                                                ]}
                                            />
                                        )
                                    },
                                    hAlign: RIGHT,
                                    padding: '0 10px',
                                    showOnRowHover: true,
                                    withControls: true,
                                    width: '120px',
                                },
                            ]}
                        />
                        <Divider className={styles.divider}/>
                    </>
                ) : (
                    <NotSearched
                        className={styles.stub}
                        titleText={t('shopPage.noJuristicPersonsRegistered')}
                        hintText=""
                    />
                )}
                <div className={styles.addButtonContainer}>
                    <Button
                        id="addJuristicPersonButton"
                        disabled={connectedToCentrum}
                        className={styles.addButton}
                        onClick={() => openJuristicPersonSettings(NEW)}
                    >
                        <AddCircle
                            className={styles.icon}
                            color="primary"
                        />
                        <span>
                            {t('shopPage.addJuristicPerson')}
                        </span>
                    </Button>
                </div>
            </div>
        )
    }
}
