import { ValidationResult, Validator } from '../form-validation'
import { t } from 'i18next'

export const requiredField: Validator = (value: any): ValidationResult => {
    const fault: ValidationResult = {
        valid: false,
        error: t('validation.requiredField'),
    }

    if (typeof value === 'string' && !value.trim()) {
        return fault
    } else if (value === undefined || value === null) {
        return fault
    } else {
        return {
            valid: true
        }
    }
}
