import { chunk } from 'lodash'

export const CHUNK_SIZE = 1000

type Request<T> = Promise<T> | (() => Promise<T>)

export const chunkedLoading = async <T, K>(params: K[], callbackToTakeRequest: (chunkedParams: K[]) => Request<T[]>): Promise<T[]> => {
    if (!callbackToTakeRequest) {
        return Promise.resolve(undefined)
    }

    const makeRequest = (request: Request<T[]>): Promise<T[]> => {
        return typeof request === 'function' ? request() : request
    }

    if (params.length > CHUNK_SIZE) {
        const paramsChunks = chunk(params, CHUNK_SIZE)
    
        const ps: Array<Promise<T[]>> = paramsChunks.map((chunkedParams: K[]) => {
            return makeRequest(callbackToTakeRequest(chunkedParams))
        })
    
        try {
            const results: T[][] = await Promise.all(ps)
            const result: T[] = [].concat(...results)
            return result
        } catch (error) {
            return Promise.reject(error)
        }
    } else {
        try {
            const result: T[] = await makeRequest(callbackToTakeRequest(params))
            return result
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
