import { t } from 'i18next'

export type Color = 'red' | 'blue' | 'green' | 'orange' | 'yellow' | 'violet'

export const COLOR_RED: Color = 'red'
export const COLOR_BLUE: Color = 'blue'
export const COLOR_GREEN: Color = 'green'
export const COLOR_ORANGE: Color = 'orange'
export const COLOR_YELLOW: Color = 'yellow'
export const COLOR_VIOLET: Color = 'violet'

export interface ColorOption {
    value: Color
    label: string
}

export const getColorsAsOptions = (): ColorOption[] => [
    { value: COLOR_RED, label: t(`common.colors.${COLOR_RED}`) },
    { value: COLOR_BLUE, label: t(`common.colors.${COLOR_BLUE}`) },
    { value: COLOR_GREEN, label: t(`common.colors.${COLOR_GREEN}`) },
    { value: COLOR_ORANGE, label: t(`common.colors.${COLOR_ORANGE}`) },
    { value: COLOR_YELLOW, label: t(`common.colors.${COLOR_YELLOW}`) },
    { value: COLOR_VIOLET, label: t(`common.colors.${COLOR_VIOLET}`) }
]
