import React from 'react'
import { PriceTagFormatVO } from '../../../../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-vo'
import { ListItem, List, ListItemText, Grid } from '@material-ui/core'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { t } from 'i18next'

const styles = require('./formats-select.scss')

export interface FormatsItem {
    selected: boolean
    items: PriceTagFormatVO[]
    externalCode: string
}

export type FormatsMap = Map<string, FormatsItem>

interface FormatsSelectProps {
    formatsMap: FormatsMap
    onFormatsMapChanged: (newMap: FormatsMap) => void
}

export class FormatsSelect extends React.Component<FormatsSelectProps> {

    render() {
        const { formatsMap, onFormatsMapChanged } = this.props

        if (!formatsMap) {
            return null
        }

        const items: FormatsItem[] = Array.from(formatsMap.keys())
            .map(key => {
                return formatsMap.get(key)
            })

        return (
            <List
                id="formatsSelect"
                component="div"
            >
                { items.map(item => {
                    return (
                        <React.Fragment key={item.externalCode}>
                            <ListItem
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Checkbox
                                    checked={item.selected}
                                    color="primary"
                                    onValueChange={(checked: boolean) => {
                                        const newMap = formatsMap

                                        newMap.set(item.externalCode, {
                                            ...item,
                                            selected: checked
                                        })

                                        onFormatsMapChanged(newMap)
                                    }}
                                />
                                <div>
                                    <span className={styles.label}>
                                        { t('advertisingActions.setPriceTag.formatExternalCode') }
                                    </span>
                                    <span
                                        style={{
                                            paddingLeft: '8px'
                                        }}
                                        data-contenttype="formatsExternalCode"
                                    >
                                        { item.externalCode }
                                    </span>
                                </div>
                            </ListItem>
                            <List component="div" disablePadding>
                                { item.items.map((format: PriceTagFormatVO) => {
                                    return (
                                        <ListItem key={format.uuid + format.name + format.id}
                                          style={{
                                            paddingLeft: '64px'
                                        }}>
                                            <ListItemText
                                                primary={
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={3}>
                                                                <span className={styles.label}>
                                                                    { t('advertisingActions.setPriceTag.formatName') }
                                                                </span>
                                                        </Grid>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={8}>
                                                            { format.name || '-' }
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                                <span className={styles.label}>
                                                                    { t('advertisingActions.setPriceTag.formatDescription') }
                                                                </span>
                                                        </Grid>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={8}>
                                                            { format.description || '-' }
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                                <span className={styles.label}>
                                                                    { t('advertisingActions.setPriceTag.formatTopologyName') }
                                                                </span>
                                                        </Grid>
                                                        <Grid item xs={1} />
                                                        <Grid item xs={8}>
                                                            { format.topologyName || '-' }
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItem>
                                    )
                                }) }
                            </List>
                        </React.Fragment>
                    )
                }) }
            </List>
        )
    }
}
