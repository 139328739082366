import { ProducerVO } from './producer-vo'

export interface GoodsCompositionVO {
    '@class': 'ru.crystals.setretailx.products.vo.GoodsCompositionVO'
    id?: number
    producerVo?: ProducerVO
    plugin?: boolean
    composition?: string
    storageConditions?: string
    foodValue?: string
    marking?: string
    active?: boolean
}

export const createGoodsCompositionVO = (initialization: Partial<GoodsCompositionVO>): GoodsCompositionVO => {
    if (!initialization) return null
    
    let result: GoodsCompositionVO = {
        id: initialization.id,
        producerVo: initialization.producerVo,
        plugin: initialization.plugin,
        composition: initialization.composition,
        storageConditions: initialization.storageConditions,
        foodValue: initialization.foodValue,
        marking: initialization.marking,
        active: initialization.active,
        '@class': 'ru.crystals.setretailx.products.vo.GoodsCompositionVO',
    }
    
    return result
}
