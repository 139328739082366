import * as React from 'react'
import uuid from 'uuid'
import { PriceTagImagePreview, PriceTagImagePreviewProps } from './price-tag-image-preview/price-tag-image-preview'
import { XMLPriceTag } from '../../core/price-tags/xml/xml-price-tag'
import { XMLTagImage } from '../../core/price-tags/xml/xml-tag-image'
import { XMLTagCustomElement } from '../../core/price-tags/xml/xml-tag-custom-element'
import { XMLTagTextBlock } from '../../core/price-tags/xml/xml-tag-text-block'
import { PriceTagTextPreview, PriceTagTextPreviewProps } from './price-tag-text-preview/price-tag-text-preview'
import { XMLTagSubTemplate } from '../../core/price-tags/xml/xml-tag-sub-template'
import { PriceTagSubtemplatePreview, PriceTagSubtemplatePreviewProps } from './price-tag-subtemplate-preview/price-tag-subtemplate-preview'
import { XMLTagFormula } from '../../core/price-tags/xml/xml-tag-formula'
import { RESIZE } from '../../../utils/dom/dom-events'
import { getImageForCustomElement, imageToCSS, metersToPixels, parseMnemonics } from '../../core/price-tags/price-tags-util'
import { XMLTagQrcodeElement } from '../../core/price-tags/xml/xml-tag-qrcode-element'
import { t } from 'i18next'
import { XMLTagBarcodeElement } from '../../core/price-tags/xml/xml-tag-barcode-element'
import { withPositionalStyleProps } from './price-tag-element-preview'
import { getFormulaDescription } from '../../core/price-tags/price-tag-formulas-util'
import { MnemonicPropertiesVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'

const BARCODE_IMAGE: string = require('../../../assets/images/icons/price-tags/barcode-full.png')
const QR_CODE_IMAGE: string = require('../../../assets/images/icons/price-tags/qr_code.png')

const styles = require('./price-tag-template-preview.scss')

const MM_IN_METER = 1000

const DEFAULT_DPI: number = 72
const METERS_IN_INCH: number = 0.0254

function getPixelsInMeters(value: number, dpi: number): number {
    return value * dpi / METERS_IN_INCH
}

export const COORDINATE_SCALE: number = getPixelsInMeters(0.001, DEFAULT_DPI)

interface PriceTagPreviewProps extends React.HTMLProps<HTMLDivElement> {
    /**
     * объект ценника
     */
    priceTag: XMLPriceTag
    /**
     * массив названий подшаблонов
     */
    subPriceTagTemplatesNames?: string[]
    // TODO Временное решение проблемы с необходимостью занимать всё свободное место родительского контейнера
    /**
     * Позволить превью занять всё свободное место родителя (с сохраненим пропорций)
     */
    fillParentContainer?: boolean
    /**
     * Позволяет добавить свой DOM элемент в превью (в частности, оверлей)
     */
    templateEndAdorment?: React.ReactNode
    /**
     * Если true (по дефолту false), то width и height фиксированы и берутся из priceTag.width и priceTag.height
     */
    keepOriginalSize?: boolean
    /**
     * Если true (по дефолту false), то width и height фиксированы и берутся из priceTag.width и priceTag.height
     */
    mnemonics: MnemonicPropertiesVO[]
}

interface PriceTagPreviewState {
    scale: number
    scaleSet: boolean
}

export class PriceTagTemplatePreview extends React.Component<PriceTagPreviewProps, PriceTagPreviewState> {

    static defaultProps: Partial<PriceTagPreviewProps> = {
        fillParentContainer: false,
        subPriceTagTemplatesNames: [],
        keepOriginalSize: false
    }

    ref: React.RefObject<HTMLDivElement> = React.createRef()

    state: PriceTagPreviewState = {
        scale: 1,
        // если scaleSet false, то после render вызывается adjustScale
        scaleSet: false
    }

    componentDidMount(): void {
        window.addEventListener(RESIZE, this.handleResize)

        if (!this.state.scaleSet) {
            this.adjustScale(this.props.priceTag)
            // если после первоначального рендера появляется полоса прокрутки, то scale нужно пересчитать
            // поэтому делаем ещё одну проверку в следующий тик после первоначального рендера страницы
            setTimeout(() => { this.setState({ scaleSet: false }) })
        }
    }

    componentDidUpdate(): void {
        if (!this.state.scaleSet) {
            this.adjustScale(this.props.priceTag)
        }
    }

    componentWillReceiveProps(nextProps: PriceTagPreviewProps): void {
        const nextPriceTag = nextProps.priceTag
        const priceTag = this.props.priceTag

        if (nextPriceTag && priceTag
            && (priceTag.width !== nextPriceTag.width || priceTag.height !== nextPriceTag.height)
        ) {
            this.setState({ scaleSet: false })
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener(RESIZE, this.handleResize)
    }

    handleResize = (): void => {
        this.setState({ scaleSet: false })
    }

    /**
     * Метод высчитывает scale - множитель для width и height компонента превью и всех его детей.
     * Запускать adjustScale следует, устанавливая state.scaleSet в false.
     * Это запускает один рендер, где width и height установлены в auto.
     * После этого рендера запускается adjustScale, в нём читается computedStyle.
     * Из computedStyle высчитывается оптимальное значение scale и scaleSet устанавливается в true.
     * Scale высчитывается таким образом, что маленькие шаблоны отображаются с их оригинальными width и height,
     * а большие ограничиваются изначальной высотой родителя компонента превью.
     */
    // TODO В текущей вариации не учитываются паддинги, вёрстку и стили довольно тяжело настраивать
    adjustScale = (priceTag: XMLPriceTag): void => {
        const { keepOriginalSize } = this.props
        if (keepOriginalSize) {
            this.setState({
                scale: metersToPixels(priceTag.width / MM_IN_METER) / priceTag.width,
                scaleSet: true
            })

            return
        }

        let computedStyles: CSSStyleDeclaration = getComputedStyle(this.ref.current)

        // Максимально возможная ширина элемента
        let maxWidth: number = parseInt(computedStyles.width, 10) || 0

        let computedWidth = maxWidth

        if (!this.props.fillParentContainer) {
            // Стандартная ширина элемента в пикселях
            let defaultWidth: number = metersToPixels(priceTag.width / MM_IN_METER)

            // Берем ширину по умолчанию, но не более максимально доступной ширины
            computedWidth = Math.min(defaultWidth, maxWidth)
        }

        // div-элемент при первом рендере имеет height: 0, поэтому вычисляем ее из пропорции
        let pseudoComputedHeight: number = computedWidth * priceTag.height / priceTag.width

        // максимальная высота считается как clientHeight родителя при отсутствии данного компонента
        const maxPreviewHeight: number = this.ref.current.parentElement.clientHeight

        let computedHeight = Math.min(pseudoComputedHeight, maxPreviewHeight)

        this.setState({
            scale: Math.min(
                computedWidth / priceTag.width,
                computedHeight / priceTag.height,
            ),
            scaleSet: true
        })
    }

    render() {
        const {
            fillParentContainer, keepOriginalSize, priceTag, subPriceTagTemplatesNames, templateEndAdorment, mnemonics, ...other
        } = this.props

        if (!priceTag) return null

        const { images, customElements, textBlocks, subTemplates, formulas, qrcodeElements, barcodeElements } = priceTag
        const { scale } = this.state

        return (
            <div
                ref={this.ref}
                { ...other }
            >
                <div
                    className={styles.templatePreview}
                    style={this.state.scaleSet ? {
                        width: priceTag.width * scale,
                        height: priceTag.height * scale,
                    } : null}
                >
                    { images && images.map((image: XMLTagImage) => {
                        const PreviewElement: React.ComponentType<PriceTagImagePreviewProps> = withPositionalStyleProps(PriceTagImagePreview)
                        return (
                            <PreviewElement
                                key={uuid()}
                                element={image}
                                scale={scale}
                                src={imageToCSS(image)}
                                alt={image.fileName}
                            />
                        )
                    }) }

                    { customElements && customElements.map((customElement: XMLTagCustomElement) => {
                        const PreviewElement: React.ComponentType<PriceTagImagePreviewProps> = withPositionalStyleProps(PriceTagImagePreview)
                        return (
                            <PreviewElement
                                key={uuid()}
                                element={customElement}
                                scale={scale}
                                src={getImageForCustomElement(customElement.type)}
                                alt={customElement.type}
                            />
                        )
                    }) }

                    { barcodeElements && barcodeElements.map((barcodeElement: XMLTagBarcodeElement) => {
                        const PreviewElement: React.ComponentType<PriceTagImagePreviewProps> = withPositionalStyleProps(PriceTagImagePreview)
                        return (
                            <PreviewElement
                                key={uuid()}
                                element={barcodeElement}
                                scale={scale}
                                src={BARCODE_IMAGE}
                                alt={t('priceTags.editor.barcode')}
                            />
                        )
                    }) }

                    { qrcodeElements && qrcodeElements.map((qrcodeElement: XMLTagQrcodeElement) => {
                        const PreviewElement: React.ComponentType<PriceTagImagePreviewProps> = withPositionalStyleProps(PriceTagImagePreview)
                        return (
                            <PreviewElement
                                key={uuid()}
                                element={qrcodeElement}
                                scale={scale}
                                src={QR_CODE_IMAGE}
                                alt={t('priceTags.editor.qrcode')}
                            />
                        )
                    }) }

                    { textBlocks && textBlocks.map((textBlock: XMLTagTextBlock) => {
                        const PreviewElement: React.ComponentType<PriceTagTextPreviewProps> = withPositionalStyleProps(PriceTagTextPreview)
                        return (
                            <PreviewElement
                                key={uuid()}
                                element={textBlock}
                                scale={scale}
                                parts={parseMnemonics(textBlock.text, mnemonics)}
                                style={{
                                    outline: '1px solid grey'
                                }}
                            />
                        )
                    }) }

                    { subTemplates && subTemplates.map((subTemplate: XMLTagSubTemplate, index: number) => {
                        const PreviewElement: React.ComponentType<PriceTagSubtemplatePreviewProps>
                            = withPositionalStyleProps(PriceTagSubtemplatePreview)
                        return (
                            <PreviewElement
                                key={uuid()}
                                element={subTemplate}
                                scale={scale}
                                name={subPriceTagTemplatesNames[index] || ''}
                                style={{
                                    outline: '1px solid grey'
                                }}
                            />
                        )
                    }) }

                    { formulas && formulas.map((formula: XMLTagFormula) => {
                        const PreviewElement: React.ComponentType<PriceTagTextPreviewProps> = withPositionalStyleProps(PriceTagTextPreview)
                        return (
                            <PreviewElement
                                key={uuid()}
                                element={formula}
                                scale={scale}
                                parts={getFormulaDescription(formula, mnemonics)}
                                style={{
                                    outline: '1px solid grey'
                                }}
                            />
                        )
                    }) }

                    { templateEndAdorment }
                </div>
            </div>
        )
    }
}
