import React from 'react'
import { TextStrikedKind } from '../../../set10/core/price-tags/xml/xml-tag-text-block'

const LINE_TOP_TO_BOTTOM = (<line key="1" x1="0" y1="0" x2="100%" y2="100%" />)
const LINE_BOTTOM_TO_TOP = (<line key="2" x1="0" y1="100%" x2="100%" y2="0" />)
const LINE_HORIZONTAL = (<line x1="0" y1="50%" x2="100%" y2="50%" />)

export interface StrikedSvgProps {
    /**
     * Тип зачеркивания
     */
    strikedKind: TextStrikedKind
    /**
     * Цвет в формате: #00ff00
     */
    hexColor: string
    className?: string
}

export class StrikedSvg extends React.Component<StrikedSvgProps> {

    render() {
        const {
            className, hexColor, strikedKind
        } = this.props

        let lines = null

        switch (strikedKind) {
            case TextStrikedKind.FROM_LEFT_TOP_TO_RIGHT_BOTTOM:
                lines = LINE_TOP_TO_BOTTOM
                break
            case TextStrikedKind.FROM_LEFT_BOTTOM_TO_RIGHT_TOP:
                lines = LINE_BOTTOM_TO_TOP
                break
            case TextStrikedKind.HORIZONTAL:
                lines = LINE_HORIZONTAL
                break
            case TextStrikedKind.CROSS:
                lines = (
                    <>
                        {LINE_TOP_TO_BOTTOM}
                        {LINE_BOTTOM_TO_TOP}
                    </>
                )
                break
            default:
                break
        }

        return (
            <svg
                className={className}
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                style={{stroke: hexColor, strokeWidth: 1}}
            >
                {lines}
            </svg>
        )
    }
}
