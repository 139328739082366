import React from 'react'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { t } from 'i18next'
import { withSpinner } from '../../with-spinner'
import { SnackbarStore } from '../../snackbar-store'
import { getStore } from '../../stores-repository'
import { SNACKBAR_STORE, USER_STORE, APP_STORE, APP_BAR_STORE } from '../../stores'
import { scalesManagerLocal1 } from '../../../../protocol/set10/scales-manager-local1'
import { UserStore } from '../../user-store'
import { ScalesTemplateVO, createScalesTemplateVO } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/scales-template-vo'
import { SNACKBAR_EXTENDED_DURATION } from '../../../../utils/default-timeouts'
import Button from '@material-ui/core/Button'
import { goTo } from '../../../utils/router-util'
import { SCALES_MODULE, TEMPLATES } from '../../../core/app-routes'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { AppStore } from '../../app-store'
import { isEqual, cloneDeep, isNil } from 'lodash'
import { scalesManagerLocal2 } from '../../../../protocol/set10/scales-manager-local2'
import { scalesProductLoadingsManagerLocal } from '../../../../protocol/set10/scales-product-loadings-manager-local'
import { SimpleDepartment } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/simple-department'
import { iProductsManagerLocal } from '../../../../protocol/set10/i-products-manager-local'
import { ShortProductVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/short-product-vo-sf'
import { scalesInfoManager } from '../../../../protocol/set10/scales-info-manager'
import { UploadedProductsVO } from '../../../../protocol/set10/set-retail10-commons/set-scales-commons/uploaded-products-vo'
import { reportsProcessorLocal } from '../../../../protocol/set10/reports-processor-local'
import { config } from '../../../config/config'
import { FILE_TYPE } from '../../../core/file-types'
import { AppBarStore, LEFT_ARROW } from '../../app-bar-store'

export const FROM_PRODUCT_SETTINGS = 'FROM_PRODUCT_SETTINGS'

export class ScaleTemplatesListStore {

    @observable
    scaleTemplates: ScalesTemplateVO[] = []

    @observable
    editedTemplate: ScalesTemplateVO = null

    @observable
    originalEditedTemplate: ScalesTemplateVO = null

    @observable
    departments: SimpleDepartment[] = []

    @observable
    productGroupsNames: Map<string, string> = new Map<string, string>()

    @observable
    saleGroupsNames: Map<string, string> = new Map<string, string>()

    @observable
    bannedProducts: ShortProductVO_SF[]

    @observable
    loadedProducts: UploadedProductsVO[]

    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    @computed
    get modified(): boolean {
        return !isEqual(toJS(this.editedTemplate), toJS(this.originalEditedTemplate))
    }

    @computed
    get productsModified(): boolean {
        return !isEqual(toJS(this.editedTemplate?.scalesServeProductsGroupIds), toJS(this.originalEditedTemplate?.scalesServeProductsGroupIds)) ||
            !isEqual(toJS(this.editedTemplate?.scalesServeSectionIds), toJS(this.originalEditedTemplate?.scalesServeSectionIds)) ||
            !isEqual(toJS(this.editedTemplate?.scalesServeSaleGroups), toJS(this.originalEditedTemplate?.scalesServeSaleGroups)) ||
            this.editedTemplate?.serveAllProductsList !== this.originalEditedTemplate?.serveAllProductsList
    }

    fetchScaleTemplates = async (): Promise<void> => {
        const scaleTemplates = await withSpinner(scalesManagerLocal1.getScalesTemplates(this.userStore.session))

        runInAction(() => {
            this.scaleTemplates = scaleTemplates || []
        })
    }

    @action
    clearBannedProducts = (): void => {
        this.bannedProducts = null
    }

    fetchBannedProducts = async (): Promise<void> => {
        if (isNil(this.editedTemplate?.guid)) {
            this.showNeedSaveSnackbar()
            return
        }
        const result = await scalesProductLoadingsManagerLocal.getBannedProductsForTemplate(
            this.editedTemplate.guid
        )

        runInAction(() => {
            this.bannedProducts = result || []
        })
    }

    showNeedSaveSnackbar = (): void => {
        this.appStore.showSnackbar({
            message: t('scaleTemplatesList.needSaveTemplate'),
            variant: 'warning'
        })
    }

    @action
    clearLoadedProducts = (): void => {
        this.loadedProducts = null
    }

    fetchLoadedProducts = async (): Promise<void> => {
        if (isNil(this.editedTemplate?.guid)) {
            this.showNeedSaveSnackbar()
            return
        }
        const result = await scalesInfoManager.getLoadedProducts(this.editedTemplate.id)

        runInAction(() => {
            this.loadedProducts = result.sort((a: UploadedProductsVO, b: UploadedProductsVO) => {
                if (a.marking < b.marking) return -1
                if (a.marking > b.marking) return 1
                return 0
            })
        })
    }

    addProductToBannedList = async (productCode: string): Promise<void> => {
        if (isNil(this.editedTemplate?.guid)) {
            this.showNeedSaveSnackbar()
            return
        }
        await scalesProductLoadingsManagerLocal.addToBannedListForTemplate([productCode], this.editedTemplate.guid)

        this.fetchBannedProducts()
    }

    addProductsFromListInputToBannedList = async (productCodes: string[]): Promise<void> => {
        if (isNil(this.editedTemplate?.guid)) {
            this.showNeedSaveSnackbar()
            return
        }

        const productsBefore = this.bannedProducts.length
        await scalesProductLoadingsManagerLocal.addToBannedListForTemplate(productCodes, this.editedTemplate.guid)
        await this.fetchBannedProducts()

        const productsNow = this.bannedProducts.length

        if (productsNow > productsBefore) {
            this.appStore.showSnackbar({
                message: t('scaleTemplatesList.addedProducts', { count: productsNow - productsBefore }),
            })
        } else {
            this.appStore.showSnackbar({
                message: t('scaleTemplatesList.productsNotAdded'),
            })
        }
    }

    removeProductFromBannedList = async (product: ShortProductVO_SF): Promise<void> => {
        await scalesProductLoadingsManagerLocal.removeFromBannedListForTemplate([product.code], this.editedTemplate.guid)

        runInAction(() => {
            this.bannedProducts = this.bannedProducts.filter(item => item.code !== product.code)
        })
    }

    // Список департаментов нужен, чтобы получить имя департамента
    fetchDepartments = async (): Promise<void> => {
        const departments: SimpleDepartment[] = await iProductsManagerLocal.getDepartments(this.userStore.session)

        runInAction(() => {
            this.departments = departments || []
        })
    }

    // Заполняем имена уже добавленных групп
    fetchProductGroupNames = async (): Promise<void> => {
        const sessionId = this.userStore.session
        const usedProductGroups = this.editedTemplate.scalesServeProductsGroupIds || []

        let namesToAdd = {}
        for (let usedGroup of usedProductGroups) {
            const productGroupId = usedGroup.serveProductsGroupId
            const result = await iProductsManagerLocal.getProductGroups(sessionId, productGroupId)

            const productGroup = result.find(item => item.code === productGroupId)
            if (productGroup) {
                namesToAdd[productGroupId] = productGroup.name
            }
        }

        runInAction(() => {
            Object.keys(namesToAdd).forEach(key => {
                this.productGroupsNames.set(key, namesToAdd[key])
            })
        })
    }

    @action
    setProductGroupName = (code: string, name: string): void => {
        this.productGroupsNames.set(code, name)
    }

    // Заполняем имена уже добавленных списков товаров
    fetchSaleGroupsNames = async (): Promise<void> => {
        const usedSaleGroups = this.editedTemplate.scalesServeSaleGroups || []

        const fetchedSaleGroups = await iProductsManagerLocal.getSaleGroupsByType(this.userStore.session, 'SCALES') || []

        let namesToAdd = {}
        for (let usedGroup of usedSaleGroups) {
            const saleGroupCode = usedGroup.code

            const saleGroup = fetchedSaleGroups.find(item => item.code === saleGroupCode)
            if (saleGroup) {
                namesToAdd[saleGroupCode] = saleGroup.name
            }
        }

        runInAction(() => {
            Object.keys(namesToAdd).forEach(key => {
                this.saleGroupsNames.set(key, namesToAdd[key])
            })
        })
    }

    @action
    setSaleGroupName = (code: string, name: string): void => {
        this.saleGroupsNames.set(code, name)
    }

    getMarkingsFromIds = (itemIds: number[]): string[] => {
        if (!this.loadedProducts) return []

        let markings = []
        itemIds.forEach(id => {
            const product = this.loadedProducts.find(product => product.id === id)
            if (product) {
                markings.push(product.marking)
            }
        })
        return markings
    }

    changeManufactureDate = async (itemIds: number[], newDate: string): Promise<void> => {
        const markings = this.getMarkingsFromIds(itemIds)
        await scalesInfoManager.updateDateOfManufacture(markings, newDate, this.editedTemplate.id)

        this.fetchLoadedProducts()
    }

    openLoadedProductsReport = async (itemIds: number[], fileType: FILE_TYPE): Promise<void> => {
        const markings = this.getMarkingsFromIds(itemIds)

        // window.open сработает только если он был вызван в главном потоке
        // поэтому мы сначала открываем окно, а потом подменяем ему адрес
        const reportWindow = window.open()

        const fileName = await reportsProcessorLocal.getFileForScalesTemplateProductsWithDateOfManufactureReport(
            markings,
            this.editedTemplate.id,
            fileType
        )

        const reportUrl = `${config.reportsAddress}?Action=getFile&FILE_NAME=${fileName}`
        reportWindow.location.replace(reportUrl)
    }

    @action
    openTemplate = async (id: number): Promise<ScalesTemplateVO> => {
        this.editedTemplate = null
        this.originalEditedTemplate = null
        this.departments = []
        this.bannedProducts = null
        this.loadedProducts = null

        if (!this.scaleTemplates?.length) {
            await this.fetchScaleTemplates()
        }

        let editedTemplate = toJS(this.scaleTemplates.find(item => item.id === id))

        if (id === -1) {
            editedTemplate = createScalesTemplateVO({
                id: -1,
                deleted: false,
                name: t('scaleTemplatesList.newTemplate'),
                plues: [],
                scalesServeProductsGroupIds: [],
                scalesServeSaleGroups: [],
                scalesServeSectionIds: [],
                scalesSet: [],
                serveAllProductsList: false
            })
        }

        if (!editedTemplate) {
            this.goBack()
            return null
        }
        this.editedTemplate = editedTemplate
        this.originalEditedTemplate = id === -1 ? null : cloneDeep(editedTemplate)
        return editedTemplate
    }

    @action
    closeEditedTemplate = (): void => {
        if (this.modified) {
            this.appStore.showDialog({
                title: t('common.notSavedTitle'),
                message: t('common.notSavedMessage'),
                mode: DIALOG,
                onYes: this.goBack
            })
            return
        }

        this.goBack()
    }

    goBack = (): void => {
        goTo(`${SCALES_MODULE}${TEMPLATES}`)
        this.editedTemplate = null
        this.originalEditedTemplate = null
    }

    copyTemplate = async (template: ScalesTemplateVO): Promise<void> => {
        await withSpinner(scalesManagerLocal1.copyScalesTemplate(
            this.userStore.session,
            toJS(template)
        ))

        this.snackbarStore.show({
            message: t('scaleTemplatesList.templateCopied', { name: template.name, interpolation: {
                escapeValue: false
            }}),
            variant: 'success'
        })
        this.fetchScaleTemplates()
    }

    showDeleteTeplateDialog = (template: ScalesTemplateVO): void => {
        this.appStore.showDialog({
            mode: DIALOG,
            title: t('scaleTemplatesList.deleteDialogTitle'),
            message: t('scaleTemplatesList.deleteDialogMessage'),
            onYes: () => this.deleteTemplate(template),
        })
    }

    deleteTemplate = async (template: ScalesTemplateVO): Promise<void> => {
        await withSpinner(scalesManagerLocal1.deleteScalesTemplate(
            this.userStore.session,
            template.id
        ))

        this.snackbarStore.show({
            message: t('scaleTemplatesList.templateRemoved', { name: template.name, interpolation: {
                escapeValue: false
            }}),
            duration: SNACKBAR_EXTENDED_DURATION,
            variant: 'error',
            actions: [
                <Button
                    key="snackbarUndoButton"
                    id="snackbarUndoButton"
                    onClick={async () => {
                        this.undoDeleteTemplate(template)
                        this.snackbarStore.close()
                    }}
                    size="small"
                    style={{ color: '#FFB800', marginRight: 8 }}
                >
                    { t('common.undo') }
                </Button>
            ]
        })

        runInAction(() => {
            const removedIndex = this.scaleTemplates.findIndex(item => item.id === template.id)
            if (removedIndex !== -1) {
                this.scaleTemplates.splice(removedIndex, 1)
            }
        })
    }

    undoDeleteTemplate = async (template: ScalesTemplateVO): Promise<void> => {
        await withSpinner(scalesManagerLocal1.undoDeleteScalesTemplate(
            this.userStore.session,
            template.id
        ))

        this.snackbarStore.show({
            variant: 'success',
            message: t('scaleTemplatesList.templateUndo', { name: template.name, interpolation: {
                escapeValue: false
            }})
        })
        this.fetchScaleTemplates()
    }

    updateTemplate = (changes: Partial<ScalesTemplateVO>): void => {
        Object.keys(changes).forEach(key => {
            this.editedTemplate[key] = changes[key]
        })
    }

    saveTemplate = async (): Promise<void> => {
        let newTemplate = await withSpinner(scalesManagerLocal1.addScalesTemplate(
            this.userStore.session,
            toJS(this.editedTemplate),
        ))

        this.snackbarStore.show({
            variant: 'success',
            message: t('scaleTemplatesList.templateSaved', {
                name: this.editedTemplate.name, interpolation: {
                    escapeValue: false
                }
            })
        })

        runInAction(() => {
            this.editedTemplate = newTemplate
            this.originalEditedTemplate = cloneDeep(newTemplate)
        })

        this.updateAppBar()
    }

    loadProductsToScale = async (): Promise<void> => {
        if (isNil(this.editedTemplate?.guid)) {
            this.showNeedSaveSnackbar()
            return
        }

        await withSpinner(scalesManagerLocal2.loadProductsToTemplate(
            this.userStore.session,
            this.editedTemplate.guid
        ))
    }

    updateAppBar = () => {
        const { updateState } = this.appBarStore

        updateState({
            title: `${t('set10.scalesTemplates')} - ${this.editedTemplate?.name}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: this.closeEditedTemplate,
        })
    }

    @action
    reset = () => {
        this.scaleTemplates = []
        this.editedTemplate = null
        this.originalEditedTemplate = null
        this.departments = []
        this.productGroupsNames = new Map<string, string>()
        this.saleGroupsNames = new Map<string, string>()
        this.bannedProducts = null
        this.loadedProducts = null
    }
}
