import React, { Component, HTMLProps } from 'react'
import { inject, observer } from 'mobx-react'
import { NOTIFICATION_STORE } from '../../../store/stores'
import { NotificationInfo, NotificationStore, NotificationType } from '../../../store/notification-store'
import classNames from 'classnames'
import { findNotificationInGroup, getNotificationTooltip } from './notifications-util'
import Tooltip from '@material-ui/core/Tooltip'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'

const styles = require('./notifications.scss')

export interface MenuItemWithNotificationProps extends HTMLProps<HTMLDivElement> {
    notificationStore?: NotificationStore
    notificationType: NotificationType
}

@inject(NOTIFICATION_STORE)
@observer
export class MenuNotification extends Component<MenuItemWithNotificationProps> {
    render() {
        const { groups } = this.props.notificationStore
        const { notificationType } = this.props

        if (!groups) return null

        let notification: NotificationInfo
        for (let group of groups) {
            notification = findNotificationInGroup(notificationType, group)
            if (notification) {
                break
            }
        }

        if (!notification || !notification.counters || notification.counters.length < 1) return null

        return (
            <Tooltip enterDelay={TOOLTIP_DELAY} title={getNotificationTooltip(notificationType)}>
                <p className={classNames(styles.counters)}>({notification.counters.join(' / ')})</p>
            </Tooltip>
        )
    }
}
