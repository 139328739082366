import * as React from 'react'
import { observable, action, autorun } from 'mobx'
import { ROUTE_CHANGE_HANDLERS } from '../utils/router-util'
import { RouterStore } from 'mobx-react-router'
import { getStore } from './stores-repository'
import { ROUTING_STORE } from './stores'

export type AppBarIcon = 'menu' | 'leftArrow'
export const MENU: AppBarIcon = 'menu'
export const LEFT_ARROW: AppBarIcon = 'leftArrow'

export interface AppBarState {
    title?: string
    leftIcon?: AppBarIcon
    onLeftIconClick?: () => void
    additionalContent?: React.ReactNode | React.ReactNode[]
    showNotifications?: boolean
}

export class AppBarStore {
    @observable
    leftIcon: AppBarIcon = MENU
    @observable
    title: string
    @observable
    onLeftIconClick: () => void
    @observable
    additionalContent: React.ReactNode | React.ReactNode[]
    @observable
    showNotifications: boolean = true

    nextRoute: string = null

    prevRoute: string = null

    private routingStore: RouterStore = getStore(ROUTING_STORE)

    startRouteWatching = () => {
        autorun(() => {
            this.prevRoute = this.nextRoute
            this.nextRoute = this.routingStore.location.pathname

            if (!this.nextRoute || this.prevRoute === this.nextRoute) return

            if (this.prevRoute) {
                for (let handler of ROUTE_CHANGE_HANDLERS) {
                    if (this.prevRoute.match(handler.routeMatcher)) {
                        if (handler.onLeave) {
                            handler.onLeave(this.nextRoute, this.prevRoute)
                        }
                        break
                    }
                }
            }

            for (let handler of ROUTE_CHANGE_HANDLERS) {
                if (this.nextRoute.match(handler.routeMatcher)) {
                    if (handler.onEnter) {
                        handler.onEnter(this.nextRoute, this.prevRoute)
                    }
                    break
                }
            }
        })
    }

    @action
    updateState = (state: AppBarState): void => {
        this.leftIcon = state.leftIcon
        this.title = state.title
        this.onLeftIconClick = state.onLeftIconClick
        this.additionalContent = state.additionalContent
    }

    @action
    reset = (): void => {
        this.leftIcon = MENU
        this.title = undefined
        this.onLeftIconClick = undefined
        this.additionalContent = undefined
        this.showNotifications = true
    }
}
