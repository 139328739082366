import React, { Component } from 'react'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { t } from 'i18next'
import Grid from '@material-ui/core/Grid'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { INT, NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { Tape } from '../../../core/advertising-actions/advertising-actions'

export interface ChequeAdvertisingPrintSettingsModalProps {
    open: boolean,
    tapeWidthProp: number,
    settings: any,
    onSettingsChange: (value: any) => void,
    handleClose: () => void,
    handleSetTapeWidth: (value: number) => void
}

export interface ChequeAdvertisingPrintSettingsModalState {
    isSetupManually: boolean,
    printSlipInsideReceipt: string,
    tapeWidth: Tape
}

export class ChequeAdvertisingPrintSettingsModal extends Component
    <ChequeAdvertisingPrintSettingsModalProps, ChequeAdvertisingPrintSettingsModalState> {

    state: ChequeAdvertisingPrintSettingsModalState = {
        isSetupManually: getTape(this.props.tapeWidthProp) === Tape.TapeManual,
        printSlipInsideReceipt: this.props?.settings?.printSlipInsideReceipt && this.props.settings.printSlipInsideReceipt === 'true'
            ? 'true' : 'false',
        tapeWidth: this.props.tapeWidthProp ? getTape(this.props.tapeWidthProp) : Tape.Tape80
    }

    render() {
        const { isSetupManually, printSlipInsideReceipt, tapeWidth } = this.state
        const { open, tapeWidthProp, handleClose, onSettingsChange, handleSetTapeWidth } = this.props

        return (
            <AdaptiveDialog
                open={open}
                fullWidth
                maxWidth={'sm'}
            >
                <DialogTitle style={{ padding: '16px' }}>
                    {t('actionResults.baseTextEditor.settings')}
                </DialogTitle>
                <DialogContent style={{ padding: '8px 16px' }}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <SelectInput
                                options={getTapeWidthOptions()}
                                label={t('actionResults.baseTextEditor.tapeWidth')}
                                value={tapeWidth}
                                onSelect={option => {
                                    this.setState({ tapeWidth: option.value })
                                    if (option.value !== Tape.TapeManual) {
                                        handleSetTapeWidth(getTapeWidth(option.value))
                                        this.setState({ isSetupManually: false })
                                    } else {
                                        this.setState({ isSetupManually: true })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <NumberInput
                                label={t('actionResults.baseTextEditor.specifyWidth')}
                                disabled={!isSetupManually}
                                value={tapeWidthProp}
                                onValueChange={value => handleSetTapeWidth(value)}
                                valuePostfix={tapeWidthProp ? t('actionResults.baseTextEditor.millimeters') : ''}
                                min={37}
                                max={80}
                                dataType={INT}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <SelectInput
                                options={getPrintMessage()}
                                label={t('actionResults.baseTextEditor.printMessage')}
                                value={printSlipInsideReceipt}
                                onSelect={option => this.setState({ printSlipInsideReceipt: option.value })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleClose}
                    >
                        {t('common.undo')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            onSettingsChange({
                                printSlipInsideReceipt
                            })
                            handleClose()
                        }}
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}

export const getTape = (value: number): Tape => {
    switch (value) {
        case 80:
            return Tape.Tape80
        case 57:
            return Tape.Tape57
        default:
            return Tape.TapeManual
    }
}

export const getTapeWidth = (tape: Tape): number => {
    switch (tape) {
        case Tape.Tape80:
            return 80
        case Tape.Tape57:
            return 57
        case Tape.TapeManual:
            return 80
    }
}

export const getTapeWidthOptions = () => {
    return [
        {
            label: t('actionResults.baseTextEditor.manualTapeWidth', { manualTapeWidth: 80 }),
            value: Tape.Tape80
        },
        {
            label: t('actionResults.baseTextEditor.manualTapeWidth', { manualTapeWidth: 57 }),
            value: Tape.Tape57
        },
        {
            label: t('actionResults.baseTextEditor.installManually'),
            value: Tape.TapeManual
        }
    ]
}

export const getPrintMessage = () => {
    return [
        {
            label: t('actionResults.baseTextEditor.separateSlip'),
            value: 'false'
        },
        {
            label: t('actionResults.baseTextEditor.partCheck'),
            value: 'true'
        }
    ]
}
