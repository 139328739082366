import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { CLOSED_DOWN } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-status'
import { CashTemplateVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-template-vo'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import {SHOP_CASHES_STORE, APP_STORE, SHOP_STORE} from '../../../store/stores'
import {DeviceSettingsType} from '../../../core/values'
import {ShopCashesStore} from '../../../store/shops/shop-settings/shop-cashes-store'
import { config } from '../../../config/config'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { ShopStore } from '../../../store/shops/shop-settings/shop-store'
import { AppStore } from '../../../store/app-store'
import { AdaptiveDialog } from '../../../../components/adaptive-dialog/adaptive-dialog'
import { LabeledData } from '@crystalservice/crystals-ui/lib/components/labeled-data/labeled-data'
import { goTo } from '../../../utils/router-util'
import { CASH_MODULE, TEMPLATES } from '../../../core/app-routes'

const styles = require('./cash-settings-dialog.scss')

const MAX_CASHES_IN_SHOP = 999

interface CashSettingsDialogProps {
    shopCashesStore?: ShopCashesStore
    shopStore?: ShopStore
    app?: AppStore
}

@inject(APP_STORE)
@inject(SHOP_STORE)
@inject(SHOP_CASHES_STORE)
@observer
export class CashSettingsDialog extends React.Component<CashSettingsDialogProps> {

    handleTemplateChange = (selectedOption: DefaultSelectOption<number>) => {
        const { updateCash, cashesTemplates } = this.props.shopCashesStore
        const template = cashesTemplates.find(t => t.id === selectedOption.value)
        if (template) {
            updateCash({template: toJS(template)})
        }
    }

    handleSaveButton = () => {
        const {saveNewCashes, saveCash, cashSettings, cashSettingsType, cashSettingsCount, closeCashSettings} = this.props.shopCashesStore
        switch (cashSettingsType) {
            case DeviceSettingsType.NEW:
                saveNewCashes(toJS(cashSettings), cashSettingsCount)
                break
            case DeviceSettingsType.EDIT:
                saveCash(toJS(cashSettings))
                break
            default:
                break
        }
        closeCashSettings()
    }

    handleNewLayout = () => {
        this.props.shopCashesStore.closeCashSettings()
        // TODO Переход на экран оборудования касс
    }

    handleNewTemplate = () => {
        this.props.shopCashesStore.closeCashSettings()
        goTo(`${CASH_MODULE}${TEMPLATES}`)
    }

    render() {
        const {
            cashSettings, closeCashSettings, cashSettingsType, cashLayouts, updateCashCount,
            cashSettingsCount, cashValidation, cashesTemplates, updateCash, cashes,
        } = this.props.shopCashesStore
        const { connectedToCentrum } = this.props.shopStore
        const { isCentrum } = this.props.app
        const editMode = cashSettingsType === DeviceSettingsType.EDIT

        if (!cashSettings || !cashValidation) return null

        const numberValidaton = cashValidation.getValidationFor('number')
        const layoutValidation = cashValidation.getValidationFor('layoutName')
        const templateValidation = cashValidation.getValidationFor('template')

        let saveButtonAvailable = false
        switch (cashSettingsType) {
            case DeviceSettingsType.EDIT:
                saveButtonAvailable = cashValidation.modified && cashValidation.valid
                break
            case DeviceSettingsType.NEW:
                saveButtonAvailable = layoutValidation.valid && templateValidation.valid
                break
            default:
                break
        }

        const closed = cashSettings.status === CLOSED_DOWN

        let maxCashesError = ''
        if (!editMode) {
            const newCashesAmount = cashSettingsCount + cashes.length
            maxCashesError = newCashesAmount > MAX_CASHES_IN_SHOP
                ? t('shopPage.maxCashesWarning', { count: MAX_CASHES_IN_SHOP })
                : ''
        }

        const dialogContent = editMode ? (
            <DialogContent className={styles.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <NumberInput
                            id="dialogNumberInput"
                            autoFocus
                            value={cashSettings.number}
                            disabled={!isCentrum && connectedToCentrum}
                            min={1}
                            max={MAX_CASHES_IN_SHOP}
                            label={t('shopPage.deviceNumber')}
                            onValueChange={value => updateCash({number: value})}
                            errorText={numberValidaton.touched && numberValidaton.firstError}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={styles.state}>
                            <InputLabel className={styles.stateLabel}>
                                {t('shopPage.cashStatus')}
                            </InputLabel>
                            <Typography className={styles.stateValue} color={closed ? 'textSecondary' : 'primary'}>
                                {closed ? t('shopPage.cashClosed') : t('shopPage.cashActive')}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectInput
                            id="dialogLayoutInput"
                            onSelect={selectedOption => updateCash({ layoutName: selectedOption.value })}
                            label={t('shopPage.cashLayout')}
                            className={styles.dialogInputs}
                            options={cashLayouts.map(layout => {
                                return {label: layout, value: layout}
                            })}
                            value={cashSettings.layoutName}
                        />
                        {
                            cashesTemplates.length > 0 ? (
                                <SelectInput<DefaultSelectOption<number>>
                                    id="dialogTemplateInput"
                                    onSelect={this.handleTemplateChange}
                                    label={t('shopPage.deviceTemplate')}
                                    disabled={editMode}
                                    className={styles.dialogInputs}
                                    options={cashesTemplates.map((template: CashTemplateVO) => {
                                        return { label: template.name, value: template.id }
                                    })}
                                    value={cashSettings.template.id}
                                />
                            ) : (
                                <TextField
                                    id="dialogNoTemplateInput"
                                    fullWidth
                                    className={styles.dialogInputs}
                                    label={t('shopPage.deviceTemplate')}
                                    value={t('shopPage.noCashesTemplates')}
                                    InputProps={{
                                        error: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    enterDelay={TOOLTIP_DELAY}
                                                    title={t('shopPage.addNewTemplate')}
                                                >
                                                    <IconButton
                                                      onClick={this.handleNewTemplate}
                                                    >
                                                      <AddCircleOutline />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={12} className={styles.kkm}>
                        <span>{t('shopPage.kkm')}</span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabeledData // TODO SFM-928 проверить нужен ли onValueChange
                            id="dialogPrinterInput"
                            value={cashSettings.fiscalPrinterInfo || ''}
                            label={t('shopPage.fiscalPrinterInfo')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabeledData
                            id="dialogEKLZInput"
                            value={cashSettings.eklzNum || ''}
                            label={t('shopPage.eklzNum')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabeledData
                            id="dialogFactoryNumInput"
                            value={cashSettings.factoryNum || ''}
                            label={t('shopPage.factoryNum')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabeledData
                            id="dialogFiscalDateInput"
                            value={cashSettings.fiscalDate || ''}
                            label={t('shopPage.fiscalDate')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabeledData
                            id="dialogRegisterNumberInput"
                            value={cashSettings.fiscalNum || ''}
                            label={t('shopPage.fiscalNum')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        ) : (
            <DialogContent className={styles.content}>
                <NumberInput
                    id="dialogCountInput"
                    autoFocus
                    className={styles.dialogInputs}
                    value={cashSettingsCount}
                    onValueChange={updateCashCount}
                    min={1}
                    max={MAX_CASHES_IN_SHOP}
                    errorText={maxCashesError}
                    label={t('shop.amount')}
                />
                <SelectInput
                    id="dialogLayoutInput"
                    onSelect={selectedOption => updateCash({ layoutName: selectedOption.value })}
                    label={t('shopPage.cashLayout')}
                    className={styles.dialogInputs}
                    options={cashLayouts.map(layout => {
                        return { label: layout, value: layout }
                    })}
                    value={cashSettings.layoutName}
                />
                {
                    cashesTemplates.length > 0 ? (
                        <SelectInput<DefaultSelectOption<number>>
                            id="dialogTemplateInput"
                            onSelect={this.handleTemplateChange}
                            label={t('shopPage.deviceTemplate')}
                            disabled={editMode}
                            className={styles.dialogInputs}
                            options={cashesTemplates.map((template: CashTemplateVO) => {
                                return { label: template.name, value: template.id }
                            })}
                            value={cashSettings.template.id}
                        />
                    ) : (
                        <TextField
                            id="dialogNoTemplateInput"
                            fullWidth
                            className={styles.dialogInputs}
                            label={t('shopPage.deviceTemplate')}
                            value={t('shopPage.noCashesTemplates')}

                            InputProps={{
                                error: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            enterDelay={TOOLTIP_DELAY}
                                            title={t('shopPage.addNewTemplate')}
                                        >
                                            <IconButton
                                              onClick={this.handleNewTemplate}
                                            >
                                              <AddCircleOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )
                }
            </DialogContent>
        )

        return (
            <AdaptiveDialog
                id="fullScreenDialog"
                open={Boolean(cashSettings)}
                onClose={editMode && saveButtonAvailable ? null : closeCashSettings}
                className={styles.dialog}
            >
                <DialogTitle>
                    {editMode ? t('shopPage.cashTitleEdit') : t('shopPage.cashTitleNew')}
                </DialogTitle>
                {dialogContent}
                <DialogActions className={styles.actions}>
                    <Button
                        onClick={closeCashSettings}
                        color="primary"
                        id="dialogCancelButton"
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={this.handleSaveButton}
                        color="primary"
                        disabled={!saveButtonAvailable || Boolean(maxCashesError)}
                        id="dialogSaveButton"
                    >
                        {t('common.save')}
                    </Button>
                </DialogActions>
            </AdaptiveDialog>
        )
    }
}
