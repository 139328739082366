import {iProductsManagerLocal} from '../../../protocol/set10/i-products-manager-local'
import {SimpleDepartment} from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-department'
import React from 'react'
import {t} from 'i18next'
import { AutocompleteInput, AutocompleteInputProps } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import classNames from 'classnames'

const styles = require('./autocomplete.scss')

export interface DepartmentAutocompleteState {
    foundDepartments: SimpleDepartment[]
}

export interface DepartmentAutocompleteProps extends Partial<AutocompleteInputProps<SimpleDepartment>>  {
    session: string
    /**
     * Указанные айдишники не будут отображаться в найденных элементах
     */
    excludedItemsNumbers?: number[]
    /**
     * К указанным элементам будет применен дополнительный стиль
     */
    markedItemsNumbers?: number[]
    markedClassName?: string
}

export class DepartmentAutocomplete extends React.Component<DepartmentAutocompleteProps, DepartmentAutocompleteState> {
    state: DepartmentAutocompleteState = {
        foundDepartments: []
    }

    mounted: boolean = false

    componentDidMount() {
        this.mounted = true
        this.fetchDepartments()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    async fetchDepartments(): Promise<void> {
        const foundDepartments: SimpleDepartment[] = await iProductsManagerLocal.getDepartments(this.props.session)
        if (this.mounted) this.setState({foundDepartments})
    }

    render() {
        const { session, excludedItemsNumbers, markedItemsNumbers, markedClassName, ref, ...autoCompleteProps } = this.props

        return (
            <AutocompleteInput<SimpleDepartment>
                id="departmentAutocompleteInput"
                labelField="name"
                requestDelay={0}
                suggestionsLimit={50}
                placeholder={t('components.autocomplete.departmentPlaceholder')}
                keyField="number"
                fetchDataCallback={async (input: string) => {
                    let result: SimpleDepartment[] = []
                    const foundDepartments = this.state.foundDepartments

                    if (foundDepartments && foundDepartments.length > 0) {
                        if (input === '%') {
                            result = foundDepartments
                        } else {
                            result = foundDepartments.filter(department =>
                                department.name.toLocaleLowerCase().includes(input.toLowerCase())
                            )
                        }
                    }

                    if (!excludedItemsNumbers) return result

                    // Не включаем из списка исключенных
                    return result.filter(item => {
                        return !excludedItemsNumbers.some(num => num === item.number)
                    })
                }}
                menuItemRenderer={item => {
                    return (
                        <div className={classNames({
                            [markedClassName || styles.selectedAction]: markedItemsNumbers && markedItemsNumbers.some(
                                num => num === item.number
                            )
                        })}>
                            {item.name}
                        </div>
                    )
                }}
                {...autoCompleteProps}
            />
        )
    }
}
