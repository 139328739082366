import { observable, action, runInAction } from 'mobx'
import { iEquipmentManagerLocal } from '../../../protocol/set10/i-equipment-manager-local'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE, APP_STORE } from '../stores'
import { AppStore } from '../app-store'
import { EquipmentModelVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { withSpinner } from '../with-spinner'
import { t } from 'i18next'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { DevicesClasses } from '../../core/devices-classes'

export class ScalesDevicesStore {
    @observable
    registeredScales: EquipmentModelVO[] = []

    @observable
    addScaleDialogOpened: boolean = false

    @observable
    scalesToAdd: string = null

    availableScales: EquipmentModelVO[] = []

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    fetchScales = async () => {
        const availableScales = await iEquipmentManagerLocal.getAllEquipmentModelsOfClass(
            this.userStore.session,
            DevicesClasses.ScalesDeviceClass,
            this.appStore.locale,
        )

        const registeredScales = await iEquipmentManagerLocal.getAllRegisteredScales(
            this.userStore.session,
            this.appStore.locale,
        )

        runInAction(() => {
            const available = availableScales.reduce((acc, current, i) => {
                const registered = registeredScales.find(registeredScale => registeredScale.name === current.name)

                return registered ? acc : [...acc, { ...current, uuid: `${current.name}-${i}` }]
            }, [])

            this.availableScales = available
            this.registeredScales = registeredScales
            this.scalesToAdd = available[0].uuid
        })
    }

    @action
    openAddScaleDialog = () => {
        this.addScaleDialogOpened = true
    }

    @action
    closeAddScaleDialog = () => {
        this.addScaleDialogOpened = false
    }

    @action
    updateScalesToAdd = (selected: string) => {
        this.scalesToAdd = selected
    }

    @action
    saveScales = async () => {
        const scalesToAdd = this.availableScales.find(scale => scale.uuid === this.scalesToAdd)

        await iEquipmentManagerLocal.registerAndGetModel(
            this.userStore.session,
            DevicesClasses.ScalesDeviceClass,
            scalesToAdd.equipmentType.name,
            scalesToAdd.name,
            this.appStore.locale,
        )

        await withSpinner(this.fetchScales)
        this.closeAddScaleDialog()
    }

    @action
    handleDeleteScale = (id: number) => {
        this.appStore.showDialog({
            mode: DIALOG,
            title: t('scalesDevices.deleteScalesDialogTitle'),
            message: t('scalesDevices.deleteScalesDialogMessage'),
            id: 'deleteScalesDialog',
            onYes: () => this.deleteScale(id),
        })
    }

    @action
    deleteScale = async (id: number) => {
        await iEquipmentManagerLocal.unRegisterEquipment(this.userStore.session, id)
        await withSpinner(this.fetchScales)
    }

    @action
    reset = (): void => {
        this.registeredScales = []
        this.addScaleDialogOpened = false
        this.scalesToAdd = null
        this.availableScales = []
    }
}
