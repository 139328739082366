/*
Старая система разбиения проекта на модули. Если модуль не включен, то весь его функционал не доступен.
 */
export type ServerModule = 'reports' | 'register' | 'cards' | 'internalcards' | 'presentcards' | 'couponcards' | 'externalcards' | 'loy'
    | 'processing-discounts' | 'setretailx' | 'printing' | 'operday' | 'operdaymessanger' | 'topology' | 'users' | 'products' | 'limits'
    | 'nginx' | 'cash' | 'cashiers' | 'equipment' | 'scalestech' | 'scales' | 'paymenttypemanager' | 'externalsystemsmanager'
    | 'producttypemanager' | 'operdaybankverification' | 'operdayreportscdoptimizationplugin' | 'operdayreportspurchaserscountplugin'
    | 'operdayreportspurchasessumplugin' | 'operdayreportsproblematicgoodsplugin' | 'operdayreportsproductplacementplugin'
    | 'operdayreportspersonnelmotivationplugin' | 'operdayprintersmodule' | 'erp_integration' | 'centrum_erp_integration' | 'centrum_nginx'
    | 'centrum_cards' | 'maincash' | 'maincashdoctypemanager'

export const REPORTS: ServerModule = 'reports'
export const REGISTER: ServerModule = 'register'
export const CARDS: ServerModule = 'cards'
export const INTERNAL_CARDS: ServerModule = 'internalcards'
export const PRESENT_CARDS: ServerModule = 'presentcards'
export const COUPON_CARDS: ServerModule = 'couponcards'
export const EXTERNAL_CARDS: ServerModule = 'externalcards'
export const LOY: ServerModule = 'loy'
export const PROCESSING_DISCOUNTS: ServerModule = 'processing-discounts'
export const SETRETAILX: ServerModule = 'setretailx'
export const PRINTING: ServerModule = 'printing'
export const OPERDAY: ServerModule = 'operday'
export const OPERDAY_MESSENGER: ServerModule = 'operdaymessanger'
export const TOPOLOGY: ServerModule = 'topology'
export const USERS: ServerModule = 'users'
export const PRODUCTS: ServerModule = 'products'
export const LIMITS: ServerModule = 'limits'
export const NGINX: ServerModule = 'nginx'
export const CASH: ServerModule = 'cash'
export const CASHIERS: ServerModule = 'cashiers'
export const EQUIPMENT: ServerModule = 'equipment'
export const SCALES_TECH: ServerModule = 'scalestech'
export const SCALES: ServerModule = 'scales'
export const PAYMENT_TYPE_MANAGER: ServerModule = 'paymenttypemanager'
export const EXTERNAL_SYSTEMS_MANAGER: ServerModule = 'externalsystemsmanager'
export const PRODUCT_TYPE_MANAGER: ServerModule = 'producttypemanager'
export const OPERDAY_BANK_VERIFICATION: ServerModule = 'operdaybankverification'
export const OPERDAY_REPORTS_CD_OPTIMIZATION_PLUGIN: ServerModule = 'operdayreportscdoptimizationplugin'
export const OPERDAY_REPORTS_PURCHASERS_COUNT_PLUGIN: ServerModule = 'operdayreportspurchaserscountplugin'
export const OPERDAY_REPORTS_PURCHASES_SUM_PLUGIN: ServerModule = 'operdayreportspurchasessumplugin'
export const OPERDAY_REPORTS_PROBLEMATIC_GOODS_PLUGIN: ServerModule = 'operdayreportsproblematicgoodsplugin'
export const OPERDAY_REPORTS_PRODUCT_PLACEMENT_PLUGIN: ServerModule = 'operdayreportsproductplacementplugin'
export const OPERDAY_REPORTS_PERSONNEL_MOTIVATION_PLUGIN: ServerModule = 'operdayreportspersonnelmotivationplugin'
export const OPERDAY_PRINTERS_MODULE: ServerModule = 'operdayprintersmodule'
export const ERP_INTEGRATION: ServerModule = 'erp_integration'
export const CENTRUM_ERP_INTEGRATION: ServerModule = 'centrum_erp_integration'
export const CENTRUM_NGINX: ServerModule = 'centrum_nginx'
export const CENTRUM_CARDS: ServerModule = 'centrum_cards'
export const MAIN_CASH: ServerModule = 'maincash'
export const MAIN_CASH_DOC_TYPE_MANAGER: ServerModule = 'maincashdoctypemanager'
