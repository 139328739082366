import React from 'react'
import { inject, observer } from 'mobx-react'
import { PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { t } from 'i18next'
import {
    DefaultSelectOption,
    SelectInput
} from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

const styles = require('./product-info.scss')

export interface ProductRecyclingInputProps {
    productDetailsStore?: ProductDetailsStore
}

export interface ProductGeneralInfoState {
    groupsDialogOpen: boolean
}

@inject(PRODUCT_DETAILS_STORE)
@observer
export class ProductRecyclingInput extends React.Component<ProductRecyclingInputProps> {

    recyclingCodeList: Array<{ code: number, name: string, shortName: string}> = [
        { code: 0, name: t('productDetails.recyclingMaterialName.codeNull'), shortName: '' },
        { code: 1, name: t('productDetails.recyclingMaterialName.code1'), shortName: 'PET/PETE' },
        { code: 2, name: t('productDetails.recyclingMaterialName.code2'), shortName: 'PE-HD/HDPE' },
        { code: 3, name: t('productDetails.recyclingMaterialName.code3'), shortName: 'PVC/V' },
        { code: 4, name: t('productDetails.recyclingMaterialName.code4'), shortName: 'PE-LD/LDPE' },
        { code: 5, name: t('productDetails.recyclingMaterialName.code5'), shortName: 'РР' },
        { code: 6, name: t('productDetails.recyclingMaterialName.code6'), shortName: 'PS' },
        { code: 7, name: t('productDetails.recyclingMaterialName.code7'), shortName: 'О/OTHER' },

        { code: 20, name: t('productDetails.recyclingMaterialName.code20'), shortName: 'PAP' },
        { code: 21, name: t('productDetails.recyclingMaterialName.code21'), shortName: 'PAP' },
        { code: 22, name: t('productDetails.recyclingMaterialName.code22'), shortName: 'PAP' },

        { code: 40, name: t('productDetails.recyclingMaterialName.code40'), shortName: 'FE' },
        { code: 41, name: t('productDetails.recyclingMaterialName.code41'), shortName: 'ALU' },
        { code: 42, name: t('productDetails.recyclingMaterialName.code42'), shortName: '' },

        { code: 50, name: t('productDetails.recyclingMaterialName.code50'), shortName: 'FOR' },
        { code: 51, name: t('productDetails.recyclingMaterialName.code51'), shortName: 'FOR' },
        { code: 52, name: t('productDetails.recyclingMaterialName.code52'), shortName: '' },

        { code: 60, name: t('productDetails.recyclingMaterialName.code60'), shortName: 'TEX' },
        { code: 61, name: t('productDetails.recyclingMaterialName.code61'), shortName: 'TEX' },
        { code: 62, name: t('productDetails.recyclingMaterialName.code62'), shortName: '' },

        { code: 70, name: t('productDetails.recyclingMaterialName.code70'), shortName: 'GL' },
        { code: 71, name: t('productDetails.recyclingMaterialName.code71'), shortName: 'GL' },
        { code: 72, name: t('productDetails.recyclingMaterialName.code72'), shortName: 'GL' },
        { code: 73, name: t('productDetails.recyclingMaterialName.code73'), shortName: '' },

        { code: 80, name: t('productDetails.recyclingMaterialName.code80'), shortName: 'PapPet' },
        { code: 81, name: t('productDetails.recyclingMaterialName.code81'), shortName: '' },
        { code: 82, name: t('productDetails.recyclingMaterialName.code82'), shortName: '' },
        { code: 83, name: t('productDetails.recyclingMaterialName.code83'), shortName: '' },
        { code: 84, name: t('productDetails.recyclingMaterialName.code84'), shortName: 'C/PAP' },
        { code: 85, name: t('productDetails.recyclingMaterialName.code85'), shortName: '' },
        { code: 86, name: t('productDetails.recyclingMaterialName.code86'), shortName: '' },

        { code: 90, name: t('productDetails.recyclingMaterialName.code90'), shortName: '' },
        { code: 91, name: t('productDetails.recyclingMaterialName.code91'), shortName: '' },
        { code: 92, name: t('productDetails.recyclingMaterialName.code92'), shortName: '' },
        { code: 93, name: t('productDetails.recyclingMaterialName.code93'), shortName: '' },

        { code: 95, name: t('productDetails.recyclingMaterialName.code95'), shortName: '' },
        { code: 96, name: t('productDetails.recyclingMaterialName.code96'), shortName: '' },
        { code: 97, name: t('productDetails.recyclingMaterialName.code97'), shortName: '' },
        { code: 98, name: t('productDetails.recyclingMaterialName.code98'), shortName: '' },
        { code: 99, name: t('productDetails.recyclingMaterialName.code99'), shortName: '' },
    ]

    get options(): Array<DefaultSelectOption<number>> {
        return this.recyclingCodeList.map(codeItem => {
            return {
                label: `${codeItem.code ? codeItem.code : ''} ${codeItem.name} ${codeItem.shortName}`,
                value: codeItem.code,
            }
        })
    }

    render() {
        const { productDetails, editProductDetails, updateProductDetails } = this.props.productDetailsStore

        return (
            <SelectInput<DefaultSelectOption<number>>
                id="recyclingCodeSelectInput"
                options={this.options}
                slim
                className={styles.selectInput}
                value={productDetails.recyclingCode || 0}
                onSelect={selectedOption => {
                    editProductDetails({
                        recyclingCode: selectedOption.value ? selectedOption.value : null,
                    })
                    updateProductDetails()
                }}
            />
        )
    }
}
