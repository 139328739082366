import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Add from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { INT, LEFT, RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { RegionVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { NotSearched } from '../../../components/stub-with-img/not-searched'
import { NEW } from '../../../core/values'
import { DialogStore } from '../../../store/dialog-store'
import { RegionStore } from '../../../store/shops/region-store'
import { RegionsStore } from '../../../store/shops/regions-store'
import { DIALOG_STORE, REGION_STORE, REGIONS_STORE } from '../../../store/stores'
import Fab from '@material-ui/core/Fab'

const styles = require('./regions-page.scss')

export interface RegionsPageProps extends React.HTMLProps<HTMLDivElement> {
    regionsStore?: RegionsStore
    regionStore?: RegionStore
    dialogStore?: DialogStore
}

@inject(REGION_STORE)
@inject(REGIONS_STORE)
@inject(DIALOG_STORE)
@observer
export class RegionsPage extends React.Component<RegionsPageProps> {

    componentDidMount() {
        this.props.regionsStore.getRegions()
    }

    componentWillUnmount() {
        this.props.regionsStore.reset()
    }

    showDeletionDialog = (node: RegionVO) => {
        this.props.dialogStore.showDialog({
            title: t('topologyPages.regionDeletionTitle'),
            message: t('topologyPages.regionDeletionMessage', {
                regionName: node.name
            }),
            mode: DIALOG,
            onYes: () => this.props.regionsStore.deleteRegion(node.id)
        })
    }

    render() {
        const {regions} = this.props.regionsStore
        const {openRegion} = this.props.regionStore

        return (
            <div
                id="regionsPage"
                className={styles.topologyPage}
            >
                <Paper className={styles.middleSection}>
                    {regions.length > 0 ? (
                        <>
                            <Table
                                id="regionsList"
                                className={styles.table}
                                items={toJS(regions)}
                                keyFunction={(region: RegionVO): string => `${region.id}`}
                                withHeader
                                collapsible
                                onRowDoubleClick={region => openRegion(region.id)}
                                columns={[
                                    {
                                        header: t('topologyPages.regionsColumnName'),
                                        hAlign: LEFT,
                                        labelField: 'name',
                                        width: '45%'
                                    },
                                    {
                                        dataType: INT,
                                        header: t('topologyPages.regionsColumnShopsCount'),
                                        hAlign: RIGHT,
                                        labelField: 'shopsCount',
                                        width: '45%'
                                    },
                                    {
                                        renderer: props => {
                                            const region: RegionVO = props.item
                                            const id: number = region.id
                                            return (
                                                <div className={styles.actionButtons}>
                                                    <AdaptiveIconButton
                                                        id={`region${id}EditButton`}
                                                        label={t('common.edit')}
                                                        onClick={() => openRegion(region.id)}
                                                    >
                                                        <EditIcon/>
                                                    </AdaptiveIconButton>
                                                    <AdaptiveIconButton
                                                        id={`region${id}DeleteButton`}
                                                        label={t('common.remove')}
                                                        onClick={() => this.showDeletionDialog(region)}
                                                    >
                                                        <DeleteIcon/>
                                                    </AdaptiveIconButton>
                                                </div>
                                            )
                                        },
                                        hAlign: RIGHT,
                                        padding: '0 10px',
                                        showOnRowHover: true,
                                        withControls: true,
                                        width: '136px'
                                    },
                                ]}
                            />
                            <Divider className={styles.divider}/>
                        </>
                    ) : (
                        <NotSearched
                            className={styles.stub}
                            titleText={t('topologyPages.emptyRegions')}
                            hintText=""
                        />
                    )}
                    <Tooltip
                        enterDelay={TOOLTIP_DELAY}
                        title={t('topologyPages.addRegion')}
                    >
                        <div className={styles.addButtonContainer}>
                            <Fab
                                id="addRegionButton"
                                onClick={() => openRegion(NEW)}
                                color="primary"
                            >
                                <Add/>
                            </Fab>
                        </div>
                    </Tooltip>
                </Paper>
            </div>
        )
    }
}
