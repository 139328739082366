import { observable, action, runInAction, computed } from 'mobx'
import { t } from 'i18next'
import {
    createTotalSalesOnCashDesks,
    TotalSalesOnCashDesks
// tslint:disable-next-line:max-line-length
} from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-cash-desk-optimization-plugin/total-sales-on-cash-desks'
import {cashDeskOptimizationReportManagerLocal} from '../../../protocol/set10/cash-desk-optimization-report-manager-local'
import {createTimePeriod, TimePeriod} from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day/time-period'
import {countDaysBetween, getDayEnd, getDayStart} from '../../../utils/date-util'
// tslint:disable-next-line:max-line-length
import { SalesOnCashDesk } from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-cash-desk-optimization-plugin/sales-on-cash-desk'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { REPORTS, CASHES_OPTIMIZATION, CASHDESK_SALES_REPORT, SALES_REPORT } from '../../core/app-routes'
import { Trend} from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day-reports/trend'
import { DATE, DAY_OF_WEEK, HOUR, IntervalType } from '../../core/operday/report-utils'
// tslint:disable-next-line:max-line-length
import { AverageSalesOnCashDesk } from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-cash-desk-optimization-plugin/average-sales-on-cash-desk'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { SortDirection } from '@material-ui/core/TableCell'
import { withSpinner } from '../with-spinner'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, CASH_SALES_REPORT_STORE, USER_STORE } from '../stores'
import { fromClientToServerTime, fromServerToClientTime } from '../../utils/app-util'

export class CashSalesReportStore {
    @observable
    startDate: Date = getDayStart(fromClientToServerTime(new Date())).toDate()
    @observable
    endDate: Date = getDayEnd(fromClientToServerTime(new Date())).toDate()
    @observable
    totalSalesOnCashDesks: TotalSalesOnCashDesks = createTotalSalesOnCashDesks({})
    @observable
    selectedCashDeskTrends: Trend[] = []
    @observable
    intervalType: IntervalType = DATE
    @observable
    averageSalesOnCashDesk: AverageSalesOnCashDesk
    @observable
    selectedCashDeskNumber: number
    @observable
    sortingField: keyof SalesOnCashDesk = 'sum'
    @observable
    sortingDirection: SortDirection = 'desc'
    @observable
    searched: boolean = false

    private userStore: UserStore = getStore(USER_STORE)

    @computed
    get currentTimePeriod(): TimePeriod {
        return createTimePeriod({
            startDate: fromServerToClientTime(this.startDate),
            endDate: fromServerToClientTime(this.endDate),
        })
    }

    @computed
    get intervalsCount(): number {
        let result
        if (this.intervalType === DATE) {
            result = countDaysBetween(this.startDate, this.endDate)
        }
        if (this.intervalType === DAY_OF_WEEK) {
            result = 7
        }
        if (this.intervalType === HOUR) {
            result = 24
        }

        return result
    }

    @computed
    get selectedCashDesk(): SalesOnCashDesk {
        if (!this.totalSalesOnCashDesks.salesOnCashDesk) return null
        return this.totalSalesOnCashDesks.salesOnCashDesk.find(saleOnCashDesk => saleOnCashDesk.number === this.selectedCashDeskNumber)
    }

    @action
    resetCashData = (): void => {
        this.selectedCashDeskTrends = []
        this.selectedCashDeskNumber = null
    }

    fetchData = (): Promise<void> => {
        return withSpinner(([
            this.fetchSalesOnCashDesks,
            this.fetchAverageSalesOnCashDesk
        ]))
    }

    fetchSalesOnCashDesks = (): Promise<void> => {
        return cashDeskOptimizationReportManagerLocal.getSalesOnCashDesks(this.userStore.session, this.currentTimePeriod)
            .then(result => {
                runInAction(() => {
                    this.totalSalesOnCashDesks = result
                    this.searched = true
                })
            })
    }

    @action
    fetchSalesOnCashDesk = () => {
        if (!this.selectedCashDeskNumber) {
            goTo(`${REPORTS}${CASHES_OPTIMIZATION}`)
            return
        }

        withSpinner(cashDeskOptimizationReportManagerLocal.getSalesOnCashDeckByTime(
            this.userStore.session,
            this.intervalsCount,
            this.currentTimePeriod,
            this.intervalType,
            this.selectedCashDeskNumber
            )
                .then(trends => {
                    this.selectedCashDeskTrends = trends
                })
        )
    }

    fetchAverageSalesOnCashDesk = (): Promise<void> => {
        return cashDeskOptimizationReportManagerLocal.getAverageSalesOnCashDesk(this.userStore.session, this.currentTimePeriod)
            .then(result => {
                runInAction(() => {
                    this.averageSalesOnCashDesk = result
                })
            })
    }

    @action
    onDateChange = (newStartDate: Date, newEndDate: Date) => {
        this.startDate = newStartDate
        this.endDate = newEndDate
    }

    @action
    goToCashDesk = (item: SalesOnCashDesk) => {
        this.selectedCashDeskNumber = item.number

        goTo(`${REPORTS}${CASHES_OPTIMIZATION}${CASHDESK_SALES_REPORT}/${item.number}`)
    }

    @action
    editIntervalType = (newInterval: IntervalType) => {
        this.intervalType = newInterval
        this.fetchSalesOnCashDesk()
    }

    @action
    sortingChange = (value: string) => {
        const parts: string[] = String(value).split(' ')
        const field: keyof SalesOnCashDesk = parts[0] as keyof SalesOnCashDesk
        const direction: SortDirection = parts[1] as SortDirection

        this.sortingField = field
        this.sortingDirection = direction
    }

    getSortingOptions = (): Array<{ label: string, value: string }> => [
        {value: 'sum desc', label: t('reports.sortBySaleSumDescending')},
        {value: 'sum asc', label: t('reports.sortBySaleSumAscending')},
        {value: 'purchasers desc', label: t('reports.sortByPurchasersDescending')},
        {value: 'purchasers asc', label: t('reports.sortByPurchasersAscending')}
    ]

    @action
    reset = (): void => {
        this.startDate = getDayStart().toDate()
        this.endDate = getDayEnd().toDate()
        this.totalSalesOnCashDesks = createTotalSalesOnCashDesks({})
        this.selectedCashDeskTrends = []
        this.intervalType = DATE
        this.averageSalesOnCashDesk = undefined
        this.selectedCashDeskNumber = undefined
        this.sortingField = 'sum'
        this.sortingDirection = 'desc'
        this.searched = false
    }
}

export const CASH_DESK_SALES_REPORT_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${REPORTS}${CASHES_OPTIMIZATION}${CASHDESK_SALES_REPORT}/[\\w-]+/?$`),
    onEnter: () => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        appBarStore.updateState({
            title: t('reports.cashDeskSaleReport'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                goTo(`${REPORTS}${CASHES_OPTIMIZATION}${SALES_REPORT}`)
            }
        })
    },
    onLeave: () => {
        const cashSalesReportStore: CashSalesReportStore = getStore(CASH_SALES_REPORT_STORE)
        cashSalesReportStore.resetCashData()
    }
}
