import { createBaseVO } from './base-vo'
import { BaseVO } from './base-vo'
import { ClientType } from './client-type'
import { Sex } from './sex'
import { ClientAddress } from './client-address'
import { PassportVO } from './passport-vo'
import { SendBy } from './send-by'
import { SmartphoneType } from './smartphone-type'

export interface ClientVO extends BaseVO {
    '@class': 'ru.crystals.cards.common.ClientVO'
    guid?: number
    deleted?: boolean
    firstName?: string
    middleName?: string
    lastName?: string
    clientType?: ClientType
    birthDate?: string
    sex?: Sex
    marital?: boolean
    auto?: boolean
    lastChangeDate?: Date
    clientAddress?: ClientAddress
    passport?: PassportVO
    phone?: string
    email?: string
    sendBy?: SendBy
    mobilePhone?: string
    mobileOperator?: string
    shopNumber?: string
    sendCatalog?: boolean
    childrenAge?: string
    isCompleted?: boolean
    creationDate?: Date
    bonusBalance?: number
    receiptFeedbackType?: string
    smartphoneType?: SmartphoneType
    wantsECard?: boolean
}

export const createClientVO = (initialization: Partial<ClientVO>): ClientVO => {
    if (!initialization) return null
    
    let result: ClientVO = {
        ...createBaseVO(initialization),
        guid: initialization.guid,
        deleted: initialization.deleted,
        firstName: initialization.firstName,
        middleName: initialization.middleName,
        lastName: initialization.lastName,
        clientType: initialization.clientType,
        birthDate: initialization.birthDate,
        sex: initialization.sex,
        marital: initialization.marital,
        auto: initialization.auto,
        lastChangeDate: initialization.lastChangeDate,
        clientAddress: initialization.clientAddress,
        passport: initialization.passport,
        phone: initialization.phone,
        email: initialization.email,
        sendBy: initialization.sendBy,
        mobilePhone: initialization.mobilePhone,
        mobileOperator: initialization.mobileOperator,
        shopNumber: initialization.shopNumber,
        sendCatalog: initialization.sendCatalog,
        childrenAge: initialization.childrenAge,
        isCompleted: initialization.isCompleted,
        creationDate: initialization.creationDate,
        bonusBalance: initialization.bonusBalance,
        receiptFeedbackType: initialization.receiptFeedbackType,
        smartphoneType: initialization.smartphoneType,
        wantsECard: initialization.wantsECard,
        '@class': 'ru.crystals.cards.common.ClientVO',
    }
    
    return result
}
