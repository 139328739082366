import { createBaseVO } from './base-vo'
import { BaseVO } from './base-vo'
import { DisplayColor } from './display-color'

export interface CardTypeVO extends BaseVO {
    '@class': 'ru.crystals.cards.common.CardTypeVO' | 'ru.crystals.cards.common.InternalCardsVO' | 'ru.crystals.cards.presentcards.PresentCardsVO' | 'ru.crystals.cards.coupons.vo.CouponsVO' | 'ru.crystals.cards.coupons.vo.UniqueCouponVO' | 'ru.crystals.cards.externalcards.ExternalCardsVO'
    guid?: number
    deleted?: boolean
    name?: string
    color?: DisplayColor
    classType?: string
    personalized?: boolean
}

export const createCardTypeVO = (initialization: Partial<CardTypeVO>): CardTypeVO => {
    if (!initialization) return null
    
    let result: CardTypeVO = {
        ...createBaseVO(initialization),
        guid: initialization.guid,
        deleted: initialization.deleted,
        name: initialization.name,
        color: initialization.color,
        classType: initialization.classType,
        personalized: initialization.personalized,
        '@class': 'ru.crystals.cards.common.CardTypeVO',
    }
    
    return result
}
