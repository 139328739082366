export interface ComplexReceiptSearchArgument {
    '@class': 'ru.crystals.cards.coupons.vo.ComplexReceiptSearchArgument'
    shifts?: number[]
    shops?: number[]
    purchases?: number[]
    cashes?: number[]
}

export const createComplexReceiptSearchArgument = (initialization: Partial<ComplexReceiptSearchArgument>): ComplexReceiptSearchArgument => {
    if (!initialization) return null
    
    let result: ComplexReceiptSearchArgument = {
        shifts: initialization.shifts,
        shops: initialization.shops,
        purchases: initialization.purchases,
        cashes: initialization.cashes,
        '@class': 'ru.crystals.cards.coupons.vo.ComplexReceiptSearchArgument',
    }
    
    return result
}
