import { cloneDeep, isEmpty, isNil } from 'lodash'
import { action, observable, runInAction, toJS } from 'mobx'
import uuid from 'uuid'
import moment from 'moment'
import { documentEditorLocal } from '../../../protocol/set10/document-editor-local'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { GoodVO } from '../../../protocol/set10/set-retail10-commons/set-report-api/good-vo'
import { GoodsBillVO } from '../../../protocol/set10/set-retail10-commons/set-report-api/goods-bill-vo'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { FiltersState } from '../../components/filters/new-filters'
import { config } from '../../config/config'
import { OPERDAY, UPD_REGISTRY } from '../../core/app-routes'
import { goTo } from '../../utils/router-util'
import { withSpinner } from '../with-spinner'
import { fromClientToServerTime } from '../../utils/app-util'
import { BaseDateRangeFilter } from '../../components/filters/components/base-date-range-filter'
import { CHECK_TIME_FILTER } from '../../core/filters/filter'

const getDefaultFilterState = (): FiltersState => {
    const startDate: string = moment(fromClientToServerTime(new Date())).startOf('day').format(BaseDateRangeFilter.dateFormat)
    const endDate: string = moment(fromClientToServerTime(new Date())).endOf('day').format(BaseDateRangeFilter.dateFormat)

    return {
        shownFilters: {
            [CHECK_TIME_FILTER]: {
                value: `${startDate}${BaseDateRangeFilter.dateDelimiter}${endDate}`,
            }
        },
    }
}

export interface UPDHeader {
    buyerFullName: string
    buyerJurAddress: string
    buyerOfAddress: string
    buyerKpp: string
}

export interface UPDPosition {
    id: number
    declaration: string
    country: string
    countryCode: string
}

export class UPDRegistryStore {
    @observable
    goodsBills: GoodsBillVO[] = []

    @observable
    selectedGoodsBillsIds: number[] = []

    @observable
    currentGoodsBill: GoodsBillVO

    @observable
    currentHeader: UPDHeader

    @observable
    headerValidation: FormValidation<UPDHeader>

    @observable
    currentPosition: UPDPosition

    @observable
    positionValidation: FormValidation<UPDPosition>

    @observable
    selectorKey: string = null

    defaultFiltersState: FiltersState = getDefaultFilterState()

    @action

    fetchFilteredGoodsBills = (searchArguments: SearchArgumentVO[]): Promise<void> => {
        return withSpinner(async () => {
            const ids = await documentEditorLocal.getDocsIds(searchArguments)
            const goodsBills = isEmpty(ids) ? [] : await documentEditorLocal.getGoodsBills(ids)

            runInAction(() => {
                this.goodsBills = goodsBills
                // TODO SFM-208 ошибки транспорта
                if (this.goodsBills) {
                    this.goodsBills.forEach(doc => {
                        if (!(doc.dateOperation instanceof Date)) {
                            doc.dateOperation = new Date(doc.dateOperation)
                        }
                        doc.dateOperation = fromClientToServerTime(doc.dateOperation)
                    })
                }
                this.selectorKey = uuid()
                this.selectedGoodsBillsIds = []
            })
        })
    }

    @action
    fetchGoodsBillByRef = async (ref: string): Promise<void> => {
        const id = Number(ref)

        if (isNil(ref) || isNaN(id)) {
            goTo(`${OPERDAY}${UPD_REGISTRY}`)
            return
        }

        const goodsBills: GoodsBillVO[] = await withSpinner(documentEditorLocal.getGoodsBills([id]))
        if (isNil(goodsBills[0])) {
            goTo(`${OPERDAY}${UPD_REGISTRY}`)
            return Promise.resolve()
        }
        this.currentGoodsBill = goodsBills[0]
    }

    @action
    selectGoodsBillsIds = (ids: number[]): void => {
        this.selectedGoodsBillsIds = ids
    }

    @action
    printSelectedGoodsBills = (): void => {
        if (!this.selectedGoodsBillsIds
            || !this.selectedGoodsBillsIds.length
            || this.selectedGoodsBillsIds.length === 0
        ) return

        window.open(`${config.reportsAddress}?Action=UPD&REGISTRY_ID=${this.selectedGoodsBillsIds.join(',')}&FILE_TYPE=PDF`)
    }

    @action
    editGoodsBill = (goodsBill: GoodsBillVO): void => {
        this.currentGoodsBill = goodsBill
        goTo(`${OPERDAY}${UPD_REGISTRY}/${goodsBill.id}`)
    }

    @action
    createHeaderValidation = (creation: boolean = false): void => {
        this.headerValidation = new FormValidation<UPDHeader>(
            this.currentHeader,
            [
                {
                    field: 'buyerFullName',
                    rules: [
                        fieldLengthValidator({max: 255})
                    ]
                },
                {
                    field: 'buyerJurAddress',
                    rules: [
                        fieldLengthValidator({max: 255})
                    ]
                },
                {
                    field: 'buyerOfAddress',
                    rules: [
                        fieldLengthValidator({max: 255})
                    ]
                },
                {
                    field: 'buyerKpp',
                    rules: [
                        fieldLengthValidator({max: 9})
                    ]
                }
            ],
            creation
        )
    }

    @action
    editHeader = (): void => {
        if (isNil(this.currentGoodsBill)) return

        const {buyerFullName, buyerJurAddress, buyerOfAddress, buyerKpp} = this.currentGoodsBill

        this.currentHeader = {
            buyerFullName: buyerFullName || '',
            buyerJurAddress: buyerJurAddress || '',
            buyerOfAddress: buyerOfAddress || '',
            buyerKpp: buyerKpp || ''
        }

        this.createHeaderValidation(false)
    }

    @action
    createPositionValidation = (creation: boolean = false): void => {
        this.positionValidation = new FormValidation<UPDPosition>(
            this.currentPosition,
            [
                {
                    field: 'declaration',
                    rules: [
                        fieldLengthValidator({max: 255})
                    ]
                },
                {
                    field: 'country',
                    rules: [
                        fieldLengthValidator({max: 255})
                    ]
                },
                {
                    field: 'countryCode',
                    rules: [
                        fieldLengthValidator({max: 255})
                    ]
                },
            ],
            creation
        )
    }

    @action
    editPosition = (positionId: number): void => {
        if (isNil(this.currentGoodsBill)) return

        const {positions} = this.currentGoodsBill

        if (isNil(positions)) return

        const position: GoodVO = positions.find(position => position.id === positionId)

        if (isNil(position)) return

        const {id, declaration, country, countryCode} = position

        this.currentPosition = {
            id,
            declaration: declaration || '',
            country: country || '',
            countryCode: countryCode || ''
        }

        this.createPositionValidation(false)
    }

    @action
    updateHeader = (changes: Partial<UPDHeader>): void => {
        if (isNil(this.currentHeader)) return

        Object.keys(changes).forEach(key => {
            this.currentHeader[key] = changes[key]
        })
    }

    @action
    updatePosition = (changes: Partial<UPDPosition>): void => {
        if (isNil(this.currentPosition)) return

        Object.keys(changes).forEach(key => {
            this.currentPosition[key] = changes[key]
        })
    }

    @action
    saveGoodsBill = (): Promise<void> => {
        if (isNil(this.currentGoodsBill)) return Promise.resolve()

        // Сохранение заголовка
        if (!isNil(this.currentHeader)) {
            Object.keys(this.currentHeader).forEach(key => {
                this.currentGoodsBill[key] = this.currentHeader[key]
            })

            this.currentHeader = null
        }

        // Сохранение товарной позиции
        if (!isNil(this.currentPosition) && !isNil(this.currentGoodsBill.positions)) {
            const positionIndex: number = this.currentGoodsBill.positions.findIndex(position => position.id === this.currentPosition.id)

            if (positionIndex !== -1) {
                Object.keys(this.currentPosition).forEach(key => {
                    this.currentGoodsBill.positions[positionIndex][key] = this.currentPosition[key]
                })
            }
            this.currentPosition = null
        }

        return withSpinner(
            documentEditorLocal.updateGoodsBill(toJS(this.currentGoodsBill))
                .then(() => {
                    // Если попали на страницу редактирования не по прямой ссылке
                    if (!isNil(this.goodsBills)) {
                        const goodsBillIndex = this.goodsBills.findIndex(goodsBill => goodsBill.id === this.currentGoodsBill.id)

                        if (goodsBillIndex !== -1) {
                            runInAction(() => {
                                this.goodsBills[goodsBillIndex] = cloneDeep(toJS(this.currentGoodsBill))
                            })
                        }
                    }
                })
        )
    }

    @action
    cancelGoodsBillChanges = (): void => {
        this.currentHeader = null
        this.headerValidation = null
        this.currentPosition = null
        this.positionValidation = null
    }

    @action
    resetFiltersAndResults = (): void => {
        this.goodsBills = []
        this.selectedGoodsBillsIds = []
        this.defaultFiltersState = getDefaultFilterState()
        this.currentGoodsBill = undefined
        this.currentHeader = undefined
        this.currentPosition = undefined
        this.selectorKey = null
    }

    @action
    reset = (): void => {
        this.resetFiltersAndResults()
        this.headerValidation = undefined
        this.positionValidation = undefined
    }
}
