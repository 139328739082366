import React from 'react'
import { observer, inject } from 'mobx-react'
import { t } from 'i18next'
import Paper from '@material-ui/core/Paper'
import { PRICE_TAGS_ESL_COVERAGE_STORE, USER_STORE } from '../../../store/stores'
import { PriceTagsEslCoverageStore } from '../../../store/price-tags/price-tags-esl-coverage-store'
import {
    ProductEntitiesBinder,
    PRODUCTS_GROUP,
    PRODUCT,
    PRODUCT_TYPE,
    MEASURE_UNIT
} from '../../../components/product-entities-binder/product-entities-binder'
import { UserStore } from '../../../store/user-store'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { ALL_PRODUCTS } from '../../../../protocol/set10/set-retail10-commons/set-esl-api/esl-coverage-condition-type'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { createESLCoverageConditionVO } from '../../../../protocol/set10/set-retail10-commons/set-esl-api/esl-coverage-condition-vo'
import { withSpinner } from '../../../store/with-spinner'

const styles = require('./price-tags-esl-coverage.scss')

interface PriceTagsEslCoverageProps {
    priceTagsEslCoverageStore?: PriceTagsEslCoverageStore
    user?: UserStore
}

@inject(USER_STORE)
@inject(PRICE_TAGS_ESL_COVERAGE_STORE)
@observer
export class PriceTagsEslCoverage extends React.Component<PriceTagsEslCoverageProps> {

    componentDidMount(): void {
        const { fetchCoverageConditions, fetchProductGroups, fetchMeasureUnits,
                fetchProductTypes } = this.props.priceTagsEslCoverageStore
        withSpinner(Promise.all([
            fetchCoverageConditions(),
            fetchProductGroups(),
            fetchMeasureUnits(),
            fetchProductTypes()
        ]))
    }

    render() {
        const { session } = this.props.user
        const { coverageConditions, boundEntities, addCondition, deleteCondition,
                setConditionsFromEntities } = this.props.priceTagsEslCoverageStore

        if (!coverageConditions) {
            return (
                <Paper className={styles.priceTagsEslCoverage}>
                    <Empty />
                </Paper>
            )
        }

        const serveAllProductsChecked: boolean = coverageConditions.some(c => c.type === ALL_PRODUCTS)

        return (
            <Paper id="priceTagsEslCoverage" className={styles.priceTagsEslCoverage}>
                <div className={styles.settingsRow}>
                    <Checkbox
                        id="serveAllProductsCheckBox"
                        label={t('priceTagsEsl.servingAllProducts')}
                        checked={serveAllProductsChecked}
                        onValueChange={checked => {
                            if (checked) {
                                addCondition(createESLCoverageConditionVO({
                                    type: ALL_PRODUCTS,
                                    value: ALL_PRODUCTS
                                }))
                            } else {
                                deleteCondition(ALL_PRODUCTS, ALL_PRODUCTS)
                            }
                        }}
                    />
                </div>

                { !serveAllProductsChecked && (
                    <ProductEntitiesBinder
                        session={session}
                        productEntitiesTypes={[PRODUCTS_GROUP, PRODUCT, PRODUCT_TYPE, MEASURE_UNIT]}
                        boundEntities={boundEntities}
                        onEntitiesChanged={setConditionsFromEntities}
                    />
                ) }
            </Paper>
        )
    }
}
