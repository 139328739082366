import * as React from 'react'
import {t} from 'i18next'
import {StubWithImg, StubWithImgProps} from './stub-with-img'

const NO_ITEMS_STUB = require('../../../assets/stubs/no-items-stub2.svg')

export interface NotSearchedProps {
    titleText?: string
    hintText?: string
    width?: number
    className?: string
}

export class NotSearched extends React.Component<NotSearchedProps> {

    render() {
        const {titleText = t('set10.notSearchedTitle'), hintText = t('set10.notSearchedText'), width, className} = this.props
        return (
            <StubWithImg
                titleText={titleText}
                hintText={hintText}
                width={width}
                className={className}
                image={(
                    <img src={NO_ITEMS_STUB}/>
                )}
            />
        )
    }
}
