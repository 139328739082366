import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class ESLModule extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-ESL/SET_ESL!ru.crystals.setretailx.esl.ESLModule'
    }

    invalidateItems = (items: string[], options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'invalidateItems',
                params: [items]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const eSLModule: ESLModule = new ESLModule()
