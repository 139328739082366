import { ScalesTemplateVO } from './scales-template-vo'
import { ScalesState } from './scales-state'

export interface ScalesVO {
    '@class': 'ru.crystals.scales.vo.ScalesVO'
    id?: number
    code?: string
    guid?: number
    deleted?: boolean
    number?: number
    scalesTemplate?: ScalesTemplateVO
    addressing?: string
    model?: string
    productGuids?: number[]
    state?: ScalesState
    noLoadAddText?: boolean
    clearScales?: boolean
    printTimeOnLabel?: boolean
}

export const createScalesVO = (initialization: Partial<ScalesVO>): ScalesVO => {
    if (!initialization) return null
    
    let result: ScalesVO = {
        id: initialization.id,
        code: initialization.code,
        guid: initialization.guid,
        deleted: initialization.deleted,
        number: initialization.number,
        scalesTemplate: initialization.scalesTemplate,
        addressing: initialization.addressing,
        model: initialization.model,
        productGuids: initialization.productGuids,
        state: initialization.state,
        noLoadAddText: initialization.noLoadAddText,
        clearScales: initialization.clearScales,
        printTimeOnLabel: initialization.printTimeOnLabel,
        '@class': 'ru.crystals.scales.vo.ScalesVO',
    }
    
    return result
}
