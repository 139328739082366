/// <reference path="../typings/global.d.ts"/>
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Router, Route, Switch, withRouter } from 'react-router'
import { Provider } from 'mobx-react'
import { createStore, history } from './store/store'
import { AppContainer } from './components/app-container/app-container'
import './index.scss'
import { Login } from './pages/login/login'
import { PagesContainer } from './components/pages-container/pages-container'
import {
    commonErrorMiddlewares,
    commonRequestMiddlewares,
    commonResponseMiddlewares
} from '../protocol/set10/base-service'
import { appLaunchChain, appRenderer } from './utils/app-util'
import { getAppModules } from './routes'
import { LOGIN } from './core/app-routes'
import ScrollToTop from './utils/scroll-to-top'
import { UserStore } from './store/user-store'
import { getStore } from './store/stores-repository'
import { APP_STORE, IFRAME_MODULES_STORE, NAVIGATION_MENU_STORE, USER_STORE } from './store/stores'
import { NavigationMenuStore } from './store/navigation-menu-store'
import { AppStore } from './store/app-store'
import { RouteScheme, getAvailableRoutesScheme, renderRouteByScheme } from './utils/route-modules-util'
import {
    withCredentialsRequestMiddleware,
    authorizationErrorMiddleware,
    internalServerErrorMiddleware,
    generalErrorMiddleware
} from './utils/service-middlewares'
import { DragDropContextProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { config } from './config/config'
import { ModuleItem } from './core/iframe-modules/entities'

// TODO: Абстрагировать слайдер и спрятать в него импорт стилей
import 'rc-slider/assets/index.css'
// убирает ошибку "Cannot read property 'createLTR' of undefined"
import 'react-dates/initialize'
import { IframeContainer } from './components/iframe-container/iframe-container'
import { IframeModulesStore } from './store/iframe-modules-store'
import { localStorageManager } from './utils/local-storage-util'

commonRequestMiddlewares.push(withCredentialsRequestMiddleware)

commonResponseMiddlewares.push(authorizationErrorMiddleware)
commonResponseMiddlewares.push(internalServerErrorMiddleware)

commonErrorMiddlewares.push(generalErrorMiddleware)

// TODO сейчас в react router 4 проблемы с вызовом перерисовки при смене location,
// cвязано с блокированием отрисовки в реакте для оптимизации
const App = withRouter(AppContainer as any)
const Page = withRouter(PagesContainer as any)

export const renderAppRoutes = () => {
    const store = createStore()
    const appStore: AppStore = getStore(APP_STORE)
    const userStore: UserStore = getStore(USER_STORE)
    const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
    const iframeModulesStore: IframeModulesStore = getStore(IFRAME_MODULES_STORE)

    /*
    * Для дебага. Это позволяет запускать методы сторов из консоли хрома,
    * например, так: stores.user.havePrivilege('SETRETAILX_CashTemplate_ADMIN')
    *  */
    if (!config.isProduction) {
        // tslint:disable-next-line:no-string-literal
        global['stores'] = store
    }

    const availableAppRoutes: RouteScheme = getAvailableRoutesScheme(
        getAppModules(),
        appStore.isCentrum,
        userStore.privilegeManager
    )

    availableAppRoutes.children.push(...iframeModulesStore.externalModulesNavigation.map((item: ModuleItem) => {
        return {
            ...(item.name ? {
                navItemProps: {
                    id: item.parentPathPattern,
                    label: item.name,
                    icon: item.iconUrl ? <img src={item.iconUrl} alt={item.name} /> : <span>ICON</span>
                }
            } : {}),
            path: item.parentPathPattern,
            children: item.children.map((childItem: ModuleItem) => {
                return {
                    ...(childItem.name ? {
                        navItemProps: {
                            id: childItem.parentPathPattern,
                            label: childItem.name,
                        },
                    } : {}),
                    path: childItem.parentPathPattern,
                    component: IframeContainer
                }
            })
        }
    }))

    navigationMenuStore.init(availableAppRoutes)
    navigationMenuStore.synchronizeWithRoute()

    const theme = localStorageManager.getTheme()
    appStore.setTheme(theme)

    ReactDOM.render(
        <Provider {...store} >
            <Router history={history}>
                <DragDropContextProvider backend={HTML5Backend}>
                    <App>
                        <ScrollToTop />
                        <Switch>
                            <Route path={LOGIN} component={Login}/>
                            { userStore.authorized &&
                                <Route>
                                    <Page>
                                        { renderRouteByScheme(availableAppRoutes) }
                                    </Page>
                                </Route>
                            }
                        </Switch>
                    </App>
                </DragDropContextProvider>
            </Router>
        </Provider>,
        document.getElementById('content')
    )
}

appRenderer.render = renderAppRoutes

appLaunchChain()
