import * as React from 'react'
import { XMLTagCustomElement } from '../../../core/price-tags/xml/xml-tag-custom-element'
import { XMLTagImage } from '../../../core/price-tags/xml/xml-tag-image'
import { XMLTagBarcodeElement } from '../../../core/price-tags/xml/xml-tag-barcode-element'
import { XMLTagQrcodeElement } from '../../../core/price-tags/xml/xml-tag-qrcode-element'

const styles = require('./price-tag-image-preview.scss')

export interface PriceTagImagePreviewProps extends React.HTMLProps<HTMLImageElement> {
    element: XMLTagImage | XMLTagCustomElement | XMLTagBarcodeElement | XMLTagQrcodeElement
    scale: number
}

export class PriceTagImagePreview extends React.Component<PriceTagImagePreviewProps> {

    render() {
        const { element, scale, crossOrigin, alt, src, ...other } = this.props

        return (
            <img
                {...other}
                alt={alt}
                src={src}
                className={styles.imagePreview}
            />
        )
    }
}
