import { createCardTypeVO } from '../../../set-retail10-commons/data-structs-module/card-type-vo'
import { createBaseVO } from '../../../set-retail10-commons/data-structs-module/base-vo'
import { CardTypeVO } from '../../../set-retail10-commons/data-structs-module/card-type-vo'
import { TimestampPeriod } from '../../../set-retail10-commons/data-structs-module/timestamp-period'
import { TimeUnit } from '../../../set-retail10-commons/data-structs-module/time-unit'

export interface PresentCardsVO extends CardTypeVO {
    '@class': 'ru.crystals.cards.presentcards.PresentCardsVO'
    fixedAmount?: boolean
    amount?: number
    maxAmount?: number
    multiplicity?: number
    barcode?: string
    withoutFinishDate?: boolean
    workPeriodRange?: TimestampPeriod
    validity?: number
    validityTimeUnit?: TimeUnit
    partialPaymentEnabled?: boolean
}

export const createPresentCardsVO = (initialization: Partial<PresentCardsVO>): PresentCardsVO => {
    if (!initialization) return null
    
    let result: PresentCardsVO = {
        ...createCardTypeVO(initialization),        ...createBaseVO(initialization),
        fixedAmount: initialization.fixedAmount,
        amount: initialization.amount,
        maxAmount: initialization.maxAmount,
        multiplicity: initialization.multiplicity,
        barcode: initialization.barcode,
        withoutFinishDate: initialization.withoutFinishDate,
        workPeriodRange: initialization.workPeriodRange,
        validity: initialization.validity,
        validityTimeUnit: initialization.validityTimeUnit,
        partialPaymentEnabled: initialization.partialPaymentEnabled,
        '@class': 'ru.crystals.cards.presentcards.PresentCardsVO',
    }
    
    return result
}
