import { SnackbarOrigin } from '@material-ui/core/Snackbar'
import { action, observable } from 'mobx'
import { SNACKBAR_DURATION } from '../../utils/default-timeouts'

export type SNACK_BAR_VARIANT = 'success' | 'error' | 'info' | 'warning'

export const SNACK_BAR_SUCCESS: SNACK_BAR_VARIANT = 'success'
export const SNACK_BAR_ERROR: SNACK_BAR_VARIANT = 'error'
export const SNACK_BAR_INFO: SNACK_BAR_VARIANT = 'info'
export const SNACK_BAR_WARNING: SNACK_BAR_VARIANT = 'warning'

export interface SnackData {
    id?: string
    open?: boolean
    message?: string | JSX.Element
    actions?: JSX.Element[]
    duration?: number
    showCloseIcon?: boolean
    origin?: SnackbarOrigin
    variant?: SNACK_BAR_VARIANT,
    beforeIcon?: JSX.Element,
}

export class SnackbarStore {
    emptySnack: SnackData = {
        id: 'snackbarMessage',
        open: false,
        message: '',
        actions: [],
        duration: SNACKBAR_DURATION,
        showCloseIcon: false,
        origin: {
            vertical: 'bottom',
            horizontal: 'center',
        },
    }

    @observable
    snack: SnackData = this.emptySnack

    @action
    show = (item: SnackData): void => {
        this.snack = {
            ...this.emptySnack,
            open: true,
            ...item
        }
    }

    @action
    close = () => {
        this.snack = this.emptySnack
    }

    @action
    reset = (): void => {
        this.snack = this.emptySnack
    }
}
