import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { IconButtonProps } from '@material-ui/core/IconButton/IconButton'

export class ExpandButton extends React.Component<ExpandButtonProps, any> {

    static defaultProps: Partial<ExpandButtonProps> = {
        id: '',
        expanded: false,
        onExpand: () => null,
        onCollapse: () => null,
        onStateChange: () => null,
    }

    clickHandler = (): void => {
        const {expanded, onExpand, onCollapse, onStateChange} = this.props
        if (expanded) {
            onCollapse()
        } else {
            onExpand()
        }
        onStateChange(!expanded)
    }
    
    render() {
        const {expanded, onExpand, onCollapse, onStateChange, ...other} = this.props

        return (
            <IconButton
                {...other}
                onClick={this.clickHandler}
            >
                {expanded ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
        )
    }
}

export interface ExpandButtonProps extends IconButtonProps {
    expanded?: boolean
    onExpand?(): void
    onCollapse?(): void
    onStateChange?(expanded: boolean): void
}
