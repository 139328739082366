export interface TimestampPeriod {
    '@class': 'ru.crystals.setretailx.common.TimestampPeriod'
    start?: Date
    finish?: Date
}

export const createTimestampPeriod = (initialization: Partial<TimestampPeriod>): TimestampPeriod => {
    if (!initialization) return null
    
    let result: TimestampPeriod = {
        start: initialization.start,
        finish: initialization.finish,
        '@class': 'ru.crystals.setretailx.common.TimestampPeriod',
    }
    
    return result
}
