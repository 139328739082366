import * as React from 'react'
import classNames from 'classnames'
import {inject, observer} from 'mobx-react'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import {NavigationMenuStore} from '../../store/navigation-menu-store'
import {RouterStore} from 'mobx-react-router'
import {NavBar, Item} from '../../../components/nav-bar/nav-bar'
import {AppStore} from '../../store/app-store'
import {DrawerProps} from '@material-ui/core/Drawer/Drawer'
import {lg} from '../../../utils/css/breakpoints'
import {APP_STORE, NAVIGATION_MENU_STORE, ROUTING_STORE, APP_BAR_STORE} from '../../store/stores'
import {config} from '../../config/config'
import {t} from 'i18next'
import { AppBarStore, MENU } from '../../store/app-bar-store'
import { goTo } from '../../utils/router-util'
import { LICENSES, SHOP } from '../../core/app-routes'

const styles = require('./navigation-menu.scss')

const RETAIL_LOGO: string = require('../../../assets/images/icons/logo/set-retail-blue-logo.png')
const CENTRUM_LOGO: string = require('../../../assets/images/icons/logo/set-centrum-blue-logo.png')

export interface NavigationMenuProps extends DrawerProps {
    app?: AppStore
    routing?: RouterStore
    navigationMenu?: NavigationMenuStore
    appBar?: AppBarStore
}

export interface NavigationMenuState {
    licenseAgreementPopupOpen: boolean
}

@inject(APP_STORE)
@inject(ROUTING_STORE)
@inject(NAVIGATION_MENU_STORE)
@inject(APP_BAR_STORE)
@observer
export class NavigationMenu extends React.Component<NavigationMenuProps, NavigationMenuState> {

    state: NavigationMenuState = {
        licenseAgreementPopupOpen: false
    }

    selectItemHandler = (item: Item) => {
        const {navigationMenu} = this.props
        const {screenWidth} = this.props.app

        navigationMenu.selectMenuItem(item)

        if (screenWidth < lg) {
            navigationMenu.open = false
        }
    }

    expandItemHandler = (item: Item) => {
        const {expandedItems, setExpandedItems} = this.props.navigationMenu

        const expandedItem = expandedItems.find(expandedItem => expandedItem.id === item.id)
        const newExpandedItems = expandedItems.concat()
        if (expandedItem) {
            newExpandedItems.splice(newExpandedItems.indexOf(expandedItem), 1)
        } else {
            newExpandedItems.push(item)
        }
        setExpandedItems(newExpandedItems)
    }

    renderLicenseServerInfoLink(): React.ReactNode {
        const licenseInfo = this.props.app.licenseInfo

        let text
        if (!licenseInfo.licenseServerConnection) {
            text = t('set10.noConnectionWithLicenseServer')
        }
        else {
            text = t('set10.setIdNotSpecified')
        }

        if (!licenseInfo.licenseServerConnection || !licenseInfo.setId) {
            return (
                <div>
                    <a
                        id="licenseServerInfoLink"
                        className={styles.licenseLink}
                        onClick={() => goTo(`${SHOP}${LICENSES}`)}
                    >
                        {text}
                    </a>
                </div>
            )
        }

        return null
    }

    render() {
        const { navBarItemGroup, selectedItem, expandedItems, open, setOpen } = this.props.navigationMenu
        const { isCentrum, screenWidth, serverInfo } = this.props.app
        const { leftIcon } = this.props.appBar

        const showMenu = open && leftIcon === MENU

        return (
            <Drawer
                id="navigationMenu"
                className={classNames({
                    [styles.hidden]: !showMenu && screenWidth >= lg
                })}
                PaperProps={{
                    className: styles.navigationMenu
                }}
                variant={screenWidth < lg ? 'temporary' : 'permanent'}
                open={showMenu}
                onClose={() => setOpen(!open)}
            >
                <div className={styles.logoContainer}>
                    <img
                        id="logo"
                        className={styles.logo}
                        src={isCentrum ? CENTRUM_LOGO : RETAIL_LOGO}
                    />
                </div>
                <Divider/>
                <NavBar
                    id="navigationMenuNavBar"
                    selectedItem={selectedItem}
                    expandedItems={expandedItems}
                    className={styles.navBar}
                    item={navBarItemGroup}
                    onItemSelect={this.selectItemHandler}
                    onItemExpand={this.expandItemHandler}
                />
                <div className={styles.links}>
                    {this.renderLicenseServerInfoLink()}
                    <div>
                        <a
                            id="officialPageLink"
                            href="https://www.crystals.ru"
                            target="_blank">
                            {t('set10.officialSite')}
                        </a>
                    </div>
                    <div>
                        <a
                            id="supportLink"
                            href="https://crystals.atlassian.net/wiki/spaces/SR10SUPPORT/overview"
                            target="_blank">
                            {t('set10.support')}
                        </a>
                    </div>
                    <span
                        id="versionInfo"
                        className={styles.versionInfo}
                    >
                        {`${t('set10.version')} ${serverInfo.serverVersion}`}
                    </span>
                </div>
            </Drawer>
        )
    }
}
