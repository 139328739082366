import { observable, action, runInAction, toJS } from 'mobx'
import { Cancelable, debounce } from 'lodash'
import { t } from 'i18next'

import { LoyaltyPropertiesVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/loyalty-properties-vo'
import { iDiscountsManagerLocal } from '../../../protocol/set10/i-discounts-manager-local'

import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { AppStore } from '../app-store'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE } from '../stores'
import { discountsCardsPropertiesManagerLocal } from '../../../protocol/set10/discounts-cards-properties-manager-local'
import { DiscountsCardsPropertiesVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/discounts-cards-properties-vo'

export class LoyaltySettingsStore {
    @observable
    settings: LoyaltyPropertiesVO

    @observable
    discountSettings: DiscountsCardsPropertiesVO

    @observable
    days: number

    @observable
    minutes: number

    debouncedSaveMinutes: (() => void) & Cancelable = debounce(() => {
        this.saveMinutes()
    }, AUTOSAVE_DELAY)

    debouncedSaveDays: (() => void) & Cancelable = debounce(() => {
        this.saveDays()
    }, AUTOSAVE_DELAY)

    debouncedSaveSettings: (() => void) & Cancelable = debounce(() => {
        this.saveSettings()
    }, AUTOSAVE_DELAY)

    debouncedSaveDiscountSettings: (() => void) & Cancelable = debounce(() => {
        this.saveDiscountSettings()
    }, AUTOSAVE_DELAY)

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    saveSettings = () => {
        return iDiscountsManagerLocal.setLoyaltyProperties(this.userStore.session, toJS(this.settings))
            .then(() => {
                this.appStore.showSnackbar({message: t('loyaltySettings.saved')})
            })
    }

    saveDiscountSettings = () => {
        return discountsCardsPropertiesManagerLocal.setCardsPropertiesVO(toJS(this.discountSettings))
            .then(() => {
                this.appStore.showSnackbar({message: t('loyaltySettings.saved')})
            })
    }

    saveDays = () => {
        return iDiscountsManagerLocal.setRunOutActionsKeepingTimeInDays(this.userStore.session, this.days)
            .then(() => {
                this.appStore.showSnackbar({message: t('loyaltySettings.saved')})
            })
    }

    saveMinutes = () => {
        return iDiscountsManagerLocal.setActionsLoadingTimeInMinutes(this.userStore.session, this.minutes)
            .then(() => {
                this.appStore.showSnackbar({message: t('loyaltySettings.saved')})
            })
    }

    fetchSettings = async () => {
        const settings = await iDiscountsManagerLocal.getLoyaltyProperties(this.userStore.session)
        runInAction(() => {
            this.settings = settings
        })
    }

    fetchDiscountSettings = async () => {
        const discountSettings = await discountsCardsPropertiesManagerLocal.getCardsPropertiesVO()
        runInAction(() => {
            this.discountSettings = discountSettings
        })
    }

    fetchDays = async () => {
        const days = await iDiscountsManagerLocal.getRunOutActionsKeepingTimeInDays(this.userStore.session)
        runInAction(() => {
            this.days = days
        })
    }

    fetchMinutes = async () => {
        const minutes = await iDiscountsManagerLocal.getActionsLoadingTimeInMinutes2(this.userStore.session)
        runInAction(() => {
            this.minutes = minutes
        })
    }

    fetchLoyaltySettings = async () => {
        await Promise.all([
            this.fetchSettings(),
            this.fetchDays(),
            this.fetchMinutes(),
            this.fetchDiscountSettings()
        ])
    }

    @action
    editSettings = (changes: Partial<LoyaltyPropertiesVO>) => {
        Object.keys(changes).forEach(k => {
            this.settings[k] = changes[k]
        })

        this.debouncedSaveSettings()
    }

    @action
    editDiscountSettings = (changes: Partial<DiscountsCardsPropertiesVO>) => {
        Object.keys(changes).forEach(k => {
            this.discountSettings[k] = changes[k]
        })

        this.debouncedSaveDiscountSettings()
    }

    @action
    editDays = (value: number) => {
        this.days = value

        this.debouncedSaveDays()
    }

    @action
    editMinutes = (value: number) => {
        this.minutes = value

        this.debouncedSaveMinutes()
    }

    reset = (): void => {
        this.settings = null
        this.discountSettings = null
        this.days = null
        this.minutes = null
    }
}
