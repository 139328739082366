import {
    action,
    computed,
    extendObservable,
    observable,
    runInAction,
    toJS,
} from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { t } from 'i18next'
import capitalize from 'lodash/capitalize'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import pickBy from 'lodash/pickBy'
import size from 'lodash/size'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { AppStore } from '../app-store'
import { UserStore } from '../user-store'
import { SNACK_BAR_INFO } from '../snackbar-store'
import { getStore } from '../stores-repository'
import {
    APP_BAR_STORE,
    APP_STORE,
    INTEGRATION_IMPORT_EXPORT_STORE,
    ROUTING_STORE,
    USER_STORE
} from '../stores'
import {
    EDIT_ERP_LIST,
    IMPORT_EXPORT,
    INTEGRATION,
    NOT_FOUND,
} from '../../core/app-routes'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { externalSystemsManagerRemote } from '../../../protocol/set10/external-systems-manager-remote'
import { eRPIntegrationPropertiesManagerLocal } from '../../../protocol/set10/erp-integration-properties-manager-local'
import { ErpVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/erp-vo'
import { ERPIntegrationPropertiesVO } from '../../../protocol/set10/set-retail10-server/retailx/set-external-systems/erp-integration-properties-vo'
import { ErpName } from '../../core/integration/import-export/erp-name'

export interface ErpListItem extends ErpVO {
    idName: string,
    path: string,
}

export interface ErpSettings {
    [key: string]: string
}

export const allowedErps: ErpName[] = [
    ErpName.ShopEvolution,
    ErpName.Protocol585,
    ErpName.SetRetail5,
    ErpName.ESB,
    ErpName.SetRetail10File,
    ErpName.SetRetail10WSClient,
    ErpName.SetRetail10WebService,
]

// Есть ERP модули без настроек, соответственно у них нет страницы с настройками,
// соответственно мы не переходим на страницу настроек
export const erpsWithoutSettings: ErpName[] = [
    ErpName.ESB,
    ErpName.SetRetail10WebService,
]

const findErpFilter = (filterString: string) => ({ localizedName }: ErpListItem): boolean =>
    localizedName.trim().toLocaleLowerCase().indexOf(filterString) > -1

const erpVOtoErpListItem = (erp: ErpVO): ErpListItem => ({
    ...erp,
    idName: erp.name.split('.').map(capitalize).join(''),
    path: `${INTEGRATION}${IMPORT_EXPORT}/${erp.name.split('.').join('-')}`,
})

export class IntegrationImportExportStore {
    @observable
    erpList: ErpListItem[]

    @observable
    editableErpList: ErpListItem[] = []

    @observable
    erpSettings: ErpSettings

    @observable
    editableErpSettings: ErpSettings

    @observable
    erpIntegrationProperties: ERPIntegrationPropertiesVO

    @observable
    editableErpIntegrationProperties: ERPIntegrationPropertiesVO

    @observable
    registeredErpFilter: string = ''

    @observable
    editableErpFilter: string = ''

    @observable
    openedErpName: string = ''

    @observable
    isLoaded: boolean = false

    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    // "export.file" работает совместно с "import.file"
    // ERP с именем "import.file" не имеет экрана настроек, но регистрируется и удаляется
    // вместе с ERP "export.file", поэтому в общем списке его не отображаем,
    // но храним для того чтобы во время регистрации/удаления "export.file" к нему можно было обратиться
    // в методах deleteErp, deleteErpByName, updateErpList
    private importFileErp: ErpVO

    fetchErpList = async (): Promise<void> => {
        this.isLoaded = false

        // getErpList получает с бэка либо только зарегистрированные ERP либо незарегистрированные,
        // поэтому приходится делать два отдельных запроса и объединять результаты
        const registeredErpList: ErpVO[] = await externalSystemsManagerRemote.getErpList(
            this.userStore.session,
            this.appStore.locale,
            true
        )
        const notRegisteredErpList: ErpVO[] = await externalSystemsManagerRemote.getErpList(
            this.userStore.session,
            this.appStore.locale,
            false
        )
        // В приходящих с бэка данных свойство registered равно null и для зарегистрированных и для
        // незарегистрированных ERP, поэтому выставляем значение свойству сами
        const list: ErpVO[] = [
            ...registeredErpList.map(item => ({ ...item, registered: true })),
            ...notRegisteredErpList.map(item => ({ ...item, registered: false })),
        ]
        // Нам нужны не все ERP модули, фильтруем по списку разрешенных
        const filteredList: ErpVO[] = list.filter(
            ({ name }) => allowedErps.some(item => String(item) === name)
        )
        const orderedList: ErpVO[] = orderBy(filteredList, ['localizedName'], ['asc'])
        const importFileErp = find(list, ['name', 'import.file'])

        runInAction(() => {
            this.erpList = orderedList.map(erpVOtoErpListItem)
            this.importFileErp = importFileErp
            this.isLoaded = true
        })
    }

    fetchErpIntegrationProperties = async (): Promise<void> => {
        const properties = await eRPIntegrationPropertiesManagerLocal.getERPIntegrationProperties()

        runInAction(() => {
            this.erpIntegrationProperties = cloneDeep(properties)
            this.editableErpIntegrationProperties = cloneDeep(properties)
        })
    }

    fetchEditableErpList = async (): Promise<void> => {
        await this.fetchErpList()

        runInAction(() => {
            this.editableErpList = toJS(this.erpList).slice()
        })
    }

    fetchErpSettings = async (name: string): Promise<void> => {
        const settings = await externalSystemsManagerRemote.getErpSettingsByPluginName(name)

        runInAction(() => {
            this.erpSettings = cloneDeep(settings)
            this.editableErpSettings = cloneDeep(settings)
        })
    }

    changedErpList = (): ErpListItem[] => {
        return this.editableErpList.reduce((acc, editableErp) => {
            const erp: ErpListItem = find(this.erpList, ['id', editableErp.id])

            if (erp && editableErp.registered !== erp.registered) {
                return [...acc, editableErp]
            }

            return acc
        }, [])
    }

    changedErpSettings = (): ErpSettings => {
        const settings = toJS(this.editableErpSettings) || { }

        return Object.keys(settings).reduce((acc, key) => {
            if (settings[key] !== this.erpSettings[key]) {
                return { ...acc, [key]: settings[key] }
            }

            return acc
        }, { })
    }

    changedErpIntegrationProperties = (): ErpSettings => {
        const settings = toJS(this.editableErpIntegrationProperties) || { }

        return Object.keys(settings).reduce((acc, key) => {
            if (settings[key] !== this.erpIntegrationProperties[key]) {
                return { ...acc, [key]: settings[key] }
            }

            return acc
        }, { })
    }

    updateErpList = (): void => {
        const changedErps: ErpListItem[] = this.changedErpList()
        const promises: Array<Promise<ErpVO>> = changedErps.map(erp => externalSystemsManagerRemote.updateERP(
            this.userStore.session, erp, this.appStore.locale
        ))
        const SetRetail10FileErp = changedErps.find(({ name }) => name === ErpName.SetRetail10File)

        if (SetRetail10FileErp) {
            this.importFileErp.registered = SetRetail10FileErp.registered

            promises.push(externalSystemsManagerRemote.updateERP(
                this.userStore.session, this.importFileErp, this.appStore.locale
            ))
        }

        Promise.all(promises).then(() => goTo(`${INTEGRATION}${IMPORT_EXPORT}`))
    }

    updateSetting = (setting: string, value: string): void => {
        if (this.editableErpSettings.hasOwnProperty(setting)) {
            this.editableErpSettings[setting] = value
        } else {
            extendObservable(this.editableErpSettings, { [setting]: value })
        }
    }

    updateSettings = (settings: string[], value: string): void => {
        settings.forEach(setting => this.updateSetting(setting, value))
    }

    updateErpIntegrationProperty = (setting: string, value: string) => {
        if (this.editableErpIntegrationProperties.hasOwnProperty(setting)) {
            this.editableErpIntegrationProperties[setting] = value
        }
    }

    updateNavMenu = (): void => {
        this.appBarStore.updateState({
            title: t('set10.editErpList'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => goTo(`${INTEGRATION}${IMPORT_EXPORT}`)
        })
    }

    saveErpSettings = async (): Promise<void> => {
        const changedSettings = this.changedErpSettings()
        const settingsForSave = pickBy(changedSettings, (value, key) => !key.endsWith('.enabled'))
        const settingsForUpdate = pickBy(changedSettings, (value, key) => key.endsWith('.enabled'))
        const promises: Array<Promise<boolean>> = Object.keys(settingsForUpdate).map(
            key => externalSystemsManagerRemote.updatePluginStatus(
                key.replace('.enabled', ''),
                settingsForUpdate[key] === 'true'
            )
        )

        if (this.erpIntegrationProperties && size(this.changedErpIntegrationProperties())) {
            await eRPIntegrationPropertiesManagerLocal.setERPIntegrationProperties(this.editableErpIntegrationProperties)
        }

        await externalSystemsManagerRemote.saveErpSettings(settingsForSave)

        Promise.all(promises).then(() => {
            goTo(`${INTEGRATION}${IMPORT_EXPORT}`)
            this.appStore.showSnackbar({
                message: t('importExport.erpSettingsSaved'),
                variant: SNACK_BAR_INFO,
            })
        })
    }

    @action
    toggleErp = ({ id }: ErpListItem): void => {
        const erp = find(this.editableErpList, ['id', id])

        erp.registered = !erp.registered
    }

    @action
    openErpSettings = ({ path, name }: ErpListItem): void => {
        this.openedErpName = name

        this.fetchErpSettings(this.openedErpName)

        goTo(path)
    }

    @action
    deleteErp = async (erp: ErpListItem): Promise<void> => {
        const regErp = find(this.registeredErpList, ['id', erp.id])

        regErp.registered = false

        const deletedErp = await externalSystemsManagerRemote.updateERP(
            this.userStore.session,
            erp,
            this.appStore.locale
        )

        if (erp.name === ErpName.SetRetail10File) {
            this.importFileErp.registered = false

            await externalSystemsManagerRemote.updateERP(this.userStore.session, this.importFileErp, this.appStore.locale)
        }

        const deletedErpIdx = this.erpList.findIndex(({ id }) => id === deletedErp.id)

        this.appStore.showSnackbar({
            message: t('importExport.successDeleteErp', { name: regErp.localizedName }),
            variant: SNACK_BAR_INFO,
        })

        runInAction(() => {
            this.erpList.splice(deletedErpIdx, 1)
        })
    }

    @action
    deleteErpByName = async (name: string): Promise<void> => {
        const regErp = find(this.registeredErpList, ['name', name])

        regErp.registered = false

        await externalSystemsManagerRemote.updateERP(
            this.userStore.session,
            regErp,
            this.appStore.locale
        )

        if (name === ErpName.SetRetail10File) {
            this.importFileErp.registered = false

            await externalSystemsManagerRemote.updateERP(this.userStore.session, this.importFileErp, this.appStore.locale)
        }

        goTo(`${INTEGRATION}${IMPORT_EXPORT}`)
        this.appStore.showSnackbar({
            message: t('importExport.successDeleteErp', { name: regErp.localizedName }),
            variant: SNACK_BAR_INFO,
        })
    }

    @action
    setRegisteredErpFilter = (filter: string): void => {
        this.registeredErpFilter = filter
    }

    @action
    setEditableErpFilter = (filter: string): void => {
        this.editableErpFilter = filter
    }

    @computed
    get filteredRegisteredErpList(): ErpListItem[] {
        if (!this.registeredErpFilter) {
            return this.registeredErpList
        }

        const filter: string = this.registeredErpFilter.trim().toLocaleLowerCase()

        return this.registeredErpList.filter(findErpFilter(filter))
    }

    @computed
    get filteredEditableErpList(): ErpListItem[] {
        if (!this.editableErpFilter) {
            return this.editableErpList
        }

        const filter: string = this.editableErpFilter.trim().toLocaleLowerCase()

        return this.editableErpList.filter(findErpFilter(filter))
    }

    @computed
    get isCanSave(): boolean {
        return this.changedErpList().length > 0
    }

    @computed
    get isCanSaveSettings(): boolean {
        return size(this.changedErpSettings()) > 0
            || (this.editableErpIntegrationProperties && size(this.changedErpIntegrationProperties()) > 0)
    }

    @computed
    get registeredErpList(): ErpListItem[] {
        return this.erpList?.filter(erp => erp.registered)
    }
}

export const INTEGRATION_EDIT_ERP_LIST_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${INTEGRATION}${IMPORT_EXPORT}${EDIT_ERP_LIST}`),
    onEnter: () => {
        const integrationImportExportStore: IntegrationImportExportStore = getStore(INTEGRATION_IMPORT_EXPORT_STORE)
        integrationImportExportStore.updateNavMenu()
    },
}

export const INTEGRATION_ERP_VIEW_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${INTEGRATION}${IMPORT_EXPORT}/([a-z][a-z0-9]*)(-[a-z0-9]+)*$`),
    onEnter: async () => {
        const integrationImportExportStore: IntegrationImportExportStore = getStore(INTEGRATION_IMPORT_EXPORT_STORE)
        const routing: RouterStore = getStore(ROUTING_STORE)
        const appBar: AppBarStore = getStore(APP_BAR_STORE)

        if (integrationImportExportStore.openedErpName === '') {
            await integrationImportExportStore.fetchErpList()

            const erpPath: string = routing.location.pathname
            const erp: ErpListItem = integrationImportExportStore.registeredErpList.find(({ path }) => path === erpPath)

            if (
                !erp ||
                erp && erpsWithoutSettings.findIndex(item => String(item) === erp.name) >= 0
            ) {
                // Вызов goTo приводит к изменению routing.location.pathname, что должно триггерить авторан, следящий за роутами.
                // Этого не происходит, если авторан уже в стеке вызова.
                // Поэтому для подобного редиректа можно переносить изменения роута на следующий таск.
                setTimeout(() => goTo(NOT_FOUND), 0)
                return
            }

            runInAction(() => {
                integrationImportExportStore.openedErpName = erp.name

                integrationImportExportStore.fetchErpSettings(erp.name)
            })
        }

        appBar.updateState({
            title: `${t('set10.importExport')} - ${t(`importExport.protocolName.${
                integrationImportExportStore.openedErpName.replace('export.', '')
            }`)}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => goTo(`${INTEGRATION}${IMPORT_EXPORT}`)
        })
    },
    onLeave: () => {
        const integrationImportExportStore: IntegrationImportExportStore = getStore(INTEGRATION_IMPORT_EXPORT_STORE)

        integrationImportExportStore.openedErpName = ''
        integrationImportExportStore.erpSettings = null
        integrationImportExportStore.editableErpSettings = null
        integrationImportExportStore.erpIntegrationProperties = null
        integrationImportExportStore.editableErpIntegrationProperties = null
    }
}
