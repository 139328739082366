import { toJS, computed, action, observable } from 'mobx'
import { paymentTypesManager } from '../../../../protocol/set10/payment-types-manager'
import { getStore } from '../../stores-repository'
import { PAYMENT_TYPES_STORE } from '../../stores'
import { PaymentType, PaymentTypeProp, PaymentTypesStore } from './payment-types-store'
import { isEqual } from 'lodash'
import { goTo } from '../../../utils/router-util'
import { CASH_MODULE, PAYMENT_TYPES } from '../../../core/app-routes'

export class PaymentTypeSettingsStore {
    @observable
    paymentTypeId: string = undefined

    @observable
    editedPaymentType: PaymentType = undefined

    @observable
    paymentTypeProps: PaymentTypeProp[] = undefined

    @computed
    get paymentTypeClass() {
        const classProp = this.paymentTypeProps ? this.paymentTypeProps.find(prop => prop.key === 'paymentTypeClass') : undefined
        return classProp ? classProp.value : undefined
    }

    @computed
    get paymentTypeChanged() {
        return this.editedPaymentType ? !isEqual(toJS(this.editedPaymentType), this.origPaymentType) : false
    }

    @computed
    get paymentTypePropsChanged() {
        return this.paymentTypeProps ? !isEqual(toJS(this.paymentTypeProps), this._paymentTypeProps) : false
    }

    private paymentTypesStore: PaymentTypesStore = getStore(PAYMENT_TYPES_STORE)
    private origPaymentType: PaymentType = undefined
    private _paymentTypeProps: PaymentTypeProp[] = undefined

    // Payment type

    @action
    fetchPaymentType = async () => {
        const { paymentTypeId, paymentTypesStore, setPaymentType, setPaymentTypeProps } = this
        if (!paymentTypeId) return
        await paymentTypesStore.fetchPaymentTypes()
        const paymentType = toJS(paymentTypesStore.getPaymentType(paymentTypeId))
        const paymentTypeProps = await paymentTypesManager.getProperties1(paymentType.id)
        setPaymentType(paymentType)
        setPaymentTypeProps(paymentTypeProps)
    }

    @action
    savePaymentType = async () => {
        const paymentTypeProps = await paymentTypesManager.setProperties(
            this.paymentTypeId,
            toJS(this.paymentTypeProps),
            this.editedPaymentType.enabled
        )
        this.setPaymentType(toJS(this.editedPaymentType))
        this.setPaymentTypeProps(paymentTypeProps)
    }

    @action
    closePaymentType = () => {
        this.reset()
        goTo(`${CASH_MODULE}${PAYMENT_TYPES}`)
    }

    @action
    togglePaymentType = () => {
        this.editedPaymentType.enabled = !this.editedPaymentType.enabled
    }

    // Payment type props

    @action
    addPaymentTypeProp = (paymentTypeProp: PaymentTypeProp) => {
        this.paymentTypeProps.push(paymentTypeProp)
    }

    @action
    deletePaymentTypeProp = (paymentTypeProp: PaymentTypeProp) => {
        this.paymentTypeProps = this.paymentTypeProps.filter(prop => prop !== paymentTypeProp)
    }

    @action
    updatePaymentTypeProp = (paymentTypeProp: PaymentTypeProp) => {
        const exPaymentTypeProp = this.paymentTypeProps.find(prop => prop.key === paymentTypeProp.key)
        const index = this.paymentTypeProps.indexOf(exPaymentTypeProp)
        if (index === -1) {
            this.paymentTypeProps.push(paymentTypeProp)
        } else {
            this.paymentTypeProps[index] = paymentTypeProp
        }
    }

    // (Re)setters

    @action
    setPaymentTypeId = (value: string) => {
        this.paymentTypeId = value
    }

    @action
    setPaymentType = (value: PaymentType) => {
        this.editedPaymentType = value
        this.origPaymentType = toJS(value)
    }

    @action
    setPaymentTypeProps = (value: PaymentTypeProp[]) => {
        this.paymentTypeProps = value
        this._paymentTypeProps = toJS(value)
    }

    reset = () => {
        this.setPaymentTypeProps(undefined)
        this.setPaymentType(undefined)
        this.setPaymentTypeId(undefined)
    }
}
