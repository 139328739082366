import * as React from 'react'
import cx from 'classnames'
import { AutocompleteInput, AutocompleteInputProps } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import {t} from 'i18next'
import {productManagerFinderLocal} from '../../../protocol/set10/product-manager-finder-local'
import {createProductsFilter} from '../../../protocol/set10/set-retail10-commons/data-structs-module/products-filter'
import {SimpleProduct} from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import {ACTIVE} from '../../../set10/core/products/product-statuses'
import { ProductType, WEIGHT } from '../../../set10/core/products/product-types'

const styles = require('./product-autocomplete.scss')
const SUGGESTION_LIMIT = 20

interface ProductAutocompleteProps {
    statusCode?: number
    session?: string
    placeholder?: string
    searchIcon?: JSX.Element
    selectedProductsIdentifiers?: string[]
    markedClassName?: string
    productType?: ProductType
    fullWidth?: boolean
}

const defaultProps: ProductAutocompleteProps = {
    markedClassName: styles.selectedAutocompleteItem,
    fullWidth: false,
}

export const ProductAutocomplete
    = (props: Partial<AutocompleteInputProps<SimpleProduct>> & ProductAutocompleteProps) => {
    const {
        statusCode = ACTIVE,
        session,
        ref,
        placeholder = t('components.autocomplete.productPlaceholder'),
        selectedProductsIdentifiers,
        markedClassName,
        productType,
        fullWidth,
        ...other
    } = props

    return (
        <AutocompleteInput<SimpleProduct>
            fullWidth={fullWidth}
            fetchDataCallback={mask => {
                if (productType === WEIGHT) {
                    return productManagerFinderLocal.getSimpleScalesProductsByFilter(
                        createProductsFilter({
                            good: `use_like:%${mask}%`,
                            productStatusCode: statusCode
                        }),
                        0,
                        SUGGESTION_LIMIT
                    )
                }

                return productManagerFinderLocal.getSimpleProductsByFilter(
                    session,
                    createProductsFilter({
                        good: `use_like:%${mask}%`,
                        productStatusCode: statusCode
                    }),
                    0,
                    SUGGESTION_LIMIT
                )
            }}
            suggestionsLimit={SUGGESTION_LIMIT}
            placeholder={placeholder}
            keyField="identifier"
            menuItemHeight={94}
            menuItemRenderer={item => {
                const isChoosen = selectedProductsIdentifiers && selectedProductsIdentifiers.some(identifier => item.identifier === identifier)

                return (
                    <div
                        className={cx({
                            [markedClassName]: isChoosen
                        })}
                    >
                        <p>{item.name}</p>
                        <p className={styles.secondary}>{t('shop.barcode')}: {item.barCode}</p>
                        <p className={styles.secondary}>{t('shop.productCode')}: {item.code}</p>
                    </div>
                )
            }}
            labelField="name"
            id="productAutocomplete"
            autoSelectOneMatch
            {...other}
        />
    )
}

ProductAutocomplete.defaultProps = defaultProps
