import { isNil } from 'lodash'

export interface TimeZone {
    value: string
    label: string
}

function generateTimeZones(): TimeZone[] {
    let values = []
    for (let i = -11; i <= 12; i++) {
        values.push(i)
    }
    return values.map(v =>
        ({
            value: `${v}`,
            label: getTimeZoneLabel(v)
        }))
}

export function getTimeZoneLabel(value: string): string {
    if (isNil(value)) return `GMT+0`

    let v = parseInt(value, 10)
    return v < 0 ? `GMT${v}` : `GMT+${v}`
}

export const timeZones: TimeZone[] = generateTimeZones()
