import { SizeBindingConditionType } from './size-binding-condition-type'

export interface SizeBindingConditionVO {
    '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.SizeBindingConditionVO'
    id?: number
    sizeBindingId?: number
    name?: string
    code?: string
    barcode?: string
    type?: SizeBindingConditionType
    printCount?: number
    excluded?: boolean
    identifier?: string
}

export const createSizeBindingConditionVO = (initialization: Partial<SizeBindingConditionVO>): SizeBindingConditionVO => {
    if (!initialization) return null
    
    let result: SizeBindingConditionVO = {
        id: initialization.id,
        sizeBindingId: initialization.sizeBindingId,
        name: initialization.name,
        code: initialization.code,
        barcode: initialization.barcode,
        type: initialization.type,
        printCount: initialization.printCount,
        excluded: initialization.excluded,
        identifier: initialization.identifier,
        '@class': 'ru.crystals.setretailx.print.formats.presenter.vo.SizeBindingConditionVO',
    }
    
    return result
}
