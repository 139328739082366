import {observable, action, computed, runInAction} from 'mobx'
import { iEquipmentManagerLocal } from '../../../protocol/set10/i-equipment-manager-local'
import { EquipmentModelVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import {t} from 'i18next'
import { UserStore } from '../user-store'
import { AppStore } from '../app-store'
import { SnackbarStore } from '../snackbar-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE, SNACKBAR_STORE } from '../stores'
import {withSpinner} from '../with-spinner'
import { DeviceClassType } from '../../core/devices-classes'

export interface DialogEquipmentModel extends EquipmentModelVO {
    registered?: boolean
}

export class ShopDevicesStore {
    // Все модели хранятся в двумерном массиве - первый уровень = классы и у каждого класса свой массив с моделями
    @observable
    allModels: Map<DeviceClassType, EquipmentModelVO[]> = new Map()

    @observable
    registeredModels: Map<DeviceClassType, EquipmentModelVO[]> = new Map()

    @observable
    filterString: string = ''

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    getFilteredModels = (classType: DeviceClassType): DialogEquipmentModel[] => {
        if (!this.allModels.has(classType)) return []

        let tempRegisteredModels =  [...this.registeredModels.get(classType)]
        // Сначала собираем объект, который хранит все модели, а уже зарегистрированные помечены
        let allModelsWithRegistered = this.allModels.get(classType).map(item => {
            let dialogModel: DialogEquipmentModel = {...item, registered: false}
            let foundItemIndex = tempRegisteredModels.findIndex(registered => {
                return registered.name === item.name
                    && registered.equipmentType.name === item.equipmentType.name
            })

            if (foundItemIndex !== -1) {
                // Удалим из временного массива и пометим как уже зарегистрированное
                dialogModel.registered = true
                tempRegisteredModels.splice(foundItemIndex, 1)
            }
            return dialogModel
        })

        const filterString = this.filterString.toLowerCase()
        if (filterString === '') {
            return allModelsWithRegistered
        }

        let filteredModels: DialogEquipmentModel[] = []
        allModelsWithRegistered.forEach(model => {
            const modelName = model.localizedName.toLowerCase()
            const modelType = model.equipmentType.localizedName.toLowerCase()

            if (modelName.indexOf(filterString) !== -1 || modelType.indexOf(filterString) !== -1) {
                // Нашли в имени модели либо в имени "Типа" - добавляем
                filteredModels.push(model)
            }

        })

        return filteredModels
    }

    fetchAllDataOfClass = async (classType: DeviceClassType): Promise<void> => {
        await this.fetchAllModelsOfClass(classType)
        await this.fetchAllRegisteredModelsOfClass(classType)
    }

    fetchAllModelsOfClass = (classType: DeviceClassType): Promise<any> => {
        return iEquipmentManagerLocal.getAllEquipmentModelsOfClass(this.userStore.session, classType, this.appStore.locale)
            .then(newModels => {
                runInAction(() => {
                    this.allModels.set(classType, newModels)

                    // Создаем пустой объект, если еще небыло
                    if (!this.registeredModels.has(classType)) {
                        this.registeredModels.set(classType, [])
                    }
                })
            })
    }

    fetchAllRegisteredModelsOfClass = (classType: DeviceClassType): Promise<any> => {
        return iEquipmentManagerLocal.getAllRegisteredEquipmentModelsOfClass(this.userStore.session, classType, this.appStore.locale)
            .then(newModels => {
                runInAction(() => {
                    this.registeredModels.set(classType, newModels)
                })
            })
    }

    @action
    registerNewModel = async (device: EquipmentModelVO): Promise<void> => {
        let equipmentType = device.equipmentType
        let classType = equipmentType.equipmentClass.name as DeviceClassType
        let typeName = equipmentType.name
        let modelName = device.name

        await withSpinner(async () => {
            const model = await iEquipmentManagerLocal.registerAndGetModel(
                this.userStore.session,
                classType,
                typeName,
                modelName,
                this.appStore.locale
            )
            if (model.name !== null) {
                this.snackbarStore.show({message: t('devices.deviceAdded')})
            }
            await this.fetchAllRegisteredModelsOfClass(classType)
        })
    }

    removeRegisteredDevice = async (device: EquipmentModelVO): Promise<void> => {
        let classType = device.equipmentType.equipmentClass.name as DeviceClassType

        // device придет не полный, без свойства id, надо найти айдишник в списке зарегистрированных
        let registeredDevices = this.registeredModels.get(classType)
        let foundRegisteredDevice = registeredDevices.find(item => {
            return item.name === device.name && item.equipmentType.name === device.equipmentType.name
        })
        if (!foundRegisteredDevice) return Promise.reject()

        await withSpinner(async () => {
            await iEquipmentManagerLocal.unRegisterEquipment(this.userStore.session, foundRegisteredDevice.id)
            await this.fetchAllRegisteredModelsOfClass(classType)
        })
    }

    @action
    searchInputChange = (value: string): void => {
        this.filterString = value
    }

    @action
    reset = (): void => {
        this.allModels = new Map()
        this.registeredModels = new Map()
        this.filterString = ''
    }
}
