import { isArray, isNil } from 'lodash'
import React, { ComponentType, ReactElement, ReactNode } from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router'
import { PageMock } from '../../components/page-mock/page-mock'
import { LOGIN, NOT_FOUND, ROOT } from '../core/app-routes'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import { DEFAULT_PRIVILEGE } from '../core/privileges/privileges'

export type ServerType = 'Centrum' | 'Retail'

export const CENTRUM: ServerType = 'Centrum'
export const RETAIL: ServerType = 'Retail'

export interface RouteScheme {
    /**
     * Настройки для бокового меню.
     * Если undefined/null - бокового меню для данного роута нет.
     */
    navItemProps?: {
        id: string
        label?: string
        icon?: React.ReactElement
        endAdornment?: ReactNode
        selectable?: boolean
        isGroup?: boolean   // Группа используется только в navMenu. Это не папка. Используется для корня.
        onClick?: () => void
    }

    /**
     * Полный путь
     */
    path?: string
    /**
     * Если true (по-умолчанию false), то на данный роут запрещается редирект из компонента react-router <Redirect />
     */
    cannotBeRedirectTarget?: boolean

    /**
     * Привилегии могут задаваться массивом в случае простой проверки.
     * При необходимости реализовать сложную проверку привелегий, сюда может быть передана функция,
     * которая принимает объект PrivilegeManager и возвращает true, если проверка привилегий успешна
     */
    privileges?: string[] | ((privilegeManager: PrivilegeManager, isCentrum: boolean) => boolean)
    /**
     * Доступность данного элемента только на определенном типе сервера
     */
    serverType?: ServerType

    /**
     * Если есть доступные потомки, узел воспринимается как промежуточный (даже если задан component)
     */
    children?: RouteScheme[]

    component?: ComponentType<RouteComponentProps<any>> | ComponentType<any>
}

export function getAvailableRoutesScheme(
    routeScheme: RouteScheme,
    isCentrum: boolean,
    privilegeManager: PrivilegeManager
): RouteScheme {

    if (isNil(routeScheme)) return null

    const { children, privileges: routeSchemePrivileges, serverType, path } = routeScheme
    let privileges = routeSchemePrivileges

    if (![ROOT, LOGIN, NOT_FOUND].includes(path) && (!routeSchemePrivileges || !routeSchemePrivileges.length) && !isArray(children)) {
        privileges = [DEFAULT_PRIVILEGE]
    }

    if (isArray(privileges)) {
        if (privileges.some(privilege => !privilegeManager.havePrivilege(privilege))) return null
    }

    if (typeof privileges === 'function') {
        if (!privileges(privilegeManager, isCentrum)) return null
    }

    if (!isNil(serverType)) {
        if (isCentrum && serverType === RETAIL || !isCentrum && serverType === CENTRUM) return null
    }

    if (isArray(children)) {
        const availableChildren: RouteScheme[] = children.map(
            child => getAvailableRoutesScheme(child, isCentrum, privilegeManager)
        ).filter(Boolean)

        if (availableChildren.length > 0) return { ...routeScheme, children: availableChildren }

        // В остальных случаях, т.к. потомки пустые, в элементе нет смысла
        return null
    }

    return routeScheme
}

/**
 * Рекурсивно рендерит маршрут по схеме. Не проверяет доступность роутов.
 * Для получения схемы с доступными текущему пользователю роутами следует вызвать getAvailableRoutesScheme
 * @param routeScheme
 */
export function renderRouteByScheme(routeScheme: RouteScheme): ReactElement<RouteProps> {
    if (isNil(routeScheme)) return null

    const { component, children, path } = routeScheme

    let result: ReactElement<RouteProps>

    // Если есть потомки - текущий роут является модулем
    if (isArray(children) && children.length > 0) {
        const childRedirectPath = getChildRedirectPath(routeScheme)
        result = (
            <Route path={path}
                   key={path}
            >
                <Switch>
                    { childRedirectPath && (path !== childRedirectPath) &&
                        <Redirect
                            exact
                            from={path || ROOT}
                            to={childRedirectPath}
                        />
                    }

                    {children.map(child =>
                        renderRouteByScheme(child))}

                    <Redirect
                        to={NOT_FOUND}
                    />
                </Switch>
            </Route>
        )
    } else {
        // Элементы без path не надо отрисовывать, они будут перехватывать
        if (!path) return null
        result = (
            <Route
                exact
                // key={path} // SFM-1836 специально не передаём key чтобы не пересоздавался IframeContainer при смене роута;
                path={path}
                // Если указан компонент - это концевой узел дерева маршрутов
                // Если нет - это заглушка для будущей страницы
                component={component || PageMock}
            />
        )
    }
    return result
}

function getChildRedirectPath(routeScheme: RouteScheme): string {
    if (!isArray(routeScheme.children)) {
        return null
    }
    let result: string = null
    const children = [...routeScheme.children]

    // обходим children с конца, поскольку первые children имеют приоритет для редиректа
    children.reverse()

    children.forEach(childScheme => {
        if (!childScheme.cannotBeRedirectTarget && childScheme.path !== NOT_FOUND) {
            // если у данного child-роута есть свои children, то возвращаем результат, только если есть подходящий для редиректа внучатый child
            if (isArray(childScheme.children)) {
                const thisChildRouteIsValidRedirect: boolean = childScheme.children.some(grandChildScheme => {
                    return childScheme.path && !grandChildScheme.cannotBeRedirectTarget
                })

                // если среди children данного роута нет подходящих для редиректа роутов
                // то данный роут не годится для редиректа
                if (!thisChildRouteIsValidRedirect && !getChildRedirectPath(childScheme)) {
                    return
                }
            }
            result = childScheme.path && childScheme.path.split('/').filter(i => i.indexOf('?') === -1).join('/')
        }
    })

    return result
}
