import * as Cookies from 'js-cookie'
import { action, observable } from 'mobx'
import { ALL_SERVICES } from '../../protocol/set10/all-services'
import { iSessionManagerLocal } from '../../protocol/set10/i-session-manager-local'
import { PrivilegeVO } from '../../protocol/set10/set-retail10-server/retailx/server-ds/privilege-vo'
import { UserRoleVO } from '../../protocol/set10/set-retail10-server/retailx/server-ds/user-role-vo'
import { UserVO } from '../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import { LOGIN } from '../core/app-routes'
import { AppModule } from '../core/privileges/app-modules'
import { goTo } from '../utils/router-util'
import { ALL_STORES, USER_INDEPENDENT_STORES } from './stores'
import { getStore } from './stores-repository'
import { PrivilegeManager } from '../core/privileges/privilege-manager'

export const FLEX_COOKIE_NAME = 'SetRetailX'

export class UserStore {
    @observable
    session: string
    @observable
    userInfo: UserVO
    @observable
    authorized: boolean = false
    @observable
    roles: UserRoleVO[] = []

    privilegeManager: PrivilegeManager = new PrivilegeManager([])

    logout = (): Promise<any> => {
        // Удаляем cookie, чтобы флекс узнал о том, что произошел логаут
        Cookies.remove(FLEX_COOKIE_NAME, {path: ''})

        return iSessionManagerLocal.logout(this.session)
            .then(() => {
                this.resetAuthorization()
            })
    }

    @action
    fillRoles = (): void => {
        if (!this.userInfo) return

        this.roles = [...this.userInfo.userRoles]

        this.privilegeManager = new PrivilegeManager(this.userInfo.userRoles)
    }

    havePrivilege = (privilege: string): boolean => {
        return this.privilegeManager.havePrivilege(privilege)
    }

    haveAnyPrivilegeFromList = (privileges: string[]): boolean => {
        return this.privilegeManager.haveAnyPrivilegeFromList(privileges)
    }

    haveAllPrivilegesFromList = (privileges: string[]): boolean => {
        return this.privilegeManager.haveAllPrivilegesFromList(privileges)
    }

    haveAnyPrivilegeFromModule = (module: AppModule): boolean => {
        return this.privilegeManager.haveAnyPrivilegeFromModule(module)
    }

    /**
     * Возвращает все привилегии из модуля, кроме указанных
     * @param {AppModule} module
     * @param {string[]} except
     * @returns {PrivilegeVO[]}
     */
    getPrivilegesFromModule = (module: AppModule, except: string[] = []): PrivilegeVO[] => {
        return this.privilegeManager.getPrivilegesFromModule(module, except)
    }

    @action
    setActiveSession = (session: string): void => {
        this.session = session
        this.authorized = true
    }

    @action
    resetAuthorization = () => {
        this.resetUserDependentStores()
        // Сбросим кэш запросов у всех сервисов
        ALL_SERVICES.forEach(service => {
            service.clearMemoizationCache()
        })
        goTo(LOGIN)
    }

    @action
    resetUserDependentStores = (): void => {
        ALL_STORES.forEach(storeName => {
            const storeInstance: any = getStore(storeName)
            // Не сбрасываем состояние сторов, которые не зависят от текущего пользователя или сессии
            if (!USER_INDEPENDENT_STORES.includes(storeName)) {
                if (storeInstance.reset && typeof storeInstance.reset === 'function') {
                    storeInstance.reset()
                } else {
                    console.warn(`'${storeName}' should be reset but no 'reset' method has been provided. ` +
                    `Provide the necessary method or add '${storeName}' to the USER_INDEPENDENT_STORES exceptions list`)
                }
            }
        })
    }

    @action
    reset = (): void => {
        this.session = undefined
        this.userInfo = undefined
        this.authorized = false
        this.roles = []
        this.privilegeManager = new PrivilegeManager([])
    }
}
