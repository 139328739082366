import React from 'react'
import { t } from 'i18next'
import { TimelineItem } from './timeline-item'
import { DAY, WEEK, MONTH, YEAR } from '../../../../../utils/date/date-format'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import { TimelineDatePeriod, getPeriodArray } from './timeline-utils'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { throttle } from 'lodash'
import { SCROLL_SYNC_DELAY } from '../../../../../utils/default-timeouts'
import { RESIZE } from '../../../../../utils/dom/dom-events'
import { WorksAnytimeOption } from '../../../../store/loyalty/actions/actions-search-store'

const styles = require('./timeline.scss')

export const DEFAULT_CELL_WIDTH = 90

export interface TimelineItemProps<T = any> {
    id: string
    startDate: Date
    endDate: Date
    className?: string
    icon?: JSX.Element
    data?: T,
    tooltip?: JSX.Element
    worksAnyTime?: WorksAnytimeOption
}

export interface TimelineProps<T = any> {
    id?: string
    className?: string
    now: Date
    timePeriod: TimelineDatePeriod
    items?: Array<TimelineItemProps<T>>
    worksAnyTime?: WorksAnytimeOption
    labelFunction?: (item: TimelineItemProps<T>) => string
    secondaryTextFunction?: (item: TimelineItemProps<T>) => string
    itemOnDoubleClickFunction?: (item: TimelineItemProps<T>) => void
    /**
     * Следующие 4 свойства используются для синхронизации скрола с внешним компонентом
     */
    todayRef?: (ref: HTMLDivElement) => void
    headerRef?: (ref: HTMLDivElement) => void
    contentRef?: (ref: HTMLDivElement) => void
    onScrollLeftChange?: (scrollLeft: number) => void
    onWidthChange?: (width: number) => void
}

export interface TimelineState {
    anchorElement: HTMLElement,
    currentTooltip: JSX.Element,
    popupX: number,
    popupY: number,
}

export class Timeline<T = any> extends React.Component<TimelineProps<T>, TimelineState> {

    static defaultProps: Partial<TimelineProps> = {
        id: 'timeline',
        labelFunction: item => item.data,
        onScrollLeftChange: () => null,
    }

    state: TimelineState = {
        anchorElement: null,
        currentTooltip: null,
        popupX: null,
        popupY: null,
    }

    todayRef: HTMLDivElement
    headerRef: HTMLDivElement
    contentRef: HTMLDivElement
    lastTotalWidth: number

    throttledForceUpdate: (() => void) = throttle(
        () => this.forceUpdate(),
        SCROLL_SYNC_DELAY
    )

    componentDidMount() {
        window.addEventListener(RESIZE, this.throttledForceUpdate)
    }

    componentWillUnmount() {
        window.removeEventListener(RESIZE, this.throttledForceUpdate)
    }

    getCellWidth = (): number => {
        // Считать ширину каждой ячейки: минимум 90px
        // Если не влезли в текщую ширину => растягиваем пока не влезем
        if (this.headerRef) {
            const headerWidth = this.headerRef.clientWidth

            const { timePeriod } = this.props
            const numberOfSteps = getPeriodArray(timePeriod).length
            if (headerWidth > numberOfSteps * DEFAULT_CELL_WIDTH) {
                return headerWidth / numberOfSteps
            }
        }

        return DEFAULT_CELL_WIDTH
    }

    getTotalWidth = (cellWidth: number): number => {
        const { timePeriod, onWidthChange } = this.props
        const steps = getPeriodArray(timePeriod)

        let newTotalWidth = steps.length * cellWidth

        // Из-за дробных чисел получается скроллбар шириной в +1 пиксель
        if (!Number.isInteger(cellWidth)) {
            newTotalWidth -= 1
        }

        if (this.lastTotalWidth !== newTotalWidth && newTotalWidth > 100) {
            setTimeout(() => onWidthChange(newTotalWidth), 0)
            this.lastTotalWidth = newTotalWidth
        }

        return newTotalWidth
    }

    syncScrollToHeader = (): void => {
        const { onScrollLeftChange } = this.props
        let scrollLeft = this.contentRef.scrollLeft

        const width = this.headerRef.clientWidth
        const totalScroll = this.headerRef.scrollWidth
        const maxScroll = totalScroll - width

        /**
         * Если скроллируем колесиком, можно проскроллировать дальше, из-за ширины "вертикального скроллбара"
         * Т.к. у хэдера нет скроллбара, но он есть у раб.области - она может уйти дальше
         */
        if (scrollLeft > maxScroll) {
            scrollLeft = maxScroll
            this.contentRef.scrollLeft = maxScroll
        }

        if (this.headerRef) {
            this.headerRef.scrollLeft = scrollLeft
        }

        if (this.todayRef) {
            this.todayRef.scrollLeft = scrollLeft
        }

        onScrollLeftChange(scrollLeft)

        // После окончания скрола обновляем положение текста акций (если он слева за видимой областью - будет виден целиком)
        this.throttledForceUpdate()
    }

    handlePopupPosition = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.persist()

        /**
         * нужно добавлять дополнительные пиксели, иначе при наведении
         * попап будет рендериться под мышью, и затирать currentTooltip с anchorElement
         */
        let popupX = event.pageX + 20
        let popupY = event.pageY + 20

        const containerRect = event.currentTarget.getBoundingClientRect()
        const relativeY = containerRect.bottom - popupY
        const relativeX = containerRect.right - popupX

        // при приближении курсором к нижней границе контейнера отнимаем высоту попапа
        if (relativeY < 250) {
            popupY -= 220
        }

        // при приближении курсором к правой границе контейнера отнимаем ширину попапа
        if (relativeX < 350) {
            popupX -= 380
        }

        this.setState({
            popupX,
            popupY,
        })
    }

    render() {
        const {
            id, items, labelFunction, timePeriod, now, className, worksAnyTime,
            contentRef, headerRef, todayRef, itemOnDoubleClickFunction, secondaryTextFunction,
        } = this.props

        const { currentTooltip } = this.state

        const { startDate, endDate, interval } = timePeriod
        const timelineStartTime = startDate && startDate.getTime()
        const timelineEndTime = endDate && endDate.getTime()

        const steps = getPeriodArray(timePeriod)

        if (!steps) return null

        const cellWidth = this.getCellWidth()
        const totalWidth = this.getTotalWidth(cellWidth)
        const contentScrollLeft = this.contentRef && this.contentRef.scrollLeft

        const timeInOnePixel = (timelineEndTime - timelineStartTime) / totalWidth

        let todayLeftOffset: number
        const nowTime = now.getTime()
        if (nowTime > timelineStartTime && nowTime < timelineEndTime) {
            todayLeftOffset = (nowTime - timelineStartTime) / timeInOnePixel
        }

        // создаем массив только при выбранной опции
        let groupedItems: Array<TimelineItemProps<T> | string>
        if (worksAnyTime === WorksAnytimeOption.ALL) {
           let worksAnyTimeItems = items.filter((items: TimelineItemProps<any>) => items.data.worksAnytime)
           let notWorksAnyTimeItems = items.filter((items: TimelineItemProps<any>) => !items.data.worksAnytime)

           groupedItems = [
               t('actionsSearch.notWorksAnytimeFull'),
               ...notWorksAnyTimeItems,
               t('actionsSearch.worksAnytimeFull'),
               ...worksAnyTimeItems
           ]
        }

        const itemsToRender = worksAnyTime === WorksAnytimeOption.ALL ? groupedItems : items

        return (
            <Paper id={id} className={classNames(styles.timelineContainer, className)}>

                {/** Круг с линией, обозначающий сегодняшнюю дату */}
                <div className={styles.todayCircle}>
                    {todayLeftOffset && (
                        <div
                            className={styles.todayCircleScroll}
                            ref={ref => {
                                this.todayRef = ref
                                todayRef(ref)
                            }}
                        >
                            <div style={{ width: totalWidth, height: 1 }}/>
                            <div className={styles.todayCircleInner} style={{ left: todayLeftOffset }}/>
                            <div className={styles.todayLine} style={{ left: todayLeftOffset }}>
                                <div className={styles.todayLineInner}></div>
                            </div>
                        </div>
                    )}
                </div>

                {/** Хэдер с датами, скроллируемый */}
                <div
                    className={styles.headerScrollContainer}
                    ref={ref => {
                        this.headerRef = ref
                        headerRef(ref)
                    }}
                >
                    <div
                        className={styles.header}
                        style={{
                            width: totalWidth,
                            backgroundSize: `${cellWidth}px 67px`,
                        }}
                    >
                        {steps.map((step, stepIndex) => {
                            const stepTime = step.toDate().getTime()
                            let nextStepTime
                            if (steps.length === stepIndex + 1) {
                                // Последний шаг
                                nextStepTime = timelineEndTime
                            } else {
                                nextStepTime = steps[stepIndex + 1].toDate().getTime()
                            }

                            return (
                                <div
                                    id={`header_${stepIndex}`}
                                    key={step.toString()}
                                    style={{ width: cellWidth }}
                                    className={classNames(styles.headerCell, {
                                        [styles.dateHeaderNow]: nowTime > stepTime && nowTime <= nextStepTime
                                    })}
                                >
                                    {interval === DAY && (
                                        <>
                                            <div>{step.format('DD.MM.YY')}</div>
                                            <div>{step.format('HH:mm')}</div>
                                        </>
                                    )}
                                    {(interval === WEEK || interval === MONTH) && (
                                        <>
                                            <div>{step.format('DD.MM.YY')}</div>
                                            <div>{step.format('dd')}</div>
                                        </>
                                    )}
                                    {interval === YEAR && (
                                        <>
                                            <div>{step.format('YYYY')}</div>
                                            <div>{step.format('MMMM')}</div>
                                        </>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/** Основная область, где находятся элементы, скроллируемая */}
                <div
                    className={styles.scrollContainer}
                    ref={ref => {
                        this.contentRef = ref
                        contentRef(ref)
                    }}
                    onScroll={this.syncScrollToHeader}
                    onMouseMove={this.handlePopupPosition}
                >
                    <div className={styles.container} style={{ width: totalWidth }}>

                        {/** Сетка на background */}
                        <div
                            className={styles.grid}
                            style={{
                                backgroundSize: `${cellWidth}px 56px, ${cellWidth}px 56px`
                            }}
                        />

                        {/** Основные элементы */}
                        <div className={styles.items}>
                            {itemsToRender.map((item: TimelineItemProps<T> | string) => {
                                if (typeof item === 'string') {
                                    return (
                                        <div
                                            key={item}
                                            className={styles.groupTitle}
                                            style={{ paddingLeft: contentScrollLeft + 12 }}
                                        >
                                            {item}
                                        </div>
                                    )
                                }

                                let innerStyle: CSSProperties = {}
                                const itemStartTime = item.startDate.getTime() - timelineStartTime
                                let itemLeft = itemStartTime / timeInOnePixel
                                if (itemLeft < 0) {
                                    itemLeft = 0
                                    innerStyle.borderTopLeftRadius = 0
                                    innerStyle.borderBottomLeftRadius = 0
                                }

                                const itemEndTime = timelineEndTime - (!item.endDate ? Infinity : item.endDate.getTime())
                                let itemRight = itemEndTime / timeInOnePixel

                                if (itemRight < 0) {
                                    itemRight = 0
                                    innerStyle.borderTopRightRadius = 0
                                    innerStyle.borderBottomRightRadius = 0
                                }

                                // Акция вне диапазона
                                if (itemLeft > totalWidth || itemRight > totalWidth) {
                                    return null
                                }

                                const itemWidth = totalWidth - itemLeft - itemRight

                                // Подстраиваем левый отступ, чтобы видеть текст у акций, ушедших влево за видимую область
                                if (contentScrollLeft > itemLeft) {
                                    const defaultItemPadding = 12
                                    let innerLeftOffset = contentScrollLeft - itemLeft + defaultItemPadding

                                    if (innerLeftOffset > itemWidth - (defaultItemPadding * 2)) {
                                        innerLeftOffset = itemWidth - defaultItemPadding
                                    }
                                    innerStyle.paddingLeft = innerLeftOffset
                                }

                                return (
                                    <TimelineItem
                                        key={item.id}
                                        id={item.id}
                                        icon={item.icon}
                                        label={labelFunction(item)}
                                        secondaryText={secondaryTextFunction(item)}
                                        className={item.className}
                                        innerStyle={innerStyle}
                                        itemLeft={itemLeft}
                                        itemWidth={itemWidth}
                                        onDoubleClick={() => itemOnDoubleClickFunction(item)}
                                        onShowInfoTooltip={() => this.setState({ currentTooltip: item.tooltip })}
                                        onHideInfoTooltip={() => this.setState({ currentTooltip: null })}
                                    />
                                )
                            }).filter(Boolean)}

                            {currentTooltip && (
                                <div
                                    className={styles.customPopup}
                                    style={{
                                        left: this.state.popupX,
                                        top: this.state.popupY,
                                    }}
                                >{currentTooltip}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Paper>
        )
    }
}
