import * as React from 'react'
import moment from 'moment'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import { PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { baseLimitRemote } from '../../../../protocol/set10/base-limit-remote'
import { AlcoholLimitVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/alcohol-limit-vo'
import { fromClientToServerTime } from '../../../utils/app-util'
import { Table } from '@crystalservice/crystals-ui/lib'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { isNil } from 'lodash'

export interface AlcoholLimitsProps {
    productDetailsStore?: ProductDetailsStore
}

export interface AlcoholLimitsState {
    limits: AlcoholLimitVO[]
}

@inject(PRODUCT_DETAILS_STORE)
@observer
export default class AlcoholLimits extends React.Component<AlcoholLimitsProps, AlcoholLimitsState> {

    state: AlcoholLimitsState = {
        limits: null,
    }

    mounted: boolean = false

    componentDidMount(): void {
        this.initAlcoholLimits()
        this.mounted = true
    }

    componentWillUnmount(): void {
        this.mounted = false
    }

    initAlcoholLimits = async (): Promise<void> => {
        const limits = await baseLimitRemote.getAllAlcoholLimits()

        if (!this.mounted) return
        this.setState({ limits })
    }

    render() {
        const { limits } = this.state

        if (!limits) return <PageLoader/>
        if (!limits.length) return <Empty/>

        return (
            <Table
                id="alcoholLimitsList"
                withHeader
                keyField="id"
                items={limits}
                columns={[
                    {
                        header: t('common.name'),
                        labelField: 'name'
                    },
                    {
                        header: t('common.description'),
                        labelFunction: limit => getLimitDescription(limit),
                    },
                ]}
            />
        )
    }
}

const getLimitDescription = (limit: AlcoholLimitVO): string => {
    let description = `${t('productDetails.alcoholLimitsEntrance.alcoholFrom')} ${limit.minPercent}%` +
        ` ${t('productDetails.alcoholLimitsEntrance.bannedForSale')} `

    if ((limit.timeFromStr === '00:00' && limit.timeToStr === '00:00') || (!limit.timeFromStr && !limit.timeToStr)) {
        description += t('productDetails.alcoholLimitsEntrance.allDay')
    } else {
        description += `${t('common.fromStart')} ${limit.timeFromStr} ${t('common.toEnd')} ${limit.timeToStr}`
    }

    if (!isNil(limit.dateFrom) && !isNil(limit.dateTo)) {
        if (limit.dateFrom === limit.dateTo) {
            description += ` ${moment(fromClientToServerTime(limit.dateFrom)).format('DD.MM.YYYY')}`
        }
        else {
            description += ` ${t('productDetails.alcoholLimitsEntrance.inPeriodFrom')}` +
                `${moment(fromClientToServerTime(limit.dateFrom)).format('DD.MM.YYYY')}` +
                `${t('common.toEnd')} ${moment(fromClientToServerTime(limit.dateTo)).format('DD.MM.YYYY')}`
        }
    }
    return description
}
