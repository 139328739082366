import * as React from 'react'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import { PRODUCT_DETAILS_ACTIONS_STORE, PRODUCT_DETAILS_STORE, PRODUCT_RESTRICTIONS_STORE, PRODUCT_SALES_POLICY_STORE } from '../../../store/stores'
import { ProductAutocomplete } from '../../../../components/inputs/autocomplete-input/product-autocomplete'
import { SimpleProduct } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/simple-product'
import { ProductDetailsStore, SECTION_URL } from '../../../store/products/product-details-store'
import { ProductRestrictionsStore } from '../../../store/products/product-restrictions-store'
import { ProductSalesPolicyStore } from '../../../store/products/product-sales-policy-store'
import { ProductDetailsActionsStore } from '../../../store/products/product-details-actions-store'
import { goTo } from '../../../utils/router-util'
import classNames from 'classnames'

const styles = require('./products-search.scss')

export interface ProductsSearchProps extends PaperProps {
    productDetailsStore?: ProductDetailsStore
    productRestrictionsStore?: ProductRestrictionsStore
    productDetailsActionsStore?: ProductDetailsActionsStore
    productSalesPolicyStore?: ProductSalesPolicyStore
}

@inject(PRODUCT_DETAILS_STORE)
@inject(PRODUCT_RESTRICTIONS_STORE)
@inject(PRODUCT_DETAILS_ACTIONS_STORE)
@inject(PRODUCT_SALES_POLICY_STORE)
@observer
export default class ProductsSearch extends React.Component<ProductsSearchProps> {

    componentDidMount(): void {
        const { updateTitle } = this.props.productDetailsStore
        updateTitle()
    }

    handleProductSelect = (suggestion: SimpleProduct) => {
        const { setProduct, goToTarget, reset: resetProductDetailsStore } = this.props.productDetailsStore
        const { reset: resetProductRestrictionsStore } = this.props.productRestrictionsStore
        const { reset: resetProductDetailsActionsStore } = this.props.productDetailsActionsStore
        const { reset: resetProductSalesPolicyStore } = this.props.productSalesPolicyStore

        resetProductDetailsStore()
        resetProductRestrictionsStore()
        resetProductDetailsActionsStore()
        resetProductSalesPolicyStore()

        // ProductAutocomplete с опцией showClearButton при очистке поля вызывает onSuggestionSelect с null
        if (!suggestion) {
            goTo(`${SECTION_URL}`)
            return
        }

        setProduct(suggestion.code, suggestion.name, suggestion.barCode)
        goToTarget()
    }

    render() {
        const { productName, productCode, productBarCode } = this.props.productDetailsStore

        return (
            <Paper
                id="productsSearch"
                className={styles.productsSearch}
            >
                <ProductAutocomplete
                    id="productAutocomplete"
                    autoFocus={!productCode}
                    className={styles.autocomplete}
                    onSuggestionSelect={this.handleProductSelect}
                    shownValue={productName || productBarCode || productCode || ''}
                    showClearButton
                    keepShownValueOnFocus
                />
                <div
                    className={classNames(styles.helperText, {
                        [styles.empty]: !productCode
                    })}
                >
                    {
                        productCode && (
                            `${t('shop.barcode')}: ${productBarCode || ''}   ${t('shop.productCode')}: ${productCode}`
                        )
                    }
                </div>
            </Paper>
        )
    }
}
