export enum FormulaPropertyName {
    PRECISION = 'precision',
    MIN_VALUE = 'minValue',
    MAX_VALUE = 'maxValue',
    ERROR_TEXT = 'errorText',
    PRECISION_TYPE = 'precisionType',
    RETURN_TYPE = 'returnType',
    PLAIN_NUMBER_VALUES = 'plainNumber',
}

export interface XmlFormulaProperty {
    name?: FormulaPropertyName
    value?: string | number
}
