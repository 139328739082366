export enum ESLDrivers {
    ESLHanshow = 'ru.crystals.setretailx.esl.driver.hanshow.ESLHanshow',
    ESLHanshowHTTPS = 'ru.crystals.setretailx.esl.driver.hanshowhttps.ESLHanshowHTTPS',
    ESLSesImagotag = 'ru.crystals.setretailx.esl.driver.sesimagotag.ESLSesImagotag',
    ESLZkong = 'ru.crystals.setretailx.esl.driver.zkonghttp.ESLZkong'
}

type ESLDriverName = 'Hanshow' | 'Ses imagotag' | 'Hanshow HTTPS' | 'Zkong HTTP'
export const ESLHanshow: ESLDriverName = 'Hanshow'
export const ESLHanshowHTTPS: ESLDriverName = 'Hanshow HTTPS'
export const ESLSesImagotag: ESLDriverName = 'Ses imagotag'
export const ESLZkong: ESLDriverName = 'Zkong HTTP'

export enum SesImagotagDriverOptions {
    DOMAIN = 'domain',
    URL = 'url'
}

export enum HanshowDriverOptions {
    FTP_URL = 'ftpurl',
    URL = 'url',
    LOGIN = 'login',
    USER_NAME = 'username',
    PASSWORD = 'password',
    UPLOAD_PATH = 'uploadPath',
    STORE_CODE = 'storeCode',
    CUSTOMER_STORE_CODE = 'customerStoreCode'
}

export enum ZkongHTTPSDriverOptions {
    HOST = 'host',
    ACCOUNT = 'account',
    PASSWORD = 'password',
    AGENCY_ID = 'agencyId',
    MERCHANT_ID = 'merchantId',
    STORE_ID = 'storeId'
}

export const changesCheckingPeriods: number[] = [1, 2, 3, 5, 10, 30, 60]
