import { action, computed, observable, runInAction, toJS } from 'mobx'
import { t } from 'i18next'
import moment from 'moment'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, CARDS_CATEGORY_DETAILS_STORE, USER_STORE, APP_STORE } from '../stores'
import { InternalCardsVO, createInternalCardsVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/internal-cards-vo'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { CARD_CATEGORIES, CARDS, CARDS_SEARCH } from '../../core/app-routes'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import { UserStore } from '../user-store'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { createDisplayColor } from '../../../protocol/set10/set-retail10-commons/data-structs-module/display-color'
import { BonusAccountTypeVO } from '../../../protocol/set10/set-retail10-commons/set-cards-internal-cards/bonus-account-type-vo'
import { isEqual, cloneDeep } from 'lodash'
import { CardRangeVO, createCardRangeVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/card-range-vo'
import { ConditionChangeVO, createConditionChangeVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/condition-change-vo'
import { withSpinner } from '../with-spinner'
import { AppStore } from '../app-store'
import uuid from 'uuid'
import { getUniqueNameWithNumberPostfix2, postfixRegexWithBrackets } from '../../../utils/name-util'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { calculateCardNumberEnd } from '../../core/cards/cards-util'
import { INTERNAL_ERROR, INTERSECTION_RANGES } from '../../core/server-codes'

export enum TabKeys {
    COMMON_SETTINGS = 'COMMON_SETTINGS',
    ACCOUNTS_SETTINGS = 'ACCOUNTS_SETTINGS',
    CARDS_RANGE = 'CARDS_RANGE',
    RULES_OF_TRANSFER = 'RULES_OF_TRANSFER',
}

export class CardsCategoryDetailsStore {

    @observable
    editedCategory: InternalCardsVO = null

    @observable
    activeTabKey: TabKeys = TabKeys.COMMON_SETTINGS

    @observable
    nextActiveTabKey: TabKeys = null

    @observable
    validation: FormValidation<InternalCardsVO> = null

    @observable
    bonusTypesBuffer: Array<{ selected: boolean, bonusType: BonusAccountTypeVO }> = []

    @observable
    ranges: CardRangeVO[] = []

    @observable
    conditions: ConditionChangeVO[] = []

    @observable
    addConditionDialogOpened: boolean = false

    @observable
    selectedConditionOption: number = null

    @observable
    newConditionData: Partial<ConditionChangeVO> = {
        amount: null,
        oneCheck: false,
        operatorMessage: '',
    }

    @observable
    allowOperatorMessage: boolean = false

    allBonusTypes: BonusAccountTypeVO[] = []

    bonusTypesByInternalCard: BonusAccountTypeVO[] = []

    originalBonusTypesBuffer: Array<{ selected: boolean, bonusType: BonusAccountTypeVO }> = []

    originalConditions: ConditionChangeVO[] = []

    @observable
    categories: InternalCardsVO[] = null

    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    @computed
    get modified(): boolean {
        const generalSettingsModified = Boolean(this.validation?.modified)
        const bonusAccountsModified = !isEqual(toJS(this.bonusTypesBuffer), this.originalBonusTypesBuffer)
        const conditionsModified = !isEqual(toJS(this.conditions), this.originalConditions)

        return generalSettingsModified || bonusAccountsModified || conditionsModified
    }

    @computed
    get conditionOptions(): InternalCardsVO[] {
        const conditionOptions = this.categories
            .filter(category => {
                const alreadyExists = this.conditions.some(condition => condition.newCardCategory.id === category.id)

                return !(alreadyExists || category.id === this.editedCategory.id)
            })

        return conditionOptions
    }

    @computed
    get releasedAmount(): number {
        // @ts-ignore
        const rangesAmount: number = Array.from(this.ranges).reduce((acc, currentRange) => acc + currentRange.count[1], 0) ?? 0

        return rangesAmount
    }

    goBack = () => {
        if (this.modified) {
            return this.appStore.showDialog({
                title: t('cardsCategories.categoryNotSavedTitle'),
                message: t('cardsCategories.categoryNotSavedMessage'),
                onYes: this.handleCancel,
                mode: DIALOG
            })
        }

        this.handleCancel()
    }

    handleCancel = () => {
        this.editedCategory = null
        this.activeTabKey = TabKeys.COMMON_SETTINGS
        goTo(`${CARDS}${CARDS_SEARCH}${CARD_CATEGORIES}`)
    }

    updateNavMenu = (): void => {
        const categoryName: string = this.editedCategory?.name || ''
        this.appBarStore.updateState({
            title: categoryName ? `${t('cardsCategories.cardsCategoriesTitle')} - "${categoryName}"` : t('cardsCategories.cardsCategoriesTitle'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: this.goBack
        })
    }

    saveCardCategory = async () => {
        const isNew = this.editedCategory.id === -1

        if (this.activeTabKey === TabKeys.COMMON_SETTINGS || isNew) {
            await this.saveGeneralSettings()
        }

        if (this.activeTabKey === TabKeys.ACCOUNTS_SETTINGS) {
            await this.saveBonusTypes()
        }

        if (this.activeTabKey === TabKeys.RULES_OF_TRANSFER) {
            await this.saveConditions()
        }

        if (this.nextActiveTabKey) {
            this.activeTabKey = this.nextActiveTabKey
        }
    }

    @action
    openCategory = async (id: number) => {
        const isNew = id === -1

        await this.fetchCategories()

        const category = isNew ? this.createCategoryDraft() : this.getEditedCategory(id)

        this.editedCategory = category

        if (!category) return

        this.createValidation({ creation: isNew })
        this.updateNavMenu()
    }

    @action
    setActiveTabKey = (activeTabKey: TabKeys) => {
        this.nextActiveTabKey = activeTabKey

        if (
            this.activeTabKey === TabKeys.COMMON_SETTINGS && Boolean(this.validation?.modified) ||
            this.activeTabKey === TabKeys.ACCOUNTS_SETTINGS && !isEqual(toJS(this.bonusTypesBuffer), this.originalBonusTypesBuffer) ||
            this.activeTabKey === TabKeys.RULES_OF_TRANSFER && !isEqual(toJS(this.conditions), this.originalConditions)
        ) {

            this.appStore.showDialog({
                message: t('cardsCategories.saveTab'),
                noLabel: t('common.cancel'),
                onYes: () => withSpinner(this.saveCardCategory()),
                onNo: () => this.nextActiveTabKey = null,
                mode: DIALOG
            })
        } else {
            this.activeTabKey = activeTabKey
        }
    }

    // GENERAL SETTINGS
    fetchCategories = async (): Promise<void> => {
        const categories: InternalCardsVO[] = await withSpinner(iCardsManagerRemote.getInternalCards(
            this.userStore.session,
            0,
            10000
        ))

        runInAction(() => {
            this.categories = categories || []
        })
    }

    getEditedCategory = (categoryId: number): InternalCardsVO => {
        const editedCategory = this.categories.find(category => category.id === categoryId)

        if (!editedCategory) {
            this.goBack()
        }

        return editedCategory
    }

    createCategoryDraft = () => {
        const name = getUniqueNameWithNumberPostfix2(
            this.categories.map(category => category.name),
            t('cardsCategories.name'),
            postfixRegexWithBrackets,
        )

        return createInternalCardsVO({
            id: -1,
            name,
            color: createDisplayColor({
                red: 52,
                green: 65,
                blue: 241,
            }),
            start: moment().format('YYYY-MM-DD'),
            finish: moment().add(1, 'month').format('YYYY-MM-DD'),
            percentageDiscount: 0,
            withoutFinishDate: false,
            personalized: false,
        })
    }

    @action
    updateCategory = (changes: Partial<InternalCardsVO>): void => {
        Object.keys(changes).forEach(key => {
            this.editedCategory[key] = changes[key]
        })
    }

    @action
    saveGeneralSettings = async () => {
        const category = await iCardsManagerRemote.storeInternalCards(this.userStore.session, toJS(this.editedCategory))

        runInAction(() => {
            this.editedCategory = category
        })
        this.updateNavMenu()
        this.createValidation({ creation: false })
    }

    // BONUS TYPES
    fetchBonusTypes = async (): Promise<void> => {
        const [allBonusTypes, bonusTypesByInternalCard] = await withSpinner(
            Promise.all([
                this.fetchAllBonusTypes(),
                this.fetchBonusTypesByInternalCard(),
            ])
        )

        runInAction(() => {
            this.allBonusTypes = allBonusTypes
            this.bonusTypesByInternalCard = bonusTypesByInternalCard

            const initialBonusTypesBuffer = allBonusTypes.map(bonusType => {
                const selected = bonusTypesByInternalCard.some(el => el.bonusAccountTypeId === bonusType.bonusAccountTypeId)

                return {
                    selected,
                    bonusType
                }
            })

            this.bonusTypesBuffer = initialBonusTypesBuffer
            this.originalBonusTypesBuffer = cloneDeep(initialBonusTypesBuffer)
        })
    }

    @action
    saveBonusTypes = async () => {
        const bonusTypesToEnable = []
        const bonusTypesToDisable = []

        // get bonus types to enable
        for (const currentBonusType of this.bonusTypesBuffer) {
            const { bonusType, selected } = this.originalBonusTypesBuffer.find(({ bonusType }) =>
                bonusType.bonusAccountTypeId === currentBonusType.bonusType.bonusAccountTypeId)

            if (bonusType && currentBonusType.selected && !selected) {
                bonusTypesToEnable.push(bonusType)
            }
        }

        // get bonus types to disable
        for (const currentBonusType of this.bonusTypesBuffer) {
            const { bonusType, selected } = this.originalBonusTypesBuffer.find(({ bonusType }) =>
                bonusType.bonusAccountTypeId === currentBonusType.bonusType.bonusAccountTypeId)

            if (bonusType && !currentBonusType.selected && selected) {
                bonusTypesToDisable.push(bonusType)
            }
        }

        await Promise.all([
            ...bonusTypesToEnable.map(bonusType =>
                iCardsManagerRemote.addBonusTypeToInternalCard(this.userStore.session, bonusType, toJS(this.editedCategory))),
            ...bonusTypesToDisable.map(bonusType =>
                iCardsManagerRemote.removeBonusTypeFromInternalCard(this.userStore.session, bonusType, toJS(this.editedCategory)))
        ])

        this.fetchBonusTypes()
    }

    fetchAllBonusTypes = (): Promise<BonusAccountTypeVO[]> => {
        return withSpinner(iCardsManagerRemote.getAllBonusTypes(this.userStore.session))
    }

    fetchBonusTypesByInternalCard = (): Promise<BonusAccountTypeVO[]> => {
        return withSpinner(iCardsManagerRemote.getBonusTypesByInternalCard(this.userStore.session, toJS(this.editedCategory)))
    }

    @action
    updateBonusTypesByInternalCard = (selected: boolean, bonusType: BonusAccountTypeVO) => {
        this.bonusTypesBuffer = this.bonusTypesBuffer.map(el => {
            if (el.bonusType.bonusAccountTypeId === bonusType.bonusAccountTypeId) {
                return {
                    selected,
                    bonusType
                }
            }

            return el
        })
    }

    // RANGES
    fetchRanges = async (): Promise<void> => {
        const ranges = await withSpinner(iCardsManagerRemote.getCardRanges1(this.userStore.session, toJS(this.editedCategory), 0, 1000)) || []

        runInAction(() => {
            this.ranges = ranges
        })
    }

    isRangeAlreadyUsed = (start: string, finish: string): boolean =>
        this.ranges.some(range =>
            (start.length === range.start.length) &&
            ((start >= range.start && start <= range.finish) || (finish >= range.start && finish <= range.finish)))

    @action
    addRange = async (start: string, count: number, virtual: boolean): Promise<boolean> => {
        const finish = calculateCardNumberEnd(start, Number(count))

        const alreadyExists = this.isRangeAlreadyUsed(start, finish)

        if (alreadyExists) {
            this.appStore.showSnackbar({ message: t('cardsCategories.rangeExists'), variant: 'warning' })
            return false
        } else {
            const cardRange = createCardRangeVO({
                cardType: this.editedCategory,
                start,
                finish,
                // @ts-ignore
                count: ['java.math.BigInteger', Number(count)], // https://crystals.atlassian.net/browse/SFM-208
                virtual,
                deleted: false,
                id: -1,
                guid: null
            })

            try {
                const result = await iCardsManagerRemote.addCardRange1(
                    this.userStore.session,
                    cardRange,
                    {
                        customCommonResponseMiddlewares: [
                            response => {
                                let error: { code?: number, message?: string } = response.data.error
                                if (error && error.code === INTERNAL_ERROR && error.message === INTERSECTION_RANGES) {
                                    this.appStore.showSnackbar(
                                        { message: t('cardsCategories.rangeExists'), variant: 'warning' }
                                    )
                                }
                            },
                        ]
                    }
                )
                runInAction(() => {
                    this.ranges.push(result)
                })
                return true
            } catch {
                return false
            }
        }
    }

    @action
    deleteRange = async (range: CardRangeVO): Promise<void> => {
        await iCardsManagerRemote.removeCardRange1(
            this.userStore.session,
            toJS(range)
        )

        this.fetchRanges()
    }

    // CONDITIONS
    fetchConditions = async (): Promise<void> => {
        const conditions = await withSpinner(iCardsManagerRemote.getConditionChanges(
            this.userStore.session, toJS(this.editedCategory), 0, 1000)
        ) || []

        runInAction(() => {
            this.conditions = conditions
            this.originalConditions = cloneDeep(conditions)
            this.selectedConditionOption = this.conditionOptions[0]?.id
        })
    }

    @action
    saveConditions = async () => {
        const conditionsToDelete: ConditionChangeVO[] = []
        const conditionsToAdd: ConditionChangeVO[] = []

        for (const condition of this.originalConditions) {
            const deleted = this.conditions.find(c => c.id === condition.id)

            if (!deleted) {
                conditionsToDelete.push(condition)
            }
        }

        for (const condition of this.conditions) {
            const exists = this.originalConditions.find(c => c.id === condition.id)

            if (!exists) {
                conditionsToAdd.push(condition)
            }
        }

        await Promise.all([
            ...conditionsToDelete.map(condition => iCardsManagerRemote.removeConditionChange(this.userStore.session, toJS(condition))),
            ...conditionsToAdd.map(({ id, ...condition }) => {
                iCardsManagerRemote.addConditionChange(this.userStore.session, toJS(condition))
            }),
        ])

        setTimeout(() => {
            this.fetchConditions()
        }, 500)
    }

    @action
    openAddConditionDialog = () => {
        this.addConditionDialogOpened = true
    }

    @action
    closeAddConditionDialog = () => {
        this.addConditionDialogOpened = false

        this.resetAddConditionDialog()
    }

    @action
    setSelectedConditionOption = (option: number) => {
        this.selectedConditionOption = option
    }

    @action
    updateNewConditionData = (changes: Partial<ConditionChangeVO>) => {
        Object.keys(changes).forEach(key => {
            this.newConditionData[key] = changes[key]
        })
    }

    @action
    setAllowOperatorMessage = (value: boolean) => {
        this.allowOperatorMessage = value

        this.newConditionData.operatorMessage = ''
    }

    @action
    addCondition = async () => {
        const id = uuid()

        const selectedCondition = this.categories.find(category => category.id === this.selectedConditionOption)

        const condition = createConditionChangeVO({
            amount: this.newConditionData.amount,
            changeCard: false,
            intervalType: 'RELATIVE',
            typeOfComparison: 'MORE',
            oneCheck: this.newConditionData.oneCheck,
            cardCategory: this.editedCategory,
            newCardCategory: selectedCondition,
            // @ts-ignore
            id,
        })

        this.conditions.push(condition)
        this.addConditionDialogOpened = false

        this.resetAddConditionDialog()
    }

    @action
    deleteCondition = async (id: number) => {
        this.conditions = this.conditions.filter(condition => condition.id !== id)
    }

    @action
    resetAddConditionDialog = (): void => {
        this.newConditionData.amount = null
        this.newConditionData.oneCheck = false
        this.newConditionData.operatorMessage = ''
        this.allowOperatorMessage = false
        this.selectedConditionOption = this.conditionOptions[0]?.id
    }

    @action
    reset = (): void => {
        this.editedCategory = null
        this.categories = null
        this.activeTabKey = TabKeys.COMMON_SETTINGS
        this.nextActiveTabKey = null
        this.validation = null
        this.bonusTypesBuffer = []
        this.ranges = []
        this.conditions = []
        this.addConditionDialogOpened = false
        this.selectedConditionOption = null
        this.newConditionData = {
            amount: null,
            oneCheck: false,
            operatorMessage: '',
        }
        this.allowOperatorMessage = false
        this.allBonusTypes = []
        this.bonusTypesByInternalCard = []
        this.originalBonusTypesBuffer = []
        this.originalConditions = []
    }

    @action
    private createValidation = ({ creation }: ({ creation: boolean })): void => {
        this.validation = new FormValidation<InternalCardsVO>(
            this.editedCategory,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                    ]
                }
            ],
            creation,
        )
    }
}
