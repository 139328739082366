import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { FilterInput } from '@crystalservice/crystals-ui/lib/components/inputs/filter-input/filter-input'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { ShopsSearchStore } from '../../../store/shops/shops-search'
import { SHOPS_SEARCH_STORE } from '../../../store/stores'

const styles = require('./shops-search-filters.scss')

export interface ShopsSearchFiltersProps extends React.HTMLProps<HTMLDivElement> {
    shopsSearchStore?: ShopsSearchStore
}

@inject(SHOPS_SEARCH_STORE)
@observer
export class ShopsSearchFilters extends React.Component<ShopsSearchFiltersProps> {

    render() {
        const {
            regions, cities, formats, setRegion, setCity, setFormat, setNameAndNumberFilter,
            selectedRegion, selectedCity, selectedFormat, nameAndNumberFilter,
        } = this.props.shopsSearchStore

        return (
            <Paper>
                <Grid
                    container
                    spacing={2}
                    className={styles.filters}
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <FilterInput
                            id="nameAndNumberFilter"
                            label={t('shopsSearch.nameAndNumberFilter')}
                            value={nameAndNumberFilter}
                            fullWidth
                            onValueChange={setNameAndNumberFilter}
                            withPaper={false}
                        >
                            {formats.map(format => (
                                <MenuItem key={`format${format.id}`} value={format.id}>
                                    {format.name}
                                </MenuItem>
                            ))}
                        </FilterInput>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectInput<DefaultSelectOption<number>>
                            id="regionsSelect"
                            label={t('shopsSearch.regionsSelect')}
                            value={selectedRegion ? selectedRegion.id : regions[0].id}
                            onSelect={selectedOption => setRegion(selectedOption.value)}
                            options={regions.map(region => (
                                {
                                    label: region.name,
                                    value: region.id
                                }
                            ))}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectInput<DefaultSelectOption<number>>
                            id="citiesSelect"
                            disabled={!selectedRegion}
                            label={t('shopsSearch.citiesSelect')}
                            value={selectedCity ? selectedCity.id : cities[0].id}
                            onSelect={selectedOption => setCity(selectedOption.value)}
                            options={cities.map(city => (
                                {
                                    label: city.name,
                                    value: city.id
                                }
                            ))}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SelectInput<DefaultSelectOption<number>>
                            id="formatsSelect"
                            label={t('shopsSearch.formatsSelect')}
                            value={selectedFormat ? selectedFormat.id : formats[0].id}
                            onSelect={selectedOption => setFormat(selectedOption.value)}
                            options={formats.map(format => (
                                {
                                    label: format.name,
                                    value: format.id
                                }
                            ))}
                        />
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}
