import React from 'react'
import { inject, observer } from 'mobx-react'
import { PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { t } from 'i18next'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { Tabs } from '../../../../components/tabs/tabs'
import { ProductGeneralInfo } from './product-general-info'
import { ProductAdditionalInfo } from './product-additional-info'

export interface ProductInfoProps {
    productDetailsStore?: ProductDetailsStore
}

@inject(PRODUCT_DETAILS_STORE)
@observer
export class ProductInfo extends React.Component<ProductInfoProps> {

    render() {
        const { productDetails } = this.props.productDetailsStore

        if (!productDetails) return <PageLoader/>

        return (
            <Tabs
                id="productInfoTabs"
                items={[
                    t('productDetails.generalInfo.general'),
                    t('productDetails.generalInfo.additional'),
                ]}
            >
                <ProductGeneralInfo/>
                <ProductAdditionalInfo/>
            </Tabs>
        )
    }
}
