import { UserVO } from '../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import { FavoriteFiltersByUsers, UserFavoriteFilters } from '../core/filters/favorite-filters'
import { omitBy, isNil } from 'lodash'
import { ProductStatus } from '../core/products/product-statuses'
import { MILLISECONDS_IN_DAY } from '../../utils/date-util'
import { BasketItem } from '../store/price-tags/custom-printing-basket-store'
import { AvailableThemesKeys } from '../core/themes'

const FAVORITE_FILTERS_FIELD: string = 'favoriteFilters'
const CUSTOM_PRINTING_BASKET_FIELD: string = 'customPrintingBasket'
const ACTIONS_SEARCH_COLUMNS_FIELD: string = 'actionsSearchColumns'
const THEME = 'theme'

interface CustomBasketWithTime {
    data: BasketItem[]
    lastUpdateTime: number
}

export class LocalStorageManager {
    getFavoriteFilters(): FavoriteFiltersByUsers {
        const favoriteFiltersEntry: string = localStorage.getItem(FAVORITE_FILTERS_FIELD)
        try {
            return JSON.parse(favoriteFiltersEntry) as FavoriteFiltersByUsers
        } catch (error) {
            return null
        }
    }

    setFavoriteFilters(value: FavoriteFiltersByUsers): void {
        localStorage.setItem(FAVORITE_FILTERS_FIELD, JSON.stringify(value))
    }

    getUserFavoriteFilters(user: UserVO): UserFavoriteFilters {
        const favoriteFilters: FavoriteFiltersByUsers = this.getFavoriteFilters()
        if (!favoriteFilters) return null

        return favoriteFilters[user.login]
    }

    setUserFavoriteFilters(filters: UserFavoriteFilters, user: UserVO): void {
        this.setFavoriteFilters({ ...this.getFavoriteFilters(), [user.login]: filters })
    }

    resetUserFavoriteFilters(user: UserVO): void {
        this.setFavoriteFilters(
            omitBy(
                { ...this.getFavoriteFilters(), [user.login]: null },
                isNil
            )
        )
    }

    // CustomPrinting basket

    getCustomPrintingAllUsersData(status: ProductStatus): {[userLogin: string]: CustomBasketWithTime} {
        const allUsersData: string = localStorage.getItem(CUSTOM_PRINTING_BASKET_FIELD + status)
        try {
            return JSON.parse(allUsersData)
        } catch (error) {
            return null
        }
    }

    setCustomPrintingBaskets(data: BasketItem[], status: ProductStatus, userLogin: string): void {
        const allUsersData = this.getCustomPrintingAllUsersData(status)
        const itemsWithTime: CustomBasketWithTime = {
            data,
            lastUpdateTime: (new Date()).getTime()
        }
        localStorage.setItem(CUSTOM_PRINTING_BASKET_FIELD + status, JSON.stringify({
            ...allUsersData,
            [userLogin]: itemsWithTime
        }))
    }

    getCustomPrintingBaskets(status: ProductStatus, userLogin: string): BasketItem[] {
        const allUsersData = this.getCustomPrintingAllUsersData(status)
        if (!allUsersData) return []

        const basketWithTime = allUsersData[userLogin]
        if (!basketWithTime) return []

        const updatetime = basketWithTime.lastUpdateTime
        const currentDate = new Date()

        // Если с даты последнего обновления прошло больше суток - обнуляем запись
        if ((currentDate.getTime() - updatetime) / MILLISECONDS_IN_DAY > 1) {
            this.clearCustomPrintingBasket(status, userLogin)
            return []
        }

        return basketWithTime.data
    }

    clearCustomPrintingBasket(status: ProductStatus, userLogin: string): void {
        const allUsersData = this.getCustomPrintingAllUsersData(status)
        localStorage.setItem(CUSTOM_PRINTING_BASKET_FIELD + status, JSON.stringify(omitBy(
            {
                ...allUsersData,
                [userLogin]: null
            },
            isNil
        )))
    }

    // Actions search table columns

    getActionsSearchTableColumns(): string[] {
        const actionsSearchTableColumns = localStorage.getItem(ACTIONS_SEARCH_COLUMNS_FIELD)
        return JSON.parse(actionsSearchTableColumns)
    }

    setActionsSearchTableColumns(data: string[]): void {
        localStorage.setItem(ACTIONS_SEARCH_COLUMNS_FIELD, JSON.stringify(data))
    }

    removeActionsSearchTableColumns(): void {
        localStorage.removeItem(ACTIONS_SEARCH_COLUMNS_FIELD)
    }

    setTheme(theme: AvailableThemesKeys): void {
        localStorage.setItem(THEME, theme)
    }

    getTheme(): AvailableThemesKeys {
        return localStorage.getItem(THEME) as AvailableThemesKeys
    }

    clearAll = (): void => {
        localStorage.clear()
    }
}

export const localStorageManager = new LocalStorageManager()
