import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { ProductAutocomplete } from '../../../../../../../../components/inputs/autocomplete-input/product-autocomplete'
import { CounterResultData } from '../../../../../../../core/advertising-actions/advertising-actions'
import { createProductFilterList } from '../../../../../../../../protocol/set10/set-retail10-commons/data-structs-module/product-filter-list'
import { productManagerFinderLocal } from '../../../../../../../../protocol/set10/product-manager-finder-local'

const styles = require('./counter-products.scss')
const LIMIT = 20

interface CounterProductsTableProps {
    markings: string[]
    onAddMarking: (opts: CounterResultData) => void
    onRemoveMarking: (identifier: string) => void
    readOnly: boolean
}

interface CounterTableProduct {
    identifier: string
    name: string
}

export const CounterProducts = (props: CounterProductsTableProps) => {
    const [tableMarkings, setTableMarkings] = useState([])
    useEffect(() => {
        formatExistingProducts()
    }, [props.markings])

    const handleAddItem = (identifier: string): void => {
        if (props.markings.includes(identifier)) return

        props.onAddMarking({ markings: [{ String: [...props.markings, identifier] }] })
    }

    const formatExistingProducts = async () => {
        let products = []

        if (props.markings.length > 0) {
            products = await productManagerFinderLocal.getSimpleProductsByFilterList(
                '',
                createProductFilterList({ item: props.markings }),
                0,
                LIMIT,
            )
        }

        const markings: CounterTableProduct[] = products.map(product => ({ identifier: product.identifier, name: product.name }))
        setTableMarkings(markings)
    }

    return (
        <div className={styles.counterProducts} id="counterProducts">
            <ProductAutocomplete
                placeholder={t('components.autocomplete.productPlaceholder')}
                onSuggestionSelect={product => handleAddItem(product.identifier)}
                selectedProductsIdentifiers={props.markings}
                disabled={props.readOnly}
            />
            <Table
                withHeader
                id="counterProductsTable"
                keyFunction={(item: CounterTableProduct) => `${item.identifier}-${item.name}`}
                columns={[
                    {
                        header: t('advertisingActions.countersResults.productsTableIdentifier'),
                        labelFunction: data => data.identifier,
                    },
                    {
                        header: t('advertisingActions.countersResults.productsTableName'),
                        labelFunction: data => data.name,
                    },
                    {
                        renderer: ({ item }: { item: CounterTableProduct }) => {
                            if (props.readOnly) return ''
                            return (
                                <Tooltip title={t('common.remove')}>
                                    <IconButton
                                        id={`${item.identifier}DeleteButton`}
                                        onClick={() => props.onRemoveMarking(item.identifier)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </Tooltip>
                            )
                        },
                        showOnRowHover: true,
                        withControls: true,
                    },
                ]}
                items={tableMarkings}
            />
            {!tableMarkings.length && (
                <Empty id="counterProductsEmpty" />
            )}
        </div>
    )
}
