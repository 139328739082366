import React from 'react'
import { t } from 'i18next'
import { ConditionEditorProps } from '../types'
import { inject, observer } from 'mobx-react'
import { USER_STORE, APP_STORE } from '../../../../../../store/stores'
import { UserStore } from '../../../../../../store/user-store'
import {
    findCondition, CommonConditions, removeCondition, updateCondition
} from '../../../../../../core/advertising-actions/action-conditions'
import { externalSystemsManagerRemote } from '../../../../../../../protocol/set10/external-systems-manager-remote'
import { AppStore } from '../../../../../../store/app-store'
import { ServiceProviderVO } from '../../../../../../../protocol/set10/set-retail10-server/retailx/server-ds/service-provider-vo'
import { getStore } from '../../../../../../store/stores-repository'
import { get } from 'lodash'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { NOT_SPECIFIED } from '../../../../../../core/values'
import { PLUGIN } from '../../../../../../../protocol/set10/set-retail10-server/retailx/server-ds/service-provider-type-vo'
import { pluginsManager } from '../../../../../../../protocol/set10/plugins-manager'

interface ExternalSystem {
    providerName: string[]
}

const EXT_LOYALTY = 'ext-loyalty'

const getRegisteredServiceProviders = async (session: string, locale: string): Promise<ServiceProviderVO[]> => {
    const result = await externalSystemsManagerRemote.getRegisteredServiceProviders(
        session,
        EXT_LOYALTY,
        locale,
        {
            useCache: true
        }
    )

    return result || []
}

// Получение текста для чипа
export const getExternalSystemChipLabel = async (condition: ExternalSystem): Promise<string> => {
    const user = getStore<UserStore>(USER_STORE)
    const app = getStore<AppStore>(APP_STORE)
    const services = await getRegisteredServiceProviders(user.session, app.locale)

    const name: string = get(condition, 'providerName[0]')
    const service = services.find(item => item.name === name)

    return `${t('advertisingActions.common.externalSystems')}: ${service?.registeredName}`
}

interface ExternalSystemEditorProps extends ConditionEditorProps {
    user?: UserStore
    app?: AppStore
}

interface ExternalSystemOption {
    label: string
    value: string
}

interface ExternalSystemEditorState {
    options: ExternalSystemOption[]
}

@inject(USER_STORE)
@inject(APP_STORE)
@observer
export class ExternalSystemEditor extends React.Component<ExternalSystemEditorProps, ExternalSystemEditorState> {

    mounted: boolean

    state: ExternalSystemEditorState = {
        options: []
    }

    componentDidMount() {
        this.mounted = true
        this.fetchServices()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    fetchServices = async (): Promise<void> => {
        const { session } = this.props.user
        const { locale } = this.props.app

        const services = await getRegisteredServiceProviders(session, locale)
        const plugins = await pluginsManager.getLightweightAdvertisingActionConditionPlugins() || []

        const options: ExternalSystemOption[] = [
            {
                label: t('common.notSpecified'),
                value: NOT_SPECIFIED
            },
            ...services
                .filter(service => service.type !== PLUGIN)
                .map(service => ({
                    label: service.localizedName,
                    value: service.name
                })),
            ...plugins
                .map(plugin => ({
                    label: plugin.name,
                    value: plugin.id
                }))
        ]

        if (this.mounted) {
            this.setState({ options })
        }
    }

    handleUpdateCondition = (providerName: string): void => {
        const { value, onChange } = this.props

        if (providerName === NOT_SPECIFIED) {
            onChange(removeCondition(CommonConditions.ExternalSystemResolutionCondition, value))
            return
        }

        const newCondition: ExternalSystem = {
            providerName: [providerName]
        }

        onChange(updateCondition(
            CommonConditions.ExternalSystemResolutionCondition,
            newCondition,
            value
        ))
    }

    render() {
        const { options } = this.state
        const condition = findCondition(CommonConditions.ExternalSystemResolutionCondition, this.props.value)
        const externalSystem: ExternalSystem = condition && condition.data

        const value = get(externalSystem, 'providerName[0]') || NOT_SPECIFIED

        return (
            <div style={{ padding: 24 }}>
                <SelectInput<ExternalSystemOption>
                    id="externalSystem"
                    style={{ maxWidth: 240 }}
                    label={t('advertisingActions.common.externalSystem')}
                    options={options}
                    value={value}
                    disabled={this.props.readOnly}
                    onSelect={option => this.handleUpdateCondition(option.value)}
                />
            </div>
        )
    }
}
