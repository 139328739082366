import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class CurrencyManager extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Register/CurrencyManager!ru.crystals.setregister.CurrencyManager'
    }

    getCurrentCurrencyCode = (sessionId: string, options?: RequestOptions): Promise<string> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getCurrentCurrencyCode',
                params: [sessionId]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const currencyManager: CurrencyManager = new CurrencyManager()
