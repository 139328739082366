import { PrintReason } from './print-reason'
import { PrintScheduleType } from './print-schedule-type'
import { TimeInterval } from './time-interval'

export interface ConditionalPrintingScheduleVO {
    '@class': 'ru.crystals.setretailx.printing.directive.vo.ConditionalPrintingScheduleVO'
    id?: number
    uuid?: string
    topologyId?: number
    name?: string
    reasons?: PrintReason[]
    days?: number[]
    type?: PrintScheduleType
    printTime?: number
    reasonInterval?: TimeInterval
    intervalStep?: number
    periodInterval?: TimeInterval
    filterForStock?: boolean
}

export const createConditionalPrintingScheduleVO = (initialization: Partial<ConditionalPrintingScheduleVO>): ConditionalPrintingScheduleVO => {
    if (!initialization) return null
    
    let result: ConditionalPrintingScheduleVO = {
        id: initialization.id,
        uuid: initialization.uuid,
        topologyId: initialization.topologyId,
        name: initialization.name,
        reasons: initialization.reasons,
        days: initialization.days,
        type: initialization.type,
        printTime: initialization.printTime,
        reasonInterval: initialization.reasonInterval,
        intervalStep: initialization.intervalStep,
        periodInterval: initialization.periodInterval,
        filterForStock: initialization.filterForStock,
        '@class': 'ru.crystals.setretailx.printing.directive.vo.ConditionalPrintingScheduleVO',
    }
    
    return result
}
