export interface EquipmentSettingVO {
    '@class': 'ru.crystals.setretailx.equipment.EquipmentSettingVO'
    id?: number
    name?: string
    value?: string
    uuid?: string
}

export const createEquipmentSettingVO = (initialization: Partial<EquipmentSettingVO>): EquipmentSettingVO => {
    if (!initialization) return null
    
    let result: EquipmentSettingVO = {
        id: initialization.id,
        name: initialization.name,
        value: initialization.value,
        uuid: initialization.uuid,
        '@class': 'ru.crystals.setretailx.equipment.EquipmentSettingVO',
    }
    
    return result
}
