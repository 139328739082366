import { isNil } from 'lodash'
import { radToDeg } from './math-util'

export class Vector2d {
    constructor(public x: number = 0, public y: number = 0) {
        if (isNil(x)) this.x = 0
        if (isNil(y)) this.y = 0
    }

    add = (vector: Vector2d): Vector2d => {
        return new Vector2d(this.x + vector.x, this.y + vector.y)
    }

    subtract = (vector: Vector2d): Vector2d => {
        return new Vector2d(this.x - vector.x, this.y - vector.y)
    }

    multiplyByScalar = (multiplier: number): Vector2d => {
        return new Vector2d(this.x * multiplier, this.y * multiplier)
    }

    divideByScalar = (delimiter: number): Vector2d => {
        if (delimiter === 0) return new Vector2d()
        return new Vector2d(this.x / delimiter, this.y / delimiter)
    }

    dot = (vector: Vector2d): number => {
        return this.x * vector.x + this.y * vector.y
    }

    cross = (vector: Vector2d): number => {
        return this.x * vector.y - this.y * vector.x
    }

    normalize = (): Vector2d => {
        const length = this.length
        if (length === 0) return new Vector2d()
        return new Vector2d(this.x / length, this.y / length)
    }

    flip = (): Vector2d => {
        return new Vector2d(-this.x, -this.y)
    }

    rightNormal = (): Vector2d => {
        return new Vector2d(-this.y, this.x)
    }

    leftNormal = (): Vector2d => {
        return new Vector2d(this.y, -this.x)
    }

    clone = (): Vector2d => {
        return new Vector2d(this.x, this.y)
    }

    rotate = (angle: number): Vector2d => {
        return new Vector2d(
            this.x * Math.cos(angle) - this.y * Math.sin(angle),
            this.x * Math.sin(angle) + this.y * Math.cos(angle)
        )
    }

    sameDirection = (vector: Vector2d): boolean => {
        return this.dot(vector) > 0
    }

    isOrthogonal = (vector: Vector2d): boolean => {
        return this.dot(vector) === 0
    }

    projectionOn = (vector: Vector2d): Vector2d => {
        if (vector.length === 0) {
            return new Vector2d()
        }
        const vectorLength = vector.length
        return vector.multiplyByScalar(this.dot(vector) / vectorLength ** vectorLength)
    }

    angleFrom = (vector: Vector2d): number => {
        return Math.atan2(vector.y, vector.x) - Math.atan2(this.y, this.x)
    }

    equals = (vector: Vector2d): boolean => {
        return this.x === vector.x && this.y === vector.y
    }

    isZero = (): boolean => {
        return this.x === 0 && this.y === 0
    }

    get angle(): number {
        return Math.atan2(this.y, this.x)
    }

    get length(): number {
        return Math.sqrt(this.x ** 2 + this.y ** 2)
    }

    toString(): string {
        return `{ ${this.x}, ${this.y} }`
    }
}

export class Matrix2d {
    constructor(
        public a: number = 1,
        public b: number = 0,
        public c: number = 0,
        public d: number = 1,
        public tx: number = 0,
        public ty: number = 0
    ) {
        if (isNil(a)) this.a = 1
        if (isNil(b)) this.b = 0
        if (isNil(c)) this.c = 0
        if (isNil(d)) this.d = 1
        if (isNil(tx)) this.tx = 0
        if (isNil(ty)) this.ty = 0
    }

    toCSS = (): string => {
        const { a, b, c, d, tx, ty } = this
        return `matrix(${a}, ${b}, ${c}, ${d}, ${tx}, ${ty})`
    }

    toString = (): string => {
        const { a, b, c, d, tx, ty } = this
        return `\n[${a}, ${c}, ${tx}]\n[${b}, ${d}, ${ty}]`
    }

    multiply = (matrix: Matrix2d): Matrix2d => {
        /*
        [a1, c1, tx1]   [a2, c2, tx2]
        [b1, d1, ty1] x [b2, d2, ty2]
        [0,   0,   1]   [0 ,  0,   1]
         */
        const { a: a1, b: b1, c: c1, d: d1, tx: tx1, ty: ty1 } = this
        const { a: a2, b: b2, c: c2, d: d2, tx: tx2, ty: ty2 } = matrix

        return new Matrix2d(
            a1 * a2 + c1 * b2,
            b1 * a2 + d1 * b2,
            a1 * c2 + c1 * d2,
            b1 * c2 + d1 * d2,
            a1 * tx2 + c1 * ty2 + tx1,
            b1 * tx2 + d1 * ty2 + ty1
        )
    }

    translate = (tx: number, ty: number): Matrix2d => {
        return new Matrix2d(1, 0, 0, 1, tx, ty).multiply(this)
    }

    rotate = (angle: number): Matrix2d => {
        return new Matrix2d(Math.cos(angle), Math.sin(angle), -Math.sin(angle), Math.cos(angle)).multiply(
            this
        )
    }

    scale = (scaleX: number, scaleY: number): Matrix2d => {
        return new Matrix2d(scaleX, 0, 0, scaleY).multiply(this)
    }

    clone = (): Matrix2d => {
        const { a, b, c, d, tx, ty } = this
        return new Matrix2d(a, b, c, d, tx, ty)
    }
}

export class ConvertedMatrix2d {
    constructor(
        public x: number = 0,
        public y: number = 0,
        public scaleX: number = 0,
        public scaleY: number = 0,
        public rotation: number = 0
    ) {
        if (isNil(x)) this.x = 0
        if (isNil(y)) this.y = 0
        if (isNil(scaleX)) this.scaleX = 0
        if (isNil(scaleY)) this.scaleY = 0
        if (isNil(rotation)) this.rotation = 0
    }

    toString = (): string => {
        const { x, y, scaleX, scaleY, rotation } = this
        return `{ x: ${x}, y: ${y}, scaleX: ${scaleX}, scaleY: ${scaleY}, rotation: ${radToDeg(rotation)} }`
    }
}

export function convertMatrix(matrix: Matrix2d): ConvertedMatrix2d {
    const { a, b, c, d, tx, ty } = matrix

    const scaleX = Math.sqrt(a ** 2 + c ** 2)
    const scaleY = Math.sqrt(b ** 2 + d ** 2)
    const sign = Math.atan(-c / a)
    let rotation = Math.acos(a / scaleX)

    if (rotation > Math.PI / 2 && sign > 0) {
        rotation = Math.PI * 2 - rotation
    } else if (rotation < Math.PI / 2 && sign < 0) {
        rotation = Math.PI * 2 - rotation
    }

    return new ConvertedMatrix2d(tx, ty, scaleX, scaleY, rotation)
}

export interface Rect {
    x: number
    y: number
    width: number
    height: number
}

export function rectsOverlap(rect1: Rect, rect2: Rect): boolean {
    return (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
    )
}
