import { observable, runInAction, computed, action, toJS } from 'mobx'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import uuid from 'uuid'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, PRICE_TAG_FORMAT_SETTINGS_STORE } from '../stores'
import {
    FormatsProductListVO,
    createFormatsProductListVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/formats-product-list-vo'
import { formatsProductListPresenterLocal } from '../../../protocol/set10/formats-product-list-presenter-local'
import { getUniqueNameWithNumberPostfix } from '../../../utils/name-util'
import { goTo } from '../../utils/router-util'
import { NOT_FOUND } from '../../core/app-routes'
import { PriceTagFormatSettingsStore } from './price-tag-format-settings-store'
import { withSpinner } from '../with-spinner'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import {
    ProductEntityType,
    PRODUCT,
    PRODUCTS_GROUP,
    PRODUCT_TYPE, BoundProductEntity
} from '../../components/product-entities-binder/product-entities-binder'
import {
    ProductListConditionType,
    PRODUCT_TYPE as PRODUCT_LIST_PRODUCT_TYPE,
    PRODUCTS_GROUP as PRODUCT_LIST_PRODUCTS_GROUP,
    PRODUCT_CODES as PRODUCT_LIST_PRODUCT_CODES
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/product-list-condition-type'
import {
    ProductListConditionVO,
    createProductListConditionVO
} from '../../../protocol/set10/set-retail10-server/retailx/set-template-formats/product-list-condition-vo'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'

export class PriceTagsProductsListsStore {

    @observable
    productLists: FormatsProductListVO[] = null

    @observable
    editedProductsList: FormatsProductListVO = null

    @observable
    editedProductsListValidation: FormValidation<FormatsProductListVO> = null

    @computed
    get boundEntities(): BoundProductEntity[] {
        if (!this.editedProductsList || !this.editedProductsList.conditions) return []

        return this.editedProductsList.conditions.map((condition: ProductListConditionVO) => {
            return {
                type: productListTypeToBinderEntity(condition.type),
                value: null,
                label: condition.name,
                code: condition.value,
            }
        })
    }

    @computed
    get editedProductsListChanged(): boolean {
        if (!this.editedProductsList) return false

        const originalItem: FormatsProductListVO = this.productLists.find(pl => pl.id === this.editedProductsList.id)

        // если это новый список, то edited будет true
        if (!originalItem) return true

        return !isEqual(toJS(originalItem), toJS(this.editedProductsList))
    }

    private appStore: AppStore = getStore(APP_STORE)
    private priceTagFormatSettingsStore: PriceTagFormatSettingsStore = getStore(PRICE_TAG_FORMAT_SETTINGS_STORE)

    fetchProductLists = async (): Promise<void> => {
        const productLists = await formatsProductListPresenterLocal.loadAll(this.priceTagFormatSettingsStore.formatTopology.id)

        runInAction(() => {
            this.productLists = productLists || []
        })
    }

    @action
    changePriority = async (itemId: number, prioritySummand: number): Promise<void> => {

        const sortedByPriorityLists: FormatsProductListVO[] = toJS(this.productLists).sort((plA, plB) => {
            return plA.priority - plB.priority
        })

        // замещающий
        let replacingItemIndex: number = sortedByPriorityLists.findIndex(productList => productList.id === itemId)

        // замещаемый
        let replacedItem: FormatsProductListVO = sortedByPriorityLists[replacingItemIndex + prioritySummand]

        if (replacingItemIndex === -1 || !replacedItem) return

        const replacedItemPriority: number = replacedItem.priority

        replacedItem.priority = sortedByPriorityLists[replacingItemIndex].priority
        sortedByPriorityLists[replacingItemIndex].priority = replacedItemPriority

        sortedByPriorityLists.sort((plA, plB) => {
            return plA.priority - plB.priority
        })

        await withSpinner(async () => {
            await formatsProductListPresenterLocal.update(sortedByPriorityLists)
            await this.fetchProductLists()
        })

        this.appStore.showSnackbar({ message: t('priceTagsFormats.productLists.priorityRecalculatedHint') })
    }

    deleteProductList = async (itemId: number): Promise<void> => {
        this.appStore.showDialog({
            title:  t('priceTagsFormats.productLists.deleteProductListTitle'),
            message: t('priceTagsFormats.productLists.deleteProductListHint'),
            mode: DIALOG,
            onYes: async () => {
                const itemToDelete: FormatsProductListVO = this.productLists.find(pl => pl.id === itemId)
                if (!itemToDelete) return

                const deletedName: string = itemToDelete.name

                await withSpinner(async () => {
                    await formatsProductListPresenterLocal.delete(toJS(itemToDelete))
                    await this.fetchProductLists()
                })

                this.appStore.showSnackbar({ message: t('priceTagsFormats.productLists.productListDeleted', { name: deletedName }) })
            },
            onNo: () => null
        })
    }

    resetSettings = async (): Promise<void> => {
        await withSpinner(async () => {
            let productListFromCentrum = await formatsProductListPresenterLocal.requestFromCentrum(this.editedProductsList.uuid)
            await this.fetchProductLists()

            runInAction(() => {
                this.editedProductsList = productListFromCentrum
            })
        })

        const { name } = this.editedProductsList
        this.appStore.showSnackbar({ message: t('priceTagsFormats.productLists.settingsRestored', { name }) })
    }

    @action
    setConditionsFromEntities = (newEntities: BoundProductEntity[]): void => {
        this.updateEditedProductsList({
            conditions: newEntities.map(entity => {
                return createProductListConditionVO({
                    // на сервер нужно отправлять именно id -1
                    id: -1,
                    productListId: this.editedProductsList.id,
                    type: binderEntityToProductListType(entity.type as ProductEntityType),
                    value: entity.code as string,
                    name: entity.label
                })
            })
        })
    }

    @action
    createNewProductList = (): void => {
        this.setEditedProductsList(createEmptyProductList(this.productLists || []))
        this.editedProductsList.topologyId = this.priceTagFormatSettingsStore.formatTopology.id
        this.createValidation()
    }

    @action
    finishEditing = (): void => {
        this.editedProductsListValidation = null
        this.setEditedProductsList(null)
    }

    editProductList = async (productListId: number): Promise<void> => {
        if (!this.productLists) {
            return
        }

        const requestedProductsList: FormatsProductListVO
            = toJS(this.productLists).find(productList => productList.id === productListId)

        if (!requestedProductsList) {
            goTo(NOT_FOUND)
            return
        }

        this.setEditedProductsList(requestedProductsList)
        this.createValidation()
    }

    @action
    createValidation = (): void => {
        this.editedProductsListValidation = new FormValidation<FormatsProductListVO>(
            this.editedProductsList,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        fieldLengthValidator({ max: 255 }),
                        uniqueField(this.productLists
                            .filter(pl => pl.id !== this.editedProductsList.id)
                            .map(pl => pl.name)
                        )
                    ]
                },
                {
                    field: 'description',
                    rules: [
                        fieldLengthValidator({ max: 255 })
                    ]
                },
            ],
            false
        )
    }

    saveEditedProductList = async (): Promise<void> => {
        await withSpinner(async () => {
            await formatsProductListPresenterLocal.save(toJS(this.editedProductsList))
            await this.fetchProductLists()
        })

        const savedName: string = this.editedProductsList.name

        this.finishEditing()

        this.appStore.showSnackbar({ message: t('priceTagsFormats.productLists.productListSaved', { name: savedName }) })
    }

    @action
    setEditedProductsList = (editedProductsList: FormatsProductListVO): void => {
        this.editedProductsList = editedProductsList
    }

    @action
    updateEditedProductsList = (changes: Partial<FormatsProductListVO>): void => {
        if (!this.editedProductsList) return

        Object.keys(changes).forEach(key => {
            this.editedProductsList[key] = changes[key]
        })
    }

    @action
    reset = (): void => {
        this.productLists = null
        this.editedProductsList = null
        this.editedProductsListValidation = null
    }
}

function createEmptyProductList(others: FormatsProductListVO[]): FormatsProductListVO {
    return createFormatsProductListVO({
        id: -1,
        name: getUniqueNameWithNumberPostfix(t('priceTagsFormats.productLists.newProductsList'), others, 'name'),
        uuid: uuid(),
        priority: others.length,
        conditions: []
    })
}

function productListTypeToBinderEntity(productListType: ProductListConditionType): ProductEntityType {
    switch (productListType) {
        case PRODUCT_LIST_PRODUCT_TYPE:
            return PRODUCT_TYPE
        case PRODUCT_LIST_PRODUCTS_GROUP:
            return PRODUCTS_GROUP
        case PRODUCT_LIST_PRODUCT_CODES:
            return PRODUCT
        default:
            return null
    }
}

function binderEntityToProductListType(binderEntity: ProductEntityType): ProductListConditionType {
    switch (binderEntity) {
        case PRODUCT_TYPE:
            return PRODUCT_LIST_PRODUCT_TYPE
        case PRODUCTS_GROUP:
            return PRODUCT_LIST_PRODUCTS_GROUP
        case PRODUCT:
            return PRODUCT_LIST_PRODUCT_CODES
        default:
            return null
    }
}
