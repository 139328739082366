import React from 'react'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Add from '@material-ui/icons/Add'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { NEW } from '../../../core/values'
import { ShopsSearchStore } from '../../../store/shops/shops-search'
import { SHOP_STORE, SHOPS_SEARCH_STORE } from '../../../store/stores'
import { ShopStore } from '../../../store/shops/shop-settings/shop-store'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { ShopsSearchList } from './shops-search-list'
import { ShopsSearchFilters } from './shops-search-filters'
import Fab from '@material-ui/core/Fab'

const styles = require('./shops-search.scss')

export interface ShopsSearchProps extends React.HTMLProps<HTMLDivElement> {
    shopsSearchStore?: ShopsSearchStore
    shopStore?: ShopStore
}

@inject(SHOP_STORE)
@inject(SHOPS_SEARCH_STORE)
@observer
export class ShopsSearch extends React.Component<ShopsSearchProps> {

    componentDidMount() {
        this.props.shopsSearchStore.fetchTopologyMap()
    }

    handleAdd = (): void => {
        const { addDialogSelectedCity, closeAddShopDialog } = this.props.shopsSearchStore
        const { openShop } = this.props.shopStore
        openShop(NEW, addDialogSelectedCity)
        closeAddShopDialog()
    }

    getAddShopDialog = () => {
        const {
            closeAddShopDialog, addDialogSelectedCity, addDialogSelectedRegion, addDialogCities, addDialogRegions,
            setAddDialogCity, setAddDialogRegion, addShopDialogOpen
        } = this.props.shopsSearchStore
        return (
            <Dialog
                id="addShopDialog"
                open={addShopDialogOpen}
                onClose={closeAddShopDialog}
            >
                <DialogTitle>{t('shopsSearch.addShopDialogTitle')}</DialogTitle>
                <DialogContent className={styles.addDialog}>
                    <DialogContentText className={styles.message}>
                        {t('shopsSearch.addShopDialogMessage')}
                    </DialogContentText>
                    <SelectInput<DefaultSelectOption<number>>
                        id="addDialogRegionsSelect"
                        label={t('shopsSearch.regionsSelect')}
                        disableTouchLogic
                        errorText={addDialogRegions.length === 0 ? t('shopsSearch.emptyRegions') : null}
                        value={addDialogSelectedRegion ? addDialogSelectedRegion.id : ''}
                        onSelect={selectedOption => setAddDialogRegion(selectedOption.value)}
                        options={addDialogRegions.map(region => (
                            {
                                label: region.name,
                                value: region.id
                            }
                        ))}
                    />
                    <SelectInput<DefaultSelectOption<number>>
                        id="addDialogCitiesSelect"
                        disabled={!addDialogSelectedRegion || addDialogCities.length === 0}
                        label={t('shopsSearch.citiesSelect')}
                        disableTouchLogic
                        errorText={addDialogCities.length === 0 ? t('shopsSearch.emptyCities') : null}
                        value={addDialogSelectedCity ? addDialogSelectedCity.id : ''}
                        onSelect={selectedOption => setAddDialogCity(selectedOption.value)}
                        options={addDialogCities.map(city => (
                            {
                                label: city.name,
                                value: city.id
                            }
                        ))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        id="addShopCancelButton"
                        color="primary"
                        onClick={closeAddShopDialog}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        id="addShopConfirmButton"
                        onClick={this.handleAdd}
                        color="primary"
                        disabled={!addDialogSelectedCity}
                    >
                        {t('common.create')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const { openAddShopDialog } = this.props.shopsSearchStore

        return (
            <div
                id="shopsSearchPage"
                className={styles.shopsSearchPage}
            >
                { this.getAddShopDialog() }
                <ShopsSearchFilters />
                <ShopsSearchList />

                <Tooltip
                    enterDelay={TOOLTIP_DELAY}
                    title={t('shopsSearch.addShop')}
                >
                    <div className={styles.addButtonContainer}>
                        <Fab
                            id="addShopButton"
                            onClick={openAddShopDialog}
                            color="primary"
                        >
                            <Add/>
                        </Fab>
                    </div>
                </Tooltip>
            </div>
        )
    }
}
