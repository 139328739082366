import React from 'react'
import { RouteScheme } from '../utils/route-modules-util'
import {lazy} from '../utils/code-splitting'
import Settings from '@material-ui/icons/Settings'
import { t } from 'i18next'
import { config } from '../config/config'
import { SETRETAILX_COMMONSETUP_ADMIN } from '../core/privileges/privileges'
import { SETTINGS } from '../core/app-routes'

export const settingsRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'settingsNavLink',
        label: t('set10.settings'),
        icon: <Settings/>,
    },
    privileges: [SETRETAILX_COMMONSETUP_ADMIN],
    path: SETTINGS,
    component: lazy(
        () => import(/* webpackChunkName: "common-settings" */ '../pages/other/common-settings/common-settings'),
    )
})
