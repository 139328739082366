import { createTopologyNodeVO } from './topology-node-vo'
import { TopologyNodeVO } from './topology-node-vo'
import { RegionVO } from './region-vo'

export interface CityVO extends TopologyNodeVO {
    '@class': 'ru.crystals.setretailx.topology.CityVO'
    name?: string
    timeZone?: string
    region?: RegionVO
    shopsCount?: number
}

export const createCityVO = (initialization: Partial<CityVO>): CityVO => {
    if (!initialization) return null
    
    let result: CityVO = {
        ...createTopologyNodeVO(initialization),
        name: initialization.name,
        timeZone: initialization.timeZone,
        region: initialization.region,
        shopsCount: initialization.shopsCount,
        '@class': 'ru.crystals.setretailx.topology.CityVO',
    }
    
    return result
}
