import { createTopologyNodeVO } from './topology-node-vo'
import { TopologyNodeVO } from './topology-node-vo'
import { CityVO } from './city-vo'
import { FormatVO } from './format-vo'
import { JuristicPersonVO } from './juristic-person-vo'
import { PhysicalAdressShopVO } from './physical-adress-shop-vo'
import { TopologyVersion } from './topology-version'

export interface ShopVO extends TopologyNodeVO {
    '@class': 'ru.crystals.setretailx.topology.ShopVO'
    number?: number
    name?: string
    twentyFourHour?: boolean
    city?: CityVO
    format?: FormatVO
    juristicPersons?: JuristicPersonVO[]
    physicalAdress?: PhysicalAdressShopVO
    centrumUrl?: string
    version?: TopologyVersion
    address?: string
    virtual?: boolean
}

export const createShopVO = (initialization: Partial<ShopVO>): ShopVO => {
    if (!initialization) return null
    
    let result: ShopVO = {
        ...createTopologyNodeVO(initialization),
        number: initialization.number,
        name: initialization.name,
        twentyFourHour: initialization.twentyFourHour,
        city: initialization.city,
        format: initialization.format,
        juristicPersons: initialization.juristicPersons,
        physicalAdress: initialization.physicalAdress,
        centrumUrl: initialization.centrumUrl,
        version: initialization.version,
        address: initialization.address,
        virtual: initialization.virtual,
        '@class': 'ru.crystals.setretailx.topology.ShopVO',
    }
    
    return result
}
