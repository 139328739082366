import { RefundRequestPositionVO } from './refund-request-position-vo'

export interface RefundRequestVO {
    '@class': 'ru.crystals.report.vo.RefundRequestVO'
    typeRequest?: string
    clientRequest?: string
    refundReason?: string
    refundAction?: string
    refundNumber?: number
    cashNumber?: number
    shiftNumber?: number
    purchaseNumber?: number
    purchaseId?: number
    purchaseDate?: Date
    paymentType?: string
    refundDate?: Date
    fullCount?: number
    fullAmount?: number
    userId?: number
    fullRefund?: boolean
    shopName?: string
    shopAddress?: string
    positions?: RefundRequestPositionVO[]
}

export const createRefundRequestVO = (initialization: Partial<RefundRequestVO>): RefundRequestVO => {
    if (!initialization) return null
    
    let result: RefundRequestVO = {
        typeRequest: initialization.typeRequest,
        clientRequest: initialization.clientRequest,
        refundReason: initialization.refundReason,
        refundAction: initialization.refundAction,
        refundNumber: initialization.refundNumber,
        cashNumber: initialization.cashNumber,
        shiftNumber: initialization.shiftNumber,
        purchaseNumber: initialization.purchaseNumber,
        purchaseId: initialization.purchaseId,
        purchaseDate: initialization.purchaseDate,
        paymentType: initialization.paymentType,
        refundDate: initialization.refundDate,
        fullCount: initialization.fullCount,
        fullAmount: initialization.fullAmount,
        userId: initialization.userId,
        fullRefund: initialization.fullRefund,
        shopName: initialization.shopName,
        shopAddress: initialization.shopAddress,
        positions: initialization.positions,
        '@class': 'ru.crystals.report.vo.RefundRequestVO',
    }
    
    return result
}
