export type FILE_TYPE = XLS | XLSX | PDF | TXT | HTML

export type XLS = 'XLS'
export type XLSX = 'XLSX'
export type PDF = 'PDF'
export type TXT = 'TXT'
export type HTML = 'HTML'

export const XLS: FILE_TYPE = 'XLS'
export const XLSX: FILE_TYPE = 'XLSX'
export const PDF: FILE_TYPE = 'PDF'
export const TXT: FILE_TYPE = 'TXT'
export const HTML: FILE_TYPE = 'HTML'
