import { observable, action, computed, runInAction } from 'mobx'
import { t } from 'i18next'
import { SimpleDepartment, createSimpleDepartment } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-department'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE } from '../stores'
import { getUniqueNameWithNumberPostfix2, postfixRegexWithBrackets } from '../../../utils/name-util'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { withSpinner } from '../with-spinner'

export class OtherDepartmentsStore {
    @observable
    departments: SimpleDepartment[] = []

    @observable
    filter: string = ''

    @observable
    departmentTemplate: SimpleDepartment

    @observable
    addDepartmentDialogOpened: boolean = false

    @observable
    deleteDepartmentDialogOpened: boolean = false

    @observable
    validation: FormValidation<SimpleDepartment> = null

    @observable
    departmentToDelete: number = null

    private userStore: UserStore = getStore(USER_STORE)

    @action
    openAddDepartmentDialog = (): void => {
        this.createDefaultDepartmentTemplate()
        this.createValidation()
        this.addDepartmentDialogOpened = true
    }

    @action
    closeAddDepartmentDialog = (): void => {
        this.addDepartmentDialogOpened = false
    }

    @action
    openDeleteDepartmentDialog = (departmentNumber: number): void => {
        this.deleteDepartmentDialogOpened = true
        this.departmentToDelete = departmentNumber
    }

    closeDeleteDepartmentDialog = (): void => {
        this.deleteDepartmentDialogOpened = false
        this.departmentToDelete = null
    }

    getDepartments = async (): Promise<void> => {
        const departments = await withSpinner(iProductsManagerLocal.getDepartments(this.userStore.session))
        runInAction(() => {
            this.departments = departments
        })
    }

    addDepartment = async (): Promise<void> => {
        try {
            await iProductsManagerLocal.addDepartment(this.userStore.session, this.departmentTemplate)
            await this.getDepartments()
        } finally {
            this.closeAddDepartmentDialog()
        }
    }

    deleteDepartment = async (): Promise<void> => {
        try {
            await iProductsManagerLocal.deleteDepartment(this.userStore.session, this.departmentToDelete)
            await this.getDepartments()
        } finally {
            this.closeDeleteDepartmentDialog()
        }
    }

    @action
    handleFilter = (filter: string): void => {
        this.filter = filter
    }

    @action
    createValidation = (): void => {
        this.validation = new FormValidation<SimpleDepartment>(
            this.departmentTemplate,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        uniqueField(this.departments.map(d => d.name)),
                    ],
                },
                {
                    field: 'number',
                    rules: [
                        requiredField,
                    ],
                }
            ],
            false,
        )
    }

    @action
    updateDepartmentTemplate = (changes: Partial<SimpleDepartment>): void => {
        Object.keys(changes).forEach(key => {
            this.departmentTemplate[key] = changes[key]
        })
    }

    @action
    reset = (): void => {
        this.departments = []
        this.filter = ''
        this.departmentTemplate = null
        this.addDepartmentDialogOpened = false
        this.deleteDepartmentDialogOpened = false
        this.validation = null
        this.departmentToDelete = null
    }

    @computed
    get filteredDepartments(): SimpleDepartment[] {
        if (!this.filter) return this.departments
        
        return this.departments.filter(department => {
            const trimmedFilter = this.filter.trim().toLowerCase()
            const nameCorrespond = department.name.toLowerCase().includes(trimmedFilter)
            const numberCorrespond = department.number.toString().includes(trimmedFilter)
            return nameCorrespond || numberCorrespond
        })
    }

    @action
    private createDefaultDepartmentTemplate = (): void => {
        this.departmentTemplate = createSimpleDepartment({
            name: getUniqueNameWithNumberPostfix2(
                this.departments.map(d => d.name),
                t('otherDepartments.newDepartment'),
                postfixRegexWithBrackets,
            ),
            number: Math.max(...this.departments.map(d => d.number)) + 1,
            '@class': 'ru.crystals.setretailx.products.common.SimpleDepartment',
        })
    }
}
