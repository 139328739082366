import React from 'react'
import classNames from 'classnames'
import DragHandle from '@material-ui/icons/DragHandle'
import { ConnectDragSource, ConnectDropTarget, DragSource, DragSourceConnector, DragSourceMonitor, DropTarget, DropTargetMonitor } from 'react-dnd'
import { isNil } from 'lodash'

export interface DraggableItemRendererProps {
    key: string
    label: string
    isDragging: boolean
    canDrag?: boolean
}

export interface DraggableItemVO {
    label: string
    key: string
    isDragging?: boolean
    canDrag?: boolean
}

export interface CustomizableDraggableItemVO extends DraggableItemVO {
    renderer?: React.ComponentType<DraggableItemRendererProps>
}

const styles = require('./draggable-item.scss')

interface DraggableItemOwnProps<T extends CustomizableDraggableItemVO> {
    /**
     * Уникальный строковый идентификатор поля
     */
    dragAndDropType: string
    item: T
    /**
     * Получает текущий индекс элемента в массиве элементов
     */
    getItemIndex: (key: T['key']) => number
    /**
     * Вызывается при перетягивании текущего слоя поверх другого
     * @param {number} fromIndex - индекс текущего слоя в массиве
     * @param {number} toIndex - индекс слоя, над которым оказался текущий
     */
    onLayerMove: (fromIndex: number, toIndex: number) => void
    /**
     * Вызывается после смены порядка слоев через "drop" либо при нажатии на "flip to front/back"
     */
    onLayerMovementFinished: () => void
}

interface DraggableItemProps<T extends CustomizableDraggableItemVO> extends DraggableItemOwnProps<T> {
    connectDragSource?: ConnectDragSource
    connectDropTarget?: ConnectDropTarget
    isDragging?: boolean
    canDrag?: boolean
}

interface DraggedItemProps {
    itemKey: string
    originalIndex: number
}

/* TODO непонятно
   после удаления package-lock здесь ошибки TS
   руками в браузере проверил - работает
*/
// @ts-ignore
@DropTarget(
    (props: DraggableItemOwnProps<CustomizableDraggableItemVO>) => props.dragAndDropType,
    {
        hover(props: DraggableItemOwnProps<CustomizableDraggableItemVO>, monitor: DropTargetMonitor) {
            const { itemKey: draggedItemKey } = monitor.getItem() as DraggedItemProps
            const { getItemIndex, item: { key: overItemKey }, onLayerMove } = props

            const fromIndex = getItemIndex(draggedItemKey)
            const toIndex = getItemIndex(overItemKey)

            if (draggedItemKey !== overItemKey) {
                onLayerMove(fromIndex, toIndex)
            }
        },
    },
    connect => ({
        connectDropTarget: connect.dropTarget(),
    }),
)
// @ts-ignore
@DragSource(
    (props: DraggableItemOwnProps<CustomizableDraggableItemVO>) => props.dragAndDropType,
    {
        canDrag: (props: DraggableItemOwnProps<CustomizableDraggableItemVO>) => {
            return isNil(props.item.canDrag) ? true : props.item.canDrag
        },
        beginDrag: (props: DraggableItemOwnProps<CustomizableDraggableItemVO>) => ({
            itemKey: props.item.key,
            originalIndex: props.getItemIndex(props.item.key)
        }),
        endDrag(props: DraggableItemOwnProps<CustomizableDraggableItemVO>, monitor: DragSourceMonitor) {
            const { itemKey: droppedItemKey, originalIndex } = monitor.getItem() as DraggedItemProps
            const { getItemIndex, onLayerMovementFinished, onLayerMove } = props

            const droppedItemIndex = getItemIndex(droppedItemKey)

            if (monitor.didDrop()) {
                if (droppedItemIndex !== originalIndex) {
                    onLayerMovementFinished()
                }
            } else {
                onLayerMove(droppedItemIndex, originalIndex)
            }
        },
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)
export class DraggableItem<T extends CustomizableDraggableItemVO> extends React.Component<DraggableItemProps<T>> {

    render() {
        const { item, connectDragSource, connectDropTarget, isDragging } = this.props

        const Renderer: React.ComponentType<DraggableItemRendererProps> = item.renderer || DraggableItemDefaultRenderer

        return (
            connectDropTarget(
                connectDragSource(
                    <div key={item.key}>
                        <Renderer
                            key={item.key}
                            label={item.label}
                            isDragging={isDragging}
                        />
                    </div>
                )
            )
        )
    }
}

export const DraggableItemDefaultRenderer: React.FunctionComponent<DraggableItemRendererProps> = (props: DraggableItemRendererProps) => {
    const { key, label, isDragging } = props
    return (
        <div
            id={`item-${key}`}
            className={classNames(styles.defaultRendererItem, {
                [styles.draggedItem]: isDragging,
            })}
        >
            <DragHandle className={styles.itemHandle} />
            <span
                id={`itemName-${key}`}
                className={styles.itemLabel}
            >
                { label }
            </span>
        </div>
    )
}
