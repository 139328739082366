import { isNil } from 'lodash'
import { RectBounds } from '../rect-bounds'
import { BOTTOM_LEFT, CENTER, ControllerType, LEFT, ROTATION, TOP, TOP_LEFT, TOP_RIGHT } from '../hand-controller'
import { Vector2d } from '../../../utils/math/geom-util'
import { BaseConstraint, EditorState } from './constraint'
import { getResizeForAction } from '../editor-util'

export interface SizeConstraintOptions {
    minWidth?: number
    minHeight?: number
    maxWidth?: number
    maxHeight?: number
}

export class SizeConstraint extends BaseConstraint<SizeConstraintOptions> {

    constructor(options: SizeConstraintOptions, scale: number = 1, active: boolean = false) {
        super(options, scale, active)
    }

    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d {
        if (!this.active || [CENTER, ROTATION].includes(controller)) return translation

        const minWidth = !isNil(this.options.minWidth) ? this.options.minWidth * this.scale : 0
        const minHeight = !isNil(this.options.minHeight) ? this.options.minHeight * this.scale : 0
        const maxWidth = !isNil(this.options.maxWidth) ? this.options.maxWidth * this.scale : Number.MAX_VALUE
        const maxHeight = !isNil(this.options.maxHeight) ? this.options.maxHeight * this.scale : Number.MAX_VALUE

        const resize = getResizeForAction(bounds, controller, translation)
        const fixedResize = resize.clone()

        if (fixedResize.x !== 0) {
            if (fixedResize.x + bounds.width < minWidth) {
                fixedResize.x = minWidth - bounds.width
            } else if (fixedResize.x + bounds.width > maxWidth) {
                fixedResize.x = maxWidth - bounds.width
            }
        }

        if (fixedResize.y !== 0) {
            if (fixedResize.y + bounds.height < minHeight) {
                fixedResize.y = minHeight - bounds.height
            } else if (fixedResize.y + bounds.height > maxHeight) {
                fixedResize.y = maxHeight - bounds.height
            }
        }

        if (!resize.equals(fixedResize)) {
            // Если ограничение сработало, просто вернем вектор изменения размера

            // Для некоторых контроллеров направление противоположно изменению размера объекта
            const INVERT_X = [TOP_LEFT, LEFT, BOTTOM_LEFT]
            const INVERT_Y = [TOP_LEFT, TOP, TOP_RIGHT]

            if (INVERT_X.includes(controller)) fixedResize.x = -fixedResize.x
            if (INVERT_Y.includes(controller)) fixedResize.y = -fixedResize.y

            return fixedResize
        } else {
            return translation
        }
    }
}
