import { observable, runInAction, toJS } from 'mobx'
import {
    DirectivePrintingTopologyVO
} from '../../../../protocol/set10/set-retail10-server/retailx/set-directive-printing/directive-printing-topology-vo'
import { directivePrintingTopologyPresenter } from '../../../../protocol/set10/directive-printing-topology-presenter'
import { RouteChangeHandler } from '../../../utils/router-util'
import {
    DIRECTIVE_PRINTING,
    TOPOLOGIES,
    PRICE_TAGS
} from '../../../core/app-routes'
import { AppBarStore, MENU } from '../../app-bar-store'
import { getStore } from '../../stores-repository'
import { NAVIGATION_MENU_STORE, APP_BAR_STORE } from '../../stores'
import { t } from 'i18next'
import { NavigationMenuStore } from '../../navigation-menu-store'

export class TopologiesStore {
    @observable
    availableTopologies: DirectivePrintingTopologyVO[] = []

    fetchTopologies = (): Promise<any> => {
        return directivePrintingTopologyPresenter.loadAll().then(topologies => {
            runInAction(() => {
                this.availableTopologies = topologies
            })
        })
    }

    deleteTopology = async (topology: DirectivePrintingTopologyVO): Promise<any> => {
        await directivePrintingTopologyPresenter.delete(toJS(topology))
        await this.fetchTopologies()
    }

    reset = (): void => {
        this.availableTopologies = []
    }
}

export const DIRECTIVE_PRINTING_TOPOLOGIES_ROUTE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(
        `^${PRICE_TAGS}${DIRECTIVE_PRINTING}${TOPOLOGIES}/?$`
    ),
    onEnter: () => {
        const navigationMenuStore: NavigationMenuStore = getStore(
            NAVIGATION_MENU_STORE
        )
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        navigationMenuStore.synchronizeWithRoute()
        appBarStore.updateState({
            title: `${t('set10.priceTags')} - ${t(
                'directivePrinting.directivePrinting'
            )}`,
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    }
}
