export type MarkTypeVO = 'UNKNOWN' | 'FOOTWEAR' | 'LIGHT_INDUSTRY' | 'TOBACCO' | 'MILK' | 'PERFUMES' | 'PHOTO' | 'TYRES' | 'JEWELRY'

export const UNKNOWN: MarkTypeVO = 'UNKNOWN'
export const FOOTWEAR: MarkTypeVO = 'FOOTWEAR'
export const LIGHT_INDUSTRY: MarkTypeVO = 'LIGHT_INDUSTRY'
export const TOBACCO: MarkTypeVO = 'TOBACCO'
export const MILK: MarkTypeVO = 'MILK'
export const PERFUMES: MarkTypeVO = 'PERFUMES'
export const PHOTO: MarkTypeVO = 'PHOTO'
export const TYRES: MarkTypeVO = 'TYRES'
export const JEWELRY: MarkTypeVO = 'JEWELRY'
