import {t} from 'i18next'
import moment from 'moment'

export interface DayOfWeek {
    day: number
    name: string
}

export type WeekIntervalType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7

export const ALL_WEEK = 0
export const MONDAY = 1
export const TUESDAY = 2
export const WEDNESDAY = 3
export const THURSDAY = 4
export const FRIDAY = 5
export const SATURDAY = 6
export const SUNDAY = 7

export const getWeekIntervalOptions = (): Array<{ label: string, value: WeekIntervalType }> => [
    {label: t('common.weekdays.allWeek'), value: 0},
    {label: t('common.weekdays.monday'), value: 1},
    {label: t('common.weekdays.tuesday'), value: 2},
    {label: t('common.weekdays.wednesday'), value: 3},
    {label: t('common.weekdays.thursday'), value: 4},
    {label: t('common.weekdays.friday'), value: 5},
    {label: t('common.weekdays.saturday'), value: 6},
    {label: t('common.weekdays.sunday'), value: 7},
]

// ISO
export const getDaysOfWeek = (): DayOfWeek[] => {
    let result = []
    for (let i = 1; i < 8; i++) {
        result.push({
            day: i,
            name: moment().isoWeekday(i).format('dd').toUpperCase()
        })
    }
    return result
}
