export interface UniqueCouponBatchVO {
    '@class': 'ru.crystals.cards.coupons.vo.UniqueCouponBatchVO'
    name?: string
    number?: number
    couponGuid?: number
    authorFirstName?: string
    authorSecondName?: string
    creationDate?: Date
    size?: number
    status?: string
    guid?: number
    errorFile?: string
}

export const createUniqueCouponBatchVO = (initialization: Partial<UniqueCouponBatchVO>): UniqueCouponBatchVO => {
    if (!initialization) return null
    
    let result: UniqueCouponBatchVO = {
        name: initialization.name,
        number: initialization.number,
        couponGuid: initialization.couponGuid,
        authorFirstName: initialization.authorFirstName,
        authorSecondName: initialization.authorSecondName,
        creationDate: initialization.creationDate,
        size: initialization.size,
        status: initialization.status,
        guid: initialization.guid,
        errorFile: initialization.errorFile,
        '@class': 'ru.crystals.cards.coupons.vo.UniqueCouponBatchVO',
    }
    
    return result
}
