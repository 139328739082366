import { t } from 'i18next'
import { isNil } from 'lodash'
import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { BaseDateRangeFilter } from '../../components/filters/components/base-date-range-filter'
import {
    ALL_FILTERS_INSTANCES,
    BANK_TRANSACTIONS_FILTERS,
    CHECKS_SEARCH_FILTERS,
    FiltersComponentInstance,
    UPD_REGISTRY_FILTERS,
    UserFavoriteFilters,
    NOT_PRINTED_ACTION_PRICE_TAGS_FILTERS,
    NOT_PRINTED_REGULAR_PRICE_TAGS_FILTERS,
    NOT_ACTUAL_ACTION_PRICE_TAGS_FILTERS,
    NOT_ACTUAL_REGULAR_PRICE_TAGS_FILTERS,
    CUSTOM_PRINTING_ACTION_PRICE_TAGS_FILTERS,
    CUSTOM_PRINTING_REGULAR_PRICE_TAGS_FILTERS,
    PRINTING_HISTORY_FILTERS
} from '../../core/filters/favorite-filters'
import {
    BONUS_CARD_NUMBER_FILTER,
    BUYER_INN_FILTER,
    DISCOUNT_CARD_NUMBER_FILTER,
    CHECK_BARCODE_FILTER,
    CHECK_TIME_FILTER,
    Filter,
    FilterType,
    PRODUCT_ITEM_OR_BARCODE_FILTER,
    RESULT_SERVER_CODE_FILTER,
    RUNNING_ACTIONS_FILTER,
    EMPTY_FILTER_VALUE,
    PRICES_START_DATE_FILTER,
    NEARLY_PRICE_CHANGING_FILTER,
    ENDED_ACTIONS_BY_NOW_FILTER,
    ENDED_PRICES_BY_NOW_FILTER,
    PRODUCT_GROUP_FILTER,
    SALE_RESTRICTIONS_FILTER,
    ACTUAL_PRICE_NUMBERS_FILTER,
    ACTION_START_DATE_FILTER,
    FUTURE_PRICES_FILTER,
    PRICES_END_DATE_FILTER,
    ACTION_END_DATE_FILTER,
    PRICE_TAGS_PRINTING_DATE_FILTER,
    PRICE_TAG_TEMPLATE_NAME_FILTER,
    PRINT_SHELF,
    PRODUCT_AUTOCOMPLETE,
} from '../../core/filters/filter'
import { localStorageManager } from '../../utils/local-storage-util'
import { AppStore } from '../app-store'
import {
    APP_STORE,
    CHECKS_SEARCH_STORE,
    TRANSACTIONS_SEARCH_STORE,
    UPD_REGISTRY_STORE,
    USER_STORE
} from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { fromClientToServerTime } from '../../utils/app-util'

export class FavoriteFiltersStore {
    @observable
    userFavoriteFilters: UserFavoriteFilters

    @computed
    get defaultFiltersTypes(): Map<FiltersComponentInstance, FilterType[]> {
        const filterTypesMap: Map<FiltersComponentInstance, FilterType[]> = new Map<FiltersComponentInstance, FilterType[]>()

        ALL_FILTERS_INSTANCES.forEach(instance => {
            let filterTypes: FilterType[] = !isNil(this.userFavoriteFilters)
                ? this.userFavoriteFilters[instance]
                : null

            if (isNil(filterTypes)) {
                switch (instance) {
                    case CHECKS_SEARCH_FILTERS:
                        filterTypes = [
                            BONUS_CARD_NUMBER_FILTER,
                            PRODUCT_ITEM_OR_BARCODE_FILTER,
                            CHECK_TIME_FILTER
                        ]
                        break
                    case BANK_TRANSACTIONS_FILTERS:
                        filterTypes = [
                            PRODUCT_ITEM_OR_BARCODE_FILTER,
                            RESULT_SERVER_CODE_FILTER,
                            CHECK_TIME_FILTER
                        ]
                        break
                    case UPD_REGISTRY_FILTERS:
                        filterTypes = [
                            CHECK_BARCODE_FILTER,
                            BUYER_INN_FILTER,
                            DISCOUNT_CARD_NUMBER_FILTER,
                            CHECK_TIME_FILTER
                        ]
                        break
                    case NOT_PRINTED_ACTION_PRICE_TAGS_FILTERS:
                        filterTypes = [
                            RUNNING_ACTIONS_FILTER,
                            ACTION_START_DATE_FILTER,
                            PRODUCT_ITEM_OR_BARCODE_FILTER
                        ]
                        break
                    case NOT_ACTUAL_ACTION_PRICE_TAGS_FILTERS:
                        filterTypes = [
                            ENDED_ACTIONS_BY_NOW_FILTER,
                            ACTION_END_DATE_FILTER,
                            PRODUCT_ITEM_OR_BARCODE_FILTER
                        ]
                        break
                    case NOT_ACTUAL_REGULAR_PRICE_TAGS_FILTERS:
                        filterTypes = [
                            ENDED_PRICES_BY_NOW_FILTER,
                            PRICES_END_DATE_FILTER,
                            PRODUCT_ITEM_OR_BARCODE_FILTER
                        ]
                        break
                    case NOT_PRINTED_REGULAR_PRICE_TAGS_FILTERS:
                        filterTypes = [
                            PRODUCT_ITEM_OR_BARCODE_FILTER,
                            PRICES_START_DATE_FILTER,
                            NEARLY_PRICE_CHANGING_FILTER,
                        ]
                        break
                    case CUSTOM_PRINTING_ACTION_PRICE_TAGS_FILTERS:
                        filterTypes = [
                            RUNNING_ACTIONS_FILTER,
                            PRICE_TAGS_PRINTING_DATE_FILTER,
                            PRODUCT_ITEM_OR_BARCODE_FILTER
                        ]
                        break
                    case CUSTOM_PRINTING_REGULAR_PRICE_TAGS_FILTERS:
                        filterTypes = [
                            PRODUCT_GROUP_FILTER,
                            SALE_RESTRICTIONS_FILTER,
                            ACTUAL_PRICE_NUMBERS_FILTER,
                            FUTURE_PRICES_FILTER,
                        ]
                        break
                    case PRINTING_HISTORY_FILTERS:
                        filterTypes = [
                            PRODUCT_AUTOCOMPLETE,
                            PRINT_SHELF,
                            PRODUCT_ITEM_OR_BARCODE_FILTER,
                            PRICE_TAG_TEMPLATE_NAME_FILTER,
                        ]
                        break
                    default:
                        filterTypes = []
                }
            }

            filterTypesMap.set(instance, filterTypes)
        })

        return filterTypesMap
    }

    @computed
    get defaultFilters(): Map<FiltersComponentInstance, Filter[]> {
        const defaultFiltersMap: Map<FiltersComponentInstance, Filter[]> = new Map<FiltersComponentInstance, Filter[]>()

        ALL_FILTERS_INSTANCES.forEach(instance => {
            const filterTypes = this.defaultFiltersTypes.get(instance)

            const defaultFilters = filterTypes.map(filterType => {
                let defaultValue

                if (filterType === CHECK_TIME_FILTER) {
                    const startDate: string = moment(fromClientToServerTime(new Date())).startOf('day').format(BaseDateRangeFilter.dateFormat)
                    const endDate: string = moment(fromClientToServerTime(new Date())).endOf('day').format(BaseDateRangeFilter.dateFormat)
                    defaultValue = `${startDate}${BaseDateRangeFilter.dateDelimiter}${endDate}`
                }

                if (filterType === RUNNING_ACTIONS_FILTER) {
                    defaultValue = EMPTY_FILTER_VALUE
                }

                if (filterType === ENDED_ACTIONS_BY_NOW_FILTER) {
                    defaultValue = EMPTY_FILTER_VALUE
                }

                if (filterType === ENDED_PRICES_BY_NOW_FILTER) {
                    defaultValue = EMPTY_FILTER_VALUE
                }

                return new Filter(filterType, defaultValue)
            })

            defaultFiltersMap.set(instance, defaultFilters)
        })

        return defaultFiltersMap
    }

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    @action
    getUserFavoriteFilters = (): void => {
        if (!this.userStore.userInfo) return

        this.userFavoriteFilters = localStorageManager.getUserFavoriteFilters(this.userStore.userInfo)
    }

    @action
    changeDefaultFilters = (instance: FiltersComponentInstance, filters: FilterType[]): void => {
        if (!this.userStore.userInfo) return

        localStorageManager.setUserFavoriteFilters(
            { ...this.userFavoriteFilters, [instance]: filters },
            this.userStore.userInfo
        )

        this.userFavoriteFilters = localStorageManager.getUserFavoriteFilters(this.userStore.userInfo)
    }

    @action
    resetUserFavoriteFilters = (): void => {
        if (!this.userStore.userInfo) return

        localStorageManager.resetUserFavoriteFilters(this.userStore.userInfo)

        this.userFavoriteFilters = localStorageManager.getUserFavoriteFilters(this.userStore.userInfo)

        // Сбросим состояние фильтров в соответствующих сторах (включая результаты поиска)
        const affectedStores = [CHECKS_SEARCH_STORE, TRANSACTIONS_SEARCH_STORE, UPD_REGISTRY_STORE]
        affectedStores.map(storeName => {
            const storeInstance = getStore<{resetFiltersAndResults: () => void}>(storeName)
            storeInstance.resetFiltersAndResults()
        })

        this.appStore.showSnackbar({ message: t('operdaySettings.favoriteFiltersReset')})
    }

    @action
    reset = (): void => {
        this.userFavoriteFilters = null
    }
}
