/// <reference path="../../typings/global.d.ts"/>
import axios, { AxiosRequestConfig } from 'axios'
import moment from 'moment'
import * as i18next from 'i18next'
import { runInAction } from 'mobx'
import { RouterStore } from 'mobx-react-router'
import { ALL_SERVICES } from '../../protocol/set10/all-services'
import { currencyManager } from '../../protocol/set10/currency-manager'
import { haspDataManagerRemote } from '../../protocol/set10/hasp-data-manager-remote'
import { iSessionManagerLocal } from '../../protocol/set10/i-session-manager-local'
import { registerManagerLocal } from '../../protocol/set10/register-manager-local'
import { salesManagementPropertiesService } from '../../protocol/set10/sales-management-properties-service'
import { LicenseInfoVO } from '../../protocol/set10/set-retail10-commons/set-hasp-commons/license-info-vo'
import { config, DEV } from '../config/config'
import { SET_REGISTER } from '../core/app-modules'
import { LOGIN, ROOT } from '../core/app-routes'
import { Currency } from '../core/currency-utils'
import { AppBarStore } from '../store/app-bar-store'
import { AppStore } from '../store/app-store'
import { FavoriteFiltersStore } from '../store/operday/favorite-filters-store'
import { NotificationStore } from '../store/notification-store'
import {
    APP_BAR_STORE,
    APP_STORE,
    FAVORITE_FILTERS_STORE,
    NOTIFICATION_STORE,
    ROUTING_STORE,
    USER_STORE,
    CUSTOM_PRINTING_BASKET_STORE,
    IFRAME_MODULES_STORE,
    SNACKBAR_STORE
} from '../store/stores'
import { getStore } from '../store/stores-repository'
import { UserStore } from '../store/user-store'
import { prepareLocale } from './locale-util'
import { getBaseURL, goTo } from './router-util'
import { CustomPrintingBasketStore } from '../store/price-tags/custom-printing-basket-store'
import { iDiscountsManagerLocal } from '../../protocol/set10/i-discounts-manager-local'
import { commonRequestMiddlewares } from '../../protocol/set10/base-service'
import {
    NavigationAPIPoints,
    V1NotificationsResponse,
    GetNavigationAPIResponse,
    ModuleItem,
    V1NavigationResponse
} from '../core/iframe-modules/entities'
import { IframeModulesStore } from '../store/iframe-modules-store'
import { APP_LOCALE } from '../../locale/languages'
import { SETRETAILX_EXTERNALSYSTEMS_ADMIN } from '../core/privileges/privileges'
import { externalSystemsManagerRemote } from '../../protocol/set10/external-systems-manager-remote'
import { SnackbarStore, SNACK_BAR_ERROR } from '../store/snackbar-store'
import { SNACKBAR_HUGE_DURATION } from '../../utils/default-timeouts'
import React from 'react'
import { LicenseDisableAlertMessage } from '../components/etc/license-disable-alert-message'

/**
 * Объект для разрыва связи es6 модулей, чтобы решить проблему циклических зависимостей
 * appRenderer.render просто отрисовывает приложение, подменяется заказчиком
 *
 * @type {{renderRoutes: () => null}}
 */
export const appRenderer = {
    render: () => null
}

declare var global: GlobalWithBuild

export function loadConfig() {
    config.environment = global.build.environment || DEV
    config.baseURL = getBaseURL()

    const showConfig = () => {
        console.info('app config:')
        console.info(` - environment: ${config.environment}`)
        console.info(` - baseURL: ${config.baseURL}`)
        console.info(` - serverHost:  ${config.serverHost}`)
        console.info(` - serverPort:  ${config.serverPort}`)
        console.info(` - externalModules:  ${config.externalModules}`)
    }

    return new Promise((resolve, reject) => {
        axios.get(`${config.baseURL}/config.json?${Date.now()}`)
            .then(response => {
                const MIN_OK_STATUS = 200
                const MAX_OK_STATUS = 299

                if (response.status < MIN_OK_STATUS || response.status > MAX_OK_STATUS) {
                    let errorMessage = `loading config failed, status: ${response.status}`
                    console.error(errorMessage)
                    reject(new Error(errorMessage))
                } else {
                    config.serverHost = response.data.serverHost
                    config.serverPort = response.data.serverPort
                    config.externalModules = response.data.externalModules

                    showConfig()

                    resolve(config)
                }
            })
            .catch(error => {
                console.error('loading config error:', error)

                config.serverHost = window.location.hostname
                config.serverPort = window.location.port

                showConfig()

                resolve(config)
            })
    })
}

const changeServer = () => {
    ALL_SERVICES.forEach(service => {
        service.baseURL = config.serverAddress
    })
}

const fetchCurrencyCode = (): Promise<any> => {
    const appStore: AppStore = getStore(APP_STORE)
    const userStore: UserStore = getStore(USER_STORE)
    return currencyManager.getCurrentCurrencyCode(userStore.session)
        .then((result: Currency) => {
            if (result) {
                appStore.setCurrencySymbol(result)
            }
        })
}

const getLicenseInfo = (): Promise<any> => {
    const appStore: AppStore = getStore(APP_STORE)
    return haspDataManagerRemote.getLicenseInfo()
        .then((result: LicenseInfoVO) => {
            appStore.licenseInfo = result
        })
}

const getServerInfo = (): Promise<any> => {
    const appStore: AppStore = getStore(APP_STORE)
    return registerManagerLocal.getInformationAboutServer()
        .then(info => {
            appStore.serverInfo = info
            appStore.setIsCentrum(info.isItCentrum)
            document.title = info.isItCentrum ? 'Set 10: Centrum' : 'Set 10: Retail'
            return Promise.resolve()
        })
}

async function getServerTimezoneOffset(): Promise<void> {
    const userStore: UserStore = getStore(USER_STORE)
    const appStore: AppStore = getStore(APP_STORE)
    appStore.serverTimezoneOffset = await iDiscountsManagerLocal.getServerTimezoneOffset(userStore.session) || 0

    /**
     * Проверяем и исправляем объекты Date, переданные в параметрах каждого запроса
     */
    commonRequestMiddlewares.push((config: AxiosRequestConfig) => {
        config.data.params = config.data.params.map(recursiveChangeServerDate)
    })
}

async function getExternalModulesEndpoints(): Promise<NavigationAPIPoints[]> {
    let externalModules: Array<{ serverURL?: string }> = (await registerManagerLocal.getModulesDescriptors() || [])
        .filter(mod => mod.external)

    // берём из develop.config.json (либо из config.json)
    if (!config.isProduction && config.externalModules?.length > 0) {
        externalModules = config.externalModules
    }

    const moduleItems = await Promise.all(externalModules.map(module => {
        if (!module.serverURL) {
            return []
        }
        return axios.get(module.serverURL)
            .then(response => {
                const endpointsRes: GetNavigationAPIResponse = response.data
                return endpointsRes.points
            })
            .catch(e => {
                console.error('failed to fetch navigation for module %o, %o', module, e)
                return []
            })
    }))

    return moduleItems
}

async function getExternalModulesNavigation(locale: APP_LOCALE): Promise<ModuleItem[]> {
    const iframeModulesStore: IframeModulesStore = getStore(IFRAME_MODULES_STORE)
    const { externalModulesEndpoints } = iframeModulesStore

    const moduleItemsMatrix = await Promise.all(externalModulesEndpoints.map(moduleEndpoints => {
        if (!moduleEndpoints[0]) {
            return []
        }
        return axios.get(moduleEndpoints[0].url + `?locale=${locale}`)
            .then(response => {
                const navRes: V1NavigationResponse = response.data
                return navRes.modules
            })
            .catch(e => {
                console.error('failed to fetch navigation for module %o, %o', module, e)
                return []
            })
    }))

    return moduleItemsMatrix.reduce((prev, matrixRow) => [...prev, ...matrixRow], [])
}

export async function getExternalModulesNotifications(locale: APP_LOCALE): Promise<V1NotificationsResponse> {
    const iframeModulesStore: IframeModulesStore = getStore(IFRAME_MODULES_STORE)
    const { externalModulesEndpoints } = iframeModulesStore

    const moduleItems: V1NotificationsResponse[] = await Promise.all(externalModulesEndpoints.map(moduleEndpoints => {
        if (!moduleEndpoints[1]) {
            return null
        }
        return axios.get(moduleEndpoints[1].url + `?locale=${locale}`)
            .then(response => {
                const notifRes: V1NotificationsResponse = response.data
                return notifRes
            })
            .catch(e => {
                console.error('failed to fetch notifications for module %o, %o', module, e)
                return null
            })
    }))

    return moduleItems.filter(item => item?.subGroups).reduce((prev, cur) => ({
        subGroups: [
            ...cur.subGroups,
            ...prev.subGroups
        ],
    }), { subGroups: [] })
}

const checkAuth = async (): Promise<void> => {
    console.info('check auth info')
    const userStore: UserStore = getStore(USER_STORE)
    try {
        const session = await iSessionManagerLocal.getSessionID()
        if (!session) {
            return Promise.reject('no active session found')
        }
        userStore.setActiveSession(session)

        // Дополнительно проверим, активна ли сессия, бывает сервер возвращает сессию, которая неактивна
        try {
            const active = await iSessionManagerLocal.isSessionActive()
            if (!active) {
                return Promise.reject('session is not active')
            }
        } catch (error) {
            return Promise.reject('session is not active')
        }
    } catch (error) {
        console.info('session activity fault')
        goTo(LOGIN)
        return Promise.reject('authentication error')
    }
}

export const preAuthLogic = async (): Promise<void> => {
    // Загружаем конфиг
    await loadConfig()
    // Меняем сервер у всех сервисов
    changeServer()
    // Указываем title из локализации
    document.title = i18next.t('set10.title')
    // Получаем информацию о лицензии
    await getLicenseInfo()
    // Получаем информацию о сервере
    await getServerInfo()
    // Подготавливаем локализацию
    await prepareLocale()
    // Первичный рендеринг роутов
    await appRenderer.render()
}

export const postAuthLogic = async (): Promise<void> => {
    const userStore: UserStore = getStore(USER_STORE)
    if (!userStore.authorized) { return }

    const appStore: AppStore = getStore(APP_STORE)
    const routingStore: RouterStore = getStore(ROUTING_STORE)
    const iframeModulesStore: IframeModulesStore = getStore(IFRAME_MODULES_STORE)
    const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    const favoriteFiltersStore: FavoriteFiltersStore = getStore(FAVORITE_FILTERS_STORE)
    const notificationStore: NotificationStore = getStore(NOTIFICATION_STORE)
    const customPrintingBasketStore: CustomPrintingBasketStore = getStore(CUSTOM_PRINTING_BASKET_STORE)

    // Получаем временную зону сервера
    await getServerTimezoneOffset()
    // Получаем топологию торговой сети
    await appStore.fetchTopologyMap()
    // Получаем информацию о пользователе
    const userInfo = await iSessionManagerLocal.getUser()
    runInAction(() => {
        // Не позволяем заходить заблокированным пользователем
        if (userInfo.blockedStatus) {
            goTo(LOGIN)
            return new Error('User blocked')
        }
        userStore.userInfo = userInfo
        userStore.fillRoles()
    })

    // Получаем информацию об external модулях (которые через iframe будут показываться)
    // Сначала endpoint'ы, потом навигацию
    const externalModulesEndpoints = await getExternalModulesEndpoints()
    iframeModulesStore.setExternalModulesEndpoints(externalModulesEndpoints)
    const externalModulesNavigation = await getExternalModulesNavigation(appStore.serverInfo.serverLocale as APP_LOCALE)
    iframeModulesStore.setExternalModulesNavigation(externalModulesNavigation)

    // Получаем локальные настройки фильтров опердня
    favoriteFiltersStore.getUserFavoriteFilters()
    // Получаем настройки модуля печати ценников
    await appStore.getPrintingModuleSettings()
    // Получаем настройки редактора условий
    await appStore.fetchEditorsVisibilitySettings()
    // Необходимо обновить доступные роуты в соответствии с привилегиями пользователя
    await appRenderer.render()
    // Начинаем отслеживать изменение роутов
    appBarStore.startRouteWatching()

    // Редиректим нового пользователя на ROOT. Важно сделать это после appRenderer.render,
    // чтобы сработал первоначальный <Redirect/> на валидный роут текущего пользователя
    if (routingStore.location.pathname === LOGIN) {
        goTo(ROOT)
    }
    // Запускаем первоначальную валидацию корзины произвольной печати (нужна для правильной работы нотификаций)
    if (!appStore.isCentrum) {
        await customPrintingBasketStore.fillBasketItems()
    }
    // Инициируем нотификации
    await notificationStore.startNotificationsTimer()
    // Получаем текущую валюту
    await fetchCurrencyCode()
    // Обновляем статус подключения к центруму
    await appStore.refreshCentrumConnectionStatus()
    // SRTE-3563 показываем сообщение о ненастроенном или не подключенном SetAgent'е (временная мера)
    showLicenceDisableAlert()
}

export const showLicenceDisableAlert = async (): Promise<void> => {
    const userStore: UserStore = getStore(USER_STORE)

    if (userStore.havePrivilege(SETRETAILX_EXTERNALSYSTEMS_ADMIN)) {
        const appStore: AppStore = getStore(APP_STORE)

        const processings = await externalSystemsManagerRemote.getRegisteredServiceProviders(
            userStore.session, '', appStore.locale
        )

        const licenseProcessing = processings.find(v => v.name === 'license-service')

        let licenseProcessingEnabled = false

        if (licenseProcessing) {
            if (
                // есть настройка для всей сети
                licenseProcessing.settings.findIndex(v => v.shop === 0) > -1 ||
                // либо есть настройка, независимо центрум это или ритейл
                licenseProcessing.settings.length > 0 ||
                // либо это центрум, но настраивается в магазине
                (appStore.isCentrum && licenseProcessing.settingsOnShop)
            ) {
                licenseProcessingEnabled = true
            }
        }

        if (!licenseProcessingEnabled) {
            const snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
            snackbarStore.show({
                message: React.createElement(LicenseDisableAlertMessage),
                duration: SNACKBAR_HUGE_DURATION,
                showCloseIcon: true,
                variant: SNACK_BAR_ERROR,
            })
        }
    }
}

export const appLaunchChain = async (): Promise<void> => {
    try {
        await preAuthLogic()
        await checkAuth()
        await postAuthLogic()
    } catch (error) {
        goTo(LOGIN)
    }
}

/**
 * Приводит время сервера к браузерному времени
 * метод корректирует значение времени, после работы метода fromClientToServerTime
 *
 * data.toUTCString() - возвращает корректное значение
 */
export function fromServerToClientTime(date: Date): Date {
    const appStore: AppStore = getStore(APP_STORE)
    if (!date) return date
    const momentDate = moment(date)
    const result = momentDate.add(-momentDate.toDate().getTimezoneOffset() - appStore.serverTimezoneOffset, 'minutes').toDate()

    /**
     * "Правим" дату, "сломанную" ранее функцией fromClientToServerTime
     */
    // tslint:disable-next-line:no-string-literal
    result['isServerDate'] = false
    return result
}

/**
 * Приводит браузерное время к времени сервера - используется только для отображения серверного времени у клиента
 * Не меняет часовой пояс - остаётся часовой пояс клиента
 * учитывая, что меняется время, но часовой пояс остаётся неизменным - дата ломается,
 * чтобы время "починить" следует использовать метод fromServerToClientTime
 *
 * data.toUTCString() - возвращает значение некорректное значение
 */
export function fromClientToServerTime(date: Date): Date {
    const appStore: AppStore = getStore(APP_STORE)
    if (!date) return date
    const momentDate = moment(date)
    const result = momentDate
        .add(appStore.serverTimezoneOffset + momentDate.toDate().getTimezoneOffset(), 'minutes')
        .toDate()

    /**
     * Мы "ломаем" дату (прибавляем/убавляем часов до серверной таймзоны),
     * поэтому ставим тут флаг, позволяющий нам по нему определить эту "сломанную" дату
     */
    // tslint:disable-next-line:no-string-literal
    result['isServerDate'] = true
    return result
}

/*
 * Используем если надо пометить дату к исправлению, без изменения времени
 */
export function markAsServerDate(date: Date): Date {
    if (!date) return date
    const result = new Date(date)

    /**
     * Мы "ломаем" дату (прибавляем/убавляем часов до серверной таймзоны),
     * поэтому ставим тут флаг, позволяющий нам по нему определить эту "сломанную" дату
     */
    // tslint:disable-next-line:no-string-literal
    result['isServerDate'] = true
    return result
}

/**
 * Если это дата с установленным флагом 'isServerDate', то правим такую дату
 */
function recursiveChangeServerDate(node: any): any {
    // tslint:disable-next-line:no-string-literal
    if (node instanceof Date && node['isServerDate']) {
        return fromServerToClientTime(node)
    }

    if (Array.isArray(node)) {
        return node.map(recursiveChangeServerDate)
    }

    if (isObject(node)) {
        Object.keys(node).forEach(key => {
            node[key] = recursiveChangeServerDate(node[key])
        })

        return node
    }

    return node
}

const isObject = function(obj: any) {
    let type = typeof obj
    return type === 'function' || type === 'object' && !!obj
}
