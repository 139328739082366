export interface DatePeriod {
    '@class': 'ru.crystals.discounts.DatePeriod'
    start?: Date
    finish?: Date
}

export const createDatePeriod = (initialization: Partial<DatePeriod>): DatePeriod => {
    if (!initialization) return null
    
    let result: DatePeriod = {
        start: initialization.start,
        finish: initialization.finish,
        '@class': 'ru.crystals.discounts.DatePeriod',
    }
    
    return result
}
