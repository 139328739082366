import { t } from 'i18next'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { iEquipmentManagerLocal } from '../../../../protocol/set10/i-equipment-manager-local'
import { CLOSED_DOWN as CASH_CLOSED } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-status'
import {CashVO} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-vo'
import { EquipmentModelVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/equipment-model-vo'
import { FormValidation } from '../../../../utils/form-validation/form-validation'
import { requiredField } from '../../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../../utils/form-validation/validators/unique-field'
import {printersManagerLocal} from '../../../../protocol/set10/printers-manager-local'
import {createPrinterVO, PrinterVO} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/printer-vo'
import {CASHDOC, PRICETAG, PrintDocType} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/print-doc-type'
import {PriceTagTemplateVO} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import {PrinterToCashVO} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/printer-to-cash-vo'
import {iUserManagementLocal} from '../../../../protocol/set10/i-user-management-local'
import {UserVO} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import { AppStore } from '../../app-store'
import { UserStore } from '../../user-store'
import { ShopStore } from './shop-store'
import { getStore } from '../../stores-repository'
import {
    APP_STORE,
    USER_STORE,
    SHOP_CASHES_STORE,
    SHOP_STORE
} from '../../stores'
import { withSpinner } from '../../with-spinner'
import {DeviceSettingsType} from '../../../core/values'
import {ShopCashesStore} from './shop-cashes-store'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { sortBy } from 'lodash'
import uuid from 'uuid'
import { fieldLengthValidator } from '../../../../utils/form-validation/validators/length-validator'

export class ShopPrintersStore {

    @observable
    printers: PrinterVO[] = []
    @observable
    printersAddresses: string[] = []
    @observable
    printerModels: EquipmentModelVO[] = []
    @observable
    priceTags: PriceTagTemplateVO[] = []
    @observable
    printerCashLinks: PrinterToCashVO[] = []
    @observable
    users: UserVO[] = []
    @observable
    printerSettings: PrinterVO
    @observable
    printerValidation: FormValidation<PrinterVO>
    @observable
    printerSettingsType: DeviceSettingsType
    @observable
    printerDevicesDialogOpen: boolean = false
    @observable
    paginatorKey: string = ''
    @observable
    paginationState: PaginationState = undefined

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private shopStore: ShopStore = getStore(SHOP_STORE)
    private shopCashesStore: ShopCashesStore = getStore(SHOP_CASHES_STORE)

    @computed
    get sortedPrinters(): PrinterVO[]  {
        return sortBy(this.printers, 'name')
    }

    // Printers page

    fetchAllPrintersData = (): Promise<void> => {
        return withSpinner([
            this.fetchPrinters,
            this.fetchPrinterModels,
            this.fetchOSPrinters,
            this.fetchPriceTags,
            this.shopCashesStore.fetchCashes,
            this.fetchAllUsers,
        ])
    }

    fetchPrinters = (): Promise<void> => {
        const request = this.appStore.isCentrum
            ? printersManagerLocal.getPrintersLite(this.shopStore.shop.number)
            : printersManagerLocal.listPrintersLite(this.userStore.session)

        return request
            .then(printers => {
                runInAction(() => {
                    this.printers = printers
                    this.paginatorKey = uuid()
                })
            })
    }

    fetchOSPrinters = (): Promise<void> => {
        return printersManagerLocal.getOSPrinters(this.userStore.session)
            .then(osPrinters => {
                runInAction(() => {
                    this.printersAddresses = osPrinters.map(item => item.address)
                })
            })
    }

    fetchPrinterModels = (): Promise<void> => {
        return iEquipmentManagerLocal.getAllRegisteredEquipmentModelsOfClass(this.userStore.session, 'Printers', this.appStore.locale)
            .then(printerModels => {
                runInAction(() => {
                    this.printerModels = printerModels
                })
            })
    }

    fetchPrinterCashLinks = (): Promise<void> => {
        return printersManagerLocal.getPrinterToCashLinks()
            .then(printerCashLinks => {
                runInAction(() => {
                    this.printerCashLinks = printerCashLinks
                })
            })
    }

    fetchAllUsers = (): Promise<void> => {
        return iUserManagementLocal.getActiveUserList(this.userStore.session)
            .then(users => {
                runInAction(() => {
                    this.users = users
                })
            })
    }

    fetchPriceTags = (): Promise<void> => {
        return printersManagerLocal.getAllPriceTags(this.userStore.session)
            .then(priceTags => {
                runInAction(() => {
                    this.priceTags = priceTags
                })
            })
    }

    // Вычисляем данные для отображения компонента выбора пользователей принтера
    @computed
    get printerSettingsUserData() {
        if (!this.printerSettings) return {allOtherUsers: [], printerNameByUserId: {}}

        let allOtherUsers: UserVO[] = []
        let printerNameByUserId: {[id: string]: string} = {}
        this.printers.forEach(printer => {
            if (printer.id === this.printerSettings.id) return

            if (printer.users && printer.users.length > 0) {
                allOtherUsers = allOtherUsers.concat(toJS(printer.users))
                printer.users.forEach(user => {
                    printerNameByUserId[user.id] = printer.name
                })
            }
        })
        return {
            allOtherUsers,
            printerNameByUserId
        }
    }

    // Вычисляем данные для отображения компонента выбора касс принтера
    @computed
    get printerSettingsCashData() {
        if (!this.printerSettings) return {allOtherCashes: [], printerNameByCashId: {}}

        let allOtherCashes: CashVO[] = []
        let printerNameByCashId: {[id: string]: string} = {}
        this.printers.forEach(printer => {
            if (printer.id === this.printerSettings.id) return

            if (printer.cashes && printer.cashes.length > 0) {
                allOtherCashes = allOtherCashes.concat(toJS(printer.cashes))
                printer.cashes.forEach(cash => {
                    printerNameByCashId[cash.id] = printer.name
                })
            }
        })

        // Если касса законсервирована - запрещаем её привязывать
        this.shopCashesStore.cashes.forEach(cash => {
            if (cash.status === CASH_CLOSED) {
                let index = allOtherCashes.findIndex(otherCash => otherCash.id === cash.id)
                if (index === -1) {
                    allOtherCashes.push(toJS(cash))
                }
            }
        })

        return {
            allOtherCashes,
            printerNameByCashId
        }
    }

    @action
    showPrinterDevicesDialog = (): void => {
        this.printerDevicesDialogOpen = true
    }

    @action
    closePrinterDevicesDialog = (): Promise<void> => {
        this.printerDevicesDialogOpen = false

        // При закрытии диалога надо обновить доступные модели -
        return this.fetchPrinterModels()
    }

    @action
    editPrinter = (printer: PrinterVO): void => {
        if (printer) {
            this.printerSettings = toJS(printer)
            this.printerSettingsType = DeviceSettingsType.EDIT

            // Надо пофиксить значение прайстегов - т.к. они пришли не до конца заполненные, без этого упадет при сохранении
            if (printer.printType === PRICETAG && printer.priceTags && printer.priceTags.length > 0) {
                this.printerSettings.priceTags = printer.priceTags.map(priceTag => {
                    let basePriceTag = this.priceTags.find(item => item.id === priceTag.id)
                    if (basePriceTag) {
                        priceTag.displayColor = basePriceTag.displayColor
                        priceTag.type = basePriceTag.type
                        priceTag.width = basePriceTag.width
                        priceTag.height = basePriceTag.height
                        priceTag.lastUpdate = basePriceTag.lastUpdate
                    }

                    return priceTag
                })
            }

            this.createPrinterValidation()
        }
    }

    @action
    addPrinter = (): void => {
        this.printerSettings = this.getBlankPrinterSettings()
        this.printerSettingsType = DeviceSettingsType.NEW
        this.createPrinterValidation(true)
    }

    getBlankPrinterSettings = (): PrinterVO => {
        const firstModel = this.printerModels.length > 0 ? this.printerModels[0] : null

        let printerName = t('shopPage.printer')
        let regexp = new RegExp(`^${printerName} (\\d+)$`)

        let usedNumbers: boolean[] = []
        this.printers.forEach(printer => {
            let match = regexp.exec(printer.name)
            if (match && Number(match[1])) {
                usedNumbers[Number(match[1])] = true
            }
        })

        let nextNumber: number
        for (nextNumber = 1; nextNumber < usedNumbers.length; nextNumber++) {
            if (!usedNumbers[nextNumber]) {
                break
            }
        }
        let nextName = `${printerName} ${nextNumber}`

        return createPrinterVO({
            id: -1,
            equipmentModel: firstModel,
            model: '',
            name: nextName,
            address: '',
            dpi: 0,
            priceTags: [],
            templateIds: [],
            type: '',
            printType: this.appStore.isCentrum ? CASHDOC : PRICETAG,
            width: 0,
            height: 0,
            leftMargin: 0,
            rightMargin: 0,
            topMargin: 0,
            bottomMargin: 0,
            cashes: [],
            defaultFeedResolution: 0,
            defaultCrossFeedResolution: 0,
            users: [],
            shop: this.appStore.isCentrum ? this.shopStore.shop.number : undefined
        })
    }

    @action
    createPrinterValidation = (creation: boolean = false): void => {
        this.printerValidation = new FormValidation<PrinterVO>(
            this.printerSettings,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        fieldLengthValidator({ max: 60 }),
                        value => {
                            const uniqueFunction = uniqueField(this.printers
                                .filter(p => p.id !== this.printerSettings.id)
                                .map(p => p.name))
                            return uniqueFunction(value.trim())
                        }
                    ]
                },
                {
                    field: 'equipmentModel',
                    rules: [
                        requiredField
                    ]
                }
            ],
            creation
        )
    }

    @action
    updatePrinter = (changes: Partial<PrinterVO>) => {
        Object.keys(changes).forEach(k => {
            this.printerSettings[k] = changes[k]
        })
    }

    @action
    updatePrinterType = (value: PrintDocType) => {
        this.printerSettings.printType = value
        if (value === CASHDOC) {
            this.printerSettings.priceTags = []
        } else {
            this.printerSettings.cashes = []
        }
    }

    @action
    closePrinterSettings = () => {
        this.printerSettings = null
        this.printerSettingsType = null
        this.printerValidation = null
    }

    deletePrinter = (printer: PrinterVO): Promise<void> => {
        return withSpinner(printersManagerLocal.deletePrinter(this.userStore.session, printer)
            .then(result => {
                if (result) {
                    runInAction(() => {
                        const deletedId = printer.id
                        const deleteIndex = this.printers.findIndex(item => item.id === deletedId)
                        if (deleteIndex !== -1) {
                            this.printers.splice(deleteIndex, 1)
                        }
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    savePrinter = (printer: PrinterVO): Promise<void> => {
        const newPrinter = {
            ...printer,
            name: printer.name.trim()
        }
        return withSpinner(printersManagerLocal.updatePrinter(this.userStore.session, newPrinter)
            .then(savedPrinter => {
                if (savedPrinter) {
                    runInAction(() => {
                        let id = savedPrinter.id
                        let index = this.printers.findIndex(item => item.id === id)
                        if (index !== -1) {
                            this.printers[index] = savedPrinter
                        }
                        this.paginatorKey = uuid()
                    })
                }
            }))
    }

    saveNewPrinter = (printer: PrinterVO): Promise<void> => {
        const newPrinter = {
            ...printer,
            name: printer.name.trim()
        }
        return withSpinner(printersManagerLocal.addPrinterLite(this.userStore.session, newPrinter)
            .then(addedPrinter => {
                if (addedPrinter) {
                    runInAction(() => {
                        this.printers.push(addedPrinter)
                    })
                    this.paginatorKey = uuid()
                }
            }))
    }

    @action
    setPaginationState = (paginationState: PaginationState) => {
        this.paginationState = paginationState
    }

    @action
    reset = (): void => {
        this.printers = []
        this.printersAddresses = []
        this.printerModels = []
        this.priceTags = []
        this.printerCashLinks = []
        this.users = []
        this.printerSettings = undefined
        this.printerValidation = undefined
        this.printerSettingsType = undefined
        this.printerDevicesDialogOpen = false
        this.paginatorKey = ''
        this.paginationState = undefined
    }
}
