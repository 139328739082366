import React from 'react'
import classNames from 'classnames'
import { t } from 'i18next'

const styles = require('./custom-tooltip.scss')
const PRODUCT = require('../../../../../assets/images/icons/components/product.svg')
const PRODUCT_LOW = require('../../../../../assets/images/icons/components/product-low.svg')

interface Product {
    value: { int: string, float: string },
    lowestInSet?: boolean
}

interface CustomTooltipProps {
    set1: Product[]
    set2: Product[]
}

export const CustomTooltip: React.FunctionComponent<CustomTooltipProps> = ({ set1, set2 }) => (
    <div className={styles.tooltip}>
        <div className={styles.set}>
            <div className={styles.products}>
                {set1.map(({ value, lowestInSet }) => (
                    <div className={styles.product}>
                        <span className={styles.icon}>
                            <img src={lowestInSet ? PRODUCT_LOW : PRODUCT} />
                            {lowestInSet && <span className={styles.percent}>%</span>}
                        </span>
                        <p className={classNames(styles.price, { [styles.lowest]: lowestInSet })}>
                            {value.int}
                            <sup className={styles.cents}>{value.float}</sup>
                        </p>
                    </div>
                ))}
            </div>
            <div className={styles.scope} />
            <p className={styles.setName}>
                {t('loyaltySettings.setName1')}
            </p>
        </div>
        <div className={styles.set}>
            <div className={styles.products}>
                {set2.map(({ value, lowestInSet }) => (
                    <div className={styles.product}>
                        <span className={styles.icon}>
                            <img src={lowestInSet ? PRODUCT_LOW : PRODUCT} />
                            {lowestInSet && <span className={styles.percent}>%</span>}
                        </span>
                        <p className={classNames(styles.price, { [styles.lowest]: lowestInSet })}>
                            {value.int}
                            <sup className={styles.cents}>{value.float}</sup>
                        </p>
                    </div>
                ))}
            </div>
            <div className={styles.scope} />
            <p className={styles.setName}>
                {t('loyaltySettings.setName2')}
            </p>
        </div>
    </div>
)
