/**
 * Десериализирует base64 с учетом кириллицы и спец символов
 * @param source
 * NB! Не используем atob напрямую! Это приводит к проблемам как тут SFM-807
 */
export function fromBase64(source: string): string {
    if (!source) return ''
    return decodeURIComponent(escape(atob(source)))
}

/**
 * Сериализирует в base64 c учетом кириллицы и спец символов
 * @param source
 * NB! Не используем btoa напрямую! Это приводит к проблемам как тут SFM-807
 */
export function toBase64(source: string): string {
    if (!source) return btoa('')
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(source)
        .replace(/%([0-9A-F]{2})/g,
        (match: any, p1: any) => {
            const charCode: any = '0x' + p1
            return String.fromCharCode(charCode)
        }))
}

// tslint:disable:no-bitwise
export function bytesToBase64(bytes: Uint8Array) {
    let result = ''
    let i
    let l = bytes.length
    for (i = 2; i < l; i += 3) {
        result += base64alphabet[bytes[i - 2] >> 2]
        result += base64alphabet[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)]
        result += base64alphabet[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)]
        result += base64alphabet[bytes[i] & 0x3F]
    }
    if (i === l + 1) { // 1 octet missing
        result += base64alphabet[bytes[i - 2] >> 2]
        result += base64alphabet[(bytes[i - 2] & 0x03) << 4]
        result += '=='
    }
    if (i === l) { // 2 octets missing
        result += base64alphabet[bytes[i - 2] >> 2]
        result += base64alphabet[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)]
        result += base64alphabet[(bytes[i - 1] & 0x0F) << 2]
        result += '='
    }
    return result
}

const base64alphabet = (() => {
    let alphabet = []
    let A = 'A'.charCodeAt(0)
    let a = 'a'.charCodeAt(0)
    let n = '0'.charCodeAt(0)
    for (let i = 0; i < 26; ++i) {
        alphabet.push(String.fromCharCode(A + i))
    }
    for (let i = 0; i < 26; ++i) {
        alphabet.push(String.fromCharCode(a + i))
    }
    for (let i = 0; i < 10; ++i) {
        alphabet.push(String.fromCharCode(n + i))
    }
    alphabet.push('+')
    alphabet.push('/')
    return alphabet
})()
