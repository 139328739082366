import React, { Component } from 'react'
import { TabInfo, Tabs } from '../../../../components/tabs/tabs'
import { t } from 'i18next'
import { isNil } from 'lodash'
import { PriceTagsEslConfiguration } from './price-tags-esl-configuration'
import { PriceTagsEslCoverage } from './price-tags-esl-coverage'
import { ESLUploadErrors } from './esl-upload-errors'
import { inject, observer } from 'mobx-react'
import { APP_BAR_STORE, NAVIGATION_MENU_STORE, USER_STORE } from '../../../store/stores'
import { UserStore } from '../../../store/user-store'
import { SETRETAILX_ESL_ADMINISTRATION, SETRETAILX_ESL_HAND_SEND } from '../../../core/privileges/privileges'
import { RouteComponentProps, withRouter } from 'react-router'
import { goTo, RouteChangeHandler } from '../../../utils/router-util'
import { ELECTRONIC_PRICE_TAGS, PRICE_TAGS } from '../../../core/app-routes'
import { AppBarStore, MENU } from '../../../store/app-bar-store'
import { getStore } from '../../../store/stores-repository'
import { NavigationMenuStore } from '../../../store/navigation-menu-store'

const styles = require('./electronic-price-tags.scss')

export type ESLTab = 'configuration' | 'coverage' | 'errors'
export const CONFIGURATION_TAB: ESLTab = 'configuration'
export const COVERAGE_TAB: ESLTab = 'coverage'
export const ERRORS_TAB: ESLTab = 'errors'
export const ALL_ESL_TABS = [
    CONFIGURATION_TAB,
    COVERAGE_TAB,
    ERRORS_TAB,
]

// Для того чтобы сохранить боковое меню и не срабатывал обработчик захода в глубокие страницы
export const ESL_ROUTE_CHANGE_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${PRICE_TAGS}${ELECTRONIC_PRICE_TAGS}/?\\w+?$`),
    onEnter: (newRoute: string, prevRoute: string) => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)
        navigationMenuStore.synchronizeWithRoute()
        appBarStore.updateState({
            title: t('set10.electronicPriceTags'),
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => {
                navigationMenuStore.setOpen(!navigationMenuStore.open)
            }
        })
    },
}

export interface ESLRouteParams {
    tab?: ESLTab
}

export interface ElectronicPriceTagsProps extends RouteComponentProps<ESLRouteParams> {
    user?: UserStore
}

@inject(USER_STORE)
@observer
export class ElectronicPriceTags extends Component<ElectronicPriceTagsProps> {

    get selectedTab(): number {
        const { havePrivilege } = this.props.user
        let { tab = CONFIGURATION_TAB } = this.props.match.params
        if (!ALL_ESL_TABS.includes(tab)) tab = CONFIGURATION_TAB

        const indexByTab = {}
        indexByTab[CONFIGURATION_TAB] = 0
        if (havePrivilege(SETRETAILX_ESL_ADMINISTRATION)) {
            indexByTab[COVERAGE_TAB] = 1
            indexByTab[ERRORS_TAB] = 2
        } else {
            indexByTab[ERRORS_TAB] = 1
        }

        return isNil(indexByTab[tab]) ? 0 : indexByTab[tab]
    }

    onTabChange = (tab: TabInfo): void => {
        goTo(`${PRICE_TAGS}${ELECTRONIC_PRICE_TAGS}/${tab.data}`)
    }

    render() {
        const { havePrivilege, haveAnyPrivilegeFromList } = this.props.user

        if (!haveAnyPrivilegeFromList([SETRETAILX_ESL_ADMINISTRATION, SETRETAILX_ESL_HAND_SEND])) {
            return null
        }

        let tabsLabels = [
            {
                data: CONFIGURATION_TAB,
                label: t('priceTagsEsl.eslConfiguration'),
            },
            {
                data: ERRORS_TAB,
                label: t('priceTagsEsl.eslUploadErrors'),
            },
        ]

        const pages = [
            <PriceTagsEslConfiguration key="configuration"/>,
            <ESLUploadErrors key="errors"/>
        ]

        if (havePrivilege(SETRETAILX_ESL_ADMINISTRATION)) {
            tabsLabels.splice(1, 0, {
                data: COVERAGE_TAB,
                label: t('priceTagsEsl.eslCoverage'),
            })
            pages.splice(1, 0, <PriceTagsEslCoverage key="coverage"/>)
        }

        return (
            <div
                id="priceTagsEslSettings"
                key={tabsLabels[this.selectedTab].data}
                className={styles.container}
            >
                <Tabs
                    id="priceTagsEslSettingsTabs"
                    items={tabsLabels}
                    defaultSelectedIndex={this.selectedTab}
                    onTabChange={this.onTabChange}
                    className={styles.tabs}
                    tabContentClassName={styles.tab}
                >
                    { pages }
                </Tabs>
            </div>
        )
    }
}

export default withRouter(ElectronicPriceTags)
