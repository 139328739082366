import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import LinkIcon from '@material-ui/icons/Link'
import EditIcon from '@material-ui/icons/Edit'
import classNames from 'classnames'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { SelectInput, DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { AppStore } from '../../../store/app-store'
import { ShopStore } from '../../../store/shops/shop-settings/shop-store'
import { APP_STORE, SHOP_STORE } from '../../../store/stores'
import { FormatSettingsDialog } from './format-settings-dialog'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./information-step.scss')

export interface InformationStepProps extends React.HTMLProps<HTMLDivElement> {
    app?: AppStore
    shopStore?: ShopStore
}

@inject(APP_STORE)
@inject(SHOP_STORE)
@observer
export class InformationStep extends React.Component<InformationStepProps> {
    renderConnectionToCentrumDialog() {
        const {
            cancelCentrumAddressChanges, connectionToCentrumChanged, emitNewShopEvent, hideConnectionToCentrumDialog,
            newCentrumAddress, newCentrumAddressIsValid, newCentrumAddressCanBeReached, shouldUseCentrumAddress,
            saveConnectionToCentrumChanges, setShouldUseCentrumAddress, showConnectionStatus,
            testConnectionToCentrum, setNewCentrumAddress,
        } = this.props.shopStore

        return (
            <Dialog
                id="connectionToCentrumDialog"
                open
                onClose={() => {
                    cancelCentrumAddressChanges()
                    hideConnectionToCentrumDialog()
                }}
                className={styles.connectionToCentrumDialog}
                PaperProps={{className: styles.dialogPaper}}
            >
                <DialogTitle>
                    {t('shopPage.centrumConnection.connectionTitle')}
                </DialogTitle>
                <DialogContent className={styles.content}>
                    <div className={styles.blockWithPadding}>
                        <Checkbox
                            id="useCentrumConnectionCheckbox"
                            checked={shouldUseCentrumAddress || false}
                            onChange={e => setShouldUseCentrumAddress(e.target.checked)}
                            label={t('shopPage.centrumConnection.useConnection')}
                            color="primary"
                        />
                        <TextField
                            id="centrumAddressField"
                            disabled={!shouldUseCentrumAddress}
                            label={t('shopPage.centrumConnection.addressField')}
                            value={newCentrumAddress}
                            autoFocus
                            onValueChange={address => setNewCentrumAddress(address)}
                            errorText={shouldUseCentrumAddress && !newCentrumAddressIsValid
                                && t('shopPage.centrumConnection.wrongFormat')}
                        />
                        <div className={styles.connectionStatusContainer}>
                            {showConnectionStatus && (
                                <>
                                    {newCentrumAddressCanBeReached ? (
                                        <LinkIcon
                                            className={styles.connectionStatusIcon}
                                        />
                                    ) : (
                                        <LinkOffIcon
                                            className={styles.connectionStatusIcon}
                                        />
                                    )}
                                    <span
                                        className={classNames(styles.connectionStatusText, {
                                            [styles.connectionFailure]: !newCentrumAddressCanBeReached
                                        })}
                                    >
                                        {newCentrumAddressCanBeReached ? (
                                            t('shopPage.centrumConnection.addressCanBeReached')
                                        ) : (
                                            t('shopPage.centrumConnection.addressCanNotBeReached')
                                        )}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button
                        id="checkConnectionButton"
                        className={styles.checkConnectionButton}
                        disabled={!shouldUseCentrumAddress || !newCentrumAddressIsValid}
                        onClick={() => testConnectionToCentrum(newCentrumAddress)}
                        color="primary"
                    >
                        {t('shopPage.centrumConnection.checkConnectionButton')}
                    </Button>
                    <Button
                        id="newShopButton"
                        disabled={!shouldUseCentrumAddress || !newCentrumAddressIsValid}
                        onClick={() => {
                            emitNewShopEvent()
                                .then(() => hideConnectionToCentrumDialog())
                        }}
                        color="primary"
                    >
                        {t('shopPage.centrumConnection.newShopButton')}
                    </Button>
                    <Button
                        id="dialogSaveButton"
                        className={styles.saveButton}
                        disabled={!connectionToCentrumChanged || shouldUseCentrumAddress && !newCentrumAddressIsValid}
                        onClick={() => {
                            saveConnectionToCentrumChanges()
                                .then(() => hideConnectionToCentrumDialog())
                        }}
                        color="primary"
                    >
                        {t('common.save')}
                    </Button>
                    <Button
                        id="dialogCancelButton"
                        onClick={() => {
                            cancelCentrumAddressChanges()
                            hideConnectionToCentrumDialog()
                        }}
                        color="primary"
                    >
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        const {
            shop, emptyJuristicPersonsMessage, updateRegion, updateCity, updateCityName, updateFormat, juristicPersons,
            openFormatsSettings, editingConnectionToCentrum, connectedToCentrum, showConnectionToCentrumDialog,
            updateCurrentShop, updatePhysicalAddress, shopInfoValidation, physicalAddressError, topologyMap,
        } = this.props.shopStore
        const {
            number: shopNumber, name, twentyFourHour, city, format, address, virtual
        } = shop
        const {regions, cities, formats} = topologyMap

        const {isCentrum} = this.props.app

        const citiesInCurrentRegion = cities.filter(c => c.region.id === city.region.id)

        const validators = shopInfoValidation.getAllValidators()

        return (
            <>
                <FormatSettingsDialog/>

                {editingConnectionToCentrum && this.renderConnectionToCentrumDialog()}

                <Grid
                    className={styles.informationStepContent}
                    container
                    spacing={2}
                >
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="shopNumberField"
                            required
                            disabled={!isCentrum && connectedToCentrum || Boolean(shop.id)}
                            label={t('shopPage.shopNumber')}
                            value={String(shopNumber)}
                            errorText={validators.number.touched && validators.number.firstError}
                            onValueChange={shopNumber => updateCurrentShop({number: Number(shopNumber) || 0})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="shopNameField"
                            required
                            disabled={!isCentrum && connectedToCentrum}
                            label={t('shopPage.shopName')}
                            value={name}
                            errorText={validators.name.touched && validators.name.firstError}
                            onValueChange={name => updateCurrentShop({name})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="shopAddressField"
                            required
                            disabled={!isCentrum && connectedToCentrum}
                            label={t('shopPage.shopAddress')}
                            value={address || ''}
                            errorText={validators.address.touched && validators.address.firstError}
                            onValueChange={address => updateCurrentShop({address})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="shopPhysicalAddressField"
                            disabled={!isCentrum && connectedToCentrum || isEmpty(juristicPersons)}
                            helperText={isEmpty(juristicPersons) ? emptyJuristicPersonsMessage : null}
                            label={t('shopPage.shopPhysicalAddress')}
                            value={isEmpty(juristicPersons) ? '' : juristicPersons[0].physicalAddress}
                            errorText={physicalAddressError}
                            onValueChange={physicalAddress => updatePhysicalAddress(physicalAddress)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} container alignItems="center">
                        <Checkbox
                            id="shopTwentyFourHourField"
                            className={styles.checkbox}
                            disabled={!isCentrum && connectedToCentrum}
                            checked={twentyFourHour || false}
                            onChange={e => updateCurrentShop({twentyFourHour: e.target.checked})}
                            label={t('shopPage.twentyFourHour')}
                            color="primary"
                        />
                    </Grid>
                    {!isCentrum &&
                        <Grid item xs={12} md={6}>
                            <div className={styles.connectionStatusContainer}>
                                {connectedToCentrum ? (
                                    <LinkIcon
                                        className={styles.connectionStatusIcon}
                                    />
                                ) : (
                                    <LinkOffIcon
                                        className={styles.connectionStatusIcon}
                                    />
                                )}
                                <span
                                    className={classNames(styles.connectionStatusText, {
                                        [styles.connectionFailure]: !connectedToCentrum
                                    })}
                                >
                                    {connectedToCentrum ? (
                                        t('shopPage.centrumConnection.isConnected')
                                    ) : (
                                        t('shopPage.centrumConnection.notConnected')
                                    )}
                                </span>
                                <IconButton
                                    id="editConnectedToCentrum"
                                    className={styles.connectionStatusEditButton}
                                    onClick={showConnectionToCentrumDialog}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </div>
                        </Grid>
                    }
                    {
                        isCentrum && (
                            <Grid item xs={12} md={6} container alignItems="center">
                                <Checkbox
                                    id="shopVirtualField"
                                    className={styles.checkbox}
                                    checked={virtual || false}
                                    onChange={e => updateCurrentShop({virtual: e.target.checked})}
                                    label={t('shopPage.virtual')}
                                    color="primary"
                                />
                            </Grid>
                        )
                    }
                    {isCentrum &&
                        <Grid item xs={12} md={6}>
                            <SelectInput<DefaultSelectOption<number>>
                                id="regionNameField"
                                disabled={isEmpty(regions)}
                                label={t('shopPage.regionName')}
                                value={city.region.id || ''}
                                onSelect={selectedOption => updateRegion(selectedOption.value)}
                                options={regions.map(region => ({ label: region.name, value: region.id }))}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        {isCentrum ? (
                            <SelectInput<DefaultSelectOption<number>>
                                id="cityNameField"
                                disabled={isEmpty(citiesInCurrentRegion)}
                                label={t('shopPage.cityName')}
                                value={city.id || ''}
                                onSelect={selectedOption => updateCity(selectedOption.value)}
                                options={citiesInCurrentRegion.map(city => ({ label: city.name, value: city.id }))}
                            />
                        ) : (
                            <TextField
                                id="cityNameField"
                                disabled={!isCentrum && connectedToCentrum}
                                label={t('shopPage.cityName')}
                                value={city.name}
                                fullWidth
                                onValueChange={cityName => updateCityName(cityName)}
                            />
                        )}
                    </Grid>
                    {isCentrum &&
                        <>
                            <Grid
                                container
                                item
                                xs={12}
                                md={6}
                                wrap="nowrap"
                                alignItems="center"
                            >
                                <SelectInput<DefaultSelectOption<number>>
                                    id="formatNameField"
                                    disabled={isEmpty(formats)}
                                    label={t('shopPage.formatName')}
                                    value={format.id || ''}
                                    onSelect={selectedOption => updateFormat(selectedOption.value)}
                                    options={formats.map(format => ({ label: format.name, value: format.id }))}
                                />
                                <AdaptiveIconButton
                                    id="formatEditButton"
                                    adaptive={false}
                                    className={styles.editFormats}
                                    label={t('common.edit')}
                                    onClick={openFormatsSettings}
                                >
                                    <EditIcon/>
                                </AdaptiveIconButton>
                            </Grid>
                        </>
                    }
                </Grid>
            </>
        )
    }
}
