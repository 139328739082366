import { TopologyFilterVO } from '../../../set-retail10-commons/data-structs-module/topology-filter-vo'

export interface DirectivePrintingTopologyVO {
    '@class': 'ru.crystals.setretailx.printing.directive.vo.DirectivePrintingTopologyVO'
    id?: number
    name?: string
    description?: string
    topologyFilters?: TopologyFilterVO[]
}

export const createDirectivePrintingTopologyVO = (initialization: Partial<DirectivePrintingTopologyVO>): DirectivePrintingTopologyVO => {
    if (!initialization) return null
    
    let result: DirectivePrintingTopologyVO = {
        id: initialization.id,
        name: initialization.name,
        description: initialization.description,
        topologyFilters: initialization.topologyFilters,
        '@class': 'ru.crystals.setretailx.printing.directive.vo.DirectivePrintingTopologyVO',
    }
    
    return result
}
