import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Stepper from '@material-ui/core/Stepper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Autorenew from '@material-ui/icons/Autorenew'
import Delete from '@material-ui/icons/Delete'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import classNames from 'classnames'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { TOOLTIP_DELAY } from '../../../../utils/default-timeouts'
import { SettingsActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/settings-action-panel'
import { TopologyFilter, TopologyNode } from '../../../components/topology-filter/topology-filter'
import { convertConditionsToNodesOfMap, convertHierarchicalNodesToConditions } from '../../../components/topology-filter/topology-filter-util'
import { AppStore } from '../../../store/app-store'
import { UserSettingsStore } from '../../../store/staff/user-settings-store'
import { APP_STORE, USER_SETTINGS_STORE } from '../../../store/stores'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./user-settings.scss')

export interface RoleSettingsURLParams {
    id?: string
}

export interface UserSettingsProps extends RouteComponentProps<RoleSettingsURLParams>, PaperProps {
    app?: AppStore
    userSettingsStore?: UserSettingsStore
}

interface UserSettingsState {
    currentStep: EditSteps
    showPassword: boolean
    editingLogin: boolean
    editingPassword: boolean
    editingRoles: boolean
}

export enum EditSteps {
    USER = 0,
    ROLES = 1,
    TOPOLOGY = 2,
}

@inject(APP_STORE)
@inject(USER_SETTINGS_STORE)
@observer
export class UserSettings extends React.Component<UserSettingsProps, UserSettingsState> {
    state: UserSettingsState = {
        currentStep: EditSteps.USER,
        showPassword: false,
        editingLogin: false,
        editingPassword: false,
        editingRoles: false,
    }

    componentDidMount(): void {
        const { openUser } = this.props.userSettingsStore
        openUser(this.props.match.params.id)
    }

    userTopologyChangeHandler = (topology: TopologyNode[]): void => {
        const {updateUserTopology} = this.props.userSettingsStore
        updateUserTopology(convertHierarchicalNodesToConditions(topology))
    }

    userRolesChangeHandler = (roleId: number, newValue: boolean) => () => {
        const {updateUserRole} = this.props.userSettingsStore
        this.setState({editingRoles: true})
        updateUserRole(roleId, newValue)
    }

    handleAllUserRoles = () => {
        const {updateAllUserRoles, allRolesChecked} = this.props.userSettingsStore
        this.setState({editingRoles: true})
        updateAllUserRoles(!allRolesChecked)
    }

    handleNoneUserRoles = () => {
        const {updateAllUserRoles} = this.props.userSettingsStore
        this.setState({editingRoles: true})
        updateAllUserRoles(false)
    }

    handleMouseDownPassword = (event: any) => {
        event.preventDefault()
    }

    handleClickShowPassword = () => {
        this.setState(prevState => ({ showPassword: !prevState.showPassword }))
    }

    generatePasswordHandler = (): void => {
        const {generatePassword, updateUser} = this.props.userSettingsStore
        updateUser({password: generatePassword()})
        this.setState({ showPassword: true})
    }

    handleStep = (step: EditSteps) => () => {
        this.setState({currentStep: step })
    }

    handleNextStep = () => {
        this.setState(prevState => ({currentStep: prevState.currentStep + 1}))
    }

    handleStepBack = () => {
        this.setState(prevState => ({currentStep: prevState.currentStep - 1}))
    }

    getUserEditPaper = (disabled: boolean) => {
        const {user, newUser, updateUser, validation } = this.props.userSettingsStore
        const {showPassword} = this.state

        const loginValidator = validation.getValidationFor('login')
        const passwordValidator = validation.getValidationFor('password')
        const firstNameValidator = validation.getValidationFor('firstName')
        const middleNameValidator = validation.getValidationFor('middleName')
        const lastNameValidator = validation.getValidationFor('lastName')
        const positionValidator = validation.getValidationFor('position')
        const emailValidator = validation.getValidationFor('email')

        const passwordButtons = (
            <InputAdornment position="end">
                <Tooltip
                    enterDelay={TOOLTIP_DELAY}
                    title={showPassword ? t('staff.hidePassword') : t('staff.showPassword')}
                >
                    <IconButton
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </Tooltip>
                {disabled ? null : (
                    <Tooltip
                        enterDelay={TOOLTIP_DELAY}
                        title={t('staff.generateNewPassword')}
                    >
                        <IconButton
                            onClick={this.generatePasswordHandler}
                        >
                            <Autorenew/>
                        </IconButton>
                    </Tooltip>
                )}
            </InputAdornment>
        )

        return (
            <div>
                <p className={styles.title}>{t('staff.user')}</p>
                <Grid
                    className={styles.fieldsRow}
                    container
                    spacing={2}
                >
                    <Grid item xs={12} md={12} >
                        <TextField
                            id="loginInput"
                            required={newUser}
                            className={styles.login}
                            label={t('staff.login')}
                            disabled={disabled || !newUser}
                            value={user.login}
                            errorText={loginValidator.touched ? loginValidator.firstError : ''}
                            onValueChange={login => updateUser({ login })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="loginPassword"
                            required={!user.adAuth && newUser}
                            className={styles.password}
                            disabled={disabled || user.adAuth}
                            errorText={passwordValidator.touched && !user.adAuth && passwordValidator.firstError}
                            value={user.adAuth ? '' : user.password}
                            label={t('staff.password')}
                            type={showPassword ? 'text' : 'password'}
                            onChange={event => updateUser({ password: event.target.value })}
                            fullWidth
                            endAdornment={!user.adAuth && passwordButtons}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Checkbox
                            id="activeDirectoryCheckBox"
                            label={t('staff.activeDirectoryAuthentication')}
                            checked={user.adAuth}
                            disabled={disabled}
                            onValueChange={checked => updateUser({adAuth: checked})}
                        />
                    </Grid>
                </Grid>

                <Divider/>

                <p className={styles.title}>{t('staff.userInfo')}</p>
                <Grid
                    className={styles.fieldsRow}
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="firstNameInput"
                            className={styles.userField}
                            label={t('staff.firstName')}
                            value={user.firstName}
                            onValueChange={firstName => updateUser({ firstName })}
                            disabled={disabled}
                            errorText={firstNameValidator.touched ? firstNameValidator.firstError : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="lastNameInput"
                            className={styles.userField}
                            label={t('staff.lastName')}
                            value={user.lastName}
                            onValueChange={lastName => updateUser({ lastName })}
                            disabled={disabled}
                            errorText={lastNameValidator.touched ? lastNameValidator.firstError : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="middleNameInput"
                            className={styles.userFieldHalf}
                            label={t('staff.middleName')}
                            value={user.middleName}
                            onValueChange={middleName => updateUser({ middleName })}
                            disabled={disabled}
                            errorText={middleNameValidator.touched ? middleNameValidator.firstError : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="emailInput"
                            className={styles.userField}
                            label={t('staff.email')}
                            value={user.email}
                            onValueChange={email => updateUser({ email })}
                            disabled={disabled}
                            errorText={emailValidator.touched ? emailValidator.firstError : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="positionInput"
                            className={styles.userField}
                            label={t('staff.userPosition')}
                            value={user.position}
                            onValueChange={position => updateUser({ position })}
                            disabled={disabled}
                            errorText={positionValidator.touched ? positionValidator.firstError : ''}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }

    render() {
        const {isCentrum, topologyMap} = this.props.app
        const {
            user,
            rolesWithCheckStatus,
            userTopology,
            topologyChanged,
            newUser,
            step1haveErrors,
            step2haveErrors,
            userChanged,
            allRolesChecked,
            saveUser,
            goBack,
        } = this.props.userSettingsStore
        const {currentStep, editingRoles} = this.state

        if (!user) return null

        const dataChanged = topologyChanged || userChanged

        let actionPanel = null
        if (newUser) {
            const lastStep = currentStep === EditSteps.TOPOLOGY
            const firstStep = currentStep === EditSteps.USER
            let nextEnabled = false
            switch (currentStep) {
                case EditSteps.USER:
                    nextEnabled = !step1haveErrors
                    break
                case EditSteps.ROLES:
                    nextEnabled = !step2haveErrors
                    break
                case EditSteps.TOPOLOGY:
                    nextEnabled = !step1haveErrors && !step2haveErrors
                    break
            }

            actionPanel = (
                <SettingsActionPanel
                    saveEnabled={nextEnabled}
                    onSave={lastStep ? saveUser : this.handleNextStep}
                    onCancel={firstStep ? goBack : this.handleStepBack}
                    saveLabel={lastStep ? t('common.save') : t('common.next')}
                    cancelLabel={firstStep ? t('common.cancel') : t('common.back')}
                />
            )
        } else {
            actionPanel = (
                <SettingsActionPanel
                    saveEnabled={!step1haveErrors && !step2haveErrors && dataChanged}
                    onSave={saveUser}
                    onCancel={goBack}
                />
            )
        }

        const atLeastOneRoleChecked = rolesWithCheckStatus.some(role => role.checked)
        const indeterminate = !allRolesChecked && atLeastOneRoleChecked
        let content = null

        switch (currentStep) {
            case EditSteps.USER:
                content = (
                    <Paper className={styles.inputPaper}>
                        {this.getUserEditPaper(false)}
                    </Paper>
                )
                break
            case EditSteps.ROLES:
                let rolesArray = []
                rolesArray.push((
                    <ListItem
                        key="title"
                        id="roles_all"
                        button
                        divider
                        className={styles.roleListItem}
                        onClick={this.handleAllUserRoles}
                    >
                        <Checkbox
                            checked={atLeastOneRoleChecked}
                            tabIndex={-1}
                            disableRipple
                            className={styles.roleCheckBox}
                            indeterminate={indeterminate}
                            nodeMode
                        />
                        <ListItemText
                            primary={<Typography className={styles.allRolesItem}>{t('staff.role')}</Typography>}
                            disableTypography
                        />
                    </ListItem>
                ))
                rolesWithCheckStatus.forEach(role => {
                    rolesArray.push((
                        <ListItem
                            id={`roles_${role.id}`}
                            key={role.id}
                            button
                            divider
                            className={styles.roleListItem}
                            onClick={this.userRolesChangeHandler(role.id, !role.checked)}
                        >
                            <Checkbox
                                checked={role.checked}
                                tabIndex={-1}
                                disableRipple
                                className={styles.roleCheckBox}
                            />
                            <ListItemText primary={role.roleName} />
                        </ListItem>
                    ))
                })
                let rolesCount = user ? user.userRoles.length : 0
                let header = null
                let headerText = null
                if (!editingRoles && rolesCount === 0) {
                    headerText = (
                        <Typography className={styles.pickRoleHeader}>
                            {t('staff.pickRoles')}
                        </Typography>
                    )
                } else {
                    headerText = (
                        <Typography className={styles.pickRoleText} color="inherit">
                            {t('staff.pickedRoles', {count: rolesCount})}
                        </Typography>
                    )
                }

                // Хэдер зависит от того началось ли редакитрование или нет и от количества выбранных элементов
                header = (
                    <div
                        id="rolesHeader"
                        className={classNames({
                            [styles.rolesHeader]: true,
                            [styles.rolesHeaderError]: step2haveErrors && editingRoles,
                            [styles.rolesHeaderCount]: rolesCount > 0,
                        })}
                    >
                        {headerText}
                        {rolesCount > 0 ? (
                            <IconButton
                                id="removeRolesButton"
                                className={styles.trashButton}
                                onClick={this.handleNoneUserRoles}
                            >
                                <Delete/>
                            </IconButton>
                        ) : null}
                    </div>
                )
                content = (
                    <Paper className={styles.inputPaper}>
                        {header}
                        <List id="rolesList">
                            {rolesArray}
                        </List>
                    </Paper>
                )
                break
            case EditSteps.TOPOLOGY:
                if (isCentrum) {
                    content = (
                        <TopologyFilter
                            id="topologyFilter"
                            map={topologyMap}
                            className={styles.inputPaper}
                            defaultSelection={convertConditionsToNodesOfMap(userTopology, topologyMap)}
                            withPaper
                            use24hFlag={false}
                            title={t('staff.shopsBinding')}
                            addButtonLabel={t('staff.addToBindings')}
                            onFiltersChange={this.userTopologyChangeHandler}
                            startExpanded
                            showExpandButton={false}
                        />
                    )
                } else {
                    content = (
                        <Paper className={styles.inputPaper}>
                            {this.getUserEditPaper(true)}
                            <Divider/>
                            <p className={styles.title}>{t('staff.roles')}</p>
                            <List className={styles.list} id="roleNamesList">
                                {rolesWithCheckStatus.map(role => {
                                    if (!role.checked) return null
                                    return (
                                        <ListItem
                                            key={role.id}
                                        >
                                            <ListItemText className={styles.values} primary={role.roleName} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Paper>
                    )
                }
                break
        }

        return (
            <div
                id="userSettings"
                className={styles.userSettings}
            >
                <Paper>
                    <Stepper activeStep={currentStep} nonLinear={!newUser}>
                        <Step completed={!step1haveErrors}>
                            <StepButton id="stepButtonUser"
                                onClick={this.handleStep(EditSteps.USER)}
                            >
                                {t('staff.user')}
                            </StepButton>
                        </Step>
                        <Step completed={!step2haveErrors && (newUser ? currentStep >= EditSteps.ROLES : true)} >
                            <StepButton id="stepButtonRoles" onClick={this.handleStep(EditSteps.ROLES)}>
                                {t('staff.pickRoles')}
                            </StepButton>
                        </Step>
                        <Step completed={newUser ? currentStep === EditSteps.TOPOLOGY : true}>
                            <StepButton id="stepButtonTopology" onClick={this.handleStep(EditSteps.TOPOLOGY)}>
                                {isCentrum ? t('staff.shopsBinding') : t('staff.stepPreview')}
                            </StepButton>
                        </Step>
                    </Stepper>
                </Paper>
                {content}
                {actionPanel}
            </div>
        )
    }
}
