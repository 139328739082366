import { observable, action, runInAction } from 'mobx'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { USER_STORE } from '../stores'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { MENU } from '../../core/product-lists-type'
import { SaleGroupVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/sale-group-vo'

export class ProductsListsStore {
    @observable
    lists: SaleGroupVO[] = null

    private userStore: UserStore = getStore(USER_STORE)

    fetchLists = async (): Promise<void> => {
        const lists = await iProductsManagerLocal.getSaleGroupsByType(this.userStore.session, MENU) || []
        runInAction(() => {
            this.lists = lists
        })
    }

    @action
    reset = (): void => {
        this.lists = null
    }
}
