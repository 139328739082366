import React from 'react'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { t } from 'i18next'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { INT, LEFT, RIGHT, TEXT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { ShopVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import { NotSearched } from '../../../components/stub-with-img/not-searched'
import { NothingFound } from '../../../components/stub-with-img/nothing-found'
import { DialogStore } from '../../../store/dialog-store'
import { ShopsSearchStore } from '../../../store/shops/shops-search'
import { DIALOG_STORE, SHOP_STORE, SHOPS_SEARCH_STORE } from '../../../store/stores'
import { ShopStore } from '../../../store/shops/shop-settings/shop-store'
import { ALL_ITEMS, Paginator } from '@crystalservice/crystals-ui/lib/components/table/paginator'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'

const styles = require('./shops-search-list.scss')

export interface ShopsSearchListProps extends React.HTMLProps<HTMLDivElement> {
    shopsSearchStore?: ShopsSearchStore
    shopStore?: ShopStore
    dialogStore?: DialogStore
}

@inject(DIALOG_STORE)
@inject(SHOP_STORE)
@inject(SHOPS_SEARCH_STORE)
@observer
export class ShopsSearchList extends React.Component<ShopsSearchListProps> {

    handleEdit = (shopId: number) => {
        this.props.shopStore.openShop(shopId)
    }

    showDeletionDialog = (shop: ShopVO) => {
        this.props.dialogStore.showDialog({
            title: t('shopsSearch.shopDeletionTitle'),
            message: t('shopsSearch.shopDeletionMessage', {
                shopName: shop.name
            }),
            mode: DIALOG,
            onYes: () => this.props.shopsSearchStore.deleteShop(shop.id)
        })
    }

    render() {
        const { shops, selectedRegion, selectedCity, selectedFormat, nameAndNumberFilter,
                shopsListSelectorKey, paginationState, setPaginationState
        } = this.props.shopsSearchStore
        const isFiltered: boolean = Boolean(selectedRegion || selectedCity || selectedFormat || nameAndNumberFilter)

        if (!shops) {
            return <Empty message={'...'} className={styles.stub} />
        }

        if (shops.length === 0) {
            if (isFiltered) {
                return (
                    <NothingFound
                        className={styles.stub}
                        titleText={t('shopsSearch.notFound')}
                        hintText={t('shopsSearch.notFoundTip')}
                    />
                )
            }

            return (
                <NotSearched
                    className={styles.stub}
                    titleText={t('shopsSearch.emptyShops')}
                    hintText=""
                />
            )
        }

        return (
            <Paper className={styles.shopsSearchList}>
                <Paginator<ShopVO>
                    mode={ALL_ITEMS}
                    key={shopsListSelectorKey}
                    defaultItems={toJS(shops)}
                    defaultPagination={paginationState}
                    onPageItemsChange={setPaginationState}
                    className={styles.paginator}
                >
                    <Table
                        id="shopsList"
                        className={styles.table}
                        keyField="id"
                        title={t('shopsSearch.shopsTableTitle')}
                        withHeader
                        withPaper={false}
                        collapsible
                        onRowDoubleClick={shop => this.handleEdit(shop.id)}
                        columns={[
                            {
                                header: t('shopsSearch.shopsColumnName'),
                                hAlign: LEFT,
                                labelField: 'name',
                                width: '100%'
                            },
                            {
                                header: t('shopsSearch.shopsColumnRegion'),
                                hAlign: RIGHT,
                                dataType: TEXT,
                                labelFunction: shop => shop.city.region.name,
                                width: '240px'
                            },
                            {
                                header: t('shopsSearch.shopsColumnCity'),
                                hAlign: RIGHT,
                                dataType: TEXT,
                                labelFunction: shop => shop.city.name,
                                width: '200px'
                            },
                            {
                                header: t('shopsSearch.shopsColumnNumber'),
                                hAlign: RIGHT,
                                dataType: INT,
                                labelField: 'number',
                                width: '104px'
                            },
                            {
                                renderer: props => {
                                    const shop: ShopVO = props.item
                                    const id: number = shop.id as number
                                    return (
                                        <div className={styles.actionButtons}>
                                            <AdaptiveIconButton
                                                id={`shop${id}EditButton`}
                                                label={t('common.edit')}
                                                onClick={() => this.handleEdit(shop.id)}
                                            >
                                                <EditIcon/>
                                            </AdaptiveIconButton>
                                            <AdaptiveIconButton
                                                id={`shop${id}DeleteButton`}
                                                label={t('common.remove')}
                                                onClick={() => this.showDeletionDialog(shop)}
                                            >
                                                <DeleteIcon/>
                                            </AdaptiveIconButton>
                                        </div>
                                    )
                                },
                                hAlign: RIGHT,
                                showOnRowHover: true,
                                withControls: true,
                                width: '136px'
                            },
                        ]}
                    />

                    <Divider className={styles.divider}/>
                </Paginator>
            </Paper>
        )
    }
}
