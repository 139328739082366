import React from 'react'
import {
    CASHIERS_ROLES,
    CASHIERS,
    STAFF,
    USER,
    USER_ROLE,
    USER_ROLES,
    USERS,
    getRouteName
    } from '../core/app-routes'
import { SETRETAILX_CASHIER_ROLES_ADMIN, SETRETAILX_CASHIERS_ADMIN, SETRETAILX_USER_ADMIN } from '../core/privileges/privileges'
import { RoleSettings } from '../pages/staff/server-user-roles/role-settings'
import { Roles } from '../pages/staff/server-user-roles/roles'
import { UserSettings } from '../pages/staff/server-users/user-settings'
import { Users } from '../pages/staff/server-users/users'
import { RouteScheme } from '../utils/route-modules-util'
import { lazy } from '../utils/code-splitting'
import { PrivilegeManager } from '../core/privileges/privilege-manager'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'

export const staffRoutesScheme = (): RouteScheme => ({
    path: STAFF,
    navItemProps: {
        id: 'staffNavLink',
        label: getRouteName(STAFF),
        icon: <SupervisorAccount/>,
    },
    privileges: (privilegeManager: PrivilegeManager) => {
        return privilegeManager.haveAnyPrivilegeFromList([
            SETRETAILX_USER_ADMIN,
            SETRETAILX_CASHIERS_ADMIN,
            SETRETAILX_CASHIER_ROLES_ADMIN,
        ])
    },
    children: [
        {
            navItemProps: {
                id: 'usersNavLink',
                label: getRouteName(USERS),
            },
            path: `${STAFF}${USERS}`,
            component: Users,
            privileges: [SETRETAILX_USER_ADMIN]
        },
        {
            path: `${STAFF}${USER}/:id`,
            cannotBeRedirectTarget: true,
            component: UserSettings,
            privileges: [SETRETAILX_USER_ADMIN]
        },
        {
            navItemProps: {
                id: 'userRolesNavLink',
                label: getRouteName(USER_ROLES),
            },
            path: `${STAFF}${USER_ROLES}`,
            component: Roles,
            privileges: [SETRETAILX_USER_ADMIN]
        },
        {
            path: `${STAFF}${USER_ROLE}/:id`,
            cannotBeRedirectTarget: true,
            component: RoleSettings,
            privileges: [SETRETAILX_USER_ADMIN]
        },
        {
            navItemProps: {
                id: 'cashiersNavLink',
                label: getRouteName(CASHIERS),
            },
            path: `${STAFF}${CASHIERS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "cashiers-search" */ '../pages/staff/cashiers/cashiers-search')),
            privileges: [SETRETAILX_CASHIERS_ADMIN]
        },
        {
            path: `${STAFF}${CASHIERS}/:id`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "cashier-settings" */ '../pages/staff/cashiers/cashier-settings')),
            privileges: [SETRETAILX_CASHIERS_ADMIN]
        },
        {
            navItemProps: {
                id: 'cashierRolesNavLink',
                label: getRouteName(CASHIERS_ROLES),
            },
            path: `${STAFF}${CASHIERS_ROLES}`,
            component: lazy(() =>
                import(/* webpackChunkName: "cashiers-roles" */ '../pages/staff/cashiers-roles/cashiers-roles')),
            privileges: [SETRETAILX_CASHIER_ROLES_ADMIN]
        },
        {
            path: `${STAFF}${CASHIERS_ROLES}/:id`,
            cannotBeRedirectTarget: true,
            component: lazy(() =>
                import(/* webpackChunkName: "cashiers-role-settings" */ '../pages/staff/cashiers-roles/cashiers-role-settings')),
            privileges: [SETRETAILX_CASHIER_ROLES_ADMIN]
        },
    ]
})
