import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, PRODUCT_DETAILS_STORE, USER_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { AppStore } from '../../../store/app-store'
import { t } from 'i18next'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import NavigateNext from '@material-ui/icons/NavigateNext'
import { iProductsManagerLocal } from '../../../../protocol/set10/i-products-manager-local'
import { UserStore } from '../../../store/user-store'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { ShortProductVO_SF } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/short-product-vo-sf'
import { Table, RIGHT } from '@crystalservice/crystals-ui/lib'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'

export interface KitStructureProps {
    productDetailsStore?: ProductDetailsStore
    app?: AppStore
    user?: UserStore
}

export interface KitStructureState {
    kit: ShortProductVO_SF[]
}

const styles = require('./kit-structure.scss')

@inject(PRODUCT_DETAILS_STORE)
@inject(APP_STORE)
@inject(USER_STORE)
@observer
export default class KitStructure extends React.Component<KitStructureProps, KitStructureState> {

    state: KitStructureState = {
        kit: null,
    }

    mounted: boolean = false

    componentDidMount(): void {
        this.initKit()
        this.mounted = true
    }

    componentWillUnmount(): void {
        this.mounted = false
    }

    initKit = async (): Promise<void> => {
        const { productCode } = this.props.productDetailsStore
        const { session } = this.props.user

        const kit = await iProductsManagerLocal.findProductsFromKitByMarking(session, productCode)

        if (!this.mounted) return
        this.setState({ kit })
    }

    render() {
        const { goToProduct } = this.props.productDetailsStore
        const { kit } = this.state

        if (!kit) return <PageLoader/>
        if (!kit.length) return <Empty/>

        return (
            <Table
                id="kitStructureList"
                withHeader
                keyField="id"
                items={kit}
                columns={[
                    {
                        header: t('common.barcode'),
                        labelField: 'barCode',
                        tdClassName: styles.kitStructureBarcode
                    },
                    {
                        header: t('common.name'),
                        labelField: 'name'
                    },
                    {
                        header: t('common.quantity'),
                        labelFunction: (item: ShortProductVO_SF) => String(item.quantityInKit / 1000)
                    },
                    {
                        header: t('productDetails.kitStructureEntrance.priceForItem'),
                        labelField: 'price'
                    },
                    {
                        renderer: props => {
                            const product: ShortProductVO_SF = props.item

                            return (
                                <AdaptiveIconButton
                                    id={`goToButton${product.code}`}
                                    label={t('productDetails.kitStructureEntrance.toDetails')}
                                    onClick={() =>
                                        goToProduct(product.code)
                                    }
                                >
                                    <NavigateNext />
                                </AdaptiveIconButton>
                            )
                        },
                        hAlign: RIGHT,
                        padding: '0 10px',
                        showOnRowHover: true,
                        withControls: true,
                        width: '70px'
                    }
                ]}
            />
        )
    }
}
