import { observable, runInAction, action, toJS, computed } from 'mobx'
import {RouteChangeHandler, goTo} from '../../utils/router-util'
import {personnelMotivationReportManagerLocal} from '../../../protocol/set10/personnel-motivation-report-manager-local'
import {TimePeriod, createTimePeriod} from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day/time-period'
// tslint:disable-next-line
import {CashierPerformance} from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-personnel-motivation-plugin/cashier-performance'
// tslint:disable-next-line
import {AverageCashierPerformance} from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day-reports-plugins/set-oper-day-reports-personnel-motivation-plugin/average-cashier-performance'
import {Trend} from '../../../protocol/set10/set-retail10-server/oper-day/set-oper-day-reports/trend'
import {IntervalType, DATE, DAY_OF_WEEK, HOUR} from '../../core/operday/report-utils'
import {REPORTS, PERSONNEL_MOTIVATION, CASHIER_PERFOMANCE, POSITION_PER_MINUTE, ACTIVITY_TIME} from '../../core/app-routes'
import {getDayStart, getDayEnd, countDaysBetween} from '../../../utils/date-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import {t} from 'i18next'
import uuid from 'uuid'
import { withSpinner } from '../with-spinner'
import { UserStore } from '../user-store'
import { getStore } from '../stores-repository'
import { APP_BAR_STORE, PERSONNEL_MOTIVATION_STORE, USER_STORE } from '../stores'
import { fromClientToServerTime, fromServerToClientTime } from '../../utils/app-util'

export class PersonnelMotivationStore {

    @observable
    timePeriod: TimePeriod = createTimePeriod({
        startDate: getDayStart(fromClientToServerTime(new Date())).toDate(),
        endDate: getDayEnd(fromClientToServerTime(new Date())).toDate()
    })

    @computed
    get fetchedTimePeriod(): TimePeriod {
        const {
            startDate,
            endDate,
        } = this.timePeriod

        return createTimePeriod({
            startDate: fromServerToClientTime(startDate),
            endDate: fromServerToClientTime(endDate),
        })
    }

    @observable
    dateTimeKey: string = uuid()

    @observable
    filter: string = ''

    @observable
    intervalType: IntervalType = HOUR

    @observable
    intervalsCount: number = 24

    @observable
    searched: boolean = false

    @observable
    cashierPerfomance: CashierPerformance[] = []

    @observable
    selectedCashierPerfomance: CashierPerformance

    @observable
    averageCashierData: AverageCashierPerformance

    @observable
    detailedCashierData: Trend[] = []

    private userStore: UserStore = getStore(USER_STORE)

    @action
    resetFiltersAndResults = (): void => {
        this.timePeriod.startDate = getDayStart(fromClientToServerTime(new Date())).toDate()
        this.timePeriod.endDate = getDayEnd(fromClientToServerTime(new Date())).toDate()
        this.dateTimeKey = uuid()
        this.filter = ''
        this.searched = false
        this.cashierPerfomance = []
    }

    @action
    resetCashierData = (): void => {
        this.selectedCashierPerfomance = null
        this.detailedCashierData = []
    }

    @action
    editTimePeriod = (timePeriod: Partial<TimePeriod>): void => {
        Object.keys(timePeriod).forEach(k => {
            this.timePeriod[k] = timePeriod[k]
        })
    }

    @action
    editFilter = (value: string): void => {
        this.filter = value
    }

    @action
    editIntervalType = (newInterval: IntervalType): Promise<void> => {
        this.intervalType = newInterval

        if (newInterval === DATE) {
            this.intervalsCount = countDaysBetween(this.fetchedTimePeriod.startDate, this.fetchedTimePeriod.endDate)
        }
        if (newInterval === DAY_OF_WEEK) {
            this.intervalsCount = 7
        }
        if (newInterval === HOUR) {
            this.intervalsCount = 24
        }
        return withSpinner(this.fetchDetailedCashierData())
    }

    // Используется для перехода из списка
    @action
    goToCashierPerformance = (cashierPerformance: CashierPerformance): void => {
        this.selectedCashierPerfomance = cashierPerformance
        goTo(`${REPORTS}${PERSONNEL_MOTIVATION}${CASHIER_PERFOMANCE}/${cashierPerformance.number}`)
    }

    // Используется при прямом переходе по ссылке
    @action
    getCashierPerformanceData = (urlNumber?: string): Promise<void> => {
        if (!this.selectedCashierPerfomance) {
            // Если пришли через url, надо сначала задать временной период - отправляем на страницу с отчетом
            goTo(`${REPORTS}${PERSONNEL_MOTIVATION}`)
            return Promise.resolve()
        }

        return withSpinner(this.fetchDetailedCashierData())
    }

    fetchData = async (): Promise<void> => {
        await withSpinner(async () => {
            await this.fetchCashierPerfomance()
            await this.fetchAverageCashierData()
        })
    }

    fetchCashierPerfomance = (): Promise<void> => {
        return personnelMotivationReportManagerLocal.getCashierPerformance(this.userStore.session, this.fetchedTimePeriod)
            .then(result => {
                runInAction(() => {
                    this.cashierPerfomance = result
                    this.searched = true
                })
            })
    }

    fetchAverageCashierData = (): Promise<void> => {
        return personnelMotivationReportManagerLocal.getAverageCashierPerformance(this.userStore.session, this.fetchedTimePeriod)
            .then(result => {
                runInAction(() => {
                    this.averageCashierData = result
                })
            })
    }

    fetchDetailedCashierData = (): Promise<void> => {
        return personnelMotivationReportManagerLocal.getDetailedCashierPerformance(
            this.userStore.session, this.fetchedTimePeriod, this.intervalsCount, this.intervalType, this.selectedCashierPerfomance
        )
            .then(result => {
                runInAction(() => {
                    this.detailedCashierData = result
                })
            })
    }

    @action
    reset = (): void => {
        this.resetFiltersAndResults()
        this.resetCashierData()
        this.intervalType = HOUR
        this.intervalsCount = 24
        this.averageCashierData = undefined
    }
}

export const CASHIER_PERFOMANCE_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${REPORTS}${PERSONNEL_MOTIVATION}${CASHIER_PERFOMANCE}/`),
    onEnter: () => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)

        // Возвращаемся на тот же экран, с которого пришли
        let leftIconClick = () => null
        if (appBarStore.prevRoute && appBarStore.prevRoute.indexOf(ACTIVITY_TIME) !== -1) {
            leftIconClick = () => {
                goTo(`${REPORTS}${PERSONNEL_MOTIVATION}${ACTIVITY_TIME}`)
            }
        } else {
            leftIconClick = () => {
                goTo(`${REPORTS}${PERSONNEL_MOTIVATION}${POSITION_PER_MINUTE}`)
            }
        }

        appBarStore.updateState({
            title: t('set10.cashierPerfomance'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: leftIconClick
        })
    },
    onLeave: () => {
        const personnelMotivationStore: PersonnelMotivationStore = getStore(PERSONNEL_MOTIVATION_STORE)
        personnelMotivationStore.resetCashierData()
    }
}
