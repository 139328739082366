import { observable, action, runInAction, computed, toJS } from 'mobx'
import moment from 'moment'
import { t } from 'i18next'
import { sortBy, isEqual } from 'lodash'
import { MenuVO, createMenuVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/menu-vo'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { CASH_MODULE, CASH_SCHEDULES, NEW } from '../../core/app-routes'
import {
    MenusCollisionCheckResult, MenusCollisionChecker
} from '../../core/cash-templates/cashes/menus-collision-checker'
import { cashMenuScheduleManagerLocal } from '../../../protocol/set10/cash-menu-schedule-manager-local'
import { CashMenuScheduleVO, createCashMenuScheduleVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-menu-schedule-vo'
import { getUniqueNameWithNumberPostfix2, postfixRegexWithBrackets } from '../../../utils/name-util'
import { SCHEDULED } from '../../../protocol/set10/set-retail10-commons/data-structs-module/cash-menu-schedule-type'
import { CashMenuContentItem, CashMenuTile, FOLDER_ICON_ID } from '../../core/cash-templates/cash-menu-content'
import { fromClientToServerTime, fromServerToClientTime } from '../../utils/app-util'
import { DialogStore } from '../dialog-store'
import { getStore } from '../stores-repository'
import { DIALOG_STORE, SNACKBAR_STORE, APP_BAR_STORE, NAVIGATION_MENU_STORE, APP_STORE } from '../stores'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { SnackbarStore } from '../snackbar-store'
import { withSpinner } from '../with-spinner'
import { AppBarStore, MENU } from '../app-bar-store'
import { NavigationMenuStore } from '../navigation-menu-store'
import { bitMaskToWeekDays } from '../../../utils/date/date-conversion-util'
import { getDayOfWeekName } from '../../../utils/date-util'
import { AppStore } from '../app-store'

export class CashSchedulesStore {

    @observable
    schedules: CashMenuScheduleVO[] = null

    @observable
    editedMenu: MenuVO = null

    @observable
    private _editedSchedule: CashMenuScheduleVO = null // schedule с учётом deleted menus

    private dialogStore: DialogStore = getStore(DIALOG_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private appStore: AppStore = getStore(APP_STORE)

    @computed
    get editedSchedule(): CashMenuScheduleVO { // на визуализации работаем с меню, которые не deleted
        if (!this._editedSchedule) {
            return this._editedSchedule
        }

        return {
            ...this._editedSchedule,
            menus: this._editedSchedule.menus.filter(menu => menu.deleted !== true)
        }
    }

    @computed
    get notSavedSchedule(): CashMenuScheduleVO {
        if (this.editedMenu && !this.editedMenu.id) { // если есть NEW menu
            return {
                ...this._editedSchedule,
                menus: [...this._editedSchedule.menus, this.editedMenu]
            }
        } else {
            return this._editedSchedule
        }
    }

    @computed
    get scheduleModified(): boolean {
        if (!this._editedSchedule) return false
        const originalEditedSchedule = this.schedules.find(sch => sch.guid === this._editedSchedule.guid)

        return !isEqual(toJS(originalEditedSchedule), toJS(this.notSavedSchedule))
    }

    @computed
    get menusCollisions(): MenusCollisionCheckResult {
        if (!this.notSavedSchedule || !this.notSavedSchedule) {
            return new MenusCollisionCheckResult()
        }

        return new MenusCollisionChecker(fromClientToServerTime(new Date())).check(
            this.notSavedSchedule.menus
                .filter(menu => menu.deleted !== true) // проверяем коллизии среди неудалённых меню, включая editedMenu
        )
    }

    /* Schedules methods */

    fetchSchedules = async (): Promise<void> => {
        const schedules = await cashMenuScheduleManagerLocal.getAll() || []
        runInAction(() => {
            this.schedules = fixSchedulesFromServer(schedules)
        })
    }

    deleteSchedule = (schedule: CashMenuScheduleVO): void => {
        this.dialogStore.showDialog({
            title: t('cashMenusSchedules.deleteScheduleConfirmationTitle'),
            message: t('cashMenusSchedules.deleteScheduleConfirmationMessage', { name: schedule.name }),
            mode: DIALOG,
            onYes: async () => {
                await cashMenuScheduleManagerLocal.delete(schedule.guid)
                await this.fetchSchedules()

                this.snackbarStore.show({
                    message: t('cashMenusSchedules.scheduleDeletedMessage', { name: schedule.name }),
                })
            }
        })
    }

    @action
    copySchedule = (schedule: CashMenuScheduleVO): void => {
        this.setEditedSchedule({
            ...schedule,
            name: getUniqueNameWithNumberPostfix2(
                this.schedules.map(s => s.name),
                t('cashMenusSchedules.schedule'),
                postfixRegexWithBrackets
            ),
            guid: undefined,
            menus: schedule.menus.map((menu: MenuVO) => ({
                ...menu,
                id: undefined
            }))
        })
    }

    editScheduleName = async (schedule: CashMenuScheduleVO, name: string): Promise<CashMenuScheduleVO> => {
        return withSpinner(this.saveSchedule({
            ...toJS(schedule),
            name
        }))
    }

    /* Edited schedule methods */

    openSchedule = async (scheduleGuid: number): Promise<void> => {
        if (!this.schedules) {
            await this.fetchSchedules()
        }

        const editedSchedule = this.schedules.find(sch => sch.guid === scheduleGuid)

        if (!editedSchedule) {
            goTo(`${CASH_MODULE}${CASH_SCHEDULES}`)
            return
        }

        this.setEditedSchedule(editedSchedule)
    }

    @action
    createNewSchedule = (): void => {
        this.setEditedSchedule(getDefaultSchedule(this.schedules))
    }

    saveEditedSchedule = async (): Promise<CashMenuScheduleVO> => {
        // на сервер всегда отправляем все меню (в т.ч. с флагом deleted)
        let savedEditedSchedule = await this.saveSchedule(toJS(this._editedSchedule))
        savedEditedSchedule = fixScheduleFromServer(savedEditedSchedule)

        this.setEditedSchedule(savedEditedSchedule)

        return savedEditedSchedule
    }

    @action
    updateEditedSchedule = (changes: Partial<CashMenuScheduleVO>): void => {
        Object.keys(changes).forEach(key => {
            this._editedSchedule[key] = changes[key]
        })
    }

    @action
    closeSchedule = (): void => {
        this.setEditedSchedule(null)
    }

    /* Edited menu methods */

    openMenu = async (scheduleId: number, menuId: number): Promise<void> => {
        await this.openSchedule(scheduleId)

        const editedMenu = this.editedSchedule && this.editedSchedule.menus.find(menu => { // открыть можно только неудаленное меню
            return menu.id === menuId
        })

        if (!editedMenu) {
            goTo(`${CASH_MODULE}${CASH_SCHEDULES}/${scheduleId}`)
            return
        }

        runInAction(() => {
            this.editedMenu = editedMenu
        })
    }

    @action
    createNewMenu = (): void => {
        this.createMenu(getDefaultMenu(
            this.editedSchedule.menus, // при создании меню учитываем только неудаленные
            this.appStore.serverInfo.serverVersion,
            moment(fromClientToServerTime(this.appStore.now())).startOf('day').toDate())
        )
    }

    @action
    copyMenu = (menu: MenuVO): void => {
        this.createMenu({
            ...menu,
            number: Math.max(...this.editedSchedule.menus.map(menu => menu.number)) + 1,
            id: undefined
        })
    }

    @action
    updateMenu = (newMenu: MenuVO): void => {
        this.editedMenu = newMenu
    }

    @action
    saveMenu = async (): Promise<void> => {
        this.fixMenuContentBeforeSave()
        const menuName: string = this.editedMenu.name
        if (!this.editedMenu.id) {
            this._editedSchedule.menus.push(this.editedMenu)
        } else {
            this._editedSchedule.menus = this._editedSchedule.menus.map(menu => {
                if (menu.id === this.editedMenu.id) return this.editedMenu
                return menu
            })
        }

        await this.saveEditedSchedule()
        this.snackbarStore.show({
            message: t('cashMenusSchedules.menuSaved', { name: menuName }),
        })
    }

    deleteMenuFromEditedSchedule = (menu: MenuVO): void => {
        this.dialogStore.showDialog({
            title: t('cashMenusSchedules.deleteMenuConfirmationTitle'),
            message: t('cashMenusSchedules.deleteMenuConfirmationMessage', { name: menu.name }),
            mode: DIALOG,
            onYes: async () => {
                this.changeMenusInSchedule([menu], [])
                await this.saveEditedSchedule()

                this.snackbarStore.show({
                    message: t('cashMenusSchedules.menuDeletedMessage', { name: menu.name }),
                })
            }
        })
    }

    @action
    deleteStaleMenus = (): void => {
        const { menusToDelete, menusToCreate } = this.menusCollisions
        this.changeMenusInSchedule(menusToDelete, menusToCreate)
    }

    @action
    changeMenusInSchedule = (menusToDelete: MenuVO[], menusToCreate: MenuVO[]): void => {
        const newMenus = [
            ...this._editedSchedule.menus.map(menu => {
                const isMarkedForDelete = menusToDelete.some(m => m.id === menu.id)

                return {
                    ...menu,
                    deleted: isMarkedForDelete
                }
            }),
            ...menusToCreate
        ]

        this.setEditedSchedule({
            ...this._editedSchedule,
            menus: newMenus
        })
    }

    @action
    updateEditedMenu = (changes: Partial<MenuVO>): void => {
        Object.keys(changes).forEach(key => {
            this.editedMenu[key] = changes[key]
        })
    }

    @action
    closeMenu = (): void => {
        this.editedMenu = null
    }

    @action
    reset = (): void => {
        this.schedules = null
        this.setEditedSchedule(null)
        this.editedMenu = null
    }

    @action
    private setEditedSchedule = (schedule: CashMenuScheduleVO): void => {
        this._editedSchedule = toJS(schedule)
        if (schedule && !schedule.menus) {
            this._editedSchedule.menus = [] // на фронте неудобно работать с null
        }
    }
    @action
    private createMenu = (menu: MenuVO): void => {
        this.editedMenu = menu
        goTo(`${CASH_MODULE}${CASH_SCHEDULES}/${this._editedSchedule.guid}${NEW}`)
    }

    private saveSchedule = async (schedule: CashMenuScheduleVO): Promise<CashMenuScheduleVO> => {
        const savedSchedule = await cashMenuScheduleManagerLocal.save(fixScheduleFromClient(schedule))
        await this.fetchSchedules()
        return savedSchedule
    }

    @action
    private fixMenuContentBeforeSave = (): void => {
        let menuContent: CashMenuContentItem[]

        try {
            menuContent = JSON.parse(this.editedMenu.content)
        } catch {
            menuContent = []
        }

        const sortItemsByIndex = (tiles: CashMenuTile[]): CashMenuTile[] => {
            let newTiles = sortBy(tiles, 'index')
            newTiles.forEach(tileInfo => {
                if (tileInfo.tiles.length > 0) {
                    tileInfo.tiles = sortItemsByIndex(tileInfo.tiles)
                }
            })
            return newTiles
        }

        menuContent.forEach(tabInfo => {
            tabInfo.tiles = sortItemsByIndex(tabInfo.tiles)
        })

        this.updateEditedMenu({content: JSON.stringify(menuContent)})
    }
}

export const CASH_TEMPLATES_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${CASH_MODULE}${CASH_SCHEDULES}/?$`),
    onEnter: () => {
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const navigationMenuStore: NavigationMenuStore = getStore(NAVIGATION_MENU_STORE)

        navigationMenuStore.synchronizeWithRoute()
        appBarStore.updateState({
            title: t('cashMenusSchedules.schedulesTitle'),
            leftIcon: MENU,
            showNotifications: true,
            onLeftIconClick: () => navigationMenuStore.setOpen(!navigationMenuStore.open)
        })
    }
}

export function isPastDate(date: Date, now: Date): boolean {
    return moment(date).startOf('day')
        .isBefore(
            moment(fromClientToServerTime(now)).startOf('day')
        )
}

export function getHumanReadableWeekdays(dayOfWeek: number): string {
    return bitMaskToWeekDays(dayOfWeek)
        .map(dayOfWeek => getDayOfWeekName(dayOfWeek, 'dd'))
        .join(', ')
}

function fixSchedulesFromServer(schedules: CashMenuScheduleVO[]): CashMenuScheduleVO[] {
    return schedules.map(fixScheduleFromServer)
}

// FIXME: SFM-208 -> Дата приходит в unix_time, в протоколе просто Date указано
function fixScheduleFromServer(schedule: CashMenuScheduleVO): CashMenuScheduleVO {
    return {
        ...schedule,
        menus: schedule.menus?.map(menu => {
            return {
                ...menu,
                timeFrom: menu.timeFrom && fromClientToServerTime(new Date(menu.timeFrom)),
                timeTo: menu.timeTo && fromClientToServerTime(new Date(menu.timeTo)),
                dateFrom: menu.dateFrom && fromClientToServerTime(new Date(menu.dateFrom)),
                dateTo: menu.dateTo && fromClientToServerTime(new Date(menu.dateTo)),
            }
        })
    }
}

function fixScheduleFromClient(schedule: CashMenuScheduleVO): CashMenuScheduleVO {
    return {
        ...schedule,
        menus: schedule.menus.map(menu => {
            return {
                ...menu,
                timeFrom: menu.timeFrom && fromServerToClientTime(menu.timeFrom),
                dateFrom: menu.dateFrom && fromServerToClientTime(menu.dateFrom),
                timeTo: menu.timeTo && fromServerToClientTime(menu.timeTo),
                dateTo: menu.dateTo && fromServerToClientTime(menu.dateTo)
            }
        })
    }
}

function getDefaultSchedule(allSchedules: CashMenuScheduleVO[]): CashMenuScheduleVO {
    return createCashMenuScheduleVO({
        name: getUniqueNameWithNumberPostfix2(
            allSchedules.map(s => s.name),
            'Расписание',
            postfixRegexWithBrackets
        ),
        type: SCHEDULED,
        menus: []
    })
}

export function getDefaultMenu(allMenus: MenuVO[], serverVersion: string, today: Date): MenuVO {
    const prevMenusNumbers = allMenus.map(m => m.number)

    today.setHours(0, 0, 0, 0)

    const num = prevMenusNumbers.length === 0 ? 1 : Math.max(...prevMenusNumbers) + 1

    return createMenuVO({
        number: num, // TODO SFM-761 узнать какой нужен number
        xmlContent: '',
        content: '',
        contentInfo: `version:${serverVersion}\nformat:json`,
        timeFrom: null,
        timeTo: null,
        dateFrom: today,
        dateTo: null,
        dayOfWeek: 1,
        deleted: false,
        cashType: 'TOUCH_2'
    })
}
