import { t } from 'i18next'
import {
    MarkTypeVO, UNKNOWN, FOOTWEAR, LIGHT_INDUSTRY, TOBACCO, MILK, PERFUMES, PHOTO, TYRES
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/mark-type-vo'

export function getMarkTypeName(type: MarkTypeVO): string {
    switch (type) {
        case FOOTWEAR:
            return t(`productDetails.markTypes.footwear`)
        case TOBACCO:
            return t(`productDetails.markTypes.tobacco`)
        case LIGHT_INDUSTRY:
            return t(`productDetails.markTypes.light_industry`)
        case MILK:
            return t(`productDetails.markTypes.milk`)
        case PERFUMES:
            return t(`productDetails.markTypes.perfumes`)
        case PHOTO:
            return t(`productDetails.markTypes.photo`)
        case TYRES:
            return t(`productDetails.markTypes.tyres`)
        case UNKNOWN:
        default:
            return t(`productDetails.markTypes.unknown`)
    }
}
