import React, { Component } from 'react'
import { t } from 'i18next'
import { NumberInput } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'

import {
    removeCondition,
    updateCondition,
    findCondition,
    CountersAndLimitsConditions,
    PeriodUnitType,
    getActionResultsQuantityPeriodUnitsOpts,
    ActionResultsQuantity,
} from '../../../../../../core/advertising-actions/action-conditions'
import { ConditionEditorProps } from '../types'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'

const styles = require('./action-results-quantity-editor.scss')

const getConditionData = (condition: ActionResultsQuantity): {
    actionMaxValue?: string, clientMaxValue?: string, period?: string, periodType: PeriodUnitType, offline: string,
} => {
    const actionMaxValue = condition?.actionRestriction?.[0]?.maxValue?.[0]
    const clientMaxValue = condition?.clientRestriction?.[0]?.maxValue?.[0] || null
    const period = condition?.clientRestriction?.[0]?.period?.[0]
    const periodType = condition?.clientRestriction?.[0]?.periodType?.[0]
    const offline = condition?.allowOffline?.[0]

    return {
        actionMaxValue,
        clientMaxValue,
        period,
        periodType,
        offline,
    }
}

const getPeriodLabel = (period: PeriodUnitType, count: number): string => {
    switch (period) {
        case PeriodUnitType.Days:
            return t('advertisingActions.countersAndLimitsCond.days', { count })
        case PeriodUnitType.Weeks:
            return t('advertisingActions.countersAndLimitsCond.weeks', { count })
        case PeriodUnitType.Months:
            return t('advertisingActions.countersAndLimitsCond.months', { count })
        default:
            return ''
    }
}

// Получение текста для чипа
export const getActionResultsQuantityChipLabel = (condition: ActionResultsQuantity): string => {
    const {
        actionMaxValue,
        clientMaxValue,
        period,
        periodType,
    } = getConditionData(condition)
    let label: string = ''

    if (actionMaxValue) {
        label += t('advertisingActions.countersAndLimitsCond.actionRestrictionChip', { maxValue: actionMaxValue })
    }

    if (clientMaxValue) {
        label += `${label.length > 0 ? '; ' : ''}${t('advertisingActions.countersAndLimitsCond.clientRestrictionChip', { maxValue: clientMaxValue })}`

        if (period && Number(period) > 0) {
            const periodLabel = getPeriodLabel(periodType, Number(period))

            label += ` ${t('advertisingActions.countersAndLimitsCond.clientPeriod', {
                count: Number(period),
                periodType: periodLabel.toLowerCase(),
            })}`
        } else {
            label += ` ${t('advertisingActions.countersAndLimitsCond.clientWholePeriod')}`
        }
    }

    return label
}

interface ActionResultsQuantityEditorState {
    numberInputKey: number
}

export class ActionResultsQuantityEditor extends Component<ConditionEditorProps, ActionResultsQuantityEditorState> {
    constructor(props: ConditionEditorProps, context: any) {
        super(props, context)

        this.state = {
            numberInputKey: 0,
        }
    }

    static getDerivedStateFromProps(props: ConditionEditorProps, state: ActionResultsQuantityEditorState) {
        const condition = findCondition(CountersAndLimitsConditions.ActionResultsQuantityCondition, props.value)

        if (!condition) {
            return {
                numberInputKey: state.numberInputKey + 1,
            }
        }

        return null
    }

    handleChangeCondition = (newCondition: ActionResultsQuantity): void => {
        const { value, onChange } = this.props

        if (!(newCondition?.actionRestriction?.[0]?.maxValue || newCondition?.clientRestriction?.[0]?.maxValue)) {
            onChange(removeCondition(CountersAndLimitsConditions.ActionResultsQuantityCondition, value))
            return
        }

        onChange(updateCondition(
            CountersAndLimitsConditions.ActionResultsQuantityCondition,
            newCondition,
            value,
        ))
    }

    render() {
        const { value, readOnly } = this.props
        const { numberInputKey } = this.state
        const condition = findCondition(CountersAndLimitsConditions.ActionResultsQuantityCondition, value)
        const existingCond: ActionResultsQuantity = condition?.data
        const {
            actionMaxValue,
            clientMaxValue,
            period,
            periodType,
            offline,
        } = getConditionData(existingCond)

        return (
            <div className={styles.actionResultsQuantity}>
                <Box mb={2}>
                    <FormLabel style={{ fontSize: 14 }}>{t('advertisingActions.countersAndLimitsCond.client')}</FormLabel>
                    <Grid container spacing={2} style={{ marginTop: -4 }}>
                        <Grid item xs={12} md={4}>
                            <NumberInput
                                key={`clientRestriction${numberInputKey}`}
                                id="clientRestriction"
                                canBeNull
                                label={t('advertisingActions.countersAndLimitsCond.triggersCount')}
                                min={1}
                                disabled={readOnly}
                                value={Number(clientMaxValue) || null}
                                onValueChange={(newValue: number) => {
                                    const newCondition: ActionResultsQuantity = {
                                        ...existingCond,
                                        clientRestriction: [{
                                            ...existingCond?.clientRestriction?.[0],
                                            periodType: periodType ? [periodType] : [PeriodUnitType.Days],
                                            maxValue: newValue ? [String(newValue)] : null,
                                        }],
                                    }

                                    this.handleChangeCondition(newCondition)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={2}>
                            <NumberInput
                                key={`clientRestrictionPeriod${numberInputKey}`}
                                id="clientRestrictionPeriod"
                                canBeNull
                                label={t('actionResults.chequeAdvertising.forEvery', { count: Number(period) })}
                                disabled={readOnly}
                                value={Number(period) || null}
                                onValueChange={(newValue: number) => {
                                    const newCondition: ActionResultsQuantity = {
                                        ...existingCond,
                                        clientRestriction: [{
                                            ...existingCond?.clientRestriction?.[0],
                                            periodType: periodType ? [periodType] : [PeriodUnitType.Days],
                                            period: newValue ? [String(newValue)] : null,
                                        }],
                                    }

                                    this.handleChangeCondition(newCondition)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <SelectInput
                                options={getActionResultsQuantityPeriodUnitsOpts(Number(period) || 0)}
                                id="clientRestrictionPeriodType"
                                value={periodType || PeriodUnitType.Days}
                                disabled={readOnly}
                                onSelect={opt => {
                                    const newCondition: ActionResultsQuantity = {
                                        ...existingCond,
                                        clientRestriction: [{
                                            ...existingCond?.clientRestriction?.[0],
                                            periodType: [opt.value]
                                        }],
                                    }

                                    this.handleChangeCondition(newCondition)
                                }}
                                helperText={t('advertisingActions.countersAndLimitsCond.fromActionStart')}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <FormLabel style={{ fontSize: 14}}>{t('advertisingActions.countersAndLimitsCond.action')}</FormLabel>
                <Grid container spacing={2} style={{ marginTop: -4 }}>
                    <Grid item xs={12} md={4}>
                        <NumberInput
                            key={`actionResultsQuantity${numberInputKey}`}
                            id="actionResultsQuantity"
                            canBeNull
                            disabled={readOnly}
                            label={t('advertisingActions.common.actionResultsQuantityLabel')}
                            value={Number(actionMaxValue) || null}
                            onValueChange={(newValue: number) => {
                                const newCondition: ActionResultsQuantity = {
                                    ...existingCond,
                                    actionRestriction: [{
                                        maxValue: newValue ? [String(newValue)] : null,
                                    }],
                                    clientRestriction: [{
                                        ...existingCond?.clientRestriction?.[0],
                                        periodType: periodType ? [periodType] : [PeriodUnitType.Days],
                                    }],
                                }

                                this.handleChangeCondition(newCondition)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} container alignItems="center">
                        <FormLabel style={{ fontSize: 14 }}>{t('advertisingActions.countersAndLimitsCond.forAllActionPeriod')}</FormLabel>
                    </Grid>
                </Grid>

                <Checkbox
                    label={t('advertisingActions.countersAndLimitsCond.offlinePreference')}
                    color="primary"
                    checked={offline === 'true'}
                    id="offlineCheckbox"
                    disabled={readOnly}
                    onValueChange={checked => {
                        const newCondition: ActionResultsQuantity = {
                            ...existingCond,
                            allowOffline: [String(checked)]
                        }

                        this.handleChangeCondition(newCondition)
                    }}
                />
            </div>
        )
    }
}
