export interface CardsCondition {
    '@class': 'ru.crystals.discounts.CardsCondition'
    cardTypeGuids?: number[]
}

export const createCardsCondition = (initialization: Partial<CardsCondition>): CardsCondition => {
    if (!initialization) return null
    
    let result: CardsCondition = {
        cardTypeGuids: initialization.cardTypeGuids,
        '@class': 'ru.crystals.discounts.CardsCondition',
    }
    
    return result
}
