import { PriceCheckerVO } from './set-retail10-commons/data-structs-module/price-checker-vo'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class PriceCheckerManagerLocal extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Products/SET/PriceCheckerService!ru.crystals.setretailx.pricecheker.PriceCheckerManagerLocal'
    }

    findPriceCheckers1 = (options?: RequestOptions): Promise<PriceCheckerVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findPriceCheckers',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    findPriceCheckers2 = (shopNumber: number, options?: RequestOptions): Promise<PriceCheckerVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findPriceCheckers',
                params: [shopNumber]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    findPriceCheckersWithoutShop = (options?: RequestOptions): Promise<PriceCheckerVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'findPriceCheckersWithoutShop',
                params: [],
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    removePriceChecker = (id: number, options?: RequestOptions): Promise<void> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'removePriceChecker',
                params: [id]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    savePriceChecker = (priceCheckerVO: PriceCheckerVO, options?: RequestOptions): Promise<PriceCheckerVO> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'savePriceChecker',
                params: [priceCheckerVO]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const priceCheckerManagerLocal: PriceCheckerManagerLocal = new PriceCheckerManagerLocal()
