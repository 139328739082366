import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

const styles = require('./nav-bar.scss')

export const ROOT_LEVEL: number = 0

export interface NavBarProps extends React.HTMLProps<HTMLDivElement> {
    item: Item
    expandedItems?: Item[]
    alwaysExpanded?: boolean
    level?: number
    onItemSelect?: (item: Item) => void
    onItemExpand?: (item: Item) => void
    parentItem?: Item
    selectedItem?: Item
    selectedFolderClassName?: string
    selectedItemClassName?: string
}

export class NavBar extends React.PureComponent<NavBarProps> {

    static defaultProps: Partial<NavBarProps> = {
        id: 'navBar',
        level: ROOT_LEVEL,
        alwaysExpanded: false,
        parentItem: null,
        onItemSelect: () => null,
        onItemExpand: () => null,
        selectedFolderClassName: '',
        selectedItemClassName: '',
    }

    getItemLeftPadding = (level: number): number => {
        return level <= 1
            ? level * 32 + 24
            : (level - 1) * 24 + 48
    }

    render() {
        const { expandedItems, item, level, onItemSelect, onItemExpand, parentItem, selectedItem, selectedItemClassName,
                selectedFolderClassName, alwaysExpanded, ...other } = this.props

        if (!isVisible(item)) return null

        // Предупреждение об использовании четырёх и более уровней вложенности
        if (level >= 3) {
            console.warn('Side navigation menu: using nested items of depth >= 3 is not recommended for sake of UX')
        }

        // Текущий пункт меню - группа
        if (isItemGroup(item)) {
            // Группа не может не иметь потомков
            if (!item.children || item.children.length === 0) return null

            const components: React.ReactNode[] = []
            const addItem = (item: Item): void => {
                if (item.hidden) return

                components.push(
                    <NavBar
                        alwaysExpanded={alwaysExpanded}
                        expandedItems={expandedItems}
                        item={item}
                        key={item.id}
                        level={level}
                        onItemSelect={onItemSelect}
                        onItemExpand={onItemExpand}
                        parentItem={parentItem}
                        selectedItem={selectedItem}
                        selectedItemClassName={selectedItemClassName}
                        selectedFolderClassName={selectedFolderClassName}
                    />
                )
            }

            let prevItem: Item = null

            item.children.forEach(child => {
                // Текущий потомок скрыт
                if (!isVisible(child)) return
                // Если перед текущим потомком была отрендерена группа - отделим её визуально
                if (isItemGroup(prevItem) && isVisible(prevItem)) {
                    components.push(<Divider id={`${prevItem.id}GroupEnd`} key={`${prevItem.id}GroupEnd`}/>)
                }
                // Если текущий потомок - группа и до этого не было группы - добавим разделитель
                if (isItemGroup(child) && !isItemGroup(prevItem)) {
                    components.push(<Divider id={`${child.id}GroupStart`} key={`${child.id}GroupStart`}/>)
                }
                // Добавим текущего потомка в список на отрисовку
                addItem(child)

                prevItem = child
            })

            return (
                <div {...other}>
                    {components}
                </div>
            )
        // Текущий пункт меню - папка или конечный пункт
        } else {
            const { children, icon, label, onClick, selectable = true, endAdornment } = item
            const selected = selectedItem && containsSelectedItem(selectedItem, item)
            const expanded = alwaysExpanded || expandedItems && expandedItems.some(expandedItem => item.id === expandedItem.id)
            const folder: boolean = isFolder(item)
            const padding: number = this.getItemLeftPadding(level)
            const deepNestedItemBarWidth = 2

            let iconComponent = null

            if (icon) {
                iconComponent = React.cloneElement(icon, {
                    className: classNames(styles.icon, {
                        [styles.iconSelected]: selected
                    })
                })
            }

            return (
                <div
                    className={styles.menuItem}
                    id={item.id}
                >
                    {/* Для упрощения задания высоты "указателя текущего элемента" вынес "указатель" в контейнер потомка,
                    таким образом можно задать высоту прямо в css; иначе в родительском компоненте пришлось бы получать ref
                    у потомка, получать высоту элемента по ref и задавать высоту "указателя" уже через style */}
                    {selected && level >= 2 && parentItem && parentItem.children.length > 1 &&
                        <div
                            className={styles.deepNestedItemPointer}
                            style={{ left: this.getItemLeftPadding(level) + deepNestedItemBarWidth }}
                        />
                    }
                    <div
                        className={styles.header}
                        style={{paddingLeft: padding}}
                        onClick={() => {
                            if (onClick) {
                                onClick(item)
                            }
                            if (selectable && !folder) {
                                onItemSelect(item)
                            }
                            if (folder) {
                                onItemExpand(item)
                            }
                        }}
                    >
                        {iconComponent}
                        <span
                            className={classNames(styles.label, {
                                [selectedItemClassName || styles.labelSelected]:
                                    selectedItem && item && selectedItem.id === item.id,
                                [selectedFolderClassName || styles.folderSelected]:
                                    isFolder(item) && containsSelectedItem(selectedItem, item)
                            })}
                            data-selected={selectedItem && item && selectedItem.id === item.id}
                        >
                            {label}
                        </span>
                        { endAdornment }
                    </div>

                    <Collapse in={expanded}>
                        { folder && (
                            <div className={styles.folderChildrenContainer}>
                                {/* Рисуем для потомков на 2 уровне вложенности (0 - корневой) полосу, на которой будет
                            располагаться "указатель текущего элемента" (см. выше) */}
                                {children && children.length > 1 && level >= 1 &&
                                <div
                                    className={styles.deepNestedItemBar}
                                    style={{ left: this.getItemLeftPadding(level + 1) + deepNestedItemBarWidth }}
                                />
                                }
                                {children && children.map(child => (
                                    <NavBar
                                        alwaysExpanded={alwaysExpanded}
                                        expandedItems={expandedItems}
                                        selectedItemClassName={selectedItemClassName}
                                        selectedFolderClassName={selectedFolderClassName}
                                        id={`${child.id}NavBar`}
                                        item={child}
                                        key={`${child.id}NavBar`}
                                        level={level + 1}
                                        onItemSelect={onItemSelect}
                                        onItemExpand={onItemExpand}
                                        parentItem={item}
                                        selectedItem={selectedItem}
                                    />
                                ))}
                            </div>
                        ) }
                    </Collapse>
                </div>
            )
        }
    }
}

export interface Item {
    id: string
    children?: Item[]
    data?: any
    hidden?: boolean
    icon?: React.ReactElement<any>
    isGroup?: boolean
    label?: string
    selectable?: boolean
    endAdornment?: ReactNode
    onClick?: (item: Item) => void
}

export const isItemGroup = (item: Item): boolean => {
    if (!item) return false
    return item.isGroup
}

export const isFolder = (item: Item): boolean => {
    if (!item) return false
    return !item.isGroup && item.children !== undefined
}

export const isEndNode = (item: Item): boolean => {
    if (!item) return false
    return item.children === undefined
        || item.children.length === 0
}

export const isVisible = (item: Item): boolean => {
    if (!item || item.hidden) return false
    if (item.children && item.children.length > 0) {
        return item.children.some(child => isVisible(child))
    }
    return true
}

export const containsSelectedItem = (selectedItem: Item, item: Item): boolean => {
    if (!selectedItem) return false

    if (item.id === selectedItem.id) return true

    if (item.children && item.children.length > 0) {
        const childrenResults: boolean[] = item.children.map(child => containsSelectedItem(selectedItem, child))
        return childrenResults.some(child => child)
    }

    return false
}
