import * as React from 'react'
import { AutocompleteInput, AutocompleteInputProps } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import {t} from 'i18next'
import {iDiscountsManagerLocal} from '../../../protocol/set10/i-discounts-manager-local'
import {AdvertisingAction} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import {getMomentDate} from '../../../utils/date-util'
import {createActionsFilter} from '../../../protocol/set10/set-retail10-server/retailx/server-ds/actions-filter'
import classNames from 'classnames'

const styles = require('./discount-autocomplete.scss')

export interface DiscountAutocompleteProps extends Partial<AutocompleteInputProps<AdvertisingAction>>  {
    session: string
    /**
     * Указанные айдишники не будут отображаться в найденных акциях
     */
    excludedActionsIds?: number[]
    /**
     * К указанным акциям будет применен дополнительный стиль
     */
    markedActionsIds?: number[]
    markedClassName?: string
}

export class DiscountAutocomplete extends React.Component<DiscountAutocompleteProps> {

    render() {
        const { session, ref, excludedActionsIds, markedActionsIds, markedClassName, ...other } = this.props
        return (
            <AutocompleteInput<AdvertisingAction>
                fetchDataCallback={async (mask: string) => {
                    const result = await iDiscountsManagerLocal.getLazyActionsByFilter1(session, createActionsFilter({
                        keyWord: mask || '%'
                    }), 0, 30)

                    if (!excludedActionsIds) return result

                    // Не включаем акции из списка исключенных
                    return result.filter(item => {
                        return !excludedActionsIds.some(id => id === item.id)
                    })
                }}
                suggestionsLimit={30}
                keyField="id"
                menuItemHeight={94}
                menuItemRenderer={item => {
                    // Сделана обертка в new Date т.к. с сервера приходит unixTime
                    const dateStart = getMomentDate(new Date(item.workPeriod.start)).format('LL')
                    const dateEnd = getMomentDate(new Date(item.workPeriod.finish)).format('LL')
                    let endText = `${t('common.toEnd')} ${dateEnd}`

                    // Акция без ограничения срока действия
                    if (item.workPeriod.finish === null) {
                        endText = t('components.noEndTime')
                    }

                    let dateText = `${t('common.fromStart')} ${dateStart} ${endText}`

                    if (!item.workPeriod.start) {
                        dateText = '-'
                    }

                    return (
                        <div className={classNames({
                            [markedClassName || styles.selectedAction]: markedActionsIds && markedActionsIds.some(
                                id => item.id === id
                            )
                        })}>
                            <p>{item.name}</p>
                            <p><span className={styles.secondary}>{t('common.code')}</span> {item.externalCode}</p>
                            <p className={styles.date}>
                                {dateText}
                            </p>
                        </div>
                    )
                }}
                id="discountAutocomplete"
                labelField="name"
                placeholder={t('components.autocomplete.actionPlaceholder')}
                {...other}
            />
        )
    }
}
