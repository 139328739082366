import { observable, action, computed, toJS, runInAction } from 'mobx'
import { isArray, uniqBy } from 'lodash'
import { SearchArgumentVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/search-argument-vo'
import { printLogFacadeLocal } from '../../../protocol/set10/print-log-facade-local'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { XLSX } from '../../core/file-types'
import { config } from '../../config/config'
import { PrintLogVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/print-log-vo'
import uuid from 'uuid'
import { withSpinner } from '../with-spinner'
import { fromClientToServerTime } from '../../utils/app-util'
import { SideBarFiltersState, getSearchArguments } from '../../components/filters/side-bar-filters'
import { chunkedLoading } from '../../core/chunked-loading'
import { DEFAULT_PRICE_TAGS_PAGINATION } from '../../core/price-tags/price-tags-util'

export class PrintingHistoryStore {

    @observable
    searchArguments: SearchArgumentVO[] = []

    @observable
    printLogIds: number[] = []

    @observable
    selectedPrintLogs: number[] = []

    @observable
    currentFiltersState: SideBarFiltersState = {}

    @observable
    pagination: PaginationState = DEFAULT_PRICE_TAGS_PAGINATION

    @observable
    printBoxKey: string = null

    @observable
    printLogItems: PrintLogVO[] = []

    fetchPrintLogs = async (): Promise<void> => {
        let printLogIds: number[] = await printLogFacadeLocal.getPrintLogIds(toJS(this.searchArguments)) || []

        // TODO SFM-208 пока приходит в некорректном формате
        printLogIds = printLogIds.map(id => {
            if (isArray(id) && id.length === 2) {
                return id[1] as any
            } else {
                return id
            }
        })

        const printLogItems: PrintLogVO[] = await this.fetchPrintLogsByIds(printLogIds)

        runInAction(() => {
            this.printLogIds = printLogIds
            this.printLogItems = printLogItems
            this.pagination.total = printLogItems.length
            this.pagination.page = 1
        })
    }

    fetchPrintLogsByIds = async (ids: number[]): Promise<PrintLogVO[]> => {
        let printLogItems: PrintLogVO[] = await withSpinner(chunkedLoading(ids, chunkIds => {
            return printLogFacadeLocal.getPrintLog(chunkIds)
        }))

        // TODO SFM-208 пока приходит в некорректном формате
        if (printLogItems && printLogItems.length === 2 && isArray(printLogItems[1])) {
            printLogItems = (printLogItems[1] as any).map(item => {
                if (item && item.date) {
                    item.date = fromClientToServerTime(new Date(item.date))
                }
                if (item && item.dateToPrint) {
                    item.dateToPrint = fromClientToServerTime(new Date(item.dateToPrint))
                }

                return item
            })
        }

        // SFM-2022 сервер отдает логи с одинаковыми id (первые два items)
        printLogItems = uniqBy(printLogItems, 'id')

        return printLogItems
    }

    downloadExcelHandler = async (): Promise<void> => {
        const reportWindow = window.open()
        const fileName: string = await printLogFacadeLocal.getFileNameForPrintLog(
            toJS(this.selectedPrintLogs),
            XLSX
        )

        const reportUrl: string = `${config.reportsAddress}?Action=getFile&FILE_NAME=${fileName}`
        // window.open сработает только если он был вызван в главном потоке
        // поэтому мы сначала открываем окно, а потом подменяем ему адрес
        reportWindow.location.replace(reportUrl)
    }

    printPriceTags = async (): Promise<void> => {
        await printLogFacadeLocal.startReprintTask(toJS(this.selectedPrintLogs))
        runInAction(() => {
            this.printBoxKey = uuid()
        })
    }

    @action
    applyFilters = (searchArguments: SearchArgumentVO[]): void => {
        this.searchArguments = searchArguments
        this.fetchPrintLogs()
    }

    @action
    clearFilters = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
        this.searchArguments = getSearchArguments(filterState.shownFilters)
        this.fetchPrintLogs()
    }

    @action
    setFiltersState = (filterState: SideBarFiltersState): void => {
        this.currentFiltersState = filterState
    }

    @action
    setPagination = (pagination: PaginationState): void => {
        this.pagination = pagination
    }

    @action
    setSelectedPrintLogIds = (ids: number[]): void => {
        this.selectedPrintLogs = ids
    }

    @action
    selectOnPage = (newSelectedIds: number[], oldSelectedIds: number[]): void => {
        let addToSelection: number[] = [...newSelectedIds]
        let newSelection = [...this.selectedPrintLogs]

        oldSelectedIds.forEach(oldId => {
            const newIndex = addToSelection.findIndex(newId => newId === oldId)
            if (newIndex === -1) {
                const selectedIndex = newSelection.findIndex(currentId => currentId === oldId)
                newSelection.splice(selectedIndex, 1)
            } else {
                addToSelection.splice(newIndex, 1)
            }
        })

        this.selectedPrintLogs = [...newSelection, ...addToSelection]
    }

    @action
    handleAllTasksCompleted = (): void => {
        this.selectedPrintLogs = []
    }

    @action
    reset = (): void => {
        this.searchArguments = []
        this.printLogIds = []
        this.printLogItems = []
        this.selectedPrintLogs = []
        this.currentFiltersState = {}
        this.pagination = DEFAULT_PRICE_TAGS_PAGINATION
        this.printBoxKey = null
    }
}
