import * as React from 'react'
import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router'
import { UserStore } from '../../../../store/user-store'
import { ActionSettingsStore } from '../../../../store/actions/action-settings-store'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Delete from '@material-ui/icons/Delete'
import FileCopy from '@material-ui/icons/FileCopyOutlined'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Stop from '@material-ui/icons/Stop'
import Edit from '@material-ui/icons/Edit'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'
import { SelectInput } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import ActionLabelsAutocomplete from '../../../../../components/inputs/autocomplete-input/action-labels-autocomplete'
import { Chips } from '../../../../components/displaying/chips'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import { APP_STORE, ROUTING_STORE, ACTION_SETTINGS_STORE, APP_BAR_STORE, USER_STORE, CASHIERS_SEARCH_STORE } from '../../../../store/stores'
import { createTimestampPeriod } from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/timestamp-period'
import { t } from 'i18next'
import { ActionPanel } from '@crystalservice/crystals-ui/lib/components/settings-action-panel/action-panel'
import { NEW, CONDITIONS, RESULTS, LOYALTY, ACTIONS, ACTION_EDIT } from '../../../../core/app-routes'
import { AppBarStore, LEFT_ARROW } from '../../../../store/app-bar-store'
import { AppStore } from '../../../../store/app-store'
import { AdvertisingAction } from '../../../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { RouterStore } from 'mobx-react-router'
import { truncate, uniqBy } from 'lodash'
import { ColorOption, getColorsAsOptions } from '../../../../core/color-utils'
import moment from 'moment'
import { goTo } from '../../../../utils/router-util'
import { ActionPriorityEditor } from './action-priority-editor/action-priority-editor'
import { AffectedZoneEditor } from './affected-zone-editor/affected-zone-editor'
import { AffectedZoneResults } from './affected-zone-results/affected-zone-results'
import { SetPriceTagPrintEditor } from './set-price-tag-print-editor/set-price-tag-print-editor'
import {
    findCondition,
    removeCondition,
    CommonConditions,
    Condition,
    formatCondition,
    getConditionsModeOptions,
} from '../../../../core/advertising-actions/action-conditions'
import { formatResult, AdvertisingActionParsed, getActionStatus, ActionStatus } from '../../../../core/advertising-actions/advertising-actions'
import { AsyncWrapper } from '../../../../components/async-wrapper/async-wrapper'
import { ActionEditBlock } from './action-edit-block'
import { AdaptiveIconButton } from '../../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import { withSpinner } from '../../../../store/with-spinner'
import { getUserShortName } from '../../../../../utils/name-util'
import { getDayEnd } from '../../../../../utils/date-util'
import { DateRangePicker } from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/date-range-picker/date-range-picker'
import { fromClientToServerTime, fromServerToClientTime } from '../../../../utils/app-util'
import { MANUAL, UNCONDITIONAL, AUTOMATIC, BY_CONDITION } from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/apply-mode'
import AddIcon from '@material-ui/icons/Add'
import Error from '@material-ui/icons/Error'
import Event from '@material-ui/icons/Event'
import OutlinedFlag from '@material-ui/icons/OutlinedFlag'
import InfoRounded from '@material-ui/icons/InfoRounded'
import { LOY_STOP, LOY_START, LOY_CREATE_ACTION_LABEL, LOY_EDIT } from '../../../../core/privileges/privileges'
import { DIALOG, DIALOG_CANCELABLE } from '../../../../../components/simple-dialog/simple-dialog'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { TOOLTIP_DELAY } from '../../../../../utils/default-timeouts'
import { NumberInput, NUMBER, NumberInputProps } from '@crystalservice/crystals-ui/lib/components/inputs/number-input/number-input'
import { iDiscountsManagerLocal } from '../../../../../protocol/set10/i-discounts-manager-local'
import { ActionEditRolesDialog } from './action-edit-roles-dialog'
import { CashiersSearchStore } from '../../../../store/staff/cashiers-search-store'
import {
    GeneralInteractionMethod, WORKS_ANY, FIRST_ORDER, MAXIMUM_DISCOUNT, MAXIMUM_SETS_INDEPENDENT_DISCOUNT
} from '../../../../../protocol/set10/set-retail10-commons/data-structs-module/general-interaction-method'

const styles = require('./action-edit.scss')
const SET_PRICE_TAG_ICON = require('../../../../../assets/images/icons/price-tags/set-price-tag.svg')

const ACTION_NAME_MAX_LENGTH = 100

export const getActionStatusChip = (action: AdvertisingAction): JSX.Element => {
    let text: string
    let icon: React.ReactElement = <PlayArrow />
    let className = ''
    const status = getActionStatus(action)

    switch (status) {
        case ActionStatus.DRAFT_PAST:
            const isNew = action.id === -1
            text = isNew ? t('advertisingActions.statuses.draft') : t('advertisingActions.statuses.pastDraft')
            className = styles.pastAction
            icon = <Edit />
            break
        case ActionStatus.DRAFT:
            className = styles.draft
            text = t('advertisingActions.statuses.draft')
            icon = <Edit />
            break
        case ActionStatus.PAST:
            className = styles.pastAction
            text = t('advertisingActions.statuses.pastAction')
            icon = <OutlinedFlag/>
            break
        case ActionStatus.FUTURE:
            className = styles.futureAction
            text = t('advertisingActions.statuses.futureAction')
            icon = <Event/>
            break
        case ActionStatus.CURRENT:
            className = styles.currentAction
            text = t('advertisingActions.statuses.currentAction')
            icon = <PlayArrow />
            break
    }
    return <Chip className={classNames(styles.actionStatus, className)} label={text} icon={icon}/>
}

export interface ActionsEditURLParams {
    id?: string
}

export interface ActionEditProps extends RouteComponentProps<ActionsEditURLParams> {
    user?: UserStore
    actionSettingsStore?: ActionSettingsStore
    appBar?: AppBarStore
    routing?: RouterStore
    app?: AppStore
    cashiersSearchStore?: CashiersSearchStore
}

const openSettings = (settingsUrl: string): void => {
    goTo(settingsUrl)
}

interface ActionEditState {
    isAffectedZoneDialogOpen: boolean
    isSetPriceTagEditorOpen: boolean
    dateKey: number
    generalInteractionMethod: GeneralInteractionMethod
}

@inject(ACTION_SETTINGS_STORE)
@inject(APP_BAR_STORE)
@inject(USER_STORE)
@inject(APP_STORE)
@inject(ROUTING_STORE)
@inject(CASHIERS_SEARCH_STORE)
@observer
export class ActionEdit extends React.Component<ActionEditProps, ActionEditState> {

    state: ActionEditState = {
        isAffectedZoneDialogOpen: false,
        isSetPriceTagEditorOpen: false,
        dateKey: 0,
        generalInteractionMethod: FIRST_ORDER
    }

    mounted: boolean = false

    componentDidMount() {
        this.mounted = true
        this.fetchLoyaltyProps()
        this.props.cashiersSearchStore.fetchCashiersRoles()
        this.componentMounted()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentMounted = async () => {
        const { editedAction, openAction, updateAppBar } = this.props.actionSettingsStore
        const idParam = this.props.match.params.id
        const guid: number = `/${idParam}` === NEW ? -1 : +idParam

        if (!editedAction) {
            await withSpinner(openAction(guid))
        }

        updateAppBar(this.props.actionSettingsStore.editedAction)
    }

    fetchLoyaltyProps = async (): Promise<any> => {
        const { session } = this.props.user
        const loyProps = await iDiscountsManagerLocal.getLoyaltyProperties(session)

        if (!this.mounted) return

        this.setState({
            generalInteractionMethod: loyProps.generalInteractionMethod
        })
    }

    handleSaveAction = async (): Promise<void> => {
        const { saveEditedAction } = this.props.actionSettingsStore
        await withSpinner(saveEditedAction())
    }

    getWarningText = (
        correctDate: boolean,
        gotTopology: boolean,
        gotConditions: boolean,
        gotResults: boolean
    ) => {
        const { editedAction, actionDraft, openAction, editedActionModified, updateAppBar } = this.props.actionSettingsStore
        const { showDialog } = this.props.app
        const isActive = editedAction.active

        if (editedAction.parentGuid && !isActive) {
            const setParentActionUrl = () => goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${editedAction.parentGuid}`)
            return (
                <>
                    <Error className={styles.icon}/>
                    <div className={styles.title}>
                        <span>{t('advertisingActions.gotDraft')} </span>
                        <a
                            className={styles.link}
                            onClick={async () => {
                                if (editedActionModified) {
                                    showDialog({
                                        title: t('advertisingActions.saveActionTitle'),
                                        message: t('advertisingActions.saveActionMessage'),
                                        mode: DIALOG_CANCELABLE,
                                        onYes: () => {
                                            withSpinner(async () => {
                                                await this.handleSaveAction
                                                setParentActionUrl()
                                                const openedAction = await openAction(editedAction.parentGuid)
                                                updateAppBar(openedAction)
                                            })
                                        },
                                        onNo: async () => {
                                            setParentActionUrl()
                                            const openedAction = await withSpinner(openAction(editedAction.parentGuid))
                                            updateAppBar(openedAction)
                                        },
                                    })
                                } else {
                                    setParentActionUrl()
                                    const openedAction = await withSpinner(openAction(editedAction.parentGuid))
                                    updateAppBar(openedAction)
                                }
                            }}
                        >
                            {t('advertisingActions.goToParentAction')}
                        </a>
                    </div>
                </>
            )
        }

        if (actionDraft) {
            return (
                <>
                    <Error className={styles.icon}/>
                    <div className={styles.title}>
                        <span>{t('advertisingActions.gotParentAction')} </span>
                        <a
                            className={styles.link}
                            onClick={async () => {
                                goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${actionDraft.guid}`)
                                const openedAction = await withSpinner(openAction(actionDraft.guid))
                                updateAppBar(openedAction)
                            }}
                        >
                            {t('advertisingActions.goToDraft')}
                        </a>
                    </div>
                </>
            )
        }

        let notFilledText = ''
        if (!correctDate) {
            notFilledText = t('advertisingActions.needDate')
        }
        if (!gotTopology) {
            notFilledText = t('advertisingActions.needTopology')
        }
        if (!gotConditions) {
            notFilledText = t('advertisingActions.needConditions')
        }
        if (!gotResults) {
            notFilledText = t('advertisingActions.needResults')
        }

        if (!isActive && notFilledText) {
            return (
                <>
                    <Error className={styles.icon}/>
                    <div id="errorText" className={styles.title}>{notFilledText}</div>
                </>
            )
        }
    }

    showActiveActionDateChangeDialog = (startDate: Date, endDate: Date): void => {
        const { modifyEditedAction } = this.props.actionSettingsStore
        const { showDialog } = this.props.app

        showDialog({
            title: t('advertisingActions.confirmDateTitle'),
            message: t('advertisingActions.confirmDateMessage'),
            mode: DIALOG,
            onYes: () => {
                modifyEditedAction({
                    workPeriod: createTimestampPeriod({
                        start: startDate,
                        finish: endDate
                    }),
                })
                this.setState(prevState => ({ dateKey: prevState.dateKey + 1 }))

                this.handleSaveAction()
            },
            onNo: () => this.setState(prevState => ({ dateKey: prevState.dateKey + 1 })),
            yesLabel: t('advertisingActions.confirmDate'),
        })
    }

    showActionEditRolesDialog = (): void => {
        const { initCashierRoles, updateCashierRoles, editedAction } = this.props.actionSettingsStore

        initCashierRoles()

        const existingCondition = findCondition(CommonConditions.CashierCondition, editedAction.externalConditionsParsed)
        const roles = existingCondition?.data?.cashierRoles[0].long || []
        updateCashierRoles(roles.map(guid => this.props.cashiersSearchStore.roles.find(role => role.guid === +guid)))
    }

    render() {
        const {
            editedAction,
            workingAllTime,
            editedActionModified,
            modifyEditedAction,
            modifyWorkingAllTime,
            showPriorityEditor,
            isOpenPriorityEditor,
            stopEditedAction,
            actionDraft,
            actionParent,
            cloneEditedAction,
            updateAppBar,
        } = this.props.actionSettingsStore
        const { isAffectedZoneDialogOpen, isSetPriceTagEditorOpen, dateKey, generalInteractionMethod } = this.state
        const { currencyText, isCentrum, loading, now } = this.props.app
        const { havePrivilege } = this.props.user

        if (!editedAction) return null

        const status = getActionStatus(editedAction)
        const finishedAction = status === ActionStatus.PAST
        const currentAction = status === ActionStatus.CURRENT
        const futureAction = status === ActionStatus.FUTURE

        const editPrivilege = havePrivilege(LOY_EDIT)
        const readOnly = Boolean(actionDraft) || finishedAction || !editPrivilege

        const isNew = editedAction.id === -1
        const isActive = editedAction.active

        let chipConditions = [...editedAction.externalConditionsParsed]
        if (editedAction?.masterActions?.length > 0) {
            chipConditions.push({
                type: CommonConditions.MasterActions,
                data: editedAction.masterActions,
            })
        }

        const isAllNodes = !!editedAction.allNodes
        const gotTopology = isAllNodes || editedAction.topologyConditions?.length > 0
        const gotConditions = chipConditions?.length > 0 || editedAction.mode === MANUAL || editedAction.mode === UNCONDITIONAL
        const gotResults = editedAction.resultsParsed?.length > 0
        const correctDate = status !== ActionStatus.DRAFT_PAST

        const isFilled = gotTopology && gotConditions && gotResults && correctDate

        let createdText = ''
        let lastChangedText = ''
        const actionCreatedBy = editedAction.author && (getUserShortName(editedAction.author) || editedAction.author.login)
        const actionEditedBy = editedAction.editor && (getUserShortName(editedAction.editor) || editedAction.editor.login)

        createdText = `${moment(editedAction.created).format('DD.MM.YYYY')} ${actionCreatedBy || ''}`
        lastChangedText = `${moment(editedAction.lastChanges).format('DD.MM.YYYY')} ${actionEditedBy || ''}`

        const priorityEditingEnabled: boolean = (() => {
            if (editedAction.workPeriod.finish && editedAction.workPeriod.finish < fromClientToServerTime(now())) {
                return false
            }

            switch (generalInteractionMethod) {
                default:
                case FIRST_ORDER:
                case WORKS_ANY:
                    return true
                case MAXIMUM_DISCOUNT:
                case MAXIMUM_SETS_INDEPENDENT_DISCOUNT:
                    return editedAction.worksAnytime
            }
        })()

        return (
            <>
                <ActionEditRolesDialog/>
                <Paper className={styles.section}>
                    {!isNew && (
                        <div id="actionInfo" className={styles.head}>
                            <p className={styles.title}>{`${t('advertisingActions.actionCreated')}: ${createdText}`}</p>
                            <p className={styles.title}>{`${t('advertisingActions.actionUpdated')}: ${lastChangedText}`}</p>
                        </div>
                    )}

                    <Grid container spacing={6} alignItems="flex-start">
                        <Grid item spacing={2} xs={12} md={6} container alignItems="center">
                            {/* Название акции */}
                            <ActionEditBlock label={t('advertisingActions.name')}>
                                <TextField
                                    slim
                                    id="editedActionName"
                                    value={editedAction.name}
                                    disabled={readOnly || isActive}
                                    maxLength={ACTION_NAME_MAX_LENGTH}
                                    onValueChange={name => modifyEditedAction({ name })}
                                    fullWidth
                                    onBlur={() => updateAppBar(editedAction)}
                                    noClearBtn
                                />
                            </ActionEditBlock>

                            {/* Код акции */}
                            <ActionEditBlock label={t('advertisingActions.code')}>
                                <TextField
                                    slim
                                    id="editedActionCode"
                                    value={editedAction.externalCode || ''}
                                    disabled
                                    fullWidth
                                />
                            </ActionEditBlock>

                            {/* Метки */}
                            <ActionEditBlock label={t('advertisingActions.tags')}>
                                <ActionLabelsAutocomplete
                                    value={editedAction.labels}
                                    disabled={readOnly}
                                    canCreateNewLabels={havePrivilege(LOY_CREATE_ACTION_LABEL)}
                                    onChange={labels => modifyEditedAction({ labels })}
                                />
                            </ActionEditBlock>

                            {/* Цвет */}
                            <ActionEditBlock label={t('advertisingActions.color')}>
                                <SelectInput<ColorOption>
                                    id="colorInput"
                                    slim
                                    fullWidth
                                    disabled={readOnly || isActive}
                                    value={editedAction.displayStyleName}
                                    onSelect={option => {
                                        modifyEditedAction({ displayStyleName: option.value })
                                    }}
                                    options={getColorsAsOptions()}
                                    labelFunction={(item: ColorOption): any => (
                                        <div className={styles.selectOption}>
                                            <div
                                                className={classNames(
                                                    styles.colorContainer,
                                                    styles[item.value]
                                                )}
                                            />
                                            <div className={styles.selectLabel}>{item.label}</div>
                                        </div>
                                    )}
                                />
                            </ActionEditBlock>

                            {/* Срок действия */}
                            <ActionEditBlock
                                label={t('advertisingActions.workPeriod')}
                                renderChildren={() => (
                                    <>
                                    <Grid item xs={12} md={8}>
                                        <DateRangePicker
                                            id="workPeriod"
                                            key={`workPeriodPicker_${workingAllTime}_${dateKey}`}
                                            slim
                                            withTime
                                            defaultStartDate={editedAction.workPeriod.start}
                                            defaultEndDate={workingAllTime ? null : editedAction.workPeriod.finish}
                                            onDatesChange={(startDate: Date, endDate: Date) => {
                                                // У текущей акций только в onBlur изменяем дату
                                                if (!currentAction) {
                                                    modifyEditedAction({
                                                        workPeriod: createTimestampPeriod({
                                                            start: startDate,
                                                            finish: endDate
                                                        }),
                                                    })
                                                }
                                            }}
                                            startDatePickerProps={{
                                                notNullable: true,
                                                disabled: readOnly || currentAction,
                                                isDateDisabled: date => {
                                                    if (currentAction) {
                                                        return true
                                                    }

                                                    // Акция является черновиком уже запущенной акции
                                                    if (editedAction.parentGuid && !isActive) {
                                                        const minAvailableDate = moment(fromClientToServerTime(new Date()))
                                                            .add(1, 'hour')
                                                        const parentFinishDate = actionParent.workPeriod.finish

                                                        // Если без даты окончания, запрещаем только дату начала
                                                        if (!parentFinishDate) {
                                                            return moment(date).isBefore(minAvailableDate)
                                                        }

                                                        const maxAvailableDate = moment(
                                                            fromClientToServerTime(actionParent.workPeriod.finish)
                                                        )

                                                        // Можно выбирать дату только от текущего момента, до конца действия родителя
                                                        return moment(date).isBefore(minAvailableDate) ||
                                                            moment(date).isAfter(maxAvailableDate)
                                                    }

                                                    if (futureAction) {
                                                        const minAvailableDate = moment(fromClientToServerTime(new Date()))
                                                            .add(1, 'hour')
                                                        if (moment(date).isBefore(minAvailableDate)) {
                                                            return true
                                                        }
                                                    }
                                                    return false
                                                },
                                                onBlur: (date: Date, error: string) => {
                                                    if (currentAction) {
                                                        if (!error) {
                                                            const startDate = editedAction.workPeriod.start

                                                            if (startDate.getTime() !== date.getTime()) {
                                                                const endDate = workingAllTime ? null : editedAction.workPeriod.finish
                                                                this.showActiveActionDateChangeDialog(date, endDate)
                                                            }
                                                        } else {
                                                            this.setState(prevState => ({ dateKey: prevState.dateKey + 1 }))
                                                        }
                                                    }
                                                }
                                            }}
                                            endDatePickerProps={{
                                                disabled: readOnly || workingAllTime,
                                                notNullable: !workingAllTime,
                                                isDateDisabled: date => {
                                                    if (currentAction) {
                                                        const minAvailableDate = moment(fromClientToServerTime(new Date()))
                                                        if (moment(date).isBefore(minAvailableDate)) {
                                                            return true
                                                        }
                                                    }
                                                    if (futureAction || (editedAction.parentGuid && !isActive)) {
                                                        const minAvailableDate = moment(editedAction.workPeriod.start)
                                                            .add(1, 'day')

                                                        if (moment(date).isBefore(minAvailableDate)) {
                                                            return true
                                                        }
                                                    }
                                                    return false
                                                },
                                                onBlur: (date: Date, error: string) => {
                                                    if (currentAction) {
                                                        if (!error) {
                                                            const endDate = workingAllTime ? null : editedAction.workPeriod.finish

                                                            if (endDate.getTime() !== date.getTime()) {
                                                                const startDate = editedAction.workPeriod.start
                                                                this.showActiveActionDateChangeDialog(startDate, date)
                                                            }
                                                        } else {
                                                            this.setState(prevState => ({ dateKey: prevState.dateKey + 1 }))
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {/* Действует всегда */}
                                    <Grid item xs={6} md={4}/>
                                    <Grid item xs={6} md={4} style={{ marginTop: 8 }}>
                                        <Checkbox
                                            id="worksPeriodAlways"
                                            slim
                                            color="primary"
                                            disabled={readOnly}
                                            style={{ marginLeft: 8 }}
                                            className={styles.checkbox}
                                            label={t('advertisingActions.actsAlways')}
                                            checked={workingAllTime}
                                            onValueChange={(checked: boolean) => {
                                                let newStart: Date = editedAction.workPeriod?.start
                                                let newFinish: Date = null

                                                if (!checked) {
                                                    newFinish = getDayEnd().toDate()

                                                    if (moment(newFinish).isBefore(moment(newStart))) {
                                                        newFinish = moment(newStart).add(1, 'day').toDate()
                                                    }

                                                    // У запущенных нельзя чтобы заканчивалась раньше чем сегодня
                                                    if (isActive) {
                                                        const now = moment(this.props.app.now())
                                                        if (moment(newFinish).isBefore(now)) {
                                                            newFinish = now.toDate()
                                                        }
                                                    }
                                                }

                                                if (currentAction) {
                                                    this.showActiveActionDateChangeDialog(newStart, newFinish)
                                                } else {
                                                    modifyWorkingAllTime(checked)
                                                    modifyEditedAction({
                                                        workPeriod: createTimestampPeriod({
                                                            start: newStart,
                                                            finish: newFinish,
                                                        }),
                                                    })
                                                }
                                            }}
                                        />
                                    </Grid>
                                    </>
                                )}
                            />

                            {/* Взаимодействие с другими акциями */}
                            <ActionEditBlock
                                label={t('advertisingActions.relatedActions')}
                                renderChildren={() => (
                                    <>
                                        <Grid item xs={12} md={8}>
                                            <Checkbox
                                                id="exemptFromBonusDiscounts"
                                                slim
                                                color="primary"
                                                disabled={readOnly}
                                                className={styles.checkbox}
                                                label={t('advertisingActions.exemptFromBonusDiscounts')}
                                                checked={editedAction.exemptFromBonusDiscounts}
                                                onValueChange={exemptFromBonusDiscounts => {
                                                    modifyEditedAction({ exemptFromBonusDiscounts })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Checkbox
                                                id="disableChargeOnBonuses"
                                                slim
                                                color="primary"
                                                disabled={readOnly}
                                                className={styles.checkbox}
                                                label={t('advertisingActions.disableChargeOnBonuses')}
                                                checked={editedAction.disableChargeOnBonuses}
                                                onValueChange={disableChargeOnBonuses => {
                                                    modifyEditedAction({ disableChargeOnBonuses })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Checkbox
                                                id="finalAction"
                                                slim
                                                color="primary"
                                                disabled={readOnly || editedAction.worksAnytime}
                                                className={styles.checkbox}
                                                label={t('advertisingActions.finalAction')}
                                                checked={editedAction.finalAction}
                                                onValueChange={finalAction => {
                                                    modifyEditedAction({ finalAction })
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <div className={styles.checkboxWithTooltip}>
                                                <Checkbox
                                                    id="worksAnytime"
                                                    slim
                                                    color="primary"
                                                    disabled={readOnly}
                                                    className={styles.checkbox}
                                                    label={t('advertisingActions.worksAnytime')}
                                                    checked={editedAction.worksAnytime}
                                                    onValueChange={worksAnytime => {
                                                        if (worksAnytime) {
                                                            modifyEditedAction({ worksAnytime, finalAction: false })
                                                        } else {
                                                            modifyEditedAction({ worksAnytime })
                                                        }
                                                    }}
                                                />
                                                <Tooltip
                                                    placement="top-start"
                                                    title={
                                                        <div className={styles.tooltipContainer}>
                                                            <p className={styles.tooltipParagraph}>1. {t('advertisingActions.tooltipMessage1')}</p>
                                                            <p className={styles.tooltipParagraph}>2. {t('advertisingActions.tooltipMessage2')}</p>
                                                            <p className={styles.tooltipParagraph}>3. {t('advertisingActions.tooltipMessage3')}</p>
                                                        </div>
                                                    }
                                                >
                                                    <InfoRounded color="action" />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </>
                                )}
                            />

                            {/* Приоритет */}
                            <ActionEditBlock
                                label={t('advertisingActions.priority')}
                                renderChildren={() => (
                                    <div style={{ flex: 1, display: 'flex', paddingRight: 8 }}>
                                        {/* TODO: Нужен метод для получения предыдущей акции по приоритету
                                            (https://crystals.atlassian.net/browse/SFM-1152)
                                        */}
                                        {/* <Grid item xs={12} md={5}>
                                            <p className={styles.priority}>
                                                Акция сработает после акции "Сезонная акция на мандарины"
                                            </p>
                                        </Grid> */}
                                        <div style={{ flex: 1, paddingRight: 8 }}>
                                            <PriorityInput
                                                slim
                                                key={editedAction.priority} // приоритет может быть задан снаружи
                                                defaultValue={editedAction.priority}
                                                dataType={NUMBER}
                                                min={1}
                                                max={Number.MAX_SAFE_INTEGER}
                                                canBeNull
                                                rounding={15}
                                                onValueChange={priority => modifyEditedAction({ priority })}
                                                disabled={readOnly || !priorityEditingEnabled || loading}
                                            />
                                        </div>
                                        <div >
                                            {!isNew ? (
                                                <Button
                                                    id="priorityButton"
                                                    color="primary"
                                                    disabled={readOnly || isNew || !priorityEditingEnabled}
                                                    onClick={() => showPriorityEditor(true)}
                                                >
                                                    {t('advertisingActions.adjustSettings')}
                                                </Button>
                                            ) : (
                                                <Tooltip
                                                    enterDelay={TOOLTIP_DELAY}
                                                    title={t('advertisingActions.needActionSave')}
                                                >
                                                    <div>
                                                        <Button
                                                            id="priorityButton"
                                                            color="primary"
                                                            disabled
                                                        >
                                                            {t('advertisingActions.adjustSettings')}
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                )}
                            />

                            {/* Ограничения */}
                            <ActionEditBlock label={t('advertisingActions.restrictions')}>
                                <Checkbox
                                    id="useRestrictions"
                                    slim
                                    color="primary"
                                    disabled={readOnly}
                                    className={styles.checkbox}
                                    label={t('advertisingActions.ignoreRestrictions')}
                                    checked={!editedAction.useRestrictions}
                                    onValueChange={ignoreRestrictions =>
                                        modifyEditedAction({ useRestrictions: !ignoreRestrictions })
                                    }
                                />
                            </ActionEditBlock>

                        </Grid>

                        <Grid item spacing={2} xs={12} md={6} container alignItems="center">
                            {/* Зона охвата */}
                            {isCentrum && (
                                <ActionEditBlock
                                    label={t('advertisingActions.affectedZone')}
                                    renderChildren={() => (
                                        <>
                                            <Grid item xs={11} md={8}>
                                                <Checkbox
                                                    id="isAllNodes"
                                                    slim
                                                    color="primary"
                                                    disabled={readOnly}
                                                    className={styles.checkbox}
                                                    label={t('topology.allNodes')}
                                                    checked={isAllNodes}
                                                    onValueChange={(newValue: boolean) => {
                                                        modifyEditedAction({ allNodes: newValue })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1} md={1} />

                                            <Grid item xs={11} md={8}>
                                                <AffectedZoneResults
                                                    className={classNames(styles.box, styles.chipsHolder)}
                                                    emptyClassName={classNames(styles.centered, {
                                                        [styles.empty]: isAllNodes
                                                    })}
                                                    chipClassName={styles.chip}
                                                    disabled={readOnly}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    handlerId="affectedZoneEdit"
                                    handler={() => this.setState({ isAffectedZoneDialogOpen: true })}
                                    handlerDisabled={isAllNodes || readOnly}
                                />
                            )}

                            {/* Условия */}
                            <ActionEditBlock
                                label={t('advertisingActions.conditions')}
                                renderChildren={() => (
                                    <>
                                        <Grid item xs={11} md={8}>
                                            <SelectInput
                                                id="conditionTypeInput"
                                                slim
                                                disabled={readOnly}
                                                onSelect={opt => {
                                                    let changes: Partial<AdvertisingActionParsed> = { mode: opt.value }
                                                    if (opt.value === MANUAL || opt.value === UNCONDITIONAL) {
                                                        changes.externalConditionsParsed = []
                                                        changes.masterActions = []
                                                    } else {
                                                        changes.externalConditionsParsed = removeCondition(CommonConditions.CashierCondition, 
                                                            editedAction.externalConditionsParsed)
                                                    }
                                                    modifyEditedAction(changes)
                                                }}
                                                options={getConditionsModeOptions()}
                                                value={editedAction.mode}
                                            />
                                        </Grid>
                                        <Grid item xs={1} md={1} />

                                        <Grid item xs={11} md={8}>
                                            <Box
                                                mt={1}
                                                className={classNames(
                                                    styles.box, styles.chipsHolder, {
                                                        [styles.centered]: chipConditions.length === 0,
                                                        [styles.empty]: editedAction.mode === UNCONDITIONAL,
                                                    }
                                                )}
                                            >
                                                {chipConditions.length > 0 && chipConditions.map(cond => {
                                                    return (
                                                        <AsyncWrapper<string>
                                                            key={cond.type}
                                                            data={cond}
                                                            fetchFunction={async (data: Condition) => {
                                                                const chipLabel = await formatCondition(data, currencyText)
                                                                return truncate(chipLabel, { length: 150 })
                                                            }}
                                                            renderProp={value => {
                                                                return (
                                                                    <Chip
                                                                        className={styles.chip}
                                                                        label={value}
                                                                        color="primary"
                                                                    />
                                                                )
                                                            }}
                                                        />
                                                    )
                                                })}
                                                {(editedAction.mode === AUTOMATIC || editedAction.mode === BY_CONDITION)
                                                    && chipConditions.length === 0 && (
                                                        <div className={styles.centeredText}>
                                                            <p>{t('advertisingActions.noConditions')}</p>
                                                            <Button
                                                                id="conditionsAdd"
                                                                startIcon={<AddIcon />}
                                                                color="primary"
                                                                disabled={readOnly}
                                                                onClick={
                                                                    () => openSettings(
                                                                        `${isNew ? 'new' : editedAction.guid}${CONDITIONS}`
                                                                    )
                                                                }
                                                            >
                                                                {t('common.add')}
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                                {editedAction.mode === MANUAL && chipConditions.length === 0 && (
                                                        <div className={styles.centeredText}>
                                                            <p>{t('advertisingActions.conditionsMode.manualHelperText')}</p>
                                                            <Button
                                                                id="rolesAdd"
                                                                startIcon={<AddIcon />}
                                                                color="primary"
                                                                disabled={readOnly}
                                                                onClick={
                                                                    () => this.showActionEditRolesDialog()
                                                                }
                                                            >
                                                                {t('common.addRole')}
                                                            </Button>
                                                        </div>
                                                )}
                                                {editedAction.mode === UNCONDITIONAL && (
                                                    <div className={styles.centeredText}>
                                                        {t('advertisingActions.conditionsMode.unconditionalHelperText')}
                                                    </div>
                                                )}
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                handlerId="conditionsEdit"
                                handler={() => editedAction.mode === MANUAL ? this.showActionEditRolesDialog() : 
                                    openSettings(`${isNew ? 'new' : editedAction.guid}${CONDITIONS}`)}
                                handlerDisabled={
                                    editedAction.mode === UNCONDITIONAL
                                }
                            />

                            {/* Результат */}
                            <ActionEditBlock
                                labelAtTop
                                label={t('advertisingActions.result')}
                                handlerId="resultEdit"
                                handler={() => openSettings(`${isNew ? 'new' : editedAction.guid}${RESULTS}`)}
                                renderChildren={() => (
                                    <Grid item xs={12} md={8}>
                                        <Box
                                            className={classNames(
                                                styles.box, styles.chipsHolder, {
                                                    [styles.centered]: editedAction.resultsParsed.length === 0,
                                                }
                                            )}
                                        >
                                            {editedAction.resultsParsed?.length ? (
                                                <Chips
                                                    className={styles.chip}
                                                    items={uniqBy(editedAction.resultsParsed, 'type')}
                                                    format={formatResult(editedAction)}
                                                />
                                            ) : (
                                                <div className={styles.centeredText}>
                                                    <p>{t('advertisingActions.noResults')}</p>
                                                    <Button
                                                        id="resultAdd"
                                                        startIcon={<AddIcon />}
                                                        color="primary"
                                                        disabled={readOnly}
                                                        onClick={
                                                            () => openSettings(`${isNew ? 'new' : editedAction.guid}${RESULTS}`)
                                                        }
                                                    >
                                                        {t('common.add')}
                                                    </Button>
                                                </div>
                                            )}
                                        </Box>
                                    </Grid>
                                )}
                            />
                        </Grid>
                    </Grid>

                    {isAffectedZoneDialogOpen && (
                        <AffectedZoneEditor
                            onClose={() => this.setState({ isAffectedZoneDialogOpen: false })}
                        />
                    )}
                    {isSetPriceTagEditorOpen && (
                        <SetPriceTagPrintEditor
                            onClose={() => this.setState({ isSetPriceTagEditorOpen: false })}
                        />
                    )}
                    {isOpenPriorityEditor && <ActionPriorityEditor generalInteractionMethod={generalInteractionMethod} />}
                </Paper>
                <ActionPanel>
                    <div className={styles.actionPanel}>
                        <Button
                            id="backButton"
                            color="primary"
                            onClick={() => {
                                this.props.actionSettingsStore.closeEditedAction()
                            }}
                        >
                            {t('common.cancel')}
                        </Button>
                        {!isActive && editPrivilege && (
                            <AdaptiveIconButton
                                id="removeButton"
                                showTooltip
                                label={t('common.remove')}
                                adaptive={false}
                                className={styles.actionPanelIcon}
                                onClick={() => {
                                    this.props.actionSettingsStore.deleteEditedAction()
                                }}
                            >
                                <Delete />
                            </AdaptiveIconButton>
                        )}
                        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                            {
                                this.getWarningText(
                                    correctDate,
                                    gotTopology,
                                    gotConditions,
                                    gotResults,
                                )
                            }
                        </div>
                        {!isActive && editPrivilege && (
                            <Button
                                id="activateButton"
                                disabled={!isFilled || !havePrivilege(LOY_START)}
                                color="primary"
                                startIcon={<PlayArrow className={styles.playIcon} />}
                                onClick={() => {
                                    this.props.actionSettingsStore.activateEditedAction()
                                }}
                            >
                                {t('advertisingActions.startAction')}
                            </Button>
                        )}
                        {(status === ActionStatus.CURRENT || status === ActionStatus.FUTURE) && !actionDraft && editPrivilege && (
                            <Button
                                id="stopButton"
                                color="primary"
                                disabled={!havePrivilege(LOY_STOP)}
                                startIcon={<Stop className={styles.stopIcon} />}
                                onClick={stopEditedAction}
                            >
                                {t('advertisingActions.stopAction')}
                            </Button>
                        )}
                        {!finishedAction && !actionDraft && (
                            <AdaptiveIconButton
                                id="printButton"
                                showTooltip
                                adaptive={false}
                                label={isNew ? t('advertisingActions.needActionSave') : t('advertisingActions.printPriceTag')}
                                disabled={isNew}
                                style={isNew ? { opacity: 0.55 } : undefined}
                                className={styles.actionPanelIcon}
                                onClick={() => this.setState({ isSetPriceTagEditorOpen: true })}
                            >
                                <img src={SET_PRICE_TAG_ICON} alt="Setup price tags" />
                            </AdaptiveIconButton>
                        )}
                        {editPrivilege && (
                            <AdaptiveIconButton
                                id="copyButton"
                                adaptive={false}
                                showTooltip
                                label={isNew ? t('advertisingActions.needActionSave') : t('advertisingActions.copyAction')}
                                disabled={isNew}
                                className={styles.actionPanelIcon}
                                onClick={async () => {
                                    const newAction = await cloneEditedAction()
                                    updateAppBar(newAction)
                                }}
                            >
                                <FileCopy />
                            </AdaptiveIconButton>
                        )}
                        {!finishedAction && !actionDraft && editPrivilege && (
                            <Button
                                id="saveButton"
                                disabled={!(isNew || editedActionModified)}
                                variant="contained"
                                color="primary"
                                onClick={this.handleSaveAction}
                            >
                                {t('common.save')}
                            </Button>
                        )}
                    </div>
                </ActionPanel>
            </>
        )
    }
}

const PriorityInput: React.FC<Omit<NumberInputProps, 'value'> & { defaultValue: number }> = props => {
    const [value, setValue] = React.useState(props.defaultValue)
    const { ref, defaultValue, ...other } = props

    return (
        <NumberInput
            {...other}
            value={value}
            onValueChange={setValue}
            onBlur={e => {
                if (value !== props.defaultValue) {
                    props.onValueChange(value)
                }
                if (props.onBlur) {
                    props.onBlur(e)
                }
            }}
        />
    )
}

export default withRouter(ActionEdit)
