import React from 'react'
import {
    FilterType,
    USED_DATE,
} from '../../../../core/filters/filter'
import { BaseDateRangeFilter } from '../../components/base-date-range-filter'
import { InternalFilterComponentProps } from '../../new-filters'

export class UsedDate extends React.PureComponent<InternalFilterComponentProps>  {
    static filterType: FilterType = USED_DATE

    render() {
        const { id, value, onChange, slim } = this.props
        return (
            <BaseDateRangeFilter
                id={id}
                defaultValue={value}
                filterType={UsedDate.filterType}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                slim={slim}
                popperPlacement="top"
            />
        )
    }
}
