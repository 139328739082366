import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

export const GET = 'get'
export const POST = 'post'
export const PATCH = 'patch'
export const DELETE = 'delete'
export const PUT = 'put'

export const FORMATTED_DATE_REGEXP: RegExp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/

export const dateFromJSONReviver = (key: string, value: any): any => {
    if (typeof value === 'string') {
        if (value.match(FORMATTED_DATE_REGEXP)) {
            return new Date(value)
        }
    }
    return value
}

export const METHOD_NOT_SPECIFIED: string = 'notSpecified'

export type ServiceRequestMiddleware = (config: AxiosRequestConfig) => void
export type ServiceResponseMiddleware = (response: AxiosResponse) => void
export type ServiceErrorMiddleware = (error: AxiosError) => void

export interface RequestOptions {
    /**
     * Массив функций, вызывающихся перед каждым запросом.
     */
    requestMiddlewares?: ServiceRequestMiddleware[]
    /**
     * Массив функций, вызывающихся перед возвратом response
     */
    responseMiddlewares?: ServiceResponseMiddleware[]
    /**
     * Массив функций, вызывающихся перед возвратом error
     */
    errorMiddlewares?: ServiceErrorMiddleware[]
    /**
     * Массив функций, дополнительно вызывающихся перед каждым запросом.
     * Если не задан - используются commonRequestMiddlewares
     */
    customCommonRequestMiddlewares?: ServiceRequestMiddleware[]
    /**
     * Массив функций, дополнительно вызывающихся перед возвратом response
     * Если не задан - используются commonResponseMiddlewares
     */
    customCommonResponseMiddlewares?: ServiceResponseMiddleware[]
    /**
     * Массив функций, дополнительно вызывающихся перед возвратом error
     * Если не задан - используются commonErrorMiddlewares
     */
    customCommonErrorMiddlewares?: ServiceErrorMiddleware[]
    /**
     * Использовать кэшированную версию результатов запроса, если кэш есть
     */
    useCache?: boolean
    /**
     * Позволяет задать кастомный resolver для кэширования.
     * resolver позволяет получить ключ для последующего хранения результата вызова функции.
     * @param {any} args Аргументы вызываемого метода
     */
    cacheResolver?: (args: any[]) => string
    /**
     * Не писать информацию о запросе в консоль
     */
    hideConsoleLog?: boolean
}

export const commonRequestMiddlewares: ServiceRequestMiddleware[] = []
export const commonResponseMiddlewares: ServiceResponseMiddleware[] = []
export const commonErrorMiddlewares: ServiceErrorMiddleware[] = []

export class BaseService {
    private cache: { [key: string]: Map<string, any> } = {}

    constructor(public baseURL: string = '/',
                public endpoint: string = '') {
    }

    send(config: AxiosRequestConfig,
         options: RequestOptions = {}): any {
        const { errorMiddlewares, requestMiddlewares, responseMiddlewares,
                customCommonRequestMiddlewares = commonRequestMiddlewares,
                customCommonResponseMiddlewares = commonResponseMiddlewares,
                customCommonErrorMiddlewares = commonErrorMiddlewares,
                useCache,
                cacheResolver
        } = options

        const { method = METHOD_NOT_SPECIFIED, params, id } = config.data

        if (process.env.NODE_ENV === 'test') {
            // tslint:disable-next-line:no-string-literal
            if (!axios.post['mock']) {
                throw new Error(`HTTP call ${method} not mocked!`)
            }
        }

        if (customCommonRequestMiddlewares) {
            // Перед запросом прогоним конфиг через все requestMiddleware
            customCommonRequestMiddlewares.forEach(middleware => middleware(config))
        }
        if (requestMiddlewares) {
            requestMiddlewares.forEach(middleware => middleware(config))
        }
        // По-умолчанию axios не определяет корректно дату из строки, хотя использует кастомный сериализатор для дат
        config.transformResponse = (data: any): any => {
            if (!data) {
                return {}
            }
            return JSON.parse(data, dateFromJSONReviver)
        }

        let requestStart: number = Date.now()
        const showConsoleLog = !options.hideConsoleLog

        if (useCache) {
            const resolver = cacheResolver || this.defaultResolver
            const key = resolver(params)

            if (this.hasMemoizedResponse(method, key)) {
                const memoizedResponse = this.getMemoizedResponse(method, key)

                if (showConsoleLog) {
                    console.debug(`<== Сached response, data:\n`, {
                        id,
                        method,
                        params,
                        response: memoizedResponse.result
                    })
                }
                return Promise.resolve(memoizedResponse)
            }
        }

        // TODO в axios.create сейчас ошибка data.params: [] превращается в params: {}, это как-то связано с typescript
        return axios.post(this.baseURL, null, config)
            .then((response: AxiosResponse) => {
                if (response.data.error) {
                    console.error(`<== ${Date.now() - requestStart}ms, data:\n`, {
                        id,
                        method,
                        params,
                        response: response.data.error
                    })
                } else {
                    if (showConsoleLog) {
                        console.debug(`<== ${Date.now() - requestStart}ms, data:\n`, {
                            id,
                            method,
                            params,
                            response: response.data.result
                        })
                    }
                }

                if (customCommonResponseMiddlewares) {
                    // Перед возвратом response прогоняем его через все responseMiddleware
                    customCommonResponseMiddlewares.forEach(middleware => middleware(response))
                }

                if (responseMiddlewares) {
                    responseMiddlewares.forEach(middleware => middleware(response))
                }

                // Мемоизация
                const resolver = cacheResolver || this.defaultResolver

                const key = resolver(params)
                this.setMemoizedResponse(method, key, response.data)

                return response.data
            })
            .catch((error: AxiosError) => {
                console.error(`<== ${Date.now() - requestStart}ms, Axios error:\n`, {
                    id,
                    method,
                    params,
                    response: error
                })

                if (customCommonErrorMiddlewares) {
                    // Перед возвратом ошибки прогоняем ее через все errorMiddleware
                    customCommonErrorMiddlewares.forEach(middleware => middleware(error))
                }
                if (errorMiddlewares) {
                    errorMiddlewares.forEach(middleware => middleware(error))
                }
                return error
            })
    }

    setMemoizedResponse(method: string, key: string, response: any): void {
        if (!method) {
            return
        }

        if (!(method in this.cache)) {
            this.cache[method] = new Map<string, any>()
        }

        this.cache[method].set(key, response)
    }

    hasMemoizedResponses(method: string): boolean {
        return Boolean(method)
            && (method in this.cache)
            && (this.cache[method].size > 0)
    }

    hasMemoizedResponse(method: string, key: string): boolean {
        return this.hasMemoizedResponses(method)
            && this.cache[method].has(key)
    }

    getMemoizedResponse(method: string, key: string): any {
        // Вызов getMemoizedResult предваряется вызовом hasMemoizedResponse, поэтому в нём нет проверок
        return this.cache[method].get(key)
    }

    /**
     * Очищает кэш всего модуля или конкретного метода
     * @param method Метод, чей кэш необходимо очистить. Если не задан - очищает весь модуль.
     * @param key Ключ, сохраненное значение которого необходимо очистить. Если не задан - очищает метод.
     */
    clearMemoizationCache(method: string = '', key: string = ''): void {
        // Метод не задан - очищаем весь кэш модуля
        if (!method) {
            this.cache = {}
            return
        }

        // Метод не кэшировался - поле method не задано, инициализируем его новым Map
        if (!(method in this.cache)) {
            this.cache[method] = new Map<string, any>()
        }

        // Ключ не задан - очищаем весь метод
        if (!key) {
            this.cache[method].clear()
            return
        }

        // Очищаем значение с заданными методом и ключом
        this.cache[method].delete(key)
    }

    private defaultResolver: (args: any[]) => string = args => args.join(',')
}
