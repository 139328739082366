import { action, computed, observable, runInAction, toJS } from 'mobx'
import { isArray, isEqual } from 'lodash'
import uuid from 'uuid'
import { t } from 'i18next'
import { ESLHistoryConditionVO } from '../../../protocol/set10/set-retail10-commons/set-esl-api/esl-history-condition-vo'
import { eSLHistoryPresenter } from '../../../protocol/set10/esl-history-presenter'
import { eSLConfiguration } from '../../../protocol/set10/esl-configuration'
import { ESLConfigurationVO } from '../../../protocol/set10/set-retail10-commons/set-esl-api/esl-configuration-vo'
import { eSLModule } from '../../../protocol/set10/esl-module'
import { withSpinner } from '../with-spinner'
import { SnackbarStore } from '../snackbar-store'
import { getStore } from '../stores-repository'
import { SNACKBAR_STORE } from '../stores'
import { DEFAULT_PAGINATION, PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'

export class ESLUploadErrorsStore {

    @observable
    allConditionsIds: string[] = []

    @observable
    foundCondition: ESLHistoryConditionVO = null

    @observable
    configuration: ESLConfigurationVO

    @observable
    conditionsTableKey: string = uuid()

    @observable
    pagination: PaginationState = DEFAULT_PAGINATION

    pollingTimer: any = null
    pollingTimeOut: number = 4 * 1000

    snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    @computed
    get filterConditions(): string[] {
        if (this.foundCondition) {
            // Находимся в режиме поиска продукта
            return [this.foundCondition.item]
        } else {
            return this.allConditionsIds
        }
    }

    update = async (): Promise<void> => {
        await withSpinner(
            async () => {
                await this.fetchConditions()
                await this.fetchConfiguration()
                this.startPolling()
            }
        )
    }

    fetchConditions = async (): Promise<string[]> => {
        let ids = await eSLHistoryPresenter.findAll()

        if (!ids) ids = []

        // TODO ошибка транспорта https://crystals.atlassian.net/browse/SFM-208
        if (ids.length === 2 && isArray(ids[1])) {
            ids = ids[1] as any
        }

        runInAction(() => {
            if (!isEqual(toJS(this.allConditionsIds), ids)) {
                this.conditionsTableKey = uuid()
            }
            this.allConditionsIds = ids
        })
        return ids
    }

    fetchConfiguration = async (): Promise<any> => {
        const configuration = await eSLConfiguration.load()

        runInAction(() => {
            this.configuration = configuration
        })
    }

    startPolling = (): void => {
        clearTimeout(this.pollingTimer)
        this.pollingTimer = setTimeout(() => {
            this.fetchConfiguration()
            this.fetchConditions()
            this.startPolling()
        }, this.pollingTimeOut)
    }

    stopPolling = (): void => {
        clearTimeout(this.pollingTimer)
    }

    findConditionsByIds = async (ids: string[]): Promise<ESLHistoryConditionVO[]> => {
        let conditions = await eSLHistoryPresenter.findAllByItems(ids)

        if (!conditions) conditions = []

        // TODO ошибка транспорта https://crystals.atlassian.net/browse/SFM-208
        if (conditions.length === 2 && isArray(conditions[1])) {
            conditions = conditions[1] as any
        }

        return conditions
    }

    searchCondition = async (filter: string): Promise<ESLHistoryConditionVO[]> => {
        filter = filter.trim().toLowerCase()

        let conditions = await eSLHistoryPresenter.findAllBySearchString(filter)

        if (!conditions) conditions = []

        // TODO ошибка транспорта https://crystals.atlassian.net/browse/SFM-208
        if (conditions.length === 2 && isArray(conditions[1])) {
            conditions = conditions[1] as any
        }

        return conditions
    }

    launchReupload = async (condition: ESLHistoryConditionVO): Promise<void> => {
        await withSpinner(
            async () => {
                await eSLModule.invalidateItems([condition.item])
                await this.fetchConfiguration()
                await this.fetchConditions()
            }
        )

        this.snackbarStore.show({
            message: t('priceTagsEsl.productsUploadHasLaunched')
        })
    }

    @action
    updatePagination = (pagination: PaginationState): void => {
        this.pagination = pagination
    }

    @action
    setFoundCondition = (condition: ESLHistoryConditionVO): void => {
        this.foundCondition = condition
        this.conditionsTableKey = uuid()
        this.pagination = {
            ...DEFAULT_PAGINATION,
            numberOfRows: this.pagination.numberOfRows
        }
    }

    @action
    clearFoundCondition = (): void => {
        this.foundCondition = null
        this.conditionsTableKey = uuid()
    }

    @action
    reset = () => {
        this.stopPolling()
        this.allConditionsIds = []
        this.foundCondition = null
        this.configuration = null
        this.pagination = DEFAULT_PAGINATION
    }
}
