import { getWeekIntervalOptions } from '../../set10/core/week-utils'

export const HOURS_IN_DAY: number = 24
export const MINUTES_IN_HOUR: number = 60

export const minutesToHours = (minutes: number): number => {
    if (minutes === undefined) return 0
    return Math.floor(minutes / MINUTES_IN_HOUR)
}

export const daysToMinutes = (days: number): number => {
    if (days === undefined) return 0
    return days * HOURS_IN_DAY * MINUTES_IN_HOUR
}

export const hoursToMinutes = (hours: number): number => {
    if (hours === undefined) return 0
    return hours * MINUTES_IN_HOUR
}

export function bitMaskToWeekDays(bitMask: number): number[] {
    return Number(bitMask).toString(2).split('').reverse()
        .map((item, index) => {
            if (Number(item)) return index + 1
        })
        .filter(Boolean)
}

export function weekDaysToBitMask(weekDays: number[]): number {
    const [allWeekParam, ...allWeekDays] = getWeekIntervalOptions()

    const binaryBitMaskString = allWeekDays.map(weekDay => {
        if (weekDays.some(day => day === weekDay.value)) {
            return '1'
        }
        return '0'
    }).reverse().join('')

    return parseInt(binaryBitMaskString, 2)
}
