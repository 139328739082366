import { observable, action, computed, runInAction, toJS } from 'mobx'
import { t } from 'i18next'
import { isNil, get } from 'lodash'
import { UserStore } from '../user-store'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import {
    APP_STORE, USER_STORE, PRICE_TAG_SETTINGS_STORE, APP_BAR_STORE, PRICE_TAG_TEMPLATE_BINDING_STORE,
    PRICE_TAGS_TEMPLATES_STORE, PRICE_TAGS_EDITOR_STORE,
} from '../stores'
import { printersManagerLocal } from '../../../protocol/set10/printers-manager-local'
import { PriceTagTemplateVO, createPriceTagTemplateVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-template-vo'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { SimpleDepartment } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-department'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { RouteChangeHandler, goTo } from '../../utils/router-util'
import { EDITOR, PRICE_TAGS, TEMPLATES } from '../../core/app-routes'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { PRICETAG } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/print-doc-type'
import { deserializePriceTagXML, serializePriceTagXML } from '../../core/price-tags/serialize-utils'
import { XMLPriceTag } from '../../core/price-tags/xml/xml-price-tag'
import {
    PriceTagSizeLabelVO, createPriceTagSizeLabelVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/price-tag-size-label-vo'
import { getUniqueNameWithNumberPostfix } from '../../../utils/name-util'
import { PriceTagsTemplatesStore } from './price-tags-templates-store'
import { PriceTagTemplatesBindingStore } from './price-tag-templates-binding-store'
import { PriceTagsEditorStore } from './price-tags-editor-store'
import { withSpinner } from '../with-spinner'
import { mnemonicsPropertiesFacadeLocal } from '../../../protocol/set10/mnemonics-properties-facade-local'
import { MnemonicGroupVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-group-vo'
import { MnemonicPropertiesVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'

const A4_WIDTH = 210
const A4_HEIGHT = 297

export class PriceTagSettingsStore {

    @observable
    priceTagSettings: PriceTagTemplateVO = null

    @observable
    validation: FormValidation<PriceTagTemplateVO> = null

    @observable
    newPriceTag: boolean = false

    @observable
    departments: SimpleDepartment[] = []

    @observable
    priceTagXMLData: XMLPriceTag = null

    @observable
    sizeLabels: PriceTagSizeLabelVO[] = []

    @observable
    newSizeLabel: string = ''

    @observable
    sizeLabelDialogOpen: boolean = false

    @observable
    mnemonicGroups: MnemonicGroupVO[] = []

    @computed
    get sizeLabelsOptions(): Array<{ label: string, value: number }> {
        // Добавляем элемент "Не указано"
        let optionsArray = [
            createPriceTagSizeLabelVO({
                id: -1,
                name: t('priceTags.noSizeLabel')
            })
        ]
        optionsArray = optionsArray.concat(toJS(this.sizeLabels))

        return optionsArray.map(item => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }

    @computed
    get mnemonics(): MnemonicPropertiesVO[] {
        return this.mnemonicGroups.reduce((prevMnems: MnemonicPropertiesVO[], currentGroup: MnemonicGroupVO) => {
            return [
                ...prevMnems,
                ...currentGroup.mnemonicProperties
            ]
        }, [])
    }

    @computed
    get sizeLabelIsNotUnique(): boolean {
        return Boolean(this.sizeLabels.find(item => item.name === this.newSizeLabel))
    }

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)
    private priceTagsTemplatesStore: PriceTagsTemplatesStore = getStore(PRICE_TAGS_TEMPLATES_STORE)
    private priceTagsEditorStore: PriceTagsEditorStore = getStore(PRICE_TAGS_EDITOR_STORE)

    fetchMnemonicGroups = async (): Promise<void> => {
        const mnemonicGroups = await mnemonicsPropertiesFacadeLocal.getAllByGroups({ useCache: true }) || []

        runInAction(() => {
            this.mnemonicGroups = mnemonicGroups
        })
    }

    fetchDepartments = async (): Promise<void> => {
        const departments = await iProductsManagerLocal.getDepartments(this.userStore.session)
        runInAction(() => {
            this.departments = departments
        })
    }

    fetchSizeLabels = async (): Promise<void> => {
        const sizeLabels = await printersManagerLocal.getAllPriceTagSizeLabels()
        runInAction(() => {
            this.sizeLabels = this.sortSizeLabels(sizeLabels)
        })
    }

    sortSizeLabels = (sizeLabelsArray: PriceTagSizeLabelVO[]): PriceTagSizeLabelVO[] => {
        return sizeLabelsArray.sort((a: PriceTagSizeLabelVO, b: PriceTagSizeLabelVO) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
        })
    }

    fetchPriceTag = async (id: string): Promise<void> => {
        if (isNaN(Number(id))) {
            goTo(`${PRICE_TAGS}${TEMPLATES}`)
            return Promise.resolve()
        }

        const priceTag = await printersManagerLocal.getPriceTagTemplate(this.userStore.session, id)
        runInAction(() => {
            this.priceTagSettings = priceTag
            this.newPriceTag = false
        })

        await this.createPriceTagXMLData()
        this.createValidation()
        this.updateNavMenu()

        await this.appStore.refreshCentrumConnectionStatus()
        await this.fetchSizeLabels()
        await this.fetchDepartments()
    }

    @action
    reopenPriceTag = async (priceTag: PriceTagTemplateVO): Promise<void> => {
        this.priceTagSettings = priceTag
        this.newPriceTag = false

        await this.createPriceTagXMLData()
        this.createValidation()
    }

    @action
    addPriceTag = async (): Promise<void> => {

        // Для определения номера нового ценника необходим список всех ценников
        if (!this.priceTagsTemplatesStore.priceTags) {
            await this.priceTagsTemplatesStore.fetchPriceTags()
        }

        runInAction(() => {
            this.newPriceTag = true
            this.priceTagSettings = createPriceTagTemplateVO({
                id: null,
                displayColor: 'blue',
                name: getUniqueNameWithNumberPostfix(
                    t('priceTags.newTemplateName'),
                    this.priceTagsTemplatesStore.priceTags,
                    'name'
                ),
                width: A4_WIDTH,
                height: A4_HEIGHT,
                type: PRICETAG,
                data: '',
                subPriceTagTemplates: [],
                serveAllProductsList: true,
            })
        })

        await this.createNewPriceTagXMLData(A4_WIDTH, A4_HEIGHT)
        this.createValidation(true)
        this.updateNavMenu()

        await this.appStore.refreshCentrumConnectionStatus()
        await this.fetchSizeLabels()
        await this.fetchDepartments()
    }

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<PriceTagTemplateVO>(
            this.priceTagSettings,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField
                    ]
                },
            ],
            creation
        )
    }

    @action
    editPriceTag = (changes: Partial<PriceTagTemplateVO>): void => {
        Object.keys(changes).forEach(key => {
            this.priceTagSettings[key] = changes[key]
        })
    }

    @action
    editPriceTagSizeLabel = (sizeLabelId: number): void => {
        const index = this.sizeLabels.findIndex(item => sizeLabelId === item.id)

        if (index === -1) {
            this.priceTagSettings.sizeLabel = null
        } else {
            this.priceTagSettings.sizeLabel = this.sizeLabels[index]
        }
    }

    createPriceTagXMLData = async (): Promise<void> => {
        if (!this.priceTagSettings || !this.priceTagSettings.data) return
        const xmlData = await deserializePriceTagXML(this.priceTagSettings.data)
        runInAction(() => {
            this.priceTagXMLData = xmlData
        })
    }

    createNewPriceTagXMLData = async (width: number, height: number): Promise<void> => {
        let xmlData: XMLPriceTag = {
            width,
            height,
            images: null,
            textBlocks: null,
            subTemplates: null,
            formulas: null,
            customElements: null,
            barcodeElements: null,
            qrcodeElements: null,
        }
        const base64Data = serializePriceTagXML(xmlData)

        runInAction(() => {
            this.priceTagSettings.data = base64Data
            this.priceTagSettings.height = height
            this.priceTagSettings.width = width

            this.priceTagXMLData = xmlData
        })
    }

    editPriceTagXMLData = async (changes: Partial<XMLPriceTag>): Promise<void> => {
        let xmlData = await deserializePriceTagXML(this.priceTagSettings.data)

        Object.keys(changes).forEach(k => {
            xmlData[k] = changes[k]
        })

        const base64Data = serializePriceTagXML(xmlData)

        runInAction(() => {
            this.priceTagSettings.data = base64Data
            this.priceTagSettings.height = xmlData.height
            this.priceTagSettings.width = xmlData.width

            this.priceTagXMLData = xmlData
        })
    }

    openTemplateEditor = (): void => {
        this.priceTagsEditorStore.openPriceTag(toJS(this.priceTagSettings))
    }

    savePriceTagSettings = async (): Promise<void> => {
        await printersManagerLocal.savePriceTagTemplate(this.userStore.session, toJS(this.priceTagSettings))
        goTo(`${PRICE_TAGS}${TEMPLATES}`)
        this.appStore.showSnackbar({
            message: t('priceTags.priceTagSaved')
        })
    }

    @action
    openSizeLabelDialog = (): void => {
        this.sizeLabelDialogOpen = true
    }

    @action
    closeSizeLabelDialog = (): void => {
        this.sizeLabelDialogOpen = false
        this.newSizeLabel = ''
    }

    @action
    editSizeLabel = (id: number, name: string): void => {
        if (isNil(id)) {
            this.newSizeLabel = name
        } else {
            const sizeLabel = this.sizeLabels.find(item => item.id === id)
            if (sizeLabel) {
                sizeLabel.name = name

                // При изменении имени сортируем по новой
                this.sizeLabels = this.sortSizeLabels(this.sizeLabels)
            }
        }
    }

    // Проверяет наличие дубликатов имен, среди всех других названий ценников
    checkSizeLabelsForDuplicates = (sizeLabelId: number, newName: string): boolean => {
        return Boolean(this.sizeLabels.find(item => item.id !== sizeLabelId && item.name === newName))
    }

    showSaveSizeLabelDialog = (sizeLabel: PriceTagSizeLabelVO, newName: string): void => {
        this.newSizeLabel = ''
        const oldName = sizeLabel.name
        this.editSizeLabel(sizeLabel.id, newName)

        this.appStore.showDialog({
            title: t('priceTags.saveSizeLabelDialogTitle'),
            message: t('priceTags.saveSizeLabelDialogMessage'),
            mode: DIALOG,
            onYes: () => this.saveSizeLabel(sizeLabel),
            onNo: () => this.editSizeLabel(sizeLabel.id, oldName)
        })
    }

    saveSizeLabel = async (sizeLabel: PriceTagSizeLabelVO): Promise<void> => {
        this.newSizeLabel = ''
        await printersManagerLocal.savePriceTagSizeLabel(toJS(sizeLabel))
        await this.fetchSizeLabels()
        this.appStore.showSnackbar({message: t('priceTags.sizeLabelSaved')})
    }

    showRemoveSizeLabelDialog = (sizeLabel: PriceTagSizeLabelVO): void => {
        this.appStore.showDialog({
            title: t('priceTags.removeSizeLabelDialogTitle'),
            message: t('priceTags.removeSizeLabelDialogMessage'),
            mode: DIALOG,
            onYes: () => this.removeSizeLabel(sizeLabel)
        })
    }

    removeSizeLabel = async (sizeLabel: PriceTagSizeLabelVO): Promise<void> => {
        await printersManagerLocal.removePriceTagSizeLabel(sizeLabel.id)
        await this.fetchSizeLabels()
        this.appStore.showSnackbar({message: t('priceTags.sizeLabelRemoved')})

        // Если мы удалили уже выбранное нами название размера - размер не выбран становится
        const currentSizeLabel = this.priceTagSettings.sizeLabel
        if (currentSizeLabel && currentSizeLabel.id === sizeLabel.id) {
            runInAction(() => {
                this.priceTagSettings.sizeLabel = null
            })
        }
    }

    goBack = (): void => {
        if (this.validation.modified) {
            this.appStore.showDialog({
                title: t('priceTags.modifiedTitle'),
                message: t('priceTags.modifiedMessage'),
                mode: DIALOG,
                onYes: () => goTo(`${PRICE_TAGS}${TEMPLATES}`)
            })
        } else {
            goTo(`${PRICE_TAGS}${TEMPLATES}`)
        }
    }

    updateNavMenu = (): void => {
        this.appBarStore.updateState({
            title: this.newPriceTag ? t('priceTags.newPriceTag') : t('priceTags.priceTagEditing'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: this.goBack
        })
    }

    @action
    reset = (): void => {
        this.priceTagSettings = null
        this.validation = null
        this.priceTagXMLData = null
        this.newPriceTag = false
        this.sizeLabels = []
        this.departments = []
        this.newSizeLabel = ''
        this.sizeLabelDialogOpen = false
        this.mnemonicGroups = []
    }

}

export const PRICE_TAG_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`${PRICE_TAGS}${TEMPLATES}/-?\\w+$`),
    onEnter: () => {
        const priceTagSettingsStore: PriceTagSettingsStore = getStore(PRICE_TAG_SETTINGS_STORE)
        priceTagSettingsStore.updateNavMenu()
    },
    onLeave: (newRoute: string) => {
        const priceTagSettingsStore: PriceTagSettingsStore = getStore(PRICE_TAG_SETTINGS_STORE)

        const id = get(priceTagSettingsStore, 'priceTagSettings.id')

        if (!id || newRoute !== `${PRICE_TAGS}${TEMPLATES}/${id}${EDITOR}`) {
            priceTagSettingsStore.reset()

            const priceTagsBindingStore: PriceTagTemplatesBindingStore = getStore(PRICE_TAG_TEMPLATE_BINDING_STORE)
            priceTagsBindingStore.reset()
        }
    }
}
