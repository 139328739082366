import {observable, action} from 'mobx'
import {DialogMode, ALERT} from '../../components/simple-dialog/simple-dialog'

export interface AppDialogProps {
    id?: string
    title?: string
    message: string | JSX.Element
    mode?: DialogMode
    onOk?: () => void
    onYes?: () => void
    onNo?: () => void
    onCancel?: () => void
    okLabel?: string
    yesLabel?: string
    noLabel?: string
    className?: string
}

export class DialogStore {

    @observable
    open: boolean = false

    defaultDialog: AppDialogProps = {
        id: 'appDialog',
        title: '',
        message: '',
        mode: ALERT,
        onOk: () => null,
        onYes: () => null,
        onNo: () => null,
        onCancel: () => null,
        okLabel: '',
        yesLabel: '',
        noLabel: '',
        className: '',
    }

    @observable
    dialogProps: AppDialogProps = this.defaultDialog

    createCloseCallback = (handler: () => void) => {
        return () => {
            handler()
            this.close()
        }
    }

    @action
    showDialog = (dialogProps: AppDialogProps): void => {
        this.open = true
        this.dialogProps = {
            ...this.defaultDialog,
            ...dialogProps,
        }
        // Нажатие любой кнопки закроет диалог
        const {onOk, onYes, onNo, onCancel} = this.dialogProps
        this.dialogProps.onOk = this.createCloseCallback(onOk)
        this.dialogProps.onYes = this.createCloseCallback(onYes)
        this.dialogProps.onNo = this.createCloseCallback(onNo)
        this.dialogProps.onCancel = this.createCloseCallback(onCancel)
    }

    @action
    close = () => {
        this.open = false
    }

    @action
    reset = (): void => {
        this.open = false
        this.dialogProps = this.defaultDialog
    }
}
