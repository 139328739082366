import React from 'react'
import { getFilterTypeName, FilterType, ACTION_LABEL } from '../../../core/filters/filter'
import { InternalFilterComponentProps } from '../new-filters'
import { AutocompleteInput } from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { iDiscountsManagerLocal } from '../../../../protocol/set10/i-discounts-manager-local'
import { t } from 'i18next'

export class ActionLabel extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_LABEL

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <AutocompleteInput<string>
                id={id}
                slim={slim}
                onSuggestionSelect={(label: string) => {
                    onChange(ActionLabel.filterType, {
                        value: label,
                    })
                }}
                label={getFilterTypeName(ActionLabel.filterType)}
                shownValue={value}
                showClearButton
                placeholder={t('filters.actionLabelPlaceholder')}
                fetchDataCallback={mask => iDiscountsManagerLocal.findLabelsLike(mask, 20, null)}
                suggestionsLimit={20}
            />
        )
    }
}
