import React from 'react'
import { inject, observer } from 'mobx-react'
import { APP_STORE, USER_STORE, LOYALTY_COUPONS_INSTANCES_STORE } from '../../../../store/stores'
import { SideBarFilters, SideBarFiltersState } from '../../../../components/filters/side-bar-filters'
import { ACTIONS_COUPONS_FILTERS } from '../../../../core/filters/favorite-filters'
import { FilterGroup } from '../../../../components/filters/components/filter-group'
import { AppStore } from '../../../../store/app-store'
import { t } from 'i18next'
import { UserStore } from '../../../../store/user-store'
import { LoyaltyCouponsInstancesStore } from '../../../../store/loyalty/loyalty-coupons-instances-store'
import { NamePart } from '../../../../components/filters/new-modules/loyalty-coupons/name-part'
import { UsedDate } from '../../../../components/filters/new-modules/loyalty-coupons/used-date'
import { CreatedDate } from '../../../../components/filters/new-modules/loyalty-coupons/created-date'
import { CouponStatus } from '../../../../components/filters/new-modules/loyalty-coupons/coupon-status'
import { CreatedCheck } from '../../../../components/filters/new-modules/loyalty-coupons/created-check'
import { UsedCheck } from '../../../../components/filters/new-modules/loyalty-coupons/used-check'
import { CouponNames } from '../../../../components/filters/new-modules/loyalty-coupons/coupon-names'

interface LoyaltyCouponsInstancesProps {
    loyaltyCouponsInstancesStore?: LoyaltyCouponsInstancesStore
    user?: UserStore
    app?: AppStore
}

@inject(LOYALTY_COUPONS_INSTANCES_STORE)
@inject(USER_STORE)
@inject(APP_STORE)
@observer
export class LoyaltyCouponsInstancesFilters extends React.Component<LoyaltyCouponsInstancesProps> {

    render() {
        const { fetchCoupons, clearFilters, filtersState, setFiltersState, gotFilters } = this.props.loyaltyCouponsInstancesStore
        const { scrollToTop } = this.props.app

        return (
            <SideBarFilters
                favoriteFiltersKey={ACTIONS_COUPONS_FILTERS}
                defaultState={filtersState}
                onStateChange={setFiltersState}
                onApply={() => {
                    fetchCoupons()
                    scrollToTop()
                }}
                applyDisabled={!gotFilters}
                onClear={(state: SideBarFiltersState) => {
                    clearFilters(state)
                    scrollToTop()
                }}
                withPaper
            >
                <FilterGroup name={t('loyaltyCoupons.instancesPage.filters.fullName')}>
                    <CouponNames/>
                </FilterGroup>
                <FilterGroup name={t('loyaltyCoupons.instancesPage.filters.namePart')}>
                    <NamePart defaultFavorite/>
                </FilterGroup>
                <FilterGroup name={t('loyaltyCoupons.instancesPage.filters.status')}>
                    <CouponStatus />
                </FilterGroup>
                <FilterGroup name={t('loyaltyCoupons.instancesPage.filters.usedCheck')}>
                    <UsedCheck/>
                </FilterGroup>
                <FilterGroup name={t('loyaltyCoupons.instancesPage.filters.createdCheck')}>
                    <CreatedCheck/>
                </FilterGroup>
                <FilterGroup name={t('loyaltyCoupons.instancesPage.filters.usedDate')}>
                    <UsedDate />
                </FilterGroup>
                <FilterGroup name={t('loyaltyCoupons.instancesPage.filters.createdDate')}>
                    <CreatedDate />
                </FilterGroup>
            </SideBarFilters>
        )
    }
}
