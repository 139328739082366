import React from 'react'
import { FilterType, ACTION_SECONDARY_RESULT } from '../../../core/filters/filter'
import { BaseSelectFilter } from '../components/base-select-filter'
import { DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { t } from 'i18next'
import { InternalFilterComponentProps } from '../new-filters'
import {
    CHEQUE_MESSAGE, OPERATOR_MESSAGE, SCANNER_MESSAGE, COUPON, BONUS_CHARGE, GIFT_NOTIFICATION
} from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/secondary-result-types'

const getOptions = (): DefaultSelectOption[] => [
    {
        label: t('actionsSearch.results.chequeAtPrint'),
        value: CHEQUE_MESSAGE
    },
    {
        label: t('actionsSearch.results.messageToCashier'),
        value: OPERATOR_MESSAGE
    },
    {
        label: t('actionsSearch.results.messageToScanner'),
        value: SCANNER_MESSAGE
    },
    {
        label: t('actionsSearch.results.printingOfCoupon'),
        value: COUPON
    },
    {
        label: t('actionsSearch.results.bonusCharging'),
        value: BONUS_CHARGE
    },
    {
        label: t('actionsSearch.results.informationAboutGifts'),
        value: GIFT_NOTIFICATION
    },
]

export class ActionSecondaryResult extends React.PureComponent<InternalFilterComponentProps> {
    static filterType: FilterType = ACTION_SECONDARY_RESULT

    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <BaseSelectFilter
                id={id}
                defaultValue={value}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                multiple
                filterType={ActionSecondaryResult.filterType}
                options={getOptions()}
                slim={slim}
            />
        )
    }
}
