import React from 'react'
import { inject, observer } from 'mobx-react'
import { PRODUCT_RESTRICTIONS_STORE, PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductRestrictionsStore, Restriction } from '../../../store/products/product-restrictions-store'
import { t } from 'i18next'
import moment from 'moment'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { formatPrice } from '../../../core/currency-utils'
import { NothingFound } from '../../../components/stub-with-img/nothing-found'
import { Checkbox } from '@crystalservice/crystals-ui/lib/components/inputs/checkbox/checkbox'
import {
    DateRangePicker
} from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/date-range-picker/date-range-picker'
import { Table, NUMBER } from '@crystalservice/crystals-ui/lib'
import { ProductDetailsStore } from '../../../store/products/product-details-store'

const styles = require('./product-restrictions.scss')

export interface ProductRestrictionsProps {
    productRestrictionsStore?: ProductRestrictionsStore
    productDetailsStore?: ProductDetailsStore
}

@inject(PRODUCT_RESTRICTIONS_STORE)
@inject(PRODUCT_DETAILS_STORE)
@observer
export default class ProductRestrictions extends React.Component<ProductRestrictionsProps> {

    componentDidMount() {
        const { fetchRestrictions } = this.props.productRestrictionsStore
        fetchRestrictions()
    }

    render() {
        const {
            dateRange, restrictions, setDateRange, showOnlyActive, setShowOnlyActive
        } = this.props.productRestrictionsStore

        let content
        let notLoaded = !restrictions

        if (notLoaded) {
            content = <PageLoader/>
        } else {
            content = (
                <Table
                    id="restrictionsLists"
                    withHeader
                    items={restrictions}
                    keyField="id"
                    columns={[
                        {
                            header: t('productDetails.restrictions.type'),
                            labelField: 'label',
                        },
                        {
                            header: t('productDetails.restrictions.price'),
                            labelFunction: (item: Restriction) => {
                                return item.price ? formatPrice(item.price) : ''
                            },
                            dataType: NUMBER,
                        },
                        {
                            header: t('productDetails.restrictions.discount'),
                            labelFunction: (item: Restriction) => {
                                return item.percent ? `${item.percent}%` : ''
                            },
                            dataType: NUMBER
                        },
                        {
                            padding: '0 0 0 16px',
                            header: t('productDetails.restrictions.dateRange'),
                            labelFunction: (item: Restriction) => {
                                const startDate = moment(item.startDate).format('DD.MM.YYYY (HH:mm)')
                                const endDate = item.endDate ?
                                    moment(item.endDate).format('DD.MM.YYYY (HH:mm)') :
                                    `${t('productDetails.indefinitely')}`

                                return `${startDate} – ${endDate}`
                            },
                        },
                        {
                            header: t('productDetails.restrictions.timeRange'),
                            labelFunction: (item: Restriction) => {
                                if (!item.sinceTime && !item.tillTime) {
                                    return `${t('productDetails.restrictions.allDay')}`
                                }

                                const sinceTime = moment(item.sinceTime, 'HH:mm:ss').format('HH:mm')
                                const tillTime = moment(item.tillTime, 'HH:mm:ss').format('HH:mm')

                                return `${sinceTime} – ${tillTime}`
                            },
                        },
                    ]}
                />
            )
        }

        return (
            <div className={styles.restrictions}>
                <div className={styles.dateContainer}>
                    <Checkbox
                        id="showActiveCheckbox"
                        label={t('productDetails.restrictions.showOnlyActive')}
                        checked={showOnlyActive}
                        onValueChange={setShowOnlyActive}
                        disabled={notLoaded}
                        className={styles.showActive}
                        color="primary"
                    />
                    <DateRangePicker
                        id="rangePicker"
                        label={t('productDetails.restrictions.rangeLabel')}
                        disabled={showOnlyActive || notLoaded}
                        defaultStartDate={dateRange.startDate}
                        defaultEndDate={dateRange.endDate}
                        endDatePickerProps={{
                            placeholder: `  ${t('productDetails.indefinitely')}`
                        }}
                        onDatesChange={(startDate: Date, endDate: Date) => {
                            setDateRange(startDate, endDate)
                        }}
                        withTime
                    />
                </div>

                {content}
                {restrictions && restrictions.length === 0 && (
                    <NothingFound
                        hintText=""
                        titleText={t('productDetails.restrictions.noRestrictions')}
                    />
                )}
            </div>
        )
    }
}
