import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import { action, observable, runInAction, toJS } from 'mobx'
import { TreeItem } from '../../../components/tree-view/tree-view'
import { iUserManagementLocal } from '../../../protocol/set10/i-user-management-local'
import { PrivilegeVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/privilege-vo'
import { StringPairVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/string-pair-vo'
import { createUserRoleVO, UserRoleVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/user-role-vo'
import { FormValidation } from '../../../utils/form-validation/form-validation'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../utils/form-validation/validators/unique-field'
import { getUniqueNameWithNumberPostfix } from '../../../utils/name-util'
import { STAFF, USER_ROLE, USER_ROLES } from '../../core/app-routes'
import { NEW } from '../../core/values'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { AppStore } from '../app-store'
import { APP_BAR_STORE, APP_STORE, ROLES_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { textWithDotsValidator } from '../../../utils/form-validation/validators/regexp-validator'

const MAX_NAME_LENGTH: number = 30

export class RolesStore {

    @observable
    roles: UserRoleVO[] = []
    @observable
    role: UserRoleVO
    @observable
    originalRole: UserRoleVO
    @observable
    newRole: boolean = false
    @observable
    privileges: PrivilegeVO[]
    @observable
    privilegeHierarchy: TreeItem[]
    @observable
    selectedPrivileges: StringPairVO[] = []
    @observable
    validation: FormValidation<UserRoleVO>

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    @action
    createValidation = (creation: boolean = false): void => {
        this.validation = new FormValidation<UserRoleVO>(
            this.role,
            [
                {
                    field: 'roleName',
                    rules: [
                        requiredField,
                        fieldLengthValidator({
                            max: MAX_NAME_LENGTH
                        }),
                        textWithDotsValidator,
                        uniqueField(this.roles
                            .filter(r => r.id !== this.role.id)
                            .map(r => r.roleName))
                    ]
                }
            ],
            creation
        )
    }

    @action
    getEditingRoleData = async (roleIdString: string): Promise<void> => {
        const newRoleInUrl = roleIdString === NEW
        await this.getRoles()
        if (newRoleInUrl) {
            runInAction(() => {
                this.newRole = true
                this.role = createUserRoleVO({
                    roleName: getUniqueNameWithNumberPostfix(t('staff.newRole'), this.roles, 'roleName'),
                    privilegeVOList: [],
                    centrum: false
                })
                this.createValidation(true)
                this.updateNavMenu()
            })
        } else {
            const roleId = Number(roleIdString)
            const role = this.roles.find(roleItem => roleItem.id === roleId)

            if (!role) {
                goTo(`${STAFF}${USER_ROLES}`)
            } else {
                runInAction(() => {
                    this.newRole = false
                    this.role = role
                    this.originalRole = cloneDeep(toJS(role))
                    this.createValidation()
                })
            }
        }
    }

    getServerPrivileges = async (): Promise<void> => {
        if (!this.privileges || !this.privilegeHierarchy) {
            await this.getAllPrivileges()
            await this.getPrivilegeHierarchy()
        }
    }

    @action
    leaveRolePage = (): void => {
        this.role = null
        this.selectedPrivileges = []
    }

    getRoles = (): Promise<any> => {
        if (this.appStore.isCentrum) {
            return iUserManagementLocal.getUserRoleList(this.userStore.session)
                .then(roles => {
                    runInAction(() => {
                        this.roles = roles
                    })
                })
        } else {
            return iUserManagementLocal.getUserRoles(this.userStore.session, false)
                .then(roles => {
                    runInAction(() => {
                        this.roles = roles
                    })
                })
        }
    }

    addNewRole = (): void => {
        this.newRole = true
        this.role = createUserRoleVO({
            roleName: getUniqueNameWithNumberPostfix(t('staff.newRole'), this.roles, 'roleName'),
            privilegeVOList: [],
            centrum: false
        })
        this.createValidation(true)
        goTo(`${STAFF}${USER_ROLE}/${NEW}`)
    }

    @action
    openRole = (role: UserRoleVO): void => {
        this.newRole = false
        this.role = role
        this.originalRole = cloneDeep(toJS(role))
        this.createValidation()
        goTo(`${STAFF}${USER_ROLE}/${role.id}`)
    }

    @action
    editRole = (changes: Partial<UserRoleVO>): void => {
        this.role = {
            ...toJS(this.role),
            ...changes
        }
        this.validation.item = this.role
    }

    @action
    selectPrivilege = (info: StringPairVO, selected: boolean): void => {
        let privilege = this.selectedPrivileges.find(p => p.key === info.key)
        if (selected && !privilege) {
            this.selectedPrivileges.push(info)
        }
        if (!selected && privilege) {
            this.selectedPrivileges.splice(this.selectedPrivileges.indexOf(privilege), 1)
        }
    }

    getAllPrivileges = () => {
        return iUserManagementLocal.getAllPrivileges()
            .then(privileges => {
                runInAction(() => {
                    this.privileges = privileges
                })
            })
    }

    getPrivilegeHierarchy = (): Promise<any> => {
        return getPrivilegeHierarchy(this.appStore.isCentrum, this.privileges)
            .then(hierarchy => {
                runInAction(() => {
                    this.privilegeHierarchy = hierarchy
                })
            })
    }

    saveRoleAndExit = async (): Promise<void> => {
        await withSpinner(async () => {
            if (this.newRole) {
                // Вначале нужно создать новую роль на сервере, а после проапдейтить ее
                const savedRole = this.appStore.isCentrum
                    ? await iUserManagementLocal.addRole(this.userStore.session, this.role.roleName, this.role.centrum)
                    : await iUserManagementLocal.newRole(this.userStore.session, this.role.roleName)
                await iUserManagementLocal.updateRole(this.userStore.session, {
                    ...toJS(this.role),
                    id: savedRole.id,
                })
            } else {
                await iUserManagementLocal.updateRole(this.userStore.session, toJS(this.role))
            }
            goTo(`${STAFF}${USER_ROLES}`)
        })
    }

    cancelChanges = (): void => {
        goTo(`${STAFF}${USER_ROLES}`)
    }

    updateNavMenu = (): void => {
        this.appBarStore.updateState({
            title: this.newRole ? t('staff.newRoleCreation') : t('staff.roleEditing'),
            leftIcon: LEFT_ARROW,
            onLeftIconClick: this.cancelChanges
        })
    }

    @action
    reset = (): void => {
        this.roles = []
        this.role = undefined
        this.originalRole = undefined
        this.newRole = false
        this.privileges = undefined
        this.privilegeHierarchy = undefined
        this.selectedPrivileges = []
        this.validation = undefined
    }
}

// TODO сейчас приходится делать кучу запросов, чтобы вытащить список всех привилегий из xml на сервере
const getPrivilegeHierarchy = async (centrum: boolean, allPrivileges: PrivilegeVO[]): Promise<TreeItem[]> => {
    let modules: StringPairVO[] = null
    let categories: StringPairVO[] = []
    let receivedPrivileges: StringPairVO[] = []
    let result: TreeItem[] = []

    let moduleByName: { [name: string]: TreeItem } = {}
    let categoryByName: { [name: string]: TreeItem } = {}

    const appStore: AppStore = getStore(APP_STORE)
    const userStore: UserStore = getStore(USER_STORE)

    // Получаем все модули
    const modulesFromServer = await iUserManagementLocal.getModuleListFromRegistry(
        userStore.session,
        appStore.locale,
        centrum
    )
    modules = modulesFromServer
    modulesFromServer.forEach(module => {
        let item: TreeItem = { item: module, children: [] }
        result.push(item)
        moduleByName[module.key] = item
    })

    // Получаем все категории для каждого модуля
    await new Promise(resolve => {
        const getCategory = (moduleIndex: number = 0) => {
            let moduleName: string = modules[moduleIndex].key
            return iUserManagementLocal.getCategoryListFromRegistry(
                userStore.session, moduleName, appStore.locale, centrum)
                .then(categoriesFromServer => {
                    categories = categories.concat(categoriesFromServer)
                    categoriesFromServer.forEach(category => {
                        let item: TreeItem = {
                            item: category,
                            children: []
                        }
                        categoryByName[category.key] = item
                        moduleByName[moduleName].children.push(item)
                    })

                    if (moduleIndex >= modules.length - 1) {
                        resolve(categories)
                    } else {
                        return getCategory(moduleIndex + 1)
                    }
                })
        }

        if (modules.length > 0) {
            return getCategory()
        } else {
            resolve(categories)
        }
    })

    // Получаем все привилегии для каждой категории
    await new Promise(resolve => {
        const getPrivileges = (categoryIndex: number = 0) => {
            let categoryName: string = categories[categoryIndex].key
            let module: TreeItem = result.find(moduleItem => {
                let categoryItem = moduleItem.children.find(categoryItem => {
                    return categoryItem.item.key === categoryName
                })
                return Boolean(categoryItem)
            })
            return iUserManagementLocal.getRightsList(
                userStore.session, module.item.key, appStore.locale, categoryName, centrum)
                .then(privilegesFromServer => {
                    receivedPrivileges = receivedPrivileges.concat(privilegesFromServer)
                    privilegesFromServer.forEach(privilege => {
                        if (!allPrivileges.some(p => p.name === privilege.key)) {
                            // Приходят привилегии, которых нет на сервере
                            return
                        }
                        let item: TreeItem = {
                            item: privilege,
                        }
                        categoryByName[categoryName].children.push(item)
                    })

                    if (categoryIndex >= categories.length - 1) {
                        resolve(categories)
                    } else {
                        return getPrivileges(categoryIndex + 1)
                    }
                })
        }

        if (categories.length > 0) {
            return getPrivileges()
        } else {
            resolve(receivedPrivileges)
        }
    })

    return result
}

export const ROLE_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${STAFF}${USER_ROLE}/[\\w-]+/?$`),
    onEnter: () => {
        const rolesStore: RolesStore = getStore(ROLES_STORE)
        rolesStore.updateNavMenu()
    },
    onLeave: () => {
        const rolesStore: RolesStore = getStore(ROLES_STORE)
        rolesStore.leaveRolePage()
    }
}
