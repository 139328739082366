import { Vector2d } from '../../../utils/math/geom-util'
import { RectBounds } from '../rect-bounds'
import { ControllerType } from '../hand-controller'
import { InteractiveObject } from '../interactive-object'

export interface EditorState {
    items: InteractiveObject[],
}

export interface Constraint<T = any> {
    active: boolean
    scale: number
    options: T
    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d
    constraintSize(bounds: RectBounds, controller: ControllerType, translation: Vector2d, resize: Vector2d, editorState: EditorState): Vector2d
    filterControllers(controllers: ControllerType[]): ControllerType[]
}

export class BaseConstraint<T> implements Constraint<T> {

    constructor(
        public options: T,
        public scale: number = 1,
        public active: boolean = false,
    ) {
    }

    constraintController(bounds: RectBounds, controller: ControllerType, translation: Vector2d, editorState: EditorState): Vector2d {
        return translation
    }

    constraintSize(bounds: RectBounds, controller: ControllerType, translation: Vector2d, resize: Vector2d, editorState: EditorState): Vector2d {
        return resize
    }

    filterControllers(controllers: ControllerType[]): ControllerType[] {
        return controllers
    }
}
