import { t } from 'i18next'
import { action, observable, toJS, computed } from 'mobx'
import { isNil } from 'lodash'
import { DIALOG } from '../../../components/simple-dialog/simple-dialog'
import { cashiersManagerLocal } from '../../../protocol/set10/cashiers-manager-local'
import { RoleVO, createRoleVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/role-vo'
import { createUserVO, UserVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/user-vo'
import { AsyncFormValidation, AsyncValidationField } from '../../../utils/form-validation/async-form-validation'
import { CASHIERS, STAFF } from '../../core/app-routes'
import { NEW } from '../../core/values'
import { goTo, RouteChangeHandler } from '../../utils/router-util'
import { AppBarStore, LEFT_ARROW } from '../app-bar-store'
import { AppStore } from '../app-store'
import { DialogStore } from '../dialog-store'
import { SnackbarStore } from '../snackbar-store'
import {
    APP_BAR_STORE,
    APP_STORE,
    CASHIER_SETTINGS_STORE,
    DIALOG_STORE,
    SNACKBAR_STORE,
    USER_STORE
} from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { SETRETAILX_CASHIERS_VISIBILITY } from '../../core/privileges/privileges'
import { fieldLengthValidator } from '../../../utils/form-validation/validators/length-validator'
import { requiredField } from '../../../utils/form-validation/validators/required-field'
import { AxiosResponse } from 'axios'
import { salesManagementPropertiesService } from '../../../protocol/set10/sales-management-properties-service'
import { SET_CASHIERS } from '../../core/app-modules'
import { Gender, MALE, FEMALE, UNDEFINED } from '../../../protocol/set10/set-retail10-commons/data-structs-module/gender'
import { INTERNAL_ERROR } from '../../core/server-codes'

export const MAX_TEXT_LENGTH: number = 30
export const MAX_TAB_LENGTH: number = 15
export const MAX_LENGTH: number = 255

export const ACTIVE_CASHIERS_TAB_ID: number = 0
export const BLOCKED_CASHIERS_TAB_ID: number = 1
export const ALL_ROLES_VALUE: number = -1
export const NO_ROLE_VALUE: number = -2

export type NullableGender = Gender | 'null'
export const NULL_GENDER: NullableGender = 'null'

export const nullableGenderToGender = (gender: NullableGender): Gender => {
    return gender === NULL_GENDER ? null : gender as Gender
}

export class CashierSettingsStore {
    @observable
    roles: RoleVO[] = []

    @observable
    rolesOptions: Array<{ label: string, value: number }> = []

    @observable
    cashier: UserVO

    @observable
    originalPassword: string

    @observable
    showPassword: boolean = false

    @observable
    formValidation: AsyncFormValidation<UserVO>

    @observable
    generateMode: boolean = null

    private userStore: UserStore = getStore(USER_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)

    /*
    При наличии у пользователя привилегии "Администрирование кассиров только по своему магазину"
    обязательно необходимо привязать пользователя к магазину. Только на Centrum и только для нового создаваемого кассира.
     */
    @computed
    get haveCashierShopBindingError(): boolean {
        return this.appStore.isCentrum
            && this.userStore.havePrivilege(SETRETAILX_CASHIERS_VISIBILITY)
            && (this.cashier && isNil(this.cashier.shop) && this.cashier.id === -1)
    }

    @computed
    get selectedGender(): NullableGender {
        const gender = this.cashier ? this.cashier.gender : NULL_GENDER
        return gender || NULL_GENDER
    }

    get noRole(): RoleVO {
        return createRoleVO({
            name: t('staff.cashiers.noRole'),
            id: NO_ROLE_VALUE
        })
    }

    get genderOptions(): Array<{label: string, value: NullableGender}> {
        return [
            { label: t('staff.cashiers.notSelectedGender'), value: NULL_GENDER },
            { label: t('staff.cashiers.undefinedGender'), value: UNDEFINED },
            { label: t('staff.cashiers.male'), value: MALE },
            { label: t('staff.cashiers.female'), value: FEMALE }
        ]
    }

    @action
    fillRolesOptions = (roles: RoleVO[]): void => {
        this.roles = [
            this.noRole,
            ...roles
        ]

        this.rolesOptions =
            this.roles.map(role => (
                {
                    label: role.name,
                    value: role.id,
                }
            ))
    }

    @action
    createFormValidation = (creation: boolean = false) => {
        this.formValidation = new AsyncFormValidation<UserVO>(
            {
                firstName: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.firstName,
                        required: true,
                        asyncCheckCallback: (value: string) => {
                            const requiredValidation = requiredField(value)
                            const lengthValidation = fieldLengthValidator({ max: MAX_TEXT_LENGTH })(value)

                            return Promise.resolve(requiredValidation.error || lengthValidation.error || '')
                        },
                        onValueChange: firstName => this.updateCashier({ firstName })
                    }
                ),
                middleName: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.middleName,
                        asyncCheckCallback: (value: string) => {
                            const validation = fieldLengthValidator({ max: MAX_TEXT_LENGTH })(value)
                            return Promise.resolve(validation.error || '')
                        },
                        onValueChange: middleName => this.updateCashier({ middleName })
                    }
                ),
                lastName: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.lastName,
                        required: true,
                        asyncCheckCallback: (value: string) => {
                            const requiredValidation = requiredField(value)
                            const lengthValidation = fieldLengthValidator({ max: MAX_TEXT_LENGTH })(value)

                            return Promise.resolve(requiredValidation.error || lengthValidation.error || '')
                        },
                        onValueChange: lastName => this.updateCashier({ lastName })
                    }
                ),
                shop: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.shop,
                        onValueChange: shop => this.updateCashier({ shop })
                    }
                ),
                role: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.role || this.noRole,
                        required: true,
                        onValueChange: role => this.updateCashier({ role })
                    }
                ),
                gender: new AsyncValidationField(
                    {
                        defaultValue: nullableGenderToGender(this.selectedGender),
                        required: false,
                        onValueChange: gender => this.updateCashier({ gender: nullableGenderToGender(gender) })
                    }
                ),
                tabNumber: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.tabNumber,
                        asyncCheckCallback: async (value: string) => {
                            const requiredValidation = requiredField(value)
                            if (requiredValidation.error) return requiredValidation.error

                            const lengthValidation = fieldLengthValidator({ max: MAX_TAB_LENGTH })(value)
                            if (lengthValidation.error) return lengthValidation.error

                            const correct = await cashiersManagerLocal.checkTabNumber(
                                this.userStore.session,
                                value.trim() || null,
                                this.cashier.id,
                                this.cashier.shop
                            )
                            return correct ? '' : t('staff.cashiers.tabValidationError')
                        },
                        required: true,
                        onValueChange: tabNumber => this.updateCashier({ tabNumber: tabNumber || null })
                    }
                ),
                passwordUnique: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.passwordUnique,
                        asyncCheckCallback: async (value: string) => {
                            const validation = fieldLengthValidator({ max: MAX_LENGTH })(value)
                            if (validation.error) return validation.error

                            const correct = await cashiersManagerLocal.checkPassword(
                                this.userStore.session,
                                value,
                                this.cashier.id,
                                this.cashier.shop
                            )
                            return correct ? '' : t('staff.cashiers.passwordValidationError')
                        },
                        required: creation,
                        onValueChange: passwordUnique => this.updateCashier({ passwordUnique })
                    }
                ),
                set10Card: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.set10Card,
                        asyncCheckCallback: async (value: string) => {
                            const validation = fieldLengthValidator({ max: MAX_LENGTH })(value)
                            if (validation.error) return validation.error

                            const correct = await cashiersManagerLocal.checkSet10Card(
                                this.userStore.session,
                                value || null,
                                this.cashier.id,
                                this.cashier.shop
                            )
                            return correct ? '' : t('staff.cashiers.set10CardValidationError')
                        },
                        onValueChange: set10Card => this.updateCashier({
                            set10Card: set10Card || null
                        })
                    }
                ),
                magneticCard: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.magneticCard,
                        asyncCheckCallback: async (value: string) => {
                            const validation = fieldLengthValidator({ max: MAX_LENGTH })(value)
                            if (validation.error) return validation.error

                            const correct = await cashiersManagerLocal.checkMagneticCard(
                                this.userStore.session,
                                value || null,
                                this.cashier.id,
                                this.cashier.shop
                            )
                            return correct ? '' : t('staff.cashiers.magneticCardValidationError')
                        },
                        onValueChange: magneticCard => this.updateCashier({
                            magneticCard: magneticCard || null
                        })
                    }
                ),
                magneticKey: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.magneticKey,
                        asyncCheckCallback: async (value: string) => {
                            const validation = fieldLengthValidator({ max: MAX_LENGTH })(value)
                            if (validation.error) return validation.error

                            const correct = await cashiersManagerLocal.checkMagneticKey(
                                this.userStore.session,
                                value || null,
                                this.cashier.id,
                                this.cashier.shop
                            )
                            return correct ? '' : t('staff.cashiers.magneticKeyValidationError')
                        },
                        onValueChange: magneticKey => this.updateCashier({
                            magneticKey: magneticKey || null
                        })
                    }
                ),
                barcode: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.barcode,
                        asyncCheckCallback: async (value: string) => {
                            const validation = fieldLengthValidator({ max: MAX_LENGTH })(value)
                            if (validation.error) return validation.error

                            const correct = await cashiersManagerLocal.checkBarCode(
                                this.userStore.session,
                                value || null,
                                this.cashier.id,
                                this.cashier.shop
                            )
                            return correct ? '' : t('staff.cashiers.barcodeValidationError')
                        },
                        onValueChange: barcode => this.updateCashier({
                            barcode: barcode || null
                        })
                    }
                ),
                jobTitle: new AsyncValidationField(
                    {
                        defaultValue: this.cashier.jobTitle,
                        asyncCheckCallback: (value: string) => {
                            const lengthValidation = fieldLengthValidator({ max: MAX_TEXT_LENGTH })(value)

                            return Promise.resolve(lengthValidation.error || '')
                        },
                        onValueChange: jobTitle => this.updateCashier({ jobTitle })
                    }
                ),
            }
        )
    }

    @action
    setExistingCashier = (cashier: UserVO) => {
        this.cashier = cashier
        this.originalPassword = cashier.passwordUnique
        // В этом поле при получении с сервера хранится хеш пароля
        // А при отправке на сервер - сам новый пароль
        // Обнуляем поле, чтобы не показывать пользователю хеш старого пароля
        this.cashier.passwordUnique = ''
        // Нужно всегда передавать undefined, его задаёт сервер
        this.cashier.tabNum = undefined
    }

    @action
    setEmptyCashier = (): void => {
        this.cashier = createUserVO(
            {
                id: -1,
                deleted: false,
                tabNumber: null,
                firstName: '',
                middleName: '',
                lastName: '',
                blocked: false,
                barcode: null,
                magneticCard: null,
                set10Card: null,
                magneticKey: null,
                role: this.roles && this.roles[0] || null,
                shop: null,
                // Нужно всегда передавать undefined, его задаёт сервер
                tabNum: undefined,
                passwordUnique: null,
                gender: null,
                jobTitle: null,
            }
        )
    }

    @action
    updateCashier = (changes: Partial<UserVO>) => {
        Object.keys(changes).forEach(key => {
            this.cashier[key] = changes[key]
        })
    }

    @action
    cancelCashierChanges = (): void => {
        const keys: string[] = Object.keys(this.formValidation.originalFormValues)

        keys.forEach(key => {
            this.cashier[key] = toJS(this.formValidation.originalFormValues[key])
        })

        this.createFormValidation(this.cashier.id === -1)
        this.goToCashiersSearchPage()
    }

    @action
    saveCashier = async (): Promise<void> => {
        if (!this.cashier) return Promise.resolve()

        // Восстанавливаем хеш пароля из originalPassword
        this.cashier.passwordUnique = this.cashier.passwordUnique || this.originalPassword

        const { lastName, middleName, firstName, tabNumber, jobTitle } = this.cashier

        // Убираем пробелы спереди и сзади
        this.cashier.lastName = lastName ? lastName.trim() : lastName
        this.cashier.middleName = middleName ? middleName.trim() : middleName
        this.cashier.firstName = firstName ? firstName.trim() : firstName
        this.cashier.tabNumber = tabNumber ? tabNumber.trim() : tabNumber
        this.cashier.jobTitle = jobTitle ? jobTitle.trim() : jobTitle

        if (this.cashier.role && this.cashier.role.id === NO_ROLE_VALUE) {
            this.cashier.role = null
        }

        const shouldRedirect: boolean = this.cashier.id === -1

        try {
            const cashier: UserVO = await withSpinner(
                cashiersManagerLocal.addCashier(this.userStore.session, toJS(this.cashier))
            )
            this.setExistingCashier(cashier)
            this.createFormValidation()

            if (shouldRedirect) goTo(`${STAFF}${CASHIERS}/${this.cashier.id}`)

            this.snackbarStore.show({ message: t('staff.cashiers.saveSuccess') })
        } catch (error) {
            this.appStore.showDialog({
                title: t('staff.cashiers.saveError'),
                message: error.message,
            })
        }
    }

    goToCashiersSearchPage = (): void => {
        goTo(`${STAFF}${CASHIERS}`)
    }

    @action
    toggleShowPassword = (): void => {
        this.showPassword = !this.showPassword
    }

    @action
    generatePasswordAndBarcode = async (): Promise<void> => {
        const { barcode, passwordUnique } = await cashiersManagerLocal.generatePasswordAndBarcode(this.cashier.tabNumber, {
            customCommonResponseMiddlewares: [
                this.internalServerErrorHandler
            ]
        })
        this.formValidation.form.barcode.updateValue(barcode)
        this.formValidation.form.passwordUnique.updateValue(passwordUnique)
    }

    @action
    initServerSettings = async (): Promise<void> => {
        const properties = await salesManagementPropertiesService.getProperties1(SET_CASHIERS) || {}
        this.generateMode = properties['cashiers.barcode.generate'] === 'true'
    }

    internalServerErrorHandler = (response: AxiosResponse): void => {
        let error: { code?: number, message?: string } = response.data.error

        if (error && error.code === INTERNAL_ERROR) {
            let message: string = response.data.error.message
            if (!message) message = ''

            if (message.indexOf('UserVO.tabNum: is empty') > -1) {
                message = t('staff.cashiers.generateErrorTubNumber')
            } else if (message.indexOf('value element [null]') > -1) {
                message = t('staff.cashiers.generateErrorNullTubNumber')
            } else if (message.indexOf('IndexOutOfBoundsException') > -1) {
                message = t('staff.cashiers.generateErrorNotExistTubNumber')
            }

            this.appStore.showErrorDialog(message)
        }
    }

    @action
    resetCashier = (): void => {
        this.cashier = undefined
        this.formValidation = undefined
        this.originalPassword = undefined
    }

    @action
    reset = (): void => {
        this.roles = []
        this.rolesOptions = []
        this.resetCashier()
        this.showPassword = false
    }
}

/**
 * Обработчик для страниц редактирования и добавления учетной записи кассира
 */
export const CASHIER_SETTINGS_ROUTING_HANDLER: RouteChangeHandler = {
    routeMatcher: new RegExp(`^${STAFF}${CASHIERS}/([\\w-]+)/?$`),
    onEnter: (newRoute: string) => {
        const cashierSettingsStore: CashierSettingsStore = getStore(CASHIER_SETTINGS_STORE)
        const appBarStore: AppBarStore = getStore(APP_BAR_STORE)
        const dialogStore: DialogStore = getStore(DIALOG_STORE)

        const matchNewRoute = newRoute.match(CASHIER_SETTINGS_ROUTING_HANDLER.routeMatcher)
        const cashierId = matchNewRoute[1]

        appBarStore.updateState({
            title: cashierId === NEW ? `${t(`staff.cashiers.cashierCreation`)}` : `${t(`staff.cashiers.cashierEdition`)}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => {
                if (cashierSettingsStore.formValidation.modified) {
                    dialogStore.showDialog({
                        title: t('staff.cashiers.saveChangesTitle'),
                        message: t('staff.cashiers.saveChangesMessage'),
                        mode: DIALOG,
                        onYes: cashierSettingsStore.goToCashiersSearchPage,
                        yesLabel: t('common.exit'),
                        noLabel: t('common.cancel')
                    })
                } else {
                    cashierSettingsStore.goToCashiersSearchPage()
                }
            }
        })
    }
}
