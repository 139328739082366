import { t } from 'i18next'
import { get, uniqBy } from 'lodash'
import {CityVO, createCityVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/city-vo'
import { CITY, EnTopologyLevel, REGION, SHOP } from '../../../protocol/set10/set-retail10-commons/data-structs-module/en-topology-level'
import {createFormatVO, FormatVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/format-vo'
import {createRegionVO, RegionVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/region-vo'
import {createShopVO, ShopVO} from '../../../protocol/set10/set-retail10-commons/data-structs-module/shop-vo'
import {
    createTopologyAdressVO,
    TopologyAdressVO
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-adress-vo'
import { TopologyFilterVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-filter-vo'
import { TopologyNodeVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/topology-node-vo'
import { TopologyNode } from '../../components/topology-filter/topology-filter'
import { TopologyMap } from '../../components/topology-filter/topology-map'
import { ShopsCityGroup } from '../../../protocol/set10/set-retail10-commons/data-structs-module/shops-city-group'
import { ShopsRegionGroup, createShopsRegionGroup } from '../../../protocol/set10/set-retail10-commons/data-structs-module/shops-region-group'
import { TopologyConditions } from '../advertising-actions/action-conditions'

export const isSameNodes = (node1: TopologyNodeVO, node2: TopologyNodeVO): boolean => {
    if (!node1 || !node2) return false
    if (node1.id === node2.id
        && getNodeLevel(node1) === getNodeLevel(node2)) {
        return true
    }
    return false
}

/**
 * Получить топологический адрес заданного узла
 * @param {TopologyNode} node Узел топологии
 * @return {TopologyAdressVO} Топологический адрес узла
 */
export const getNodeAddress = (node: TopologyNodeVO): TopologyAdressVO => {
    if (!node) {
        return null
    }
    let nodeId = node.id
    let nodeLevel = getNodeLevel(node)
    return createTopologyAdressVO({nodeId, nodeLevel})
}

export const getNodeLevel = (node: TopologyNodeVO): EnTopologyLevel => {
    if (!node) throw Error('Topology node not specified')

    if (isShop(node)) return SHOP
    if (isCity(node)) return CITY
    if (isRegion(node)) return REGION

    throw Error(`Can not recognize topology level of ${node}`)
}

export const isShop = (node: TopologyNodeVO): node is ShopVO => {
    return (node as ShopVO).twentyFourHour !== undefined
        && (node as ShopVO).virtual !== undefined
}

export const isCity = (node: TopologyNodeVO): node is CityVO => {
    return !isShop(node) && (node as CityVO).region !== undefined
}

export const isRegion = (node: TopologyNodeVO): node is RegionVO => {
    return !isShop(node) && !isCity(node)
        && (node as RegionVO).name !== undefined
        && (node as RegionVO).shopsCount !== undefined
}

export const isSameFormats = (format1: FormatVO, format2: FormatVO): boolean => {
    if (!format1 || !format2) return false
    return format1.id === format2.id
}

export const isShopInsideFormats = (shop: ShopVO, formats: FormatVO[] = null): boolean => {
    if (!formats) return false

    for (let format of formats) {
        if (isSameFormats(shop.format, format)) {
            return true
        }
    }
    return false
}

export const getTopologyNodeName = (node: TopologyNodeVO): string => {
    if (isRegion(node)) {
        return node.name || t('topology.regionNameNotSpecified')
    }
    else if (isCity(node)) {
        return node.name || t('topology.cityNameNotSpecified')
    }
    else if (isShop(node)) {
        let text: string = node.name || t('topology.shopNameNotSpecified')
        if (node.twentyFourHour) {
            text += `(${t('shop.fullTimeShop')})`
        }
        return text
    }
    return ''
}

export const getFormatNodeName = (format: FormatVO): string => {
    return format.name || t('topology.formatNameNotSpecified')
}

export const getSameNodeInList = (node: TopologyNodeVO, list: TopologyNodeVO[]): TopologyNodeVO => {
    if (!node || !list) return null
    for (let nodeToCheck of list) {
        if (isSameNodes(node, nodeToCheck)) {
            return nodeToCheck
        }
    }
    return null
}

export const getHighestTopologyLevel = (nodes: TopologyNodeVO[]): EnTopologyLevel => {
    let levels: EnTopologyLevel[] = [REGION, CITY, SHOP]
    let result: EnTopologyLevel = REGION
    nodes.forEach(node => {
        let nodeLevel: EnTopologyLevel = getNodeLevel(node)
        if (levels.indexOf(result) < levels.indexOf(nodeLevel)) {
            result = nodeLevel
        }
    })
    return result
}

export const getNodesWithLevel = (nodes: TopologyNodeVO[], level: EnTopologyLevel): TopologyNodeVO[] => {
    return nodes.filter(node => getNodeLevel(node) === level)
}

export const createEmptyRegion = (): RegionVO => {
    return createRegionVO({
        name: '',
        shopsCount: 0
    })
}

export const createEmptyCity = (): CityVO => {
    return createCityVO({
        name: '',
        timeZone: '0',
        region: createEmptyRegion(),
        shopsCount: 0
    })
}

export const createEmptyFormat = (): FormatVO => {
    return createFormatVO({
        name: '',
    })
}

export const createEmptyShop = (): ShopVO => {
    return createShopVO({
        number: 0,
        name: '',
        twentyFourHour: false,
        city: createEmptyCity(),
        format: createEmptyFormat(),
        juristicPersons: [],
        physicalAdress: null,
        centrumUrl: '',
        address: '',
        virtual: false,
    })
}

export const getMinimalUnusedShopNumber = (shops: ShopVO[]): number => {
    let usedShopNumbers: boolean[] = []
    shops.forEach(shop => usedShopNumbers[shop.number] = true)
    let i
    for (i = 1; i < usedShopNumbers.length; i++) {
        if (!usedShopNumbers[i]) {
            return i
        }
    }
    return i
}

/**
 * Находит все магазины в TopologyHierarchy используя фильтр по TopologyMap
 */
export const getShopsInHierarchy = (filter: TopologyNode,
                                    map: TopologyMap): ShopVO[] => {
    if (!filter || !map) return []

    let result: ShopVO[] = []

    for (let shop of map.shops) {
        if (filter.only24h && !shop.twentyFourHour) continue

        if (!isShopInsideFormats(shop, filter.formats)) continue

        if (isShop(filter.node) && filter.node.id === shop.id) {
            result.push(shop)
        }

        if (isCity(filter.node) && filter.node.id === shop.city.id) {
            result.push(shop)
        }

        if (isRegion(filter.node) && filter.node.id === shop.city.region.id) {
            result.push(shop)
        }
    }

    return result
}

export const createTopologyHierarchyForCityVO = (city: CityVO, formats: FormatVO[],
                                                 twentyFourHour: boolean = false): TopologyNode => {
    let regionParent = new TopologyNode(city.region, formats)
    let result = new TopologyNode(city, formats, regionParent, twentyFourHour)
    return result
}

/**
 * Находит все конфликты у добавляемого фильтра топологии и уже существующего.
 * Конфликты сортируются по глубине уровня топологии от региона к магазину.
 */
export const haveConflictsForAddingHierarchyWithFilters = (node: TopologyNode,
                                                           filters: TopologyFilterVO[],
                                                           map: TopologyMap): TopologyNode[] => {
    let result: TopologyNode[] = []
    let nodeShops = getShopsInHierarchy(node, map)

    for (let filter of filters) {
        if (filter.level === REGION) {
            // Проверяем конфликты по регионам
            let region: RegionVO = map.getNode(REGION, filter.id) as RegionVO
            let shops: ShopVO[] = map.getShopsInRegion(region, filter.formats, filter.onlyTwentyFourHours)

            let conflictFound: boolean = false

            for (let conditionShop of shops) {
                for (let nodeShop of nodeShops) {
                    if (nodeShop.id === conditionShop.id) {
                        result.push(new TopologyNode(region, filter.formats, null, filter.onlyTwentyFourHours))
                        conflictFound = true
                        break
                    }
                }

                if (conflictFound) break
            }
        }

        if (filter.level === CITY) {
            // Проверяем конфликты по городам
            let city: CityVO = map.getNode(CITY, filter.id) as CityVO
            let shops: ShopVO[] = map.getShopsInCity(city, filter.formats, filter.onlyTwentyFourHours)

            let conflictFound: boolean = false

            for (let conditionShop of shops) {
                for (let nodeShop of nodeShops) {
                    if (nodeShop.id === conditionShop.id) {
                        result.push(createTopologyHierarchyForCityVO(city, filter.formats,
                            filter.onlyTwentyFourHours))
                        conflictFound = true
                        break
                    }
                }

                if (conflictFound) break
            }
        }

        if (filter.level === SHOP) {
            // Проверяем конфликты по магазинам
            let shop: ShopVO = map.getNode(SHOP, filter.id) as ShopVO

            for (let nodeShop of nodeShops) {
                if (nodeShop.id === shop.id) {
                    result.push(new TopologyNode(
                        nodeShop,
                       [nodeShop.format],
                        createTopologyHierarchyForCityVO(shop.city, [nodeShop.format], nodeShop.twentyFourHour),
                        nodeShop.twentyFourHour
                    ))
                    break
                }
            }
        }
    }

    return result
}

export const squashShopsIntoCities = ({
    shops,
    cities,
    topologyMap,
}: {
    shops: ShopVO[],
    cities: ShopsCityGroup[],
    topologyMap: TopologyMap,
}): { shops: ShopVO[], cityGroups: ShopsCityGroup[] } => {
    let _selectedShops: ShopVO[] = [...shops]
    let _shopsCityGroups: ShopsCityGroup[] = [...cities]

    const selectedShopsByCityId = new Map<number, ShopVO[]>()
    _selectedShops.forEach(shop => {
        const cityId = shop.city.id
        const cityShops = selectedShopsByCityId.get(cityId) || []

        cityShops.push(shop)
        selectedShopsByCityId.set(cityId, cityShops)
    })

    selectedShopsByCityId.forEach((cityShops: ShopVO[], key: number) => {
        const city = cityShops[0].city
        const allShopsInCity = topologyMap.getShopsInCity(city)

        if (cityShops.length === allShopsInCity.length) {
            const shopsCityGroup: ShopsCityGroup = {
                '@class': TopologyConditions.ShopsCityGroups,
                id: null,
                roundTheClock: null,
                cities: [city],
            }
            _shopsCityGroups.push(shopsCityGroup)
            _selectedShops = _selectedShops.filter(shop => city.id !== shop.city.id)
        }
    })

    _shopsCityGroups = uniqBy(
        _shopsCityGroups, (shopsCityGroup: ShopsCityGroup) => get(shopsCityGroup, 'cities[0].id', null)
    )

    return { cityGroups: _shopsCityGroups, shops: _selectedShops }
}

export const squashCitiesIntoRegions = ({
    cities,
    regions,
    topologyMap,
}: {
    cities: ShopsCityGroup[],
    regions: ShopsRegionGroup[],
    topologyMap: TopologyMap,
}): { cityGroups: ShopsCityGroup[], regions: ShopsRegionGroup[] } => {
    let _cityGroups: ShopsCityGroup[] = [...cities]
    let _regions: ShopsRegionGroup[] = [...regions]

    const selectedCitiesByRegionId = new Map<number, ShopsCityGroup[]>()
    _cityGroups.forEach(cityGroup => {
        const regionId = cityGroup.cities[0].region.id
        const regions = selectedCitiesByRegionId.get(regionId) || []

        regions.push(cityGroup)
        selectedCitiesByRegionId.set(regionId, regions)
    })

    selectedCitiesByRegionId.forEach((regionCities: ShopsCityGroup[], key: number) => {
        const region = regionCities[0].cities[0].region
        const allCitiesInRegion = topologyMap.getCitiesInRegion(region)

        if (regionCities.length === allCitiesInRegion.filter(city => city.shopsCount > 0).length) {
            const regionGroup: ShopsRegionGroup = createShopsRegionGroup({
                id: null,
                roundTheClock: null,
                regions: [region],
            })
            _regions.push(regionGroup)
            _cityGroups = _cityGroups.filter(city => region.id !== city.cities[0].region.id)
        }
    })

    _regions = uniqBy(
        _regions, (regionGroup: ShopsRegionGroup) => get(regionGroup, 'regions[0].id', null)
    )

    return { cityGroups: _cityGroups, regions: _regions }
}
