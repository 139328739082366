export const ROUTING_STORE = 'routing'
export const APP_STORE = 'app'
export const APP_BAR_STORE = 'appBar'
export const USER_STORE = 'user'
export const NAVIGATION_MENU_STORE = 'navigationMenu'
export const LOGIN_STORE = 'login'
export const CHECKS_SEARCH_STORE = 'checksSearch'
export const NON_FISCAL_DOCUMENTS_LIST_STORE = 'nonFiscalDocumentsList'
export const NON_FISCAL_DOCUMENT_STORE = 'nonFiscalDocument'
export const CHECK_PAGE_STORE = 'checkPage'
export const CHECK_REFUND_PAGE_STORE = 'checkRefund'
export const OPERDAY_STORE = 'operdayStore'
export const FAVORITE_FILTERS_STORE = 'favoriteFiltersStore'
export const ROLES_STORE = 'rolesStore'
export const USERS_STORE = 'usersStore'
export const USER_SETTINGS_STORE = 'userSettingsStore'
export const DEVICES_STORE = 'devices'
export const REGIONS_STORE = 'regionsStore'
export const REGION_STORE = 'regionStore'
export const CITY_STORE = 'cityStore'
export const SHOP_STORE = 'shopStore'
export const SHOP_CASHES_STORE = 'shopCashesStore'
export const SHOP_PRICE_CHECKERS_STORE = 'shopPriceCheckersStore'
export const SHOP_PRINTERS_STORE = 'shopPrintersStore'
export const SHOP_SCALES_STORE = 'shopScalesStore'
export const SNACKBAR_STORE = 'snackbar'
export const DIALOG_STORE = 'dialogStore'
export const SHOPS_SEARCH_STORE = 'shopsSearchStore'
export const KM_3_REPORT_STORE = 'reportKm3Store'
export const KM_6_REPORT_STORE = 'reportKm6Store'
export const UPD_REGISTRY_STORE = 'updRegistryStore'
export const OPERDAY_SETTINGS_STORE = 'operdaySettingsStore'
export const OPERDAY_VALIDATION_STORE = 'operdayValidationStore'
export const TRANSACTIONS_SEARCH_STORE = 'transactionsSearchStore'
export const NOTIFICATION_STORE = 'notificationStore'
export const PERSONNEL_MOTIVATION_STORE = 'personnelMotivationStore'
export const CASH_SALES_REPORT_STORE = 'cashSalesReportStore'
export const VENDOR_PAGE_STORE = 'vendorPage'
export const CASHIER_SETTINGS_STORE = 'cashierSettingsStore'
export const CASHIERS_SEARCH_STORE = 'cashiersSearchStore'
export const CASHIERS_ROLES_STORE = 'cashiersRolesStore'
export const PRODUCT_SHELVES_STORE = 'productShelvesStore'
export const LICENSES_STORE = 'licensesStore'
export const SHOP_DEVICES_STORE = 'shopDevicesStore'
export const PRICE_TAG_SETTINGS_STORE = 'priceTagSettingsStore'
export const PRICE_TAGS_TEMPLATES_STORE = 'priceTagsTemplatesStore'
export const PRICE_TAGS_MODULE_SETTINGS_STORE = 'priceTagsModuleSettingsStore'
export const PRICE_TAG_FORMATS_TOPOLOGIES_STORE = 'priceTagFormatsTopologiesStore'
export const PRICE_TAGS_ESL_CONFIGURATION_STORE = 'priceTagsEslConfigurationStore'
export const PRICE_TAGS_ESL_COVERAGE_STORE = 'priceTagsEslCoverageStore'
export const PRICE_TAG_TEMPLATE_BINDING_STORE = 'priceTagTemplatesBindingStore'
export const PRICE_TAG_FORMAT_SETTINGS_STORE = 'priceTagFormatSettingsStore'
export const PRICE_TAG_FORMAT_MATRICES_STORE = 'priceTagFormatMatricesStore'
export const NOT_BINDED_TO_PRICE_TAGS_PRODUCTS_STORE = 'notBindedToPriceTagsProductsStore'
export const PRICE_TAGS_PRODUCTS_LISTS_STORE = 'priceTagsProductsListsStore'
export const PRICE_TAG_FORMAT_SIZE_LABELS_STORE = 'priceTagFormatSizeLabelsStore'
export const PRICE_TAGS_PRINTING_STORE = 'priceTagsPrintingStore'
export const PRICE_TAGS_PRINTING_PREVIEW_STORE = 'priceTagsPrintingPreviewStore'
export const PRINT_BY_BINDED_PRICE_TAGS_SELECTION_STORE = 'printByBindedPriceTagsSelectionStore'
export const PRINT_BY_PRICE_TAGS_SELECTION_STORE = 'printByPriceTagsSelectionStore'
export const PRINT_BY_FORMATS_SELECTION_STORE = 'printByFormatsSelectionStore'
export const NOT_PRINTED_ACTION_PRICE_TAGS_STORE = 'notPrintedActionPriceTagsStore'
export const NOT_PRINTED_REGULAR_PRICE_TAGS_STORE = 'notPrintedRegularPriceTagsStore'
export const NOT_ACTUAL_ACTION_PRICE_TAGS_STORE = 'notActualActionPriceTagsStore'
export const NOT_ACTUAL_REGULAR_PRICE_TAGS_STORE = 'notActualRegularPriceTagsStore'
export const CUSTOM_PRINTING_REGULAR_PRICE_TAGS_STORE = 'customPrintingRegularPriceTagsStore'
export const CUSTOM_PRINTING_ACTION_PRICE_TAGS_STORE = 'customPrintingActionPriceTagsStore'
export const CUSTOM_PRINTING_BASKET_STORE = 'customPrintingBasketStore'
export const CUSTOM_PRINTING_TSD_STORE = 'customPrintingTsdStore'
export const PRICE_TAGS_EDITOR_STORE = 'priceTagsEditorStore'
export const ESL_UPLOAD_ERRORS_STORE = 'eslUploadErrorsStore'
export const DIRECTIVE_PRINTING_TOPOLOGIES_STORE = 'topologies'
export const DIRECTIVE_PRINTING_TOPOLOGY_SETTINGS_STORE = 'topologySettings'
export const DIRECTIVE_PRINTING_SCHEDULES_STORE = 'printingSchedules'
export const PRINTING_HISTORY_STORE = 'printingHistoryStore'
export const CASH_TEMPLATES_STORE = 'cashTemplatesStore'
export const CASH_DEVICES_STORE = 'cashDevicesStore'
export const CASH_DEVICE_SETTINGS_STORE = 'cashDeviceSettingsStore'
export const PAYMENT_TYPES_STORE = 'paymentTypesStore'
export const PAYMENT_TYPE_SETTINGS_STORE = 'paymentTypeSettingsStore'
export const PAYMENT_TYPE_BANKS_STORE = 'paymentTypeBanksStore'
export const BANKS_STORE = 'banksStore'
export const NOT_LOADED_TO_CASHES_STORE = 'notLoadedToCashesStore'
export const PRODUCT_LISTS_STORE = 'productsListsStore'
export const CASH_SCHEDULES_STORE = 'cashSchedulesStore'
export const PRODUCT_DETAILS_STORE = 'productDetailsStore'
export const PRODUCT_RESTRICTIONS_STORE = 'productRestrictionsStore'
export const KO_5_REPORT_STORE = 'ko5ReportStore'
export const PRODUCT_SALES_POLICY_STORE = 'productSalesPolicyStore'
export const PRODUCT_DETAILS_ACTIONS_STORE = 'productDetailsActionsStore'
export const ACTIONS_SEARCH_STORE = 'actionsSearchStore'
export const ACTION_SETTINGS_STORE = 'actionSettingsStore'
export const SALE_GROUP_EDIT_STORE = 'saleGroupEditStore'
export const SEGMENTS_STORE = 'segmentsStore'
export const LOYALTY_COUPONS_STORE = 'loyaltyCouponsStore'
export const LOYALTY_COUPONS_INSTANCES_STORE = 'loyaltyCouponsInstancesStore'
export const CASH_PRINTER_IMAGE_STORE = 'cashPrinterImageStore'
export const PRESENT_CARDS_STORE = 'presentCardsStore'
export const BONUS_ACCOUNTS_STORE = 'bonusAccountsStore'
export const FORM25_REPORT_STORE = 'form25ReportStore'
export const LOYALTY_SETTINGS_STORE = 'loyaltySettingsStore'
export const CARDS_SEARCH_STORE = 'cardsSearchStore'
export const EXTERNAL_CARDS_STORE = 'externalCardsStore'
export const OTHER_DEPARTMENTS_STORE = 'otherDepartmentsStore'
export const PRODUCT_TYPES_STORE = 'productTypesStore'
export const CARDS_CATEGORIES_STORE = 'cardsCategoriesStore'
export const CARDS_CATEGORY_DETAILS_STORE = 'cardsCategoryDetailsStore'
export const INTEGRATION_IMPORT_EXPORT_STORE = 'integrationImportExportStore'
export const INTEGRATION_EXTERNAL_PROCESSINGS_STORE = 'integrationExternalProcessingsStore'
export const CARDS_SETTINGS_STORE = 'cardsSettingsStore'
export const IFRAME_MODULES_STORE = 'iframeModulesStore'
export const CHECK_FORMS_STORE = 'checkFormsStore'
export const SCALE_TEMPLATES_LIST_STORE = 'scaleTemplatesListStore'
export const SCALES_DEVICES_STORE = 'scalesDevicesStore'
export const SCALES_PRODUCT_LISTS_STORE = 'scalesProductListsStore'
export const SCALES_PRODUCT_EDIT_STORE = 'scalesProductEditStore'

export const ALL_STORES = [
    ROUTING_STORE,
    APP_STORE,
    APP_BAR_STORE,
    USER_STORE,
    NAVIGATION_MENU_STORE,
    LOGIN_STORE,
    CHECKS_SEARCH_STORE,
    NON_FISCAL_DOCUMENTS_LIST_STORE,
    NON_FISCAL_DOCUMENT_STORE,
    CHECK_PAGE_STORE,
    CHECK_REFUND_PAGE_STORE,
    OPERDAY_STORE,
    FAVORITE_FILTERS_STORE,
    ROLES_STORE,
    USERS_STORE,
    USER_SETTINGS_STORE,
    DEVICES_STORE,
    REGIONS_STORE,
    REGION_STORE,
    CITY_STORE,
    SHOP_STORE,
    SHOP_CASHES_STORE,
    SHOP_PRICE_CHECKERS_STORE,
    SHOP_PRINTERS_STORE,
    SHOP_SCALES_STORE,
    SNACKBAR_STORE,
    DIALOG_STORE,
    SHOPS_SEARCH_STORE,
    KM_3_REPORT_STORE,
    KM_6_REPORT_STORE,
    UPD_REGISTRY_STORE,
    OPERDAY_SETTINGS_STORE,
    OPERDAY_VALIDATION_STORE,
    TRANSACTIONS_SEARCH_STORE,
    NOTIFICATION_STORE,
    PERSONNEL_MOTIVATION_STORE,
    CASH_SALES_REPORT_STORE,
    VENDOR_PAGE_STORE,
    CASHIER_SETTINGS_STORE,
    CASHIERS_SEARCH_STORE,
    CASHIERS_ROLES_STORE,
    PRODUCT_SHELVES_STORE,
    LICENSES_STORE,
    SHOP_DEVICES_STORE,
    PRICE_TAGS_TEMPLATES_STORE,
    PRICE_TAG_SETTINGS_STORE,
    PRICE_TAGS_MODULE_SETTINGS_STORE,
    PRICE_TAG_FORMATS_TOPOLOGIES_STORE,
    PRICE_TAGS_ESL_CONFIGURATION_STORE,
    PRICE_TAGS_ESL_COVERAGE_STORE,
    PRICE_TAG_TEMPLATE_BINDING_STORE,
    PRICE_TAG_FORMAT_SETTINGS_STORE,
    PRICE_TAG_FORMAT_MATRICES_STORE,
    NOT_BINDED_TO_PRICE_TAGS_PRODUCTS_STORE,
    PRICE_TAGS_PRODUCTS_LISTS_STORE,
    PRICE_TAG_FORMAT_SIZE_LABELS_STORE,
    PRICE_TAGS_PRINTING_STORE,
    PRICE_TAGS_PRINTING_PREVIEW_STORE,
    PRINT_BY_BINDED_PRICE_TAGS_SELECTION_STORE,
    PRINT_BY_PRICE_TAGS_SELECTION_STORE,
    PRINT_BY_FORMATS_SELECTION_STORE,
    NOT_PRINTED_ACTION_PRICE_TAGS_STORE,
    NOT_PRINTED_REGULAR_PRICE_TAGS_STORE,
    NOT_ACTUAL_ACTION_PRICE_TAGS_STORE,
    NOT_ACTUAL_REGULAR_PRICE_TAGS_STORE,
    CUSTOM_PRINTING_BASKET_STORE,
    CUSTOM_PRINTING_REGULAR_PRICE_TAGS_STORE,
    CUSTOM_PRINTING_ACTION_PRICE_TAGS_STORE,
    CUSTOM_PRINTING_TSD_STORE,
    PRICE_TAGS_EDITOR_STORE,
    ESL_UPLOAD_ERRORS_STORE,
    DIRECTIVE_PRINTING_TOPOLOGIES_STORE,
    DIRECTIVE_PRINTING_TOPOLOGY_SETTINGS_STORE,
    DIRECTIVE_PRINTING_SCHEDULES_STORE,
    PRINTING_HISTORY_STORE,
    CASH_TEMPLATES_STORE,
    CASH_DEVICES_STORE,
    CASH_DEVICE_SETTINGS_STORE,
    PAYMENT_TYPES_STORE,
    PAYMENT_TYPE_SETTINGS_STORE,
    PAYMENT_TYPE_BANKS_STORE,
    BANKS_STORE,
    NOT_LOADED_TO_CASHES_STORE,
    PRODUCT_LISTS_STORE,
    CASH_SCHEDULES_STORE,
    PRODUCT_DETAILS_STORE,
    PRODUCT_RESTRICTIONS_STORE,
    KO_5_REPORT_STORE,
    PRODUCT_SALES_POLICY_STORE,
    PRODUCT_DETAILS_ACTIONS_STORE,
    ACTIONS_SEARCH_STORE,
    ACTION_SETTINGS_STORE,
    SALE_GROUP_EDIT_STORE,
    SEGMENTS_STORE,
    LOYALTY_COUPONS_STORE,
    LOYALTY_COUPONS_INSTANCES_STORE,
    CASH_PRINTER_IMAGE_STORE,
    PRESENT_CARDS_STORE,
    BONUS_ACCOUNTS_STORE,
    FORM25_REPORT_STORE,
    LOYALTY_SETTINGS_STORE,
    CARDS_SEARCH_STORE,
    EXTERNAL_CARDS_STORE,
    OTHER_DEPARTMENTS_STORE,
    PRODUCT_TYPES_STORE,
    CARDS_CATEGORIES_STORE,
    CARDS_CATEGORY_DETAILS_STORE,
    INTEGRATION_IMPORT_EXPORT_STORE,
    INTEGRATION_EXTERNAL_PROCESSINGS_STORE,
    CARDS_SETTINGS_STORE,
    IFRAME_MODULES_STORE,
    CHECK_FORMS_STORE,
    SCALE_TEMPLATES_LIST_STORE,
    SCALES_DEVICES_STORE,
    SCALES_PRODUCT_LISTS_STORE,
    SCALES_PRODUCT_EDIT_STORE,
]

// Сторы, данные в которых не зависят от текущего пользователя
export const USER_INDEPENDENT_STORES: string[] = [
    APP_STORE,
    ROUTING_STORE,
    IFRAME_MODULES_STORE,
]
