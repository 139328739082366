import React from 'react'
import {
    AutocompleteInput, AutocompleteInputProps
} from '@crystalservice/crystals-ui/lib/components/inputs/autocomplete-input/autocomplete-input'
import { last, without } from 'lodash'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'

const styles = require('./chip-autocomplete-input.scss')

export interface ChipAutocompleteProps extends Omit<Partial<AutocompleteInputProps<string>>,
    'value' | 'onChange' | 'withPaper'> {
    value: string[]
    onChange: (value: string[]) => void
    fetchFunction: (searchString: string) => Promise<string[]>
    disabled?: boolean
}

export interface ChipAutocompleteState {
    isOpen: boolean
    inputValue: string
}

export class ChipAutocomplete extends React.Component<ChipAutocompleteProps, ChipAutocompleteState> {
    state: ChipAutocompleteState = {
        isOpen: false,
        inputValue: '',
    }

    mounted: boolean = false

    componentDidMount() {
        this.mounted = true
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleAddLabel = (label: string): void => {
        const { value, onChange } = this.props

        if (!value.some(item => item === label)) {
            onChange(value.concat(label))
        }
    }

    render() {
        const { value, onChange, disabled, ref, fetchFunction, ...other } = this.props
        const { isOpen, inputValue } = this.state
        const setIsOpen = isOpen => this.setState({ isOpen })

        return (
            <div className={styles.relative}>
                <AutocompleteInput<string>
                    id="chipAutocomplete"
                    {...other}
                    fullWidth
                    disabled={disabled}
                    fetchDataCallback={input => {
                        const term = last(input.split(',')).trim()
                        return fetchFunction(term)
                    }}
                    onValueChange={inputValue => {
                        this.setState({ inputValue })
                    }}
                    onSuggestionSelect={label => {
                        this.handleAddLabel(label)
                        this.setState({ inputValue: '' })
                    }}
                    onFocus={() => {
                        setIsOpen(true)
                    }}
                    onClose={() => {
                        // 700 мс чтобы успеть нажать на кнопку удаления
                        setTimeout(() => {
                            if (this.mounted) {
                                setIsOpen(false)
                                this.setState({ inputValue: '' })
                            }
                        }, 700)
                    }}
                />
                {!isOpen && value.length > 0 && (
                    <div className={styles.labelsHolder}>
                        {value.map(label => (
                            <Chip
                                color="primary"
                                key={label}
                                label={label}
                                size="small"
                                className={styles.chipLabel}
                                onDelete={disabled ? undefined : () => {
                                    onChange(without(value, label))
                                }}
                            />
                        ))}
                    </div>
                )}
                {isOpen && !inputValue && value.length > 0 && (
                    <Paper className={styles.labelsHolderOpen}>
                        {value.map(label => (
                            <Chip
                                color="primary"
                                key={label}
                                label={label}
                                size="small"
                                className={styles.chipLabel}
                                onDelete={disabled ? undefined : () => {
                                    onChange(without(value, label))
                                }}
                            />
                        ))}
                    </Paper>
                )}
            </div>
        )
    }
}
