import * as React from 'react'
import moment from 'moment'
import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import { PRODUCT_DETAILS_STORE } from '../../../store/stores'
import { ProductDetailsStore } from '../../../store/products/product-details-store'
import { PageLoader } from '../../../../components/loaders/page-loader'
import { fromClientToServerTime } from '../../../utils/app-util'
import { SimpleWholesaleLevel } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/simple-wholesale-level'
import { formatPrice } from '../../../core/currency-utils'
import _ from 'lodash'
import { Table } from '@crystalservice/crystals-ui/lib'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'

export interface WholesaleLevelsProps {
    productDetailsStore?: ProductDetailsStore
}

export interface WholesaleLevelsState {
    levels: ExtendedWholesaleLevel[]
}

export interface ExtendedWholesaleLevel extends SimpleWholesaleLevel {
    number: number
}

@inject(PRODUCT_DETAILS_STORE)
@observer
export default class WholesaleLevels extends React.Component<WholesaleLevelsProps, WholesaleLevelsState> {

    state: WholesaleLevelsState = {
        levels: null,
    }

    mounted: boolean = false

    componentDidMount(): void {
        this.mounted = true
        this.initWholesaleLevels()
    }

    componentWillUnmount(): void {
        this.mounted = false
    }

    initWholesaleLevels = async (): Promise<void> => {
        const { wholesaleLevels } = this.props.productDetailsStore

        if (!this.mounted) return

        this.setState({
            levels: _.sortBy(wholesaleLevels, 'activationLimit[1]').map((level, index) => ({...level, number: index + 1}))
        })
    }

    render() {
        const { levels } = this.state

        if (!levels) return <PageLoader/>
        if (!levels.length) return <Empty/>

        return (
            <Table
                id="wholesaleLevelsList"
                withHeader
                keyField="id"
                items={levels}
                columns={[
                    {
                        header: t('productDetails.wholesaleLevelsEntrance.regularPrice'),
                        labelFunction: level => level.regularPrice ? formatPrice(level.regularPrice / 100) : '–'
                    },
                    {
                        header: t('productDetails.wholesaleLevelsEntrance.levelNumber'),
                        labelField: 'number'
                    },
                    {
                        header: t('productDetails.wholesaleLevelsEntrance.quantityFrom'),
                        labelFunction: level => level.activationLimit && level.activationLimit[1] ?
                            (level.activationLimit[1] / 1000).toString() :
                            '–'
                    },
                    {
                        header: t('productDetails.wholesaleLevelsEntrance.priceValue'),
                        labelFunction: level => level.fixedValue ? formatPrice(level.fixedValue / 100) : '–'
                    },
                    {
                        header: t('productDetails.wholesaleLevelsEntrance.percentValue'),
                        labelFunction: level => level.valueInPercent ? formatPrice(level.valueInPercent / 100) : '–'
                    },
                    {
                        header: t('productDetails.wholesaleLevelsEntrance.expirationDate'),
                        labelFunction: level => {
                            const from = level.dateFrom ?
                                `${t('common.fromStart')} ${moment(fromClientToServerTime(level.dateFrom)).format('DD.MM.YYYY HH:mm:ss')} ` +
                                `${t('common.toEnd')} ` :
                                ''
                            const to = level.dateTo ?
                                `${moment(fromClientToServerTime(level.dateTo)).format('DD.MM.YYYY HH:mm:ss')}` :
                                `${t('productDetails.indefinitely')}`

                            return from + to
                        }
                    },
                ]}
            />
        )
    }
}
