import uuid from 'uuid'
import { t } from 'i18next'
import { memoize } from 'lodash'
import {
    FIRST_NOT_NULL,
    LOGIC,
    MIN_VALUE,
    OLD_PRICE,
    SIMPLE,
    WHOLESALE_LEVEL,
    FormulaType
} from './xml/xml-formula-type'
import { XMLTagFormula } from './xml/xml-tag-formula'
import { getMnemonicLocalizedName } from './price-tags-util'
import { FormulaPrecision } from './xml/xml-formula-precision'
import { XmlFormulaProperty, FormulaPropertyName } from './xml/xml-formula-property'
import {
    RUB, COP, FULL_PRICE, PERCENT, COUNT, WholesaleLevelFormulaReturnType, WHOLESALE_RUB, WHOLESALE_COP, FormulaReturnType, DEFAULT
} from './xml/xml-formula-return-type'
import { DefaultSelectOption } from '@crystalservice/crystals-ui/lib/components/inputs/select-input/select-input'
import { MnemonicPropertiesVO } from '../../../protocol/set10/set-retail10-server/retailx/set-print-price-tags/mnemonic-properties-vo'
import { PLAIN_NUMBER_MNEMONIC } from './xml/xml-formula-specific-mnemonics'

export const FORMULA_START_SYMBOL: string = '#'
export const SIMPLE_FORMULA_VALIDATOR_REGEXP: RegExp = /\s*(#?\w+)\s*(\+|-|%|\/|\*|=|<|>|(?:<>))\s*(#?\w+)\s*(?:[;\s\w=]+)?\s*/
export const SIMPLE_FORMULA_OPERATIONS: string[] = ['+', '-', '/', '*', '%']
export const LOGIC_FORMULA_OPERATIONS: string[] = ['=', '<', '>', '<>']

/**
 * Генерирует uid без тире, т.к. тире используется в простых формулах как знак вычитания
 */
export function getUIDWithoutDashes(): string {
    let result = uuid()
    result = result.replace(/-/g, '')
    return result
}

export function getFormulaTypeName(type: FormulaType): string {
    if (!type) return ''
    return t(`priceTags.formulaTypes.${type}`)
}

/**
 * Возвращает элементы простых формул, состоящих из трех элементов, например:
 * простая - подстановка1 + подстановка2
 * логическая - подстановка1 > подстановка2
 * @param text
 */
export function getFormulaElements(text: string): string[] {
    if (!text) return []

    const result = SIMPLE_FORMULA_VALIDATOR_REGEXP.exec(text)

    if (!result || result.length < 4) return []

    const mnemonic1 = result[1]
    const sign = result[2]
    const mnemonic2 = result[3]

    return [mnemonic1, sign, mnemonic2]
}

/**
 * Возвращает элементы properties в объекте:
 * @param properties
 */
export function getFormulaPropertiesValues(properties: XmlFormulaProperty[]): {
    precision: number
    minValue: number
    maxValue: number
    errorText: string
    precisionType: string
    returnType: string
    plainNumberValues: string[]
} {
    const result = {
        precision: null,
        minValue: null,
        maxValue: null,
        errorText: null,
        precisionType: null,
        returnType: null,
        plainNumberValues: null,
    }
    if (!properties) return result

    properties.forEach(prop => {
        switch (prop.name) {
            default: return
            case FormulaPropertyName.PRECISION:
                result.precision = prop.value
                break
            case FormulaPropertyName.MIN_VALUE:
                result.minValue = prop.value
                break
            case FormulaPropertyName.MAX_VALUE:
                result.maxValue = prop.value
                break
            case FormulaPropertyName.ERROR_TEXT:
                result.errorText = prop.value
                break
            case FormulaPropertyName.PRECISION_TYPE:
                result.precisionType = prop.value
                break
            case FormulaPropertyName.RETURN_TYPE:
                result.returnType = prop.value
                break
            case FormulaPropertyName.PLAIN_NUMBER_VALUES:
                result.plainNumberValues = prop.value ? String(prop.value).split(',') : []
                break
        }
    })

    return result
}

export function getPlainNumberValues(
    selectedMnemonic1: string,
    selectedMnemonic2: string,
    rawPainNumberValues: string[]
): number[] {
    if (rawPainNumberValues?.length > 0) {
        if (rawPainNumberValues.length > 1) {
            return rawPainNumberValues.map(Number)
        } else {
            if (selectedMnemonic1 === PLAIN_NUMBER_MNEMONIC) return [Number(rawPainNumberValues[0]), null]
            else if (selectedMnemonic2 === PLAIN_NUMBER_MNEMONIC) return [null, Number(rawPainNumberValues[0])]
            else return [null, null]
        }
    } else {
        return [null, null]
    }
}

interface FormulaPart {
    value: string,
    isMnemonic?: boolean,
}

/**
 * Возвращает короткое описание формулы для отображения на подстановке в ценнике
 * @param formula
 * @param mnemonics
 */
export function getFormulaDescription(formula: XMLTagFormula, mnemonics: MnemonicPropertiesVO[]): FormulaPart[] {
    if (!formula) {
        return [{
            value: t('priceTags.incorrectFormula')
        }]
    }

    let result: FormulaPart[] = [{
        value: `${t('priceTags.formula')}: `
    }]

    switch (formula.type) {
        case SIMPLE:
        case LOGIC:
            const elements = getFormulaElements(formula.text)

            if (elements.length !== 3) {
                return [{
                    value: t('priceTags.incorrectFormula')
                }]
            }

            const firstElement = elements[0]
            const secondArgument = elements[2]

            const describeArgument = (arg: string): string => {
                if (isLinkToFormula(arg)) {
                    const index = getChildFormulaIndexByLink(arg, formula.children)
                    return t('priceTags.formulaNumber', { number: index > -1 ? index + 1 : '?' })
                } else {
                    return getMnemonicLocalizedName(arg, mnemonics)
                }
            }

            result.push({
                value: describeArgument(firstElement),
                isMnemonic: true
            })
            result.push({
                value: ` ${elements[1]} `,
            })
            result.push({
                value: describeArgument(secondArgument),
                isMnemonic: true
            })

            return result

        case MIN_VALUE:
        case FIRST_NOT_NULL:
        case OLD_PRICE:
            result.push({
                value: getFormulaTypeName(formula.type)
            })
            return result

        case WHOLESALE_LEVEL:
            result.push({
                value: getFormulaTypeName(formula.type)
            })
            if (formula.text) {
                result.push({
                    value: ` ${FORMULA_START_SYMBOL}${formula.text}`
                })
            }

            return result

        default: return [{
            value: t('priceTags.incorrectFormula')
        }]
    }
}

/**
 * Определеяет, является ли мнемоника ссылкой на дочернюю формулу
 * @param mnemonic
 */
export function isLinkToFormula(mnemonic: string): boolean {
    if (!mnemonic) return false
    return mnemonic.startsWith(FORMULA_START_SYMBOL)
}

/**
 * Находит дочернюю формулу в списке по ссылке
 * @param mnemonic
 * @param children
 */
export function getChildFormulaByLink(mnemonic: string, children: XMLTagFormula[]): XMLTagFormula {
    if (!mnemonic || !children) return null
    const uid = mnemonic.substr(FORMULA_START_SYMBOL.length)

    return children.find(childFormula => childFormula.uid === uid)
}

/**
 * Находит индекс дочерней формулы по ссылке
 * @param mnemonic
 * @param children
 */
export function getChildFormulaIndexByLink(mnemonic: string, children: XMLTagFormula[]): number {
    if (!mnemonic || !children) return -1
    const uid = mnemonic.substr(FORMULA_START_SYMBOL.length)

    return children.findIndex(childFormula => childFormula.uid === uid)
}

export function getPrecisionType(precision: FormulaPrecision): string {
    if (!precision) return ''
    return t(`priceTags.formulaPrecision.${precision}`)
}

export const getFormulaTypeOptions = memoize((): Array<DefaultSelectOption<FormulaType>> => (
    [SIMPLE, MIN_VALUE, FIRST_NOT_NULL, OLD_PRICE, WHOLESALE_LEVEL].map(ft => {
        return {
            value: ft,
            label: t(`priceTags.formulaTypes.${ft}`)
        }
    })
))

const TEXT_DIVIDER: string = ','

export function formulaTextToItems(text: string, formulaChildren: XMLTagFormula[], priceMnemonics: MnemonicPropertiesVO[]): DefaultSelectOption[] {
    const valuesFromText: string[] = text ? text.split(TEXT_DIVIDER) : []
    const childrenFormulaOptions = getChildrenFormulaOptions(formulaChildren)

    return valuesFromText
        .map(value => {
            const pm = priceMnemonics.find(pm => pm.name === value)
            if (pm) {
                return {
                    label: pm.localizedName,
                    value: pm.name
                }
            } else {
                const children = childrenFormulaOptions.find(v => v.value === value)
                return children
            }
        })
}

export function itemsToFormulaText(items: DefaultSelectOption[]): string {
    return items ? items.map(i => i.value).join(TEXT_DIVIDER) : ''
}

export function mnemonicNamesToFormulaText(mnemonicNames: string[]): string {
    return mnemonicNames ? mnemonicNames.join(TEXT_DIVIDER) : ''
}

export const getChildrenFormulaOptions = memoize((children: XMLTagFormula[]): DefaultSelectOption[] => {
    if (!children) return []

    return children.map((child, index) => ({
        label: t('priceTags.formulaEditor.formulaNumber', { number: index + 1 }),
        value: FORMULA_START_SYMBOL + child.uid
    }))
})

export const getSignOptions = memoize((): DefaultSelectOption[] => {
    return SIMPLE_FORMULA_OPERATIONS.map(sign => ({
        value: sign,
        label: sign
    }))
})

export const getLogicSignOptions = memoize((): DefaultSelectOption[] => {
    return LOGIC_FORMULA_OPERATIONS.map(sign => ({
        value: sign,
        label: sign
    }))
})

export const getReturnTypeOptions = memoize((formulaType: FormulaType): DefaultSelectOption[] => {
    if (formulaType === WHOLESALE_LEVEL) {
        return [
            WHOLESALE_RUB,
            WHOLESALE_COP,
            FULL_PRICE,
            PERCENT,
            COUNT
        ].map(returnType => ({
            value: returnType,
            label: wholeSaleReturnTypeToLabel(returnType)
        }))
    }

    return [
        DEFAULT,
        RUB,
        COP
    ].map(returnType => ({
        value: returnType,
        label: returnTypeToLabel(returnType)
    }))
})

function wholeSaleReturnTypeToLabel(returnType: WholesaleLevelFormulaReturnType): string {
    switch (returnType) {
        default: return ''
        case WHOLESALE_RUB: return t('priceTags.formulaEditor.rub')
        case WHOLESALE_COP: return t('priceTags.formulaEditor.cop')
        case FULL_PRICE: return t('priceTags.formulaEditor.fullPrice')
        case PERCENT: return t('priceTags.formulaEditor.percent')
        case COUNT: return t('priceTags.formulaEditor.count')
    }
}

function returnTypeToLabel(returnType: FormulaReturnType): string {
    switch (returnType) {
        default: return ''
        case RUB: return t('priceTags.formulaEditor.rub')
        case COP: return t('priceTags.formulaEditor.cop')
        case DEFAULT: return t('priceTags.formulaEditor.default')
    }
}
