import { FixPriceApplyObject } from './fix-price-apply-object'
import { parse, X2jOptionsOptional } from 'fast-xml-parser'
import { ResultApplyObject } from './result-apply-object'
import { SetApplyObject } from './set-apply-object'
import { CompositeApplyObject } from './composite-apply-object'
import { flatten } from 'lodash'

export function deserializeApplyObject(value: string): ResultApplyObject {
    const parserOptions: X2jOptionsOptional = {
        parseTrueNumberOnly: true,
        stopNodes: ['String']
    }

    const jsonObj = parse(value, parserOptions)

    const pluginName: string = Object.keys(jsonObj)[0]
    const props = jsonObj[pluginName]

    switch (pluginName) {
        case 'ru.crystalservice.setv6.discounts.plugins.FixPriceApplyObject':
            createArray(props, 'applyObjects')
            return  new FixPriceApplyObject(props)
        case 'ru.crystalservice.setv6.discounts.plugins.SetApplyObject':
            if (props.rows) {
                createArray(props, 'rows')
            }

            const rowArrayFields = ['markings', 'manufacturers', 'groups', 'departments', 'countries', 'saleGroups']

            if (props.rows) {
                for (let row of props.rows) {
                    for (let field of rowArrayFields) {
                        if (row[field]) {
                            createArray(row, field)
                        }
                    }
                }
            }

            return new SetApplyObject(props)
        case 'ru.crystalservice.setv6.discounts.plugins.CompositeApplyObject':
            const arrayFields = ['item', 'manufacturerId', 'groupId', 'departId', 'countryId', 'typeProductId', 'saleGroupId']

            for (let field of arrayFields) {
                if (props[field]) {
                    createArray(props, field)
                }
            }

            return new CompositeApplyObject(props)
        default:
            return null
    }
}

function createArray(initialData: any, field: string): any {
    initialData[field] = Object.keys(initialData[field]).map(i => initialData[field][i])
    initialData[field] = flatten(initialData[field])
}
