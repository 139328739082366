import { observable, action, runInAction, computed, toJS } from 'mobx'
import { AppStore } from '../app-store'
import { getStore } from '../stores-repository'
import { APP_STORE, USER_STORE } from '../stores'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { cloneDeep, isEqual } from 'lodash'
import { iCardsManagerRemote } from '../../../protocol/set10/i-cards-manager-remote'
import {
    BonusAccountTypeVO, createBonusAccountTypeVO
} from '../../../protocol/set10/set-retail10-commons/set-cards-internal-cards/bonus-account-type-vo'
import { t } from 'i18next'
import { MONEY } from '../../../protocol/set10/set-retail10-commons/set-cards-internal-cards/bonus-accounts-type'

export class BonusAccountsStore {

    @observable
    bonusAccounts: BonusAccountTypeVO[]

    @observable
    filterString: string = ''

    @observable
    bonusAccount: BonusAccountTypeVO

    @observable
    originalBonusAccount: BonusAccountTypeVO

    private appStore: AppStore = getStore(APP_STORE)
    private userStore: UserStore = getStore(USER_STORE)

    get currency(): string {
        return this.appStore.currencySymbol
    }

    get session(): string {
        return this.userStore.session
    }

    @computed
    get filteredBonusAccounts(): BonusAccountTypeVO[] {
        if (!this.filterString || !this.bonusAccounts) return this.bonusAccounts

        const usedFilter = this.filterString.trim().toLocaleLowerCase()
        return this.bonusAccounts.filter(
            item => item.bonusAccountsTypeName?.trim().toLocaleLowerCase().indexOf(usedFilter) > -1
        )
    }

    @computed
    get modified(): boolean {
        return !isEqual(toJS(this.bonusAccount), toJS(this.originalBonusAccount))
    }

    fetchBonusAccounts = async (): Promise<void> => {
        const bonusAccounts: BonusAccountTypeVO[] = await withSpinner(
            iCardsManagerRemote.getAllBonusTypes(this.userStore.session)
        )

        runInAction(() => {
            this.bonusAccounts = bonusAccounts || []
        })
    }

    @action
    updateBonusAccount = (changes: Partial<BonusAccountTypeVO>): void => {
        Object.keys(changes).forEach(key => {
            this.bonusAccount[key] = changes[key]
        })
    }

    @action
    addNewBonusAccount = (): void => {
        this.bonusAccount = createBonusAccountTypeVO({
            bonusAccountTypeId: -1,
            bonusAccountsTypeName: '',
            accountsType: MONEY,
            bonusCourse: 100,
            currencyCourse: 100,
            entirePurchase: true,
            writeOffAllAmountAtOnce: false,
        })
        this.originalBonusAccount = undefined
    }

    @action
    editBonusAccount = async (item: BonusAccountTypeVO): Promise<void> => {
        this.bonusAccount = cloneDeep(item)
        this.originalBonusAccount = cloneDeep(item)
    }

    saveBonusAccount = async (): Promise<void> => {
        if (this.bonusAccount.bonusAccountTypeId === -1) {
            const newBonusAccount = await withSpinner(
                iCardsManagerRemote.createBonusType(this.session, toJS(this.bonusAccount))
            )

            runInAction(() => {
                this.bonusAccounts.push(newBonusAccount)

                this.resetEditedBonusAccount()
                this.appStore.showSnackbar({
                    message: t('bonusAccounts.accountCreated')
                })
            })
        } else {
            const editedBonusAccount = await withSpinner(
                iCardsManagerRemote.storeBonusType(this.session, toJS(this.bonusAccount))
            )

            runInAction(() => {
                const current = this.bonusAccount
                const currentIndex = this.bonusAccounts.findIndex(
                    item => item.bonusAccountTypeCode === current.bonusAccountTypeCode
                        && item.bonusAccountTypeId === current.bonusAccountTypeId
                )

                this.bonusAccounts[currentIndex] = editedBonusAccount

                this.resetEditedBonusAccount()
                this.appStore.showSnackbar({
                    message: t('bonusAccounts.accountSaved')
                })
            })
        }
    }

    @action
    setFilterString = (filter: string): void => {
        this.filterString = filter
    }

    @action
    resetEditedBonusAccount = (): void => {
        this.bonusAccount = undefined
        this.originalBonusAccount = undefined
    }

    @action
    reset = (): void => {
        this.bonusAccounts = undefined
        this.filterString = ''
        this.bonusAccount = undefined
        this.originalBonusAccount = undefined
    }
}
