import { PrivilegeVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/privilege-vo'
import { UserRoleVO } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/user-role-vo'
import { AppModule } from './app-modules'

export class PrivilegeManager {

    private roles: UserRoleVO[] = []

    private privileges: PrivilegeVO[] = []

    private privilegeByName: Map<string, PrivilegeVO> = new Map<string, PrivilegeVO>()

    constructor(userRoles: UserRoleVO[]) {
        if (!userRoles) return

        userRoles.forEach(role => {
            this.roles.push(role)
            role.privilegeVOList.forEach(privilege => {
                this.privileges.push(privilege)
                this.privilegeByName.set(privilege.name, privilege)
            })
        })
    }

    /**
     * Проверяет наличие привилегии
     * @param privilege
     */
    havePrivilege(privilege: string): boolean {
        return this.privilegeByName.has(privilege)
    }

    /**
     * Проверяет наличие хотя бы одной привилегии из списка
     * @param privileges
     */
    haveAnyPrivilegeFromList = (privileges: string[]): boolean => {
        if (!this.privilegeByName || !privileges) return false

        return privileges.some(p => this.privilegeByName.has(p))
    }

    /**
     * Проверяет наличие всех привилегий из списка
     * @param privileges
     */
    haveAllPrivilegesFromList = (privileges: string[]): boolean => {
        if (!this.privilegeByName || !privileges) return false

        return !privileges.some(p => !this.privilegeByName.has(p))
    }

    /**
     * Проверяет наличие хотя бы одной привилегии из модуля
     * @param module
     */
    haveAnyPrivilegeFromModule = (module: AppModule): boolean => {
        return this.privileges.some(p => p.moduleName === module)
    }

    /**
     * Возвращает все привилегии из модуля, кроме указанных
     * @param {AppModule} module
     * @param {string[]} except
     * @returns {PrivilegeVO[]}
     */
    getPrivilegesFromModule = (module: AppModule, except: string[] = []): PrivilegeVO[] => {
        return this.privileges
            .filter(p => !except.includes(p.name) && p.moduleName === module)
    }
}
