import { SegmentVO } from './set-retail10-commons/data-structs-module/segment-vo'
import { PartialList } from './set-retail10-commons/data-structs-module/partial-list'
import { SegmentsFilter } from './set-retail10-commons/data-structs-module/segments-filter'
import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class SegmentService extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-Discounts/SET/Discount/SegmentService!ru.crystalservice.setv6.discounts.SegmentService'
    }

    getSegmentsByFilter = (filter: SegmentsFilter, page: number, itemsPerPage: number, options?: RequestOptions): Promise<PartialList<SegmentVO>> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getSegmentsByFilter',
                params: [filter, page, itemsPerPage]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getSegmentsByGuids = (guids: string[], options?: RequestOptions): Promise<SegmentVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getSegmentsByGuids',
                params: [guids]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

    getSegmentsByNameOrCodeLike = (arg: string, maxResult: number, options?: RequestOptions): Promise<SegmentVO[]> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getSegmentsByNameOrCodeLike',
                params: [arg, maxResult]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                // TODO сейчас в транспорте, если в результате запроса возвращается массив или простой тип, то jackson присылает обертку
                // TODO Для массива - result: ["some.java.Class", [...resultArray]]
                if (result && result.length > 1) {
                    result = result[1]
                }
                
                return Promise.resolve(result)
            }
        })
    }

}

export const segmentService: SegmentService = new SegmentService()
