import React from 'react'
import { PageMock } from '../../components/page-mock/page-mock'
import {
    COUNTERPARTIES,
    DEPARTMENTS,
    HANDBOOKS,
    PRODUCERS,
    PRODUCT_TYPES,
    PIECE_RESTRICTION,
    ALCOHOL_TIME_LIMITS,
    ALCOHOL_AGE_LIMITS,
    getRouteName,
    } from '../core/app-routes'
import { RouteScheme } from '../utils/route-modules-util'
import LibraryBooks from '@material-ui/icons/LibraryBooks'
import { config } from '../config/config'
import { SETRETAILX_COMMONSETUP_ADMIN, SETRETAILX_TYPESGONFIG } from '../core/privileges/privileges'
import { lazy } from '../utils/code-splitting'

export const otherRoutesScheme = (): RouteScheme => ({
    navItemProps: {
        id: 'otherNavLink',
        label: getRouteName(HANDBOOKS),
        icon: <LibraryBooks/>,
    },
    path: HANDBOOKS,
    children: [
        {
            navItemProps: {
                id: 'departmentsNavLink',
                label: getRouteName(DEPARTMENTS),
            },
            privileges: [SETRETAILX_COMMONSETUP_ADMIN],
            path: `${HANDBOOKS}${DEPARTMENTS}`,
            component: lazy(() =>
                import(/* webpackChunkName: "other-departments" */ '../pages/other/departments/other-departments')),
        },
        {
            navItemProps: {
                id: 'productTypesNavLink',
                label: getRouteName(PRODUCT_TYPES),
            },
            privileges: [SETRETAILX_TYPESGONFIG],
            path: `${HANDBOOKS}${PRODUCT_TYPES}`,
            component: lazy(() =>
                import(/* webpackChunkName: "product-types-list" */ '../pages/other/product-types/product-types-list')),
        },
        {
            privileges: [SETRETAILX_TYPESGONFIG],
            path: `${HANDBOOKS}${PRODUCT_TYPES}/:type`,
            component: lazy(() =>
                import(/* webpackChunkName: "product-type" */ '../pages/other/product-types/product-type')),
        },
        {
            privileges: [SETRETAILX_TYPESGONFIG],
            path: `${HANDBOOKS}${PRODUCT_TYPES}/:type${PIECE_RESTRICTION}/:id`,
            component: lazy(() =>
                import(/* webpackChunkName: "piece-product-limit" */ '../pages/other/product-types/product-editors/piece-product-limit'))
        },
        {
            privileges: [SETRETAILX_TYPESGONFIG],
            path: `${HANDBOOKS}${PRODUCT_TYPES}/:type${ALCOHOL_TIME_LIMITS}/:limit`,
            component: lazy(() =>
                import(/* webpackChunkName: "spirits-alcohol-time-limit" */
                    '../pages/other/product-types/product-editors/spirits-alcohol-time-limit')),
        },
        {
            privileges: [SETRETAILX_TYPESGONFIG],
            path: `${HANDBOOKS}${PRODUCT_TYPES}/:type${ALCOHOL_AGE_LIMITS}/:limit`,
            component: lazy(() =>
                import(/* webpackChunkName: "spirits-alcohol-age-limit" */
                    '../pages/other/product-types/product-editors/spirits-alcohol-age-limit')),
        },
        {
            path: `${HANDBOOKS}${COUNTERPARTIES}`,
            component: PageMock
        },
        {
            path: `${HANDBOOKS}${PRODUCERS}`,
            component: PageMock
        },
    ]
})
