import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { t } from 'i18next'
import {
    ServiceRequestMiddleware,
    ServiceErrorMiddleware,
    ServiceResponseMiddleware
} from '../../protocol/set10/base-service'
import { UserStore } from '../store/user-store'
import { getStore } from '../store/stores-repository'
import { USER_STORE, APP_STORE, DIALOG_STORE } from '../store/stores'
import { AppStore } from '../store/app-store'
import { DialogStore } from '../store/dialog-store'
import { ERROR } from '../../components/simple-dialog/simple-dialog'

// Добавляем заголовок, чтобы заработали кроссдоменные куки
export const withCredentialsRequestMiddleware: ServiceRequestMiddleware = (config: AxiosRequestConfig) => {
    config.withCredentials = true
}

// Добавляем обработчик авторизационной ошибки
export const authorizationErrorMiddleware: ServiceResponseMiddleware = (response: AxiosResponse) => {
    const userStore: UserStore = getStore(USER_STORE)

    const AUTHORIZATION_ERROR: number = -32000

    let error: {code?: number, message?: string} = response.data.error

    if (error && error.code === AUTHORIZATION_ERROR) {
        userStore.resetAuthorization()
    }
}

export const internalServerErrorMiddleware: ServiceResponseMiddleware = (response: AxiosResponse) => {
    const appStore: AppStore = getStore(APP_STORE)

    const INTERNAL_ERROR: number = -32603

    let error: { code?: number, message?: string } = response.data.error

    if (error && error.code === INTERNAL_ERROR) {
        let message: string = response.data.error.message
        if (!message) message = ''
        appStore.showErrorDialog(message)
    }
}

// Добавляем общий обработчик ошибок
export const generalErrorMiddleware: ServiceErrorMiddleware = (error: AxiosError) => {
    if (error.response && error.response.status >= 501) {
        const dialogStore: DialogStore = getStore(DIALOG_STORE)
        dialogStore.showDialog({
            title: t('common.commonServerErrorTitle'),
            message: `${error.name}: ${error.message}`,
            mode: ERROR,
            okLabel: t('common.reloadPage'),
            onOk: () => {
                window.location.reload()
            }
        })
        return
    }

    const appStore: AppStore = getStore(APP_STORE)
    appStore.showErrorDialog(`${error.name}: ${error.message}`)
}
