import React from 'react'
import {
    CHECK_TIME_FILTER,
    FilterType,
    PRICE_TAGS_PRINTING_DATE_FILTER,
    PRICES_END_DATE_FILTER,
    PRICES_START_DATE_FILTER,
    ACTUAL_PRICES_DATES_FILTER,
    ACTION_LAST_CHANGED_FILTER,
} from '../../../core/filters/filter'
import { BaseDateRangeFilter } from '../components/base-date-range-filter'
import { InternalFilterComponentProps } from '../new-filters'

export function dateRangeFiltersFactory(filterType: FilterType): typeof DateRangeCommonFilter {
    return class BuiltDateRangeFilter extends React.PureComponent<InternalFilterComponentProps> {
        static filterType: FilterType = filterType

        render() {
            const { id, value, onChange, slim } = this.props

            return (
                <BaseDateRangeFilter
                    id={id}
                    defaultValue={value}
                    onFilterChange={(type: FilterType, value: string) => {
                        onChange(type, { value })
                    }}
                    filterType={filterType}
                    slim={slim}
                />
            )
        }
    }
}

export class DateRangeCommonFilter extends React.PureComponent<InternalFilterComponentProps> {
    render() {
        const { id, value, onChange, slim } = this.props

        return (
            <BaseDateRangeFilter
                id={id}
                defaultValue={value}
                onFilterChange={(type: FilterType, value: string) => {
                    onChange(type, { value })
                }}
                slim={slim}
            />
        )
    }
}

export const LoyActionLastChangedDate = dateRangeFiltersFactory(ACTION_LAST_CHANGED_FILTER)
export const CheckTime = dateRangeFiltersFactory(CHECK_TIME_FILTER)
export const PriceTagsPrintingDate = dateRangeFiltersFactory(PRICE_TAGS_PRINTING_DATE_FILTER)
export const ActualPricesDates = dateRangeFiltersFactory(ACTUAL_PRICES_DATES_FILTER)
export const PricesEndDate = dateRangeFiltersFactory(PRICES_END_DATE_FILTER)
export const PricesStartDate = dateRangeFiltersFactory(PRICES_START_DATE_FILTER)
