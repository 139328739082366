import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import { UsersStore } from '../../../store/staff/users-store'
import Paper from '@material-ui/core/Paper'
import { Table } from '@crystalservice/crystals-ui/lib/components/table/table'
import { Empty } from '@crystalservice/crystals-ui/lib/components/empty/empty'
import { t } from 'i18next'
import { RIGHT } from '@crystalservice/crystals-ui/lib/components/table/column'
import { UserVO } from '../../../../protocol/set10/set-retail10-server/retailx/server-ds/user-vo'
import Divider from '@material-ui/core/Divider'
import { AdaptiveIconButton } from '../../../../components/buttons/adaptive-icon-button/adaptive-icon-button'
import UndoIcon from '@material-ui/icons/Undo'
import EditIcon from '@material-ui/icons/Edit'
import { getUserFullName } from '../../../../utils/name-util'
import { USERS_STORE } from '../../../store/stores'
import { Paginator, ALL_ITEMS } from '@crystalservice/crystals-ui/lib/components/table/paginator'

const styles = require('./users.scss')

export interface BlockedUsersListProps extends React.HTMLProps<HTMLDivElement>{
    usersStore?: UsersStore
}

@inject(USERS_STORE)
@observer
export class BlockedUsersList extends React.Component<BlockedUsersListProps> {

    goToUserHandler = (user: UserVO): void => {
        const { openUser } = this.props.usersStore
        openUser(user)
    }

    render() {
        const { filteredBlockedUsers: users, unblockUser, blockedUsersPaginatorKey, setBlockedUsersPagination,
                blockedUsersPagination } = this.props.usersStore

        if (!users || users.length === 0) {
            return (
                <Paper>
                    <Empty id="blockedUsersEmpty" />
                </Paper>
            )
        }

        return (
            <Paper className={styles.usersList}>
                <Paginator<UserVO>
                    mode={ALL_ITEMS}
                    key={blockedUsersPaginatorKey}
                    defaultItems={toJS(users)}
                    className={styles.paginator}
                    defaultPagination={blockedUsersPagination}
                    onPageItemsChange={setBlockedUsersPagination}
                >
                    <Table
                        key="blockedUsers"
                        id="blockedUsersList"
                        visibleOverflow
                        withPaper={false}
                        withHeader
                        keyField="id"
                        onRowDoubleClick={this.goToUserHandler}
                        columns={[
                            {
                                header: t('staff.userName'),
                                renderer: props => {
                                    const user: UserVO = props.item
                                    return (
                                        <div>
                                            <p>{getUserFullName(user)}</p>
                                            <p className={styles.secondary}>{user.login}</p>
                                        </div>
                                    )
                                },
                                width: '150px',
                            },
                            {
                                header: t('staff.roles'),
                                labelFunction: (user: UserVO): string => {
                                    if (!user.userRoles) return ''
                                    return user.userRoles.map(role => role.roleName).join(', ')
                                },
                                padding: '16px',
                                width: '300px',
                            },
                            {
                                padding: '0 10px',
                                hAlign: RIGHT,
                                width: '120px',
                                showOnRowHover: true,
                                withControls: true,
                                renderer: props => {
                                    let user: UserVO = props.item
                                    return (
                                        <div className={styles.iconsDiv}>
                                            <AdaptiveIconButton
                                                id={`user${user.id}EditButton`}
                                                label={t('common.edit')}
                                                onClick={() => this.goToUserHandler(user)}
                                            >
                                                <EditIcon />
                                            </AdaptiveIconButton>
                                            <AdaptiveIconButton
                                                id={`user${user.id}UnblockButton`}
                                                label={t('common.unblock')}
                                                onClick={() => unblockUser(user)}
                                            >
                                                <UndoIcon />
                                            </AdaptiveIconButton>
                                        </div>
                                    )
                                }
                            }
                        ]}
                    />
                    <Divider className={styles.divider}/>
                </Paginator>
            </Paper>
        )
    }
}
