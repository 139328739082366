import { AdvertisingAction } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/advertising-action'
import { observable, action } from 'mobx'
import { iDiscountsManagerLocal } from '../../../protocol/set10/i-discounts-manager-local'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { USER_STORE, PRODUCT_DETAILS_STORE } from '../stores'
import { createOrderingField } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/ordering-field'
import {
        createDatePeriod
} from '../../../protocol/set10/set-retail10-commons/data-structs-module/date-period'
import { createActionsFilter } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/actions-filter'
import { ProductDetailsStore } from './product-details-store'
import {
    Period,
    PeriodType, DAY
} from '@crystalservice/crystals-ui/lib/components/date-and-time-pickers/period-selector/period-selector'
import moment from 'moment'
import { ACTIVE } from '../../../protocol/set10/set-retail10-server/retailx/server-ds/active-type'
import { PaginationState } from '@crystalservice/crystals-ui/lib/components/pagination/pagination'
import { withSpinner } from '../with-spinner'
import uuid from 'uuid'
import { goTo } from '../../utils/router-util'
import { LOYALTY, ACTIONS, ACTION_EDIT } from '../../core/app-routes'
import { PluginType, ANY } from '../../../protocol/set10/set-retail10-commons/data-structs-module/plugin-type'

const MAX_ACTION_NUM = 100

export class ProductDetailsActionsStore {

    @observable
    actions: AdvertisingAction[] = []

    @observable
    period: Period = {
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate()
    }
    @observable
    periodType: PeriodType = DAY

    @observable
    showOnlyActiveActions: boolean = true

    @observable
    paginatorKey: string = ''

    @observable
    paginationState: PaginationState = undefined

    @observable
    pluginType: PluginType = ANY

    private userStore: UserStore = getStore(USER_STORE)
    private productDetailsStore: ProductDetailsStore = getStore(PRODUCT_DETAILS_STORE)

    @action
    fetchActions = async (): Promise<void> => {
        const period = this.showOnlyActiveActions ?
            createDatePeriod({
                start: this.now(),
                finish: this.now()
            }) :
            createDatePeriod({
                start: this.period.start,
                finish: this.period.end
            })

        const filter = createActionsFilter({
            period,
            good: this.productDetailsStore.productCode,
            activeType: ACTIVE,
            pluginType: this.pluginType
        })

        const orderingList = [
            createOrderingField({
                field: 'workPeriod.start',
                ascending: true
            }),
            createOrderingField({
                field: 'workPeriod.finish', ascending: true
            })
        ]

        await withSpinner(async () => {
                this.actions = await iDiscountsManagerLocal.getActionsByFilterAndOrdering(
                    this.userStore.session,
                    filter, orderingList,
                    0, MAX_ACTION_NUM
                )
                this.paginatorKey = uuid()
            }
        )
    }

    @action
    changePeriod = (period: Period, periodType: PeriodType) => {
        this.period = period
        this.periodType = periodType
        this.fetchActions()
    }

    @action
    changeShowOnlyActiveActions = (value: boolean) => {
        this.showOnlyActiveActions = value
        this.fetchActions()
    }

    @action
    setPaginationState = (paginationState: PaginationState) => {
        this.paginationState = paginationState
    }

    @action
    setPluginType = (pluginType: PluginType): void => {
        this.pluginType = pluginType
        this.fetchActions()
    }

    @action
    reset = (): void => {
        this.period = {
            start: moment().startOf('day').toDate(),
            end: moment().endOf('day').toDate()
        }
        this.actions = []
        this.periodType = DAY
        this.paginatorKey = ''
        this.paginationState = undefined
        this.showOnlyActiveActions = true
        this.pluginType = ANY
    }

    goToAction = (action: AdvertisingAction) => {
        goTo(`${LOYALTY}${ACTIONS}${ACTION_EDIT}/${action.guid}`)
    }

    now = (): Date => new Date()
}
