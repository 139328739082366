import { t } from 'i18next'
import * as React from 'react'
import { iProductsManagerLocal } from '../../../protocol/set10/i-products-manager-local'
import { SimpleProductsGroup } from '../../../protocol/set10/set-retail10-commons/data-structs-module/simple-products-group'
import { uniq } from 'lodash'
import { ListInputProps, ListInput } from '@crystalservice/crystals-ui/lib/components/inputs/list-input/list-input'

// Вынес отдельно, т.к. фильтр ProductGroup использует эти же данные
export const getProductGroupsListInputPresetProps = (sessionId: string): ListInputProps<SimpleProductsGroup> => ({
    showAutocomplete: true,
    AutocompleteProps: {
        fetchDataCallback: namePart => iProductsManagerLocal.getProductGroups(sessionId, namePart),
        labelField: 'name',
        placeholder: t('shop.productGroup')
    },
    getAppendedSuggestionText: productGroup => productGroup.code,
    getEntryTypeText: count => t('components.listInput.entryType.productGroupCode', { count }),
    validator: async inputValue => {
        const inputGroupCodes: string[] = inputValue.split(',')
        try {
            const allGroups: SimpleProductsGroup[] = await iProductsManagerLocal.getProductGroups(sessionId, '')
            const correct: string[] = []
            const incorrect: string[] = []

            inputGroupCodes.forEach(groupCode => {
                if (allGroups.find(g => g.code === groupCode)) {
                    correct.push(groupCode)
                } else {
                    incorrect.push(groupCode)
                }
            })

            return {correct: uniq(correct), incorrect: uniq(incorrect)}
        } catch (error) {
            console.warn(error)
            return {correct: [], incorrect: []}
        }
    }
})

export interface ProductGroupsListInputProps extends ListInputProps<SimpleProductsGroup> {
    sessionId: string
}

export class ProductGroupsListInput extends React.Component<ProductGroupsListInputProps> {
    render() {
        const {onChange, sessionId, ...other} = this.props

        return (
            <ListInput<SimpleProductsGroup>
                {...other}
                label={t('shop.productGroup')}
                onChange={onChange}
                {...getProductGroupsListInputPresetProps(sessionId)}
            />
        )
    }
}
