import React from 'react'
import { observable, action, runInAction, toJS, computed, extendObservable } from 'mobx'
import { isEqual, cloneDeep } from 'lodash'
import Button from '@material-ui/core/Button'
import { t } from 'i18next'
import { cashManagerLocal } from '../../../../protocol/set10/cash-manager-local'
import {
    CashTemplateVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-template-vo'
import {
    CashierQuestionVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cashier-question-vo'
import { getStore } from '../../stores-repository'
import { FormValidation } from '../../../../utils/form-validation/form-validation'
import { requiredField } from '../../../../utils/form-validation/validators/required-field'
import { uniqueField } from '../../../../utils/form-validation/validators/unique-field'
import { goTo } from '../../../utils/router-util'
import { CASH_MODULE, TEMPLATES } from '../../../core/app-routes'
import { getUniqueNameWithNumberPostfix2, postfixRegexWithBrackets } from '../../../../utils/name-util'
import { SNACKBAR_STORE, DIALOG_STORE, USER_STORE, APP_BAR_STORE, APP_STORE } from '../../stores'
import { DIALOG } from '../../../../components/simple-dialog/simple-dialog'
import { SnackbarStore } from '../../snackbar-store'
import { DialogStore } from '../../dialog-store'
import { UserStore } from '../../user-store'
import { ShiftOpenDocumentVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/shift-open-document-vo'
import {
    ShiftCloseDocumentVO, createShiftCloseDocumentVO
} from '../../../../protocol/set10/set-retail10-commons/data-structs-module/shift-close-document-vo'
import { LEFT_ARROW, AppBarStore } from '../../app-bar-store'
import { PaymentTypeIdVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/payment-type-id-vo'
import { AppStore } from '../../app-store'
import { CashFunctionsVO } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-functions-vo'
import { TOUCH_2, CashType, UNDETERMINED } from '../../../../protocol/set10/set-retail10-commons/data-structs-module/cash-type'
import { DeserializedMenuVO } from '../../../core/cash-templates/deserialized-menu-vo'
import { deserializeCashMenuXML, serializeCashMenuToXML } from '../../../core/cash-templates/serialize-utils'
import { getZReport } from '../../../core/cash-templates/shift-documents'
import { withSpinner } from '../../with-spinner'
import { cashMenuScheduleManagerLocal } from '../../../../protocol/set10/cash-menu-schedule-manager-local'

export const ERROR_DELIMITER = '|'
export const EMPTY_TEXT_ERROR = 'emptyTextError'
export const PERIOD_ERROR = 'periodLessThanTimeoutError'

export class CashTemplatesStore {

    @observable
    cashTemplates: CashTemplateVO[] = null

    @observable
    editedTemplate: CashTemplateVO = null

    @observable
    creationDialogOpened: boolean = false

    @observable
    creationDialogNewTemplate: boolean = false

    @observable
    validation: FormValidation<CashTemplateVO> = null

    @observable
    cashFunctions: CashFunctionsVO = null

    @observable
    originalCashFunctions: CashFunctionsVO = null

    @observable
    menuList: DeserializedMenuVO[] = null

    origMenuList: DeserializedMenuVO[] = null

    private userStore: UserStore = getStore(USER_STORE)
    private snackbarStore: SnackbarStore = getStore(SNACKBAR_STORE)
    private dialogStore: DialogStore = getStore(DIALOG_STORE)
    private appStore: AppStore = getStore(APP_STORE)
    private appBarStore: AppBarStore = getStore(APP_BAR_STORE)

    @computed
    get counterpatyPayments(): PaymentTypeIdVO[] {
        return this.editedTemplate.paymentTypeIds.filter(item => item.useWithCounterpartyCard)
    }

    @computed
    get modifiedMenus(): DeserializedMenuVO[] {
        let result = []

        if (!this.menuList) return result

        toJS(this.menuList).forEach((menu, index) => {
            if (!isEqual(menu, this.origMenuList[index])) {
                result.push(menu)
            }
        })

        return result
    }

    @computed
    get cashFunctionsModified(): boolean {
        return !isEqual(toJS(this.cashFunctions), toJS(this.originalCashFunctions))
    }

    @computed
    get cashModified(): boolean {
        const modified = this.validation && this.validation.modified

        return modified || this.cashFunctionsModified || this.modifiedMenus.length > 0
    }

    fetchCashTemplates = async (): Promise<void> => {
        const cashTemplates = await cashManagerLocal.getCashTemplates(this.userStore.session) || []
        runInAction(() => {
            this.cashTemplates = cashTemplates
        })
    }

    fetchCashFunctions = async (template: CashTemplateVO): Promise<void> => {
        if (template.cashType !== TOUCH_2) {
            return
        }
        const cashFunctions = await cashManagerLocal.getCashFunctions(
            this.userStore.session,
            this.appStore.locale,
            template.id,
            template.cashType
        )
        runInAction(() => {
            this.cashFunctions = cashFunctions
            this.originalCashFunctions = cloneDeep(cashFunctions)
        })
    }

    @action
    updateCashFunctions = (groupCode: string, itemCode: string, value: boolean) => {
        const group = this.cashFunctions.groups.find(group => group.code === groupCode)
        const item = group.functions.find(item => item.code === itemCode)
        item.enabled = value
    }

    saveCashFunctions = async (): Promise<void> => {
        const cashFunctions = await cashManagerLocal.saveOrUpdateCashFunctions(
            this.userStore.session,
            this.appStore.locale,
            toJS(this.cashFunctions)
        )
        if (cashFunctions) {
            runInAction(() => {
                this.cashFunctions = cashFunctions
            })
        }
    }

    fetchMenuList = async (): Promise<void> => {
        const { session } = this.userStore
        const { id, cashType } = this.editedTemplate

        const serializedMenuList = await cashManagerLocal.getMenuListByCashType(session, id, cashType) || []
        const deserializedMenuList = []

        for (const menuItem of serializedMenuList) {
            const deserialized = await deserializeCashMenuXML(menuItem)
            deserializedMenuList.push(deserialized)
        }

        runInAction(() => {
            this.menuList = deserializedMenuList
            this.origMenuList = deserializedMenuList
        })
    }

    @action
    setMenuList = (menuList: DeserializedMenuVO[]) => {
        this.menuList = menuList
    }

    editName = async (template: CashTemplateVO, name: string) => {
        this.selectEditedTemplate({
            ...template,
            name
        }, true)
        withSpinner(async () => {
            await this.saveTemplate()
            runInAction(() => {
                this.editedTemplate = null
            })
            this.fetchCashTemplates()
        })
    }

    showTypeChangeDialog = (template: CashTemplateVO, cashType: CashType): void => {
        this.dialogStore.showDialog({
            title: t('cashTemplates.changeCashTypeTitle'),
            message: t('cashTemplates.changeCashTypeMessage', { type: t(`cashTemplates.cashTypes.${cashType}`) }),
            mode: DIALOG,
            onYes: () => this.editCashType(template, cashType)
        })
    }

    editCashType = async (template: CashTemplateVO, cashType: CashType): Promise<void> => {
        this.selectEditedTemplate({
            ...template,
            cashType
        }, true)
        withSpinner(async () => {
            await this.saveTemplate()
            runInAction(() => {
                this.editedTemplate = null
            })
            this.fetchCashTemplates()
        })
    }

    @action
    openTemplate = (template: CashTemplateVO) => {
        this.selectEditedTemplate(template, false)
    }

    openTemplateById = async (templateId: number): Promise<void> => {
        if (!this.cashTemplates) {
            await this.fetchCashTemplates()
        }

        const editedTemplate = this.cashTemplates.find(temp => temp.id === Number(templateId))

        if (!editedTemplate || editedTemplate.cashType === UNDETERMINED) {
            goTo(CASH_MODULE + TEMPLATES)
        } else {
            editedTemplate.paymentTypeIds = editedTemplate.paymentTypeIds.map(type => {
                return type.hasOwnProperty('useIndividual')
                    ? type
                    : ({ ...type, useIndividual: true })
            })

            this.openTemplate(editedTemplate)
        }
    }

    copyTemplate = (template: CashTemplateVO): void => {
        const newTemplate: CashTemplateVO = {
            ...template,
            id: -1,
            guid: null,
            name: getUniqueNameWithNumberPostfix2(
                this.cashTemplates.map(t => t.name),
                template.name,
                postfixRegexWithBrackets
            ),
        }

        this.selectEditedTemplate(newTemplate, true)
        runInAction(() => {
            this.creationDialogOpened = true
            this.creationDialogNewTemplate = false
        })
    }

    deleteTemplate = async (template: CashTemplateVO): Promise<void> => {
        this.dialogStore.showDialog({
            title: t('cashTemplates.deleteConfirmationTitle'),
            message: t('cashTemplates.deleteConfirmationMessage', { name: template.name }),
            mode: DIALOG,
            onYes: async () => {
                await cashManagerLocal.deleteCashTemplate(this.userStore.session, template.id)
                this.fetchCashTemplates()
                this.snackbarStore.show({
                    message: t('cashTemplates.templateDeletedMessage', { name: template.name }),
                    actions: [
                        <Button
                            key="snackbarUndoButton"
                            id="snackbarUndoButton"
                            onClick={async () => {
                                await cashManagerLocal.undoDeleteCashTemplate(this.userStore.session, template.id)
                                await this.fetchCashTemplates()
                                this.snackbarStore.close()

                            }}
                            color="primary"
                        >
                            { t('common.undo') }
                        </Button>
                    ]
                })
            }
        })
    }

    updateNavMenu = (): void => {
        const templateCode = this.editedTemplate ? `${this.editedTemplate.guid} ` : ''
        this.appBarStore.updateState({
            title: `${templateCode}${t('cashTemplates.cashTemplate')}: ${this.editedTemplate && this.editedTemplate.name}`,
            leftIcon: LEFT_ARROW,
            onLeftIconClick: () => this.requestCancelChanges(false)
        })
    }

    requestCancelChanges = (ignoreModified: boolean = true): void => {
        const cancel = () => {
            this.cancelCreatingTemplate()
            goTo(CASH_MODULE + TEMPLATES)
        }

        if (!this.cashModified || ignoreModified) {
            cancel()
            return
        }

        this.dialogStore.showDialog({
            title: t('cashTemplates.cancelChangesTitle'),
            message: t('cashTemplates.cancelChangesConfirmationMessage'),
            mode: DIALOG,
            onYes: cancel
        })
    }

    addNewTemplate = async (): Promise<void> => {
        const templateDefaults: CashTemplateVO = await cashManagerLocal.getCashTemplateDefaults()

        const newTemplate: CashTemplateVO = {
            ...templateDefaults,
            cashType: TOUCH_2,
            name: getUniqueNameWithNumberPostfix2(
                this.cashTemplates.map(t => t.name),
                t('cashTemplates.newTemplate'),
                postfixRegexWithBrackets
            ),
            shiftCloseDocuments: [createShiftCloseDocumentVO({
                documentId: getZReport().documentId
            })]
        }

        this.selectEditedTemplate(newTemplate, true)

        runInAction(() => {
            this.creationDialogOpened = true
            this.creationDialogNewTemplate = true
        })
    }

    showDisableReceiptPrintHelp = (): void => {
        this.appStore.showDialog({
            message: t('cashTemplates.disableReceiptPrintHelp')
        })
    }

    showPrintLegalEntityHeaderHelp = (): void => {
        this.appStore.showDialog({
            message: t('cashTemplates.printLegalEntityHeaderHelp')
        })
    }

    showDiscOnPaySuspendedHelp = (): void => {
        this.appStore.showDialog({
            message: t('cashTemplates.discOnPaySuspendedHelp')
        })
    }

    @action
    updateTemplate = (changes: Partial<CashTemplateVO>): void => {
        Object.keys(changes).forEach(key => {
            if (!this.editedTemplate.hasOwnProperty(key)) {
                this.editedTemplate = {...extendObservable(this.editedTemplate, { [key]: changes[key] })}
            } else {
                this.editedTemplate[key] = changes[key]
            }
        })
    }

    @action
    cancelCreatingTemplate = (): void => {
        this.editedTemplate = null
        this.creationDialogOpened = false
        this.creationDialogNewTemplate = false
        this.validation = null
        this.cashFunctions = null
        this.originalCashFunctions = null
    }

    @action
    saveTemplate = async (): Promise<void> => {
        const { session } = this.userStore
        const { id } = this.editedTemplate

        if (this.validation.originalItem.menuScheduleGuid !== this.editedTemplate.menuScheduleGuid) {
            await cashMenuScheduleManagerLocal.applyMenuSchedule(
                this.editedTemplate.menuScheduleGuid,
                this.editedTemplate.guid
            )
        }

        if (this.validation && this.validation.modified) {
            const savedTemplate = await cashManagerLocal.addCashTemplate(session, toJS(this.editedTemplate))
            if (savedTemplate) {
                this.selectEditedTemplate(savedTemplate, false)
            }
        }

        if (this.modifiedMenus.length > 0) {
            for (const menuItem of this.modifiedMenus) {
                await cashManagerLocal.saveOrUpdateMenu(session, serializeCashMenuToXML(menuItem), id)
            }

            await this.fetchMenuList()
        }

        if (this.cashFunctionsModified) {
            await this.saveCashFunctions()
            await this.fetchCashFunctions(this.editedTemplate)
        }

        const successMessageKey = !id || id < 0 ? 'cashTemplates.templateCreatedMessage' : 'cashTemplates.templateSavedMessage'

        this.snackbarStore.show({
            message: t(successMessageKey, { name: this.editedTemplate.name }),
        })
    }

    @action
    reset = (): void => {
        this.cashTemplates = null
        this.editedTemplate = null
        this.creationDialogOpened = false
        this.creationDialogNewTemplate = false
        this.validation = null
        this.cashFunctions = null
        this.originalCashFunctions = null
        this.menuList = null
        this.origMenuList = null
    }

    private selectEditedTemplate = (template: CashTemplateVO, isNew: boolean) => {
        runInAction(() => {
            this.editedTemplate = toJS(template) // deepClone, чтобы при редактировании не менялись this.cashTemplates
        })

        // обязательно в следующем action, чтобы присванивание this.editedTemplate успело примениться
        runInAction(() => {
            this.createValidation(isNew)
        })
    }

    @action
    private createValidation = (isNew: boolean): void => {
        this.validation = new FormValidation<CashTemplateVO>(
            this.editedTemplate,
            [
                {
                    field: 'name',
                    rules: [
                        requiredField,
                        uniqueField(this.cashTemplates
                            .filter(temp => temp.guid !== this.editedTemplate.guid)
                            .map(temp => temp.name),
                        )
                    ]
                },
                {
                    field: 'cashierQuestions',
                    rules: [
                        (cashierQuestions: CashierQuestionVO[]) => {
                            if (!cashierQuestions) return { valid: true }
                            for (let question of cashierQuestions) {
                                const { period, timeout, question: text } = question
                                const errors = []
                                if (!text) {
                                    errors.push(EMPTY_TEXT_ERROR)
                                }
                                if (timeout >= period) {
                                    errors.push(PERIOD_ERROR)
                                }
                                if (errors.length) {
                                    return { valid: false, error: errors.join(ERROR_DELIMITER) }
                                }
                            }
                            return { valid: true }
                        }
                    ]
                }
            ],
            isNew,
            cashTemplatesComparator
        )
    }
}

function cashTemplatesComparator(item1: CashTemplateVO, item2: CashTemplateVO): boolean {
    const compareDocuments = (docs1: Array<ShiftOpenDocumentVO | ShiftCloseDocumentVO> = [],
                              docs2: Array<ShiftOpenDocumentVO | ShiftCloseDocumentVO> = []): boolean => {
        if (docs1.length !== docs2.length) {
            return false
        }

        let equal = true

        docs1.forEach((doc, index) => {
            if (docs2[index].documentId !== doc.documentId) {
                equal = false
            }
        })

        return equal
    }

    const {
        shiftOpenDocuments: item1ShiftOpenDocuments,
        shiftCloseDocuments: item1ShiftCloseDocuments,
        ...item1Other
    } = item1
    const {
        shiftOpenDocuments: item2ShiftOpenDocuments,
        shiftCloseDocuments: item2ShiftCloseDocuments,
        ...item2Other
    } = item2

    return compareDocuments(item1ShiftOpenDocuments, item2ShiftOpenDocuments)
        && compareDocuments(item1ShiftCloseDocuments, item2ShiftCloseDocuments)
        && isEqual(item1Other, item2Other)
}
