import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

export class ScrollToTopWithoutRouter extends React.Component<RouteComponentProps<any>> {
    componentDidUpdate(prevProps: Readonly<RouteComponentProps<any>>) {
        if (this.props.location !== prevProps.location) {
            const pageContent = document.getElementById('pageContent')
            if (pageContent) pageContent.scrollTop = 0
        }
    }

    render(): JSX.Element {
        return null
    }
}

export default withRouter(ScrollToTopWithoutRouter)
