import { RoleRightVO } from './role-right-vo'
import { UserVO } from './user-vo'

export interface RoleVO {
    '@class': 'ru.crystals.pos.user.RoleVO'
    id?: number
    guid?: number
    name?: string
    rights?: RoleRightVO[]
    users?: UserVO[]
}

export const createRoleVO = (initialization: Partial<RoleVO>): RoleVO => {
    if (!initialization) return null
    
    let result: RoleVO = {
        id: initialization.id,
        guid: initialization.guid,
        name: initialization.name,
        rights: initialization.rights,
        users: initialization.users,
        '@class': 'ru.crystals.pos.user.RoleVO',
    }
    
    return result
}
