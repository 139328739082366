import { Cancelable, debounce, isNaN, isNil } from 'lodash'
import { action, observable, runInAction, toJS } from 'mobx'
import { cashiersManagerLocal } from '../../../protocol/set10/cashiers-manager-local'
import { PartialList } from '../../../protocol/set10/set-retail10-commons/data-structs-module/partial-list'
import { RoleVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/role-vo'
import { UserVO } from '../../../protocol/set10/set-retail10-commons/data-structs-module/user-vo'
import { AUTOSAVE_DELAY } from '../../../utils/default-timeouts'
import { CASHIERS, STAFF } from '../../core/app-routes'
import { NEW } from '../../core/values'
import { goTo } from '../../utils/router-util'
import { CASHIER_SETTINGS_STORE, USER_STORE } from '../stores'
import { getStore } from '../stores-repository'
import { UserStore } from '../user-store'
import { withSpinner } from '../with-spinner'
import { CashierSettingsStore } from './cashier-settings-store'
import { iTopologyManagerLocal } from '../../../protocol/set10/i-topology-manager-local'
import { t } from 'i18next'

export const ACTIVE_CASHIERS_TAB_ID: number = 0
export const BLOCKED_CASHIERS_TAB_ID: number = 1
export const ALL_ROLES_VALUE: number = -1
export const NO_ROLE_VALUE: number = -2

export const CASHIERS_ON_PAGE = 20

export class CashiersSearchStore {
    @observable
    cashiers: UserVO[] = []

    @observable
    cashierPartialList: PartialList<UserVO>

    @observable
    roles: RoleVO[] = []

    @observable
    rolesOptions: Array<{ label: string, value: number }> = []

    @observable
    nameOrTabNumberFilter: string = ''

    @observable
    roleId: number = ALL_ROLES_VALUE

    @observable
    hasEmptyPassword: boolean = false

    @observable
    isBlocked: boolean = false

    @observable
    shopsList: Array<{ label: string, value: number }> = []

    @observable
    shopsNumbersValues: number[] = []

    fetchCashiersDebounced: ((pageNumber?: number) => Promise<void>) & Cancelable = debounce(
        (pageNumber: number = 1) => this.fetchCashiers(pageNumber),
        AUTOSAVE_DELAY
    )

    private userStore: UserStore = getStore(USER_STORE)
    private cashierSettingsStore: CashierSettingsStore = getStore(CASHIER_SETTINGS_STORE)

    fetchCashiers = (pageNumber: number = 1): Promise<void> => {
        return withSpinner(
            async () => {
                const cashierList: PartialList<UserVO> = await cashiersManagerLocal.getCashierList(
                    this.userStore.session,
                    this.nameOrTabNumberFilter,
                    this.isBlocked,
                    this.roleId !== NO_ROLE_VALUE ? this.roleId : null,
                    toJS(this.shopsNumbersValues),
                    pageNumber,
                    this.hasEmptyPassword
                )
                runInAction(() => {
                    this.cashierPartialList = cashierList
                    this.cashiers = cashierList.items
                })
            }
        )
    }

    fetchShops = async (): Promise<void> => {
        let shopsList: Array<{ label: string, value: number }> = [
            {label: t('shopsSearch.allShops'), value: -1}
        ]
        const fetchedShops = await iTopologyManagerLocal.getShops(this.userStore.session, 0, 0, 0, 0)

        fetchedShops.forEach(shop => {
            shopsList.push({ label: shop.number.toString(), value: shop.number })
        })

        this.shopsList = shopsList
    }

    fetchCashiersRoles = async (): Promise<void> => {
        const roles: RoleVO[] = await withSpinner(
            cashiersManagerLocal.getRoles(this.userStore.session)
        )
        runInAction(() => {
            this.roles = roles
        })
        this.fillRolesOptions()
    }

    @action
    fillRolesOptions = (): void => {
        this.rolesOptions = this.roles.map(role => (
            {
                label: role.name,
                value: role.id,
            }
        ))
    }

    @action
    filterCashiersByNameOrTabNumber = (value: string): void => {
        if (this.nameOrTabNumberFilter !== value) {
            this.nameOrTabNumberFilter = value
            this.fetchCashiersDebounced()
        }
    }

    @action
    filterCashiersByRoleId = (id: number): void => {
        if (this.roleId !== id) {
            this.roleId = id
            this.fetchCashiers()
        }
    }

    @action
    filterCashiersByPasswordPresence = (hasEmptyPassword: boolean): void => {
        if (this.hasEmptyPassword !== hasEmptyPassword) {
            this.hasEmptyPassword = hasEmptyPassword
            this.fetchCashiers()
        }
    }

    @action
    switchCashiersTabs = (isBlocked: boolean): void => {
        if (this.isBlocked !== isBlocked) {
            this.isBlocked = isBlocked
            this.fetchCashiers()
        }
    }

    blockCashier = async (cashier: UserVO): Promise<void> => {
        await withSpinner(
            cashiersManagerLocal.addCashier(this.userStore.session, {
                ...toJS(cashier),
                blocked: true,
                // Нужно всегда передавать undefined, его задаёт сервер
                tabNum: undefined
            })
        )
        this.fetchCashiers()
    }

    unblockCashier = async (cashier: UserVO): Promise<void> => {
        await withSpinner(
            cashiersManagerLocal.addCashier(this.userStore.session, {
                ...toJS(cashier),
                blocked: false,
                // Нужно всегда передавать undefined, его задаёт сервер
                tabNum: undefined
            })
        )
        this.fetchCashiers()
    }

    openCashier = async (ref: string): Promise<void> => {
        if (isNil(ref)) return Promise.resolve()

        // Создание нового кассира
        if (ref === NEW) {
            await this.cashierSettingsStore.fillRolesOptions(toJS(this.roles))
            await this.cashierSettingsStore.setEmptyCashier()
            await this.cashierSettingsStore.createFormValidation(true)
            goTo(`${STAFF}${CASHIERS}/${ref}`)
        }

        const id = Number(ref)

        if (isNaN(id)) return Promise.resolve()

        // Редактирование существующего кассира
        const cashier: UserVO = this.cashiers.find(c => c.id === id)

        if (!cashier) return Promise.resolve()

        await this.cashierSettingsStore.fillRolesOptions(toJS(this.roles))
        await this.cashierSettingsStore.setExistingCashier(cashier)
        await this.cashierSettingsStore.createFormValidation()
        goTo(`${STAFF}${CASHIERS}/${id}`)
    }

    setShopNumbersIds = (selectedShops: number[]): void => {
        this.shopsNumbersValues = selectedShops
        this.fetchCashiers()
    }

    @action
    reset = (): void => {
        this.cashiers = []
        this.cashierPartialList = undefined
        this.roles = []
        this.rolesOptions = []
        this.nameOrTabNumberFilter = ''
        this.roleId = ALL_ROLES_VALUE
        this.hasEmptyPassword = false
        this.isBlocked = false
        this.shopsList = []
        this.shopsNumbersValues = []
    }
}
