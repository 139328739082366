import * as React from 'react'
import {observer, inject} from 'mobx-react'
import {AppStore} from '../../store/app-store'
import {LoginStore} from '../../store/login-store'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import {t} from 'i18next'
import {APP_STORE, LOGIN_STORE} from '../../store/stores'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { TextField } from '@crystalservice/crystals-ui/lib/components/inputs/text-field/text-field'

const styles = require('./login.scss')

const CENTRUM_LOGO: string = require('../../../assets/images/icons/logo/set-centrum-blue-logo.png')
const RETAIL_LOGO: string = require('../../../assets/images/icons/logo/set-retail-blue-logo.png')

export interface LoginProps extends React.HTMLProps<HTMLDivElement> {
    app?: AppStore
    login?: LoginStore
}

export interface LoginState {
    licenseAgreementPopupOpen: boolean
    licenseServerInfoPopupOpen: boolean
    capsLockWarning: boolean
}

@inject(APP_STORE)
@inject(LOGIN_STORE)
@observer
export class Login extends React.Component<LoginProps, LoginState> {

    state: LoginState = {
        licenseAgreementPopupOpen: false,
        licenseServerInfoPopupOpen: false,
        capsLockWarning: false,
    }

    checkForCapsLock = (event: Partial<KeyboardEvent>) => {
        this.setState({
            capsLockWarning: event.getModifierState('CapsLock')
        })
    }

    loginChangeHandler = (value: string) => {
        this.props.login.updateLogin(value)
    }

    passwordChangeHandler = (value: string) => {
        this.props.login.updatePassword(value)
    }

    submitHandler = (event: any) => {
        this.props.login.submit()
        event.preventDefault()
    }

    renderLicenseServerInfoLink(): React.ReactNode {
        const licenseInfo = this.props.app.licenseInfo

        let text
        if (!licenseInfo.licenseServerConnection) {
            text = t('set10.noConnectionWithLicenseServer')
        }
        else {
            text = t('set10.setIdNotSpecified')
        }

        if (!licenseInfo.licenseServerConnection || !licenseInfo.setId) {
            return (
                <a
                    id="licenseServerInfoLink"
                    href="#"
                    className={styles.licenseLink}
                    onClick={() => this.setState({licenseServerInfoPopupOpen: true})}
                >
                    {text}
                </a>
            )
        }

        return null
    }

    renderLicenceServerInfoPopup(): React.ReactNode {
        const popupOpen = this.state.licenseServerInfoPopupOpen

        return (
            <Dialog
                id="licenseServerInfoPopup"
                open={popupOpen}
            >
                <DialogContent>
                    <div dangerouslySetInnerHTML={{__html: t('login.licenseServerConnectionText')}}/>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={() => this.setState({licenseServerInfoPopupOpen: false})}>
                        {t('common.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        let { wrongPassword, login, password } = this.props.login

        const capsLockWarningText = this.state.capsLockWarning ? t('login.capsLockWarning') : null
        const passwordWarning = wrongPassword ? t('login.wrongPassword') : null
        return (
            <div
                id="loginPage"
                className={styles.login}
            >
                <div className={styles.loginFormContainer}>
                    <img
                        id="logo"
                        className={styles.img}
                        src={this.props.app.isCentrum ? CENTRUM_LOGO : RETAIL_LOGO}
                    />
                    <form
                        id="loginForm"
                        action=""
                        className={styles.form}
                        onSubmit={this.submitHandler}
                    >
                        <Paper className={styles.loginForm}>
                            <h1 className={styles.title}>{t('login.enterToService')}</h1>
                            <TextField
                                autoFocus
                                id="nameInput"
                                value={login}
                                label={t('login.login')}
                                onValueChange={this.loginChangeHandler}
                            />
                            <TextField
                                id="passwordInput"
                                value={password}
                                label={t('login.password')}
                                type="password"
                                errorText={passwordWarning || capsLockWarningText}
                                onValueChange={this.passwordChangeHandler}
                                onKeyPress={this.checkForCapsLock}
                            />
                            <Button
                                id="submitButton"
                                type="submit"
                                className={styles.enterButton}
                            >
                                {t('login.enter')}
                            </Button>
                        </Paper>
                    </form>
                </div>
                <div className={styles.links}>
                    {this.renderLicenseServerInfoLink()}
                </div>
                {this.renderLicenceServerInfoPopup()}
            </div>
        )
    }

}
