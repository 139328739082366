import { observable, action, runInAction, autorun } from 'mobx'
import { PRICE_TAGS_PRINTING_STORE } from '../../stores'
import { getStore } from '../../stores-repository'
import { PriceTagsPrintingStore } from './printing-store'
import { PriceTagsFiltersState } from '../../../pages/price-tags/printing/price-tags-filters'
import { PriceTagFormatVO } from '../../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-vo'
import {
    ACTION,
    REGULAR
} from '../../../../protocol/set10/set-retail10-server/retailx/set-template-formats/price-tag-format-type'

export class PrintByFormatsSelectionStore {

    @observable
    filters: PriceTagsFiltersState = null

    @observable
    filteredFormats: PriceTagFormatVO[] = []

    @observable
    tableKey: string = ''

    private printingStore: PriceTagsPrintingStore = getStore(PRICE_TAGS_PRINTING_STORE)

    constructor() {
        autorun(() => {
            const { availableFormats, shelfParams, selectedFormats, setSelectedFormats, actionable } = this.printingStore
            if (!availableFormats || !shelfParams) {
                return
            }
            const filteredFormats = getFilteredFormats(
                availableFormats,
                this.filters,
                actionable
            )

            let nextTableKey: string = filteredFormats.map(format => format.id).join(';')

            runInAction(() => {
                if (nextTableKey !== this.tableKey) {
                    this.tableKey = nextTableKey
                    setSelectedFormats(selectedFormats.filter(selectedFormat => {
                        return filteredFormats.some(format => format.id === selectedFormat.id)
                    }))
                }
                this.filteredFormats = filteredFormats
            })
        })
    }

    @action
    setFilters = (newFilters: PriceTagsFiltersState): void => {
        this.filters = newFilters
    }

    @action
    reset = (): void => {
        this.filters = null
        this.filteredFormats = []
        this.tableKey = ''
    }
}

function getFilteredFormats(availableFormats: PriceTagFormatVO[], filters: PriceTagsFiltersState, 
                            actionable: boolean): PriceTagFormatVO[] {

    if (!availableFormats) {
        return []
    }

    let filteredPriceTags: PriceTagFormatVO[] = availableFormats.filter(format => {
        return format.type === ACTION && actionable || format.type === REGULAR && !actionable
    })

    if (!filters) {
        return filteredPriceTags
    }

    const { formatName } = filters

    return filteredPriceTags.filter(format => {
        return format.name.toLowerCase().includes(formatName.toLowerCase().trim())
    })
}
