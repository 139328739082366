import * as React from 'react'
import classNames from 'classnames'
import MUITabs, { TabsProps as MUITabsProps } from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

/* TODO заменить на Tabs из crystals-ui, после обновления в нём mui */

const styles = require('./tabs.scss')

export interface TabsProps {
    items: Array<string | TabInfo>
    onTabChange?: (tab: string | TabInfo, index?: number) => void
    defaultSelectedIndex?: number
    breakpoint?: Breakpoint
    tabContentClassName?: string
    id?: string
    className?: string
    orientation?: 'horizontal' | 'vertical'
    variant?: 'standard' | 'scrollable' | 'fullWidth'
}

export interface TabsState {
    selectedIndex: number
}

export interface TabInfo {
    label: string
    data?: any
    hidden?: boolean
    disabled?: boolean
    id?: string
}

export class Tabs extends React.Component<TabsProps, TabsState> {
    static defaultProps: Partial<TabsProps> = {
        id: '',
        items: [],
        defaultSelectedIndex: 0,
        onTabChange: () => null,
        breakpoint: 'md',
        tabContentClassName: ''
    }

    constructor(props: TabsProps, context: any) {
        super(props, context)

        const defaultIndex = this.getAvailableIndex(props.defaultSelectedIndex, props)
        if (defaultIndex > 0) {
            props.onTabChange(props.items[defaultIndex], defaultIndex)
        }
        this.state = {
            selectedIndex: defaultIndex
        }
    }

    componentWillReceiveProps(nextProps: TabsProps) {
        // Проверяем только случай, когда до этого был пустой массив (при создании компонента уже есть проверка)
        if (this.props.items.length === 0 && nextProps.items.length !== 0) {
            // Нам пришли новые данные => проверяем не выбран ли у нас заблокированный элемент
            let newIndex = this.getAvailableIndex(this.state.selectedIndex, nextProps)
            if (newIndex > 0) {
                nextProps.onTabChange(nextProps.items[newIndex])
            }
            this.setState({ selectedIndex: newIndex })
        }
    }

    /**
     * Проверяет, является ли вкладка заблокированной и находит индекс ближайшей незаблокированной вкладки
     * @param {number} currentIndex - индекс который надо проверять
     * @param {TabsProps} props - свойства компонента
     */
    getAvailableIndex = (currentIndex: number, props: TabsProps): number => {
        let usedIndex = currentIndex
        let defaultItem = props.items[usedIndex] as TabInfo

        // Нельзя выбрать вкладку которая скрыта либо заблокирована по умолчанию
        if (defaultItem && (defaultItem.hidden || defaultItem.disabled)) {
            usedIndex = 0
            props.items.some((item, index) => {
                let tabItem = item as TabInfo
                if (!tabItem.disabled && !tabItem.hidden) {
                    usedIndex = index
                    return true
                }

                return false
            })
        }
        return usedIndex
    }

    tabChangeHandler = (event: any, value?: number): void => {
        const { items, onTabChange } = this.props
        this.setState({ selectedIndex: value })
        onTabChange(items[value], value)
    }

    render() {
        const {
            children, items, className, onTabChange, defaultSelectedIndex, breakpoint, tabContentClassName,
            orientation = 'horizontal', variant, ...other
        } = this.props
        const {selectedIndex} = this.state

        let childrenNum: number = 0
        if (children) {
            childrenNum = children instanceof Array ? children.length : 1
        }
        let selectedChildren = null
        if (children instanceof Array) {
            selectedChildren = childrenNum > 0 && selectedIndex < childrenNum ? children[selectedIndex] : null
        } else {
            selectedChildren = children
        }

        return (
            <div {...other} className={classNames(className, styles.tabs)}>
                <Paper className={styles.tabsContainer}>
                    <TabsWrapper
                        className={styles.tabsComponent}
                        value={selectedIndex}
                        onChange={this.tabChangeHandler}
                        breakpoint={breakpoint}
                        scrollButtons="on"
                        textColor="primary"
                        indicatorColor="primary"
                        orientation={orientation}
                        variant={variant}
                    >
                        { items.map((tab, index) => {
                            const gotTabInfo = typeof tab !== 'string'
                            let label: string
                            let disabled: boolean = false
                            let id = `${other.id}Tab${index}`

                            if (gotTabInfo) {
                                const tabObj = tab as TabInfo
                                label = tabObj.label

                                // Обработка заблокированных и скрытых
                                if (tabObj.disabled) {
                                    disabled = true
                                    if (index === selectedIndex) {
                                        selectedChildren = null
                                    }
                                }
                                if (tabObj.hidden) {
                                    if (index === selectedIndex) {
                                        selectedChildren = null
                                    }
                                    return null
                                }
                                if (tabObj.id) {
                                    id = tabObj.id
                                }
                            } else {
                                const tabObj = tab as string
                                label = tabObj
                            }

                            return (
                                <Tab
                                    id={`${other.id}Tab${index}`}
                                    className={styles.tab}
                                    key={label + index}
                                    value={index}
                                    label={label}
                                    disabled={disabled}
                                />
                            )
                        }) }
                    </TabsWrapper>
                </Paper>

                <div className={classNames(styles.tabContent, tabContentClassName)}>{selectedChildren}</div>
            </div>
        )
    }
}

interface TabsWrapperProps extends MUITabsProps {
    breakpoint?: Breakpoint
}

// https://material-ui.com/components/use-media-query/#migrating-from-withwidth
// withWidth - deprecated, используем хук useMediaQuery
const TabsWrapper: React.FunctionComponent<TabsWrapperProps> = props => {
    const { breakpoint, variant, ...other } = props
    const largeScreen = useMediaQuery(useTheme().breakpoints.up(breakpoint))

    const screenVariant = largeScreen ? 'fullWidth' : 'scrollable'

    return (
        <MUITabs
            variant={variant ?? screenVariant}
            {...other}
        >
            { props.children }
        </MUITabs>
    )
}
