export interface ActorId {
    '@class': 'ru.crystals.discounts.ActorId'
    login?: string
}

export const createActorId = (initialization: Partial<ActorId>): ActorId => {
    if (!initialization) return null
    
    let result: ActorId = {
        login: initialization.login,
        '@class': 'ru.crystals.discounts.ActorId',
    }
    
    return result
}
