import { POST, BaseService, RequestOptions } from './base-service'
import { getRequestId } from './get-request-id'

export class SalesManagementPropertiesService extends BaseService {

    constructor() {
        super()
        this.endpoint = 'java:app/SET-SalesManagementProperties/SalesManagementPropertiesServiceBean!ru.crystals.setretailx.properties.SalesManagementPropertiesService'
    }

    getProperties1 = (moduleName: string, options?: RequestOptions): Promise<{ [key: string]: string }> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getProperties',
                params: [moduleName]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

    getProperties2 = (moduleName: string, pluginName: string, options?: RequestOptions): Promise<{ [key: string]: string }> => {
        return super.send({
            method: POST,
            data: {
                '@class': 'ru.crystals.commons.jsonrpc.domain.JsonRpcRequest',
                id: getRequestId(),
                version: '1.0.0',
                endpoint: this.endpoint,
                method: 'getProperties',
                params: [moduleName, pluginName]
            }
        }, options)
        .then(response => {
            if (response.error) {
                return Promise.reject(response.error)
            } else {
                let result = response.result
                return Promise.resolve(result)
            }
        })
    }

}

export const salesManagementPropertiesService: SalesManagementPropertiesService = new SalesManagementPropertiesService()
