export type FormulaType = 'simple' | 'minValue' | 'firstNotNull' | 'logic' | 'oldPrice' | 'wholesaleLevel'

export const SIMPLE: FormulaType = 'simple'
export const MIN_VALUE: FormulaType = 'minValue'
export const FIRST_NOT_NULL: FormulaType = 'firstNotNull'
export const LOGIC: FormulaType = 'logic'
export const OLD_PRICE: FormulaType = 'oldPrice'
export const WHOLESALE_LEVEL: FormulaType = 'wholesaleLevel'

export const ALL_FORMULA_TYPES: FormulaType[] = [
    SIMPLE,
    MIN_VALUE,
    FIRST_NOT_NULL,
    LOGIC,
    OLD_PRICE,
    WHOLESALE_LEVEL,
]
