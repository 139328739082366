import {CardType, EXTERNAL_CARD} from '../../../protocol/set10/set-retail10-commons/set-oper-day-api/card'
import {t} from 'i18next'
import { isNil } from 'lodash'

export const getCardTextByType = (type: CardType): string => {
    return t([`checkPage.cardType.${type}`, `checkPage.cardType.${EXTERNAL_CARD}`])
}

export const formatCardNumber = (cardNumber: string): string => {
    let trimmedNumber = cardNumber.split(' ').join('')
    const blockCount = trimmedNumber.length / 4
    let formattedCardNumber = ''

    for (let index = 0; index < blockCount; index++) {
        if (index > 0) formattedCardNumber += ' '
        formattedCardNumber += trimmedNumber.substr(index * 4, 4)
    }
    return formattedCardNumber
}

export const addLeadingZero = (text: string, totalLength: number): string => {
    let result = text
    let currentLength = text.length

    while (currentLength < totalLength) {
        result = '0' + result
        currentLength++
    }
    return result
}

export const calculateCardNumberEnd = (startNumber: string, count: number): string => {
    if (isNil(startNumber) || startNumber === '' || isNil(count)) return ''

    const startLength = startNumber.length

    const endNumber = Number(startNumber) + count - 1
    let result = String(endNumber)

    /**
     * Если количество знаков совпало или больше - добавлять нули не требуется
     */
    let endLength = String(endNumber).length
    if (endLength >= startLength) {
        return result
    }

    result = addLeadingZero(result, startLength)
    return result
}
